import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectUsers = (state) => state.users || initialState;


const makeSelectAllUsersData = () => createSelector(selectUsers, (substate) => substate.allUsersData);
const makeSelectIsAllUsersDataLoaded= () => createSelector(selectUsers, (substate)=> substate.isUsersDataLoaded);
const makeSelectIsUsersDataAdded= () => createSelector(selectUsers, (substate)=> substate.isUserAdded);
const makeSelectIsUsersStatusChanged= () => createSelector(selectUsers, (substate)=> substate.isUserStatusUpdated);
const makeSelectUsersDataError= () => createSelector(selectUsers, (substate)=> substate.error);
const makeSelectUserDataLoading = ()=>createSelector(selectUsers, (substate)=> substate.loading);
const makeSelectUserDataSuccess = ()=>createSelector(selectUsers, (substate)=> substate.success);
const makeSelectUserUpdated = () =>createSelector(selectUsers, (substate)=> substate.isUserUpdated);
const makeSelectEditUserDataLoaded = () =>createSelector(selectUsers, (substate)=> substate.editUserDataLoaded);
const makeSelectIsDeleteSuccess = () =>createSelector(selectUsers, (substate)=> substate.isDeleteSuccess);
export {
    makeSelectAllUsersData,
    makeSelectIsAllUsersDataLoaded,
    makeSelectIsUsersDataAdded,
    makeSelectUsersDataError,
    makeSelectIsUsersStatusChanged,
    makeSelectUserDataLoading,
    makeSelectUserDataSuccess,
    makeSelectUserUpdated,
    makeSelectEditUserDataLoaded,
    makeSelectIsDeleteSuccess
}
