import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectDashboardState= (state) =>  state.Dashboard || initialState;

const makeSelectDashboardLoading = () => createSelector(selectDashboardState, (subState) => subState.loading);
const makeSelectDashboardSuccess = () => createSelector(selectDashboardState, (subState) => subState.success);
const makeSelectDashboardError = () => createSelector(selectDashboardState, (subState) => subState.error);

const makeSelectAllDashboardData = ()=>createSelector(selectDashboardState, (subState) => subState.allDashboardData);
const makeSelectIsAllDashboardDataLoaded = ()=>createSelector(selectDashboardState, (subState) => subState.isAllDashboardDataLoaded);

const makeSelectDashboardSummary = ()=>createSelector(selectDashboardState, (subState) => subState.dashboardSummary);
const makeSelectIsAllDashboardSummaryLoaded = ()=>createSelector(selectDashboardState, (subState) => subState.isDashboardSummaryLoaded);

const makeSelectDashboardChartsData = ()=>createSelector(selectDashboardState, (subState) => subState.allDashboardChartsDataLoaded);
const makeSelectIsAllDashboardChartsDataLoaded = ()=>createSelector(selectDashboardState, (subState) => subState.isDashboardChartsDataLoaded);

export{
    makeSelectDashboardLoading,
    makeSelectDashboardSuccess,
    makeSelectDashboardError,
    makeSelectAllDashboardData,
    makeSelectIsAllDashboardDataLoaded,
    makeSelectDashboardSummary,
    makeSelectIsAllDashboardSummaryLoaded,
    makeSelectDashboardChartsData,
    makeSelectIsAllDashboardChartsDataLoaded
}