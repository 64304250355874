import { createSelector } from "reselect";
import { initialOfferState } from "./reducer";
import _ from "lodash"
const selectOffersState= (state) =>  state.Offers || initialOfferState;

const makeSelectLoading = () => createSelector(selectOffersState, (subState) => subState.loading);
const makeSelectSuccess = () => createSelector(selectOffersState, (subState) => subState.success);
const makeSelectError = () => createSelector(selectOffersState, (subState) => subState.error);


const makeSelectTrackingUrlsReceived = () => createSelector(selectOffersState, (subState) => subState.trackingUrlsReceived);
const makeSelectLoadCurrenciesList = () => createSelector(selectOffersState, (subState) => subState.currenciesListReceived);


const makeSelectNewOfferDataSent = () => createSelector(selectOffersState, (subState) => subState.newOfferDataSent);
const makeSelectOffersDataLoaded = () => createSelector(selectOffersState, (subState) => subState.offerDataLoaded);

const makeSelectOffersDataLoadedSorted = () => createSelector(selectOffersState, (subState) => { 
        let affiliatedNetworkDataLoaded = _.cloneDeep(subState?.affiliatedNetworkDataLoaded?.data ?? [])
        affiliatedNetworkDataLoaded?.sort((first,second) => first.name.localeCompare(second.name))    
        return { data: affiliatedNetworkDataLoaded, total :  subState?.affiliatedNetworkDataLoaded?.total }
    });

const makeSelectEditOfferDataReceived = () => createSelector(selectOffersState, (subState) => subState.editOfferDataReceived);
const makeSelectPatchedOfferDataSent = () => createSelector(selectOffersState, (subState) => subState.patchedOfferDataSent);
const makeSelectDeleteOfferDataSent = () => createSelector(selectOffersState, (subState) => subState.deleteOfferDataSent);

const makeSelectArchiveOfferDataSent = () => createSelector(selectOffersState, (subState) => subState.archiveOfferDataSent);

const makeSelectIsOfferChartsDataLoaded = () => createSelector(selectOffersState, (subState) => subState.charts.isChartsDataLoaded);
const makeSelectAllOfferChartsDataLoaded = () => createSelector(selectOffersState, (subState) => subState.charts.allChartsDataLoaded);

const makeSelectLoadTimeZoneData = () => createSelector(selectOffersState, (subState) => subState.timeZoneDataReceived);
const makeSelectArchiveDeleteResponseOffers = () => createSelector(selectOffersState, (subState) => subState.archiveDeleteResponseOffers);

export {
    makeSelectLoading,
    makeSelectSuccess,
    makeSelectError,
    makeSelectTrackingUrlsReceived,
    makeSelectLoadCurrenciesList,
    makeSelectNewOfferDataSent,
    makeSelectOffersDataLoaded,
    makeSelectEditOfferDataReceived,
    makeSelectPatchedOfferDataSent,
    makeSelectDeleteOfferDataSent,
    makeSelectArchiveOfferDataSent,
    makeSelectLoadTimeZoneData,
    makeSelectIsOfferChartsDataLoaded,
    makeSelectAllOfferChartsDataLoaded,
    makeSelectArchiveDeleteResponseOffers,
    makeSelectOffersDataLoadedSorted
}