import produce from "immer";
import {
    LOAD_CURRENCY, LOAD_CURRENCY_SUCCESS, LOAD_CURRENCY_FAILED,
    POST_TRAFFIC_SOURCE_DATA, POST_TRAFFIC_SOURCE_DATA_SUCCESS, POST_TRAFFIC_SOURCE_DATA_FAILED,
    LOAD_TRAFFIC_SOURCES_DATA, LOAD_TRAFFIC_SOURCES_DATA_SUCCESS, LOAD_TRAFFIC_SOURCES_DATA_FAILED,
    LOAD_EDIT_TRAFFIC_SOURCE_DATA, LOAD_EDIT_TRAFFIC_SOURCE_DATA_SUCCESS, LOAD_EDIT_TRAFFIC_SOURCE_DATA_FAILED,
    SEND_EDIT_TRAFFIC_SOURCE_DATA, SEND_EDIT_TRAFFIC_SOURCE_DATA_SUCCESS, SEND_EDIT_TRAFFIC_SOURCE_DATA_FAILED,
    DELETE_TRAFFIC_SOURCE_DATA, DELETE_TRAFFIC_SOURCE_DATA_SUCCESS, DELETE_TRAFFIC_SOURCE_DATA_FAILED,
    LOAD_TOKEN_TAGS, LOAD_TOKEN_TAGS_SUCCESS, LOAD_TOKEN_TAGS_FAILED,
    LOAD_CUSTOM_TRAFFIC_SOURCE, LOAD_CUSTOM_TRAFFIC_SOURCE_SUCCESS, LOAD_CUSTOM_TRAFFIC_SOURCE_FAILED,
    LOAD_CUSTOM_TRAFFIC_SOURCE_BY_ID, LOAD_CUSTOM_TRAFFIC_SOURCE_BY_ID_SUCCESS, LOAD_CUSTOM_TRAFFIC_SOURCE_BY_ID_FAILED,
    RESET_CUSTOM_TRAFFIC_SOURCE_DATA_BY_ID,RESET_EDIT_TRAFFIC_SOURCE_DATA,
    ARCHIVE_TRAFFIC_SOURCE_DATA, ARCHIVE_TRAFFIC_SOURCE_DATA_SUCCESS, ARCHIVE_TRAFFIC_SOURCE_DATA_FAILED,
    GET_TS_CHARTS_DATA, GET_TS_CHARTS_DATA_SUCCESS, GET_TS_CHARTS_DATA_FAILED
} from "./constants";


export const initialTrafficSourcesState = {
    loading: false,
    success: false,
    error: false,
    newTrafficSourceDataSent: false,
    patchedTrafficSourceDataSent: false,
    deleteTrafficSourceDataSent: false,
    archivedTrafficSourceDataSent: false,
    loadAllData: false,
    isTagsListReceived: false,
    trafficSourcesDataLoaded: null,
    currenciesListReceived: null,
    tagsListReceived: [],
    editTrafficSourceDataReceived: null,

    loadAllCustomTrafficSourceData: null,
    isAllCustomTrafficSourceDataLoaded: false,
    loadCustomTrafficSourceDataById: null,
    isCustomTrafficSourceDataByIdLoaded: false,
    archiveDeleteResponseTS: null,
    charts: {
        isChartsDataLoaded: false,
        allChartsDataLoaded: null
    }
};

const trafficSourcesReducer = (state= initialTrafficSourcesState, action) => produce(state, (draft => {
    switch (action.type) {
        case LOAD_CURRENCY:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case LOAD_CURRENCY_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.currenciesListReceived = action.payload;
            return draft;
        case LOAD_CURRENCY_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            return draft;


        case LOAD_TOKEN_TAGS:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case LOAD_TOKEN_TAGS_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.tagsListReceived = action.payload;
            draft.isTagsListReceived = true;
            return draft;
        case LOAD_TOKEN_TAGS_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            return draft;


        case POST_TRAFFIC_SOURCE_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case POST_TRAFFIC_SOURCE_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.newTrafficSourceDataSent = true;
            draft.editTrafficSourceDataReceived = null;
            draft.loadAllData = false;
            draft.deleteTrafficSourceDataSent = false;
            draft.archivedTrafficSourceDataSent = false;
            draft.patchedTrafficSourceDataSent = false;
            return draft;
        case POST_TRAFFIC_SOURCE_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            draft.newTrafficSourceDataSent = false;
            return draft;


        case LOAD_TRAFFIC_SOURCES_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case LOAD_TRAFFIC_SOURCES_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.trafficSourcesDataLoaded = action.payload;
            draft.editTrafficSourceDataReceived = null;
            draft.loadAllData = true;
            draft.newTrafficSourceDataSent = false;
            draft.patchedTrafficSourceDataSent = false;
            draft.deleteTrafficSourceDataSent = false;
            draft.archivedTrafficSourceDataSent = false;
            return draft;
        case LOAD_TRAFFIC_SOURCES_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            return draft;


        case LOAD_EDIT_TRAFFIC_SOURCE_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case LOAD_EDIT_TRAFFIC_SOURCE_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.editTrafficSourceDataReceived = action.payload;
            draft.loadAllData = false;
            draft.newTrafficSourceDataSent = false;
            draft.patchedTrafficSourceDataSent = false;
            draft.deleteTrafficSourceDataSent = false;
            draft.archivedTrafficSourceDataSent = false;
            return draft;
        case LOAD_EDIT_TRAFFIC_SOURCE_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            return draft;


        case SEND_EDIT_TRAFFIC_SOURCE_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case SEND_EDIT_TRAFFIC_SOURCE_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.patchedTrafficSourceDataSent = true;
            draft.loadAllData = false;
            draft.deleteTrafficSourceDataSent = false;
            draft.newTrafficSourceDataSent = false;
            draft.archivedTrafficSourceDataSent = false;
            return draft;
        case SEND_EDIT_TRAFFIC_SOURCE_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            return draft;


        case DELETE_TRAFFIC_SOURCE_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.archiveDeleteResponseTS = null;
            return draft;
        case DELETE_TRAFFIC_SOURCE_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.editTrafficSourceDataReceived = null;
            draft.deleteTrafficSourceDataSent = true;
            draft.archivedTrafficSourceDataSent = false;
            draft.loadAllData = false;
            draft.newTrafficSourceDataSent = false;
            draft.patchedTrafficSourceDataSent = false;
            draft.archiveDeleteResponseTS = action.payload;
            return draft;
        case DELETE_TRAFFIC_SOURCE_DATA_FAILED:
            console.log("DELETE_TRAFFIC_SOURCE_DATA_FAILED");
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            draft.archiveDeleteResponseTS = null;
            return draft;

        case ARCHIVE_TRAFFIC_SOURCE_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.archiveDeleteResponseTS = null;
            return draft;
        case ARCHIVE_TRAFFIC_SOURCE_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.editTrafficSourceDataReceived = null;
            draft.deleteTrafficSourceDataSent = false;
            draft.archivedTrafficSourceDataSent = true;
            draft.loadAllData = false;
            draft.newTrafficSourceDataSent = false;
            draft.patchedTrafficSourceDataSent = false;
            draft.archiveDeleteResponseTS = action.payload;
            return draft;
        case ARCHIVE_TRAFFIC_SOURCE_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            draft.archiveDeleteResponseTS = null;
            return draft;

        case LOAD_CUSTOM_TRAFFIC_SOURCE:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.loadAllCustomTrafficSourceData = null;
            draft.isAllCustomTrafficSourceDataLoaded = false;
            return draft;
        case LOAD_CUSTOM_TRAFFIC_SOURCE_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.loadAllCustomTrafficSourceData = action.payload;
            draft.isAllCustomTrafficSourceDataLoaded = true;
            return draft;
        case LOAD_CUSTOM_TRAFFIC_SOURCE_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.loadAllCustomTrafficSourceData = null;
            draft.isAllCustomTrafficSourceDataLoaded = false;
            draft.error = action.payload.response;
            return draft;
        case LOAD_CUSTOM_TRAFFIC_SOURCE_BY_ID:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.loadCustomTrafficSourceDataById = null;
            draft.isCustomTrafficSourceDataByIdLoaded = false;
            return draft;
        case LOAD_CUSTOM_TRAFFIC_SOURCE_BY_ID_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.loadCustomTrafficSourceDataById = action.payload;
            draft.isCustomTrafficSourceDataByIdLoaded = true;
            draft.error = false;
            return draft;
        case LOAD_CUSTOM_TRAFFIC_SOURCE_BY_ID_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            draft.loadCustomTrafficSourceDataById = null;
            draft.isCustomTrafficSourceDataByIdLoaded = false;
            return draft;
        case GET_TS_CHARTS_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case GET_TS_CHARTS_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.charts.isChartsDataLoaded = true;
            draft.charts.allChartsDataLoaded = action.payload;
            return draft;
        case GET_TS_CHARTS_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.charts.isChartsDataLoaded = false;
            draft.error = action.payload.response;
            return draft;
        case RESET_CUSTOM_TRAFFIC_SOURCE_DATA_BY_ID:
            draft.loadCustomTrafficSourceDataById = null;
            draft.isCustomTrafficSourceDataByIdLoaded = false;
        case RESET_EDIT_TRAFFIC_SOURCE_DATA:
            draft.editTrafficSourceDataReceived = null;
            draft.success = false;
            return draft;
        default:
            return draft;
    }
}));

export default trafficSourcesReducer;