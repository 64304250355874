
import {
	ADD_PRIVATE_WORKSPACE, ADD_PRIVATE_WORKSPACE_FAILED, ADD_PRIVATE_WORKSPACE_SUCCESS,
	LOAD_PRIVATE_WORKSPACE, LOAD_PRIVATE_WORKSPACE_SUCCESS, LOAD_PRIVATE_WORKSPACE_FAILED,
	EDIT_PRIVATE_WORKSPACE, EDIT_PRIVATE_WORKSPACE_SUCCESS, EDIT_PRIVATE_WORKSPACE_FAILED,
	DELETE_PRIVATE_WORKSPACE,DELETE_PRIVATE_WORKSPACE_SUCCESS,DELETE_PRIVATE_WORKSPACE_FAILED
} from './constants';


export const addPrivateWorkspace = (workspaceName,token) => {
	return {
		type: ADD_PRIVATE_WORKSPACE,
		payload:{workspaceName,token}
	}
}

export const addPrivateWorkspaceSuccess = (workspaceName)=>{
	return {
		type: ADD_PRIVATE_WORKSPACE_SUCCESS,
		payload:{workspaceName}
	}
}

export const addPrivateWorkspaceFailed = (error)=>{
	return {
		type: ADD_PRIVATE_WORKSPACE_FAILED,
		payload:{error}
	}
}

export const fetchingData = (axiosdata) => {
	return {
		type: LOAD_PRIVATE_WORKSPACE,
		payload: {axiosdata}
	}
}

export const fetchingDataSuccess = (data) => {
	return {
		type: LOAD_PRIVATE_WORKSPACE_SUCCESS,
		payload:{data}
	}
}

export const fetchingDataFailed = (error) => {
	return {
		type: LOAD_PRIVATE_WORKSPACE_FAILED,
		payload:{error}
	}
}

export const editData = (name,id,token) => {
	return {
		type: EDIT_PRIVATE_WORKSPACE,
		payload: {name,id,token}
	}
}

export const editDataSuccess = (data) => {
	return {
		type: EDIT_PRIVATE_WORKSPACE_SUCCESS,
		payload: {data}
	}
}

export const editDataFailed = (error) => {
	return {
		type: EDIT_PRIVATE_WORKSPACE_FAILED,
		payload: {error}
	}
}

export const deletePrivateWorkspace = (id, token) => {
	return {
		type: DELETE_PRIVATE_WORKSPACE,
		payload: {id,token}
	}
}

export const deletePrivateWorkspaceSuccess = (data) => {
	return {
		type: DELETE_PRIVATE_WORKSPACE_SUCCESS,
		payload: {data}
	}
}

export const deletePrivateWorkspaceFailed = (error) => {
	return {
		type: DELETE_PRIVATE_WORKSPACE_FAILED,
		payload: {error}
	}
}