import React from "react";
import { Button } from '@progress/kendo-react-buttons';

const MenuButton = (props) => {
    return (
        <Button style={props.style} icon={props.icon} onClick={props.onClickMethod} className={props.classNameForButton}>
            {props.buttonText}
        </Button>
    );
};

export default MenuButton;