import { LOAD_DASHBOARD_CHART_DATA,LOAD_DASHBOARD_CHART_DATA_FAILED,LOAD_DASHBOARD_CHART_DATA_SUCCESS,LOAD_DASHBOARD_DATA,LOAD_DASHBOARD_DATA_FAILED,LOAD_DASHBOARD_DATA_SUCCESS,LOAD_DASHBOARD_SUMMARY,LOAD_DASHBOARD_SUMMARY_FAILED,LOAD_DASHBOARD_SUMMARY_SUCCESS } from "./constants";



export const loadDashboardSummary=(accessToken,dashboardSummary,modifiedDate, workspaces, timeZone)=>{
    return{
        type:LOAD_DASHBOARD_SUMMARY,
        payload: {accessToken,dashboardSummary, modifiedDate, workspaces, timeZone}
    }
}

export const loadDashboardSummarySucess=(dashboardSummarySuccess)=>{
    return{
        type:LOAD_DASHBOARD_SUMMARY_SUCCESS,
        payload: {dashboardSummarySuccess}
    }
}
export const loadDashboardSummaryFailed=(error)=>{
    return{
        type:LOAD_DASHBOARD_SUMMARY_FAILED,
        payload: {error}
    }
}

export const loadDashboardData=(accessToken,dashboardData,modifiedDate, workspaces, timeZone)=>{
    return{
        type:LOAD_DASHBOARD_DATA,
        payload: {accessToken,dashboardData,modifiedDate, workspaces, timeZone}
    }
}

export const loadDashboardDataSucess=(dashboardDataSuccess)=>{
    return{
        type:LOAD_DASHBOARD_DATA_SUCCESS,
        payload: {dashboardDataSuccess}
    }
}
export const loadDashboardDataFailed=(error)=>{
    return{
        type:LOAD_DASHBOARD_DATA_FAILED,
        payload: {error}
    }
}

export const loadDashboardChartsData=(accessToken,chartsData,modifiedDate, workspaces, timeZone)=>{
    return{
        type:LOAD_DASHBOARD_CHART_DATA,
        payload: {accessToken,chartsData,modifiedDate, workspaces, timeZone}
    }
}

export const loadDashboardChartsDataSucess=(chartsDataSuccess)=>{
    return{
        type:LOAD_DASHBOARD_CHART_DATA_SUCCESS,
        payload: {chartsDataSuccess}
    }
}
export const loadDashboardChartsDataFailed=(error)=>{
    return{
        type:LOAD_DASHBOARD_CHART_DATA_FAILED,
        payload: {error}
    }
}
