import React, {useEffect} from "react";
import GeneralTab from "./generalTab";
import Tracking from "./tracking";
import Destination from "./destination";
import produce from "immer";

class ModalForm extends React.Component {
    state = {
        showGeneralTab: true,
        showDestinationTab: false,
        showTrackingTab: false,
        activeStyle: {
            "color": "#e0358b",
            "borderTop": "1px solid #e0358b",
            "borderRight": "1px solid #f6f7f9",
            "box-shadow": "0px 2px 4px rgb(0 0 0 / 16%)"
        },
        isVisible: false
    }

    render () {
        return (
            <div className="form_con">
                {/*Tabs at the top*/}
                <section className="campaign_modal_tabs k-pb-0">
                    <div
                        className="tab k-p-2 text_center"
                        id="general"
                        onClick={this.props.toggleTabs}
                        style={this.props.state.isShowGeneralTab ? this.state.activeStyle : null}
                    >General</div>
                    <div
                        className="tab k-p-2 text_center"
                        id="destination"
                        onClick={this.props.toggleTabs}
                        style={this.props.state.isShowDestinationTab ? this.state.activeStyle : null}
                    >Destination</div>
                    {this.props.state.isCampaignLoadedSuccessfully
                        ?
                        <div
                            className="tab k-p-2 text_center"
                            id="tracking"
                            onClick={this.props.toggleTabs}
                            style={this.props.state.isShowTrackingTab ? this.state.activeStyle : null}
                        >Tracking</div>
                        
                        :
                        <div className="k-p-2 text_center disabled">Tracking</div>
                    }
                </section>
                {/*components here*/}
                {
                    this.props.state.isShowGeneralTab ?
                        <GeneralTab
                            props={this.props}
                        />
                        : null
                }
                {
                    this.props.state.isShowDestinationTab ?
                        <Destination
                            props={this.props}
                            simpleRadioChangeHandler={this.props.simpleRadioChangeHandler}
                            flowsDataReceived={this.props.flowsDataReceived}
                            inputChangeHandler={this.props.inputChangeHandler}
                            filterChange={this.props.filterChange}
                            
                            onChangePane={this.props.onChangePane}
                            handleSwitchChange={this.props.handleSwitchChange}
                            addNewRule={this.props.addNewRule}
                            addNewFlow={this.props.addNewFlow}
                            addNewPath={this.props.addNewPath}
                            addDefaultPath={this.props.addDefaultPath}
                            deleteRule={(e) => this.props.deleteRule(e)}
                            duplicateRule={(e) => this.props.duplicateRule(e)}
                            newRuleClicked={(e) => this.props.newRuleClicked(e)}
                            defaultPathClicked={(e,defaultP) => this.props.defaultPathClicked(e,defaultP)}
                            newRulePathClicked={(e,rule)=>this.props.newRulePathClicked(e,rule)}
                            onRuleNameChange={(e) => this.props.onRuleNameChange(e)}
                            deleteDefaultPath={(e) => this.props.deleteDefaultPath(e)}
                            duplicateDefaultPath={(e) => this.props.duplicateDefaultPath(e)}
                            handleDefaultPathChange={(e) => this.props.handleDefaultPathChange(e)}
                            handleRulePathChange={(e)=>this.props.handleRulePathChange(e)}
                            offersDataReceived={this.props.offersDataReceived}
                            landersDataReceived={this.props.landersDataReceived}
                            addLandersAndOffersMethod={this.props.addLandersAndOffersMethod}
                            increaseWeightMethod={this.props.increaseWeightMethod}
                            decreaseWeightMethod={this.props.decreaseWeightMethod}
                            weightageSelectTagChangeHandler={this.props.weightageSelectTagChangeHandler}
                            deleteWeightageMethod={this.props.deleteWeightageMethod}    
                            addNewOffer={this.props.addNewOffer}
                            addConditions={this.props.addConditions}
                            showConditionValues={this.props.showConditionValues}
                            deleteAllConditions={this.props.deleteAllConditions}
                            showOptions={this.props.showOptions}
                            deleteOneCondition={this.props.deleteOneCondition}
                            editRuleCondition={this.props.editRuleCondition}
                            showCondtionsName={this.props.showCondtionsName}
                            decreaseDefaultPathWeightMethod={this.props.decreaseDefaultPathWeightMethod}
                            increaseDefaultPathWeightMethod={this.props.increaseDefaultPathWeightMethod}
                            onChangePathSwitch={this.props.onChangePathSwitch}
                            changeWeightageHandler={this.props.changeWeightageHandler}
                            onSort={this.props.onSort}
                        />
                        : null
                }
                {
                    this.props.state.isShowTrackingTab ?
                        <Tracking
                            props={this.props}
                            trafficSourceName={this.props.state.trafficSourceName}
                            countryName={this.props.state.countryName}
                            campaignName={this.props.state.dataToSend.name}
                            campaignUrl={this.props.state.campaign_url_generated}
                        />
                        : null
                }
            </div>
        );
    }
};

export default ModalForm;