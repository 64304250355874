import React, { Component } from "react";
import { formatDate } from "../../../components/searchParameters";
import Layout from "../../../layout";
import "./dashboard.css";
import "../pages.css";
import DashboardActions from "./dashboardActions";
import StatsBoxes from "./statsBoxes";
// import {Notification, NotificationGroup} from "@progress/kendo-react-notification";
// import {Fade} from "@progress/kendo-react-animation";
import LoadingPanel from "../../grid/loader/loader";
import "hammerjs";
import * as dashboardActions from "./actions";
import {
  makeSelectDashboardChartsData,
  makeSelectAllDashboardData,
  makeSelectDashboardError,
  makeSelectDashboardSummary,
  makeSelectIsAllDashboardChartsDataLoaded,
  makeSelectIsAllDashboardDataLoaded,
  makeSelectIsAllDashboardSummaryLoaded,
} from "./selectors";
import { makeSelectToken } from "../../../registration/LoginRegister/selectors";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import produce from "immer";
import MenuCharts from "./dashboardChart";
//import ResponsiveMenuCharts from "./ResponsiveMenuCharts"
import moment from "moment-timezone";
import { setDatesToLocalStorage } from "../../../components/setDatesToLocalStorage";
import { timeZonesConversion } from "../../../components/timeZonesExchange";
import { Helmet } from "react-helmet"

class Dashboard extends Component {
  state = {
    dataToSend: {
      order: "Top",
      data: "profit",
      sort: "ASC",
    },
    statsBoxData: null,
    allDashboardData: null,
    dashboardChartsData: null,
    dates: {
      startDate: moment(new Date()),
      endDate: moment(new Date()),
      timeZone: "Pacific/Kwajalein",
      secondDisplay: false,
    },
    modifiedDate: {
      startDate: moment(new Date()).format("MM/DD/YYYY hh:mm A"),
      endDate: moment(new Date()).format("MM/DD/YYYY hh:mm A"),
    },
    firstTime: {
      chart: true,
      summary: true,
      dashboardDate: true,
    },
    showCalendar: false,
    // loader: true,
    allDashboardDataLoader: false,
    summaryLoader: false,
    chartLoader: false,
    ranges: {},
    defaultTimeZone: "(GMT -12:00) Eniwetok, Kwajalein",
    timeZonesList: [],
  };

  componentDidMount() {
    let search = JSON.parse(localStorage.getItem("search"));
    this.setState(
      produce((draft) => {
        draft.dates.timeZone = search.dates.timeZone;
        draft.dates.startDate = moment(search.dates.startDate);
        draft.dates.endDate = moment(search.dates.endDate);
        draft.modifiedDate = {
          startDate: moment(search.dates.startDate).format(
            "MM/DD/YYYY hh:mm A"
          ),
          endDate: moment(search.dates.endDate).format("MM/DD/YYYY hh:mm A"),
        };
      })
    );
    this.props.dispatchLoadDashboardData(
      this.props.accessToken,
      this.state.dataToSend,
      search.modifiedDate,
      search.selectedWorkspacesList,
      search.dates.timeZone
    );
    this.props.dispatchLoadDashboardSummaryData(
      this.props.accessToken,
      this.state.dataToSend,
      search.modifiedDate,
      search.selectedWorkspacesList,
      search.dates.timeZone
    );
    this.props.dispatchLoadDashboarChartsData(
      this.props.accessToken,
      this.state.dataToSend,
      search.modifiedDate,
      search.selectedWorkspacesList,
      search.dates.timeZone
    );
    this.setState(
      produce((draft) => {
        draft.allDashboardDataLoader = true;
        draft.summaryLoader = true;
        draft.chartLoader = true;
      })
    );
    setTimeout(() => {
      this.returnRanges();
      this.calculateNewTimeZonesList();
    }, 500);
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.allDashboardData !== this.props.allDashboardData &&
      this.props.allDashboardData !== null
    ) {
      this.setState(
        produce((draft) => {
          draft.statsBoxData = this.props.allDashboardData;
          draft.allDashboardDataLoader = false;
          if (this.state.firstTime.dashboardDate === true) {
            this.setState(
              produce((draft) => {
                draft.firstTime.dashboardDate = false;
              })
            );
          } else {
            this.setState(
              produce((draft) => {
                draft.allDashboardDataLoader = false;
              })
            );
          }
        })
      );
    }
    if (
      prevProps.dahsboardSummaryData !== this.props.dahsboardSummaryData &&
      this.props.dahsboardSummaryData !== null
    ) {
      this.setState(
        produce((draft) => {
          draft.allDashboardData = this.props.dahsboardSummaryData;
          draft.summaryLoader = false;
        })
      );
      if (this.state.firstTime.summary === true) {
        this.setState(
          produce((draft) => {
            draft.firstTime.summary = false;
          })
        );
      } else {
        this.setState(
          produce((draft) => {
            draft.summaryLoader = false;
          })
        );
      }
    }
    if (
      prevProps.dashboardChartsData !== this.props.dashboardChartsData &&
      this.props.dashboardChartsData !== null
    ) {
      this.setState(
        produce((draft) => {
          draft.dashboardChartsData = this.props.dashboardChartsData;
          draft.chartLoader = false;
        })
      );
      if (this.state.firstTime.chart === true) {
        this.setState(
          produce((draft) => {
            draft.firstTime.chart = false;
          })
        );
      } else {
        this.setState(
          produce((draft) => {
            draft.chartLoader = false;
          })
        );
      }
    }
  }

  addTooltipDate = () => {
    let search = JSON.parse(localStorage.getItem("search"));
    for (let i = 0; i < this.state.timeZonesList.length; i++) {
      if (search.dates.timeZone === this.state.timeZonesList[i].calendar) {
        this.setState(
          produce((draft) => {
            draft.defaultTimeZone = this.state.timeZonesList[i].name;
          })
        );
      }
    }
  };

  calculateNewTimeZonesList = () => {
    let newTimeZoneList = [];
    let defaultTimeZoneId = JSON.parse(localStorage.getItem("search")).setting
      .timeZoneId;
    for (let i = 0; i < timeZonesConversion.length; i++) {
      if (Number(defaultTimeZoneId) === Number(timeZonesConversion[i].id)) {
        newTimeZoneList.push({
          id: timeZonesConversion[i].id,
          name: "Default: " + timeZonesConversion[i].name,
          calendar: timeZonesConversion[i].calendar,
        });
      }
    }
    for (let i = 0; i < timeZonesConversion.length; i++) {
      if (Number(defaultTimeZoneId) !== Number(timeZonesConversion[i].id)) {
        newTimeZoneList.push({
          id: timeZonesConversion[i].id,
          name: timeZonesConversion[i].name,
          calendar: timeZonesConversion[i].calendar,
        });
      }
    }
    this.setState(
      produce((draft) => {
        draft.timeZonesList = newTimeZoneList;
      })
    );
    setTimeout(() => {
      this.addTooltipDate();
    }, 100);
  };

  changeTabs = (event) => {
    const order = event.target.id.split("-")[0];
    const data = event.target.id.split("-")[1];
    this.setState(
      produce((draft) => {
        draft.dataToSend[order] = data;
      })
    );
    if (data === "Top") {
      this.setState(
        produce((draft) => {
          draft.dataToSend.sort = "ASC";
        })
      );
    }
    if (data === "Bottom") {
      this.setState(
        produce((draft) => {
          draft.dataToSend.sort = "DESC";
        })
      );
    }
    let search = JSON.parse(localStorage.getItem("search"));
    setTimeout(() => {
      this.setState(
        produce((draft) => {
          draft.summaryLoader = true;
        })
      );
      this.props.dispatchLoadDashboardSummaryData(
        this.props.accessToken,
        this.state.dataToSend,
        search.modifiedDate,
        search.selectedWorkspacesList,
        search.dates.timeZone
      );
    }, 40);
  };

  toggleCalendarWindow = () => {
    this.setState(
      produce((draft) => {
        draft.showCalendar = !this.state.showCalendar;
      })
    );
  };

  sendAllDataRequest = () => {
    this.setState(
      produce((draft) => {
        draft.showCalendar = false;
        // draft.loader = true;
        draft.allDashboardDataLoader = true;
        draft.summaryLoader = true;
        draft.chartLoader = true;
      })
    );
    let search = JSON.parse(localStorage.getItem("search"));
    this.props.dispatchLoadDashboardData(
      this.props.accessToken,
      this.state.dataToSend,
      search.modifiedDate,
      search.selectedWorkspacesList,
      search.dates.timeZone
    );
    this.props.dispatchLoadDashboardSummaryData(
      this.props.accessToken,
      this.state.dataToSend,
      search.modifiedDate,
      search.selectedWorkspacesList,
      search.dates.timeZone
    );
    this.props.dispatchLoadDashboarChartsData(
      this.props.accessToken,
      this.state.dataToSend,
      search.modifiedDate,
      search.selectedWorkspacesList,
      search.dates.timeZone
    );
  };

  toggleLoader = () => {
    this.setState(
      produce((draft) => {
        draft.loader = !this.state.loader;
      })
    );
  };

  rangeCallback = (index, value) => {
    //   setTimeout(() => {
    //     for (let i=0;i<12;i++) {
    //         document.getElementById("rangeButton" + i).style.backgroundColor = "rgb(245, 245, 245) !important";
    //         document.getElementById("rangeButton" + i).style.border = "1px solid rgb(245, 245, 245) !important";
    //         document.getElementById("rangeButton" + i).style.color = "rgb(0, 136, 204) !important";
    //     }
    //     document.getElementById("rangeButton" + index).style.backgroundColor = "rgb(0, 136, 204) !important";
    //     document.getElementById("rangeButton" + index).style.border = "1px solid rgb(245, 245, 245) !important";
    //     document.getElementById("rangeButton" + index).style.color = "rgb(245, 245, 245) !important";
    //   },50);
  };

  returnRanges = () => {
    let now = new Date();
    let start = moment(now).startOf("day");
    let end = moment(now).endOf("day");

    const ranges = {
      Today: [moment(start), moment(end)],
      Yesterday: [
        moment(start).subtract(1, "days"),
        moment(end).subtract(1, "days"),
      ],
      "Last 24 hours": [moment(now).subtract(1, "days"), moment(now)],
      "Last 48 hours": [moment(now).subtract(2, "days"), moment(now)],
      "Last 3 Days": [moment(start).subtract(2, "days"), moment(end)],
      "Last 7 Days": [moment(start).subtract(6, "days"), moment(end)],
      "This month": [
        moment(start).clone().startOf("month"),
        moment(end).clone().endOf("month"),
      ],
      "Last 30 Days": [moment(start).subtract(29, "days"), moment(end)],
      "Last month": [
        moment(start).subtract(1, "months").startOf("month"),
        moment(start).subtract(1, "months").endOf("month"),
      ],
      "This year": [
        moment(start).clone().startOf("year"),
        moment(start).clone().endOf("year"),
      ],
      "Last year": [
        moment(start).subtract(1, "year").startOf("year"),
        moment(start).subtract(1, "year").endOf("year"),
      ],
    };
    this.setState(
      produce((draft) => {
        draft.ranges = ranges;
      })
    );
  };

  setDatesToState = (startDate, endDate) => {
    this.setState(
      produce((draft) => {
        draft.dates.startDate = startDate;
        draft.dates.endDate = endDate;
        draft.modifiedDate.startDate = startDate.format("MM/DD/YYYY hh:mm A");
        draft.modifiedDate.endDate = endDate.format("MM/DD/YYYY hh:mm A");
      })
    );
  };

  applyCallback = (startDate, endDate) => {
    this.setDatesToState(startDate, endDate);
    setDatesToLocalStorage(startDate, endDate);
  };

  timeZoneChange = (e) => {
    this.setState(
      produce((draft) => {
        draft.dates.timeZone = e.target.value;
      })
    );

    // set the changed timeZone to localStorage
    let search = JSON.parse(localStorage.getItem("search"));
    search.dates.timeZone = e.target.value;
    search.report.report1.search.date.timeZone = e.target.value;
    search.report.report2.search.date.timeZone = e.target.value;
    search.report.report3.search.date.timeZone = e.target.value;
    localStorage.setItem("search", JSON.stringify(search));

    setTimeout(() => {
      this.addTooltipDate();
    }, 60);
  };

  render() {
    return (
      <>
        <Helmet>
          <meta name="viewport" content="width=1024" ></meta>
        </Helmet>  
        <Layout>
          {(this.state.allDashboardDataLoader ||
            this.state.summaryLoader ||
            this.state.chartLoader) && <LoadingPanel />}
          <div className="statsContainer">
            {this.props.dahsboardSummaryData !== null &&
            this.props.allDashboardData !== null &&
            this.props.dashboardChartsData !== null ? (
              <>
                <DashboardActions
                  sendAllDataRequest={this.sendAllDataRequest}
                  dates={this.state.dates}
                  modifiedDate={this.state.modifiedDate}
                  toggleCalendarWindow={this.toggleCalendarWindow}
                  showCalendar={this.state.showCalendar}
                  ranges={this.state.ranges}
                  applyCallback={this.applyCallback}
                  rangeCallback={this.rangeCallback}
                  timeZoneChange={this.timeZoneChange}
                  defaultTimeZone={this.state.defaultTimeZone}
                  timeZonesList={this.state.timeZonesList}
                />
                {this.props.allDashboardData !== null && (
                  <StatsBoxes
                    state={this.state}
                    data={this.props.allDashboardData}
                  />
                )}
                <div className="table-chart-wrapper">
                  <div className="stats-table-chart-container">
                    <div className="dashboard-table">
                      <div className=" stats-table-togglers">
                        <div className="toggle-btn-widgets">
                          <ul>
                            <li
                              name="order"
                              id="order-Top"
                              className={
                                this.state.dataToSend.order === "Top"
                                  ? "selected-tab"
                                  : ""
                              }
                              onClick={this.changeTabs}
                            >
                              Top
                            </li>
                            <li
                              name="order"
                              id="order-Bottom"
                              className={
                                this.state.dataToSend.order === "Bottom"
                                  ? "selected-tab"
                                  : ""
                              }
                              onClick={this.changeTabs}
                            >
                              Bottom
                            </li>
                          </ul>
                        </div>
                        <div className="toggle-btn-widgets">
                          <ul>
                            <li
                              name="data"
                              id="data-revenue"
                              className={
                                this.state.dataToSend.data === "revenue"
                                  ? "selected-tab"
                                  : ""
                              }
                              onClick={this.changeTabs}
                            >
                              Revenue
                            </li>
                            <li
                              name="data"
                              id="data-cost"
                              className={
                                this.state.dataToSend.data === "cost"
                                  ? "selected-tab"
                                  : ""
                              }
                              onClick={this.changeTabs}
                            >
                              Cost
                            </li>
                            <li
                              name="data"
                              id="data-profit"
                              className={
                                this.state.dataToSend.data === "profit"
                                  ? "selected-tab"
                                  : ""
                              }
                              onClick={this.changeTabs}
                            >
                              Profit
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="stats-table-data">
                        <table className="campaigns">
                          <thead>
                            <tr>
                              <th colSpan="2">Campaigns</th>
                              <th>{this.state.dataToSend.data}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.props.dahsboardSummaryData !== null ? (
                              this.props.dahsboardSummaryData.campaign ? (
                                this.props.dahsboardSummaryData.campaign
                                  .length > 0 ? (
                                  this.props.dahsboardSummaryData.campaign.map(
                                    (dashboard, index) => (
                                      <tr key={dashboard.id}>
                                        {this.state.dataToSend.data ===
                                        "revenue" ? (
                                          <td
                                            className={
                                              Number(dashboard.revenue) > 0
                                                ? "plus_td_bg"
                                                : Number(dashboard.revenue) < 0
                                                ? "minus_td_bg"
                                                : "neutral_td_bg"
                                            }
                                          >
                                            {index + 1}{" "}
                                          </td>
                                        ) : null}
                                        {this.state.dataToSend.data ===
                                        "profit" ? (
                                          <td
                                            className={
                                              Number(dashboard.profit) > 0
                                                ? "plus_td_bg"
                                                : Number(dashboard.profit) < 0
                                                ? "minus_td_bg"
                                                : "neutral_td_bg"
                                            }
                                          >
                                    
                                          </td>
                                        ) : null}
                                        {this.state.dataToSend.data ===
                                        "cost" ? (
                                          <td
                                            className={
                                              Number(dashboard.cost) > 0
                                                ? "plus_td_bg"
                                                : Number(dashboard.cost) < 0
                                                ? "minus_td_bg"
                                                : "neutral_td_bg"
                                            }
                                          >
                                            {index + 1}
                                          </td>
                                        ) : null}
                                        <td>{dashboard.name}</td>
                                        {this.state.dataToSend.data ===
                                        "revenue" ? (
                                          <td
                                            className={
                                              Number(dashboard.revenue) > 0
                                                ? "plus_td_color"
                                                : Number(dashboard.revenue) < 0
                                                ? "minus_td_color"
                                                : null
                                            }
                                          >
                                            {"$" +
                                              (
                                                Math.round(
                                                  dashboard.revenue * 100
                                                ) / 100
                                              ).toFixed(2)}
                                          </td>
                                        ) : null}
                                        {this.state.dataToSend.data ===
                                        "profit" ? (
                                          <td
                                            className={
                                              Number(dashboard.profit) > 0
                                                ? "plus_td_color"
                                                : Number(dashboard.profit) < 0
                                                ? "minus_td_color"
                                                : null
                                            }
                                          >
                                            {"$" +
                                              (
                                                Math.round(
                                                  dashboard.profit * 100
                                                ) / 100
                                              ).toFixed(2)}
                                          </td>
                                        ) : null}
                                        {this.state.dataToSend.data ===
                                        "cost" ? (
                                          <td
                                            className={
                                              Number(dashboard.cost) > 0
                                                ? "plus_td_color"
                                                : Number(dashboard.cost) < 0
                                                ? "minus_td_color"
                                                : null
                                            }
                                          >
                                            {"$" +
                                              (
                                                Math.round(
                                                  dashboard.cost * 100
                                                ) / 100
                                              ).toFixed(2)}
                                          </td>
                                        ) : null}
                                      </tr>
                                    )
                                  )
                                ) : (
                                  <tr>
                                    <td></td>
                                    <td>
                                      No records available for this date range
                                    </td>
                                    <td></td>
                                  </tr>
                                )
                              ) : null
                            ) : null}
                          </tbody>
                        </table>
                        <table className="countries">
                          <thead>
                            <tr>
                              <th colSpan="2">Countries</th>
                              <th>{this.state.dataToSend.data}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.props.dahsboardSummaryData !== null ? (
                              this.props.dahsboardSummaryData.country ? (
                                this.props.dahsboardSummaryData.country.length >
                                0 ? (
                                  this.props.dahsboardSummaryData.country.map(
                                    (country, index) => (
                                      <tr key={country.id}>
                                        {this.state.dataToSend.data ===
                                        "revenue" ? (
                                          <td
                                            className={
                                              Number(country.revenue) > 0
                                                ? "plus_td_bg"
                                                : Number(country.revenue) < 0
                                                ? "minus_td_bg"
                                                : "neutral_td_bg"
                                            }
                                          >
                                            {index + 1}
                                          </td>
                                        ) : null}
                                        {this.state.dataToSend.data ===
                                        "profit" ? (
                                          <td
                                            className={
                                              Number(country.profit) > 0
                                                ? "plus_td_bg"
                                                : Number(country.profit) < 0
                                                ? "minus_td_bg"
                                                : "neutral_td_bg"
                                            }
                                          >
                                            
                                          </td>
                                        ) : null}
                                        {this.state.dataToSend.data ===
                                        "cost" ? (
                                          <td
                                            className={
                                              Number(country.cost) > 0
                                                ? "plus_td_bg"
                                                : Number(country.cost) < 0
                                                ? "minus_td_bg"
                                                : "neutral_td_bg"
                                            }
                                          >
                                            {index + 1}
                                          </td>
                                        ) : null}
                                        <td>{country.country_name}</td>
                                        {this.state.dataToSend.data ===
                                        "revenue" ? (
                                          <td
                                            className={
                                              Number(country.revenue) > 0
                                                ? "plus_td_color"
                                                : Number(country.revenue) < 0
                                                ? "minus_td_color"
                                                : null
                                            }
                                          >
                                            {"$" +
                                              (
                                                Math.round(
                                                  country.revenue * 100
                                                ) / 100
                                              ).toFixed(2)}
                                          </td>
                                        ) : null}
                                        {this.state.dataToSend.data ===
                                        "profit" ? (
                                          <td
                                            className={
                                              Number(country.profit) > 0
                                                ? "plus_td_color"
                                                : Number(country.profit) < 0
                                                ? "minus_td_color"
                                                : null
                                            }
                                          >
                                            {"$" +
                                              (
                                                Math.round(
                                                  country.profit * 100
                                                ) / 100
                                              ).toFixed(2)}
                                          </td>
                                        ) : null}
                                        {this.state.dataToSend.data ===
                                        "cost" ? (
                                          <td
                                            className={
                                              Number(country.cost) > 0
                                                ? "plus_td_color"
                                                : Number(country.cost) < 0
                                                ? "minus_td_color"
                                                : null
                                            }
                                          >
                                            {"$" +
                                              (
                                                Math.round(country.cost * 100) /
                                                100
                                              ).toFixed(2)}
                                          </td>
                                        ) : null}
                                      </tr>
                                    )
                                  )
                                ) : (
                                  <tr>
                                    <td></td>
                                    <td>
                                      No records available for this date range
                                    </td>
                                    <td></td>
                                  </tr>
                                )
                              ) : null
                            ) : null}
                          </tbody>
                        </table>
                        <table className="offers">
                          <thead>
                            <tr>
                              <th colSpan="2">Offers</th>
                              <th>{this.state.dataToSend.data}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.props.dahsboardSummaryData !== null ? (
                              this.props.dahsboardSummaryData.offers ? (
                                this.props.dahsboardSummaryData.offers.length >
                                0 ? (
                                  this.props.dahsboardSummaryData.offers.map(
                                    (offer, index) =>
                                      offer.name && (
                                        <tr key={offer.id}>
                                          {this.state.dataToSend.data ===
                                          "revenue" ? (
                                            <td
                                              className={
                                                Number(offer.revenue) > 0
                                                  ? "plus_td_bg"
                                                  : Number(offer.revenue) < 0
                                                  ? "minus_td_bg"
                                                  : "neutral_td_bg"
                                              }
                                            >
                                              {index + 1}
                                            </td>
                                          ) : null}
                                          {this.state.dataToSend.data ===
                                          "profit" ? (
                                            <td
                                              className={
                                                Number(offer.profit) > 0
                                                  ? "plus_td_bg"
                                                  : Number(offer.profit) < 0
                                                  ? "minus_td_bg"
                                                  : "neutral_td_bg"
                                              }
                                            >
                                              
                                            </td>
                                          ) : null}
                                          {this.state.dataToSend.data ===
                                          "cost" ? (
                                            <td
                                              className={
                                                Number(offer.cost) > 0
                                                  ? "plus_td_bg"
                                                  : Number(offer.cost) < 0
                                                  ? "minus_td_bg"
                                                  : "neutral_td_bg"
                                              }
                                            >
                                              {index + 1}
                                            </td>
                                          ) : null}
                                          <td>{offer.name}</td>
                                          {/*<td>{"$" + this.state.dataToSend.data==="revenue" ? "$" +offer.revenue: this.state.dataToSend.data==="profit" ? "$" +offer.profit: "$" +offer.cost}</td>*/}
                                          {this.state.dataToSend.data ===
                                          "revenue" ? (
                                            <td
                                              className={
                                                Number(offer.revenue) > 0
                                                  ? "plus_td_color"
                                                  : Number(offer.revenue) < 0
                                                  ? "minus_td_color"
                                                  : null
                                              }
                                            >
                                              {"$" +
                                                (
                                                  Math.round(
                                                    offer.revenue * 100
                                                  ) / 100
                                                ).toFixed(2)}
                                            </td>
                                          ) : null}
                                          {this.state.dataToSend.data ===
                                          "profit" ? (
                                            <td
                                              className={
                                                Number(offer.profit) > 0
                                                  ? "plus_td_color"
                                                  : Number(offer.profit) < 0
                                                  ? "minus_td_color"
                                                  : null
                                              }
                                            >
                                              {"$" +
                                                (
                                                  Math.round(
                                                    offer.profit * 100
                                                  ) / 100
                                                ).toFixed(2)}
                                            </td>
                                          ) : null}
                                          {this.state.dataToSend.data ===
                                          "cost" ? (
                                            <td
                                              className={
                                                Number(offer.cost) > 0
                                                  ? "plus_td_color"
                                                  : Number(offer.cost) < 0
                                                  ? "minus_td_color"
                                                  : null
                                              }
                                            >
                                              {"$" +
                                                (
                                                  Math.round(offer.cost * 100) /
                                                  100
                                                ).toFixed(2)}
                                            </td>
                                          ) : null}
                                        </tr>
                                      )
                                  )
                                ) : (
                                  <tr>
                                    <td></td>
                                    <td>
                                      No records available for this date range
                                    </td>
                                    <td></td>
                                  </tr>
                                )
                              ) : null
                            ) : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="dashboard-chart">
                      <div className="dashboard-chart-widget">
                        <div className="dashboard_chart_container">

                        <ResponsiveMenuCharts    
                        data={this.state.dashboardChartsData}
                            dates={
                              JSON.parse(localStorage.getItem("search"))
                                .modifiedDate
                            }/>
                          
                          {//<MenuCharts
                           // data={this.state.dashboardChartsData}
                           // dates={
                            //  JSON.parse(localStorage.getItem("search"))
                            //    .modifiedDate
                           // }
                          //>
                          }
                        
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </Layout>
      </>
    );
  }
}

export const mapStateToProps = createStructuredSelector({
  error: makeSelectDashboardError(),
  allDashboardData: makeSelectAllDashboardData(),
  dahsboardSummaryData: makeSelectDashboardSummary(),
  dashboardChartsData: makeSelectDashboardChartsData(),
  isDashboardSummaryLoaded: makeSelectIsAllDashboardSummaryLoaded(),
  accessToken: makeSelectToken(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatchLoadDashboardData: (...payload) =>
      dispatch(dashboardActions.loadDashboardData(...payload)),
    dispatchLoadDashboarChartsData: (...payload) =>
      dispatch(dashboardActions.loadDashboardChartsData(...payload)),
    dispatchLoadDashboardSummaryData: (...payload) =>
      dispatch(dashboardActions.loadDashboardSummary(...payload)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

function ResponsiveMenuCharts(props) {
  const [dimensions, setDimensions] = React.useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  })
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    
}

    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)
    
}
  })
  return <>{//<div>{window.innerWidth}</div>
  }
  <MenuCharts data={props.data} dates={props.dates}/>
  </>

}