//import "../../../../../public/assets/vid/domain_cname.mp4";
import React from 'react';
const CNAME = () => {
    const CNAME = process.env.PUBLIC_URL + "/assets/img/CNAME/add-a-cname-godaddy-01.gif";
    return (
        <div>
            <img src={CNAME} alt="CNAME wokring"/>
        </div>
    )
}
export default CNAME;