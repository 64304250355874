import React from "react";
import {Dialog} from "@progress/kendo-react-dialogs";
import "./popups.css";

const DeleteCustomDomainDialogue = (props) => {
    return (
        props.props.state.deleteCustomDomainDialogueVisible && <Dialog title={"Delete Domain"} className="settings_dialogue" onClose={props.props.closeDeleteCustomDomainDialog}>
            <div className="settings_add_custom_domains_form delete_domain_form">
                <div>
                    <div className="row">
                        <label className="block normal_text black medium_font full_width_label">Domain Name</label>
                        {
                            <p className="normal_text black medium_font delete_domain_text k-pl-3">{props.props.state.domainUnderEditing.name}</p>
                        }
                    </div>
                    <div className="row">
                        <p>This action will permanently delete this domain from the database. Are you sure you want to continue?</p>
                    </div>
                </div>
                <div className="buttons_row">
                    <button className="cancel_button gray_button_bg_imp black border_radius_normal_btn profile_form_btn normal_text black no_border" onClick={props.props.closeDeleteCustomDomainDialog}>Cancel</button>
                    <button
                        className="theme_dark_bg_color_imp color_white_imp border_radius_normal_btn save_button normal_text"
                        onClick={props.props.handleDeleteCustomDomainFormSubmit}
                    >Continue</button>
                </div>
            </div>
        </Dialog>
    );
};

export default DeleteCustomDomainDialogue;