import React, { Component } from "react";

class StatsBoxes extends Component {
    state = {};
    render() {
        // console.log('props.state',this.props.state.statsBoxData);
        return (
            <div className="stats-boxes">
                <div className="stats-box">
                    <h3>Visits</h3>
                    <div>
                        <span className="stats-value">{this.props.data.visits}</span>
                    </div>
                </div>
                <div className="stats-box">
                    <h3>Clicks</h3>
                    <div>
                        <span className="stats-value">{this.props.data.clicks}</span>
                    </div>
                </div>
                <div className="stats-box">
                    <h3>Conversions</h3>
                    <div>
                        <span className="stats-value">{this.props.data.conversion}</span>
                    </div>
                </div>
                <div className="stats-box">
                    <h3>Revenue</h3>
                    <div>
                        <span className="currency-symbol">$</span>
                        <span className="stats-value">{(Math.round((this.props.data.revenue) * 100) / 100).toFixed(2)}</span>
                    </div>
                </div>
                <div className="stats-box">
                    <h3>Cost</h3>
                    <div>
                        <span className="currency-symbol">$</span>
                        <span className="stats-value">{(Math.round((this.props.data.cost) * 100) / 100).toFixed(2)}</span>
                    </div>
                </div>
                <div className="stats-box">
                    <h3>Profit</h3>
                    <div>
                        <span className="currency-symbol">$</span>
                        <span className={this.props.data.profit>0 ?"stats-value stats-value-green":"stats-value"}>
                        {(Math.round((this.props.data.profit) * 100) / 100).toFixed(2)}
                        </span>
                    </div>
                </div>
                {/* <div className="stats-box">
                    <h3>ROI</h3>
                    <div>
                        <span className="stats-value stats-value-green">6,291,057.43</span>
                        <span className="percentage-symbol">%</span>
                    </div>
                </div> */}
            </div>
        );
    }
}

export default StatsBoxes;
