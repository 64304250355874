export const LOAD_ALL_REPORT_DATA = "LOAD_ALL_REPORT_DATA";
export const LOAD_ALL_REPORT_DATA_SUCCESS = "LOAD_ALL_REPORT_DATA_SUCCESS";
export const LOAD_ALL_REPORT_DATA_FAILED = "LOAD_ALL_REPORT_DATA_FAILED";

export const GET_CHARTS_DATA = "GET_CHARTS_DATA";
export const GET_CHARTS_DATA_SUCCESS = "GET_CHARTS_DATA_SUCCESS";
export const GET_CHARTS_DATA_FAILED = "GET_CHARTS_DATA_FAILED";

export const LOAD_SUB_LEVEL_DATA = "LOAD_SUB_LEVEL_DATA";
export const LOAD_SUB_LEVEL_DATA_SUCCESS = "LOAD_SUB_LEVEL_DATA_SUCCESS";
export const LOAD_SUB_LEVEL_DATA_FAILED = "LOAD_SUB_LEVEL_DATA_FAILED";