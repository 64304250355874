import React from "react";
import { RadioButton } from "@progress/kendo-react-inputs";
import DestinationFlow from "./DestinationFLow/destinationFLow";
import FlowModalForm from "./../Flows/ModalForm/index";
//import { Fade } from "@progress/kendo-react-animation";
import { Error } from '@progress/kendo-react-labels';

const Destination = (props) => {
    return (
        <div className="form_con k-p-5" style={{ paddingLeft: "0px" }} id="errors_container">
            <section>
                <h3>Campaign Destination</h3>
                <div className="flex_row">
                    <div className="inline_block k-mr-5">
                        <RadioButton
                            name="campaign_destination"
                            value="Flow"
                            checked={props.props.state.dataToSend.campaign_destination === "Flow"}
                            label="Flow"
                            onChange={props.simpleRadioChangeHandler}
                        />
                    </div>
                    <div className="inline_block k-mr-5">
                        <RadioButton
                            name="campaign_destination"
                            value="Path"
                            checked={props.props.state.dataToSend.campaign_destination === "Path"}
                            label="Path"
                            onChange={props.simpleRadioChangeHandler}
                        />
                    </div>
                </div>
            </section>
            <hr></hr>
            <div >
            {props.props.state.errors.name !== null &&
                <Error>-{props.props.state.errors.name}</Error>
            }
            {props.props.state.errors.flow !== null &&
                <Error>-{props.props.state.errors.flow}</Error>
            }
            {props.props.state.errors.default_path_name !== null &&
                <Error>-{props.props.state.errors.default_path_name}</Error>
            }
            {props.props.state.errors.rule_path_name !== null &&
                <Error>-{props.props.state.errors.rule_path_name}</Error>
            }
            {(props.props.state.errors.default_path_lander !== null || props.props.state.errors.default_path_offer !== null) &&
                <Error>-{props.props.state.errors.default_path_lander}<br></br>-{props.props.state.errors.default_path_offer}</Error>
                
            }
            {(props.props.state.errors.rule_path_lander !== null || props.props.state.errors.rule_path_offer !== null) &&
                <Error>-{props.props.state.errors.rule_path_lander}<br></br>-{props.props.state.errors.rule_path_offer}</Error>    
            }
            {(!props.props.state.errors.isOfferExist && props.props.state.dataToSend.campaign_destination==="Path") && <Error>-You must have atleast one Offer to create advance campaign with path. Go back to Offer and create one. Or change Campaign Destination to Flow.</Error>}
                            
            {(!props.props.state.errors.isLanderExist && props.props.state.errors.isOfferExist && props.props.state.dataToSend.campaign_destination==="Path") ?
                <Error>- Note: You do not have any Lander. Please select *Offer Only* in path to create Campaign.</Error> : (!props.props.state.errors.isLanderExist && !props.props.state.errors.isOfferExist && props.props.state.dataToSend.campaign_destination==="Path")?
            <Error>-You do not have any Lander nor Offer. Please go back and create Offer and Lander First.</Error>:null}    
            </div>
            {props.props.state.dataToSend.campaign_destination === "Flow" ? (
                <DestinationFlow
                    state={props.props.state}
                    flowsDataReceived={props.flowsDataReceived}
                    inputChangeHandler={props.inputChangeHandler}
                    filterChange={props.filterChange}
                />
            ) : (
                <FlowModalForm
                    state={props.props.state}
                    onChangePane={props.onChangePane}
                    inputChangeHandler={(e) => props.inputChangeHandler(e)}
                    handleSwitchChange={props.handleSwitchChange}
                    addNewRule={props.addNewRule}
                    addNewFlow={props.addNewFlow}
                    addNewPath={props.addNewPath}
                    addDefaultPath={props.addDefaultPath}
                    deleteRule={(e) => props.deleteRule(e)}
                    duplicateRule={(e) => props.duplicateRule(e)}
                    newRuleClicked={(e) => props.newRuleClicked(e)}
                    defaultPathClicked={(e,defaultP) => props.defaultPathClicked(e,defaultP)}
                    newRulePathClicked={(e,rule) => props.newRulePathClicked(e,rule)}
                    onRuleNameChange={(e) => props.onRuleNameChange(e)}
                    deleteDefaultPath={(e) => props.deleteDefaultPath(e)}
                    duplicateDefaultPath={(e) => props.duplicateDefaultPath(e)}
                    handleDefaultPathChange={(e) => props.handleDefaultPathChange(e)}
                    handleRulePathChange={(e) => props.handleRulePathChange(e)}
                    offersDataReceived={props.offersDataReceived}
                    landersDataReceived={props.landersDataReceived}
                    addLandersAndOffersMethod={props.addLandersAndOffersMethod}
                    addNewOffer={props.addNewOffer}
                    addConditions={props.addConditions}
                    showConditionValues={props.showConditionValues}
                    deleteAllConditions={props.deleteAllConditions}
                    showOptions={props.showOptions}
                    deleteOneCondition={props.deleteOneCondition}
                    editRuleCondition={props.editRuleCondition}
                    showCondtionsName={props.showCondtionsName}
                    increaseWeightMethod={props.increaseWeightMethod}
                    decreaseWeightMethod={props.decreaseWeightMethod}
                    weightageSelectTagChangeHandler={props.weightageSelectTagChangeHandler}
                    deleteWeightageMethod={props.deleteWeightageMethod}
                    decreaseDefaultPathWeightMethod={props.decreaseDefaultPathWeightMethod}
                    increaseDefaultPathWeightMethod={props.increaseDefaultPathWeightMethod}
                    onChangePathSwitch={props.onChangePathSwitch}
                    changeWeightageHandler={props.changeWeightageHandler}
                    onSort={props.onSort}
                />
            )}
        </div>
    );
};

export default Destination;
