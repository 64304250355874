// import { Button } from '@progress/kendo-react-buttons';
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
// import { Field, Form, FormElement } from '@progress/kendo-react-form';
import React, { Component } from 'react';
// import LoadingPanel from '../../../grid/loader/loader';
import produce from 'immer';
// import { Error } from '@progress/kendo-react-labels';
import {makeSelectRegistered, makeSelectToken} from '../../../../registration/LoginRegister/selectors';
import { makeSelectAllUsersData, makeSelectIsAllUsersDataLoaded,makeSelectIsUsersDataAdded,makeSelectUsersDataError,makeSelectUserUpdated, makeSelectEditUserDataLoaded } from '../selectors';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import * as userActions from "../actions";
import {formatDate} from "../../../../components/searchParameters";
import ModalForm from "./ModalForm";
import * as loginRegisterActions from "../../../../registration/LoginRegister/actions";

class EditUserModal extends Component {
    state = {
        loader: false,
        visible: false,
        dataToSend: {
            // showBirthday: formatDate(new Date(2000, 0, 1)),
            // showCalendar: false,
            firstname: "",
            lastname: "",
            email: "",
            password: "",
            confirmPassword: "",
            accType: "Individual",
            link: "",
            company: "",
            instant_messenger:"Skype",
            instant_messenger_number:"",
            website: "",
            address: "",
            city: "",
            state: "",
            signupCountry: "",
            zipCode: "",
            phone: "",
            accountStatus: ""
        },
        // birthday: new Date(2000, 0, 1),
        emailValidation: true,
        error: {
            firstname: null,
            lastname:null,
            // birthday: null,
            accType: null,
            link: null,
            company: null,
            website: null,
            instant_messenger_number:null,
            address: null,
            city: null,
            state: null,
            signupCountry: null,
            zipCode: null,
            phone: null,
        },
        phone: "",
        acc_Type: [
            {
                label: "Individual",
                value: "Individual",
            },
            {
                label: "Company",
                value: "Company",
            },
        ]
    }

    componentDidMount() {
        this.props.toggleLoader();
        this.props.fetchEditUserData({
            accessToken: this.props.accessToken,
            id: this.props.id
        });
    }

    componentDidUpdate(prevProps){
        if ((prevProps.editUserDataLoaded !== this.props.editUserDataLoaded) && this.props.editUserDataLoaded !== null) {
            // this.props.userAddedSuccessfully();
            this.props.toggleLoader();

            this.setState(produce(draft => {
                if (this.props.editUserDataLoaded.accountStatus === null) {
                    draft.dataToSend.accountStatus = "PENDING";
                } else { draft.dataToSend.accountStatus = this.props.editUserDataLoaded.accountStatus; }

                console.log("this.props.editUserDataLoaded.accountType",this.props.editUserDataLoaded.accountType)
                if (this.props.editUserDataLoaded.accountType === null) {
                    // this.radioChangeHandler(this.props.editUserDataLoaded.accountType)
                    draft.dataToSend.accType = "Individual";
                } else { 
                    draft.dataToSend.accType = this.props.editUserDataLoaded.accountType;
                 }

                if (this.props.editUserDataLoaded.address === null) {
                    draft.dataToSend.address = "";
                } else { draft.dataToSend.address = this.props.editUserDataLoaded.address; }

                if (this.props.editUserDataLoaded.city === null) {
                    draft.dataToSend.city = "";
                } else { draft.dataToSend.city = this.props.editUserDataLoaded.city; }

                if (this.props.editUserDataLoaded.company === null) {
                    draft.dataToSend.company = "";
                } else { draft.dataToSend.company = this.props.editUserDataLoaded.company; }

                if (this.props.editUserDataLoaded.country === null) {
                    draft.dataToSend.signupCountry = "";
                } else { draft.dataToSend.signupCountry = this.props.editUserDataLoaded.country; }

                // if (this.props.editUserDataLoaded.dob === null) {
                //     draft.dataToSend.showBirthday = formatDate(new Date(2000, 0, 1));
                //     draft.birthday = new Date(2000, 0, 1);
                // } else {
                //     draft.dataToSend.showBirthday = formatDate(new Date(this.props.editUserDataLoaded.dob));
                //     draft.birthday = new Date(formatDate(new Date(this.props.editUserDataLoaded.dob)));
                // }

                if (this.props.editUserDataLoaded.email === null) {
                    draft.dataToSend.email = "";
                } else { draft.dataToSend.email = this.props.editUserDataLoaded.email; }

                // if (this.props.editUserDataLoaded.facebook === null) {
                //     draft.dataToSend.facebook = "";
                // } else { draft.dataToSend.facebook = this.props.editUserDataLoaded.facebook; }

                if (this.props.editUserDataLoaded.link === null) {
                    draft.dataToSend.link = "";
                } else { draft.dataToSend.link = this.props.editUserDataLoaded.link; }

                if (this.props.editUserDataLoaded.phoneNumber === null) {
                    draft.phone = "";
                } else { draft.phone = this.props.editUserDataLoaded.phoneNumber; }

                if (this.props.editUserDataLoaded.region === null) {
                    draft.dataToSend.state = "";
                } else { draft.dataToSend.state = this.props.editUserDataLoaded.region; }

                if (this.props.editUserDataLoaded.firstname === null) {
                    draft.dataToSend.firstname = "";
                } else { draft.dataToSend.firstname = this.props.editUserDataLoaded.firstname; }

                if (this.props.editUserDataLoaded.lastname === null) {
                    draft.dataToSend.lastname = "";
                } else { draft.dataToSend.lastname = this.props.editUserDataLoaded.lastname; }

                if (this.props.editUserDataLoaded.instant_messenger === null) {
                    draft.dataToSend.instant_messenger = "";
                } else { draft.dataToSend.instant_messenger = this.props.editUserDataLoaded.instant_messenger; }

                if (this.props.editUserDataLoaded.instant_messenger_number === null) {
                    draft.dataToSend.instant_messenger_number = "";
                } else { draft.dataToSend.instant_messenger_number = this.props.editUserDataLoaded.instant_messenger_number; }

                if (this.props.editUserDataLoaded.website === null) {
                    draft.dataToSend.website = "";
                } else { draft.dataToSend.website = this.props.editUserDataLoaded.website; }

                if (this.props.editUserDataLoaded.zipCode === null) {
                    draft.dataToSend.zipCode = "";
                } else { draft.dataToSend.zipCode = this.props.editUserDataLoaded.zipCode; }
            }));
        }

        if ((prevProps.userUpdateSuccess !== this.props.userUpdateSuccess) && this.props.userUpdateSuccess !== false) {
            this.props.closeVisibleEditUserModalSuccess();
        }
    }

    toggleDialog = () => {
        this.setState(produce(draft => { draft.visible = !this.state.visible; }))
        // this.props.closeVisibleEditUserModal();
    };

    handleSubmit = () => {
        this.props.toggleLoader();
        this.checkRegistrationFormErrors();
        setTimeout(() => {
            if (this.state.error.firstname === null && this.state.error.lastname === null && this.state.error.accType === null && this.state.error.link === null && this.state.error.company === null && this.state.error.website === null && this.state.error.address === null && this.state.error.city === null && this.state.error.state === null && this.state.error.signupCountry === null && this.state.error.zipCode === null && this.state.error.phone === null && this.state.error.instant_messenger_number === null) {
                this.props.updateUser(this.props.accessToken, this.props.id, {
                    firstname: this.state.dataToSend.firstname,
                    lastname: this.state.dataToSend.lastname,
                    // dob: this.state.dataToSend.showBirthday,
                    accountType: this.state.dataToSend.accType,
                    link: this.state.dataToSend.link,
                    company: this.state.dataToSend.company,
                    instant_messenger: this.state.dataToSend.instant_messenger,
                    instant_messenger_number: this.state.dataToSend.instant_messenger_number,
                    website: this.state.dataToSend.website,
                    address: this.state.dataToSend.address,
                    city: this.state.dataToSend.city,
                    region: this.state.dataToSend.state,
                    zipCode: this.state.dataToSend.zipCode,
                    country: this.state.dataToSend.signupCountry,
                    phoneNumber: this.state.phone,
                    accountStatus: this.state.dataToSend.accountStatus
                });
            } else {
                this.props.toggleLoader();
            }
        },200);
    };

    handleBirthdayChange = (e) => {
        // this.setState({
        //     ,
        //     showBirthday: formatDate(e.value)
        // });
        this.setState(produce(draft => {
            draft.dataToSend.showBirthday = formatDate(e.value);
            draft.birthday = e.value;
        }));
    }
    toggleCalendar = () => {
        this.setState({ showCalendar: !this.state.showCalendar });
    }
    selectCountry               = (v) => { this.setState(produce(draft => { draft.dataToSend.signupCountry = v; })); }
    
    radioChangeHandler          = (e) => {
        // console.log(e,"radioChangeHandler") 
        this.setState(produce(draft => { draft.dataToSend.accType = e.target.value; })); 
        // setTimeout(())
    }

    simpleInputChangeHandler    = (e) => {
        this.setState(produce(draft => {
            draft.dataToSend[e.target.id] = e.target.value;
        }));
        if (e.target.id === "password" && e.target.value.length < 8) {
            this.setState(produce(draft => { draft.error.password = "Password must be a minimum of 8 Characters."; }));
        } else { this.setState(produce(draft => { draft.error.password = null; })); }
    }

    checkRegistrationFormErrors = () => {
        if (this.state.dataToSend.firstname === "") {
            this.setState(produce(draft => { draft.error.firstname = "This field is required"; }));
        } else { this.setState(produce(draft => { draft.error.firstname = null; })); }

        if (this.state.dataToSend.lastname === "") {
            this.setState(produce(draft => { draft.error.lastname = "This field is required"; }));
        } else { this.setState(produce(draft => { draft.error.lastname = null; })); }

        console.log(this.state.dataToSend.accType,"ACCOUNT TYPE")
        if (this.state.dataToSend.accType === "Company") {
            if (this.state.dataToSend.link === "") {
                this.setState(produce(draft => { draft.error.link = "Linkedin or Facebook Profile link is required." }));
            } else { this.setState(produce(draft => { draft.error.link = null })); }
            if (this.state.dataToSend.company === "") {
                this.setState(produce(draft => { draft.error.company = "Company name is required." }));
            } else { this.setState(produce(draft => { draft.error.company = null })); }
            if (this.state.dataToSend.website === "") {
                this.setState(produce(draft => { draft.error.website = "Website link is required." }));
            } else { this.setState(produce(draft => { draft.error.website = null })); }
        } else {
            this.setState(produce(draft => {
                draft.error.linkedin = null;
                draft.error.facebook = null;
                draft.error.website = null;
            }));
        }

        if (this.state.dataToSend.city === "" || this.state.dataToSend.country === "" || this.state.dataToSend.state === "" || this.state.dataToSend.country === "" || this.state.dataToSend.zipCode === "") {
            this.setState(produce(draft => { draft.error.address = "You must enter the complete address. It includes Street Address, City, State, Country, and Zip Code/Postal Code." }));
        } else { this.setState(produce(draft => { draft.error.address = null })); }

        if (this.state.phone === "") {
            this.setState(produce(draft => { draft.error.phone = "Phone number is required." }));
        } else { this.setState(produce(draft => { draft.error.phone = null })); }

        if (this.state.dataToSend.instant_messenger_number === "") {
            this.setState(produce(draft => { draft.error.instant_messenger_number = "Messenger number is required." }));
        } else { this.setState(produce(draft => { draft.error.instant_messenger_number = null })); }
    }
    phoneChangeHandler = phone => this.setState({ phone: phone });
    radioGroupValidator = (value) => !value ? "Account Type is required" : "";
    render() {
        return (
            <div className="add_user_modal">
                <Dialog className="add_affiliate_networks_dialogue" title={"Edit user"} onClose={() => this.props.closeVisibleEditUserModal()}>
                    <div className="add_affiliate_network_dialogue_content">
                        <div className="content_form">
                            {this.state.showCalendar ? <div className="black_bg_signup" onClick={this.toggleCalendar}></div> : null }
                            <ModalForm
                                state={this.state}
                                simpleInputChangeHandler={this.simpleInputChangeHandler}
                                selectCountry={this.selectCountry}
                                radioChangeHandler={this.radioChangeHandler}
                                toggleCalendar={this.toggleCalendar}
                                radioGroupValidator={this.radioGroupValidator}
                                handleBirthdayChange={this.handleBirthdayChange}
                                phoneChangeHandler={this.phoneChangeHandler}
                            />
                        </div>
                    </div>
                    <DialogActionsBar>
                        <div className="action_bar_buttons">
                            <div className="left">
                                <button onClick={() => this.props.closeVisibleEditUserModal("")} className="normal_btn cancel_button gray_button_bg_imp black border_radius_normal_btn normal_text black no_border">Close</button>
                            </div>
                            <div className="right">
                                <button
                                    onClick={this.handleSubmit}
                                    className="normal_btn theme_dark_btn theme_dark_bg_color_imp color_white_imp border_radius_normal_btn save_button normal_text"
                                    disabled={this.state.disabled}
                                >Save changes</button>
                            </div>
                        </div>
                    </DialogActionsBar>
                </Dialog>
            </div>
        );
    }
}

export const mapStateToProps = createStructuredSelector({
    accessToken: makeSelectToken(),
    allUsersDataLoaded: makeSelectAllUsersData(),
    isAllUsersDataLoaded: makeSelectIsAllUsersDataLoaded(),
    isUserAdded:makeSelectIsUsersDataAdded(),
    error: makeSelectUsersDataError(),
    userUpdateSuccess: makeSelectUserUpdated(),
    editUserDataLoaded: makeSelectEditUserDataLoaded()
});

export function mapDispatchToProps(dispatch) {
    return {
        fetchingUsersData: (...payload) => dispatch(userActions.loadAllUsers(...payload)),
        fetchEditUserData: (...payload) => dispatch(userActions.loadEditUserData(...payload)),
        addUser: (...payload) => dispatch(userActions.addUsers(...payload)),
        updateUser:(...payload) => dispatch(userActions.updateUsers(...payload)),
        signUpUser: (...payload) => dispatch(loginRegisterActions.signUpUser(...payload)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUserModal);