import _ from "lodash";
import React from "react";

const FlowWeightageComponent = (props) => {

  const [options, setOptions] = React.useState([]);
  React.useEffect(() => {
      if(!Boolean(options.length)) {
        let landerOptions = _.cloneDeep(props?.options)
        landerOptions?.length && landerOptions.sort((a,b) => a.name.localeCompare(b.name));
        setOptions([...landerOptions])
      }
  },[props])

  // console.log("options", recentprods)
  // const filteredOptions = options?.map((item) => item)
  return (
    <div className="weightage_con k-mb-2">
      <select
        className="weightage_dropdown add_custom_domain_dropdown"
        id={props.type + "_" + props.index}
        value={props.selectedId}
        onChange={props.weightageSelectTagChangeHandler}
      >
        {options?.map((item,index) => {
          return (
            <>
              {Boolean(item.id !== options[index + 1]?.id) && (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
              )}
            </>
          )
        })}
      </select>
      <span
        id={props.type + "_" + props.index}
        className="k-icon k-i-delete k-ml-2 k-mr-2 icon_color_theme_default"
        onClick={props.deleteWeightageMethod}
      ></span>
      <div className="weightage_inner_con light_gray_border_color">
        {/* <span className="number normal_text normal_font black k-ml-1 k-mr-1">{props.weightageNumber}</span> */}
        <input
          type="number"
          onChange={props.changeWeightageHandler}
          className="lan_off_weightage number normal_text normal_font black k-ml-1 k-mr-1"
          value={props.weightageNumber}
          id={props.type + "_" + props.index}
        />
        <span
          className="k-icon k-i-minus icon_color_theme_default"
          id={props.type + "_" + props.index}
          onClick={props.decreaseWeightMethod}
        ></span>
        <span
          className="k-icon k-i-plus icon_color_theme_default"
          id={props.type + "_" + props.index}
          onClick={props.increaseWeightMethod}
        ></span>
      </div>
      <div className="percentage normal_text normal_font black k-ml-2">
        ({isNaN(props?.weightagePercentage) ? "0" : props?.weightagePercentage}
        %)
      </div>
    </div>
  );
};

export default FlowWeightageComponent;
