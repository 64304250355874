import React from "react";
import { Switch } from "@progress/kendo-react-inputs";

const Path = (props) => {

    const defaultPathClicked = (event) => {
        //console.log('event',event.target.parentElement.name);
        //console.log('event',event);
        if (event.target.parentElement.name !== "delete") {
            if (props.defaultPathClicked && !props.newRulePathClicked) {
                props.defaultPathClicked(props.index,event);
            } else {
                props.newRulePathClicked(props.index,event);
            }
        }
        
    };
    
    return (
        <div className={
            props.type + "_" + props.index==="default_"+props.state.bgClrPathIndex?"new-path selected":
            props.type + "_" + props.index === "rule_" + props.state.newRuleIndex + "_" + props.state.bgClrRulePathIndex?"new-path selected"
        : "new-path"} id="new-path">
            <div className={"new-path-content"} style={{paddingTop:"6px",paddingBottom:"6px"}} onClick={defaultPathClicked} id={props.type + "_" + props.index}>
            
                <div className="name" onClick={defaultPathClicked} id={props.type + "_" + props.index}>
                    {props.state.onDefaultPathClick? props.defaultValue.path_name : props.defaultValue.path_name}
                    </div>
                
                    <button
                        className="copy transparent-btn"
                        name="copy"
                        id={props.type + "_" + props.index}
                        onClick={props.duplicateDefaultPath}
                        style={{padding:"4px"}}
                    >
                    <span className="k-icon k-i-copy"/>
                    </button>
                    <button
                        className="remove transparent-btn"
                        onClick={props.deleteDefaultPath}
                        name="delete"
                        id={props.type + "_" + props.index}
                        style={{padding:"4px"}}
                    >
                    <span className="k-icon k-i-delete"/>
                </button>
                    <div className="ctas_con path-weight-input">
                        <input 
                            type="number" 
                            className="weight-input" 
                            value={props.defaultValue.value} 
                            name="weightage" 
                            onChange={props.inputChangeHandler}
                            id={props.type + "_" + props.index}
                            />

                        <div className="cta_icons_con">
                            <span className="cta_icons k-icon k-i-plus" id={props.type + "_" + props.index} onClick={props.increaseDefaultPathWeightMethod}></span>
                            <span className="cta_icons k-icon k-i-minus" id={props.type + "_" + props.index} onClick={props.decreaseDefaultPathWeightMethod} ></span>
                        </div>
                    </div>
                   
                    <div className="switch notification-switch" style={{paddingLeft:"10px"}}>
                    <span className="name-span" style={{paddingRight:"5px"}}>{props.defaultValue.weight_optimization? props.defaultValue.weight+"%": "(N/A)"}</span>
                        <Switch checked={props.defaultValue.weight_optimization} onChange={props.onChangePathSwitch} name={props.type + "_" + props.index + "_switch"} onLabel="" offLabel="" />
                    </div>
                
            </div>
        </div>
    );
};

export default Path;
