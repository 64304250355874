import * as React from "react";
import "../../grid/grid.css";
import produce from "immer";
import ReactTooltip from "react-tooltip";
import {
  makeSelectColumnsData,
  makeSelectIsColumnsDataPatched,
} from "../../grid/selectors";
import * as GridActions from "../../grid/actions";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { makeSelectToken } from "../../../registration/LoginRegister/selectors";
import GridColumnsModal from "../../grid/GridColumnsToShowModal";
import { loadSubLevelData } from "./actions";
import { makeSelectSubLevelReportData } from "./selectors";
import { makeSelectUserCurrency } from "../../../registration/LoginRegister/selectors";
import { checkFilters } from "../../../components/checkNamesForGrouping/checkNames";
import { returnTimeZoneId } from "../../../components/timeZonesExchange";

let rowClass=0;
let ifFirstRow =true

class GridReportComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.nameColRef = React.createRef();
  }
  state = {
    skip: 0,
    take: 50,
    sort: [{ field: "id", dir: "asc" }],
    allDataLoaded: [],
    data: [],
    columns: [],
    selectedRowIds: [],
    group: [],
    allChecked: false,
    groupingDataToLoad: {
      mainId: null,
      group1Id: null,
    },
    pageMounted: false,
    columnsData: null,
    allColumnsChecked: false,
  };

  fetchColumnsData = async () => {
    return this.props.dispatchGetColumnsData({
      accessToken: this.props.accessToken,
    });
  };
  patchColumnsData = async (columnsData) => {
    return this.props.dispatchPatchColumnsData({
      accessToken: this.props.accessToken,
      columnsData: columnsData,
    });
  };

  componentDidMount() {
    this.fetchColumnsData();
    this.setState(
      produce((draft) => {
        draft.pageMounted = true;
      })
    );
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log("Data received in grid: ", this.props.allDataLoaded);
    if (
      prevProps.allDataLoaded !== this.props.allDataLoaded &&
      this.props.allDataLoaded !== null
    ) {
      this.setState({ allDataLoaded: this.props.allDataLoaded.data });
      setTimeout(() => {
        this.addSelection();
      }, 200);
      setTimeout(() => {
        this.resetGetSublevelDataMarkers();
      }, 350);
    }
    if (
      prevProps.reportSubLevelDataLoaded !==
        this.props.reportSubLevelDataLoaded &&
      this.props.reportSubLevelDataLoaded !== null
    ) {
      this.props.toggleLoader();
      let dataReceived = [...this.props.reportSubLevelDataLoaded.data];
      if (dataReceived.length > 0) {
        for (let i = 0; i < this.state.allDataLoaded.length; i++) {
          if (
            this.state.groupingDataToLoad.mainId !== null &&
            this.state.groupingDataToLoad.group1Id !== null
          ) {
            if (
              Number(this.state.allDataLoaded[i].id) ===
              Number(this.state.groupingDataToLoad.mainId.split("_")[0])
            ) {
              for (let j = 0; j < this.state.allDataLoaded[i].sub.length; j++) {
                if (
                  Number(this.state.allDataLoaded[i].sub[j].id) ===
                  Number(this.state.groupingDataToLoad.group1Id)
                ) {
                  this.setState(
                    produce((draft) => {
                      draft.allDataLoaded[i].sub[j] = Object.assign(
                        { sub: dataReceived },
                        this.state.allDataLoaded[i].sub[j]
                      );
                    })
                  );
                }
              }
            }
          } else {
            if (
              Number(this.state.allDataLoaded[i].id) ===
              Number(this.state.groupingDataToLoad.mainId.split("_")[0])
            ) {
              this.setState(
                produce((draft) => {
                  draft.allDataLoaded[i] = Object.assign(
                    { sub: dataReceived },
                    this.state.allDataLoaded[i]
                  );
                })
              );
            }
          }
        }
      } else if (dataReceived.length === 0) {
        // console.log("Data received length: ", dataReceived.length);
        for (let i = 0; i < this.state.allDataLoaded.length; i++) {
          if (
            this.state.groupingDataToLoad.mainId !== null &&
            this.state.groupingDataToLoad.group1Id !== null
          ) {
            if (
              Number(this.state.allDataLoaded[i].id) ===
              Number(this.state.groupingDataToLoad.mainId.split("_")[0])
            ) {
              for (let j = 0; j < this.state.allDataLoaded[i].sub.length; j++) {
                if (
                  Number(this.state.allDataLoaded[i].sub[j].id) ===
                  Number(this.state.groupingDataToLoad.group1Id)
                ) {
                  this.setState(
                    produce((draft) => {
                      draft.allDataLoaded[i].sub[j] = Object.assign(
                        { sub: [] },
                        this.state.allDataLoaded[i].sub[j]
                      );
                    })
                  );
                }
              }
            }
          } else {
            if (
              Number(this.state.allDataLoaded[i].id) ===
              Number(this.state.groupingDataToLoad.mainId.split("_")[0])
            ) {
              this.setState(
                produce((draft) => {
                  draft.allDataLoaded[i] = Object.assign(
                    { sub: [] },
                    this.state.allDataLoaded[i]
                  );
                })
              );
            }
          }
        }
      }
    }

    if (
      prevProps.columnsDataLoaded !== this.props.columnsDataLoaded &&
      this.props.columnsDataLoaded !== null
    ) {
      this.setState(
        produce((draft) => {
          draft.columnsData = this.props.columnsDataLoaded;
        })
      );
      setTimeout(() => {
        this.columnsCheckSelectAllHandler();
      }, 40);
    }
    if (
      prevProps.isColumnsDataPatched !== this.props.isColumnsDataPatched &&
      this.props.isColumnsDataPatched === true
    ) {
      this.props.toggleColumnsModal();
    }
  }

  resetGetSublevelDataMarkers = () => {
    let search = this.props.search;
    if (search.reportGroupingValues.filter3.length > 0) {
      for (let i = 0; i < this.state.allDataLoaded.length; i++) {
        document.getElementById(
          this.state.allDataLoaded[i].id + "_get"
        ).style.display = "block";
        document.getElementById(
          this.state.allDataLoaded[i].id + "_expand"
        ).style.display = "none";
        document.getElementById(
          this.state.allDataLoaded[i].id + "_collapse"
        ).style.display = "none";
      }
    }
  };

  addSelection = () => {
    // dataToChange.map(dataItem => Object.assign({ selected: false }, dataItem));
    this.setState(
      produce((draft) => {
        draft.allDataLoaded = this.state.allDataLoaded.map((dataItem) =>
          Object.assign({ selected: false }, dataItem)
        );
      })
    );
  };
  // Pagination method
  pageChange = (event) => {
    this.setState({ skip: event.page.skip, take: event.page.take });
  };
  // for selection of row or rows
  selectionChange = (event) => {
    const data = this.state.allDataLoaded.map((item) => {
      if (item.id === Number(event.target.id)) {
        let selectedList = [...this.state.selectedRowIds];
        let itemFound = false;
        for (let i = 0; i < selectedList.length; i++) {
          if (selectedList[i].id === String(item.id)) {
            itemFound = true;
          }
        }
        if (itemFound) {
          selectedList = selectedList.filter(
            (elem) => elem.id !== String(item.id)
          );
        } else {
          selectedList.push({
            id: String(item.id),
            extra_info: item.is_advance,
            is_active: item.is_active,
          });
        }
        this.setState({ ...this.state, selectedRowIds: selectedList });
        // item.selected = !event.dataItem.selected;
        return { ...item, selected: event.target.checked };
      }
      return item;
    });
    this.setState({ allDataLoaded: data });
    event.stopPropagation();
    // check is single selection has all the values checked...
    // than make the header selection chacked as well.
    setTimeout(() => {
      if (
        this.state.selectedRowIds.length === this.state.allDataLoaded.length
      ) {
        this.setState({ allChecked: true });
      } else {
        this.setState({ allChecked: false });
      }
      this.gridCheckList();
    }, 20);
  };

  rowClick = (event) => {
    const data = this.state.allDataLoaded.map((item) => {
      if (item.id === Number(event.target.parentNode.id)) {
        this.setState({
          ...this.state,
          selectedRowIds: [
            {
              id: String(item.id),
              extra_info: item.is_advance,
              is_active: item.is_active,
            },
          ],
        });
        return { ...item, selected: true };
      } else {
        return { ...item, selected: false };
      }
      // return item;
    });
    this.setState({ allDataLoaded: data });
    // check is single selection has all the values checked...
    // than make the header selection chacked as well.
    setTimeout(() => {
      if (
        this.state.selectedRowIds.length === this.state.allDataLoaded.length
      ) {
        this.setState({ allChecked: true });
      } else {
        this.setState({ allChecked: false });
      }
      this.gridCheckList();
    }, 20);
  };

  headerSelectionChange = (event) => {
    const checked = event.target.checked;
    const data = this.state.allDataLoaded.map((item) => {
      // item.selected = checked;
      // return item;
      return { ...item, selected: checked };
    });
    this.setState({ allDataLoaded: data });
    this.setState({ allChecked: !this.state.allChecked });

    setTimeout(() => {
      if (this.state.selectedRowIds.length > 0) {
        this.setState({ ...this.state, selectedRowIds: [] });
        setTimeout(() => {
          this.gridCheckList([]);
        }, 50);
      } else {
        let selectedList = [];
        let count = 0;
        for (let i = 0; i < this.state.data.length; i++) {
          selectedList.push({
            id: String(this.state.data[i].id),
            extra_info: this.state.data[i].is_advance,
            is_active: this.state.data[i].is_active,
          });
          count += 1;
        }
        this.setState({ ...this.state, selectedRowIds: selectedList });
        if (count === this.state.data.length) {
          setTimeout(() => {
            this.gridCheckList();
          }, 100);
        }
      }
    }, 20);
  };

  gridCheckList = () => {
    this.props.gridCheckedList(this.state.selectedRowIds);
  };

  fetchGridSubLevelData = async (parameters) => {
    this.props.toggleLoader();
    setTimeout(() => {
      return this.props.dispatchGetReportSubLevelData({
        accessToken: this.props.accessToken,
        parameters: parameters,
      });
    }, 2000);
  };

  getSubLevelData = (e, data) => {
    let localStorageSearch = JSON.parse(localStorage.getItem("search"));
    // &group1Id=22&group2=browser&order=name&sort=ASC
    // let endDate = search.modifiedDate.endDate.split(", ")[0] + ", 23:59:59";
    // let startDate = search.modifiedDate.startDate.split(", ")[0] + ", 0:00:00";

    let filtersToSend = checkFilters(
      this.props.appliedGroupingValues.filter2,
      this.props.appliedGroupingValues.filter3,
      this.props.appliedGroupingValues.filter4
    );

    let changeFilter1 = checkFilters(
      this.props.appliedGroupingValues.filter1,
      "",
      ""
    );

    let timeZoneInLocalStorage = this.props.search.date.timeZone;
    let timeZoneIdToSend = returnTimeZoneId(timeZoneInLocalStorage);

    if (data === "secondLevel") {
      // show and hide the data laoding arrows
      let elemId = e.target.id.split("_")[0];
      document.getElementById(e.target.id).style.display = "none";
      document.getElementById(elemId + "_collapse").style.display = "block";
      document.getElementById(elemId + "_expand").style.display = "none";
      let parameters =
        "?id=" +
        this.props.appliedGroupingValues.id +
        "&from=" +
        this.props.search.modifiedDate.startDate +
        "&to=" +
        this.props.search.modifiedDate.endDate +
        "&tags=" +
        this.props.search.tagsSearchValue +
        "&filter1=" +
        changeFilter1[0] +
        "&filter2=" +
        filtersToSend[0] +
        "&filter2Id=" +
        e.target.id.split("_")[0] +
        "&filter3=" +
        filtersToSend[1] +
        "&filter3Id=&filter4=&sort=" +
        this.props.sorting.sort +
        "&order=" +
        this.props.sorting.order +
        "&workspace=" +
        localStorageSearch.selectedWorkspacesList +
        "&timezone=" +
        timeZoneIdToSend;
      setTimeout(() => {
        this.setState(
          produce((draft) => {
            draft.groupingDataToLoad.mainId = e.target.id;
            draft.groupingDataToLoad.group1Id = null;
          })
        );
        setTimeout(() => {
          this.fetchGridSubLevelData(parameters);
        }, 300);
      }, 100);
    }

    if (data === "thirdLevel") {
      // show and hide the data laoding arrows
      let elemFirstId = e.target.id.split("_")[0];
      let elemSecondId = e.target.id.split("_")[1];
      document.getElementById(e.target.id).style.display = "none";
      document.getElementById(
        elemFirstId + "_" + elemSecondId + "_collapse"
      ).style.display = "block";
      document.getElementById(
        elemFirstId + "_" + elemSecondId + "_expand"
      ).style.display = "none";
      // id=113&filter1=campaign&filter2=city&filter2Id=88304&filter3=lander&filter3Id=20&filter4=trafficSource
      let parameters =
        "?id=" +
        this.props.appliedGroupingValues.id +
        "&from=" +
        this.props.search.modifiedDate.startDate +
        "&to=" +
        this.props.search.modifiedDate.endDate +
        "&tags=" +
        this.props.search.tagsSearchValue +
        "&filter1=" +
        changeFilter1[0] +
        "&filter2=" +
        filtersToSend[0] +
        "&filter2Id=" +
        e.target.id.split("_")[0] +
        "&filter3=" +
        filtersToSend[1] +
        "&filter3Id=" +
        e.target.id.split("_")[1] +
        "&filter4=" +
        filtersToSend[2] +
        "&sort=" +
        this.props.sorting.sort +
        "&order=" +
        this.props.sorting.order +
        "&workspace=" +
        localStorageSearch.selectedWorkspacesList +
        "&timezone=" +
        timeZoneIdToSend;
      setTimeout(() => {
        this.setState(
          produce((draft) => {
            draft.groupingDataToLoad.mainId = e.target.id.split("_")[0];
            draft.groupingDataToLoad.group1Id = e.target.id.split("_")[1];
          })
        );
        setTimeout(() => {
          this.fetchGridSubLevelData(parameters);
        }, 300);
      }, 100);
    }
  };

  saveColumnsHandler = () => {
    let columnsData = {
      payload: [],
    };
    for (let i = 0; i < this.state.columnsData.length; i++) {
      columnsData.payload.push({
        name: this.state.columnsData[i].name,
        is_active: this.state.columnsData[i].is_active,
      });
    }
    this.patchColumnsData(columnsData);
  };

  columnsCheckBoxChangeHandler = (e) => {
    for (let i = 0; i < this.state.columnsData.length; i++) {
      if (Number(this.state.columnsData[i].id) === Number(e.target.id)) {
        this.setState(
          produce((draft) => {
            draft.columnsData[i].is_active =
              !this.state.columnsData[i].is_active;
          })
        );
      }
    }
    setTimeout(() => {
      this.columnsCheckSelectAllHandler();
    }, 30);
  };

  columnsCheckSelectAllHandler = () => {
    let counter = this.state.columnsData.length;
    let checkedCounter = 0;
    for (let i = 0; i < this.state.columnsData.length; i++) {
      if (this.state.columnsData[i].is_active) {
        checkedCounter += 1;
      }
    }
    if (counter === checkedCounter) {
      this.setState(
        produce((draft) => {
          draft.allColumnsChecked = true;
        })
      );
    } else {
      this.setState(
        produce((draft) => {
          draft.allColumnsChecked = false;
        })
      );
    }
  };

  columnsSelectAllHandler = (e) => {
    this.setState(
      produce((draft) => {
        draft.allColumnsChecked = e.target.checked;
      })
    );
    for (let i = 0; i < this.state.columnsData.length; i++) {
      if (this.state.columnsData[i].name !== "Name") {
        this.setState(
          produce((draft) => {
            draft.columnsData[i].is_active = e.target.checked;
          })
        );
      }
    }
  };

  expandRows = (e) => {
    if (e.target.id.split("_").length === 2) {
      // show and hide arrors
      let spanId = e.target.id.split("_")[0];
      document.getElementById(e.target.id).style.display = "none";
      document.getElementById(spanId + "_collapse").style.display = "block";
      document.getElementById(spanId + "_expand").style.display = "none";
      // show and hide table rows
      let id = "row_" + e.target.id.split("_")[0] + "_";
      let elems = document.querySelectorAll(`[class^=${id}]`);
      for (let i = 0; i < elems.length; i++) {
        if (
          document.querySelectorAll(`[class^=${id}]`)[i].className.split("_")
            .length === 3
        ) {
          document.querySelectorAll(`[class^=${id}]`)[i].style.display =
            "table-row";
        }
      }
    } else {
      // show and hide arrors
      let elemFirstId = e.target.id.split("_")[0];
      let elemSecondId = e.target.id.split("_")[1];
      document.getElementById(e.target.id).style.display = "none";
      document.getElementById(
        elemFirstId + "_" + elemSecondId + "_collapse"
      ).style.display = "block";
      document.getElementById(
        elemFirstId + "_" + elemSecondId + "_expand"
      ).style.display = "none";
      // show and hide table rows
      let id =
        "row_" +
        e.target.id.split("_")[0] +
        "_" +
        e.target.id.split("_")[1] +
        "_";
      let elems = document.querySelectorAll(`[class^=${id}]`);
      for (let i = 0; i < elems.length; i++) {
        document.querySelectorAll(`[class^=${id}]`)[i].style.display =
          "table-row";
      }
    }
  };
  collapseRows = (e) => {
    if (e.target.id.split("_").length === 2) {
      // show and hide arrors
      let spanId = e.target.id.split("_")[0];
      document.getElementById(e.target.id).style.display = "none";
      document.getElementById(spanId + "_collapse").style.display = "none";
      document.getElementById(spanId + "_expand").style.display = "block";
      // show and hide table rows
      let id = "row_" + e.target.id.split("_")[0] + "_";
      let elems = document.querySelectorAll(`[class^=${id}]`);
      for (let i = 0; i < elems.length; i++) {
        document.querySelectorAll(`[class^=${id}]`)[i].style.display = "none";
      }
    } else {
      // show and hide arrors
      let elemFirstId = e.target.id.split("_")[0];
      let elemSecondId = e.target.id.split("_")[1];
      document.getElementById(e.target.id).style.display = "none";
      document.getElementById(
        elemFirstId + "_" + elemSecondId + "_collapse"
      ).style.display = "none";
      document.getElementById(
        elemFirstId + "_" + elemSecondId + "_expand"
      ).style.display = "block";
      // show and hide table rows
      let id =
        "row_" +
        e.target.id.split("_")[0] +
        "_" +
        e.target.id.split("_")[1] +
        "_";
      let elems = document.querySelectorAll(`[class^=${id}]`);
      for (let i = 0; i < elems.length; i++) {
        document.querySelectorAll(`[class^=${id}]`)[i].style.display = "none";
      }
    }
  };

  render() {
    console.log("Report props: ", this.props);
    return (
      <>
        <table
          id="grid"
          className="grid"
          // style={{ height: ((Number(this.props.gridHeight) - 86) + "px") }}
          style={{ height: "inherit" }}
        >
          <tbody
            style={{ height: "inherit" }}
            // style={{ height: ((Number(this.props.gridHeight) - 116) + "px") }}
          >
            <tr className="table_header_row">
              {/*<th className="profit_change" style={{ minWidth: "15px" }}></th>*/}
              <th
                className="grid_sticky_th"
                style={{
                  minWidth: "35px",
                  paddingLeft: "6px",
                  height: "35px",
                  paddingTop: "6px",
                }}
              >
                <div className="profit_change_con">
                  <div className="profit_change">
                    <div className="empty">
                      <span>&nbsp;</span>
                    </div>
                  </div>
                  <input
                    type="checkbox"
                    value={this.state.allChecked}
                    checked={this.state.allChecked}
                    onClick={this.headerSelectionChange}
                  />
                </div>
              </th>
              {this.props.appliedGroupingValues.filter3.length > 0 ||
              this.props.appliedGroupingValues.filter4.length > 0 ? (
                <th style={{ minWidth: "70px" }}></th>
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[0].is_active ? (
                  <th style={{ width: "100px" }}className="resizingDiv" id="table-header-column">
                    <div
                      
                      style={{ width: "inherit" }}
                      onClick={() => this.props.changeSorting("id")}
                    >
                      id
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "id" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "id" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                    </div>
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[1].is_active ? (
                  <th style={{ width: "200px", padding: "0px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit" }}
                      onClick={() => this.props.changeSorting("name")}
                    >
                      Name
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "name" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "name" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                    </div>
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[24].is_active &&
                this.props.pageType === "Campaign" ? (
                  <th style={{ width: "100px", padding: "0px" }}className="resizingDiv">
                    <div  style={{ width: "inherit" }}>
                      Notes
                    </div>
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[2].is_active ? (
                  this.props.appliedGroupingValues.filter2 === "Campaign" ||
                  this.props.appliedGroupingValues.filter2 === "Offers" ||
                  this.props.appliedGroupingValues.filter2 === "Lander" ||
                  this.props.appliedGroupingValues.filter2 === "Flows" ||
                  this.props.appliedGroupingValues.filter2 ===
                    "Traffic Source" ||
                  this.props.appliedGroupingValues.filter2 ===
                    "Affiliate Network" ? (
                    <th style={{ width: "200px" }}className="resizingDiv">
                      <div
                        
                        style={{ width: "inherit" }}
                        onClick={() => this.props.changeSorting("workspace")}
                      >
                        Assigned Workspace
                        <div className="sort_icon_con">
                          {this.props.sorting.order === "workspace" ? (
                            <span
                              className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                                this.props.sorting.sort === "ASC"
                                  ? "down"
                                  : "up"
                              }`}
                            ></span>
                          ) : null}
                          {this.props.sorting.order === "workspace" &&
                          this.props.sorting.sort === "ASC" ? (
                            <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                          ) : (
                            <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                          )}
                        </div>
                        &nbsp;
                        <span
                          data-tip
                          data-for="assigned_wp"
                          className="question_mark"
                        >
                          <span className="k-icon k-i-question-circle"></span>
                        </span>
                        <ReactTooltip
                          className="grid_tooltip"
                          id="assigned_wp"
                          type="dark"
                        >
                          <span>
                            The Workspace assignment determines which campaign
                            funnels a user can gain access to. This allows to
                            isolate the funnels and make them unavailable for
                            some Workers, where at the same time the Account
                            Owner and Admin(s) can easily have insight into all
                            data.
                          </span>
                        </ReactTooltip>
                      </div>
                    </th>
                  ) : null
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[3].is_active ? (
                  <th style={{ width: "100px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit" }}
                      onClick={() => this.props.changeSorting("visits")}
                    >
                      Visits
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "visits" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "visits" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                      &nbsp;
                      <span
                        data-tip
                        data-for="visits_tt"
                        className="question_mark"
                      >
                        <span className="k-icon k-i-question-circle"></span>
                      </span>
                      <ReactTooltip
                        className="grid_tooltip"
                        id="visits_tt"
                        type="dark"
                      >
                        <span>
                          Visit is a specific user statistic recorded for an
                          active Tellmobi campaign. When a user goes to a
                          landing page, a visit is recorded. Note that when
                          direct-linking is used and the user goes directly to
                          an offer, such event is classified as a visit.
                        </span>
                      </ReactTooltip>
                    </div>
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[4].is_active === true ? (
                  <th style={{ width: "100px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit"  }}
                      onClick={() => this.props.changeSorting("uniquevisit")}
                    >
                      Unique Visits
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "uniquevisit" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "uniquevisit" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                      &nbsp;
                      <span
                        data-tip
                        data-for="u_visits_tt"
                        className="question_mark"
                      >
                        <span className="k-icon k-i-question-circle"></span>
                      </span>
                      <ReactTooltip
                        className="grid_tooltip"
                        id="u_visits_tt"
                        type="dark"
                      >
                        <span>
                          A visit is treated as unique when there is no cookie
                          related to the person behind this visit. Currently,
                          cookies live 48 hours. after that time, a secondary
                          visit from the same person will be counted as unique.
                        </span>
                      </ReactTooltip>
                    </div>
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[5].is_active ? (
                  <th style={{ width: "100px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit"  }}
                      onClick={() => this.props.changeSorting("clicks")}
                    >
                      Clicks
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "clicks" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "clicks" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                      &nbsp;
                      <span
                        data-tip
                        data-for="clicks_tt"
                        className="question_mark"
                      >
                        <span className="k-icon k-i-question-circle"></span>
                      </span>
                      <ReactTooltip
                        className="grid_tooltip"
                        id="clicks_tt"
                        type="dark"
                      >
                        <span>
                          Click is a specific user statistic recorded for an
                          active Tellmobi campaign. When a user goes to an
                          offer, a click is recorded. Note that when
                          direct-linking is used and the user goes directly to
                          an offer, such event is classified as a visit.
                        </span>
                      </ReactTooltip>
                    </div>
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[23].is_active === true ? (
                  <th style={{ width: "100px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit" }}
                      onClick={() => this.props.changeSorting("uniqueclick")}
                    >
                      Unique Clicks
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "uniqueclick" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "uniqueclick" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                      &nbsp;
                      <span
                        data-tip
                        data-for="u_clicks_tt"
                        className="question_mark"
                      >
                        <span className="k-icon k-i-question-circle"></span>
                      </span>
                      <ReactTooltip
                        className="grid_tooltip"
                        id="u_clicks_tt"
                        type="dark"
                      >
                        <span>
                          A click is treated as unique when there is no cookie
                          related to the person behind this click. Currently,
                          cookies live 48 hours. after that time, a secondary
                          click from the same person will be counted as unique.
                        </span>
                      </ReactTooltip>
                    </div>
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[6].is_active ? (
                  <th style={{ width: "100px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit" , overflow:"hidden" }}
                      onClick={() => this.props.changeSorting("conversion")}
                    >
                      Conversion
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "conversion" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "conversion" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                      &nbsp;
                      <span
                        data-tip
                        data-for="conversion_tt"
                        className="question_mark"
                      >
                        <span className="k-icon k-i-question-circle"></span>
                      </span>
                      <ReactTooltip
                        className="grid_tooltip"
                        id="conversion_tt"
                        type="dark"
                      >
                        <span>
                          Conversion is a specific user statistic recorded for
                          an active Tellmobi campaign. A conversion is added
                          when a user buys an offer.
                        </span>
                      </ReactTooltip>
                    </div>
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[22].is_active ? (
                  <th style={{ width: "100px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit"  }}
                      onClick={() => this.props.changeSorting("cost")}
                    >
                      Cost
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "cost" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "cost" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                      &nbsp;
                      <span
                        data-tip
                        data-for="cost_tt"
                        className="question_mark"
                      >
                        <span className="k-icon k-i-question-circle"></span>
                      </span>
                      <ReactTooltip
                        className="grid_tooltip"
                        id="cost_tt"
                        type="dark"
                      >
                        <span>Money spent on each campaign.</span>
                      </ReactTooltip>
                    </div>
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[7].is_active ? (
                  <th style={{ width: "100px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit"  }}
                      onClick={() => this.props.changeSorting("profit")}
                    >
                      Profit
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "profit" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "profit" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                      &nbsp;
                      <span
                        data-tip
                        data-for="profit_tt"
                        className="question_mark"
                      >
                        <span className="k-icon k-i-question-circle"></span>
                      </span>
                      <ReactTooltip
                        className="grid_tooltip"
                        id="profit_tt"
                        type="dark"
                      >
                        <span>
                          Difference between amount earned and amount spent.
                        </span>
                      </ReactTooltip>
                    </div>
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[8].is_active ? (
                  <th style={{ width: "100px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit" }}
                      onClick={() => this.props.changeSorting("revenue")}
                    >
                      Revenue
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "revenue" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "revenue" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                      &nbsp;
                      <span
                        data-tip
                        data-for="revenue_tt"
                        className="question_mark"
                      >
                        <span className="k-icon k-i-question-circle"></span>
                      </span>
                      <ReactTooltip
                        className="grid_tooltip"
                        id="revenue_tt"
                        type="dark"
                      >
                        <span>Total income of expenses.</span>
                      </ReactTooltip>
                    </div>
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[9].is_active ? (
                  <th style={{ width: "100px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit" }}
                      onClick={() => this.props.changeSorting("AP")}
                    >
                      AP
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "AP" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "AP" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                      &nbsp;
                      <span data-tip data-for="AP_tt" className="question_mark">
                        <span className="k-icon k-i-question-circle"></span>
                      </span>
                      <ReactTooltip
                        className="grid_tooltip"
                        id="AP_tt"
                        type="dark"
                      >
                        <span>
                          Average payout is an indicator of how much revenue you
                          get for a single conversion. You can find it in the AP
                          column in Tellmobi. it is calculated using the below
                          formula:
                        </span>
                        <br />
                        <br />
                        <span>
                          Average payout ($) = Total revenue ($) / Number of
                          conversions
                        </span>
                      </ReactTooltip>
                    </div>
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[10].is_active ? (
                  <th style={{ width: "100px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit" }}
                      onClick={() => this.props.changeSorting("CPV")}
                    >
                      CPV
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "CPV" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "CPV" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                      &nbsp;
                      <span
                        data-tip
                        data-for="CPV_tt"
                        className="question_mark"
                      >
                        <span className="k-icon k-i-question-circle"></span>
                      </span>
                      <ReactTooltip
                        className="grid_tooltip"
                        id="CPV_tt"
                        type="dark"
                      >
                        <span>
                          Cost per view is an indicator of how much you pay for
                          a single advertisement view on publisher's site
                          (typically for pop-up or pop-under types of ads). You
                          can find it in the CPV column in Tellmobi.
                        </span>
                        <br />
                        <br />
                        <span>
                          Cost per view ($) = Total Cost ($) / Number of views
                        </span>
                      </ReactTooltip>
                    </div>
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[11].is_active ? (
                  <th style={{ width: "100px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit"  }}
                      onClick={() => this.props.changeSorting("CR")}
                    >
                      CR
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "CR" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "CR" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                      &nbsp;
                      <span data-tip data-for="CR_tt" className="question_mark">
                        <span className="k-icon k-i-question-circle"></span>
                      </span>
                      <ReactTooltip
                        className="grid_tooltip"
                        id="CR_tt"
                        type="dark"
                      >
                        <span>
                          Conversion rate (clicks) is one of the main indicators
                          of your campaign performance and how profitable your
                          offer is. It show how many users have converted after
                          clicking on an offer on a landing page. You can find
                          it in the CR column in Tellmobi.
                        </span>
                        <br />
                        <br />
                        <span>
                          Conversion rate (%) (clicks) = Number of conversions /
                          Number of clicks * 100%
                        </span>
                      </ReactTooltip>
                    </div>
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[12].is_active ? (
                  <th style={{ width: "100px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit"  }}
                      onClick={() => this.props.changeSorting("CTR")}
                    >
                      CTR
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "CTR" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "CTR" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                      &nbsp;
                      <span
                        data-tip
                        data-for="CTR_tt"
                        className="question_mark"
                      >
                        <span className="k-icon k-i-question-circle"></span>
                      </span>
                      <ReactTooltip
                        className="grid_tooltip"
                        id="CTR_tt"
                        type="dark"
                      >
                        <span>
                          Click-through rate is an indicator of how attractive
                          your offer is to your audience. It shows how many
                          users have clicked on the offer after viewing it on a
                          landing page. You can find it in the CTR column in
                          Tellmobi.
                        </span>
                        <br />
                        <br />
                        <span>
                          Click-through rate (%) = Number of clicks / Number of
                          visits * 100%
                        </span>
                      </ReactTooltip>
                    </div>
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[13].is_active ? (
                  <th style={{ width: "100px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit"  }}
                      onClick={() => this.props.changeSorting("CV")}
                    >
                      CV
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "CV" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "CV" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                      &nbsp;
                      <span data-tip data-for="CV_tt" className="question_mark">
                        <span className="k-icon k-i-question-circle"></span>
                      </span>
                      <ReactTooltip
                        className="grid_tooltip"
                        id="CV_tt"
                        type="dark"
                      >
                        <span>
                          Conversion rate (visits) is one of the main indicators
                          of your campaign performance and how profitable your
                          offer is. It shows how many users have converted after
                          clicking an ad on a publisher's page. You can find it
                          in the CV column in Tellmobi.
                        </span>
                        <br />
                        <br />
                        <span>
                          Conversion rate (%) (visits) = Number of conversions /
                          Number of visits * 100%
                        </span>
                      </ReactTooltip>
                    </div>
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[14].is_active ? (
                  <th style={{ width: "100px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit" }}
                      onClick={() => this.props.changeSorting("ECPA")}
                    >
                      eCPA
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "ECPA" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "ECPA" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                      &nbsp;
                      <span
                        data-tip
                        data-for="eCPA_tt"
                        className="question_mark"
                      >
                        <span className="k-icon k-i-question-circle"></span>
                      </span>
                      <ReactTooltip
                        className="grid_tooltip"
                        id="eCPA_tt"
                        type="dark"
                      >
                        <span>
                          Effective cost per action is an indicator of a cost
                          for a single user action (like sign up to a
                          newsletter, installation of an application, etc.)
                          calculated retrospectively post-campaign. It shows the
                          actual cost per action of traffic purchased.
                        </span>
                        <br />
                        <br />
                        <span>
                          If you have purchased traffic using the CPA cost
                          model, the eCPA = CPA (for this cost model, second
                          price auction bidding model is not used)
                        </span>
                        <br />
                        <br />
                        <span>
                          If you have purchased traffic using other (CPM, CPC,
                          Revshare), the eCPA will be calculated like this;
                          Effective cost per action or acquisition rate ($) -
                          Total cost / Number of acquisitions (conversions)
                        </span>
                      </ReactTooltip>
                    </div>
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[15].is_active ? (
                  <th style={{ width: "100px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit" }}
                      onClick={() => this.props.changeSorting("ECPC")}
                    >
                      eCPC
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "ECPC" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "ECPC" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                      &nbsp;
                      <span
                        data-tip
                        data-for="eCPC_tt"
                        className="question_mark"
                      >
                        <span className="k-icon k-i-question-circle"></span>
                      </span>
                      <ReactTooltip
                        className="grid_tooltip"
                        id="eCPC_tt"
                        type="dark"
                      >
                        <span>
                          Effective cost per click is an indicator of a cost for
                          a single clicks on the ad, calculated retrospectively
                          post-campaign. It shows the actual cost per click of
                          traffic purchased. The eCPC is calculated using the
                          below formula:
                        </span>
                        <br />
                        <br />
                        <span>
                          Effective cost per click = Total cost / Number of
                          clicks
                        </span>
                      </ReactTooltip>
                    </div>
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[16].is_active ? (
                  <th style={{ width: "100px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit" }}
                      onClick={() => this.props.changeSorting("ECPM")}
                    >
                      eCPM
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "ECPM" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "ECPM" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                      &nbsp;
                      <span
                        data-tip
                        data-for="eCPM_tt"
                        className="question_mark"
                      >
                        <span className="k-icon k-i-question-circle"></span>
                      </span>
                      <ReactTooltip
                        className="grid_tooltip"
                        id="eCPM_tt"
                        type="dark"
                      >
                        <span>
                          Effective cost per mille (thousand) is an indicator of
                          a cost for buying a thousand ad impressions,
                          calculated retrospectively post-campaign. It shows the
                          actual cost per thousand impressions of traffic
                          purchased. The eCPM is calculated using the below
                          formula:
                        </span>
                        <br />
                        <br />
                        <span>
                          Effective cost per mille = Total cost / Number of
                          impressions * 1000
                        </span>
                      </ReactTooltip>
                    </div>
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[17].is_active ? (
                  <th style={{ width: "100px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit"  }}
                      onClick={() => this.props.changeSorting("EPC")}
                    >
                      EPC
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "EPC" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "EPC" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                      &nbsp;
                      <span
                        data-tip
                        data-for="EPC_tt"
                        className="question_mark"
                      >
                        <span className="k-icon k-i-question-circle"></span>
                      </span>
                      <ReactTooltip
                        className="grid_tooltip"
                        id="EPC_tt"
                        type="dark"
                      >
                        <span>
                          Earning per click is an indicator of how much revenue
                          you get for a single click on the ad. You can find it
                          in the EPC column in Tellmobi. It is calculated using
                          the below formula:
                        </span>
                        <br />
                        <br />
                        <span>
                          Earnings per click ($) = Total revenue ($) / Number of
                          clicks
                        </span>
                      </ReactTooltip>
                    </div>
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[18].is_active ? (
                  <th style={{ width: "100px" }}className="resizingDiv">
                    <div
                      
                      style={{width: "inherit" }}
                      onClick={() => this.props.changeSorting("EPV")}
                    >
                      EPV
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "EPV" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "EPV" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                      &nbsp;
                      <span
                        data-tip
                        data-for="EPV_tt"
                        className="question_mark"
                      >
                        <span className="k-icon k-i-question-circle"></span>
                      </span>
                      <ReactTooltip
                        className="grid_tooltip"
                        id="EPV_tt"
                        type="dark"
                      >
                        <span>
                          Earning per visit is an indicator of how much revenue
                          you get for a single visit to your website. You can
                          find it in the EPV column in Tellmobi. It is
                          calculated using the below formula:
                        </span>
                        <br />
                        <br />
                        <span>
                          Earnings per visit ($) = Total revenue ($) / Number of
                          visits
                        </span>
                      </ReactTooltip>
                    </div>
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[19].is_active ? (
                  <th style={{ width: "100px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit" }}
                      onClick={() => this.props.changeSorting("ICTR")}
                    >
                      ICTR
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "ICTR" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "ICTR" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                      &nbsp;
                      <span
                        data-tip
                        data-for="ICTR_tt"
                        className="question_mark"
                      >
                        <span className="k-icon k-i-question-circle"></span>
                      </span>
                      <ReactTooltip
                        className="grid_tooltip"
                        id="ICTR_tt"
                        type="dark"
                      >
                        <span>
                          Impression click-through rate is an indicator of how
                          attractive your ad is to your audience. It shows how
                          many users have clicked on the ad after viewing it on
                          a publisher's site. You can find this indicator in the
                          iCTR column in Tellmobi. It is calculated using the
                          below formula:
                        </span>
                        <br />
                        <br />
                        <span>
                          Impression click-through rate (%) = Number of visits /
                          Number of impressions * 100%
                        </span>
                      </ReactTooltip>
                    </div>
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[20].is_active ? (
                  <th style={{ width: "100px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit"  }}
                      onClick={() => this.props.changeSorting("ROI")}
                    >
                      ROI
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "ROI" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "ROI" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                      &nbsp;
                      <span
                        data-tip
                        data-for="ROI_tt"
                        className="question_mark"
                      >
                        <span className="k-icon k-i-question-circle"></span>
                      </span>
                      <ReactTooltip
                        className="grid_tooltip"
                        id="ROI_tt"
                        type="dark"
                      >
                        <span>
                          Return of investment is a main indicator of the
                          profitability of your campaign. It shows the
                          percentage of the money you earned as a fraction of
                          the money you have invested.
                        </span>
                        <br />
                        <br />
                        <span>
                          ROI = 0%: it means that you neither have lost or
                          earned any money
                        </span>
                        <br />
                        <br />
                        <span>
                          ROI {">"} 0%: it means that you have earned money. For
                          example, if ROI = 150%, you have earned 50% more money
                          that you have invested in running your campaigns.
                        </span>
                        <br />
                        <br />
                        <span>
                          If ROI {"<"} 0%: it means that you have spent more
                          money than you have earned.
                        </span>
                        <br />
                        <br />
                        <span>
                          Return on investment (%) = (Total revenue ($) - Total
                          cost ($) ) / Total cost ($) * 100%
                        </span>
                      </ReactTooltip>
                    </div>
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[21].is_active ? (
                  <th style={{ width: "100px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit"  }}
                      onClick={() => this.props.changeSorting("RPM")}
                    >
                      RPM
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "RPM" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "RPM" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                      &nbsp;
                      <span
                        data-tip
                        data-for="RPM_tt"
                        className="question_mark"
                      >
                        <span className="k-icon k-i-question-circle"></span>
                      </span>
                      <ReactTooltip
                        className="grid_tooltip"
                        id="RPM_tt"
                        type="dark"
                      >
                        <span>
                          Revenue per mille (thousand) is an indicator of how
                          much you earn for thousand impressions. You can find
                          it in the RPM column in Tellmobi. It is calculated
                          using the below formula:
                        </span>
                        <br />
                        <br />
                        <span>
                          Revenue per mille ($) = Total revenue ($) / Number of
                          impressions * 1000
                        </span>
                      </ReactTooltip>
                    </div>
                  </th>
                ) : null
              ) : null}
            </tr>
            {this.state.allDataLoaded.map((e) => {
              rowClass = rowClass +1
              let rowClassName =""
              if (rowClass % 2 == 0){
                rowClassName="even-row"
              }else{
                rowClassName="odd-row"
              }
              const parentRow = (
                <tr
                  id={e.id}
                  key={e.id}
                  onClick={this.rowClick}
                  className={rowClassName}
                  style={{
                    color: e.is_active
                      ? e.selected
                        ? "#fff"
                        : "#000"
                      : e.selected
                      ? "#fff"
                      : "#8d8d8d",
                    backgroundColor: e.selected ? "#2e4887" : null,
                  }}
                >
                  {/*<td className="profit_change">*/}
                  {/*    { Number(e.profit) === 0 ? <div className="neutral"><span>&#183;</span></div> : null }*/}
                  {/*    { Number(e.profit) > 0 ? <div className="plus"><span>+</span></div> : null }*/}
                  {/*    { Number(e.profit) < 0 ? <div className="minus"><span>-</span></div> : null }*/}
                  {/*</td>*/}
                  <td className="grid_sticky_td">
                    <div className="profit_change_con">
                      <div className="profit_change">
                        {Number(e.profit) === 0 ? (
                          <div className="neutral">
                            <span>&#183;</span>
                          </div>
                        ) : null}
                        {Number(e.profit) > 0 ? (
                          <div className="plus">
                            <span>+</span>
                          </div>
                        ) : null}
                        {Number(e.profit) < 0 ? (
                          <div className="minus">
                            <span>-</span>
                          </div>
                        ) : null}
                      </div>
                      <input
                        id={e.id}
                        type="checkbox"
                        onClick={this.selectionChange}
                        value={e.selected}
                        checked={e.selected}
                      />
                    </div>
                  </td>
                  {this.props.appliedGroupingValues.filter3.length > 0 ||
                  this.props.appliedGroupingValues.filter4.length > 0 ? (
                    <td style={{ textAlign: "left" }}>
                      <span
                        id={e.id + "_get"}
                        onClick={(e) => this.getSubLevelData(e, "secondLevel")}
                        className="k-icon k-i-arrow-chevron-right"
                      ></span>
                      <span
                        style={{ display: "none" }}
                        id={e.id + "_expand"}
                        onClick={this.expandRows}
                        className="k-icon k-i-arrow-chevron-right"
                      ></span>
                      <span
                        style={{ display: "none" }}
                        id={e.id + "_collapse"}
                        onClick={this.collapseRows}
                        className="k-icon k-i-arrow-chevron-down"
                      ></span>
                    </td>
                  ) : null}
                  {this.state.columnsData ? (
                    this.state.columnsData[0].is_active ? (
                      <td style={{ textAlign: "left" }}>
                        {String(e.id).length > 0 ? e.id : null}
                      </td>
                    ) : null
                  ) : null}
                  {this.state.columnsData ? (
                    this.state.columnsData[1].is_active &&
                    this.props.pageType === "Campaign" ? (
                      this.props.appliedGroupingValues.filter2 === "Campaign" ||
                      this.props.appliedGroupingValues.filter2 === "Country" ||
                      this.props.appliedGroupingValues.filter2 ===
                        "Browser Version" ||
                      this.props.appliedGroupingValues.filter2 ===
                        "OS Version" ||
                      this.props.appliedGroupingValues.filter2 === "Models" ||
                      this.props.appliedGroupingValues.filter2 === "Day" ||
                      this.props.appliedGroupingValues.filter2 === "Month" ? (
                        <td style={{ textAlign: "left", maxWidth:"200px", textOverflow:"ellipsis", whiteSpace:"nowrap", overflow:"hidden" }}>
                          {this.props.appliedGroupingValues.filter2 ===
                          "Campaign"
                            ? e.name
                            : null}
                          {this.props.appliedGroupingValues.filter2 ===
                          "Country"
                            ? e.country_name
                            : null}
                          {this.props.appliedGroupingValues.filter2 ===
                          "Browser Version"
                            ? e.version && e.version.length > 0
                              ? e.version[e.version.length - 1]
                              : null
                            : null}
                          {this.props.appliedGroupingValues.filter2 ===
                          "OS Version"
                            ? e.version && e.version.length > 0
                              ? e.version[e.version.length - 1]
                              : null
                            : null}
                          {this.props.appliedGroupingValues.filter2 === "Models"
                            ? e.models && e.models.length > 0
                              ? e.models[e.models.length - 1]
                              : null
                            : null}
                          {this.props.appliedGroupingValues.filter2 === "Day"
                            ? e.date_trunc
                              ? e.date_trunc.split("T")[0]
                              : null
                            : null}
                          {this.props.appliedGroupingValues.filter2 === "Month"
                            ? e.date_trunc
                              ? e.date_trunc.split("T")[0]
                              : null
                            : null}
                        </td>
                      ) : (
                        <td style={{ textAlign: "left", maxWidth:"200px", textOverflow:"ellipsis", whiteSpace:"nowrap", overflow:"hidden" }}>
                          {String(e.name).length > 0 ? e.name : null}
                        </td>
                      )
                    ) : null
                  ) : null}
                  {this.state.columnsData ? (
                    this.state.columnsData[1].is_active &&
                    this.props.pageType === "Offers" ? (
                      this.props.appliedGroupingValues.filter2 === "Offers" ||
                      this.props.appliedGroupingValues.filter2 === "Country" ||
                      this.props.appliedGroupingValues.filter2 ===
                        "Browser Version" ||
                      this.props.appliedGroupingValues.filter2 ===
                        "OS Version" ||
                      this.props.appliedGroupingValues.filter2 === "Models" ||
                      this.props.appliedGroupingValues.filter2 === "Day" ||
                      this.props.appliedGroupingValues.filter2 === "Month" ? (
                        <td style={{ textAlign: "left", maxWidth:"200px", textOverflow:"ellipsis", whiteSpace:"nowrap", overflow:"hidden" }}>
                          {this.props.appliedGroupingValues.filter2 === "Offers"
                            ? e.name
                            : null}
                          {this.props.appliedGroupingValues.filter2 ===
                          "Country"
                            ? e.country_name
                            : null}
                          {this.props.appliedGroupingValues.filter2 ===
                          "Browser Version"
                            ? e.version && e.version.length > 0
                              ? e.version[e.version.length - 1]
                              : null
                            : null}
                          {this.props.appliedGroupingValues.filter2 ===
                          "OS Version"
                            ? e.version && e.version.length > 0
                              ? e.version[e.version.length - 1]
                              : null
                            : null}
                          {this.props.appliedGroupingValues.filter2 === "Models"
                            ? e.models && e.models.length > 0
                              ? e.models[e.models.length - 1]
                              : null
                            : null}
                          {this.props.appliedGroupingValues.filter2 === "Day"
                            ? e.date_trunc
                              ? e.date_trunc.split("T")[0]
                              : null
                            : null}
                          {this.props.appliedGroupingValues.filter2 === "Month"
                            ? e.date_trunc
                              ? e.date_trunc.split("T")[0]
                              : null
                            : null}
                        </td>
                      ) : (
                        <td style={{ textAlign: "left", maxWidth:"200px", textOverflow:"ellipsis", whiteSpace:"nowrap", overflow:"hidden" }}>
                          {String(e.name).length > 0 ? e.name : null}
                        </td>
                      )
                    ) : null
                  ) : null}
                  {this.state.columnsData ? (
                    this.state.columnsData[1].is_active &&
                    this.props.pageType === "Lander" ? (
                      this.props.appliedGroupingValues.filter2 === "Lander" ||
                      this.props.appliedGroupingValues.filter2 === "Country" ||
                      this.props.appliedGroupingValues.filter2 ===
                        "Browser Version" ||
                      this.props.appliedGroupingValues.filter2 ===
                        "OS Version" ||
                      this.props.appliedGroupingValues.filter2 === "Models" ||
                      this.props.appliedGroupingValues.filter2 === "Day" ||
                      this.props.appliedGroupingValues.filter2 === "Month" ? (
                        <td style={{ textAlign: "left", maxWidth:"200px", textOverflow:"ellipsis", whiteSpace:"nowrap", overflow:"hidden" }}>
                          {this.props.appliedGroupingValues.filter2 === "Lander"
                            ? e.name
                            : null}
                          {this.props.appliedGroupingValues.filter2 ===
                          "Country"
                            ? e.country_name
                            : null}
                          {this.props.appliedGroupingValues.filter2 ===
                          "Browser Version"
                            ? e.version && e.version.length > 0
                              ? e.version[e.version.length - 1]
                              : null
                            : null}
                          {this.props.appliedGroupingValues.filter2 ===
                          "OS Version"
                            ? e.version && e.version.length > 0
                              ? e.version[e.version.length - 1]
                              : null
                            : null}
                          {this.props.appliedGroupingValues.filter2 === "Models"
                            ? e.models && e.models.length > 0
                              ? e.models[e.models.length - 1]
                              : null
                            : null}
                          {this.props.appliedGroupingValues.filter2 === "Day"
                            ? e.date_trunc
                              ? e.date_trunc.split("T")[0]
                              : null
                            : null}
                          {this.props.appliedGroupingValues.filter2 === "Month"
                            ? e.date_trunc
                              ? e.date_trunc.split("T")[0]
                              : null
                            : null}
                        </td>
                      ) : (
                        <td style={{ textAlign: "left", maxWidth:"200px", textOverflow:"ellipsis", whiteSpace:"nowrap", overflow:"hidden" }}>
                          {String(e.name).length > 0 ? e.name : null}
                        </td>
                      )
                    ) : null
                  ) : null}
                  {this.state.columnsData ? (
                    this.state.columnsData[1].is_active &&
                    this.props.pageType === "Flows" ? (
                      this.props.appliedGroupingValues.filter2 === "Flows" ||
                      this.props.appliedGroupingValues.filter2 === "Country" ||
                      this.props.appliedGroupingValues.filter2 ===
                        "Browser Version" ||
                      this.props.appliedGroupingValues.filter2 ===
                        "OS Version" ||
                      this.props.appliedGroupingValues.filter2 === "Models" ||
                      this.props.appliedGroupingValues.filter2 === "Day" ||
                      this.props.appliedGroupingValues.filter2 === "Month" ? (
                        <td style={{ textAlign: "left", maxWidth:"200px", textOverflow:"ellipsis", whiteSpace:"nowrap", overflow:"hidden" }}>
                          {this.props.appliedGroupingValues.filter2 === "Flows"
                            ? e.name
                            : null}
                          {this.props.appliedGroupingValues.filter2 ===
                          "Country"
                            ? e.country_name
                            : null}
                          {this.props.appliedGroupingValues.filter2 ===
                          "Browser Version"
                            ? e.version && e.version.length > 0
                              ? e.version[e.version.length - 1]
                              : null
                            : null}
                          {this.props.appliedGroupingValues.filter2 ===
                          "OS Version"
                            ? e.version && e.version.length > 0
                              ? e.version[e.version.length - 1]
                              : null
                            : null}
                          {this.props.appliedGroupingValues.filter2 === "Models"
                            ? e.models && e.models.length > 0
                              ? e.models[e.models.length - 1]
                              : null
                            : null}
                          {this.props.appliedGroupingValues.filter2 === "Day"
                            ? e.date_trunc
                              ? e.date_trunc.split("T")[0]
                              : null
                            : null}
                          {this.props.appliedGroupingValues.filter2 === "Month"
                            ? e.date_trunc
                              ? e.date_trunc.split("T")[0]
                              : null
                            : null}
                        </td>
                      ) : (
                        <td style={{ textAlign: "left", maxWidth:"200px", textOverflow:"ellipsis", whiteSpace:"nowrap", overflow:"hidden" }}>
                          {String(e.name).length > 0 ? e.name : null}
                        </td>
                      )
                    ) : null
                  ) : null}
                  {this.state.columnsData ? (
                    this.state.columnsData[1].is_active &&
                    this.props.pageType === "Traffic Source" ? (
                      this.props.appliedGroupingValues.filter2 === "Country" ||
                      this.props.appliedGroupingValues.filter2 ===
                        "Browser Version" ||
                      this.props.appliedGroupingValues.filter2 ===
                        "OS Version" ||
                      this.props.appliedGroupingValues.filter2 === "Models" ||
                      this.props.appliedGroupingValues.filter2 === "Day" ||
                      this.props.appliedGroupingValues.filter2 === "Month" ? (
                        <td style={{ textAlign: "left", maxWidth:"200px", textOverflow:"ellipsis", whiteSpace:"nowrap", overflow:"hidden" }}>
                          {this.props.appliedGroupingValues.filter2 ===
                          "Country"
                            ? e.country_name
                            : null}
                          {this.props.appliedGroupingValues.filter2 ===
                          "Browser Version"
                            ? e.version && e.version.length > 0
                              ? e.version[e.version.length - 1]
                              : null
                            : null}
                          {this.props.appliedGroupingValues.filter2 ===
                          "OS Version"
                            ? e.version && e.version.length > 0
                              ? e.version[e.version.length - 1]
                              : null
                            : null}
                          {this.props.appliedGroupingValues.filter2 === "Models"
                            ? e.models && e.models.length > 0
                              ? e.models[e.models.length - 1]
                              : null
                            : null}
                          {this.props.appliedGroupingValues.filter2 === "Day"
                            ? e.date_trunc
                              ? e.date_trunc.split("T")[0]
                              : null
                            : null}
                          {this.props.appliedGroupingValues.filter2 === "Month"
                            ? e.date_trunc
                              ? e.date_trunc.split("T")[0]
                              : null
                            : null}
                        </td>
                      ) : (
                        <td style={{ textAlign: "left", maxWidth:"200px", textOverflow:"ellipsis", whiteSpace:"nowrap", overflow:"hidden" }}>
                          {String(e.name).length > 0 ? e.name : null}
                        </td>
                      )
                    ) : null
                  ) : null}
                  {this.state.columnsData ? (
                    this.state.columnsData[1].is_active &&
                    this.props.pageType === "Affiliate Network" ? (
                      this.props.appliedGroupingValues.filter2 === "Country" ||
                      this.props.appliedGroupingValues.filter2 ===
                        "Browser Version" ||
                      this.props.appliedGroupingValues.filter2 ===
                        "OS Version" ||
                      this.props.appliedGroupingValues.filter2 === "Models" ||
                      this.props.appliedGroupingValues.filter2 === "Day" ||
                      this.props.appliedGroupingValues.filter2 === "Month" ? (
                        <td style={{ textAlign: "left", maxWidth:"200px", textOverflow:"ellipsis", whiteSpace:"nowrap", overflow:"hidden" }}>
                          {this.props.appliedGroupingValues.filter2 ===
                          "Country"
                            ? e.country_name
                            : null}
                          {this.props.appliedGroupingValues.filter2 ===
                          "Browser Version"
                            ? e.version && e.version.length > 0
                              ? e.version[e.version.length - 1]
                              : null
                            : null}
                          {this.props.appliedGroupingValues.filter2 ===
                          "OS Version"
                            ? e.version && e.version.length > 0
                              ? e.version[e.version.length - 1]
                              : null
                            : null}
                          {this.props.appliedGroupingValues.filter2 === "Models"
                            ? e.models && e.models.length > 0
                              ? e.models[e.models.length - 1]
                              : null
                            : null}
                          {this.props.appliedGroupingValues.filter2 === "Day"
                            ? e.date_trunc
                              ? e.date_trunc.split("T")[0]
                              : null
                            : null}
                          {this.props.appliedGroupingValues.filter2 === "Month"
                            ? e.date_trunc
                              ? e.date_trunc.split("T")[0]
                              : null
                            : null}
                        </td>
                      ) : (
                        <td style={{ textAlign: "left", maxWidth:"200px", textOverflow:"ellipsis", whiteSpace:"nowrap", overflow:"hidden" }}>
                          {String(e.name).length > 0 ? e.name : null}
                        </td>
                      )
                    ) : null
                  ) : null}
                  {this.state.columnsData ? (
                    this.state.columnsData[1].is_active &&
                    this.props.pageType !== "Campaign" &&
                    this.props.pageType !== "Offers" &&
                    this.props.pageType !== "Lander" &&
                    this.props.pageType !== "Flows" &&
                    this.props.pageType !== "Affilate Network" &&
                    this.props.pageType !== "Traffic Source" ? (
                      this.props.appliedGroupingValues.filter2 === "Country" ||
                      this.props.appliedGroupingValues.filter2 ===
                        "Browser Version" ||
                      this.props.appliedGroupingValues.filter2 ===
                        "OS Version" ||
                      this.props.appliedGroupingValues.filter2 === "Models" ||
                      this.props.appliedGroupingValues.filter2 === "Day" ||
                      this.props.appliedGroupingValues.filter2 === "Month" ? (
                        <td style={{ textAlign: "left", maxWidth:"200px", textOverflow:"ellipsis", whiteSpace:"nowrap", overflow:"hidden" }}>
                          {this.props.appliedGroupingValues.filter2 ===
                          "Country"
                            ? e.country_name
                            : null}
                          {this.props.appliedGroupingValues.filter2 ===
                          "Browser Version"
                            ? e.version && e.version.length > 0
                              ? e.version[e.version.length - 1]
                              : null
                            : null}
                          {this.props.appliedGroupingValues.filter2 ===
                          "OS Version"
                            ? e.version && e.version.length > 0
                              ? e.version[e.version.length - 1]
                              : null
                            : null}
                          {this.props.appliedGroupingValues.filter2 === "Models"
                            ? e.models && e.models.length > 0
                              ? e.models[e.models.length - 1]
                              : null
                            : null}
                          {this.props.appliedGroupingValues.filter2 === "Day"
                            ? e.date_trunc
                              ? e.date_trunc.split("T")[0]
                              : null
                            : null}
                          {this.props.appliedGroupingValues.filter2 === "Month"
                            ? e.date_trunc
                              ? e.date_trunc.split("T")[0]
                              : null
                            : null}
                        </td>
                      ) : (
                        <td style={{ textAlign: "left", maxWidth:"200px", textOverflow:"ellipsis", whiteSpace:"nowrap", overflow:"hidden" }}>
                          {String(e.name).length > 0 ? e.name : null}
                        </td>
                      )
                    ) : null
                  ) : null}
                  {this.state.columnsData ? (
                    this.state.columnsData[24].is_active &&
                    this.props.pageType === "Campaign" ? (
                      <td style={{ textAlign: "left" }}>
                        {e.notes !== null ? (
                          <>
                            <span
                              data-tip
                              data-for={"notes_tt_" + e.id}
                              className="question_mark"
                            >
                              <span className="k-icon k-i-table-align-top-left"></span>
                            </span>
                            <ReactTooltip
                              className="grid_tooltip"
                              id={"notes_tt_" + e.id}
                              type="light"
                            >
                              <span>{e.notes}</span>
                            </ReactTooltip>
                          </>
                        ) : null}
                      </td>
                    ) : null
                  ) : null}
                  {this.state.columnsData ? (
                    this.state.columnsData[2].is_active ? (
                      this.props.appliedGroupingValues.filter2 === "Campaign" ||
                      this.props.appliedGroupingValues.filter2 === "Offers" ||
                      this.props.appliedGroupingValues.filter2 === "Lander" ||
                      this.props.appliedGroupingValues.filter2 === "Flows" ||
                      this.props.appliedGroupingValues.filter2 ===
                        "Traffic Source" ||
                      this.props.appliedGroupingValues.filter2 ===
                        "Affiliate Network" ? (
                        <td style={{ textAlign: "left" }}>
                          {e.workspace ? e.workspace.name : null}
                        </td>
                      ) : null
                    ) : null
                  ) : null}
                  {this.state.columnsData ? (
                    this.state.columnsData[3].is_active ? (
                      <td>{e.visits}</td>
                    ) : null
                  ) : null}
                  {this.state.columnsData ? (
                    this.state.columnsData[4].is_active ? (
                      <td>{e.uniquevisit}</td>
                    ) : null
                  ) : null}
                  {this.state.columnsData ? (
                    this.state.columnsData[5].is_active ? (
                      <td>{e.clicks}</td>
                    ) : null
                  ) : null}
                  {this.state.columnsData ? (
                    this.state.columnsData[23].is_active ? (
                      <td>{e.uniqueclick}</td>
                    ) : null
                  ) : null}
                  {this.state.columnsData ? (
                    this.state.columnsData[6].is_active ? (
                      <td>{e.conversion}</td>
                    ) : null
                  ) : null}
                  {this.state.columnsData ? (
                    this.state.columnsData[22].is_active ? (
                      <td>
                        {this.props.userCurrency +
                          (Math.round(Number(e.cost) * 100) / 100).toFixed(2)}
                      </td>
                    ) : null
                  ) : null}
                  {/*{ this.state.columnsData ? (this.state.columnsData[7].is_active ? <td>{e.profit}</td> : null) : null }*/}
                  {this.state.columnsData ? (
                    this.state.columnsData[7].is_active ? (
                      Number(e.profit) < 0 ? (
                        <td style={{ color: "#d25454" }}>
                          {this.props.userCurrency +
                            (Math.round(Number(e.profit) * 100) / 100).toFixed(
                              2
                            )}
                        </td>
                      ) : Number(e.profit) > 0 ? (
                        <td style={{ color: "#29af61" }}>
                          {this.props.userCurrency +
                            (Math.round(Number(e.profit) * 100) / 100).toFixed(
                              2
                            )}
                        </td>
                      ) : (
                        <td>
                          {this.props.userCurrency +
                            (Math.round(Number(e.profit) * 100) / 100).toFixed(
                              2
                            )}
                        </td>
                      )
                    ) : null
                  ) : null}
                  {this.state.columnsData ? (
                    this.state.columnsData[8].is_active ? (
                      <td>
                        {this.props.userCurrency +
                          (Math.round(Number(e.revenue) * 100) / 100).toFixed(
                            2
                          )}
                      </td>
                    ) : null
                  ) : null}
                  {this.state.columnsData ? (
                    this.state.columnsData[9].is_active ? (
                      <td>
                        {this.props.userCurrency +
                          (Math.round(Number(e.AP) * 100) / 100).toFixed(2)}
                      </td>
                    ) : null
                  ) : null}
                  {this.state.columnsData ? (
                    this.state.columnsData[10].is_active ? (
                      <td>
                        {this.props.userCurrency +
                          (Math.round(Number(e.CPV) * 100) / 100).toFixed(2)}
                      </td>
                    ) : null
                  ) : null}
                  {this.state.columnsData ? (
                    this.state.columnsData[11].is_active ? (
                      <td>
                        {(Math.round(Number(e.CR) * 100) / 100).toFixed(2) +
                          "%"}
                      </td>
                    ) : null
                  ) : null}
                  {this.state.columnsData ? (
                    this.state.columnsData[12].is_active ? (
                      <td>
                        {(Math.round(Number(e.CTR) * 100) / 100).toFixed(2) +
                          "%"}
                      </td>
                    ) : null
                  ) : null}
                  {this.state.columnsData ? (
                    this.state.columnsData[13].is_active ? (
                      <td>
                        {(Math.round(Number(e.CV) * 100) / 100).toFixed(2) +
                          "%"}
                      </td>
                    ) : null
                  ) : null}
                  {this.state.columnsData ? (
                    this.state.columnsData[14].is_active ? (
                      <td>
                        {this.props.userCurrency +
                          (Math.round(Number(e.ECPA) * 100) / 100).toFixed(2)}
                      </td>
                    ) : null
                  ) : null}
                  {this.state.columnsData ? (
                    this.state.columnsData[15].is_active ? (
                      <td>
                        {this.props.userCurrency +
                          (Math.round(Number(e.ECPC) * 100) / 100).toFixed(2)}
                      </td>
                    ) : null
                  ) : null}
                  {this.state.columnsData ? (
                    this.state.columnsData[16].is_active ? (
                      <td>
                        {this.props.userCurrency +
                          (Math.round(Number(e.ECPM) * 100) / 100).toFixed(2)}
                      </td>
                    ) : null
                  ) : null}
                  {this.state.columnsData ? (
                    this.state.columnsData[17].is_active ? (
                      <td>
                        {this.props.userCurrency +
                          (Math.round(Number(e.EPC) * 100) / 100).toFixed(2)}
                      </td>
                    ) : null
                  ) : null}
                  {this.state.columnsData ? (
                    this.state.columnsData[18].is_active ? (
                      <td>
                        {this.props.userCurrency +
                          (Math.round(Number(e.EPV) * 100) / 100).toFixed(2)}
                      </td>
                    ) : null
                  ) : null}
                  {this.state.columnsData ? (
                    this.state.columnsData[19].is_active ? (
                      <td>
                        {(Math.round(Number(e.ICTR) * 100) / 100).toFixed(2) +
                          "%"}
                      </td>
                    ) : null
                  ) : null}
                  {/*{ this.state.columnsData ? (this.state.columnsData[20].is_active ? <td>{e.ROI + "%"}</td> : null) : null }*/}
                  {this.state.columnsData ? (
                    this.state.columnsData[20].is_active ? (
                      <td
                        style={
                          Number(e.ROI) < 0
                            ? { color: "#d25454" }
                            : Number(e.ROI) > 0
                            ? { color: "#29af61" }
                            : null
                        }
                      >
                        {(Math.round(Number(e.ROI) * 100) / 100).toFixed(2) +
                          "%"}
                      </td>
                    ) : null
                  ) : null}
                  {this.state.columnsData ? (
                    this.state.columnsData[21].is_active ? (
                      <td>
                        {this.props.userCurrency +
                          (Math.round(Number(e.RPM) * 100) / 100).toFixed(2)}
                      </td>
                    ) : null
                  ) : null}
                </tr>
              );
              const child = e.sub ? (
                e.sub.length > 0 ? (
                  e.sub.map((item) => {
                    let setClass =""
                        if (ifFirstRow){
                          setClass=" inside-first-row"
                          ifFirstRow=false
                        }else{
                          setClass =""
                        }
                    const secondLevel = (
                      <tr className={"row_" + e.id + "_" + item.id+ setClass } id="inside_section_background">
                        {/*<td className="profit_change">*/}
                        {/*    { Number(item.profit) === 0 ? <div className="neutral"><span>&#183;</span></div> : null }*/}
                        {/*    { Number(item.profit) > 0 ? <div className="plus"><span>+</span></div> : null }*/}
                        {/*    { Number(item.profit) < 0 ? <div className="minus"><span>-</span></div> : null }*/}
                        {/*</td>*/}
                        <td className="grid_sticky_td">
                          <div className="profit_change_con">
                            <div className="profit_change">
                              {Number(item.profit) === 0 ? (
                                <div className="neutral">
                                  <span>&#183;</span>
                                </div>
                              ) : null}
                              {Number(item.profit) > 0 ? (
                                <div className="plus">
                                  <span>+</span>
                                </div>
                              ) : null}
                              {Number(item.profit) < 0 ? (
                                <div className="minus">
                                  <span>-</span>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </td>
                        {this.props.appliedGroupingValues.filter4.length > 0 ? (
                          <td
                            style={{ textAlign: "left", paddingLeft: "30px" }}
                          >
                            <span
                              id={e.id + "_" + item.id}
                              onClick={(e) =>
                                this.getSubLevelData(e, "thirdLevel")
                              }
                              className="k-icon k-i-arrow-chevron-right"
                            ></span>

                            <span
                              style={{ display: "none" }}
                              id={e.id + "_" + item.id + "_expand"}
                              onClick={this.expandRows}
                              className="k-icon k-i-arrow-chevron-right"
                            ></span>
                            <span
                              style={{ display: "none" }}
                              id={e.id + "_" + item.id + "_collapse"}
                              onClick={this.collapseRows}
                              className="k-icon k-i-arrow-chevron-down"
                            ></span>
                          </td>
                        ) : (
                          <td></td>
                        )}
                        {this.state.columnsData ? (
                          this.state.columnsData[0].is_active ? (
                            <td>{item.id ? item.id : null}</td>
                          ) : null
                        ) : null}
                        {this.state.columnsData ? (
                          this.state.columnsData[1].is_active &&
                          this.props.pageType === "Campaign" ? (
                            this.props.appliedGroupingValues.filter3 ===
                              "Campaign" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Country" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Browser Version" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "OS Version" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Models" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Day" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Month" ? (
                              <td style={{ textAlign: "left" }}>
                                {this.props.appliedGroupingValues.filter3 ===
                                "Campaign"
                                  ? item.name
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Country"
                                  ? item.country_name
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Browser Version"
                                  ? item.version && item.version.length > 0
                                    ? item.version[item.version.length - 1]
                                    : null
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "OS Version"
                                  ? item.version && item.version.length > 0
                                    ? item.version[item.version.length - 1]
                                    : null
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Models"
                                  ? item.models && item.models.length > 0
                                    ? item.models[item.models.length - 1]
                                    : null
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Day"
                                  ? item.date_trunc
                                    ? item.date_trunc.split("T")[0]
                                    : null
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Month"
                                  ? item.date_trunc
                                    ? item.date_trunc.split("T")[0]
                                    : null
                                  : null}
                              </td>
                            ) : (
                              <td style={{ textAlign: "left" }}>
                                {item.name ? item.name : null}
                              </td>
                            )
                          ) : null
                        ) : null}
                        {this.state.columnsData ? (
                          this.state.columnsData[1].is_active &&
                          this.props.pageType === "Offers" ? (
                            this.props.appliedGroupingValues.filter3 ===
                              "Offers" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Country" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Browser Version" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "OS Version" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Models" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Day" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Month" ? (
                              <td style={{ textAlign: "left" }}>
                                {this.props.appliedGroupingValues.filter3 ===
                                "Offers"
                                  ? item.name
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Country"
                                  ? item.country_name
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Browser Version"
                                  ? item.version && item.version.length > 0
                                    ? item.version[item.version.length - 1]
                                    : null
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "OS Version"
                                  ? item.version && item.version.length > 0
                                    ? item.version[item.version.length - 1]
                                    : null
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Models"
                                  ? item.models && item.models.length > 0
                                    ? item.models[item.models.length - 1]
                                    : null
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Day"
                                  ? item.date_trunc
                                    ? item.date_trunc.split("T")[0]
                                    : null
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Month"
                                  ? item.date_trunc
                                    ? item.date_trunc.split("T")[0]
                                    : null
                                  : null}
                              </td>
                            ) : (
                              <td style={{ textAlign: "left" }}>
                                {item.name ? item.name : null}
                              </td>
                            )
                          ) : null
                        ) : null}
                        {this.state.columnsData ? (
                          this.state.columnsData[1].is_active &&
                          this.props.pageType === "Lander" ? (
                            this.props.appliedGroupingValues.filter3 ===
                              "Lander" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Country" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Browser Version" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "OS Version" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Models" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Day" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Month" ? (
                              <td style={{ textAlign: "left" }}>
                                {this.props.appliedGroupingValues.filter3 ===
                                "Lander"
                                  ? item.name
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Country"
                                  ? item.country_name
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Browser Version"
                                  ? item.version && item.version.length > 0
                                    ? item.version[item.version.length - 1]
                                    : null
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "OS Version"
                                  ? item.version && item.version.length > 0
                                    ? item.version[item.version.length - 1]
                                    : null
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Models"
                                  ? item.models && item.models.length > 0
                                    ? item.models[item.models.length - 1]
                                    : null
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Day"
                                  ? item.date_trunc
                                    ? item.date_trunc.split("T")[0]
                                    : null
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Month"
                                  ? item.date_trunc
                                    ? item.date_trunc.split("T")[0]
                                    : null
                                  : null}
                              </td>
                            ) : (
                              <td style={{ textAlign: "left" }}>
                                {item.name ? item.name : null}
                              </td>
                            )
                          ) : null
                        ) : null}
                        {this.state.columnsData ? (
                          this.state.columnsData[1].is_active &&
                          this.props.pageType === "Flows" ? (
                            this.props.appliedGroupingValues.filter3 ===
                              "Flows" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Country" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Browser Version" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "OS Version" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Models" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Day" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Month" ? (
                              <td style={{ textAlign: "left" }}>
                                {this.props.appliedGroupingValues.filter3 ===
                                "Flows"
                                  ? item.name
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Country"
                                  ? item.country_name
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Browser Version"
                                  ? item.version && item.version.length > 0
                                    ? item.version[item.version.length - 1]
                                    : null
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "OS Version"
                                  ? item.version && item.version.length > 0
                                    ? item.version[item.version.length - 1]
                                    : null
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Models"
                                  ? item.models && item.models.length > 0
                                    ? item.models[item.models.length - 1]
                                    : null
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Day"
                                  ? item.date_trunc
                                    ? item.date_trunc.split("T")[0]
                                    : null
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Month"
                                  ? item.date_trunc
                                    ? item.date_trunc.split("T")[0]
                                    : null
                                  : null}
                              </td>
                            ) : (
                              <td style={{ textAlign: "left" }}>
                                {item.name ? item.name : null}
                              </td>
                            )
                          ) : null
                        ) : null}
                        {this.state.columnsData ? (
                          this.state.columnsData[1].is_active &&
                          this.props.pageType === "Traffic Source" ? (
                            this.props.appliedGroupingValues.filter3 ===
                              "Country" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Browser Version" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "OS Version" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Models" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Day" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Month" ? (
                              <td style={{ textAlign: "left" }}>
                                {this.props.appliedGroupingValues.filter3 ===
                                "Country"
                                  ? item.country_name
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Browser Version"
                                  ? item.version && item.version.length > 0
                                    ? item.version[item.version.length - 1]
                                    : null
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "OS Version"
                                  ? item.version && item.version.length > 0
                                    ? item.version[item.version.length - 1]
                                    : null
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Models"
                                  ? item.models && item.models.length > 0
                                    ? item.models[item.models.length - 1]
                                    : null
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Day"
                                  ? item.date_trunc
                                    ? item.date_trunc.split("T")[0]
                                    : null
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Month"
                                  ? item.date_trunc
                                    ? item.date_trunc.split("T")[0]
                                    : null
                                  : null}
                              </td>
                            ) : (
                              <td style={{ textAlign: "left" }}>
                                {item.name ? item.name : null}
                              </td>
                            )
                          ) : null
                        ) : null}
                        {this.state.columnsData ? (
                          this.state.columnsData[1].is_active &&
                          this.props.pageType === "Affiliate Network" ? (
                            this.props.appliedGroupingValues.filter3 ===
                              "Country" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Browser Version" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "OS Version" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Models" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Day" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Month" ? (
                              <td style={{ textAlign: "left" }}>
                                {this.props.appliedGroupingValues.filter3 ===
                                "Country"
                                  ? item.country_name
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Browser Version"
                                  ? item.version && item.version.length > 0
                                    ? item.version[item.version.length - 1]
                                    : null
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "OS Version"
                                  ? item.version && item.version.length > 0
                                    ? item.version[item.version.length - 1]
                                    : null
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Models"
                                  ? item.models && item.models.length > 0
                                    ? item.models[item.models.length - 1]
                                    : null
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Day"
                                  ? item.date_trunc
                                    ? item.date_trunc.split("T")[0]
                                    : null
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Month"
                                  ? item.date_trunc
                                    ? item.date_trunc.split("T")[0]
                                    : null
                                  : null}
                              </td>
                            ) : (
                              <td style={{ textAlign: "left" }}>
                                {item.name ? item.name : null}
                              </td>
                            )
                          ) : null
                        ) : null}
                        {this.state.columnsData ? (
                          this.state.columnsData[1].is_active &&
                          this.props.pageType !== "Campaign" &&
                          this.props.pageType !== "Offers" &&
                          this.props.pageType !== "Lander" &&
                          this.props.pageType !== "Flows" &&
                          this.props.pageType !== "Affilate Network" &&
                          this.props.pageType !== "Traffic Source" ? (
                            this.props.appliedGroupingValues.filter3 ===
                              "Country" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Browser Version" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "OS Version" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Models" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Day" ||
                            this.props.appliedGroupingValues.filter3 ===
                              "Month" ? (
                              <td style={{ textAlign: "left" }}>
                                {this.props.appliedGroupingValues.filter3 ===
                                "Country"
                                  ? item.country_name
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Browser Version"
                                  ? item.version && item.version.length > 0
                                    ? item.version[item.version.length - 1]
                                    : null
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "OS Version"
                                  ? item.version && item.version.length > 0
                                    ? item.version[item.version.length - 1]
                                    : null
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Models"
                                  ? item.models && item.models.length > 0
                                    ? item.models[item.models.length - 1]
                                    : null
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Day"
                                  ? item.date_trunc
                                    ? item.date_trunc.split("T")[0]
                                    : null
                                  : null}
                                {this.props.appliedGroupingValues.filter3 ===
                                "Month"
                                  ? item.date_trunc
                                    ? item.date_trunc.split("T")[0]
                                    : null
                                  : null}
                              </td>
                            ) : (
                              <td style={{ textAlign: "left" }}>
                                {item.name ? item.name : null}
                              </td>
                            )
                          ) : null
                        ) : null}
                        {this.state.columnsData ? (
                          this.state.columnsData[24].is_active &&
                          this.props.pageType === "Campaign" ? (
                            <td style={{ textAlign: "left" }}></td>
                          ) : null
                        ) : null}
                        {this.props.appliedGroupingValues.filter2 ===
                          "Campaign" ||
                        this.props.appliedGroupingValues.filter2 === "Offers" ||
                        this.props.appliedGroupingValues.filter2 === "Lander" ||
                        this.props.appliedGroupingValues.filter2 === "Flows" ||
                        this.props.appliedGroupingValues.filter2 ===
                          "Traffic Source" ||
                        this.props.appliedGroupingValues.filter2 ===
                          "Affiliate Network" ? (
                          <td></td>
                        ) : null}
                        {this.state.columnsData ? (
                          this.state.columnsData[3].is_active ? (
                            <td>{item.visits}</td>
                          ) : null
                        ) : null}
                        {this.state.columnsData ? (
                          this.state.columnsData[4].is_active ? (
                            <td>{item.uniquevisit}</td>
                          ) : null
                        ) : null}
                        {this.state.columnsData ? (
                          this.state.columnsData[5].is_active ? (
                            <td>{item.clicks}</td>
                          ) : null
                        ) : null}
                        {this.state.columnsData ? (
                          this.state.columnsData[23].is_active ? (
                            <td>{item.uniqueclick}</td>
                          ) : null
                        ) : null}
                        {this.state.columnsData ? (
                          this.state.columnsData[6].is_active ? (
                            <td>{item.conversion}</td>
                          ) : null
                        ) : null}
                        {this.state.columnsData ? (
                          this.state.columnsData[22].is_active ? (
                            <td>
                              {this.props.userCurrency +
                                (
                                  Math.round(Number(item.cost) * 100) / 100
                                ).toFixed(2)}
                            </td>
                          ) : null
                        ) : null}
                        {/*{ this.state.columnsData ? (this.state.columnsData[7].is_active ? <td>{item.profit}</td> : null) : null }*/}
                        {this.state.columnsData ? (
                          this.state.columnsData[7].is_active ? (
                            Number(item.profit) < 0 ? (
                              <td style={{ color: "#d25454" }}>
                                {this.props.userCurrency +
                                  (
                                    Math.round(Number(item.profit) * 100) / 100
                                  ).toFixed(2)}
                              </td>
                            ) : Number(item.profit) > 0 ? (
                              <td style={{ color: "#29af61" }}>
                                {this.props.userCurrency +
                                  (
                                    Math.round(Number(item.profit) * 100) / 100
                                  ).toFixed(2)}
                              </td>
                            ) : (
                              <td>
                                {this.props.userCurrency +
                                  (
                                    Math.round(Number(item.profit) * 100) / 100
                                  ).toFixed(2)}
                              </td>
                            )
                          ) : null
                        ) : null}
                        {this.state.columnsData ? (
                          this.state.columnsData[8].is_active ? (
                            <td>
                              {this.props.userCurrency +
                                (
                                  Math.round(Number(item.revenue) * 100) / 100
                                ).toFixed(2)}
                            </td>
                          ) : null
                        ) : null}
                        {this.state.columnsData ? (
                          this.state.columnsData[9].is_active ? (
                            <td>
                              {this.props.userCurrency +
                                (
                                  Math.round(Number(item.AP) * 100) / 100
                                ).toFixed(2)}
                            </td>
                          ) : null
                        ) : null}
                        {this.state.columnsData ? (
                          this.state.columnsData[10].is_active ? (
                            <td>
                              {this.props.userCurrency +
                                (
                                  Math.round(Number(item.CPV) * 100) / 100
                                ).toFixed(2)}
                            </td>
                          ) : null
                        ) : null}
                        {this.state.columnsData ? (
                          this.state.columnsData[11].is_active ? (
                            <td>
                              {(
                                Math.round(Number(item.CR) * 100) / 100
                              ).toFixed(2) + "%"}
                            </td>
                          ) : null
                        ) : null}
                        {this.state.columnsData ? (
                          this.state.columnsData[12].is_active ? (
                            <td>
                              {(
                                Math.round(Number(item.CTR) * 100) / 100
                              ).toFixed(2) + "%"}
                            </td>
                          ) : null
                        ) : null}
                        {this.state.columnsData ? (
                          this.state.columnsData[13].is_active ? (
                            <td>
                              {(
                                Math.round(Number(item.CV) * 100) / 100
                              ).toFixed(2) + "%"}
                            </td>
                          ) : null
                        ) : null}
                        {this.state.columnsData ? (
                          this.state.columnsData[14].is_active ? (
                            <td>
                              {this.props.userCurrency +
                                (
                                  Math.round(Number(item.ECPA) * 100) / 100
                                ).toFixed(2)}
                            </td>
                          ) : null
                        ) : null}
                        {this.state.columnsData ? (
                          this.state.columnsData[15].is_active ? (
                            <td>
                              {this.props.userCurrency +
                                (
                                  Math.round(Number(item.ECPC) * 100) / 100
                                ).toFixed(2)}
                            </td>
                          ) : null
                        ) : null}
                        {this.state.columnsData ? (
                          this.state.columnsData[16].is_active ? (
                            <td>
                              {this.props.userCurrency +
                                (
                                  Math.round(Number(item.ECPM) * 100) / 100
                                ).toFixed(2)}
                            </td>
                          ) : null
                        ) : null}
                        {this.state.columnsData ? (
                          this.state.columnsData[17].is_active ? (
                            <td>
                              {this.props.userCurrency +
                                (
                                  Math.round(Number(item.EPC) * 100) / 100
                                ).toFixed(2)}
                            </td>
                          ) : null
                        ) : null}
                        {this.state.columnsData ? (
                          this.state.columnsData[18].is_active ? (
                            <td>
                              {this.props.userCurrency +
                                (
                                  Math.round(Number(item.EPV) * 100) / 100
                                ).toFixed(2)}
                            </td>
                          ) : null
                        ) : null}
                        {this.state.columnsData ? (
                          this.state.columnsData[19].is_active ? (
                            <td>
                              {(
                                Math.round(Number(item.ICTR) * 100) / 100
                              ).toFixed(2) + "%"}
                            </td>
                          ) : null
                        ) : null}
                        {/*{ this.state.columnsData ? (this.state.columnsData[20].is_active ? <td>{item.ROI + "%"}</td> : null) : null }*/}
                        {this.state.columnsData ? (
                          this.state.columnsData[20].is_active ? (
                            <td
                              style={
                                Number(item.ROI) < 0
                                  ? { color: "#d25454" }
                                  : Number(item.ROI) > 0
                                  ? { color: "#29af61" }
                                  : null
                              }
                            >
                              {(
                                Math.round(Number(item.ROI) * 100) / 100
                              ).toFixed(2) + "%"}
                            </td>
                          ) : null
                        ) : null}
                        {this.state.columnsData ? (
                          this.state.columnsData[21].is_active ? (
                            <td>
                              {this.props.userCurrency +
                                (
                                  Math.round(Number(item.RPM) * 100) / 100
                                ).toFixed(2)}
                            </td>
                          ) : null
                        ) : null}
                      </tr>
                    );
                    const thirdChild = item.sub ? (
                      item.sub.length > 0 ? (
                        item.sub?.map((k) => (
                          <tr
                            className={
                              "row_" + e.id + "_" + item.id + "_" + k.id
                            }
                            id="inside_section_background-2"
                          >
                            {/*<td className="profit_change">*/}
                            {/*    { Number(k.profit) === 0 ? <div className="neutral"><span>&#183;</span></div> : null }*/}
                            {/*    { Number(k.profit) > 0 ? <div className="plus"><span>+</span></div> : null }*/}
                            {/*    { Number(k.profit) < 0 ? <div className="minus"><span>-</span></div> : null }*/}
                            {/*</td>*/}
                            <td className="grid_sticky_td">
                              <div className="profit_change_con">
                                <div className="profit_change">
                                  {Number(k.profit) === 0 ? (
                                    <div className="neutral">
                                      <span>&#183;</span>
                                    </div>
                                  ) : null}
                                  {Number(k.profit) > 0 ? (
                                    <div className="plus">
                                      <span>+</span>
                                    </div>
                                  ) : null}
                                  {Number(k.profit) < 0 ? (
                                    <div className="minus">
                                      <span>-</span>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </td>
                            <td></td>
                            {this.state.columnsData ? (
                              this.state.columnsData[0].is_active ? (
                                <td
                                  style={{
                                    textAlign: "right",
                                    paddingRight: "8px",
                                  }}
                                >
                                  {k.id ? k.id : null}
                                </td>
                              ) : null
                            ) : null}
                            {this.state.columnsData ? (
                              this.state.columnsData[1].is_active &&
                              this.props.pageType === "Campaign" ? (
                                this.props.appliedGroupingValues.filter4 ===
                                  "Campaign" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Country" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Browser Version" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "OS Version" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Models" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Day" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Month" ? (
                                  <td style={{ textAlign: "left" }}>
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Campaign"
                                      ? k.name
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Country"
                                      ? k.country_name
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Browser Version"
                                      ? k.version && k.version.length > 0
                                        ? k.version[k.version.length - 1]
                                        : null
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "OS Version"
                                      ? k.version && k.version.length > 0
                                        ? k.version[k.version.length - 1]
                                        : null
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Models"
                                      ? k.models && k.models.length > 0
                                        ? k.models[k.models.length - 1]
                                        : null
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Day"
                                      ? k.date_trunc
                                        ? k.date_trunc.split("T")[0]
                                        : null
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Month"
                                      ? k.date_trunc
                                        ? k.date_trunc.split("T")[0]
                                        : null
                                      : null}
                                  </td>
                                ) : (
                                  <td style={{ textAlign: "left" }}>
                                    {k.name ? k.name : null}
                                  </td>
                                )
                              ) : null
                            ) : null}
                            {this.state.columnsData ? (
                              this.state.columnsData[1].is_active &&
                              this.props.pageType === "Offers" ? (
                                this.props.appliedGroupingValues.filter4 ===
                                  "Offers" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Country" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Browser Version" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "OS Version" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Models" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Day" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Month" ? (
                                  <td style={{ textAlign: "left" }}>
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Offers"
                                      ? k.name
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Country"
                                      ? k.country_name
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Browser Version"
                                      ? k.version && k.version.length > 0
                                        ? k.version[k.version.length - 1]
                                        : null
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "OS Version"
                                      ? k.version && k.version.length > 0
                                        ? k.version[k.version.length - 1]
                                        : null
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Models"
                                      ? k.models && k.models.length > 0
                                        ? k.models[k.models.length - 1]
                                        : null
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Day"
                                      ? k.date_trunc
                                        ? k.date_trunc.split("T")[0]
                                        : null
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Month"
                                      ? k.date_trunc
                                        ? k.date_trunc.split("T")[0]
                                        : null
                                      : null}
                                  </td>
                                ) : (
                                  <td style={{ textAlign: "left" }}>
                                    {k.name ? k.name : null}
                                  </td>
                                )
                              ) : null
                            ) : null}
                            {this.state.columnsData ? (
                              this.state.columnsData[1].is_active &&
                              this.props.pageType === "Lander" ? (
                                this.props.appliedGroupingValues.filter4 ===
                                  "Lander" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Country" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Browser Version" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "OS Version" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Models" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Day" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Month" ? (
                                  <td style={{ textAlign: "left" }}>
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Lander"
                                      ? k.name
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Country"
                                      ? k.country_name
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Browser Version"
                                      ? k.version && k.version.length > 0
                                        ? k.version[k.version.length - 1]
                                        : null
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "OS Version"
                                      ? k.version && k.version.length > 0
                                        ? k.version[k.version.length - 1]
                                        : null
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Models"
                                      ? k.models && k.models.length > 0
                                        ? k.models[k.models.length - 1]
                                        : null
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Day"
                                      ? k.date_trunc
                                        ? k.date_trunc.split("T")[0]
                                        : null
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Month"
                                      ? k.date_trunc
                                        ? k.date_trunc.split("T")[0]
                                        : null
                                      : null}
                                  </td>
                                ) : (
                                  <td style={{ textAlign: "left" }}>
                                    {k.name ? k.name : null}
                                  </td>
                                )
                              ) : null
                            ) : null}
                            {this.state.columnsData ? (
                              this.state.columnsData[1].is_active &&
                              this.props.pageType === "Flows" ? (
                                this.props.appliedGroupingValues.filter4 ===
                                  "Flows" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Country" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Browser Version" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "OS Version" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Models" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Day" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Month" ? (
                                  <td style={{ textAlign: "left" }}>
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Flows"
                                      ? k.name
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Country"
                                      ? k.country_name
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Browser Version"
                                      ? k.version && k.version.length > 0
                                        ? k.version[k.version.length - 1]
                                        : null
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "OS Version"
                                      ? k.version && k.version.length > 0
                                        ? k.version[k.version.length - 1]
                                        : null
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Models"
                                      ? k.models && k.models.length > 0
                                        ? k.models[k.models.length - 1]
                                        : null
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Day"
                                      ? k.date_trunc
                                        ? k.date_trunc.split("T")[0]
                                        : null
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Month"
                                      ? k.date_trunc
                                        ? k.date_trunc.split("T")[0]
                                        : null
                                      : null}
                                  </td>
                                ) : (
                                  <td style={{ textAlign: "left" }}>
                                    {k.name ? k.name : null}
                                  </td>
                                )
                              ) : null
                            ) : null}
                            {this.state.columnsData ? (
                              this.state.columnsData[1].is_active &&
                              this.props.pageType === "Traffic Source" ? (
                                this.props.appliedGroupingValues.filter4 ===
                                  "Country" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Browser Version" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "OS Version" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Models" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Day" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Month" ? (
                                  <td style={{ textAlign: "left" }}>
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Country"
                                      ? k.country_name
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Browser Version"
                                      ? k.version && k.version.length > 0
                                        ? k.version[k.version.length - 1]
                                        : null
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "OS Version"
                                      ? k.version && k.version.length > 0
                                        ? k.version[k.version.length - 1]
                                        : null
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Models"
                                      ? k.models && k.models.length > 0
                                        ? k.models[k.models.length - 1]
                                        : null
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Day"
                                      ? k.date_trunc
                                        ? k.date_trunc.split("T")[0]
                                        : null
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Month"
                                      ? k.date_trunc
                                        ? k.date_trunc.split("T")[0]
                                        : null
                                      : null}
                                  </td>
                                ) : (
                                  <td style={{ textAlign: "left" }}>
                                    {k.name ? k.name : null}
                                  </td>
                                )
                              ) : null
                            ) : null}
                            {this.state.columnsData ? (
                              this.state.columnsData[1].is_active &&
                              this.props.pageType === "Affiliate Network" ? (
                                this.props.appliedGroupingValues.filter4 ===
                                  "Country" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Browser Version" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "OS Version" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Models" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Day" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Month" ? (
                                  <td style={{ textAlign: "left" }}>
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Country"
                                      ? k.country_name
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Browser Version"
                                      ? k.version && k.version.length > 0
                                        ? k.version[k.version.length - 1]
                                        : null
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "OS Version"
                                      ? k.version && k.version.length > 0
                                        ? k.version[k.version.length - 1]
                                        : null
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Models"
                                      ? k.models && k.models.length > 0
                                        ? k.models[k.models.length - 1]
                                        : null
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Day"
                                      ? k.date_trunc
                                        ? k.date_trunc.split("T")[0]
                                        : null
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Month"
                                      ? k.date_trunc
                                        ? k.date_trunc.split("T")[0]
                                        : null
                                      : null}
                                  </td>
                                ) : (
                                  <td style={{ textAlign: "left" }}>
                                    {k.name ? k.name : null}
                                  </td>
                                )
                              ) : null
                            ) : null}
                            {this.state.columnsData ? (
                              this.state.columnsData[1].is_active &&
                              this.props.pageType !== "Campaign" &&
                              this.props.pageType !== "Offers" &&
                              this.props.pageType !== "Lander" &&
                              this.props.pageType !== "Flows" &&
                              this.props.pageType !== "Affilate Network" &&
                              this.props.pageType !== "Traffic Source" ? (
                                this.props.appliedGroupingValues.filter4 ===
                                  "Country" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Browser Version" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "OS Version" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Models" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Day" ||
                                this.props.appliedGroupingValues.filter4 ===
                                  "Month" ? (
                                  <td style={{ textAlign: "left" }}>
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Country"
                                      ? k.country_name
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Browser Version"
                                      ? k.version && k.version.length > 0
                                        ? k.version[k.version.length - 1]
                                        : null
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "OS Version"
                                      ? k.version && k.version.length > 0
                                        ? k.version[k.version.length - 1]
                                        : null
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Models"
                                      ? k.models && k.models.length > 0
                                        ? k.models[k.models.length - 1]
                                        : null
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Day"
                                      ? k.date_trunc
                                        ? k.date_trunc.split("T")[0]
                                        : null
                                      : null}
                                    {this.props.appliedGroupingValues
                                      .filter4 === "Month"
                                      ? k.date_trunc
                                        ? k.date_trunc.split("T")[0]
                                        : null
                                      : null}
                                  </td>
                                ) : (
                                  <td style={{ textAlign: "left" }}>
                                    {k.name ? k.name : null}
                                  </td>
                                )
                              ) : null
                            ) : null}
                            {this.state.columnsData ? (
                              this.state.columnsData[24].is_active &&
                              this.props.pageType === "Campaign" ? (
                                <td></td>
                              ) : null
                            ) : null}
                            {this.state.columnsData ? (
                              this.state.columnsData[2].is_active ? (
                                this.props.appliedGroupingValues.filter2 ===
                                  "Campaign" ||
                                this.props.appliedGroupingValues.filter2 ===
                                  "Offers" ||
                                this.props.appliedGroupingValues.filter2 ===
                                  "Lander" ||
                                this.props.appliedGroupingValues.filter2 ===
                                  "Flows" ||
                                this.props.appliedGroupingValues.filter2 ===
                                  "Traffic Source" ||
                                this.props.appliedGroupingValues.filter2 ===
                                  "Affiliate Network" ? (
                                  <td></td>
                                ) : null
                              ) : null
                            ) : null}
                            {this.state.columnsData ? (
                              this.state.columnsData[3].is_active ? (
                                <td>{k.visits}</td>
                              ) : null
                            ) : null}
                            {this.state.columnsData ? (
                              this.state.columnsData[4].is_active ? (
                                <td>{k.uniquevisit}</td>
                              ) : null
                            ) : null}
                            {this.state.columnsData ? (
                              this.state.columnsData[5].is_active ? (
                                <td>{k.clicks}</td>
                              ) : null
                            ) : null}
                            {this.state.columnsData ? (
                              this.state.columnsData[23].is_active ? (
                                <td>{k.uniqueclick}</td>
                              ) : null
                            ) : null}
                            {this.state.columnsData ? (
                              this.state.columnsData[6].is_active ? (
                                <td>{k.conversion}</td>
                              ) : null
                            ) : null}
                            {this.state.columnsData ? (
                              this.state.columnsData[22].is_active ? (
                                <td>
                                  {this.props.userCurrency +
                                    (
                                      Math.round(Number(k.cost) * 100) / 100
                                    ).toFixed(2)}
                                </td>
                              ) : null
                            ) : null}
                            {/*{ this.state.columnsData ? (this.state.columnsData[7].is_active ? <td>{k.profit}</td> : null) : null }*/}
                            {this.state.columnsData ? (
                              this.state.columnsData[7].is_active ? (
                                Number(k.profit) < 0 ? (
                                  <td style={{ color: "#d25454" }}>
                                    {this.props.userCurrency +
                                      (
                                        Math.round(Number(k.profit) * 100) / 100
                                      ).toFixed(2)}
                                  </td>
                                ) : Number(k.profit) > 0 ? (
                                  <td style={{ color: "#29af61" }}>
                                    {this.props.userCurrency +
                                      (
                                        Math.round(Number(k.profit) * 100) / 100
                                      ).toFixed(2)}
                                  </td>
                                ) : (
                                  <td>
                                    {this.props.userCurrency +
                                      (
                                        Math.round(Number(k.profit) * 100) / 100
                                      ).toFixed(2)}
                                  </td>
                                )
                              ) : null
                            ) : null}
                            {this.state.columnsData ? (
                              this.state.columnsData[8].is_active ? (
                                <td>
                                  {this.props.userCurrency +
                                    (
                                      Math.round(Number(k.revenue) * 100) / 100
                                    ).toFixed(2)}
                                </td>
                              ) : null
                            ) : null}
                            {this.state.columnsData ? (
                              this.state.columnsData[9].is_active ? (
                                <td>
                                  {this.props.userCurrency +
                                    (
                                      Math.round(Number(k.AP) * 100) / 100
                                    ).toFixed(2)}
                                </td>
                              ) : null
                            ) : null}
                            {this.state.columnsData ? (
                              this.state.columnsData[10].is_active ? (
                                <td>
                                  {this.props.userCurrency +
                                    (
                                      Math.round(Number(k.CPV) * 100) / 100
                                    ).toFixed(2)}
                                </td>
                              ) : null
                            ) : null}
                            {this.state.columnsData ? (
                              this.state.columnsData[11].is_active ? (
                                <td>
                                  {(
                                    Math.round(Number(k.CR) * 100) / 100
                                  ).toFixed(2) + "%"}
                                </td>
                              ) : null
                            ) : null}
                            {this.state.columnsData ? (
                              this.state.columnsData[12].is_active ? (
                                <td>
                                  {(
                                    Math.round(Number(k.CTR) * 100) / 100
                                  ).toFixed(2) + "%"}
                                </td>
                              ) : null
                            ) : null}
                            {this.state.columnsData ? (
                              this.state.columnsData[13].is_active ? (
                                <td>
                                  {(
                                    Math.round(Number(k.CV) * 100) / 100
                                  ).toFixed(2) + "%"}
                                </td>
                              ) : null
                            ) : null}
                            {this.state.columnsData ? (
                              this.state.columnsData[14].is_active ? (
                                <td>
                                  {this.props.userCurrency +
                                    (
                                      Math.round(Number(k.ECPA) * 100) / 100
                                    ).toFixed(2)}
                                </td>
                              ) : null
                            ) : null}
                            {this.state.columnsData ? (
                              this.state.columnsData[15].is_active ? (
                                <td>
                                  {this.props.userCurrency +
                                    (
                                      Math.round(Number(k.ECPC) * 100) / 100
                                    ).toFixed(2)}
                                </td>
                              ) : null
                            ) : null}
                            {this.state.columnsData ? (
                              this.state.columnsData[16].is_active ? (
                                <td>
                                  {this.props.userCurrency +
                                    (
                                      Math.round(Number(k.ECPM) * 100) / 100
                                    ).toFixed(2)}
                                </td>
                              ) : null
                            ) : null}
                            {this.state.columnsData ? (
                              this.state.columnsData[17].is_active ? (
                                <td>
                                  {this.props.userCurrency +
                                    (
                                      Math.round(Number(k.EPC) * 100) / 100
                                    ).toFixed(2)}
                                </td>
                              ) : null
                            ) : null}
                            {this.state.columnsData ? (
                              this.state.columnsData[18].is_active ? (
                                <td>
                                  {this.props.userCurrency +
                                    (
                                      Math.round(Number(k.EPV) * 100) / 100
                                    ).toFixed(2)}
                                </td>
                              ) : null
                            ) : null}
                            {this.state.columnsData ? (
                              this.state.columnsData[19].is_active ? (
                                <td>
                                  {(
                                    Math.round(Number(k.ICTR) * 100) / 100
                                  ).toFixed(2) + "%"}
                                </td>
                              ) : null
                            ) : null}
                            {/*{ this.state.columnsData ? (this.state.columnsData[20].is_active ? <td>{k.ROI + "%"}</td> : null) : null }*/}
                            {this.state.columnsData ? (
                              this.state.columnsData[20].is_active ? (
                                <td
                                  style={
                                    Number(k.ROI) < 0
                                      ? { color: "#d25454" }
                                      : Number(k.ROI) > 0
                                      ? { color: "#29af61" }
                                      : null
                                  }
                                >
                                  {(
                                    Math.round(Number(k.ROI) * 100) / 100
                                  ).toFixed(2) + "%"}
                                </td>
                              ) : null
                            ) : null}
                            {this.state.columnsData ? (
                              this.state.columnsData[21].is_active ? (
                                <td>
                                  {this.props.userCurrency +
                                    (
                                      Math.round(Number(k.RPM) * 100) / 100
                                    ).toFixed(2)}
                                </td>
                              ) : null
                            ) : null}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          {/*<td></td>*/}
                          <td className="grid_sticky_td"></td>
                          <td></td>
                          {this.state.columnsData ? (
                            this.state.columnsData[0].is_active ? (
                              <td></td>
                            ) : null
                          ) : null}
                          {this.state.columnsData ? (
                            this.state.columnsData[1].is_active ? (
                              <td style={{ color: "#F73A5D" }}>
                                No Records.
                              </td>
                            ) : null
                          ) : null}
                          {this.state.columnsData ? (
                            this.state.columnsData[24].is_active &&
                            this.props.pageType === "Campaign" ? (
                              <td></td>
                            ) : null
                          ) : null}
                          {this.state.columnsData ? (
                            this.state.columnsData[2].is_active ? (
                              this.props.appliedGroupingValues.filter2 ===
                                "Campaign" ||
                              this.props.appliedGroupingValues.filter2 ===
                                "Offers" ||
                              this.props.appliedGroupingValues.filter2 ===
                                "Lander" ||
                              this.props.appliedGroupingValues.filter2 ===
                                "Flows" ||
                              this.props.appliedGroupingValues.filter2 ===
                                "Traffic Source" ||
                              this.props.appliedGroupingValues.filter2 ===
                                "Affiliate Network" ? (
                                <td></td>
                              ) : null
                            ) : null
                          ) : null}
                          {this.state.columnsData ? (
                            this.state.columnsData[3].is_active ? (
                              <td></td>
                            ) : null
                          ) : null}
                          {this.state.columnsData ? (
                            this.state.columnsData[4].is_active ? (
                              <td></td>
                            ) : null
                          ) : null}
                          {this.state.columnsData ? (
                            this.state.columnsData[5].is_active ? (
                              <td></td>
                            ) : null
                          ) : null}
                          {this.state.columnsData ? (
                            this.state.columnsData[23].is_active ? (
                              <td></td>
                            ) : null
                          ) : null}
                          {this.state.columnsData ? (
                            this.state.columnsData[6].is_active ? (
                              <td></td>
                            ) : null
                          ) : null}
                          {this.state.columnsData ? (
                            this.state.columnsData[22].is_active ? (
                              <td></td>
                            ) : null
                          ) : null}
                          {this.state.columnsData ? (
                            this.state.columnsData[7].is_active ? (
                              <td></td>
                            ) : null
                          ) : null}
                          {this.state.columnsData ? (
                            this.state.columnsData[8].is_active ? (
                              <td></td>
                            ) : null
                          ) : null}
                          {this.state.columnsData ? (
                            this.state.columnsData[9].is_active ? (
                              <td></td>
                            ) : null
                          ) : null}
                          {this.state.columnsData ? (
                            this.state.columnsData[10].is_active ? (
                              <td></td>
                            ) : null
                          ) : null}
                          {this.state.columnsData ? (
                            this.state.columnsData[11].is_active ? (
                              <td></td>
                            ) : null
                          ) : null}
                          {this.state.columnsData ? (
                            this.state.columnsData[12].is_active ? (
                              <td></td>
                            ) : null
                          ) : null}
                          {this.state.columnsData ? (
                            this.state.columnsData[13].is_active ? (
                              <td></td>
                            ) : null
                          ) : null}
                          {this.state.columnsData ? (
                            this.state.columnsData[14].is_active ? (
                              <td></td>
                            ) : null
                          ) : null}
                          {this.state.columnsData ? (
                            this.state.columnsData[15].is_active ? (
                              <td></td>
                            ) : null
                          ) : null}
                          {this.state.columnsData ? (
                            this.state.columnsData[16].is_active ? (
                              <td></td>
                            ) : null
                          ) : null}
                          {this.state.columnsData ? (
                            this.state.columnsData[17].is_active ? (
                              <td></td>
                            ) : null
                          ) : null}
                          {this.state.columnsData ? (
                            this.state.columnsData[18].is_active ? (
                              <td></td>
                            ) : null
                          ) : null}
                          {this.state.columnsData ? (
                            this.state.columnsData[19].is_active ? (
                              <td></td>
                            ) : null
                          ) : null}
                          {this.state.columnsData ? (
                            this.state.columnsData[20].is_active ? (
                              <td></td>
                            ) : null
                          ) : null}
                          {this.state.columnsData ? (
                            this.state.columnsData[21].is_active ? (
                              <td></td>
                            ) : null
                          ) : null}
                        </tr>
                      )
                    ) : null;
                    return (
                      <>
                        {" "}
                        {secondLevel} {thirdChild}
                      </>
                    );
                  })
                ) : (
                  <tr id="inside_section_background">
                    {/*<td></td>*/}
                    <td className="grid_sticky_td"></td>
                    <td></td>
                    {this.state.columnsData ? (
                      this.state.columnsData[0].is_active ? (
                        <td></td>
                      ) : null
                    ) : null}
                    {this.state.columnsData ? (
                      this.state.columnsData[1].is_active ? (
                        <td style={{ color: "#F73A5D" }}>No more records.</td>
                      ) : null
                    ) : null}
                    {this.state.columnsData ? (
                      this.state.columnsData[24].is_active &&
                      this.props.pageType === "Campaign" ? (
                        <td></td>
                      ) : null
                    ) : null}
                    {this.state.columnsData ? (
                      this.state.columnsData[2].is_active ? (
                        this.props.appliedGroupingValues.filter2 ===
                          "Campaign" ||
                        this.props.appliedGroupingValues.filter2 === "Offers" ||
                        this.props.appliedGroupingValues.filter2 === "Lander" ||
                        this.props.appliedGroupingValues.filter2 === "Flows" ||
                        this.props.appliedGroupingValues.filter2 ===
                          "Traffic Source" ||
                        this.props.appliedGroupingValues.filter2 ===
                          "Affiliate Network" ? (
                          <td></td>
                        ) : null
                      ) : null
                    ) : null}
                    {this.state.columnsData ? (
                      this.state.columnsData[3].is_active ? (
                        <td></td>
                      ) : null
                    ) : null}
                    {this.state.columnsData ? (
                      this.state.columnsData[4].is_active ? (
                        <td></td>
                      ) : null
                    ) : null}
                    {this.state.columnsData ? (
                      this.state.columnsData[5].is_active ? (
                        <td></td>
                      ) : null
                    ) : null}
                    {this.state.columnsData ? (
                      this.state.columnsData[23].is_active ? (
                        <td></td>
                      ) : null
                    ) : null}
                    {this.state.columnsData ? (
                      this.state.columnsData[6].is_active ? (
                        <td></td>
                      ) : null
                    ) : null}
                    {this.state.columnsData ? (
                      this.state.columnsData[22].is_active ? (
                        <td></td>
                      ) : null
                    ) : null}
                    {this.state.columnsData ? (
                      this.state.columnsData[7].is_active ? (
                        <td></td>
                      ) : null
                    ) : null}
                    {this.state.columnsData ? (
                      this.state.columnsData[8].is_active ? (
                        <td></td>
                      ) : null
                    ) : null}
                    {this.state.columnsData ? (
                      this.state.columnsData[9].is_active ? (
                        <td></td>
                      ) : null
                    ) : null}
                    {this.state.columnsData ? (
                      this.state.columnsData[10].is_active ? (
                        <td></td>
                      ) : null
                    ) : null}
                    {this.state.columnsData ? (
                      this.state.columnsData[11].is_active ? (
                        <td></td>
                      ) : null
                    ) : null}
                    {this.state.columnsData ? (
                      this.state.columnsData[12].is_active ? (
                        <td></td>
                      ) : null
                    ) : null}
                    {this.state.columnsData ? (
                      this.state.columnsData[13].is_active ? (
                        <td></td>
                      ) : null
                    ) : null}
                    {this.state.columnsData ? (
                      this.state.columnsData[14].is_active ? (
                        <td></td>
                      ) : null
                    ) : null}
                    {this.state.columnsData ? (
                      this.state.columnsData[15].is_active ? (
                        <td></td>
                      ) : null
                    ) : null}
                    {this.state.columnsData ? (
                      this.state.columnsData[16].is_active ? (
                        <td></td>
                      ) : null
                    ) : null}
                    {this.state.columnsData ? (
                      this.state.columnsData[17].is_active ? (
                        <td></td>
                      ) : null
                    ) : null}
                    {this.state.columnsData ? (
                      this.state.columnsData[18].is_active ? (
                        <td></td>
                      ) : null
                    ) : null}
                    {this.state.columnsData ? (
                      this.state.columnsData[19].is_active ? (
                        <td></td>
                      ) : null
                    ) : null}
                    {this.state.columnsData ? (
                      this.state.columnsData[20].is_active ? (
                        <td></td>
                      ) : null
                    ) : null}
                    {this.state.columnsData ? (
                      this.state.columnsData[21].is_active ? (
                        <td></td>
                      ) : null
                    ) : null}
                  </tr>
                )
              ) : null;
              return (
                <>
                  {" "}
                  {parentRow} {child}{" "}
                </>
              );
            })}
                  <tr>
                    <th style={{height:"65vh",background:"transparent"
                  ,display: this.state.allDataLoaded?.length ? "none" : "table-cell"  
                  }} 
                    colSpan="26"
                    >
                      <div
                        style={{
                        display: !this.state.allDataLoaded?.length
                          ? // ? "flow-root"
                            "flex"
                          : "none",
                        height: "inherit",
                        justifyContent: "center",
                        alignItems: "center",
                        background:"transparent",
                        width:window.innerWidth,
                      }}
                    >
                      <span className="k-icon k-i-search"></span> No results have
                      been found in response
                      </div>
                    </th>
                  
                  </tr>

            <tr className="table_footer_row">
              {/*<th style={{ minWidth: "15px" }}></th>*/}
              <th className="grid_sticky_td" style={{ minWidth: "46px" }}></th>
              {this.props.appliedGroupingValues.filter3.length > 0 ||
              this.props.appliedGroupingValues.filter4.length > 0 ? (
                <th style={{ minWidth: "70px" }}></th>
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[0].is_active ? (
                  <th style={{ minWidth: "100px" }}></th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[1].is_active ? (
                  <th
                    style={{
                      minWidth: "200px",
                      textAlign: "left",
                      paddingLeft: "8px",
                    }}
                  >
                    Total (
                    {this.props.allDataLoaded
                      ? this.props.allDataLoaded.total
                        ? this.props.allDataLoaded.total.totalRows
                        : null
                      : null}
                    )
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[24].is_active &&
                this.props.pageType === "Campaign" ? (
                  <th style={{ minWidth: "100px" }}></th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[2].is_active ? (
                  this.props.appliedGroupingValues.filter2 === "Campaign" ||
                  this.props.appliedGroupingValues.filter2 === "Offers" ||
                  this.props.appliedGroupingValues.filter2 === "Lander" ||
                  this.props.appliedGroupingValues.filter2 === "Flows" ||
                  this.props.appliedGroupingValues.filter2 ===
                    "Traffic Source" ||
                  this.props.appliedGroupingValues.filter2 ===
                    "Affiliate Network" ? (
                    <th style={{ minWidth: "200px" }}></th>
                  ) : null
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[3].is_active ? (
                  <th style={{ minWidth: "100px" }}>
                    {this.props.allDataLoaded
                      ? this.props.allDataLoaded.total
                        ? this.props.allDataLoaded.total.visits
                        : null
                      : null}
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[4].is_active ? (
                  <th style={{ minWidth: "100px" }}>
                    {this.props.allDataLoaded
                      ? this.props.allDataLoaded.total
                        ? this.props.allDataLoaded.total.uniquevisit
                        : null
                      : null}
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[5].is_active ? (
                  <th style={{ minWidth: "100px" }}>
                    {this.props.allDataLoaded
                      ? this.props.allDataLoaded.total
                        ? this.props.allDataLoaded.total.clicks
                        : null
                      : null}
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[23].is_active ? (
                  <th style={{ minWidth: "100px" }}>
                    {this.props.allDataLoaded
                      ? this.props.allDataLoaded.total
                        ? this.props.allDataLoaded.total.uniqueclick
                        : null
                      : null}
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[6].is_active ? (
                  <th style={{ minWidth: "100px" }}>
                    {this.props.allDataLoaded
                      ? this.props.allDataLoaded.total
                        ? this.props.allDataLoaded.total.conversion
                        : null
                      : null}
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[22].is_active ? (
                  <th style={{ minWidth: "100px" }}>
                    {this.props.allDataLoaded
                      ? this.props.allDataLoaded.total
                        ? this.props.userCurrency +
                          (
                            Math.round(
                              Number(this.props.allDataLoaded.total.cost) * 100
                            ) / 100
                          ).toFixed(2)
                        : null
                      : null}
                  </th>
                ) : null
              ) : null}
              {/*{*/}
              {/*    this.state.columnsData ? this.state.columnsData[7].is_active ?*/}
              {/*        <th style={{ minWidth: "100px" }}>{ this.props.allDataLoaded ? this.props.allDataLoaded.total ? this.props.userCurrency + this.props.allDataLoaded.total.profit : null : null}</th> : null*/}
              {/*        : null*/}
              {/*}*/}
              {this.state.columnsData ? (
                this.state.columnsData[7].is_active ? (
                  this.props.allDataLoaded ? (
                    this.props.allDataLoaded.total ? (
                      <th
                        style={
                          Number(this.props.allDataLoaded.total.profit) < 0
                            ? { color: "#f34d4d", minWidth: "100px" }
                            : Number(this.props.allDataLoaded.total.profit) > 0
                            ? { color: "#06e061 ", minWidth: "100px" }
                            : { minWidth: "100px" }
                        }
                      >
                        {this.props.userCurrency +
                          this.props.allDataLoaded.total.profit}
                      </th>
                    ) : null
                  ) : null
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[8].is_active ? (
                  <th style={{ minWidth: "100px" }}>
                    {this.props.allDataLoaded
                      ? this.props.allDataLoaded.total
                        ? this.props.userCurrency +
                          (
                            Math.round(
                              Number(this.props.allDataLoaded.total.revenue) *
                                100
                            ) / 100
                          ).toFixed(2)
                        : null
                      : null}
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[9].is_active ? (
                  <th style={{ minWidth: "100px" }}>
                    {this.props.allDataLoaded
                      ? this.props.allDataLoaded.total
                        ? this.props.userCurrency +
                          (
                            Math.round(
                              Number(this.props.allDataLoaded.total.AP) * 100
                            ) / 100
                          ).toFixed(2)
                        : null
                      : null}
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[10].is_active ? (
                  <th style={{ minWidth: "100px" }}>
                    {this.props.allDataLoaded
                      ? this.props.allDataLoaded.total
                        ? this.props.userCurrency +
                          (
                            Math.round(
                              Number(this.props.allDataLoaded.total.CPV) * 100
                            ) / 100
                          ).toFixed(2)
                        : null
                      : null}
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[11].is_active ? (
                  <th style={{ minWidth: "100px" }}>
                    {this.props.allDataLoaded
                      ? this.props.allDataLoaded.total
                        ? (
                            Math.round(
                              Number(this.props.allDataLoaded.total.CR) * 100
                            ) / 100
                          ).toFixed(2) + "%"
                        : null
                      : null}
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[12].is_active ? (
                  <th style={{ minWidth: "100px" }}>
                    {this.props.allDataLoaded
                      ? this.props.allDataLoaded.total
                        ? (
                            Math.round(
                              Number(this.props.allDataLoaded.total.CTR) * 100
                            ) / 100
                          ).toFixed(2) + "%"
                        : null
                      : null}
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[13].is_active ? (
                  <th style={{ minWidth: "100px" }}>
                    {this.props.allDataLoaded
                      ? this.props.allDataLoaded.total
                        ? (
                            Math.round(
                              Number(this.props.allDataLoaded.total.CV) * 100
                            ) / 100
                          ).toFixed(2) + "%"
                        : null
                      : null}
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[14].is_active ? (
                  <th style={{ minWidth: "100px" }}>
                    {this.props.allDataLoaded
                      ? this.props.allDataLoaded.total
                        ? this.props.userCurrency +
                          (
                            Math.round(
                              Number(this.props.allDataLoaded.total.ECPA) * 100
                            ) / 100
                          ).toFixed(2)
                        : null
                      : null}
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[15].is_active ? (
                  <th style={{ minWidth: "100px" }}>
                    {this.props.allDataLoaded
                      ? this.props.allDataLoaded.total
                        ? this.props.userCurrency +
                          (
                            Math.round(
                              Number(this.props.allDataLoaded.total.ECPC) * 100
                            ) / 100
                          ).toFixed(2)
                        : null
                      : null}
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[16].is_active ? (
                  <th style={{ minWidth: "100px" }}>
                    {this.props.allDataLoaded
                      ? this.props.allDataLoaded.total
                        ? this.props.userCurrency +
                          (
                            Math.round(
                              Number(this.props.allDataLoaded.total.ECPM) * 100
                            ) / 100
                          ).toFixed(2)
                        : null
                      : null}
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[17].is_active ? (
                  <th style={{ minWidth: "100px" }}>
                    {this.props.allDataLoaded
                      ? this.props.allDataLoaded.total
                        ? this.props.userCurrency +
                          (
                            Math.round(
                              Number(this.props.allDataLoaded.total.EPC) * 100
                            ) / 100
                          ).toFixed(2)
                        : null
                      : null}
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[18].is_active ? (
                  <th style={{ minWidth: "100px" }}>
                    {this.props.allDataLoaded
                      ? this.props.allDataLoaded.total
                        ? this.props.userCurrency +
                          (
                            Math.round(
                              Number(this.props.allDataLoaded.total.EPV) * 100
                            ) / 100
                          ).toFixed(2)
                        : null
                      : null}
                  </th>
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[19].is_active ? (
                  <th style={{ minWidth: "100px" }}>
                    {this.props.allDataLoaded
                      ? this.props.allDataLoaded.total
                        ? (
                            Math.round(
                              Number(this.props.allDataLoaded.total.ICTR) * 100
                            ) / 100
                          ).toFixed(2) + "%"
                        : null
                      : null}
                  </th>
                ) : null
              ) : null}
              {/*{*/}
              {/*    this.state.columnsData ? this.state.columnsData[20].is_active ?*/}
              {/*        <th style={{ minWidth: "100px" }}>{ this.props.allDataLoaded ? this.props.allDataLoaded.total ? this.props.allDataLoaded.total.ROI + "%" : null : null}</th> : null*/}
              {/*        : null*/}
              {/*}*/}
              {this.state.columnsData ? (
                this.state.columnsData[20].is_active ? (
                  this.props.allDataLoaded ? (
                    this.props.allDataLoaded.total ? (
                      <th
                        style={
                          Number(this.props.allDataLoaded.total.ROI) < 0
                            ? { color: "#f34d4d", minWidth: "100px" }
                            : Number(this.props.allDataLoaded.total.ROI) > 0
                            ? { color: "#06e061 ", minWidth: "100px" }
                            : { minWidth: "100px" }
                        }
                      >
                        {this.props.userCurrency +
                          (
                            Math.round(
                              Number(this.props.allDataLoaded.total.ROI) * 100
                            ) / 100
                          ).toFixed(2)}
                      </th>
                    ) : null
                  ) : null
                ) : null
              ) : null}
              {this.state.columnsData ? (
                this.state.columnsData[21].is_active ? (
                  <th style={{ minWidth: "100px" }}>
                    {this.props.allDataLoaded
                      ? this.props.allDataLoaded.total
                        ? this.props.userCurrency +
                          (
                            Math.round(
                              Number(this.props.allDataLoaded.total.RPM) * 100
                            ) / 100
                          ).toFixed(2)
                        : null
                      : null}
                  </th>
                ) : null
              ) : null}
            </tr>
          </tbody>
          <tfoot></tfoot>
        </table>
        {this.props.showColumnsModal && (
          <GridColumnsModal
            toggleColumnsModal={this.props.toggleColumnsModal}
            allColumnsChecked={this.state.allColumnsChecked}
            columnsData={this.state.columnsData}
            saveColumnsHandler={this.saveColumnsHandler}
            columnsSelectAllHandler={this.columnsSelectAllHandler}
            columnsCheckBoxChangeHandler={this.columnsCheckBoxChangeHandler}
          />
        )}
      </>
    );
  }
}

export const mapStateToProps = createStructuredSelector({
  reportSubLevelDataLoaded: makeSelectSubLevelReportData(),
  accessToken: makeSelectToken(),
  columnsDataLoaded: makeSelectColumnsData(),
  isColumnsDataPatched: makeSelectIsColumnsDataPatched(),
  userCurrency: makeSelectUserCurrency(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatchGetReportSubLevelData: (...payload) =>
      dispatch(loadSubLevelData(...payload)),
    dispatchGetColumnsData: (...payload) =>
      dispatch(GridActions.getColumnsData(...payload)),
    dispatchPatchColumnsData: (...payload) =>
      dispatch(GridActions.patchColumnsData(...payload)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GridReportComponent);
