import axios from 'axios';
import { BASE_URL } from '../../helpers/path';

export const loadEventLogApi = async (request) => {
    const LOAD_PROFILE_API_ENDPOINT = BASE_URL + 'event?';
    //console.log('request log', request);
    const accessToken = request.accessToken;
    axiosintercepter(accessToken);
    const data = request.data;
    try {
		const response = await axios.get(`${LOAD_PROFILE_API_ENDPOINT}${data}`);
		return response;
	} catch (err) {
		console.log('errors', err);
		return err;
	}
}

export const loadEventLogDetailsApi = async (request) => {
    const UPDATE_PROFILE_API_ENDPOINT = BASE_URL + 'event';
    // console.log('request of update data', request);
    const accessToken = request.accessToken;
    axiosintercepter(accessToken);
    
    try {
		const response = await axios.patch(UPDATE_PROFILE_API_ENDPOINT,request.updateData);
		return response;
	} catch (err) {
		console.log('errors', err);
		return err;
	}
}


function axiosintercepter(accessToken) {
	axios.interceptors.request.use(
		config => {
			config.headers.authorization = `Bearer ${accessToken}`;
		  return config;
		},
		error => {
		  return Promise.reject(error);
		}
	  )
}