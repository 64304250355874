import produce from "immer";

import { MOVE_TO_WORKSPACE, MOVE_TO_WORKSPACE_SUCCESS, MOVE_TO_WORKSPACE_FAILED } from "./constants";

export const initialMoveToWorkspaceState = {
    loading: false,
    success: false,
    error: false,
    movedToWorkspace: false
}

const moveToWorkspaceReducer = (state= initialMoveToWorkspaceState, action) => produce(state, (draft => {
    switch (action.type) {
        case MOVE_TO_WORKSPACE:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.movedToWorkspace = false;
            return draft;
        case MOVE_TO_WORKSPACE_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.movedToWorkspace = true;
            return draft;
        case MOVE_TO_WORKSPACE_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = true;
            draft.movedToWorkspace = false;
            return draft;
        default:
            return draft;
    }
}));

export default moveToWorkspaceReducer;