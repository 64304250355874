import { call, put, takeLatest } from "redux-saga/effects";
import { loadNotificationsSuccess, loadNotificationsFailed, updateNotificationsSuccess, updateNotificationsFailed } from './actions';
import { LOAD_NOTIFICATIONS, UPDATE_NOTIFICATIONS } from './constants';
import { loadNotificationsApi, updateNotificationsApi } from "./notificationsApi";

function* loadNotifications(payload) {
    try {
        const response = yield call(loadNotificationsApi, payload.payload);
        console.log('response of load notifications', response);
        yield put( loadNotificationsSuccess({data:response.data}))
    } catch (error) {
        yield put( loadNotificationsFailed(error));
    }
}

function* updateNotifications(payload) {
    try {
        const response = yield call(updateNotificationsApi, payload.payload);
        yield put( updateNotificationsSuccess({data:response.data}))
    } catch (error) {
        yield put( updateNotificationsFailed(error));
    }
}

export default function* NotificationsSaga() {
    yield takeLatest(LOAD_NOTIFICATIONS, loadNotifications);
    yield takeLatest(UPDATE_NOTIFICATIONS, updateNotifications);
  }
  