import { call, put, takeLatest } from "redux-saga/effects";
import { LOAD_EVENTLOG_DETAILS,LOAD_EVENTLOG } from './constants';

import { loadEventLogDetailSuccess,loadEventLogDetailFailed,loadEventLogSuccess,loadEventLogFailed} from './actions';
import { loadEventLogApi, loadEventLogDetailsApi } from "./api";

function* loadEventLog(payload) {
    try {
		const response = yield call(loadEventLogApi, payload.payload);
		yield put(
			loadEventLogSuccess({ eventlogData: response.data})
		);
	  } catch (error) {
		yield put(loadEventLogFailed(error));
	  }
}

function* loadEventLogDetails(payload) {
    try {
		const response = yield call(loadEventLogDetailsApi, payload.payload);
		//console.log('response update data', response.data);
		yield put(
			loadEventLogDetailSuccess({ eventlogDetailsData: response.data})
		);
	  } catch (error) {
		yield put(loadEventLogDetailFailed(error));
	  }
}

export default function* EventLogSaga() {
    yield takeLatest (LOAD_EVENTLOG, loadEventLog);
    yield takeLatest(LOAD_EVENTLOG_DETAILS, loadEventLogDetails);
}
