import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import { Form,  FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
//import EmailInput from "./../../../components/authentication/emailInput/emailInput";
import {  RadioGroup } from '@progress/kendo-react-inputs';
// import PasswordInput from "./../../../components/authentication/passwordInput/passwordInput";
// import { userNameValidator, passwordValidator, emailValidator,domainNameValidator, emptyValidator } from "../../../validator/validator";
import { connect } from 'react-redux';
import * as loginRegisterActions from '../actions'
import { createStructuredSelector } from 'reselect';
import { makeSelectRegisterUsername, makeSelectRegisterError, makeSelectRegisterSuccess, makeSelectRegisterLoading, makeSelectRegistered } from './../selectors';
import { Fade } from '@progress/kendo-react-animation';
// import { Calendar } from "@progress/kendo-react-dateinputs";
import "./signup.css";
import {formatDate} from "../../../components/searchParameters";
import produce from "immer";
import {makeSelectCountriesListReceived} from "../../../containers/Pages/Campaigns/selectors";
import * as CampaignActions from "../../../containers/Pages/Campaigns/actions";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { CountryDropdown } from 'react-country-region-selector';
import LoadingPanel from "../../../containers/grid/loader/loader";
import Navbar from "../../../components/header/navbar";
import { Helmet } from "react-helmet"

class Signup extends React.Component {
    state = {
        // birthday: new Date(2000, 0, 1),
        // showBirthday: formatDate(new Date(2000, 0, 1)),
        // showCalendar: false,
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        confirmPassword: "",
        accType: "Individual",
        link: "",
        company:"",
        website: "",
        instant_messenger:"Skype",
        instant_messenger_number:"",
        address: "",
        city: "",
        state: "",
        signupCountry: "",
        zipCode: "",
        phone: "",
        error: {
            firstname: null,
            lastname: null,
            email: null,
            password: null,
            confirmPassword: null,
            accType: null,
            link: null,
            company:null,
            instant_messenger_number:null,
            // facebook: null,
            website: null,
            address: null,
            city: null,
            state: null,
            signupCountry: null,
            zipCode: null,
            phone: null,
            signup: null,
            success: null
        },
        acc_Type: [
            {
                label: "Individual",
                value: "Individual",
            },
            {
                label: "Company",
                value: "Company",
            },
        ],
        loader: false
    }

    componentDidMount() {
        //this.props.dispatchLoadCountries({})
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.isRegistered !== this.props.isRegistered) && this.props.isRegistered === true) {
            this.toggleLoader();
            this.setState(produce(draft => { draft.error.success = "Thank you for registering - We will get back to you shortly on your account status!"; }));
            setTimeout(() => {
                this.setState(produce(draft => { draft.error.success = null; }));
            },10000);
        }
        if ((prevProps.error !== this.props.error) && this.props.error !== false) {
            this.toggleLoader();
            this.setState(produce(draft => { draft.error.signup = this.props.error.data ? this.props.error.data.message : null; }));
            setTimeout(() => {
                this.setState(produce(draft => { draft.error.signup = null; }));
            },10000);
        }
        
    }

    toggleLoader = () => {
        this.setState(produce(draft => { draft.loader = !this.state.loader; }));
    }

    handleBirthdayChange = (e) => {
        this.setState({
            birthday: e.value,
            showBirthday: formatDate(e.value)
        });
    }
    toggleCalendar = () => {
        this.setState({ showCalendar: !this.state.showCalendar });
    }
    selectCountry               = (v) => { this.setState({ signupCountry: v }); }
    radioChangeHandler          = (e) => { this.setState(produce(draft => { draft.accType = e.value; })); }

    simpleInputChangeHandler    = (e) => {
        this.setState(produce(draft => {
            draft[e.target.id] = e.target.value;
        }));
        if (e.target.id === "password" && e.target.value.length < 8) {
            this.setState(produce(draft => { draft.error.password = "Password must be a minimum of 8 Characters."; }));
        } else { this.setState(produce(draft => { draft.error.password = null; })); }
    }

    checkRegistrationFormErrors = () => {
        if (this.state.firstname === "") {
            this.setState(produce(draft => { draft.error.firstname = "This field is required"; }));
        } else { this.setState(produce(draft => { draft.error.firstname = null; })); }

        if (this.state.lastname === "") {
            this.setState(produce(draft => { draft.error.lastname = "This field is required"; }));
        } else { this.setState(produce(draft => { draft.error.lastname = null; })); }

        if (this.state.email === "") {
            this.setState(produce(draft => { draft.error.email = "This field is required"; }));
        } else {
            let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

            if (!pattern.test(this.state.email)) {
                this.setState(produce(draft => { draft.error.email = "Please enter valid email address."; }));
            } else { this.setState(produce(draft => { draft.error.email = null; })); }
        }

        if (this.state.password === "") {
            this.setState(produce(draft => { draft.error.password = "This field is required" }));
        } else {
            if (this.state.password.length < 8) {
                this.setState(produce(draft => { draft.error.password = "Password must be a minimum of 8 Characters."; }));
            } else { this.setState(produce(draft => { draft.error.password = null })); }
        }

        if (this.state.confirmPassword === "") {
            this.setState(produce(draft => { draft.error.confirmPassword = "This field is required" }));
        } else {
            if (this.state.confirmPassword !== this.state.password) {
                this.setState(produce(draft => { draft.error.confirmPassword = "Passwords do not match. Please re-enter the passwords."; }));
            } else { this.setState(produce(draft => { draft.error.confirmPassword = null })); }
        }

        if (this.state.accType === "Company") {
            if (this.state.link === "") {
                this.setState(produce(draft => { draft.error.link = "Linkedin or Facebook Profile link is required." }));
            } else { this.setState(produce(draft => { draft.error.link = null })); }
            // if (this.state.facebook === "") {
            //     this.setState(produce(draft => { draft.error.facebook = "Facebook Page link is required." }));
            // } else { this.setState(produce(draft => { draft.error.facebook = null })); }

            if (this.state.website === "") {
                this.setState(produce(draft => { draft.error.website = "Website link is required." }));
            } else { this.setState(produce(draft => { draft.error.website = null })); }
            if (this.state.company === "") {
                this.setState(produce(draft => { draft.error.company = "Company name is required." }));
            } else { this.setState(produce(draft => { draft.error.company = null })); }
        } else {
            this.setState(produce(draft => {
                draft.error.link = null;
                draft.error.company = null;
                draft.error.website = null;
            }));
        }

        if (this.state.city === "" || this.state.country === "" || this.state.state === "" || this.state.country === "" || this.state.zipCode === "") {
            this.setState(produce(draft => { draft.error.address = "You must enter the complete address. It includes Street Address, City, State, Country, and Zip Code/Postal Code." }));
        } else { this.setState(produce(draft => { draft.error.address = null })); }

        if (this.state.phone === "") {
            this.setState(produce(draft => { draft.error.phone = "Phone number is required." }));
        } else { this.setState(produce(draft => { draft.error.phone = null })); }

        if (this.state.instant_messenger_number === "") {
            this.setState(produce(draft => { draft.error.instant_messenger_number = "Messenger number is required." }));
        } else { this.setState(produce(draft => { draft.error.instant_messenger_number = null })); }
        
    }

    handleSubmit = event => {
        this.toggleLoader();
        this.checkRegistrationFormErrors();
        setTimeout(() => {
            if (this.state.error.accType === null && this.state.error.confirmPassword === null && this.state.error.firstname === null && this.state.error.lastname === null && this.state.error.address === null && this.state.error.email === null && this.state.error.company === null && this.state.error.link === null && this.state.error.password === null && this.state.error.phone === null && this.state.error.website === null && this.state.error.instant_messenger_number===null) {
                this.props.signUpUser({
                    firstname: this.state.firstname,
                    lastname: this.state.lastname,
                    email: this.state.email,
                    password: this.state.password,
                    accountType: this.state.accType,
                    link: this.state.link,
                    company:this.state.company,
                    instant_messenger:this.state.instant_messenger,
                    instant_messenger_number:this.state.instant_messenger_number,
                    // facebook: this.state.facebook,
                    website: this.state.website,
                    address: this.state.address,
                    city: this.state.city,
                    region: this.state.state,
                    zipCode: this.state.zipCode,
                    country: this.state.signupCountry,
                    phoneNumber: this.state.phone
                });
            }
            else{
                this.toggleLoader();
            }
        },200);

    }

    render () {
        const radioGroupValidator = (value) => !value ? "Account Type is required" : "";

        // const confirmPasswordValidator = (value) => value && value.length >= 8 ? '' : 'Password must be at least 8 symbols.';
        // console.log("Signup props", this.props);

        return (
            <Fragment>
                <Helmet>
                    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"></meta>
                </Helmet>    
                <div className="page_container">
                    <div className="hide-buttons"><Navbar/></div>
                    {this.state.showCalendar ? <div className="black_bg_signup" onClick={this.toggleCalendar}></div> : null }
                    {this.state.loader && <LoadingPanel/>}
                    <div className="container">
                        <Form
                            // onSubmit={handleSubmit}
                            render={(formRenderProps) => (
                                <FormElement className="register_form_element" style={{background:"#18181b",border:"0px"}}>
                                    <fieldset className={"k-form-fieldset"}>
                                         
                                        <h2 className="login_h2" style={{paddingTop:"40px",borderBottomColor:"hsla(0,0%,100%,.85)"}}>Sign Up</h2>
                                        <div className="signup_grid">
                                            <div className="signup-row">
                                                <div className="mb-2 col">
                                                    <div className="input_group">
                                                        <label htmlFor="firstname">First Name </label>
                                                        <input
                                                            type="text"
                                                            name="firstname"
                                                            id="firstname"
                                                            value={this.state.firstname}
                                                            onChange={this.simpleInputChangeHandler}
                                                        />
                                                    </div>
                                                    {
                                                        this.state.error.firstname !== null ?
                                                            <Fade enter={true} exit={true}>
                                                                <p className="registration_failure_para">{this.state.error.firstname}</p>
                                                            </Fade>
                                                            : null
                                                    }
                                                </div>    
                                            </div>

                                            <div className="signup-row">
                                                <div className="mb-2 col">
                                                    <div className="input_group">
                                                        <label htmlFor="lastname">Last Name </label>
                                                        <input
                                                            type="text"
                                                            name="lastname"
                                                            id="lastname"
                                                            value={this.state.lastname}
                                                            onChange={this.simpleInputChangeHandler}
                                                        />
                                                    </div>
                                                    {
                                                        this.state.error.lastname !== null ?
                                                            <Fade enter={true} exit={true}>
                                                                <p className="registration_failure_para">{this.state.error.lastname}</p>
                                                            </Fade>
                                                            : null
                                                    }
                                                </div>
                                            </div>

                                            <div className="email">
                                                <div className="input_group">
                                                    <label htmlFor="email">E-mail </label>
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        id="email"
                                                        value={this.state.email}
                                                        onChange={this.simpleInputChangeHandler}
                                                    />
                                                </div>
                                                {
                                                    this.state.error.email !== null ?
                                                        <Fade enter={true} exit={true}>
                                                            <p className="registration_failure_para">{this.state.error.email}</p>
                                                        </Fade>
                                                        : null
                                                }
                                            </div>
                                            <div>
                                                <div className="input_group">
                                                    <label htmlFor="password">Password </label>
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        id="password"
                                                        value={this.state.password}
                                                        onChange={this.simpleInputChangeHandler}
                                                    />
                                                </div>
                                                {
                                                    this.state.error.password !== null ?
                                                        <Fade enter={true} exit={true}>
                                                            <p className="registration_failure_para">{this.state.error.password}</p>
                                                        </Fade>
                                                        : null
                                                }
                                            </div>
                                            <div>
                                                <div className="input_group">
                                                    <label htmlFor="confirmPassword">Confirm Password </label>
                                                    <input
                                                        type="password"
                                                        name="confirmPassword"
                                                        id="confirmPassword"
                                                        value={this.state.confirmPassword}
                                                        onChange={this.simpleInputChangeHandler}
                                                    />
                                                </div>
                                                {
                                                    this.state.error.confirmPassword !== null ?
                                                        <Fade enter={true} exit={true}>
                                                            <p className="registration_failure_para">{this.state.error.confirmPassword}</p>
                                                        </Fade>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        {/* <div className="signup_grid" style={{ marginTop: "20px"}}>
                                            <div className="sign_up_birthday_con">
                                                <div className="inner">
                                                    <label className="" htmlFor="birthday">Birthday *</label>
                                                    <div
                                                        className="birthday_show"
                                                        onClick={this.toggleCalendar}
                                                    >{this.state.showBirthday}</div>
                                                </div>
                                                {
                                                    this.state.showCalendar ?
                                                        <div className="birthday_calendar">
                                                            <Calendar
                                                                disabled={false}
                                                                value={this.state.birthday}
                                                                onChange={this.handleBirthdayChange}
                                                            />
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    this.state.error.birthday !== null ?
                                                        <Fade enter={true} exit={true}>
                                                            <p className="registration_failure_para">{this.state.error.birthday}</p>
                                                        </Fade>
                                                        : null
                                                }
                                            </div>
                                        </div> */}
                                        <div className="acc_type_row">
                                            <label className="" htmlFor="acc_type">Select Account Type </label>
                                            <RadioGroup
                                                data={this.state.acc_Type}
                                                defaultValue={this.state.acc_Type[0].value}
                                                valid={radioGroupValidator}
                                                onChange={this.radioChangeHandler}
                                            />
                                        </div>
                                        <div className="signup_grid">
                                            <div className="styled-input acc_type_row">
                                                <div className="input_group" style={{ paddingTop: "0px" }}>
                                                    <label htmlFor="linkedin or facebook">Linkedin or Facebook Url {this.state.accType === "Company" ? "*" : null}</label>
                                                    <input
                                                        type="text"
                                                        name="link"
                                                        id="link"
                                                        value={this.state.link}
                                                        onChange={this.simpleInputChangeHandler}
                                                    />
                                                </div>
                                                {
                                                    this.state.error.link !== null ?
                                                    <Fade enter={true} exit={true}>
                                                        <p className="registration_failure_para">{this.state.error.link}
                                                        </p>
                                                    </Fade>
                                                    : null
                                                }
                                            </div>
                                            {/* <div className="acc_type_row">
                                                <div className="input_group">
                                                    <label htmlFor="facebook">Linkedin / Facebook Url {this.state.accType === "Company" ? "*" : null}</label>
                                                    <input
                                                        type="text"
                                                        name="facebook"
                                                        id="facebook"
                                                        value={this.state.facebook}
                                                        onChange={this.simpleInputChangeHandler}
                                                    />
                                                </div>
                                                {
                                                    this.state.error.facebook !== null ?
                                                        <Fade enter={true} exit={true}>
                                                            <p className="registration_failure_para">{this.state.error.facebook}</p>
                                                        </Fade>
                                                        : null
                                                }
                                            </div> */}
                                            <div className="acc_type_row">
                                                <div className="input_group" style={{ paddingTop: "0px" }}>
                                                    <label htmlFor="website">Website Link {this.state.accType === "Company" ? "*" : null}</label>
                                                    <input
                                                        type="text"
                                                        name="website"
                                                        id="website"
                                                        value={this.state.website}
                                                        onChange={this.simpleInputChangeHandler}
                                                    />
                                                </div>
                                                {
                                                    this.state.error.website !== null ?
                                                        <Fade enter={true} exit={true}>
                                                            <p className="registration_failure_para">{this.state.error.website}</p>
                                                        </Fade>
                                                        : null
                                                }
                                            </div>
                                            {this.state.accType === "Company" && 
                                                <div className="acc_type_row">
                                                    <div className="input_group" style={{ paddingTop: "0px" }}>
                                                        <label htmlFor="company">Company Name </label>
                                                        <input
                                                            type="text"
                                                            name="company"
                                                            id="company"
                                                            value={this.state.company}
                                                            onChange={this.simpleInputChangeHandler}
                                                        />
                                                    </div>
                                                    {
                                                        this.state.error.company !== null ?
                                                            <Fade enter={true} exit={true}>
                                                                <p className="registration_failure_para">{this.state.error.company}</p>
                                                            </Fade>
                                                            : null
                                                    }
                                                </div>
                                            }
                                            <div className="acc_type_row">
                                                <div className="input_group" style={{ paddingTop: "0px" }}>
                                                    <label htmlFor="website">Instant messenger </label>
                                                    <div className="instant-messenger">
                                                        <select className="instant-messenger-select" style={{textAlign:"center",background:"#212124"}} name="instant_messenger" id="instant_messenger" onChange={this.simpleInputChangeHandler} value={this.state.instant_messenger}>
                                                            <option value="Skype">Skype</option>
                                                            <option value="Telegram">Telegram</option>
                                                            <option value="Whatsapp">Whatsapp</option>
                                                        </select>
                                                        <input
                                                            type="text"
                                                            name="instant_messenger_number"
                                                            id="instant_messenger_number"
                                                            value={this.state.instant_messenger_number}
                                                            onChange={this.simpleInputChangeHandler}
                                                        />
                                                    </div>
                                                </div>
                                                {
                                                    this.state.error.instant_messenger_number !== null ?
                                                        <Fade enter={true} exit={true}>
                                                            <p className="registration_failure_para">{this.state.error.instant_messenger_number}</p>
                                                        </Fade>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        <div className="acc_type_row">
                                            {/* <label className="signup_label" htmlFor="address">Address</label> */}
                                            <div className="address_con">
                                                <div className="input_group">
                                                    <label htmlFor="address">Street Address </label>
                                                    <input
                                                        type="text"
                                                        name="address"
                                                        id="address"
                                                        value={this.state.address}
                                                        onChange={this.simpleInputChangeHandler}
                                                    />
                                                </div>
                                                <div className="input_group">
                                                    <label htmlFor="city">City </label>
                                                    <input
                                                        type="text"
                                                        name="city"
                                                        id="city"
                                                        value={this.state.city}
                                                        onChange={this.simpleInputChangeHandler}
                                                    />
                                                </div>
                                                <div className="input_group">
                                                    <label htmlFor="state">Region / State / Province </label>
                                                    <input
                                                        type="text"
                                                        name="state"
                                                        id="state"
                                                        value={this.state.state}
                                                        onChange={this.simpleInputChangeHandler}
                                                    />
                                                </div>
                                                <div className="input_group">
                                                    <label htmlFor="signupCountry">Select Country </label>
                                                    <CountryDropdown
                                                        className="country_dropdown"
                                                        id="signupCountry"
                                                        name="signupCountry"
                                                        value={this.state.signupCountry}
                                                        onChange={this.selectCountry}
                                                    />
                                                </div>
                                                <div className="input_group">
                                                    <label htmlFor="zipCode">Zip Code / Postal Code </label>
                                                    <input
                                                        type="text"
                                                        name="zipCode"
                                                        id="zipCode"
                                                        value={this.state.zipCode}
                                                        onChange={this.simpleInputChangeHandler}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                this.state.error.address !== null ?
                                                    <Fade enter={true} exit={true}>
                                                        <p className="registration_failure_para">{this.state.error.address}</p>
                                                    </Fade>
                                                    : null
                                            }
                                        </div>
                                        <div className="acc_type_row">
                                            <div className="address_con">
                                                <div className="input_group_phone">
                                                    <label htmlFor="phone">Phone Number </label>
                                                    <PhoneInput
                                                        country={'ca'}
                                                        value={this.state.phone}
                                                        onChange={phone => this.setState({ phone: phone })}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                this.state.error.phone !== null ?
                                                    <Fade enter={true} exit={true}>
                                                        <p className="registration_failure_para">{this.state.error.phone}</p>
                                                    </Fade>
                                                    : null
                                            }
                                        </div>
                                    </fieldset>
                                    <div className="k-mt-5">
                                        <Button
                                            className="half_width register-btn"
                                            type={"submit"}
                                            // disabled={!formRenderProps.allowSubmit}
                                            onClick={this.handleSubmit}
                                        >
                                            Sign Up
                                        </Button>
                                        <div>
                                            {
                                                this.state.error.success !== null ?
                                                    <Fade enter={true} exit={true}>
                                                        <p className="registration_success_para">{this.state.error.success}</p>
                                                    </Fade>
                                                    : null
                                            }
                                            {
                                                    this.state.error.signup !== null ?
                                                        <Fade enter={true} exit={true}>
                                                            <p className="registration_failure_para">{this.state.error.signup}</p>
                                                        </Fade>
                                                        : null
                                            }
                                        </div>
                                    </div>
                                    
                                    <Link to="/login"  href="/login" className="login_links"> Log In</Link>
                                    {
                                    //<a href="/login" className="login_links"> Log In</a>
                                    }
                                </FormElement>
                            )}
                            />
                    </div>
                    <div className="homepage">
                <footer id="contact-us">


                        <div className="copy"><div>Copyright © 2023 Tellmobi. All rights reserved.</div><a href="https://nmfmedia.com/" target="_blank"> Part of NMF Media Ltd.</a></div>
                        

                </footer>
                    </div>
                </div>

            </Fragment>
        );
    }
};

export const mapStateToProps = createStructuredSelector(
  {
      error: makeSelectRegisterError(),
      loading: makeSelectRegisterLoading(),
      success: makeSelectRegisterSuccess(),
      username: makeSelectRegisterUsername(),
      isRegistered: makeSelectRegistered(),
      countriesListReceived: makeSelectCountriesListReceived(),
  });
export function mapDispatchToProps(dispatch) {
  return {
      signUpUser: (...payload) => dispatch(loginRegisterActions.signUpUser(...payload)),
      dispatchLoadCountries: (...payload) => dispatch(CampaignActions.loadCountry(...payload)),
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(Signup);