import { createSelector } from "reselect";
import { initialCampaignsState } from "./reducer";

const selectCampaignsState= (state) =>  state.Campaigns || initialCampaignsState;

const makeSelectLoading = () => createSelector(selectCampaignsState, (subState) => subState.loading);
const makeSelectSuccess = () => createSelector(selectCampaignsState, (subState) => subState.success);
const makeSelectError = () => createSelector(selectCampaignsState, (subState) => subState.error);
const makeSelectCountriesListReceived = () => createSelector(selectCampaignsState, (subState) => subState.countriesListReceived);

const makeSelectTokenTagsListReceived = () => createSelector(selectCampaignsState, (subState) => subState.tokenTagsListReceived);
const makeSelectSearchTagsListReceived = () => createSelector(selectCampaignsState, (subState) => subState.searchTagsListReceived);
const makeSelectIsTagsListReceived = () => createSelector(selectCampaignsState, (subState) => subState.isTokenTagsListReceived);
const makeSelectIsSearchTagsListReceived = () => createSelector(selectCampaignsState, (subState) => subState.isSearchTagsListReceived);

const makeSelectIsNewCampaignDataSent = () => createSelector(selectCampaignsState, (subState) => subState.isNewCampaignDataSent);
const makeSelectNewCampaignSuccessResponse = () => createSelector(selectCampaignsState, (subState) => subState.newCampaignSuccessResponse);
const makeSelectIsAllCampaignsLoaded = () => createSelector(selectCampaignsState, (subState) => subState.isAllCampaignsLoaded);
const makeSelectIsPatchedCampaignDataSent = () => createSelector(selectCampaignsState, (subState) => subState.isPatchedCampaignDataSent);
const makeSelectPatchedCampaignSuccessResponse = () => createSelector(selectCampaignsState, (subState) => subState.patchedCampaignSuccessResponse);
const makeSelectIsDeleteCampaignDataSent = () => createSelector(selectCampaignsState, (subState) => subState.isDeleteCampaignDataSent);
const makeSelectIsArchiveCampaignDataSent = () => createSelector(selectCampaignsState, (subState) => subState.isArchiveCampaignDataSent);

const makeSelectAllCampaignsDataLoaded = () => createSelector(selectCampaignsState, (subState) => subState.allCampaignsDataLoaded);
const makeSelectEditCampaignDataReceived = () => createSelector(selectCampaignsState, (subState) => subState.editCampaignDataReceived);


const makeSelectIsChartsDataLoaded = () => createSelector(selectCampaignsState, (subState) => subState.charts.isChartsDataLoaded);
const makeSelectAllChartsDataLoaded = () => createSelector(selectCampaignsState, (subState) => subState.charts.allChartsDataLoaded);

const makeSelectTimeZonesDataLoaded = () => createSelector(selectCampaignsState, (subState) => subState.timeZonesData);
const makeSelectManualCostUpdateSuccess = () => createSelector(selectCampaignsState, (subState) => subState.manualCostUpdateSuccess);
const makeSelectManualCostCurrencyList = () => createSelector(selectCampaignsState, (subState) => subState.manualCostCurrencyList);
const makeSelectArchiveDeleteResponse = () => createSelector(selectCampaignsState, (subState) => subState.archiveDeleteResponse);


export {
    makeSelectLoading,
    makeSelectSuccess,
    makeSelectError,

    makeSelectCountriesListReceived,
    makeSelectTokenTagsListReceived,
    makeSelectSearchTagsListReceived,
    makeSelectIsTagsListReceived,
    makeSelectIsSearchTagsListReceived,

    makeSelectIsNewCampaignDataSent,
    makeSelectNewCampaignSuccessResponse,
    makeSelectIsAllCampaignsLoaded,
    makeSelectIsPatchedCampaignDataSent,
    makeSelectPatchedCampaignSuccessResponse,
    makeSelectIsDeleteCampaignDataSent,
    makeSelectIsArchiveCampaignDataSent,

    makeSelectAllCampaignsDataLoaded,
    makeSelectEditCampaignDataReceived,

    makeSelectIsChartsDataLoaded,
    makeSelectAllChartsDataLoaded,
    makeSelectTimeZonesDataLoaded,
    makeSelectManualCostUpdateSuccess,
    makeSelectManualCostCurrencyList,

    makeSelectArchiveDeleteResponse
}