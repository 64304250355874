export const LOAD_CURRENCY = "LOAD_CURRENCY";
export const LOAD_CURRENCY_SUCCESS = "LOAD_CURRENCY_SUCCESS";
export const LOAD_CURRENCY_FAILED = "LOAD_CURRENCY_FAILED";

export const POST_TRAFFIC_SOURCE_DATA = "POST_TRAFFIC_SOURCE_DATA";
export const POST_TRAFFIC_SOURCE_DATA_SUCCESS = "POST_TRAFFIC_SOURCE_DATA_SUCCESS";
export const POST_TRAFFIC_SOURCE_DATA_FAILED = "POST_TRAFFIC_SOURCE_DATA_FAILED";

export const LOAD_TRAFFIC_SOURCES_DATA = "LOAD_TRAFFIC_SOURCES_DATA";
export const LOAD_TRAFFIC_SOURCES_DATA_SUCCESS = "LOAD_TRAFFIC_SOURCES_DATA_SUCCESS";
export const LOAD_TRAFFIC_SOURCES_DATA_FAILED = "LOAD_TRAFFIC_SOURCES_DATA_FAILED";

export const LOAD_EDIT_TRAFFIC_SOURCE_DATA = "LOAD_EDIT_TRAFFIC_SOURCE_DATA";
export const LOAD_EDIT_TRAFFIC_SOURCE_DATA_SUCCESS = "LOAD_EDIT_TRAFFIC_SOURCE_DATA_SUCCESS";
export const LOAD_EDIT_TRAFFIC_SOURCE_DATA_FAILED = "LOAD_EDIT_TRAFFIC_SOURCE_DATA_FAILED";


export const SEND_EDIT_TRAFFIC_SOURCE_DATA = "SEND_EDIT_TRAFFIC_SOURCE_DATA";
export const SEND_EDIT_TRAFFIC_SOURCE_DATA_SUCCESS = "SEND_EDIT_TRAFFIC_SOURCE_DATA_SUCCESS";
export const SEND_EDIT_TRAFFIC_SOURCE_DATA_FAILED = "SEND_EDIT_TRAFFIC_SOURCE_DATA_FAILED";

export const DELETE_TRAFFIC_SOURCE_DATA = "DELETE_TRAFFIC_SOURCE_DATA";
export const DELETE_TRAFFIC_SOURCE_DATA_SUCCESS = "DELETE_TRAFFIC_SOURCE_DATA_SUCCESS";
export const DELETE_TRAFFIC_SOURCE_DATA_FAILED = "DELETE_TRAFFIC_SOURCE_DATA_FAILED";

export const ARCHIVE_TRAFFIC_SOURCE_DATA = "ARCHIVE_TRAFFIC_SOURCE_DATA";
export const ARCHIVE_TRAFFIC_SOURCE_DATA_SUCCESS = "ARCHIVE_TRAFFIC_SOURCE_DATA_SUCCESS";
export const ARCHIVE_TRAFFIC_SOURCE_DATA_FAILED = "ARCHIVE_TRAFFIC_SOURCE_DATA_FAILED";

export const LOAD_TOKEN_TAGS = "LOAD_TOKEN_TAGS";
export const LOAD_TOKEN_TAGS_SUCCESS = "LOAD_TOKEN_TAGS_SUCCESS";
export const LOAD_TOKEN_TAGS_FAILED = "LOAD_TOKEN_TAGS_FAILED";

export const LOAD_CUSTOM_TRAFFIC_SOURCE = "LOAD_CUSTOM_TRAFFIC_SOURCE";
export const LOAD_CUSTOM_TRAFFIC_SOURCE_SUCCESS = "LOAD_CUSTOM_TRAFFIC_SOURCE_SUCCESS";
export const LOAD_CUSTOM_TRAFFIC_SOURCE_FAILED = "LOAD_CUSTOM_TRAFFIC_SOURCE_FAILED";

export const LOAD_CUSTOM_TRAFFIC_SOURCE_BY_ID = "LOAD_CUSTOM_TRAFFIC_SOURCE_BY_ID";
export const LOAD_CUSTOM_TRAFFIC_SOURCE_BY_ID_SUCCESS = "LOAD_CUSTOM_TRAFFIC_SOURCE_BY_ID_SUCCESS";
export const LOAD_CUSTOM_TRAFFIC_SOURCE_BY_ID_FAILED = "LOAD_CUSTOM_TRAFFIC_SOURCE_BY_ID_FAILED";

export const RESET_CUSTOM_TRAFFIC_SOURCE_DATA_BY_ID = "RESET_CUSTOM_TRAFFIC_SOURCE_DATA_BY_ID";
export const RESET_EDIT_TRAFFIC_SOURCE_DATA = "RESET_EDIT_TRAFFIC_SOURCE_DATA";

export const GET_TS_CHARTS_DATA = "GET_TS_CHARTS_DATA";
export const GET_TS_CHARTS_DATA_SUCCESS = "GET_TS_CHARTS_DATA_SUCCESS";
export const GET_TS_CHARTS_DATA_FAILED = "GET_TS_CHARTS_DATA_FAILED";