import React from "react";
import ReactTooltip from 'react-tooltip';
const Tooltip = (props) => {
    return (
        <div>
            <span data-tip data-for={ props.datafor} className="question_mark">
                <span className="k-icon k-i-question-circle"></span>
            </span>
            <ReactTooltip id={props.datafor} type="dark">
                <span>{props.text}</span>
            </ReactTooltip>
        </div>
    );
};
export default Tooltip;
