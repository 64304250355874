import  axios  from 'axios';
import { BASE_URL } from '../../containers/helpers/path';

export const registerUserService = async (request) => {
	// console.log("request: ", request);
	const REGISTER_API_ENDPOINT = BASE_URL + 'account/sign-up';
	const data = {
		"firstname": request.payload.firstname,
		"lastname": request.payload.lastname,
	  	"email": request.payload.email,
		"password": request.payload.password,
		// "dob": request.payload.dob,
		"accountType": request.payload.accountType,
		"link": request.payload.link,
		"company": request.payload.company,
		"website": request.payload.website,
		"instant_messenger":request.payload.instant_messenger,
		"instant_messenger_number":request.payload.instant_messenger_number,
		"address": request.payload.address,
		"city": request.payload.city,
		"region": request.payload.region,
		"zipCode": request.payload.zipCode,
		"country": request.payload.country,
		"phoneNumber": request.payload.phoneNumber,
	  }

	try {
		const response = axios.post(REGISTER_API_ENDPOINT, data);
		  return response;
	  } catch (err) {
		  return err;
	  }
  };
  
export const loginUserService = async (request) => {
	  const LOGIN_API_ENDPOINT = BASE_URL + 'account/sign-in';
	  const data = {
	  	  "email": request.payload.email,
		  "password": request.payload.password,
	  }

	  const response = await axios.post(LOGIN_API_ENDPOINT, data);
	  return response;
};

export const handleLoginSuccess = (response) => {
}
  

export const forgetUserService = (request) => {
	const FORGOT_PASSWORD_API_ENDPOINT = BASE_URL + 'account/forgot-password';
	const data = {
		"email": request.payload,
	}
  try {
		const response = axios.post(FORGOT_PASSWORD_API_ENDPOINT, data);
		return response;
	} catch (err) {
		console.log('errors', err);
		return err;
	}
};