import React, { Component } from "react";
import produce from "immer";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import ModalForm from "./ModalForm";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { makeSelectDomainsDataReceived } from "../../../Settings/Domains/selectors";
import { loadCustomDomains } from "../../../Settings/Domains/actions";
import { makeSelectToken } from "../../../../registration/LoginRegister/selectors";
import { makeSelectTrafficSourcesDataLoaded } from "../../TrafficSources/selectors";
import { loadTrafficSources } from "../../TrafficSources/actions";
import { makeSelectAllLandersDataLoaded } from "../../Landers/selectors";
import { loadAllLanders } from "../../Landers/actions";
import { loadOffers } from "../../Offers/actions";
import { makeSelectOffersDataLoaded } from "../../Offers/selectors";
import {
  makeSelectLoading,
  makeSelectSuccess,
  makeSelectError,
  makeSelectTokenTagsListReceived,
  makeSelectIsTagsListReceived,
  makeSelectCountriesListReceived,
  makeSelectNewCampaignSuccessResponse,
  makeSelectEditCampaignDataReceived,
  makeSelectPatchedCampaignSuccessResponse,
  makeSelectIsPatchedCampaignDataSent,
  makeSelectIsNewCampaignDataSent,
} from "../selectors";
import * as CampaignActions from "../actions";
import * as newFlowsActions from "../../NewFlows/actions";
import {
  makeSelectLoadFlowsData,
  makeSelectBrandData,
  makeSelectBrowserData,
  makeSelectCityData,
  makeSelectConnectionTypeData,
  makeSelectDeviceData,
  makeSelectLanguageData,
  makeSelectOperatingSystemData,
  makeSelectProxyData,
  makeSelectRegionData,
  makeSelectISPData,
  makeSelectConditionCountryData,
} from "../../NewFlows/selectors";
import { filterBy } from "@progress/kendo-data-query";
// import LoadingPanel from "../../../grid/loader/loader";

class EditAdvancedCampaignModal extends Component {
  state = {
    dataToSend: {
      name: "",
      workspaceId: "",
      countryId: "",
      trafficSourceId: "",
      campaign_destination: "Path",
      path_destination: "Lander & Offer",
      redirect_mode: "302",
      cost_model: "Not Tracked",
      cost_value: "1",
      tsPostbackUrl: "Default",
      campaignMode: "Redirect",
      tsPostbackEventType: null,
      tsPixelRedirect: "Default",
      is_advance: true,
      tags: null,
      simpleOfferId: [],
      simpleLanderId: [],
      path: {
        create_flow: false,
        name: "",
        is_default: [
          {
            path_name: "New Path",
            weight: "100",
            value: "100",
            weight_optimization: true,
            offerId: [],
            landerId: [],
            offerWeight: [],
            landerWeight: [],
            offerValue: [],
            landerValue: [],
            path_redirect_mode: "302",
            path_destination: "Lander & Offer",
          },
        ],
        rule: [],
      },
      flowId: "",
      notes_app: "",
      notes: null,
    },
    domainId: null,
    loadedWorkspaces: null,
    loadedDomains: null,
    tagsListReceived: null,
    loadedCountries: null,
    loadedTrafficSources: null,
    countryName: "",
    domainName: "",
    trafficSourceName: "",
    resolveErrors: "",
    allLandersDataLoaded: null,
    allOffersDataLoaded: null,
    allFlowsDataLoaded: null,
    selectedFlowId: "",
    landersSelected: [],
    offersSelected: [],
    flowsSelected: [],
    isCampaignPatched: false,
    isCampaignCreated: false,
    isShowDestinationTab: false,
    isShowGeneralTab: true,
    isActiveTab:"general",
    isCampaignCreatedSuccessfully: false,
    isCampaignPatchedSuccessfully: false,
    isCampaignLoadedSuccessfully: true,
    isShowTrackingTab: false,
    campaign_url_generated: null,
    errors: {
      name: null,
      rule_name: null,
      workspaceId: null,
      trafficSourceId: null,
      landers: null,
      offers: null,
      flow: null,
      default_path_lander: null,
      default_path_offer: null,
      rule_path_lander: null,
      rule_path_offer: null,
      default_path_name: null,
      rule_path_name: null,
      isLanderExist: false,
      isOfferExist: false,
    },
    data: null,
    filterData: "",
    panes: [{ size: "50%", min: "45%", resizable: false }, {}],
    count: 0,
    onRuleClick: false,
    onFlowClick: false,
    onPathClick: true,
    onDefaultPathClick: true,
    newRuleIndex: null,
    newPathIndex: 0,
    newDefaultPathIndex: 0,
    newRulePathIndex: 0,
    offersCounter: [1],
    landersCounter: 1,
    offersData: [],
    defaultPathClickedCount: 0,
    countryValue: null,
    editClicked: false,
    newFlows: false,
    success_msg: "",
    warning_msg: "",
    general_tab: true,
    landerValue: [],
    offerValue: [],
    select: "",
    editSelect: "",
    conditionsName: [
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
    ],
    filteredOptions: [
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
    ],
    selectedOptions: [
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
    ],
    options: [
      { value: "", name: "Select options" },
      { value: "brand", name: "Brand" },
      { value: "browser", name: "Browser" },
      { value: "city", name: "City" },
      { value: "connection", name: "Connection type" },
      { value: "country", name: "Country" },
      { value: "device", name: "Device type" },
      { value: "isp", name: "ISP" },
      { value: "IP", name: "IP" },
      { value: "language", name: "Language" },
      { value: "Mobile carrier", name: "Mobile carrier" },
      { value: "operating", name: "Operating system" },
      { value: "proxy", name: "Proxy" },
      { value: "region", name: "Region" },
      { value: "Referrer", name: "Referrer" },
      { value: "Referrer domain", name: "Referrer Domain" },
      { value: "timezone", name: "Time of day" },
      { value: "weekday", name: "Weekday" },
      { value: "Custom variable 1", name: "Custom variable 1" },
      { value: "Custom variable 2", name: "Custom variable 2" },
      { value: "Custom variable 3", name: "Custom variable 3" },
      { value: "Custom variable 4", name: "Custom variable 4" },
      { value: "Custom variable 5", name: "Custom variable 5" },
      { value: "Custom variable 6", name: "Custom variable 6" },
      { value: "Custom variable 7", name: "Custom variable 7" },
      { value: "Custom variable 8", name: "Custom variable 8" },
      { value: "Custom variable 9", name: "Custom variable 9" },
      { value: "Custom variable 10", name: "Custom variable 10" },
      { value: "Custom variable 11", name: "Custom variable 11" },
      { value: "Custom variable 12", name: "Custom variable 12" },
      { value: "Custom variable 13", name: "Custom variable 13" },
      { value: "Custom variable 14", name: "Custom variable 14" },
      { value: "Custom variable 15", name: "Custom variable 15" },
      { value: "Custom variable 16", name: "Custom variable 16" },
      { value: "Custom variable 17", name: "Custom variable 17" },
      { value: "Custom variable 18", name: "Custom variable 18" },
      { value: "Custom variable 19", name: "Custom variable 19" },
      { value: "Custom variable 20", name: "Custom variable 20" },
      // { value: "IP", name: "IP" },
    ],
    is_default: [
      {
        dataToMap: {
          landers: [],
          offers: [],
        },
        counts: {
          landerInBackend: 0,
          landersAdded: 0,
          offersInBackend: 0,
          offersAdded: 0,
          landerWeightageTotal: 0,
          offerWeightageTotal: 0,
        },
        allLandersDataLoaded: null,
        allOffersDataLoaded: null,
      },
    ],
    rule: [],
    loadRuleFirstTime: true,
    loadDefaultFirstTime: true,
    isEditCampaignDataAdded: false,
    search: {
      tagsListReceived: [],
      inputSearchValue: "",
      tagsSearchValue: [],
      isTagsSearchSelected: false,
      groupingValues: {
        main: "",
        group1: "",
        group2: "",
      },
      dates: {
        startDate: "",
        endDate: "",
      },
      isFiltersChanged: false,
    },
    // rule: [],
    // loadRuleFirstTime: true,
    // loadDefaultFirstTime: true,
    defaultWeightageData: {
      pathAddedCount: 1,
      pathsTotalWeightage: 100,
    },
    ruleWeightageData: [],
    // rule: [],
    // loadRuleFirstTime: true,
    // loadDefaultFirstTime: true,
    bgClrPathIndex: null,
    bgClrRulePathIndex: null,
    bgClrRuleIndex: null,
    isDefaultPathsDataPopulated: false,
    isRulePathsDataPopulated: false,
    defaultPathCounter: 1,
    rulePathCounter: 0,
    landersPopulated: false,
    offersPopulated: false,
    loadFirstTimeLanders: false,
    loadFirstTimeOffers: false,
    allLandersAndOffersLoadedInitially: false,
    allRulePathLandersAndOffersLoadedInitially: false,
    defaultPathDataCompleted: false,
    editAdvanceCampaignDataLoaded: false,
    sorting: {
      order: "id",
      sort: "ASC",
    },
    saveBtnDisabled: false,
    // loader:false,
    deleted: false,
    ruleConditionName: [
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
    ],
    ruleNameWithCondition: "",
    from: [
      "00",
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
    ],
    to: [
      "00",
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      38,
      39,
      40,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      58,
      59,
    ],
    tempIP: "",
    tempreferrer: "",
    tempreferrerDomain: "",
    fromh: "",
    fromm: "",
    toh: "",
    tom: "",
    addedConditions: false,
    editAdvanceCampaign: true,
    allRequestsLoaded: false,
    initialLandersData: [],
    initialOffersData: [],
    loadLO: false,
    loadRLO: false,
  };
  fetchDomainsAndWorkspaces = async () => {
    return this.props.dispatchLoadDomainsAndWorkspaces(this.props.accessToken);
  };
  // fetchTrafficSources = async () => { return this.props.dispatchLoadTrafficSources(this.props.accessToken); };
  fetchTrafficSources = async () => {
    return this.props.dispatchLoadTrafficSources({
      accessToken: this.props.accessToken,
      searchQuery: JSON.parse(localStorage.getItem("search")),
      sorting: { order: "id", sort: "ASC" },
      primaryPage: false,
      dataType: "short",
    });
  };
  fetchTokenTags = async () => {
    return this.props.dispatchLoadTags({ accessToken: this.props.accessToken });
  };
  fetchCountries = async () => {
    return this.props.dispatchLoadCountries({
      accessToken: this.props.accessToken,
    });
  };
  fetchAllLanders = async () => {
    return this.props.dispatchLoadAllLanders({
      accessToken: this.props.accessToken,
      searchQuery: JSON.parse(localStorage.getItem("search")),
      sorting: { order: "name", sort: "DESC" },
      primaryPage: false,
      dataType: "short",
    });
  };
  // fetchAllOffers = async () => { return this.props.dispatchLoadAllOffers(this.props.accessToken,this.state.search); }

  fetchAllOffers = async () => {
    return this.props.dispatchLoadAllOffers(
      this.props.accessToken,
      JSON.parse(localStorage.getItem("search")),
      { order: "name", sort: "DESC" },
      false,
      "short"
    );
  };
  // fetchAllFlows = async () => { return this.props.dispatchLoadFlows(this.props.accessToken,this.state.search); }
  fetchAllFlows = async () => {
    return this.props.dispatchLoadFlows(
      this.props.accessToken,
      JSON.parse(localStorage.getItem("search")),
      { order: "name", sort: "DESC" },
      false,
      "short"
    );
  };
  fetchEditCampaignData = async () => {
    return this.props.dispatchLoadEditCampaign({
      accessToken: this.props.accessToken,
      loadEditCampaignId: this.props.campaignId,
    });
  };

  componentDidMount() {
    this.setState(
      produce((draft) => {
        draft.editAdvanceCampaignDataLoaded = true;
      })
    );

    // this.fetchAllFlows();
    // this.fetchAllLanders();
    // this.fetchAllOffers();
    // this.fetchTrafficSources();
    // this.fetchCountries();
    // this.fetchTokenTags();

    // fetching and workspaces initially
    if (
      this.state.loadedWorkspaces === null ||
      this.state.loadedDomains === null
    ) {
      this.fetchDomainsAndWorkspaces();
    } else {
      this.setState(
        produce((draft) => {
          draft.dataToSend.workspaceId = String(
            this.state.loadedWorkspaces[0].id
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.dataToSend.domainId = String(this.state.loadedDomains[0].id);
        })
      );
    }

    // if (this.state.tagsListReceived === null) {this.fetchTokenTags();}
    // if (this.state.loadedCountries === null) {this.fetchCountries(); }

    // if(this.props.countriesListReceived === null || this.props.countriesListReceived === undefined){
    //     this.fetchCountries();
    // }
    // if (this.state.loadedTrafficSources === null) {this.fetchTrafficSources();}
    // if(this.props.trafficSourcesDataReceived === null || this.props.trafficSourcesDataReceived.data === undefined ){
    //     this.fetchTrafficSources();
    // }

    if (this.props.countriesListReceived === null) {
      this.fetchCountries();
    }
    if (this.props.conditionCountryDataReceived === null) {
      this.props.fetchCountryData(this.props.accessToken);
    }
    // if (this.state.loadedTrafficSources === null) {this.fetchTrafficSources();}
    if (this.props.trafficSourcesDataReceived === null) {
      this.fetchTrafficSources();
    }
    // if (this.state.allLandersDataLoaded === null) {this.fetchAllLanders();  }
    if (this.props.allLandersDataLoaded === null) {
      this.fetchAllLanders();
    }
    // if (this.state.allOffersDataLoaded === null) { this.fetchAllOffers(); }
    if (this.props.allOffersDataLoaded === null) {
      this.fetchAllOffers();
    }
    // if (this.state.allFlowsDataLoaded === null) {this.fetchAllFlows();  }
    if (this.props.flowsDataReceived === null) {
      this.fetchAllFlows();
    }
    // if (this.state.allLandersDataLoaded === null) {this.fetchAllLanders();  }
    // if (this.state.allOffersDataLoaded === null) { this.fetchAllOffers(); }
    // if (this.state.allFlowsDataLoaded === null) {this.fetchAllFlows();  }
    if (this.props.browserDataReceived === null) {
      this.props.fetchBrowserData(this.props.accessToken);
    }
    if (this.props.brandDataReceived === null) {
      this.props.fetchBrandData(this.props.accessToken);
    }
    if (this.props.languageDataReceived === null) {
      this.props.fetchLanguageData(this.props.accessToken);
    }
    if (this.props.proxyDataReceived === null) {
      this.props.fetchProxyData(this.props.accessToken);
    }
    if (this.props.regionDataReceived === null) {
      this.props.fetchRegionData(this.props.accessToken);
    }
    if (this.props.cityDataReceived === null) {
      this.props.fetchCityData(this.props.accessToken);
    }
    // if (this.props.conditionCountryDaraReceived === null) { this.props.fetchCountryData(this.props.accessToken); }
    // this.props.fetchCountryData(this.props.accessToken);
    if (this.props.connectionTypeDataReceived === null) {
      this.props.fetchConnectionTypeData(this.props.accessToken);
    }
    if (this.props.deviceTypeDataReceived === null) {
      this.props.fetchDeviceTypeData(this.props.accessToken);
    }
    if (this.props.osDataReceived === null) {
      this.props.fetchOperatingSystemData(this.props.accessToken);
    }
    if (this.props.ispDataReceived === null) {
      this.props.fetchISPData(this.props.accessToken);
    }
    if (this.props.weekdayDataReceived === null) {
      this.props.fetchWeekDayData(this.props.accessToken);
    }
    this.fetchEditCampaignData();

    this.setState(
      produce((draft) => {
        draft.onDefaultPathClick = true;
      })
    );
    this.setState(
      produce((draft) => {
        draft.newDefaultPathIndex = 0;
      })
    );
    this.setState(
      produce((draft) => {
        draft.bgClrPathIndex = 0;
      })
    );
    this.setState(
      produce((draft) => {
        draft.bgClrRuleIndex = null;
      })
    );
    this.setState(
      produce((draft) => {
        draft.bgClrRulePathIndex = null;
      })
    );
    this.setState(
      produce((draft) => {
        draft.onRuleClick = false;
      })
    );
    this.setState(
      produce((draft) => {
        draft.onFlowClick = false;
      })
    );
    this.setState(
      produce((draft) => {
        draft.onPathClick = true;
      })
    );

    // if (this.props.allLandersDataLoaded.data.length > 0) {
    //     this.setState(produce(draft => { draft.errors.isLanderExist = true }));
    // }
    // if (this.props.allOffersDataLoaded.data.length > 0) {
    //     this.setState(produce(draft => { draft.errors.isOfferExist = true }));
    // }

    if (
      this.props.allLandersDataLoaded !== null &&
      typeof this.props.allLandersDataLoaded !== "undefined"
    ) {
      if (this.props.allLandersDataLoaded.data.length !== 0) {
        this.setState(
          produce((draft) => {
            draft.errors.isLanderExist = true;
            draft.initialLandersData = this.props.allLandersDataLoaded.data;
          })
        );
      }
    }
    if (
      this.props.allOffersDataLoaded !== null &&
      typeof this.props.allOffersDataLoaded !== "undefined"
    ) {
      if (this.props.allOffersDataLoaded.data.length !== 0) {
        this.setState(
          produce((draft) => {
            draft.errors.isOfferExist = true;
            draft.initialOffersData = this.props.allOffersDataLoaded.data;
          })
        );
      }
    }
    this.props.toggleLoader();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.domainsAndWorkspacesListsReceived !== null &&
      this.props.countriesListReceived !== null &&
      this.props.trafficSourcesDataReceived !== null &&
      this.props.allLandersDataLoaded !== null &&
      this.props.allOffersDataLoaded !== null &&
      this.props.flowsDataReceived !== null &&
      this.props.browserDataReceived !== null &&
      this.props.brandDataReceived !== null &&
      this.props.languageDataReceived !== null &&
      this.props.proxyDataReceived !== null &&
      this.props.regionDataReceived !== null &&
      this.props.cityDataReceived !== null &&
      this.props.conditionCountryDaraReceived !== null &&
      this.props.connectionTypeDataReceived !== null &&
      this.props.deviceTypeDataReceived !== null &&
      this.props.osDataReceived !== null &&
      this.props.ispDataReceived !== null &&
      this.props.weekdayDataReceived !== null &&
      this.props.editCampaignDataReceived !== null &&
      this.state.allRequestsLoaded === false
    ) {
      // console.log('all requests loaded', this.state);
      this.setState((draft) => {
        draft.allRequestsLoaded = true;
      });
      this.props.removeLoader();
    }

    if (
      prevProps.allLandersDataLoaded !== this.props.allLandersDataLoaded &&
      this.props.allLandersDataLoaded !== null &&
      typeof this.props.allLandersDataLoaded !== "undefined"
    ) {
      if (this.props.allLandersDataLoaded.data.length !== 0) {
        // console.log('lander loaded');
        this.setState(
          produce((draft) => {
            draft.errors.isLanderExist = true;
            draft.initialLandersData = this.props.allLandersDataLoaded.data;
          })
        );
      }
    }
    if (
      prevProps.allOffersDataLoaded !== this.props.allOffersDataLoaded &&
      this.props.allOffersDataLoaded !== null &&
      typeof this.props.allOffersDataLoaded !== "undefined"
    ) {
      // console.log('offers loaded');
      if (this.props.allOffersDataLoaded.data.length !== 0) {
        this.setState(
          produce((draft) => {
            draft.errors.isOfferExist = true;
            draft.initialOffersData = this.props.allOffersDataLoaded.data;
          })
        );
      }
    }
    //successFullyCampaignPatched
    if (
      prevProps.patchedAdvanceCampaign !== this.props.patchedAdvanceCampaign &&
      this.props.patchedAdvanceCampaign &&
      !this.props.error &&
      !this.state.isCampaignPatched
    ) {
      this.setState(
        produce((draft) => {
          draft.isCampaignPatched = true;
          // draft.loader = false;
          draft.saveBtnDisabled = true;
        })
      );
      // this.props.toggleLoader();
      // console.log('remove 1 patchedAdvanceCampaign');
      // this.props.removeLoader();
    }
    //successFullyCampaignDuplicated
    if (
      prevProps.successAdvanceCampaign !== this.props.successAdvanceCampaign &&
      this.props.successAdvanceCampaign &&
      !this.props.error &&
      !this.state.isCampaignCreated
    ) {
      this.setState(
        produce((draft) => {
          draft.isCampaignCreated = true;
          draft.saveBtnDisabled = true;
          // draft.loader = false;
        })
      );
      // this.props.toggleLoader();
      // console.log('remove 2 successAdvanceCampaign');
      // this.props.removeLoader();
    }
    if (prevProps.error !== this.props.error && this.props.error !== false) {
      // this.setState(produce(draft => { draft.loader = false }));
      // this.props.toggleLoader();
      // console.log('remove 3 error');
      this.props.removeLoader();
    }
    // Set domains and workspaces initial values in the state
    if (
      prevProps.domainsAndWorkspacesListsReceived !==
        this.props.domainsAndWorkspacesListsReceived &&
      this.props.domainsAndWorkspacesListsReceived !== null &&
      this.props.domainsAndWorkspacesListsReceived !== undefined &&
      this.state.loadedWorkspaces === null
    ) {
      this.setState(
        produce((draft) => {
          draft.loadedWorkspaces =
            this.props.domainsAndWorkspacesListsReceived.workspace;
        })
      );
      this.setState(
        produce((draft) => {
          draft.dataToSend.workspaceId = String(
            this.props.domainsAndWorkspacesListsReceived.workspace[0].id
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.loadedDomains =
            this.props.domainsAndWorkspacesListsReceived.domain;
        })
      );

      for (
        let i = 0;
        i < this.props.domainsAndWorkspacesListsReceived.domain.length;
        i++
      ) {
        if (
          this.props.domainsAndWorkspacesListsReceived.domain[i].selected ===
          true
        ) {
          this.setState(
            produce((draft) => {
              draft.dataToSend.domainId = String(
                this.props.domainsAndWorkspacesListsReceived.domain[i].id
              );
            })
          );
        }
      }
    }
    // Set token tags list in the state
    if (
      prevProps.tagsListReceived !== this.props.tagsListReceived &&
      this.props.tagsListReceived !== null &&
      this.props.tagsListReceived !== undefined &&
      this.state.tagsListReceived === null
    ) {
      this.setState(
        produce((draft) => {
          draft.tagsListReceived = this.props.tagsListReceived;
        })
      );
    }
    // Set countries list in the state
    if (
      this.props.countriesListReceived !== null &&
      this.props.countriesListReceived !== undefined &&
      this.state.loadedCountries === null
    ) {
      if (this.props.countriesListReceived.length > 0) {
        this.setState(
          produce((draft) => {
            draft.loadedCountries = this.props.countriesListReceived;
          })
        );
      }
    }

    // Set Traffic Sources list in the state
    if (
      prevProps.trafficSourcesDataReceived !==
        this.props.trafficSourcesDataReceived ||
      (this.props.trafficSourcesDataReceived !== null &&
        this.props.trafficSourcesDataReceived.data !== undefined &&
        this.state.loadedTrafficSources === null)
    ) {
      // console.log('traffic source loaded');
      if (this.props.trafficSourcesDataReceived.data.length > 0) {
        this.setState(
          produce((draft) => {
            draft.loadedTrafficSources =
              this.props.trafficSourcesDataReceived.data;
          })
        );
        this.setState(
          produce((draft) => {
            draft.loadedTrafficSources.sort(this.dynamicSort("name"));
          })
        );
        // this.setState(produce(draft => { draft.dataToSend.trafficSourceId = draft.loadedTrafficSources[0].id }));
        // this.setState(produce(draft => { draft.trafficSourceName = draft.loadedTrafficSources[0].name }));
      }
    }

    // -----------------------------------------------
    // set initial dataToSend of the edit campaign data received

    //fixed---------------
    if (
      this.state.isRulePathsDataPopulated === true &&
      this.state.rulePathCounter === 0 &&
      this.props.allLandersDataLoaded !== null &&
      this.props.allOffersDataLoaded !== null &&
      this.state.loadRLO === false
    ) {
      // console.log('isRulePathsDataPopulated outside');

      this.setState(
        produce((draft) => {
          draft.isRulePathsDataPopulated = false;
        })
      );
      this.setState(
        produce((draft) => {
          draft.rulePathCounter = 1;
        })
      );
      this.setState(
        this.setState(
          produce((draft) => {
            draft.newRuleIndex = 0;
          })
        )
      );
      this.setState(
        this.setState(
          produce((draft) => {
            draft.newRulePathIndex = 0;
          })
        )
      );
      for (
        let i = 0;
        i < this.props.editCampaignDataReceived.path.rules.length;
        i++
      ) {
        this.setState(
          produce((draft) => {
            draft.dataToSend.path.rule[i].name =
              this.props.editCampaignDataReceived.path.rules[i].name;
          })
        );
        this.setState(
          produce((draft) => {
            draft.dataToSend.path.rule[i].order = String(
              this.props.editCampaignDataReceived.path.rules[i].order
            );
          })
        );
        this.setState(
          produce((draft) => {
            draft.dataToSend.path.rule[i].ruleId =
              this.props.editCampaignDataReceived.path.rules[i].id;
          })
        );

        for (
          let j = 0;
          j < this.props.editCampaignDataReceived.path.rules[i].path.length;
          j++
        ) {
          // console.log('isRulePathsDataPopulated inside');
          //console.log('this.props.editCampaignDataReceived.path.rules[i].path.length',this.props.editCampaignDataReceived.path.rules[i].path);
          this.setState(
            produce((draft) => {
              draft.ruleWeightageData[i].pathAddedCount =
                this.props.editCampaignDataReceived.path.rules[i].path.length;
            })
          );
          this.setState(
            produce((draft) => {
              draft.ruleWeightageData[i].pathsTotalWeightage =
                this.props.editCampaignDataReceived.path.rules[i].path.length *
                100;
            })
          );

          this.setState(
            produce((draft) => {
              draft.dataToSend.path.rule[i].path[j].path_name =
                this.props.editCampaignDataReceived.path.rules[i].path[j].name;
            })
          );
          this.setState(
            produce((draft) => {
              draft.dataToSend.path.rule[i].path[j].pathId =
                this.props.editCampaignDataReceived.path.rules[i].path[j].id;
            })
          );
          this.setState(
            produce((draft) => {
              draft.dataToSend.path.rule[i].path[j].path_destination =
                this.props.editCampaignDataReceived.path.rules[i].path[
                  j
                ].path_destination;
            })
          );
          this.setState(
            produce((draft) => {
              draft.dataToSend.path.rule[i].path[j].path_redirect_mode =
                this.props.editCampaignDataReceived.path.rules[i].path[
                  j
                ].redirect_mode;
            })
          );
          this.setState(
            produce((draft) => {
              draft.dataToSend.path.rule[i].path[j].weight =
                this.props.editCampaignDataReceived.path.rules[i].path[
                  j
                ].weight;
            })
          );
          this.setState(
            produce((draft) => {
              draft.dataToSend.path.rule[i].path[j].value =
                this.props.editCampaignDataReceived.path.rules[i].path[j].value;
            })
          );
          this.setState(
            produce((draft) => {
              draft.dataToSend.path.rule[i].path[j].weight_optimization =
                this.props.editCampaignDataReceived.path.rules[i].path[
                  j
                ].weight_optimization;
            })
          );
        }
      }

      if (typeof this.props.editCampaignDataReceived.path !== "undefined") {
        if (
          this.props.allLandersDataLoaded !== null &&
          typeof this.props.allLandersDataLoaded !== "undefined" &&
          this.state.loadFirstTimeOffers === false &&
          this.props.editCampaignDataReceived !== null &&
          typeof this.props.editCampaignDataReceived !== "undefined"
        ) {
          // console.log('andar jaa bhai');
          if (
            this.state.dataToSend.path.rule &&
            this.props.editCampaignDataReceived.path.rules
          ) {
            // console.log('q nh chal rha');
            // console.log('this.state.dataToSend.path.rule.length',this.state.dataToSend.path.rule.length);
            // console.log('this.props.editCampaignDataReceived.path.rules.length',this.props.editCampaignDataReceived.path.rules.length);
            if (
              this.state.dataToSend.path.rule.length ===
              this.props.editCampaignDataReceived.path.rules.length
            ) {
              // this.setState(produce(draft => { draft.loadRLO = true }));
              // console.log('wja smjh nh aa rhi');
              // console.log('allRulePathLandersAndOffersLoadedInitially------------------------------',this.state.allRulePathLandersAndOffersLoadedInitially);
              this.setState(
                produce((draft) => {
                  draft.loadFirstTimeOffers = true;
                })
              );
              this.setState(
                produce((draft) => {
                  draft.allRulePathLandersAndOffersLoadedInitially = true;
                })
              );
              if (this.state.rule && this.state.rule.length > 0) {
                // console.log('rule k andar agya he ');
                this.setState(
                  produce((draft) => {
                    draft.loadRLO = true;
                  })
                );
                for (let j = 0; j < this.state.rule.length; j++) {
                  for (let k = 0; k < this.state.rule[j].path.length; k++) {
                    if (
                      this.props.allLandersDataLoaded !== null &&
                      this.props.allLandersDataLoaded.data &&
                      this.props.allLandersDataLoaded.data.length > 0 &&
                      this.state.rule[j].path[k].allLandersDataLoaded === null
                    ) {
                      let landers_list = [];
                      for (
                        let i = 0;
                        i < this.props.allLandersDataLoaded.data.length;
                        i++
                      ) {
                        landers_list.push({
                          name: this.props.allLandersDataLoaded.data[i].name,
                          id: this.props.allLandersDataLoaded.data[i].id,
                        });
                      }
                      this.setState(
                        produce((draft) => {
                          draft.rule[j].path[k].counts.landerInBackend =
                            landers_list.length;
                        })
                      );
                      this.setState(
                        produce((draft) => {
                          draft.rule[j].path[k].allLandersDataLoaded =
                            landers_list;
                        })
                      );
                    }
                    if (
                      this.props.allOffersDataLoaded !== null &&
                      this.props.allOffersDataLoaded.data &&
                      this.props.allOffersDataLoaded.data.length > 0 &&
                      this.state.rule[j].path[k].allOffersDataLoaded === null
                    ) {
                      let offers_list = [];
                      for (
                        let i = 0;
                        i < this.props.allOffersDataLoaded.data.length;
                        i++
                      ) {
                        offers_list.push({
                          name: this.props.allOffersDataLoaded.data[i].name,
                          id: this.props.allOffersDataLoaded.data[i].id,
                        });
                      }
                      // Set offers in backend count
                      // console.log('offer k andar ghuss jaaa');
                      this.setState(
                        produce((draft) => {
                          draft.rule[j].path[k].counts.offersInBackend =
                            offers_list.length;
                        })
                      );
                      this.setState(
                        produce((draft) => {
                          draft.rule[j].path[k].allOffersDataLoaded =
                            offers_list;
                        })
                      );
                    }
                  }
                }
              }
            }
          }
        }
      }
      // console.log('remove 4 isRulePathsDataPopulated');
      // this.props.removeLoader();
    }
    //fixed-----------------

    //fixed-----------------
    if (
      this.props.editCampaignDataReceived !== null &&
      this.props.allLandersDataLoaded !== null &&
      this.props.allOffersDataLoaded !== null &&
      this.state.loadLO === false
    ) {
      if (
        this.props.editCampaignDataReceived.notes !== null ||
        this.props.editCampaignDataReceived.notes !== false
      ) {
        this.setState(
          produce((draft) => {
            draft.dataToSend.notes = this.props.editCampaignDataReceived.notes;
            draft.dataToSend.notes_app =
              this.props.editCampaignDataReceived.notes;
          })
        );
      }

      if (typeof this.props.editCampaignDataReceived.path !== "undefined") {
        // console.log('typeof this.props.editCampaignDataReceived');
        if (
          this.props.allLandersDataLoaded !== null &&
          typeof this.props.allLandersDataLoaded !== undefined &&
          this.state.loadFirstTimeLanders === false &&
          this.props.editCampaignDataReceived !== null &&
          typeof this.props.editCampaignDataReceived !== undefined
        ) {
          // console.log('typeof this.props.editCampaignDataReceived----1');
          if (
            this.props.editCampaignDataReceived.path.is_default !== undefined &&
            this.props.editCampaignDataReceived.path.is_default !== null
          ) {
            // console.log('typeof this.props.editCampaignDataReceived------2');
            if (
              this.state.dataToSend.path.is_default !== undefined &&
              this.state.dataToSend.path.is_default !== null
            ) {
              // console.log('typeof this.props.editCampaignDataReceived----3');
              if (
                this.state.dataToSend.path.is_default.length ===
                this.props.editCampaignDataReceived.path.is_default.length
              ) {
                // console.log('typeof this.props.editCampaignDataReceived----4');
                this.setState(
                  produce((draft) => {
                    draft.loadFirstTimeLanders = true;
                  })
                );
                this.setState(
                  produce((draft) => {
                    draft.allLandersAndOffersLoadedInitially = true;
                  })
                );
                this.setState(
                  produce((draft) => {
                    draft.loadLO = true;
                  })
                );

                for (let j = 0; j < this.state.is_default.length; j++) {
                  if (this.state.is_default[j].allLandersDataLoaded === null) {
                    // console.log('chal lander');
                    let landers_list = [];
                    for (
                      let i = 0;
                      i < this.props.allLandersDataLoaded.data.length;
                      i++
                    ) {
                      landers_list.push({
                        name: this.props.allLandersDataLoaded.data[i].name,
                        id: this.props.allLandersDataLoaded.data[i].id,
                      });
                    }
                    this.setState(
                      produce((draft) => {
                        draft.is_default[j].counts.landerInBackend =
                          landers_list.length;
                      })
                    );
                    this.setState(
                      produce((draft) => {
                        draft.is_default[j].allLandersDataLoaded = landers_list;
                      })
                    );
                  }
                  if (
                    this.props.allOffersDataLoaded !== null &&
                    this.props.allOffersDataLoaded.data.length > 0 &&
                    this.state.is_default[j].allOffersDataLoaded === null
                  ) {
                    // console.log('chal offer');
                    let offers_list = [];
                    for (
                      let i = 0;
                      i < this.props.allOffersDataLoaded.data.length;
                      i++
                    ) {
                      offers_list.push({
                        name: this.props.allOffersDataLoaded.data[i].name,
                        id: this.props.allOffersDataLoaded.data[i].id,
                      });
                    }
                    // console.log('offers_list',offers_list);
                    this.setState(
                      produce((draft) => {
                        draft.is_default[j].counts.offersInBackend =
                          offers_list.length;
                      })
                    );
                    this.setState(
                      produce((draft) => {
                        draft.is_default[j].allOffersDataLoaded = offers_list;
                      })
                    );
                  }
                }
              }
            }
          }
        }
      }
      // console.log('remove 5 editCampaignDataReceived');
      // this.props.removeLoader();
    }
    //fixed-----------------

    ///fixed-----------------
    if (
      this.props.editCampaignDataReceived !== null &&
      this.state.isDefaultPathsDataPopulated === true
    ) {
      // console.log('isDefaultPathsDataPopulated -------- 1');
      if (typeof this.props.editCampaignDataReceived.path !== "undefined") {
        // console.log('path id populated');
        // console.log('this.state.isDefaultPathsDataPopulated',this.state.isDefaultPathsDataPopulated);
        if (
          this.state.isDefaultPathsDataPopulated &&
          this.props.editCampaignDataReceived !== null &&
          typeof this.props.editCampaignDataReceived !== "undefined"
        ) {
          // console.log('INSIDE');
          this.setState(
            produce((draft) => {
              draft.isDefaultPathsDataPopulated = false;
            })
          );
          for (
            let i = 0;
            i < this.props.editCampaignDataReceived.path.is_default.length;
            i++
          ) {
            this.setState(
              produce((draft) => {
                draft.dataToSend.path.is_default[i].path_name =
                  this.props.editCampaignDataReceived.path.is_default[i].name;
              })
            );
            this.setState(
              produce((draft) => {
                draft.dataToSend.path.is_default[i].pathId =
                  this.props.editCampaignDataReceived.path.is_default[i].id;
              })
            );
            this.setState(
              produce((draft) => {
                draft.dataToSend.path.is_default[i].path_redirect_mode =
                  this.props.editCampaignDataReceived.path.is_default[
                    i
                  ].redirect_mode;
              })
            );
            this.setState(
              produce((draft) => {
                draft.dataToSend.path.is_default[i].path_destination =
                  this.props.editCampaignDataReceived.path.is_default[
                    i
                  ].path_destination;
              })
            );
            this.setState(
              produce((draft) => {
                draft.dataToSend.path.is_default[i].weight =
                  this.props.editCampaignDataReceived.path.is_default[i].weight;
              })
            );

            this.setState(
              produce((draft) => {
                draft.dataToSend.path.is_default[i].value =
                  this.props.editCampaignDataReceived.path.is_default[i].value;
              })
            );
            this.setState(
              produce((draft) => {
                draft.dataToSend.path.is_default[i].weight_optimization =
                  this.props.editCampaignDataReceived.path.is_default[
                    i
                  ].weight_optimization;
              })
            );
            this.setState(
              produce((draft) => {
                draft.defaultWeightageData.pathAddedCount =
                  this.props.editCampaignDataReceived.path.is_default.length;
                draft.defaultWeightageData.pathsTotalWeightage =
                  this.props.editCampaignDataReceived.path.is_default.length *
                  100;
              })
            );
          }
        }
      }
    }
    ///fixed-----------------

    if (
      this.props.editCampaignDataReceived !== null &&
      this.state.landersPopulated === false
    ) {
      if (typeof this.props.editCampaignDataReceived.path !== "undefined") {
        // console.log('allLandersAndOffersLoadedInitially',this.state.allLandersAndOffersLoadedInitially);
        // console.log('landersPopulated---------------------------------',this.state.landersPopulated);

        if (
          this.props.editCampaignDataReceived !== null &&
          typeof this.props.editCampaignDataReceived !== "undefined" &&
          this.state.landersPopulated === false &&
          this.state.allLandersAndOffersLoadedInitially === true
        ) {
          if (
            this.state.dataToSend.path.is_default.length ===
            this.props.editCampaignDataReceived.path.is_default.length
          ) {
            // console.log('-----------------------------------------populate');
            // console.log('equal');
            this.setState(
              produce((draft) => {
                draft.landersPopulated = true;
              })
            );
            this.setState(
              produce((draft) => {
                draft.allLandersAndOffersLoadedInitially = true;
              })
            );
            for (
              let d = 0;
              d < this.props.editCampaignDataReceived.path.is_default.length;
              d++
            ) {
              let offerIds = [];
              let offerWeights = [];
              let offerValues = [];
              let offerWeightageTotal = 0;
              let landerIds = [];
              let landerWeights = [];
              let landerValues = [];
              let landerWeightageTotal = 0;
              for (
                let i = 0;
                i <
                this.props.editCampaignDataReceived.path.is_default[d].landers
                  .length;
                i++
              ) {
                landerIds.push(
                  String(
                    this.props.editCampaignDataReceived.path.is_default[d]
                      .landers[i].id
                  )
                );
                landerWeights.push(
                  String(
                    this.props.editCampaignDataReceived.path.is_default[d]
                      .landerWeight[i]
                  )
                );
                landerValues.push(
                  String(
                    this.props.editCampaignDataReceived.path.is_default[d]
                      .landerValue[i]
                  )
                );
                landerWeightageTotal =
                  landerWeightageTotal +
                  Number(
                    this.props.editCampaignDataReceived.path.is_default[d]
                      .landerValue[i]
                  );
                // add values to dataToMap to show it all on the frontend
                this.setState(
                  produce((draft) => {
                    draft.is_default[d].dataToMap.landers.push({
                      name: "lander_" + i,
                      weightageNumber: Number(
                        this.props.editCampaignDataReceived.path.is_default[d]
                          .landerValue[i]
                      ),
                      weightagePercentage: Number(
                        this.props.editCampaignDataReceived.path.is_default[d]
                          .landerWeight[i]
                      ),
                      options: [],
                      selectedId:
                        this.props.editCampaignDataReceived.path.is_default[d]
                          .landers[i].id,
                    });
                  })
                );
              }
              this.setState(
                produce((draft) => {
                  draft.is_default[d].counts.landersAdded =
                    this.props.editCampaignDataReceived.path.is_default[
                      d
                    ].landers.length;
                })
              );
              for (
                let j = 0;
                j <
                this.props.editCampaignDataReceived.path.is_default[d].offers
                  .length;
                j++
              ) {
                offerIds.push(
                  String(
                    this.props.editCampaignDataReceived.path.is_default[d]
                      .offers[j].id
                  )
                );
                offerWeights.push(
                  String(
                    this.props.editCampaignDataReceived.path.is_default[d]
                      .offerWeight[j]
                  )
                );
                offerValues.push(
                  String(
                    this.props.editCampaignDataReceived.path.is_default[d]
                      .offerValue[j]
                  )
                );
                offerWeightageTotal =
                  offerWeightageTotal +
                  Number(
                    this.props.editCampaignDataReceived.path.is_default[d]
                      .offerValue[j]
                  );
                // add values to dataToMap to show it all on the frontend
                this.setState(
                  produce((draft) => {
                    draft.is_default[d].dataToMap.offers.push({
                      name: "offer_" + j,
                      weightageNumber: Number(
                        this.props.editCampaignDataReceived.path.is_default[d]
                          .offerValue[j]
                      ),
                      weightagePercentage: Number(
                        this.props.editCampaignDataReceived.path.is_default[d]
                          .offerWeight[j]
                      ),
                      options: [],
                      selectedId:
                        this.props.editCampaignDataReceived.path.is_default[d]
                          .offers[j].id,
                    });
                  })
                );
              }
              this.setState(
                produce((draft) => {
                  draft.is_default[d].counts.offersAdded =
                    this.props.editCampaignDataReceived.path.is_default[
                      d
                    ].offers.length;
                })
              );

              this.setState(
                produce((draft) => {
                  draft.dataToSend.path.is_default[d].offerId = offerIds;
                })
              );
              this.setState(
                produce((draft) => {
                  draft.dataToSend.path.is_default[d].offerWeight =
                    offerWeights;
                })
              );
              this.setState(
                produce((draft) => {
                  draft.dataToSend.path.is_default[d].offerValue = offerValues;
                })
              );
              this.setState(
                produce((draft) => {
                  draft.is_default[d].counts.offerWeightageTotal =
                    offerWeightageTotal;
                })
              );
              this.setState(
                produce((draft) => {
                  draft.dataToSend.path.is_default[d].landerId = landerIds;
                })
              );
              this.setState(
                produce((draft) => {
                  draft.dataToSend.path.is_default[d].landerWeight =
                    landerWeights;
                })
              );
              this.setState(
                produce((draft) => {
                  draft.dataToSend.path.is_default[d].landerValue =
                    landerValues;
                })
              );
              this.setState(
                produce((draft) => {
                  draft.is_default[d].counts.landerWeightageTotal =
                    landerWeightageTotal;
                })
              );
              // this.setState(produce(draft => { draft.newDefaultPathIndex = d }));
              // this.setState(produce(draft => { draft.onDefaultPathClick = true }));
              // console.log('this.initialRepopulateSelectOptions(true, "offer", d);');
              this.initialRepopulateSelectOptions(true, "offer", d);
              this.initialRepopulateSelectOptions(true, "lander", d);
            }
            this.setState(
              produce((draft) => {
                draft.defaultPathDataCompleted = true;
              })
            );
          }
        }
      }
    }

    if (
      this.props.editCampaignDataReceived !== null &&
      this.state.offersPopulated === false
    ) {
      // console.log('andar ghuss gya');
      if (typeof this.props.editCampaignDataReceived.path !== "undefined") {
        // console.log('allRulePathLandersAndOffersLoadedInitially -------------------asdf',this.state.allRulePathLandersAndOffersLoadedInitially);
        // console.log('offersPopulated-------------asdf',this.state.offersPopulated);
        // console.log('this.state.defaultPathDataCompleted',this.state.defaultPathDataCompleted);
        if (
          this.props.editCampaignDataReceived !== null &&
          typeof this.props.editCampaignDataReceived !== "undefined" &&
          this.state.offersPopulated === false &&
          this.state.allRulePathLandersAndOffersLoadedInitially === true &&
          this.state.defaultPathDataCompleted === true
        ) {
          // console.log('inside rule landers and offers ------------------------------------');
          if (
            this.props.editCampaignDataReceived.path.rules.length ===
            this.state.dataToSend.path.rule.length
          ) {
            // console.log('again inside--------------------------------------------');
            if (this.state.rule.length > 0) {
              // console.log('ruleeeingggggg-----------------------');
              this.setState(
                produce((draft) => {
                  draft.offersPopulated = true;
                })
              );
              for (
                let d = 0;
                d < this.props.editCampaignDataReceived.path.rules.length;
                d++
              ) {
                for (
                  let p = 0;
                  p <
                  this.props.editCampaignDataReceived.path.rules[d].path.length;
                  p++
                ) {
                  let offerIds = [];
                  let offerWeights = [];
                  let offerValues = [];
                  let offerWeightageTotal = 0;
                  let landerIds = [];
                  let landerWeights = [];
                  let landerValues = [];
                  let landerWeightageTotal = 0;
                  for (
                    let i = 0;
                    i <
                    this.props.editCampaignDataReceived.path.rules[d].path[p]
                      .landers.length;
                    i++
                  ) {
                    landerIds.push(
                      String(
                        this.props.editCampaignDataReceived.path.rules[d].path[
                          p
                        ].landers[i].id
                      )
                    );
                    landerWeights.push(
                      String(
                        this.props.editCampaignDataReceived.path.rules[d].path[
                          p
                        ].landerWeight[i]
                      )
                    );
                    landerValues.push(
                      String(
                        this.props.editCampaignDataReceived.path.rules[d].path[
                          p
                        ].landerValue[i]
                      )
                    );
                    landerWeightageTotal =
                      landerWeightageTotal +
                      Number(
                        this.props.editCampaignDataReceived.path.rules[d].path[
                          p
                        ].landerValue[i]
                      );
                    // add values to dataToMap to show it all on the frontend
                    this.setState(
                      produce((draft) => {
                        draft.rule[d].path[p].dataToMap.landers.push({
                          name: "lander_" + i,
                          weightageNumber: Number(
                            this.props.editCampaignDataReceived.path.rules[d]
                              .path[p].landerValue[i]
                          ),
                          weightagePercentage: Number(
                            this.props.editCampaignDataReceived.path.rules[d]
                              .path[p].landerWeight[i]
                          ),
                          options: [],
                          selectedId:
                            this.props.editCampaignDataReceived.path.rules[d]
                              .path[p].landers[i].id,
                        });
                      })
                    );
                  }
                  this.setState(
                    produce((draft) => {
                      draft.rule[d].path[p].counts.landersAdded =
                        this.props.editCampaignDataReceived.path.rules[d].path[
                          p
                        ].landers.length;
                    })
                  );
                  for (
                    let j = 0;
                    j <
                    this.props.editCampaignDataReceived.path.rules[d].path[p]
                      .offers.length;
                    j++
                  ) {
                    offerIds.push(
                      String(
                        this.props.editCampaignDataReceived.path.rules[d].path[
                          p
                        ].offers[j].id
                      )
                    );
                    offerWeights.push(
                      String(
                        this.props.editCampaignDataReceived.path.rules[d].path[
                          p
                        ].offerWeight[j]
                      )
                    );
                    offerValues.push(
                      String(
                        this.props.editCampaignDataReceived.path.rules[d].path[
                          p
                        ].offerValue[j]
                      )
                    );
                    offerWeightageTotal =
                      offerWeightageTotal +
                      Number(
                        this.props.editCampaignDataReceived.path.rules[d].path[
                          p
                        ].offerValue[j]
                      );
                    // add values to dataToMap to show it all on the frontend
                    this.setState(
                      produce((draft) => {
                        draft.rule[d].path[p].dataToMap.offers.push({
                          name: "offer_" + j,
                          weightageNumber: Number(
                            this.props.editCampaignDataReceived.path.rules[d]
                              .path[p].offerValue[j]
                          ),
                          weightagePercentage: Number(
                            this.props.editCampaignDataReceived.path.rules[d]
                              .path[p].offerWeight[j]
                          ),
                          options: [],
                          selectedId:
                            this.props.editCampaignDataReceived.path.rules[d]
                              .path[p].offers[j].id,
                        });
                      })
                    );
                  }
                  this.setState(
                    produce((draft) => {
                      draft.rule[d].path[p].counts.offersAdded =
                        this.props.editCampaignDataReceived.path.rules[d].path[
                          p
                        ].offers.length;
                    })
                  );

                  this.setState(
                    produce((draft) => {
                      draft.dataToSend.path.rule[d].path[p].offerId = offerIds;
                    })
                  );
                  this.setState(
                    produce((draft) => {
                      draft.dataToSend.path.rule[d].path[p].offerWeight =
                        offerWeights;
                    })
                  );
                  this.setState(
                    produce((draft) => {
                      draft.dataToSend.path.rule[d].path[p].offerValue =
                        offerValues;
                    })
                  );
                  this.setState(
                    produce((draft) => {
                      draft.rule[d].path[p].counts.offerWeightageTotal =
                        offerWeightageTotal;
                    })
                  );
                  this.setState(
                    produce((draft) => {
                      draft.dataToSend.path.rule[d].path[p].landerId =
                        landerIds;
                    })
                  );
                  this.setState(
                    produce((draft) => {
                      draft.dataToSend.path.rule[d].path[p].landerWeight =
                        landerWeights;
                    })
                  );
                  this.setState(
                    produce((draft) => {
                      draft.dataToSend.path.rule[d].path[p].landerValue =
                        landerValues;
                    })
                  );
                  this.setState(
                    produce((draft) => {
                      draft.rule[d].path[p].counts.landerWeightageTotal =
                        landerWeightageTotal;
                    })
                  );
                  // this.setState(produce(draft => { draft.newRuleIndex = d }));
                  // this.setState(produce(draft => { draft.newRulePathIndex = p }));
                  // this.setState(produce(draft => { draft.onDefaultPathClick = false }));
                  // console.log('bla  bla bla bla');
                  this.initialRepopulateSelectOptions(false, "lander", d, p);
                  this.initialRepopulateSelectOptions(false, "offer", d, p);
                }
              }
            }
          }
        }
      }
    }

    if (
      this.props.editCampaignDataReceived !== null &&
      this.state.isEditCampaignDataAdded === false
    ) {
      // console.log('traffic source');
      // this.tSChangeHandler(this.props.editCampaignDataReceived.trafficSource.id);
      this.setState(
        produce((draft) => {
          draft.isEditCampaignDataAdded = true;
          draft.dataToSend.name =
            this.props.editCampaignDataReceived.name +
            (this.props.state.isDuplicate ? " (Duplicate)" : "");
          draft.dataToSend.workspaceId =
            this.props.editCampaignDataReceived.workspace.id;
          draft.dataToSend.trafficSourceId =
            this.props.editCampaignDataReceived.trafficSource.id;
          draft.trafficSourceName =
            this.props.editCampaignDataReceived.trafficSource.name;
          // draft.dataToSend.countryId = this.props.editCampaignDataReceived.countryId;
          if (this.state.loadedCountries !== null) {
            this.countryChangeHandler(
              this.props.editCampaignDataReceived.countryId
            );
          } else {
            setTimeout(() => {
              this.countryChangeHandler(
                this.props.editCampaignDataReceived.countryId
              );
            }, 1500);
          }
          draft.dataToSend.campaign_destination =
            this.props.editCampaignDataReceived.campaign_destination;
          draft.dataToSend.tags = this.props.editCampaignDataReceived.tags;
          draft.dataToSend.cost_model =
            this.props.editCampaignDataReceived.cost_model;
          draft.dataToSend.tsPostbackUrl =
            this.props.editCampaignDataReceived.tsPostbackUrl;
          draft.dataToSend.campaignMode =
            this.props.editCampaignDataReceived.campaignMode;
          draft.dataToSend.tsPostbackEventType =
            this.props.editCampaignDataReceived.tsPostbackEventType;
          draft.dataToSend.tsPixelRedirect =
            this.props.editCampaignDataReceived.tsPixelRedirect;
          draft.dataToSend.is_advance =
            this.props.editCampaignDataReceived.is_advance;
          draft.dataToSend.cost_value =
            this.props.editCampaignDataReceived.cost_value;
          draft.dataToSend.flowId = this.props.editCampaignDataReceived.flowId;
          draft.dataToSend.redirect_mode =
            this.props.editCampaignDataReceived.redirect_mode;
          draft.campaign_url_generated =
            this.props.editCampaignDataReceived.campaign_url;
          draft.filterData = this.props.editCampaignDataReceived.flow;
        })
      );

      // setTimeout(() => {
      // },1000);

      //-------------------------------------------------------------------------------------------------------------------------------------------------------
      //-------------------------------------------------------------------------------------------------------------------------------------------------------

      if (
        typeof this.props.editCampaignDataReceived.path !== "undefined" &&
        this.props.editCampaignDataReceived.path.is_default
      ) {
        // this.props.toggleLoader();
        for (
          let i = 0;
          i < this.props.editCampaignDataReceived.path.is_default.length - 1;
          i++
        ) {
          this.setState(
            produce((draft) => {
              draft.defaultPathCounter = this.state.defaultPathCounter + 1;
            })
          );
          const defaultPath = {
            weight: "",
            value: "",
            weight_optimization: true,
            path_name: "New Path",
            offerId: [],
            landerId: [],
            offerWeight: [],
            landerWeight: [],
            offerValue: [],
            landerValue: [],
            pathId: "",
            path_redirect_mode: "302",
            path_destination: "Lander & Offer",
          };
          const outerDefaultPath = {
            dataToMap: {
              landers: [],
              offers: [],
            },
            counts: {
              landerInBackend: 0,
              landersAdded: 0,
              offersInBackend: 0,
              offersAdded: 0,
              landerWeightageTotal: 0,
              offerWeightageTotal: 0,
            },
            allLandersDataLoaded: null,
            allOffersDataLoaded: null,
          };
          this.setState(
            produce((draft) => {
              draft.dataToSend.path.is_default.push(defaultPath);
            })
          );
          this.setState(
            produce((draft) => {
              draft.is_default.push(outerDefaultPath);
            })
          );
        }
        // console.log('isDefaultPathsDataPopulated',this.state.isDefaultPathsDataPopulated);
        this.setState(
          produce((draft) => {
            draft.isDefaultPathsDataPopulated = true;
          })
        );

        if (this.props.editCampaignDataReceived.path.rules.length > 0) {
          for (
            let i = 0;
            i < this.props.editCampaignDataReceived.path.rules.length;
            i++
          ) {
            const rule = {
              name: "",
              conditions: {},
              order: "",
              ruleId: "",
              path: [
                {
                  path_name: "New Path",
                  weight: "100",
                  value: "100",
                  weight_optimization: true,
                  offerId: [],
                  landerId: [],
                  offerWeight: [],
                  landerWeight: [],
                  offerValue: [],
                  landerValue: [],
                  pathId: "",
                  path_redirect_mode: "302",
                  path_destination: "Lander & Offer",
                },
              ],
            };
            const outerRule = {
              order: String(i),
              path: [
                {
                  dataToMap: {
                    landers: [],
                    offers: [],
                  },
                  counts: {
                    landerInBackend: 0,
                    landersAdded: 0,
                    offersInBackend: 0,
                    offersAdded: 0,
                    landerWeightageTotal: 0,
                    offerWeightageTotal: 0,
                  },
                  allLandersDataLoaded: null,
                  allOffersDataLoaded: null,
                },
              ],
            };
            const ruleWeightage = {
              pathAddedCount: 1,
              pathsTotalWeightage: 100,
            };
            this.setState(
              produce((draft) => {
                draft.dataToSend.path.rule.push(rule);
              })
            );
            this.setState(
              produce((draft) => {
                draft.rule.push(outerRule);
              })
            );
            this.setState(
              produce((draft) => {
                draft.ruleWeightageData.push(ruleWeightage);
              })
            );
          }

          for (
            let i = 0;
            i < this.props.editCampaignDataReceived.path.rules.length;
            i++
          ) {
            const path = {
              path_name: "New Path",
              offerId: [],
              landerId: [],
              weight: "100",
              value: "100",
              weight_optimization: true,
              offerWeight: [],
              landerWeight: [],
              offerValue: [],
              landerValue: [],
              pathId: "",
              path_redirect_mode: "302",
              path_destination: "Lander & Offer",
            };
            const outerPath = {
              dataToMap: {
                landers: [],
                offers: [],
              },
              counts: {
                landerInBackend: 0,
                landersAdded: 0,
                offersInBackend: 0,
                offersAdded: 0,
                landerWeightageTotal: 0,
                offerWeightageTotal: 0,
              },
              allLandersDataLoaded: null,
              allOffersDataLoaded: null,
            };

            for (
              let j = 0;
              j <
              this.props.editCampaignDataReceived.path.rules[i].path.length - 1;
              j++
            ) {
              this.setState(
                produce((draft) => {
                  draft.dataToSend.path.rule[i].path.push(path);
                })
              );
              this.setState(
                produce((draft) => {
                  draft.rule[i].path.push(outerPath);
                })
              );
            }
          }
          //console.log('rule populated');
          this.setState(
            produce((draft) => {
              draft.isRulePathsDataPopulated =
                !this.state.isRulePathsDataPopulated;
            })
          );
        }
        setTimeout(() => {
          this.setState(
            produce((draft) => {
              draft.addedConditions = true;
            })
          );
          for (
            let i = 0;
            i < this.props.editCampaignDataReceived.path.rules.length;
            i++
          ) {
            if (
              this.props.editCampaignDataReceived.path.rules[i].conditions !==
              null
            ) {
              this.setState(
                produce((draft) => {
                  draft.dataToSend.path.rule[i].conditions =
                    this.props.editCampaignDataReceived.path.rules[
                      i
                    ].conditions;
                })
              );
            } else {
              this.setState(
                produce((draft) => {
                  draft.dataToSend.path.rule[i].conditions = {};
                })
              );
            }
          }
        }, 1000);
        setTimeout(() => {
          this.setState(
            produce((draft) => {
              draft.addedConditions = false;
            })
          );
          for (let i = 0; i < this.state.dataToSend.path.rule.length; i++) {
            this.initialCalculatePlaceholderName(i);
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "connectionType"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Connection type");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("connection");
                })
              );
            }
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "referrer"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Referrer");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("Referrer");
                })
              );
            }
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "referrerDomain"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Referrer domain");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("Referrer domain");
                })
              );
            }
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty("ip")
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("IP");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("IP");
                })
              );
            }
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "timeofday"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Time of day");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("timezone");
                })
              );
            }
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "var1"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Custom variable 1");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("Custom variable 1");
                })
              );
            }
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "var2"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Custom variable 2");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("Custom variable 2");
                })
              );
            }
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "var3"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Custom variable 3");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("Custom variable 3");
                })
              );
            }
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "var4"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Custom variable 4");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("Custom variable 4");
                })
              );
            }
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "var5"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Custom variable 5");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("Custom variable 5");
                })
              );
            }
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "var6"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Custom variable 6");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("Custom variable 6");
                })
              );
            }
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "var7"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Custom variable 7");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("Custom variable 7");
                })
              );
            }
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "var8"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Custom variable 8");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("Custom variable 8");
                })
              );
            }
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "var9"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Custom variable 9");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("Custom variable 9");
                })
              );
            }
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "var10"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Custom variable 10");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("Custom variable 10");
                })
              );
            }
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "var11"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Custom variable 11");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("Custom variable 11");
                })
              );
            }
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "var12"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Custom variable 12");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("Custom variable 12");
                })
              );
            }
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "var13"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Custom variable 13");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("Custom variable 13");
                })
              );
            }
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "var14"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Custom variable 14");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("Custom variable 14");
                })
              );
            }
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "var15"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Custom variable 15");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("Custom variable 15");
                })
              );
            }
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "var16"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Custom variable 16");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("Custom variable 16");
                })
              );
            }
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "var17"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Custom variable 17");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("Custom variable 17");
                })
              );
            }
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "var18"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Custom variable 18");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("Custom variable 18");
                })
              );
            }
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "var19"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Custom variable 19");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("Custom variable 19");
                })
              );
            }
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "var20"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Custom variable 20");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("Custom variable 20");
                })
              );
            }
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "deviceType"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Device type");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("device");
                })
              );
            }
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "proxy"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Proxy");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("proxy");
                })
              );
            }
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "region"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Region");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("region");
                })
              );
            }
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "isp"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("ISP");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("isp");
                })
              );
            }

            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "country"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Country");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("country");
                })
              );
            }

            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "city"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("City");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("city");
                })
              );
            }

            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "language"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Language");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("language");
                })
              );
            }

            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "weekday"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Weekday");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("weekday");
                })
              );
            }
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "mobileCarrier"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Mobile carrier");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("Mobile carrier");
                })
              );
            }
            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "browser"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Browser");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("browser");
                })
              );
            }

            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty(
                "brand"
              )
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Brand");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("brand");
                })
              );
            }

            if (
              this.state.dataToSend.path.rule[i].conditions.hasOwnProperty("os")
            ) {
              this.setState(
                produce((draft) => {
                  draft.conditionsName[i].push("Operating system");
                })
              );
              this.setState(
                produce((draft) => {
                  draft.selectedOptions[i].push("operating");
                })
              );
            }
          }
          this.setState(
            produce((draft) => {
              draft.editAdvanceCampaignDataLoaded = false;
            })
          );
          this.setState(
            produce((draft) => {
              draft.sorted = true;
            })
          );
          //console.log('this.state.rule',this.state.dataToSend.path.rule);
          //console.log('this.state.defaultpath',this.state.dataToSend.path.is_default);
        }, 2000);
      } else {
        this.setState(
          produce((draft) => {
            draft.editAdvanceCampaignDataLoaded = false;
          })
        );
      }
    }

    // set all flows in the state
    if (
      this.props.flowsDataReceived !== null &&
      this.props.flowsDataReceived.data.length > 0 &&
      this.state.allFlowsDataLoaded === null
    ) {
      this.setState(
        produce((draft) => {
          draft.allFlowsDataLoaded = this.props.flowsDataReceived.data;
        })
      );
    }

    // ===========================================================================
    // ===========================================================================

    // check if data is received after campaign patching
    if (
      this.props.patchedCampaignSuccessResponse !== null &&
      this.state.isCampaignPatched === true
    ) {
      // this.props.toggleLoader();
      this.setState(
        produce((draft) => {
          draft.isCampaignPatched = false;
        })
      );
      // To get the tracker tab working and showing the resulting data there
      // make this variable true
      this.setState(
        produce((draft) => {
          draft.isCampaignPatchedSuccessfully = true;
        })
      );
      // set campaign url generated to display it in the tracker
      this.setState(
        produce((draft) => {
          draft.campaign_url_generated =
            this.props.patchedCampaignSuccessResponse.campaign_url;
        })
      );
      // console.log('remove 6 patchedCampaignSuccessResponse');
      this.props.removeLoader();
    }

    // check if data is received after campaign duplication
    if (
      this.props.newCampaignSuccessResponse !== null &&
      this.state.isCampaignCreated === true
    ) {
      // this.props.toggleLoader();
      this.setState(
        produce((draft) => {
          draft.isCampaignCreated = false;
        })
      );
      // To get the tracker tab working and showing the resulting data there
      // make this variable true
      this.setState(
        produce((draft) => {
          draft.isCampaignPatchedSuccessfully = true;
        })
      );
      // set campaign url generated to display it in the tracker
      this.setState(
        produce((draft) => {
          draft.campaign_url_generated =
            this.props.newCampaignSuccessResponse.campaign_url;
        })
      );
      // console.log('remove 7 newCampaignSuccessResponse');
      this.props.removeLoader();
    }
    // ===========================================================================
    // ===========================================================================
  }

  dynamicSort = (property) => {
    var sortOrder = 1;

    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      if (sortOrder == -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    };
  };
  rePopulateDefaultRuleLandersOffer = () => {
    if (this.state.onDefaultPathClick) {
      if (
        this.props.allLandersDataLoaded !== null &&
        this.props.allLandersDataLoaded.data.length > 0 &&
        this.state.is_default[this.state.newDefaultPathIndex]
          .allLandersDataLoaded === null
      ) {
        let landers_list = [];
        for (let i = 0; i < this.props.allLandersDataLoaded.data.length; i++) {
          landers_list.push({
            name: this.props.allLandersDataLoaded.data[i].name,
            id: this.props.allLandersDataLoaded.data[i].id,
          });
        }
        // set landers in backend count
        this.setState(
          produce((draft) => {
            draft.is_default[draft.newDefaultPathIndex].counts.landerInBackend =
              landers_list.length;
          })
        );
        this.setState(
          produce((draft) => {
            draft.is_default[draft.newDefaultPathIndex].allLandersDataLoaded =
              landers_list;
          })
        );
      }
      // Set All offers list in the state

      if (
        this.props.allOffersDataLoaded !== null &&
        this.props.allOffersDataLoaded.data.length > 0 &&
        this.state.is_default[this.state.newDefaultPathIndex]
          .allOffersDataLoaded === null
      ) {
        let offers_list = [];
        for (let i = 0; i < this.props.allOffersDataLoaded.data.length; i++) {
          offers_list.push({
            name: this.props.allOffersDataLoaded.data[i].name,
            id: this.props.allOffersDataLoaded.data[i].id,
          });
        }
        // Set offers in backend count
        this.setState(
          produce((draft) => {
            draft.is_default[draft.newDefaultPathIndex].counts.offersInBackend =
              offers_list.length;
          })
        );
        this.setState(
          produce((draft) => {
            draft.is_default[draft.newDefaultPathIndex].allOffersDataLoaded =
              offers_list;
          })
        );
      }
    }
    if (this.state.dataToSend.path.rule.length > 0) {
      if (this.state.newRuleIndex !== null) {
        if (
          this.props.allLandersDataLoaded !== null &&
          this.props.allLandersDataLoaded.data.length > 0 &&
          this.state.rule[this.state.newRuleIndex].path[
            this.state.newRulePathIndex
          ].allLandersDataLoaded === null
        ) {
          let landers_list = [];
          for (
            let i = 0;
            i < this.props.allLandersDataLoaded.data.length;
            i++
          ) {
            landers_list.push({
              name: this.props.allLandersDataLoaded.data[i].name,
              id: this.props.allLandersDataLoaded.data[i].id,
            });
          }
          // set landers in backend count
          this.setState(
            produce((draft) => {
              draft.rule[draft.newRuleIndex].path[
                draft.newRulePathIndex
              ].counts.landerInBackend = landers_list.length;
            })
          );
          this.setState(
            produce((draft) => {
              draft.rule[draft.newRuleIndex].path[
                draft.newRulePathIndex
              ].allLandersDataLoaded = landers_list;
            })
          );
        }

        // Set All offers list in the state
        if (
          this.props.allOffersDataLoaded !== null &&
          this.props.allOffersDataLoaded.data.length > 0 &&
          this.state.rule[this.state.newRuleIndex].path[
            this.state.newRulePathIndex
          ].allOffersDataLoaded === null
        ) {
          let offers_list = [];
          for (let i = 0; i < this.props.allOffersDataLoaded.data.length; i++) {
            offers_list.push({
              name: this.props.allOffersDataLoaded.data[i].name,
              id: this.props.allOffersDataLoaded.data[i].id,
            });
          }
          // Set offers in backend count
          this.setState(
            produce((draft) => {
              draft.rule[draft.newRuleIndex].path[
                draft.newRulePathIndex
              ].counts.offersInBackend = offers_list.length;
            })
          );
          this.setState(
            produce((draft) => {
              draft.rule[draft.newRuleIndex].path[
                draft.newRulePathIndex
              ].allOffersDataLoaded = offers_list;
            })
          );
        }
      }
    }
  };

  // =============================================================================================
  // =============================================================================================
  // for adding landers and offers
  addLandersAndOffersMethod = (e) => {
    if (this.state.onDefaultPathClick) {
      let type = e.target.id.split("_")[1];
      if (typeof type !== "undefined") {
        this.setState(
          produce((draft) => {
            draft.is_default[draft.newDefaultPathIndex].counts[
              type + "sAdded"
            ] =
              Number(
                this.state.is_default[this.state.newDefaultPathIndex].counts[
                  type + "sAdded"
                ]
              ) + 1;
          })
        );
        let remainingData = [];
        for (
          let i = 0;
          i <
          this.state.is_default[this.state.newDefaultPathIndex][
            "all" + this.capitalizeFirstLetter(type) + "sDataLoaded"
          ].length;
          i++
        ) {
          let itemFound = false;
          for (
            let j = 0;
            j <
            this.state.dataToSend.path.is_default[
              this.state.newDefaultPathIndex
            ][type + "Id"].length;
            j++
          ) {
            if (
              Number(
                this.state.is_default[this.state.newDefaultPathIndex][
                  "all" + this.capitalizeFirstLetter(type) + "sDataLoaded"
                ][i].id
              ) ===
              Number(
                this.state.dataToSend.path.is_default[
                  this.state.newDefaultPathIndex
                ][type + "Id"][j]
              )
            ) {
              itemFound = true;
            }
          }
          if (itemFound === false) {
            remainingData.push(
              this.state.is_default[this.state.newDefaultPathIndex][
                "all" + this.capitalizeFirstLetter(type) + "sDataLoaded"
              ][i]
            );
          }
        }
        let data = {
          name:
            type +
            "_" +
            (this.state.is_default[this.state.newDefaultPathIndex].counts[
              type + "sAdded"
            ] +
              1),
          weightageNumber: 100,
          weightagePercentage: this.calculateIndividualLanderWeightage(
            100,
            "true"
          ),
          options: remainingData,
          selectedId: remainingData[0].id,
        };
        // Push the data to the dataMap array
        // then set the values of total lander Weightage, simpleLanderId, landerWeight & landerValue
        this.setState(
          produce((draft) => {
            draft.is_default[draft.newDefaultPathIndex].dataToMap[
              type + "s"
            ].push(data);
          })
        );
        this.setState(
          produce((draft) => {
            draft.is_default[draft.newDefaultPathIndex].counts[
              type + "WeightageTotal"
            ] =
              this.state.is_default[this.state.newDefaultPathIndex].counts[
                type + "WeightageTotal"
              ] + 100;
          })
        );
        this.setState(
          produce((draft) => {
            draft.dataToSend.path.is_default[draft.newDefaultPathIndex][
              type + "Id"
            ].push(data.selectedId);
          })
        );
        this.setState(
          produce((draft) => {
            draft.dataToSend.path.is_default[draft.newDefaultPathIndex][
              type + "Weight"
            ].push(data.weightagePercentage);
          })
        );
        this.setState(
          produce((draft) => {
            draft.dataToSend.path.is_default[draft.newDefaultPathIndex][
              type + "Value"
            ].push(data.weightageNumber);
          })
        );
        setTimeout(() => {
          this.recalculateAllWeightsPercentages(type);
          this.repopulateSelectOptions(type);
        }, 50);
        setTimeout(() => {}, 300);
      }
    } else {
      let type = e.target.id.split("_")[1];
      if (typeof type !== "undefined") {
        this.setState(
          produce((draft) => {
            draft.rule[draft.newRuleIndex].path[draft.newRulePathIndex].counts[
              type + "sAdded"
            ] =
              Number(
                this.state.rule[this.state.newRuleIndex].path[
                  this.state.newRulePathIndex
                ].counts[type + "sAdded"]
              ) + 1;
          })
        );
        let remainingData = [];
        for (
          let i = 0;
          i <
          this.state.rule[this.state.newRuleIndex].path[
            this.state.newRulePathIndex
          ]["all" + this.capitalizeFirstLetter(type) + "sDataLoaded"].length;
          i++
        ) {
          let itemFound = false;
          for (
            let j = 0;
            j <
            this.state.dataToSend.path.rule[this.state.newRuleIndex].path[
              this.state.newRulePathIndex
            ][type + "Id"].length;
            j++
          ) {
            if (
              Number(
                this.state.rule[this.state.newRuleIndex].path[
                  this.state.newRulePathIndex
                ]["all" + this.capitalizeFirstLetter(type) + "sDataLoaded"][i]
                  .id
              ) ===
              Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex].path[
                  this.state.newRulePathIndex
                ][type + "Id"][j]
              )
            ) {
              itemFound = true;
            }
          }
          if (itemFound === false) {
            remainingData.push(
              this.state.rule[this.state.newRuleIndex].path[
                this.state.newRulePathIndex
              ]["all" + this.capitalizeFirstLetter(type) + "sDataLoaded"][i]
            );
          }
        }
        let data = {
          name:
            type +
            "_" +
            (this.state.rule[this.state.newRuleIndex].path[
              this.state.newRulePathIndex
            ].counts[type + "sAdded"] +
              1),
          weightageNumber: 100,
          weightagePercentage: this.calculateIndividualLanderWeightage(
            100,
            "true"
          ),
          options: remainingData,
          selectedId: remainingData[0].id,
        };
        // Push the data to the dataMap array
        // then set the values of total lander Weightage, simpleLanderId, landerWeight & landerValue
        this.setState(
          produce((draft) => {
            draft.rule[draft.newRuleIndex].path[
              draft.newRulePathIndex
            ].dataToMap[type + "s"].push(data);
          })
        );
        this.setState(
          produce((draft) => {
            draft.rule[draft.newRuleIndex].path[draft.newRulePathIndex].counts[
              type + "WeightageTotal"
            ] =
              this.state.rule[this.state.newRuleIndex].path[
                this.state.newRulePathIndex
              ].counts[type + "WeightageTotal"] + 100;
          })
        );
        this.setState(
          produce((draft) => {
            draft.dataToSend.path.rule[draft.newRuleIndex].path[
              draft.newRulePathIndex
            ][type + "Id"].push(data.selectedId);
          })
        );
        this.setState(
          produce((draft) => {
            draft.dataToSend.path.rule[draft.newRuleIndex].path[
              draft.newRulePathIndex
            ][type + "Weight"].push(data.weightagePercentage);
          })
        );
        this.setState(
          produce((draft) => {
            draft.dataToSend.path.rule[draft.newRuleIndex].path[
              draft.newRulePathIndex
            ][type + "Value"].push(data.weightageNumber);
          })
        );
        setTimeout(() => {
          this.recalculateAllWeightsPercentages(type);
          this.repopulateSelectOptions(type);
        }, 50);
        setTimeout(() => {}, 300);
      }
    }
  };

  capitalizeFirstLetter = (string) => {
    if (string !== undefined || string !== null) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  };

  initialRepopulateSelectOptions = (
    onDefaultPathClick,
    type,
    index,
    index2
  ) => {
    if (onDefaultPathClick) {
      // console.log('true');
      let remainingOptions = [];
      if (
        this.state.is_default[index][
          "all" + this.capitalizeFirstLetter(type) + "sDataLoaded"
        ] !== null &&
        typeof this.state.is_default[index][
          "all" + this.capitalizeFirstLetter(type) + "sDataLoaded"
        ] !== "undefined"
      ) {
        // console.log('yess');
        for (
          let i = 0;
          i <
          this.state.is_default[index][
            "all" + this.capitalizeFirstLetter(type) + "sDataLoaded"
          ].length;
          i++
        ) {
          let itemFound = false;
          for (
            let j = 0;
            j <
            this.state.dataToSend.path.is_default[index][type + "Id"].length;
            j++
          ) {
            if (
              Number(
                this.state.is_default[index][
                  "all" + this.capitalizeFirstLetter(type) + "sDataLoaded"
                ][i].id
              ) ===
              Number(
                this.state.dataToSend.path.is_default[index][type + "Id"][j]
              )
            ) {
              itemFound = true;
            }
          }
          if (itemFound === false) {
            remainingOptions.push(
              this.state.is_default[index][
                "all" + this.capitalizeFirstLetter(type) + "sDataLoaded"
              ][i]
            );
          }
        }
      }
      this.initiallyRepopulateAllSelectTagOptions(
        true,
        remainingOptions,
        type,
        index
      );
    }
    setTimeout(() => {
      if (!onDefaultPathClick) {
        // console.log('false');
        // this.setState(produce(draft => { draft.onDefaultPathClick = false }));
        if (index2 !== null && typeof index2 !== "undefined") {
          let remainingOptions = [];
          if (
            this.state.rule[index].path[index2][
              "all" + this.capitalizeFirstLetter(type) + "sDataLoaded"
            ] !== null &&
            typeof this.state.rule[index].path[index2][
              "all" + this.capitalizeFirstLetter(type) + "sDataLoaded"
            ] !== "undefined"
          ) {
            for (
              let i = 0;
              i <
              this.state.rule[index].path[index2][
                "all" + this.capitalizeFirstLetter(type) + "sDataLoaded"
              ].length;
              i++
            ) {
              let itemFound = false;
              for (
                let j = 0;
                j <
                this.state.dataToSend.path.rule[index].path[index2][type + "Id"]
                  .length;
                j++
              ) {
                if (
                  Number(
                    this.state.rule[index].path[index2][
                      "all" + this.capitalizeFirstLetter(type) + "sDataLoaded"
                    ][i].id
                  ) ===
                  Number(
                    this.state.dataToSend.path.rule[index].path[index2][
                      type + "Id"
                    ][j]
                  )
                ) {
                  itemFound = true;
                }
              }
              if (itemFound === false) {
                remainingOptions.push(
                  this.state.rule[index].path[index2][
                    "all" + this.capitalizeFirstLetter(type) + "sDataLoaded"
                  ][i]
                );
              }
              this.initiallyRepopulateAllSelectTagOptions(
                false,
                remainingOptions,
                type,
                index,
                index2
              );
            }
          }
        }
      }
    }, 100);
  };

  // function for both landers and offers to re-Populate the select tag options
  // re populate dataToMap select tags

  // this functions check all the remaining options.....
  repopulateSelectOptions = (type) => {
    if (this.state.onDefaultPathClick) {
      if (typeof type !== "undefined") {
        let remainingOptions = [];
        for (
          let i = 0;
          i <
          this.state.is_default[this.state.newDefaultPathIndex][
            "all" + this.capitalizeFirstLetter(type) + "sDataLoaded"
          ].length;
          i++
        ) {
          let itemFound = false;
          for (
            let j = 0;
            j <
            this.state.dataToSend.path.is_default[
              this.state.newDefaultPathIndex
            ][type + "Id"].length;
            j++
          ) {
            if (
              Number(
                this.state.is_default[this.state.newDefaultPathIndex][
                  "all" + this.capitalizeFirstLetter(type) + "sDataLoaded"
                ][i].id
              ) ===
              Number(
                this.state.dataToSend.path.is_default[
                  this.state.newDefaultPathIndex
                ][type + "Id"][j]
              )
            ) {
              itemFound = true;
            }
          }
          if (itemFound === false) {
            remainingOptions.push(
              this.state.is_default[this.state.newDefaultPathIndex][
                "all" + this.capitalizeFirstLetter(type) + "sDataLoaded"
              ][i]
            );
          }
        }
        this.repopulateAllSelectTagOptions(remainingOptions, type);
      }
    } else {
      if (typeof type !== "undefined") {
        let remainingOptions = [];
        for (
          let i = 0;
          i <
          this.state.rule[this.state.newRuleIndex].path[
            this.state.newRulePathIndex
          ]["all" + this.capitalizeFirstLetter(type) + "sDataLoaded"].length;
          i++
        ) {
          let itemFound = false;
          for (
            let j = 0;
            j <
            this.state.dataToSend.path.rule[this.state.newRuleIndex].path[
              this.state.newRulePathIndex
            ][type + "Id"].length;
            j++
          ) {
            if (
              Number(
                this.state.rule[this.state.newRuleIndex].path[
                  this.state.newRulePathIndex
                ]["all" + this.capitalizeFirstLetter(type) + "sDataLoaded"][i]
                  .id
              ) ===
              Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex].path[
                  this.state.newRulePathIndex
                ][type + "Id"][j]
              )
            ) {
              itemFound = true;
            }
          }
          if (itemFound === false) {
            remainingOptions.push(
              this.state.rule[this.state.newRuleIndex].path[
                this.state.newRulePathIndex
              ]["all" + this.capitalizeFirstLetter(type) + "sDataLoaded"][i]
            );
          }
        }
        this.repopulateAllSelectTagOptions(remainingOptions, type);
      }
    }
  };

  initiallyRepopulateAllSelectTagOptions = (
    onDefaultPathClick,
    options,
    type,
    index,
    index2
  ) => {
    if (onDefaultPathClick) {
      setTimeout(() => {
        if (
          this.state.is_default[index].dataToMap[type + "s"] !== null &&
          typeof this.state.is_default[index].dataToMap[type + "s"] !==
            "undefined"
        ) {
          for (
            let i = 0;
            i < this.state.is_default[index].dataToMap[type + "s"].length;
            i++
          ) {
            let selectOptions = [...options];
            if (
              typeof this.state.is_default[index][
                "all" + this.capitalizeFirstLetter(type) + "sDataLoaded"
              ] !== "undefined" &&
              this.state.is_default[index][
                "all" + this.capitalizeFirstLetter(type) + "sDataLoaded"
              ] !== null
            ) {
              for (
                let j = 0;
                j <
                this.state.is_default[index][
                  "all" + this.capitalizeFirstLetter(type) + "sDataLoaded"
                ].length;
                j++
              ) {
                if (
                  Number(
                    this.state.is_default[index][
                      "all" + this.capitalizeFirstLetter(type) + "sDataLoaded"
                    ][j].id
                  ) ===
                  Number(
                    this.state.is_default[index].dataToMap[type + "s"][i]
                      .selectedId
                  )
                ) {
                  selectOptions.push(
                    this.state.is_default[index][
                      "all" + this.capitalizeFirstLetter(type) + "sDataLoaded"
                    ][j]
                  );
                }
              }
            }
            this.setState(
              produce((draft) => {
                draft.is_default[index].dataToMap[type + "s"][i].options =
                  selectOptions;
              })
            );
          }
        }
      }, 100);
    } else {
      if (
        this.state.rule[index].path[index2].dataToMap[type + "s"] !== null &&
        typeof this.state.rule[index].path[index2].dataToMap[type + "s"] !==
          "undefined"
      ) {
        for (
          let i = 0;
          i < this.state.rule[index].path[index2].dataToMap[type + "s"].length;
          i++
        ) {
          let selectOptions = [...options];
          if (
            this.state.rule[index].path[index2][
              "all" + this.capitalizeFirstLetter(type) + "sDataLoaded"
            ] !== null &&
            typeof this.state.rule[index].path[index2][
              "all" + this.capitalizeFirstLetter(type) + "sDataLoaded"
            ] !== "undefined"
          ) {
            for (
              let j = 0;
              j <
              this.state.rule[index].path[index2][
                "all" + this.capitalizeFirstLetter(type) + "sDataLoaded"
              ].length;
              j++
            ) {
              if (
                Number(
                  this.state.rule[index].path[index2][
                    "all" + this.capitalizeFirstLetter(type) + "sDataLoaded"
                  ][j].id
                ) ===
                Number(
                  this.state.rule[index].path[index2].dataToMap[type + "s"][i]
                    .selectedId
                )
              ) {
                selectOptions.push(
                  this.state.rule[index].path[index2][
                    "all" + this.capitalizeFirstLetter(type) + "sDataLoaded"
                  ][j]
                );
              }
            }
          }
          this.setState(
            produce((draft) => {
              draft.rule[index].path[index2].dataToMap[type + "s"][i].options =
                selectOptions;
            })
          );
        }
      }
    }
  };
  // this function populated all the remaining options to all the select tags
  repopulateAllSelectTagOptions = (options, type) => {
    if (this.state.onDefaultPathClick) {
      if (typeof type !== "undefined") {
        for (
          let i = 0;
          i <
          this.state.is_default[this.state.newDefaultPathIndex].dataToMap[
            type + "s"
          ].length;
          i++
        ) {
          let selectOptions = [...options];
          // match the selected id of each lander/offer to add it to the options
          for (
            let j = 0;
            j <
            this.state.is_default[this.state.newDefaultPathIndex][
              "all" + this.capitalizeFirstLetter(type) + "sDataLoaded"
            ].length;
            j++
          ) {
            if (
              Number(
                this.state.is_default[this.state.newDefaultPathIndex][
                  "all" + this.capitalizeFirstLetter(type) + "sDataLoaded"
                ][j].id
              ) ===
              Number(
                this.state.is_default[this.state.newDefaultPathIndex].dataToMap[
                  type + "s"
                ][i].selectedId
              )
            ) {
              selectOptions.push(
                this.state.is_default[this.state.newDefaultPathIndex][
                  "all" + this.capitalizeFirstLetter(type) + "sDataLoaded"
                ][j]
              );
            }
          }
          this.setState(
            produce((draft) => {
              draft.is_default[draft.newDefaultPathIndex].dataToMap[type + "s"][
                i
              ].options = selectOptions;
            })
          );
        }
      }
    } else {
      if (typeof type !== "undefined") {
        for (
          let i = 0;
          i <
          this.state.rule[this.state.newRuleIndex].path[
            this.state.newRulePathIndex
          ].dataToMap[type + "s"].length;
          i++
        ) {
          let selectOptions = [...options];
          for (
            let j = 0;
            j <
            this.state.rule[this.state.newRuleIndex].path[
              this.state.newRulePathIndex
            ]["all" + this.capitalizeFirstLetter(type) + "sDataLoaded"].length;
            j++
          ) {
            if (
              Number(
                this.state.rule[this.state.newRuleIndex].path[
                  this.state.newRulePathIndex
                ]["all" + this.capitalizeFirstLetter(type) + "sDataLoaded"][j]
                  .id
              ) ===
              Number(
                this.state.rule[this.state.newRuleIndex].path[
                  this.state.newRulePathIndex
                ].dataToMap[type + "s"][i].selectedId
              )
            ) {
              selectOptions.push(
                this.state.rule[this.state.newRuleIndex].path[
                  this.state.newRulePathIndex
                ]["all" + this.capitalizeFirstLetter(type) + "sDataLoaded"][j]
              );
            }
          }
          this.setState(
            produce((draft) => {
              draft.rule[draft.newRuleIndex].path[
                draft.newRulePathIndex
              ].dataToMap[type + "s"][i].options = selectOptions;
            })
          );
        }
      }
    }
  };

  initialCalculatePlaceholderName = (index) => {
    let newArray = "";
    // if(this.state.ruleConditionName[this.state.newRuleIndex][0]!==undefined){
    //     this.setState(produce(draft=>{draft.ruleConditionName[this.state.newRuleIndex].length=0}));
    // }
    if (this.state.dataToSend.path.rule.length > 0) {
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.connectionType !==
            null &&
          this.state.dataToSend.path.rule[index].conditions.connectionType !==
            undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.connectionType
              .connection.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.connectionType
                .connection[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.connectionType
                .connection[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.connectionType
                  .connection.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.deviceType !==
            null &&
          this.state.dataToSend.path.rule[index].conditions.deviceType !==
            undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.deviceType.device
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.deviceType
                .device[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.deviceType
                .device[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.deviceType
                  .device.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.region !== null &&
          this.state.dataToSend.path.rule[index].conditions.region !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.region.regions
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.region
                .regions[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.region
                .regions[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.region.regions
                  .length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.proxy !== null &&
          this.state.dataToSend.path.rule[index].conditions.proxy !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.proxy.proxies
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.proxy.proxies[0]
                .name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.proxy.proxies[0]
                .name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.proxy.proxies
                  .length
              ) -
                1) +
              ") & ";
          }
        }
      }

      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.city !== null &&
          this.state.dataToSend.path.rule[index].conditions.city !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.city.cities
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.city.cities[0]
                .name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.city.cities[0]
                .name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.city.cities
                  .length
              ) -
                1) +
              ") & ";
          }
        }
      }

      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.mobileCarrier !==
            null &&
          this.state.dataToSend.path.rule[index].conditions.mobileCarrier !==
            undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.mobileCarrier
              .mobileCarrieries.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.mobileCarrier
                .mobileCarrieries[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.mobileCarrier
                .mobileCarrieries[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.mobileCarrier
                  .mobileCarrieries.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.country !== null &&
          this.state.dataToSend.path.rule[index].conditions.country !==
            undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.country.countries
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.country
                .countries[0].country_name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.country
                .countries[0].country_name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.country
                  .countries.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.isp !== null &&
          this.state.dataToSend.path.rule[index].conditions.isp !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.isp.ispies
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.isp.ispies[0]
                .name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.isp.ispies[0]
                .name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.isp.ispies
                  .length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.language !== null &&
          this.state.dataToSend.path.rule[index].conditions.language !==
            undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.language.languages
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.language
                .languages[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.language
                .languages[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.language
                  .languages.length
              ) -
                1) +
              ") & ";
          }
        }
      }

      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.brand !== null &&
          this.state.dataToSend.path.rule[index].conditions.brand !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.brand.brands[0]
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.brand
                .brands[0][0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.brand
                .brands[0][0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.brand
                  .brands[0].length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.os !== null &&
          this.state.dataToSend.path.rule[index].conditions.os !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.os.oss[0]
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.os.oss[0][0]
                .name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.os.oss[0][0]
                .name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.os.oss[0]
                  .length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.browser !== null &&
          this.state.dataToSend.path.rule[index].conditions.browser !==
            undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.browser
              .browsers[0].length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.browser
                .browsers[0][0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.browser
                .browsers[0][0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.browser
                  .browsers[0].length
              ) -
                1) +
              ") & ";
          }
        }
      }

      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.weekday !== null &&
          this.state.dataToSend.path.rule[index].conditions.weekday !==
            undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.weekday.weekdays
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.weekday
                .weekdays[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.weekday
                .weekdays[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.weekday
                  .weekdays.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.ip !== null &&
          this.state.dataToSend.path.rule[index].conditions.ip !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.ip.ips.length ===
            1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.ip.ips[0].name +
              " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.ip.ips[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.ip.ips.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.referrer !== null &&
          this.state.dataToSend.path.rule[index].conditions.referrer !==
            undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.referrer.referrers
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.referrer
                .referrers[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.referrer
                .referrers[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.referrer
                  .referrers.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.referrerDomain !==
            null &&
          this.state.dataToSend.path.rule[index].conditions.referrerDomain !==
            undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.referrerDomain
              .referrerDomains.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.referrerDomain
                .referrerDomains[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.referrerDomain
                .referrerDomains[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.referrerDomain
                  .referrerDomains.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.timeofday !==
            null &&
          this.state.dataToSend.path.rule[index].conditions.timeofday !==
            undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.timeofday.timezone
              .length > 0
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.timeofday
                .timezone[0] +
              " - " +
              this.state.dataToSend.path.rule[index].conditions.timeofday
                .timezone[1] +
              " & ";
          }
          // else{
          //     newArray +=  this.state.dataToSend.path.rule[index].conditions.timeofday.ips[0].name + "(+" +
          //     (Number(this.state.dataToSend.path.rule[index].conditions.timeofday.ips.length)-1) + ") & ";
          // }
        }
      }

      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.var1 !== null &&
          this.state.dataToSend.path.rule[index].conditions.var1 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.var1.var1s
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var1.var1s[0]
                .name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var1.var1s[0]
                .name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.var1.var1s
                  .length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.var2 !== null &&
          this.state.dataToSend.path.rule[index].conditions.var2 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.var2.var2s
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var2.var2s[0]
                .name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var2.var2s[0]
                .name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.var2.var2s
                  .length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.var3 !== null &&
          this.state.dataToSend.path.rule[index].conditions.var3 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.var3.var3s
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var3.var3s[0]
                .name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var3.var3s[0]
                .name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.var3.var3s
                  .length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.var4 !== null &&
          this.state.dataToSend.path.rule[index].conditions.var4 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.var4.var4s
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var4.var4s[0]
                .name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var4.var4s[0]
                .name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.var4.var4s
                  .length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.var5 !== null &&
          this.state.dataToSend.path.rule[index].conditions.var5 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.var5.var5s
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var5.var5s[0]
                .name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var5.var5s[0]
                .name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.var5.var5s
                  .length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.var6 !== null &&
          this.state.dataToSend.path.rule[index].conditions.var6 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.var6.var6s
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var6.var6s[0]
                .name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var6.var6s[0]
                .name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.var6.var6s
                  .length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.var7 !== null &&
          this.state.dataToSend.path.rule[index].conditions.var7 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.var7.var7s
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var7.var7s[0]
                .name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var7.var7s[0]
                .name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.var7.var7s
                  .length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.var8 !== null &&
          this.state.dataToSend.path.rule[index].conditions.var8 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.var8.var8s
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var8.var8s[0]
                .name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var8.var8s[0]
                .name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.var8.var8s
                  .length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.var9 !== null &&
          this.state.dataToSend.path.rule[index].conditions.var9 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.var9.var9s
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var9.var9s[0]
                .name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var9.var9s[0]
                .name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.var9.var9s
                  .length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.var10 !== null &&
          this.state.dataToSend.path.rule[index].conditions.var10 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.var10.var10s
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var10.var10s[0]
                .name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var10.var10s[0]
                .name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.var10.var10s
                  .length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.var11 !== null &&
          this.state.dataToSend.path.rule[index].conditions.var11 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.var11.var11s
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var11.var11s[0]
                .name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var11.var11s[0]
                .name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.var11.var11s
                  .length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.var12 !== null &&
          this.state.dataToSend.path.rule[index].conditions.var12 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.var12.var12s
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var12.var12s[0]
                .name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var12.var12s[0]
                .name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.var12.var12s
                  .length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.var13 !== null &&
          this.state.dataToSend.path.rule[index].conditions.var13 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.var13.var13s
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var13.var13s[0]
                .name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var13.var13s[0]
                .name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.var13.var13s
                  .length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.var14 !== null &&
          this.state.dataToSend.path.rule[index].conditions.var14 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.var14.var14s
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var14.var14s[0]
                .name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var14.var14s[0]
                .name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.var14.var14s
                  .length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.var15 !== null &&
          this.state.dataToSend.path.rule[index].conditions.var15 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.var15.var15s
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var15.var15s[0]
                .name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var15.var15s[0]
                .name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.var15.var15s
                  .length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.var16 !== null &&
          this.state.dataToSend.path.rule[index].conditions.var16 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.var16.var16s
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var16.var16s[0]
                .name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var16.var16s[0]
                .name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.var16.var16s
                  .length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.var17 !== null &&
          this.state.dataToSend.path.rule[index].conditions.var17 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.var17.var17s
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var17.var17s[0]
                .name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var17.var17s[0]
                .name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.var17.var17s
                  .length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.var18 !== null &&
          this.state.dataToSend.path.rule[index].conditions.var18 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.var18.var18s
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var18.var18s[0]
                .name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var18.var18s[0]
                .name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.var18.var18s
                  .length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.var19 !== null &&
          this.state.dataToSend.path.rule[index].conditions.var19 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.var19.var19s
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var19.var19s[0]
                .name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var19.var19s[0]
                .name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.var19.var19s
                  .length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(this.state.dataToSend.path.rule[index].conditions).length >
        0
      ) {
        if (
          this.state.dataToSend.path.rule[index].conditions.var20 !== null &&
          this.state.dataToSend.path.rule[index].conditions.var20 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[index].conditions.var20.var20s
              .length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var20.var20s[0]
                .name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[index].conditions.var20.var20s[0]
                .name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[index].conditions.var20.var20s
                  .length
              ) -
                1) +
              ") & ";
          }
        }
      }
      this.setState(
        produce((draft) => {
          draft.ruleNameWithCondition = newArray.slice(0, -2);
        })
      );
      //console.log('rule name',this.state.dataToSend.path.rule[index].name);
      if (
        this.state.dataToSend.path.rule[index].name === newArray.slice(0, -2)
      ) {
        this.setState(
          produce((draft) => {
            draft.dataToSend.path.rule[index].name = "";
          })
        );
      }
      this.setState(
        produce((draft) => {
          draft.ruleConditionName[index][0] = newArray.slice(0, -2);
        })
      );
    }
    //console.log('newArray',newArray.slice(0,-2));

    setTimeout(() => {
      //console.log('placeholdername',this.state.ruleNameWithCondition);
      //console.log('rule name',this.state.ruleConditionName);
    }, 100);
  };

  calculateIndividualLanderWeightage = (num, isNew) => {
    if (this.state.onDefaultPathClick) {
      if (isNew) {
        return (
          (num /
            (this.state.is_default[this.state.newDefaultPathIndex].counts
              .landerWeightageTotal +
              100)) *
          100
        ).toFixed(2);
      } else {
        return (
          (num /
            this.state.is_default[this.state.newDefaultPathIndex].counts
              .landerWeightageTotal) *
          100
        ).toFixed(2);
      }
    } else {
      if (isNew) {
        return (
          (num /
            (this.state.rule[this.state.newRuleIndex].path[
              this.state.newRulePathIndex
            ].counts.landerWeightageTotal +
              100)) *
          100
        ).toFixed(2);
      } else {
        return (
          (num /
            this.state.rule[this.state.newRuleIndex].path[
              this.state.newRulePathIndex
            ].counts.landerWeightageTotal) *
          100
        ).toFixed(2);
      }
    }
  };
  calculateIndividualOfferWeightage = (num) => {
    if (this.state.onDefaultPathClick) {
      return (
        (num /
          this.state.is_default[this.state.newDefaultPathIndex].counts
            .offerWeightageTotal) *
        100
      ).toFixed(2);
    } else {
      return (
        (num /
          this.state.rule[this.state.newRuleIndex].path[
            this.state.newRulePathIndex
          ].counts.offerWeightageTotal) *
        100
      ).toFixed(2);
    }
  };

  // function for both landers and offers, where type = lander or offer
  recalculateAllWeightsPercentages = (type) => {
    if (this.state.onDefaultPathClick) {
      if (typeof type !== "undefined") {
        for (
          let j = 0;
          j <
          this.state.dataToSend.path.is_default[this.state.newDefaultPathIndex][
            type + "Weight"
          ].length;
          j++
        ) {
          // first change it in dataToSend
          this.setState(
            produce((draft) => {
              draft.dataToSend.path.is_default[draft.newDefaultPathIndex][
                type + "Weight"
              ][j] = (
                (this.state.dataToSend.path.is_default[
                  this.state.newDefaultPathIndex
                ][type + "Value"][j] /
                  this.state.is_default[this.state.newDefaultPathIndex].counts[
                    type + "WeightageTotal"
                  ]) *
                100
              ).toFixed(2);
            })
          );
          // then change it all in dataToMap
          this.setState(
            produce((draft) => {
              draft.is_default[draft.newDefaultPathIndex].dataToMap[type + "s"][
                j
              ].weightagePercentage = (
                (Number(
                  this.state.dataToSend.path.is_default[
                    this.state.newDefaultPathIndex
                  ][type + "Value"][j]
                ) /
                  Number(
                    this.state.is_default[this.state.newDefaultPathIndex]
                      .counts[type + "WeightageTotal"]
                  )) *
                100
              ).toFixed(2);
            })
          );
        }
      }
    } else {
      if (typeof type !== "undefined") {
        for (
          let j = 0;
          j <
          this.state.dataToSend.path.rule[this.state.newRuleIndex].path[
            this.state.newRulePathIndex
          ][type + "Weight"].length;
          j++
        ) {
          this.setState(
            produce((draft) => {
              draft.dataToSend.path.rule[draft.newRuleIndex].path[
                draft.newRulePathIndex
              ][type + "Weight"][j] = (
                (this.state.dataToSend.path.rule[this.state.newRuleIndex].path[
                  this.state.newRulePathIndex
                ][type + "Value"][j] /
                  this.state.rule[this.state.newRuleIndex].path[
                    this.state.newRulePathIndex
                  ].counts[type + "WeightageTotal"]) *
                100
              ).toFixed(2);
            })
          );
          this.setState(
            produce((draft) => {
              draft.rule[draft.newRuleIndex].path[
                draft.newRulePathIndex
              ].dataToMap[type + "s"][j].weightagePercentage = (
                (Number(
                  this.state.dataToSend.path.rule[this.state.newRuleIndex].path[
                    this.state.newRulePathIndex
                  ][type + "Value"][j]
                ) /
                  Number(
                    this.state.rule[this.state.newRuleIndex].path[
                      this.state.newRulePathIndex
                    ].counts[type + "WeightageTotal"]
                  )) *
                100
              ).toFixed(2);
            })
          );
        }
      }
    }
  };
  recalculateAllDefaultWeightsPercentages = (type, index) => {
    if (typeof type !== "undefined") {
      if (type === "default") {
        let length = this.state.defaultWeightageData.pathAddedCount;
        for (let j = 0; j < length; j++) {
          // then change it all in dataToMap
          this.setState(
            produce((draft) => {
              draft.dataToSend.path.is_default[j].weight = (
                (Number(this.state.dataToSend.path.is_default[j].value) /
                  Number(this.state.defaultWeightageData.pathsTotalWeightage)) *
                100
              ).toFixed(2);
            })
          );
        }
      } else {
        let length = this.state.ruleWeightageData[index].pathAddedCount;
        for (let j = 0; j < length; j++) {
          // then change it all in dataToMap
          this.setState(
            produce((draft) => {
              draft.dataToSend.path.rule[index].path[j].weight = (
                (Number(this.state.dataToSend.path.rule[index].path[j].value) /
                  Number(
                    this.state.ruleWeightageData[index].pathsTotalWeightage
                  )) *
                100
              ).toFixed(2);
            })
          );
        }
      }
    }
  };

  // increase and decrease weights
  increaseWeightMethod = (e) => {
    if (!this.state.onDefaultPathClick) {
      let type = e.target.id.split("_")[0];
      let index = e.target.id.split("_")[1];
      // set lander value in dataToSend
      // then set weightageNumber in dataToMap
      if (typeof type !== "undefined") {
        this.setState(
          produce((draft) => {
            draft.dataToSend.path.rule[draft.newRuleIndex].path[
              draft.newRulePathIndex
            ][type + "Value"][index] =
              Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex].path[
                  this.state.newRulePathIndex
                ][type + "Value"][index]
              ) + 1;
          })
        );
        this.setState(
          produce((draft) => {
            draft.rule[draft.newRuleIndex].path[
              draft.newRulePathIndex
            ].dataToMap[type + "s"][index].weightageNumber =
              Number(
                this.state.rule[this.state.newRuleIndex].path[
                  this.state.newRulePathIndex
                ].dataToMap[type + "s"][index].weightageNumber
              ) + 1;
          })
        );
        this.setState(
          produce((draft) => {
            draft.rule[draft.newRuleIndex].path[draft.newRulePathIndex].counts[
              type + "WeightageTotal"
            ] =
              Number(
                this.state.rule[this.state.newRuleIndex].path[
                  this.state.newRulePathIndex
                ].counts[type + "WeightageTotal"]
              ) + 1;
          })
        );
        setTimeout(() => {
          this.recalculateAllWeightsPercentages(type);
        }, 50);
      }
    } else {
      let type = e.target.id.split("_")[0];
      let index = e.target.id.split("_")[1];
      // set lander value in dataToSend
      // then set weightageNumber in dataToMap
      if (typeof type !== "undefined") {
        this.setState(
          produce((draft) => {
            draft.dataToSend.path.is_default[draft.newDefaultPathIndex][
              type + "Value"
            ][index] =
              Number(
                this.state.dataToSend.path.is_default[
                  this.state.newDefaultPathIndex
                ][type + "Value"][index]
              ) + 1;
          })
        );
        this.setState(
          produce((draft) => {
            draft.is_default[draft.newDefaultPathIndex].dataToMap[type + "s"][
              index
            ].weightageNumber =
              Number(
                this.state.is_default[this.state.newDefaultPathIndex].dataToMap[
                  type + "s"
                ][index].weightageNumber
              ) + 1;
          })
        );
        this.setState(
          produce((draft) => {
            draft.is_default[draft.newDefaultPathIndex].counts[
              type + "WeightageTotal"
            ] =
              Number(
                this.state.is_default[this.state.newDefaultPathIndex].counts[
                  type + "WeightageTotal"
                ]
              ) + 1;
          })
        );
        setTimeout(() => {
          this.recalculateAllWeightsPercentages(type);
        }, 50);
      }
    }
  };
  decreaseWeightMethod = (e) => {
    if (this.state.onDefaultPathClick) {
      let type = e.target.id.split("_")[0];
      let index = e.target.id.split("_")[1];
      // set lander value in dataToSend
      // then set weightageNumber in dataToMap
      if (typeof type !== "undefined") {
        if (
          this.state.dataToSend.path.is_default[this.state.newDefaultPathIndex][
            type + "Value"
          ][index] > 0
        ) {
          this.setState(
            produce((draft) => {
              draft.dataToSend.path.is_default[draft.newDefaultPathIndex][
                type + "Value"
              ][index] =
                this.state.dataToSend.path.is_default[
                  this.state.newDefaultPathIndex
                ][type + "Value"][index] - 1;
            })
          );
          this.setState(
            produce((draft) => {
              draft.is_default[draft.newDefaultPathIndex].dataToMap[type + "s"][
                index
              ].weightageNumber =
                this.state.is_default[this.state.newDefaultPathIndex].dataToMap[
                  type + "s"
                ][index].weightageNumber - 1;
            })
          );
          this.setState(
            produce((draft) => {
              draft.is_default[draft.newDefaultPathIndex].counts[
                type + "WeightageTotal"
              ] =
                this.state.is_default[this.state.newDefaultPathIndex].counts[
                  type + "WeightageTotal"
                ] - 1;
            })
          );
          setTimeout(() => {
            this.recalculateAllWeightsPercentages(type);
          }, 50);
        }
      }
    } else {
      let type = e.target.id.split("_")[0];
      let index = e.target.id.split("_")[1];
      // set lander value in dataToSend
      // then set weightageNumber in dataToMap
      if (typeof type !== "undefined") {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].path[
            this.state.newRulePathIndex
          ][type + "Value"][index] > 0
        ) {
          this.setState(
            produce((draft) => {
              draft.dataToSend.path.rule[draft.newRuleIndex].path[
                draft.newRulePathIndex
              ][type + "Value"][index] =
                this.state.dataToSend.path.rule[this.state.newRuleIndex].path[
                  this.state.newRulePathIndex
                ][type + "Value"][index] - 1;
            })
          );
          this.setState(
            produce((draft) => {
              draft.rule[draft.newRuleIndex].path[
                draft.newRulePathIndex
              ].dataToMap[type + "s"][index].weightageNumber =
                this.state.rule[this.state.newRuleIndex].path[
                  this.state.newRulePathIndex
                ].dataToMap[type + "s"][index].weightageNumber - 1;
            })
          );
          this.setState(
            produce((draft) => {
              draft.rule[draft.newRuleIndex].path[
                draft.newRulePathIndex
              ].counts[type + "WeightageTotal"] =
                this.state.rule[this.state.newRuleIndex].path[
                  this.state.newRulePathIndex
                ].counts[type + "WeightageTotal"] - 1;
            })
          );
          setTimeout(() => {
            this.recalculateAllWeightsPercentages(type);
          }, 50);
        }
      }
    }
  };
  //change weightage method

  changeWeightageHandler = (e) => {
    if (!this.state.onDefaultPathClick) {
      let type = e.target.id.split("_")[0];
      let index = e.target.id.split("_")[1];

      this.setState(
        produce((draft) => {
          draft.dataToSend.path.rule[draft.newRuleIndex].path[
            draft.newRulePathIndex
          ][type + "Value"][index] = e.target.value !== "" ? Number(e.target.value) : "";
        })
      );

      this.setState(
        produce((draft) => {
          draft.rule[draft.newRuleIndex].path[draft.newRulePathIndex].dataToMap[
            type + "s"
          ][index].weightageNumber =  e.target.value !== "" ? Number(e.target.value) : "";
        })
      );

      setTimeout(() => {
        this.recalculateTotalValueInput(type, index);
      }, 30);
      setTimeout(() => {
        this.recalculateAllWeightsPercentages(type);
      }, 70);
    } else {
      let type = e.target.id.split("_")[0];
      let index = e.target.id.split("_")[1];
      // set lander value in dataToSend
      // then set weightageNumber in dataToMap
      if (typeof type !== "undefined") {
        console.log(e.target.value)
        console.log(Number(e.target.value))
        if(e.target.value !== "") {
          this.setState(
            produce((draft) => {
              draft.dataToSend.path.is_default[draft.newDefaultPathIndex][
                type + "Value"
              ][index] = e.target.value !== "" ? Number(e.target.value) : "";
            })
          );

          this.setState(
            produce((draft) => {
              draft.is_default[draft.newDefaultPathIndex].dataToMap[type + "s"][
                index
              ].weightageNumber = e.target.value !== "" ? Number(e.target.value) : "";
            })
          );
        } else {
          this.setState(
            produce((draft) => {
              draft.dataToSend.path.is_default[draft.newDefaultPathIndex][
                type + "Value"
              ][index] = "";
            })
          );

          this.setState(
            produce((draft) => {
              draft.is_default[draft.newDefaultPathIndex].dataToMap[type + "s"][
                index
              ].weightageNumber = ""
            })
          );
        }


        setTimeout(() => {
          this.recalculateTotalValueInput(type, index);
        }, 30);
        setTimeout(() => {
          this.recalculateAllWeightsPercentages(type);
        }, 70);
      }
    }
  };
  recalculateTotalValueInput = (type, index) => {
    if (this.state.onDefaultPathClick) {
      let total = 0;
      for (
        let i = 0;
        i <
        this.state.dataToSend.path.is_default[this.state.newDefaultPathIndex][
          type + "Value"
        ].length;
        i++
      ) {
        total += Number(
          this.state.dataToSend.path.is_default[this.state.newDefaultPathIndex][
            type + "Value"
          ][i]
        );
      }

      this.setState(
        produce((draft) => {
          draft.is_default[draft.newDefaultPathIndex].counts[
            type + "WeightageTotal"
          ] = Number(total);
        })
      );
      setTimeout(() => {}, 30);
    } else {
      let total = 0;
      for (
        let i = 0;
        i <
        this.state.dataToSend.path.rule[this.state.newRuleIndex].path[
          this.state.newRulePathIndex
        ][type + "Value"].length;
        i++
      ) {
        total += Number(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].path[
            this.state.newRulePathIndex
          ][type + "Value"][i]
        );
      }
      this.setState(
        produce((draft) => {
          draft.rule[draft.newRuleIndex].path[draft.newRulePathIndex].counts[
            type + "WeightageTotal"
          ] = Number(total);
        })
      );
    }
  };

  decreaseDefaultPathWeightMethod = (e) => {
    let type = e.target.id.split("_")[0];
    let index = e.target.id.split("_")[1];
    let index2 = e.target.id.split("_")[2];
    if (typeof type !== "undefined") {
      if (type === "default") {
        this.setState(
          produce((draft) => {
            draft.defaultPathClicked = true;
          })
        );

        if (this.state.dataToSend.path.is_default[index].value > 0) {
          this.setState(
            produce((draft) => {
              draft.dataToSend.path.is_default[index].value -= 1;
            })
          );
          this.setState(
            produce((draft) => {
              draft.defaultWeightageData.pathsTotalWeightage -= 1;
            })
          );
          this.setState(
            produce((draft) => {
              draft.newDefaultPathIndex = Number(index);
            })
          );
          this.setState(
            produce((draft) => {
              draft.bgClrPathIndex = Number(index);
            })
          );
          setTimeout(() => {
            this.recalculateAllDefaultWeightsPercentages(type);
          }, 50);
          setTimeout(() => {
            this.rePopulateDefaultRuleLandersOffer();
          }, 100);
        }
      } else {
        // let type = e.target.id.split("_")[0];
        // let index = e.target.id.split("_")[1];
        // set lander value in dataToSend
        // then set weightageNumber in dataToMap
        this.setState(
          produce((draft) => {
            draft.defaultPathClicked = false;
          })
        );

        if (this.state.dataToSend.path.rule[index].path[index2].value > 0) {
          this.setState(
            produce((draft) => {
              draft.dataToSend.path.rule[index].path[index2].value -= 1;
            })
          );
          this.setState(
            produce((draft) => {
              draft.ruleWeightageData[index].pathsTotalWeightage -= 1;
            })
          );

          this.setState(
            produce((draft) => {
              draft.newRulePathIndex = Number(index2);
            })
          );
          this.setState(
            produce((draft) => {
              draft.bgClrRulePathIndex = Number(index2);
            })
          );
          setTimeout(() => {
            this.recalculateAllDefaultWeightsPercentages(type, index);
          }, 50);
          setTimeout(() => {
            this.rePopulateDefaultRuleLandersOffer();
          }, 100);
        }
      }
    }
  };
  increaseDefaultPathWeightMethod = (e) => {
    let type = e.target.id.split("_")[0];
    let index = e.target.id.split("_")[1];
    let index2 = e.target.id.split("_")[2];
    if (typeof type !== "undefined") {
      if (type === "default") {
        if (this.state.dataToSend.path.is_default[index].value > 0) {
          this.setState(
            produce((draft) => {
              draft.dataToSend.path.is_default[index].value =
                Number(this.state.dataToSend.path.is_default[index].value) + 1;
            })
          );
          this.setState(
            produce((draft) => {
              draft.defaultWeightageData.pathsTotalWeightage += 1;
            })
          );
          this.setState(
            produce((draft) => {
              draft.newDefaultPathIndex = Number(index);
            })
          );
          this.setState(
            produce((draft) => {
              draft.bgClrPathIndex = Number(index);
            })
          );
          setTimeout(() => {
            this.recalculateAllDefaultWeightsPercentages(type);
          }, 50);
          setTimeout(() => {
            this.rePopulateDefaultRuleLandersOffer();
          }, 100);
        }
      } else {
        // let type = e.target.id.split("_")[0];
        // let index = e.target.id.split("_")[1];
        // set lander value in dataToSend
        // then set weightageNumber in dataToMap
        this.setState(
          produce((draft) => {
            draft.defaultPathClicked = false;
          })
        );

        if (this.state.dataToSend.path.rule[index].path[index2].value > 0) {
          this.setState(
            produce((draft) => {
              draft.dataToSend.path.rule[index].path[index2].value =
                Number(
                  this.state.dataToSend.path.rule[index].path[index2].value
                ) + 1;
            })
          );
          this.setState(
            produce((draft) => {
              draft.ruleWeightageData[index].pathsTotalWeightage += 1;
            })
          );

          this.setState(
            produce((draft) => {
              draft.newRulePathIndex = Number(index2);
            })
          );
          this.setState(
            produce((draft) => {
              draft.bgClrRulePathIndex = Number(index2);
            })
          );
          setTimeout(() => {
            this.recalculateAllDefaultWeightsPercentages(type, index);
          }, 50);
          setTimeout(() => {
            this.rePopulateDefaultRuleLandersOffer();
          }, 100);
        }
      }
    }
  };

  // Select tag onChange handler
  weightageSelectTagChangeHandler = (e) => {
    if (this.state.onDefaultPathClick) {
      let type = e.target.id.split("_")[0];
      // let index = Number(e.target.id.split("_")[1])-1;
      let index = Number(e.target.id.split("_")[1]);
      if (typeof type !== "undefined") {
        this.setState(
          produce((draft) => {
            draft.is_default[draft.newDefaultPathIndex].dataToMap[type + "s"][
              index
            ].selectedId = e.target.value;
          })
        );
        this.setState(
          produce((draft) => {
            draft.dataToSend.path.is_default[draft.newDefaultPathIndex][
              type + "Id"
            ][index] = e.target.value;
          })
        );
        setTimeout(() => {
          this.repopulateSelectOptions(type);
        }, 50);
      }
    } else {
      let type = e.target.id.split("_")[0];
      let index = Number(e.target.id.split("_")[1]);
      if (typeof type !== "undefined") {
        this.setState(
          produce((draft) => {
            draft.rule[draft.newRuleIndex].path[
              draft.newRulePathIndex
            ].dataToMap[type + "s"][index].selectedId = e.target.value;
          })
        );
        this.setState(
          produce((draft) => {
            draft.dataToSend.path.rule[draft.newRuleIndex].path[
              draft.newRulePathIndex
            ][type + "Id"][index] = e.target.value;
          })
        );
        setTimeout(() => {
          this.repopulateSelectOptions(type);
        }, 50);
      }
    }
  };

  // delete method
  deleteWeightageMethod = (e) => {
    if (this.state.onDefaultPathClick) {
      let type = e.target.id.split("_")[0];
      let index = Number(e.target.id.split("_")[1]);
      let newSimpleItemsId = [];
      let newItemWeightList = [];
      let newItemValueList = [];
      let newDataToMapArr = [];
      let deleteItemWeightage = null;
      if (typeof type !== "undefined") {
        for (
          let i = 0;
          i <
          this.state.dataToSend.path.is_default[this.state.newDefaultPathIndex][
            type + "Id"
          ].length;
          i++
        ) {
          if (i === index) {
            deleteItemWeightage =
              this.state.dataToSend.path.is_default[
                this.state.newDefaultPathIndex
              ][type + "Value"][i];
          } else {
            newSimpleItemsId.push(
              this.state.dataToSend.path.is_default[
                this.state.newDefaultPathIndex
              ][type + "Id"][i]
            );
            newItemWeightList.push(
              this.state.dataToSend.path.is_default[
                this.state.newDefaultPathIndex
              ][type + "Weight"][i]
            );
            newItemValueList.push(
              this.state.dataToSend.path.is_default[
                this.state.newDefaultPathIndex
              ][type + "Value"][i]
            );
            newDataToMapArr.push(
              this.state.is_default[this.state.newDefaultPathIndex].dataToMap[
                type + "s"
              ][i]
            );
          }
        }
        this.setState(
          produce((draft) => {
            draft.is_default[draft.newDefaultPathIndex].counts[
              type + "sAdded"
            ] =
              Number(
                this.state.is_default[this.state.newDefaultPathIndex].counts[
                  type + "sAdded"
                ]
              ) - 1;
          })
        );
        this.setState(
          produce((draft) => {
            draft.is_default[draft.newDefaultPathIndex].counts[
              type + "WeightageTotal"
            ] =
              Number(
                this.state.is_default[this.state.newDefaultPathIndex].counts[
                  type + "WeightageTotal"
                ]
              ) - Number(deleteItemWeightage);
          })
        );
        this.setState(
          produce((draft) => {
            draft.dataToSend.path.is_default[draft.newDefaultPathIndex][
              type + "Id"
            ] = newSimpleItemsId;
          })
        );
        this.setState(
          produce((draft) => {
            draft.dataToSend.path.is_default[draft.newDefaultPathIndex][
              type + "Weight"
            ] = newItemWeightList;
          })
        );
        this.setState(
          produce((draft) => {
            draft.dataToSend.path.is_default[draft.newDefaultPathIndex][
              type + "Value"
            ] = newItemValueList;
          })
        );
        this.setState(
          produce((draft) => {
            draft.is_default[draft.newDefaultPathIndex].dataToMap[type + "s"] =
              newDataToMapArr;
          })
        );

        setTimeout(() => {
          this.recalculateAllWeightsPercentages(type);
          this.repopulateSelectOptions(type);
        }, 50);
      }
    } else {
      let type = e.target.id.split("_")[0];
      let index = Number(e.target.id.split("_")[1]);
      let newSimpleItemsId = [];
      let newItemWeightList = [];
      let newItemValueList = [];
      let newDataToMapArr = [];
      let deleteItemWeightage = null;
      if (typeof type !== "undefined") {
        for (
          let i = 0;
          i <
          this.state.dataToSend.path.rule[this.state.newRuleIndex].path[
            this.state.newRulePathIndex
          ][type + "Id"].length;
          i++
        ) {
          if (i === index) {
            deleteItemWeightage =
              this.state.dataToSend.path.rule[this.state.newRuleIndex].path[
                this.state.newRulePathIndex
              ][type + "Value"][i];
          } else {
            newSimpleItemsId.push(
              this.state.dataToSend.path.rule[this.state.newRuleIndex].path[
                this.state.newRulePathIndex
              ][type + "Id"][i]
            );
            newItemWeightList.push(
              this.state.dataToSend.path.rule[this.state.newRuleIndex].path[
                this.state.newRulePathIndex
              ][type + "Weight"][i]
            );
            newItemValueList.push(
              this.state.dataToSend.path.rule[this.state.newRuleIndex].path[
                this.state.newRulePathIndex
              ][type + "Value"][i]
            );
            newDataToMapArr.push(
              this.state.rule[this.state.newRuleIndex].path[
                this.state.newRulePathIndex
              ].dataToMap[type + "s"][i]
            );
          }
        }
        this.setState(
          produce((draft) => {
            draft.rule[draft.newRuleIndex].path[draft.newRulePathIndex].counts[
              type + "sAdded"
            ] =
              Number(
                this.state.rule[this.state.newRuleIndex].path[
                  this.state.newRulePathIndex
                ].counts[type + "sAdded"]
              ) - 1;
          })
        );
        this.setState(
          produce((draft) => {
            draft.rule[draft.newRuleIndex].path[draft.newRulePathIndex].counts[
              type + "WeightageTotal"
            ] =
              Number(
                this.state.rule[this.state.newRuleIndex].path[
                  this.state.newRulePathIndex
                ].counts[type + "WeightageTotal"]
              ) - Number(deleteItemWeightage);
          })
        );
        this.setState(
          produce((draft) => {
            draft.dataToSend.path.rule[draft.newRuleIndex].path[
              draft.newRulePathIndex
            ][type + "Id"] = newSimpleItemsId;
          })
        );
        this.setState(
          produce((draft) => {
            draft.dataToSend.path.rule[draft.newRuleIndex].path[
              draft.newRulePathIndex
            ][type + "Weight"] = newItemWeightList;
          })
        );
        this.setState(
          produce((draft) => {
            draft.dataToSend.path.rule[draft.newRuleIndex].path[
              draft.newRulePathIndex
            ][type + "Value"] = newItemValueList;
          })
        );
        this.setState(
          produce((draft) => {
            draft.rule[draft.newRuleIndex].path[
              draft.newRulePathIndex
            ].dataToMap[type + "s"] = newDataToMapArr;
          })
        );

        setTimeout(() => {
          this.recalculateAllWeightsPercentages(type);
          this.repopulateSelectOptions(type);
        }, 50);
      }
    }
  };

  // =============================================================================================
  // =============================================================================================

  // for workspace and changes
  simpleInputChangeHandler = (e) => {
    //this.checkErrors();
    if (
      e.target.name === "tsPostbackUrl" ||
      e.target.name === "tsPixelRedirect"
    ) {
      this.setState(
        produce((draft) => {
          draft.dataToSend[e.target.name] = e.value;
        })
      );
    } else if (e.target.name === "domainId") {
      this.setStateDomainName(e.target.value);
    } else {
      this.setState(
        produce((draft) => {
          draft.dataToSend[e.target.name] = e.target.value;
        })
      );
    }
  };

  // For kendo radio buttons
  simpleRadioChangeHandler = (e) => {
    //this.checkErrors();
    this.setState(
      produce((draft) => {
        draft.dataToSend[e.target.element.name] = e.value;
      })
    );
    setTimeout(() => {
      this.checkErrors();
    }, 300);
  };

  // check the change in flow value and then set it in the state
  // selectFlowHandler = (e) => {
  // }

  // for changing countries
  countryChangeHandler = (value) => {
    for (let i = 0; i < this.state.loadedCountries.length; i++) {
      if (Number(value) === this.state.loadedCountries[i].id) {
        this.setState(
          produce((draft) => {
            draft.dataToSend.countryId = this.state.loadedCountries[i].id;
          })
        );
        this.setState(
          produce((draft) => {
            draft.countryName = this.state.loadedCountries[i].country_name;
          })
        );
      }
    }
  };

  // for changing traffic Sources
  tSChangeHandler = (value) => {
    // console.log('console log traffic sources');
    if (this.state.loadedTrafficSources) {
      for (let i = 0; i < this.state.loadedTrafficSources.length; i++) {
        if (Number(value) === this.state.loadedTrafficSources[i].id) {
          this.setState(
            produce((draft) => {
              draft.dataToSend.trafficSourceId =
                this.state.loadedTrafficSources[i].id;
            })
          );
          this.setState(
            produce((draft) => {
              draft.trafficSourceName = this.state.loadedTrafficSources[i].name;
            })
          );
        }
        // console.log('searching...');
      }
    }
  };

  costModelSelectChangeHandler = (e) => {
    this.setState(
      produce((draft) => {
        draft.dataToSend.cost_model = e.target.value;
      })
    );
    if (e.target.value === "Not Tracked" || e.target.value === "Auto (CPI)") {
      this.setState(
        produce((draft) => {
          draft.errors.cost_value = null;
        })
      );
      this.setState(
        produce((draft) => {
          draft.dataToSend.cost_value = 1;
        })
      );
    }
  };

  costValueChangeHandler = (e) => {
    const type = e.target.id;
    if (
      type === "plus" &&
      this.state.dataToSend.cost_model === "CPC" &&
      this.state.dataToSend.cost_value < 100
    ) {
      this.setState(
        produce((draft) => {
          draft.dataToSend.cost_value =
            Number(this.state.dataToSend.cost_value) + 1;
        })
      );
    }
    if (
      type === "plus" &&
      this.state.dataToSend.cost_model === "CPM" &&
      this.state.dataToSend.cost_value < 1000
    ) {
      this.setState(
        produce((draft) => {
          draft.dataToSend.cost_value =
            Number(this.state.dataToSend.cost_value) + 1;
        })
      );
    }
    if (
      type === "plus" &&
      this.state.dataToSend.cost_model === "CPA" &&
      this.state.dataToSend.cost_value < 5000
    ) {
      this.setState(
        produce((draft) => {
          draft.dataToSend.cost_value =
            Number(this.state.dataToSend.cost_value) + 1;
        })
      );
    }
    if (
      type === "plus" &&
      this.state.dataToSend.cost_model === "RevShare" &&
      this.state.dataToSend.cost_value < 1000
    ) {
      this.setState(
        produce((draft) => {
          draft.dataToSend.cost_value =
            Number(this.state.dataToSend.cost_value) + 1;
        })
      );
    }
    if (
      type === "minus" &&
      (this.state.dataToSend.cost_model === "CPC" ||
        this.state.dataToSend.cost_model === "CPM") &&
      this.state.dataToSend.cost_value > 1.00001
    ) {
      this.setState(
        produce((draft) => {
          draft.dataToSend.cost_value =
            Number(this.state.dataToSend.cost_value) - 1;
        })
      );
    }
    if (
      type === "minus" &&
      this.state.dataToSend.cost_model === "CPA" &&
      this.state.dataToSend.cost_value >= 1
    ) {
      this.setState(
        produce((draft) => {
          draft.dataToSend.cost_value =
            Number(this.state.dataToSend.cost_value) - 1;
        })
      );
    }
    if (
      type === "minus" &&
      this.state.dataToSend.cost_model === "RevShare" &&
      this.state.dataToSend.cost_value >= 1
    ) {
      this.setState(
        produce((draft) => {
          draft.dataToSend.cost_value =
            Number(this.state.dataToSend.cost_value) - 1;
        })
      );
    }
  };
  costValueInputChangeHandler = (e) => {
    // change the value
    this.setState(
      produce((draft) => {
        draft.dataToSend.cost_value = e.target.value;
      })
    );
    // check for error in the value changed
    if (
      this.state.dataToSend.cost_model === "CPC" &&
      (e.target.value > 100 || e.target.value < 0.00001)
    ) {
      this.setState(
        produce((draft) => {
          draft.errors.cost_value =
            "CPC must be greater than 0.00001 and less than 100.";
        })
      );
    } else if (
      this.state.dataToSend.cost_model === "CPM" &&
      (e.target.value > 1000 || e.target.value < 0.00001)
    ) {
      this.setState(
        produce((draft) => {
          draft.errors.cost_value =
            "CPM must be greater than 0.00001 and less than 1000.";
        })
      );
    } else if (
      this.state.dataToSend.cost_model === "CPA" &&
      (e.target.value > 5000 || e.target.value < 0)
    ) {
      this.setState(
        produce((draft) => {
          draft.errors.cost_value =
            "CPA must be greater than or equal to 0 and less than or equal to 5000.";
        })
      );
    } else if (
      this.state.dataToSend.cost_model === "RevShare" &&
      (e.target.value > 1000 || e.target.value < 0)
    ) {
      this.setState(
        produce((draft) => {
          draft.errors.cost_value =
            "RevShare must be greater than or equal to 0 and less than or equal to 1000.";
        })
      );
    } else {
      this.setState(
        produce((draft) => {
          draft.errors.cost_value = null;
        })
      );
    }
  };

  domainChangeHandler = (value) => {
    for (let i = 0; i < this.state.loadedDomains.length; i++) {
      if (Number(value) === this.state.loadedDomains[i].id) {
        this.setState(
          produce((draft) => {
            draft.dataToSend.domainId = this.state.loadedDomains[i].id;
          })
        );
        this.setState(
          produce((draft) => {
            draft.trafficSourceName = this.state.loadedDomains[i].name;
          })
        );
      }
    }
  };

  // for adding landers

  // for adding custom tags
  handleTagChange = (event) => {
    const values = event.target.value;
    const lastItem = values[values.length - 1];

    if (lastItem) {
      values.pop();
      const sameItem = values.find((value) => value === lastItem);
      if (sameItem === undefined) {
        values.push(lastItem);
      }
    }
    this.setState(
      produce((draft) => {
        draft.dataToSend.tags = values;
      })
    );
  };

  checkErrors = () => {
    //default-path-lander and offer
    //default-path-lander and offer
    if (this.state.dataToSend.campaign_destination === "Path") {
      if (this.props.allLandersDataLoaded.data.length === 0) {
        this.setState(
          produce((draft) => {
            draft.errors.isLanderExist = false;
          })
        );
      }
      if (this.props.allOffersDataLoaded.data.length === 0) {
        this.setState(
          produce((draft) => {
            draft.errors.isOfferExist = false;
          })
        );
      }
      this.setState(
        produce((draft) => {
          draft.errors.flow = null;
        })
      );

      //default-path-lander and offer
      for (let i = 0; i < this.state.dataToSend.path.is_default.length; i++) {
        if (this.state.dataToSend.path.is_default[i].path_name === "") {
          this.setState(
            produce((draft) => {
              draft.errors.default_path_name = ` You must add # ${
                i + 1
              } default path's name.`;
            })
          );
        } else {
          this.setState(
            produce((draft) => {
              draft.errors.default_path_name = null;
            })
          );
        }

        if (
          this.props.allOffersDataLoaded.data.length !== 0 &&
          this.props.allLandersDataLoaded.data.length !== 0
        ) {
          if (
            this.state.dataToSend.path.is_default[i].path_destination ===
            "Lander & Offer"
          ) {
            if (
              this.state.dataToSend.path.is_default[i].landerId === null ||
              this.state.dataToSend.path.is_default[i].landerId.length === 0
            ) {
              this.setState(
                produce((draft) => {
                  draft.errors.default_path_lander = ` You must add # ${
                    i + 1
                  } default path's lander.`;
                })
              );
            } else {
              this.setState(
                produce((draft) => {
                  draft.errors.default_path_lander = null;
                })
              );
            }
          }
        }
        if (
          this.props.allOffersDataLoaded.data.length !== 0 &&
          this.props.allLandersDataLoaded.data.length === 0 &&
          this.state.dataToSend.path.is_default[i].path_destination ===
            "Lander & Offer"
        ) {
          this.setState(
            produce((draft) => {
              draft.errors.default_path_lander = ` Please select Offer Only in # ${
                i + 1
              } default path to create Campaign without Lander.`;
            })
          );
          this.setState(
            produce((draft) => {
              draft.errors.isLanderExist = false;
            })
          );
        }
        if (this.props.allOffersDataLoaded.data.length !== 0) {
          if (
            this.state.dataToSend.path.is_default[i].path_destination ===
            "Offer Only"
          ) {
            this.setState(
              produce((draft) => {
                draft.errors.default_path_lander = null;
              })
            );
          }

          if (
            this.state.dataToSend.path.is_default[i].offerId === null ||
            this.state.dataToSend.path.is_default[i].offerId.length === 0
          ) {
            this.setState(
              produce((draft) => {
                draft.errors.default_path_offer = ` You must add # ${
                  i + 1
                } default path's offer.`;
              })
            );
            break;
          } else {
            this.setState(
              produce((draft) => {
                draft.errors.default_path_offer = null;
              })
            );
            if (
              this.state.dataToSend.path.is_default[i].path_destination ===
                "Lander & Offer" &&
              this.props.allLandersDataLoaded.data.length !== 0
            ) {
              if (
                this.state.dataToSend.path.is_default[i].landerId === null ||
                this.state.dataToSend.path.is_default[i].landerId.length === 0
              ) {
                this.setState(
                  produce((draft) => {
                    draft.errors.default_path_lander = ` You must add # ${
                      i + 1
                    } default path's lander.`;
                  })
                );
                break;
              } else {
                this.setState(
                  produce((draft) => {
                    draft.errors.default_path_lander = null;
                  })
                );
              }
            }
          }
        }
        if (
          this.props.allOffersDataLoaded.data.length === 0 &&
          this.props.allLandersDataLoaded.data.length === 0
        ) {
          this.setState(
            produce((draft) => {
              draft.errors.isOfferExist = false;
            })
          );
        }
        let errors = false;
        setTimeout(() => {
          if (
            this.state.errors.default_path_lander !== null ||
            this.state.errors.default_path_lander !== null ||
            this.state.errors.default_path_name !== null
          ) {
            errors = true;
          }
        }, 30);
        if (errors) {
          break;
        }
      }
      //rule-lander-offer

      if (this.state.dataToSend.path.rule.length > 0) {
        for (let i = 0; i < this.state.dataToSend.path.rule.length; i++) {
          if (
            this.state.dataToSend.path.rule[i].name === "" &&
            Object.keys(this.state.dataToSend.path.rule[i].conditions)
              .length === 0
          ) {
            this.setState(
              produce((draft) => {
                draft.errors.rule_name = ` You must add # ${
                  i + 1
                } rule's name.`;
              })
            );
          } else {
            this.setState(
              produce((draft) => {
                draft.errors.rule_name = null;
              })
            );
          }
          let errors = false;
          for (
            let j = 0;
            j < this.state.dataToSend.path.rule[i].path.length;
            j++
          ) {
            if (this.state.dataToSend.path.rule[i].path[j].path_name === "") {
              this.setState(
                produce((draft) => {
                  draft.errors.rule_path_name = ` You must add # ${
                    j + 1
                  } path's name of rule # ${i + 1}.`;
                })
              );
            } else {
              this.setState(
                produce((draft) => {
                  draft.errors.rule_path_name = null;
                })
              );
            }

            if (
              this.props.allOffersDataLoaded.data.length !== 0 &&
              this.props.allLandersDataLoaded.data.length !== 0
            ) {
              if (
                this.state.dataToSend.path.rule[i].path[j].path_destination ===
                "Lander & Offer"
              ) {
                if (
                  this.state.dataToSend.path.rule[i].path[j].landerId ===
                    null ||
                  this.state.dataToSend.path.rule[i].path[j].landerId.length ===
                    0
                ) {
                  this.setState(
                    produce((draft) => {
                      draft.errors.rule_path_lander = ` You must add # ${
                        j + 1
                      } path's lander of rule # ${i + 1}.`;
                    })
                  );
                } else {
                  this.setState(
                    produce((draft) => {
                      draft.errors.rule_path_lander = null;
                    })
                  );
                }
              }
            }

            if (
              this.props.allOffersDataLoaded.data.length !== 0 &&
              this.props.allLandersDataLoaded.data.length === 0 &&
              this.state.dataToSend.path.rule[i].path[j].path_destination ===
                "Lander & Offer"
            ) {
              this.setState(
                produce((draft) => {
                  draft.errors.rule_path_lander = ` Please select *Offer Only* in # ${
                    j + 1
                  } path of rule # ${i + 1} to create Campaign without Lander.`;
                })
              );
            }

            if (this.props.allOffersDataLoaded.data.length !== 0) {
              if (
                this.state.dataToSend.path.rule[i].path[j].path_destination ===
                "Offer Only"
              ) {
                this.setState(
                  produce((draft) => {
                    draft.errors.rule_path_lander = null;
                  })
                );
              }

              if (
                this.state.dataToSend.path.rule[i].path[j].offerId === null ||
                this.state.dataToSend.path.rule[i].path[j].offerId.length === 0
              ) {
                this.setState(
                  produce((draft) => {
                    draft.errors.rule_path_offer = ` You must add # ${
                      j + 1
                    } path's offer of rule # ${i + 1}.`;
                  })
                );
                errors = true;
                break;
              } else {
                this.setState(
                  produce((draft) => {
                    draft.errors.rule_path_offer = null;
                  })
                );
                if (
                  this.state.dataToSend.path.rule[i].path[j]
                    .path_destination === "Lander & Offer" &&
                  this.props.allLandersDataLoaded.data.length !== 0
                ) {
                  if (
                    this.state.dataToSend.path.rule[i].path[j].landerId ===
                      null ||
                    this.state.dataToSend.path.rule[i].path[j].landerId
                      .length === 0
                  ) {
                    errors = true;
                    this.setState(
                      produce((draft) => {
                        draft.errors.rule_path_lander = ` You must add # ${
                          j + 1
                        } path's lander of rule # ${i + 1}.`;
                      })
                    );
                    break;
                  } else {
                    this.setState(
                      produce((draft) => {
                        draft.errors.rule_path_lander = null;
                      })
                    );
                  }
                }
              }
            }
            setTimeout(() => {
              if (
                this.state.errors.rule_path_offer !== null ||
                this.state.errors.rule_path_lander !== null ||
                this.state.errors.rule_name !== null ||
                this.state.errors.rule_path_name !== null
              ) {
                errors = true;
              }
            }, 10);
            if (errors) {
              break;
            }
          }
          setTimeout(() => {
            if (
              this.state.errors.rule_path_offer !== null ||
              this.state.errors.rule_path_lander !== null ||
              this.state.errors.rule_name !== null ||
              this.state.errors.rule_path_name !== null
            ) {
              errors = true;
            }
          }, 10);
          if (errors) {
            break;
          }
        }
      }
    }
    if (this.state.dataToSend.campaign_destination === "Flow") {
      this.setState(
        produce((draft) => {
          draft.errors.rule_path_offer = null;
        })
      );
      this.setState(
        produce((draft) => {
          draft.errors.rule_path_lander = null;
        })
      );
      this.setState(
        produce((draft) => {
          draft.errors.default_path_offer = null;
        })
      );
      this.setState(
        produce((draft) => {
          draft.errors.default_path_lander = null;
        })
      );
      if (this.props.allLandersDataLoaded.data.length === 0) {
        this.setState(
          produce((draft) => {
            draft.errors.isLanderExist = true;
          })
        );
      }
      if (this.props.allOffersDataLoaded.data.length === 0) {
        this.setState(
          produce((draft) => {
            draft.errors.isOfferExist = true;
          })
        );
      }
    }

    // name
    if (this.state.dataToSend.name === "") {
      this.setState(
        produce((draft) => {
          draft.errors.name = "You must add a campaigns name";
        })
      );
    } else {
      this.setState(
        produce((draft) => {
          draft.errors.name = null;
        })
      );
    }

    // check if campaign destination set to flow then check if flow is populated
    if (
      this.state.dataToSend.campaign_destination === "Flow" &&
      this.state.dataToSend.flowId === ""
    ) {
      this.setState(
        produce((draft) => {
          draft.errors.flow = "You must add a flow before you add a campaign.";
        })
      );
    } else {
      this.setState(
        produce((draft) => {
          draft.errors.flow = null;
        })
      );
    }
  };

  submitCampaignData = () => {

    const replaceValues = (value, type) => {
      let newValue;
      if(value === "" || value === "NaN" || value === "undefined" || value === NaN) {
          newValue = type === "number" ? 0 : "0"
      } else {
          newValue = value
      }
      return newValue
  }

      this.state.dataToSend.path.is_default.forEach((path,mainIndx) => {
        if(path.offerValue.some((item) => item === "" || item === "NaN" || item === NaN)) {
          let values = path.offerValue?.map((ele) => replaceValues(ele, "number"))
          this.setState(
            produce((draft) => {
              draft.dataToSend.path.is_default[mainIndx].offerValue = values;
            })
          )
        }
        if(path.offerWeight?.some((item) => item === "" || item === NaN || item === "NaN")) {
          let values = path?.offerWeight?.map((ele) => replaceValues(ele, "string"))
          this.setState(
            produce((draft) => {
              draft.dataToSend.path.is_default[mainIndx].offerWeight = values;
             }))
            }

            if(path?.landerValue?.some((item) => item === "" || item === "undefined" || item === "NaN")) {
              let values = path?.landerValue?.map((ele) => replaceValues(ele, "number"))
              this.setState(
                produce((draft) => {
                  draft.dataToSend.path.is_default[mainIndx].landerValue = values;
                })
              )
            }

            if(path?.landerWeight?.some((item) => item === "" || item === "undefined" || item === "NaN")) {
              let values = path?.landerWeight?.map((ele) => replaceValues(ele, "string"))
              this.setState(
                produce((draft) => {
                  draft.dataToSend.path.is_default[mainIndx].landerWeight = values;
                })
              )
            }
      })

        this.state.dataToSend.path?.rule?.forEach((rule,mainIndx) => {
           rule?.path?.forEach((path,index) => {
             if(path.offerValue.some((item) => item === "")) {
               let values = path.offerValue?.map((ele) => replaceValues(ele, "number"))
               this.setState(
                 produce((draft) => {
                   draft.dataToSend.path.rule[mainIndx].path[index].offerValue = values;
                 })
               )
                }

               if(path.offerWeight?.some((item) => item === "" || item === NaN || item === "NaN")) {
                 let values = path?.offerWeight?.map((ele) => replaceValues(ele, "string"))
                 this.setState(
                   produce((draft) => {
                     draft.dataToSend.path.rule[mainIndx].path[index].offerWeight = values;
                    }))
                  }
                  if(path?.landerValue?.some((item) => item === "" || item === "undefined" || item === "NaN")) {
                    let values = path?.landerValue?.map((ele) => replaceValues(ele, "number"))
                    this.setState(
                      produce((draft) => {
                        draft.dataToSend.path.rule[mainIndx].path[index].landerValue = values;
                      })
                    )
                  }

                  if(path?.landerWeight?.some((item) => item === "" || item === "undefined" || item === "NaN")) {
                    let values = path?.landerWeight?.map((ele) => replaceValues(ele, "string"))
                    this.setState(
                      produce((draft) => {
                        draft.dataToSend.path.rule[mainIndx].path[index].landerWeight = values;
                      })
                    )
                  }
                })})


    this.props.toggleLoader();
    this.checkErrors();

    setTimeout(() => {
      if (
        this.state.errors.name === null &&
        this.state.errors.default_path_name === null &&
        this.state.errors.rule_path_name === null &&
        this.state.errors.rule_name === null &&
        this.state.errors.flow === null &&
        this.state.errors.default_path_lander === null &&
        this.state.errors.default_path_offer === null &&
        this.state.errors.rule_path_lander === null &&
        this.state.errors.rule_path_offer === null
      ) {
        if (this.state.dataToSend.path.rule.length > 0) {
          for (let i = 0; i < this.state.dataToSend.path.rule.length; i++) {
            if (this.state.dataToSend.path.rule[i].name === "") {
              if (this.state.ruleConditionName[i].length > 0) {
                this.setState(
                  produce((draft) => {
                    draft.dataToSend.path.rule[i].name =
                      this.state.ruleConditionName[i][0];
                  })
                );
              }
            }
          }
        }
        this.setState(
          produce((draft) => {
            draft.resolveErrors = "";
          })
        );
        if (this.state.dataToSend.notes_app === "") {
          this.setState(
            produce((draft) => {
              draft.dataToSend.notes = null;
            })
          );
        } else {
          this.setState(
            produce((draft) => {
              draft.dataToSend.notes = this.state.dataToSend.notes_app;
            })
          );
        }
        this.setState(
          produce((draft) => {
            draft.dataToSend.countryId = String(
              this.state.dataToSend.countryId
            );
          })
        );
        this.setState(
          produce((draft) => {
            draft.dataToSend.workspaceId = String(
              this.state.dataToSend.workspaceId
            );
          })
        );
        this.setState(
          produce((draft) => {
            draft.dataToSend.trafficSourceId = String(
              this.state.dataToSend.trafficSourceId
            );
          })
        );
        this.setState(
          produce((draft) => {
            draft.dataToSend.flowId = String(this.state.dataToSend.flowId);
          })
        );
        this.setState(
          produce((draft) => {
            draft.dataToSend.cost_value = String(
              this.state.dataToSend.cost_value
            );
          })
        );
        // this.setState(produce(draft => { draft.loader = true }));
        // this.props.toggleLoader();
        if (!this.props.state.isDuplicate) {
          return this.props.dispatchPatchCampaign({
            data: this.state.dataToSend,
            accessToken: this.props.accessToken,
            sendEditCampaignId: this.props.campaignId,
          });
        } else {
          //this.setState(produce(draft => { draft.isCampaignCreated = true }));
          return this.props.dispatchPostCampaign({
            data: this.state.dataToSend,
            accessToken: this.props.accessToken,
          });
        }
        // setTimeout(() => {
        //     this.setState(produce(draft => { draft.isCampaignPatched = true }));
        // },200);
      } else {
        this.props.toggleLoader();
        this.setState(
          produce((draft) => {
            draft.resolveErrors =
              "Please make sure to resolve all errors before submitting the data.";
          })
        );
        document.getElementById("errors_container").scrollIntoView();
      }
    }, 300);
  };
  filterChange = (event) => {
    this.setState({
      data: this.filterData(event.filter),
    });
  };

  filterData(filter) {
    const data = this.props.flowsDataReceived.data.slice();
    return filterBy(data, filter);
  }

  handleSwitchChange = (e) => {
    if (e.target.element.name === "flow_redirect_mode") {
      this.setState(
        produce((draft) => {
          draft.dataToSend[e.target.element.name] = e.value;
        })
      );
    }
    if (e.target.element.name === "redirect_mode") {
      this.setState(
        produce((draft) => {
          draft.dataToSend[e.target.element.name] = e.value;
        })
      );
    }
  };
  inputChangeHandler = (e) => {
    //comment
    if (e.target.name === "flowId" && e.value !== null) {
      this.setState(
        produce((draft) => {
          draft.dataToSend[e.target.name] = e.target.value;
        })
      );
      // this.setState(produce(draft => { draft.filterData = e.value }));
    }
    if (e.target.name === "flowId" && e.value === null) {
      this.setState(
        produce((draft) => {
          draft.dataToSend[e.target.name] = "";
        })
      );
      // this.setState(produce(draft => { draft.filterData = "" }));
    }
    let type = e.target.id.split("_")[0];
    let index = e.target.id.split("_")[1];
    let index2 = e.target.id.split("_")[2];
    if (e.target.name === "weightage") {
      if (typeof type !== "undefined") {
        if (type === "default") {
          if (this.state.dataToSend.path.is_default[index].value > 0) {
            this.setState(
              produce((draft) => {
                draft.dataToSend.path.is_default[index].value = Number(
                  e.target.value
                );
              })
            );
            let total = 0;
            setTimeout(() => {
              for (
                let i = 0;
                i < this.state.dataToSend.path.is_default.length;
                i++
              ) {
                total += Number(this.state.dataToSend.path.is_default[i].value);
              }
              this.setState(
                produce((draft) => {
                  draft.defaultWeightageData.pathsTotalWeightage =
                    Number(total);
                })
              );
            }, 20);
            this.setState(
              produce((draft) => {
                draft.newDefaultPathIndex = Number(index);
              })
            );
            this.setState(
              produce((draft) => {
                draft.bgClrPathIndex = Number(index);
              })
            );
            setTimeout(() => {
              this.recalculateAllDefaultWeightsPercentages(type);
            }, 50);
            setTimeout(() => {
              this.rePopulateDefaultRuleLandersOffer();
            }, 100);
          }
        } else {
          this.setState(
            produce((draft) => {
              draft.defaultPathClicked = false;
            })
          );

          if (this.state.dataToSend.path.rule[index].path[index2].value > 0) {
            this.setState(
              produce((draft) => {
                draft.dataToSend.path.rule[index].path[index2].value = Number(
                  e.target.value
                );
              })
            );
            let total = 0;
            setTimeout(() => {
              for (
                let i = 0;
                i < this.state.dataToSend.path.rule[index].path.length;
                i++
              ) {
                total += Number(
                  this.state.dataToSend.path.rule[index].path[i].value
                );
              }
              this.setState(
                produce((draft) => {
                  draft.ruleWeightageData[index].pathsTotalWeightage =
                    Number(total);
                })
              );
            }, 20);

            this.setState(
              produce((draft) => {
                draft.newRulePathIndex = Number(index2);
              })
            );
            this.setState(
              produce((draft) => {
                draft.bgClrRulePathIndex = Number(index2);
              })
            );
            setTimeout(() => {
              this.recalculateAllDefaultWeightsPercentages(type, index);
            }, 50);
            setTimeout(() => {
              this.rePopulateDefaultRuleLandersOffer();
            }, 100);
          }
        }
      }
    }
    if (e.target.name === "flow_name") {
      if (e.value === "") {
        this.setState(
          produce((draft) => {
            draft.errors.name = "Please enter flow name.";
          })
        );
      } else {
        this.setState(
          produce((draft) => {
            draft.errors.name = null;
          })
        );
      }
      this.setState(
        produce((draft) => {
          draft.dataToSend.flow_name = e.value;
        })
      );
    }
    //this.setState(produce(draft => { draft.dataToSend[e.target.name] = e.target.value }));
    if (e.target.name === "workspaceId") {
      this.setState(
        produce((draft) => {
          draft.dataToSend[e.target.name] = e.target.value;
        })
      );
    }
    if (e.target.name === "countryId") {
      this.setState(
        produce((draft) => {
          draft.countryValue = e.nativeEvent.target.selectedIndex + 1;
        })
      );
      this.setState(
        produce((draft) => {
          draft.dataToSend[e.target.name] = e.target.value;
        })
      );
      for (let i = 0; i < this.props.countriesDataRecieved.length; i++) {
        if (e.target.value === String(this.props.countriesDataRecieved[i].id)) {
          this.setState(
            produce((draft) => {
              draft.countryName =
                this.props.countriesDataRecieved[i].country_name;
            })
          );
        }
      }
    }
  };

  onChangePane = (event) => {
    this.setState({
      panes: event.newState,
    });
  };

  toggleDialog = () => {
    this.setState({
      visible: !this.state.visible,
    });
    this.props.toggleAddFlowsDialog();
  };

  addNewPath = (index) => {
    this.setState(
      produce((draft) => {
        draft.bgClrPathIndex = null;
      })
    );
    this.setState(
      produce((draft) => {
        draft.bgClrRuleIndex = null;
      })
    );
    this.setState(
      produce((draft) => {
        draft.onDefaultPathClick = false;
      })
    );
    const path = {
      path_name: "New Path",
      weight: "100",
      value: "100",
      weight_optimization: true,
      offerId: [],
      landerId: [],
      offerWeight: [],
      landerWeight: [],
      offerValue: [],
      landerValue: [],
      path_redirect_mode: "302",
      path_destination: "Lander & Offer",
    };
    const outerPath = {
      dataToMap: {
        landers: [],
        offers: [],
      },
      counts: {
        landerInBackend: 0,
        landersAdded: 0,
        offersInBackend: 0,
        offersAdded: 0,
        landerWeightageTotal: 0,
        offerWeightageTotal: 0,
      },
      allLandersDataLoaded: null,
      allOffersDataLoaded: null,
    };
    this.setState(
      produce((draft) => {
        draft.dataToSend.path.rule[index].path.push(path);
      })
    );
    this.setState(
      produce((draft) => {
        draft.rule[index].path.push(outerPath);
      })
    );
    this.setState(
      produce((draft) => {
        draft.ruleWeightageData[index].pathAddedCount += 1;
      })
    );
    this.setState(
      produce((draft) => {
        draft.ruleWeightageData[index].pathsTotalWeightage += 100;
      })
    );
    this.setState(
      produce((draft) => {
        draft.newPathIndex = index;
      })
    );
    this.setState(
      produce((draft) => {
        draft.bgClrRulePathIndex = index;
      })
    );
    this.setState(
      produce((draft) => {
        draft.onRuleClick = false;
      })
    );
    this.setState(
      produce((draft) => {
        draft.onFlowClick = false;
      })
    );
    this.setState(
      produce((draft) => {
        draft.onPathClick = true;
      })
    );
    setTimeout(() => {
      this.recalculateAllDefaultWeightsPercentages("rule_path", index);
    }, 100);
  };
  addDefaultPath = () => {
    this.setState(
      produce((draft) => {
        draft.onDefaultPathClick = true;
      })
    );
    this.setState(
      produce((draft) => {
        draft.bgClrRuleIndex = null;
      })
    );
    this.setState(
      produce((draft) => {
        draft.bgClrRulePathIndex = null;
      })
    );
    const defaultPath = {
      weight: "100",
      value: "100",
      weight_optimization: true,
      path_name: "New Path",
      offerId: [],
      landerId: [],
      offerWeight: [],
      landerWeight: [],
      offerValue: [],
      landerValue: [],
      path_redirect_mode: "302",
      path_destination: "Lander & Offer",
    };
    const outerDefaultPath = {
      dataToMap: {
        landers: [],
        offers: [],
      },
      counts: {
        landerInBackend: 0,
        landersAdded: 0,
        offersInBackend: 0,
        offersAdded: 0,
        landerWeightageTotal: 0,
        offerWeightageTotal: 0,
      },
      allLandersDataLoaded: null,
      allOffersDataLoaded: null,
    };
    this.setState(
      produce((draft) => {
        draft.dataToSend.path.is_default.push(defaultPath);
      })
    );
    this.setState(
      produce((draft) => {
        draft.is_default.push(outerDefaultPath);
      })
    );
    this.setState(
      produce((draft) => {
        draft.onRuleClick = false;
      })
    );
    this.setState(
      produce((draft) => {
        draft.onFlowClick = false;
      })
    );
    this.setState(
      produce((draft) => {
        draft.onPathClick = true;
      })
    );
    this.setState(
      produce((draft) => {
        draft.bgClrPathIndex = 0;
      })
    );
    this.setState(
      produce((draft) => {
        draft.defaultWeightageData.pathAddedCount =
          this.state.defaultWeightageData.pathAddedCount + 1;
        draft.defaultWeightageData.pathsTotalWeightage =
          this.state.defaultWeightageData.pathsTotalWeightage + 100;
      })
    );
    setTimeout(() => {
      this.recalculateAllDefaultWeightsPercentages("default");
    }, 50);
    //this.setState(produce(draft => { draft.newDefaultPathIndex = index}));
  };
  defaultPathClicked = (index) => {
    this.setState(
      produce((draft) => {
        draft.onDefaultPathClick = true;
      })
    );
    this.setState(
      produce((draft) => {
        draft.newDefaultPathIndex = index;
      })
    );
    this.setState(
      produce((draft) => {
        draft.bgClrPathIndex = index;
      })
    );
    this.setState(
      produce((draft) => {
        draft.bgClrRuleIndex = null;
      })
    );
    this.setState(
      produce((draft) => {
        draft.bgClrRulePathIndex = null;
      })
    );
    this.setState(
      produce((draft) => {
        draft.onRuleClick = false;
      })
    );
    this.setState(
      produce((draft) => {
        draft.onFlowClick = false;
      })
    );
    this.setState(
      produce((draft) => {
        draft.onPathClick = true;
      })
    );
    setTimeout(() => {
      this.rePopulateDefaultRuleLandersOffer();
    }, 100);
  };
  calculatePlaceholderName = () => {
    let newArray = "";
    // if(this.state.ruleConditionName[this.state.newRuleIndex][0]!==undefined){
    //     this.setState(produce(draft=>{draft.ruleConditionName[this.state.newRuleIndex].length=0}));
    // }
    if (this.state.dataToSend.path.rule.length > 0) {
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .connectionType !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .connectionType !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .connectionType.connection.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.connectionType.connection[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.connectionType.connection[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.connectionType.connection.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .deviceType !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .deviceType !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .deviceType.device.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.deviceType.device[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.deviceType.device[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.deviceType.device.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .region !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .region !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .region.regions.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.region.regions[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.region.regions[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.region.regions.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .proxy !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .proxy !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .proxy.proxies.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.proxy.proxies[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.proxy.proxies[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.proxy.proxies.length
              ) -
                1) +
              ") & ";
          }
        }
      }

      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .city !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .city !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .city.cities.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.city.cities[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.city.cities[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.city.cities.length
              ) -
                1) +
              ") & ";
          }
        }
      }

      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .mobileCarrier !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .mobileCarrier !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .mobileCarrier.mobileCarrieries.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.mobileCarrier.mobileCarrieries[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.mobileCarrier.mobileCarrieries[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.mobileCarrier.mobileCarrieries.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .country !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .country !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .country.countries.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.country.countries[0].country_name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.country.countries[0].country_name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.country.countries.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .isp !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .isp !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .isp.ispies.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.isp.ispies[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.isp.ispies[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.isp.ispies.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .language !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .language !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .language.languages.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.language.languages[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.language.languages[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.language.languages.length
              ) -
                1) +
              ") & ";
          }
        }
      }

      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .brand !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .brand !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .brand.brands[0].length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.brand.brands[0][0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.brand.brands[0][0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.brand.brands[0].length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .os !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .os !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .os.oss[0].length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.os.oss[0][0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.os.oss[0][0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.os.oss[0].length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .browser !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .browser !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .browser.browsers[0].length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.browser.browsers[0][0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.browser.browsers[0][0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.browser.browsers[0].length
              ) -
                1) +
              ") & ";
          }
        }
      }

      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .weekday !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .weekday !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .weekday.weekdays.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.weekday.weekdays[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.weekday.weekdays[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.weekday.weekdays.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .ip !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .ip !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .ip.ips.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.ip.ips[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.ip.ips[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.ip.ips.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .referrer !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .referrer !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .referrer.referrers.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.referrer.referrers[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.referrer.referrers[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.referrer.referrers.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .referrerDomain !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .referrerDomain !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .referrerDomain.referrerDomains.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.referrerDomain.referrerDomains[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.referrerDomain.referrerDomains[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.referrerDomain.referrerDomains.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .timeofday !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .timeofday !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .timeofday.timezone.length > 0
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.timeofday.timezone[0] +
              " - " +
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.timeofday.timezone[1] +
              " & ";
          }
          // else{
          //     newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.timeofday.ips[0].name + "(+" +
          //     (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.timeofday.ips.length)-1) + ") & ";
          // }
        }
      }

      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var1 !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var1 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .var1.var1s.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var1.var1s[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var1.var1s[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.var1.var1s.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var2 !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var2 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .var2.var2s.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var2.var2s[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var2.var2s[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.var2.var2s.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var3 !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var3 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .var3.var3s.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var3.var3s[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var3.var3s[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.var3.var3s.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var4 !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var4 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .var4.var4s.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var4.var4s[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var4.var4s[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.var4.var4s.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var5 !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var5 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .var5.var5s.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var5.var5s[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var5.var5s[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.var5.var5s.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var6 !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var6 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .var6.var6s.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var6.var6s[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var6.var6s[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.var6.var6s.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var7 !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var7 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .var7.var7s.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var7.var7s[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var7.var7s[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.var7.var7s.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var8 !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var8 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .var8.var8s.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var8.var8s[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var8.var8s[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.var8.var8s.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var9 !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var9 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .var9.var9s.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var9.var9s[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var9.var9s[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.var9.var9s.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var10 !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var10 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .var10.var10s.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var10.var10s[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var10.var10s[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.var10.var10s.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var11 !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var11 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .var11.var11s.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var11.var11s[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var11.var11s[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.var11.var11s.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var12 !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var12 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .var12.var12s.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var12.var12s[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var12.var12s[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.var12.var12s.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var13 !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var13 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .var13.var13s.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var13.var13s[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var13.var13s[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.var13.var13s.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var14 !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var14 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .var14.var14s.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var14.var14s[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var14.var14s[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.var14.var14s.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var15 !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var15 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .var15.var15s.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var15.var15s[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var15.var15s[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.var15.var15s.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var16 !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var16 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .var16.var16s.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var16.var16s[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var16.var16s[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.var16.var16s.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var17 !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var17 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .var17.var17s.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var17.var17s[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var17.var17s[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.var17.var17s.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var18 !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var18 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .var18.var18s.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var18.var18s[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var18.var18s[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.var18.var18s.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var19 !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var19 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .var19.var19s.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var19.var19s[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var19.var19s[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.var19.var19s.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      if (
        Object.keys(
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
        ).length > 0
      ) {
        if (
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var20 !== null &&
          this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .var20 !== undefined
        ) {
          if (
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .var20.var20s.length === 1
          ) {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var20.var20s[0].name + " & ";
          } else {
            newArray +=
              this.state.dataToSend.path.rule[this.state.newRuleIndex]
                .conditions.var20.var20s[0].name +
              "(+" +
              (Number(
                this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .conditions.var20.var20s.length
              ) -
                1) +
              ") & ";
          }
        }
      }
      this.setState(
        produce((draft) => {
          draft.ruleNameWithCondition = newArray.slice(0, -2);
        })
      );
      this.setState(
        produce((draft) => {
          draft.ruleConditionName[this.state.newRuleIndex][0] = newArray.slice(
            0,
            -2
          );
        })
      );
    }
    //console.log('newArray',newArray.slice(0,-2));

    setTimeout(() => {
      //console.log('placeholdername',this.state.ruleNameWithCondition);
      //console.log('rule name',this.state.ruleConditionName);
    }, 100);
  };
  handleDefaultPathChange = (e) => {
    if (e.value === "") {
      this.setState(
        produce((draft) => {
          draft.errors.default_path_name = "Please enter path name.";
        })
      );
    } else {
      this.setState(
        produce((draft) => {
          draft.errors.default_path_name = null;
        })
      );
    }
    this.setState(
      produce((draft) => {
        draft.dataToSend.path.is_default[this.state.newDefaultPathIndex][
          e.target.element.name
        ] = e.value;
      })
    );

    if (
      e.target.element.name === "path_destination" &&
      e.value === "Offer Only" &&
      this.state.errors.isOfferExist &&
      this.props.allLandersDataLoaded.data.length === 0
    ) {
      this.setState(
        produce((draft) => {
          draft.errors.isLanderExist = true;
        })
      );
    }
    if (
      e.target.element.name === "path_destination" &&
      e.value === "Lander & Offer" &&
      this.state.errors.isOfferExist &&
      this.props.allLandersDataLoaded.data.length === 0
    ) {
      this.setState(
        produce((draft) => {
          draft.errors.isLanderExist = false;
        })
      );
    }
  };
  handleRulePathChange = (e) => {
    if (e.value === "") {
      this.setState(
        produce((draft) => {
          draft.errors.default_path_name = "Please enter path name.";
        })
      );
    } else {
      this.setState(
        produce((draft) => {
          draft.errors.default_path_name = null;
        })
      );
    }
    this.setState(
      produce((draft) => {
        draft.dataToSend.path.rule[this.state.newRuleIndex].path[
          this.state.newRulePathIndex
        ][e.target.element.name] = e.value;
      })
    );

    if (
      e.target.element.name === "path_destination" &&
      e.value === "Offer Only" &&
      this.state.errors.isOfferExist &&
      this.props.allLandersDataLoaded.data.length === 0
    ) {
      this.setState(
        produce((draft) => {
          draft.errors.isLanderExist = true;
        })
      );
    }
    if (
      e.target.element.name === "path_destination" &&
      e.value === "Lander & Offer" &&
      this.state.errors.isOfferExist &&
      this.props.allLandersDataLoaded.data.length === 0
    ) {
      this.setState(
        produce((draft) => {
          draft.errors.isLanderExist = false;
        })
      );
    }
  };
  deleteDefaultPath = (e, id) => {
    let type = e.target.parentElement.id.split("_")[0];
    let index = Number(e.target.parentElement.id.split("_")[1]);
    let index2 = Number(e.target.parentElement.id.split("_")[2]);
    this.setState(
      produce((draft) => {
        draft.bgClrPathIndex = null;
      })
    );
    this.setState(
      produce((draft) => {
        draft.bgClrRuleIndex = null;
      })
    );
    this.setState(
      produce((draft) => {
        draft.bgClrRulePathIndex = null;
      })
    );
    if (typeof type !== "undefined") {
      if (type === "default") {
        if (this.state.dataToSend.path.is_default.length > 1) {
          if (
            typeof this.state.dataToSend.path.is_default[index].pathId !==
            "undefined"
          ) {
            this.props.deleteDefaultPath(
              this.props.accessToken,
              this.state.dataToSend.path.is_default[index].pathId
            );
          }
          this.setState(
            produce((draft) => {
              draft.success_msg =
                "Default path has been deleted successfully. Please Select new path to proceed...";
            })
          );
          let e = index;
          let newDefaultPath = [];
          let j = 0;
          let outerNewDefaultPath = [];
          let p = 0;
          let deleteWeight = this.state.dataToSend.path.is_default[index].value;
          for (
            let i = 0;
            i < this.state.dataToSend.path.is_default.length;
            i++
          ) {
            if (i !== e) {
              newDefaultPath[j] = this.state.dataToSend.path.is_default[i];
              j++;
            }
          }
          for (let i = 0; i < this.state.is_default.length; i++) {
            if (i !== e) {
              outerNewDefaultPath[p] = this.state.is_default[i];
              p++;
            }
          }
          this.setState(
            produce((draft) => {
              draft.dataToSend.path.is_default.length = 0;
            })
          );
          this.setState(
            produce((draft) => {
              draft.is_default.length = 0;
            })
          );
          setTimeout(() => {
            for (let k = 0; k < newDefaultPath.length; k++) {
              this.setState(
                produce((draft) => {
                  draft.dataToSend.path.is_default.push(newDefaultPath[k]);
                })
              );
            }
          }, 50);
          setTimeout(() => {
            for (let k = 0; k < outerNewDefaultPath.length; k++) {
              this.setState(
                produce((draft) => {
                  draft.is_default.push(outerNewDefaultPath[k]);
                })
              );
            }
          }, 100);

          this.setState(
            produce((draft) => {
              draft.onDefaultPathClick = false;
            })
          );
          this.setState(
            produce((draft) => {
              draft.onRuleClick = false;
            })
          );
          this.setState(
            produce((draft) => {
              draft.onFlowClick = false;
            })
          );
          this.setState(
            produce((draft) => {
              draft.onPathClick = false;
            })
          );
          this.setState(
            produce((draft) => {
              draft.defaultWeightageData.pathAddedCount =
                this.state.defaultWeightageData.pathAddedCount - 1;
              draft.defaultWeightageData.pathsTotalWeightage =
                this.state.defaultWeightageData.pathsTotalWeightage -
                deleteWeight;
            })
          );
          setTimeout(() => {
            this.recalculateAllDefaultWeightsPercentages("default");
          }, 200);
        }
        if (this.state.dataToSend.path.is_default.length === 1) {
          //alert("cant delete")
        }
        if (this.state.newDefaultPathIndex > 0) {
          this.setState(
            produce((draft) => {
              draft.newDefaultPathIndex = this.state.newDefaultPathIndex - 1;
            })
          );
        }
        if (this.state.newDefaultPathIndex === 0) {
          this.setState(
            produce((draft) => {
              draft.newDefaultPathIndex = 0;
            })
          );
        }

        if (this.state.dataToSend.path.is_default.length === 1) {
          this.setState(
            produce((draft) => {
              draft.success_msg = "";
            })
          );
          this.setState(
            produce((draft) => {
              draft.warning_msg =
                "Only default path can not be deleted. Please Select new path to proceed...";
            })
          );
          this.setState(
            produce((draft) => {
              draft.onDefaultPathClick = false;
            })
          );
          this.setState(
            produce((draft) => {
              draft.onRuleClick = false;
            })
          );
          this.setState(
            produce((draft) => {
              draft.onFlowClick = false;
            })
          );
          this.setState(
            produce((draft) => {
              draft.onPathClick = false;
            })
          );
        }
      } else {
        if (this.state.dataToSend.path.rule.length > 0) {
          if (type === "rule") {
            if (this.state.dataToSend.path.rule[index].path.length > 1) {
              if (
                typeof this.state.dataToSend.path.rule[this.state.newRuleIndex]
                  .path[index].pathId !== "undefined"
              ) {
                this.props.deleteDefaultPath(
                  this.props.accessToken,
                  this.state.dataToSend.path.rule[this.state.newRuleIndex].path[
                    index
                  ].pathId
                );
              }
              this.setState(
                produce((draft) => {
                  draft.success_msg =
                    "New rule path has been deleted successfully. Please Select new path to proceed...";
                })
              );
              let e = index2;
              let newDefaultPath = [];
              let j = 0;
              let outerNewDefaultPath = [];
              let p = 0;
              let deleteWeight;
              for (
                let i = 0;
                i < this.state.dataToSend.path.rule[index].path.length;
                i++
              ) {
                if (i !== e) {
                  newDefaultPath[j] =
                    this.state.dataToSend.path.rule[index].path[i];
                  j++;
                }
                if (i === e) {
                  deleteWeight =
                    this.state.dataToSend.path.rule[index].path[i].value;
                }
              }
              for (let i = 0; i < this.state.rule[index].path.length; i++) {
                if (i !== e) {
                  outerNewDefaultPath[p] = this.state.rule[index].path[i];
                  p++;
                }
              }
              this.setState(
                produce((draft) => {
                  draft.dataToSend.path.rule[index].path.length = 0;
                })
              );
              this.setState(
                produce((draft) => {
                  draft.rule[index].path.length = 0;
                })
              );
              setTimeout(() => {
                for (let k = 0; k < newDefaultPath.length; k++) {
                  this.setState(
                    produce((draft) => {
                      draft.dataToSend.path.rule[index].path.push(
                        newDefaultPath[k]
                      );
                    })
                  );
                }
              }, 50);
              setTimeout(() => {
                for (let k = 0; k < outerNewDefaultPath.length; k++) {
                  this.setState(
                    produce((draft) => {
                      draft.rule[index].path.push(outerNewDefaultPath[k]);
                    })
                  );
                }
              }, 50);
              this.setState(
                produce((draft) => {
                  draft.onDefaultPathClick = false;
                })
              );
              this.setState(
                produce((draft) => {
                  draft.onRuleClick = false;
                })
              );
              this.setState(
                produce((draft) => {
                  draft.onFlowClick = false;
                })
              );
              this.setState(
                produce((draft) => {
                  draft.onPathClick = false;
                })
              );
              this.setState(
                produce((draft) => {
                  draft.ruleWeightageData[index].pathAddedCount =
                    this.state.ruleWeightageData[index].pathAddedCount - 1;
                  draft.ruleWeightageData[index].pathsTotalWeightage =
                    this.state.ruleWeightageData[index].pathsTotalWeightage -
                    deleteWeight;
                })
              );
              setTimeout(() => {
                this.recalculateAllDefaultWeightsPercentages("rule", index);
              }, 200);
            }
            if (this.state.dataToSend.path.rule[index].path.length === 1) {
              this.setState(
                produce((draft) => {
                  draft.warning_msg =
                    "Only rule path can not be deleted. Please select new path to proceed..";
                })
              );
              this.setState(
                produce((draft) => {
                  draft.success_msg = "";
                })
              );
              this.setState(
                produce((draft) => {
                  draft.onDefaultPathClick = false;
                })
              );
              this.setState(
                produce((draft) => {
                  draft.onRuleClick = false;
                })
              );
              this.setState(
                produce((draft) => {
                  draft.onFlowClick = false;
                })
              );
              this.setState(
                produce((draft) => {
                  draft.onPathClick = false;
                })
              );
            }
          }
        }
      }
    }
  };
  onRuleNameChange = (e) => {
    if (e.value === "") {
      this.setState(
        produce((draft) => {
          draft.errors.rule_name = "Please enter rule name.";
        })
      );
    } else {
      this.setState(
        produce((draft) => {
          draft.errors.rule_name = null;
        })
      );
    }
    this.setState(
      produce((draft) => {
        draft.dataToSend.path.rule[this.state.newRuleIndex].name = e.value;
      })
    );
  };
  addNewRule = () => {
    this.setState(
      produce((draft) => {
        draft.bgClrPathIndex = null;
      })
    );
    this.setState(
      produce((draft) => {
        draft.bgClrRulePathIndex = null;
      })
    );
    this.setState(
      produce((draft) => {
        draft.onDefaultPathClick = false;
      })
    );
    const rule = {
      name: "",
      conditions: {},
      order: String(this.state.dataToSend.path.rule.length),
      path: [
        {
          path_name: "New Path",
          offerId: [],
          landerId: [],
          weight: "100",
          value: "100",
          weight_optimization: true,
          offerWeight: [],
          landerWeight: [],
          offerValue: [],
          landerValue: [],
          path_redirect_mode: "302",
          path_destination: "Lander & Offer",
        },
      ],
    };

    const outerRule = {
      order: String(this.state.dataToSend.path.rule.length),
      path: [
        {
          dataToMap: {
            landers: [],
            offers: [],
          },
          counts: {
            landerInBackend: 0,
            landersAdded: 0,
            offersInBackend: 0,
            offersAdded: 0,
            landerWeightageTotal: 0,
            offerWeightageTotal: 0,
          },
          allLandersDataLoaded: null,
          allOffersDataLoaded: null,
        },
      ],
    };
    const ruleWeightage = {
      pathAddedCount: 1,
      pathsTotalWeightage: 100,
    };

    this.setState(
      produce((draft) => {
        draft.dataToSend.path.rule.push(rule);
      })
    );
    this.setState(
      produce((draft) => {
        draft.rule.push(outerRule);
      })
    );
    this.setState(
      produce((draft) => {
        draft.ruleWeightageData.push(ruleWeightage);
      })
    );
    this.setState(
      produce((draft) => {
        draft.count = this.state.count + 1;
      })
    );
    this.setState(
      produce((draft) => {
        draft.onRuleClick = true;
      })
    );
    this.setState(
      produce((draft) => {
        draft.onFlowClick = false;
      })
    );
    this.setState(
      produce((draft) => {
        draft.newRulePathIndex = 0;
      })
    );
    if (this.state.newRuleIndex === null) {
      this.setState(
        produce((draft) => {
          draft.newRuleIndex = 0;
        })
      );
      this.setState(
        produce((draft) => {
          draft.bgClrRuleIndex = 0;
        })
      );
    }
    if (this.state.newRuleIndex !== null) {
      this.setState(
        produce((draft) => {
          draft.bgClrRuleIndex = this.state.newRuleIndex;
        })
      );
    }
    setTimeout(() => {
      this.filterSelectForConditions();
    }, 100);
  };
  addNewFlow = () => {
    this.setState(
      produce((draft) => {
        draft.onRuleClick = false;
      })
    );
    this.setState(
      produce((draft) => {
        draft.onFlowClick = true;
      })
    );
    this.setState(
      produce((draft) => {
        draft.bgClrRuleIndex = null;
      })
    );
    this.setState(
      produce((draft) => {
        draft.bgClrPathIndex = null;
      })
    );
    this.setState(
      produce((draft) => {
        draft.bgClrRulePathIndex = null;
      })
    );
  };
  newRuleClicked = (e) => {
    //let index = Number(e.target.parentElement.id.split("_")[2]);
    let index;
    if (e.target.parentElement.id.includes("rule")) {
      index = Number(e.target.parentElement.id.split("_")[2]);
    } else {
      index = Number(e.target.id.split("_")[2]);
    }

    this.setState(
      produce((draft) => {
        draft.onDefaultPathClick = false;
      })
    );
    this.setState(
      produce((draft) => {
        draft.newRuleIndex = index;
      })
    );
    this.setState(
      produce((draft) => {
        draft.bgClrRuleIndex = index;
      })
    );
    this.setState(
      produce((draft) => {
        draft.bgClrPathIndex = null;
      })
    );
    this.setState(
      produce((draft) => {
        draft.bgClrRulePathIndex = null;
      })
    );
    this.setState(
      produce((draft) => {
        draft.onRuleClick = true;
      })
    );
    this.setState(
      produce((draft) => {
        draft.onFlowClick = false;
      })
    );
    this.setState(
      produce((draft) => {
        draft.onPathClick = false;
      })
    );
    this.setState(
      produce((draft) => {
        draft.newRulePathIndex = 0;
      })
    );
    setTimeout(() => {
      this.filterSelectForConditions();
    }, 100);
    setTimeout(() => {
      this.rePopulateDefaultRuleLandersOffer();
    }, 100);
  };
  newRulePathClicked = (index, event) => {
    let ruleIndex = Number(event.target.id.split("_")[1]);
    // let name = (event.target.name.split("_")[3]);
    if (ruleIndex !== undefined && !(ruleIndex !== ruleIndex)) {
      // console.log('rule',ruleIndex);
      this.setState(
        produce((draft) => {
          draft.newRuleIndex = ruleIndex;
        })
      );
    }
    this.setState(
      produce((draft) => {
        draft.onDefaultPathClick = false;
      })
    );
    this.setState(
      produce((draft) => {
        draft.newRulePathIndex = index;
      })
    );
    this.setState(
      produce((draft) => {
        draft.bgClrPathIndex = null;
      })
    );
    this.setState(
      produce((draft) => {
        draft.bgClrRuleIndex = null;
      })
    );
    this.setState(
      produce((draft) => {
        draft.bgClrRulePathIndex = index;
      })
    );
    this.setState(
      produce((draft) => {
        draft.onRuleClick = false;
      })
    );
    this.setState(
      produce((draft) => {
        draft.onFlowClick = false;
      })
    );
    this.setState(
      produce((draft) => {
        draft.onPathClick = true;
      })
    );
    setTimeout(() => {
      this.rePopulateDefaultRuleLandersOffer();
    }, 100);
  };
  deleteRule = (e) => {
    let type = e.target.parentElement.id.split("_")[0];
    let index = Number(e.target.parentElement.id.split("_")[2]);
    if (index !== undefined && !(index !== index)) {
      this.setState(
        produce((draft) => {
          draft.deleted = true;
        })
      );
      this.setState(
        produce((draft) => {
          draft.bgClrPathIndex = null;
        })
      );
      this.setState(
        produce((draft) => {
          draft.bgClrRuleIndex = null;
        })
      );
      this.setState(
        produce((draft) => {
          draft.bgClrRulePathIndex = null;
        })
      );
      this.setState(
        produce((draft) => {
          draft.success_msg =
            "Rule has been deleted successfully. Please Select new rule to proceed...";
        })
      );
      if (this.state.dataToSend.path.rule[index].ruleId !== undefined) {
        this.props.deleteRule(
          this.props.accessToken,
          this.state.dataToSend.path.rule[index].ruleId
        );
      }
      let newRule = [];
      let outerRule = [];
      let conditions = [];
      let selected = [];
      let weightageRule = [];
      let condtionRuleName = [];
      let j = 0;
      let p = 0;
      let c = 0;
      let s = 0;
      let w = 0;
      let k = 0;
      for (let i = 0; i < this.state.dataToSend.path.rule.length; i++) {
        if (i !== index) {
          newRule[j] = this.state.dataToSend.path.rule[i];
          j++;
        }
      }
      for (let i = 0; i < this.state.rule.length; i++) {
        if (i !== index) {
          outerRule[p] = this.state.rule[i];
          p++;
        }
      }
      for (let i = 0; i < this.state.ruleWeightageData.length; i++) {
        if (i !== index) {
          weightageRule[w] = this.state.ruleWeightageData[i];
          w++;
        }
      }
      for (let i = 0; i < this.state.conditionsName.length; i++) {
        if (i !== index) {
          conditions[c] = this.state.conditionsName[i];
          c++;
        }
      }
      for (let i = 0; i < this.state.selectedOptions.length; i++) {
        if (i !== index) {
          selected[s] = this.state.selectedOptions[i];
          s++;
        }
      }
      for (let i = 0; i < this.state.ruleConditionName.length; i++) {
        if (i !== index) {
          condtionRuleName[k] = this.state.ruleConditionName[i];
          k++;
        }
      }
      this.setState(
        produce((draft) => {
          draft.dataToSend.path.rule.length = 0;
        })
      );
      this.setState(
        produce((draft) => {
          draft.rule.length = 0;
        })
      );
      this.setState(
        produce((draft) => {
          draft.conditionsName.length = 0;
        })
      );
      this.setState(
        produce((draft) => {
          draft.ruleWeightageData.length = 0;
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions.length = 0;
        })
      );
      this.setState(
        produce((draft) => {
          draft.ruleConditionName.length = 0;
        })
      );
      setTimeout(() => {
        for (let k = 0; k < newRule.length; k++) {
          this.setState(
            produce((draft) => {
              draft.dataToSend.path.rule.push(newRule[k]);
            })
          );
        }
      }, 50);
      setTimeout(() => {
        for (let k = 0; k < weightageRule.length; k++) {
          this.setState(
            produce((draft) => {
              draft.ruleWeightageData.push(weightageRule[k]);
            })
          );
        }
      }, 50);
      setTimeout(() => {
        for (let k = 0; k < outerRule.length; k++) {
          this.setState(
            produce((draft) => {
              draft.rule.push(outerRule[k]);
            })
          );
        }
      }, 50);
      setTimeout(() => {
        for (let k = 0; k < conditions.length; k++) {
          this.setState(
            produce((draft) => {
              draft.conditionsName.push(conditions[k]);
            })
          );
        }
      }, 50);
      setTimeout(() => {
        for (let k = 0; k < selected.length; k++) {
          this.setState(
            produce((draft) => {
              draft.selectedOptions.push(selected[k]);
            })
          );
        }
      }, 50);
      setTimeout(() => {
        for (let k = 0; k < selected.length; k++) {
          this.setState(
            produce((draft) => {
              draft.ruleConditionName.push(condtionRuleName[k]);
            })
          );
        }
      }, 50);
      setTimeout(() => {
        for (let i = index; i < this.state.dataToSend.path.rule.length; i++) {
          this.setState(
            produce((draft) => {
              draft.dataToSend.path.rule[i].order = String(i);
            })
          );
          this.setState(
            produce((draft) => {
              draft.rule[i].order = String(i);
            })
          );
        }
      }, 50);
      this.setState(
        produce((draft) => {
          draft.onDefaultPathClick = false;
        })
      );
      this.setState(
        produce((draft) => {
          draft.onRuleClick = false;
        })
      );
      this.setState(
        produce((draft) => {
          draft.onFlowClick = false;
        })
      );
      this.setState(
        produce((draft) => {
          draft.onPathClick = false;
        })
      );

      setTimeout(() => {
        if (this.state.dataToSend.path.rule.length === 0) {
          this.setState(
            produce((draft) => {
              draft.newRuleIndex = null;
            })
          );
        }
      }, 30);
      setTimeout(() => {
        this.setState(
          produce((draft) => {
            draft.deleted = false;
          })
        );
      }, 100);
    }
  };
  duplicateRule = (e) => {
    let type = e.target.parentElement.id.split("_")[0];
    let index = Number(e.target.parentElement.id.split("_")[2]);
    if (index !== undefined && !(index !== index)) {
      let duplicateValue = this.state.dataToSend.path.rule[index];
      let duplicateRuleWeightage = this.state.ruleWeightageData[index];
      let outerRuleDuplicateValue = this.state.rule[index];
      let conditionsName = this.state.conditionsName[index];
      let selectedOptions = this.state.selectedOptions[index];
      let filteredOptions = this.state.filteredOptions[index];
      let ruleConditionName = this.state.ruleConditionName[index];
      this.setState(
        produce((draft) => {
          draft.dataToSend.path.rule.splice(index + 1, 0, duplicateValue);
        })
      );
      this.setState(
        produce((draft) => {
          draft.rule.splice(index + 1, 0, outerRuleDuplicateValue);
        })
      );
      this.setState(
        produce((draft) => {
          draft.ruleWeightageData.splice(index + 1, 0, duplicateRuleWeightage);
        })
      );
      this.setState(
        produce((draft) => {
          draft.conditionsName.splice(index + 1, 0, conditionsName);
        })
      );
      this.setState(
        produce((draft) => {
          draft.ruleConditionName.splice(index + 1, 0, ruleConditionName);
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions.splice(index + 1, 0, selectedOptions);
        })
      );
      this.setState(
        produce((draft) => {
          draft.filteredOptions.splice(index + 1, 0, filteredOptions);
        })
      );
      setTimeout(() => {
        const { ruleId, ...data } = this.state.dataToSend.path.rule[index + 1];
        this.setState(
          produce((draft) => {
            draft.dataToSend.path.rule[index + 1] = data;
          })
        );

        for (
          let i = index + 1;
          i < this.state.dataToSend.path.rule.length;
          i++
        ) {
          this.setState(
            produce((draft) => {
              draft.dataToSend.path.rule[i].order = String(i);
            })
          );
          this.setState(
            produce((draft) => {
              draft.rule[i].order = String(i);
            })
          );
        }
      }, 50);
    }
  };
  onSort = async (e) => {
    this.setState(
      produce((draft) => {
        draft.sorted = false;
      })
    );
    let type = e.target.parentElement.id.split("_")[0];
    let index = Number(e.target.parentElement.id.split("_")[2]);
    let order = e.target.parentElement.id.split("_")[3];
    // console.log('type', type);
    // console.log('index',index);
    // console.log('order',order);
    if (index !== undefined && !(index !== index)) {
      if (this.state.dataToSend.path.rule.length > 1) {
        let tempArray = [];
        let ruleNameArray = [];
        let selectedOptionsArray = [];
        let ruleWeightage = [];
        if (order === "down") {
          // console.log('order', this.state.dataToSend.path.rule[index].order);
          this.setState(
            produce((draft) => {
              draft.newRuleIndex = index + 1;
            })
          );
          this.setState(
            produce((draft) => {
              draft.newRulePathIndex = 0;
            })
          );
          this.setState(
            produce((draft) => {
              draft.dataToSend.path.rule[index].order = String(index + 1);
            })
          );
          this.setState(
            produce((draft) => {
              draft.dataToSend.path.rule[index + 1].order = String(index);
            })
          );
          this.setState(
            produce((draft) => {
              draft.rule[index].order = String(index + 1);
            })
          );
          this.setState(
            produce((draft) => {
              draft.rule[index + 1].order = String(index);
            })
          );
          tempArray.push(this.state.conditionsName[index]);
          this.setState(
            produce((draft) => {
              draft.conditionsName[index] =
                this.state.conditionsName[index + 1];
            })
          );
          this.setState(
            produce((draft) => {
              draft.conditionsName[index + 1] = tempArray[0];
            })
          );
          ruleNameArray.push(this.state.ruleConditionName[index]);
          this.setState(
            produce((draft) => {
              draft.ruleConditionName[index] =
                this.state.ruleConditionName[index + 1];
            })
          );
          this.setState(
            produce((draft) => {
              draft.ruleConditionName[index + 1] = ruleNameArray[0];
            })
          );
          selectedOptionsArray.push(this.state.selectedOptions[index]);
          this.setState(
            produce((draft) => {
              draft.selectedOptions[index] =
                this.state.selectedOptions[index + 1];
            })
          );
          this.setState(
            produce((draft) => {
              draft.selectedOptions[index + 1] = selectedOptionsArray[0];
            })
          );
          ruleWeightage.push(this.state.ruleWeightageData[index]);
          this.setState(
            produce((draft) => {
              draft.ruleWeightageData[index] =
                this.state.ruleWeightageData[index + 1];
            })
          );
          this.setState(
            produce((draft) => {
              draft.ruleWeightageData[index + 1] = ruleWeightage[0];
            })
          );

          this.setState(
            produce((draft) => {
              draft.onDefaultPathClick = false;
            })
          );
          //this.setState(produce(draft => { draft.newRuleIndex = index+1 }));
          this.setState(
            produce((draft) => {
              draft.bgClrRuleIndex = index + 1;
            })
          );
          this.setState(
            produce((draft) => {
              draft.bgClrPathIndex = null;
            })
          );
          this.setState(
            produce((draft) => {
              draft.bgClrRulePathIndex = null;
            })
          );
        }
        if (order === "up") {
          // console.log('order', this.state.dataToSend.path.rule[index].order);
          this.setState(
            produce((draft) => {
              draft.newRuleIndex = index - 1;
            })
          );
          this.setState(
            produce((draft) => {
              draft.newRulePathIndex = 0;
            })
          );
          this.setState(
            produce((draft) => {
              draft.dataToSend.path.rule[index].order = String(index - 1);
            })
          );
          this.setState(
            produce((draft) => {
              draft.dataToSend.path.rule[index - 1].order = String(index);
            })
          );
          this.setState(
            produce((draft) => {
              draft.rule[index].order = String(index - 1);
            })
          );
          this.setState(
            produce((draft) => {
              draft.rule[index - 1].order = String(index);
            })
          );
          tempArray.push(this.state.conditionsName[index]);
          this.setState(
            produce((draft) => {
              draft.conditionsName[index] =
                this.state.conditionsName[index - 1];
            })
          );
          this.setState(
            produce((draft) => {
              draft.conditionsName[index - 1] = tempArray[0];
            })
          );
          ruleNameArray.push(this.state.ruleConditionName[index]);
          this.setState(
            produce((draft) => {
              draft.ruleConditionName[index] =
                this.state.ruleConditionName[index - 1];
            })
          );
          this.setState(
            produce((draft) => {
              draft.ruleConditionName[index - 1] = ruleNameArray[0];
            })
          );
          selectedOptionsArray.push(this.state.selectedOptions[index]);
          this.setState(
            produce((draft) => {
              draft.selectedOptions[index] =
                this.state.selectedOptions[index - 1];
            })
          );
          this.setState(
            produce((draft) => {
              draft.selectedOptions[index - 1] = selectedOptionsArray[0];
            })
          );
          ruleWeightage.push(this.state.ruleWeightageData[index]);
          this.setState(
            produce((draft) => {
              draft.ruleWeightageData[index] =
                this.state.ruleWeightageData[index - 1];
            })
          );
          this.setState(
            produce((draft) => {
              draft.ruleWeightageData[index - 1] = ruleWeightage[0];
            })
          );

          this.setState(
            produce((draft) => {
              draft.onDefaultPathClick = false;
            })
          );
          //this.setState(produce(draft => { draft.newRuleIndex = index-1 }));
          this.setState(
            produce((draft) => {
              draft.bgClrRuleIndex = index - 1;
            })
          );
          this.setState(
            produce((draft) => {
              draft.bgClrPathIndex = null;
            })
          );
          this.setState(
            produce((draft) => {
              draft.bgClrRulePathIndex = null;
            })
          );
        }
      }
      setTimeout(() => {
        this.setState(
          produce((draft) => {
            draft.dataToSend.path.rule = [
              ...this.state.dataToSend.path.rule,
            ].sort((a, b) => {
              return Number(a.order) - Number(b.order);
            });
          })
        );
        this.setState(
          produce((draft) => {
            draft.rule = [...this.state.rule].sort((a, b) => {
              return Number(a.order) - Number(b.order);
            });
          })
        );
        this.filterSelectForConditions();
        this.setState(
          produce((draft) => {
            draft.sorted = true;
          })
        );
      }, 50);
    }
  };
  duplicateDefaultPath = (e) => {
    let type = e.target.parentElement.id.split("_")[0];
    let index = Number(e.target.parentElement.id.split("_")[1]);
    let index2 = Number(e.target.parentElement.id.split("_")[2]);
    if (typeof type !== "undefined") {
      if (type === "default") {
        let duplicateValue = this.state.dataToSend.path.is_default[index];
        let outerDefaultDuplicateValue = this.state.is_default[index];
        this.setState(
          produce((draft) => {
            draft.dataToSend.path.is_default.splice(
              index + 1,
              0,
              duplicateValue
            );
          })
        );
        this.setState(
          produce((draft) => {
            draft.is_default.splice(index + 1, 0, outerDefaultDuplicateValue);
          })
        );
        this.setState(
          produce((draft) => {
            draft.defaultWeightageData.pathAddedCount =
              this.state.defaultWeightageData.pathAddedCount + 1;
            draft.defaultWeightageData.pathsTotalWeightage =
              this.state.defaultWeightageData.pathsTotalWeightage + 100;
          })
        );
        this.setState(
          produce((draft) => {
            draft.bgClrPathIndex = index;
          })
        );
        this.setState(
          produce((draft) => {
            draft.bgClrRuleIndex = null;
          })
        );
        this.setState(
          produce((draft) => {
            draft.bgClrRulePathIndex = null;
          })
        );
        this.setState(
          produce((draft) => {
            draft.onDefaultPathClick = true;
          })
        );
        this.setState(
          produce((draft) => {
            draft.newDefaultPathIndex = index;
          })
        );
        this.setState(
          produce((draft) => {
            draft.onRuleClick = false;
          })
        );
        this.setState(
          produce((draft) => {
            draft.onFlowClick = false;
          })
        );
        this.setState(
          produce((draft) => {
            draft.onPathClick = true;
          })
        );
        setTimeout(() => {
          const { pathId, ...data } =
            this.state.dataToSend.path.is_default[index + 1];
          this.setState(
            produce((draft) => {
              draft.dataToSend.path.is_default[index + 1] = data;
            })
          );
          this.recalculateAllDefaultWeightsPercentages("default");
        }, 100);
      } else {
        if (type === "rule") {
          this.duplicateRulePath(index, index2);
        }
      }
    }
  };
  duplicateRulePath = (index, index2) => {
    let duplicateValue = this.state.dataToSend.path.rule[index].path[index2];
    let outerRuleDuplicateValue = this.state.rule[index].path[index2];
    this.setState(
      produce((draft) => {
        draft.dataToSend.path.rule[index].path.splice(
          index2 + 1,
          0,
          duplicateValue
        );
      })
    );
    this.setState(
      produce((draft) => {
        draft.rule[index].path.splice(index2 + 1, 0, outerRuleDuplicateValue);
      })
    );
    this.setState(
      produce((draft) => {
        draft.ruleWeightageData[index].pathAddedCount =
          this.state.ruleWeightageData[index].pathAddedCount + 1;
        draft.ruleWeightageData[index].pathsTotalWeightage =
          this.state.ruleWeightageData[index].pathsTotalWeightage + 100;
      })
    );
    this.setState(
      produce((draft) => {
        draft.bgClrPathIndex = null;
      })
    );
    this.setState(
      produce((draft) => {
        draft.bgClrRuleIndex = null;
      })
    );
    this.setState(
      produce((draft) => {
        draft.bgClrRulePathIndex = index2;
      })
    );
    this.setState(
      produce((draft) => {
        draft.newRulePathIndex = index2;
      })
    );
    this.setState(
      produce((draft) => {
        draft.onDefaultPathClick = false;
      })
    );
    this.setState(
      produce((draft) => {
        draft.onRuleClick = false;
      })
    );
    this.setState(
      produce((draft) => {
        draft.onFlowClick = false;
      })
    );
    this.setState(
      produce((draft) => {
        draft.onPathClick = true;
      })
    );

    setTimeout(() => {
      const { pathId, ...data } =
        this.state.dataToSend.path.rule[index].path[index2 + 1];
      this.setState(
        produce((draft) => {
          draft.dataToSend.path.rule[index].path[index2 + 1] = data;
        })
      );
      this.recalculateAllDefaultWeightsPercentages("rule", index);
    }, 100);
  };
  onChangePathSwitch = (e) => {
    let type = e.target.name.split("_")[0];
    let index = Number(e.target.name.split("_")[1]);
    let index2 = Number(e.target.name.split("_")[2]);
    if (type === "default") {
      this.setState(
        produce((draft) => {
          draft.dataToSend.path.is_default[index].weight_optimization =
            !this.state.dataToSend.path.is_default[index].weight_optimization;
        })
      );
    } else {
      this.setState(
        produce((draft) => {
          draft.dataToSend.path.rule[index].path[index2].weight_optimization =
            !this.state.dataToSend.path.rule[index].path[index2]
              .weight_optimization;
        })
      );
    }
  };
  reCalculatePathWeigtageSwitch = (type) => {
    if (typeof type !== "undefined") {
      if (type === "default") {
        let counter = 0;
        let total = 0;
        this.state.dataToSend.path.is_default.forEach(function (count) {
          total++;
          if (count.weight_optimization === false) {
            counter++;
          }
        });
        // console.log('total path:', total);
        // console.log('false weightage', counter);
        this.setState(
          produce((draft) => {
            draft.defaultWeightageData.pathAddedCount =
              this.state.defaultWeightageData.pathAddedCount - counter;
            draft.defaultWeightageData.pathsTotalWeightage =
              this.state.defaultWeightageData.pathsTotalWeightage -
              counter * 100;
          })
        );
        setTimeout(() => {
          this.recalculateAllDefaultWeightsPercentages("default");
        }, 200);
      }
    }
  };

  addConditions = (data) => {
    this.setState(
      produce((draft) => {
        draft.sorted = true;
      })
    );
    if (data.select === "connection") {
      if (
        !this.state.conditionsName[this.state.newRuleIndex].includes(
          "Connection type"
        )
      ) {
        this.setState(
          produce((draft) => {
            draft.conditionsName[this.state.newRuleIndex].push(
              "Connection type"
            );
          })
        );
      }
      const connectionType = {
        connectionType: {
          predicate: "MUST_BE",
          connection: [],
        },
      };
      for (let i = 0; i < data.cityValue.length; i++) {
        const { id, update_at, create_at, ...qux } = data.cityValue[i];
        connectionType.connectionType.connection.push(qux);
      }
      if (data.predicate === false) {
        const value = connectionType.connectionType;
        value.predicate = "MUST_NOT_BE";
        this.setState({ connectionType });
      }
      //this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].conditions = connectionType }));
      this.setState(
        produce((draft) => {
          Object.assign(
            draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
            connectionType
          );
        })
      );
    }

    if (data.select === "weekday") {
      if (
        !this.state.conditionsName[this.state.newRuleIndex].includes("Weekday")
      ) {
        this.setState(
          produce((draft) => {
            draft.conditionsName[this.state.newRuleIndex].push("Weekday");
          })
        );
      }
      const weekday = {
        weekday: {
          predicate: "MUST_BE",
          weekdays: [],
        },
      };
      for (let i = 0; i < data.cityValue.length; i++) {
        const { id, create_at, ...qux } = data.cityValue[i];
        weekday.weekday.weekdays.push(qux);
      }
      if (data.predicate === false) {
        const value = weekday.weekday;
        value.predicate = "MUST_NOT_BE";
        this.setState({ weekday });
      }
      //this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].conditions = connectionType }));
      this.setState(
        produce((draft) => {
          Object.assign(
            draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
            weekday
          );
        })
      );
    }

    if (data.select === "device") {
      if (
        !this.state.conditionsName[this.state.newRuleIndex].includes(
          "Device type"
        )
      ) {
        this.setState(
          produce((draft) => {
            draft.conditionsName[this.state.newRuleIndex].push("Device type");
          })
        );
      }
      const deviceType = {
        deviceType: {
          predicate: "MUST_BE",
          device: [],
        },
      };
      for (let i = 0; i < data.cityValue.length; i++) {
        const { id, update_at, create_at, ...qux } = data.cityValue[i];
        deviceType.deviceType.device.push(qux);
      }
      if (data.predicate === false) {
        const value = deviceType.deviceType;
        value.predicate = "MUST_NOT_BE";
        this.setState({ deviceType });
      }
      this.setState(
        produce((draft) => {
          Object.assign(
            draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
            deviceType
          );
        })
      );
      //this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].conditions = deviceType }));
    }
    if (data.select === "language") {
      if (
        !this.state.conditionsName[this.state.newRuleIndex].includes("Language")
      ) {
        this.setState(
          produce((draft) => {
            draft.conditionsName[this.state.newRuleIndex].push("Language");
          })
        );
      }
      const language = {
        language: {
          predicate: "MUST_BE",
          languages: [],
        },
      };
      for (let i = 0; i < data.cityValue.length; i++) {
        const { id, update_at, create_at, ...qux } = data.cityValue[i];
        language.language.languages.push(qux);
      }
      if (data.predicate === false) {
        const value = language.language;
        value.predicate = "MUST_NOT_BE";
        this.setState({ language });
      }
      this.setState(
        produce((draft) => {
          Object.assign(
            draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
            language
          );
        })
      );
      //this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].conditions = deviceType }));
    }
    if (data.select === "proxy") {
      if (
        !this.state.conditionsName[this.state.newRuleIndex].includes("Proxy")
      ) {
        this.setState(
          produce((draft) => {
            draft.conditionsName[this.state.newRuleIndex].push("Proxy");
          })
        );
      }
      const proxy = {
        proxy: {
          predicate: "MUST_BE",
          proxies: [],
        },
      };
      for (let i = 0; i < data.cityValue.length; i++) {
        const { id, update_at, create_at, ...qux } = data.cityValue[i];
        proxy.proxy.proxies.push(qux);
      }
      if (data.predicate === false) {
        const value = proxy.proxy;
        value.predicate = "MUST_NOT_BE";
        this.setState({ proxy });
      }
      this.setState(
        produce((draft) => {
          Object.assign(
            draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
            proxy
          );
        })
      );
      //this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].conditions = deviceType }));
    }
    if (data.select === "IP") {
      if (!this.state.conditionsName[this.state.newRuleIndex].includes("IP")) {
        this.setState(
          produce((draft) => {
            draft.conditionsName[this.state.newRuleIndex].push("IP");
          })
        );
      }
      const ip = {
        ip: {
          predicate: "MUST_BE",
          ips: [],
        },
      };
      this.setState(
        produce((draft) => {
          draft.tempIP = data.ip;
        })
      );
      let ipData = data.ip.split(",");
      //console.log('id',ipData);
      for (let i = 0; i < ipData.length; i++) {
        ip.ip.ips.push({ name: ipData[i] });
      }
      // ip.ip.ips=ipData;
      //console.log('ip',ip);

      if (data.predicate === false) {
        const value = ip.ip;
        value.predicate = "MUST_NOT_BE";
        this.setState({ ip });
      }
      this.setState(
        produce((draft) => {
          Object.assign(
            draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
            ip
          );
        })
      );
    }
    if (data.select === "Referrer") {
      if (
        !this.state.conditionsName[this.state.newRuleIndex].includes("Referrer")
      ) {
        this.setState(
          produce((draft) => {
            draft.conditionsName[this.state.newRuleIndex].push("Referrer");
          })
        );
      }
      const referrer = {
        referrer: {
          predicate: "MUST_BE",
          is_empty: false,
          referrers: [],
        },
      };

      this.setState(
        produce((draft) => {
          draft.tempreferrer = data.domain;
        })
      );
      let referrerData = data.domain.split(",");
      referrer.referrer.is_empty = data.is_empty;
      //console.log('id',referrerData);
      for (let i = 0; i < referrerData.length; i++) {
        referrer.referrer.referrers.push({ name: referrerData[i] });
      }
      // referrer.referrer.referrers=referrerData;
      //console.log('referrer',referrer);

      if (data.predicate === false) {
        const value = referrer.referrer;
        value.predicate = "MUST_NOT_BE";
        this.setState({ referrer });
      }
      this.setState(
        produce((draft) => {
          Object.assign(
            draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
            referrer
          );
        })
      );
    }
    if (data.select === "Referrer domain") {
      if (
        !this.state.conditionsName[this.state.newRuleIndex].includes(
          "Referrer domain"
        )
      ) {
        this.setState(
          produce((draft) => {
            draft.conditionsName[this.state.newRuleIndex].push(
              "Referrer domain"
            );
          })
        );
      }
      const referrerDomain = {
        referrerDomain: {
          predicate: "MUST_BE",
          is_empty: false,
          referrerDomains: [],
        },
      };
      this.setState(
        produce((draft) => {
          draft.tempreferrerDomain = data.domain;
        })
      );
      let referrerDomainData = data.domain.split(",");
      referrerDomain.referrerDomain.is_empty = data.is_empty;
      //console.log('id',referrerDomainData);
      for (let i = 0; i < referrerDomainData.length; i++) {
        referrerDomain.referrerDomain.referrerDomains.push({
          name: referrerDomainData[i],
        });
      }
      // referrerDomain.referrerDomain.referrerDomains=referrerDomainData;
      //console.log('referrerDomain',referrerDomain);

      if (data.predicate === false) {
        const value = referrerDomain.referrerDomain;
        value.predicate = "MUST_NOT_BE";
        this.setState({ referrerDomain });
      }
      this.setState(
        produce((draft) => {
          Object.assign(
            draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
            referrerDomain
          );
        })
      );
    }
    if (data.select.includes("Custom variable")) {
      if (data.select === "Custom variable 1") {
        if (
          !this.state.conditionsName[this.state.newRuleIndex].includes(
            `Custom variable 1`
          )
        ) {
          this.setState(
            produce((draft) => {
              draft.conditionsName[this.state.newRuleIndex].push(
                `Custom variable 1`
              );
            })
          );
        }
        const varr = {
          var1: {
            predicate: "MUST_BE",
            is_empty: false,
            var1s: [],
          },
        };
        let varData = data.customVar.split(",");
        varr.var1.predicate = data.iRSelectedValue;
        varr.var1.is_empty = data.is_empty;
        for (let i = 0; i < varData.length; i++) {
          varr.var1.var1s.push({ name: varData[i] });
        }
        this.setState(
          produce((draft) => {
            Object.assign(
              draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
              varr
            );
          })
        );
      }
      if (data.select === "Custom variable 2") {
        if (
          !this.state.conditionsName[this.state.newRuleIndex].includes(
            `Custom variable 2`
          )
        ) {
          this.setState(
            produce((draft) => {
              draft.conditionsName[this.state.newRuleIndex].push(
                `Custom variable 2`
              );
            })
          );
        }
        const varr = {
          var2: {
            predicate: "MUST_BE",
            is_empty: false,
            var2s: [],
          },
        };
        let varData = data.customVar.split(",");
        varr.var2.predicate = data.iRSelectedValue;
        varr.var2.is_empty = data.is_empty;
        for (let i = 0; i < varData.length; i++) {
          varr.var2.var2s.push({ name: varData[i] });
        }
        this.setState(
          produce((draft) => {
            Object.assign(
              draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
              varr
            );
          })
        );
      }
      if (data.select === "Custom variable 3") {
        if (
          !this.state.conditionsName[this.state.newRuleIndex].includes(
            `Custom variable 3`
          )
        ) {
          this.setState(
            produce((draft) => {
              draft.conditionsName[this.state.newRuleIndex].push(
                `Custom variable 3`
              );
            })
          );
        }
        const varr = {
          var3: {
            predicate: "MUST_BE",
            is_empty: false,
            var3s: [],
          },
        };
        let varData = data.customVar.split(",");
        varr.var3.predicate = data.iRSelectedValue;
        varr.var3.is_empty = data.is_empty;
        for (let i = 0; i < varData.length; i++) {
          varr.var3.var3s.push({ name: varData[i] });
        }
        this.setState(
          produce((draft) => {
            Object.assign(
              draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
              varr
            );
          })
        );
      }
      if (data.select === "Custom variable 4") {
        if (
          !this.state.conditionsName[this.state.newRuleIndex].includes(
            `Custom variable 4`
          )
        ) {
          this.setState(
            produce((draft) => {
              draft.conditionsName[this.state.newRuleIndex].push(
                `Custom variable 4`
              );
            })
          );
        }
        const varr = {
          var4: {
            predicate: "MUST_BE",
            is_empty: false,
            var4s: [],
          },
        };
        let varData = data.customVar.split(",");
        varr.var4.predicate = data.iRSelectedValue;
        varr.var4.is_empty = data.is_empty;
        for (let i = 0; i < varData.length; i++) {
          varr.var4.var4s.push({ name: varData[i] });
        }
        this.setState(
          produce((draft) => {
            Object.assign(
              draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
              varr
            );
          })
        );
      }
      if (data.select === "Custom variable 5") {
        if (
          !this.state.conditionsName[this.state.newRuleIndex].includes(
            `Custom variable 5`
          )
        ) {
          this.setState(
            produce((draft) => {
              draft.conditionsName[this.state.newRuleIndex].push(
                `Custom variable 5`
              );
            })
          );
        }
        const varr = {
          var5: {
            predicate: "MUST_BE",
            is_empty: false,
            var5s: [],
          },
        };
        let varData = data.customVar.split(",");
        varr.var5.predicate = data.iRSelectedValue;
        varr.var5.is_empty = data.is_empty;
        for (let i = 0; i < varData.length; i++) {
          varr.var5.var5s.push({ name: varData[i] });
        }
        this.setState(
          produce((draft) => {
            Object.assign(
              draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
              varr
            );
          })
        );
      }
      if (data.select === "Custom variable 6") {
        if (
          !this.state.conditionsName[this.state.newRuleIndex].includes(
            `Custom variable 6`
          )
        ) {
          this.setState(
            produce((draft) => {
              draft.conditionsName[this.state.newRuleIndex].push(
                `Custom variable 6`
              );
            })
          );
        }
        const varr = {
          var6: {
            predicate: "MUST_BE",
            is_empty: false,
            var6s: [],
          },
        };
        let varData = data.customVar.split(",");
        varr.var6.predicate = data.iRSelectedValue;
        varr.var6.is_empty = data.is_empty;
        for (let i = 0; i < varData.length; i++) {
          varr.var6.var6s.push({ name: varData[i] });
        }
        this.setState(
          produce((draft) => {
            Object.assign(
              draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
              varr
            );
          })
        );
      }
      if (data.select === "Custom variable 7") {
        if (
          !this.state.conditionsName[this.state.newRuleIndex].includes(
            `Custom variable 7`
          )
        ) {
          this.setState(
            produce((draft) => {
              draft.conditionsName[this.state.newRuleIndex].push(
                `Custom variable 7`
              );
            })
          );
        }
        const varr = {
          var7: {
            predicate: "MUST_BE",
            is_empty: false,
            var7s: [],
          },
        };
        let varData = data.customVar.split(",");
        varr.var7.predicate = data.iRSelectedValue;
        varr.var7.is_empty = data.is_empty;
        for (let i = 0; i < varData.length; i++) {
          varr.var7.var7s.push({ name: varData[i] });
        }
        this.setState(
          produce((draft) => {
            Object.assign(
              draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
              varr
            );
          })
        );
      }
      if (data.select === "Custom variable 8") {
        if (
          !this.state.conditionsName[this.state.newRuleIndex].includes(
            `Custom variable 8`
          )
        ) {
          this.setState(
            produce((draft) => {
              draft.conditionsName[this.state.newRuleIndex].push(
                `Custom variable 8`
              );
            })
          );
        }
        const varr = {
          var8: {
            predicate: "MUST_BE",
            is_empty: false,
            var8s: [],
          },
        };
        let varData = data.customVar.split(",");
        varr.var8.predicate = data.iRSelectedValue;
        varr.var8.is_empty = data.is_empty;
        for (let i = 0; i < varData.length; i++) {
          varr.var8.var8s.push({ name: varData[i] });
        }
        this.setState(
          produce((draft) => {
            Object.assign(
              draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
              varr
            );
          })
        );
      }
      if (data.select === "Custom variable 9") {
        if (
          !this.state.conditionsName[this.state.newRuleIndex].includes(
            `Custom variable 9`
          )
        ) {
          this.setState(
            produce((draft) => {
              draft.conditionsName[this.state.newRuleIndex].push(
                `Custom variable 9`
              );
            })
          );
        }
        const varr = {
          var9: {
            predicate: "MUST_BE",
            is_empty: false,
            var9s: [],
          },
        };
        let varData = data.customVar.split(",");
        varr.var9.predicate = data.iRSelectedValue;
        varr.var9.is_empty = data.is_empty;
        for (let i = 0; i < varData.length; i++) {
          varr.var9.var9s.push({ name: varData[i] });
        }
        this.setState(
          produce((draft) => {
            Object.assign(
              draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
              varr
            );
          })
        );
      }
      if (data.select === "Custom variable 10") {
        if (
          !this.state.conditionsName[this.state.newRuleIndex].includes(
            `Custom variable 10`
          )
        ) {
          this.setState(
            produce((draft) => {
              draft.conditionsName[this.state.newRuleIndex].push(
                `Custom variable 10`
              );
            })
          );
        }
        const varr = {
          var10: {
            predicate: "MUST_BE",
            is_empty: false,
            var10s: [],
          },
        };
        let varData = data.customVar.split(",");
        varr.var10.predicate = data.iRSelectedValue;
        varr.var10.is_empty = data.is_empty;
        for (let i = 0; i < varData.length; i++) {
          varr.var10.var10s.push({ name: varData[i] });
        }
        this.setState(
          produce((draft) => {
            Object.assign(
              draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
              varr
            );
          })
        );
      }
      if (data.select === "Custom variable 11") {
        if (
          !this.state.conditionsName[this.state.newRuleIndex].includes(
            `Custom variable 11`
          )
        ) {
          this.setState(
            produce((draft) => {
              draft.conditionsName[this.state.newRuleIndex].push(
                `Custom variable 11`
              );
            })
          );
        }
        const varr = {
          var11: {
            predicate: "MUST_BE",
            is_empty: false,
            var11s: [],
          },
        };
        let varData = data.customVar.split(",");
        varr.var11.predicate = data.iRSelectedValue;
        varr.var11.is_empty = data.is_empty;
        for (let i = 0; i < varData.length; i++) {
          varr.var11.var11s.push({ name: varData[i] });
        }
        this.setState(
          produce((draft) => {
            Object.assign(
              draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
              varr
            );
          })
        );
      }
      if (data.select === "Custom variable 12") {
        if (
          !this.state.conditionsName[this.state.newRuleIndex].includes(
            `Custom variable 12`
          )
        ) {
          this.setState(
            produce((draft) => {
              draft.conditionsName[this.state.newRuleIndex].push(
                `Custom variable 12`
              );
            })
          );
        }
        const varr = {
          var12: {
            predicate: "MUST_BE",
            is_empty: false,
            var12s: [],
          },
        };
        let varData = data.customVar.split(",");
        varr.var12.predicate = data.iRSelectedValue;
        varr.var12.is_empty = data.is_empty;
        for (let i = 0; i < varData.length; i++) {
          varr.var12.var12s.push({ name: varData[i] });
        }
        this.setState(
          produce((draft) => {
            Object.assign(
              draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
              varr
            );
          })
        );
      }
      if (data.select === "Custom variable 13") {
        if (
          !this.state.conditionsName[this.state.newRuleIndex].includes(
            `Custom variable 13`
          )
        ) {
          this.setState(
            produce((draft) => {
              draft.conditionsName[this.state.newRuleIndex].push(
                `Custom variable 13`
              );
            })
          );
        }
        const varr = {
          var13: {
            predicate: "MUST_BE",
            is_empty: false,
            var13s: [],
          },
        };
        let varData = data.customVar.split(",");
        varr.var13.predicate = data.iRSelectedValue;
        varr.var13.is_empty = data.is_empty;
        for (let i = 0; i < varData.length; i++) {
          varr.var13.var13s.push({ name: varData[i] });
        }
        this.setState(
          produce((draft) => {
            Object.assign(
              draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
              varr
            );
          })
        );
      }
      if (data.select === "Custom variable 14") {
        if (
          !this.state.conditionsName[this.state.newRuleIndex].includes(
            `Custom variable 14`
          )
        ) {
          this.setState(
            produce((draft) => {
              draft.conditionsName[this.state.newRuleIndex].push(
                `Custom variable 14`
              );
            })
          );
        }
        const varr = {
          var14: {
            predicate: "MUST_BE",
            is_empty: false,
            var14s: [],
          },
        };
        let varData = data.customVar.split(",");
        varr.var14.predicate = data.iRSelectedValue;
        varr.var14.is_empty = data.is_empty;
        for (let i = 0; i < varData.length; i++) {
          varr.var14.var14s.push({ name: varData[i] });
        }
        this.setState(
          produce((draft) => {
            Object.assign(
              draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
              varr
            );
          })
        );
      }
      if (data.select === "Custom variable 15") {
        if (
          !this.state.conditionsName[this.state.newRuleIndex].includes(
            `Custom variable 15`
          )
        ) {
          this.setState(
            produce((draft) => {
              draft.conditionsName[this.state.newRuleIndex].push(
                `Custom variable 15`
              );
            })
          );
        }
        const varr = {
          var15: {
            predicate: "MUST_BE",
            is_empty: false,
            var15s: [],
          },
        };
        let varData = data.customVar.split(",");
        varr.var15.predicate = data.iRSelectedValue;
        varr.var15.is_empty = data.is_empty;
        for (let i = 0; i < varData.length; i++) {
          varr.var15.var15s.push({ name: varData[i] });
        }
        this.setState(
          produce((draft) => {
            Object.assign(
              draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
              varr
            );
          })
        );
      }
      if (data.select === "Custom variable 16") {
        if (
          !this.state.conditionsName[this.state.newRuleIndex].includes(
            `Custom variable 16`
          )
        ) {
          this.setState(
            produce((draft) => {
              draft.conditionsName[this.state.newRuleIndex].push(
                `Custom variable 16`
              );
            })
          );
        }
        const varr = {
          var16: {
            predicate: "MUST_BE",
            is_empty: false,
            var16s: [],
          },
        };
        let varData = data.customVar.split(",");
        varr.var16.predicate = data.iRSelectedValue;
        varr.var16.is_empty = data.is_empty;
        for (let i = 0; i < varData.length; i++) {
          varr.var16.var16s.push({ name: varData[i] });
        }
        this.setState(
          produce((draft) => {
            Object.assign(
              draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
              varr
            );
          })
        );
      }
      if (data.select === "Custom variable 17") {
        if (
          !this.state.conditionsName[this.state.newRuleIndex].includes(
            `Custom variable 17`
          )
        ) {
          this.setState(
            produce((draft) => {
              draft.conditionsName[this.state.newRuleIndex].push(
                `Custom variable 17`
              );
            })
          );
        }
        const varr = {
          var17: {
            predicate: "MUST_BE",
            is_empty: false,
            var17s: [],
          },
        };
        let varData = data.customVar.split(",");
        varr.var17.predicate = data.iRSelectedValue;
        varr.var17.is_empty = data.is_empty;
        for (let i = 0; i < varData.length; i++) {
          varr.var17.var17s.push({ name: varData[i] });
        }
        this.setState(
          produce((draft) => {
            Object.assign(
              draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
              varr
            );
          })
        );
      }
      if (data.select === "Custom variable 18") {
        if (
          !this.state.conditionsName[this.state.newRuleIndex].includes(
            `Custom variable 18`
          )
        ) {
          this.setState(
            produce((draft) => {
              draft.conditionsName[this.state.newRuleIndex].push(
                `Custom variable 18`
              );
            })
          );
        }
        const varr = {
          var18: {
            predicate: "MUST_BE",
            is_empty: false,
            var18s: [],
          },
        };
        let varData = data.customVar.split(",");
        varr.var18.predicate = data.iRSelectedValue;
        varr.var18.is_empty = data.is_empty;
        for (let i = 0; i < varData.length; i++) {
          varr.var18.var18s.push({ name: varData[i] });
        }
        this.setState(
          produce((draft) => {
            Object.assign(
              draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
              varr
            );
          })
        );
      }
      if (data.select === "Custom variable 19") {
        if (
          !this.state.conditionsName[this.state.newRuleIndex].includes(
            `Custom variable 19`
          )
        ) {
          this.setState(
            produce((draft) => {
              draft.conditionsName[this.state.newRuleIndex].push(
                `Custom variable 19`
              );
            })
          );
        }
        const varr = {
          var19: {
            predicate: "MUST_BE",
            is_empty: false,
            var19s: [],
          },
        };
        let varData = data.customVar.split(",");
        varr.var19.predicate = data.iRSelectedValue;
        varr.var19.is_empty = data.is_empty;
        for (let i = 0; i < varData.length; i++) {
          varr.var19.var19s.push({ name: varData[i] });
        }
        this.setState(
          produce((draft) => {
            Object.assign(
              draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
              varr
            );
          })
        );
      }
      if (data.select === "Custom variable 20") {
        if (
          !this.state.conditionsName[this.state.newRuleIndex].includes(
            `Custom variable 20`
          )
        ) {
          this.setState(
            produce((draft) => {
              draft.conditionsName[this.state.newRuleIndex].push(
                `Custom variable 20`
              );
            })
          );
        }
        const varr = {
          var20: {
            predicate: "MUST_BE",
            is_empty: false,
            var20s: [],
          },
        };
        let varData = data.customVar.split(",");
        varr.var20.predicate = data.iRSelectedValue;
        varr.var20.is_empty = data.is_empty;
        for (let i = 0; i < varData.length; i++) {
          varr.var20.var20s.push({ name: varData[i] });
        }
        this.setState(
          produce((draft) => {
            Object.assign(
              draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
              varr
            );
          })
        );
      }
    }
    if (data.select === "timezone") {
      if (
        !this.state.conditionsName[this.state.newRuleIndex].includes(
          "Time of day"
        )
      ) {
        this.setState(
          produce((draft) => {
            draft.conditionsName[this.state.newRuleIndex].push("Time of day");
          })
        );
      }
      const timeofday = {
        timeofday: {
          predicate: "MUST_BE",
          timezone: [],
        },
      };
      this.setState(
        produce((draft) => {
          draft.fromh = data.fromh;
          draft.fromm = data.fromm;
          draft.toh = data.toh;
          draft.tom = data.tom;
        })
      );
      let from = data.dataToSend.from;
      let to = data.dataToSend.to;
      let timezoneId = String(data.dataToSend.timeZoneId);
      timeofday.timeofday.timezone.push(from, to, timezoneId);

      if (data.predicate === false) {
        const value = timeofday.timeofday;
        value.predicate = "MUST_NOT_BE";
        this.setState({ timeofday });
      }
      this.setState(
        produce((draft) => {
          Object.assign(
            draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
            timeofday
          );
        })
      );
      //this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].conditions = deviceType }));
    }
    if (data.select === "region") {
      if (
        !this.state.conditionsName[this.state.newRuleIndex].includes("Region")
      ) {
        this.setState(
          produce((draft) => {
            draft.conditionsName[this.state.newRuleIndex].push("Region");
          })
        );
      }
      const region = {
        region: {
          predicate: "MUST_BE",
          regions: [],
        },
      };
      for (let i = 0; i < data.cityValue.length; i++) {
        const { id, update_at, create_at, ...qux } = data.cityValue[i];
        region.region.regions.push(qux);
      }
      if (data.predicate === false) {
        const value = region.region;
        value.predicate = "MUST_NOT_BE";
        this.setState({ region });
      }
      this.setState(
        produce((draft) => {
          Object.assign(
            draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
            region
          );
        })
      );
      //this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].conditions = deviceType }));
    }
    if (data.select === "city") {
      if (
        !this.state.conditionsName[this.state.newRuleIndex].includes("City")
      ) {
        this.setState(
          produce((draft) => {
            draft.conditionsName[this.state.newRuleIndex].push("City");
          })
        );
      }
      const city = {
        city: {
          predicate: "MUST_BE",
          cities: [],
        },
      };
      for (let i = 0; i < data.cityValue.length; i++) {
        const { update_at, create_at, ...qux } = data.cityValue[i];
        city.city.cities.push(qux);
      }
      if (data.predicate === false) {
        const value = city.city;
        value.predicate = "MUST_NOT_BE";
        this.setState({ city });
      }
      this.setState(
        produce((draft) => {
          Object.assign(
            draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
            city
          );
        })
      );
      //this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].conditions = deviceType }));
    }
    if (data.select === "Mobile carrier") {
      if (
        !this.state.conditionsName[this.state.newRuleIndex].includes(
          data.select
        )
      ) {
        this.setState(
          produce((draft) => {
            draft.conditionsName[this.state.newRuleIndex].push(data.select);
          })
        );
      }
      const mobileCarrier = {
        mobileCarrier: {
          predicate: "MUST_BE",
          mobileCarrieries: [],
        },
      };
      for (let i = 0; i < data.cityValue.length; i++) {
        const { update_at, create_at, ...qux } = data.cityValue[i];
        mobileCarrier.mobileCarrier.mobileCarrieries.push(qux);
      }
      if (data.predicate === false) {
        const value = mobileCarrier.mobileCarrier;
        value.predicate = "MUST_NOT_BE";
        this.setState({ mobileCarrier });
      }
      this.setState(
        produce((draft) => {
          Object.assign(
            draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
            mobileCarrier
          );
        })
      );
      //this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].conditions = deviceType }));
    }

    if (data.select === "country") {
      if (
        !this.state.conditionsName[this.state.newRuleIndex].includes("Country")
      ) {
        this.setState(
          produce((draft) => {
            draft.conditionsName[this.state.newRuleIndex].push("Country");
          })
        );
      }
      const country = {
        country: {
          predicate: "MUST_BE",
          countries: [],
        },
      };
      for (let i = 0; i < data.cityValue.length; i++) {
        const { id, update_at, create_at, ...qux } = data.cityValue[i];
        country.country.countries.push(qux);
      }
      if (data.predicate === false) {
        const value = country.country;
        value.predicate = "MUST_NOT_BE";
        this.setState({ country });
      }
      this.setState(
        produce((draft) => {
          Object.assign(
            draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
            country
          );
        })
      );
      //this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].conditions = deviceType }));
    }

    if (data.select === "isp") {
      if (!this.state.conditionsName[this.state.newRuleIndex].includes("ISP")) {
        this.setState(
          produce((draft) => {
            draft.conditionsName[this.state.newRuleIndex].push("ISP");
          })
        );
      }
      const isp = {
        isp: {
          predicate: "MUST_BE",
          ispies: [],
        },
      };
      for (let i = 0; i < data.cityValue.length; i++) {
        const { id, update_at, create_at, ...qux } = data.cityValue[i];
        isp.isp.ispies.push(qux);
      }
      if (data.predicate === false) {
        const value = isp.isp;
        value.predicate = "MUST_NOT_BE";
        this.setState({ isp });
      }
      this.setState(
        produce((draft) => {
          Object.assign(
            draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
            isp
          );
        })
      );
      //this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].conditions = deviceType }));
    }
    if (data.select === "brand") {
      if (
        !this.state.conditionsName[this.state.newRuleIndex].includes("Brand")
      ) {
        this.setState(
          produce((draft) => {
            draft.conditionsName[this.state.newRuleIndex].push("Brand");
          })
        );
      }
      const brand = {
        brand: {
          predicate: "MUST_BE",
          brands: [],
        },
      };
      const tempObj = [];
      let countModels = 0;
      for (let i = 0; i < this.props.brandDataReceived.length; i++) {
        for (let j = 0; j < data.models.length; j++) {
          if (data.models[j] === this.props.brandDataReceived[i].name) {
            tempObj.push(this.props.brandDataReceived[i]);
            countModels++;
          }
          if (countModels === data.models.length) {
            break;
          }
        }
        if (countModels === data.models.length) {
          break;
        }
      }
      let tempBrand = [];
      for (let i = 0; i < tempObj.length; i++) {
        const { id, update_at, create_at, ...qux } = tempObj[i];
        tempBrand.push(qux);
      }

      for (let i = 0; i < tempBrand.length; i++) {
        tempBrand[i].models = tempBrand[i].models.filter((model) =>
          data.tagsData.includes(model)
        );
      }

      if (data.predicate === false) {
        const value = brand.brand;
        value.predicate = "MUST_NOT_BE";
        this.setState({ brand });
      }

      brand.brand.brands.push(tempBrand);

      this.setState(
        produce((draft) => {
          Object.assign(
            draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
            brand
          );
        })
      );
    }

    if (data.select === "browser") {
      if (
        !this.state.conditionsName[this.state.newRuleIndex].includes("Browser")
      ) {
        this.setState(
          produce((draft) => {
            draft.conditionsName[this.state.newRuleIndex].push("Browser");
          })
        );
      }
      const browser = {
        browser: {
          predicate: "MUST_BE",
          browsers: [],
        },
      };
      const tempObj = [];
      let countModels = 0;
      for (let i = 0; i < this.props.browserDataReceived.length; i++) {
        for (let j = 0; j < data.models.length; j++) {
          if (data.models[j] === this.props.browserDataReceived[i].name) {
            tempObj.push(this.props.browserDataReceived[i]);
            countModels++;
          }
          if (countModels === data.models.length) {
            break;
          }
        }
        if (countModels === data.models.length) {
          break;
        }
      }
      let tempBrand = [];
      for (let i = 0; i < tempObj.length; i++) {
        const { id, update_at, create_at, ...qux } = tempObj[i];
        tempBrand.push(qux);
      }

      for (let i = 0; i < tempBrand.length; i++) {
        tempBrand[i].version = tempBrand[i].version.filter((version) =>
          data.tagsData.includes(version)
        );
      }

      if (data.predicate === false) {
        const value = browser.browser;
        value.predicate = "MUST_NOT_BE";
        this.setState({ browser });
      }
      browser.browser.browsers.push(tempBrand);
      this.setState(
        produce((draft) => {
          Object.assign(
            draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
            browser
          );
        })
      );
    }

    if (data.select === "operating") {
      if (
        !this.state.conditionsName[this.state.newRuleIndex].includes(
          "Operating system"
        )
      ) {
        this.setState(
          produce((draft) => {
            draft.conditionsName[this.state.newRuleIndex].push(
              "Operating system"
            );
          })
        );
      }
      const os = {
        os: {
          predicate: "MUST_BE",
          oss: [],
        },
      };
      const tempObj = [];
      let countModels = 0;
      for (let i = 0; i < this.props.osDataReceived.length; i++) {
        for (let j = 0; j < data.models.length; j++) {
          if (data.models[j] === this.props.osDataReceived[i].name) {
            tempObj.push(this.props.osDataReceived[i]);
            countModels++;
          }
          if (countModels === data.models.length) {
            break;
          }
        }
        if (countModels === data.models.length) {
          break;
        }
      }
      let tempBrand = [];
      for (let i = 0; i < tempObj.length; i++) {
        const { id, update_at, create_at, ...qux } = tempObj[i];
        tempBrand.push(qux);
      }

      for (let i = 0; i < tempBrand.length; i++) {
        tempBrand[i].version = tempBrand[i].version.filter((version) =>
          data.tagsData.includes(version)
        );
      }

      if (data.predicate === false) {
        const value = os.os;
        value.predicate = "MUST_NOT_BE";
        this.setState({ os });
      }
      os.os.oss.push(tempBrand);
      this.setState(
        produce((draft) => {
          Object.assign(
            draft.dataToSend.path.rule[draft.newRuleIndex].conditions,
            os
          );
        })
      );
    }
    if (
      !this.state.selectedOptions[this.state.newRuleIndex].includes(data.select)
    ) {
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex].push(data.select);
        })
      );
    }
    setTimeout(() => {
      this.filterSelectForConditions();
    }, 100);
    this.calculatePlaceholderName();
  };

  showCondtionsName = (name) => {
    if (name === "Connection type") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .connectionType.predicate === "MUST_BE"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }

    if (name === "Device type") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .deviceType.predicate === "MUST_BE"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
    if (name === "Brand") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .brand.predicate === "MUST_BE"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
    if (name === "Browser") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .browser.predicate === "MUST_BE"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
    if (name === "Operating system") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.os
          .predicate === "MUST_BE"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
    if (name === "Region") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .region.predicate === "MUST_BE"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
    if (name === "City") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.city
          .predicate === "MUST_BE"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
    if (name === "Mobile carrier") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .mobileCarrier.predicate === "MUST_BE"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
    if (name === "Country") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .country.predicate === "MUST_BE"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
    if (name === "Proxy") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .proxy.predicate === "MUST_BE"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
    if (name === "ISP") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.isp
          .predicate === "MUST_BE"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
    if (name === "Language") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .language.predicate === "MUST_BE"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }

    if (name === "Weekday") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .weekday.predicate === "MUST_BE"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
    if (name === "IP") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.ip
          .predicate === "MUST_BE"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
    if (name === "Referrer") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .referrer.predicate === "MUST_BE"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
    if (name === "Referrer domain") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .referrerDomain.predicate === "MUST_BE"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
    if (name === "Time of day") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .timeofday.predicate === "MUST_BE"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
    if (name === "Custom variable 1") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var1
          .predicate === "MUST_BE" ||
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var1
          .predicate === "CONTAIN"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }

    if (name === "Custom variable 2") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var2
          .predicate === "MUST_BE" ||
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var2
          .predicate === "CONTAIN"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
    if (name === "Custom variable 3") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var3
          .predicate === "MUST_BE" ||
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var3
          .predicate === "CONTAIN"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
    if (name === "Custom variable 4") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var4
          .predicate === "MUST_BE" ||
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var4
          .predicate === "CONTAIN"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
    if (name === "Custom variable 5") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var5
          .predicate === "MUST_BE" ||
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var5
          .predicate === "CONTAIN"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
    if (name === "Custom variable 6") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var6
          .predicate === "MUST_BE" ||
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var6
          .predicate === "CONTAIN"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
    if (name === "Custom variable 7") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var7
          .predicate === "MUST_BE" ||
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var7
          .predicate === "CONTAIN"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }

    if (name === "Custom variable 8") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var8
          .predicate === "MUST_BE" ||
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var8
          .predicate === "CONTAIN"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
    if (name === "Custom variable 9") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var9
          .predicate === "MUST_BE" ||
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var9
          .predicate === "CONTAIN"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
    if (name === "Custom variable 10") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .var10.predicate === "MUST_BE" ||
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .var10.predicate === "CONTAIN"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
    if (name === "Custom variable 11") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .var11.predicate === "MUST_BE" ||
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .var11.predicate === "CONTAIN"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }

    if (name === "Custom variable 12") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .var12.predicate === "MUST_BE" ||
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .var12.predicate === "CONTAIN"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
    if (name === "Custom variable 13") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .var13.predicate === "MUST_BE" ||
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .var13.predicate === "CONTAIN"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
    if (name === "Custom variable 14") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .var14.predicate === "MUST_BE" ||
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .var14.predicate === "CONTAIN"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
    if (name === "Custom variable 15") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .var15.predicate === "MUST_BE" ||
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .var15.predicate === "CONTAIN"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
    if (name === "Custom variable 16") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .var16.predicate === "MUST_BE" ||
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .var16.predicate === "CONTAIN"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
    if (name === "Custom variable 17") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .var17.predicate === "MUST_BE" ||
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .var17.predicate === "CONTAIN"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }

    if (name === "Custom variable 18") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .var18.predicate === "MUST_BE" ||
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .var18.predicate === "CONTAIN"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
    if (name === "Custom variable 19") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .var19.predicate === "MUST_BE" ||
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .var19.predicate === "CONTAIN"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
    if (name === "Custom variable 20") {
      if (
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .var20.predicate === "MUST_BE" ||
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
          .var20.predicate === "CONTAIN"
      ) {
        return <div className="condition-name-green">{name}</div>;
      } else {
        return <div className="condition-name-red">{name}</div>;
      }
    }
  };

  showConditionValues = (data) => {
    if (data === "Connection type") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.connectionType.connection.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }
    if (data === "Device type") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.deviceType.device.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }
    if (data === "Region") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.region.regions.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }

    if (data === "Proxy") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.proxy.proxies.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }

    if (data === "City") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.city.cities.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }
    if (data === "Mobile carrier") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.mobileCarrier.mobileCarrieries.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }
    if (data === "Country") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.country.countries.map((name) => (
            <>{name.country_name}/</>
          ))}
        </>
      );
    }

    if (data === "ISP") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.isp.ispies.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }

    if (data === "Language") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.language.languages.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }

    if (data === "Brand") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.brand.brands[0].map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }

    if (data === "Operating system") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.os.oss[0].map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }

    if (data === "Browser") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.browser.browsers[0].map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }

    if (data === "Weekday") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.weekday.weekdays.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }
    if (data === "IP") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.ip.ips.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }
    if (data === "Referrer") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.referrer.referrers.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }
    if (data === "Referrer domain") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.referrerDomain.referrerDomains.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }
    if (data === "Time of day") {
      return (
        <>
          {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
            .timeofday.timezone[0] +
            " - " +
            this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions
              .timeofday.timezone[1]}
        </>
      );
    }
    if (data === "Custom variable 1") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.var1.var1s.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }
    if (data === "Custom variable 2") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.var2.var2s.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }
    if (data === "Custom variable 3") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.var3.var3s.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }
    if (data === "Custom variable 4") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.var4.var4s.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }
    if (data === "Custom variable 5") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.var5.var5s.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }
    if (data === "Custom variable 6") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.var6.var6s.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }
    if (data === "Custom variable 7") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.var7.var7s.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }
    if (data === "Custom variable 8") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.var8.var8s.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }
    if (data === "Custom variable 9") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.var9.var9s.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }
    if (data === "Custom variable 10") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.var10.var10s.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }
    if (data === "Custom variable 11") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.var11.var11s.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }
    if (data === "Custom variable 12") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.var12.var12s.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }
    if (data === "Custom variable 13") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.var13.var13s.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }
    if (data === "Custom variable 14") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.var14.var14s.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }
    if (data === "Custom variable 15") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.var15.var15s.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }
    if (data === "Custom variable 16") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.var16.var16s.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }
    if (data === "Custom variable 17") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.var17.var17s.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }
    if (data === "Custom variable 18") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.var18.var18s.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }
    if (data === "Custom variable 19") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.var19.var19s.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }
    if (data === "Custom variable 20") {
      return (
        <>
          {this.state.dataToSend.path.rule[
            this.state.newRuleIndex
          ].conditions.var20.var20s.map((name) => (
            <>{name.name}/</>
          ))}
        </>
      );
    }
  };

  showOptions = () => {
    if (this.state.options.length > 0) {
      this.state.options.map((options) => {
        return <option value={options.value}>{options.name}</option>;
      });
    }
  };

  removeOptions = (data) => {
    this.state.options = this.state.options.filter(function (el) {
      return el.value !== data.select;
    });
  };

  deleteAllConditions = () => {
    const conditions = {
      conditions: {},
    };
    this.setState(
      produce((draft) => {
        Object.assign(
          draft.dataToSend.path.rule[draft.newRuleIndex],
          conditions
        );
      })
    );
    this.setState(
      produce((draft) => {
        draft.conditionsName[this.state.newRuleIndex] = [];
      })
    );
    this.setState(
      produce((draft) => {
        draft.selectedOptions[draft.newRuleIndex] = [];
      })
    );
    this.filterSelectForConditions();
  };

  editRuleCondition = (name) => {
    this.setState(
      produce((draft) => {
        draft.editSelect = name;
      })
    );
  };

  reAssignSelectConditions = () => {
    for (let i = 0; i < this.state.options.length; i++) {
      this.setState(
        produce((draft) => {
          draft.filteredOptions[i] = draft.options.concat();
        })
      );
    }
  };

  filterSelectForConditions = () => {
    this.reAssignSelectConditions();
    for (
      let i = 0;
      i < this.state.selectedOptions[this.state.newRuleIndex].length;
      i++
    ) {
      this.setState(
        produce((draft) => {
          draft.filteredOptions[this.state.newRuleIndex] =
            draft.filteredOptions[this.state.newRuleIndex].filter(function (
              el
            ) {
              return el.value !== draft.selectedOptions[draft.newRuleIndex][i];
            });
        })
      );
    }
  };

  deleteOneCondition = (name) => {
    this.setState(
      produce((draft) => {
        draft.conditionsName[this.state.newRuleIndex] =
          this.state.conditionsName[this.state.newRuleIndex].filter(function (
            el
          ) {
            return el !== name;
          });
      })
    );
    if (name === "Connection type") {
      const { connectionType, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "connection";
          });
        })
      );
    }

    if (name === "Weekday") {
      const { weekday, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "weekday";
          });
        })
      );
    }
    if (name === "IP") {
      const { ip, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "IP";
          });
        })
      );
    }
    if (name === "Time of day") {
      const { timeofday, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "timezone";
          });
        })
      );
    }
    if (name === "Referrer") {
      const { referrer, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "Referrer";
          });
        })
      );
    }
    if (name === "Referrer domain") {
      const { referrerDomain, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "Referrer domain";
          });
        })
      );
    }
    if (name === "Device type") {
      const { deviceType, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "device";
          });
        })
      );
    }
    if (name === "Region") {
      const { region, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "region";
          });
        })
      );
    }
    if (name === "City") {
      const { city, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "city";
          });
        })
      );
    }
    if (name === "Mobile carrier") {
      const { mobileCarrier, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "Mobile carrier";
          });
        })
      );
    }
    if (name === "Country") {
      const { country, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "country";
          });
        })
      );
    }

    if (name === "Proxy") {
      const { proxy, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "proxy";
          });
        })
      );
    }
    if (name === "ISP") {
      const { isp, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "isp";
          });
        })
      );
    }
    if (name === "Language") {
      const { language, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "language";
          });
        })
      );
    }
    if (name === "Browser") {
      const { browser, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "browser";
          });
        })
      );
    }
    if (name === "Brand") {
      const { brand, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "brand";
          });
        })
      );
    }
    if (name === "Operating system") {
      const { os, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "operating";
          });
        })
      );
    }
    if (name === "Custom variable 1") {
      const { var1, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "Custom variable 1";
          });
        })
      );
    }
    if (name === "Custom variable 2") {
      const { var2, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "Custom variable 2";
          });
        })
      );
    }
    if (name === "Custom variable 3") {
      const { var3, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "Custom variable 3";
          });
        })
      );
    }
    if (name === "Custom variable 4") {
      const { var4, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "Custom variable 4";
          });
        })
      );
    }
    if (name === "Custom variable 5") {
      const { var5, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "Custom variable 5";
          });
        })
      );
    }
    if (name === "Custom variable 6") {
      const { var6, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "Custom variable 6";
          });
        })
      );
    }
    if (name === "Custom variable 7") {
      const { var7, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "Custom variable 7";
          });
        })
      );
    }
    if (name === "Custom variable 8") {
      const { var8, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "Custom variable 8";
          });
        })
      );
    }
    if (name === "Custom variable 9") {
      const { var9, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "Custom variable 9";
          });
        })
      );
    }
    if (name === "Custom variable 10") {
      const { var10, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "Custom variable 10";
          });
        })
      );
    }
    if (name === "Custom variable 11") {
      const { var11, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "Custom variable 11";
          });
        })
      );
    }
    if (name === "Custom variable 12") {
      const { var12, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "Custom variable 12";
          });
        })
      );
    }
    if (name === "Custom variable 13") {
      const { var13, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "Custom variable 13";
          });
        })
      );
    }
    if (name === "Custom variable 14") {
      const { var14, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "Custom variable 14";
          });
        })
      );
    }
    if (name === "Custom variable 15") {
      const { var15, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "Custom variable 15";
          });
        })
      );
    }
    if (name === "Custom variable 16") {
      const { var16, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "Custom variable 16";
          });
        })
      );
    }
    if (name === "Custom variable 17") {
      const { var17, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "Custom variable 17";
          });
        })
      );
    }
    if (name === "Custom variable 18") {
      const { var18, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "Custom variable 18";
          });
        })
      );
    }
    if (name === "Custom variable 19") {
      const { var19, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "Custom variable 19";
          });
        })
      );
    }
    if (name === "Custom variable 20") {
      const { var20, ...qux } =
        this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
      this.setState(
        produce((draft) => {
          Object.assign(
            (draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux)
          );
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[
            draft.newRuleIndex
          ].filter(function (el) {
            return el !== "Custom variable 20";
          });
        })
      );
    }
    this.filterSelectForConditions();
    setTimeout(() => {
      this.calculatePlaceholderName();
    }, 100);
  };

  changeTabs = (value) => {
    if (value === "generalTab") {
      this.setState(
        produce((draft) => {
          draft.isShowDestinationTab = false;
          draft.isShowGeneralTab = true;
        })
      );
    } else if (value === "destinationTab") {
      this.setState(
        produce((draft) => {
          draft.isShowGeneralTab = false;
          draft.isShowDestinationTab = true;
        })
      );
    } else if (value === "trackingTab") {
    }
  };


  toggleTabs = (e) => {

    if(e.target.id ==="general") {
      this.setState(
        produce((draft) => {
          draft.isShowDestinationTab = false;
          draft.isShowGeneralTab = true;
          draft.isShowTrackingTab = false;
          draft.isActiveTab = "general";
        })
      );
    }

    if (e.target.id === "destination"){
      this.setState(
        produce((draft) => {
          draft.isShowDestinationTab = true;
          draft.isShowGeneralTab = false;
          draft.isShowTrackingTab = false;
          draft.isActiveTab = "destination";
        })
      );
    }
    if (e.target.id === "tracking" && this.state.isCampaignLoadedSuccessfully){
      this.setState(
        produce((draft) => {
          draft.isShowDestinationTab = false;
          draft.isShowGeneralTab = false;
          draft.isShowTrackingTab = true;
          draft.isActiveTab = "tracking";
        })
      );
    }

  }

  onBackClicked = () => {
    if(this.state.isShowDestinationTab === true) {
      this.setState(
        produce((draft) => {
          draft.isShowDestinationTab = false;
          draft.isShowGeneralTab = true;
          draft.isShowTrackingTab = false;
          draft.isActiveTab = "general";
        })
      );
    }

    if(this.state.isShowTrackingTab === true) {
      this.setState(
        produce((draft) => {
          draft.isShowDestinationTab = true;
          draft.isShowGeneralTab = false;
          draft.isShowTrackingTab = false;
          draft.isActiveTab = "destination";
        })
      );
    }

  }

  nextClicked = () => {
    if(this.state.isShowDestinationTab === false) {
      this.setState(
        produce((draft) => {
          draft.isShowDestinationTab = true;
          draft.isShowGeneralTab = false;
          draft.isShowTrackingTab = false;
          draft.isActiveTab = "destination";
        })
      );
    }
    if(this.state.isShowDestinationTab === true) {
      this.setState(
        produce((draft) => {
          draft.isShowDestinationTab = false;
          draft.isShowGeneralTab = false;
          draft.isShowTrackingTab = true;
          draft.isActiveTab = "tracking";
        })
      );
    }


  };
  render() {
    console.log("SAJDJSDJSDJKSDKJSD", this.state.isShowDestinationTab)
    return (
      <div>
        <Dialog
          className="add_affiliate_networks_dialogue"
          title={
            (this.props.state.isDuplicate ? "Duplicate " : "Edit ") +
            this.state.dataToSend.name
          }
          onClose={
            this.state.isCampaignPatchedSuccessfully
              ? () =>
                  this.props.closeEditAdvancedCampaignDialogueAfterSubmission(
                    this.props.state.isDuplicate ? "true" : "false"
                  )
              : () => this.props.toggleCampaignDialogue("Edit_Toggle_Campaign")
          }
        >
          <div className="add_affiliate_network_dialogue_content">
            <div className="content_form">
              <ModalForm
                state={this.state}
                changeTabs={this.changeTabs}
                simpleInputChangeHandler={this.simpleInputChangeHandler}
                simpleRadioChangeHandler={this.simpleRadioChangeHandler}
                countryChangeHandler={this.countryChangeHandler}
                tSChangeHandler={this.tSChangeHandler}
                addParameter={this.addParameter}
                addTokenToUrl={this.addTokenToUrl}
                handleTagChange={this.handleTagChange}
                addLandersAndOffersMethod={this.addLandersAndOffersMethod}
                selectFlowHandler={this.selectFlowHandler}
                flowsDataReceived={
                  this.props.flowsDataReceived !== null &&
                  this.props.flowsDataReceived.data
                }
                costValueChangeHandler={this.costValueChangeHandler}
                costModelSelectChangeHandler={this.costModelSelectChangeHandler}
                costValueInputChangeHandler={this.costValueInputChangeHandler}
                //inputChangeHandler={this.inputChangeHandler}
                filterChange={this.filterChange}
                onChangePane={this.onChangePane}
                inputChangeHandler={(e) => this.inputChangeHandler(e)}
                handleSwitchChange={this.handleSwitchChange}
                addNewRule={this.addNewRule}
                addNewFlow={this.addNewFlow}
                addNewPath={this.addNewPath}
                addDefaultPath={this.addDefaultPath}
                deleteRule={(e) => this.deleteRule(e)}
                duplicateRule={(e) => this.duplicateRule(e)}
                newRuleClicked={(e) => this.newRuleClicked(e)}
                defaultPathClicked={(e, defaultP) =>
                  this.defaultPathClicked(e, defaultP)
                }
                newRulePathClicked={(e, rule) =>
                  this.newRulePathClicked(e, rule)
                }
                onRuleNameChange={(e) => this.onRuleNameChange(e)}
                deleteDefaultPath={(e) => this.deleteDefaultPath(e)}
                duplicateDefaultPath={(e) => this.duplicateDefaultPath(e)}
                handleDefaultPathChange={(e) => this.handleDefaultPathChange(e)}
                handleRulePathChange={(e) => this.handleRulePathChange(e)}
                offersDataReceived={
                  this.props.allOffersDataLoaded !== null &&
                  this.props.allOffersDataLoaded.data
                }
                landersDataReceived={
                  this.props.allLandersDataLoaded !== null &&
                  this.props.allLandersDataLoaded.data
                }
                addNewOffer={this.addNewOffer}
                //addLandersAndOffersMethod={(e) => this.addLandersAndOffersMethod(e)}
                increaseWeightMethod={this.increaseWeightMethod}
                decreaseWeightMethod={this.decreaseWeightMethod}
                weightageSelectTagChangeHandler={
                  this.weightageSelectTagChangeHandler
                }
                deleteWeightageMethod={this.deleteWeightageMethod}
                addConditions={(data) => this.addConditions(data)}
                showConditionValues={(data) => this.showConditionValues(data)}
                deleteAllConditions={this.deleteAllConditions}
                showOptions={this.showOptions}
                deleteOneCondition={(name) => this.deleteOneCondition(name)}
                editRuleCondition={(name) => this.editRuleCondition(name)}
                showCondtionsName={(name) => this.showCondtionsName(name)}
                decreaseDefaultPathWeightMethod={
                  this.decreaseDefaultPathWeightMethod
                }
                increaseDefaultPathWeightMethod={
                  this.increaseDefaultPathWeightMethod
                }
                onChangePathSwitch={this.onChangePathSwitch}
                changeWeightageHandler={this.changeWeightageHandler}
                onSort={this.onSort}
                toggleTabs={this.toggleTabs}
              />
              {/* {(this.state.editAdvanceCampaignDataLoaded ) && <LoadingPanel/>} */}
            </div>
          </div>
          <DialogActionsBar>
            <div className="action_bar_buttons">
              <div className="left">
                <button
                  disabled={this.state.editAdvanceCampaignDataLoaded}
                  onClick={
                    this.state.isCampaignPatchedSuccessfully
                      ? () =>
                          this.props.closeEditAdvancedCampaignDialogueAfterSubmission(
                            this.props.state.isDuplicate ? "true" : "false"
                          )
                      : () =>
                          this.props.toggleCampaignDialogue(
                            "Edit_Toggle_Campaign"
                          )
                  }
                  className="normal_btn cancel_button gray_button_bg_imp black border_radius_normal_btn normal_text black no_border"
                >
                  Close
                </button>
                {this.state.isShowDestinationTab || this.state.isShowTrackingTab ? (
                  <button
                    disabled={this.state.editAdvanceCampaignDataLoaded}
                    onClick={this.onBackClicked}
                    className="normal_btn cancel_button gray_button_bg_imp black border_radius_normal_btn normal_text black no_border"
                  >
                    Back
                  </button>
                ) : null}
              </div>
              <div className="right">
                {this.state.isShowTrackingTab && (
                    <button
                    style={{marginRight:"8px"}}
                      onClick={this.submitCampaignData}
                      disabled={this.state.editAdvanceCampaignDataLoaded}
                      className="normal_btn theme_dark_btn theme_dark_bg_color_imp color_white_imp border_radius_normal_btn save_button normal_text"
                    >
                      Save
                    </button>
                )}
                {this.state.isShowGeneralTab || this.state.isShowDestinationTab ? (
                  <button
                    onClick={this.nextClicked}
                    disabled={this.state.editAdvanceCampaignDataLoaded}
                    className="normal_btn theme_dark_btn theme_dark_bg_color_imp color_white_imp border_radius_normal_btn save_button normal_text"
                  >
                    Next
                  </button>
                ) : null}
              </div>
            </div>
          </DialogActionsBar>
        </Dialog>
      </div>
    );
  }
}

export const mapStateToProps = createStructuredSelector({
  accessToken: makeSelectToken(),
  loading: makeSelectLoading(),
  success: makeSelectSuccess(),
  error: makeSelectError(),
  domainsAndWorkspacesListsReceived: makeSelectDomainsDataReceived(),
  tagsListReceived: makeSelectTokenTagsListReceived(),
  isTagsListReceived: makeSelectIsTagsListReceived(),
  countriesListReceived: makeSelectCountriesListReceived(),
  newCampaignSuccessResponse: makeSelectNewCampaignSuccessResponse(),
  trafficSourcesDataReceived: makeSelectTrafficSourcesDataLoaded(),
  allLandersDataLoaded: makeSelectAllLandersDataLoaded(),
  allOffersDataLoaded: makeSelectOffersDataLoaded(),
  flowsDataReceived: makeSelectLoadFlowsData(),
  patchedCampaignSuccessResponse: makeSelectPatchedCampaignSuccessResponse(),
  successAdvanceCampaign: makeSelectIsNewCampaignDataSent(),
  patchedAdvanceCampaign: makeSelectIsPatchedCampaignDataSent(),
  // newCampaignSuccessResponse: makeSelectNewCampaignSuccessResponse(),

  brandDataReceived: makeSelectBrandData(),
  browserDataReceived: makeSelectBrowserData(),
  cityDataReceived: makeSelectCityData(),
  conditionCountryDataReceived: makeSelectConditionCountryData(),
  languageDataReceived: makeSelectLanguageData(),
  regionDataReceived: makeSelectRegionData(),
  connectionTypeDataReceived: makeSelectConnectionTypeData(),
  deviceTypeDataReceived: makeSelectDeviceData(),
  osDataReceived: makeSelectOperatingSystemData(),
  proxyDataReceived: makeSelectProxyData(),
  ispDataReceived: makeSelectISPData(),
  editCampaignDataReceived: makeSelectEditCampaignDataReceived(),
});
export function mapDispatchToProps(dispatch) {
  return {
    dispatchLoadDomainsAndWorkspaces: (...payload) =>
      dispatch(loadCustomDomains(...payload)),
    dispatchLoadTrafficSources: (...payload) =>
      dispatch(loadTrafficSources(...payload)),
    dispatchLoadAllLanders: (...payload) =>
      dispatch(loadAllLanders(...payload)),
    dispatchLoadAllOffers: (...payload) => dispatch(loadOffers(...payload)),
    dispatchLoadTags: (...payload) =>
      dispatch(CampaignActions.loadTokensDictionary(...payload)),
    dispatchLoadCountries: (...payload) =>
      dispatch(CampaignActions.loadCountry(...payload)),
    dispatchPostCampaign: (...payload) =>
      dispatch(CampaignActions.postCampaign(...payload)),
    dispatchPatchCampaign: (...payload) =>
      dispatch(CampaignActions.sendEditCampaign(...payload)),
    dispatchLoadFlows: (...payload) =>
      dispatch(newFlowsActions.loadFlows(...payload)),
    dispatchLoadEditCampaign: (...payload) =>
      dispatch(CampaignActions.loadEditCampaign(...payload)),
    deleteDefaultPath: (...payload) =>
      dispatch(newFlowsActions.deleteDefaultPath(...payload)),
    deleteRulePath: (...payload) =>
      dispatch(newFlowsActions.deleteRulePath(...payload)),
    deleteRule: (...payload) =>
      dispatch(newFlowsActions.deleteRule(...payload)),

    fetchBrandData: (...payload) =>
      dispatch(newFlowsActions.loadBrand(...payload)),
    fetchCityData: (...payload) =>
      dispatch(newFlowsActions.loadCity(...payload)),
    fetchCountryData: (...payload) =>
      dispatch(newFlowsActions.loadConditionCountries(...payload)),
    fetchBrowserData: (...payload) =>
      dispatch(newFlowsActions.loadBrowser(...payload)),
    fetchDeviceTypeData: (...payload) =>
      dispatch(newFlowsActions.loadDeviceType(...payload)),
    fetchConnectionTypeData: (...payload) =>
      dispatch(newFlowsActions.loadConnectionType(...payload)),
    fetchLanguageData: (...payload) =>
      dispatch(newFlowsActions.loadLanguage(...payload)),
    fetchProxyData: (...payload) =>
      dispatch(newFlowsActions.loadProxy(...payload)),
    fetchRegionData: (...payload) =>
      dispatch(newFlowsActions.loadRegion(...payload)),
    fetchOperatingSystemData: (...payload) =>
      dispatch(newFlowsActions.loadOperatingSystem(...payload)),
    fetchISPData: (...payload) => dispatch(newFlowsActions.loadISP(...payload)),
    fetchWeekDayData: (...payload) =>
      dispatch(newFlowsActions.loadWeekDay(...payload)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditAdvancedCampaignModal);
