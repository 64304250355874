import { createSelector } from "reselect";
import { initialLandersState } from "./reducer";

const selectLandersState= (state) =>  state.Landers || initialLandersState;

const makeSelectLoading = () => createSelector(selectLandersState, (subState) => subState.loading);
const makeSelectSuccess = () => createSelector(selectLandersState, (subState) => subState.success);
const makeSelectError = () => createSelector(selectLandersState, (subState) => subState.error);
const makeSelectCountriesListReceived = () => createSelector(selectLandersState, (subState) => subState.countriesListReceived);

const makeSelectTokenTagsListReceived = () => createSelector(selectLandersState, (subState) => subState.tokenTagsListReceived);
const makeSelectIsTagsListReceived = () => createSelector(selectLandersState, (subState) => subState.isTokenTagsListReceived);

const makeSelectIsNewLanderDataSent = () => createSelector(selectLandersState, (subState) => subState.isNewLanderDataSent);
const makeSelectIsAllLandersLoaded = () => createSelector(selectLandersState, (subState) => subState.isAllLandersLoaded);
const makeSelectIsPatchedLanderDataSent = () => createSelector(selectLandersState, (subState) => subState.isPatchedLanderDataSent);
const makeSelectIsDeleteLanderDataSent = () => createSelector(selectLandersState, (subState) => subState.isDeleteLanderDataSent);
const makeSelectIsArchiveLanderDataSent = () => createSelector(selectLandersState, (subState) => subState.isArchiveLanderDataSent);

const makeSelectIsChartsDataLoaded = () => createSelector(selectLandersState, (subState) => subState.charts.isChartsDataLoaded);
const makeSelectAllChartsDataLoaded = () => createSelector(selectLandersState, (subState) => subState.charts.allChartsDataLoaded);

const makeSelectAllLandersDataLoaded = () => createSelector(selectLandersState, (subState) => subState.allLandersDataLoaded);
const makeSelectEditLanderDataReceived = () => createSelector(selectLandersState, (subState) => subState.editLanderDataReceived);

const makeSelectArchiveDeleteResponseLanders = () => createSelector(selectLandersState, (subState) => subState.archiveDeleteResponseLanders);


export {
    makeSelectLoading,
    makeSelectSuccess,
    makeSelectError,

    makeSelectCountriesListReceived,
    makeSelectTokenTagsListReceived,
    makeSelectIsTagsListReceived,

    makeSelectIsNewLanderDataSent,
    makeSelectIsAllLandersLoaded,
    makeSelectIsPatchedLanderDataSent,
    makeSelectIsDeleteLanderDataSent,
    makeSelectIsArchiveLanderDataSent,

    makeSelectAllLandersDataLoaded,
    makeSelectEditLanderDataReceived,
    makeSelectIsChartsDataLoaded,
    makeSelectAllChartsDataLoaded,

    makeSelectArchiveDeleteResponseLanders
}