import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Layout from "../../../layout";
//import PageMenuBarTop from "./PageMenuBarTop";
import PageMenuBarTop from "../../PageMenuBarTop";
import PageMenuBarBottom from "./PageMenuBarBottom";
import "../pages.css";
import { createStructuredSelector } from "reselect";
import OffersModal from "./PageModal";
import EditOffersModal from "./EditPageModal";
import DeletePageModal from "./DeletePageModal";
import {
  makeSelectDeleteOfferDataSent,
  makeSelectOffersDataLoaded,
  makeSelectArchiveOfferDataSent,
  makeSelectAllOfferChartsDataLoaded,
  makeSelectIsOfferChartsDataLoaded,
  makeSelectLoading,
  makeSelectError,
  makeSelectNewOfferDataSent,
  makeSelectPatchedOfferDataSent,
  makeSelectArchiveDeleteResponseOffers,
  makeSelectLoadCurrenciesList,
  makeSelectTrackingUrlsReceived,
} from "./selectors";
import { deleteOffer } from "./actions";
import { makeSelectToken } from "../../../registration/LoginRegister/selectors";
import { makeSelectDomainsDataReceived } from "../../Settings/Domains/selectors";
import { loadCustomDomains } from "../../Settings/Domains/actions";
import "./affiliateNetworks.css";
import * as affiliatedNetworksActions from "../AffiliateNetworks/actions";
import * as offerActions from "./actions";
import * as flowsActions from "../NewFlows/actions";
import * as TSActions from "../../Pages/TrafficSources/actions";
import produce from "immer";
//import { Fade } from "@progress/kendo-react-animation";
import { makeSelectAffiliatedNetworkDataLoaded } from "../AffiliateNetworks/selectors";
import { makeSelectCountriesData } from "../NewFlows/selectors";
import { makeSelectLoadTimeZoneData } from "./selectors";
import {
  makeSelectIsTagsListReceived,
  makeSelectTagsListReceived,
} from "../TrafficSources/selectors";
import MenuCharts from "./../../charts/index";
import GridClassComponent from "./../../grid/grid";
import { makeSelectMoveToWorkspace } from "../../../moveToWorkspaceRedux/selectors";
import { moveToWorkspace } from "../../../moveToWorkspaceRedux/actions";
import LoadingPanel from "../../grid/loader/loader";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";

class Offers extends Component {
  state = {
    visibleOffersDialog: false,
    visibleEditOffersDialog: false,
    visibleDeleteOffersDialog: false,
    selectedOffers: null,
    offerDialogueNextBtnClicked: false,
    loadedOffersData: null,
    allOffersDataLoaded: null,
    editOfferId: null,
    success_msg: "",
    loadedWorkspaces: null,
    deletedSuccessfully: false,
    archivedSuccessfully: false,
    updateState: false,
    paginationSize: 10,
    selectedListReceivedFromGrid: [],
    isDuplicate: false,
    isArchive: false,
    archiveType: "",
    charts: {
      showCharts: false,
      chartsData: null,
      isChartsDataLoaded: false,
    },
    pageContentHeight: "500",
    groupingValues: {
      main: "",
      group1: "",
      group2: "",
    },
    showCalendar: false,
    showColumnsModal: false,
    sorting: {
      order: "visits",
      sort: "ASC",
    },
    loader: false,
    topCenter: { top: 0, left: "50%", transform: "translateX(-50%)" },
    pagination: {
      skip: 0,
      take: 50,
      page: 1,
      total: 0,
      totalPages: 1,
      blur: {
        blurFirst: true,
        blurLast: true,
        blurPrev: true,
        blurNext: true,
      },
    },
    filterStatus: "all",
    backendErrors: {
      error: false,
      errorMsg: "",
    },
    success: false,
    disableReport: false,
    reportCount: 0,
    archiveDeleteResponseOffers: null,
    showNoRecordFound: false,
  };

  fetchOffers = () => {
    this.setState(
      produce((draft) => {
        draft.loader = true;
        draft.selectedListReceivedFromGrid = [];
      })
    );
    let search = JSON.parse(localStorage.getItem("search"));
    this.setState(
      produce((draft) => {
        draft.groupingValues = search.groupingValues;
      })
    );
    return this.props.dispatchLoadOffers(
      this.props.accessToken,
      JSON.parse(localStorage.getItem("search")),
      this.state.sorting,
      true
    );
  };
  fetchDomains = async () => {
    return this.props.dispatchLoadDomains(this.props.accessToken);
  };
  fetchNetworks = async () => {
    return this.props.dispatchLoadAffiliateNetwork({
      accessToken: this.props.accessToken,
      searchData: JSON.parse(localStorage.getItem("search")),
      sorting: this.state.sorting,
      primaryPage: false,
      dataType: "short",
    });
  };
  fetchCountries = async () => {
    return this.props.dispatchLoadCountries(this.props.accessToken);
  };
  fetchTags = async () => {
    return this.props.dispatchLoadTags(this.props.accessToken);
  };
  fetchTimezone = async () => {
    return this.props.dispatchLoadTimezone(this.props.accessToken);
  };
  fetchCurrenciesData = async () => {
    return this.props.dispatchLoadingCurrencies(this.props.accessToken);
  };
  fetchTrackingUrls = async () => {
    return this.props.dispatchLoadTrackingURLS(this.props.accessToken);
  };
  fetchChartsData = () => {
    return this.props.dispatchLoadOfferChartsData({
      accessToken: this.props.accessToken,
      dates: JSON.parse(localStorage.getItem("search")).modifiedDate,
    });
  };

  deleteOffer = () => {
    let ids = [];
    for (let i = 0; i < this.state.selectedListReceivedFromGrid.length; i++) {
      ids.push(this.state.selectedListReceivedFromGrid[i].id);
    }
    return this.props.dispatchDeleteOffer({
      accessToken: this.props.accessToken,
      offerId: ids,
    });
  };

  componentDidMount() {
    // check if main group value is not offers value...
    // than set it to offers value to avoid any errors.
    if (
      JSON.parse(localStorage.getItem("search")).groupingValues.main !==
      "Offers"
    ) {
      let search = JSON.parse(localStorage.getItem("search"));
      search.groupingValues.main = "Offers";
      localStorage.setItem("search", JSON.stringify(search));
    }
    // set initial grouping values in state to be passed on to the grid...
    // so that is does not show error grouping values of none.
    if (JSON.parse(localStorage.getItem("search"))) {
      this.setState(
        produce((draft) => {
          draft.groupingValues.main = JSON.parse(
            localStorage.getItem("search")
          ).groupingValues.main;
          draft.groupingValues.group1 = JSON.parse(
            localStorage.getItem("search")
          ).groupingValues.group1;
          draft.groupingValues.group2 = JSON.parse(
            localStorage.getItem("search")
          ).groupingValues.group2;
          draft.reportCount = JSON.parse(
            localStorage.getItem("search")
          ).report.reportCount;
          draft.filterStatus = JSON.parse(
            localStorage.getItem("search")
          ).filterStatus;
        })
      );
    }

    this.fetchOffers();
    this.fetchDomains();
    this.fetchNetworks();
    this.fetchCountries();
    this.fetchTags();
    this.fetchTimezone();
    this.fetchCurrenciesData();
    this.fetchTrackingUrls();

    // code for setting grid height initially
    let pageMenuHeight = Number(
      document.getElementById("page_menu").clientHeight
    );
    let pageFullHeight = Number(
      document.getElementsByTagName("html")[0].clientHeight
    );
    this.setState(
      produce((draft) => {
        draft.pageContentHeight = pageFullHeight - pageMenuHeight - 127;
      })
    );
    this.setState({ showNoRecordFound: true });
  }

  componentDidUpdate(prevProps) {
    // Display message on archive
    if (this.props.archiveOffer && this.state.archivedSuccessfully) {
      this.setState(
        produce((draft) => {
          draft.success_msg = "Offer archived successfully.";
        })
      );
      this.setState(
        produce((draft) => {
          draft.archivedSuccessfully = false;
        })
      );
      setTimeout(() => {
        this.setState(
          produce((draft) => {
            draft.success_msg = "";
          })
        );
      }, 5000);
    }
    if (
      prevProps.chartsAllDataLoaded !== this.props.chartsAllDataLoaded &&
      this.props.chartsAllDataLoaded !== null
    ) {
      this.setState(
        produce((draft) => {
          draft.charts.chartsData = this.props.chartsAllDataLoaded;
        })
      );
    }
    if (
      prevProps.loadOffersData !== this.props.loadOffersData &&
      this.props.loadOffersData !== null
    ) {
      this.setState(
        produce((draft) => {
          draft.allOffersDataLoaded = this.props.loadOffersData;
          if (this.props.loadOffersData.total) {
            draft.pagination.total = this.props.loadOffersData.total.totalRows;
          }
          draft.loader = false;
        })
      );
      this.setTotalPagination();
      setTimeout(() => {
        this.setPaginationUI();
      }, 50);
    }
    if (
      prevProps.isMovedToWorkspace !== this.props.isMovedToWorkspace &&
      this.props.isMovedToWorkspace === true
    ) {
      this.fetchOffers();
      // this.setState(produce((draft) => {draft.loader = false;}));
    }
    if (
      prevProps.domainsDataReceived !== this.props.domainsDataReceived &&
      this.props.domainsDataReceived !== null
    ) {
      this.setState(
        produce((draft) => {
          draft.loadedWorkspaces = this.props.domainsDataReceived.workspace;
        })
      );
    }

    //showing errors
    if (prevProps.error !== this.props.error && this.props.error !== false) {
      // console.log('error in main page');
      if (Object.keys(this.props.error).length > 0) {
        this.setState(
          produce((draft) => {
            draft.backendErrors.error = true;
          })
        );
        this.setState(
          produce((draft) => {
            draft.loader = false;
          })
        );
      }
    }
    //successfully data added
    if (
      prevProps.newOfferDataSent !== this.props.newOfferDataSent &&
      this.props.error === false &&
      this.props.newOfferDataSent
    ) {
      this.setState(
        produce((draft) => {
          draft.backendErrors.error = false;
        })
      );
      // this.setState(produce((draft) => {draft.success_msg = "Offer Added Successfully.";}));
      // this.fetchOffers();
      // this.setState(produce((draft) => {draft.visibleOffersDialog = !this.state.visibleOffersDialog;}));
      // this.setState(produce((draft) => {draft.offerDialogueNextBtnClicked = !this.state.offerDialogueNextBtnClicked;}));
      // this.setState(produce((draft) => {draft.loader = false;}));
    }
    //successfully data updated
    if (
      prevProps.patchedOfferDataSent !== this.props.patchedOfferDataSent &&
      this.props.error === false &&
      this.props.patchedOfferDataSent
    ) {
      this.setState(
        produce((draft) => {
          draft.backendErrors.error = false;
        })
      );
    }
    //successfully data delete
    if (
      prevProps.deletedOffer !== this.props.deletedOffer &&
      this.props.error === false &&
      this.props.deletedOffer === true
    ) {
      this.setState(
        produce((draft) => {
          draft.backendErrors.error = false;
        })
      );
      this.setState(
        produce((draft) => {
          draft.success_msg = "Offer deleted successfully.";
        })
      );
      this.fetchOffers();
      this.setState(
        produce((draft) => {
          draft.visibleDeleteOffersDialog =
            !this.state.visibleDeleteOffersDialog;
        })
      );
      // this.setState(produce((draft) => { draft.loader = false; }));
    }
    //successfully data archived or restore
    if (
      prevProps.archiveOffer !== this.props.archiveOffer &&
      this.props.error === false &&
      this.props.archiveOffer
    ) {
      this.setState(
        produce((draft) => {
          draft.backendErrors.error = false;
        })
      );
      this.setState(
        produce((draft) => {
          draft.success = true;
        })
      );
      this.fetchOffers();
      this.setState(
        produce((draft) => {
          draft.archivedSuccessfully = true;
          draft.visibleDeleteOffersDialog =
            !this.state.visibleDeleteOffersDialog;
        })
      );
      // this.setState(produce((draft) => { draft.loader = false; }));
    }

    // check for archive or delete or restore message from backend
    if (
      prevProps.archiveDeleteResponseOffers !==
        this.props.archiveDeleteResponseOffers &&
      this.props.archiveDeleteResponseOffers !== null
    ) {
      this.setState(
        produce((draft) => {
          draft.archiveDeleteResponseOffers =
            this.props.archiveDeleteResponseOffers;
        })
      );
      setTimeout(() => {
        this.setState(
          produce((draft) => {
            draft.archiveDeleteResponseOffers = null;
          })
        );
      }, 10000);
    }
  }

  // toggle modal windows
  toggleAddOfferDialog = () => {
    this.setState({ ...this.state, offerDialogueNextBtnClicked: false });
    this.setState(
      produce((draft) => {
        draft.visibleOffersDialog = !this.state.visibleOffersDialog;
      })
    );
  };
  closeOfferDialogueAfterSubmission = (data) => {
    if (data === "Add_Offer") {
      this.setState(
        produce((draft) => {
          draft.visibleOffersDialog = !this.state.visibleOffersDialog;
        })
      );
      this.setState(
        produce((draft) => {
          draft.offerDialogueNextBtnClicked =
            !this.state.offerDialogueNextBtnClicked;
        })
      );
      this.setState(
        produce((draft) => {
          draft.success_msg = "Offer Added Successfully.";
        })
      );
      this.fetchOffers();

      setTimeout(() => {
        this.setState(
          produce((draft) => {
            draft.success_msg = "";
          })
        );
      }, 5000);
    }
    if (data === "Update_Offer") {
      this.setState(
        produce((draft) => {
          draft.visibleEditOffersDialog = !this.state.visibleEditOffersDialog;
        })
      );
      this.setState(
        produce((draft) => {
          draft.success_msg = "Offer Updated Successfully.";
        })
      );
      this.fetchOffers();

      setTimeout(() => {
        this.setState(
          produce((draft) => {
            draft.success_msg = "";
          })
        );
      }, 10000);
    }
  };
  deleteOfferOnSubmit = () => {
    this.setState(
      produce((draft) => {
        draft.loader = true;
      })
    );
    this.deleteOffer();
  };
  openEditOfferDialog = (data) => {
    this.setState(
      produce((draft) => {
        draft.loader = true;
      })
    );
    if (data === "Edit") {
      this.setState(
        produce((draft) => {
          draft.isDuplicate = false;
        })
      );
    } else if (data === "Duplicate") {
      this.setState(
        produce((draft) => {
          draft.isDuplicate = true;
        })
      );
    }

    this.setState(
      produce((draft) => {
        draft.editOfferId = this.state.selectedListReceivedFromGrid[0].id;
      })
    );
    setTimeout(() => {
      if (this.state.editOfferId) {
        this.setState(
          produce((draft) => {
            draft.visibleEditOffersDialog = !this.state.visibleEditOffersDialog;
          })
        );
        // this.setState(produce((draft) => {draft.loader = false;}));
      }
    }, 300);
  };
  closeEditOfferDialog = () => {
    this.setState(
      produce((draft) => {
        draft.visibleEditOffersDialog = !this.state.visibleEditOffersDialog;
      })
    );
  };
  openDeleteOfferDialog = (e) => {
    this.setState(
      produce((draft) => {
        draft.loader = true;
      })
    );
    this.setState(
      produce((draft) => {
        draft.editOfferId = e.target.id;
      })
    );
    //console.log('draft delete edit offer', this.state.editOfferId);
    setTimeout(() => {
      this.setState(
        produce((draft) => {
          draft.visibleDeleteOffersDialog =
            !this.state.visibleDeleteOffersDialog;
        })
      );
      this.setState(
        produce((draft) => {
          draft.loader = false;
        })
      );
    }, 300);
  };
  closeDeleteOfferDialog = () => {
    this.setState(
      produce((draft) => {
        draft.visibleDeleteOffersDialog = !this.state.visibleDeleteOffersDialog;
      })
    );
  };

  selectOffer = (e) => {
    this.setState({ ...this.state, selectedOffers: e.target.id });
    document.getElementById(e.target.id).style.border = "1px solid #e0358b";
  };
  offerDialogueNextClicked = () => {
    this.setState({ ...this.state, offerDialogueNextBtnClicked: true });
  };

  sendSearchRequest = () => {
    let search = JSON.parse(localStorage.getItem("search"));
    search.pagination.skip = 0;
    if (
      search.groupingValues.main === "conversion" ||
      search.groupingValues.main === "months" ||
      search.groupingValues.main === "days" ||
      search.groupingValues.main === "hours"
    ) {
      this.setState(
        produce((draft) => {
          draft.disableReport = true;
        })
      );
    } else {
      this.setState(
        produce((draft) => {
          draft.disableReport = false;
        })
      );
    }
    localStorage.setItem("search", JSON.stringify(search));
    this.setState(
      produce((draft) => {
        draft.pagination.page = 1;
      })
    );
    this.setPaginationUI();
    this.fetchOffers();
    this.fetchChartsData();
    this.setState(
      produce((draft) => {
        draft.isAllLandersLoaded = false;
        draft.showCalendar = false;
      })
    );
  };

  showCharts = () => {
    if (this.state.charts.showCharts !== true) {
      this.fetchChartsData();
    }
    this.setState(
      produce((draft) => {
        draft.charts.showCharts = !this.state.charts.showCharts;
        draft.showCalendar = false;
        draft.charts.isChartsDataLoaded = !this.state.charts.isChartsDataLoaded;
      })
    );
  };

  toggleDeleteArchiveModal = (data) => {
    this.setState(
      produce((draft) => {
        draft.editOfferId = this.state.selectedListReceivedFromGrid[0].id;
      })
    );
    this.setState(
      produce((draft) => {
        draft.visibleDeleteOffersDialog = !this.state.visibleDeleteOffersDialog;
        if (data === "Archive") {
          draft.archiveType = "Archive";
        } else if (data === "Delete") {
          draft.archiveType = "Delete";
        } else if (data === "Restore") {
          draft.archiveType = "Restore";
        } else if (data === "Close") {
          draft.archiveType = "";
        }
      })
    );
  };

  archiveOfferHandler = (data) => {
    this.setState(
      produce((draft) => {
        draft.loader = true;
      })
    );
    let ids = [];
    for (let i = 0; i < this.state.selectedListReceivedFromGrid.length; i++) {
      ids.push(this.state.selectedListReceivedFromGrid[i].id);
    }
    this.props.dispatchArchiveOffer({
      accessToken: this.props.accessToken,
      offerId: ids,
      value: data === "Archive" ? false : true,
    });
  };

  gridCheckedList = (list) => {
    this.setState(
      produce((draft) => {
        draft.selectedListReceivedFromGrid = list;
      })
    );
  };

  toggleCalendarWindow = () => {
    this.setState(
      produce((draft) => {
        draft.showCalendar = !this.state.showCalendar;
      })
    );
  };
  moveToWorkspaceMethod = (workspaceId) => {
    this.setState(
      produce((draft) => {
        draft.loader = true;
      })
    );
    let selectedList = [...this.state.selectedListReceivedFromGrid];
    let idStringList = [];
    for (let i = 0; i < this.state.selectedListReceivedFromGrid.length; i++) {
      idStringList.push(selectedList[i].id);
    }
    return this.props.dispatchMoveToWorkspace({
      accessToken: this.props.accessToken,
      query: {
        type: "offers",
        typeIdStringList: idStringList.join(","),
        workspaceId: workspaceId,
      },
    });
  };

  toggleColumnsModal = () => {
    this.setState(
      produce((draft) => {
        draft.showColumnsModal = !this.state.showColumnsModal;
      })
    );
  };

  changeSorting = (data) => {
    let sorting = { ...this.state.sorting };
    if (String(sorting.order) === String(data) && sorting.sort === "ASC") {
      this.setState(
        produce((draft) => {
          draft.sorting.sort = "DESC";
        })
      );
    } else if (
      String(sorting.order) === String(data) &&
      sorting.sort === "DESC"
    ) {
      this.setState(
        produce((draft) => {
          draft.sorting.sort = "ASC";
        })
      );
    } else if (String(sorting.order) !== String(data)) {
      this.setState(
        produce((draft) => {
          draft.sorting.order = data;
          draft.sorting.sort = "ASC";
        })
      );
    }
    setTimeout(() => {
      this.fetchOffers();
    }, 50);
  };

  setTotalPagination = () => {
    if (this.props.loadOffersData.total) {
      if (Number(this.props.loadOffersData.total.totalRows) > 0) {
        let totalRows = Number(this.props.loadOffersData.total.totalRows);
        if (totalRows <= this.state.pagination.take) {
          this.setState(
            produce((draft) => {
              draft.pagination.totalPages = 1;
            })
          );
        } else {
          if (totalRows % Number(this.state.pagination.take) > 0) {
            this.setState(
              produce((draft) => {
                draft.pagination.totalPages =
                  Math.floor(totalRows / this.state.pagination.take) + 1;
              })
            );
          } else {
            this.setState(
              produce((draft) => {
                draft.pagination.totalPages =
                  totalRows / this.state.pagination.take;
              })
            );
          }
        }
      } else {
        this.setState(
          produce((draft) => {
            draft.pagination.totalPages = 1;
          })
        );
      }
    }
  };
  setPaginationUI = () => {
    if (Number(this.state.pagination.totalPages) === 1) {
      this.setState(
        produce((draft) => {
          draft.pagination.blur.blurFirst = true;
          draft.pagination.blur.blurPrev = true;
          draft.pagination.blur.blurNext = true;
          draft.pagination.blur.blurLast = true;
        })
      );
    } else if (
      this.state.pagination.page === 1 &&
      this.state.pagination.totalPages > 1
    ) {
      this.setState(
        produce((draft) => {
          draft.pagination.blur.blurFirst = true;
          draft.pagination.blur.blurPrev = true;
          draft.pagination.blur.blurNext = false;
          draft.pagination.blur.blurLast = false;
        })
      );
    } else if (
      this.state.pagination.page !== 1 &&
      this.state.pagination.totalPages > 1 &&
      this.state.pagination.page < this.state.pagination.totalPages
    ) {
      this.setState(
        produce((draft) => {
          draft.pagination.blur.blurFirst = false;
          draft.pagination.blur.blurPrev = false;
          draft.pagination.blur.blurNext = false;
          draft.pagination.blur.blurLast = false;
        })
      );
    } else if (
      this.state.pagination.page !== 1 &&
      this.state.pagination.totalPages > 1 &&
      this.state.pagination.page === this.state.pagination.totalPages
    ) {
      this.setState(
        produce((draft) => {
          draft.pagination.blur.blurFirst = false;
          draft.pagination.blur.blurPrev = false;
          draft.pagination.blur.blurNext = true;
          draft.pagination.blur.blurLast = true;
        })
      );
    }
  };

  changePagination = (data) => {
    let search = JSON.parse(localStorage.getItem("search"));
    if (data === "first") {
      this.setState(
        produce((draft) => {
          draft.pagination.skip = 0;
          draft.pagination.page = 1;
        })
      );
      search.pagination.skip = 0;
    } else if (data === "prev") {
      let dataToSkip = this.state.pagination.skip - this.state.pagination.take;
      this.setState(
        produce((draft) => {
          draft.pagination.skip = dataToSkip;
          draft.pagination.page -= 1;
        })
      );
      search.pagination.skip = dataToSkip;
    } else if (data === "next") {
      let dataToSkip = this.state.pagination.skip + this.state.pagination.take;
      this.setState(
        produce((draft) => {
          draft.pagination.skip = dataToSkip;
          draft.pagination.page += 1;
        })
      );
      search.pagination.skip = dataToSkip;
    } else if (data === "last") {
      let dataToSkip =
        (this.state.pagination.totalPages - 1) * this.state.pagination.take;
      this.setState(
        produce((draft) => {
          draft.pagination.skip = dataToSkip;
          draft.pagination.page = this.state.pagination.totalPages;
        })
      );
      search.pagination.skip = dataToSkip;
    }

    localStorage.setItem("search", JSON.stringify(search));
    setTimeout(() => {
      this.fetchOffers();
    }, 50);
    this.setPaginationUI();
  };

  changeGridRowsNumber = (e) => {
    let search = JSON.parse(localStorage.getItem("search"));
    search.pagination.take = Number(e.target.value);
    search.pagination.skip = 0;
    this.setState(
      produce((draft) => {
        draft.pagination.skip = 0;
        draft.pagination.page = 1;
        draft.pagination.take = Number(e.target.value);
      })
    );
    localStorage.setItem("search", JSON.stringify(search));
    setTimeout(() => {
      this.fetchOffers();
    }, 50);
    this.setPaginationUI();
  };

  changeFilterStatusType = (e) => {
    let search = JSON.parse(localStorage.getItem("search"));
    search.filterStatus = e.target.value;
    this.setState(
      produce((draft) => {
        draft.filterStatus = e.target.value;
      })
    );
    localStorage.setItem("search", JSON.stringify(search));
    setTimeout(() => {
      this.fetchOffers();
    }, 50);
  };

  toggleLoader = () => {
    this.setState(
      produce((draft) => {
        draft.loader = !this.state.loader;
      })
    );
  };
  removeLoader = () => {
    this.setState(
      produce((draft) => {
        draft.loader = false;
      })
    );
  };

  openNewReport = (data) => {
    let search = JSON.parse(localStorage.getItem("search"));
    if (data === "minus" && this.state.reportCount > 0) {
      search.report.reportCount = this.state.reportCount - 1;
      this.setState(
        produce((draft) => {
          draft.reportCount = this.state.reportCount - 1;
        })
      );
      localStorage.setItem("search", JSON.stringify(search));
    } else if (data === "plus") {
      let count = this.state.reportCount + 1;
      search.report["report" + count].name =
        this.state.selectedListReceivedFromGrid[0].name;
      search.report.reportCount = count;
      search.activeTab = count;
      this.setState(
        produce((draft) => {
          draft.reportCount = this.state.reportCount + 1;
        })
      );
      let startDate = search.modifiedDate.startDate;
      let endDate = search.modifiedDate.endDate;
      localStorage.setItem("search", JSON.stringify(search));
      setTimeout(() => {
        this.props.history.push({
          pathname: "/report",
          search:
            "id=" +
            this.state.selectedListReceivedFromGrid[0].id +
            "&reportType=" +
            search.groupingValues.main +
            "&startDate=" +
            startDate +
            "&endDate=" +
            endDate +
            "&new=true",
          hash: "",
        });
      }, 80);
    }
  };

  decreaseReportCount = () => {
    this.setState(
      produce((draft) => {
        draft.reportCount = Number(this.state.reportCount) - 1;
      })
    );
  };

  render() {
    return (
      <Fragment>
        <Layout
          reportCount={this.state.reportCount}
          decreaseReportCount={this.decreaseReportCount}
        >
          {this.state.backendErrors.error && (
            <NotificationGroup
              style={{
                alignItems: "flex-start",
                flexWrap: "wrap-reverse",
                left: "50%",
                zIndex: 10000000,
                top: "20px",
                transform: "translateX(-50%)",
              }}
            >
              <Fade enter={true} exit={true}>
                {this.state.backendErrors.error && this.props.error && (
                  <Notification
                    type={{ style: "error", icon: true }}
                    closable={true}
                    onClose={() =>
                      this.setState(
                        produce((draft) => {
                          draft.backendErrors.error = false;
                        })
                      )
                    }
                  >
                    <span>
                      {this.props.error.message + ", " + this.props.error.error}
                    </span>
                  </Notification>
                )}
              </Fade>
            </NotificationGroup>
          )}

          <div id="page_container" className="page_container">
            <div id="page_menu" className="page_menu_bar">
              <PageMenuBarTop
                sendSearchRequest={this.sendSearchRequest}
                showCharts={this.showCharts}
                toggleLoader={this.toggleLoader}
                showCalendar={this.state.showCalendar}
                toggleCalendarWindow={this.toggleCalendarWindow}
                pageType="Offers"
              />
              {this.state.charts.showCharts ? (
                <ResponsiveMenuCharts
                  data={this.state.charts.chartsData}
                  dates={
                    JSON.parse(localStorage.getItem("search")).modifiedDate
                  }
                />
              ) : null}

              <div>
                <PageMenuBarBottom
                  toggleAddOfferDialog={this.toggleAddOfferDialog}
                  loadedWorkspaces={this.state.loadedWorkspaces}
                  selectedListReceivedFromGrid={
                    this.state.selectedListReceivedFromGrid
                  }
                  toggleDeleteArchiveModal={this.toggleDeleteArchiveModal}
                  openEditOfferDialog={this.openEditOfferDialog}
                  moveToWorkspaceMethod={this.moveToWorkspaceMethod}
                  toggleColumnsModal={this.toggleColumnsModal}
                  pagination={this.state.pagination}
                  changePagination={this.changePagination}
                  changeGridRowsNumber={this.changeGridRowsNumber}
                  changeFilterStatusType={this.changeFilterStatusType}
                  filterStatus={this.state.filterStatus}
                  reportId={
                    this.state.selectedListReceivedFromGrid.length > 0
                      ? this.state.selectedListReceivedFromGrid[0].id
                      : ""
                  }
                  disable={this.state.disableReport}
                  openNewReport={this.openNewReport}
                  reportCount={this.state.reportCount}
                />
              </div>
            </div>
            <div className="page_content"></div>
            <div className="page_modal">
              {this.state.visibleOffersDialog && (
                <OffersModal
                  state={this.state}
                  toggleAddOfferDialog={this.toggleAddOfferDialog}
                  closeOfferDialogueAfterSubmission={
                    this.closeOfferDialogueAfterSubmission
                  }
                  selectOffer={(e) => this.selectOffer(e)}
                  offerDialogueNextClicked={this.offerDialogueNextClicked}
                  toggleLoader={this.toggleLoader}
                />
              )}
            </div>
            <div
              // style={{ height: this.state.pageContentHeight }}
              style={{ height: `calc(100% - 85px - 127px)` }}
              className="temporary_affiliate_network_page_data"
            >
              <GridClassComponent
                pageType="Offers"
                gridHeight={this.state.pageContentHeight}
                allDataLoaded={this.state.allOffersDataLoaded}
                paginationSize={this.state.paginationSize}
                gridCheckedList={(list) => this.gridCheckedList(list)}
                groupingValues={this.state.groupingValues}
                showColumnsModal={this.state.showColumnsModal}
                toggleColumnsModal={this.toggleColumnsModal}
                sorting={this.state.sorting}
                changeSorting={this.changeSorting}
                toggleLoader={this.toggleLoader}
                selectedListFromPage={this.state.selectedListReceivedFromGrid}
                showNoRecordFound={this.state.showNoRecordFound}
              />
            </div>

            {this.state.visibleEditOffersDialog && (
              <EditOffersModal
                state={this.state}
                openEditOfferDialog={this.openEditOfferDialog}
                closeEditOfferDialog={this.closeEditOfferDialog}
                closeOfferDialogueAfterSubmission={
                  this.closeOfferDialogueAfterSubmission
                }
                editOfferId={this.state.editOfferId}
                toggleLoader={this.toggleLoader}
                removeLoader={this.removeLoader}
              />
            )}
            {this.state.visibleDeleteOffersDialog && (
              <DeletePageModal
                state={this.state}
                closeDeleteOfferDialog={this.closeDeleteOfferDialog}
                deleteOfferOnSubmit={this.deleteOfferOnSubmit}
                editOfferId={this.state.editOfferId}
                archiveOfferHandler={this.archiveOfferHandler}
              />
            )}
            {this.state.loader && <LoadingPanel />}
            {/*end temporary page table area*/}
            {this.state.archiveDeleteResponseOffers && (
              <NotificationGroup
                style={{
                  alignItems: "flex-start",
                  flexWrap: "wrap-reverse",
                  left: "50%",
                  zIndex: 10000000,
                  top: "20px",
                  transform: "translateX(-50%)",
                }}
              >
                <Fade enter={true} exit={true}>
                  {this.state.archiveDeleteResponseOffers && (
                    <Notification
                      type={{ style: "warning", icon: true }}
                      closable={true}
                      onClose={() =>
                        this.setState(
                          produce((draft) => {
                            draft.archiveDeleteResponseOffers = null;
                          })
                        )
                      }
                    >
                      <span>{this.state.archiveDeleteResponseOffers}</span>
                    </Notification>
                  )}
                </Fade>
              </NotificationGroup>
            )}
          </div>
        </Layout>
      </Fragment>
    );
  }
}

export const mapStateToProps = createStructuredSelector({
  domainsDataReceived: makeSelectDomainsDataReceived(),
  loadAffiliateNetworkData: makeSelectAffiliatedNetworkDataLoaded(),
  loadOffersData: makeSelectOffersDataLoaded(),
  deletedOffer: makeSelectDeleteOfferDataSent(),
  archiveOffer: makeSelectArchiveOfferDataSent(),
  accessToken: makeSelectToken(),
  countriesDataReceived: makeSelectCountriesData(),
  tagsListReceived: makeSelectTagsListReceived(),
  isTagsListReceived: makeSelectIsTagsListReceived(),
  timezoneDataReceived: makeSelectLoadTimeZoneData(),
  isChartsDataLoaded: makeSelectIsOfferChartsDataLoaded(),
  chartsAllDataLoaded: makeSelectAllOfferChartsDataLoaded(),
  isMovedToWorkspace: makeSelectMoveToWorkspace(),
  offerLoading: makeSelectLoading(),
  error: makeSelectError(),
  newOfferDataSent: makeSelectNewOfferDataSent(),
  patchedOfferDataSent: makeSelectPatchedOfferDataSent(),
  archiveDeleteResponseOffers: makeSelectArchiveDeleteResponseOffers(),
  currenciesDataReceived: makeSelectLoadCurrenciesList(),
  trackingUrlsReceived: makeSelectTrackingUrlsReceived(),
});
export function mapDispatchToProps(dispatch) {
  return {
    dispatchLoadOffers: (...payload) =>
      dispatch(offerActions.loadOffers(...payload)),
    dispatchArchiveOffer: (...payload) =>
      dispatch(offerActions.archiveOffer(...payload)),
    dispatchLoadAffiliateNetwork: (...payload) =>
      dispatch(affiliatedNetworksActions.loadAffiliateNetwork(...payload)),
    dispatchLoadDomains: (...payload) =>
      dispatch(loadCustomDomains(...payload)),
    dispatchDeleteOffer: (...payload) => dispatch(deleteOffer(...payload)),
    dispatchLoadCountries: (...payload) =>
      dispatch(flowsActions.loadCountries(...payload)),
    dispatchLoadTags: (...payload) =>
      dispatch(TSActions.loadTokensDictionary(...payload)),
    dispatchLoadTimezone: (...payload) =>
      dispatch(offerActions.loadTimezone(...payload)),
    dispatchLoadingCurrencies: (...payload) =>
      dispatch(offerActions.loadCurrency(...payload)),
    dispatchLoadTrackingURLS: (...payload) =>
      dispatch(offerActions.loadTrackingURLS(...payload)),
    dispatchLoadOfferChartsData: (...payload) =>
      dispatch(offerActions.loadOffersChartsData(...payload)),
    dispatchMoveToWorkspace: (...payload) =>
      dispatch(moveToWorkspace(...payload)),

    //dispatchLoadAffiliateNetworks: (...payload) => dispatch(affiliatedNetworksActions.loadAffiliateNetwork(...payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
function ResponsiveMenuCharts(props) {
  const [dimensions, setDimensions] = React.useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  })
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    
}

    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)
    
}
  })
  return <>{//<div>{window.innerWidth}</div>
  }
  <MenuCharts data={props.data} dates={props.dates}/>
  </>

}