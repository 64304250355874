// LoginRegister selectors file.
import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectLoginRegisterDomain = (state) => state.login || initialState;

const makeSelectLoading = () => createSelector(selectLoginRegisterDomain, (subState) => subState.loading);
const makeSelectError = () => createSelector(selectLoginRegisterDomain, (subState) => subState.error);
const makeSelectSuccess = () => createSelector(selectLoginRegisterDomain, (subState) => subState.success);

const makeSelectToken = () => createSelector(selectLoginRegisterDomain, (subState) => subState.token);
const makeSelectLoggedIn = () => createSelector(selectLoginRegisterDomain, (subState) => subState.isLoggedIn);

// user data
const makeSelectUser = () => createSelector(selectLoginRegisterDomain, (subState) => subState.user.user);
const makeSelectUserId = () => createSelector(selectLoginRegisterDomain, (subState) => subState.user.userId);
const makeSelectIsUserAdmin = ()=>createSelector(selectLoginRegisterDomain, (subState) => subState.user.isUserAdmin);
const makeSelectUserCurrency = ()=>createSelector(selectLoginRegisterDomain, (subState) => subState.user.userCurrency);
// Time zone id and home_screen
const makeSelectHomeScreen = () => createSelector(selectLoginRegisterDomain, (subState) => subState.user.home_screen);
const makeSelectTimeZoneId= () => createSelector(selectLoginRegisterDomain, (subState) => subState.user.timeZoneId);

// Workspace list
const makeSelectWorkspacesList = () => createSelector(selectLoginRegisterDomain, (subState) => subState.workspacesList);
const makeSelectFilterStatus = () => createSelector(selectLoginRegisterDomain, (subState) => subState.filterStatus);


  //register selectors
const makeSelectRegisterUsername = () => createSelector(selectLoginRegisterDomain, (subState) => subState.register.username);
const makeSelectRegisterError = () => createSelector(selectLoginRegisterDomain, (subState) => subState.register.error);
const makeSelectRegisterSuccess = () => createSelector(selectLoginRegisterDomain, (subState) => subState.register.success);
const makeSelectRegisterLoading = () => createSelector(selectLoginRegisterDomain, (subState) => subState.register.loading);
const makeSelectRegistered = () => createSelector(selectLoginRegisterDomain, (subState) => subState.register.isRegistered);

//forgot password
const makeSelectForgetPasswordUserEmail = () => createSelector(selectLoginRegisterDomain, (subState) => subState.forgetpassword.useremail);
const makeSelectForgetPasswordError = () => createSelector(selectLoginRegisterDomain, (subState) => subState.forgetpassword.error);
const makeSelectForgetPasswordSuccess = () => createSelector(selectLoginRegisterDomain, (subState) => subState.forgetpassword.success);
const makeSelectForgetPasswordLoading = () => createSelector(selectLoginRegisterDomain, (subState) => subState.forgetpassword.loading);
const makeSelectForgetPassword = () => createSelector(selectLoginRegisterDomain, (subState) => subState.forgetpassword.isForgotPass);

//export default makeSelectUser;
export {
//login selectors
  makeSelectLoading,
  makeSelectError,
  makeSelectSuccess,
  makeSelectUser,
  makeSelectUserId,
  makeSelectToken,
  makeSelectLoggedIn,
  makeSelectIsUserAdmin,
  makeSelectUserCurrency,
  makeSelectWorkspacesList,
//register selectors
  makeSelectRegisterUsername,
  makeSelectRegisterError,
  makeSelectRegisterSuccess,
  makeSelectRegisterLoading,
  makeSelectRegistered,
  //forgot password selectors
  makeSelectForgetPasswordUserEmail,
  makeSelectForgetPasswordError,
  makeSelectForgetPasswordSuccess,
  makeSelectForgetPasswordLoading,
  makeSelectForgetPassword,

  makeSelectHomeScreen,
  makeSelectTimeZoneId,
  makeSelectFilterStatus
};
