import React, {Component} from "react";
import produce from "immer";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import ModalForm from "./ModalForm";
import {createStructuredSelector} from "reselect";
import {connect} from 'react-redux';
import {makeSelectDomainsDataReceived} from "../../../Settings/Domains/selectors";
import { loadCustomDomains } from "../../../Settings/Domains/actions";
import {makeSelectToken} from "../../../../registration/LoginRegister/selectors";
import { makeSelectTrafficSourcesDataLoaded } from "../../TrafficSources/selectors";
import { loadTrafficSources } from "../../TrafficSources/actions";
import { makeSelectAllLandersDataLoaded } from "../../Landers/selectors";
import { loadAllLanders } from "../../Landers/actions";
import { loadOffers } from "../../Offers/actions";
import { makeSelectOffersDataLoaded, makeSelectOffersDataLoadedSorted } from "../../Offers/selectors";
import {
    makeSelectLoading,
    makeSelectSuccess,
    makeSelectError,
    makeSelectTokenTagsListReceived,
    makeSelectIsTagsListReceived,
    makeSelectCountriesListReceived,
    makeSelectNewCampaignSuccessResponse,
    makeSelectPatchedCampaignSuccessResponse,
    makeSelectEditCampaignDataReceived,
} from "../selectors";
import * as CampaignActions from "../actions";
import * as newFlowsActions from "../../NewFlows/actions";
import { makeSelectLoadFlowsData } from "../../NewFlows/selectors";
import LoadingPanel from "../../../grid/loader/loader";

class EditCampaignModal extends Component {
    state = {
        dataToSend: {
            "name": "",
            "workspaceId": "",
            "trafficSourceId": "",
            "countryId": "",
            "campaign_destination": "Path",
            "path_destination": "Lander & Offer",
            "redirect_mode": "302",
            "cost_model": "Not Tracked",
            "cost_value": "1",
            "tsPostbackUrl": null,
            "campaignMode": "Redirect",
            "tsPostbackEventType": null,
            "tsPixelRedirect": null,
            "is_advance": false,
            "tags": null,
            "simpleOfferId": [],
            "simpleLanderId": [],
            "landerWeight": [],
            "offerWeight": [],
            "landerValue": [],
            "offerValue": [],
            "path": {},
            "flowId": "",
            "notes": null,
            "notes_app": ""
        },
        loadedWorkspaces: null,
        loadedDomains: null,
        tagsListReceived: null,
        loadedCountries: null,
        loadedTrafficSources: null,
        countryName: "",
        trafficSourceName: "",
        resolveErrors: "",
        allLandersDataLoaded: null,
        allOffersDataLoaded: null,
        allFlowsDataLoaded: null,
        selectedFlowId: "",
        isCampaignCreated: false,
        isCampaignCreatedSuccessfully: false,
        isCampaignPatched: false,
        isCampaignPatchedSuccessfully: false,
        isCampaignLoadedSuccessfully: true,
        campaign_url_generated: null,
        empty: {
            landers: false,
            offers: false
        },
        errors: {
            name: null,
            workspaceId: null,
            trafficSourceId: null,
            landers: null,
            offers: null,
            flow: null,
            cost_value: null,
            on_submit_cost_value: null
        },
        count: {
            landerInBackend: 0,
            landersAdded: 0,
            offersInBackend: 0,
            offersAdded: 0,
            landerWeightageTotal: 0,
            offerWeightageTotal: 0
        },
        dataToMap: {
            landers: [],
            offers: []
        },
        isEditCampaignDataAdded: false,
        editCampaignDataLoaded: true,
        sorting: {
            order: "id",
            sort: "ASC"
        },
    }
    fetchDomainsAndWorkspaces = async () => { return this.props.dispatchLoadDomainsAndWorkspaces(this.props.accessToken);  }
    fetchTrafficSources = async () => { return this.props.dispatchLoadTrafficSources({
        accessToken: this.props.accessToken,
        searchQuery: JSON.parse(localStorage.getItem("search")),
        sorting: { order: "id", sort: "ASC" },
        primaryPage: false,
        dataType: "short"
    }); };
    fetchTokenTags = async () => { return this.props.dispatchLoadTags({accessToken: this.props.accessToken}); }
    fetchCountries = async () => { return this.props.dispatchLoadCountries({accessToken: this.props.accessToken}); }
    fetchAllLanders = async () => { return this.props.dispatchLoadAllLanders({
        accessToken: this.props.accessToken,
        searchQuery: JSON.parse(localStorage.getItem("search")),
        sorting: { order: "name", sort: "DESC" },
        primaryPage: false,
        dataType: "short"
    }); }
    fetchAllOffers = async () => { return this.props.dispatchLoadAllOffers(this.props.accessToken,JSON.parse(localStorage.getItem("search")),{ order: "name", sort: "DESC" },false, "short"); }
    fetchAllFlows = async () => { return this.props.dispatchLoadFlows(this.props.accessToken,JSON.parse(localStorage.getItem("search")), { order: "name", sort: "DESC" },false, "short"); }
    fetchEditCampaignData = async () => { return this.props.dispatchLoadEditCampaign({
        accessToken: this.props.accessToken,
        loadEditCampaignId: this.props.campaignId
    }); }

    componentDidMount() {
        // this.setState(produce(draft => { draft.editCampaignDataLoaded = true }));
        // fetching and workspaces initially
        if (this.state.loadedWorkspaces === null || this.state.loadedDomains === null) {
            this.fetchDomainsAndWorkspaces();
        } else {
            this.setState(produce(draft => { draft.dataToSend.workspaceId = String(this.state.loadedWorkspaces[0].id) }));
            this.setState(produce(draft => { draft.dataToSend.domainId = String(this.state.loadedDomains[0].id) }));
        }
        if (this.state.tagsListReceived === null) { this.fetchTokenTags(); }
        if (this.state.loadedCountries === null) { this.fetchCountries(); }
        if (this.state.loadedTrafficSources === null) { this.fetchTrafficSources(); }
        if (this.state.allLandersDataLoaded === null) { this.fetchAllLanders(); }
        if (this.state.allOffersDataLoaded === null) { this.fetchAllOffers(); }
        if (this.state.allFlowsDataLoaded === null) { this.fetchAllFlows(); }
        this.fetchEditCampaignData();

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        // Set domains and workspaces initial values in the state
        if (this.props.domainsAndWorkspacesListsReceived !== null && this.props.domainsAndWorkspacesListsReceived !== undefined && this.state.loadedWorkspaces === null) {
            this.setState(produce(draft => { 
                draft.loadedWorkspaces = this.props.domainsAndWorkspacesListsReceived.workspace;
                draft.dataToSend.workspaceId = String(this.props.domainsAndWorkspacesListsReceived.workspace[0].id);
                draft.loadedDomains = this.props.domainsAndWorkspacesListsReceived.domain;
             }));
        }
        // Set token tags list in the state
        if (this.props.tagsListReceived !== null && this.props.tagsListReceived !== undefined && this.state.tagsListReceived === null) {
            this.setState(produce(draft => { draft.tagsListReceived = this.props.tagsListReceived }));
        }
        // Set countries list in the state
        if (this.props.countriesListReceived !== null && this.props.countriesListReceived.length > 0 && this.state.loadedCountries === null) {
            this.setState(produce(draft => { draft.loadedCountries = this.props.countriesListReceived }));
            // this.setState(produce(draft => { draft.dataToSend.countryId = this.props.countriesListReceived[87].id }));
            // this.setState(produce(draft => { draft.countryName = this.props.countriesListReceived[87].country_name }));
        }

        // Set Traffic Sources list in the state
        if (this.props.trafficSourcesDataReceived !== null && this.props.trafficSourcesDataReceived.data.length > 0 && this.state.loadedTrafficSources === null) {
            this.setState(produce(draft => { draft.loadedTrafficSources = this.props.trafficSourcesDataReceived.data }));
            this.setState(produce(draft => { draft.loadedTrafficSources.sort(this.dynamicSort("name")) }));
            this.setState(produce(draft => { draft.dataToSend.trafficSourceId = draft.loadedTrafficSources[0].id }));
            this.setState(produce(draft => { draft.trafficSourceName = draft.loadedTrafficSources[0].name }));
                
            // this.setState(produce(draft => { draft.dataToSend.trafficSourceId = this.props.trafficSourcesDataReceived.data[0].id }));
            // this.setState(produce(draft => { draft.trafficSourceName = this.props.trafficSourcesDataReceived.data[0].name }));
        }

        // Set All landers list in the state
        if (this.props.allLandersDataLoaded !== null && this.props.allLandersDataLoaded.data.length > 0 && this.state.allLandersDataLoaded === null) {
            let landers_list = [];
            for (let i=0; i<this.props.allLandersDataLoaded.data.length; i++) {
                landers_list.push({
                    "name": this.props.allLandersDataLoaded.data[i].name,
                    "id": this.props.allLandersDataLoaded.data[i].id
                });
            }
            // set landers in backend count
            this.setState(produce(draft => { 
                draft.count.landerInBackend = landers_list.length;
                draft.allLandersDataLoaded = landers_list;
            }));
        }
        // Set landers error if no previous lander has been added by the user
        if (this.props.allLandersDataLoaded !== null && this.props.allLandersDataLoaded.data.length === 0 && this.state.empty.landers === false) {
            this.setState(produce(draft => { draft.empty.landers = true }));
            this.setState(produce(draft => { draft.errors.landers = "You do not have any lander added yet. Please go to landers section to add lander/landers before making a campaign." }));
        }

        // Set All offers list in the state
        if (this.props.allOffersDataLoaded !== null && this.props.allOffersDataLoaded.data.length > 0 && this.state.allOffersDataLoaded === null) {
            let offers_list = [];
            for (let i=0; i<this.props.allOffersDataLoaded.data.length; i++) {
                offers_list.push({
                    "name":this.props.allOffersDataLoaded.data[i].name,
                    "id": this.props.allOffersDataLoaded.data[i].id
                });
            }
            // Set offers in backend count
            this.setState(produce(draft => { draft.count.offersInBackend = offers_list.length }));
            this.setState(produce(draft => { draft.allOffersDataLoaded = offers_list }));
        }
        // Set offers error if no previous offer has been added by the user
        if (this.props.allOffersDataLoaded !== null && this.props.allOffersDataLoaded.data.length === 0 && this.state.empty.offers === false) {
            this.setState(produce(draft => { draft.empty.offers = true }));
            this.setState(produce(draft => { draft.errors.offers = "You do not have any offer added yet. Please go to offers section to add offer/offers before making a campaign." }));
        }

        // set all flows in the state
        if (this.props.flowsDataReceived !== null && this.props.flowsDataReceived !== undefined && this.state.allFlowsDataLoaded === null) {
            // if (this.props.flowsDataReceived.data) {
            //     if () {}
            // }
            this.setState(produce(draft => { draft.allFlowsDataLoaded = this.props.flowsDataReceived.data }));
        }

        // -----------------------------------------------s
        // set initial dataToSend of the edit campaign data received
        // if (this.props.editCampaignDataReceived !== null && this.state.isEditCampaignDataAdded === false) {
        if ((prevProps.editCampaignDataReceived !== this.props.editCampaignDataReceived) && this.props.editCampaignDataReceived !== null && this.state.isEditCampaignDataAdded === false) {
            // console.log("Data received: ", this.props.editCampaignDataReceived);
            this.setState(produce(draft => {
                draft.isEditCampaignDataAdded = true;
                draft.dataToSend.name = this.props.editCampaignDataReceived.name + (this.props.state.isDuplicate ? " (Duplicate)" : "");
                draft.dataToSend.workspaceId = this.props.editCampaignDataReceived.workspace.id;
                draft.dataToSend.trafficSourceId = this.props.editCampaignDataReceived.trafficSource.id;
                if (this.props.editCampaignDataReceived.notes !== null || this.props.editCampaignDataReceived.notes !== false) {
                    draft.dataToSend.notes = this.props.editCampaignDataReceived.notes;
                    draft.dataToSend.notes_app = this.props.editCampaignDataReceived.notes;
                }
                // draft.dataToSend.countryId = this.props.editCampaignDataReceived.countryId;
                if (this.state.loadedCountries !== null) {
                    this.countryChangeHandler(this.props.editCampaignDataReceived.countryId);
                } else {
                    setTimeout(() => {
                        this.countryChangeHandler(this.props.editCampaignDataReceived.countryId);
                    },1500);
                }
                draft.dataToSend.campaign_destination = this.props.editCampaignDataReceived.campaign_destination;
                draft.dataToSend.cost_model = this.props.editCampaignDataReceived.cost_model;
                draft.dataToSend.tsPostbackUrl = this.props.editCampaignDataReceived.tsPostbackUrl;
                draft.dataToSend.campaignMode = this.props.editCampaignDataReceived.campaignMode;
                draft.dataToSend.tsPostbackEventType = this.props.editCampaignDataReceived.tsPostbackEventType;
                draft.dataToSend.tsPixelRedirect = this.props.editCampaignDataReceived.tsPixelRedirect;
                draft.dataToSend.is_advance = this.props.editCampaignDataReceived.is_advance;
            }));
            setTimeout(() => {
                this.tSChangeHandler(this.props.editCampaignDataReceived.trafficSource.id);
            },1000);

            if (this.props.editCampaignDataReceived.campaign_destination !== "Flow") {
                this.setState(produce(draft => {
                    draft.dataToSend.path_destination = this.props.editCampaignDataReceived.path[0].path_destination;
                    draft.dataToSend.redirect_mode = this.props.editCampaignDataReceived.path[0].redirect_mode;
                }));
                let offerIds = [];
                let offerWeights = [];
                let offerValues = [];
                let offerWeightageTotal = 0;
                let landerIds = [];
                let landerWeights = [];
                let landerValues = [];
                let landerWeightageTotal = 0;
                if (this.props.editCampaignDataReceived.path[0].path_destination !== "Offer Only") {
                    for (let i=0; i<this.props.editCampaignDataReceived.path[0].landers.length;i++) {
                        landerIds.push(String(this.props.editCampaignDataReceived.path[0].landers[i].id));
                        landerWeights.push(String(this.props.editCampaignDataReceived.path[0].landerWeight[i]));
                        landerValues.push(String(this.props.editCampaignDataReceived.path[0].landerValue[i]));
                        landerWeightageTotal = landerWeightageTotal + Number(this.props.editCampaignDataReceived.path[0].landerValue[i]);
                        // add values to dataToMap to show it all on the frontend
                        this.setState(produce(draft => {
                            draft.dataToMap.landers.push({
                                name: "lander_" + (i),
                                weightageNumber: Number(this.props.editCampaignDataReceived.path[0].landerValue[i]),
                                weightagePercentage: Number(this.props.editCampaignDataReceived.path[0].landerWeight[i]),
                                options: [],
                                selectedId: this.props.editCampaignDataReceived.path[0].landers[i].id
                            });
                        }));
                    }
                    this.setState(produce(draft => { draft.count.landersAdded = this.props.editCampaignDataReceived.path[0].landers.length }));
                    // re-populate the lander options in the select tags..... if landers were selected
                    setTimeout(() => {
                        this.repopulateSelectOptions("lander")
                    },500);
                }
                for (let j=0; j<this.props.editCampaignDataReceived.path[0].offers.length; j++) {
                    offerIds.push(String(this.props.editCampaignDataReceived.path[0].offers[j].id));
                    offerWeights.push(String(this.props.editCampaignDataReceived.path[0].offerWeight[j]));
                    offerValues.push(String(this.props.editCampaignDataReceived.path[0].offerValue[j]));
                    offerWeightageTotal = offerWeightageTotal + Number(this.props.editCampaignDataReceived.path[0].offerValue[j]);
                    // add values to dataToMap to show it all on the frontend
                    this.setState(produce(draft => {
                        draft.dataToMap.offers.push({
                            name: "offer_" + (j),
                            weightageNumber: Number(this.props.editCampaignDataReceived.path[0].offerValue[j]),
                            weightagePercentage: Number(this.props.editCampaignDataReceived.path[0].offerWeight[j]),
                            options: [],
                            selectedId: this.props.editCampaignDataReceived.path[0].offers[j].id
                        });
                    }));
                }
                this.setState(produce(draft => { draft.count.offersAdded = this.props.editCampaignDataReceived.path[0].offers.length }));
                // re-populate the offer options in the select tags..... if offers were selected
                setTimeout(() => {
                    this.repopulateSelectOptions("offer")
                },500);

                this.setState(produce(draft => { draft.dataToSend.simpleOfferId = offerIds }));
                this.setState(produce(draft => { draft.dataToSend.offerWeight = offerWeights }));
                this.setState(produce(draft => { draft.dataToSend.offerValue = offerValues }));
                this.setState(produce(draft => { draft.count.offerWeightageTotal = offerWeightageTotal }));
                this.setState(produce(draft => { draft.dataToSend.simpleLanderId = landerIds }));
                this.setState(produce(draft => { draft.dataToSend.landerWeight = landerWeights }));
                this.setState(produce(draft => { draft.dataToSend.landerValue = landerValues }));
                this.setState(produce(draft => { draft.count.landerWeightageTotal = landerWeightageTotal }));
            }
            this.setState(produce(draft => { draft.dataToSend.path = {} }));
            this.setState(produce(draft => { draft.dataToSend.flowId = this.props.editCampaignDataReceived.flowId }));
            this.setState(produce(draft => { draft.campaign_url_generated = this.props.editCampaignDataReceived.campaign_url }));
            this.setState(produce(draft => { draft.editCampaignDataLoaded = false }));
        }

        // check if data is received after campaign patching
        if ((prevProps.patchedCampaignSuccessResponse !== this.props.patchedCampaignSuccessResponse) && this.props.patchedCampaignSuccessResponse !== null && this.state.isCampaignPatched === true) {
            this.props.toggleLoader();
            this.setState(produce(draft => {
                draft.isCampaignPatched = false;
                // To get the tracker tab working and showing the resulting data there
                // make this variable true
                draft.isCampaignPatchedSuccessfully = true;
                // set campaign url generated to display it in the tracker
                draft.campaign_url_generated = this.props.patchedCampaignSuccessResponse.campaign_url;
            }));
        }

        // check if data is received after campaign duplication
        if ((prevProps.newCampaignSuccessResponse !== this.props.newCampaignSuccessResponse) && this.props.newCampaignSuccessResponse !== null && this.state.isCampaignCreated === true) {
            this.props.toggleLoader();
            this.setState(produce(draft => {
                draft.isCampaignCreated = false;
                // To get the tracker tab working and showing the resulting data there
                // make this variable true
                draft.isCampaignPatchedSuccessfully = true;
                // set campaign url generated to display it in the tracker
                draft.campaign_url_generated = this.props.newCampaignSuccessResponse.campaign_url;
            }));
        }

        // remove loader after everything is laoded
        if (prevState.editCampaignDataLoaded !== this.state.editCampaignDataLoaded) {
            this.props.toggleLoader();
        }
    }

    dynamicSort = (property)=> {
        var sortOrder = 1;
    
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
    
        return function (a,b) {
            if(sortOrder == -1){
                return b[property].localeCompare(a[property]);
            }else{
                return a[property].localeCompare(b[property]);
            }        
        }
    }
    // for workspace and changes
    simpleInputChangeHandler = (e) => {
            this.setState(produce(draft => { draft.dataToSend[e.target.name] = e.target.value }));
            if (e.target.name === "domainId") {
                this.setStateDomainName(e.target.value);
            }
    }

    // For kendo radio buttons
    simpleRadioChangeHandler = (e) => {
        this.setState(produce(draft => { draft.dataToSend[e.target.element.name] = e.value }));
    }

    // for changing countries
    countryChangeHandler = (value) => {
        for (let i=0;i<this.state.loadedCountries.length;i++) {
            if (Number(value) === this.state.loadedCountries[i].id) {
                this.setState(produce(draft => { draft.dataToSend.countryId = this.state.loadedCountries[i].id }));
                this.setState(produce(draft => { draft.countryName = this.state.loadedCountries[i].country_name }));
            }
        }
    }

    // for changing traffic Sources
    tSChangeHandler = (value) => {
        // console.log('value', value, this.state.loadedTrafficSources);
        if (this.state.loadedTrafficSources) {
            for (let i=0;i<this.state.loadedTrafficSources.length;i++) {
                if (Number(value) === this.state.loadedTrafficSources[i].id) {
                    this.setState(produce(draft => { draft.dataToSend.trafficSourceId = this.state.loadedTrafficSources[i].id }));
                    this.setState(produce(draft => { draft.trafficSourceName = this.state.loadedTrafficSources[i].name }));
                }
            }
        }
    }


    // =============================================================================================
    // =============================================================================================
    // for adding landers and offers
    addLandersAndOffersMethod = (e) => {
        let type = e.target.id.split("_")[1];
        this.setState(produce(draft => { draft.count[type+"sAdded"] = (Number(this.state.count[type+"sAdded"]) + 1) }));
        let remainingData = [];
        // Match each value in allLandersDataLoaded to dataToSend.simpleLanderId
        // If not found the id in simpleLanderId then add it to the remainingData list....
        // because the data has not been added yet.
        for (let i=0;i<this.state["all"+this.capitalizeFirstLetter(type)+"sDataLoaded"].length;i++) {
            let itemFound = false;
            for (let j=0;j<this.state.dataToSend["simple"+this.capitalizeFirstLetter(type)+"Id"].length;j++) {
                if (Number(this.state["all"+this.capitalizeFirstLetter(type)+"sDataLoaded"][i].id) === Number(this.state.dataToSend["simple"+this.capitalizeFirstLetter(type)+"Id"][j])) {
                    itemFound = true;
                }
            }
            if (itemFound === false) {
                remainingData.push(this.state["all"+this.capitalizeFirstLetter(type)+"sDataLoaded"][i]);
            }
        }
        let data = {
            name: type + "_"+ (this.state.count[type + "sAdded"] + 1),
            weightageNumber: 100,
            weightagePercentage: this.calculateIndividualLanderWeightage(100, "true"),
            options: remainingData,
            selectedId: remainingData[0].id
        };
        // Push the data to the dataMap array
        // then set the values of total lander Weightage, simpleLanderId, landerWeight & landerValue
        this.setState(produce(draft => { draft.dataToMap[type + "s"].push(data) }));
        this.setState(produce(draft => { draft.count[type + "WeightageTotal"] = this.state.count[type+ "WeightageTotal"] + 100 }));
        this.setState(produce(draft => { draft.dataToSend["simple"+this.capitalizeFirstLetter(type)+"Id"].push(data.selectedId) }));
        this.setState(produce(draft => { draft.dataToSend[type + "Weight"].push(data.weightagePercentage) }));
        this.setState(produce(draft => { draft.dataToSend[type+ "Value"].push(data.weightageNumber) }));
        setTimeout(() => {
            this.recalculateAllWeightsPercentages(type);
            this.repopulateSelectOptions(type);
            },50);
    }

    capitalizeFirstLetter = (string) => {
        if(string !== undefined || string !== null){
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    }

    // function for both landers and offers to re-Populate the select tag options
    // re populate dataToMap select tags

    // this functions check all the remaining options.....
    repopulateSelectOptions = (type) => {
        let remainingOptions = [];
        for (let i=0;i<this.state["all"+this.capitalizeFirstLetter(type)+"sDataLoaded"].length;i++) {
            let itemFound = false;
            for (let j=0;j<this.state.dataToSend["simple"+this.capitalizeFirstLetter(type)+"Id"].length;j++) {
                if (Number(this.state["all"+this.capitalizeFirstLetter(type)+"sDataLoaded"][i].id) === Number(this.state.dataToSend["simple"+this.capitalizeFirstLetter(type)+"Id"][j])) {
                    itemFound = true;
                }
            }
            if (itemFound === false) {
                remainingOptions.push(this.state["all"+this.capitalizeFirstLetter(type)+"sDataLoaded"][i]);
            }
        }
        this.repopulateAllSelectTagOptions(remainingOptions, type);
    }

    // this function populated all the remaining options to all the select tags
    repopulateAllSelectTagOptions = (options,type) => {
        for (let i=0;i<this.state.dataToMap[type+"s"].length;i++) {
            let selectOptions = [...options];
            // match the selected id of each lander/offer to add it to the options
            for (let j=0;j<this.state["all"+this.capitalizeFirstLetter(type)+"sDataLoaded"].length;j++) {
                if (Number(this.state["all"+this.capitalizeFirstLetter(type)+"sDataLoaded"][j].id) === Number(this.state.dataToMap[type+"s"][i].selectedId)) {
                    selectOptions.push(this.state["all"+this.capitalizeFirstLetter(type)+"sDataLoaded"][j]);
                }
            }
            this.setState(produce(draft => { draft.dataToMap[type+"s"][i].options = selectOptions }));
        }
    }

    calculateIndividualLanderWeightage = (num, isNew) => {
        if (isNew) {
            return ((num/(this.state.count.landerWeightageTotal+100))*100).toFixed(2);
        } else {
            return ((num/this.state.count.landerWeightageTotal)*100).toFixed(2);
        }
    }
    calculateIndividualOfferWeightage = (num) => {
        return ((num/this.state.count.offerWeightageTotal)*100).toFixed(2);
    }

    // function for both landers and offers, where type = lander or offer
    recalculateAllWeightsPercentages = (type) => {
        for (let j=0;j<this.state.dataToSend[type+'Weight'].length; j++) {
            // first change it in dataToSend
            this.setState(produce(draft => {
                draft.dataToSend[type+'Weight'][j] = ((this.state.dataToSend[type+'Value'][j]/this.state.count[type+'WeightageTotal'])*100).toFixed(2);
            }));
            // then change it all in dataToMap
            this.setState(produce(draft => {
                draft.dataToMap[type+'s'][j].weightagePercentage = ((Number(this.state.dataToSend[type+'Value'][j])/Number(this.state.count[type+'WeightageTotal']))*100).toFixed(2);
            }));
        }
    }

    // increase and decrease weights
    increaseWeightMethod = (e) => {
        let type = e.target.id.split("_")[0];
        let index = e.target.id.split("_")[1];
        // set lander value in dataToSend
        // then set weightageNumber in dataToMap
        this.setState(produce(draft => { draft.dataToSend[type+"Value"][index] = this.state.dataToSend[type+"Value"][index] + 1 }));
        this.setState(produce(draft => { draft.dataToMap[type+"s"][index].weightageNumber = this.state.dataToMap[type+"s"][index].weightageNumber + 1 }));
        this.setState(produce(draft => { draft.count[type+"WeightageTotal"] = this.state.count[type+"WeightageTotal"] + 1 }));
        setTimeout(() => {
            console.log("State: ", this.state);
            this.recalculateAllWeightsPercentages(type);
        },50);
    }
    decreaseWeightMethod = (e) => {
        let type = e.target.id.split("_")[0];
        let index = e.target.id.split("_")[1];
        // set lander value in dataToSend
        // then set weightageNumber in dataToMap
        if (this.state.dataToSend[type+"Value"][index] > 0) {
            this.setState(produce(draft => { draft.dataToSend[type+"Value"][index] = this.state.dataToSend[type+"Value"][index] - 1 }));
            this.setState(produce(draft => { draft.dataToMap[type+"s"][index].weightageNumber = this.state.dataToMap[type+"s"][index].weightageNumber - 1 }));
            this.setState(produce(draft => { draft.count[type+"WeightageTotal"] = this.state.count[type+"WeightageTotal"] - 1 }));
            setTimeout(() => {
                this.recalculateAllWeightsPercentages(type);
            },50);
        }
    }

    changeWeightageHandler = (e)=>{
        let type = e.target.id.split("_")[0];
        let index = e.target.id.split("_")[1];
        this.setState(produce(draft => { draft.dataToSend[type+"Value"][index] = e.target.value !== "" ? Number(e.target.value) : "" }));
        this.setState(produce(draft => { draft.dataToMap[type+"s"][index].weightageNumber = e.target.value !== "" ? Number(e.target.value) : "" }));
        //this.setState(produce(draft => { draft.count[type+"WeightageTotal"] = e.target.value }))0
        setTimeout(() => {
           this.recalculateTotalValueInput(type); 
        }, 30);
        setTimeout(() => {
            this.recalculateAllWeightsPercentages(type);
        },70);
    }
    recalculateTotalValueInput = (type)=>{
        //this.setState(produce(draft => { draft.count[type+"WeightageTotal"] = e.target.value }));
        let total = 0;
        for(let i = 0 ; i< this.state.dataToSend[type + "Value"].length; i++){
            total += Number(this.state.dataToSend[type+"Value"][i]);
        }
        this.setState(produce(draft => { draft.count[type+"WeightageTotal"] = Number(total) }));
    }

    // Select tag onChange handler
    weightageSelectTagChangeHandler = (e) => {
        let type = e.target.id.split("_")[0];
        // let index = Number(e.target.id.split("_")[1])-1;
        let index = Number(e.target.id.split("_")[1]);
        this.setState(produce(draft => { draft.dataToMap[type+"s"][index].selectedId = e.target.value }));
        this.setState(produce(draft => { draft.dataToSend["simple"+this.capitalizeFirstLetter(type)+"Id"][index] = e.target.value }));
        setTimeout(() => {
            this.repopulateSelectOptions(type);
        },50);
    }

    // delete method
    deleteWeightageMethod = (e) => {
        let type = e.target.id.split("_")[0];
        let index = Number(e.target.id.split("_")[1]);
        let newSimpleItemsId = [];
        let newItemWeightList = [];
        let newItemValueList = [];
        let newDataToMapArr = [];
        let deleteItemWeightage = null;
        for (let i=0; i<this.state.dataToSend["simple"+this.capitalizeFirstLetter(type)+"Id"].length;i++) {
            if (i === index) {
                deleteItemWeightage = this.state.dataToSend[type+"Value"][i];
            } else {
                newSimpleItemsId.push(this.state.dataToSend["simple"+this.capitalizeFirstLetter(type)+"Id"][i]);
                newItemWeightList.push(this.state.dataToSend[type+"Weight"][i]);
                newItemValueList.push(this.state.dataToSend[type+"Value"][i]);
                newDataToMapArr.push(this.state.dataToMap[type+"s"][i]);
            }
        }
        this.setState(produce(draft => {draft.count[type+"sAdded"] = Number(this.state.count[type+"sAdded"]) - 1 }));
        this.setState(produce(draft => {draft.count[type+"WeightageTotal"] = Number(this.state.count[type+"WeightageTotal"]) - Number(deleteItemWeightage) }));
        this.setState(produce(draft => {draft.dataToSend["simple"+this.capitalizeFirstLetter(type)+"Id"] = newSimpleItemsId }));
        this.setState(produce(draft => {draft.dataToSend[type+"Weight"] = newItemWeightList }));
        this.setState(produce(draft => {draft.dataToSend[type+"Value"] = newItemValueList }));
        this.setState(produce(draft => {draft.dataToMap[type+"s"] = newDataToMapArr }));

        setTimeout(() => {
            this.recalculateAllWeightsPercentages(type);
            this.repopulateSelectOptions(type);
        },50);
    }

    // =============================================================================================
    // =============================================================================================

    // for adding custom tags
    tagsInputChangeHandler = () => {
        console.log("tags changed");
    }

    costModelSelectChangeHandler = (e) => {
        this.setState(produce(draft => { draft.dataToSend.cost_model = e.target.value }));
        if (e.target.value === "Not Tracked" || e.target.value === "Auto (CPI)") {
            this.setState(produce(draft => { draft.errors.cost_value = null }));
            this.setState(produce(draft => { draft.dataToSend.cost_value = 1 }));
        }
    }

    costValueChangeHandler = (e) => {
        const type = e.target.id;
        if (type === "plus" && this.state.dataToSend.cost_model === "CPC" && this.state.dataToSend.cost_value < 100) {
            this.setState(produce(draft => { draft.dataToSend.cost_value = Number(this.state.dataToSend.cost_value) + 1 }));
        }
        if (type === "plus" && this.state.dataToSend.cost_model === "CPM" && this.state.dataToSend.cost_value < 1000) {
            this.setState(produce(draft => { draft.dataToSend.cost_value = Number(this.state.dataToSend.cost_value) + 1 }));
        }
        if (type === "plus" && this.state.dataToSend.cost_model === "CPA" && this.state.dataToSend.cost_value < 5000) {
            this.setState(produce(draft => { draft.dataToSend.cost_value = Number(this.state.dataToSend.cost_value) + 1 }));
        }
        if (type === "plus" && this.state.dataToSend.cost_model === "RevShare" && this.state.dataToSend.cost_value < 1000) {
            this.setState(produce(draft => { draft.dataToSend.cost_value = Number(this.state.dataToSend.cost_value) + 1 }));
        }
        if (type === "minus" && (this.state.dataToSend.cost_model === "CPC" || this.state.dataToSend.cost_model === "CPM") && this.state.dataToSend.cost_value > 1.00001) {
            this.setState(produce(draft => { draft.dataToSend.cost_value = Number(this.state.dataToSend.cost_value) - 1 }));
        }
        if (type === "minus" && this.state.dataToSend.cost_model === "CPA" && this.state.dataToSend.cost_value >= 1) {
            this.setState(produce(draft => { draft.dataToSend.cost_value = Number(this.state.dataToSend.cost_value) - 1 }));
        }
        if (type === "minus" && this.state.dataToSend.cost_model === "RevShare" && this.state.dataToSend.cost_value >= 1) {
            this.setState(produce(draft => { draft.dataToSend.cost_value = Number(this.state.dataToSend.cost_value) - 1 }));
        }
    }
    costValueInputChangeHandler = (e) => {
        // change the value
        this.setState(produce(draft => { draft.dataToSend.cost_value = e.target.value }));
        // check for error in the value changed
        if (this.state.dataToSend.cost_model === "CPC" && (e.target.value > 100 || e.target.value < 0.00001)) {
            this.setState(produce(draft => { draft.errors.cost_value = "CPC must be greater than 0.00001 and less than 100." }));
        } else if (this.state.dataToSend.cost_model === "CPM" && (e.target.value > 1000 || e.target.value < 0.00001)) {
            this.setState(produce(draft => { draft.errors.cost_value = "CPM must be greater than 0.00001 and less than 1000." }));
        } else if (this.state.dataToSend.cost_model === "CPA" && (e.target.value > 5000 || e.target.value < 0)) {
            this.setState(produce(draft => { draft.errors.cost_value = "CPA must be greater than or equal to 0 and less than or equal to 5000." }));
        } else if (this.state.dataToSend.cost_model === "RevShare" && (e.target.value > 1000 || e.target.value < 0)) {
            this.setState(produce(draft => { draft.errors.cost_value = "RevShare must be greater than or equal to 0 and less than or equal to 1000." }));
        } else {
            this.setState(produce(draft => { draft.errors.cost_value = null }));
        }
    }

    checkErrors = () => {
        // name
        if (this.state.dataToSend.name === "" ) {
            this.setState(produce(draft => { draft.errors.name = "You must add a campaigns name" }));
        } else { this.setState(produce(draft => { draft.errors.name = null })); }
        // workspace Id
        if (this.state.dataToSend.workspaceId === "" ) {
            this.setState(produce(draft => { draft.errors.workspaceId = "You must add a workspace before you add a campaign" }));
        } else { this.setState(produce(draft => { draft.errors.workspaceId = null })); }
        // trafficSource Id
        if (this.state.dataToSend.trafficSourceId === "" ) {
            this.setState(produce(draft => { draft.errors.trafficSourceId = "You must add a traffic source before you add a campaign. If you have not added any traffic source before then go to the traffic sources tab and create a new one." }));
        } else { this.setState(produce(draft => { draft.errors.trafficSourceId = null })); }

        // Check for cost value error
        if ((this.state.dataToSend.cost_model !== "Auto (CPI)" || this.state.dataToSend.cost_model !== "Not Tracked") && this.state.dataToSend.cost_value !== null && this.state.errors.cost_value !== null) {
            this.setState(produce(draft => { draft.errors.on_submit_cost_value = "Please resolve the above error for cost value before submitting the campaign." }));
        } else { this.setState(produce(draft => { draft.errors.on_submit_cost_value = null })); }

        // check if campaign destination set to flow then check if flow is populated
        if (this.state.dataToSend.campaign_destination === "Flow" && this.state.dataToSend.flowId === "") {
            this.setState(produce(draft => { draft.errors.flow = "You must add a flow before you add a campaign." }));
        } else { this.setState(produce(draft => { draft.errors.flow = null })); }

        // check if campaign destination set to path then check if landers or offers are populated
        if (this.state.dataToSend.campaign_destination === "Path" && this.state.dataToSend.path_destination === "Lander & Offer") {
            if (this.state.dataToSend.simpleLanderId.length === 0) {
                this.setState(produce(draft => { draft.errors.landers = "You must add Lander/Landers before you add a campaign. If you have not added any Lander before then you go to the Landers tab and create a new Lander" }));
            } else { this.setState(produce(draft => { draft.errors.landers = null })); }
            if (this.state.dataToSend.simpleOfferId.length === 0) {
                this.setState(produce(draft => { draft.errors.offers = "You must add Offer/Offers before you add a campaign. If you have not added any Offer before then you go to the Offers tab and create a new Offer" }));
            } else { this.setState(produce(draft => { draft.errors.offers = null })); }
        }

        if (this.state.dataToSend.campaign_destination === "Path" && this.state.dataToSend.path_destination === "Offer Only") {
            if (this.state.dataToSend.simpleOfferId.length === 0) {
                this.setState(produce(draft => { draft.errors.offers = "You must add Offer/Offers before you add a campaign. If you have not added any Offer before then you can go to the Offers tab to create a new Offer." }));
            } else { this.setState(produce(draft => { draft.errors.offers = null })); }
            this.setState(produce(draft => { draft.errors.landers = null }));
        }
    }

    submitCampaignData = () => {

     
        const replaceValues = (value, type) => {
            let newValue;
            if(value === "" || value === "NaN" || value === "undefined" || value === NaN) {
                newValue = type === "number" ? 0 : "0"
            } else {
                newValue = value
            }
            return newValue
        }
            if(this.state.dataToSend.offerValue.some((item) => item === "" || item === NaN || item === "NaN")) {
              let values = this.state.dataToSend.offerValue?.map((ele) => replaceValues(ele, "number"))
              this.setState(
                produce((draft) => {
                  draft.dataToSend.offerValue = values;
                })
              )
            }

            if(this.state.dataToSend.offerWeight.some((item) => item === "" || item === NaN || item === "NaN")) {
                let values = this.state.dataToSend.offerWeight?.map((ele) => replaceValues(ele, "string"))
                this.setState(
                  produce((draft) => {
                    draft.dataToSend.offerWeight = values;
                  })
                )
              }

              if(this.state.dataToSend.landerValue.some((item) => item === "" || item === NaN || item === "NaN")) {
                let values = this.state.dataToSend.landerValue?.map((ele) => replaceValues(ele, "number"))
                this.setState(
                  produce((draft) => {
                    draft.dataToSend.landerValue = values;
                  })
                )
              }

              if(this.state.dataToSend.landerWeight.some((item) => item === "" || item === NaN || item === "NaN")) {
                let values = this.state.dataToSend.landerWeight?.map((ele) => replaceValues(ele, "string"))
                this.setState(
                  produce((draft) => {
                    draft.dataToSend.landerWeight = values;
                  })
                )
              }
        this.props.toggleLoader();
        // check for empty values
        this.checkErrors();
            // check if name if not empty or domain entered is a valid url
        setTimeout(() => {
            if (this.state.errors.name === null && this.state.errors.workspaceId === null && this.state.errors.trafficSourceId === null && this.state.errors.landers === null && this.state.errors.offers === null && this.state.errors.flow === null && this.state.errors.cost_value === null && this.state.errors.on_submit_cost_value === null) {
                if (this.props.state.isDuplicate === false) {
                    this.setState(produce(draft => { draft.isCampaignPatched = true }));
                } else {
                    this.setState(produce(draft => { draft.isCampaignCreated = true }));
                }
                if (this.state.dataToSend.notes_app === "") {
                    this.setState(produce(draft => { draft.dataToSend.notes = null; }));
                } else { this.setState(produce(draft => { draft.dataToSend.notes = this.state.dataToSend.notes_app; })); }
                this.setState(produce(draft => { draft.resolveErrors = "" }));
                this.setState(produce(draft => { draft.dataToSend.countryId = String(this.state.dataToSend.countryId ) }));
                this.setState(produce(draft => { draft.dataToSend.workspaceId = String(this.state.dataToSend.workspaceId ) }));
                this.setState(produce(draft => { draft.dataToSend.trafficSourceId = String(this.state.dataToSend.trafficSourceId ) }));

                if (this.state.dataToSend.campaign_destination === "Path") {
                    this.setState(produce(draft => { draft.dataToSend.flowId = null }));
                    if (this.state.dataToSend.path_destination === "Offer Only") {
                        this.setState(produce(draft => { draft.dataToSend.simpleLanderId = [] }));
                        this.setState(produce(draft => { draft.dataToSend.landerWeight = [] }));
                        this.setState(produce(draft => { draft.dataToSend.landerValue = [] }));
                    }
                } else {
                    this.setState(produce(draft => { draft.dataToSend.flowId = String(this.state.dataToSend.flowId )}));
                    this.setState(produce(draft => { draft.dataToSend.path_destination = null }));
                    this.setState(produce(draft => { draft.dataToSend.redirect_mode = null }));
                    this.setState(produce(draft => { draft.dataToSend.simpleOfferId = [] }));
                    this.setState(produce(draft => { draft.dataToSend.simpleLanderId = [] }));
                    this.setState(produce(draft => { draft.dataToSend.landerWeight = [] }));
                    this.setState(produce(draft => { draft.dataToSend.offerWeight = [] }));
                    this.setState(produce(draft => { draft.dataToSend.landerValue = [] }));
                    this.setState(produce(draft => { draft.dataToSend.offerValue = [] }));
                }
                setTimeout(() => {
                    if (this.props.state.isDuplicate === false) {
                        return this.props.dispatchPatchCampaign({
                            data: this.state.dataToSend,
                            accessToken: this.props.accessToken,
                            sendEditCampaignId: this.props.campaignId
                        });
                    } else {
                        return this.props.dispatchPostCampaign({
                            data: this.state.dataToSend,
                            accessToken: this.props.accessToken,
                        });
                    }
                },200);
            } else {
                this.props.toggleLoader();
                this.setState(produce(draft => { draft.resolveErrors = "Please make sure to resolve all errors before submitting the data." }));
            }
        }, 200)
                // this.setState(produce(draft => { draft.resolveErrors = "" }));
                // this.sendCampaignData();
                // this.props.closeAddCampaignDialogueAfterSubmission();

    }
    render () {
        return (
            <div id="page_container">
                <Dialog
                    className="add_affiliate_networks_dialogue"
                    title={ (this.props.state.isDuplicate ? "Duplicate " : "Edit ") + this.state.dataToSend.name}
                    onClose={
                        this.state.isCampaignPatchedSuccessfully
                        ?
                            () => this.props.closeEditCampaignDialogueAfterSubmission(this.props.state.isDuplicate ? "true" : "false")
                            :
                            () => this.props.toggleCampaignDialogue("Edit_Toggle_Campaign")
                    }>
                    <div className="add_affiliate_network_dialogue_content">
                        <div className="content_form">
                            <ModalForm
                                state={this.state}
                                simpleInputChangeHandler={this.simpleInputChangeHandler}
                                simpleRadioChangeHandler={this.simpleRadioChangeHandler}
                                countryChangeHandler={this.countryChangeHandler}
                                tSChangeHandler={this.tSChangeHandler}
                                addParameter={this.addParameter}
                                addTokenToUrl={this.addTokenToUrl}
                                tagsInputChangeHandler={this.tagsInputChangeHandler}
                                addLandersAndOffersMethod={this.addLandersAndOffersMethod}
                                selectFlowHandler={this.selectFlowHandler}
                                increaseWeightMethod={this.increaseWeightMethod}
                                decreaseWeightMethod={this.decreaseWeightMethod}
                                weightageSelectTagChangeHandler={this.weightageSelectTagChangeHandler}
                                deleteWeightageMethod={this.deleteWeightageMethod}
                                costValueChangeHandler={this.costValueChangeHandler}
                                costModelSelectChangeHandler={this.costModelSelectChangeHandler}
                                costValueInputChangeHandler={this.costValueInputChangeHandler}
                                changeWeightageHandler={this.changeWeightageHandler}
                            />
                            {/*{this.state.editCampaignDataLoaded && <LoadingPanel/>}*/}
                        </div>
                    </div>
                    <DialogActionsBar>
                        <div className="action_bar_buttons">
                            <div className="left">
                                <button
                                    onClick={
                                        this.state.isCampaignPatchedSuccessfully
                                        ?
                                            () => this.props.closeEditCampaignDialogueAfterSubmission(this.props.state.isDuplicate ? "true" : "false")
                                            :
                                            () => this.props.toggleCampaignDialogue("Edit_Toggle_Campaign")
                                    }
                                    className="normal_btn cancel_button gray_button_bg_imp black border_radius_normal_btn normal_text black no_border">Close</button>
                            </div>
                            <div className="right">
                                {
                                    this.state.isCampaignPatchedSuccessfully
                                    ?
                                        <button onClick={() => this.props.closeEditCampaignDialogueAfterSubmission(this.props.state.isDuplicate ? "true" : "false")} className="normal_btn theme_dark_btn theme_dark_bg_color_imp color_white_imp border_radius_normal_btn save_button normal_text">Close</button>
                                        :
                                        <button onClick={this.submitCampaignData} className="normal_btn theme_dark_btn theme_dark_bg_color_imp color_white_imp border_radius_normal_btn save_button normal_text">Save</button>
                                }
                            </div>
                        </div>
                    </DialogActionsBar>
                </Dialog>
            </div>
        );
    }
}

export const mapStateToProps = createStructuredSelector({
    accessToken: makeSelectToken(),
    loading: makeSelectLoading(),
    success: makeSelectSuccess(),
    error: makeSelectError(),
    domainsAndWorkspacesListsReceived: makeSelectDomainsDataReceived(),
    tagsListReceived: makeSelectTokenTagsListReceived(),
    isTagsListReceived: makeSelectIsTagsListReceived(),
    countriesListReceived: makeSelectCountriesListReceived(),
    newCampaignSuccessResponse: makeSelectNewCampaignSuccessResponse(),
    patchedCampaignSuccessResponse: makeSelectPatchedCampaignSuccessResponse(),
    trafficSourcesDataReceived: makeSelectTrafficSourcesDataLoaded(),
    allLandersDataLoaded: makeSelectAllLandersDataLoaded(),
    allOffersDataLoaded: makeSelectOffersDataLoaded(),
    // allOffersDataLoaded: makeSelectOffersDataLoadedSorted(),
    flowsDataReceived: makeSelectLoadFlowsData(),
    editCampaignDataReceived: makeSelectEditCampaignDataReceived()
});
export function mapDispatchToProps(dispatch) {
    return {
        dispatchLoadDomainsAndWorkspaces: (...payload) => dispatch(loadCustomDomains(...payload)),
        dispatchLoadTrafficSources: (...payload) => dispatch(loadTrafficSources(...payload)),
        dispatchLoadAllLanders: (...payload) => dispatch(loadAllLanders(...payload)),
        dispatchLoadAllOffers: (...payload) => dispatch(loadOffers(...payload)),
        dispatchLoadTags: (...payload) => dispatch(CampaignActions.loadTokensDictionary(...payload)),
        dispatchLoadCountries: (...payload) => dispatch(CampaignActions.loadCountry(...payload)),
        dispatchPostCampaign: (...payload) => dispatch(CampaignActions.postCampaign(...payload)),
        dispatchPatchCampaign: (...payload) => dispatch(CampaignActions.sendEditCampaign(...payload)),
        dispatchLoadEditCampaign: (...payload) => dispatch(CampaignActions.loadEditCampaign(...payload)),
        dispatchLoadFlows: (...payload) => dispatch(newFlowsActions.loadFlows(...payload)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCampaignModal);