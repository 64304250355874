import React, { Component } from 'react';
// import React, { useState, useEffect, Fragment } from "react";
import "../../../index.css";
import "../CollaborationTools/Workspace/workspace.css";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import WorkspaceModal from "../CollaborationTools/Workspace/workspacemodal";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router";
import * as colloborationWorkspaceActions from "./actions";
import { makeSelectWorkspaceAdded, makeSelectWorkspaceID, makeSelectWorkspaceName, makeSelectWorkspaceError, makeSelectWorkspaceSuccess, makeSelectWorkspaceLoading, makeSelectWorkspaceData, makeSelectWorkspaceDelete, makeSelectWorkspaceEdit } from "./selectors";
//import axios from 'axios'
import Layout from "../../../layout";
//import DeletePageModal from './DeleteWorkspacePageModal/index';
import LoadingPanel from "../../grid/loader/loader";
import { makeSelectToken } from '../../../registration/LoginRegister/selectors';
import produce from 'immer';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { orderBy } from '@progress/kendo-data-query';
class Workspace extends Component {

  state = {
    loader: false,
    selected: 0,
    backendErrors: {
      error: false,
      errorMsg: ""
    },
    skip: 0,
    take: 10,
    sort: [
      { field: 'name', dir: 'asc' }
    ]
  }

  componentDidMount() {
    if (this.props.workspaceData === null) {
      this.toggleLoader();
      this.props.fetchingData(this.props.accessToken);
      setTimeout(() => {
        this.toggleLoader();
      }, 1000);
    }
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.workspaceData !== this.props.workspaceData) && this.props.workspaceData === null) {
      //this.toggleLoader();
      this.props.fetchingData(this.props.accessToken);
    }

    if ((prevProps.workspaceError !== this.props.workspaceError) && this.props.workspaceError !== false) {
      this.toggleLoader();
      this.setState(produce(draft => { draft.backendErrors.error = true }));
    }

    if ((prevProps.workspaceDeleted !== this.props.workspaceDeleted) && this.props.workspaceDeleted && !this.props.error) {
      this.props.fetchingData(this.props.accessToken);
      this.toggleLoader();
      this.setState(produce(draft => { draft.backendErrors.error = false }));
    }
    if ((prevProps.workspaceAdded !== this.props.workspaceAdded) && this.props.workspaceAdded && !this.props.error) {
      this.toggleLoader();
      this.props.fetchingData(this.props.accessToken);
      this.setState(produce(draft => { draft.backendErrors.error = false }));
    }
    if ((prevProps.workspaceEdit !== this.props.workspaceEdit) && this.props.workspaceEdit && !this.props.error) {
      this.toggleLoader();
      this.props.fetchingData(this.props.accessToken);
      this.setState(produce(draft => { draft.backendErrors.error = false }));
    }
  }


  handleSelect = (e) => {
    //setSelected(e.selected);
    this.setState(produce(draft => { draft.selected = e.selected }));
  };
  toggleLoader = () => {
    this.setState(produce(draft => { draft.loader = !this.state.loader }));
  }

  pageChange = (event) => {
    this.setState({
      skip: event.page.skip,
      take: event.page.take
    });
  }
  render() {
    return (
      <>
        <Layout>
          {(this.state.loader) && <LoadingPanel></LoadingPanel>}
          {(this.state.backendErrors.error) &&
            <NotificationGroup
              style={{
                alignItems: 'flex-start',
                flexWrap: 'wrap-reverse',
                left: "50%",
                zIndex: 10000000,
                top: "20px",
                transform: "translateX(-50%)",
              }}
            >
              <Fade enter={true} exit={true}>
                {(this.props.workspaceError !== false) && <Notification
                  type={{ style: 'error', icon: true }}
                  closable={true}
                  onClose={() => this.setState(produce(draft => { draft.backendErrors.error = false; }))}
                >
                  <span>{this.props.workspaceError.message + ", " + this.props.workspaceError.error}</span>
                </Notification>}
              </Fade>
            </NotificationGroup>
          }
          {this.props.workspaceData ?
            <div className="settings_area_container">
              <div>
                <div className="header" style={{ backgroundColor: "transparent" }}>
                  <div className="text_left header_title">
                    <h1 className="h1_collaborations ">Collaboration tools</h1>
                  </div>
                  <div className="feedback">{/* give feedback with svg icon*/}</div>
                </div>

                <div className="Workspaces">
                  <div className="tabstrip workspace_tab">
                    <TabStrip selected={this.state.selected} onSelect={this.handleSelect}>
                      <TabStripTab title={"WORKSPACE (" + this.props.workspaceData.length + ")"}>
                        <div className="private_workspace">
                          <div>
                            <b className="text_left color_black">
                              AVAILABLE PRIVATE WORKSPACES: {50 - this.props.workspaceData.length} out of 50
                          </b>
                          </div>
                          <div className="workspace_btn">

                            <WorkspaceModal
                              icon="k-icon k-i-plus-circle"
                              classNameForButton={"add_workspace_btn"}
                              buttonText="Add private workspace"
                              title={"Add private workspace"}
                              token={this.props.accessToken}
                              toggleLoader={this.toggleLoader}
                              id={null}
                            ></WorkspaceModal>
                          </div>
                        </div>
                        <div className="available_worspace_info">
                          <span className="k-icon k-i-information"></span>
                          <p className="information_text">
                            By default, there is always one Public Workspace that can be
                            managed by an Account Owner and all Admins. If you want to
                            keep campaigns and its elements separated, add Private
                            Workspaces.
                        </p>
                        </div>
                        <div className="grid_data">
                          <Grid
                            style={{ height: "100%" }}
                            data={orderBy(this.props.workspaceData.slice(this.state.skip, this.state.take + this.state.skip), this.state.sort)}
                            skip={this.state.skip}
                            take={this.state.take}
                            total={this.props.workspaceData.length}
                            pageable={true}
                            onPageChange={this.pageChange}
                            sortable={true}
                            sort={this.state.sort}
                            onSortChange={(e) => {
                              this.setState({
                                sort: e.sort
                              });
                            }}
                          >
                            <GridColumn
                              field="name"
                              title="Private workspace name"
                              // width="300px"
                              // minResizableWidth="30"  
                              icon="k-icon k-i-edit"
                            />
                            <span className="k-icon k-i-edit">icon</span>
                            <GridColumn
                              field="id"
                              title="Workspace id"
                            // width="290px"
                            />
                            {/* <GridColumn
                              field="AssignedWorkers"
                              // width="310px"
                              title="Assigned workers"
                            />
                            <GridColumn
                              field="AssignedDomains"
                              title="Assigned domains"
                            // width="310px"
                            /> */}
                            <GridColumn
                              title="Actions"
                              field="name"
                              width="100px"
                              cell={(props) => (
                                <><td className="row">
                                  {(props.dataItem["name"] !== "Public Workspace") &&
                                    <WorkspaceModal
                                      icon="k-icon k-i-edit"
                                      classNameForButton="edit_workspace_btn"
                                      buttonText=""
                                      title={"Edit private workspace"}
                                      item={props.dataItem[props.field]}
                                      id={props.dataItem["id"]}
                                      token={this.props.accessToken}
                                      action={"Edit"}
                                      toggleLoader={this.toggleLoader}
                                    ></WorkspaceModal>
                                  }
                                  {(props.dataItem["name"] !== "Default Workspace" && props.dataItem["name"] !== "Public Workspace") &&
                                    <WorkspaceModal
                                      icon="k-icon k-i-delete"
                                      classNameForButton="edit_workspace_btn"
                                      buttonText=""
                                      title={"Delete private workspace"}
                                      item={props.dataItem[props.field]}
                                      id={props.dataItem["id"]}
                                      token={this.props.accessToken}
                                      action={"Delete"}
                                      toggleLoader={this.toggleLoader}
                                    ></WorkspaceModal>}
                                </td>
                                </>
                              )}
                            />
                          </Grid>
                        </div>
                      </TabStripTab>
                    </TabStrip>
                  </div>
                </div>
              </div>
            </div>
            : <LoadingPanel></LoadingPanel>}
        </Layout>
      </>

    );
  }
};

export const mapStateToProps = createStructuredSelector({
  workspaceName: makeSelectWorkspaceName(),
  workspaceID: makeSelectWorkspaceID(),
  workspaceLoading: makeSelectWorkspaceLoading(),
  workspaceError: makeSelectWorkspaceError(),
  workspaceSuccess: makeSelectWorkspaceSuccess(),
  workspaceEdit: makeSelectWorkspaceEdit(),
  workspaceAdded: makeSelectWorkspaceAdded(),
  workspaceDeleted: makeSelectWorkspaceDelete(),
  workspaceData: makeSelectWorkspaceData(),
  accessToken: makeSelectToken()
})

export function mapDispatchToProps(dispatch) {
  return {
    //addPrivateWorkspace: (...payload) => dispatch(colloborationWorkspaceActions.addPrivateWorkspace(...payload)),
    fetchingData: (...payload) => dispatch(colloborationWorkspaceActions.fetchingData(...payload))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Workspace));