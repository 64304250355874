import * as requestHandler from "../../helpers/requestHandlers";
import {
    PATH_DASHBOARD_OVERVIEW_DATA,
    PATH_DASHBOARD_OVERVIEW_SUMMARY_DATA,
    PATH_DASHBOARD_OVERVIEW_CHART,
} from "../../helpers/path"; 
import { returnTimeZoneId } from "../../../components/timeZonesExchange";

export const loadDashboardDataApi = async (request) => {
    let timeZoneInLocalStorage = request.payload.timeZone;
    let timeZoneIdToSend = returnTimeZoneId(timeZoneInLocalStorage);

    let parameters = 'to=' + request.payload.modifiedDate.endDate + '&from=' + request.payload.modifiedDate.startDate + "&workspace=" + request.payload.workspaces + "&timezone=" + timeZoneIdToSend;
    
    const response = await requestHandler.get(PATH_DASHBOARD_OVERVIEW_DATA + parameters);
    return response;
};


export const loadDashboardSummaryApi = async (request) => {
    const sort = request.payload.dashboardSummary.sort;
    const order = request.payload.dashboardSummary.data;

    let timeZoneInLocalStorage = request.payload.timeZone;
    let timeZoneIdToSend = returnTimeZoneId(timeZoneInLocalStorage);

    let parameters = `to=${request.payload.modifiedDate.endDate}&from=${request.payload.modifiedDate.startDate}&sort=${sort}&order=${order}&workspace=${request.payload.workspaces}&timezone=${timeZoneIdToSend}`;

    const response = await requestHandler.get(PATH_DASHBOARD_OVERVIEW_SUMMARY_DATA + parameters);
    return response;
};

export const loadDashboardChartsDataApi = async (request) => {    
    let timeZoneInLocalStorage = request.payload.timeZone;
    let timeZoneIdToSend = returnTimeZoneId(timeZoneInLocalStorage);

    let parameters = 'to=' + request.payload.modifiedDate.endDate + '&from=' + request.payload.modifiedDate.startDate + "&workspace=" + request.payload.workspaces + "&timezone=" + timeZoneIdToSend;
    const response = await requestHandler.get(PATH_DASHBOARD_OVERVIEW_CHART + parameters);
    return response;
};