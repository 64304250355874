import React from "react";
import {Redirect, Route } from "react-router-dom";
import auth from "../auth";                   



const UnProtectedRoutes = ({component: Component, ...rest}) => {
    //console.log("...rest in unprotected routes");
    //console.log(rest);
    return (
        <Route
            {...rest}
            render = {
                (props) => {
                    // console.log("auth isAuthenticated: ", auth.isAuthenticated());
                    if(auth.isAuthenticated()) {

                        return <Redirect to={
                            {
                                pathname: "/dashboard",
                                state: {
                                    from: props.location
                                }
                            }
                        } />;
                    }
                    else {
                        return <Component {...props} {...rest} />;
                    }
                }
            }
        />
    );
};

export default UnProtectedRoutes;