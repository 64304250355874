import React from  "react";
import {Form, FormElement} from "@progress/kendo-react-form";
import {Fade} from "@progress/kendo-react-animation";
import {Calendar} from "@progress/kendo-react-dateinputs";
import {RadioGroup} from "@progress/kendo-react-inputs";
import {CountryDropdown} from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
// import {Button} from "@progress/kendo-react-buttons";
// import {Link} from "react-router-dom";

class ModalForm extends React.Component {
    render () {
        // console.log(this.props.state);
        return (
            <div>
                <div>
                        <FormElement className="register_form_element">
                            <fieldset className={"k-form-fieldset"}>
                                {/*<h2 className="login_h2">Register</h2>*/}
                                <div className="signup_grid">
                                    <div className="signup-row">
                                        <div className="mb-2 col">
                                            <div className="input_group">
                                                <label htmlFor="firstname">First Name *</label>
                                                <input
                                                    type="text"
                                                    name="firstname"
                                                    id="firstname"
                                                    value={this.props.state.dataToSend.firstname}
                                                    onChange={this.props.simpleInputChangeHandler}
                                                />
                                            </div>
                                            {
                                                this.props.state.error.firstname !== null ?
                                                    <Fade enter={true} exit={true}>
                                                        <p className="registration_failure_para">{this.props.state.error.firstname}</p>
                                                    </Fade>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                    <div className="signup-row">
                                        <div className="mb-2 col">
                                            <div className="input_group">
                                                <label htmlFor="lastname">Last Name *</label>
                                                <input
                                                    type="text"
                                                    name="lastname"
                                                    id="lastname"
                                                    value={this.props.state.dataToSend.lastname}
                                                    onChange={this.props.simpleInputChangeHandler}
                                                />
                                            </div>
                                            {
                                                this.props.state.error.lastname !== null ?
                                                    <Fade enter={true} exit={true}>
                                                        <p className="registration_failure_para">{this.props.state.error.lastname}</p>
                                                    </Fade>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                    <div className="email">
                                        <div className="input_group">
                                            <label htmlFor="email">E-mail *</label>
                                            <input
                                                type="email"
                                                name="email"
                                                id="email"
                                                value={this.props.state.dataToSend.email}
                                                onChange={this.props.simpleInputChangeHandler}
                                            />
                                        </div>
                                        {
                                            this.props.state.error.email !== null ?
                                                <Fade enter={true} exit={true}>
                                                    <p className="registration_failure_para">{this.props.state.error.email}</p>
                                                </Fade>
                                                : null
                                        }
                                    </div>
                                    <div>
                                        <div className="input_group">
                                            <label htmlFor="password">Password *</label>
                                            <input
                                                type="password"
                                                name="password"
                                                id="password"
                                                value={this.props.state.dataToSend.password}
                                                onChange={this.props.simpleInputChangeHandler}
                                            />
                                        </div>
                                        {
                                            this.props.state.error.password !== null ?
                                                <Fade enter={true} exit={true}>
                                                    <p className="registration_failure_para">{this.props.state.error.password}</p>
                                                </Fade>
                                                : null
                                        }
                                    </div>
                                    <div>
                                        <div className="input_group">
                                            <label htmlFor="confirmPassword">Confirm Password *</label>
                                            <input
                                                type="password"
                                                name="confirmPassword"
                                                id="confirmPassword"
                                                value={this.props.state.dataToSend.confirmPassword}
                                                onChange={this.props.simpleInputChangeHandler}
                                            />
                                        </div>
                                        {
                                            this.props.state.error.confirmPassword !== null ?
                                                <Fade enter={true} exit={true}>
                                                    <p className="registration_failure_para">{this.props.state.error.confirmPassword}</p>
                                                </Fade>
                                                : null
                                        }
                                    </div>
                                </div>
                                {/* <div className="signup_grid" style={{ marginTop: "20px"}}>
                                    <div className="sign_up_birthday_con">
                                        <div className="inner">
                                            <label className="" htmlFor="birthday">Birthday *</label>
                                            <div
                                                className="birthday_show"
                                                onClick={this.props.toggleCalendar}
                                            >{this.props.state.dataToSend.showBirthday}</div>
                                        </div>
                                        {
                                            this.props.state.showCalendar ?
                                                <div className="birthday_calendar">
                                                    <Calendar
                                                        disabled={false}
                                                        value={this.props.state.birthday}
                                                        onChange={this.props.handleBirthdayChange}
                                                    />
                                                </div>
                                                : null
                                        }
                                        {
                                            this.props.state.error.birthday !== null ?
                                                <Fade enter={true} exit={true}>
                                                    <p className="registration_failure_para">{this.props.state.error.birthday}</p>
                                                </Fade>
                                                : null
                                        }
                                    </div>
                                </div> */}
                                <div className="acc_type_row">
                                    <label className="" htmlFor="acc_type">Select Account Type *</label>
                                    <RadioGroup
                                        data={this.props.state.acc_Type}
                                        defaultValue={this.props.state.acc_Type[0].value}
                                        valid={this.props.radioGroupValidator}
                                        onChange={this.props.radioChangeHandler}
                                    />
                                </div>
                                <div className="signup_grid">
                                    <div className="styled-input acc_type_row">
                                        <div className="input_group" style={{ paddingTop: "0px" }}>
                                            <label htmlFor="linkedin or facebook">Linkedin or Facebook Url {this.props.state.dataToSend.accType === "Company" ? "*" : null}</label>
                                            <input
                                                type="text"
                                                name="link"
                                                id="link"
                                                value={this.props.state.dataToSend.link}
                                                onChange={this.props.simpleInputChangeHandler}
                                            />
                                        </div>
                                        {
                                            this.props.state.error.link !== null ?
                                                <Fade enter={true} exit={true}>
                                                    <p className="registration_failure_para">{this.props.state.error.link}
                                                    </p>
                                                </Fade>
                                                : null
                                        }
                                    </div>
                                    {/* <div className="acc_type_row">
                                        <div className="input_group">
                                            <label htmlFor="facebook">Facebook Url {this.props.state.accType === "Company" ? "*" : null}</label>
                                            <input
                                                type="text"
                                                name="facebook"
                                                id="facebook"
                                                value={this.props.state.dataToSend.facebook}
                                                onChange={this.props.simpleInputChangeHandler}
                                            />
                                        </div>
                                        {
                                            this.props.state.error.facebook !== null ?
                                                <Fade enter={true} exit={true}>
                                                    <p className="registration_failure_para">{this.props.state.error.facebook}</p>
                                                </Fade>
                                                : null
                                        }
                                    </div> */}
                                    <div className="acc_type_row">
                                        <div className="input_group" style={{ paddingTop: "0px" }}>
                                            <label htmlFor="website">Website Link {this.props.state.dataToSend.accType === "Company" ? "*" : null}</label>
                                            <input
                                                type="text"
                                                name="website"
                                                id="website"
                                                value={this.props.state.dataToSend.website}
                                                onChange={this.props.simpleInputChangeHandler}
                                            />
                                        </div>
                                        {
                                            this.props.state.error.website !== null ?
                                                <Fade enter={true} exit={true}>
                                                    <p className="registration_failure_para">{this.props.state.error.website}</p>
                                                </Fade>
                                                : null
                                        }
                                    </div>
                                    {this.props.state.dataToSend.accType === "Company" && 
                                        <div className="acc_type_row">
                                            <div className="input_group" style={{ paddingTop: "0px" }}>
                                                <label htmlFor="company">Company Name *</label>
                                                <input
                                                    type="text"
                                                    name="company"
                                                    id="company"
                                                    value={this.props.state.dataToSend.company}
                                                    onChange={this.props.simpleInputChangeHandler}
                                                />
                                            </div>
                                            {
                                                this.props.state.error.company !== null ?
                                                    <Fade enter={true} exit={true}>
                                                        <p className="registration_failure_para">{this.props.state.error.company}</p>
                                                    </Fade>
                                                    : null
                                            }
                                        </div>
                                    }
                                    <div className="acc_type_row">
                                        <div className="input_group" style={{ paddingTop: "0px" }}>
                                            <label htmlFor="website">Instant messenger *</label>
                                            <div className="instant-messenger">
                                                <select className="instant-messenger-select" name="instant_messenger" id="instant_messenger" onChange={this.props.simpleInputChangeHandler} value={this.props.state.dataToSend.instant_messenger}>
                                                    <option value="Skype">Skype</option>
                                                    <option value="Telegram">Telegram</option>
                                                    <option value="Whatsapp">Whatsapp</option>
                                                </select>
                                                <input
                                                    type="text"
                                                    name="instant_messenger_number"
                                                    id="instant_messenger_number"
                                                    value={this.props.state.dataToSend.instant_messenger_number}
                                                    onChange={this.props.simpleInputChangeHandler}
                                                />
                                            </div>
                                        </div>
                                        {
                                            this.props.state.error.instant_messenger_number !== null ?
                                                <Fade enter={true} exit={true}>
                                                    <p className="registration_failure_para">{this.props.state.error.instant_messenger_number}</p>
                                                </Fade>
                                                : null
                                        }
                                    </div>
                                </div>
                                <div className="acc_type_row">
                                    {/* <label className="signup_label" htmlFor="address">Address</label> */}
                                    <div className="address_con">
                                        <div className="input_group">
                                            <label htmlFor="address">Street Address *</label>
                                            <input
                                                type="text"
                                                name="address"
                                                id="address"
                                                value={this.props.state.dataToSend.address}
                                                onChange={this.props.simpleInputChangeHandler}
                                            />
                                        </div>
                                        <div className="input_group">
                                            <label htmlFor="city">City *</label>
                                            <input
                                                type="text"
                                                name="city"
                                                id="city"
                                                value={this.props.state.dataToSend.city}
                                                onChange={this.props.simpleInputChangeHandler}
                                            />
                                        </div>
                                        <div className="input_group">
                                            <label htmlFor="state">Region / State / Province *</label>
                                            <input
                                                type="text"
                                                name="state"
                                                id="state"
                                                value={this.props.state.dataToSend.state}
                                                onChange={this.props.simpleInputChangeHandler}
                                            />
                                        </div>
                                        <div className="input_group">
                                            <label htmlFor="signupCountry">Select Country *</label>
                                            <CountryDropdown
                                                className="country_dropdown"
                                                id="signupCountry"
                                                name="signupCountry"
                                                value={this.props.state.dataToSend.signupCountry}
                                                onChange={this.props.selectCountry}
                                            />
                                        </div>
                                        <div className="input_group">
                                            <label htmlFor="zipCode">Zip Code / Postal Code *</label>
                                            <input
                                                type="text"
                                                name="zipCode"
                                                id="zipCode"
                                                value={this.props.state.dataToSend.zipCode}
                                                onChange={this.props.simpleInputChangeHandler}
                                            />
                                        </div>
                                    </div>
                                    {
                                        this.props.state.error.address !== null ?
                                            <Fade enter={true} exit={true}>
                                                <p className="registration_failure_para">{this.props.state.error.address}</p>
                                            </Fade>
                                            : null
                                    }
                                </div>
                                <div className="acc_type_row">
                                    <div className="address_con">
                                        <div className="input_group_phone">
                                            <label htmlFor="phone">Phone Number *</label>
                                            <PhoneInput
                                                country={'us'}
                                                value={this.props.state.phone}
                                                onChange={this.props.phoneChangeHandler}
                                            />
                                        </div>
                                    </div>
                                    {
                                        this.props.state.error.phone !== null ?
                                            <Fade enter={true} exit={true}>
                                                <p className="registration_failure_para">{this.props.state.error.phone}</p>
                                            </Fade>
                                            : null
                                    }
                                </div>
                            </fieldset>
                            <div className="k-mt-5">
                                {/*<Button*/}
                                {/*    className="half_width register-btn"*/}
                                {/*    type={"submit"}*/}
                                {/*    // disabled={!formRenderProps.allowSubmit}*/}
                                {/*    onClick={this.handleSubmit}*/}
                                {/*>*/}
                                {/*    Register*/}
                                {/*</Button>*/}
                                {
                                    this.props.isRegistered ?
                                        <Fade enter={true} exit={true}>
                                            <p className="registration_success_para">Thank you for registering - We will get back to you shortly on your account status!</p>
                                        </Fade>
                                        :
                                        this.props.isRegistered == false ?
                                            <Fade enter={true} exit={true}>
                                                <p className="registration_failure_para">This e-mail already exists.
                                                    Please login or signup with a different e-mail address.</p>
                                            </Fade>
                                            : null
                                }
                            </div>
                        </FormElement>
                </div>
            </div>
        );
    }
}

export default ModalForm;