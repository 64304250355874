
import React, { Component } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import ModalForm from './ModalForm/index';
import produce from 'immer';
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { makeSelectToken } from '../../../../registration/LoginRegister/selectors';
import * as ipuaFilteringActions from "../actions";
import { makeSelectIsFilterDataError, makeSelectIsFilterDataLoaded } from '../selectors';

class IPUAModal extends Component {
    state = { 
        dataToSend: {
            name: "",
            ip_address: "",
            user_agent: "",
        },
        visible: this.props.state.visibleFilteringDialog,
        errors: {
            name: null,
            ip_address: null,
            user_agent: null
        },
        ipaddress: [],
        loader:false,
    }

    componentDidUpdate(prevProps) {
        //console.log('this state value', this.state);
        if ((prevProps.successAdded !== this.props.successAdded) && this.props.successAdded && !this.props.error) {
            this.toggleDialog();
        }
    }

    ValidateIPaddress = (ipaddress)=> {
           var ipformat = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
            if(ipaddress.match(ipformat)){
                return true;
            }
            else
            {
                return false;
            }
    }

    inputChangeHandler = (e) => {
        // console.log('e.target.name', e.target.name);
        if (e.target.name === "name") {
            this.setState((produce(draft => { draft.dataToSend[e.target.name] = e.value })))
        }
        else {
            this.setState((produce(draft => { draft.dataToSend[e.target.name] = e.target.value })))
        }
        if (e.target.name === "ip_address") {
            this.ValidateIPaddress(e.target.value);
        }
        // console.log('value',e.target.value);
    }
    
    toggleDialog = () => {
        this.setState((produce(draft => { draft.visible = !this.state.visible })))
        this.props.openFilteringDialog();
    }

    checkErrors = () => {
        //name error
        if (this.state.dataToSend.name.length === 0) {
            this.setState(produce(draft => { draft.errors.name = "You must add name." }));
        }
        else {
            this.setState(produce(draft => { draft.errors.name = null }));
        }
        //ip address error
        if (this.state.dataToSend.ip_address.length === 0) {
            //console.log('nullllllllll');
            this.setState(produce(draft => { draft.errors.ip_address = "You must add atleast one ip address." }));
        }
        else {
            this.setState(produce(draft => { draft.errors.ip_address = null }));
        }
        //user agent error
        if (this.state.dataToSend.user_agent.length === 0) {
            this.setState(produce(draft => { draft.errors.user_agent = "You must add atleast one user agent." }));
        }
        else {
            this.setState(produce(draft => { draft.errors.user_agent = null }));
        }
        //user and ip address agent
        if (this.state.dataToSend.user_agent.length >= 5 && this.state.dataToSend.ip_address.length === 0) {
            //console.log('null2');
            this.setState(produce(draft => { draft.errors.user_agent = null }));
            this.setState(produce(draft => { draft.errors.ip_address = null }));
        } else if (this.state.dataToSend.user_agent.length > 0 && this.state.dataToSend.user_agent.length < 5 && this.state.dataToSend.ip_address.length === 0) {
            //console.log('null3');
            this.setState(produce(draft => { draft.errors.user_agent = "You must add atleast one user agent." }));
            this.setState(produce(draft => { draft.errors.ip_address = null }));
        }
        else {
            // this.setState(produce(draft => { draft.errors.user_agent = null }));
            // this.setState(produce(draft => { draft.errors.ip_address = null }));
        }
        if (this.state.dataToSend.ip_address.length > 0 && this.state.dataToSend.user_agent.length === 0) {
            this.setState(produce(draft => { draft.errors.user_agent = null }));
            if(this.ValidateIPaddress(this.state.dataToSend.ip_address)===false){
                this.setState(produce(draft => { draft.errors.ip_address = "You must add atleast one ip address with valid ip address." }));
            }
            else {
                this.setState(produce(draft => { draft.errors.ip_address = null }));
            }
        }
        this.checkIpAddressValidation();
    }

    checkIpAddressValidation = () => {
        let ip = this.state.dataToSend.ip_address.split(',');
        //console.log('ip', ip);
        if (this.state.dataToSend.ip_address.length > 0) {
            for (let i = 0; i < ip.length; i++){
                if(this.ValidateIPaddress(ip[i])===false){
                    this.setState(produce(draft => { draft.errors.ip_address = "You must add atleast one ip address with valid ip address." }));
                    break;
                }
                else {
                    this.setState(produce(draft => { draft.errors.ip_address = null }));
                }   
            }
        }
    }

    submitData = () => {
        this.checkErrors();
        //console.log('this state value', this.state);
        setTimeout(() => {
            if (this.state.errors.name === null && this.state.errors.ip_address === null && this.state.errors.user_agent === null) {
                this.props.submitData(this.state.dataToSend);
            }
        }, 300);
        //console.log('resolve errors');
    }
    render() { 
        return (
            <div>
                {this.state.visible && (
                    <Dialog className="add_affiliate_networks_dialogue" title={"New filtering rule"} onClose={this.toggleDialog}>
                    <div className="add_affiliate_network_dialogue_content">
                        <ModalForm 
                                state={this.state}
                                inputChangeHandler={(e)=>this.inputChangeHandler(e)}
                        />
                        </div>
                        <DialogActionsBar>
                                <div className="action_bar_buttons">
                                    <div className="left">
                                        <button onClick={this.toggleDialog} className="normal_btn cancel_button gray_button_bg_imp black border_radius_normal_btn normal_text black no_border">Cancel</button>
                                     </div>        
                                    <div className="right">
                                        <button onClick={this.submitData} className="normal_btn theme_dark_btn theme_dark_bg_color_imp color_white_imp border_radius_normal_btn save_button normal_text">Save</button>
                                </div>
                            </div>
                        </DialogActionsBar>
                        
                    </Dialog>
                )}
            </div>
        );
    }
}
export const mapStateToProps = createStructuredSelector({
    // notificationsDataReceived: makeSelectNotficationsData(),
    accessToken: makeSelectToken(),
    error: makeSelectIsFilterDataError(),
    successAdded: makeSelectIsFilterDataLoaded(),

});

export function mapDispatchToProps(dispatch) {
    return {
        addFilter: (...payload)=> dispatch(ipuaFilteringActions.addIPUA(...payload)),
        // updateNotifications: (...payload)=>dispatch(notificationActions.updateNotifications(...payload)),
        // loadNotifications: (...payload)=>dispatch(notificationActions.loadNotifications(...payload))       
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(IPUAModal);
 