import React from "react";
import {Dialog} from "@progress/kendo-react-dialogs";
import "./popups.css";

const EditCustomDomainDialogue = (props) => {
    return (
        props.props.state.editCustomDomainDialogueVisible && <Dialog title={"Edit Custom Domain"} className="settings_dialogue" onClose={props.props.closeEditCustomDomainDialog}>
            <div className="settings_add_custom_domains_form">
                <div className="row">
                    <label className="block normal_text black medium_font full_width_label">Domain</label>
                    <input className="block add_custom_domain_input" name={'editCustomDomainInput'} onChange={props.props.handleEditCustomDomainInputChange} value={props.props.state.domainUnderEditing.name} />
                    {
                        props.props.addCustomDomainInputError ?
                            <p className="domain_error">{props.props.editCustomDomainInputErrorValue}</p>
                            : null
                    }
                </div>
                <div className="row">
                    <label className="block normal_text black medium_font full_width_label">Select Workspace</label>
                    <select className="add_custom_domain_dropdown normal_text normal_font" name="workspace" value={props.props.state.domainUnderEditing.workspaceId} onChange={props.props.handleCustomDomainEditDropChange}>
                        {
                            props.props.state.domainsDataReceived.workspace.map((workspace) => (
                                <option key={workspace.id} value={workspace.id}>{workspace.name}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="buttons_row">
                    <button className="cancel_button gray_button_bg_imp black border_radius_normal_btn profile_form_btn normal_text black no_border" onClick={props.props.closeEditCustomDomainDialog}>Cancel</button>
                    <button
                        className="theme_dark_bg_color_imp color_white_imp border_radius_normal_btn save_button normal_text"
                        onClick={props.props.handleEditCustomDomainFormSubmit}
                    >Save</button>
                </div>
            </div>
        </Dialog>
    );
};

export default EditCustomDomainDialogue;