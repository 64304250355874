import React, { useEffect } from "react";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
//import CondtionModal from './../conditionModal/index';
import CondtionModal from "./../../../../conditionModal/index";
import EditCondtionModal from "./../../../../EditConditionModal/index";
import { Error } from '@progress/kendo-react-labels';

const RuleBased = (props) => {
    const [visible, setVisible] = React.useState(false);
    const [editVisible, setEditVisible] = React.useState(false);

    const onVisibleClick = (e) => {
        //console.log('event ', e);
        if (visible === false) {
            setVisible(true);
        } else {
            //console.log('else');
            setVisible(false);
        }
    };

    const onEditVisibleClick = () => {
        if (editVisible === false) {
            setEditVisible(true);
        }
        else {
            setEditVisible(false);            
        }
    }

    const onRuleNameChange = (e) => {
        props.onRuleNameChange(e);
    };

    const deleteRule = (event) => {
        props.deleteOneCondition(event.target.id);
    }

    const editRule = (event) => {
        onEditVisibleClick();
        props.editRuleCondition(event.target.id);
    }

    // console.log('props.state.newRuleIndex',props.state.newRuleIndex);
    // console.log('props.state.dataToSend.path.rule[props.state.newRuleIndex]',Object.keys(props.state.dataToSend.path.rule[props.state.newRuleIndex].conditions).length);
    return (
        <div className="new-right-flow ">
            <div className="path">
                <label className="flow-name-label">Rule name</label>
                <Input
                    name="name"
                    placeholder={
                        props.state.dataToSend.path.rule[props.state.newRuleIndex].name.length===0?
                        Object.keys(props.state.dataToSend.path.rule[props.state.newRuleIndex].conditions).length>0?
                        props.state.ruleConditionName[props.state.newRuleIndex]: 
                        "New rule":
                        props.state.dataToSend.path.rule[props.state.newRuleIndex].name
                    }
                    style={{ width: "100%" }}
                    required
                    value={props.state.dataToSend.path.rule[props.state.newRuleIndex].name}
                    onChange={onRuleNameChange}
                    ariaDescribedBy={"rule_name"}
                />
                {
                    props.state.errors.rule_name !== "" && props.state.dataToSend.path.rule[props.state.newRuleIndex].name==="" &&
                    Object.keys(props.state.dataToSend.path.rule[props.state.newRuleIndex].conditions).length===0 &&
                    <Error id={"rule_name"}>{props.state.errors.rule_name}</Error>
                }
                <hr></hr>
                <div className="row new-rule-based">
                    <h2 className="rule-based-condition-heading ">CONDITIONS</h2>
                    <div className="push">
                        {props.state.dataToSend.path.rule.length > 0 &&
                        Object.keys(
                            props.state.dataToSend.path.rule[props.state.newRuleIndex].conditions
                        ).length !== 0 ? (
                            <Button className="transparent-btn" icon={"k-icon k-i-delete"} onClick={props.deleteAllConditions}>
                                Delete all
                            </Button>
                        ) : null}
                        <Button
                            className="transparent-btn"
                            icon={"k-icon k-i-plus-circle"}
                            onClick={onVisibleClick}
                        >
                            Add condtion
                        </Button>
                    </div>
                    {visible ? (
                        <CondtionModal
                            visible={visible}
                            onVisibleClick={(e) => onVisibleClick(e)}
                            addConditions={props.addConditions}
                            showOptions={props.showOptions}
                            state={props.state}
                        />
                    ) : null}
                    {editVisible ?
                        <EditCondtionModal
                            state={props.state}
                            visible={editVisible}
                            onEditVisibleClick={(e) => onEditVisibleClick(e)}
                            addConditions={props.addConditions}
                        />
                    :null
                    }
                </div>

                <div>
                    {props.state.dataToSend.path.rule.length > 0 &&
                    Object.keys(
                        props.state.dataToSend.path.rule[props.state.newRuleIndex].conditions
                    ).length === 0 ? (
                        <div className="conditions">
                            <div className="conditions-data">
                                <div className="header">No conditions created</div>
                                <span>Start adding conditions to define your rule</span>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {props.state.sorted &&  props.state.conditionsName[props.state.newRuleIndex].length > 0 && Object.keys(props.state.dataToSend.path.rule[props.state.newRuleIndex].conditions).length===props.state.conditionsName[props.state.newRuleIndex].length
                                ? props.state.conditionsName[props.state.newRuleIndex].map((name) => (
                                      <div className="conditions-data-show condition-active row">
                                          <>
                                            {/* <div className="condition-name">{name}</div> */}
                                            {props.showCondtionsName(name)}
                                              <span className="condition-value">
                                                  {props.showConditionValues(name)}
                                              </span>
                                              <div className="condition-actions push">
                                                  <span className="k-icon k-i-edit blue-icon" id={name} onClick={editRule}/>
                                                <span className="k-icon k-i-delete blue-icon" id={name} onClick={deleteRule}/>
                                              </div>
                                          </>
                                      </div>
                                  ))
                                : null}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RuleBased;
