import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectEventLog = (state) => state.eventlog || initialState;

const makeSelectEventLogData = () => createSelector(selectEventLog, (substate) => substate.eventlogData);

const makeSelectEventLogDataLoaded = () => createSelector(selectEventLog, (substate) => substate.isEventLogDataLoaded);

const makeSelectEventLogDetailsData = () => createSelector(selectEventLog, (substate) => substate.eventlogDetailData);

const makeSelectEventLogDetailsDataLoaded = () => createSelector(selectEventLog, (substate) => substate.isEventLogDetailDataLoaded);

const makeSelectEventLogDataLoading = () => createSelector(selectEventLog, (substate) => substate.loading);

const makeSelectEventLogDataSuccess = () => createSelector(selectEventLog, (substate) => substate.success);

const makeSelectEventLogDataError = () => createSelector(selectEventLog, (substate) => substate.error);


export {
    makeSelectEventLogData,
    makeSelectEventLogDataLoaded,
    makeSelectEventLogDetailsData,
    makeSelectEventLogDetailsDataLoaded,
    makeSelectEventLogDataLoading,
    makeSelectEventLogDataSuccess,
    makeSelectEventLogDataError
}
