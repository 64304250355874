export const BASE_URL = "https://tell.mbitrk.com/";
// export const BASE_URL = "http://localhost:3000/";

// admin
export const PATH_LOAD_TRACKING_URLS = "/admin/tracking-url/";
export const PATH_LOAD_CURRENCY_LIST = "/admin/currency/";
export const PATH_LOAD_COUNTRIES_LIST = "/admin/country/";
export const PATH_LOAD_TOKENS_DICTIONARY = "/admin/token-dictionary/";
export const PATH_LOAD_TIMEZONES_LIST = "/admin/timezone/";

export const PATH_CONDITION_BRAND = "/admin/condition/brand/";
export const PATH_CONDITION_BROWSER = "/admin/condition/browser/";
export const PATH_CONDITION_CITY = "/admin/condition/city/";
export const PATH_CONDITION_CITY_ALL = "/admin/condition/city/all/";
export const PATH_CONDITION_CONNECTION_TYPE =
  "/admin/condition/connection-type/";
export const PATH_CONDITION_COUNTRY = "/admin/condition/country/";

export const PATH_CONDITION_DEVICE_TYPE = "/admin/condition/device-type/";
export const PATH_CONDITION_LANGUAGE = "/admin/condition/language/";
export const PATH_CONDITION_MOBILE_CARRIER = "/admin/condition/mobile-carrier/";
export const PATH_CONDITION_OS = "/admin/condition/os/";
export const PATH_CONDITION_PROXY = "/admin/condition/proxy/";

export const PATH_CONDITION_REGION = "/admin/condition/region/";
export const PATH_CONDITION_ISP = "/admin/condition/isp/";
export const PATH_CONDITION_ISP_ALL = "/admin/condition/isp/all/";
export const PATH_CONDITION_WEEKDAY = "/admin/condition/weekday/";

// General
export const PATH_LOAD_TAGS = "/tags/";
export const PATH_DASHBOARD_OVERVIEW_DATA = "/overview?";
export const PATH_DASHBOARD_OVERVIEW_SUMMARY_DATA = "/overview/summary?";
export const PATH_OF_PATH = "/path/";
export const PATH_RULE = "/rule/";

// Pages
export const PATH_AFFILIATE_NETWORKS = "/affilate-network/";
export const PATH_AFFILIATE_NETWORKS_SHORT = "/affilate-network/adv/campaign/";
export const PATH_AFFILIATE_NETWORKS_ARCHIVED = "/affilate-network/an/archive/";
export const PATH_AFFILIATE_NETWORKS_CUSTOM = "/affilate-network/an/public/";

export const PATH_CAMPAIGNS = "/campaign/";
export const PATH_CAMPAIGNS_ARCHIVED = "/campaign/archive/";
export const PATH_CAMPAIGNS_MANUAL_COST = "/campaign/manual-cost/";

export const PATH_LANDERS = "/lander/";
export const PATH_LANDERS_SHORT = "/lander/adv/campaign/";
export const PATH_LANDERS_ARCHIVED = "/lander/archive/";

export const PATH_FLOWS = "/flow/";
export const PATH_FLOWS_SHORT = "/flow/adv/campaign/";
export const PATH_FLOWS_ARCHIVED = "/flow/archive/";

export const PATH_OFFERS = "/offers/";
export const PATH_OFFERS_SHORT = "/offers/adv/campaign/";
export const PATH_OFFERS_ARCHIVED = "/offers/archive/";

export const PATH_TRAFFIC_SOURCES = "/traffic-source/";
export const PATH_TRAFFIC_SOURCES_SHORT = "/traffic-source/adv/campaign/";
export const PATH_TRAFFIC_SOURCES_ARCHIVED = "/traffic-source/ts/archive/";
export const PATH_TRAFFIC_SOURCES_CUSTOM = "/traffic-source/ts/public/";

// settings area
export const PATH_WORKSPACE = "/workspace/";
export const PATH_WORKSPACE_MOVE_TO = "/workspace/move-to/";

export const PATH_DOMAIN = "/domain/";
export const PATH_DOMAIN_REDIRECT = "/domain/rd/redirect/";

// Charts
export const PATH_DASHBOARD_OVERVIEW_CHART = "/overview/ch/chart?";
export const PATH_AFFILIATE_NETWORKS_CHART = "/affilate-network/ch/chart?";
export const PATH_LANDERS_CHART = "/lander/ch/chart?";
export const PATH_CAMPAIGNS_CHART = "/campaign/ch/chart?";
export const PATH_FLOWS_CHART = "/flow/ch/chart?";
export const PATH_OFFERS_CHART = "/offers/ch/chart?";
export const PATH_TRAFFIC_SOURCES_CHART = "/traffic-source/ch/chart?";

// Grouping
export const PATH_GROUPING = "/grouping";
