import React, {Component} from "react";
import produce from "immer";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import ModalForm from "./ModalForm";
import {createStructuredSelector} from "reselect";
import {connect} from 'react-redux';
import * as affiliatedNetworksActions from "../actions";
import * as workspaceActions from "../../../Settings/CollaborationTools/actions";
import * as TSActions from "../actions";
import {makeSelectToken} from "../../../../registration/LoginRegister/selectors";
import {
    makeSelectLoading,
    makeSelectSuccess,
    makeSelectError,
    makeSelectLoadCurrenciesList,
    makeSelectTagsListReceived,
    makeSelectIsTagsListReceived,
    makeSelectEditTrafficSourceDataReceived
} from "../selectors";
import { makeSelectWorkspaceData } from "../../../Settings/CollaborationTools/selectors";

class EditTrafficSourcesModal extends Component {
    state = {
        dataToSend: {
            "name": "",
            "workspaceId": "",
            "currencyId": "",
            "parameters": [
                { "name": "External Id", "ts_parameter": "", "ts_token": "", "vlm_token": "{externalId}", "is_active": true },
                { "name": "Cost", "ts_parameter": "", "ts_token": "", "vlm_token": "{cost}", "is_active": true },
                { "name": "", "ts_parameter": "", "ts_token": "", "vlm_token": "{var1}", "is_active": false },
                { "name": "", "ts_parameter": "", "ts_token": "", "vlm_token": "{var2}", "is_active": false },
                { "name": "", "ts_parameter": "", "ts_token": "", "vlm_token": "{var3}", "is_active": false },
                { "name": "", "ts_parameter": "", "ts_token": "", "vlm_token": "{var4}", "is_active": false },
                { "name": "", "ts_parameter": "", "ts_token": "", "vlm_token": "{var5}", "is_active": false },
                { "name": "", "ts_parameter": "", "ts_token": "", "vlm_token": "{var6}", "is_active": false },
                { "name": "", "ts_parameter": "", "ts_token": "", "vlm_token": "{var7}", "is_active": false },
                { "name": "", "ts_parameter": "", "ts_token": "", "vlm_token": "{var8}", "is_active": false },
                { "name": "", "ts_parameter": "", "ts_token": "", "vlm_token": "{var9}", "is_active": false },
                { "name": "", "ts_parameter": "", "ts_token": "", "vlm_token": "{var10}", "is_active": false },
                { "name": "", "ts_parameter": "", "ts_token": "", "vlm_token": "{var11}", "is_active": false },
                { "name": "", "ts_parameter": "", "ts_token": "", "vlm_token": "{var12}", "is_active": false },
                { "name": "", "ts_parameter": "", "ts_token": "", "vlm_token": "{var13}", "is_active": false },
                { "name": "", "ts_parameter": "", "ts_token": "", "vlm_token": "{var14}", "is_active": false },
                { "name": "", "ts_parameter": "", "ts_token": "", "vlm_token": "{var15}", "is_active": false },
                { "name": "", "ts_parameter": "", "ts_token": "", "vlm_token": "{var16}", "is_active": false },
                { "name": "", "ts_parameter": "", "ts_token": "", "vlm_token": "{var17}", "is_active": false },
                { "name": "", "ts_parameter": "", "ts_token": "", "vlm_token": "{var18}", "is_active": false },
                { "name": "", "ts_parameter": "", "ts_token": "", "vlm_token": "{var19}", "is_active": false },
                { "name": "", "ts_parameter": "", "ts_token": "", "vlm_token": "{var20}", "is_active": false }
            ],
            "postback_url": "",
            "pixel_redirect_url": "",
            "impression_tracking":false,
            "direct_tracking":false
        },
        const_parameters: [],
        currenciesListReceived: null,
        workspacesListReceived: null,
        var_parameters: [],
        extra_parameters_count: 20,
        postback_url_check: false,
        pixel_redirect_url: false,
        isNameEmpty: false,
        tagsListReceived: null,
        is_postback_url_valid: true,
        is_protocol_valid: true,
        is_domain_valid: true,
        resolveErrors: "",
        editTSDataReceived: null,
        isEditTSDataReceived: false,
        trafficSourcesDataLoaded:false,
        disabled: false,
        allLoaded: false
    }
    fetchCurrencies = async () => {
        return this.props.dispatchLoadCurrencies(this.props.accessToken);
    }
    fetchWorkspaces = async () => {
        return this.props.dispatchLoadWorkspaces(this.props.accessToken);
    }

    fetchTags = async () => {
        return this.props.dispatchLoadTags({accessToken: this.props.accessToken});
    }

    fetchEditTrafficSource = async () => {
        return this.props.dispatchLoadEditTSNetwork(
            {
                accessToken: this.props.accessToken,
                loadEditTrafficSourceId: this.props.editTrafficSourceId
            })
    }

    componentDidMount() {
        // this.setState(produce(draft => { draft.trafficSourcesDataLoaded = true }));
        // fetching currencies and workspaces initially
        if (this.state.currenciesListReceived === null) { this.fetchCurrencies(); }
        else {
            this.setState(produce(draft => { draft.dataToSend.currencyId = String(this.state.currenciesListReceived[0].id) }));
        }
        if (this.state.workspacesListReceived === null) { this.fetchWorkspaces(); }
        else {
            this.setState(produce(draft => { draft.dataToSend.workspaceId = String(this.state.workspacesListReceived[0].id) }));
        }
        if (this.state.tagsListReceived === null) { this.fetchTags(); }
        if (this.state.editTSDataReceived === null) { this.fetchEditTrafficSource(); }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Set workspaces and currencies initial values in the state
        if ((prevProps.currenciesListReceived !== this.props.currenciesListReceived) && this.props.currenciesListReceived !== null && this.props.currenciesListReceived !== undefined && this.state.currenciesListReceived === null) {
            this.setState(produce(draft => { draft.currenciesListReceived = this.props.currenciesListReceived }));
            this.setState(produce(draft => { draft.dataToSend.currencyId = String(this.props.currenciesListReceived[0].id) }));
        } else {}
        if ((prevProps.workspacesListReceived !== this.props.workspacesListReceived) && this.props.workspacesListReceived !== null && this.props.workspacesListReceived !== undefined && this.state.workspacesListReceived === null) {
            this.setState(produce(draft => { draft.workspacesListReceived = this.props.workspacesListReceived }));
            this.setState(produce(draft => { draft.dataToSend.workspaceId = String(this.props.workspacesListReceived[0].id) }));
        }
        // set tags lists initially
        if ((prevProps.tagsListReceived !== this.props.tagsListReceived) && this.props.tagsListReceived !== null && this.props.tagsListReceived !== undefined && this.state.tagsListReceived === null) {
            this.setState(produce(draft => { draft.tagsListReceived = this.props.tagsListReceived }));
        }
        // set the edit data received initially.
        if ((prevProps.editTSDataReceived !== this.props.editTSDataReceived) && this.props.editTSDataReceived !== null && this.props.editTSDataReceived !== undefined && this.state.isEditTSDataReceived === false) {
            this.setState(produce(draft => { draft.isEditTSDataReceived = true }));
            this.setState(produce(draft => { draft.dataToSend = this.props.editTSDataReceived }));
            this.setState(produce(draft => { draft.trafficSourcesDataLoaded = false }));
            setTimeout(() => { this.addDuplicateKeyword(); },500);
            setTimeout(() => {
                if (this.props.isTagsListReceived !== null && this.props.isTagsListReceived !== undefined && this.state.tagsListReceived !== null) {
                    if(this.state.dataToSend.postback_url !==null && this.state.dataToSend.postback_url !==""){
                        this.changeTagsColor(this.state.dataToSend.postback_url);
                    }
                }
            },1000);
        }

        // Stop the loader when currencies,tags, workspaces list are all loaded
        if (this.state.tagsListReceived !== null && this.state.workspacesListReceived !== null && this.state.currenciesListReceived !== null && this.state.allLoaded === false) {
            this.setState(produce(draft => { draft.allLoaded = true; }))
            this.props.toggleLoader();
        }
    }

    addDuplicateKeyword = () => {
        if (this.props.state.isDuplicate === "Duplicate") {
            if (this.state.dataToSend.name.length > 0) {
                this.setState(produce(draft => { draft.dataToSend.name = this.state.dataToSend.name + " (Duplicate)"}));
            } else {
                setTimeout(() => {
                    this.setState(produce(draft => { draft.dataToSend.name = this.state.dataToSend.name + " (Duplicate)"}));
                },1000);
            }
        }
    }

    // for name, workspace and currency changes
    simpleInputChangeHandler = (e) => {
            this.setState(produce(draft => { draft.dataToSend[e.target.name] = e.target.value }));
    }

    switchChangeHandler = (e) => {
        if (e.target.name === "direct_tracking" || e.target.name === "impression_tracking") {
            this.setState(produce(draft => { draft.dataToSend[e.target.name] = !this.state.dataToSend[e.target.name] }));
        } else {
            this.setState(produce(draft => { draft[e.target.name] = !this.state[e.target.name] }));
        }
    }

    sendTrafficSourcesData = async () => {
        if(this.state.dataToSend.pixel_redirect_url === "") {
            this.setState(produce(draft => { draft.dataToSend.pixel_redirect_url = null }));
        }
        if(this.state.dataToSend.postback_url === "") {
            this.setState(produce(draft => { draft.dataToSend.postback_url = null }));
        }
        let currencyId = String(this.state.dataToSend.currencyId);
        let direct_tracking = this.state.dataToSend.direct_tracking;
        let id = String(this.state.dataToSend.id);
        let impression_tracking = this.state.dataToSend.impression_tracking;
        let is_active = this.state.dataToSend.is_active;
        let name = String(this.state.dataToSend.name);
        let parameters = this.state.dataToSend.parameters;
        let pixel_redirect_url = this.state.dataToSend.pixel_redirect_url;
        let postback_url = this.state.dataToSend.postback_url;
        let userId = String(this.state.dataToSend.userId);
        let workspaceId = String(this.state.dataToSend.workspaceId);
        let allData = {
            currencyId: currencyId,
            direct_tracking: direct_tracking,
            id: id,
            impression_tracking: impression_tracking,
            is_active: is_active,
            name: name,
            parameters: parameters,
            pixel_redirect_url: pixel_redirect_url,
            postback_url: postback_url,
            userId: userId,
            workspaceId: workspaceId
        }
        // this.setState(produce(draft => { draft.dataToSend.currencyId = String(this.state.dataToSend.currencyId) }));
        // this.setState(produce(draft => { draft.dataToSend.workspaceId = String(this.state.dataToSend.workspaceId) }));
        // this.setState(produce(draft => { draft.dataToSend.userId = String(this.state.dataToSend.userId) }));
        // this.setState(produce(draft => { draft.dataToSend.id = String(this.state.dataToSend.id) }));
        setTimeout(() => {
            if (this.props.state.isDuplicate === "Duplicate") {
                return this.props.dispatchPostTrafficSource({
                    data: allData,
                    accessToken: this.props.accessToken
                });
            } else {
                return this.props.dispatchSendEditTSNetwork({
                    // data: this.state.dataToSend,
                    data: allData,
                    accessToken: this.props.accessToken,
                    sendEditTrafficSourceId: this.state.dataToSend.id
                });
            }
        },200);
    }

    submitTrafficSourcesData = () => {
        this.setState(produce(draft => { draft.disabled = true; }))
        if (this.state.dataToSend.name === "") {
            this.setState(produce(draft => {
                draft.isNameEmpty = true;
                draft.disabled = false;
            }));
        } else if (this.state.is_protocol_valid === false || this.state.is_postback_url_valid === false) {
            this.setState(produce(draft => {
                draft.resolveErrors = "Please make sure to resolve all errors before submitting the data.";
                draft.disabled = false;
            }));
        } else {
            this.setState(produce(draft => { draft.resolveErrors = "" }));
            this.sendTrafficSourcesData();
            this.props.closeEditTrafficSourcesDialogueAfterSubmission(this.props.state.isDuplicate);
        }
    }

    increaseExtraParametersCount = () => {
        if (this.state.extra_parameters_count < 20){
            this.setState(produce(draft => { draft.extra_parameters_count = this.state.extra_parameters_count + 1 }));
        }
    }

    decreaseCountAndRemoveData = () => {
        if (this.state.extra_parameters_count > 0) {
            this.setState(produce(draft => { draft.dataToSend.parameters[(this.state.extra_parameters_count+1)] = {
                "name": "",
                "ts_parameter": "",
                "ts_token": "",
                "vlm_token": "{var2}",
                "is_active": false
            } }));
            this.setState(produce(draft => { draft.extra_parameters_count = this.state.extra_parameters_count - 1 }));
        }
    }

    parameterChangeHandler = (e) => {
        const parameter = e.target.name.split("_")[0];
        const number = Number(e.target.name.split("_")[1]);
        let state_parameter;
        if (parameter === "name") {
            state_parameter = "name";
        } else if (parameter === "token") {
            state_parameter = "ts_token";
        } else if (parameter === "parameter") {
            state_parameter = "ts_parameter";
        }
        this.setState(produce(draft => { draft.dataToSend.parameters[number][state_parameter] = e.target.value }));
    }


    tsVarSwitchChangeHandler = (e) => {
        const number = Number(e.target.name.split("_")[2]);
        this.setState(produce(draft => { draft.dataToSend.parameters[number]["is_active"] = !this.state.dataToSend.parameters[number]["is_active"] }));
    }

    //Checking for postback url
    // Checking for http:// and https://
    hasValidUrlProtocol = (url = '') => Boolean(['http://', 'https://', 'ftp://'].some(protocol => url.startsWith(protocol)));
    // checking for valid domain name
    hasValidDomainName = (text) => {
        return new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(text);
    }
    checkProtocolsValidity = (data) => {
        if(this.hasValidUrlProtocol(data)) {
            this.setState(produce(draft => {draft.is_protocol_valid = true }));
        } else { this.setState(produce(draft => {draft.is_protocol_valid = false })); }
        if (this.hasValidDomainName(data)) {
            this.setState(produce(draft => { draft.is_domain_valid = true }));
        } else { this.setState(produce(draft => { draft.is_domain_valid = false })); }
    }
    changeTagsColor = (data) => {
        let indexes = [];
        for(let i=0;i<this.state.tagsListReceived.length;i++) {
            if (data.includes(this.state.tagsListReceived[i].code)) {
                indexes.push(data.indexOf(this.state.tagsListReceived[i].code));
                document.getElementById(this.state.tagsListReceived[i].code).style.backgroundColor = "#6786DE";
                document.getElementById(this.state.tagsListReceived[i].code).style.color = "#fff";
            } else {
                document.getElementById(this.state.tagsListReceived[i].code).style.backgroundColor = "#F0F3F7";
                document.getElementById(this.state.tagsListReceived[i].code).style.color = "#8892AA";
            }
        }
    }
    postbackUrlChangeHandler = (e) => {
        if (e.target.value !== "") {
            this.checkProtocolsValidity(e.target.value);
            this.changeTagsColor(e.target.value);
        }
        else {
            this.setState(produce(draft => {draft.is_protocol_valid = true}));
            this.setState(produce(draft => { draft.is_domain_valid = true }));
        }
        this.setState(produce(draft => { draft.dataToSend.postback_url = e.target.value }));
    }
    addTokenToUrl = (e) => {
        if (!this.state.dataToSend.postback_url.includes(e.target.id)) {
            const value = this.state.dataToSend.postback_url + String(e.target.id);
            this.setState(produce(draft => { draft.dataToSend.postback_url =  value }));
            document.getElementById(e.target.id).style.backgroundColor = "#6786DE";
            document.getElementById(e.target.id).style.color = "#fff";
        }
        this.checkProtocolsValidity(this.state.dataToSend.postback_url);
    }

    render () {
        return (
            <div>
                <Dialog className="add_affiliate_networks_dialogue" title={this.props.state.isDuplicate === "Duplicate" ?"Duplicate traffic source" :"Edit traffic source"} onClose={() => this.props.toggleTrafficSourcesDialogue("Close_EDIT_Toggle")}>
                    <div className="add_affiliate_network_dialogue_content">
                        <div className="content_form">
                            <ModalForm
                                state={this.state}
                                simpleInputChangeHandler={this.simpleInputChangeHandler}
                                addParameter={this.addParameter}
                                increaseExtraParametersCount={this.increaseExtraParametersCount}
                                parameterChangeHandler={this.parameterChangeHandler}
                                switchChangeHandler={this.switchChangeHandler}
                                tsVarSwitchChangeHandler={this.tsVarSwitchChangeHandler}
                                decreaseCountAndRemoveData={this.decreaseCountAndRemoveData}
                                tagsListReceived={this.state.tagsListReceived}
                                postbackUrlChangeHandler={this.postbackUrlChangeHandler}
                                addTokenToUrl={this.addTokenToUrl}
                            />
                            {/*{this.state.trafficSourcesDataLoaded&& <LoadingPanel/>}*/}
                        </div>
                    </div>
                    <DialogActionsBar>
                        <div className="action_bar_buttons">
                            <div className="left">
                                <button onClick={() => this.props.toggleTrafficSourcesDialogue("Close_EDIT_Toggle")} className="normal_btn cancel_button gray_button_bg_imp black border_radius_normal_btn normal_text black no_border">Close</button>
                            </div>
                            <div className="right">
                                <button
                                    onClick={this.submitTrafficSourcesData}
                                    className="normal_btn theme_dark_btn theme_dark_bg_color_imp color_white_imp border_radius_normal_btn save_button normal_text"
                                    disabled={this.state.disabled}
                                >Save</button>
                            </div>
                        </div>
                    </DialogActionsBar>
                </Dialog>
            </div>
        );
    }
}

export const mapStateToProps = createStructuredSelector({
    accessToken: makeSelectToken(),
    // Traffic Sources props
    loading: makeSelectLoading(),
    success: makeSelectSuccess(),
    error: makeSelectError(),
    currenciesListReceived: makeSelectLoadCurrenciesList(),
    workspacesListReceived: makeSelectWorkspaceData(),
    tagsListReceived: makeSelectTagsListReceived(),
    isTagsListReceived: makeSelectIsTagsListReceived(),
    editTSDataReceived: makeSelectEditTrafficSourceDataReceived()

});
export function mapDispatchToProps(dispatch) {
    return {
        dispatchLoadCurrencies: (...payload) => dispatch(affiliatedNetworksActions.loadCurrency(...payload)),
        dispatchLoadWorkspaces: (...payload) => dispatch(workspaceActions.fetchingData(...payload)),
        dispatchLoadTags: (...payload) => dispatch(TSActions.loadTokensDictionary(...payload)),
        dispatchLoadEditTSNetwork: (...payload) => dispatch(TSActions.loadEditTrafficSource(...payload)),
        dispatchSendEditTSNetwork: (...payload) => dispatch(TSActions.sendEditTrafficSource(...payload)),
        dispatchPostTSNetwork: (...payload) => dispatch(TSActions.postTrafficSource(...payload)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTrafficSourcesModal);