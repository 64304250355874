import produce from "immer";
import {
	ADD_PRIVATE_WORKSPACE, ADD_PRIVATE_WORKSPACE_FAILED, ADD_PRIVATE_WORKSPACE_SUCCESS,
	LOAD_PRIVATE_WORKSPACE, LOAD_PRIVATE_WORKSPACE_SUCCESS, LOAD_PRIVATE_WORKSPACE_FAILED,
	EDIT_PRIVATE_WORKSPACE, EDIT_PRIVATE_WORKSPACE_SUCCESS, EDIT_PRIVATE_WORKSPACE_FAILED,
	DELETE_PRIVATE_WORKSPACE,DELETE_PRIVATE_WORKSPACE_SUCCESS,DELETE_PRIVATE_WORKSPACE_FAILED
} from './constants';

export const initialState = {
	workspaceName:null,
	workspaceID:null,
	workspaceAdded: false,
	workspaceEdit: false,
	workspaceDelete: false,
	error:false,
	success: false,
	loading: false,
	data: null
	// Workspacetoken: null
}

const collaborationToolsWorkspace = (state = initialState, action) => 
	produce(state, (draft) => {
		switch (action.type) {
			case ADD_PRIVATE_WORKSPACE:
				draft.loading = true;
				draft.error = false;
				draft.success = false;
				draft.workspaceAdded = false;
				return draft;
			case ADD_PRIVATE_WORKSPACE_SUCCESS:
				draft.workspaceAdded = true;
				draft.success = true;
				draft.loading = false;
				draft.error = false;
				return draft;
			case ADD_PRIVATE_WORKSPACE_FAILED:
				draft.error = true;
				draft.workspaceAdded = false;
				draft.success = false;
				draft.workspaceAdded = false;
				return draft;
			case LOAD_PRIVATE_WORKSPACE:
				//console.log('hit');
				draft.loading = true;
				draft.error = false;
				draft.success = false;
				draft.workspaceAdded = false;
				draft.workspaceDelete = false;
				draft.workspaceEdit = false;
				return draft;
			case LOAD_PRIVATE_WORKSPACE_SUCCESS:
				draft.data = action.payload.data.data;
				draft.loading = false;
				draft.error = false;
				draft.success = true;
				draft.workspaceAdded = false;
				draft.workspaceDelete = false;
				draft.workspaceEdit = false;
				return draft;
			case LOAD_PRIVATE_WORKSPACE_FAILED:
				draft.loading = false;
				draft.error = true;
				draft.success = false;
				draft.workspaceAdded = false;
				draft.workspaceDelete = false;
				draft.workspaceEdit = false;
				return draft;
			
			case EDIT_PRIVATE_WORKSPACE:
				draft.loading = true;
				draft.error = false;
				draft.success = false;
				draft.workspaceEdit = false;
				return draft;
			case EDIT_PRIVATE_WORKSPACE_SUCCESS:
				draft.loading = false;
				draft.error = false;
				draft.success = true;
				draft.workspaceEdit = true;
				return draft;
			case EDIT_PRIVATE_WORKSPACE_FAILED:
				draft.loading = false;
				draft.error = true;
				draft.success = false;
				draft.workspaceEdit = false;
				return draft;
			case DELETE_PRIVATE_WORKSPACE:
				draft.loading = true;
				draft.error = false;
				draft.success = false;
				draft.workspaceDelete = false;	
			return draft;
			case DELETE_PRIVATE_WORKSPACE_SUCCESS:
				draft.loading = false;
				draft.error = false;
				draft.success = true;
				draft.workspaceDelete = true;
				return draft;
			case DELETE_PRIVATE_WORKSPACE_FAILED:
				//console.log(' delete failed', action.payload.error.response.data);
				draft.loading = false;
				draft.error = action.payload.error.response.data;
				draft.success = false;
				draft.workspaceDelete = false;
				return draft;
			default:
				return draft;
		}
	})



export default collaborationToolsWorkspace;

