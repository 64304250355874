import { produce } from "immer";

import {
    LOAD_PROFILE,
    LOAD_PROFILE_FAILED,
    LOAD_PROFILE_SUCCESS,
    EDIT_PROFILE,
    EDIT_PROFILE_SUCCESS,
    EDIT_PROFILE_FAILED,
    UPDATE_PROFILE,
    UPDATE_PROFILE_FAILED,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PASSWORD,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAILED,
} from "./constants";

export const initialState = {
    loading: false,
    success: false,
    error: false,
    profileData: null,
    isProfileDataLoaded: false,
    editProfileData: null,
    isEditProfileDataLoaded: false,
    updateProfileData: null,
    isUpdateProfileDataLoaded: false,
    updatePassworData: null,
    isPasswordUpdated: false,
};

const profileReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case LOAD_PROFILE:
                draft.loading = true;
                draft.success = false;
                draft.error = false;
                draft.isPasswordUpdated = false;
                draft.isUpdateProfileDataLoaded = false;
                return draft;
            case LOAD_PROFILE_SUCCESS:
                //console.log('payload.profileData',action.payload.profileData.profileData);
                draft.profileData = action.payload.profileData.profileData;
                draft.loading = false;
                draft.success = true;
                draft.error = false;
                draft.isUpdateProfileDataLoaded = false;
                draft.isPasswordUpdated = false;
                draft.isProfileDataLoaded = true;
                return draft;
            case LOAD_PROFILE_FAILED:
                //console.log('payload.error',action.payload.error.error);
                draft.error = action.payload.error.error;
                draft.loading = false;
                draft.success = false;
                draft.isPasswordUpdated = false;
                draft.isUpdateProfileDataLoaded = false;
                draft.isProfileDataLoaded = false;
                return draft;
            case EDIT_PROFILE:
                draft.loading = true;
                draft.success = false;
                draft.error = false;
                return draft;
            case EDIT_PROFILE_SUCCESS:
                //draft.editProfileData = payload.editProfileData;
                draft.loading = false;
                draft.success = true;
                draft.error = false;
                draft.isEditProfileDataLoaded = true;
                return draft;
            case EDIT_PROFILE_FAILED:
                //draft.error = payload.error;
                draft.loading = false;
                draft.success = false;
                draft.isEditProfileDataLoaded = false;
                return draft;
            case UPDATE_PROFILE:
                draft.loading = true;
                draft.success = false;
                draft.error = false;
                draft.isUpdateProfileDataLoaded = false;
                return draft;
            case UPDATE_PROFILE_SUCCESS:
                //console.log('success', action.payload.updateProfileData);
                //draft.updateProfileData = payload.updateProfileData;
                draft.profileData = action.payload.updateProfileData;
                draft.loading = false;
                draft.success = true;
                draft.error = false;
                draft.isUpdateProfileDataLoaded = true;
                return draft;
            case UPDATE_PROFILE_FAILED:
                draft.error = action.payload;
                draft.loading = false;
                draft.success = false;
                draft.isUpdateProfileDataLoaded = false;
                return draft;

                case UPDATE_PASSWORD:
                draft.loading = true;
                draft.success = false;
                draft.error = false;
                
                draft.isPasswordUpdated = false;
                return draft;
            case UPDATE_PASSWORD_SUCCESS:
                draft.updatePassworData = action.payload.updatePasswordSuccess;
                draft.loading = false;
                draft.success = true;
                draft.error = false;
                draft.isPasswordUpdated = true;
                return draft;
            case UPDATE_PASSWORD_FAILED:
                draft.error = action.payload;
                draft.loading = false;
                draft.success = false;
                draft.isPasswordUpdated = false;
                return draft;
            default:
                return draft;
        }
    });
export default profileReducer;
