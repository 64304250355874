import React, {useState, useRef, useEffect} from "react";
import ReactTooltip from 'react-tooltip';
import { Fade } from '@progress/kendo-react-animation';
import { MultiSelect } from '@progress/kendo-react-dropdowns';

const ModalForm = (props) => {
    const [affiliateTrackingURLCopySuccess, setAffiliateTrackingURLCopySuccess] = useState('');
    const intClickURLRef1 = useRef(null);
    const intClickURLRef2 = useRef(null);
    const intClickURLRef3 = useRef(null);
    const intClickURLRef4 = useRef(null);
    const intClickURLRef5 = useRef(null);
    const intClickURLRef6 = useRef(null);
    const intClickURLRef7 = useRef(null);
    const intClickURLRef8 = useRef(null);
    const intClickURLRef9 = useRef(null);
    const intClickURLRef10 = useRef(null);
    const inputCountryTagReg = useRef(null);
    const [inputCountryTagWidth, setInputCountryTagWidth] = useState("");

    function copyToClipboard(e) {
        switch (e.target.id) {
            case "Ref1":
                intClickURLRef1.current.select();
                document.execCommand('copy');
                e.target.focus();
                break;
            case "Ref2":
                intClickURLRef2.current.select();
                document.execCommand('copy');
                e.target.focus();
                break;
            case "Ref3":
                intClickURLRef3.current.select();
                document.execCommand('copy');
                e.target.focus();
                break;
            case "Ref4":
                intClickURLRef4.current.select();
                document.execCommand('copy');
                e.target.focus();
                break;
            case "Ref5":
                intClickURLRef5.current.select();
                document.execCommand('copy');
                e.target.focus();
                break;
            case "Ref6":
                intClickURLRef6.current.select();
                document.execCommand('copy');
                e.target.focus();
                break;
            case "Ref7":
                intClickURLRef7.current.select();
                document.execCommand('copy');
                e.target.focus();
                break;
            case "Ref8":
                intClickURLRef8.current.select();
                document.execCommand('copy');
                e.target.focus();
                break;
            case "Ref9":
                intClickURLRef9.current.select();
                document.execCommand('copy');
                e.target.focus();
                break;
            case "Ref10":
                intClickURLRef10.current.select();
                document.execCommand('copy');
                e.target.focus();
                break;
        }
    };

    const setWidthOfInputCountryTag = (e) => {
        props.countryChangeHandler(e.target.value);
    };

    useEffect(() => {
        // Update the document title using the browser API
        setInputCountryTagWidth(inputCountryTagReg.current.clientWidth + "px");
    },[props.state.countryName]);



    //=========================================
    // Code for displaying intclick urls
    useEffect(() => {
        if (props.state.dataToSend.no_of_cta > 0) {
            setTimeout(() => {
                for (let i=0; i<props.state.dataToSend.no_of_cta; i++) {
                    if (document.getElementById("intClickURLRef" + (i+1))) {
                        document.getElementById("intClickURLRef" + (i+1)).style.display = "block";
                    }
                    // (intClickURLRef+ [i]).current.style.display = "block";
                }
                for (let i=props.state.dataToSend.no_of_cta; i<10; i++) {
                    if (document.getElementById("intClickURLRef" + (i+1))) {
                        document.getElementById("intClickURLRef" + (i+1)).style.display = "none";
                    }
                }
            },200);
        }
    }, [props.state.dataToSend.no_of_cta]);
    // }, [props.state.dataToSend.no_of_cta]);

    const addIntClick = () => {
        if (props.state.dataToSend.no_of_cta < 10) {
            document.getElementById("intClickURLRef" + (props.state.dataToSend.no_of_cta+1)).style.display = "block";
        }
        props.changeNumberOfCTAs("add");
    }

    const removeIntCLick = () => {
        if (props.state.dataToSend.no_of_cta > 1) {
            document.getElementById("intClickURLRef" + (props.state.dataToSend.no_of_cta)).style.display = "none";
        }
        props.changeNumberOfCTAs("subtract");
    }

    //=========================================


    return (
        <div className="form_con">
            <section>
                <h3>General</h3>
                <div className="input_group five_hun">
                    <label htmlFor="affiliate-network-name">
                        Workspace
                        <span data-tip data-for='workspaces_tooltip' className="question_mark">
                            <span className="k-icon k-i-question-circle"></span>
                        </span>
                        <ReactTooltip id='workspaces_tooltip' type='dark'>
                            <span>Workspace is an isolated area with a set of entities where users with different roles can work on. Assign a workspace to make the lander accessible only by the workspace members.</span>
                        </ReactTooltip>
                    </label>
                    <select
                        className="add_custom_domain_dropdown normal_text normal_font"
                        name="workspaceId"
                        value={props.state.dataToSend.workspaceId}
                        onChange={props.simpleInputChangeHandler}
                    >
                        {
                            props.state.loadedWorkspaces ?
                                props.state.loadedWorkspaces.map((workspace) => (
                                    <option key={workspace.id} value={workspace.id}>{workspace.name}</option>
                                ))
                                : null
                        }
                    </select>
                </div>
                <div className="input_group five_hun">
                    <label htmlFor="affiliate-network-name">
                        Country Tag
                        <span data-tip data-for='country_tooltip' className="question_mark">
                            <span className="k-icon k-i-question-circle"></span>
                        </span>
                        <ReactTooltip id='country_tooltip' type='dark'>
                            <span>It has only an organizational purpose to group prelanders by its Country label.</span>
                        </ReactTooltip>
                    </label>
                    <select
                        className="add_custom_domain_dropdown normal_text normal_font"
                        name="countryId"
                        value={props.state.dataToSend.countryId}
                        // onChange={props.countryChangeHandler}
                        onChange={setWidthOfInputCountryTag}
                    >
                        {
                            props.state.loadedCountries ?
                                props.state.loadedCountries.map((country) => (
                                    <option key={country.id} value={country.id}>{country.country_name}</option>
                                ))
                                : null
                        }
                    </select>
                </div>
                <div className="input_group">
                    <label htmlFor="affiliate-network-name">Name</label>
                    <div className="name_con_mix">
                        <span
                            className="normal_text black"
                            ref={inputCountryTagReg}
                        >{props.state.countryName}&nbsp;-&nbsp;</span>
                        <input
                            type="text"
                            name="name"
                            placeholder=""
                            value={props.state.dataToSend.name}
                            onChange={props.simpleInputChangeHandler}
                        />
                        {/*<span*/}
                        {/*    className="lander_name_country_tag normal_text black"*/}
                        {/*    ref={inputCountryTagReg}*/}
                        {/*>{props.state.countryName} - </span>*/}
                        {/*<input*/}
                        {/*    type="text"*/}
                        {/*    name="name"*/}
                        {/*    placeholder=""*/}
                        {/*    value={props.state.dataToSend.name}*/}
                        {/*    onChange={props.simpleInputChangeHandler}*/}
                        {/*    style={{paddingLeft: inputCountryTagWidth}}*/}
                        {/*/>*/}
                    </div>
                    {
                        props.state.isNameEmpty ?
                            <Fade enter={true} exit={true}>
                                <p className="danger_para mb-10 mh-0">You must add the Lander name.</p>
                            </Fade>
                            : null
                    }
                </div>
                <div className="input_group">
                    <label htmlFor="affiliate-network-name">Tags
                    <span data-tip data-for="tags_tooltip" className="question_mark">
                            <span className="k-icon k-i-question-circle"></span>
                        </span>
                        <ReactTooltip id="tags_tooltip" type="dark">
                            <span>
                                Add personalized tags to easily search for landers afterward. Keep in
                                mind that tags can only contain letters, numbers, and underscores.
                            </span>
                        </ReactTooltip>
                    </label>
                    <MultiSelect
                        placeholder="Type or select tags from list"
                        onChange={props.handleTagChange}
                        value={props.state.dataToSend["tags"]}
                        allowCustom={true}
                        className="add_tags"
                        
                    />
                    {/* <input
                        type="text"
                        name="tags"
                        placeholder="Type and enter to create a tag... Disabled for now... backend connectivity issue"
                        value={props.state.dataToSend.tags}
                        onChange={props.tagsInputChangeHandler}
                        disabled={true}
                    /> */}
                    {/*{*/}
                    {/*    props.state.isNameEmpty ?*/}
                    {/*        <Fade enter={true} exit={true}>*/}
                    {/*            <p className="danger_para mb-10 mh-0">You must add the PreLander name.</p>*/}
                    {/*        </Fade>*/}
                    {/*        : null*/}
                    {/*}*/}
                </div>
            </section>
            <section>
                <h3>{props.state.dataToSend.pre_lander ? "Prelander" : "Lander"} URL</h3>
                <div className="additional_settings_con">
                    <input className="ts_postback_input" type="text" placeholder="E.g. https://www.lander-url.com" name="url" onChange={props.urlChangeHandler} value={props.state.dataToSend.url} />
                    {
                        !props.state.is_protocol_valid || !props.state.is_domain_valid || props.state.is_url_empty ?
                            <Fade enter={true} exit={true}>
                                <p className="danger_para mb-10 mh-0" style={{margin: "0 0 10px", padding: "10px 15px"}}>
                                    {
                                        !props.state.is_protocol_valid ?
                                            "- Please make sure the url starts with http:// or https:// "
                                            : null
                                    }
                                    &nbsp;&nbsp;
                                    {
                                        !props.state.is_domain_valid ?
                                            " - Please make sure you enter a valid domain name "
                                            : null
                                    }
                                    &nbsp;&nbsp;
                                    {
                                        props.state.is_url_empty ?
                                            ` - Url of the ${props.state.dataToSend.pre_lander ? 'pre-' : ""} lander page can not be empty. Please add a url.`
                                            : null
                                    }
                                </p>
                            </Fade>
                            : null
                    }
                    <div className="note normal_font normal_text">
                        <span className="question_mark">
                            <span className="k-icon k-i-question-circle icon_color_theme_default"></span>
                        </span>
                        <div className="note_p black">Please make sure you add a valid url, it must start with http:// or https:// and token parameters must be after the domain followed by a "?"
                            <br />
                            Here are a couple of valid examples, <span className="theme_blue_color">http://domain.com?aid=REPLACE&tid=REPLACE&visitor_id={'{externalid}'}</span> or <span className="theme_blue_color">https://subdomain.domain-name.com/page.php?cid={'{externalid}'}</span>
                        </div>
                    </div>
                    <div className="ts_postback_tokens_container">
                        {
                            props.tagsListReceived ?
                                props.tagsListReceived.map(tag => (
                                    <span id={tag.code} className="token" onClick={props.addTokenToUrl}>{tag.code}</span>
                                ))
                                : null
                        }
                    </div>
                </div>
            </section>
            <section>
                <h3>{props.state.dataToSend.pre_lander ? "Prelanding" : "Landing"} page setup</h3>
                <div className="flex_vertical_row">
                    <label htmlFor="affiliate-network-name">
                        Number of CTAs
                        <span data-tip data-for='ctas_tooltip' className="question_mark">
                            <span className="k-icon k-i-question-circle"></span>
                        </span>
                        <ReactTooltip id='ctas_tooltip' type='dark'>
                            <span>A call-to-action is any link or button that usually is used to redirect the user from a lander to an offer or from prelander to lander. If using multiple CTAs the corresponding amount of Click URLs will appear below. To learn more about the prelander setup click here</span>
                        </ReactTooltip>
                    </label>
                    <div className="ctas_con">
                        <input type="text" value={props.state.dataToSend.no_of_cta} />
                        <div className="cta_icons_con">
                            {/*<span className="cta_icons k-icon k-i-plus" onClick={() => props.changeNumberOfCTAs("add")}></span>*/}
                            {/*<span className="cta_icons k-icon k-i-minus" onClick={() => props.changeNumberOfCTAs("subtract")}></span>*/}
                            <span className="cta_icons k-icon k-i-plus" onClick={addIntClick}></span>
                            <span className="cta_icons k-icon k-i-minus" onClick={removeIntCLick}></span>
                        </div>
                    </div>
                </div>
                <div className="empty_space_10"></div>
                <div className="input_group five_hun">
                    <label htmlFor="affiliate-network-name">Tracking domain</label>
                    <select
                        className="add_custom_domain_dropdown normal_text normal_font"
                        name="domainId"
                        value={props.state.dataToSend.domainId}
                        onChange={props.simpleInputChangeHandler}
                    >
                        {
                            props.state.loadedDomains ?
                                props.state.loadedDomains.map((domain) => (
                                    <option key={domain.id} value={domain.id}>{domain.name}</option>
                                ))
                                : null
                        }
                    </select>
                </div>
                <div className="input_group five_hun">
                    <label htmlFor="affiliate-network-name">
                        Intermediate click URL(s)
                        <span data-tip data-for='intermediate_click_url_tooltip' className="question_mark">
                            <span className="k-icon k-i-question-circle"></span>
                        </span>
                        <ReactTooltip id='intermediate_click_url_tooltip' type='dark'>
                            <span>The number at the end of each link is connected to the order in which you can, later on, add the offers when setting up a campaign in AffMark. Place each of the links under a different call to action on your prelander.</span>
                        </ReactTooltip>
                    </label>
                    {/*=================================================================*/}
                    {/*            Input Container for intermediate click urls          */}
                    {/*=================================================================*/}
                    <div id="intClickURLRef1" className="input_group copy_group intermediate_click_urls_group">
                        <div className="input_container">
                            <input
                                className="data"
                                ref={intClickURLRef1}
                                name="intClickURLRef1"
                                value={
                                    props.state.dataToSend.no_of_cta === 1 ?
                                        props.state.selectedDomainName + "/click"
                                        :
                                        props.state.selectedDomainName + "/click/1"
                                }
                            />
                            <div className="copyTags">
                                <span className="copy" id="Ref1" onClick={copyToClipboard}>Copy</span>
                            </div>
                        </div>
                    </div>
                    <div id="intClickURLRef2" className="input_group copy_group intermediate_click_urls_group">
                        <div className="input_container">
                            <input
                                className="data"
                                ref={intClickURLRef2}
                                name="intClickURLRef2"
                                value={props.state.selectedDomainName + "/click/2"}
                            />
                            <div className="copyTags">
                                <span className="copy" id="Ref2" onClick={copyToClipboard}>Copy</span>
                            </div>
                        </div>
                    </div>
                    <div id="intClickURLRef3" className="input_group copy_group intermediate_click_urls_group">
                        <div className="input_container">
                            <input
                                className="data"
                                ref={intClickURLRef3}
                                name={intClickURLRef3}
                                value={props.state.selectedDomainName + "/click/3"}
                            />
                            <div className="copyTags">
                                <span className="copy" id="Ref3" onClick={copyToClipboard}>Copy</span>
                            </div>
                        </div>
                    </div>
                    <div id="intClickURLRef4" className="input_group copy_group intermediate_click_urls_group">
                        <div className="input_container">
                            <input
                                className="data"
                                ref={intClickURLRef4}
                                name={intClickURLRef4}
                                value={props.state.selectedDomainName + "/click/4"}
                            />
                            <div className="copyTags">
                                <span className="copy" id="Ref4" onClick={copyToClipboard}>Copy</span>
                            </div>
                        </div>
                    </div>
                    <div id="intClickURLRef5" className="input_group copy_group intermediate_click_urls_group">
                        <div className="input_container">
                            <input
                                className="data"
                                ref={intClickURLRef5}
                                name={intClickURLRef5}
                                value={props.state.selectedDomainName + "/click/5"}
                            />
                            <div className="copyTags">
                                <span className="copy" id="Ref5" onClick={copyToClipboard}>Copy</span>
                            </div>
                        </div>
                    </div>
                    <div id="intClickURLRef6" className="input_group copy_group intermediate_click_urls_group">
                        <div className="input_container">
                            <input
                                className="data"
                                ref={intClickURLRef6}
                                name={intClickURLRef6}
                                value={props.state.selectedDomainName + "/click/6"}
                            />
                            <div className="copyTags">
                                <span className="copy" id="Ref6" onClick={copyToClipboard}>Copy</span>
                            </div>
                        </div>
                    </div>
                    <div id="intClickURLRef7" className="input_group copy_group intermediate_click_urls_group">
                        <div className="input_container">
                            <input
                                className="data"
                                ref={intClickURLRef7}
                                name={intClickURLRef7}
                                value={props.state.selectedDomainName + "/click/7"}
                            />
                            <div className="copyTags">
                                <span className="copy" id="Ref7" onClick={copyToClipboard}>Copy</span>
                            </div>
                        </div>
                    </div>
                    <div id="intClickURLRef8" className="input_group copy_group intermediate_click_urls_group">
                        <div className="input_container">
                            <input
                                className="data"
                                ref={intClickURLRef8}
                                name={intClickURLRef8}
                                value={props.state.selectedDomainName + "/click/8"}
                            />
                            <div className="copyTags">
                                <span className="copy" id="Ref8" onClick={copyToClipboard}>Copy</span>
                            </div>
                        </div>
                    </div>
                    <div id="intClickURLRef9" className="input_group copy_group intermediate_click_urls_group">
                        <div className="input_container">
                            <input
                                className="data"
                                ref={intClickURLRef9}
                                name={intClickURLRef9}
                                value={props.state.selectedDomainName + "/click/9"}
                            />
                            <div className="copyTags">
                                <span className="copy" id="Ref9" onClick={copyToClipboard}>Copy</span>
                            </div>
                        </div>
                    </div>
                    <div id="intClickURLRef10" className="input_group copy_group intermediate_click_urls_group">
                        <div className="input_container">
                            <input
                                className="data"
                                ref={intClickURLRef10}
                                name={intClickURLRef10}
                                value={props.state.selectedDomainName + "/click/10"}
                            />
                            <div className="copyTags">
                                <span className="copy" id="Ref10" onClick={copyToClipboard}>Copy</span>
                            </div>
                        </div>
                    </div>
                    {/*=================================================================*/}
                    {/*            Input Container for intermediate click urls          */}
                    {/*=================================================================*/}
                    <div className="empty_space_10"></div>
                    <div className="empty_space_10"></div>
                    <div className="note normal_font normal_text ts_note">
                        <span className="question_mark">
                            <span className="k-icon k-i-question-circle icon_color_theme_default"></span>
                        </span>
                        <div className="note_p black">Make sure that you have added click URL(s) within the {'<a>'} tag of each CTA.</div>
                    </div>
                </div>
            </section>
            {/*<section>*/}
            {/*    <div className="input_group five_hun">*/}
            {/*        <label htmlFor="affiliate-network-name">*/}
            {/*            Setup Verification*/}
            {/*            <span data-tip data-for='setup_verification_tooltip' className="question_mark">*/}
            {/*                <span className="k-icon k-i-question-circle"></span>*/}
            {/*            </span>*/}
            {/*            <ReactTooltip id='setup_verification_tooltip' type='dark'>*/}
            {/*                <span>By clicking the button below you will be able to check if your prelanding page has been properly set up.</span>*/}
            {/*            </ReactTooltip>*/}
            {/*        </label>*/}
            {/*    </div>*/}
            {/*    <div className="five_hun status_verification_con mb-10">*/}
            {/*        <div className="text">*/}
            {/*            <span className="normal_text normal_font black">Status:</span>*/}
            {/*            <span className="normal_text normal_font black">not verified yet.</span>*/}
            {/*        </div>*/}
            {/*        <div className="verify_button">*/}
            {/*            {*/}
            {/*                !props.state.dataToSend.verify_status && !props.state.isVerifyingSetupDomain*/}
            {/*                    ?*/}
            {/*                    <button className="normal_btn cancel_button gray_button_bg_imp black border_radius_normal_btn normal_text black no_border" onClick={props.verifySetupLandingPage}>Verify landing page</button>*/}
            {/*                    : null*/}
            {/*            }*/}
            {/*            {*/}
            {/*                props.state.isVerifyingSetupDomain*/}
            {/*                    ?*/}
            {/*                    <button className="normal_btn cancel_button gray_button_bg_imp black border_radius_normal_btn normal_text black no_border" >Checking...</button>*/}
            {/*                    : null*/}
            {/*            }*/}
            {/*            {*/}
            {/*                props.state.dataToSend.verify_status*/}
            {/*                    ?*/}
            {/*                    <button className="normal_btn success_button white border_radius_normal_btn normal_text no_border">Verified</button>*/}
            {/*                    : null*/}
            {/*            }*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="five_hun">*/}
            {/*        {*/}
            {/*            props.state.setupVerificationError !== null ?*/}
            {/*                <Fade enter={true} exit={true}>*/}
            {/*                    <p className="danger_para mb-10 mh-0">{props.state.setupVerificationError}</p>*/}
            {/*                </Fade>*/}
            {/*                : null*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*</section>*/}
            <section>
                <div className="empty_space_10"></div>
                {
                    props.state.resolveErrors !== "" ?
                        <Fade enter={true} exit={true}>
                            <p className="danger_para mb-10 mh-0">{props.state.resolveErrors}</p>
                        </Fade>
                        : null
                }
            </section>
        </div>
    );
};

export default ModalForm;