import * as requestHandler from "../../helpers/requestHandlers";
import {
    PATH_DOMAIN,
    PATH_DOMAIN_REDIRECT
} from "../../helpers/path";

export const addDomainToAPI = async (request) => {
    const data = {
        'name': request.payload.name,
        'workspaceId': request.payload.workspaceId
    }
    const response = await requestHandler.post(PATH_DOMAIN, data);
    return response;
};

export const LoadCustomDomainsFromAPI = async (request) => {
    const response = await requestHandler.get(PATH_DOMAIN);
    return response;
};

export const EditCustomDomainFromAPI = async (request) => {
    const data = {
        'name': request.payload.name,
        'workspaceId': request.payload.workspaceId
    }
    const response = await requestHandler.patch(PATH_DOMAIN + request.payload.domainId, data);
    return response;
};

export const DeleteCustomDomainFromAPI = async (request) => {
    const response = await requestHandler.delet(PATH_DOMAIN + request.payload.domainId);
    return response;
};

export const sendMainDomainFromAPI = async (request) => {
    const response = await requestHandler.post(PATH_DOMAIN + request.payload.domainId + "/select/");
    return response;
};

export const SendRedirectDomainFromAPI = async (request) => {
    let data = { name: request.payload.redirectDomainName };
    const response = await requestHandler.post(PATH_DOMAIN_REDIRECT, data);
    return response;
};

export const LoadRedirectDomainFromAPI = async (request) => {
    const response = await requestHandler.get(PATH_DOMAIN_REDIRECT);
    return response;
};