import currencyReducer from "./currencyReducer";
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";
import loginRegisterContainerReducer from "../../registration/LoginRegister/reducer";
import collaborationToolsWorkspace from "../../containers/Settings/CollaborationTools/reducer";
import domainsSettingsReducer from "../../containers/Settings/Domains/reducers";
import affiliateNetworksReducer from "../../containers/Pages/AffiliateNetworks/reducer";
import trafficSourcesReducer from "../../containers/Pages/TrafficSources/reducer";
import offersReducer from "../../containers/Pages/Offers/reducer";
import landersReducer from "../../containers/Pages/Landers/reducer";
import newFlowsReducer from "../../containers/Pages/NewFlows/reducer";
import profileReducer from "../../containers/Settings/Profile/reducer";
import notificationReducer from '../../containers/Settings/Notifications/reducer';
import ipuaFilterReducer from "../../containers/Settings/IP-UA-Filtering/reducer";
import settingReducer from "../../containers/Settings/GeneralSettings/reducer";
import campaignsReducer from "../../containers/Pages/Campaigns/reducer";
import conversionReducer from "../../containers/Pages/Conversion/reducer";
import eventlogReducer from "../../containers/Settings/Event-Log/reducer";
import gridReducer from "../../containers/grid/reducer";
import moveToWorkspaceReducer from "../../moveToWorkspaceRedux/reducer";
import reportReducer from "../../containers/Report/reportPage/reducer";
import allUsersReducer from "../../containers/Settings/Users/reducer";
import dashboardReducer from "../../containers/Pages/Dashboard/reducer";


const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
  currencyData: currencyReducer,
  login: loginRegisterContainerReducer,
  workspace: collaborationToolsWorkspace,
  domains: domainsSettingsReducer,
  affiliateNetworks: affiliateNetworksReducer,
  trafficSources: trafficSourcesReducer,
  // flows: flowsReducer,
  Offers: offersReducer,
  Landers: landersReducer,
  newFlows:newFlowsReducer,
  profile: profileReducer,
  notifications: notificationReducer,
  ipuaFilter: ipuaFilterReducer,
  settings: settingReducer,
  Campaigns: campaignsReducer,
  eventlog: eventlogReducer,
  Grid: gridReducer,
  moveToWorkspace: moveToWorkspaceReducer,
  Report: reportReducer,
  users: allUsersReducer,
  Dashboard: dashboardReducer,
  Conversions:conversionReducer
});

export default rootReducer;
