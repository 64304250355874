import produce from "immer";
import {
    LOAD_OFFER_TRACKING_URLS, LOAD_OFFER_TRACKING_URLS_SUCCESS, LOAD_OFFER_TRACKING_URLS_FAILED,
    LOAD_OFFER_CURRENCY, LOAD_OFFER_CURRENCY_SUCCESS, LOAD_OFFER_CURRENCY_FAILED,
    POST_NEW_OFFER_DATA, POST_NEW_OFFER_DATA_SUCCESS, POST_NEW_OFFER_DATA_FAILED,
    LOAD_OFFERS_DATA, LOAD_OFFERS_DATA_SUCCESS, LOAD_OFFERS_DATA_FAILED,
    EDIT_OFFER_DATA, EDIT_OFFER_DATA_SUCCESS, EDIT_OFFER_DATA_FAILED,
    EDIT_OFFER_DATA_SENT, EDIT_OFFER_DATA_SENT_SUCCESS, EDIT_OFFER_DATA_SENT_FAILED,
    DELETE_OFFER_DATA, DELETE_OFFER_DATA_SUCCESS, DELETE_OFFER_DATA_FAILED,
    ARCHIVE_OFFER_DATA, ARCHIVE_OFFER_DATA_SUCCESS, ARCHIVE_OFFER_DATA_FAILED,
    LOAD_TIMEZONE, LOAD_TIMEZONE_SUCCESS, LOAD_TIMEZONE_FAILED,
    GET_OFFERS_CHARTS_DATA, GET_OFFERS_CHARTS_DATA_SUCCESS, GET_OFFERS_CHARTS_DATA_FAILED
} from "./constants";


export const initialOfferState = {
    loading: false,
    success: false,
    error: false,
    trackingUrlsReceived: null,
    currenciesListReceived: null,
    newOfferDataSent: null,
    offerDataLoaded: null,
    editOfferDataReceived: null,
    patchedOfferDataSent: null,
    deleteOfferDataSent: null,
    timeZoneDataReceived: null,
    archiveOfferDataSent: null,
    archiveDeleteResponseOffers: null,
    charts: {
        isChartsDataLoaded: false,
        allChartsDataLoaded: null
    }
};

const offersReducer = (state= initialOfferState, action) => produce(state, (draft => {
    switch (action.type) {
        case LOAD_OFFER_TRACKING_URLS:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case LOAD_OFFER_TRACKING_URLS_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.trackingUrlsReceived = action.payload;
            return draft;
        case LOAD_OFFER_TRACKING_URLS_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload;
            return draft;


        case LOAD_OFFER_CURRENCY:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case LOAD_OFFER_CURRENCY_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.currenciesListReceived = action.payload;
            return draft;
        case LOAD_OFFER_CURRENCY_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload;
            return draft;


        case POST_NEW_OFFER_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.newOfferDataSent = false;
            return draft;
        case POST_NEW_OFFER_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.newOfferDataSent = true;
            
            return draft;
        case POST_NEW_OFFER_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response.data;
            draft.newOfferDataSent = false;
            return draft;


        case LOAD_OFFERS_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.newOfferDataSent = false;
            draft.patchedOfferDataSent = false;
            draft.archiveOfferDataSent = false;
            draft.deleteOfferDataSent = false;
            return draft;
        case LOAD_OFFERS_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.newOfferDataSent = false;
            draft.patchedOfferDataSent = false;
            draft.archiveOfferDataSent = false;
            draft.deleteOfferDataSent = false;
            draft.offerDataLoaded = action.payload;
            return draft;
        case LOAD_OFFERS_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.newOfferDataSent = false;
            draft.patchedOfferDataSent = false;
            draft.archiveOfferDataSent = false;
            draft.deleteOfferDataSent = false;
            draft.error = action.payload.response.data;
            return draft;


        case EDIT_OFFER_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case EDIT_OFFER_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.editOfferDataReceived = action.payload;
            return draft;
        case EDIT_OFFER_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response.data;
            return draft;


        case EDIT_OFFER_DATA_SENT:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.patchedOfferDataSent = false;
            return draft;
        case EDIT_OFFER_DATA_SENT_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.patchedOfferDataSent = true;
            return draft;
        case EDIT_OFFER_DATA_SENT_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.patchedOfferDataSent = false;
            draft.error = action.payload.response.data;
            return draft;


        case DELETE_OFFER_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.deleteOfferDataSent = false;
            draft.archiveDeleteResponseOffers = null;
            return draft;
        case DELETE_OFFER_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.deleteOfferDataSent = true;
            draft.archiveDeleteResponseOffers = action.payload;
            return draft;
        case DELETE_OFFER_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.deleteOfferDataSent = false;
            draft.error = action.payload.response.data;
            draft.archiveDeleteResponseOffers = null;
            return draft;
        
        case ARCHIVE_OFFER_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.archiveOfferDataSent = false;
            draft.archiveDeleteResponseOffers = null;
            return draft;
        case ARCHIVE_OFFER_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.archiveOfferDataSent = true;
            draft.archiveDeleteResponseOffers = action.payload;
            return draft;
        case ARCHIVE_OFFER_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.archiveOfferDataSent = false;
            draft.error = action.payload.response.data;
            draft.archiveDeleteResponseOffers = null;
            return draft;

            
        case LOAD_TIMEZONE:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;

        case LOAD_TIMEZONE_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            // console.log('success timezone payload', action.payload);
            draft.timeZoneDataReceived = action.payload;
            return draft;
        case LOAD_TIMEZONE_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload;
            return draft;
        
        case GET_OFFERS_CHARTS_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case GET_OFFERS_CHARTS_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.charts.isChartsDataLoaded = true;
            draft.charts.allChartsDataLoaded = action.payload;
            return draft;
        case GET_OFFERS_CHARTS_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.charts.isChartsDataLoaded = false;
            draft.error = action.payload;
            return draft;
        default:
            return draft;
    }
}));

export default offersReducer;