import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
// import { Menu, MenuItem } from '@progress/kendo-react-layout';
import { SvgIcon } from '@progress/kendo-react-common';
import { gearIcon, bellIcon } from '@progress/kendo-svg-icons';
import "./header_top_bar.css";
import WorkspacePopup from "../../../components/header/popups/workspace-popup";
import ProfilePopup from "../../../components/header/popups/profile-popup";
//import logo from "../assets/img/logos/tellmobi-logo.png"
import logo from "./tellmobi-logo.png"
//import ProfilePopup from './../../../components/header/popups/pop';
class HeaderTopGlobalBar extends Component {
    // props = {}
    // onSelect = (event) => {
    //     this.props.history.push(event.item.data.route);
    // }

    render () {
        let home_screen = JSON.parse(localStorage.getItem("search"))?.setting?.home_screen ?? "dashboard";
        return (
            <div className="top_menu_bar">
                <div>
                    <div className="top_menu_left">
                        <Link to={"/" + home_screen + "/"}>
                            <div className="logo_container">
                               <img src={logo} style={{width:"120px"}}/>
                            </div>
                        </Link>
                        
                    </div>
                    <div className="top_menu_right">
                        <div className="right_inner">
                            {/*<Link to="#">*/}
                            {/*    <div className="price_span">dsp Balance <span>$0.00</span></div>*/}
                            {/*</Link>*/}
                            {/* <Link to="/settings/notifications/">
                                <SvgIcon icon={bellIcon} className="icons"/>
                            </Link> */}
                            <Link to="/settings/">
                                <SvgIcon icon={gearIcon} className="icons" size="small" />
                            </Link>
                            <WorkspacePopup />
                            <ProfilePopup />
                        </div>
                    </div>

                </div>
            </div>
        );
    }
};


export default withRouter(HeaderTopGlobalBar);