import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './../reducers/rootReducer';
import sagas from './../saga/saga';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];


function saveToLocalStorage(state) {
    try {
        const serializedState = JSON.stringify(state)
        localStorage.setItem('state', serializedState)
    } catch (e) {
        console.log(e);
    }

}

function loadFromLocalStorage() {
    try {
        const serializedState = localStorage.getItem('state')
        if (serializedState === null){return undefined} 

        return JSON.parse(serializedState)
    } catch (error) {
        console.log(error);
        return undefined
        
    }
}
export function configureStore(initialState) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const persistedState = loadFromLocalStorage();

    const store = createStore(reducers, persistedState, composeEnhancers(applyMiddleware(...middlewares)));
    store.subscribe(()=>saveToLocalStorage(store.getState()))
    sagaMiddleware.run(sagas);
    return store;
}
 

