import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import MenuButton from "../../../components/header/menuButtons/menuButtons";
import "./headerMenuBar.css";

class GeneralHeaderMenuBar extends Component {
    menuButtonClickedMethod = (e) => {
        let search = JSON.parse(localStorage.getItem("search"));
        search.groupingValues.main = e.target.id;
        search.groupingValues.group1 = "";
        search.groupingValues.group2 = "";
        localStorage.setItem("search",JSON.stringify(search));
    }
    render () {
        return (
            <div className="header_menu_bar">
                <NavLink exact to={{
                    pathname: "/dashboard/"
                }}
                    activeClassName="menu-active"
                >
                    <MenuButton
                        id="dashboard"
                        icon={"k-i-gears k-i-user"}
                        buttonText="Dashboard"
                        classNameForButton="normal_button menu_button"
                        // onClickMethod={this.menuButtonClickedMethod}
                    />
                </NavLink>
                <NavLink exact to={{
                    pathname: "/campaigns/"
                }}
                         activeClassName="menu-active"
                >
                    <MenuButton
                        id="campaign"
                        icon={"k-i-gears k-i-user"}
                        buttonText="Campaigns"
                        classNameForButton="normal_button menu_button"
                        onClickMethod={this.menuButtonClickedMethod}
                    />
                </NavLink>
                <NavLink exact to={{
                    pathname: "/offers/"
                }}
                         activeClassName="menu-active"
                >
                    <MenuButton
                        id="offers"
                        icon={"k-i-gears k-i-user"}
                        buttonText="Offers"
                        classNameForButton="normal_button menu_button"
                        onClickMethod={this.menuButtonClickedMethod}
                    />
                </NavLink>
                <NavLink exact to={{
                    pathname: "/flows/"
                }}
                         activeClassName="menu-active"
                >
                    <MenuButton
                        id="flow"
                        icon={"k-i-gears k-i-user"}
                        buttonText="Flows"
                        classNameForButton="normal_button menu_button"
                        onClickMethod={this.menuButtonClickedMethod}
                    />
                </NavLink>
                <NavLink exact to={{
                    pathname: "/affiliate-networks/"
                }}
                         activeClassName="menu-active"
                >
                    <MenuButton
                        id="affilateNetwork"
                        icon={"k-i-gears k-i-user"}
                        buttonText="Affiliate Networks"
                        classNameForButton="normal_button menu_button"
                        onClickMethod={this.menuButtonClickedMethod}
                    />
                </NavLink>
                <NavLink exact to={{
                    pathname: "/traffic-sources/"
                }}
                         activeClassName="menu-active"
                >
                    <MenuButton
                        id="trafficSource"
                        icon={"k-i-gears k-i-user"}
                        buttonText="Traffic Sources"
                        classNameForButton="normal_button menu_button"
                        onClickMethod={this.menuButtonClickedMethod}
                    />
                </NavLink>
                <NavLink exact to={{
                    pathname: "/landers/"
                }}
                         activeClassName="menu-active"
                >
                    <MenuButton
                        id="lander"
                        icon={"k-i-gears k-i-user"}
                        buttonText="Landers"
                        classNameForButton="normal_button menu_button"
                        onClickMethod={this.menuButtonClickedMethod}
                    />
                </NavLink>
            </div>
        );
    }
}

export default GeneralHeaderMenuBar;