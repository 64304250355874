import axios from 'axios';
import { BASE_URL } from '../../helpers/path';

export const loadProfileApi = async (request) => {
    const LOAD_PROFILE_API_ENDPOINT = BASE_URL + 'account/profile';
    
    const accessToken = request.accessToken;
    axiosintercepter(accessToken);
    
	const response = await axios.get(LOAD_PROFILE_API_ENDPOINT);
	return response;
}

export const updateProfileApi = async (request) => {
    const UPDATE_PROFILE_API_ENDPOINT = BASE_URL + 'account/profile';
    const accessToken = request.accessToken;
    axiosintercepter(accessToken);
    
	const response = await axios.patch(UPDATE_PROFILE_API_ENDPOINT,request.updateData);
	return response;
}

export const updatePasswordApi = async (request) => {
    const UPDATE_PASSWORD_API_ENDPOINT = BASE_URL + 'account/profile/change-password';
    const accessToken = request.accessToken;
    axiosintercepter(accessToken);
    
	const response = await axios.post(UPDATE_PASSWORD_API_ENDPOINT,request.updateData);
	return response;
}


function axiosintercepter(accessToken) {
	axios.interceptors.request.use(
		config => {
			config.headers.authorization = `Bearer ${accessToken}`;
		  return config;
		},
		error => {
		  return Promise.reject(error);
		}
	  )
}