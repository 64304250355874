import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import MenuCalendar from "../menuCalendar/index";
import GroupingDropdowns from "../header/groupingDropdowns";
import { withRouter } from "react-router-dom";
import "./styles.css";

const PageMenuBarTop = (props) => {
  const [showCalender, setShowCalender] = React.useState(false);
  return (
    <div className="header-first-row">
      <div style={{ display: "flex", alignItems: "center" ,flexWrap:"wrap"}}>
        <div style={{ position: "relative" }}>
          <input
            className="search-box normal_text black simple_gray_border"
            type="text"
            placeholder="Search Name..."
            value={props?.searchValue}
            id="inputSearchValue"
            onChange={(e) => props?.setSearchValue(e.target.value)}
          />
          {Boolean(props?.searchValue) && (
            <span
              className="k-icon k-i-check"
              style={{
                position: "absolute",
                top: "20%",
                right: "12%",
                color: "#656565",
              }}
              onClick={() => props.apply()}
            ></span>
          )}
          {Boolean(props?.searchValue) && (
            <span
              className="k-icon k-i-x"
              style={{
                position: "absolute",
                top: "20%",
                right: "5%",
                color: "darkgoldenrod",
              }}
              onClick={() => {
                props?.setSearchValue("");
                props.RemoveSearchAndReset();
              }}
            ></span>
          )}
        </div>
        <div>
          <MenuCalendar
            dates={props.dates}
            modifiedDate={props.dates.modifiedDate}
            showCalender={showCalender}
            rangeCallback={props.timeRangeApply}
            applyCallback={props.applyDates}
            setDates={props.setDates}
            toggleCalendarWindow={() => setShowCalender(!showCalender)}
            defaultTimeZone={"(GMT -12:00) Eniwetok, Kwajalein"}
          />
        </div>
        <Button
          className="menu-buttons"
          icon="k-i-icon k-i-refresh"
          onClick={() => props.refresh()}
        >
          Refresh
        </Button>
        <button
          className="k-button normal_text normal_font page_bottom_menu_button k-ml-1 k-mr-1"
          onClick={() => {
            props.setShowCharts();
            setShowCalender(false);
          }}
        >
          <span className="k-icon k-i-copy"></span>
          &nbsp; Charts
        </button>
      </div>
    </div>
  );
};

export default withRouter(PageMenuBarTop);
