import React from "react";
import {Redirect, Route} from "react-router-dom";
import auth from "../auth";

const ProtectedRoutes = ({component: Component, to: to, ...rest}) => {
    return (
        <Route
            
            {...rest}
            exact
            render = {
                (props) => {
                    if(auth.isAuthenticated()) {
                        return <Component {...props} />;
                        
                    }
                    else {
                        return <Redirect to={
                            {
                                pathname: "/login",
                                state: {
                                    from: props.location
                                }
                            }
                        } />
                    }
                }
            }
        />
    );
};

export default ProtectedRoutes;