import React from "react";
import Navbar from "../components/header/navbar/index";
import "./homePage.css"
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet"
import Features from "../components/homepage/features/index"
import Pricing from "../components/homepage/pricing/index"


export default function homePage(){
    
    function toggleCareersColor1 (){
        let preventTouchMove = false ;
        document.addEventListener("touchmove",function(){preventTouchMove=true})
    if (preventTouchMove == false) {document.getElementById("panel-heading1").classList.toggle("careershover")}  
    }
    function toggleCareersColor3 (){
        let preventTouchMove = false;
        document.addEventListener("touchmove",function(){preventTouchMove=true})
    if (preventTouchMove == false) {document.getElementById("panel-heading1").classList.add("")}
        
    }
    function addCareersColor1 (){
        let preventTouchMove = false ;
        document.addEventListener("touchmove",function(){preventTouchMove=true})
    if (preventTouchMove == false) {document.getElementById("panel-heading1").classList.add("careershover")}
        
    }
    function toggleCareersColor2 (){
        let preventTouchMove = false ;
        document.addEventListener("touchmove",function(){preventTouchMove=true})
    if (preventTouchMove == false) {document.getElementById("panel-heading2").classList.toggle("careershover")}
        
    }
    function addCareersColor2 (){
        let preventTouchMove = false;
        document.addEventListener("touchmove",function(){preventTouchMove=true})
    if (preventTouchMove == false) {document.getElementById("panel-heading2").classList.add("careershover")}
        
    }

    
    
    return(

            <div className="homepage" id="homepage">        
                <Helmet>
                    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"></meta>
                </Helmet>    
                <Navbar/>
                <section className="header" id="header">
                    <div className="header-content"  id="header-content" >
                        <div  className="header-content-right">
                            <h1>Complete Ad Tracking &<br/> Conversion Attribution Tool</h1>
                            <div style={{display:"flex",justifyContent:"center"}}>
                            <p  style={{textAlign:"left"}} className="header-hero-text">Manage, analyze, optimize & monetize your campaigns across all devices, channels & platforms. Track your campaigns in real-time & access detailed reports that help you make informed decisions to maximize your ROI.</p>
                            </div>
                            <div className="btns" id="desktop-btns">
                                <a className="button" id="Request-btn" href="mailto: demo@tellmobi.com" >Request A Demo</a>
                                <Link class="sign-up-btn" to="/signup" id="sign-up-btn">Sign Up Now</Link>
                                
                            </div>
                        </div>
                        <div>
                            <img src="./assets/img/Homepage/laptop-DB.png" className="db-img"/>
                            <div className="btns" id="mobile-btns">
                                <a className="button" id="Request-btn" href="mailto: demo@tellmobi.com" >Request A Demo</a>
                                <Link class="sign-up-btn" to="/signup" id="sign-up-btn">Sign Up Now</Link>
                                
                            </div>
                        </div>

                    </div>
                </section>

                <Features/>
                <Pricing/>

                {
                //<section className="pricing" id="pricing">
        //<h1 className="title">Pricing</h1>
       // </section>

       //onTouchEnd={toggleCareersColor2} onTouchCancel={toggleCareersColor2} id="panel-heading2"
        }
            
            <section id="jobs" >
                    <div className="inner" >
                        <h2>Careers</h2>
                        <div className="panel-group" id="career" >
                        <div className="panel panel-default" >
                            <a data-toggle="collapse" data-parent="#career" href="#career1">
                            <div className="panel-heading"  id="panel-heading1" onTouchEnd={toggleCareersColor1} onTouchCancel={toggleCareersColor1}>
                            <h4 className="panel-title">
                            <div >Full-Stack Software Engineer</div>
                            </h4>
                            </div></a>
                            <div id="career1" className="panel-collapse collapse">
                            <div className="panel-body">
                                <div className="jobs_desc" >
                                    <p><strong>Location:</strong> Remote</p>
                                    <p><strong>Job Type:</strong> Full-time</p>
                                    <h4>Overview:</h4>
                                    <p>Tellmobi is looking for a Full-Stack Software Engineer to join our
                                         dynamic and fast-paced team. The successful candidate will work 
                                         closely with our product and development teams to design, develop
                                         , and implement innovative software solutions to help manage, 
                                         track and optimize digital marketing campaigns. 
                                         The ideal candidate should have experience in both frontend and
                                          backend development with a focus on ReactJS, Redux, Saga, 
                                          Selectors, API (Request), NestJS, AWS EBS, 
                                          PostgreSQL, and Github.</p>

                                <h4>Responsibilities:</h4>
                                <div className="job_res">
                                    <ul>
                                    <li>Collaborate with product managers, designers, and developers to design and develop software solutions for managing, tracking and optimizing digital marketing campaigns.</li>
                                    <li> Develop frontend components using ReactJS, Redux, Saga, Selectors, API (Request), and Firebase.</li>
                                    <li>Develop backend services using NestJS and deploy them on AWS EBS.</li>
                                    <li>Design and manage the database using PostgreSQL.</li>
                                    <li>Collaborate with the DevOps team to ensure continuous integration and deployment using Github.</li>
                                    <li>Write clean and maintainable code that is scalable and efficient.</li>
                                    <li>Collaborate with the QA team to ensure the software meets the quality standards and is bug-free.</li>
                                    <li>Stay up-to-date with the latest industry trends and best practices in software development.</li>
                                    </ul>
                                </div>
                                <h4>Requirements:</h4>
                                <div className="job_req">
                                    <ul>
                                        <li>Bachelor’s degree in Computer Science, Software Engineering, or related field.</li>
                                        <li> At least 3 years of experience in software development.</li>
                                        <li>Strong proficiency in frontend development using ReactJS, Redux, Saga, Selectors, API (Request), and Firebase.</li>
                                        <li>Strong proficiency in backend development using NestJS and AWS EBS.</li>
                                        <li>Experience working with PostgreSQL.</li>
                                        <li>Experience working with Github.</li>
                                        <li>Strong problem-solving and analytical skills.</li>
                                        <li>Excellent verbal and written communication skills.</li>
                                        <li>Ability to work in a fast-paced environment and meet deadlines.</li>
                                        <li>Experience working in the ad tech industry is a plus.</li>
                                    </ul>
                                </div>
                                <br/>
                                <p>
                                    If you are a talented Full-Stack Software Engineer 
                                    looking for a new challenge in the ad tech industry, we want to hear from you. 
                                    Please apply with your updated resume and a cover letter.
                                </p>

                                </div>
                            </div>
                            </div>
                        </div>

                        <div className="panel panel-default">
                            <a data-toggle="collapse" data-parent="#career" href="#career3">
                            <div className="panel-heading" id="panel-heading2" onTouchEnd={toggleCareersColor2} onTouchCancel={toggleCareersColor2} >
                            <h4 className="panel-title">
                            <div>UX/UI Designer</div>
                            </h4>
                            </div></a>
                            <div id="career3" className="panel-collapse collapse">
                            <div className="panel-body">
                                <div className="jobs_desc">
                                    <p><strong>Location:</strong> Remote</p>
                                    <p><strong>Job Type:</strong> Full-time</p>

                                    <h4>Overview:</h4>
                                    <p>
                                        Tellmobi is seeking a talented UX/UI Designer to join our team. 
                                        The successful candidate will work closely with our product and development 
                                        teams to design innovative user interfaces for our software products that help manage, 
                                        track, and optimize digital marketing campaigns. The ideal candidate should have experience in both
                                        UX and UI design, with a strong portfolio that demonstrates their design skills and creativity.
                                    </p>
                                
                                
                                <h4>Responsibilities:</h4>
                                <div className="job_res">
                                    <ul>
                                        <li>Collaborate with product managers, developers, and other designers to design intuitive, user-friendly interfaces for our software products.</li>
                                        <li>Create wireframes, prototypes, and high-fidelity designs that meet the needs of our users and align with our product goals.</li>
                                        <li>Conduct user research and usability testing to gather feedback and improve the design of our products.</li>
                                        <li>Work closely with developers to ensure the designs are implemented accurately and to a high standard.</li>
                                        <li>Stay up-to-date with the latest design trends and best practices in UX and UI design.</li>

                                    </ul>
                                </div>
                                <h4>Requirements:</h4>
                                <div className="job_req">
                                    <ul>
                                        <li>Bachelor’s degree in Design, Human-Computer Interaction, or related field.</li>
                                        <li>At least 3 years of experience in UX/UI design.</li>
                                        <li> Strong proficiency in design tools such as Sketch, Figma, or Adobe Creative Suite.</li>
                                        <li>Experience with prototyping tools such as InVision or Marvel.</li>
                                        <li>Strong portfolio demonstrating your design skills and creativity.</li>
                                        <li>Strong problem-solving and analytical skills.</li>
                                        <li>Excellent verbal and written communication skills.</li>
                                        <li>Ability to work in a fast-paced environment and meet deadlines.</li>
                                        <li>Experience working in the ad tech industry is a plus.</li>
                                    </ul>

                                </div>
                                <br/>
                                <p>
                                If you are a talented UX/UI Designer looking for a new challenge in the ad tech industry,
                                 we want to hear from you. Please apply with your updated resume, portfolio, and a cover letter.
                                </p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="email_us_sec transition_small">
                        <a href="mailto:careers@tellmobi.com">Email us your resume</a>
                        </div>
                    </div>
            </section>
                
                
                <footer id="contact-us">
                    <div className="inner">
                        <div className="left">
                     
                            <img src="./assets/img/logos/logo.png" style={{width:"60px"}}/>


                            <h4>Digital Marketing Made Simple</h4>
                        </div>
                        
                        
                        <div className="right" style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >
                        <a className="mail_to_btn" style={{width:"155px"}} href="mailto:contact@tellmobi.com"><i class="fa fa-envelope"></i>&nbsp;&nbsp;Contact Us</a>
                        <div className="social-icons">
                        <a class="social_links" href="https://www.facebook.com/Tellmobi" target="_blank"><i class="fa-brands fa-facebook-f"></i></a>
                        <a class="social_links" href="https://www.linkedin.com/company/tellmobi" target="_blank"><i class="fa-brands fa-linkedin-in"></i></a>
                        <a class="social_links" href="https://www.youtube.com/@tellmobi" target="_blank"><i class="fa-brands fa-youtube"></i></a>
                        
                        </div>
                        </div>
                    </div>

                        <div className="copy"><div>Copyright © 2023 Tellmobi. All rights reserved.</div><a href="https://nmfmedia.com/" target="_blank"> Part of NMF Media Ltd.</a></div>
                        

                </footer>



    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"/>


            </div>
            )
}