import produce from "immer";
import {
    LOAD_COUNTRY, LOAD_COUNTRY_SUCCESS, LOAD_COUNTRY_FAILED,
    POST_CAMPAIGN_DATA, POST_CAMPAIGN_DATA_SUCCESS, POST_CAMPAIGN_DATA_FAILED,
    LOAD_ALL_CAMPAIGNS_DATA, LOAD_ALL_CAMPAIGNS_DATA_SUCCESS, LOAD_ALL_CAMPAIGNS_DATA_FAILED,
    LOAD_EDIT_CAMPAIGN_DATA, LOAD_EDIT_CAMPAIGN_DATA_SUCCESS, LOAD_EDIT_CAMPAIGN_DATA_FAILED,
    EDIT_CAMPAIGN_DIALOGUE_CLOSED,
    SEND_EDIT_CAMPAIGN_DATA, SEND_EDIT_CAMPAIGN_DATA_SUCCESS, SEND_EDIT_CAMPAIGN_DATA_FAILED,
    DELETE_CAMPAIGN_DATA, DELETE_CAMPAIGN_DATA_SUCCESS, DELETE_CAMPAIGN_DATA_FAILED,
    LOAD_TOKEN_TAGS, LOAD_TOKEN_TAGS_SUCCESS, LOAD_TOKEN_TAGS_FAILED,
    LOAD_SEARCH_TAGS, LOAD_SEARCH_TAGS_SUCCESS, LOAD_SEARCH_TAGS_FAILED,
    ARCHIVE_CAMPAIGN_DATA, ARCHIVE_CAMPAIGN_DATA_SUCCESS, ARCHIVE_CAMPAIGN_DATA_FAILED,
    GET_CHARTS_DATA, GET_CHARTS_DATA_SUCCESS, GET_CHARTS_DATA_FAILED,
    TIME_ZONES_DATA, TIME_ZONES_DATA_SUCCESS, TIME_ZONES_DATA_FAILED,
    MANUAL_COST_UPDATE_DATA, MANUAL_COST_UPDATE_DATA_SUCCESS, MANUAL_COST_UPDATE_DATA_FAILED,
    GET_COST_CURRENCY_DATA, GET_COST_CURRENCY_DATA_SUCCESS, GET_COST_CURRENCY_DATA_FAILED
} from "./constants";


export const initialCampaignsState = {
    loading: false,
    success: false,
    error: false,
    countriesListReceived: null,
    tokenTagsListReceived: null,
    searchTagsListReceived: null,
    isTokenTagsListReceived: false,
    isSearchTagsListReceived: false,
    isNewCampaignDataSent: false,
    newCampaignSuccessResponse: null,
    isAllCampaignsLoaded: false,
    isPatchedCampaignDataSent: false,
    patchedCampaignSuccessResponse: null,
    isDeleteCampaignDataSent: false,
    isArchiveCampaignDataSent: false,
    allCampaignsDataLoaded: [],
    editCampaignDataReceived: null,
    timeZonesData: null,
    manualCostUpdateSuccess: false,
    manualCostCurrencyList: null,
    archiveDeleteResponse: null,
    charts: {
        isChartsDataLoaded: false,
        allChartsDataLoaded: null
    }
};

const campaignsReducer = (state= initialCampaignsState, action) => produce(state, (draft => {
    switch (action.type) {
        case LOAD_COUNTRY:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case LOAD_COUNTRY_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.countriesListReceived = action.payload;
            return draft;
        case LOAD_COUNTRY_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            return draft;


        case LOAD_TOKEN_TAGS:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case LOAD_TOKEN_TAGS_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.tokenTagsListReceived = action.payload;
            draft.isTokenTagsListReceived = true;
            return draft;
        case LOAD_TOKEN_TAGS_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            return draft;

        case LOAD_SEARCH_TAGS:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case LOAD_SEARCH_TAGS_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.searchTagsListReceived = action.payload;
            draft.isSearchTagsListReceived = true;
            return draft;
        case LOAD_SEARCH_TAGS_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            return draft;


        case POST_CAMPAIGN_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.isNewCampaignDataSent = false;
            return draft;
        case POST_CAMPAIGN_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            // draft.allCampaignsDataLoaded = null;
            draft.editCampaignDataReceived = null;
            draft.isNewCampaignDataSent = true;
            draft.newCampaignSuccessResponse = action.payload;
            draft.isAllCampaignsLoaded = false;
            draft.isPatchedCampaignDataSent = false;
            draft.patchedCampaignSuccessResponse = null;
            draft.isDeleteCampaignDataSent = false;
            draft.isArchiveCampaignDataSent = false;
            return draft;
        case POST_CAMPAIGN_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            draft.isNewCampaignDataSent = false;
            draft.newPreCampaignDataSent = false;
            return draft;

        case LOAD_ALL_CAMPAIGNS_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.allCampaignsDataLoaded = null;
            return draft;
        case LOAD_ALL_CAMPAIGNS_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.allCampaignsDataLoaded = action.payload;
            draft.editCampaignDataReceived = null;
            draft.isNewCampaignDataSent = false;
            draft.newCampaignSuccessResponse = null;
            draft.isAllCampaignsLoaded = true;
            draft.isPatchedCampaignDataSent = false;
            draft.patchedCampaignSuccessResponse = null;
            draft.isDeleteCampaignDataSent = false;
            draft.isArchiveCampaignDataSent = false;
            draft.charts.isChartsDataLoaded = false;
            draft.charts.allChartsDataLoaded = null;
            return draft;
        case LOAD_ALL_CAMPAIGNS_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            draft.allPreCampaignsLoaded = false;
            return draft;


        case LOAD_EDIT_CAMPAIGN_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case LOAD_EDIT_CAMPAIGN_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.editCampaignDataReceived = action.payload;
            // draft.allCampaignsDataLoaded = null;
            draft.isNewCampaignDataSent = false;
            draft.newCampaignSuccessResponse = null;
            draft.isAllCampaignsLoaded = false;
            draft.isPatchedCampaignDataSent = false;
            draft.patchedCampaignSuccessResponse = null;
            draft.isDeleteCampaignDataSent = false;
            draft.isArchiveCampaignDataSent = false;
            return draft;
        case LOAD_EDIT_CAMPAIGN_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            return draft;

        case EDIT_CAMPAIGN_DIALOGUE_CLOSED:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.editCampaignDataReceived = null;
            draft.patchedCampaignSuccessResponse = null;
            draft.allCampaignsDataLoaded = null;
            draft.isNewCampaignDataSent = false;
            draft.isAllCampaignsLoaded = false;
            draft.isPatchedCampaignDataSent = false;
            draft.isDeleteCampaignDataSent = false;
            draft.isArchiveCampaignDataSent = false;
            return draft;


        case SEND_EDIT_CAMPAIGN_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.isPatchedCampaignDataSent = false;
            return draft;
        case SEND_EDIT_CAMPAIGN_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            // draft.allCampaignsDataLoaded = null;
            draft.isNewCampaignDataSent = false;
            // draft.isAllCampaignsLoaded = false;
            // draft.allCampaignsDataLoaded = null;
            draft.newCampaignSuccessResponse = null;
            draft.isPatchedCampaignDataSent = true;
            draft.patchedCampaignSuccessResponse = action.payload;
            draft.isDeleteCampaignDataSent = false;
            draft.isArchiveCampaignDataSent = false;
            return draft;
        case SEND_EDIT_CAMPAIGN_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            draft.isPatchedCampaignDataSent = false;
            return draft;


        case DELETE_CAMPAIGN_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.archiveDeleteResponse = null;
            return draft;
        case DELETE_CAMPAIGN_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.allCampaignsDataLoaded = null;
            draft.editCampaignDataReceived = null;
            draft.isNewCampaignDataSent = false;
            draft.newCampaignSuccessResponse = null;
            draft.isAllCampaignsLoaded = false;
            draft.isPatchedCampaignDataSent = false;
            draft.patchedCampaignSuccessResponse = null;
            draft.isDeleteCampaignDataSent = true;
            draft.isArchiveCampaignDataSent = false;
            draft.archiveDeleteResponse = action.payload;
            return draft;
        case DELETE_CAMPAIGN_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            draft.archiveDeleteResponse = null;
            return draft;

        case ARCHIVE_CAMPAIGN_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.archiveDeleteResponse = null;
            return draft;
        case ARCHIVE_CAMPAIGN_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.allCampaignsDataLoaded = null;
            draft.editCampaignDataReceived = null;
            draft.isNewCampaignDataSent = false;
            draft.newCampaignSuccessResponse = null;
            draft.isAllCampaignsLoaded = false;
            draft.isPatchedCampaignDataSent = false;
            draft.patchedCampaignSuccessResponse = null;
            draft.isDeleteCampaignDataSent = false;
            draft.isArchiveCampaignDataSent = true;
            draft.archiveDeleteResponse = action.payload;
            return draft;
        case ARCHIVE_CAMPAIGN_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            draft.archiveDeleteResponse = null;
            return draft;

        case GET_CHARTS_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case GET_CHARTS_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.editCampaignDataReceived = null;
            draft.isNewCampaignDataSent = false;
            draft.newCampaignSuccessResponse = null;
            draft.isPatchedCampaignDataSent = false;
            draft.patchedCampaignSuccessResponse = null;
            draft.isDeleteCampaignDataSent = false;
            draft.isArchiveCampaignDataSent = true;
            draft.charts.isChartsDataLoaded = true;
            draft.charts.allChartsDataLoaded = action.payload;
            return draft;
        case GET_CHARTS_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            return draft;


        case TIME_ZONES_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.timeZonesData = null;
            return draft;
        case TIME_ZONES_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.timeZonesData = action.payload;
            return draft;
        case TIME_ZONES_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.timeZonesData = null;
            draft.error = action.payload.response;
            return draft;

        case MANUAL_COST_UPDATE_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.manualCostUpdateSuccess = false;
            return draft;
        case MANUAL_COST_UPDATE_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.manualCostUpdateSuccess = true;
            return draft;
        case MANUAL_COST_UPDATE_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            draft.manualCostUpdateSuccess = false;
            return draft;

        case GET_COST_CURRENCY_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.manualCostCurrencyList = null;
            return draft;
        case GET_COST_CURRENCY_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.manualCostCurrencyList = action.payload;
            return draft;
        case GET_COST_CURRENCY_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            draft.manualCostCurrencyList = null;
            return draft;

        default:
            return draft;
    }
}));

export default campaignsReducer;