import axios from 'axios';
import { BASE_URL } from '../../helpers/path';

export const loadIPUAFilterApi = async (request) => {
	const LOAD_FILTER_ENDPOINT = BASE_URL + 'ip-ua-filtering';
	const accessToken = request.accessToken;
	axiosintercepter(accessToken);
	const response = await axios.get(LOAD_FILTER_ENDPOINT);
	return response;
}

export const addIPUAFilterApi = async (request) => {
	const ADD_FILTER_ENDPOINT = BASE_URL + 'ip-ua-filtering';
	const accessToken = request.accessToken;
	axiosintercepter(accessToken);
	const response = await axios.post(ADD_FILTER_ENDPOINT, request.data);
	return response;
}

export const editIPUAFilterApi = async (request) => {
	const UPDATE_FILTER_ENDPOINT = BASE_URL + 'ip-ua-filtering';
	const accessToken = request.accessToken;
	axiosintercepter(accessToken);
	const id = request.id;
	const response = await axios.get(`${UPDATE_FILTER_ENDPOINT}/${id}`);
	return response;
}

export const deleteIPUAFilterApi = async (request) => {
	const DELETE_FILTER_ENDPOINT = BASE_URL + 'ip-ua-filtering';
	const accessToken = request.accessToken;
	axiosintercepter(accessToken);
	const response = await axios.delete(`${DELETE_FILTER_ENDPOINT}/${request.id}`);
	return response;
}



export const updateIPUAFilterApi = async (request) => {
	const UPDATE_FILTER_ENDPOINT = BASE_URL + 'ip-ua-filtering';
	const accessToken = request.accessToken;
	axiosintercepter(accessToken);
	const response = await axios.patch(`${UPDATE_FILTER_ENDPOINT}/${request.id}`,request.data);
	return response;
}

function axiosintercepter(accessToken) {
	axios.interceptors.request.use(
		config => {
			config.headers.authorization = `Bearer ${accessToken}`;
		  return config;
		},
		error => {
		  return Promise.reject(error);
		}
	  )
}