import React, { Component } from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import * as ipuaFilteringActions from "./actions";
import produce from "immer";
//import { Switch } from "@progress/kendo-react-inputs";
import Layout from "../../../layout";
import { Button } from "@progress/kendo-react-buttons";
import "./filtering.css";
import IPUAModal from './PageModal/index';
import { makeSelectToken } from "../../../registration/LoginRegister/selectors";
import { makeSelectEditFilterData, makeSelectFilterData, makeSelectIsFilterDataAddingSuccess, makeSelectIsFilterDataAddingError, makeSelectIsFilterDataError, makeSelectIsFilterDataLoaded, makeSelectIsFilterDataDeleted, makeSelectIsFilterDataUpdated } from "./selectors";
import DeletePageModal from './DeleteModal/index';
import IPUAEditModal from './EditModal/index';
import { Fade } from '@progress/kendo-react-animation';
import LoadingPanel from './../../grid/loader/loader';


class IpUaFiltering extends Component {

    state = {
        visibleFilteringDialog: false,
        visibleEditFilteringDialog: false,
        visibleDeleteFilteringDialog: false,
        visibleEditFilteringLoader: false,
        updateFilter: false,
        deleteId: null,
        editId: null,
        deletedFilterData: false,
        success_msg: "",
        error_msg: "",
        addedData: false,
        loader: false,
    };

    componentDidMount() {
        //console.log('component did mount');
        this.props.loadFilters(this.props.accessToken);
    }

    componentDidUpdate(prevProps) {
        //console.log('component did update');
        if (this.state.updateFilter) {
            setTimeout(() => {
                this.props.loadFilters(this.props.accessToken);
                this.setState(produce(draft => { draft.updateFilter = !this.state.updateFilter }))
            }, 500);
        }

        if ((prevProps.successAdded !== this.props.successAdded) && this.props.successAdded && !this.props.error) {
            this.setState(produce(draft => { draft.addedData = true }))
            this.props.loadFilters(this.props.accessToken);
            this.setState(produce(draft => { draft.success_msg = "Filtering rule has been successfully saved." }));
            this.setState(produce(draft => { draft.loader = false }));
            this.setState(produce(draft => { draft.visibleFilteringDialog = !this.state.visibleFilteringDialog }));
        }

        if ((prevProps.successDeleted !== this.props.successDeleted) && this.props.successDeleted && !this.props.error) {
            this.props.loadFilters(this.props.accessToken);
            this.setState(produce(draft => { draft.loader = false }));
            this.setState(produce(draft => { draft.deletedFilterData = true }));
            this.setState(produce(draft => { draft.success_msg = "Filter Deleted Successfully!" }));
            this.setState(produce(draft => { draft.visibleDeleteFilteringDialog = !this.state.visibleDeleteFilteringDialog }))
        }

        if ((prevProps.successUpdated !== this.props.successUpdated) && this.props.successUpdated && !this.props.error) {
            this.props.loadFilters(this.props.accessToken);
            this.setState(produce(draft => { draft.loader = false }));
            this.setState(produce(draft => { draft.deletedFilterData = true }));
            this.setState(produce(draft => { draft.success_msg = "Filter Updated Successfully!" }));
            this.setState(produce(draft => { draft.visibleEditFilteringDialog = !this.state.visibleEditFilteringDialog }))
        }

        if ((prevProps.error !== this.props.error) && this.props.error !== false) {
            this.setState(produce(draft => { draft.loader = false }));
        }

        // if (this.state.addedData === true) {
        //     setTimeout(() => {
        //         if (this.state.addedData===true && this.props.success===true) {
        //             this.setState(produce(draft => { draft.success_msg = "Filtering rule has been successfully saved." }))
        //             this.setState(produce(draft => { draft.error_msg = "" }))
        //             this.setState(produce(draft => { draft.addedData = false }))
        //         }
        //         if (this.state.addedData===true && this.props.error===true) {
        //             this.setState(produce(draft => { draft.success_msg = "" }))
        //             this.setState(produce(draft => { draft.error_msg = "There is an error adding filtering rule." }))
        //             this.setState(produce(draft => { draft.addedData = false }))
        //         }
        //     }, 200);
        //     setTimeout(() => {
        //         this.setState(produce(draft => { draft.addedData = false }))
        //         this.setState(produce(draft => { draft.error_msg = "" }))
        //         this.setState(produce(draft => { draft.success_msg = "" }))
        //     }, 5000);
        // }
        if (this.state.deletedFilterData === true && this.state.success_msg !== "") {
            setTimeout(() => {
                this.setState(produce(draft => { draft.success_msg = "" }));
            }, 3000);
            this.setState(produce(draft => { draft.deletedFilterData = false }));
        }
        if (this.state.addedData === true && this.state.success_msg !== "") {
            setTimeout(() => {
                //console.log('emptying success msg');
                this.setState(produce(draft => { draft.success_msg = "" }));
            }, 3000);
            this.setState(produce(draft => { draft.addedData = false }));
        }
    }

    openEditFilteringDialog = (e) => {
        this.setState(produce(draft => { draft.visibleEditFilteringLoader = !this.state.visibleEditFilteringLoader }));
        const editId = e.target.id;
        this.setState(produce(draft => { draft.editId = e.target.id }));
        this.props.editIpUaFilter(this.props.accessToken, editId);
        setTimeout(() => {
            this.setState(produce(draft => { draft.visibleEditFilteringDialog = !this.state.visibleEditFilteringDialog }));
            this.setState(produce(draft => { draft.visibleEditFilteringLoader = !this.state.visibleEditFilteringLoader }));
        }, 1000);
    }
    openDeleteFilteringDialogue = (e) => {
        this.setState(produce(draft => { draft.visibleEditFilteringLoader = !this.state.visibleEditFilteringLoader }));
        const deleteId = e.target.id;
        this.setState(produce(draft => { draft.deleteId = e.target.id }));
        this.props.editIpUaFilter(this.props.accessToken, deleteId);
        setTimeout(() => {
            this.setState(produce(draft => { draft.visibleDeleteFilteringDialog = !this.state.visibleDeleteFilteringDialog }))
            this.setState(produce(draft => { draft.visibleEditFilteringLoader = !this.state.visibleEditFilteringLoader }));
        }, 1000);

    }
    closeDeleteFilterDialog = () => {
        this.setState(produce(draft => { draft.visibleDeleteFilteringDialog = !this.state.visibleDeleteFilteringDialog }))
    }
    deleteFilterOnSubmit = () => {
        this.props.deleteIpUaFilter(this.props.accessToken, this.state.deleteId);
        this.setState(produce(draft => { draft.loader = true }));
    }
    openFilteringDialog = () => {
        this.setState(produce(draft => { draft.visibleFilteringDialog = !this.state.visibleFilteringDialog }))
    }
    closeEditFilterDialog = () => {
        this.setState(produce(draft => { draft.visibleEditFilteringDialog = !this.state.visibleEditFilteringDialog }))
    }

    submitData = (dataToSend) => {
        this.setState(produce(draft => { draft.loader = true }));
        this.props.addFilter(this.props.accessToken, dataToSend);
    }

    submitEditData = (dataToSend) => {
        this.setState(produce(draft => { draft.loader = true }));
        this.props.updateFilter(this.props.accessToken, this.state.editId, dataToSend);
        //this.setState(produce(draft=>{draft.updateFilter=!this.state.updateFilter}))
    }
    render() {
        return (
            <>
                <Layout>
                    <div className="page_content"></div>
                    <div className="page_modal">
                        {
                            this.state.visibleFilteringDialog &&
                            <IPUAModal
                                submitData={this.submitData}
                                openFilteringDialog={this.openFilteringDialog}
                                state={this.state}
                            />
                        }
                        {(this.state.loader || this.state.visibleEditFilteringLoader || this.props.ipuaDataReceived === null) && <LoadingPanel></LoadingPanel>}
                        {/* {this.state.visibleEditFilteringLoader && <LoadingPanel></LoadingPanel>} */}
                    </div>
                    <div className="settings_area_container">
                        <div className="small_width_page_con">
                            <div className="small_width_page_con_inner">
                                <div className="h1_con">
                                    <h1 className="h1 no_border inline_block">IP/UA Fltering</h1>
                                </div>

                                <div className="white_box_with_header_con white_bg">
                                    <div className="box">
                                        <section className="section text_align_left">
                                            <div className="add_custom_domain_row">
                                                <p className="normal_text normal_font black ml-0 pl-0">
                                                    To exclude certain traffic events from your
                                                    campaign reporting, add filtering rules based on
                                                    IP / IP range and user agent values. You can
                                                    create up to 50 filters.
                                                </p>
                                            </div>
                                        </section>
                                    </div>
                                </div>

                                {this.state.success_msg !== "" ? (
                                    <Fade enter={true} exit={true}>
                                        <p className="success_para k-mt-4 mb-10 mh-0">
                                            {this.state.success_msg}
                                        </p>
                                    </Fade>
                                ) : null}
                                {this.state.error_msg !== "" ? (
                                    <Fade enter={true} exit={true}>
                                        <p className="danger_para k-mt-4 mb-10 mh-0">
                                            {this.state.error_msg}
                                        </p>
                                    </Fade>
                                ) : null}
                                <div className="white_box_with_header_con white_bg">
                                    <div className="box">
                                        <section className="section text_align_left">
                                            <div className="add_custom_domain_row">
                                                <div className="bold_font normal_text black">
                                                    AVAILABLE FILTERS: {this.props.ipuaDataReceived && this.props.ipuaDataReceived.length} of 50
                                </div>
                                                <div className="notification-switch push">
                                                    {/* <span style={{marginRight:"8px"}}>Filtering rules</span>
                                    <Switch checked={false} name="" />
                                    <span> Disabled </span> */}
                                                </div>
                                                <Button
                                                    onClick={this.openFilteringDialog}
                                                    icon="k-i-help k-i-plus-circle"
                                                    className="theme_dark_bg_color_imp color_white_imp theme_dark_bg_color save_button add-width"
                                                >
                                                    Add
                                </Button>
                                            </div>
                                            <div className="settings_table_con">
                                                <table className="settings_table">
                                                    <tbody>
                                                        <tr>
                                                            <th>Filter name	</th>
                                                            <th>IP / IP range</th>
                                                            <th>User agent</th>
                                                            <th className="text_center">Actions</th>
                                                        </tr>
                                                        {this.props.ipuaDataReceived !== null && typeof this.props.ipuaDataReceived !== 'undefined' && this.props.ipuaDataReceived.length > 0 ?
                                                            this.props.ipuaDataReceived.map((ipuafilter) => (
                                                                <tr
                                                                    key={ipuafilter.id}
                                                                    id={ipuafilter.id}
                                                                >
                                                                    <td>
                                                                        <div className="icon_with_tooltip text_center">
                                                                            {ipuafilter.name}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="icons text_center">
                                                                            {ipuafilter.ip_address}
                                                                        </div>
                                                                    </td>
                                                                    <td>

                                                                        <div className="icons text_center">
                                                                            {ipuafilter.user_agent}
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div className="icons text_center">
                                                                            <span
                                                                                id={ipuafilter.id}
                                                                                className="k-icon k-i-edit icon_color_theme_dark"
                                                                                onClick={this.openEditFilteringDialog}
                                                                            ></span>
                                                                            {/* <EditFlowModal onClick={onHandleEditFlow} class={"icon_color_theme_dark"} titleName={"Edit flow"} btnName={""} btnIcon={"k-icon k-i-edit"}></EditFlowModal> */}

                                                                            <span
                                                                                id={ipuafilter.id}
                                                                                className="k-icon k-i-delete icon_color_theme_dark"
                                                                                onClick={this.openDeleteFilteringDialogue}
                                                                            ></span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                            :
                                                            <tr><td>No IP/UA Filters have been added yet.</td></tr>}
                                                    </tbody>
                                                </table>
                                                {this.state.visibleEditFilteringDialog &&
                                                    <IPUAEditModal
                                                        state={this.state}
                                                        closeEditFilterDialog={this.closeEditFilterDialog}
                                                        editFilterDataRecieved={this.props.editFilterDataRecieved}
                                                        accessToken={this.props.accessToken}
                                                        submitEditData={this.submitEditData}
                                                    />
                                                }


                                                {this.state.visibleDeleteFilteringDialog &&
                                                    <DeletePageModal
                                                        state={this.state}
                                                        closeDeleteFilterDialog={this.closeDeleteFilterDialog}
                                                        deleteFilterOnSubmit={this.deleteFilterOnSubmit}
                                                        editFilterDataRecieved={this.props.editFilterDataRecieved}
                                                    />}
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout>    </>
        );
    }
}

export const mapStateToProps = createStructuredSelector({
    editFilterDataRecieved: makeSelectEditFilterData(),
    ipuaDataReceived: makeSelectFilterData(),
    accessToken: makeSelectToken(),
    error: makeSelectIsFilterDataAddingError(),
    success: makeSelectIsFilterDataAddingSuccess(),
    error: makeSelectIsFilterDataError(),
    successAdded: makeSelectIsFilterDataLoaded(),
    successDeleted: makeSelectIsFilterDataDeleted(),
    successUpdated: makeSelectIsFilterDataUpdated(),

});

export function mapDispatchToProps(dispatch) {
    return {
        loadFilters: (...payload) => dispatch(ipuaFilteringActions.loadIPUA(...payload)),
        addFilter: (...payload) => dispatch(ipuaFilteringActions.addIPUA(...payload)),
        editIpUaFilter: (...payload) => dispatch(ipuaFilteringActions.editIPUA(...payload)),
        deleteIpUaFilter: (...payload) => dispatch(ipuaFilteringActions.deleteIPUA(...payload)),
        updateFilter: (...payload) => dispatch(ipuaFilteringActions.updateIPUA(...payload))
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(IpUaFiltering);

