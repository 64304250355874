import React, {Fragment} from "react";
// import { withRouter } from "react-router";
import Layout from "../../../layout";
import { RadioGroup, Checkbox } from '@progress/kendo-react-inputs';
// import { DropDownList } from '@progress/kendo-react-dropdowns';
import "./general_settings.css";
import "../settingsGlobalSherry.css";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Fade } from '@progress/kendo-react-animation';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Dialog } from '@progress/kendo-react-dialogs';
import LoadingPanel from "../../grid/loader/loader";

// const cr_time_reporting = [
//     { label: 'Visit Timestamp', value: 'Visit Timestamp' },
//     { label: 'Postback Timestamp', value: 'Postback Timestamp' }
// ];
const default_home_screen = [
    { label: 'Dashboard', value: 'dashboard' },
    { label: 'Campaign List', value: 'campaigns' },
    // { label: 'Last opened global report', value: 'Global Report' },
];

// const default_open_report = [
//     { label: 'Go to the report', value: 'Open Report' },
//     { label: 'Open in the background', value: 'Background' }
// ];

// const default_tab_in_campaign_form = [
//     { label: 'General', value: 'General' },
//     { label: 'Destination', value: 'Destination' },
//     { label: 'Last opened', value: 'Last Open' }
// ];
const GeneralSettings = (props) => {
    console.log('props settings',props.state);
    return (
        <Fragment>
            <Layout>
                {props.settingsDataReceived === null ? <LoadingPanel></LoadingPanel> :
                    <div className="settings_area_container" style={{height:"100vh"}}>
                        <div className="small_width_page_con">
                            <div className="small_width_page_con_inner">
                                <div className="h1_con">
                                    <h1 className="h1 no_border inline_block">General Settings</h1>
                                </div>
                                {
                                    props.state.success_msg !== "" ?
                                        <div className="success_msg_con">
                                            <Fade enter={true} exit={true}>
                                                <p className="success_para mb-10 mh-0">{props.state.success_msg}</p>
                                            </Fade>
                                        </div>
                                        : null
                                }
                            
                                <div className="white_box_with_header_con white_bg">
                                    <div className="box">
                                        {/* <section className="section text_align_left">
                                            <h2 className="h2">Conversion registration time reporting</h2>
                                            <RadioGroup className="normal_text normal_font black radio_group"
                                                name="time_report"
                                                defaultValue={props.state.dataToSend.time_report}
                                                value={props.state.dataToSend.time_report}
                                                data={cr_time_reporting}
                                                onChange={props.inputChangeHandler}
                                            />
                                        </section> */}
                                        <section className="section text_align_left">
                                            <h2 className="h2">Default report time zone</h2>
                                            <ComboBox
                                                className="settings-combobox"
                                                data={props.state.data}
                                                filterable={true}
                                                onFilterChange={props.filterChange}
                                                defaultValue={props.state.defaultTime}
                                                textField="name"
                                                dataItemKey="id"
                                                name="timeZoneId"
                                                value={props.state.timeZoneId}
                                                onChange={props.inputChangeHandler}
                                            />
                                        </section>
                                        <section className="section text_align_left">
                                            <h2 className="h2">Default HOME screen</h2>
                                            <RadioGroup className="normal_text normal_font black radio_group"
                                                name="home_screen"
                                                defaultValue={props.state.dataToSend.home_screen}
                                                value={props.state.dataToSend.home_screen}
                                                data={default_home_screen}
                                                onChange={props.inputChangeHandler}
                                            />
                                        </section>
                                        {/*<section className="section text_align_left">*/}
                                        {/*    <h2 className="h2">Default way to open a report</h2>*/}
                                        {/*    <RadioGroup className="normal_text normal_font black radio_group"*/}
                                        {/*        name="report_type"*/}
                                        {/*        defaultValue={props.state.dataToSend.report_type}*/}
                                        {/*        value={props.state.dataToSend.report_type}*/}
                                        {/*        data={default_open_report}*/}
                                        {/*        onChange={props.inputChangeHandler}*/}
                                        {/*    />*/}
                                        {/*</section>*/}
                                        {/*<section className="section text_align_left">*/}
                                        {/*    <h2 className="h2">Default tab in campaign form</h2>*/}
                                        {/*    <RadioGroup className="normal_text normal_font black radio_group"*/}
                                        {/*        name="campaign_form"*/}
                                        {/*        defaultValue={props.state.dataToSend.campaign_form}*/}
                                        {/*        value={props.state.dataToSend.campaign_form}*/}
                                        {/*        data={default_tab_in_campaign_form}*/}
                                        {/*        onChange={props.inputChangeHandler}*/}
                                        {/*    />*/}
                                        {/*</section>*/}
                                        {/* <section className="section text_align_left">
                                            <h2 className="h2">Simple campaign form</h2>
                                            <Checkbox className="default_checkbox normal_text normal_font black"
                                                name="simple_campaign_form"
                                                defaultChecked={props.state.dataToSend.simple_campaign_form}
                                                checked={props.state.dataToSend.simple_campaign_form}
                                                label={'Enabled'}
                                                onChange={props.inputChangeHandler}
                                            />
                                        </section> */}
                                        {/* <section className="section text_align_left">
                                        <h2 className="h2">IP Anonymization</h2>
                                        <Checkbox className="default_checkbox normal_text normal_font black" defaultChecked={false} label={'Active'} />
                                    </section> */}
                                        <section className="section text_align_left">
                                            <h2 className="h2">Default currency</h2>
                                            {/* <DropDownList
                                            data={currencies}
                                            textField="text"
                                            value={}
                                            defaultItem={defaultCurrency}
                                        /> */}
                                            <ComboBox
                                                data={props.state.currency}
                                                filterable={true}
                                                onFilterChange={props.filterCurrencyChange}
                                                defaultValue={props.state.defaultCurrency}
                                                textField="name"
                                                dataItemKey="id"
                                                name="currencyId"
                                                value={props.state.currecnyId}
                                                onChange={props.inputChangeHandler}
                                            />
                                        </section>
                                        {/* <section className="section text_align_left">
                                        <h2 className="h2">Support for GDPR consent strings</h2>
                                        <Checkbox className="default_checkbox normal_text normal_font black" id="gdpr-consent-strings" defaultChecked={false} label={'Active'} />
                                    </section> */}
                                        {
                                            props.state.change ?
                                    
                                                <div className="buttons_row text_align_right">
                                                    <Button
                                                        className="edit_button gray_button_bg_imp black profile_form_btn"
                                                        name="cancel"
                                                        onClick={props.onCancelButton}
                                                    >
                                                        Cancel
                                        </Button>
                                                    <Button
                                                        className="theme_dark_bg_color_imp color_white_imp theme_dark_bg_color save_button normal_text"
                                                        name="save"
                                                        onClick={props.onSubmit}
                                                    >
                                                        Save
                                        </Button>
                                                </div> : null
                                        }
                                        {props.state.visible &&
                                            <Dialog title={"Please confirm"} onClose={props.toggleDialog}>
                                                <p style={{ margin: "25px", textAlign: "center", color: "black" }}>You have entered changes to the General Settings. If you quit now, you <br /> will lose all input.</p>
                                                <DialogActionsBar>
                                                    <button className="k-button" onClick={props.toggleDialog}>Go back</button>
                                                    <button className="save-btn-bg-clr k-button" onClick={props.onCancelClicked}>Yes, discard changes</button>
                                                </DialogActionsBar>
                                            </Dialog>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
            </Layout>
        </Fragment>
    );
};

export default GeneralSettings;