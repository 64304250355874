import {
    LOAD_CONVERSIONS_DATA_SUCCESS,
    LOAD_CONVERSIONS_DATA_FAILED,
    LOAD_ALL_CONVERSIONS_DATA,LOAD_INIT_STATE,
    LOAD_LAST_ROWS, SEARCHED_VALUE
} from "./constants";


export const loadInitState = () => {
    return {
        type: LOAD_INIT_STATE,
    };
};

export const loadAllConversions = (payload) => {
    return {
        type: LOAD_ALL_CONVERSIONS_DATA,
        payload: payload
    };
};

export const loadConversionsDataSuccess = (loadConversionsDataSuccessPayload) => {
    return {
        type: LOAD_CONVERSIONS_DATA_SUCCESS,
        payload: loadConversionsDataSuccessPayload
    };
};

export const loadConversionsDataFailed = (error) => {
    return {
        type: LOAD_CONVERSIONS_DATA_FAILED,
        payload: error
    };
};

export const loadLastRowsFromConversion = (payload) => {
    return {
        type: LOAD_LAST_ROWS,
        payload: payload
    };
};

export const searchedValue = (payload) => {
    return {
        type: SEARCHED_VALUE,
        payload: payload
    };
};




