import {
    ADD_IP_UA_FILTERING, ADD_IP_UA_FILTERING_SUCCESS, ADD_IP_UA_FILTERING_FAILED,
    LOAD_IP_UA_FILTERING, LOAD_IP_UA_FILTERING_SUCCESS, LOAD_IP_UA_FILTERING_FAILED,
    EDIT_IP_UA_FILTERING, EDIT_IP_UA_FILTERING_SUCCESS, EDIT_IP_UA_FILTERING_FAILED,
    UPDATE_IP_UA_FILTERING, UPDATE_IP_UA_FILTERING_SUCCESS, UPDATE_IP_UA_FILTERING_FAILED,
    DELETE_IP_UA_FILTERING,DELETE_IP_UA_FILTERING_SUCCESS,DELETE_IP_UA_FILTERING_FAILED
} from "./constants";


export const loadIPUA = (accessToken) => {
    return {
        type:LOAD_IP_UA_FILTERING,
        payload:{accessToken}
    }
}

export const loadIPUASuccess = (ipuaData) => {
    return {
        type:LOAD_IP_UA_FILTERING_SUCCESS,
        payload:{ipuaData}
    }
}

export const loadIPUAFailed = (error) => {
    return {
        type:LOAD_IP_UA_FILTERING_FAILED,
        payload:{error}
    }
}

export const addIPUA = (accessToken, data) => {
    return {
        type: ADD_IP_UA_FILTERING,
        payload:{accessToken, data}
    }
}

export const addIPUASuccess = (ipuaDataAdded) => {
    return {
        type:ADD_IP_UA_FILTERING_SUCCESS,
        payload:{ipuaDataAdded}
    }
}

export const addIPUAFailed = (error) => {
    return {
        type: ADD_IP_UA_FILTERING_FAILED,
        payload: {error}
    }
}

export const editIPUA = (accessToken,id) => {
    return {
        type:EDIT_IP_UA_FILTERING,
        payload:{accessToken,id}
    }
}

export const editIPUASuccess = (editData) => {
    return {
        type: EDIT_IP_UA_FILTERING_SUCCESS,
        payload: {editData}
    }
}

export const editIPUAFailed = (error) => {
    return {
        type:EDIT_IP_UA_FILTERING_FAILED,
        payload:{error}
    }
}
export const updateIPUA = (accessToken,id,data) => {
    return {
        type:UPDATE_IP_UA_FILTERING,
        payload:{accessToken,id,data}
    }
}

export const updateIPUASuccess = (updateData) => {
    return {
        type: UPDATE_IP_UA_FILTERING_SUCCESS,
        payload: {updateData}
    }
}

export const updateIPUAFailed = (error) => {
    return {
        type:UPDATE_IP_UA_FILTERING_FAILED,
        payload:{error}
    }
}

export const deleteIPUA = (accessToken,id) => {
    return {
        type:DELETE_IP_UA_FILTERING,
        payload:{accessToken,id}
    }
}

export const deleteIPUASuccess = (deleteData) => {
    return {
        type: DELETE_IP_UA_FILTERING_SUCCESS,
        payload:{deleteData}
    }
}

export const deleteIPUAFailed = (error) => {
    return {
        type:DELETE_IP_UA_FILTERING_FAILED,
        payload:{error}
    }
}

