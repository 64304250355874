import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Layout from "../../../layout";
//import PageMenuBarTop from "./PageMenuBarTop";
import PageMenuBarTop from "../../PageMenuBarTop";
import PageMenuBarBottom from "./PageMenuBarBottom";
import "../pages.css";
import { createStructuredSelector } from "reselect";
import AddTrafficSourcesModal from "./PageModal";
import DeletePageModal from "./DeletePageModal";
import EditTrafficSourcesModal from "./EditModal";
import {
  makeSelectTrafficSourcesDataLoaded,
  makeSelectDeleteTrafficSourceDataSent,
  makeSelectArchiveTrafficSourceDataSent,
  makeSelectNewTrafficSourceDataSent,
  makeSelectPatchedTrafficSourceDataSent,
  makeSelectLoadAllData,
  makeSelectAllCustomDataReceived,
  makeSelectCustomDataReceivedById,
  makeSelectAllChartsDataLoaded,
  makeSelectIsChartsDataLoaded,
  makeSelectError,
  makeSelectArchiveDeleteResponseTS,
} from "./selectors";
import {
  loadTrafficSources,
  deleteTrafficSource,
  archiveTrafficSource,
  postTrafficSource,
} from "./actions";
import * as TSActions from "./actions";
import { makeSelectToken } from "../../../registration/LoginRegister/selectors";
import { makeSelectWorkspaceData } from "../../Settings/CollaborationTools/selectors";
import { fetchingData } from "../../Settings/CollaborationTools/actions";
import "./affiliateNetworks.css";
import produce from "immer";
import { Fade } from "@progress/kendo-react-animation";
import MenuCharts from "../../charts";
import GridClassComponent from "../../grid/grid";
import { makeSelectMoveToWorkspace } from "../../../moveToWorkspaceRedux/selectors";
import { moveToWorkspace } from "../../../moveToWorkspaceRedux/actions";
import LoadingPanel from "../../grid/loader/loader";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";

class TrafficSources extends Component {
  state = {
    visibleAddTrafficSourceDialog: false,
    visibleEditTrafficSourceDialog: false,
    visibleDeleteTrafficSourceDialog: false,
    selectedAddTrafficSourceMethod: null,
    trafficSourceDialogueNextBtnClicked: false,
    loadedTrafficSources: null,
    loadedWorkspaces: null,
    success_msg: "",
    deletedSuccessfully: false,
    archivedSuccessfully: false,
    updateState: false,
    newTSDataSentSuccessfully: false,
    patchedTSDataSentSuccessfully: false,
    pageContentHeight: "500",
    paginationSize: 10,
    selectedListReceivedFromGrid: [],
    isDuplicate: false,
    isArchive: false,
    archiveType: "",
    charts: {
      showCharts: false,
      chartsData: null,
      isChartsDataLoaded: false,
    },
    groupingValues: {
      main: "",
      group1: "",
      group2: "",
    },
    showCalendar: false,
    showColumnsModal: false,
    sorting: {
      order: "visits",
      sort: "ASC",
    },
    loader: false,
    pagination: {
      skip: 0,
      take: 50,
      page: 1,
      total: 0,
      totalPages: 1,
      blur: {
        blurFirst: true,
        blurLast: true,
        blurPrev: true,
        blurNext: true,
      },
    },
    filterStatus: "all",
    backendErrors: {
      error: false,
      errorMsg: "",
    },
    disableReport: false,
    reportCount: 0,
    archiveDeleteResponseTS: null,
    showNoRecordFound: false,
  };

  fetchWorkspaces = async () => {
    return this.props.dispatchLoadWorkspaces(this.props.accessToken);
  };
  fetchTrafficSources = () => {
    this.setState(
      produce((draft) => {
        draft.loader = true;
        draft.selectedListReceivedFromGrid = [];
      })
    );
    let search = JSON.parse(localStorage.getItem("search"));
    this.setState(
      produce((draft) => {
        draft.groupingValues = search.groupingValues;
      })
    );
    return this.props.dispatchLoadTrafficSources({
      accessToken: this.props.accessToken,
      searchQuery: JSON.parse(localStorage.getItem("search")),
      sorting: this.state.sorting,
      primaryPage: true,
    });
  };
  fetchAllCustomTrafficSources = async () => {
    return this.props.dispatchLoadAllCustomTrafficSources({
      accessToken: this.props.accessToken,
      searchQuery: JSON.parse(localStorage.getItem("search")),
    });
  };
  resetEditTrafficSourceData = async () => {
    return this.props.dispatchResetEditTrafficSourceData();
  };

  fetchCustomTrafficSourceById = async () => {
    return this.props.dispatchLoadCustomTrafficSourceById({
      accessToken: this.props.accessToken,
      customTrafficSourceId: this.state.selectedAddTrafficSourceMethod,
    });
  };
  fetchChartsData = () => {
    return this.props.dispatchLoadChartsData({
      accessToken: this.props.accessToken,
      dates: JSON.parse(localStorage.getItem("search")).modifiedDate,
    });
  };

  componentDidMount() {
    // check if main group value is not trafficSource value...
    // than set it to trafficSource value to avoid any errors.
    if (
      JSON.parse(localStorage.getItem("search")).groupingValues.main !==
      "Traffic Source"
    ) {
      let search = JSON.parse(localStorage.getItem("search"));
      search.groupingValues.main = "Traffic Source";
      localStorage.setItem("search", JSON.stringify(search));
    }
    // set initial grouping values in state to be passed on to the grid...
    // so that is does not show error grouping values of none.
    if (JSON.parse(localStorage.getItem("search"))) {
      this.setState(
        produce((draft) => {
          draft.groupingValues.main = JSON.parse(
            localStorage.getItem("search")
          ).groupingValues.main;
          draft.groupingValues.group1 = JSON.parse(
            localStorage.getItem("search")
          ).groupingValues.group1;
          draft.groupingValues.group2 = JSON.parse(
            localStorage.getItem("search")
          ).groupingValues.group2;
          draft.reportCount = JSON.parse(
            localStorage.getItem("search")
          ).report.reportCount;
          draft.filterStatus = JSON.parse(
            localStorage.getItem("search")
          ).filterStatus;
        })
      );
    }

    // fetching currencies and workspaces initially
    if (this.state.loadedWorkspaces === null) {
      this.fetchWorkspaces();
    }
    if (this.state.loadedTrafficSources === null) {
      this.fetchTrafficSources();
    }
    this.fetchAllCustomTrafficSources();
    this.setState(
      produce((draft) => {
        draft.updateState = true;
      })
    );

    // code for setting grid height initially
    let pageMenuHeight = Number(
      document.getElementById("page_menu").clientHeight
    );
    let pageFullHeight = Number(
      document.getElementsByTagName("html")[0].clientHeight
    );
    this.setState(
      produce((draft) => {
        draft.pageContentHeight = pageFullHeight - pageMenuHeight - 127;
      })
    );
    this.setState({ showNoRecordFound: true });
  }

  returnWorkspaceName = (id) => {
    for (let i = 0; i < this.state.loadedWorkspaces.length; i++) {
      if (id === this.state.loadedWorkspaces[i].id) {
        return this.state.loadedWorkspaces[i].name;
      }
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Set workspaces and currencies initial values in the state
    if (
      this.props.workspacesListReceived !== null &&
      this.props.workspacesListReceived !== undefined &&
      this.state.loadedWorkspaces === null
    ) {
      this.setState(
        produce((draft) => {
          draft.loadedWorkspaces = this.props.workspacesListReceived;
        })
      );
    }

    // Display message on adding source
    // if (this.props.newTrafficSourceDataSent && this.state.newTSDataSentSuccessfully) {
    if (
      prevProps.newTrafficSourceDataSent !==
        this.props.newTrafficSourceDataSent &&
      this.props.newTrafficSourceDataSent !== false &&
      this.state.newTSDataSentSuccessfully === true
    ) {
      this.setState(
        produce((draft) => {
          draft.success_msg = "Network added successfully";
          draft.loader = false;
          draft.trafficSourceDialogueNextBtnClicked = false;
          if (this.state.visibleAddTrafficSourceDialog === true) {
            draft.visibleAddTrafficSourceDialog =
              !this.state.visibleAddTrafficSourceDialog;
          }
          if (this.state.visibleEditTrafficSourceDialog === true) {
            draft.visibleEditTrafficSourceDialog =
              !this.state.visibleEditTrafficSourceDialog;
          }
        })
      );
      // change update state status to load fresh data
      this.setState(
        produce((draft) => {
          draft.updateState = true;
        })
      );
      this.fetchTrafficSources();
      // change newTSDataSentSuccessfully to false to that this loop runs only once
      this.setState(
        produce((draft) => {
          draft.newTSDataSentSuccessfully = false;
        })
      );
      setTimeout(() => {
        this.setState(
          produce((draft) => {
            draft.success_msg = "";
          })
        );
      }, 10000);
    }

    // Display message on updating source
    if (
      prevProps.patchedTrafficSourceDataSent !==
        this.props.patchedTrafficSourceDataSent &&
      this.props.patchedTrafficSourceDataSent === true &&
      this.state.patchedTSDataSentSuccessfully
    ) {
      this.setState(
        produce((draft) => {
          // change patchedTSDataSentSuccessfully to false to that this loop runs only once
          draft.patchedTSDataSentSuccessfully = false;
          draft.success_msg = "Network updated successfully";
          draft.visibleEditTrafficSourceDialog =
            !this.state.visibleEditTrafficSourceDialog;
          // change update state status to load fresh data
          draft.updateState = true;
        })
      );
      this.fetchTrafficSources();
      setTimeout(() => {
        this.setState(
          produce((draft) => {
            draft.success_msg = "";
          })
        );
      }, 10000);
    }

    // Display message on delete source
    // if (this.props.trafficSourceDeleted && this.state.deletedSuccessfully) {
    if (
      prevProps.trafficSourceDeleted !== this.props.trafficSourceDeleted &&
      this.props.trafficSourceDeleted === true &&
      this.state.deletedSuccessfully === true
    ) {
      this.setState(
        produce((draft) => {
          draft.loader = false;
          draft.success_msg = "Network deleted successfully";
          // change update state status to load fresh data
          draft.updateState = true;
          // change deletedSuccessfully to false to that this loop runs only once
          draft.deletedSuccessfully = false;
          draft.visibleDeleteTrafficSourceDialog =
            !this.state.visibleDeleteTrafficSourceDialog;
        })
      );
      this.toggleDeleteArchiveModal("");
      this.fetchTrafficSources();
      setTimeout(() => {
        this.setState(
          produce((draft) => {
            draft.success_msg = "";
          })
        );
      }, 10000);
    }

    // Display message on Archive source
    // if (this.props.trafficSourceArchived && this.state.archivedSuccessfully) {
    if (
      prevProps.trafficSourceArchived !== this.props.trafficSourceArchived &&
      this.props.trafficSourceArchived === true &&
      this.state.archivedSuccessfully === true
    ) {
      this.setState(
        produce((draft) => {
          draft.loader = false;
          draft.success_msg = "Traffic Source Updated successfully";
          draft.visibleDeleteTrafficSourceDialog =
            !this.state.visibleDeleteTrafficSourceDialog;
          // change update state status to load fresh data
          draft.updateState = true;
          // change deletedSuccessfully to false to that this loop runs only once
          draft.archivedSuccessfully = false;
        })
      );
      this.fetchTrafficSources();
      setTimeout(() => {
        this.setState(
          produce((draft) => {
            draft.success_msg = "";
          })
        );
      }, 10000);
    }
    // Reload the data on any update
    if (
      prevProps.trafficSourcesDataLoaded !==
        this.props.trafficSourcesDataLoaded &&
      this.props.trafficSourcesDataLoaded !== null
    ) {
      this.setState(
        produce((draft) => {
          draft.loadedTrafficSources = this.props.trafficSourcesDataLoaded;
          if (this.props.trafficSourcesDataLoaded.total) {
            draft.pagination.total =
              this.props.trafficSourcesDataLoaded.total.totalRows;
          }
          draft.loader = false;
        })
      );
      this.setTotalPagination();
      setTimeout(() => {
        this.setPaginationUI();
      }, 50);
    }
    if (prevProps.chartsAllDataLoaded !== this.props.chartsAllDataLoaded) {
      this.setState(
        produce((draft) => {
          draft.charts.chartsData = this.props.chartsAllDataLoaded;
        })
      );
    }

    if (
      prevProps.isMovedToWorkspace !== this.props.isMovedToWorkspace &&
      this.props.isMovedToWorkspace === true
    ) {
      this.fetchTrafficSources();
      this.setState(
        produce((draft) => {
          draft.loader = false;
        })
      );
    }

    // Check the error
    // console.log("Error, ", Error);
    if (prevProps.Error !== this.props.Error && this.props.Error !== false) {
      // console.log("Error, ", Error);
      // console.log("Error message in index file: ", this.props.Error.message ? this.props.Error.message : this.props.Error);
      if (this.props.Error && this.props.Error.data) {
        this.setState(
          produce((draft) => {
            draft.backendErrors.errorMsg =
              (this.props.Error.data.message
                ? this.props.Error.data.message
                : null) +
              ", " +
              (this.props.Error.data.error
                ? this.props.Error.data.error
                : null);
            draft.backendErrors.error = true;
            draft.loader = false;
          })
        );
      }
    }

    // check for archive or delete or restore message from backend
    if (
      prevProps.archiveDeleteResponseTS !==
        this.props.archiveDeleteResponseTS &&
      this.props.archiveDeleteResponseTS !== null
    ) {
      this.setState(
        produce((draft) => {
          draft.archiveDeleteResponseTS = this.props.archiveDeleteResponseTS;
        })
      );
      setTimeout(() => {
        this.setState(
          produce((draft) => {
            draft.archiveDeleteResponseTS = null;
          })
        );
      }, 10000);
    }
  }

  // toggle modal windows
  toggleTrafficSourcesDialogue = (data) => {
    this.setState(
      produce((draft) => {
        draft.loader = true;
      })
    );
    if (data === "Add_Toggle") {
      this.setState(
        produce((draft) => {
          draft.visibleAddTrafficSourceDialog =
            !this.state.visibleAddTrafficSourceDialog;
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedAddTrafficSourceMethod = null;
        })
      );
    } else if (data === "Close_Add_Toggle") {
      this.setState(
        produce((draft) => {
          draft.visibleAddTrafficSourceDialog =
            !this.state.visibleAddTrafficSourceDialog;
        })
      );
      this.setState(
        produce((draft) => {
          draft.trafficSourceDialogueNextBtnClicked = false;
        })
      );
    } else if (data === "Close_EDIT_Toggle") {
      this.setState(
        produce((draft) => {
          draft.visibleEditTrafficSourceDialog =
            !this.state.visibleEditTrafficSourceDialog;
        })
      );
      this.resetEditTrafficSourceData();
    }
    this.setState(
      produce((draft) => {
        draft.loader = false;
      })
    );
  };

  // edit functionality
  openEditTSDialog = (data) => {
    this.setState(
      produce((draft) => {
        draft.loader = true;
        draft.visibleEditTrafficSourceDialog =
          !this.state.visibleEditTrafficSourceDialog;
        draft.isDuplicate = data;
      })
    );
  };

  // Close the Modal window after data submission and then fetch the traffic sources again.
  closeTrafficSourcesDialogueAfterSubmission = () => {
    this.setState(
      produce((draft) => {
        draft.loader = true;
        draft.newTSDataSentSuccessfully = true;
      })
    );
  };

  closeEditTrafficSourcesDialogueAfterSubmission = (data) => {
    this.setState(
      produce((draft) => {
        draft.loader = true;
      })
    );
    if (data === "Duplicate") {
      this.setState(
        produce((draft) => {
          draft.trafficSourceDialogueNextBtnClicked = false;
          draft.newTSDataSentSuccessfully = true;
          // draft.visibleEditTrafficSourceDialog = !this.state.visibleEditTrafficSourceDialog;
        })
      );
    } else {
      this.setState(
        produce((draft) => {
          draft.patchedTSDataSentSuccessfully = true;
          // draft.visibleEditTrafficSourceDialog = !this.state.visibleEditTrafficSourceDialog;
        })
      );
    }
    setTimeout(() => {
      this.setState(
        produce((draft) => {
          draft.loader = false;
        })
      );
    }, 1000);
  };

  // Add or edit selection
  selectTrafficSource = (e) => {
    this.setState({
      ...this.state,
      selectedAddTrafficSourceMethod: e.target.id,
    });
    document.getElementById(e.target.id).style.border = "1px solid #e0358b";
    if (this.state.selectedAddTrafficSourceMethod !== null) {
      document.getElementById(
        this.state.selectedAddTrafficSourceMethod
      ).style.border = "1px solid white";
    }
  };
  trafficSourceDialogueNextClicked = () => {
    if (this.state.selectedAddTrafficSourceMethod !== "custom-traffic-source") {
      this.fetchCustomTrafficSourceById();
    }
    this.setState(
      produce((draft) => {
        draft.trafficSourceDialogueNextBtnClicked = true;
      })
    );
    this.toggleLoader();
  };

  sendSearchRequest = () => {
    let search = JSON.parse(localStorage.getItem("search"));
    search.pagination.skip = 0;
    if (
      search.groupingValues.main === "conversion" ||
      search.groupingValues.main === "months" ||
      search.groupingValues.main === "days" ||
      search.groupingValues.main === "hours"
    ) {
      this.setState(
        produce((draft) => {
          draft.disableReport = true;
        })
      );
    } else {
      this.setState(
        produce((draft) => {
          draft.disableReport = false;
        })
      );
    }
    localStorage.setItem("search", JSON.stringify(search));
    this.setState(
      produce((draft) => {
        draft.pagination.page = 1;
      })
    );
    this.setPaginationUI();
    this.fetchTrafficSources();
    this.fetchChartsData();
    this.setState(
      produce((draft) => {
        draft.isAllCampaignsLoaded = false;
        draft.showCalendar = false;
      })
    );
  };
  showCharts = () => {
    if (this.state.charts.showCharts !== true) {
      this.fetchChartsData();
    }
    this.setState(
      produce((draft) => {
        draft.charts.showCharts = !this.state.charts.showCharts;
        draft.showCalendar = false;
        draft.charts.isChartsDataLoaded = !this.state.charts.isChartsDataLoaded;
      })
    );
  };

  // Delete functionality
  deleteTSNetwork = () => {
    let ids = [];
    for (let i = 0; i < this.state.selectedListReceivedFromGrid.length; i++) {
      ids.push(this.state.selectedListReceivedFromGrid[i].id);
    }
    return this.props.dispatchDeleteTrafficSource({
      accessToken: this.props.accessToken,
      networkId: ids,
    });
  };
  deleteTSNetworkOnSubmit = () => {
    this.deleteTSNetwork();
    this.setState(
      produce((draft) => {
        draft.deletedSuccessfully = true;
        draft.loader = true;
      })
    );
  };

  toggleDeleteArchiveModal = (data) => {
    // this.setState(produce(draft => { draft.loader = true }));
    this.setState(
      produce((draft) => {
        draft.visibleDeleteTrafficSourceDialog =
          !this.state.visibleDeleteTrafficSourceDialog;
        if (data === "Archive") {
          draft.archiveType = "Archive";
        } else if (data === "Delete") {
          draft.archiveType = "Delete";
        } else if (data === "Restore") {
          draft.archiveType = "Restore";
        } else if (data === "Close") {
          draft.archiveType = "";
        }
      })
    );
  };

  archiveTrafficSourceHandler = (data) => {
    this.setState(
      produce((draft) => {
        draft.loader = true;
        draft.archivedSuccessfully = true;
      })
    );
    let ids = [];
    for (let i = 0; i < this.state.selectedListReceivedFromGrid.length; i++) {
      ids.push(this.state.selectedListReceivedFromGrid[i].id);
    }
    this.props.dispatchArchiveTrafficSource({
      accessToken: this.props.accessToken,
      networkId: ids,
      value: data === "Archive" ? false : true,
    });
  };

  gridCheckedList = (list) => {
    this.setState(
      produce((draft) => {
        draft.selectedListReceivedFromGrid = list;
      })
    );
  };

  toggleCalendarWindow = () => {
    this.setState(
      produce((draft) => {
        draft.showCalendar = !this.state.showCalendar;
      })
    );
  };
  moveToWorkspaceMethod = (workspaceId) => {
    this.setState(
      produce((draft) => {
        draft.loader = true;
      })
    );
    let selectedList = [...this.state.selectedListReceivedFromGrid];
    let idStringList = [];
    for (let i = 0; i < this.state.selectedListReceivedFromGrid.length; i++) {
      idStringList.push(selectedList[i].id);
    }
    this.props.dispatchMoveToWorkspace({
      accessToken: this.props.accessToken,
      query: {
        type: "trafficSource",
        typeIdStringList: idStringList.join(","),
        workspaceId: workspaceId,
      },
    });
    // setTimeout(() => {
    //     this.setState(produce(draft => { draft.loader = false }));
    // }, 1000);
  };

  toggleColumnsModal = () => {
    this.setState(
      produce((draft) => {
        draft.showColumnsModal = !this.state.showColumnsModal;
      })
    );
  };
  changeSorting = (data) => {
    let sorting = { ...this.state.sorting };
    if (String(sorting.order) === String(data) && sorting.sort === "ASC") {
      this.setState(
        produce((draft) => {
          draft.sorting.sort = "DESC";
        })
      );
    } else if (
      String(sorting.order) === String(data) &&
      sorting.sort === "DESC"
    ) {
      this.setState(
        produce((draft) => {
          draft.sorting.sort = "ASC";
        })
      );
    } else if (String(sorting.order) !== String(data)) {
      this.setState(
        produce((draft) => {
          draft.sorting.order = data;
          draft.sorting.sort = "ASC";
        })
      );
    }
    setTimeout(() => {
      this.fetchTrafficSources();
    }, 50);
  };

  setTotalPagination = () => {
    if (this.props.trafficSourcesDataLoaded.total) {
      if (Number(this.props.trafficSourcesDataLoaded.total.totalRows) > 0) {
        let totalRows = Number(
          this.props.trafficSourcesDataLoaded.total.totalRows
        );
        if (totalRows <= this.state.pagination.take) {
          this.setState(
            produce((draft) => {
              draft.pagination.totalPages = 1;
            })
          );
        } else {
          if (totalRows % Number(this.state.pagination.take) > 0) {
            this.setState(
              produce((draft) => {
                draft.pagination.totalPages =
                  Math.floor(totalRows / this.state.pagination.take) + 1;
              })
            );
          } else {
            this.setState(
              produce((draft) => {
                draft.pagination.totalPages =
                  totalRows / this.state.pagination.take;
              })
            );
          }
        }
      } else {
        this.setState(
          produce((draft) => {
            draft.pagination.totalPages = 1;
          })
        );
      }
    }
  };
  setPaginationUI = () => {
    if (Number(this.state.pagination.totalPages) === 1) {
      this.setState(
        produce((draft) => {
          draft.pagination.blur.blurFirst = true;
          draft.pagination.blur.blurPrev = true;
          draft.pagination.blur.blurNext = true;
          draft.pagination.blur.blurLast = true;
        })
      );
    } else if (
      this.state.pagination.page === 1 &&
      this.state.pagination.totalPages > 1
    ) {
      this.setState(
        produce((draft) => {
          draft.pagination.blur.blurFirst = true;
          draft.pagination.blur.blurPrev = true;
          draft.pagination.blur.blurNext = false;
          draft.pagination.blur.blurLast = false;
        })
      );
    } else if (
      this.state.pagination.page !== 1 &&
      this.state.pagination.totalPages > 1 &&
      this.state.pagination.page < this.state.pagination.totalPages
    ) {
      this.setState(
        produce((draft) => {
          draft.pagination.blur.blurFirst = false;
          draft.pagination.blur.blurPrev = false;
          draft.pagination.blur.blurNext = false;
          draft.pagination.blur.blurLast = false;
        })
      );
    } else if (
      this.state.pagination.page !== 1 &&
      this.state.pagination.totalPages > 1 &&
      this.state.pagination.page === this.state.pagination.totalPages
    ) {
      this.setState(
        produce((draft) => {
          draft.pagination.blur.blurFirst = false;
          draft.pagination.blur.blurPrev = false;
          draft.pagination.blur.blurNext = true;
          draft.pagination.blur.blurLast = true;
        })
      );
    }
  };

  changePagination = (data) => {
    let search = JSON.parse(localStorage.getItem("search"));
    if (data === "first") {
      this.setState(
        produce((draft) => {
          draft.pagination.skip = 0;
          draft.pagination.page = 1;
        })
      );
      search.pagination.skip = 0;
    } else if (data === "prev") {
      let dataToSkip = this.state.pagination.skip - this.state.pagination.take;
      this.setState(
        produce((draft) => {
          draft.pagination.skip = dataToSkip;
          draft.pagination.page -= 1;
        })
      );
      search.pagination.skip = dataToSkip;
    } else if (data === "next") {
      let dataToSkip = this.state.pagination.skip + this.state.pagination.take;
      this.setState(
        produce((draft) => {
          draft.pagination.skip = dataToSkip;
          draft.pagination.page += 1;
        })
      );
      search.pagination.skip = dataToSkip;
    } else if (data === "last") {
      let dataToSkip =
        (this.state.pagination.totalPages - 1) * this.state.pagination.take;
      this.setState(
        produce((draft) => {
          draft.pagination.skip = dataToSkip;
          draft.pagination.page = this.state.pagination.totalPages;
        })
      );
      search.pagination.skip = dataToSkip;
    }

    localStorage.setItem("search", JSON.stringify(search));
    setTimeout(() => {
      this.fetchTrafficSources();
    }, 50);
    this.setPaginationUI();
  };

  changeGridRowsNumber = (e) => {
    let search = JSON.parse(localStorage.getItem("search"));
    search.pagination.take = Number(e.target.value);
    search.pagination.skip = 0;
    this.setState(
      produce((draft) => {
        draft.pagination.skip = 0;
        draft.pagination.page = 1;
        draft.pagination.take = Number(e.target.value);
      })
    );
    localStorage.setItem("search", JSON.stringify(search));
    setTimeout(() => {
      this.fetchTrafficSources();
    }, 50);
    this.setPaginationUI();
  };

  changeFilterStatusType = (e) => {
    let search = JSON.parse(localStorage.getItem("search"));
    search.filterStatus = e.target.value;
    this.setState(
      produce((draft) => {
        draft.filterStatus = e.target.value;
      })
    );
    localStorage.setItem("search", JSON.stringify(search));
    setTimeout(() => {
      this.fetchTrafficSources();
    }, 50);
  };

  toggleLoader = () => {
    this.setState(
      produce((draft) => {
        draft.loader = !this.state.loader;
      })
    );
  };

  openNewReport = (data) => {
    let search = JSON.parse(localStorage.getItem("search"));
    if (data === "minus" && this.state.reportCount > 0) {
      search.report.reportCount = this.state.reportCount - 1;
      this.setState(
        produce((draft) => {
          draft.reportCount = this.state.reportCount - 1;
        })
      );
      localStorage.setItem("search", JSON.stringify(search));
    } else if (data === "plus") {
      let count = this.state.reportCount + 1;
      search.report["report" + count].name =
        this.state.selectedListReceivedFromGrid[0].name;
      search.report.reportCount = count;
      search.activeTab = count;
      this.setState(
        produce((draft) => {
          draft.reportCount = this.state.reportCount + 1;
        })
      );
      let startDate = search.modifiedDate.startDate;
      let endDate = search.modifiedDate.endDate;
      localStorage.setItem("search", JSON.stringify(search));
      setTimeout(() => {
        this.props.history.push({
          pathname: "/report",
          search:
            "id=" +
            this.state.selectedListReceivedFromGrid[0].id +
            "&reportType=" +
            search.groupingValues.main +
            "&startDate=" +
            startDate +
            "&endDate=" +
            endDate +
            "&new=true",
          hash: "",
        });
      }, 80);
    }
  };

  decreaseReportCount = () => {
    this.setState(
      produce((draft) => {
        draft.reportCount = Number(this.state.reportCount) - 1;
      })
    );
  };

  render() {
    return (
      <Fragment>
        <Layout
          reportCount={this.state.reportCount}
          decreaseReportCount={this.decreaseReportCount}
        >
          <div id="page_container" className="page_container">
            <div id="page_menu" className="page_menu_bar">
              <PageMenuBarTop
                sendSearchRequest={this.sendSearchRequest}
                showCharts={this.showCharts}
                toggleLoader={this.toggleLoader}
                showCalendar={this.state.showCalendar}
                toggleCalendarWindow={this.toggleCalendarWindow}
                pageType="Traffic Source"
              />
              {this.state.charts.showCharts ? (
                <ResponsiveMenuCharts
                  data={this.state.charts.chartsData}
                  dates={
                    JSON.parse(localStorage.getItem("search")).modifiedDate
                  }
                />
              ) : null}
              <PageMenuBarBottom
                toggleTrafficSourcesDialogue={this.toggleTrafficSourcesDialogue}
                loadedWorkspaces={this.state.loadedWorkspaces}
                selectedListReceivedFromGrid={
                  this.state.selectedListReceivedFromGrid
                }
                toggleDeleteArchiveModal={this.toggleDeleteArchiveModal}
                openEditTSDialog={this.openEditTSDialog}
                moveToWorkspaceMethod={this.moveToWorkspaceMethod}
                toggleColumnsModal={this.toggleColumnsModal}
                pagination={this.state.pagination}
                changePagination={this.changePagination}
                changeGridRowsNumber={this.changeGridRowsNumber}
                changeFilterStatusType={this.changeFilterStatusType}
                filterStatus={this.state.filterStatus}
                reportId={
                  this.state.selectedListReceivedFromGrid.length > 0
                    ? this.state.selectedListReceivedFromGrid[0].id
                    : ""
                }
                disable={this.state.disableReport}
                openNewReport={this.openNewReport}
                reportCount={this.state.reportCount}
              />
              <div className="page_content"></div>
            </div>
            <div
              // style={{ height: this.state.pageContentHeight }}
              style={{ height: `calc(100% - 85px - 127px)` }}
              className="temporary_affiliate_network_page_data"
            >
              <GridClassComponent
                pageType="Traffic Source"
                gridHeight={this.state.pageContentHeight}
                allDataLoaded={this.state.loadedTrafficSources}
                paginationSize={this.state.paginationSize}
                gridCheckedList={(list) => this.gridCheckedList(list)}
                groupingValues={this.state.groupingValues}
                showColumnsModal={this.state.showColumnsModal}
                toggleColumnsModal={this.toggleColumnsModal}
                sorting={this.state.sorting}
                changeSorting={this.changeSorting}
                toggleLoader={this.toggleLoader}
                selectedListFromPage={this.state.selectedListReceivedFromGrid}
                showNoRecordFound={this.state.showNoRecordFound}
              />
            </div>

            <div className="page_modal">
              {this.state.visibleAddTrafficSourceDialog && (
                <AddTrafficSourcesModal
                  state={this.state}
                  toggleTrafficSourcesDialogue={
                    this.toggleTrafficSourcesDialogue
                  }
                  closeAffiliateNetworkDialogueAfterSubmission={
                    this.closeAffiliateNetworkDialogueAfterSubmission
                  }
                  selectTrafficSource={this.selectTrafficSource}
                  trafficSourceDialogueNextClicked={
                    this.trafficSourceDialogueNextClicked
                  }
                  closeTrafficSourcesDialogueAfterSubmission={
                    this.closeTrafficSourcesDialogueAfterSubmission
                  }
                  dispatchPostTrafficSource={
                    this.props.dispatchPostTrafficSource
                  }
                  newTrafficSourceDataSent={this.props.newTrafficSourceDataSent}
                  customTrafficSourceData={
                    this.props.customTrafficSourcesDataReceived
                  }
                  customTrafficSourceDataByIdRecieved={
                    this.props.customTrafficSourceDataByIdRecieved
                  }
                  toggleLoader={this.toggleLoader}
                />
              )}
              {this.state.loader && <LoadingPanel />}
            </div>
          </div>

          {this.state.visibleEditTrafficSourceDialog && (
            <EditTrafficSourcesModal
              state={this.state}
              editTrafficSourceId={
                this.state.selectedListReceivedFromGrid[0].id
              }
              toggleTrafficSourcesDialogue={this.toggleTrafficSourcesDialogue}
              selectTrafficSource={this.selectTrafficSource}
              closeEditTrafficSourcesDialogueAfterSubmission={
                this.closeEditTrafficSourcesDialogueAfterSubmission
              }
              dispatchPostTrafficSource={this.props.dispatchPostTrafficSource}
              newTrafficSourceDataSent={this.props.newTrafficSourceDataSent}
              toggleLoader={this.toggleLoader}
            />
          )}
          {this.state.visibleDeleteTrafficSourceDialog && (
            <DeletePageModal
              state={this.state}
              toggleDeleteArchiveModal={this.toggleDeleteArchiveModal}
              deleteTSNetworkOnSubmit={this.deleteTSNetworkOnSubmit}
              archiveTrafficSourceHandler={this.archiveTrafficSourceHandler}
            />
          )}
          {this.state.backendErrors.error && (
            <NotificationGroup
              style={{
                alignItems: "flex-start",
                flexWrap: "wrap-reverse",
                left: "50%",
                zIndex: 10000000,
                top: "20px",
                transform: "translateX(-50%)",
              }}
            >
              <Fade enter={true} exit={true}>
                {this.state.backendErrors.error && (
                  <Notification
                    type={{ style: "error", icon: true }}
                    closable={true}
                    // onClose={() => this.setState({ backendErrors.error: false })}
                    onClose={() =>
                      this.setState(
                        produce((draft) => {
                          draft.backendErrors.error = false;
                        })
                      )
                    }
                  >
                    <span>{this.state.backendErrors.errorMsg}</span>
                  </Notification>
                )}
              </Fade>
            </NotificationGroup>
          )}
          {this.state.archiveDeleteResponseTS && (
            <NotificationGroup
              style={{
                alignItems: "flex-start",
                flexWrap: "wrap-reverse",
                left: "50%",
                zIndex: 10000000,
                top: "20px",
                transform: "translateX(-50%)",
              }}
            >
              <Fade enter={true} exit={true}>
                {this.state.archiveDeleteResponseTS && (
                  <Notification
                    type={{ style: "warning", icon: true }}
                    closable={true}
                    onClose={() =>
                      this.setState(
                        produce((draft) => {
                          draft.archiveDeleteResponseTS = null;
                        })
                      )
                    }
                  >
                    <span>{this.state.archiveDeleteResponseTS}</span>
                  </Notification>
                )}
              </Fade>
            </NotificationGroup>
          )}
        </Layout>
      </Fragment>
    );
  }
}

export const mapStateToProps = createStructuredSelector({
  trafficSourcesDataLoaded: makeSelectTrafficSourcesDataLoaded(),
  trafficSourceDeleted: makeSelectDeleteTrafficSourceDataSent(),
  trafficSourceArchived: makeSelectArchiveTrafficSourceDataSent(),
  accessToken: makeSelectToken(),
  newTrafficSourceDataSent: makeSelectNewTrafficSourceDataSent(),
  patchedTrafficSourceDataSent: makeSelectPatchedTrafficSourceDataSent(),
  workspacesListReceived: makeSelectWorkspaceData(),
  loadAllData: makeSelectLoadAllData(),
  customTrafficSourcesDataReceived: makeSelectAllCustomDataReceived(),
  customTrafficSourceDataByIdRecieved: makeSelectCustomDataReceivedById(),
  isChartsDataLoaded: makeSelectIsChartsDataLoaded(),
  chartsAllDataLoaded: makeSelectAllChartsDataLoaded(),
  isMovedToWorkspace: makeSelectMoveToWorkspace(),
  Error: makeSelectError(),
  archiveDeleteResponseTS: makeSelectArchiveDeleteResponseTS(),
});
export function mapDispatchToProps(dispatch) {
  return {
    dispatchPostTrafficSource: (...payload) =>
      dispatch(postTrafficSource(...payload)),
    dispatchLoadTrafficSources: (...payload) =>
      dispatch(loadTrafficSources(...payload)),
    dispatchLoadWorkspaces: (...payload) => dispatch(fetchingData(...payload)),
    dispatchDeleteTrafficSource: (...payload) =>
      dispatch(deleteTrafficSource(...payload)),
    dispatchArchiveTrafficSource: (...payload) =>
      dispatch(archiveTrafficSource(...payload)),
    dispatchLoadAllCustomTrafficSources: (...payload) =>
      dispatch(TSActions.loadAllCustomTrafficSourceData(...payload)),
    dispatchLoadCustomTrafficSourceById: (...payload) =>
      dispatch(TSActions.loadTrafficSourceDataById(...payload)),
    dispatchResetEditTrafficSourceData: () =>
      dispatch(TSActions.resetLoadedEditTrafficSourceData()),
    dispatchLoadChartsData: (...payload) =>
      dispatch(TSActions.loadTSChartsData(...payload)),
    dispatchMoveToWorkspace: (...payload) =>
      dispatch(moveToWorkspace(...payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrafficSources);

function ResponsiveMenuCharts(props) {
  const [dimensions, setDimensions] = React.useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  })
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    
}

    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)
    
}
  })
  return <>{//<div>{window.innerWidth}</div>
  }
  <MenuCharts data={props.data} dates={props.dates}/>
  </>

}