import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectProfile = (state) => state.profile || initialState;


const makeSelectProfileData = () => createSelector(selectProfile, (substate) => substate.profileData);

const makeSelectProfileDataLoading = () => createSelector(selectProfile, (substate) => substate.isProfileDataLoaded);

const makeSelectEditProfileData = () => createSelector(selectProfile, (substate) => substate.editProfileData);

const makeSelectEditProfileDataLoading = () => createSelector(selectProfile, (substate) => substate.isEditProfileDataLoaded);

const makeSelectUpdateProfileData = () => createSelector(selectProfile, (substate) => substate.updateProfileData);

const makeSelectUpdateProfileDataLoading = () => createSelector(selectProfile, (substate) => substate.isUpdateProfileDataLoaded);

const makeSelectUpdatePasswordData = () => createSelector(selectProfile, (substate) => substate.updatePassworData);

const makeSelectIsPasswordUpdated = () => createSelector(selectProfile, (substate) => substate.isPasswordUpdated);

const makeSelectProfileLoading = () => createSelector(selectProfile, (substate) => substate.loading);

const makeSelectProfileSuccess = () => createSelector(selectProfile, (substate) => substate.success);

const makeSelectProfileError = () => createSelector(selectProfile, (substate) => substate.error);

export {
    makeSelectProfileData,
    makeSelectProfileDataLoading,
    makeSelectEditProfileData,
    makeSelectEditProfileDataLoading,
    makeSelectUpdateProfileData,
    makeSelectUpdateProfileDataLoading,
    makeSelectProfileLoading,
    makeSelectProfileSuccess,
    makeSelectProfileError,
    makeSelectIsPasswordUpdated,
    makeSelectUpdatePasswordData
}