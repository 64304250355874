import React, {Component} from 'react';
import { Popup } from '@progress/kendo-react-popup';
import TooltipsCon from "../../tooltips/tooltip";
//import AuthApi from './../../../registration/LoginRegister/Signup/AuthApi';
import "./popups.css";
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import auth from "../../../auth";
import * as logout from '../../../registration/LoginRegister/actions'

class ProfilePopup extends Component {
    anchor = null;
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            all_workspaces_checkbox: true,
            anchorAlign: {
                horizontal: 'right',
                vertical: 'bottom'
            },
            popupAlign: {
                horizontal: 'right',
                vertical: 'top'
            }
        };
    }

    componentDidMount() {
        this.setState({ show: false });
    }
    showValueInConsole = event => {
        this.setState({ all_workspaces_checkbox: event.value });
    }
    render() {
        const { anchorAlign, popupAlign } = this.state;
        const handleOnClick = () => {
            this.props.logoutUser();
            localStorage.clear();
            auth.logout(() => {
                window.location.reload();
            });
        };
        return (
            <div
                className="workspace_popup_con_outer"
                ref={(button) => {
                     this.anchor = button;
                }}
            >
                <div className="workspace_popup_con">
                    <button
                        className="img_con"
                        onClick={this.onClick}
                    >
                        <img className="profile_img" src="https://www.worldfuturecouncil.org/wp-content/uploads/2020/02/dummy-profile-pic-300x300-1.png" alt="user name" />
                    </button>
                    <Popup
                        anchor={this.anchor}
                        anchorAlign={anchorAlign}
                        popupAlign={popupAlign}
                        collision={{
                            horizontal: "fit",
                            vertical: "flip"
                        }}
                        show={this.state.show}
                        popupClass={'popup-content'}
                    >
                        <div className="popup_header">
                            <div>
                                <span className="text">Account</span>
                            </div>
                            <TooltipsCon />
                        </div>
                        <div className="popup_body">
                            <span className="link_span" onClick={handleOnClick}>Sign out</span>
                        </div>
                    </Popup>
                </div>
            </div>
        );
    }
    onClick = () => {
        this.setState({ show: !this.state.show });
    }
}

export function mapDispatchToProps(dispatch) {
    return {
        logoutUser: () => dispatch(logout.logoutUser()),
    };
  }
export default withRouter(connect(null, mapDispatchToProps)(ProfilePopup));