import {
    LOAD_ALL_REPORT_DATA,
    LOAD_ALL_REPORT_DATA_SUCCESS,
    LOAD_ALL_REPORT_DATA_FAILED,

    GET_CHARTS_DATA,
    GET_CHARTS_DATA_SUCCESS,
    GET_CHARTS_DATA_FAILED,

    LOAD_SUB_LEVEL_DATA,
    LOAD_SUB_LEVEL_DATA_SUCCESS,
    LOAD_SUB_LEVEL_DATA_FAILED
} from "./constants";


// Load Report All (First level) data Actions
export const loadAllReportData = (payload) => {
    return {
        type: LOAD_ALL_REPORT_DATA,
        payload: payload
    };
};

export const loadAllReportDataSuccess = (payload) => {
    return {
        type: LOAD_ALL_REPORT_DATA_SUCCESS,
        payload: payload
    };
};

export const loadAllReportDataFailed = (error) => {
    return {
        type: LOAD_ALL_REPORT_DATA_FAILED,
        payload: error
    };
};

export const loadChartsData = (payload) => {
    return {
        type: GET_CHARTS_DATA,
        payload: payload
    };
};

export const loadChartsDataSuccess = (payload) => {
    return {
        type: GET_CHARTS_DATA_SUCCESS,
        payload: payload
    };
};

export const loadChartsDataFailed = (error) => {
    return {
        type: GET_CHARTS_DATA_FAILED,
        payload: error
    };
};


// Load sub level data for report grid
export const loadSubLevelData = (payload) => {
    return {
        type: LOAD_SUB_LEVEL_DATA,
        payload: payload
    };
};

export const loadSubLevelDataSuccess = (payload) => {
    return {
        type: LOAD_SUB_LEVEL_DATA_SUCCESS,
        payload: payload
    };
};

export const loadSubLevelDataFailed = (error) => {
    return {
        type: LOAD_SUB_LEVEL_DATA_FAILED,
        payload: error
    };
};
