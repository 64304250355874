import axios from 'axios';
import { BASE_URL } from '../../helpers/path';

export const loadAllUsersDataApi = async (request) => {
    const LOAD_USERS_DATA_API_ENDPOINT = BASE_URL + 'admin/users';
    
    const accessToken = request.accessToken;
    axiosintercepter(accessToken);
    
	const response = await axios.get(LOAD_USERS_DATA_API_ENDPOINT);
	return response;
}

export const addUserApi = async (request) => {
    const ADD_USERS_DATA_API_ENDPOINT = BASE_URL + 'admin/users';
    
    const accessToken = request.accessToken;
    axiosintercepter(accessToken);
    
	const response = await axios.post(ADD_USERS_DATA_API_ENDPOINT, request.data);
	return response;
}

export const changeUserStatusApi = async (request) => {
    const CHANGE_USER_STATUS_API_ENDPOINT = `${BASE_URL}admin/user/${request.id}/active`;
    
    const accessToken = request.accessToken;
    axiosintercepter(accessToken);
    
	const response = await axios.post(CHANGE_USER_STATUS_API_ENDPOINT, request.data);
	return response;
}

export const updateUserApi = async (request) => {
    const UPDATE_USER_API_ENDPOINT = `${BASE_URL}admin/user/${request.id}/`;
    
    const accessToken = request.accessToken;
    axiosintercepter(accessToken);
    
	const response = await axios.patch(UPDATE_USER_API_ENDPOINT, request.data);
	return response;
}

export const loadEditUserDataApi = async (request) => {
	const EDIT_USER_API_ENDPOINT = `${BASE_URL}admin/user/${request.id}`;

	const accessToken = request.accessToken;
	axiosintercepter(accessToken);

	const response = await axios.get(EDIT_USER_API_ENDPOINT);
	return response;
}

export const DeleteUserDataApi = async (request) => {
	const EDIT_USER_API_ENDPOINT = `${BASE_URL}admin/user/${request.id}`;

	const accessToken = request.accessToken;
	axiosintercepter(accessToken);

	const response = await axios.delete(EDIT_USER_API_ENDPOINT);
	return response;
}



function axiosintercepter(accessToken) {
	axios.interceptors.request.use(
		config => {
			config.headers.authorization = `Bearer ${accessToken}`;
		  return config;
		},
		error => {
		  return Promise.reject(error);
		}
	  )
}