import { call, put, takeLatest } from "redux-saga/effects";
import { loadSettingsSuccess,loadSettingsFailed,updateSettingsSuccess,updateSettingsFailed } from "./actions";

import { LOAD_SETTINGS, UPDATE_SETTINGS } from "./constants";
import { loadSettingsApi, updateSettingsApi } from "./api";

function* loadSettings(payload) {
    try {
		const response = yield call(loadSettingsApi, payload.payload);
		yield put(
			loadSettingsSuccess({ data: response.data})
		);
	  } catch (error) {
		yield put(loadSettingsFailed(error));
	  }
}

function* updateSettings(payload) {
    try {
		const response = yield call(updateSettingsApi, payload.payload);
		//console.log('response update data', response.data);
		yield put(
			updateSettingsSuccess({ data: response.data})
		);
	  } catch (error) {
		yield put(updateSettingsFailed(error));
	  }
}

export default function* SettingsSaga() {
    yield takeLatest (LOAD_SETTINGS, loadSettings);
    yield takeLatest(UPDATE_SETTINGS, updateSettings);
}