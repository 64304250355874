import { call, put, takeLatest } from "redux-saga/effects";
import { loadAllUsersSuccess, loadAllUsersFailed,addUsersSuccess,addUsersFailed,changeUserStatusFailed,changeUserStatusSuccess, updateUsersSuccess, updateUsersFailed, loadEditUserDataSuccess, loadEditUserDataFailed, DeleteDataSuccess, DeleteDataFailed } from "./actions";
import { LOAD_ALL_USERS_DATA,ADD_USER_BY_ADMIN,CHANGE_STATUS_USER , UPDATE_USER, EDIT_USER, DELETE_USER } from "./constants";
import {loadAllUsersDataApi, addUserApi, changeUserStatusApi, updateUserApi, loadEditUserDataApi, DeleteUserDataApi} from "./api";

function* loadAllUsersData(payload) {
    // console.log('payload',payload);
    try {
		const response = yield call(loadAllUsersDataApi, payload.payload);
		yield put(
			loadAllUsersSuccess(response.data)
		);
	  } catch (error) {
		yield put(loadAllUsersFailed(error));
	  }
}
function* addUser(payload) {
	console.log("Saga payload: ", payload);
	try {
		const response = yield call(addUserApi, payload.payload);
		yield put(
			addUsersSuccess(response.data)
		);
	  } catch (error) {
		yield put(addUsersFailed(error));
	  }
}

function* changeUserStatus(payload) {
	try {
		const response = yield call(changeUserStatusApi, payload.payload);
		yield put(
			changeUserStatusSuccess(response.data)
		);
	  } catch (error) {
		yield put(changeUserStatusFailed(error));
	  }
}

function* updateUser(payload) {
	try {
		const response = yield call(updateUserApi, payload.payload);
		yield put(
			updateUsersSuccess(response.data)
		);
	  } catch (error) {
		yield put(updateUsersFailed(error));
	  }
}

function* loadEditUserData(payload) {
	try {
		const response = yield call(loadEditUserDataApi, payload.payload);
		yield put(
			loadEditUserDataSuccess(response.data)
		);
	} catch (error) {
		yield put(loadEditUserDataFailed(error));
	}
}

function* DeleteUserData(payload) {
	try {
		const response = yield call(DeleteUserDataApi, payload.payload);
		console.log(response);
		yield put(
			DeleteDataSuccess(response.data)
		);
	} catch (error) {
		yield put(DeleteDataFailed(error));
	}
}

export default function* UsersSaga() {
	yield takeLatest(LOAD_ALL_USERS_DATA, loadAllUsersData);
	yield takeLatest(ADD_USER_BY_ADMIN, addUser);
	yield takeLatest(CHANGE_STATUS_USER, changeUserStatus);
	yield takeLatest(UPDATE_USER, updateUser);
	yield takeLatest(EDIT_USER, loadEditUserData);
	yield takeLatest(DELETE_USER, DeleteUserData);
}