import React from 'react';
import {Input} from "@progress/kendo-react-inputs";
import {Error} from "@progress/kendo-react-labels";

const EmailInput = (fieldRenderProps) => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
        <div>
            <Input {...others} />
            {
                visited && validationMessage &&
                (<Error>{validationMessage}</Error>)
            }
        </div>
    );
};

export default EmailInput;