import React, { Component } from 'react';
import { makeSelectToken } from '../../../registration/LoginRegister/selectors';
import GeneralSettings from './index';
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";
import * as settingsActions from "./actions";
import * as offerActions from "../../Pages/Offers/actions";
import produce from "immer";
import { makeSelectSettingData , makeSelectSettingDataUpdated} from './selectors';
import { makeSelectLoadTimeZoneData, makeSelectLoadCurrenciesList } from '../../Pages/Offers/selectors';
// import { Loader } from '@progress/kendo-react-indicators';
import { filterBy } from '@progress/kendo-data-query';
import LoadingPanel from '../../grid/loader/loader';



class Settings extends Component {
    state = { 
        dataToSend: {
            time_report: "Visit Timestamp",
            timeZoneId: "5",
            home_screen: "dashboard",
            report_type: "Open Report",
            campaign_form: "General",
            simple_campaign_form: true,
            currencyId: "1",
        },
        change: false,
        data: null,
        defaultTime: null,
        currency: null,
        defaultCurrency: null,
        success_msg: "",
        submitData: false,
        visible: false,
        statesUpdated: false,
    }
    toggleDialog = () => {
        this.setState({
            visible: !this.state.visible
        });
    }
    
    filterChange = (event) => {
        this.setState({
            data: this.filterData(event.filter)
        });
        //console.log('this.state.', this.state.data);
    }

    filterData(filter) {
        const data = this.props.timezoneDataReceived.slice();
        return filterBy(data, filter);
    }

    filterCurrencyChange = (event) => {
        this.setState({
            currency: this.filterCurrencyData(event.filter)
        });
        //console.log('this.state.', this.state.data);
    }

    filterCurrencyData(filter) {
        const currency = this.props.currencyDataReceived.slice();
        return filterBy(currency, filter);
    }

    componentDidMount() {
        //console.log('component did mount');
        if (this.props.settingsDataReceived === null) {
            this.props.loadSettings(this.props.accessToken);
        }
        if (this.props.currencyDataReceived === null) {
            this.props.loadCurrency(this.props.accessToken);
        }
        if (this.props.timezoneDataReceived === null) {
            this.props.loadTimeZone(this.props.accessToken);
        }
        setTimeout(() => {
            if (this.props.settingsDataReceived !== null && typeof this.props.settingsDataReceived !== "undefined") {
                    this.setState(produce(draft=>{draft.dataToSend = this.props.settingsDataReceived}))
            }
            else {
                this.setState(produce(draft=>{draft.dataToSend = this.state.dataToSend}))
            }
            if (this.props.timezoneDataReceived !== null) {
                //console.log('time loaded');
                this.setState(produce(draft => {draft.data = this.props.timezoneDataReceived.slice()}))
                for (let i = 0; i < this.props.timezoneDataReceived.length; i++){
                    if (this.state.dataToSend.timeZoneId === this.props.timezoneDataReceived[i].id) {
                        this.setState(produce(draft => {draft.defaultTime = this.props.timezoneDataReceived[i]}))
                    }
                }
            }
            if (this.props.currencyDataReceived !== null) {
                //console.log('currency loaded.');
                this.setState(produce(draft => { draft.currency = this.props.currencyDataReceived.slice() }))
                for (let i = 0; i < this.props.currencyDataReceived.length; i++){
                    if (this.state.dataToSend.currencyId === this.props.currencyDataReceived[i].id) {
                        this.setState(produce(draft => {draft.defaultCurrency = this.props.currencyDataReceived[i]}))
                    }
                }
            }
        }, 2000);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        //console.log('this.state.', this.state);
        //console.log('component did update');
        setTimeout(() => {
            if (this.state.submitData) {
                setTimeout(() => {
                    this.props.loadSettings(this.props.accessToken);
                }, 500);
                setTimeout(() => {
                    this.componentDidMount();
                }, 1500);
                this.setState(produce(draft => { draft.submitData = false }));
            }
        }, 100);
        if (this.state.success_msg.length > 0) {
            setTimeout(() => {
                this.setState(produce(draft => { draft.success_msg = "" }));
            }, 10000);
        }
        if ((prevProps.isSettingDataUpdated !== this.props.isSettingDataUpdated) && this.props.isSettingDataUpdated !== false) {
            let search = JSON.parse(localStorage.getItem("search"));
            search.setting.home_screen = this.state.dataToSend.home_screen.toLocaleLowerCase();
            localStorage.setItem("search", JSON.stringify(search));
            this.setState(produce(draft => { draft.success_msg = "Changes have been successfully saved. Window will re-load in 3 seconds." }));
            setTimeout(() => {
                window.location.reload();
            },3000);
        }
    }


    inputChangeHandler = (e) => {
        //console.log('e value', e);
        if (!this.state.change) {
            this.setState(produce(draft => { draft.change = true }));
        }
        if (e.target.name === "timeZoneId" && e.value!==null) {
            this.setState(produce(draft => { draft.dataToSend[e.target.name] = e.value.id }));    
        }
        if (e.target.name === "currencyId" && e.value !== null) {
            this.setState(produce(draft => { draft.dataToSend[e.target.name] = e.value.id }));    
        }
        else {
            this.setState(produce(draft => { draft.dataToSend[e.syntheticEvent.target.name] = e.value }));
        }
    }
    onCancelButton = () => {
        
        this.toggleDialog();
    }
    onCancelClicked = () => {
        if (this.state.change) {
            this.setState(produce(draft => { draft.change = false }));
        }
        this.componentDidMount();
        this.toggleDialog();
    }
    onSubmit = () => {
        this.props.updateSettings(this.props.accessToken, this.state.dataToSend);
        this.setState(produce(draft => { draft.submitData = true }));
        if (this.state.change) {
            this.setState(produce(draft => { draft.change = false }));
        }
    }
    render() { 
        return (<div>
            {this.props.settingsDataReceived!==null && this.state.data !==null && this.state.currency!==null ?
                <GeneralSettings
                    state={this.state}
                    inputChangeHandler={(e) => this.inputChangeHandler(e)}
                    onCancelButton={this.onCancelButton}
                    onSubmit={this.onSubmit}
                    filterChange={this.filterChange}
                    filterCurrencyChange={this.filterCurrencyChange}
                    toggleDialog={this.toggleDialog}
                    onCancelClicked={this.onCancelClicked}
                    settingsDataReceived={this.props.settingsDataReceived}
                /> :
                <div className='col-4'>
                    {/* <Loader size='large' type={'converging-spinner'} themeColor="info" /> */}
                    <LoadingPanel></LoadingPanel>
                </div>
            }
        </div> );
    }
}
 

export const mapStateToProps = createStructuredSelector({
    accessToken: makeSelectToken(),
    settingsDataReceived: makeSelectSettingData(),
    timezoneDataReceived: makeSelectLoadTimeZoneData(),
    currencyDataReceived: makeSelectLoadCurrenciesList(),
    isSettingDataUpdated: makeSelectSettingDataUpdated(),
    // profileDataReceived: makeSelectProfileData()

});

export function mapDispatchToProps(dispatch) {
    return {
        loadSettings: (...payload) => dispatch(settingsActions.loadSettings(...payload)),
        updateSettings: (...payload) => dispatch(settingsActions.updateSettings(...payload)),
        loadTimeZone: (...payload) => dispatch(offerActions.loadTimezone(...payload)),
        loadCurrency: (...payload) => dispatch(offerActions.loadCurrency(...payload)),
        // fetchingProfileData: (...payload) =>dispatch(profileActions.loadProfile(...payload)),
        // submitProfileData:(...payload)=>dispatch(profileActions.updateProfile(...payload))
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(Settings);