import React, {useRef, useState} from "react";

const Cname = (props) => {
    const [cnameURLCopySuccess, setCnameURLCopySuccess] = useState('');
    const cnameRef = useRef(null);

    function copyToClipboard(e) {
        cnameRef.current.select();
        document.execCommand('copy');
        e.target.focus();
        setCnameURLCopySuccess('Copied!');
    };
    return (
        <div className="cname_info">
            <p className="normal_text bold black">1. Copy the system dedicated domain name below to set as the CNAME target.</p>
            <div className="copy_group">
                <div className="input_group input_container">
                    <input
                        className="data"
                        ref={cnameRef}
                        name="conversion_tracking_code_input"
                        value={"tell.mbitrk.com"}
                    />
                    <div className="tags">
                        <span
                            className="copy"
                            onClick={copyToClipboard}
                        >Copy</span>
                    </div>
                </div>
            </div>
            <p className="normal_text bold p2 black">2. Sign in to your domain name provider, such as GoDaddy, Hostgator, Cloudflare, etc.
            </p>
            <p className="normal_text bold black">3. Create a subdomain of your custom domain.</p>
            <p className="normal_text black">Provide the subdomain host/name of your choice, e.g. <em><strong>track</strong></em>. With that example, if your custom domain is <em><strong>customdomain.com</strong></em> then your subdomain full name would be <em><strong>track.customdomain.com</strong></em>.</p>
            <p className="normal_text bold black">4. Set your systems dedicated domain that you have copied from the software as a CNAME target in your DNS setup.</p>
        </div>
    );
}

export default Cname;