import * as React from 'react';

import { Reveal } from '@progress/kendo-react-animation';

import './collapse.css'
// import { barcodeOutlineIcon } from '@progress/kendo-svg-icons';

export default class Collapse extends React.Component {
    constructor(props) {
        super(props);

        this.state = { show: false, direction: "vertical" };
    }

    onClick = () => {
        this.setState({
            show: !this.state.show
        });
    }
    onChange = (e) => {
        this.setState({
            direction: e.target.value
        })
    }

    render() {
        const { show, direction } = this.state;
        const link1 = "http://your_affmark_domain.com/postback?cid={aff_sub}";
        const link2 = "http://your_affmark_domain.com/postback?cid={aff_sub}&payout={payout}&txid={transaction_id}";
		const children = show ? (
		<div className="collapse-child">
                {this.props.id === 1 ? <p classname="child-p normal_font_p_tu">
				<b>Click URL:</b> Copy and paste it on your landing page, if the page has a single offer or rotates many in one placement.<br />
				<b>Multi-offer click URL:</b> If there is more than one offer placement on the page, copy the URL, change the value appended to the end of the click URL with a corresponding offer number, and paste all URLs on the landing page. For three offer placements on the landing page, there should be three URLs.  
				<br/><b>Examples:</b>
				<br/>http://your_affmark_domain.com/click/1; 
				<br/>http://your_affmark_domain.com/click/2; 
				<br />http://your_affmark_domain.com/click/3;
			</p> : this.props.id === 2 ? <p classname="child-p normal_font_p_tu">
                    To make the postback URL work correctly, follow the steps:<br />
                    <ol>
                        <li>Copy the postback URL to an affiliate network.</li>
                        <li>Update <b>REPLACE</b> with the clickid-specific token taken from your affiliate network; for example:<br/>
                            <span className="link">&nbsp;{ link1}</span>
                        </li>
                        <li>If you want to pass on payout and transaction id info, replace <b>OPTIONAL</b> with the payout- and txid-specific tokens; for example:
                            <span className="link">&nbsp; { link2}</span>
                        </li>
                        <li>Save the postback URL on the affiliate network side.</li>
                    </ol>
                </p>: this.props.id === 3 ?<p classname="child-p">
                    If you want to pass on payout and transaction ID info, replace <b>OPTIONAL</b> with the <b>payout</b> and <b>txid</b>  values or add specific tokens.&nbsp; &nbsp; &nbsp; The <b>cid</b> value is stored in a cookie, so conversion tracking will still work even if the parameter is missing.
                </p>: null}
		</div>) : null;

        return (
            <div className="row pointer">
                <div className="col-md-12">
                    <dl>
						<dt onClick={this.onClick}>
							<div className="flex">
							<p className="pointer" style={{marginLeft:"1.7em"}}>
								{this.props.text}
							</p>
							<div className="arrow-up-down">
								<span className={this.state.show ? "k-icon k-i-arrow-chevron-up" : "k-icon k-i-arrow-chevron-down"}></span>
								</div>
							</div>
                        </dt>                        
                    </dl>
                    <Reveal direction={direction}>
                        {children}
                    </Reveal>
                </div>
            </div>
        );
    }
}
