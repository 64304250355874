import React from "react";
import "./navbarblack.css";
import logo from "./tellmobi-logo.png"
import { useEffect } from 'react';
import { Link } from 'react-router-dom';


export default function Navbar(){
    
    function jquery () {
        const script = document.createElement("script");
    
        script.src = "https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js";
        script.async = true;
    
        document.body.appendChild(script);
    }
    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "../test.js";
        script.async = true;
      
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        }
      }, []);
      jquery ();
    
    return(
        <div className="nav-bar">     
        <header id="header" >
        <div id="toggle_icon" className="icon"> 
          <Link to="/" className="mobile_logo"><img onClick={scrollUp} src={logo} className="mobile_logo_img"/></Link>  
          <div className="nav-hidden-logo" style={{width:"min-content"}}>   <img src={logo} alt="logo" id="sticky-logo-img"/></div>
          <div className="inner transition_small">
            <div className="line"></div>
            <div className="line line_second transition_small"></div>
            <div className="line"></div>
          </div>
       
        </div>

          
          <nav>
            <div id="header-logo"> <div onClick={scrollUp}> <Link  to="/" id="header-logo-Link"  ><img src="../tellmobi-logo.png" style={{width:"120px",}} alt="logo"/></Link></div> </div>
            <div id="nav-center">
              {
              //<Link className="transition_small" to="/" >Home</Link>
              }
              <a className="transition_small" href="/#" onClick={navClose}>Platform</a>
              <a className="transition_small" href="/#features"  to="/features" onClick={navClose}>Features</a>
              <a className="transition_small" href="/#pricing" onClick={navClose}>Pricing</a>
              <a className="transition_small" href="/#jobs" to="/#jobs" onClick={navClose}>Careers</a>
              <a className="transition_small" href="/#contact-us" onClick={navClose}>Contact Us</a>
            </div>
          
          <div className="btns">
            <Link class="k-button signinbutton" id="sign-in-btn" to="/login" >Log In</Link>
            <Link class="sign-up-btn k-button signinbutton " to="/signup" id="sign-up-btn">Sign Up</Link>
          </div>
          </nav>

      </header>
      </div>  
            )
}

const navClose =  function (){
  let nav = document.getElementById("header")
  nav.classList.toggle("header_auto")
}
const scrollUp = function (event){
  let nav = document.getElementById("header")
  let header = document.getElementById("homepage")
  header.scrollIntoView({ behavior: 'smooth' });
  nav.classList.value=""

}