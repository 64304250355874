import React, { Component } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { withRouter } from "react-router";
import { connect} from "react-redux";
import * as newFlowsActions from "../actions"
import * as colloborationWorkspaceActions from "../../../Settings/CollaborationTools/actions";
import ModalForm from "./ModalForm/index";
import "./modal.css"
import { makeSelectWorkspaceData } from "../../../Settings/CollaborationTools/selectors";
import { makeSelectBrandData, makeSelectBrowserData, makeSelectCountriesData, makeSelectOperatingSystemData,makeSelectFlowsAdd,makeSelectUpdateFlow,makeSelectDeleteFlow,makeSelectArchiveFlow,makeSelectFlowError } from "../selectors";
import { createStructuredSelector } from "reselect";
import * as offerActions from "../../Offers/actions";
import * as landerActions from "../../Landers/actions";
import produce from "immer";
import { makeSelectLoadTimeZoneData, makeSelectOffersDataLoaded } from "../../Offers/selectors";
import { makeSelectAllLandersDataLoaded } from "../../Landers/selectors";
import { Error } from '@progress/kendo-react-labels';
import { ReactDOM } from 'react-dom';
import LoadingPanel from "../../../grid/loader/loader";

class FlowsModal extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
      }
    state = {
        dataToSend: {
            flow_name: "",
            workspaceId: null,
            countryId: null,
            flow_redirect_mode: "302",
            path: {
                is_default: [
                    {
                        weight:"100",
                        value: "100",
                        weight_optimization:true,
                        path_name: "New Path",
                        offerId: [],
                        landerId: [],
                        offerWeight: [],
                        landerWeight: [],
                        offerValue: [],
                        landerValue: [],
                        path_redirect_mode: "302",
                        path_destination: "Lander & Offer",
                    }
                ],
                rule: []
            }
        },
        visible: this.props.visible,
        countryName: "",
        panes: [
            { size: '47%', min: '40%', resizable: false },
            {},
        ],
        count: 0,
        onRuleClick: false,
        onFlowClick: true,
        onPathClick: false,
        onDefaultPathClick: false,
        newRuleIndex: null,
        newPathIndex: 0,
        newDefaultPathIndex: 0,
        newRulePathIndex: 0,
        offersCounter: [1],
        landersCounter: 1,
        offersData: [],
        defaultPathClickedCount: 0,
        countryValue: null,
        editClicked: false,
        newFlows: true,
        success_msg: "",
        warning_msg: "",
        landerValue: [],
        offerValue: [],
        select: "",
        editSelect: "",
        conditionsName: [[], [], [], [], [], [], [], [], [], [], [], [], [], [],[],[],[],[],[],[],[], [], [], [], [], [], [], [], [], [], [], [], [], [],[],[],[],[],[],[]],
        filteredOptions: [[], [], [], [], [], [], [], [], [], [], [], [], [], [],[],[],[],[],[],[],[],[], [], [], [], [], [], [], [], [], [], [], [], [], [],[],[],[],[],[],[]],
        selectedOptions: [[], [], [], [], [], [], [], [], [], [], [], [], [], [],[],[],[],[],[],[],[],[], [], [], [], [], [], [], [], [], [], [], [], [], [],[],[],[],[],[],[]],
        options: [
            { value: "", name: "Select options" },
            { value: "brand", name: "Brand" },
            { value: "browser", name: "Browser" },
            { value: "city", name: "City" },
            { value: "connection", name: "Connection type" },
            { value: "country", name: "Country" },
            { value: "device", name: "Device type" },
            { value: "isp", name: "ISP" },
            { value: "IP", name: "IP" },
            { value: "language", name: "Language" },
            { value: "Mobile carrier", name: "Mobile carrier" },
            { value: "operating", name: "Operating system" },
            { value: "proxy", name: "Proxy" },
            { value: "region", name: "Region" },
            { value: "Referrer", name:"Referrer"},
            { value: "Referrer domain", name: "Referrer Domain"},
            { value: "timezone", name:"Time of day"},
            { value: "weekday", name: "Weekday" },
            { value: "Custom variable 1", name: "Custom variable 1" },
            { value: "Custom variable 2", name: "Custom variable 2" },
            { value: "Custom variable 3", name: "Custom variable 3" },
            { value: "Custom variable 4", name: "Custom variable 4" },
            { value: "Custom variable 5", name: "Custom variable 5" },
            { value: "Custom variable 6", name: "Custom variable 6" },
            { value: "Custom variable 7", name: "Custom variable 7" },
            { value: "Custom variable 8", name: "Custom variable 8" },
            { value: "Custom variable 9", name: "Custom variable 9" },
            { value: "Custom variable 10", name: "Custom variable 10" },
            { value: "Custom variable 11", name: "Custom variable 11" },
            { value: "Custom variable 12", name: "Custom variable 12" },
            { value: "Custom variable 13", name: "Custom variable 13" },
            { value: "Custom variable 14", name: "Custom variable 14" },
            { value: "Custom variable 15", name: "Custom variable 15" },
            { value: "Custom variable 16", name: "Custom variable 16" },
            { value: "Custom variable 17", name: "Custom variable 17" },
            { value: "Custom variable 18", name: "Custom variable 18" },
            { value: "Custom variable 19", name: "Custom variable 19" },
            { value: "Custom variable 20", name: "Custom variable 20" },
            

        ],
        errors: {
            name: null,
            rule_name:null,
            workspaceId: null,
            trafficSourceId: null,
            landers: null,
            offers: null,
            flow: null,
            default_path_lander: null,
            default_path_offer: null,
            rule_path_lander:null,
            rule_path_offer: null,
            default_path_name: null,
            rule_path_name: null,
            isLanderExist: false,
            isOfferExist: false,
        },
        is_default: [
            {
                
                dataToMap: {
                    landers: [],
                    offers: []
                },
                counts: {
                    landerInBackend: 0,
                    landersAdded: 0,
                    offersInBackend: 0,
                    offersAdded: 0,
                    landerWeightageTotal: 0,
                    offerWeightageTotal: 0,
                },
                allLandersDataLoaded: null,
                allOffersDataLoaded: null,
            }
        ],
        defaultWeightageData: {
			pathAddedCount: 1,
			pathsTotalWeightage: 100,
        },
        ruleWeightageData: [],
        rule: [],
        loadRuleFirstTime: true,
        loadDefaultFirstTime: true,
        bgClrPathIndex: null,
        bgClrRulePathIndex: null,
        bgClrRuleIndex: null,
        search: {
            tagsListReceived: [],
            inputSearchValue: "",
            tagsSearchValue: [],
            isTagsSearchSelected: false,
            groupingValues: {
                main: "",
                group1: "",
                group2: "",
            },
            dates: {
                startDate: "",
                endDate: "",
            },
            isFiltersChanged: false,
        },
        saveBtnDisbaled: false,
        sorting: {
            order: "id",
            sort: "ASC"
        },
        sendingRequest:false,
        sorted:false,
        deleted:false,
        ruleConditionName:[[], [], [], [], [], [], [], [], [], [], [], [], [], [],[],[],[],[],[],[],[],[], [], [], [], [], [], [], [], [], [], [], [], [], [],[],[],[],[],[],[]],
        ruleNameWithCondition:"",
        from:['00','01','02','03','04','05','06','07','08','09',10,11,12,13,14,15,16,17,18,19,20,21,22,23],
        to:['00','01','02','03','04','05','06','07','08','09',10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,
            34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59],
        tempIP:"",
        tempreferrer:"",
        tempreferrerDomain:"",
        fromh:"",
        fromm:"",
        toh:"",
        tom:""
    };

    componentDidMount() {

        for (let i = 0; i < this.state.options.length; i++){
            this.state.filteredOptions[i] = this.state.options.concat();
        }

        if (this.props.countriesDataRecieved === null) {
            this.props.loadCountries(this.props.accessToken);    
        }
        if (this.props.offersDataReceived.data !== undefined && this.props.offersDataReceived.data !== null) {
            if (this.props.offersDataReceived.data === null || this.props.offersDataReceived.data.length===0) {
                this.props.loadOffers(this.props.accessToken,this.state.search,{ order: "name", sort: "DESC" },false, "short");
            }
        }
        if (this.props.landersDataReceived.data !== undefined && this.props.landersDataReceived.data !== null) {
            if (this.props.landersDataReceived.data === null || this.props.landersDataReceived.data.length ===0) {
                this.props.loadLanders({
                    accessToken: this.props.accessToken,
                    searchQuery: this.state.search,
                    sorting: { order: "name", sort: "DESC" },
                    primaryPage: false,
                    dataType: "short"
                });
            }
        }
        if (this.props.fetchingData === null) {
            this.props.fetchingData(this.props.accessToken);
        }
        if (this.props.workspaceDataReceived && this.props.countriesDataRecieved) {
            this.setState(produce(draft => { draft.dataToSend.workspaceId = this.props.workspaceDataReceived[0].id }));
            this.setState(produce(draft => { draft.dataToSend.countryId = this.props.countriesDataRecieved[98].id }));
            this.setState(produce(draft => { draft.countryName = this.props.countriesDataRecieved[98].country_name }));
            //this.setState(produce(draft => { draft.countryValue = this.props.countriesDataRecieved[0].country_name }));
        }
        if (this.state.offersData.length===0 && this.props.offersDataReceived) {
            for (let i = 0; i < this.props.offersDataReceived.length; i++){
                this.setState(produce(draft => { draft.offersData[i]=this.props.offersDataReceived[i].name}));
            }
        }
        if (this.props.offersDataReceived.data!==null && this.props.offersDataReceived.data.length!==0) {
            this.setState(produce(draft => { draft.errors.isOfferExist = true }));
        }
        if (this.props.landersDataReceived.data!==null && this.props.landersDataReceived.data.length!==0) {
            this.setState(produce(draft => { draft.errors.isLanderExist = true }));
        }
        if (this.props.timezoneDataReceived === null) {
            this.props.loadTimeZone(this.props.accessToken);
        }
    }
    componentDidUpdate(prevProps,prevState) {
        // console.log("name in state: ", this.state.dataToSend.flow_name);
        // Set All landers list in the state
        if (this.state.loadDefaultFirstTime) {
            //console.log('this.state in component did mount', this.state);
                
            if (this.props.landersDataReceived !== null && this.props.landersDataReceived.data.length > 0 && this.state.is_default[this.state.newDefaultPathIndex].allLandersDataLoaded === null ) {
                let landers_list = [];
                for (let i = 0; i < this.props.landersDataReceived.data.length; i++) {
                    landers_list.push({
                        "name": this.props.landersDataReceived.data[i].name,
                        "id": this.props.landersDataReceived.data[i].id
                    });
                }
                // set landers in backend count
                this.setState(produce(draft => { draft.is_default[draft.newDefaultPathIndex].counts.landerInBackend = landers_list.length }));
                this.setState(produce(draft => { draft.is_default[draft.newDefaultPathIndex].allLandersDataLoaded = landers_list }));
            }

                // Set All offers list in the state
                
            if (this.props.offersDataReceived !== null && this.props.offersDataReceived.data.length > 0 && this.state.is_default[this.state.newDefaultPathIndex].allOffersDataLoaded === null) {
                let offers_list = [];
                for (let i = 0; i < this.props.offersDataReceived.data.length; i++) {
                    offers_list.push({
                        "name": this.props.offersDataReceived.data[i].name,
                        "id": this.props.offersDataReceived.data[i].id
                    });
                }
                // Set offers in backend count
                this.setState(produce(draft => { draft.is_default[draft.newDefaultPathIndex].counts.offersInBackend = offers_list.length }));
                this.setState(produce(draft => { draft.is_default[draft.newDefaultPathIndex].allOffersDataLoaded = offers_list }));
            }
            this.setState(produce(draft => { draft.loadDefaultFirstTime= false }));
    }
        if (this.state.loadRuleFirstTime) {
            if (this.state.newRuleIndex !== null) {
                if (this.props.landersDataReceived !== null && this.props.landersDataReceived.data.length > 0 && this.state.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex].allLandersDataLoaded === null) {
    
                    let landers_list = [];
                    for (let i = 0; i < this.props.landersDataReceived.data.length; i++) {
                        landers_list.push({
                            "name": this.props.landersDataReceived.data[i].name,
                            "id": this.props.landersDataReceived.data[i].id
                        });
                    }
                    // set landers in backend count
                    this.setState(produce(draft => { draft.rule[draft.newRuleIndex].path[draft.newRulePathIndex].counts.landerInBackend = landers_list.length }));
                    this.setState(produce(draft => { draft.rule[draft.newRuleIndex].path[draft.newRulePathIndex].allLandersDataLoaded = landers_list }));
                }
    
                if (this.props.offersDataReceived !== null && this.props.offersDataReceived.data.length > 0 && this.state.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex].allOffersDataLoaded === null) {
                    let offers_list = [];
                    for (let i = 0; i < this.props.offersDataReceived.data.length; i++) {
                        offers_list.push({
                            "name": this.props.offersDataReceived.data[i].name,
                            "id": this.props.offersDataReceived.data[i].id
                        });
                    }
                    // Set offers in backend count
                    this.setState(produce(draft => { draft.rule[draft.newRuleIndex].path[draft.newRulePathIndex].counts.offersInBackend = offers_list.length }));
                    this.setState(produce(draft => { draft.rule[draft.newRuleIndex].path[draft.newRulePathIndex].allOffersDataLoaded = offers_list }));
                }
                this.setState(produce(draft => { draft.loadRuleFirstTime= false }));
            }
        }
        //console.log('state ka sara data load', this.state);
        if ((prevProps.flowsAddedSuccessfully !== this.props.flowsAddedSuccessfully) && !this.props.error) {
            this.toggleDialog();
            this.props.submitData();
            //this.props.loadFlows(this.props.accessToken,JSON.parse(localStorage.getItem("search")),this.state.sorting);
            this.setState(produce(draft => { draft.saveBtnDisbaled = false }));
            //this.props.loadFlows(this.props.accessToken,JSON.parse(localStorage.getItem("search")),this.state.sorting);
        }
        if ((prevProps.error !== this.props.error) && this.props.error !== false) {
            this.setState(produce(draft => { draft.saveBtnDisbaled = false }));
            this.setState(produce(draft => { draft.sendingRequest = false }));
        }
        if ((prevState.sendingRequest !== this.state.sendingRequest) && this.state.sendingRequest && !this.state.saveBtnDisbaled) {
            this.setState(produce(draft => { draft.saveBtnDisbaled = true }));
            this.props.toggleLoader();
            this.props.addNewFlows(this.props.accessToken, this.state.dataToSend);
        }
    }

    rePopulateDefaultRuleLandersOffer = () => {

        if (this.state.onDefaultPathClick) {
            // console.log('true');
            // console.log(this.state);
            if (this.props.landersDataReceived !== null && this.props.landersDataReceived.data.length > 0 && this.state.is_default[this.state.newDefaultPathIndex].allLandersDataLoaded === null) {
                // console.log('asign');
                let landers_list = [];
                for (let i = 0; i < this.props.landersDataReceived.data.length; i++) {
                    landers_list.push({
                        "name": this.props.landersDataReceived.data[i].name,
                        "id": this.props.landersDataReceived.data[i].id
                    });
                }
                // set landers in backend count
                this.setState(produce(draft => { draft.is_default[draft.newDefaultPathIndex].counts.landerInBackend = landers_list.length }));
                this.setState(produce(draft => { draft.is_default[draft.newDefaultPathIndex].allLandersDataLoaded = landers_list }));
            }
                // Set All offers list in the state
                
            if (this.props.offersDataReceived !== null && this.props.offersDataReceived.data.length > 0 && this.state.is_default[this.state.newDefaultPathIndex].allOffersDataLoaded === null) {
                let offers_list = [];
                for (let i = 0; i < this.props.offersDataReceived.data.length; i++) {
                    offers_list.push({
                        "name": this.props.offersDataReceived.data[i].name,
                        "id": this.props.offersDataReceived.data[i].id
                    });
                }
                // Set offers in backend count
                this.setState(produce(draft => { draft.is_default[draft.newDefaultPathIndex].counts.offersInBackend = offers_list.length }));
                this.setState(produce(draft => { draft.is_default[draft.newDefaultPathIndex].allOffersDataLoaded = offers_list }));
            }
        }
        
        if (this.state.newRuleIndex !== null) {
            //console.log('this.state. data in rule index', this.state.rule);
            if (this.props.landersDataReceived !== null && this.props.landersDataReceived.data.length > 0 && this.state.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex].allLandersDataLoaded === null) {

                let landers_list = [];
                for (let i = 0; i < this.props.landersDataReceived.data.length; i++) {
                    landers_list.push({
                        "name": this.props.landersDataReceived.data[i].name,
                        "id": this.props.landersDataReceived.data[i].id
                    });
                }
                // set landers in backend count
                this.setState(produce(draft => { draft.rule[draft.newRuleIndex].path[draft.newRulePathIndex].counts.landerInBackend = landers_list.length }));
                this.setState(produce(draft => { draft.rule[draft.newRuleIndex].path[draft.newRulePathIndex].allLandersDataLoaded = landers_list }));
            }

            // Set All offers list in the state
            if (this.props.offersDataReceived !== null && this.props.offersDataReceived.data.length > 0 && this.state.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex].allOffersDataLoaded === null) {
                let offers_list = [];
                for (let i = 0; i < this.props.offersDataReceived.data.length; i++) {
                    offers_list.push({
                        "name": this.props.offersDataReceived.data[i].name,
                        "id": this.props.offersDataReceived.data[i].id
                    });
                }
                // Set offers in backend count
                this.setState(produce(draft => { draft.rule[draft.newRuleIndex].path[draft.newRulePathIndex].counts.offersInBackend = offers_list.length }));
                this.setState(produce(draft => { draft.rule[draft.newRuleIndex].path[draft.newRulePathIndex].allOffersDataLoaded = offers_list }));
            }
        }
    }

    // =============================================================================================
    // =============================================================================================
    // for adding landers and offers
    addLandersAndOffersMethod = (e) => {
        if (this.state.onDefaultPathClick) {
            let type = e.target.id.split("_")[1];
            if (typeof type !== 'undefined') {
                this.setState(produce(draft => { draft.is_default[draft.newDefaultPathIndex].counts[type + "sAdded"] = (Number(this.state.is_default[this.state.newDefaultPathIndex].counts[type + "sAdded"]) + 1) }));
                let remainingData = [];
                for (let i = 0; i < this.state.is_default[this.state.newDefaultPathIndex]["all" + this.capitalizeFirstLetter(type) + "sDataLoaded"].length; i++) {
                    let itemFound = false;
                    for (let j = 0; j < this.state.dataToSend.path.is_default[this.state.newDefaultPathIndex][type + "Id"].length; j++) {
                        if (Number(this.state.is_default[this.state.newDefaultPathIndex]["all" + this.capitalizeFirstLetter(type) + "sDataLoaded"][i].id) === Number(this.state.dataToSend.path.is_default[this.state.newDefaultPathIndex][type + "Id"][j])) {
                            itemFound = true;
                        }
                    }
                    if (itemFound === false) {
                        remainingData.push(this.state.is_default[this.state.newDefaultPathIndex]["all" + this.capitalizeFirstLetter(type) + "sDataLoaded"][i]);
                    }
                }
                let data = {
                    name: type + "_" + (this.state.is_default[this.state.newDefaultPathIndex].counts[type + "sAdded"] + 1),
                    weightageNumber: 100,
                    weightagePercentage: this.calculateIndividualLanderWeightage(100, "true"),
                    options: remainingData,
                    selectedId: remainingData[0].id
                };
                // Push the data to the dataMap array
                // then set the values of total lander Weightage, simpleLanderId, landerWeight & landerValue
                this.setState(produce(draft => { draft.is_default[draft.newDefaultPathIndex].dataToMap[type + "s"].push(data) }));
                this.setState(produce(draft => { draft.is_default[draft.newDefaultPathIndex].counts[type + "WeightageTotal"] = this.state.is_default[this.state.newDefaultPathIndex].counts[type + "WeightageTotal"] + 100 }));
                this.setState(produce(draft => { draft.dataToSend.path.is_default[draft.newDefaultPathIndex][type + "Id"].push(data.selectedId) }));
                this.setState(produce(draft => { draft.dataToSend.path.is_default[draft.newDefaultPathIndex][type + "Weight"].push(data.weightagePercentage) }));
                this.setState(produce(draft => { draft.dataToSend.path.is_default[draft.newDefaultPathIndex][type + "Value"].push(data.weightageNumber) }));
                setTimeout(() => {
                    this.recalculateAllWeightsPercentages(type);
                    this.repopulateSelectOptions(type);
                }, 50);
                setTimeout(() => {
                    //console.log(this.state.is_default);
                }, 300);
            }
        }
        else {
            let type = e.target.id.split("_")[1];
            if (typeof type !== 'undefined') {
                this.setState(produce(draft => { draft.rule[draft.newRuleIndex].path[draft.newRulePathIndex].counts[type + "sAdded"] = (Number(this.state.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex].counts[type + "sAdded"]) + 1) }));
                let remainingData = [];
                for (let i = 0; i < this.state.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex]["all" + this.capitalizeFirstLetter(type) + "sDataLoaded"].length; i++) {
                    let itemFound = false;
                    for (let j = 0; j < this.state.dataToSend.path.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex][type + "Id"].length; j++) {
                        if (Number(this.state.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex]["all" + this.capitalizeFirstLetter(type) + "sDataLoaded"][i].id) === Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex][type + "Id"][j])) {
                            itemFound = true;
                        }
                    }
                    if (itemFound === false) {
                        remainingData.push(this.state.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex]["all" + this.capitalizeFirstLetter(type) + "sDataLoaded"][i]);
                    }
                }
                let data = {
                    name: type + "_" + (this.state.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex].counts[type + "sAdded"] + 1),
                    weightageNumber: 100,
                    weightagePercentage: this.calculateIndividualLanderWeightage(100, "true"),
                    options: remainingData,
                    selectedId: remainingData[0].id
                };
                // Push the data to the dataMap array
                // then set the values of total lander Weightage, simpleLanderId, landerWeight & landerValue
                this.setState(produce(draft => { draft.rule[draft.newRuleIndex].path[draft.newRulePathIndex].dataToMap[type + "s"].push(data) }));
                this.setState(produce(draft => { draft.rule[draft.newRuleIndex].path[draft.newRulePathIndex].counts[type + "WeightageTotal"] = this.state.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex].counts[type + "WeightageTotal"] + 100 }));
                this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].path[draft.newRulePathIndex][type + "Id"].push(data.selectedId) }));
                this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].path[draft.newRulePathIndex][type + "Weight"].push(data.weightagePercentage) }));
                this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].path[draft.newRulePathIndex][type + "Value"].push(data.weightageNumber) }));
                setTimeout(() => {
                    this.recalculateAllWeightsPercentages(type);
                    this.repopulateSelectOptions(type);
                }, 50);
                setTimeout(() => {
                    //console.log(this.state.is_default[this.state.newDefaultPathIndex].counts);
                }, 300);
            }
        }
    }

    capitalizeFirstLetter = (string) => {
        //console.log('capitalize string', string);
        if (typeof string !== 'undefined') {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    }

    // function for both landers and offers to re-Populate the select tag options
    // re populate dataToMap select tags

    // this functions check all the remaining options.....
    repopulateSelectOptions = (type) => {
        //console.log('re populate select options tag', type);
        if (this.state.onDefaultPathClick) {
            if (typeof type !== 'undefined') {
                let remainingOptions = [];
                for (let i = 0; i < this.state.is_default[this.state.newDefaultPathIndex]["all" + this.capitalizeFirstLetter(type) + "sDataLoaded"].length; i++) {
                    let itemFound = false;
                    for (let j = 0; j < this.state.dataToSend.path.is_default[this.state.newDefaultPathIndex][type + "Id"].length; j++) {
                        if (Number(this.state.is_default[this.state.newDefaultPathIndex]["all" + this.capitalizeFirstLetter(type) + "sDataLoaded"][i].id) === Number(this.state.dataToSend.path.is_default[this.state.newDefaultPathIndex][type + "Id"][j])) {
                            itemFound = true;
                        }
                    }
                    if (itemFound === false) {
                        remainingOptions.push(this.state.is_default[this.state.newDefaultPathIndex]["all" + this.capitalizeFirstLetter(type) + "sDataLoaded"][i]);
                    }
                }
                this.repopulateAllSelectTagOptions(remainingOptions, type);
            }
        }
        else {
            if (typeof type !== 'undefined') {
                let remainingOptions = [];
                for (let i = 0; i < this.state.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex]["all" + this.capitalizeFirstLetter(type) + "sDataLoaded"].length; i++) {
                    let itemFound = false;
                    for (let j = 0; j < this.state.dataToSend.path.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex][type + "Id"].length; j++) {
                        if (Number(this.state.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex]["all" + this.capitalizeFirstLetter(type) + "sDataLoaded"][i].id) === Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex][type + "Id"][j])) {
                            itemFound = true;
                        }
                    }
                    if (itemFound === false) {
                        remainingOptions.push(this.state.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex]["all" + this.capitalizeFirstLetter(type) + "sDataLoaded"][i]);
                    }
                }
                this.repopulateAllSelectTagOptions(remainingOptions, type);
            }
        }
    }

    // this function populated all the remaining options to all the select tags
    repopulateAllSelectTagOptions = (options, type) => {
        if (this.state.onDefaultPathClick) {
            if (typeof type !== 'undefined') {
                for (let i = 0; i < this.state.is_default[this.state.newDefaultPathIndex].dataToMap[type + "s"].length; i++) {
                    //console.log(this.state.is_default[this.state.newDefaultPathIndex].dataToMap[type+"s"][i].selectedId);
                    let selectOptions = [...options];
                    // match the selected id of each lander/offer to add it to the options
                    for (let j = 0; j < this.state.is_default[this.state.newDefaultPathIndex]["all" + this.capitalizeFirstLetter(type) + "sDataLoaded"].length; j++) {
                        if (Number(this.state.is_default[this.state.newDefaultPathIndex]["all" + this.capitalizeFirstLetter(type) + "sDataLoaded"][j].id) === Number(this.state.is_default[this.state.newDefaultPathIndex].dataToMap[type + "s"][i].selectedId)) {
                            selectOptions.push(this.state.is_default[this.state.newDefaultPathIndex]["all" + this.capitalizeFirstLetter(type) + "sDataLoaded"][j]);
                        }
                    }
                    this.setState(produce(draft => { draft.is_default[draft.newDefaultPathIndex].dataToMap[type + "s"][i].options = selectOptions }));
                }
            }
        }
        else {
            if (typeof type !== 'undefined') {
                for (let i = 0; i < this.state.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex].dataToMap[type + "s"].length; i++) {
                    let selectOptions = [...options];
                    for (let j = 0; j < this.state.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex]["all" + this.capitalizeFirstLetter(type) + "sDataLoaded"].length; j++) {
                        if (Number(this.state.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex]["all" + this.capitalizeFirstLetter(type) + "sDataLoaded"][j].id) === Number(this.state.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex].dataToMap[type + "s"][i].selectedId)) {
                            selectOptions.push(this.state.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex]["all" + this.capitalizeFirstLetter(type) + "sDataLoaded"][j]);
                        }
                    }
                    this.setState(produce(draft => { draft.rule[draft.newRuleIndex].path[draft.newRulePathIndex].dataToMap[type + "s"][i].options = selectOptions }));
                }
            }
        }
    }

    calculateIndividualLanderWeightage = (num, isNew) => {
        if (this.state.onDefaultPathClick) {
            if (isNew) {
                return ((num/(this.state.is_default[this.state.newDefaultPathIndex].counts.landerWeightageTotal+100))*100).toFixed(2);
            } else {
                return ((num/this.state.is_default[this.state.newDefaultPathIndex].counts.landerWeightageTotal)*100).toFixed(2);
            }
        }
        else {
            if (isNew) {
                return ((num/(this.state.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex].counts.landerWeightageTotal+100))*100).toFixed(2);
            } else {
                return ((num/this.state.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex].counts.landerWeightageTotal)*100).toFixed(2);
            }
        }
    }
    calculateIndividualOfferWeightage = (num) => {
        if (this.state.onDefaultPathClick) {
            return ((num/this.state.is_default[this.state.newDefaultPathIndex].counts.offerWeightageTotal)*100).toFixed(2);
        } else {
            return ((num/this.state.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex].counts.offerWeightageTotal)*100).toFixed(2);
        }
    }

    // function for both landers and offers, where type = lander or offer
    recalculateAllWeightsPercentages = (type) => {
        if (this.state.onDefaultPathClick) {
            //console.log("Type: ", type);
            for (let j=0;j<this.state.dataToSend.path.is_default[this.state.newDefaultPathIndex][type+'Weight'].length; j++) {
                // first change it in dataToSend
                //console.log('dataToSend.path.is_default[draft.newDef',this.state.dataToSend.path.is_default[this.state.newDefaultPathIndex][type+'Weight']);
                this.setState(produce(draft => {
                    draft.dataToSend.path.is_default[draft.newDefaultPathIndex][type+'Weight'][j] = ((this.state.dataToSend.path.is_default[this.state.newDefaultPathIndex][type+'Value'][j]/this.state.is_default[this.state.newDefaultPathIndex].counts[type+'WeightageTotal'])*100).toFixed(2);
                }));
                // then change it all in dataToMap
                this.setState(produce(draft => {
                    draft.is_default[draft.newDefaultPathIndex].dataToMap[type+'s'][j].weightagePercentage = ((Number(this.state.dataToSend.path.is_default[this.state.newDefaultPathIndex][type+'Value'][j])/Number(this.state.is_default[this.state.newDefaultPathIndex].counts[type+'WeightageTotal']))*100).toFixed(2);
                }));
            }
            // console.log('all data', this.state.dataToSend);
            // console.log('this.state.is_default',this.state.is_default);
        }
        else {
            for (let j=0;j<this.state.dataToSend.path.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex][type+'Weight'].length; j++) {
                this.setState(produce(draft => {
                    draft.dataToSend.path.rule[draft.newRuleIndex].path[draft.newRulePathIndex][type+'Weight'][j] = ((this.state.dataToSend.path.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex][type+'Value'][j]/this.state.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex].counts[type+'WeightageTotal'])*100).toFixed(2);
                }));
                this.setState(produce(draft => {
                    draft.rule[draft.newRuleIndex].path[draft.newRulePathIndex].dataToMap[type+'s'][j].weightagePercentage = ((Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex][type+'Value'][j])/Number(this.state.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex].counts[type+'WeightageTotal']))*100).toFixed(2);
                }));
            }
        }
    }

    recalculateAllDefaultWeightsPercentages = (type, index) => {
        if (typeof type !== undefined) {
            if (type === "default") {
                let length = this.state.defaultWeightageData.pathAddedCount;
                for (let j = 0; j < length; j++) {
                    // then change it all in dataToMap
                    this.setState(produce(draft => {
                        draft.dataToSend.path.is_default[j].weight = (Number(this.state.dataToSend.path.is_default[j].value) / Number(this.state.defaultWeightageData.pathsTotalWeightage) * 100).toFixed(2);
                    }));
                }
            }
            else {
                let length = this.state.ruleWeightageData[index].pathAddedCount;
                for (let j = 0; j < length; j++) {
                    // then change it all in dataToMap
                    //console.log(this.state.dataToSend.path.rule[index]);
                    // if(this.state.dataToSend.path.rule[index].path[j].value !== undefined){
                    // }
                    this.setState(produce(draft => {
                        draft.dataToSend.path.rule[index].path[j].weight = (Number(this.state.dataToSend.path.rule[index].path[j].value) / Number(this.state.ruleWeightageData[index].pathsTotalWeightage) * 100).toFixed(2);
                    }));
                }
            }
        }
    }

    // increase and decrease weights
    increaseWeightMethod = (e) => {
        if (!this.state.onDefaultPathClick) {
            let type = e.target.id.split("_")[0];
            let index = e.target.id.split("_")[1];
            // set lander value in dataToSend
            // then set weightageNumber in dataToMap
            if (typeof type !== 'undefined') {
                this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].path[draft.newRulePathIndex][type + "Value"][index] = this.state.dataToSend.path.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex][type + "Value"][index] + 1 }));
                this.setState(produce(draft => { draft.rule[draft.newRuleIndex].path[draft.newRulePathIndex].dataToMap[type + "s"][index].weightageNumber = this.state.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex].dataToMap[type + "s"][index].weightageNumber + 1 }));
                this.setState(produce(draft => { draft.rule[draft.newRuleIndex].path[draft.newRulePathIndex].counts[type + "WeightageTotal"] = this.state.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex].counts[type + "WeightageTotal"] + 1 }));
                setTimeout(() => {
                    //console.log("State: ", this.state);
                    this.recalculateAllWeightsPercentages(type);
                }, 50);

            }
        } else {
            let type = e.target.id.split("_")[0];
            let index = e.target.id.split("_")[1];
            // set lander value in dataToSend
            // then set weightageNumber in dataToMap
            if (typeof type !== 'undefined') {
                this.setState(produce(draft => { draft.dataToSend.path.is_default[draft.newDefaultPathIndex][type + "Value"][index] = this.state.dataToSend.path.is_default[this.state.newDefaultPathIndex][type + "Value"][index] + 1 }));
                this.setState(produce(draft => { draft.is_default[draft.newDefaultPathIndex].dataToMap[type + "s"][index].weightageNumber = this.state.is_default[this.state.newDefaultPathIndex].dataToMap[type + "s"][index].weightageNumber + 1 }));
                this.setState(produce(draft => { draft.is_default[draft.newDefaultPathIndex].counts[type + "WeightageTotal"] = this.state.is_default[this.state.newDefaultPathIndex].counts[type + "WeightageTotal"] + 1 }));
                setTimeout(() => {
                    //console.log("State: ", this.state);
                    this.recalculateAllWeightsPercentages(type);
                }, 50);
            }
        }
    }
    decreaseWeightMethod = (e) => {
        if (this.state.onDefaultPathClick) {
            let type = e.target.id.split("_")[0];
            let index = e.target.id.split("_")[1];
            // set lander value in dataToSend
            // then set weightageNumber in dataToMap
            if (typeof type !== 'undefined') {
                if (this.state.dataToSend.path.is_default[this.state.newDefaultPathIndex][type + "Value"][index] > 0) {
                
                    this.setState(produce(draft => { draft.dataToSend.path.is_default[draft.newDefaultPathIndex][type + "Value"][index] = this.state.dataToSend.path.is_default[this.state.newDefaultPathIndex][type + "Value"][index] - 1 }));
                
                    this.setState(produce(draft => { draft.is_default[draft.newDefaultPathIndex].dataToMap[type + "s"][index].weightageNumber = this.state.is_default[this.state.newDefaultPathIndex].dataToMap[type + "s"][index].weightageNumber - 1 }));
                
                    this.setState(produce(draft => { draft.is_default[draft.newDefaultPathIndex].counts[type + "WeightageTotal"] = this.state.is_default[this.state.newDefaultPathIndex].counts[type + "WeightageTotal"] - 1 }));
                    setTimeout(() => {
                        this.recalculateAllWeightsPercentages(type);
                    }, 50);
                }
            }
        }
        else {
            let type = e.target.id.split("_")[0];
            let index = e.target.id.split("_")[1];
            // set lander value in dataToSend
            // then set weightageNumber in dataToMap
            if (typeof type !== 'undefined') {
                if (this.state.dataToSend.path.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex][type + "Value"][index] > 0) {
                    this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].path[draft.newRulePathIndex][type + "Value"][index] = this.state.dataToSend.path.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex][type + "Value"][index] - 1 }));
                    this.setState(produce(draft => { draft.rule[draft.newRuleIndex].path[draft.newRulePathIndex].dataToMap[type + "s"][index].weightageNumber = this.state.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex].dataToMap[type + "s"][index].weightageNumber - 1 }));
                    this.setState(produce(draft => { draft.rule[draft.newRuleIndex].path[draft.newRulePathIndex].counts[type + "WeightageTotal"] = this.state.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex].counts[type + "WeightageTotal"] - 1 }));
                    setTimeout(() => {
                        this.recalculateAllWeightsPercentages(type);
                    }, 50);
                }
            }
        }
    }

    changeWeightageHandler = (e)=>{
        if (!this.state.onDefaultPathClick) {
        let type = e.target.id.split("_")[0];
        let index = e.target.id.split("_")[1];

        this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].path[draft.newRulePathIndex][type + "Value"][index] = Number(e.target.value) }));
        
        this.setState(produce(draft => { draft.rule[draft.newRuleIndex].path[draft.newRulePathIndex].dataToMap[type + "s"][index].weightageNumber = Number(e.target.value) }));
        
        setTimeout(() => {
           this.recalculateTotalValueInput(type,index); 
        }, 30);
        setTimeout(() => {
            this.recalculateAllWeightsPercentages(type);
        },70);
    }
        else{
            let type = e.target.id.split("_")[0];
            let index = e.target.id.split("_")[1];
            // set lander value in dataToSend
            // then set weightageNumber in dataToMap
            if (typeof type !== 'undefined') {
                
                this.setState(produce(draft => { draft.dataToSend.path.is_default[draft.newDefaultPathIndex][type + "Value"][index] = Number(e.target.value) }));
                
                this.setState(produce(draft => { draft.is_default[draft.newDefaultPathIndex].dataToMap[type + "s"][index].weightageNumber = Number(e.target.value) }));
                
                setTimeout(() => {
                    this.recalculateTotalValueInput(type,index); 
                 }, 30);
                setTimeout(() => {
                    this.recalculateAllWeightsPercentages(type);
                }, 70);
            }

        }
    }
    recalculateTotalValueInput = (type,index)=>{
        if (this.state.onDefaultPathClick) {
        let total = 0;
        for(let i = 0 ; i< this.state.dataToSend.path.is_default[this.state.newDefaultPathIndex][type + "Value"].length; i++){
            total += Number(this.state.dataToSend.path.is_default[this.state.newDefaultPathIndex][type + "Value"][i]);
        }
        this.setState(produce(draft => { draft.is_default[draft.newDefaultPathIndex].counts[type + "WeightageTotal"] = Number(total)}));
    }
        else{
            let total = 0;
            for(let i = 0 ; i< this.state.dataToSend.path.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex][type + "Value"].length; i++){
                total += Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex][type + "Value"][i]);
            }
            this.setState(produce(draft => { draft.rule[draft.newRuleIndex].path[draft.newRulePathIndex].counts[type + "WeightageTotal"] = Number(total) }));
        }
    }

    decreaseDefaultPathWeightMethod = (e) => {
        let type = e.target.id.split("_")[0];
        let index = e.target.id.split("_")[1];
        let index2 = e.target.id.split("_")[2];
        //console.log('e',e);
        if (typeof type !== 'undefined') {
            if (type === "default") {
                this.setState(produce(draft => { draft.defaultPathClicked = true }));

                if (this.state.dataToSend.path.is_default[index].value > 0) {
                    this.setState(produce(draft => { draft.dataToSend.path.is_default[index].value -= 1 }));
                    this.setState(produce(draft => { draft.defaultWeightageData.pathsTotalWeightage -= 1 }));
                    this.setState(produce(draft => { draft.newDefaultPathIndex = Number(index) }));
                    this.setState(produce(draft => { draft.bgClrPathIndex = Number(index) }));
                    setTimeout(() => {
                        this.recalculateAllDefaultWeightsPercentages(type);
                    }, 50);
                    setTimeout(() => {
                        this.rePopulateDefaultRuleLandersOffer();
                    }, 100);
                }
            }
            else {
                // let type = e.target.id.split("_")[0];
                // let index = e.target.id.split("_")[1];
                // set lander value in dataToSend
                // then set weightageNumber in dataToMap
                this.setState(produce(draft => { draft.defaultPathClicked = false }));

                if (this.state.dataToSend.path.rule[index].path[index2].value > 0) {
                    this.setState(produce(draft => { draft.dataToSend.path.rule[index].path[index2].value -= 1 }));
                    this.setState(produce(draft => { draft.ruleWeightageData[index].pathsTotalWeightage -= 1 }));

                    this.setState(produce(draft => { draft.newRulePathIndex = Number(index2) }));
                    this.setState(produce(draft => { draft.bgClrRulePathIndex = Number(index2) }));
                    setTimeout(() => {
                        this.recalculateAllDefaultWeightsPercentages(type, index);
                    }, 50);
                    setTimeout(() => {
                        this.rePopulateDefaultRuleLandersOffer();
                    }, 100);
                }
            }
        }
    }
    increaseDefaultPathWeightMethod = (e) => {
        let type = e.target.id.split("_")[0];
        let index = e.target.id.split("_")[1];
        let index2 = e.target.id.split("_")[2];
        if (typeof type !== 'undefined') {
            if (type === "default") {
                if (this.state.dataToSend.path.is_default[index].value > 0) {
                    this.setState(produce(draft => { draft.dataToSend.path.is_default[index].value = Number(this.state.dataToSend.path.is_default[index].value) + 1 }));
                    this.setState(produce(draft => { draft.defaultWeightageData.pathsTotalWeightage += 1 }));
                    this.setState(produce(draft => { draft.newDefaultPathIndex = Number(index) }));
                    this.setState(produce(draft => { draft.bgClrPathIndex = Number(index) }));
                    setTimeout(() => {
                        this.recalculateAllDefaultWeightsPercentages(type);
                    }, 50);
                    setTimeout(() => {
                        this.rePopulateDefaultRuleLandersOffer();
                    }, 100);
                }
            }
            else {
                // let type = e.target.id.split("_")[0];
                // let index = e.target.id.split("_")[1];
                // set lander value in dataToSend
                // then set weightageNumber in dataToMap
                this.setState(produce(draft => { draft.defaultPathClicked = false }));

                if (this.state.dataToSend.path.rule[index].path[index2].value > 0) {
                    this.setState(produce(draft => { draft.dataToSend.path.rule[index].path[index2].value = Number(this.state.dataToSend.path.rule[index].path[index2].value) + 1 }));
                    this.setState(produce(draft => { draft.ruleWeightageData[index].pathsTotalWeightage += 1 }));

                    this.setState(produce(draft => { draft.newRulePathIndex = Number(index2) }));
                    this.setState(produce(draft => { draft.bgClrRulePathIndex = Number(index2) }));
                    setTimeout(() => {
                        this.recalculateAllDefaultWeightsPercentages(type, index);
                    }, 50);
                    setTimeout(() => {
                        this.rePopulateDefaultRuleLandersOffer();
                    }, 100);
                }
            }
        }
    }

    // Select tag onChange handler
    weightageSelectTagChangeHandler = (e) => {
        if (this.state.onDefaultPathClick) {
            let type = e.target.id.split("_")[0];
            // let index = Number(e.target.id.split("_")[1])-1;
            let index = Number(e.target.id.split("_")[1]);
            this.setState(produce(draft => { draft.is_default[draft.newDefaultPathIndex].dataToMap[type+"s"][index].selectedId = e.target.value }));
            this.setState(produce(draft => { draft.dataToSend.path.is_default[draft.newDefaultPathIndex][type+"Id"][index] = e.target.value }));
            setTimeout(() => {
                this.repopulateSelectOptions(type);
            },50);
        } else {
            let type = e.target.id.split("_")[0];
            let index = Number(e.target.id.split("_")[1]);
            this.setState(produce(draft => { draft.rule[draft.newRuleIndex].path[draft.newRulePathIndex].dataToMap[type+"s"][index].selectedId = e.target.value }));
            this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].path[draft.newRulePathIndex][type+"Id"][index] = e.target.value }));
            setTimeout(() => {
                this.repopulateSelectOptions(type);
            },50);
        }
    }

    // delete method
    deleteWeightageMethod = (e) => {
        if (this.state.onDefaultPathClick) {
            let type = e.target.id.split("_")[0];
            let index = Number(e.target.id.split("_")[1]);
            let newSimpleItemsId = [];
            let newItemWeightList = [];
            let newItemValueList = [];
            let newDataToMapArr = [];
            let deleteItemWeightage = null;
            for (let i = 0; i < this.state.dataToSend.path.is_default[this.state.newDefaultPathIndex][type + "Id"].length; i++) {
                if (i === index) {
                    deleteItemWeightage = this.state.dataToSend.path.is_default[this.state.newDefaultPathIndex][type+"Value"][i];
                } else {
                    newSimpleItemsId.push(this.state.dataToSend.path.is_default[this.state.newDefaultPathIndex][type+"Id"][i]);
                    newItemWeightList.push(this.state.dataToSend.path.is_default[this.state.newDefaultPathIndex][type+"Weight"][i]);
                    newItemValueList.push(this.state.dataToSend.path.is_default[this.state.newDefaultPathIndex][type+"Value"][i]);
                    newDataToMapArr.push(this.state.is_default[this.state.newDefaultPathIndex].dataToMap[type+"s"][i]);
                }
            }
            this.setState(produce(draft => { draft.is_default[draft.newDefaultPathIndex].counts[type + "sAdded"] = Number(this.state.is_default[this.state.newDefaultPathIndex].counts[type + "sAdded"]) - 1 }));
            this.setState(produce(draft => { draft.is_default[draft.newDefaultPathIndex].counts[type + "WeightageTotal"] = Number(this.state.is_default[this.state.newDefaultPathIndex].counts[type + "WeightageTotal"]) - Number(deleteItemWeightage) }));
            this.setState(produce(draft => { draft.dataToSend.path.is_default[draft.newDefaultPathIndex][type + "Id"] = newSimpleItemsId }));
            this.setState(produce(draft => { draft.dataToSend.path.is_default[draft.newDefaultPathIndex][type + "Weight"] = newItemWeightList }));
            this.setState(produce(draft => { draft.dataToSend.path.is_default[draft.newDefaultPathIndex][type + "Value"] = newItemValueList }));
            this.setState(produce(draft => { draft.is_default[draft.newDefaultPathIndex].dataToMap[type + "s"] = newDataToMapArr }));


            setTimeout(() => {
                // console.log("New list: ", newSimpleItemsId);
                // console.log("newItemWeightList: ", newItemWeightList);
                // console.log("newItemValueList: ", newItemValueList);
                // console.log("newDataToMapArr: ", newDataToMapArr);
            }, 100);
            setTimeout(() => {
                this.recalculateAllWeightsPercentages(type);
                this.repopulateSelectOptions(type);
            }, 50);
            setTimeout(() => {
                // console.log('all data', this.state.dataToSend);
                // console.log('this.state.is_default',this.state.is_default);
            }, 500);
        } else {
            let type = e.target.id.split("_")[0];
            let index = Number(e.target.id.split("_")[1]);
            let newSimpleItemsId = [];
            let newItemWeightList = [];
            let newItemValueList = [];
            let newDataToMapArr = [];
            let deleteItemWeightage = null;
            for (let i = 0; i < this.state.dataToSend.path.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex][type + "Id"].length; i++) {
                
                if (i === index) {
                    deleteItemWeightage = this.state.dataToSend.path.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex][type+"Value"][i];
                } else {
                    newSimpleItemsId.push(this.state.dataToSend.path.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex][type+"Id"][i]);
                    newItemWeightList.push(this.state.dataToSend.path.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex][type+"Weight"][i]);
                    newItemValueList.push(this.state.dataToSend.path.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex][type+"Value"][i]);
                    newDataToMapArr.push(this.state.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex].dataToMap[type+"s"][i]);
                }
            }
            this.setState(produce(draft => { draft.rule[draft.newRuleIndex].path[draft.newRulePathIndex].counts[type + "sAdded"] = Number(this.state.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex].counts[type + "sAdded"]) - 1 }));
            this.setState(produce(draft => { draft.rule[draft.newRuleIndex].path[draft.newRulePathIndex].counts[type + "WeightageTotal"] = Number(this.state.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex].counts[type + "WeightageTotal"]) - Number(deleteItemWeightage) }));
            this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].path[draft.newRulePathIndex][type + "Id"] = newSimpleItemsId }));
            this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].path[draft.newRulePathIndex][type + "Weight"] = newItemWeightList }));
            this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].path[draft.newRulePathIndex][type + "Value"] = newItemValueList }));
            this.setState(produce(draft => { draft.rule[draft.newRuleIndex].path[draft.newRulePathIndex].dataToMap[type + "s"] = newDataToMapArr }));


            setTimeout(() => {
                // console.log("New list: ", newSimpleItemsId);
                // console.log("newItemWeightList: ", newItemWeightList);
                // console.log("newItemValueList: ", newItemValueList);
                // console.log("newDataToMapArr: ", newDataToMapArr);
            }, 100);
            setTimeout(() => {
                this.recalculateAllWeightsPercentages(type);
                this.repopulateSelectOptions(type);
            }, 50);
            setTimeout(() => {
                // console.log('all data', this.state.dataToSend);
                // console.log('this.state.is_default',this.state.is_default);
            }, 500);
        }
    }

    // =============================================================================================
    // =============================================================================================


    handleSwitchChange = (e) => {
        if (e.target.element.name === "flow_redirect_mode") {
            this.setState(produce(draft => { draft.dataToSend[e.target.element.name] = e.value }));
        }
    }


    nameChangeHandler = (e) => {
        if (e.value === "") {
            this.setState(produce(draft => { draft.errors.name = "Please enter flow name." }));
        } else {this.setState(produce(draft => { draft.errors.name = null }));}
        this.setState(produce(draft => { draft.dataToSend.flow_name = e.value }));
    }
    inputChangeHandler = (e) => {
        let type = e.target.id.split("_")[0];
        let index = e.target.id.split("_")[1];
        let index2 = e.target.id.split("_")[2];
        if(e.target.name === "weightage"){
            if (typeof type !== 'undefined') {
                if (type === "default") {
                    if (this.state.dataToSend.path.is_default[index].value > 0) {
                        this.setState(produce(draft => { draft.dataToSend.path.is_default[index].value = Number(e.target.value) }));
                        let total = 0;
                        setTimeout(() => {
                            for(let i = 0;i<this.state.dataToSend.path.is_default.length;i++){
                                total +=Number(this.state.dataToSend.path.is_default[i].value);
                            }
                            this.setState(produce(draft => { draft.defaultWeightageData.pathsTotalWeightage = Number(total) }));
                        }, 20);
                        this.setState(produce(draft => { draft.newDefaultPathIndex = Number(index) }));
                        this.setState(produce(draft => { draft.bgClrPathIndex = Number(index) }));
                        setTimeout(() => {
                            this.recalculateAllDefaultWeightsPercentages(type);
                        }, 50);
                        setTimeout(() => {
                            this.rePopulateDefaultRuleLandersOffer();
                        }, 100);
                    }
                }
                else {
                    this.setState(produce(draft => { draft.defaultPathClicked = false }));
    
                    if (this.state.dataToSend.path.rule[index].path[index2].value > 0) {
                        this.setState(produce(draft => { draft.dataToSend.path.rule[index].path[index2].value = Number(e.target.value) }));
                        let total = 0;
                        setTimeout(() => {
                            for(let i = 0;i<this.state.dataToSend.path.rule[index].path.length;i++){
                                total +=Number(this.state.dataToSend.path.rule[index].path[i].value);
                            }
                            this.setState(produce(draft => { draft.ruleWeightageData[index].pathsTotalWeightage = Number(total) }));
                        }, 20);
    
                        this.setState(produce(draft => { draft.newRulePathIndex = Number(index2) }));
                        this.setState(produce(draft => { draft.bgClrRulePathIndex = Number(index2) }));
                        setTimeout(() => {
                            this.recalculateAllDefaultWeightsPercentages(type, index);
                        }, 50);
                        setTimeout(() => {
                            this.rePopulateDefaultRuleLandersOffer();
                        }, 100);
                    }
                }
            }
        }
        if (e.target.name === "flow_name") {
            // console.log("target found");
            if (e.value === "") {
                this.setState(produce(draft => { draft.errors.name = "Please enter flow name." }));
            } else {this.setState(produce(draft => { draft.errors.name = null }));}
            this.setState(produce(draft => { draft.dataToSend.flow_name = e.value }));    
        }
        if (e.target.name === "workspaceId") {
            this.setState(produce(draft => { draft.dataToSend[e.target.name] = e.target.value }));
        }
        if (e.target.name === "countryId") {
            for (let i = 0; i < this.props.countriesDataRecieved.length; i++) {
                if (e.target.value === String(this.props.countriesDataRecieved[i].id)) {
                    this.setState(produce(draft => { draft.dataToSend.countryId = this.props.countriesDataRecieved[i].id }));
                    this.setState(produce(draft => { draft.countryName = this.props.countriesDataRecieved[i].country_name}));
                }
            }
        }
    }
    
    onChangePane = (event) => {
        this.setState({
            panes: event.newState
        });
    }

    toggleDialog = () => {
        this.setState({
            visible: !this.state.visible,
        });
        this.props.toggleAddFlowsDialog();
    };


    addNewPath = (index) => {
        //console.log('add new path');
        this.setState(produce(draft => { draft.bgClrPathIndex = null }));
        this.setState(produce(draft => { draft.bgClrRuleIndex = null }));
        this.setState(produce(draft => { draft.onDefaultPathClick = false }));
        const path = {
            "path_name": "New Path",
            weight_optimization:true,
            "weight":"100",
            "value":"100",
            "offerId": [],
            "landerId": [],
            "offerWeight": [],
            "landerWeight": [],
            "offerValue": [],
            "landerValue": [],
            "path_redirect_mode": "302",
            "path_destination": "Lander & Offer"
        }
        const outerPath = {
            dataToMap: {
                landers: [],
                offers: []
            },
            counts: {
                landerInBackend: 0,
                landersAdded: 0,
                offersInBackend: 0,
                offersAdded: 0,
                landerWeightageTotal: 0,
                offerWeightageTotal: 0
            },
                allLandersDataLoaded: null,
                allOffersDataLoaded: null,
        }

        this.setState(produce(draft => { draft.dataToSend.path.rule[index].path.push(path) }));
        this.setState(produce(draft => { draft.rule[index].path.push(outerPath) }));
        this.setState(produce(draft => { draft.ruleWeightageData[index].pathAddedCount += 1 }));
        this.setState(produce(draft => { draft.ruleWeightageData[index].pathsTotalWeightage +=100 }));
        this.setState(produce(draft => { draft.newPathIndex = index }));
        this.setState(produce(draft => { draft.bgClrRulePathIndex = index }));
        this.setState(produce(draft => { draft.onRuleClick = false }));
        this.setState(produce(draft => { draft.onFlowClick = false }));
        this.setState(produce(draft => { draft.onPathClick = true }));
        setTimeout(() => {
            //console.log(this.state.ruleWeightageData);
            this.recalculateAllDefaultWeightsPercentages("rule_path",index)
        }, 100);
    }
    addDefaultPath = () => {
        this.setState(produce(draft => { draft.onDefaultPathClick = true }));
        this.setState(produce(draft => { draft.bgClrRuleIndex = null }));
        this.setState(produce(draft => { draft.bgClrRulePathIndex = null }));

        const defaultPath = {
            weight:"100",
            value: "100",
            weight_optimization:true,
            path_name: "New Path",
            offerId: [],
            landerId: [],
            offerWeight: [],
            landerWeight: [],
            offerValue: [],
            landerValue: [],
            path_redirect_mode: "302",
            path_destination: "Lander & Offer",
        }
        const outerDefaultPath = {
            dataToMap: {
                landers: [],
                offers: []
            },
            counts: {
                landerInBackend: 0,
                landersAdded: 0,
                offersInBackend: 0,
                offersAdded: 0,
                landerWeightageTotal: 0,
                offerWeightageTotal: 0
            },
            allLandersDataLoaded: null,
            allOffersDataLoaded: null,
        }
        this.setState(produce(draft => { draft.dataToSend.path.is_default.push(defaultPath) }));
        this.setState(produce(draft => { draft.is_default.push(outerDefaultPath) }));
        this.setState(produce(draft => { draft.onRuleClick = false }));
        this.setState(produce(draft => { draft.onFlowClick = false }));
        this.setState(produce(draft => { draft.onPathClick = true }));
        this.setState(produce(draft => { draft.bgClrPathIndex = 0 }));
        this.setState(produce(draft => {
			draft.defaultWeightageData.pathAddedCount = this.state.defaultWeightageData.pathAddedCount + 1;
			draft.defaultWeightageData.pathsTotalWeightage = this.state.defaultWeightageData.pathsTotalWeightage + 100;
        }))
        setTimeout(() => {
			this.recalculateAllDefaultWeightsPercentages("default");
		},50);
        //this.setState(produce(draft => { draft.newDefaultPathIndex = index}));
    }
    defaultPathClicked = (index) => {
        //console.log('default index',index);
        this.setState(produce(draft => { draft.onDefaultPathClick = true }));
        this.setState(produce(draft => { draft.newDefaultPathIndex = index }));
        this.setState(produce(draft => { draft.bgClrPathIndex = index }));
        this.setState(produce(draft => { draft.bgClrRuleIndex = null }));
        this.setState(produce(draft => { draft.bgClrRulePathIndex = null }));
        this.setState(produce(draft => { draft.onRuleClick = false }));
        this.setState(produce(draft => { draft.onFlowClick = false }));
        this.setState(produce(draft => { draft.onPathClick = true }));
        this.backgroundColorChangeOnClick(index);
        
        setTimeout(() => {
            this.rePopulateDefaultRuleLandersOffer();
        }, 100);
    }
    calculatePlaceholderName=()=>{
        
        let newArray="";
        // if(this.state.ruleConditionName[this.state.newRuleIndex][0]!==undefined){
        //     this.setState(produce(draft=>{draft.ruleConditionName[this.state.newRuleIndex].length=0}));
        // }
        if(this.state.dataToSend.path.rule.length>0){
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){

                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.connectionType !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.connectionType !==undefined){
                
                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.connectionType.connection.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.connectionType.connection[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.connectionType.connection[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.connectionType.connection.length)-1) + ") & ";
                    }
                }
            }
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.deviceType !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.deviceType !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.deviceType.device.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.deviceType.device[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.deviceType.device[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.deviceType.device.length)-1) + ") & "; 
                    }
                }
            }
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.region !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.region !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.region.regions.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.region.regions[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.region.regions[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.region.regions.length)-1) + ") & "; 
                    }
                }
            }
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.proxy !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.proxy !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.proxy.proxies.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.proxy.proxies[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.proxy.proxies[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.proxy.proxies.length)-1) + ") & "; 
                    }
                }
            }

            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.city !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.city !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.city.cities.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.city.cities[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.city.cities[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.city.cities.length)-1) + ") & "; 
                    }
                }
            }

            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.mobileCarrier !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.mobileCarrier !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.mobileCarrier.mobileCarrieries.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.mobileCarrier.mobileCarrieries[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.mobileCarrier.mobileCarrieries[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.mobileCarrier.mobileCarrieries.length)-1) + ") & "; 
                    }
                }
            }
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.country !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.country !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.country.countries.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.country.countries[0].country_name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.country.countries[0].country_name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.country.countries.length)-1) + ") & "; 
                    }
                }
            }
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.isp !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.isp !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.isp.ispies.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.isp.ispies[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.isp.ispies[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.isp.ispies.length)-1) + ") & "; 
                    }
                }
            }
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.language !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.language !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.language.languages.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.language.languages[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.language.languages[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.language.languages.length)-1) + ") & "; 
                    }
                }
            }

            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.brand !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.brand !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.brand.brands[0].length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.brand.brands[0][0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.brand.brands[0][0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.brand.brands[0].length)-1) + ") & "; 
                    }
                }
            }
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.os !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.os !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.os.oss[0].length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.os.oss[0][0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.os.oss[0][0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.os.oss[0].length)-1) + ") & "; 
                    }
                }
            }
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.browser !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.browser !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.browser.browsers[0].length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.browser.browsers[0][0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.browser.browsers[0][0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.browser.browsers[0].length)-1) + ") & "; 
                    }
                }
            }

            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.weekday !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.weekday !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.weekday.weekdays.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.weekday.weekdays[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.weekday.weekdays[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.weekday.weekdays.length)-1) + ") & "; 
                    }
                }
            }
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.ip !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.ip !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.ip.ips.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.ip.ips[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.ip.ips[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.ip.ips.length)-1) + ") & "; 
                    }
                }
            }
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.referrer !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.referrer !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.referrer.referrers.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.referrer.referrers[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.referrer.referrers[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.referrer.referrers.length)-1) + ") & "; 
                    }
                }
            }
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.referrerDomain !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.referrerDomain !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.referrerDomain.referrerDomains.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.referrerDomain.referrerDomains[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.referrerDomain.referrerDomains[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.referrerDomain.referrerDomains.length)-1) + ") & "; 
                    }
                }
            }
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.timeofday !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.timeofday !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.timeofday.timezone.length>0){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.timeofday.timezone[0] + " - "+ this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.timeofday.timezone[1] + " & ";
                    }
                    // else{
                    //     newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.timeofday.ips[0].name + "(+" + 
                    //     (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.timeofday.ips.length)-1) + ") & "; 
                    // }

                }
            }



            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var1 !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var1 !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var1.var1s.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var1.var1s[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var1.var1s[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var1.var1s.length)-1) + ") & "; 
                    }
                }
            }
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var2 !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var2 !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var2.var2s.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var2.var2s[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var2.var2s[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var2.var2s.length)-1) + ") & "; 
                    }
                }
            }
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var3 !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var3 !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var3.var3s.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var3.var3s[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var3.var3s[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var3.var3s.length)-1) + ") & "; 
                    }
                }
            }
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var4 !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var4 !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var4.var4s.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var4.var4s[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var4.var4s[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var4.var4s.length)-1) + ") & "; 
                    }
                }
            }
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var5 !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var5 !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var5.var5s.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var5.var5s[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var5.var5s[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var5.var5s.length)-1) + ") & "; 
                    }
                }
            }
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var6 !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var6 !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var6.var6s.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var6.var6s[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var6.var6s[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var6.var6s.length)-1) + ") & "; 
                    }
                }
            }
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var7 !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var7 !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var7.var7s.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var7.var7s[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var7.var7s[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var7.var7s.length)-1) + ") & "; 
                    }
                }
            }
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var8 !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var8 !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var8.var8s.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var8.var8s[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var8.var8s[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var8.var8s.length)-1) + ") & "; 
                    }
                }
            }
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var9 !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var9 !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var9.var9s.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var9.var9s[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var9.var9s[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var9.var9s.length)-1) + ") & "; 
                    }
                }
            }
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var10 !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var10 !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var10.var10s.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var10.var10s[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var10.var10s[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var10.var10s.length)-1) + ") & "; 
                    }
                }
            }

            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var11 !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var11 !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var11.var11s.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var11.var11s[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var11.var11s[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var11.var11s.length)-1) + ") & "; 
                    }
                }
            }
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var12 !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var12 !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var12.var12s.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var12.var12s[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var12.var12s[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var12.var12s.length)-1) + ") & "; 
                    }
                }
            }
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var13 !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var13 !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var13.var13s.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var13.var13s[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var13.var13s[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var13.var13s.length)-1) + ") & "; 
                    }
                }
            }
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var14 !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var14 !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var14.var14s.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var14.var14s[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var14.var14s[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var14.var14s.length)-1) + ") & "; 
                    }
                }
            }
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var15 !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var15 !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var15.var15s.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var15.var15s[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var15.var15s[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var15.var15s.length)-1) + ") & "; 
                    }
                }
            }
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var16 !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var16 !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var16.var16s.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var16.var16s[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var16.var16s[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var16.var16s.length)-1) + ") & "; 
                    }
                }
            }
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var17 !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var17 !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var17.var17s.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var17.var17s[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var17.var17s[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var17.var17s.length)-1) + ") & "; 
                    }
                }
            }
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var18 !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var18 !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var18.var18s.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var18.var18s[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var18.var18s[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var18.var18s.length)-1) + ") & "; 
                    }
                }
            }
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var19 !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var19 !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var19.var19s.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var19.var19s[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var19.var19s[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var19.var19s.length)-1) + ") & "; 
                    }
                }
            }
            if(Object.keys(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions).length>0){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var20 !==null && this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var20 !==undefined){

                    if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var20.var20s.length===1){
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var20.var20s[0].name + " & ";
                    }
                    else{
                        newArray +=  this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var20.var20s[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var20.var20s.length)-1) + ") & "; 
                    }
                }
            }

            this.setState(produce(draft=>{draft.ruleNameWithCondition = newArray.slice(0,-2)}));
            this.setState(produce(draft=>{draft.ruleConditionName[this.state.newRuleIndex][0] = newArray.slice(0,-2)}));
        }
        //console.log('newArray',newArray.slice(0,-2));
        
        
        setTimeout(() => {
            //console.log('placeholdername',this.state.ruleNameWithCondition);
            //console.log('rule name',this.state.ruleConditionName);
        }, 100);
    }
    backgroundColorChangeOnClick = (index) => {
        for (var i = 0; i < this.state.dataToSend.path.is_default.length; i++) {
            if (this.state.dataToSend.path.is_default[i] === this.state.dataToSend.path.is_default[index]) {
            }
        }
    }
    handleDefaultPathChange = (e) => {
        if (e.value === "") {
            this.setState(produce(draft => { draft.errors.default_path_name = "Please enter path name." }));
        }else {this.setState(produce(draft => { draft.errors.default_path_name = null }));}
        
        this.setState(produce(draft => { draft.dataToSend.path.is_default[this.state.newDefaultPathIndex][e.target.element.name] = e.value }));
        
        if (e.target.element.name === "path_destination" && e.value === "Offer Only" && this.state.errors.isOfferExist && this.props.landersDataReceived.data.length === 0) {
            this.setState(produce(draft => { draft.errors.isLanderExist = true }));
        }
        if (e.target.element.name === "path_destination" && e.value === "Lander & Offer" && this.state.errors.isOfferExist && this.props.landersDataReceived.data.length === 0) {
            this.setState(produce(draft => { draft.errors.isLanderExist = false }));
        }
    }
    handleRulePathChange = (e) => {
        if (e.value === "") {
            this.setState(produce(draft => { draft.errors.default_path_name = "Please enter path name." }));
        } else { this.setState(produce(draft => { draft.errors.default_path_name = null })); }
        
        this.setState(produce(draft => { draft.dataToSend.path.rule[this.state.newRuleIndex].path[this.state.newRulePathIndex][e.target.element.name] = e.value }));

        if (e.target.element.name === "path_destination" && e.value === "Offer Only" && this.state.errors.isOfferExist && this.props.landersDataReceived.data.length === 0) {
            this.setState(produce(draft => { draft.errors.isLanderExist = true }));
        }
        if (e.target.element.name === "path_destination" && e.value === "Lander & Offer" && this.state.errors.isOfferExist && this.props.landersDataReceived.data.length === 0) {
            this.setState(produce(draft => { draft.errors.isLanderExist = false }));
        }
    }
    deleteDefaultPath = (e) => {
        //console.log(e.target.parentElement);
        //console.log('delete clicked');
        let type = e.target.parentElement.id.split("_")[0];
        let index = Number(e.target.parentElement.id.split("_")[1]);
        let index2 = Number(e.target.parentElement.id.split("_")[2]);
        this.setState(produce(draft => { draft.bgClrPathIndex = null }));
        this.setState(produce(draft => { draft.bgClrRuleIndex = null }));
        this.setState(produce(draft => { draft.bgClrRulePathIndex = null }));
        //console.log('type', type);
        if (typeof type !== 'undefined') {
            if (type === "default") {
            
                this.setState(produce(draft => { draft.success_msg = "Default path has been deleted successfully. Please Select new path to proceed..." }))
                if (this.state.dataToSend.path.is_default.length > 1) {
                    let e = index;
                    let newDefaultPath = [];
                    let j = 0;
                    let outerNewDefaultPath = [];
                    let p = 0;
                    let deleteWeight = this.state.dataToSend.path.is_default[index].value;
                    //console.log('deleted wegitage ', deleteWeight);
                    for (let i = 0; i < this.state.dataToSend.path.is_default.length; i++) {
                        if (i !== e) {
                            newDefaultPath[j] = this.state.dataToSend.path.is_default[i];
                            j++;
                        }
                    }
                    for (let i = 0; i < this.state.is_default.length; i++) {
                        if (i !== e) {
                            outerNewDefaultPath[p] = this.state.is_default[i];
                            p++;
                        }
                    }
                    this.setState(produce(draft => { draft.dataToSend.path.is_default.length = 0 }));
                    this.setState(produce(draft => { draft.is_default.length = 0 }));
                    setTimeout(() => {
                        for (let k = 0; k < newDefaultPath.length; k++) {
                            this.setState(produce(draft => { draft.dataToSend.path.is_default.push(newDefaultPath[k]) }));
                        }
                    }, 50);
                    setTimeout(() => {
                        for (let k = 0; k < outerNewDefaultPath.length; k++) {
                            this.setState(produce(draft => { draft.is_default.push(outerNewDefaultPath[k]) }));
                        }
                    }, 100);
                    this.setState(produce(draft => { draft.onDefaultPathClick = false }));
                    this.setState(produce(draft => { draft.onRuleClick = false }));
                    this.setState(produce(draft => { draft.onFlowClick = false }));
                    this.setState(produce(draft => { draft.onPathClick = false }));
                    this.setState(produce(draft => {
                        draft.defaultWeightageData.pathAddedCount = this.state.defaultWeightageData.pathAddedCount - 1;
                        draft.defaultWeightageData.pathsTotalWeightage = this.state.defaultWeightageData.pathsTotalWeightage - deleteWeight  ;
                    }))
                }
    
                if (this.state.dataToSend.path.is_default.length === 1) {
                    this.setState(produce(draft => { draft.warning_msg = "Only default path can not be deleted. Please select new path to proceed.." }))
                    this.setState(produce(draft => { draft.success_msg = "" }))
                    this.setState(produce(draft => { draft.onDefaultPathClick = false }));
                    this.setState(produce(draft => { draft.onRuleClick = false }));
                    this.setState(produce(draft => { draft.onFlowClick = false }));
                    this.setState(produce(draft => { draft.onPathClick = false }));
                }
                if (this.state.newDefaultPathIndex > 0) {
                    this.setState(produce(draft => { draft.newDefaultPathIndex = this.state.newDefaultPathIndex - 1 }));
                }
                if (this.state.newDefaultPathIndex === 0) {
                    this.setState(produce(draft => { draft.newDefaultPathIndex = 0 }));
                }
                // setTimeout(() => {
                //     console.log('this.state', this.state);
                //     console.log(this.state.is_default);
                // }, 500);
                
                setTimeout(() => {
                    this.recalculateAllDefaultWeightsPercentages("default");
                }, 200);
            
            } else {
                if (this.state.dataToSend.path.rule.length > 0) {
                    if (type === "rule") {
                        this.setState(produce(draft => { draft.success_msg = "New rule path has been deleted successfully. Please Select new path to proceed..." }))
                        // console.log('else');
                        if (this.state.dataToSend.path.rule[index].path.length > 1) {
                            let e = index2;
                            let newDefaultPath = [];
                            let j = 0;
                            let outerNewDefaultPath = [];
                            let p = 0;
                            let deleteWeight;
                            for (let i = 0; i < this.state.dataToSend.path.rule[index].path.length; i++) {
                                if (i !== e) {
                                    newDefaultPath[j] = this.state.dataToSend.path.rule[index].path[i];
                                    j++;
                                }
                                if (i === e) {
                                    deleteWeight = this.state.dataToSend.path.rule[index].path[i].value;
                                }
                            }
                            //console.log('this.state.rule[index].path.length', this.state.rule[index].path.length);
                            //console.log(this.state.rule[index].path);
                            for (let i = 0; i < this.state.rule[index].path.length; i++) {
                                if (i !== e) {
                                    outerNewDefaultPath[p] = this.state.rule[index].path[i];
                                    p++;
                                }
                            }
                            this.setState(produce(draft => { draft.dataToSend.path.rule[index].path.length = 0 }));
                            this.setState(produce(draft => { draft.rule[index].path.length = 0 }));
                            setTimeout(() => {
                                for (let k = 0; k < newDefaultPath.length; k++) {
                                    this.setState(produce(draft => { draft.dataToSend.path.rule[index].path.push(newDefaultPath[k]) }));
                                }
                            }, 50);
                            setTimeout(() => {
                                for (let k = 0; k < outerNewDefaultPath.length; k++) {
                                    this.setState(produce(draft => { draft.rule[index].path.push(outerNewDefaultPath[k]) }));
                                }
                            }, 50);
                            this.setState(produce(draft => { draft.onDefaultPathClick = false }));
                            this.setState(produce(draft => { draft.onRuleClick = false }));
                            this.setState(produce(draft => { draft.onFlowClick = false }));
                            this.setState(produce(draft => { draft.onPathClick = false }));
                            this.setState(produce(draft => {
                                draft.ruleWeightageData[index].pathAddedCount = this.state.ruleWeightageData[index].pathAddedCount - 1;
                                draft.ruleWeightageData[index].pathsTotalWeightage = this.state.ruleWeightageData[index].pathsTotalWeightage - deleteWeight;
                            }))
                            setTimeout(() => {
                                this.recalculateAllDefaultWeightsPercentages("rule", index);
                            }, 200);
                        }
                        if (this.state.dataToSend.path.rule[index].path.length === 1) {
                            this.setState(produce(draft => { draft.warning_msg = "Only rule path can not be deleted. Please select new path to proceed.." }))
                            this.setState(produce(draft => { draft.success_msg = "" }))
                            this.setState(produce(draft => { draft.onDefaultPathClick = false }));
                            this.setState(produce(draft => { draft.onRuleClick = false }));
                            this.setState(produce(draft => { draft.onFlowClick = false }));
                            this.setState(produce(draft => { draft.onPathClick = false }));
                        }
                        
                    }
                }
            }
        }
    }
    onRuleNameChange = (e) => {
        if (e.value === "") {
            this.setState(produce(draft => { draft.errors.rule_name = "Please enter rule name." }));
        }else {this.setState(produce(draft => { draft.errors.rule_name = null }));}
        this.setState(produce(draft => { draft.dataToSend.path.rule[this.state.newRuleIndex].name = e.value }));
    }
    
    addNewRule = () => {
        this.setState(produce(draft => { draft.bgClrPathIndex = null }));
        this.setState(produce(draft => { draft.bgClrRulePathIndex = null }));
        this.setState(produce(draft => { draft.onDefaultPathClick = false }));
        const rule = {
            name: "",
            conditions: {},
            order:String(this.state.dataToSend.path.rule.length),
            path: [
                {
                    "path_name": "New Path",
                    "weight_optimization":true,
                    "offerId": [],
                    "landerId": [],
                    "weight":"100",
                    "value":"100",
                    "offerWeight": [],
                    "landerWeight": [],
                    "offerValue": [],
                    "landerValue": [],
                    "path_redirect_mode": "302",
                    "path_destination": "Lander & Offer"
                }
            ]
        }

        const outerRule = {
            order:String(this.state.dataToSend.path.rule.length),
                path: [
                    {
                        dataToMap: {
                            landers: [],
                            offers: []
                        },
                        counts: {
                            landerInBackend: 0,
                            landersAdded: 0,
                            offersInBackend: 0,
                            offersAdded: 0,
                            landerWeightageTotal: 0,
                            offerWeightageTotal: 0
                        },
                    allLandersDataLoaded: null,
                    allOffersDataLoaded: null,
                },  
            ],
        }
        const ruleWeightage= {
            pathAddedCount: 1,
			pathsTotalWeightage: 100,
        }
        
        this.setState(produce(draft => { draft.dataToSend.path.rule.push(rule) }));
        this.setState(produce(draft => { draft.rule.push(outerRule) }));
        this.setState(produce(draft => { draft.ruleWeightageData.push(ruleWeightage) }));
        this.setState(produce(draft => { draft.count = this.state.count + 1 }));
        this.setState(produce(draft => { draft.onRuleClick = true }));
        this.setState(produce(draft => { draft.onFlowClick = false }));
        this.setState(produce(draft => { draft.newRulePathIndex = 0 }));
        //this.setState(produce(draft => { draft.bgClrRuleIndex = 0 }));

        if (this.state.newRuleIndex === null) {
            this.setState(produce(draft => { draft.newRuleIndex = 0 }));
            this.setState(produce(draft => { draft.bgClrRuleIndex = 0 }));

        }
        if (this.state.newRuleIndex !== null) {
            this.setState(produce(draft => { draft.bgClrRuleIndex = this.state.newRuleIndex }));
        }
        setTimeout(() => {
            this.filterSelectForConditions();
        }, 100);
        setTimeout(() => {
            this.rePopulateDefaultRuleLandersOffer();
        }, 100);
    }
    addNewFlow = () => {
        //document.getElementById("new-path").style.backgroundColor = "white"; 
        this.setState(produce(draft => { draft.onRuleClick = false }));
        this.setState(produce(draft => { draft.onFlowClick = true }));
        this.setState(produce(draft => { draft.bgClrRuleIndex = null }));
        this.setState(produce(draft => { draft.bgClrPathIndex = null }));
        this.setState(produce(draft => { draft.bgClrRulePathIndex = null }));
        //this.setState(produce(draft => { draft.landerValue = [] }));
    }
    newRuleClicked = (e) => {
        // console.log('new rule clicked');
        let index;
        if(e.target.parentElement.id.includes("rule")){
            index = Number(e.target.parentElement.id.split("_")[2]);
        }
        else{
            index = Number(e.target.id.split("_")[2]);
        }
        this.setState(produce(draft => { draft.onDefaultPathClick = false }));
        this.setState(produce(draft => { draft.newRuleIndex = index }));
        this.setState(produce(draft => { draft.bgClrRuleIndex = index }));
        this.setState(produce(draft => { draft.bgClrPathIndex = null }));
        this.setState(produce(draft => { draft.bgClrRulePathIndex = null }));
        this.setState(produce(draft => { draft.onRuleClick = true }));
        this.setState(produce(draft => { draft.onFlowClick = false }));
        this.setState(produce(draft => { draft.onPathClick = false }));
        this.setState(produce(draft => { draft.newRulePathIndex = 0}));
        setTimeout(() => {
            this.filterSelectForConditions();
        }, 100);
        setTimeout(() => {
            this.rePopulateDefaultRuleLandersOffer();
        }, 100);
    }
    newRulePathClicked = (index,event) => {
        // console.log('rule',index,event);
        let ruleIndex = Number(event.target.id.split("_")[1]);
        // let name = (event.target.name.split("_")[3]);
        if(ruleIndex !== undefined && !(ruleIndex !== ruleIndex)){
            // console.log('rule',ruleIndex);
            this.setState(produce(draft => { draft.newRuleIndex = ruleIndex }));
        }
        this.setState(produce(draft => { draft.onDefaultPathClick = false }));
        this.setState(produce(draft => { draft.newRulePathIndex = index }));
        this.setState(produce(draft => { draft.bgClrPathIndex = null }));
        this.setState(produce(draft => { draft.bgClrRuleIndex = null }));
        this.setState(produce(draft => { draft.bgClrRulePathIndex = index }));
        this.setState(produce(draft => { draft.onRuleClick = false }));
        this.setState(produce(draft => { draft.onFlowClick = false }));
        this.setState(produce(draft => { draft.onPathClick = true }));
        setTimeout(() => {
            this.rePopulateDefaultRuleLandersOffer();
        }, 100);
    }
    deleteRule = (e) => {
        //console.log('e', e);
        //let e = e.target.parentElement.id
        let type = e.target.parentElement.id.split("_")[0];
        let index = Number(e.target.parentElement.id.split("_")[2]);
        //console.log('index',index);
        if (index !== undefined && !(index !== index)) {
            this.setState(produce(draft=>{draft.deleted = true}));
            this.setState(produce(draft => { draft.bgClrPathIndex = null }));
            this.setState(produce(draft => { draft.bgClrRuleIndex = null }));
            this.setState(produce(draft => { draft.bgClrRulePathIndex = null }));
            this.setState(produce(draft => { draft.success_msg = "Rule has been deleted successfully. Please Select new rule to proceed..." }))
            let newRule = [];
            let outerRule = [];
            let weightageRule = [];
            let conditions = [];
            let selected = [];
            let condtionRuleName=[];
            let j = 0;
            let p = 0;
            let c = 0;
            let s = 0;
            let w = 0;
            let k = 0;
            for (let i = 0; i < this.state.dataToSend.path.rule.length; i++) {
                if (i !== index) { newRule[j] = this.state.dataToSend.path.rule[i]; j++; }
            }
            for (let i = 0; i < this.state.ruleWeightageData.length; i++) {
                if (i !== index) { weightageRule[w] = this.state.ruleWeightageData[i]; w++; }
            }
            for (let i = 0; i < this.state.rule.length; i++) {
                if (i !== index) { outerRule[p] = this.state.rule[i]; p++; }
            }
            for (let i = 0; i < this.state.conditionsName.length; i++) {
                if (i !== index) { conditions[c] = this.state.conditionsName[i]; c++; }
            }
            for (let i = 0; i < this.state.selectedOptions.length; i++) {
                if (i !== index) { selected[s] = this.state.selectedOptions[i]; s++; }
            }
            for (let i = 0; i < this.state.ruleConditionName.length; i++) {
                if (i !== index) { condtionRuleName[k] = this.state.ruleConditionName[i]; k++; }
            }
            this.setState(produce(draft => { draft.dataToSend.path.rule.length = 0 }));
            this.setState(produce(draft => { draft.rule.length = 0 }));
            this.setState(produce(draft => { draft.ruleWeightageData.length = 0 }));
            this.setState(produce(draft => { draft.conditionsName.length = 0 }));
            this.setState(produce(draft => { draft.selectedOptions.length = 0 }));
            this.setState(produce(draft => { draft.ruleConditionName.length = 0 }));
            setTimeout(() => {
                for (let k = 0; k < newRule.length; k++) {
                    this.setState(produce(draft => { draft.dataToSend.path.rule.push(newRule[k]) }));
                }
            }, 50);
            setTimeout(() => {
                for (let k = 0; k < weightageRule.length; k++) {
                    this.setState(produce(draft => { draft.ruleWeightageData.push(weightageRule[k]) }));
                }
            }, 50);
            setTimeout(() => {
                for (let k = 0; k < outerRule.length; k++) {
                    this.setState(produce(draft => { draft.rule.push(outerRule[k]) }));
                }
            }, 50);
            setTimeout(() => {
                for (let k = 0; k < conditions.length; k++) {
                    this.setState(produce(draft => { draft.conditionsName.push(conditions[k]) }));
                }
            }, 50);
            setTimeout(() => {
                for (let k = 0; k < selected.length; k++) {
                    this.setState(produce(draft => { draft.selectedOptions.push(selected[k]) }));
                }
            }, 50);
            setTimeout(() => {
                for (let k = 0; k < selected.length; k++) {
                    this.setState(produce(draft => { draft.ruleConditionName.push(condtionRuleName[k]) }));
                }
            }, 50);
            setTimeout(() => {
                for(let i=index ;i<this.state.dataToSend.path.rule.length;i++){
                    this.setState(produce(draft=>{draft.dataToSend.path.rule[i].order = String(i)}));
                    this.setState(produce(draft=>{draft.rule[i].order=String(i)}));
                }
            }, 50);
            this.setState(produce(draft => { draft.onDefaultPathClick = false }));
            this.setState(produce(draft => { draft.onRuleClick = false }));
            this.setState(produce(draft => { draft.onFlowClick = false }));
            this.setState(produce(draft => { draft.onPathClick = false }));

            setTimeout(() => {
                if (this.state.dataToSend.path.rule.length === 0) {
                    this.setState(produce(draft => { draft.newRuleIndex = null }));
                }
            }, 30);
            setTimeout(() => {
                this.setState(produce(draft=>{draft.deleted = false}));
            }, 100);
        }
    }
    duplicateRule = (e) => {
        let type = e.target.parentElement.id.split("_")[0];
        let index = Number(e.target.parentElement.id.split("_")[2]);
        // console.log('index', index);
        if (index !== undefined && !(index !== index)) {
            let duplicateValue = this.state.dataToSend.path.rule[index];
            let duplicateRuleWeightage = this.state.ruleWeightageData[index];
            let outerRuleDuplicateValue = this.state.rule[index];
            let conditionsName = this.state.conditionsName[index];
            let selectedOptions = this.state.selectedOptions[index];
            let filteredOptions = this.state.filteredOptions[index];
            let ruleConditionName = this.state.ruleConditionName[index];
            this.setState(produce(draft => { draft.dataToSend.path.rule.splice(index + 1, 0, duplicateValue) }));
            this.setState(produce(draft => { draft.rule.splice(index + 1, 0, outerRuleDuplicateValue) }));
            this.setState(produce(draft => { draft.ruleWeightageData.splice(index + 1, 0, duplicateRuleWeightage) }));
            this.setState(produce(draft => { draft.conditionsName.splice(index + 1, 0, conditionsName) }));
            this.setState(produce(draft => { draft.ruleConditionName.splice(index + 1, 0, ruleConditionName) }));
            this.setState(produce(draft => { draft.selectedOptions.splice(index + 1, 0, selectedOptions) }));
            this.setState(produce(draft => { draft.filteredOptions.splice(index + 1, 0, filteredOptions) }));
            setTimeout(() => {
                for(let i=index+1 ;i<this.state.dataToSend.path.rule.length;i++){
                    this.setState(produce(draft=>{draft.dataToSend.path.rule[i].order = String(i)}));
                    this.setState(produce(draft=>{draft.rule[i].order=String(i)}));
                }
            }, 50);
            
        }
    }
    onSort=async (e)=>{
        //console.log('this.state.0',this.state.ruleWeightageData);
        this.setState(produce(draft=>{draft.sorted=false }));
        let type = e.target.parentElement.id.split("_")[0];
        let index = Number(e.target.parentElement.id.split("_")[2]);
        let order = e.target.parentElement.id.split("_")[3];
        // console.log('type', type);
        // console.log('index',index);
        // console.log('order',order);
        if (index !== undefined && !(index !== index)) {
            if(this.state.dataToSend.path.rule.length>1){
                let tempArray=[];
                let ruleNameArray=[];
                let selectedOptionsArray=[];
                let ruleWeightage = [];
                if(order==="down"){
                    // console.log('order', this.state.dataToSend.path.rule[index].order);
                this.setState(produce(draft=>{draft.newRuleIndex=index+1 }));
                this.setState(produce(draft => { draft.newRulePathIndex = 0}));
                this.setState(produce(draft=>{draft.dataToSend.path.rule[index].order=String(index+1) }));
                this.setState(produce(draft=>{draft.dataToSend.path.rule[index+1].order=String(index) }));
                this.setState(produce(draft=>{draft.rule[index].order=String(index+1) }));
                this.setState(produce(draft=>{draft.rule[index+1].order=String(index) }));
                tempArray.push(this.state.conditionsName[index]);
                this.setState(produce(draft=>{draft.conditionsName[index]= this.state.conditionsName[index+1]}));
                this.setState(produce(draft=>{draft.conditionsName[index+1]= tempArray[0]}));
                ruleNameArray.push(this.state.ruleConditionName[index]);
                this.setState(produce(draft=>{draft.ruleConditionName[index]= this.state.ruleConditionName[index+1]}));
                this.setState(produce(draft=>{draft.ruleConditionName[index+1]= ruleNameArray[0]}));
                selectedOptionsArray.push(this.state.selectedOptions[index]);
                this.setState(produce(draft=>{draft.selectedOptions[index]= this.state.selectedOptions[index+1]}));
                this.setState(produce(draft=>{draft.selectedOptions[index+1]= selectedOptionsArray[0]}));

                ruleWeightage.push(this.state.ruleWeightageData[index]);
                this.setState(produce(draft=>{draft.ruleWeightageData[index]= this.state.ruleWeightageData[index+1]}));
                this.setState(produce(draft=>{draft.ruleWeightageData[index+1]= ruleWeightage[0]}));

                this.setState(produce(draft => { draft.onDefaultPathClick = false }));
                //this.setState(produce(draft => { draft.newRuleIndex = index+1 }));
                this.setState(produce(draft => { draft.bgClrRuleIndex = index+1 }));
                this.setState(produce(draft => { draft.bgClrPathIndex = null }));
                this.setState(produce(draft => { draft.bgClrRulePathIndex = null }));
                
            }
            if(order==="up"){
                // console.log('order', this.state.dataToSend.path.rule[index].order);
                this.setState(produce(draft=>{draft.newRuleIndex=index-1 }));
                this.setState(produce(draft => { draft.newRulePathIndex = 0}));
                this.setState(produce(draft=>{draft.dataToSend.path.rule[index].order=String(index-1) }));
                this.setState(produce(draft=>{draft.dataToSend.path.rule[index-1].order=String(index) }));
                this.setState(produce(draft=>{draft.rule[index].order=String(index-1) }));
                this.setState(produce(draft=>{draft.rule[index-1].order=String(index) }));
                tempArray.push(this.state.conditionsName[index]);
                this.setState(produce(draft=>{draft.conditionsName[index]= this.state.conditionsName[index-1]}));
                this.setState(produce(draft=>{draft.conditionsName[index-1]= tempArray[0]}));
                ruleNameArray.push(this.state.ruleConditionName[index]);
                this.setState(produce(draft=>{draft.ruleConditionName[index]= this.state.ruleConditionName[index-1]}));
                this.setState(produce(draft=>{draft.ruleConditionName[index-1]= ruleNameArray[0]}));
                selectedOptionsArray.push(this.state.selectedOptions[index]);
                this.setState(produce(draft=>{draft.selectedOptions[index]= this.state.selectedOptions[index-1]}));
                this.setState(produce(draft=>{draft.selectedOptions[index-1]= selectedOptionsArray[0]}));
                
                ruleWeightage.push(this.state.ruleWeightageData[index]);
                this.setState(produce(draft=>{draft.ruleWeightageData[index]= this.state.ruleWeightageData[index-1]}));
                this.setState(produce(draft=>{draft.ruleWeightageData[index-1]= ruleWeightage[0]}));

                this.setState(produce(draft => { draft.onDefaultPathClick = false }));
                //this.setState(produce(draft => { draft.newRuleIndex = index-1 }));
                this.setState(produce(draft => { draft.bgClrRuleIndex = index-1 }));
                this.setState(produce(draft => { draft.bgClrPathIndex = null }));
                this.setState(produce(draft => { draft.bgClrRulePathIndex = null }));
                
            }
        }
        setTimeout(() => {
            this.setState(produce(draft=>{draft.dataToSend.path.rule = [...this.state.dataToSend.path.rule].sort((a, b) => {
                return Number(a.order) - Number(b.order)
            }); }))
            this.setState(produce(draft=>{draft.rule = [...this.state.rule].sort((a, b) => {
                return Number(a.order) - Number(b.order)
            }); }))
                // this.calculatePlaceholderName();
                this.filterSelectForConditions();
                this.setState(produce(draft => { draft.sorted = true }));
            }, 50);
        }
    }
    duplicateDefaultPath = (e) => {
        //console.log(index,name);
        let type = e.target.parentElement.id.split("_")[0];
        let index = Number(e.target.parentElement.id.split("_")[1]);
        let index2 = Number(e.target.parentElement.id.split("_")[2]);
        if (typeof type !== 'undefined') {
            if (type === "default") {
                let duplicateValue = this.state.dataToSend.path.is_default[index];
                let outerDefaultDuplicateValue = this.state.is_default[index];
                this.setState(produce(draft => { draft.dataToSend.path.is_default.splice(index + 1, 0, duplicateValue) }))
                this.setState(produce(draft => { draft.is_default.splice(index + 1, 0, outerDefaultDuplicateValue) }))
                this.setState(produce(draft => {
                    draft.defaultWeightageData.pathAddedCount = this.state.defaultWeightageData.pathAddedCount + 1;
                    draft.defaultWeightageData.pathsTotalWeightage = this.state.defaultWeightageData.pathsTotalWeightage + 100;
                }))
                this.setState(produce(draft => { draft.bgClrPathIndex = index }));
                this.setState(produce(draft => { draft.bgClrRuleIndex = null }));
                this.setState(produce(draft => { draft.bgClrRulePathIndex = null }));
                this.setState(produce(draft => { draft.onDefaultPathClick = true }));
                this.setState(produce(draft => { draft.newDefaultPathIndex = index }));
                this.setState(produce(draft => { draft.onRuleClick = false }));
                this.setState(produce(draft => { draft.onFlowClick = false }));
                this.setState(produce(draft => { draft.onPathClick = true }));

                setTimeout(() => {
                    this.recalculateAllDefaultWeightsPercentages("default");
                }, 100);
            }
            else {
                if (type === "rule") {
                    this.duplicateRulePath(index, index2);
                }
            }
        }
    }
    duplicateRulePath = (index,index2) => {
        let duplicateValue = this.state.dataToSend.path.rule[index].path[index2];
        let outerRuleDuplicateValue = this.state.rule[index].path[index2];
        this.setState(produce(draft => { draft.dataToSend.path.rule[index].path.splice(index2 + 1, 0, duplicateValue) }));
        this.setState(produce(draft => { draft.rule[index].path.splice(index2 + 1, 0, outerRuleDuplicateValue) }));
        this.setState(produce(draft => {
            draft.ruleWeightageData[index].pathAddedCount = this.state.ruleWeightageData[index].pathAddedCount + 1;
            draft.ruleWeightageData[index].pathsTotalWeightage = this.state.ruleWeightageData[index].pathsTotalWeightage + 100;
        }))
        this.setState(produce(draft => { draft.bgClrPathIndex = null }));
        this.setState(produce(draft => { draft.bgClrRuleIndex = null }));
        this.setState(produce(draft => { draft.bgClrRulePathIndex = index2 }));
        this.setState(produce(draft => { draft.newRulePathIndex = index2 }));
        this.setState(produce(draft => { draft.onDefaultPathClick = false }));
        this.setState(produce(draft => { draft.onRuleClick = false }));
        this.setState(produce(draft => { draft.onFlowClick = false }));
        this.setState(produce(draft => { draft.onPathClick = true }));

        setTimeout(() => {
            this.recalculateAllDefaultWeightsPercentages("rule",index);
        },100);
    }
    onChangePathSwitch = (e) => {
        //console.log('e',e);
        let type = e.target.name.split("_")[0];
        let index = Number(e.target.name.split("_")[1]);
        let index2 = Number(e.target.name.split("_")[2]);
        //console.log('index', index);
        //console.log('index', index2);
        if (type === "default") {
            this.setState(produce(draft => { draft.dataToSend.path.is_default[index].weight_optimization = !this.state.dataToSend.path.is_default[index].weight_optimization }));
        } else {
            this.setState(produce(draft => { draft.dataToSend.path.rule[index].path[index2].weight_optimization = !this.state.dataToSend.path.rule[index].path[index2].weight_optimization }));
        }
    }
    reCalculatePathWeigtageSwitch = (type) => {
        if (typeof type !== 'undefined') {
            if (type === "default") {
                let counter = 0;
                let total = 0;
                this.state.dataToSend.path.is_default.forEach(function (count) {
                    total++;
                    if (count.weight_optimization === false) {
                        counter++;
                    }
                })
                // console.log('total path:', total);
                // console.log('false weightage', counter);
                this.setState(produce(draft => {
                    draft.defaultWeightageData.pathAddedCount = this.state.defaultWeightageData.pathAddedCount - (counter);
                    draft.defaultWeightageData.pathsTotalWeightage = this.state.defaultWeightageData.pathsTotalWeightage - ((counter) * 100);
                }))
                setTimeout(() => {
                    this.recalculateAllDefaultWeightsPercentages("default");
                }, 200);
            
            }
        }
    }

    addNewOffer = () => {
        this.setState(produce(draft => { draft.offersCounter.push(1)  }));
    }
    addNewLander = () => {
        this.setState(produce(draft => { draft.landersCounter +=1  }));
    }

    onClickNextBtn = () => {
        this.defaultPathClicked(0);
    }
    checkErrors = () => {
        //default-path-lander and offer
        for (let i = 0; i < this.state.dataToSend.path.is_default.length; i++){
            if (this.state.dataToSend.path.is_default[i].path_name === "") {
                this.setState(produce(draft => { draft.errors.default_path_name = `You must add # ${i + 1} default path's name` }));
            } else { this.setState(produce(draft => { draft.errors.default_path_name = null })); }
            
            if (this.props.offersDataReceived.data.length!==0 && this.props.landersDataReceived.data.length!==0) {
                if (this.state.dataToSend.path.is_default[i].path_destination === "Lander & Offer") {
                
                    if (this.state.dataToSend.path.is_default[i].landerId === null || this.state.dataToSend.path.is_default[i].landerId.length === 0) {
                        this.setState(produce(draft => { draft.errors.default_path_lander = `You must add # ${i + 1} default path's lander` }));
                    
                    } else { this.setState(produce(draft => { draft.errors.default_path_lander = null })); }
                }
            }
            if ((this.props.offersDataReceived.data.length!==0 && this.props.landersDataReceived.data.length===0) && this.state.dataToSend.path.is_default[i].path_destination === "Lander & Offer") {
                this.setState(produce(draft => { draft.errors.default_path_lander = `Please select Offer Only in # ${i + 1} default path to create Flow without Lander.` }));
                this.setState(produce(draft => {draft.errors.isLanderExist = false;}));
            }
            if (this.props.offersDataReceived.data.length !== 0) {
                
                if (this.state.dataToSend.path.is_default[i].path_destination === "Offer Only") {
                    this.setState(produce(draft => {draft.errors.default_path_lander = null;}));
                }

                if (this.state.dataToSend.path.is_default[i].offerId === null || this.state.dataToSend.path.is_default[i].offerId.length === 0) {
                    this.setState(produce(draft => { draft.errors.default_path_offer = `You must add # ${i + 1} default path's offer` }));
                    break;
                } else {
                    this.setState(produce(draft => { draft.errors.default_path_offer = null }));
                    if (this.state.dataToSend.path.is_default[i].path_destination === "Lander & Offer" && this.props.landersDataReceived.data.length!==0) {
                        if (this.state.dataToSend.path.is_default[i].landerId === null || this.state.dataToSend.path.is_default[i].landerId.length === 0) {
                            this.setState(produce(draft => { draft.errors.default_path_lander = `You must add # ${i + 1} default path's lander` }));
                            break;
                        } else { this.setState(produce(draft => { draft.errors.default_path_lander = null })); }
                    }
                }
            }
            if (this.props.offersDataReceived.data.length===0 && this.props.landersDataReceived.data.length===0) {
                this.setState(produce(draft => {draft.errors.isOfferExist = false;}));
            }
            let errors = false;
            setTimeout(() => {
                if (this.state.errors.default_path_lander !== null || this.state.errors.default_path_lander !== null || this.state.errors.default_path_name !== null ) {
                    errors = true;
                }
            }, 30);
            if (errors) {
                break;
            }
        }
            //rule-lander-offer
    
            
        if (this.state.dataToSend.path.rule.length > 0) {
            for (let i = 0; i < this.state.dataToSend.path.rule.length; i++) {
                if (this.state.dataToSend.path.rule[i].name === "" && Object.keys(this.state.dataToSend.path.rule[i].conditions).length===0) {
                    this.setState(produce(draft => { draft.errors.rule_name = `You must add # ${i + 1} rule's name` }));
                } else {this.setState(produce(draft => { draft.errors.rule_name = null }));}
                let errors = false;
                for (let j = 0; j < this.state.dataToSend.path.rule[i].path.length; j++) {
                    if (this.state.dataToSend.path.rule[i].path[j].path_name === "") {
                        this.setState(produce(draft => { draft.errors.rule_path_name = `You must add # ${j + 1} path's name of rule # ${i + 1}` }));
                    }else{this.setState(produce(draft => { draft.errors.rule_path_name = null }));}
                    
                    if (this.props.offersDataReceived.data.length!==0 && this.props.landersDataReceived.data.length!==0) {
                        if (this.state.dataToSend.path.rule[i].path[j].path_destination === "Lander & Offer") {
                            if (this.state.dataToSend.path.rule[i].path[j].landerId === null || this.state.dataToSend.path.rule[i].path[j].landerId.length === 0) {
                                this.setState(produce(draft => { draft.errors.rule_path_lander = `You must add # ${j + 1} path's lander of rule no ${i + 1}` }));
                            } else { this.setState(produce(draft => { draft.errors.rule_path_lander = null })); }
                        }
                    }
                    
                    if ((this.props.offersDataReceived.data.length !== 0 && this.props.landersDataReceived.data.length === 0) && this.state.dataToSend.path.rule[i].path[j].path_destination === "Lander & Offer") {
                        this.setState(produce(draft => { draft.errors.rule_path_lander = `Please select *Offer Only* in # ${j + 1} path of rule # ${i + 1} to create Flow without Lander.` }));
                    }

                    if (this.props.offersDataReceived.data.length !== 0) {
                        if (this.state.dataToSend.path.rule[i].path[j].path_destination === "Offer Only") {
                            this.setState(produce(draft => { draft.errors.rule_path_lander = null }));
                        }
                        
                            if ((this.state.dataToSend.path.rule[i].path[j].offerId === null || this.state.dataToSend.path.rule[i].path[j].offerId.length === 0)) {
                                this.setState(produce(draft => { draft.errors.rule_path_offer = `You must add # ${j + 1} path's offer of rule # ${i + 1}` }));
                                errors = true;
                                break;
                            } else {
                                this.setState(produce(draft => { draft.errors.rule_path_offer = null }));
                                if (this.state.dataToSend.path.rule[i].path[j].path_destination === "Lander & Offer" && this.props.landersDataReceived.data.length!==0) {
                                    if (this.state.dataToSend.path.rule[i].path[j].landerId === null || this.state.dataToSend.path.rule[i].path[j].landerId.length === 0) {
                                        errors = true;
                                        this.setState(produce(draft => { draft.errors.rule_path_lander = `You must add # ${j + 1} path's lander of rule # ${i + 1}` }));
                                        break;
                                    } else { this.setState(produce(draft => { draft.errors.rule_path_lander = null })); }
                            
                            }
                        }
                    }
                    setTimeout(() => {
                        if (this.state.errors.rule_path_offer !== null || this.state.errors.rule_path_lander !== null || this.state.errors.rule_name !== null || this.state.errors.rule_path_name !== null) {
                            errors = true;
                        }
                    }, 10);
                    if (errors) {
                        break;
                    }
                }
                    setTimeout(() => {
                        if (this.state.errors.rule_path_offer !== null || this.state.errors.rule_path_lander !== null || this.state.errors.rule_name !== null || this.state.errors.rule_path_name !== null) {
                            errors = true;
                        }
                    }, 10);
                if (errors) {
                    break;
                }
            }
            
            
        }
        
        
        // name
        if (this.state.dataToSend.flow_name === "" ) {
            this.setState(produce(draft => { draft.errors.name = "You must add a flow name." }));
        } else { this.setState(produce(draft => { draft.errors.name = null })); }
        // workspace Id
        if (this.state.dataToSend.workspaceId === "" ) {
            this.setState(produce(draft => { draft.errors.workspaceId = "You must add a workspace before you add a campaign." }));
        } else { this.setState(produce(draft => { draft.errors.workspaceId = null })); }


    }

    onModalSubmit = () => {
        if (this.state.errors.isOfferExist) {
            this.checkErrors();
            setTimeout(() => {
                if (this.state.errors.name === null && this.state.errors.default_path_name === null && this.state.errors.rule_path_name === null && this.state.errors.rule_name === null && this.state.errors.workspaceId === null && this.state.errors.default_path_lander === null && this.state.errors.default_path_offer === null && this.state.errors.rule_path_lander === null && this.state.errors.rule_path_offer === null && this.state.errors.isOfferExist) {
                    if (this.state.dataToSend.path.rule.length > 0) {
                        for (let i = 0; i < this.state.dataToSend.path.rule.length; i++) {
                            if(this.state.dataToSend.path.rule[i].name===""){
                                if(this.state.ruleConditionName[i].length>0){
                                    this.setState(produce(draft=>{draft.dataToSend.path.rule[i].name = this.state.ruleConditionName[i][0]}));
                                }
                            }
                        }
                    }
                    this.setState(produce(draft => { draft.dataToSend.workspaceId = String(draft.dataToSend.workspaceId) }));
                    this.setState(produce(draft => { draft.dataToSend.countryId = String(draft.dataToSend.countryId) }));
                    this.setState(produce(draft => { draft.sendingRequest = true }));
                    
                }
                else {
                    this.executeScroll();
                }
            }, 50);
        }
    }


    addConditions = (data) => {
        //console.log('data',data);
        // console.log('add conditions ka pura data', data);
        // console.log('state ka pura data', this.state);
        this.setState(produce(draft => { draft.sorted = true }));
        if (data.select === "connection") {
            if (!this.state.conditionsName[this.state.newRuleIndex].includes("Connection type")) {
                this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push("Connection type") }));
            }
            const connectionType = {
                connectionType: {
                    "predicate": "MUST_BE",
                    "connection":[],
                }
            }
            for (let i = 0; i < data.cityValue.length; i++){
                const { id, update_at, create_at, ...qux } = data.cityValue[i];
                connectionType.connectionType.connection.push(qux);
            }
            if (data.predicate === false) {
                const value = connectionType.connectionType;
                value.predicate = "MUST_NOT_BE";
                this.setState({connectionType});
            }
            //this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].conditions = connectionType }));
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, connectionType)  }));
        }

        if (data.select === "weekday") {
            if (!this.state.conditionsName[this.state.newRuleIndex].includes("Weekday")) {
                this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push("Weekday") }));
            }
            const weekday = {
                weekday: {
                    "predicate": "MUST_BE",
                    "weekdays":[],
                }
            }
            for (let i = 0; i < data.cityValue.length; i++){
                const { id, create_at, ...qux } = data.cityValue[i];
                weekday.weekday.weekdays.push(qux);
            }
            if (data.predicate === false) {
                const value = weekday.weekday;
                value.predicate = "MUST_NOT_BE";
                this.setState({weekday});
            }
            //this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].conditions = connectionType }));
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, weekday)  }));
        }

        if (data.select === "device") {
            if (!this.state.conditionsName[this.state.newRuleIndex].includes("Device type")) {
                this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push("Device type") }));
            }
            const deviceType = {
                deviceType: {
                    "predicate": "MUST_BE",
                    "device":[],
                }
            }
            for (let i = 0; i < data.cityValue.length; i++){
                const { id, update_at, create_at, ...qux } = data.cityValue[i];
                deviceType.deviceType.device.push(qux);
            }
            if (data.predicate === false) {
                const value = deviceType.deviceType;
                value.predicate = "MUST_NOT_BE";
                this.setState({deviceType});
            }
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, deviceType)  }));
            //this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].conditions = deviceType }));
        }
        if (data.select === "language") {
            if (!this.state.conditionsName[this.state.newRuleIndex].includes("Language")) {
                this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push("Language") }));
            }
            const language = {
                language: {
                    "predicate": "MUST_BE",
                    "languages":[],
                }
            }
            for (let i = 0; i < data.cityValue.length; i++){
                const { id, update_at, create_at, ...qux } = data.cityValue[i];
                language.language.languages.push(qux);
            }
            if (data.predicate === false) {
                const value = language.language;
                value.predicate = "MUST_NOT_BE";
                this.setState({language});
            }
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, language)  }));
            //this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].conditions = deviceType }));
        }
        if (data.select === "proxy") {
            if (!this.state.conditionsName[this.state.newRuleIndex].includes("Proxy")) {
                this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push("Proxy") }));
            }
            const proxy = {
                proxy: {
                    "predicate": "MUST_BE",
                    "proxies":[],
                }
            }
            for (let i = 0; i < data.cityValue.length; i++){
                const { id, update_at, create_at, ...qux } = data.cityValue[i];
                proxy.proxy.proxies.push(qux);
            }
            if (data.predicate === false) {
                const value = proxy.proxy;
                value.predicate = "MUST_NOT_BE";
                this.setState({proxy});
            }
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, proxy)  }));
            //this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].conditions = deviceType }));
        }
        if (data.select === "IP") {
            if (!this.state.conditionsName[this.state.newRuleIndex].includes("IP")) {
                this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push("IP") }));
            }
            const ip = {
                ip: {
                    "predicate": "MUST_BE",
                    "ips":[],
                }
            }
            this.setState(produce(draft => { draft.tempIP =  data.ip}));
            let ipData = data.ip.split(',');
            //console.log('id',ipData);
            for (let i = 0; i < ipData.length; i++){
                ip.ip.ips.push({"name":ipData[i]}) 
            }
            // ip.ip.ips=ipData;
            //console.log('ip',ip);

            if (data.predicate === false) {
                const value = ip.ip;
                value.predicate = "MUST_NOT_BE";
                this.setState({ip});
            }
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, ip)  }));
        }
        if (data.select === "Referrer") {
            if (!this.state.conditionsName[this.state.newRuleIndex].includes("Referrer")) {
                this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push("Referrer") }));
            }
            const referrer = {
                referrer: {
                    "predicate": "MUST_BE",
                    "is_empty":false,
                    "referrers":[],
                }
            }

            this.setState(produce(draft => { draft.tempreferrer =  data.domain}));
            let referrerData = data.domain.split(',');
            referrer.referrer.is_empty = data.is_empty;
            //console.log('id',referrerData);
            for (let i = 0; i < referrerData.length; i++){
                referrer.referrer.referrers.push({"name":referrerData[i]}) 
            }
            // referrer.referrer.referrers=referrerData;
            //console.log('referrer',referrer);

            if (data.predicate === false) {
                const value = referrer.referrer;
                value.predicate = "MUST_NOT_BE";
                this.setState({referrer});
            }
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, referrer)  }));
        }
        if(data.select.includes("Custom variable")){
            if(data.select === "Custom variable 1"){
                if (!this.state.conditionsName[this.state.newRuleIndex].includes(`Custom variable 1`)) {
                    this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push(`Custom variable 1`) }));
                }
                const varr = {
                    var1: {
                        "predicate": "MUST_BE",
                        "is_empty":false,
                        "var1s":[],
                    }
                }
                let varData = data.customVar.split(',');
                varr.var1.predicate=data.iRSelectedValue;
                varr.var1.is_empty = data.is_empty;
                for (let i = 0; i < varData.length; i++){
                    varr.var1.var1s.push({"name": varData[i]}) 
                }
                this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, varr)}));

            }
            if(data.select === "Custom variable 2"){
                if (!this.state.conditionsName[this.state.newRuleIndex].includes(`Custom variable 2`)) {
                    this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push(`Custom variable 2`) }));
                }
                const varr = {
                    var2: {
                        "predicate": "MUST_BE",
                        "is_empty":false,
                        "var2s":[],
                    }
                }
                let varData = data.customVar.split(',');
                varr.var2.predicate=data.iRSelectedValue;
                varr.var2.is_empty = data.is_empty;
                for (let i = 0; i < varData.length; i++){
                    varr.var2.var2s.push({"name": varData[i]}) 
                }
                this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, varr)}));
            }
            if(data.select === "Custom variable 3"){
                if (!this.state.conditionsName[this.state.newRuleIndex].includes(`Custom variable 3`)) {
                    this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push(`Custom variable 3`) }));
                }
                const varr = {
                    var3: {
                        "predicate": "MUST_BE",
                        "is_empty":false,
                        "var3s":[],
                    }
                }
                let varData = data.customVar.split(',');
                varr.var3.predicate=data.iRSelectedValue;
                varr.var3.is_empty = data.is_empty;
                for (let i = 0; i < varData.length; i++){
                    varr.var3.var3s.push({"name": varData[i]}) 
                }
                this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, varr)}));
            }
            if(data.select === "Custom variable 4"){
                if (!this.state.conditionsName[this.state.newRuleIndex].includes(`Custom variable 4`)) {
                    this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push(`Custom variable 4`) }));
                }
                const varr = {
                    var4: {
                        "predicate": "MUST_BE",
                        "is_empty":false,
                        "var4s":[],
                    }
                }
                let varData = data.customVar.split(',');
                varr.var4.predicate=data.iRSelectedValue;
                varr.var4.is_empty = data.is_empty;
                for (let i = 0; i < varData.length; i++){
                    varr.var4.var4s.push({"name": varData[i]}) 
                }
                this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, varr)}));
            }
            if(data.select === "Custom variable 5"){
                if (!this.state.conditionsName[this.state.newRuleIndex].includes(`Custom variable 5`)) {
                    this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push(`Custom variable 5`) }));
                }
                const varr = {
                    var5: {
                        "predicate": "MUST_BE",
                        "is_empty":false,
                        "var5s":[],
                    }
                }
                let varData = data.customVar.split(',');
                varr.var5.predicate=data.iRSelectedValue;
                varr.var5.is_empty = data.is_empty;
                for (let i = 0; i < varData.length; i++){
                    varr.var5.var5s.push({"name": varData[i]}) 
                }
                this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, varr)}));
            }
            if(data.select === "Custom variable 6"){
                if (!this.state.conditionsName[this.state.newRuleIndex].includes(`Custom variable 6`)) {
                    this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push(`Custom variable 6`) }));
                }
                const varr = {
                    var6: {
                        "predicate": "MUST_BE",
                        "is_empty":false,
                        "var6s":[],
                    }
                }
                let varData = data.customVar.split(',');
                varr.var6.predicate=data.iRSelectedValue;
                varr.var6.is_empty = data.is_empty;
                for (let i = 0; i < varData.length; i++){
                    varr.var6.var6s.push({"name": varData[i]}) 
                }
                this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, varr)}));
            }
            if(data.select === "Custom variable 7"){
                if (!this.state.conditionsName[this.state.newRuleIndex].includes(`Custom variable 7`)) {
                    this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push(`Custom variable 7`) }));
                }
                const varr = {
                    var7: {
                        "predicate": "MUST_BE",
                        "is_empty":false,
                        "var7s":[],
                    }
                }
                let varData = data.customVar.split(',');
                varr.var7.predicate=data.iRSelectedValue;
                varr.var7.is_empty = data.is_empty;
                for (let i = 0; i < varData.length; i++){
                    varr.var7.var7s.push({"name": varData[i]}) 
                }
                this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, varr)}));
            }
            if(data.select === "Custom variable 8"){
                if (!this.state.conditionsName[this.state.newRuleIndex].includes(`Custom variable 8`)) {
                    this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push(`Custom variable 8`) }));
                }
                const varr = {
                    var8: {
                        "predicate": "MUST_BE",
                        "is_empty":false,
                        "var8s":[],
                    }
                }
                let varData = data.customVar.split(',');
                varr.var8.predicate=data.iRSelectedValue;
                varr.var8.is_empty = data.is_empty;
                for (let i = 0; i < varData.length; i++){
                    varr.var8.var8s.push({"name": varData[i]}) 
                }
                this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, varr)}));
            }
            if(data.select === "Custom variable 9"){
                if (!this.state.conditionsName[this.state.newRuleIndex].includes(`Custom variable 9`)) {
                    this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push(`Custom variable 9`) }));
                }
                const varr = {
                    var9: {
                        "predicate": "MUST_BE",
                        "is_empty":false,
                        "var9s":[],
                    }
                }
                let varData = data.customVar.split(',');
                varr.var9.predicate=data.iRSelectedValue;
                varr.var9.is_empty = data.is_empty;
                for (let i = 0; i < varData.length; i++){
                    varr.var9.var9s.push({"name": varData[i]}) 
                }
                this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, varr)}));
            }
            if(data.select === "Custom variable 10"){
                if (!this.state.conditionsName[this.state.newRuleIndex].includes(`Custom variable 10`)) {
                    this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push(`Custom variable 10`) }));
                }
                const varr = {
                    var10: {
                        "predicate": "MUST_BE",
                        "is_empty":false,
                        "var10s":[],
                    }
                }
                let varData = data.customVar.split(',');
                varr.var10.predicate=data.iRSelectedValue;
                varr.var10.is_empty = data.is_empty;
                for (let i = 0; i < varData.length; i++){
                    varr.var10.var10s.push({"name": varData[i]}) 
                }
                this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, varr)}));
            }

            if(data.select === "Custom variable 11"){
                if (!this.state.conditionsName[this.state.newRuleIndex].includes(`Custom variable 11`)) {
                    this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push(`Custom variable 11`) }));
                }
                const varr = {
                    var11: {
                        "predicate": "MUST_BE",
                        "is_empty":false,
                        "var11s":[],
                    }
                }
                let varData = data.customVar.split(',');
                varr.var11.predicate=data.iRSelectedValue;
                varr.var11.is_empty = data.is_empty;
                for (let i = 0; i < varData.length; i++){
                    varr.var11.var11s.push({"name": varData[i]}) 
                }
                this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, varr)}));

            }
            if(data.select === "Custom variable 12"){
                if (!this.state.conditionsName[this.state.newRuleIndex].includes(`Custom variable 12`)) {
                    this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push(`Custom variable 12`) }));
                }
                const varr = {
                    var12: {
                        "predicate": "MUST_BE",
                        "is_empty":false,
                        "var12s":[],
                    }
                }
                let varData = data.customVar.split(',');
                varr.var12.predicate=data.iRSelectedValue;
                varr.var12.is_empty = data.is_empty;
                for (let i = 0; i < varData.length; i++){
                    varr.var12.var12s.push({"name": varData[i]}) 
                }
                this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, varr)}));
            }
            if(data.select === "Custom variable 13"){
                if (!this.state.conditionsName[this.state.newRuleIndex].includes(`Custom variable 13`)) {
                    this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push(`Custom variable 13`) }));
                }
                const varr = {
                    var13: {
                        "predicate": "MUST_BE",
                        "is_empty":false,
                        "var13s":[],
                    }
                }
                let varData = data.customVar.split(',');
                varr.var13.predicate=data.iRSelectedValue;
                varr.var13.is_empty = data.is_empty;
                for (let i = 0; i < varData.length; i++){
                    varr.var13.var13s.push({"name": varData[i]}) 
                }
                this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, varr)}));
            }
            if(data.select === "Custom variable 14"){
                if (!this.state.conditionsName[this.state.newRuleIndex].includes(`Custom variable 14`)) {
                    this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push(`Custom variable 14`) }));
                }
                const varr = {
                    var14: {
                        "predicate": "MUST_BE",
                        "is_empty":false,
                        "var14s":[],
                    }
                }
                let varData = data.customVar.split(',');
                varr.var14.predicate=data.iRSelectedValue;
                varr.var14.is_empty = data.is_empty;
                for (let i = 0; i < varData.length; i++){
                    varr.var14.var14s.push({"name": varData[i]}) 
                }
                this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, varr)}));
            }
            if(data.select === "Custom variable 15"){
                if (!this.state.conditionsName[this.state.newRuleIndex].includes(`Custom variable 15`)) {
                    this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push(`Custom variable 15`) }));
                }
                const varr = {
                    var15: {
                        "predicate": "MUST_BE",
                        "is_empty":false,
                        "var15s":[],
                    }
                }
                let varData = data.customVar.split(',');
                varr.var15.predicate=data.iRSelectedValue;
                varr.var15.is_empty = data.is_empty;
                for (let i = 0; i < varData.length; i++){
                    varr.var15.var15s.push({"name": varData[i]}) 
                }
                this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, varr)}));
            }
            if(data.select === "Custom variable 16"){
                if (!this.state.conditionsName[this.state.newRuleIndex].includes(`Custom variable 16`)) {
                    this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push(`Custom variable 16`) }));
                }
                const varr = {
                    var16: {
                        "predicate": "MUST_BE",
                        "is_empty":false,
                        "var16s":[],
                    }
                }
                let varData = data.customVar.split(',');
                varr.var16.predicate=data.iRSelectedValue;
                varr.var16.is_empty = data.is_empty;
                for (let i = 0; i < varData.length; i++){
                    varr.var16.var16s.push({"name": varData[i]}) 
                }
                this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, varr)}));
            }
            if(data.select === "Custom variable 17"){
                if (!this.state.conditionsName[this.state.newRuleIndex].includes(`Custom variable 17`)) {
                    this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push(`Custom variable 17`) }));
                }
                const varr = {
                    var17: {
                        "predicate": "MUST_BE",
                        "is_empty":false,
                        "var17s":[],
                    }
                }
                let varData = data.customVar.split(',');
                varr.var17.predicate=data.iRSelectedValue;
                varr.var17.is_empty = data.is_empty;
                for (let i = 0; i < varData.length; i++){
                    varr.var17.var17s.push({"name": varData[i]}) 
                }
                this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, varr)}));
            }
            if(data.select === "Custom variable 18"){
                if (!this.state.conditionsName[this.state.newRuleIndex].includes(`Custom variable 18`)) {
                    this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push(`Custom variable 18`) }));
                }
                const varr = {
                    var18: {
                        "predicate": "MUST_BE",
                        "is_empty":false,
                        "var18s":[],
                    }
                }
                let varData = data.customVar.split(',');
                varr.var18.predicate=data.iRSelectedValue;
                varr.var18.is_empty = data.is_empty;
                for (let i = 0; i < varData.length; i++){
                    varr.var18.var18s.push({"name": varData[i]}) 
                }
                this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, varr)}));
            }
            if(data.select === "Custom variable 19"){
                if (!this.state.conditionsName[this.state.newRuleIndex].includes(`Custom variable 19`)) {
                    this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push(`Custom variable 19`) }));
                }
                const varr = {
                    var19: {
                        "predicate": "MUST_BE",
                        "is_empty":false,
                        "var19s":[],
                    }
                }
                let varData = data.customVar.split(',');
                varr.var19.predicate=data.iRSelectedValue;
                varr.var19.is_empty = data.is_empty;
                for (let i = 0; i < varData.length; i++){
                    varr.var19.var19s.push({"name": varData[i]}) 
                }
                this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, varr)}));
            }
            if(data.select === "Custom variable 20"){
                if (!this.state.conditionsName[this.state.newRuleIndex].includes(`Custom variable 20`)) {
                    this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push(`Custom variable 20`) }));
                }
                const varr = {
                    var20: {
                        "predicate": "MUST_BE",
                        "is_empty":false,
                        "var20s":[],
                    }
                }
                let varData = data.customVar.split(',');
                varr.var20.predicate=data.iRSelectedValue;
                varr.var20.is_empty = data.is_empty;
                for (let i = 0; i < varData.length; i++){
                    varr.var20.var20s.push({"name": varData[i]}) 
                }
                this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, varr)}));
            }
        }
        if (data.select === "Referrer domain") {
            if (!this.state.conditionsName[this.state.newRuleIndex].includes("Referrer domain")) {
                this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push("Referrer domain") }));
            }
            const referrerDomain = {
                referrerDomain: {
                    "predicate": "MUST_BE",
                    "is_empty":false,
                    "referrerDomains":[],
                }
            }
            this.setState(produce(draft => { draft.tempreferrerDomain =  data.domain}));
            let referrerDomainData = data.domain.split(',');
            referrerDomain.referrerDomain.is_empty = data.is_empty;
            //console.log('id',referrerDomainData);
            for (let i = 0; i < referrerDomainData.length; i++){
                referrerDomain.referrerDomain.referrerDomains.push({"name":referrerDomainData[i]}) 
            }
            // referrerDomain.referrerDomain.referrerDomains=referrerDomainData;
            //console.log('referrerDomain',referrerDomain);

            if (data.predicate === false) {
                const value = referrerDomain.referrerDomain;
                value.predicate = "MUST_NOT_BE";
                this.setState({referrerDomain});
            }
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, referrerDomain)  }));
        }
        if (data.select === "timezone") {
            if (!this.state.conditionsName[this.state.newRuleIndex].includes("Time of day")) {
                this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push("Time of day") }));
            }
            const timeofday = {
                timeofday: {
                    "predicate": "MUST_BE",
                    "timezone":[],
                }
            }
            this.setState(produce(draft => { 
                draft.fromh =  data.fromh;
                draft.fromm =  data.fromm;
                draft.toh =  data.toh;
                draft.tom =  data.tom    
            }));
            let from = data.dataToSend.from;
            let to = data.dataToSend.to;
            let timezoneId = String(data.dataToSend.timeZoneId);
            timeofday.timeofday.timezone.push(from,to,timezoneId);

            if (data.predicate === false) {
                const value = timeofday.timeofday;
                value.predicate = "MUST_NOT_BE";
                this.setState({timeofday});
            }
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, timeofday)  }));
            //this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].conditions = deviceType }));
        }
        if (data.select === "region") {
            if (!this.state.conditionsName[this.state.newRuleIndex].includes("Region")) {
                this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push("Region") }));
            }
            const region = {
                region: {
                    "predicate": "MUST_BE",
                    "regions":[],
                }
            }
            for (let i = 0; i < data.cityValue.length; i++){
                const { id, update_at, create_at, ...qux } = data.cityValue[i];
                region.region.regions.push(qux);
            }
            if (data.predicate === false) {
                const value = region.region;
                value.predicate = "MUST_NOT_BE";
                this.setState({region});
            }
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, region)  }));
            //this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].conditions = deviceType }));
        }
        if (data.select === "city") {
            if (!this.state.conditionsName[this.state.newRuleIndex].includes("City")) {
                this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push("City") }));
            }
            const city = {
                city: {
                    "predicate": "MUST_BE",
                    "cities":[],
                }
            }
            for (let i = 0; i < data.cityValue.length; i++){
                const { update_at, create_at, ...qux } = data.cityValue[i];
                city.city.cities.push(qux);
            }
            if (data.predicate === false) {
                const value = city.city;
                value.predicate = "MUST_NOT_BE";
                this.setState({city});
            }
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, city)  }));
            //this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].conditions = deviceType }));
        }
        if (data.select === "Mobile carrier") {
            if (!this.state.conditionsName[this.state.newRuleIndex].includes(data.select)) {
                this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push(data.select) }));
            }
            const mobileCarrier = {
                mobileCarrier: {
                    "predicate": "MUST_BE",
                    "mobileCarrieries":[],
                }
            }
            for (let i = 0; i < data.cityValue.length; i++){
                const { update_at, create_at, ...qux } = data.cityValue[i];
                mobileCarrier.mobileCarrier.mobileCarrieries.push(qux);
            }
            if (data.predicate === false) {
                const value = mobileCarrier.mobileCarrier;
                value.predicate = "MUST_NOT_BE";
                this.setState({mobileCarrier});
            }
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, mobileCarrier)  }));
            //this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].conditions = deviceType }));
        }

        if (data.select === "country") {
            if (!this.state.conditionsName[this.state.newRuleIndex].includes("Country")) {
                this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push("Country") }));
            }
            const country = {
                country: {
                    "predicate": "MUST_BE",
                    "countries":[],
                }
            }
            for (let i = 0; i < data.cityValue.length; i++){
                const { id, update_at, create_at, ...qux } = data.cityValue[i];
                country.country.countries.push(qux);
            }
            if (data.predicate === false) {
                const value = country.country;
                value.predicate = "MUST_NOT_BE";
                this.setState({country});
            }
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, country)  }));
            //this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].conditions = deviceType }));
        }

        if (data.select === "isp") {
            if (!this.state.conditionsName[this.state.newRuleIndex].includes("ISP")) {
                this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push("ISP") }));
            }
            const isp = {
                isp: {
                    "predicate": "MUST_BE",
                    "ispies":[],
                }
            }
            for (let i = 0; i < data.cityValue.length; i++){
                const { id, update_at, create_at, ...qux } = data.cityValue[i];
                isp.isp.ispies.push(qux);
            }
            if (data.predicate === false) {
                const value = isp.isp;
                value.predicate = "MUST_NOT_BE";
                this.setState({isp});
            }
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, isp)  }));
            //this.setState(produce(draft => { draft.dataToSend.path.rule[draft.newRuleIndex].conditions = deviceType }));
        }
        if (data.select === "brand") {
            if (!this.state.conditionsName[this.state.newRuleIndex].includes("Brand")) {
                this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push("Brand") }));
            }
            const brand = {
                brand: {
                    "predicate": "MUST_BE",
                    "brands":[],
                }
            }
            const tempObj = [];
            let countModels = 0;
            for (let i = 0; i < this.props.brandDataReceived.length; i++){
                for (let j = 0; j < data.models.length; j++){
                    if (data.models[j] === this.props.brandDataReceived[i].name) {
                        tempObj.push(this.props.brandDataReceived[i]);
                        countModels++;
                    }
                    if (countModels === data.models.length) {
                        break;
                    }
                }
                if (countModels === data.models.length) {
                    break;
                }
            }
            let tempBrand = [];
            for (let i = 0; i < tempObj.length; i++){
                const { id, update_at, create_at, ...qux } = tempObj[i];
                tempBrand.push(qux);
            }

            for (let i = 0; i < tempBrand.length; i++){
                tempBrand[i].models = tempBrand[i].models.filter( model => data.tagsData.includes(model))
            }

            
            if (data.predicate === false) {
                const value = brand.brand;
                value.predicate = "MUST_NOT_BE";
                this.setState({brand});
            }

            brand.brand.brands.push(tempBrand);
            //console.log('bands value', brand.brand);

            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, brand)  }));
        }

        if (data.select === "browser") {
            if (!this.state.conditionsName[this.state.newRuleIndex].includes("Browser")) {
                this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push("Browser") }));
            }
            const browser = {
                browser: {
                    "predicate": "MUST_BE",
                    "browsers":[],
                }
            }
            const tempObj = [];
            let countModels = 0;
            for (let i = 0; i < this.props.browserDataReceived.length; i++){
                for (let j = 0; j < data.models.length; j++){
                    if (data.models[j] === this.props.browserDataReceived[i].name) {
                        tempObj.push(this.props.browserDataReceived[i]);
                        countModels++;
                    }
                    if (countModels === data.models.length) {
                        break;
                    }
                }
                if (countModels === data.models.length) {
                    break;
                }
            }
            let tempBrand = [];
            for (let i = 0; i < tempObj.length; i++){
                const { id, update_at, create_at, ...qux } = tempObj[i];
                tempBrand.push(qux);
            }

            for (let i = 0; i < tempBrand.length; i++){
                tempBrand[i].version = tempBrand[i].version.filter( version => data.tagsData.includes(version))
            }

            if (data.predicate === false) {
                const value = browser.browser;
                value.predicate = "MUST_NOT_BE";
                this.setState({browser});
            }
            browser.browser.browsers.push(tempBrand);
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, browser)  }));
        }

        if (data.select === "operating") {
            if (!this.state.conditionsName[this.state.newRuleIndex].includes("Operating system")) {
                this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex].push("Operating system") }));
            }
            const os = {
                os: {
                    "predicate": "MUST_BE",
                    "oss":[],
                }
            }
            const tempObj = [];
            let countModels = 0;
            for (let i = 0; i < this.props.osDataReceived.length; i++){
                for (let j = 0; j < data.models.length; j++){
                    if (data.models[j] === this.props.osDataReceived[i].name) {
                        tempObj.push(this.props.osDataReceived[i]);
                        countModels++;
                    }
                    if (countModels === data.models.length) {
                        break;
                    }
                }
                if (countModels === data.models.length) {
                    break;
                }
            }
            let tempBrand = [];
            for (let i = 0; i < tempObj.length; i++){
                const { id, update_at, create_at, ...qux } = tempObj[i];
                tempBrand.push(qux);
            }

            for (let i = 0; i < tempBrand.length; i++){
                tempBrand[i].version = tempBrand[i].version.filter( version => data.tagsData.includes(version))
            }
            
            if (data.predicate === false) {
                const value = os.os;
                value.predicate = "MUST_NOT_BE";
                this.setState({os});
            }
            os.os.oss.push(tempBrand);
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions, os)  }));
        }
        // if(data.select === "IP"){
        //     console.log('add ip in conditons');
        // }
        if (!this.state.selectedOptions[this.state.newRuleIndex].includes(data.select)) {
            this.setState(produce(draft => { draft.selectedOptions[draft.newRuleIndex].push(data.select) }));
        }
        setTimeout(() => {
            this.filterSelectForConditions();

        }, 100);
        this.calculatePlaceholderName();
        //this.showRuleConditionsName();
    }

    showCondtionsName = (name) => {
                
            //console.log('name', name);
            // console.log('this.state.newRuleIndex',this.state.newRuleIndex);
            if (name === "Connection type") {
                if (this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.connectionType.predicate === "MUST_BE") {
                    return (<div className="condition-name-green">{name}</div>)
                } else { return (<div className="condition-name-red">{name}</div>) }
            }
    
            if (name === "Device type") {
                if (this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.deviceType.predicate==="MUST_BE") {
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }
            if (name === "Brand") {
                if (this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.brand.predicate==="MUST_BE") {
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }
            if (name === "Browser") {
                if (this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.browser.predicate==="MUST_BE") {
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }if (name === "Operating system") {
                if (this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.os.predicate==="MUST_BE") {
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }if (name === "Region") {
                if (this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.region.predicate==="MUST_BE") {
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }if (name === "City") {
                if (this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.city.predicate==="MUST_BE") {
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }if (name === "Mobile carrier") {
                if (this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.mobileCarrier.predicate==="MUST_BE") {
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }if (name === "Country") {
                if (this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.country.predicate==="MUST_BE") {
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }if (name === "Proxy") {
                if (this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.proxy.predicate==="MUST_BE") {
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }if (name === "ISP") {
                if (this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.isp.predicate==="MUST_BE") {
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }
            if (name === "Time of day") {
                if (this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.timeofday.predicate==="MUST_BE") {
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }
            if (name === "Language") {
                if (this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.language.predicate==="MUST_BE") {
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }
    
            if (name === "Weekday") {
                if (this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.weekday.predicate==="MUST_BE") {
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }
            if(name ==="IP"){
                if (this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.ip.predicate==="MUST_BE") {
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }
            if(name ==="Referrer"){
                if (this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.referrer.predicate==="MUST_BE") {
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }
            if(name ==="Referrer domain"){
                if (this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.referrerDomain.predicate==="MUST_BE") {
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }
            if(name ==="Custom variable 1" ){
                if (this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var1.predicate==="MUST_BE" 
                || this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var1.predicate==="CONTAIN" ) {
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }
            
            if(name ==="Custom variable 2"){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var2.predicate==="MUST_BE" 
                || this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var2.predicate==="CONTAIN"){
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }
            if(name ==="Custom variable 3"){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var3.predicate==="MUST_BE" 
                || this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var3.predicate==="CONTAIN"){
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }
            if(name ==="Custom variable 4"){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var4.predicate==="MUST_BE" 
                || this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var4.predicate==="CONTAIN"){
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }
            if(name ==="Custom variable 5"){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var5.predicate==="MUST_BE" 
                || this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var5.predicate==="CONTAIN"){
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }
            if(name ==="Custom variable 6"){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var6.predicate==="MUST_BE" 
                || this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var6.predicate==="CONTAIN"){
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }
            if(name ==="Custom variable 7"){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var7.predicate==="MUST_BE" 
                || this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var7.predicate==="CONTAIN"){
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }

            if(name ==="Custom variable 8"){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var8.predicate==="MUST_BE" 
                || this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var8.predicate==="CONTAIN"){
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }
            if(name ==="Custom variable 9"){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var9.predicate==="MUST_BE" 
                || this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var9.predicate==="CONTAIN"){
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }
            if(name ==="Custom variable 10"){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var10.predicate==="MUST_BE" 
                || this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var10.predicate==="CONTAIN"){
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }

            if(name ==="Custom variable 11" ){
                if (this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var11.predicate==="MUST_BE" 
                || this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var11.predicate==="CONTAIN" ) {
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }
            
            if(name ==="Custom variable 12"){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var12.predicate==="MUST_BE" 
                || this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var12.predicate==="CONTAIN"){
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }
            if(name ==="Custom variable 13"){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var13.predicate==="MUST_BE" 
                || this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var13.predicate==="CONTAIN"){
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }
            if(name ==="Custom variable 14"){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var14.predicate==="MUST_BE" 
                || this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var14.predicate==="CONTAIN"){
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }
            if(name ==="Custom variable 15"){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var15.predicate==="MUST_BE" 
                || this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var15.predicate==="CONTAIN"){
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }
            if(name ==="Custom variable 16"){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var16.predicate==="MUST_BE" 
                || this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var16.predicate==="CONTAIN"){
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }
            if(name ==="Custom variable 17"){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var17.predicate==="MUST_BE" 
                || this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var17.predicate==="CONTAIN"){
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }

            if(name ==="Custom variable 18"){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var18.predicate==="MUST_BE" 
                || this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var18.predicate==="CONTAIN"){
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }
            if(name ==="Custom variable 19"){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var19.predicate==="MUST_BE" 
                || this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var19.predicate==="CONTAIN"){
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }
            if(name ==="Custom variable 20"){
                if(this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var20.predicate==="MUST_BE" 
                || this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var20.predicate==="CONTAIN"){
                    return (<div className="condition-name-green">{name}</div>)}
                else {return (<div className="condition-name-red">{name}</div>)}
            }
    }
    
    
    showConditionValues = (data) => {
        //console.log('value',data);
        //console.log('states data',this.state);
        //console.log('sara state ka data', this.state);
        if (data === "Connection type") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.connectionType.connection.map(name=>(
                    <>{name.name}/</>
                    ))}
                </>
            )
        }
        if (data === "Device type") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.deviceType.device.map(name=>(
                    <>{name.name}/</>
                    ))}
                </>
            )
        }
        if (data === "Region") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.region.regions.map(name=>(
                    <>{name.name}/</>
                    ))}
                </>
            )
        }

        if (data === "Proxy") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.proxy.proxies.map(name=>(
                    <>{name.name}/</>
                    ))}
                </>
            )
        }

        if (data === "City") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.city.cities.map(name=>(
                    <>{name.name}/</>
                    ))}
                </>
            )
        }
        if (data === "Mobile carrier") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.mobileCarrier.mobileCarrieries.map(name=>(
                    <>{name.name}/</>
                    ))}
                </>
            )
        }
        if (data === "Country") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.country.countries.map(name=>(
                    <>{name.country_name}/</>
                    ))}
                </>
            )
        }


        if (data === "ISP") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.isp.ispies.map(name=>(
                    <>{name.name}/</>
                    ))}
                </>
            )
        }

        if (data === "Language") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.language.languages.map(name=>(
                    <>{name.name}/</>
                    ))}
                </>
            )
        }

        if (data === "Brand") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.brand.brands[0].map(name=>(
                    <>{name.name}/</>
                    ))}
                </>
            )
        }

        if (data === "Operating system") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.os.oss[0].map(name=>(
                    <>{name.name}/</>
                    ))}
                </>
            )
        }

        if (data === "Browser") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.browser.browsers[0].map(name => (
                        <>{name.name}/</>
                    ))}
                </>
            )
        }

        if (data === "Weekday") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.weekday.weekdays.map(name => (
                        <>{name.name}/</>
                    ))}
                </>
            )
        }
        if (data === "IP") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.ip.ips.map(name => (
                        <>{name.name}/</>
                    ))}
                </>
            )
        }
        if (data === "Referrer") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.referrer.referrers.map(name => (
                        <>{name.name}/</>
                    ))}
                </>
            )
        }
        if (data === "Referrer domain") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.referrerDomain.referrerDomains.map(name => (
                        <>{name.name}/</>
                    ))}
                </>
            )
        }
        if (data === "Custom variable 1") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var1.var1s.map(name => (
                        <>{name.name}/</>
                    ))}
                </>
            )
        }
        if (data === "Custom variable 2") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var2.var2s.map(name => (
                        <>{name.name}/</>
                    ))}
                </>
            )
        }
        if (data === "Custom variable 3") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var3.var3s.map(name => (
                        <>{name.name}/</>
                    ))}
                </>
            )
        }
        if (data === "Custom variable 4") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var4.var4s.map(name => (
                        <>{name.name}/</>
                    ))}
                </>
            )
        }
        if (data === "Custom variable 5") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var5.var5s.map(name => (
                        <>{name.name}/</>
                    ))}
                </>
            )
        }
        if (data === "Custom variable 6") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var6.var6s.map(name => (
                        <>{name.name}/</>
                    ))}
                </>
            )
        }
        if (data === "Custom variable 7") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var7.var7s.map(name => (
                        <>{name.name}/</>
                    ))}
                </>
            )
        }
        if (data === "Custom variable 8") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var8.var8s.map(name => (
                        <>{name.name}/</>
                    ))}
                </>
            )
        }
        if (data === "Custom variable 9") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var9.var9s.map(name => (
                        <>{name.name}/</>
                    ))}
                </>
            )
        }
        if (data === "Custom variable 10") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var10.var10s.map(name => (
                        <>{name.name}/</>
                    ))}
                </>
            )
        }

        if (data === "Custom variable 11") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var11.var11s.map(name => (
                        <>{name.name}/</>
                    ))}
                </>
            )
        }
        if (data === "Custom variable 12") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var12.var12s.map(name => (
                        <>{name.name}/</>
                    ))}
                </>
            )
        }
        if (data === "Custom variable 13") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var13.var13s.map(name => (
                        <>{name.name}/</>
                    ))}
                </>
            )
        }
        if (data === "Custom variable 14") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var14.var14s.map(name => (
                        <>{name.name}/</>
                    ))}
                </>
            )
        }
        if (data === "Custom variable 15") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var15.var15s.map(name => (
                        <>{name.name}/</>
                    ))}
                </>
            )
        }
        if (data === "Custom variable 16") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var16.var16s.map(name => (
                        <>{name.name}/</>
                    ))}
                </>
            )
        }
        if (data === "Custom variable 17") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var17.var17s.map(name => (
                        <>{name.name}/</>
                    ))}
                </>
            )
        }
        if (data === "Custom variable 18") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var18.var18s.map(name => (
                        <>{name.name}/</>
                    ))}
                </>
            )
        }
        if (data === "Custom variable 19") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var19.var19s.map(name => (
                        <>{name.name}/</>
                    ))}
                </>
            )
        }
        if (data === "Custom variable 20") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.var20.var20s.map(name => (
                        <>{name.name}/</>
                    ))}
                </>
            )
        }
        if (data === "Time of day") {
            return (
                <>
                    {this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.timeofday.timezone[0] + " - "+
                    this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions.timeofday.timezone[1]}
                </>
                
            )
        }
    }

    showRuleConditionsName=()=>{
        //console.log('calling');
        for(let index = 0;index<this.state.dataToSend.path.rule.length;index++){
            if(this.state.dataToSend.path.rule[index].name === "" && Object.keys(this.state.dataToSend.path.rule[index].conditions).length>0){
                
                for(let i=0;i<this.state.conditionsName[index].length;i++){
                if (this.state.conditionsName[index][i] === "Connection type") {
                    if (!this.state.ruleConditionName[index].includes("Connection type")) {
                    if (this.state.dataToSend.path.rule[index].conditions.connectionType.predicate === "MUST_BE") {
                        this.setState(produce(draft=>{draft.ruleConditionName[index].push(
                        this.state.dataToSend.path.rule[index].conditions.connectionType.connection.length===1?                           
                        this.state.dataToSend.path.rule[index].conditions.connectionType.connection[0].name : 
                        this.state.dataToSend.path.rule[index].conditions.connectionType.connection[0].name + "(+" + 
                        (Number(this.state.dataToSend.path.rule[index].conditions.connectionType.connection.length)-1) + ")")}));
                    } else {
                        this.setState(produce(draft=>{draft.ruleConditionName[index].push(
                            this.state.dataToSend.path.rule[index].conditions.connectionType.connection.length===1?
                            this.state.dataToSend.path.rule[index].conditions.connectionType.connection[0].name : 
                            this.state.dataToSend.path.rule[index].conditions.connectionType.connection[0].name + "(+" + 
                            (Number(this.state.dataToSend.path.rule[index].conditions.connectionType.connection.length)-1) + ")"
                        )}));
                    }
                }
                }
                if (!this.state.ruleConditionName[index].includes("Device type")) {
                if (this.state.conditionsName[index][i] === "Device type") {
                    if (this.state.dataToSend.path.rule[index].conditions.deviceType.predicate === "MUST_BE") {
                        this.setState(produce(draft=>{draft.ruleConditionName[index].push(
                            this.state.dataToSend.path.rule[index].conditions.deviceType.device.length===1?                           
                            this.state.dataToSend.path.rule[index].conditions.deviceType.device[0].name : 
                            this.state.dataToSend.path.rule[index].conditions.deviceType.device[0].name + "(+" + 
                            (Number(this.state.dataToSend.path.rule[index].conditions.deviceType.device.length)-1) + ")"
                            )}));
                    } else {
                        this.setState(produce(draft=>{draft.ruleConditionName[index].push(
                            this.state.dataToSend.path.rule[index].conditions.deviceType.device.length===1?
                            this.state.dataToSend.path.rule[index].conditions.deviceType.device[0].name : 
                            this.state.dataToSend.path.rule[index].conditions.deviceType.device[0].name + "(+" + 
                            (Number(this.state.dataToSend.path.rule[index].conditions.deviceType.device.length)-1) + ")"
                        )}));
                    }
                }
            }
            }
            
        }
    }

        // setTimeout(() => {
        //     console.log('this.state.ruleConditionName',this.state.dataToSend.path.rule);
        // }, 1000);

            

        

        // if (name === "Device type") {
        //     if (this.state.dataToSend.path.rule[index].conditions.deviceType.predicate==="MUST_BE") {
        //         return (<div className="condition-name-green">{name}</div>)}
        //     else {return (<div className="condition-name-red">{name}</div>)}
        // }
        // if (name === "Brand") {
        //     if (this.state.dataToSend.path.rule[index].conditions.brand.predicate==="MUST_BE") {
        //         return (<div className="condition-name-green">{name}</div>)}
        //     else {return (<div className="condition-name-red">{name}</div>)}
        // }
        // if (name === "Browser") {
        //     if (this.state.dataToSend.path.rule[index].conditions.browser.predicate==="MUST_BE") {
        //         return (<div className="condition-name-green">{name}</div>)}
        //     else {return (<div className="condition-name-red">{name}</div>)}
        // }if (name === "Operating system") {
        //     if (this.state.dataToSend.path.rule[index].conditions.os.predicate==="MUST_BE") {
        //         return (<div className="condition-name-green">{name}</div>)}
        //     else {return (<div className="condition-name-red">{name}</div>)}
        // }if (name === "Region") {
        //     if (this.state.dataToSend.path.rule[index].conditions.region.predicate==="MUST_BE") {
        //         return (<div className="condition-name-green">{name}</div>)}
        //     else {return (<div className="condition-name-red">{name}</div>)}
        // }if (name === "City") {
        //     if (this.state.dataToSend.path.rule[index].conditions.city.predicate==="MUST_BE") {
        //         return (<div className="condition-name-green">{name}</div>)}
        //     else {return (<div className="condition-name-red">{name}</div>)}
        // }if (name === "Mobile carrier") {
        //     if (this.state.dataToSend.path.rule[index].conditions.mobileCarrier.predicate==="MUST_BE") {
        //         return (<div className="condition-name-green">{name}</div>)}
        //     else {return (<div className="condition-name-red">{name}</div>)}
        // }if (name === "Country") {
        //     if (this.state.dataToSend.path.rule[index].conditions.country.predicate==="MUST_BE") {
        //         return (<div className="condition-name-green">{name}</div>)}
        //     else {return (<div className="condition-name-red">{name}</div>)}
        // }if (name === "Proxy") {
        //     if (this.state.dataToSend.path.rule[index].conditions.proxy.predicate==="MUST_BE") {
        //         return (<div className="condition-name-green">{name}</div>)}
        //     else {return (<div className="condition-name-red">{name}</div>)}
        // }if (name === "ISP") {
        //     if (this.state.dataToSend.path.rule[index].conditions.isp.predicate==="MUST_BE") {
        //         return (<div className="condition-name-green">{name}</div>)}
        //     else {return (<div className="condition-name-red">{name}</div>)}
        // }
        // if (name === "Language") {
        //     if (this.state.dataToSend.path.rule[index].conditions.language.predicate==="MUST_BE") {
        //         return (<div className="condition-name-green">{name}</div>)}
        //     else {return (<div className="condition-name-red">{name}</div>)}
        // }
        // if (name === "Weekday") {
        //     if (this.state.dataToSend.path.rule[index].conditions.weekday.predicate==="MUST_BE") {
        //         return (<div className="condition-name-green">{name}</div>)}
        //     else {return (<div className="condition-name-red">{name}</div>)}
        // }
    }

    showOptions = () => {
        if (this.state.options.length > 0) {
            this.state.options.map(options => {
                return (
                    <option value={options.value}>{options.name}</option>
                )
            })
        }
    }
    removeOptions = (data) => {
        this.state.options = this.state.options.filter(function(el) { return el.value !== data.select; }); 
    }

    deleteAllConditions = () => {
        const conditions = {
            conditions:{}
        }
        this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex], conditions)  }));
        this.setState(produce(draft => { draft.conditionsName[this.state.newRuleIndex] = [] }))
        this.setState(produce(draft => { draft.selectedOptions[draft.newRuleIndex] = [] }));
        this.filterSelectForConditions();
    }

    editRuleCondition = (name) => {
        this.setState(produce(draft=> {draft.editSelect=name}))
    }

    reAssignSelectConditions = () => {
        for (let i = 0; i < this.state.options.length; i++){
            this.setState(produce(draft=>{draft.filteredOptions[i]=draft.options.concat()}))
        }
    }

    filterSelectForConditions = () => {
        this.reAssignSelectConditions();
        for (let i = 0; i < this.state.selectedOptions[this.state.newRuleIndex].length; i++){
            this.setState(produce(draft=>{draft.filteredOptions[this.state.newRuleIndex] = draft.filteredOptions[this.state.newRuleIndex].filter(function(el) { return el.value !== draft.selectedOptions[draft.newRuleIndex][i]; })}))
        }
    }

    deleteOneCondition = (name) => {
        //console.log('name',name);
        this.setState(produce(draft=>{draft.conditionsName[this.state.newRuleIndex] = this.state.conditionsName[this.state.newRuleIndex].filter(function(el) { return el !== name; })}))
        if (name === "Connection type") {
            const { connectionType, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions= qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "connection" })}))
        }

        if (name === "Weekday") {
            const { weekday, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions= qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "weekday" })}))
        }
        if (name === "IP") {
            const { ip, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions= qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "IP" })}))
        }
        if (name === "Time of day") {
            const { timeofday, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions= qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "timezone" })}))
        }
        if (name === "Referrer") {
            const { referrer, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions= qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "Referrer" })}))
        }
        if (name === "Referrer domain") {
            const { referrerDomain, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions= qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "Referrer domain" })}))
        }
        if (name === "Custom variable 1") {
            const { var1, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions= qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "Custom variable 1" })}))
        }
        if (name === "Custom variable 2") {
            const { var2, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions= qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "Custom variable 2" })}))
        }
        if (name === "Custom variable 3") {
            const { var3, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions= qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "Custom variable 3" })}))
        }
        if (name === "Custom variable 4") {
            const { var4, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions= qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "Custom variable 4" })}))
        }
        if (name === "Custom variable 5") {
            const { var5, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions= qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "Custom variable 5" })}))
        }
        if (name === "Custom variable 6") {
            const { var6, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions= qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "Custom variable 6" })}))
        }
        if (name === "Custom variable 7") {
            const { var7, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions= qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "Custom variable 7" })}))
        }
        if (name === "Custom variable 8") {
            const { var8, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions= qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "Custom variable 8" })}))
        }
        if (name === "Custom variable 9") {
            const { var9, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions= qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "Custom variable 9" })}))
        }
        if (name === "Custom variable 10") {
            const { var10, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions= qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "Custom variable 10" })}))
        }

        if (name === "Custom variable 11") {
            const { var11, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions= qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "Custom variable 11" })}))
        }
        if (name === "Custom variable 12") {
            const { var12, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions= qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "Custom variable 12" })}))
        }
        if (name === "Custom variable 13") {
            const { var13, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions= qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "Custom variable 13" })}))
        }
        if (name === "Custom variable 14") {
            const { var14, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions= qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "Custom variable 14" })}))
        }
        if (name === "Custom variable 15") {
            const { var15, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions= qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "Custom variable 15" })}))
        }
        if (name === "Custom variable 16") {
            const { var16, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions= qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "Custom variable 16" })}))
        }
        if (name === "Custom variable 17") {
            const { var17, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions= qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "Custom variable 17" })}))
        }
        if (name === "Custom variable 18") {
            const { var18, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions= qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "Custom variable 18" })}))
        }
        if (name === "Custom variable 19") {
            const { var19, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions= qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "Custom variable 19" })}))
        }
        if (name === "Custom variable 20") {
            const { var20, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions= qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "Custom variable 20" })}))
        }

        if (name === "Device type") {
            const { deviceType, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "device" })}))
        }
        if (name === "Region") {
            const { region, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "region" })}))
            
        }
        if (name === "City") {
            const { city, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "city" })}))
        }
        if (name === "Mobile carrier") {
            const { mobileCarrier, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "Mobile carrier" })}))
        }

        if (name === "Country") {
            const { country, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "country" })}))
        }

        if (name === "Proxy") {
            const { proxy, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "proxy" })}))
            
        }
        if (name === "ISP") {
            const { isp, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "isp" })}))
        }
        if (name === "Language") {
            const { language, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "language" })}))
            
        }
        if (name === "Browser") {
            const { browser, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "browser" })}))
        }
        if (name === "Brand") {
            const { brand, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "brand" })}))
            
        }
        if (name === "Operating system") {
            const { os, ...qux } = this.state.dataToSend.path.rule[this.state.newRuleIndex].conditions;
            this.setState(produce(draft => { Object.assign(draft.dataToSend.path.rule[draft.newRuleIndex].conditions = qux) }));
            this.setState(produce(draft=>{draft.selectedOptions[draft.newRuleIndex] = draft.selectedOptions[draft.newRuleIndex].filter(function(el) { return el !== "operating" })}))
        }
        this.filterSelectForConditions();
        setTimeout(() => {
            this.calculatePlaceholderName();
        }, 100);
    }
    executeScroll = () => this.myRef.current.scrollIntoView();
    render() {
        return (
            <div>
                {this.state.visible && (
                    <Dialog className="add_affiliate_networks_dialogue add_flow_dialogue" title={"New flow"} onClose={this.toggleDialog}>
                        <div className="add_affiliate_network_dialogue_content" ref={this.myRef}>
                        {
                            this.state.errors.name !== null &&
                            <Error>-{this.state.errors.name + " Go back to New Flow and add flow name."}</Error>
                        }
                        {
                            this.state.errors.default_path_name !== null &&
                            <Error>-{this.state.errors.default_path_name}</Error>
                        }
                        {
                            this.state.errors.rule_path_name !== null &&
                            <Error>-{this.state.errors.rule_path_name}</Error>
                        }
                        {
                            (this.state.errors.default_path_lander !== null || this.state.errors.default_path_offer !== null) &&
                            <Error>-{this.state.errors.default_path_lander}<br></br>-{this.state.errors.default_path_offer}</Error>
                        }
                        {
                            (this.state.errors.rule_path_lander !== null || this.state.errors.rule_path_offer !== null) &&
                            <Error>-{this.state.errors.rule_path_lander}<br></br>-{this.state.errors.rule_path_offer}</Error>    
                        }
                            
                        {(!this.state.errors.isOfferExist) && <Error>-You must have atleast one Offer to create Flow. Go back to Offer and create one.</Error>}
                            
                        {(!this.state.errors.isLanderExist && this.state.errors.isOfferExist) ?
                            <Error>- Note: You do not have any Lander. Please select *Offer Only* in path to create Flow.</Error> : (!this.state.errors.isLanderExist && !this.state.errors.isOfferExist)?
                        <Error>-You do not have any Lander nor Offer. Please go back and create Offer and Lander First.</Error>:null}
                        <ModalForm 
                            state={this.state}
                            onChangePane={this.onChangePane}
                            nameChangeHandler={this.nameChangeHandler}
                            inputChangeHandler={(e) => this.inputChangeHandler(e)}
                            handleSwitchChange={this.handleSwitchChange}
                            addNewRule={this.addNewRule}
                            addNewFlow={this.addNewFlow}
                            addNewPath={this.addNewPath}
                            addDefaultPath={this.addDefaultPath}
                            deleteRule={(e) => this.deleteRule(e)}
                            duplicateRule={(e) => this.duplicateRule(e)}
                            newRuleClicked={(e) => this.newRuleClicked(e)}
                            defaultPathClicked={(e,defaultP) => this.defaultPathClicked(e,defaultP)}
                            newRulePathClicked={(e,rule)=>this.newRulePathClicked(e,rule)}
                            onRuleNameChange={(e) => this.onRuleNameChange(e)}
                            deleteDefaultPath={(e,id,name) => this.deleteDefaultPath(e,id,name)}
                            duplicateDefaultPath={(e,name) => this.duplicateDefaultPath(e,name)}
                            handleDefaultPathChange={(e) => this.handleDefaultPathChange(e)}
                            handleRulePathChange={(e)=>this.handleRulePathChange(e)}
                            offersDataReceived={this.props.offersDataReceived}
                            landersDataReceived={this.props.landersDataReceived}
                            addNewOffer={this.addNewOffer}
                            addLandersAndOffersMethod={(e) => this.addLandersAndOffersMethod(e)}
                            increaseWeightMethod={this.increaseWeightMethod}
                            decreaseWeightMethod={this.decreaseWeightMethod}
                            weightageSelectTagChangeHandler={this.weightageSelectTagChangeHandler}
                            deleteWeightageMethod={this.deleteWeightageMethod}
                            addConditions={(data) => this.addConditions(data)}
                            showConditionValues={(data) => this.showConditionValues(data)}
                            deleteAllConditions={this.deleteAllConditions}
                            showOptions={this.showOptions}
                            deleteOneCondition={name => this.deleteOneCondition(name)}
                            editRuleCondition={name => this.editRuleCondition(name)}
                            showCondtionsName={(name) => this.showCondtionsName(name)}
                            decreaseDefaultPathWeightMethod={this.decreaseDefaultPathWeightMethod}
                            increaseDefaultPathWeightMethod={this.increaseDefaultPathWeightMethod}
                            onChangePathSwitch={this.onChangePathSwitch}
                            changeWeightageHandler={this.changeWeightageHandler}
                            onSort={this.onSort}
                            showRuleConditionsName={this.showRuleConditionsName}
                            />
                            {/* {this.state.saveBtnDisbaled && <LoadingPanel/>} */}
                        </div>
                        <DialogActionsBar>
                                <div className="action_bar_buttons">
                                    <div className="left">
                                        <button onClick={this.toggleDialog} className="normal_btn cancel_button gray_button_bg_imp black border_radius_normal_btn normal_text black no_border">Close</button>
                                </div>
                                {
                                    this.state.onFlowClick ?
                                    <div className="right">
                                        <button onClick={this.onClickNextBtn} disabled={!this.state.errors.isOfferExist} className="normal_btn theme_dark_btn theme_dark_bg_color_imp color_white_imp border_radius_normal_btn save_button normal_text">Next</button>
                                    </div> :
                                    <div className="right">
                                        <button onClick={this.onModalSubmit} disabled={this.state.saveBtnDisbaled} className="normal_btn theme_dark_btn theme_dark_bg_color_imp color_white_imp border_radius_normal_btn save_button normal_text">Save</button>
                                    </div>
                                }
                                    
                                </div>
                        </DialogActionsBar>
                        
                    </Dialog>
                )}
            </div>
        );
    }
    
}
export const mapStateToProps = createStructuredSelector({
    flowsAddedSuccessfully: makeSelectFlowsAdd(),
    error: makeSelectFlowError(),
    flowArchived: makeSelectArchiveFlow(),
    flowDeleted: makeSelectDeleteFlow(),
    flowUpdated: makeSelectUpdateFlow(),
    workspaceDataReceived: makeSelectWorkspaceData(),
    countriesDataRecieved: makeSelectCountriesData(),
    offersDataReceived: makeSelectOffersDataLoaded(),
    landersDataReceived: makeSelectAllLandersDataLoaded(),
    brandDataReceived: makeSelectBrandData(),
    browserDataReceived: makeSelectBrowserData(),
    osDataReceived: makeSelectOperatingSystemData(),
    timezoneDataReceived: makeSelectLoadTimeZoneData(),

    // timezoneData:
});

export function mapDispatchToProps(dispatch) {
    return {
        fetchingData: (...payload) =>dispatch(colloborationWorkspaceActions.fetchingData(...payload)),
        loadCountries: (...payload) =>dispatch(newFlowsActions.loadCountries(...payload)),
        addNewFlows: (...payload) =>dispatch(newFlowsActions.addNewFlows(...payload)),
        loadFlows: (...payload) => dispatch(newFlowsActions.loadFlows(...payload)),
        loadOffers: (...payload) => dispatch(offerActions.loadOffers(...payload)),
        loadLanders: (...payload) => dispatch(landerActions.loadAllLanders(...payload)),
        loadTimeZone: (...payload) => dispatch(offerActions.loadTimezone(...payload)),

    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FlowsModal));

