import React, {useState, useRef} from "react";

const Tracking = (props) => {
    // const [isCopied, setIsCopied] = useState("");
    const campaignUrlRef = useRef(null);
    const campaignNameRef = useRef(null);


    const copyURLToClipboard = (e) => {
        campaignUrlRef.current.select();
        document.execCommand('copy');
        e.target.focus();
    };
    const copyCampaignNameToClipboard = (e) => {
        campaignNameRef.current.select();
        document.execCommand('copy');
        e.target.focus();
    };
    return (
        <div className="form_con k-p-5">
            <section>
                <h3>Tracking</h3>

                {/* <div className="input_group five_hun">
                        <label htmlFor="affiliate-network-name">
                            Domain
                        </label>
                        <select
                            className="add_custom_domain_dropdown normal_text normal_font"
                            name="workspaceId"
                            value={props.props.state.domainId}
                            onChange={props.props.simpleInputChangeHandler}
                        >
                            {
                                props.props.state.loadedDomains ?
                                    props.props.state.loadedDomains.map((domain) => (
                                        <option key={domain.id} value={domain.id}>{domain.name}</option>
                                    ))
                                    : null
                            }
                        </select>
                </div> */}
                

                {/* <div className="input_group">
                    <label htmlFor="affiliate-network-name">Campaign URL</label>
                    <div className="block relative input_container">
                        <input
                            className="grey_bg_box five_hun k-p-2 k-mt-3"
                            type="text"
                            ref={campaignUrlRef}
                            name="name"
                            value={"https://www.facebook123.com/"}
                        />
                        <span className="copy normal_text normal_font theme_blue_color k-ml-5" onClick={copyCampaignNameToClipboard}>Copy</span>
                    </div>
                </div> */}
                {
                    props.campaignUrl !== null
                    ?
                        <div className="input_group">
                            <label htmlFor="affiliate-network-name">Campaign URL</label>
                            <div className="block relative input_container">
                                <input
                                    className="grey_bg_box five_hun k-p-2 k-mt-3"
                                    type="text"
                                    ref={campaignUrlRef}
                                    name="campaignUrl"
                                    value={props.campaignUrl}
                                />
                                <span className="copy normal_text normal_font theme_blue_color k-ml-5" onClick={copyURLToClipboard}>Copy</span>
                            </div>
                        </div>
                        : null
                }

                <div className="input_group">
                    <label htmlFor="affiliate-network-name">Campaign Name</label>
                    <div className="block relative input_container">
                        <input
                            className="grey_bg_box five_hun k-p-2 k-mt-3"
                            type="text"
                            ref={campaignNameRef}
                            name="name"
                            value={props.trafficSourceName + " - " + props.countryName + " - " + props.campaignName}
                        />
                        <span className="copy normal_text normal_font theme_blue_color k-ml-5" onClick={copyCampaignNameToClipboard}>Copy</span>
                    </div>
                </div>
                {/* {
                    props.campaignUrl !== null
                    ?
                        <div className="input_group">
                            <label htmlFor="affiliate-network-name">Campaign URL</label>
                            <div className="block relative input_container">
                                <input
                                    className="grey_bg_box five_hun k-p-2 k-mt-3"
                                    type="text"
                                    ref={campaignUrlRef}
                                    name="campaignUrl"
                                    value={props.campaignUrl}
                                />
                                <span className="copy normal_text normal_font theme_blue_color k-ml-5" onClick={copyURLToClipboard}>Copy</span>
                            </div>
                        </div>
                        : null
                } */}
            </section>
        </div>
    );
};

export default Tracking;