import React from "react";
import {Redirect, Switch} from "react-router-dom";
import Login from "../registration/LoginRegister/Login";
import Signup from "../registration/LoginRegister/Signup";
import ForgetPassword from "../registration/LoginRegister/Login/forgotPassword";
import homePage from "../homePage/index";
// import SettingsArea from "../containers/Settings";
import ProtectedRoutes from "./ProtectedRoutes";
import UnProtectedRoutes from "./UnProtectedRoutes";
import Domains from "../containers/Settings/Domains";
import Workspace from "../containers/Settings/CollaborationTools";
// import GeneralSettings from "../containers/Settings/GeneralSettings";
import ProfilePage from "../containers/Settings/Profile";
import TrackingUrls from "../containers/Settings/TrackingUrls";
import AffiliateNetworks from "../containers/Pages/AffiliateNetworks";
// import Flows from './../containers/Pages/Flows';
import TrafficSources from "../containers/Pages/TrafficSources";
import Landers from "../containers/Pages/Landers";
import Offers from "../containers/Pages/Offers";
import Campaigns from "../containers/Pages/Campaigns";
import NewFlows from './../containers/Pages/NewFlows/index';
// import Notifications from './../containers/Settings/Notifications/index';
import IpUaFiltering from '../containers/Settings/IP-UA-Filtering/index';
import Settings from './../containers/Settings/GeneralSettings/settings';
import EventLog from '../containers/Settings/Event-Log/index';
import CNAME from './../components/settings/domains/domainsConfiguration/CNAME';
import Report from "../containers/Report/reportPage";
import Dashboard from "../containers/Pages/Dashboard";
import Users from "../containers/Settings/Users";
import AddUser from "../containers/Settings/Users/addUser";
import { makeSelectIsUserAdmin } from "../registration/LoginRegister/selectors";
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import Conversion from "../containers/Pages/Conversion";

let home_screen = "dashboard";
// if (JSON.parse(localStorage.getItem("search"))) {
//     home_screen = JSON.parse(localStorage.getItem("search")).setting.home_screen;
// }
const Routes = (props) => {
    // console.log("Props in routes: ", props);
    return (
        <Switch>
            <UnProtectedRoutes exact path="/" component={homePage} />
            <UnProtectedRoutes exact path="/login" component={Login} />
            <UnProtectedRoutes exact path="/signup" component={Signup} />
            <UnProtectedRoutes exact path="/login/resetPassword/" component={ForgetPassword} />
            <ProtectedRoutes exact path="/settings/collaboration-tools/workspace" component={Workspace} />
            <ProtectedRoutes exact path="/settings/domains" component={Domains} />
            {props.isUserAdmin && 
                <ProtectedRoutes exact path="/settings/users" component={Users} />
            }

            <ProtectedRoutes exact path="/settings/profile" component={ProfilePage} />
            <ProtectedRoutes exact path="/settings/tracking-urls" component={TrackingUrls} />
            <ProtectedRoutes exact path="/settings/" component={Settings} />
            <ProtectedRoutes exact path="/affiliate-networks/" component={AffiliateNetworks} />
            <ProtectedRoutes exact path="/traffic-sources/" component={TrafficSources} />
            <ProtectedRoutes exact path="/landers/" component={Landers} />
            <ProtectedRoutes exact path="/offers/" component={Offers} />
            <ProtectedRoutes exact path="/flows/" component={NewFlows} />
            <ProtectedRoutes exact path="/campaigns/" component={Campaigns} />
            <ProtectedRoutes exact path="/conversion/" component={Conversion} />
            <ProtectedRoutes exact path="/dashboard/" component={Dashboard} />
            {/* <ProtectedRoutes exact path="/settings/notifications/" component={Notifications} /> */}
            <ProtectedRoutes exact path="/settings/ip-ua-filtering/" component={IpUaFiltering} />
            <ProtectedRoutes exact path="/settings/event-log/" component={EventLog} />
            <ProtectedRoutes exact path="/settings/CNAME/" component={CNAME}/>
            <ProtectedRoutes exact path="/settings/add-user/" component={AddUser}/>
            <ProtectedRoutes exact path="/report" component={Report}/>
            <Redirect to={"/dashboard"} />
            
        </Switch>
    )
}

export const mapStateToProps = createStructuredSelector(
    {
    //   isLoggedIn: makeSelectLoggedIn(),
      isUserAdmin: makeSelectIsUserAdmin(),
  });
//   export function mapDispatchToProps(dispatch) {
//     return {
      
//     };
//   }
  
export default withRouter(connect(mapStateToProps, null)(Routes));