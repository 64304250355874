import React, { Component } from 'react';
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import "./workspace.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { withRouter } from "react-router-dom";
import * as colloborationWorkspaceActions from "../actions";
import {
    makeSelectWorkspaceAdded,
    makeSelectWorkspaceData,
    makeSelectWorkspaceDelete,
    makeSelectWorkspaceEdit,
    makeSelectWorkspaceError,
    makeSelectWorkspaceID,
    makeSelectWorkspaceName,
} from "../selectors";
import { makeSelectToken } from "../../../../registration/LoginRegister/selectors";
import LoadingPanel from "../../../grid/loader/loader";
import produce from 'immer';
//import useImmer from "use-immer";


class WorkspaceModal extends Component {
    state = {
        visible: false,
        loader: false,
        saveBtnDisabled: false,
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.workspaceDeleted !== this.props.workspaceDeleted) && this.props.workspaceDeleted && !this.props.error) {
            //console.log('delete seuccess');
            this.props.fetchingData(this.props.accessToken);
            this.setState(produce(draft => {
                draft.loader = false;
                draft.visible = false;
            }));
        }
        if ((prevProps.workspaceAdded !== this.props.workspaceAdded) && this.props.workspaceAdded && !this.props.error) {
            this.props.fetchingData(this.props.accessToken);
            //this.toggleDialog();
            this.setState(produce(draft => {
                draft.loader = false;
                draft.visible = false;
            }));
            //this.props.toggleLoader();
            
        }
        if ((prevProps.workspaceEdit !== this.props.workspaceEdit) && this.props.workspaceEdit && !this.props.error) {
            this.props.fetchingData(this.props.accessToken);
            //this.props.toggleLoader();
            this.setState(produce(draft => { draft.loader = false }));
            this.setState(produce(draft => {
                draft.visible = false;
                draft.loader = false;
            }));
        }
        if ((prevProps.error !== this.props.error) && this.props.error !== false) {
            //this.props.toggleLoader();
            this.setState(produce(draft => { draft.loader = false }));
        }
    }

    toggleDialog = () => {
        this.setState(produce(draft => {draft.visible = !this.state.visible;}))
    };

     handleSubmit = (event) => {
        // console.log('clicked');
        //this.setState(produce(draft => { draft.loader = true }));
        //this.props.toggleLoader();
        const id = this.props.id;
        const workspaceNewName = event.WorkspaceName;
        const token = this.props.token;
        if (id !== null) {
            // console.log('patch request');
            this.props.toggleLoader();
            this.props.editData(workspaceNewName, id, token);
        }
        if (id === null) {
            // console.log('new workspcace request');
            this.props.toggleLoader();
            this.props.addPrivateWorkspace(workspaceNewName, token);
            this.setState(produce(draft => { draft.visible = false }));
        }
        
    };
    
     deleteWorkspace = () => {
        // console.log('delete workspace function');
         this.setState(produce(draft => { draft.loader = true }));
         this.props.toggleLoader();
        //  console.log('this.props.id', this.props.id);
        //  console.log('this.props.token',this.props.token);
        this.props.deletePrivateWorkspace(this.props.id, this.props.token);
    }

    render() {
        return (
            <div className="add_workspace_modal">
                {(this.state.loader) && <LoadingPanel></LoadingPanel>}
                <Button icon={this.props.icon} className={this.props.classNameForButton} onClick={this.toggleDialog}>
                    {this.props.buttonText}
                </Button>

                {this.state.visible && (
                    <div className="add_worspace_dialog">
                        <Form
                            onSubmit={(e) => this.handleSubmit(e)}
                            //initialValues={props.item}
                            render={() => (
                                <Dialog
                                    className="dialog_title_name"
                                    title={this.props.title}
                                    onClose={this.toggleDialog}
                                // onSubmit={handleSubmit}
                                >
                                    <FormElement>
                                        <div className="workspace_modal_heading_text">
                                            <h2 className="text">Private workspace name</h2>
                                            <Field
                                                name={"WorkspaceName"}
                                                type="text"
                                                component={Input}
                                                defaultValue={this.props.item}
                                                className="input_workspace_name "
                                                placeholder="Type workspace name"
                                                required={true}
                                                id={this.props.id}
                                            />
                                            {/* <h2 className="text">Assign workers</h2>
                                            <Input
                                                placeholder="To be done in future."
                                                className="input_workspace_name"
                                                disabled={true}
                                            />
                                            <p className="info_text">
                                                Account Owner and all Admins are assigned to all private
                                                workspaces by default
                                            </p>
                                            <h2 className="text">Assign domains</h2>
                                            <p className="info_text">
                                                This feature is not available on your current plan.{" "}
                                                <Link className="info_text">
                                                    Upgrade to get access.
                                            </Link>
                                            </p>
                                            <span className="arrow k-icon k-i-arrows-kpi"></span> */}
                                        </div>
                                        <div className="footer"></div>
                                        <div className="footer_buttons">
                                            <Button
                                                className="cancel_workspace_button button"
                                                onClick={this.toggleDialog}
                                            >
                                                Cancel
                                        </Button>

                                            {this.props.action === "Delete" ?
                                                <Button
                                                    className="save_workspace_button button"
                                                    onClick={()=>this.deleteWorkspace()}>Delete</Button> :
                                                <Button
                                                    className="save_workspace_button button"
                                                    type={"submit"}
                                                >
                                                    Save
                                            </Button>
                                            }
                                        </div>
                                    </FormElement>
                                </Dialog>
                            )}
                        />
                    
                    </div>
                )}
            </div>
        );
    }
};

export const mapStateToProps = createStructuredSelector({
    workspaceName: makeSelectWorkspaceName(),
    workspaceID: makeSelectWorkspaceID(),
    workspaceData: makeSelectWorkspaceData(),
    workspaceEdit: makeSelectWorkspaceEdit(),
    workspaceAdded: makeSelectWorkspaceAdded(),
    workspaceDeleted: makeSelectWorkspaceDelete(),
    accessToken: makeSelectToken(),
    error: makeSelectWorkspaceError()
});

export function mapDispatchToProps(dispatch) {
    return {
      addPrivateWorkspace: (...payload) => dispatch(colloborationWorkspaceActions.addPrivateWorkspace(...payload)),
      deletePrivateWorkspace: (...payload) =>dispatch(colloborationWorkspaceActions.deletePrivateWorkspace(...payload)),
      editData: (...payload) => dispatch(colloborationWorkspaceActions.editData(...payload)),
      fetchingData: (...payload) => dispatch(colloborationWorkspaceActions.fetchingData(...payload))
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspaceModal));
