import React, {Fragment, Children} from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from "reselect";
import { makeSelectCurrentLanguage } from "../redux/selectors/languageSelectors";
import Header from "../containers/LayoutHeader";
import "./layout.css";
import { logoutUser } from '../registration/LoginRegister/actions';
import auth from '../auth';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import produce from "immer";
import { logoutAndClear } from "../components/logoutAndClear";
import { accessTokenExists, checkAccessTokenExpiry } from "../components/accessTokenCheck";
import { tellMobiAxiosInstance } from "../containers/helpers/requestHandlers";

class Layout extends React.Component {

    state = {
        mainHeaderHeight: 0,
        windowHeight: 0,
        reportCount: 0,
        activeTab: 0
    }
    
    componentDidMount() {
        // set the main header height
        this.setState(produce(draft => {
            draft.windowHeight = Number(document.getElementsByTagName("html")[0].clientHeight);
        }));
        setTimeout(() => {
            this.setState(produce( draft => { draft.mainHeaderHeight = this.state.mainHeaderHeight + Number(document.getElementsByClassName("main_header_container")[0].clientHeight); }));
        },40);


        // if there is already accessToken parameter present...
        // then it means the user already has a session giong on...
        // then check if the sesstion has expired or not
        // if expired logout... otherwise do nothing.
        if (accessTokenExists()) {
            tellMobiAxiosInstance();
            checkAccessTokenExpiry(this.props.logoutUser, auth);
        } else {
            logoutAndClear(this.props.logoutUser, auth);
        }
    }

    render () {
        return (
            <Fragment>
                <div className="main_header_container">
                    <Header
                        reportCount={this.props.reportCount}
                        decreaseReportCount={this.props.decreaseReportCount}
                    />
                </div>
                <div style={{height: `calc(100% - ${this.state.mainHeaderHeight}px)`}} className="children_container" id="children_container">
                {/* <div style={{height:"80%"}}> */}
                    {this.props.children}
                </div>
            </Fragment>
        );
    }
}

Layout.propTypes = {
    children: PropTypes.any
};

const mapStateToProps = createStructuredSelector({
    lang: makeSelectCurrentLanguage()
});

export function mapDispatchToProps(dispatch) {
    return {
        logoutUser: () => dispatch(logoutUser()),
    };
  }
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));