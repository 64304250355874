import React from "react";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import "./delete.css";
import {Fade} from "@progress/kendo-react-animation";


//delete modal
class DeletePageModal extends React.Component {
    state = {
        offerData: null,
        visible: this.props.state.visibleDeleteFilteringDialog,
    }
    toggleDialog = () => {
        this.setState({
            visible: !this.state.visible,
        });
        this.props.closeDeleteFilterDialog();
    };
    toggleDeleteDialog = () => {
        this.setState({
            visible: !this.state.visible,
        });
        //this.props.closeDeleteFilterDialog();
        this.props.deleteFilterOnSubmit();
    }
    componentDidMount() {
        // for (let i=0; i< this.props.editFlowsDataRecieved.length; i++) {
            
        //         this.setState(produce(draft => {draft.offerData = this.props.editFlowsDataRecieved[i]}));
        //     }
        // }
    }
    render() {
        return (
            <div>
                <Dialog className="add_affiliate_networks_dialogue" title={"Delete IP / UA Filter"} onClose={this.toggleDialog}>

                    <div className="add_affiliate_network_dialogue_content">
                        <div className="content_form">
                            <div className="success_msg_con">
                                <Fade enter={true} exit={true}>
                                    <p className="danger_para mb-10 mh-0">This will permanently delete this IP/UA Filter data from the Database.</p>
                                </Fade>
                            </div>
                            {
                                this.props.editFilterDataRecieved
                                ?
                                    <div>
                                        <div className="row">
                                            <h4 className="h4 ">
                                                <span className="bold">IP/UA Filter Name: </span> <span className="normal">{this.props.editFilterDataRecieved.name}</span></h4>
                                        </div>
                                        <div className="row">
                                            <h4 className="h4">
                                                <span className="bold">IP/UA Filter IP Address:</span> <span className="normal">{this.props.editFilterDataRecieved.ip_address}</span></h4>
                                        </div>
                                        <div className="row">
                                            <h4 className="h4"><span className="bold">IP/UA Filter User Agent Name:</span> <span className="normal">{this.props.editFilterDataRecieved.user_agent}</span></h4>
                                        </div>
                                    </div>
                                : null
                            }
                        </div>
                    </div>
                    <DialogActionsBar>
                        <div className="action_bar_buttons">
                            <div className="left">
                                <button onClick={this.toggleDialog} className="normal_btn cancel_button gray_button_bg_imp black border_radius_normal_btn normal_text black no_border">Cancel</button>
                            </div>
                            <div className="right">
                                <button onClick={this.toggleDeleteDialog} className="normal_btn theme_dark_btn theme_dark_bg_color_imp color_white_imp border_radius_normal_btn save_button normal_text">Delete</button>
                            </div>
                        </div>
                    </DialogActionsBar>
                </Dialog>
            </div>
        );
    }
};

export default DeletePageModal;