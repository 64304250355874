import React, { useEffect } from "react";
import "./calendar.css";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
// import moment from "moment-timezone";
// import { timeZonesConversion } from "../../timeZonesExchange";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { timeZonesConversion } from "../../../../components/timeZonesExchange/index";

const MenuCalendar = (props) => {
  const [ranges, setRanges] = React.useState();
  const [timeZoneList, setTimeZoneList] = React.useState([]);
  let local = {
    // format: "DD-MM-YYYY HH:mm",
    sundayFirst: false,
  };
  let now = new Date();
  let start = moment(now).startOf("day");
  let end = moment(now).endOf("day");

  const rangesTypes = {
    Today: [moment(start), moment(end)],
    Yesterday: [
      moment(start).subtract(1, "days"),
      moment(end).subtract(1, "days"),
    ],
    "Last 24 hours": [moment(now).subtract(1, "days"), moment(now)],
    "Last 48 hours": [moment(now).subtract(2, "days"), moment(now)],
    "Last 3 Days": [moment(start).subtract(2, "days"), moment(end)],
    "Last 7 Days": [moment(start).subtract(6, "days"), moment(end)],
    "This month": [
      moment(start).clone().startOf("month"),
      moment(end).clone().endOf("month"),
    ],
    "Last 30 Days": [moment(start).subtract(29, "days"), moment(end)],
    "Last month": [
      moment(start).subtract(1, "months").startOf("month"),
      moment(start).subtract(1, "months").endOf("month"),
    ],
    "This year": [
      moment(start).clone().startOf("year"),
      moment(start).clone().endOf("year"),
    ],
    "Last year": [
      moment(start).subtract(1, "year").startOf("year"),
      moment(start).subtract(1, "year").endOf("year"),
    ],
  };

  const calculateNewTimeZonesList = () => {
    let newTimeZoneList = [];
    let defaultTimeZoneId = JSON.parse(localStorage.getItem("search")).setting
      .timeZoneId;
    for (let i = 0; i < timeZonesConversion.length; i++) {
      if (Number(defaultTimeZoneId) === Number(timeZonesConversion[i].id)) {
        newTimeZoneList.push({
          id: timeZonesConversion[i].id,
          name: "Default: " + timeZonesConversion[i].name,
          calendar: timeZonesConversion[i].calendar,
        });
      }
    }
    for (let i = 0; i < timeZonesConversion.length; i++) {
      if (Number(defaultTimeZoneId) !== Number(timeZonesConversion[i].id)) {
        newTimeZoneList.push({
          id: timeZonesConversion[i].id,
          name: timeZonesConversion[i].name,
          calendar: timeZonesConversion[i].calendar,
        });
      }
    }
    setTimeZoneList(() => newTimeZoneList);
    setTimeout(() => {
      // this.addTooltipDate();
    }, 100);
  };

  const addTooltipDate = () => {
    let search = JSON.parse(localStorage.getItem("search"));
    for (let i = 0; i < timeZoneList.length; i++) {
      if (search.dates.timeZone === timeZoneList[i].calendar) {
        props.setDates((prevDates) => ({
          ...prevDates,
          defaultTimeZone: timeZoneList.name,
        }));
      }
    }
  };

  const timeZoneChange = (e) => {
    props.setDates((prevDates) => ({
      ...prevDates,
      timeZone: e.target.value,
    }));
    let search = JSON.parse(localStorage.getItem("search"));
    search.dates.timeZone = e.target.value;
    search.report.report1.search.date.timeZone = e.target.value;
    search.report.report2.search.date.timeZone = e.target.value;
    search.report.report3.search.date.timeZone = e.target.value;
    localStorage.setItem("search", JSON.stringify(search));
    setTimeout(() => {
      addTooltipDate();
    }, 60);
  };

  useEffect(() => {
    setRanges(rangesTypes);
    calculateNewTimeZonesList();
  }, []);

  return (
    <div className="date_container">
      <div
        className="date_display normal_text black "
        data-tip
        data-for="calendar_tt"
        onClick={props.toggleCalendarWindow}
      >
        Date: {props.modifiedDate.startDate + ", " + props.modifiedDate.endDate}
      </div>
      {!props.defaultTimeZone.includes("Default") ? (
        <ReactTooltip
          className="grid_tooltip box_shadow_tt"
          id="calendar_tt"
          type="light"
        >
          <span>{props?.dates?.timeZone ?? props.defaultTimeZone}</span>
        </ReactTooltip>
      ) : null}
      {Boolean(props.showCalender) && (
        <div className="date_popup_container">
          <DateTimeRangeContainer
            ranges={ranges}
            start={props.dates.startDate}
            end={props.dates.endDate}
            // local={local}
            applyCallback={(s, e) => {
              props.applyCallback(s, e);
              props.toggleCalendarWindow();
            }}
            rangeCallback={props.rangeCallback}
            smartMode
            standalone
            style={{
              standaloneLayout: { display: "flex", maxWidth: "fit-content" },
            }}
          />
          <hr />
          <div className="calendar_bottom_row">
            <div className="select_dropdown">
              <select
                id="timeZone_select"
                name="timeZone_select"
                value={props.dates.timeZone}
                onChange={timeZoneChange}
              >
                {timeZoneList?.map((timeZone) => (
                  <option value={timeZone.calendar}>{timeZone.name}</option>
                ))}
              </select>
            </div>
            <div className="cancelBtn" onClick={props.toggleCalendarWindow}>
              Close
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuCalendar;
// import React from 'react';
// import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
// import {FormControl} from 'react-bootstrap'
// import moment from "moment"
// import "./calendar.css";

// class MenuCalendar extends React.Component {

//     constructor(props){
//         super(props);
//         let now = new Date();
//         let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0,0,0,0));
//         let end = moment(start).add(1, "days").subtract(1, "seconds");
//         this.state = {
//             start : start,
//             end : end
//         }

//         this.applyCallback = this.applyCallback.bind(this);
//     }

//     applyCallback(startDate, endDate){
//         this.setState({
//                 start: startDate,
//                 end : endDate
//             }
//         )
//     }

//     render(){
//             let now = new Date();
//             let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0,0,0,0));
//             let end = moment(start).add(1, "days").subtract(1, "seconds");
//             const ranges = {
//                 "Today": [moment(start), moment(end)],
//                 "Yesterday": [moment(start).subtract(1, "days"), moment(end).subtract(1, "days")],
//                 "Last 24 hours": [moment(now).subtract(1,"days"), moment(now)],
//                 // "Last 48 hours": [moment(now).subtract(2,"days"), moment(now)],
//                 // "Last 3 Days": [moment(start).subtract(2, "days"), moment(end)],
//                 // "Last 7 Days": [moment(start).subtract(6, "days"), moment(end)],
//                 // "This month": [moment(start).clone().startOf('month'), moment(end).clone().endOf('month')],
//                 // "Last 30 Days": [moment(start).subtract(29, "days"), moment(end)],
//                 // "Last month": [moment(start).subtract(1, 'months').startOf('month'), moment(start).subtract(1, 'months').endOf('month')],
//                 // "This year": [moment(start).clone().startOf('year'), moment(start).clone().endOf('year')],
//                 // "Last year": [moment(start).subtract(1, 'year').startOf('year'), moment(start).subtract(1, 'year').endOf('year')],
//             }
//             let local = {
//                 "format":"DD-MM-YYYY HH:mm",
//                 "sundayFirst" : false
//             }
//             let maxDate = moment(start).add(24, "hour")
//             return(
//                 <div>
//                     <DateTimeRangeContainer
//                         ranges={ranges}
//                         start={this.state.start}
//                         end={this.state.end}
//                         local={local}
//                         maxDate={maxDate}
//                         applyCallback={this.applyCallback}
//                         // standalone
//                         style={{
//                             standaloneLayout: { display: "flex", maxWidth: "fit-content" }
//                         }}
//                     >
//                         <FormControl
//                         id="formControlsTextB"
//                         type="text"
//                         label="Text"
//                         placeholder="Enter text"
//                         />
//                     </DateTimeRangeContainer>
//                 </div>
//             );
//         }
// }
// export default MenuCalendar;
