import React, { Component } from "react";
import Layout from "../../../layout";
import { makeSelectToken } from "../../../registration/LoginRegister/selectors";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import * as eventlogActions from "./actions";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { ComboBox } from '@progress/kendo-react-dropdowns';
import "./eventlog.css";
import { Button } from '@progress/kendo-react-buttons';
import { makeSelectEventLogData, makeSelectEventLogDetailsData } from "./selectors";
import { makeSelectProfileData } from "../Profile/selectors";
import { filterBy } from '@progress/kendo-data-query';
import * as profileActions from "../Profile/actions";
import { DateRangePicker } from '@progress/kendo-react-dateinputs';


import produce from 'immer';
import LoadingPanel from './../../grid/loader/loader';
import EventLogModal from './PageModal/index';

const fields = {
    user: ['All users'],
    actions: ['All actions', 'Create', 'Archive', 'Update', 'Restore'],
    entities: ['All entities', 'Campaign', 'Traffic Source', 'Affiliate Network', 'Offer', 'Lander', 'Flow'],
    date: ['Today', 'Yesterday', 'Last 3 days', 'Last 7 days', 'This month', 'Last 30 days', 'This year', 'Custom date range' ]
}

class EventLog extends Component {
    state = {
        dataToSend: {
            user: "",
            actions: "",
            entities: "",
            date:"Today"
        },        
        skip: 0,
        take: 10,
        defaultdata: 'atype=&etype=&user=&date=Today',
        user: fields.user.slice(),
        actions: fields.actions.slice(),
        entities: fields.entities.slice(),
        date: fields.date.slice(),
        visibleEventLogDialog: false,
        data: null,
        startDate: null,
        endDate: null,
        loadingData:false,
    };

    pageChange = (event) => {
        this.setState({
            skip: event.page.skip,
            take: event.page.take,
        });
    };

    componentDidMount() {
        this.props.loadEventLog(this.props.accessToken, this.state.defaultdata);
        this.props.fetchingProfileData(this.props.accessToken);
        if (this.props.profileDataReceived === null) {
        }
    }   
    componentDidUpdate() {
        //console.log('this.profile data', fields);
    }

    filterChange = (event) => {
        if (event.target.name === "user") {
            this.setState({
                user: this.filterData(event.filter,event)
                });    
        }
        if (event.target.name === "actions") {
            this.setState({
                actions: this.filterData(event.filter,event)
                });
        }
        if (event.target.name === "entities") {
            this.setState({
                entities: this.filterData(event.filter,event)
                });
        }
        if (event.target.name === "date") {
            this.setState({
                date: this.filterData(event.filter,event)
                });
        }
        
    }

    filterData(filter, event) {
        //console.log('this.event name', event);
        if (event.target.name === "user") {
            const data = fields.user.slice();
            return filterBy(data, filter);
        }
        if (event.target.name === "actions") {
            const data = fields.actions.slice();
            return filterBy(data, filter);
        }
        if (event.target.name === "entities") {
            const data = fields.entities.slice();
            return filterBy(data, filter);
        }
        if (event.target.name === "date") {
            const data = fields.date.slice();
            return filterBy(data, filter);
        }
        
    }

    inputChangeHandler = (event) => {
        if (event.target.name === "user" && event.target.value !== '' &&  event.target.value !== null) {
            if (event.target.value === "All users") {
                this.setState({ dataToSend: { ...this.state.dataToSend, user: '' } });    
            }
            else {
                this.setState({ dataToSend: { ...this.state.dataToSend, user: event.target.value} });
            }
        }
        if (event.target.name === "actions" && event.target.value !== '' &&  event.target.value !== null) {
            if (event.target.value === "All actions") {
                this.setState({ dataToSend: { ...this.state.dataToSend, actions: '' } });    
            }
            else {
                this.setState({ dataToSend: { ...this.state.dataToSend, actions: event.target.value } });
            }
        }
        if (event.target.name === "entities" && event.target.value !== '' &&  event.target.value !== null) {
            if (event.target.value === "All entities") {
                this.setState({ dataToSend: { ...this.state.dataToSend, entities: '' } });    
                
            }
            else {
                this.setState({ dataToSend: { ...this.state.dataToSend, entities: event.target.value} });
            }
        }
        if (event.target.name === "date" && event.target.value !== '' &&  event.target.value !== null) {
            this.setState({ dataToSend: { ...this.state.dataToSend, date: event.target.value} });
        }
        this.setState(produce(draft => {
            draft.loadingData = !this.state.loadingData;
        }))
        setTimeout(() => {
            if (this.state.dataToSend.date !== "Custom date range") {
                this.setState(produce(draft => {
                    draft.startDate = null;
                    draft.endDate = null;
                }))
                setTimeout(() => {
                    this.props.loadEventLog(this.props.accessToken, `atype=${this.state.dataToSend.actions}&etype=${this.state.dataToSend.entities}&user=${this.state.dataToSend.user}&date=${this.state.dataToSend.date}`);
                }, 100);
            }
            if (this.state.dataToSend.date === "Custom date range" && this.state.startDate!==null && this.state.endDate!==null) {
                setTimeout(() => {
                    this.props.loadEventLog(this.props.accessToken, `atype=${this.state.dataToSend.actions}&etype=${this.state.dataToSend.entities}&user=${this.state.dataToSend.user}&date=custom&from=${this.state.startDate + " 00:00:00"}&to=${this.state.endDate + " 23:59:59"}`);
                    }, 100);
                }
        }, 30);
        setTimeout(() => {
            this.setState(produce(draft => {
                draft.loadingData = !this.state.loadingData;
            }))
            if (this.state.loadingData) {
                this.setState(produce(draft => {
                    draft.loadingData = !this.state.loadingData;
                }))
            }
        }, 300);
    }

    popupSettings = {
        animate: false,
        anchorAlign: {
            horizontal: 'center',
            vertical: 'bottom'
        },
        popupAlign: {
            horizontal: 'center',
            vertical: 'top'
        }
    }

    dateChange = (event) => {
        let startDate = null;
        let endDate = null;
        if (event.target.value.end !== null) {
            this.setState(produce(draft => {
                draft.loadingData = !this.state.loadingData;
            }))
            startDate = new Date(event.target.value.start).toLocaleString().split(" ")[0] ;
            endDate = new Date(event.target.value.end).toLocaleString().split(" ")[0] ;
            startDate = startDate.split("/")[2] + '/' + startDate.split("/")[1] + '/' + startDate.split("/")[0];
            startDate = startDate.replace(',/', '/')
            endDate = endDate.split("/")[2] + '/' + endDate.split("/")[1] + '/' + endDate.split("/")[0];
            endDate = endDate.replace(',/','/')
        }
        this.setState(produce(draft => {
            draft.startDate = startDate;
            draft.endDate = endDate;
        }))
        if (event.target.value.end !== null) {
            setTimeout(() => {
                if (this.state.dataToSend.date === "Custom date range" && this.state.startDate!==null && this.state.endDate!==null) {
                    setTimeout(() => {
                        this.props.loadEventLog(this.props.accessToken, `atype=${this.state.dataToSend.actions}&etype=${this.state.dataToSend.entities}&user=${this.state.dataToSend.user}&date=custom&from=${this.state.startDate + " 00:00:00"}&to=${this.state.endDate + " 23:59:59"}`);
                        this.setState(produce(draft => {
                            draft.loadingData = !this.state.loadingData;
                        }))
                        }, 100);
                    }
            }, 30);
        }
    }

    closeEventLogModal = () => {
        this.setState(produce(draft => {
            draft.visibleEventLogDialog = !this.state.visibleEventLogDialog;
        }))
    }
    openEventLogModal = (props) => {
        this.setState(produce(draft => {
            draft.visibleEventLogDialog = !this.state.visibleEventLogDialog;
            draft.data = props.dataItem;
        }));
    }
    onRefreshButtonClick = () => {
        this.setState(produce(draft => {
            draft.loadingData = !this.state.loadingData;
        }))
        if (this.state.dataToSend.date !== "Custom date range") {
            setTimeout(() => {
                this.props.loadEventLog(this.props.accessToken, `atype=${this.state.dataToSend.actions}&etype=${this.state.dataToSend.entities}&user=${this.state.dataToSend.user}&date=${this.state.dataToSend.date}`);
            }, 100);
        }
        
        if (this.state.dataToSend.date === "Custom date range" && this.state.startDate!==null && this.state.endDate!==null) {
            setTimeout(() => {
                this.props.loadEventLog(this.props.accessToken, `atype=${this.state.dataToSend.actions}&etype=${this.state.dataToSend.entities}&user=${this.state.dataToSend.user}&date=custom&from=${this.state.startDate + " 00:00:00"}&to=${this.state.endDate + " 23:59:59"}`);
            }, 100);
        }
        setTimeout(() => {
            this.setState(produce(draft => {
                draft.loadingData = !this.state.loadingData;
            }))
        }, 300);
    }

    render() {
        return (
            <>
                
                <Layout>
                    <div className="settings_area_container eventlog-settings">
                        <div className="h1_con">
                            <h1 className="h1 m-l">Event log</h1>
                        </div>
                        
                        <div className="white_box_with_header_con white_bg" style={{marginBottom:"0px"}}>
                            <div className="box">
                                <section className="section text_align_left">
                                    <div className="add_custom_domain_row m-b">
                                        <div className="push">
                                        <ComboBox
                                            className="eventlog-combobox"
                                            data={this.state.user}
                                            filterable={true}
                                            onFilterChange={this.filterChange}
                                            defaultValue={"All users"}
                                            name="user"
                                            onChange={this.inputChangeHandler}
                                        />
                                        <ComboBox
                                            className="eventlog-combobox"
                                            data={this.state.actions}
                                            filterable={true}
                                            onFilterChange={this.filterChange}
                                            defaultValue={"All actions"}
                                            name="actions"
                                            onChange={this.inputChangeHandler}
                                        />
                                        <ComboBox
                                            className="eventlog-combobox"
                                            data={this.state.entities}
                                            filterable={true}
                                            onFilterChange={this.filterChange}
                                            defaultValue={"All entities"}
                                            name="entities"
                                            onChange={this.inputChangeHandler}
                                        />
                                        <ComboBox
                                            className="eventlog-combobox"
                                            data={this.state.date}
                                            filterable={true}
                                            onFilterChange={this.filterChange}
                                            defaultValue={"Today"}
                                            name="date"
                                            onChange={this.inputChangeHandler}
                                            />
                                            {
                                                this.state.dataToSend.date === "Custom date range" &&
                                                    <DateRangePicker
                                                        className="eventlog-dp"
                                                        format="yyyy/MMM/dd"
                                                        onChange={this.dateChange}
                                                        popupSettings={this.popupSettings}
                                                    />
                                            }
                                            <Button className={"refresh-btn eventlog-btn"} icon={"k-icon k-i-reload"} onClick={this.onRefreshButtonClick}>Refresh</Button>
                                        </div>
                                    </div>

                                    <>
                                        {typeof this.props.eventlogDataReceived!=='undefined' &&  this.props.eventlogDataReceived!==null &&  typeof this.props.eventlogDataReceived.eventlogData!=='undefined'?
                                            <Grid
                                                style={{ height: "100%" }}
                                                data={this.props.eventlogDataReceived.eventlogData.slice(
                                                    this.state.skip,
                                                    this.state.take + this.state.skip
                                                )}
                                                skip={this.state.skip}
                                                take={this.state.take}
                                                total={this.props.eventlogDataReceived.eventlogData.length}
                                                pageable={true}
                                                onPageChange={this.pageChange}
                                            >
                                                <GridColumn field="ProductID" title="User e-mail" cell={(props) => (<td >{ this.props.profileDataReceived.email}</td>)}/>
                                                <GridColumn field="entity_type" title="Entity type" />
                                                <GridColumn field="action_type" title="Action type" />
                                                <GridColumn field="entity_name" title="Entity name" />
                                                <GridColumn field="id" title="Entity ID" />
                                                <GridColumn field={"detail.update_at"} title="Changed at" />
                                                <GridColumn field="UnitPrice" title="Details" width={"100px"} cell={(props) => (
                                                <td>
                                                        <span className="k-icon k-i-grid-layout" style={{cursor:"pointer"}}
                                                            onClick={() => this.openEventLogModal(props)}

                                                        />
                                                </td>)} />
                                            </Grid> :
                                            <div className='col-4'>
                                                <LoadingPanel></LoadingPanel>
                                            </div>
                                        }
                                        {
                                            this.state.visibleEventLogDialog && 
                                            <EventLogModal state={this.state} closeEventLogModal={this.closeEventLogModal}></EventLogModal>
                                        }
                                        {this.state.loadingData && <LoadingPanel/>}
                                    </>
                                </section>
                            </div>
                        </div>
                    </div>
                </Layout>
            </>
        );
    }
}

export const mapStateToProps = createStructuredSelector({
    accessToken: makeSelectToken(),
    eventlogDataReceived: makeSelectEventLogData(),
    eventlogDetailDataReceived: makeSelectEventLogDetailsData(),
    profileDataReceived: makeSelectProfileData()
    //profileDataReceived: makeSelectProfileData()
});

export function mapDispatchToProps(dispatch) {
    return {
        // fetchingProfileData: (...payload) =>dispatch(profileActions.loadProfile(...payload)),
        // submitProfileData:(...payload)=>dispatch(profileActions.updateProfile(...payload)),
        fetchingProfileData: (...payload) =>dispatch(profileActions.loadProfile(...payload)),
        loadEventLog: (...payload) => dispatch(eventlogActions.loadEventLog(...payload)),
        loadEventLogDetails: (...payload) => dispatch(eventlogActions.loadEventLogDetail(...payload))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventLog);
