import React from "react";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import "./columns.css";

const GridColumnsModal = (props) => {
        return (
            <div>
                <Dialog className="add_affiliate_networks_dialogue"
                        title={"Column Settings"} onClose={() => props.toggleColumnsModal("Close")}>

                    <div className="add_affiliate_network_dialogue_content columns_dialog_content">
                        <div className="content_form">
                            <div className="note normal_font normal_text">
                                <span className="question_mark">
                                    <span className="k-icon k-i-warning icon_color_theme_default"></span>
                                </span>
                                <div className="note_p black">Column visibility settings will apply <strong>globally</strong> to all reports.</div>
                            </div>
                        </div>
                        <div className="columns_con">
                            <div className="select_all">
                                <label className="checkbox_con">Select All
                                    <input
                                        type="checkbox"
                                        name="all"
                                        id="all"
                                        checked={props.allColumnsChecked}
                                        onClick={props.columnsSelectAllHandler}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="columns">
                                {
                                    props.columnsData ?
                                        props.columnsData.map(column => (
                                            String(column.id) !== "24"
                                            ?
                                                <label className="checkbox_con">{column.name}
                                                    <input
                                                        type="checkbox"
                                                        name={column.id}
                                                        id={column.id}
                                                        checked={column.is_active}
                                                        onClick={props.columnsCheckBoxChangeHandler}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                                : null
                                        ))
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                    <DialogActionsBar>
                        <div className="action_bar_buttons">
                            <div className="left">
                                <button onClick={() => props.toggleColumnsModal("Close")} className="normal_btn cancel_button gray_button_bg_imp black border_radius_normal_btn normal_text black no_border">Cancel</button>
                            </div>
                            <div className="right">
                                <button
                                    onClick={props.saveColumnsHandler}
                                    className="normal_btn theme_dark_btn theme_dark_bg_color_imp color_white_imp border_radius_normal_btn save_button normal_text">
                                    Save
                                </button>
                            </div>
                        </div>
                    </DialogActionsBar>
                </Dialog>
            </div>
        );
};

export default GridColumnsModal;