import { createSelector } from "reselect";
import { initialGridSubLevelState } from "./reducer";

const selectGridState = (state) =>  state.Grid || initialGridSubLevelState;


const makeSelectLoading = () => createSelector(selectGridState, (subState) => subState.loading);
const makeSelectSuccess = () => createSelector(selectGridState, (subState) => subState.success);
const makeSelectError = () => createSelector(selectGridState, (subState) => subState.error);
const makeSelectSubLevelData = () => createSelector(selectGridState, (subState) => subState.subLevelData);
const makeSelectColumnsData = () => createSelector(selectGridState, (subState) => subState.columnsData);
const makeSelectIsColumnsDataPatched = () => createSelector(selectGridState, (subState) => subState.isColumnsDataPatched);

export {
    makeSelectLoading,
    makeSelectSuccess,
    makeSelectError,
    makeSelectSubLevelData,
    makeSelectColumnsData,
    makeSelectIsColumnsDataPatched
}