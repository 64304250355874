import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectNewFlows = (state) => state.newFlows || initialState;

const makeSelectCountriesLoading = () =>createSelector(selectNewFlows, (substate) => substate.countriesLoaded);
const makeSelectCountriesId = () =>createSelector(selectNewFlows, (substate) => substate.countryId);
const makeSelectCountriesData = () =>createSelector(selectNewFlows, (substate) => substate.countryData);
const makeSelectFlowsLoading = () => createSelector(selectNewFlows, (subState) => subState.loading);
const makeSelectFlowsAdd = () => createSelector(selectNewFlows, (subState) => subState.flowAdded);
const makeSelectLoadFlowsData = () => createSelector(selectNewFlows, (subState) => subState.flowData);
const makeSelectFlowName = () => createSelector(selectNewFlows, (subState) => subState.flowName);
const makeSelectLoadFlow = () => createSelector(selectNewFlows, (subState) => subState.loadFlow);
const makeSelectArchiveFlow = () => createSelector(selectNewFlows, (subState) => subState.archiveFlow);
const makeSelectDeleteFlow = () => createSelector(selectNewFlows, (subState) => subState.deleteFlow);
const makeSelectUpdateFlow = () => createSelector(selectNewFlows, (subState) => subState.updateFlow);
const makeSelectEditFlow = () => createSelector(selectNewFlows, (subState) => subState.editFlow);
const makeSelectEditDataFlow = () => createSelector(selectNewFlows, (subState) => subState.flowEditData);
const makeSelectFlowError = () => createSelector(selectNewFlows, (subState) => subState.error);
const makeSelectIsChartsDataLoaded = () => createSelector(selectNewFlows, (subState) => subState.charts.isChartsDataLoaded);
const makeSelectAllChartsDataLoaded = () => createSelector(selectNewFlows, (subState) => subState.charts.allChartsDataLoaded);


const makeSelectBrandData = () => createSelector(selectNewFlows, (subState) => subState.brandData);

const makeSelectBrowserData = () =>
    createSelector(selectNewFlows, (subState) => subState.browserData);

const makeSelectCityData = () => createSelector(selectNewFlows, (subState) => subState.cityData);
const makeSelectMCData = () => createSelector(selectNewFlows, (subState) => subState.mcData);

const makeSelectConditionCountryData = () => createSelector(selectNewFlows, (subState) => subState.conditionCountryData);


const makeSelectDeviceData = () =>
    createSelector(selectNewFlows, (subState) => subState.deviceTypeData);

const makeSelectConnectionTypeData = () =>
    createSelector(selectNewFlows, (subState) => subState.connectionTypeData);

const makeSelectLanguageData = () =>
    createSelector(selectNewFlows, (subState) => subState.languageData);

const makeSelectOperatingSystemData = () =>
    createSelector(selectNewFlows, (subState) => subState.operatingSystemData);

const makeSelectProxyData = () => createSelector(selectNewFlows, (subState) => subState.proxyData);

const makeSelectRegionData = () =>
    createSelector(selectNewFlows, (subState) => subState.regionData);

const makeSelectISPData = () =>
    createSelector(selectNewFlows, (subState) => subState.ispData);

const makeSelectAllISPData = () =>
    createSelector(selectNewFlows, (subState) => subState.ispAllData);

const makeSelectAllCitiesData = () =>
    createSelector(selectNewFlows, (subState) => subState.allCityData);

const makeSelectWeekDayData = () => createSelector(selectNewFlows, (subState) => subState.weekdayData);

const makeSelectArchiveDeleteResponseFlows = () => createSelector(selectNewFlows, (subState) => subState.archiveDeleteResponseFlows);



export {
    makeSelectCountriesLoading,
    makeSelectCountriesId,
    makeSelectCountriesData,
    makeSelectFlowError,
    makeSelectFlowsLoading,
    makeSelectDeleteFlow,
    makeSelectFlowsAdd,
    makeSelectUpdateFlow,
    makeSelectLoadFlowsData,
    makeSelectFlowName,
    makeSelectLoadFlow,
    makeSelectArchiveFlow,
    makeSelectEditFlow,
    makeSelectEditDataFlow,
    makeSelectBrandData,
    makeSelectRegionData,
    makeSelectProxyData,
    makeSelectOperatingSystemData,
    makeSelectLanguageData,
    makeSelectConnectionTypeData,
    makeSelectDeviceData,
    makeSelectCityData,
    makeSelectMCData,
    makeSelectBrowserData,
    makeSelectISPData,
    makeSelectConditionCountryData,
    makeSelectAllISPData,
    makeSelectAllCitiesData,
    makeSelectWeekDayData,
    makeSelectIsChartsDataLoaded,
    makeSelectAllChartsDataLoaded,
    makeSelectArchiveDeleteResponseFlows
};
