import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectCollaborationWorkspaceDomain = (state) => state.workspace || initialState;
	
const makeSelectWorkspaceLoading = () =>
	createSelector(selectCollaborationWorkspaceDomain, (subState) => subState.loading);

const makeSelectWorkspaceName = () =>
	createSelector(selectCollaborationWorkspaceDomain, (subState) => subState.workspaceName);

const makeSelectWorkspaceID = () =>
	createSelector(selectCollaborationWorkspaceDomain, (subState) => subState.workspaceID);

const makeSelectWorkspaceSuccess = () =>
	createSelector(selectCollaborationWorkspaceDomain, (subState) => subState.success);

const makeSelectWorkspaceAdded = () =>
	createSelector(selectCollaborationWorkspaceDomain, (subState) => subState.workspaceAdded);
	
const makeSelectWorkspaceError = () =>
	createSelector(selectCollaborationWorkspaceDomain, (subState) => subState.error);

const makeSelectWorkspaceData = () =>
	createSelector(selectCollaborationWorkspaceDomain, (subState) => subState.data);

const makeSelectWorkspaceEdit = () =>
	createSelector(selectCollaborationWorkspaceDomain, (subState) => subState.workspaceEdit);

const makeSelectWorkspaceDelete = () =>
	createSelector(selectCollaborationWorkspaceDomain, (subState) => subState.workspaceDelete);
	
export {
	makeSelectWorkspaceLoading,
	makeSelectWorkspaceName,
	makeSelectWorkspaceID,
	makeSelectWorkspaceSuccess,
	makeSelectWorkspaceAdded,
	makeSelectWorkspaceError,
	makeSelectWorkspaceData,
	makeSelectWorkspaceEdit,
	makeSelectWorkspaceDelete
}

