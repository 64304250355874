export const LOAD_IP_UA_FILTERING = "LOAD_IP_UA_FILTERING";
export const LOAD_IP_UA_FILTERING_SUCCESS = "LOAD_IP_UA_FILTERING_SUCCESS";
export const LOAD_IP_UA_FILTERING_FAILED = "LOAD_IP_UA_FILTERING_FAILED";

export const ADD_IP_UA_FILTERING = "ADD_IP_UA_FILTERING";
export const ADD_IP_UA_FILTERING_SUCCESS = "ADD_IP_UA_FILTERING_SUCCESS";
export const ADD_IP_UA_FILTERING_FAILED = "ADD_IP_UA_FILTERING_FAILED";

export const EDIT_IP_UA_FILTERING = "EDIT_IP_UA_FILTERING";
export const EDIT_IP_UA_FILTERING_SUCCESS = "EDIT_IP_UA_FILTERING_SUCCESS"; 
export const EDIT_IP_UA_FILTERING_FAILED = "EDIT_IP_UA_FILTERING_FAILED";

export const UPDATE_IP_UA_FILTERING = "UPDATE_IP_UA_FILTERING";
export const UPDATE_IP_UA_FILTERING_SUCCESS = "UPDATE_IP_UA_FILTERING_SUCCESS";
export const UPDATE_IP_UA_FILTERING_FAILED = "UPDATE_IP_UA_FILTERING_FAILED";

export const DELETE_IP_UA_FILTERING = "DELETE_IP_UA_FILTERING";
export const DELETE_IP_UA_FILTERING_SUCCESS = "DELETE_IP_UA_FILTERING_SUCCESS";
export const DELETE_IP_UA_FILTERING_FAILED = "DELETE_IP_UA_FILTERING_FAILED";

export const RESET_IP_UA_FILTERS = "RESET_IP_UA_FILTERS";
