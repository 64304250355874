import { call, put, takeLatest } from "redux-saga/effects";
import { LOAD_PROFILE, UPDATE_PASSWORD, UPDATE_PROFILE } from './constants';

import { loadProfileSuccess, loadProfileFailed,updatePasswordSuccess, updatePasswordFailed, updateProfileSuccess, updateProfileFailed } from './actions';
import { loadProfileApi, updateProfileApi, updatePasswordApi } from "./profileAPI";

function* loadProfile(payload) {
    try {
		const response = yield call(loadProfileApi, payload.payload);
		yield put(
			loadProfileSuccess({ profileData: response.data})
		);
	  } catch (error) {
		yield put(loadProfileFailed(error));
	  }
}

function* updateProfile(payload) {
    try {
		const response = yield call(updateProfileApi, payload.payload);
		//console.log('response update data', response.data);
		yield put(
			updateProfileSuccess({ updateProfileData: response.data})
		);
	  } catch (error) {
		yield put(updateProfileFailed(error));
	  }
}

function* updatePassword(payload) {
    try {
		const response = yield call(updatePasswordApi, payload.payload);
		//console.log('response update data', response.data);
		yield put(
			updatePasswordSuccess(response.data)
		);
	  } catch (error) {
		yield put(updatePasswordFailed(error));
	  }
}

export default function* ProfileSaga() {
    yield takeLatest (LOAD_PROFILE, loadProfile);
    yield takeLatest(UPDATE_PROFILE, updateProfile);
	yield takeLatest( UPDATE_PASSWORD, updatePassword)
}
