import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Layout from "../../../layout";
import PageMenuBarTop from "../../PageMenuBarTop";
import PageMenuBarBottom from "./PageMenuBarBottom";
import "../pages.css";
import { createStructuredSelector } from "reselect";
import AddCampaignModal from "./NewCampaignModel";
import AddAdvancedCampaignModal from "./NewAdvancedCampaignModel";
import DeletePageModal from "./DeleteCmpaignsModal";
import EditCampaignModal from "./EditCampaignModel";
import EditAdvancedCampaignModal from "./EditAdvancedCampaignModel";
import {
  makeSelectIsNewCampaignDataSent,
  makeSelectIsAllCampaignsLoaded,
  makeSelectIsDeleteCampaignDataSent,
  makeSelectIsArchiveCampaignDataSent,
  makeSelectAllCampaignsDataLoaded,
  makeSelectIsPatchedCampaignDataSent,
  makeSelectSearchTagsListReceived,
  makeSelectIsSearchTagsListReceived,
  makeSelectAllChartsDataLoaded,
  makeSelectIsChartsDataLoaded,
  makeSelectError,
  makeSelectArchiveDeleteResponse,
} from "./selectors";
import {
  postCampaign,
  loadAllCampaigns,
  deleteCampaign,
  archiveCampaign,
  editCampaignDialogueClosed,
  loadSearchTags,
  loadChartsData,
  timeZones,
} from "./actions";
import { makeSelectToken } from "../../../registration/LoginRegister/selectors";
import { makeSelectDomainsDataReceived } from "../../Settings/Domains/selectors";
import { loadCustomDomains } from "../../Settings/Domains/actions";
import "./Landers.css";
import produce from "immer";
import GridClassComponent from "../../grid/grid";
import { getSearchParameters } from "../../../components/searchParameters/index";

import * as newFlowsActions from "../NewFlows/actions";
import {
  makeSelectBrandData,
  makeSelectBrowserData,
  makeSelectCityData,
  makeSelectConnectionTypeData,
  makeSelectDeviceData,
  makeSelectLanguageData,
  makeSelectOperatingSystemData,
  makeSelectProxyData,
  makeSelectRegionData,
  makeSelectISPData,
  makeSelectConditionCountryData,
  makeSelectLoadFlowsData,
  makeSelectWeekDayData,
} from "../NewFlows/selectors";
import MenuCharts from "../../charts";
import { makeSelectMoveToWorkspace } from "../../../moveToWorkspaceRedux/selectors";
import { moveToWorkspace } from "../../../moveToWorkspaceRedux/actions";
import { loadAllLanders } from "./../Landers/actions";
import { loadOffers } from "../Offers/actions";
import { loadTrafficSources } from "./../TrafficSources/actions";
import { makeSelectTrafficSourcesDataLoaded } from "../TrafficSources/selectors";
import { makeSelectAllLandersDataLoaded } from "../Landers/selectors";
import { makeSelectOffersDataLoaded } from "../Offers/selectors";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import LoadingPanel from "../../grid/loader/loader";
import UpdateCostModal from "./UpdateCostCmpaignsModal";
import { TimeZonesDataFromAPI } from "./api";

class Campaigns extends Component {
  state = {
    visibleAddCampaignDialogue: false,
    visibleAdvancedAddCampaignDialogue: false,
    visibleEditCampaignDialogue: false,
    visibleEditAdvanceCampaignDialogue: false,
    visibleDeleteCampaignDialogue: false,
    visibleUpdateCostDialogue: false,
    allLoadedCampaignsData: null,
    loadedCampaignsData: null,
    isAllCampaignsLoaded: false,
    isNewCampaignAddedSuccessfully: false,
    isLanderPatched: false,
    loadedWorkspaces: null,
    loadedDomains: null,
    editLanderId: null,
    success_msg: "",
    deletedSuccessfully: false,
    archivedSuccessfully: false,
    updateState: false,
    pageContentHeight: "500",
    dataForGrid: [],
    selectedListReceivedFromGrid: [],
    isDuplicate: false,
    isArchive: false,
    archiveType: "",
    charts: {
      showCharts: false,
      chartsData: null,
      isChartsDataLoaded: false,
    },
    groupingValues: {
      main: "",
      group1: "",
      group2: "",
    },
    showCalendar: false,
    showColumnsModal: false,
    sorting: {
      order: "visits",
      sort: "ASC",
    },
    pagination: {
      skip: 0,
      take: 50,
      page: 1,
      total: 0,
      totalPages: 1,
      blur: {
        blurFirst: true,
        blurLast: true,
        blurPrev: true,
        blurNext: true,
      },
    },
    filterStatus: "all",
    backendErrors: {
      error: false,
      errorMsg: "",
    },
    loader: false,
    disableReport: false,
    reportCount: 0,
    archiveDeleteResponse: null,
    showNoRecordFound: false,
  };

  // comment to create pull request
  fetchDomainsAndWorkspaces = async () => {
    return this.props.dispatchLoadDomainsAndWorkspaces(this.props.accessToken);
  };

  fetchAllCampaigns = async () => {
    this.setState(
      produce((draft) => {
        draft.loader = true;
        draft.selectedListReceivedFromGrid = [];
      })
    );
    let search = JSON.parse(localStorage.getItem("search"));
    this.setState(
      produce((draft) => {
        draft.groupingValues = search.groupingValues;
      })
    );
    return this.props.dispatchLoadAllCampaigns({
      accessToken: this.props.accessToken,
      searchQuery: getSearchParameters(),
      sorting: this.state.sorting,
      primaryPage: true,
    });
  };
  fetchChartsData = () => {
    return this.props.dispatchLoadChartsData({
      accessToken: this.props.accessToken,
      dates: JSON.parse(localStorage.getItem("search")).modifiedDate,
    });
  };
  editCampaignDialogueClosed = () => {
    return this.props.editCampaignDialogueClosed();
  };
  fetchSearchTags = async () => {
    return this.props.dispatchLoadSearchTags({
      accessToken: this.props.accessToken,
    });
  };
  fetchTrafficSources = async () => {
    return this.props.dispatchLoadTrafficSources({
      accessToken: this.props.accessToken,
      searchQuery: JSON.parse(localStorage.getItem("search")),
      sorting: { order: "id", sort: "ASC" },
      primaryPage: false,
    });
  };
  fetchAllLanders = async () => {
    return this.props.dispatchLoadAllLanders({
      accessToken: this.props.accessToken,
      searchQuery: JSON.parse(localStorage.getItem("search")),
      sorting: { order: "id", sort: "ASC" },
      primaryPage: false,
    });
  };
  fetchAllOffers = async () => {
    return this.props.dispatchLoadAllOffers(
      this.props.accessToken,
      JSON.parse(localStorage.getItem("search")),
      { order: "id", sort: "ASC" },
      false
    );
  };
  fetchAllFlows = async () => {
    return this.props.dispatchLoadFlows(
      this.props.accessToken,
      JSON.parse(localStorage.getItem("search")),
      { order: "id", sort: "ASC" },
      false
    );
  };

  componentDidMount() {
    // check if main group value is not campaign value...
    // than set it to affiliate network value to avoid any errors.
    //this.fetchAllLanders();
    if (
      JSON.parse(localStorage.getItem("search")) &&
      JSON.parse(localStorage.getItem("search")).groupingValues.main !==
        "Campaign"
    ) {
      let search = JSON.parse(localStorage.getItem("search"));
      search.groupingValues.main = "Campaign";
      // search.reportGroupingValues.reportType = "campaign";
      localStorage.setItem("search", JSON.stringify(search));
    }
    // set initial grouping values in state to be passed on to the grid...
    // so that is does not show error grouping values of none.
    if (JSON.parse(localStorage.getItem("search"))) {
      this.setState(
        produce((draft) => {
          // draft.groupingValues.main = JSON.parse(localStorage.getItem("search")).groupingValues.main;
          draft.groupingValues.main = "campaign";
          draft.groupingValues.group1 = JSON.parse(
            localStorage.getItem("search")
          ).groupingValues.group1;
          draft.groupingValues.group2 = JSON.parse(
            localStorage.getItem("search")
          ).groupingValues.group2;
          draft.showCalendar = JSON.parse(
            localStorage.getItem("search")
          ).showCalendar;
          draft.filterStatus = JSON.parse(
            localStorage.getItem("search")
          ).filterStatus;
          draft.reportCount = JSON.parse(
            localStorage.getItem("search")
          ).report.reportCount;
        })
      );
    }

    // fetching currencies and workspaces initially

    setTimeout(() => {
      this.fetchAllCampaigns();
    }, 100);

    // code for setting grid height initially
    let pageMenuHeight = Number(
      document.getElementById("page_menu").clientHeight
    );
    let pageFullHeight = Number(
      document.getElementsByTagName("html")[0].clientHeight
    );
    this.setState(
      produce((draft) => {
        draft.pageContentHeight = pageFullHeight - pageMenuHeight - 127;
      })
    );

    //conditons data loading in advance
    // if (this.state.loadedWorkspaces === null || this.state.loadedDomains === null) { this.fetchDomainsAndWorkspaces(); }
    // this.fetchSearchTags();
    // this.fetchAllLanders();
    // this.fetchAllOffers();
    // this.fetchAllFlows();
    // this.fetchTrafficSources();

    // if (this.props.browserDataReceived === null) { this.props.fetchBrowserData(this.props.accessToken); }
    // if (this.props.brandDataReceived === null) { this.props.fetchBrandData(this.props.accessToken); }
    // if (this.props.languageDataReceived === null) { this.props.fetchLanguageData(this.props.accessToken); }
    // if (this.props.proxyDataReceived === null) { this.props.fetchProxyData(this.props.accessToken); }
    // if (this.props.regionDataReceived === null) { this.props.fetchRegionData(this.props.accessToken); }
    // if (this.props.cityDataReceived === null) { this.props.fetchCityData(this.props.accessToken); }
    // if (this.props.conditionCountryDaraReceived === null) { this.props.fetchCountryData(this.props.accessToken); }
    // if (this.props.connectionTypeDataReceived === null) { this.props.fetchConnectionTypeData(this.props.accessToken); }
    // if (this.props.deviceTypeDataReceived === null) { this.props.fetchDeviceTypeData(this.props.accessToken); }
    // if (this.props.osDataReceived === null) { this.props.fetchOperatingSystemData(this.props.accessToken); }
    // if (this.props.ispDataReceived === null) { this.props.fetchISPData(this.props.accessToken); }
    // if (this.props.weekdayDataReceived === null) { this.props.fetchWeekDayData(this.props.accessToken); }
    this.setState({ showNoRecordFound: true });
  }

  returnWorkspaceName = (id) => {
    for (let i = 0; i < this.state.loadedWorkspaces.length; i++) {
      if (id === this.state.loadedWorkspaces[i].id) {
        return this.state.loadedWorkspaces[i].name;
      }
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Set workspaces and currencies initial values in the state
    if (
      this.props.domainsAndWorkspacesListsReceived !== null &&
      this.props.domainsAndWorkspacesListsReceived !== undefined &&
      this.state.loadedWorkspaces === null
    ) {
      this.setState(
        produce((draft) => {
          draft.loadedWorkspaces =
            this.props.domainsAndWorkspacesListsReceived.workspace;
        })
      );
      this.setState(
        produce((draft) => {
          draft.loadedDomains =
            this.props.domainsAndWorkspacesListsReceived.domain;
        })
      );
    }

    // check if new Campaign data has been sent then load fresh copy of data
    if (
      this.props.isNewCampaignDataSent === true &&
      this.state.isNewCampaignAddedSuccessfully === true
    ) {
      this.setState(
        produce((draft) => {
          draft.isAllCampaignsLoaded = false;
        })
      );
      this.fetchAllCampaigns();
      this.setState(
        produce((draft) => {
          draft.isNewCampaignAddedSuccessfully = false;
        })
      );
    }

    // Display message on delete source
    // if (this.props.isCampaignDeleted && this.state.deletedSuccessfully) {
    if (
      prevProps.isCampaignDeleted !== this.props.isCampaignDeleted &&
      this.props.isCampaignDeleted === true &&
      this.state.deletedSuccessfully === true
    ) {
      // console.log("This props isCampaignDeleted: ", this.props.isCampaignDeleted);
      this.setState(
        produce((draft) => {
          draft.loader = false;
          draft.visibleDeleteCampaignDialogue =
            !this.state.visibleDeleteCampaignDialogue;
          draft.success_msg = "Campaign deleted successfully";
          draft.isAllCampaignsLoaded = false;
          // change deletedSuccessfully to false to that this loop runs only once
          draft.deletedSuccessfully = false;
        })
      );
      this.fetchAllCampaigns();
      setTimeout(() => {
        this.setState(
          produce((draft) => {
            draft.success_msg = "";
          })
        );
      }, 10000);
    }

    // Display message on archive or restore source
    if (
      prevProps.isCampaignArchived !== this.props.isCampaignArchived &&
      this.props.isCampaignArchived === true &&
      this.state.archivedSuccessfully === true
    ) {
      this.setState(
        produce((draft) => {
          draft.loader = false;
          draft.success_msg = "Campaign archived successfully";
          draft.visibleDeleteCampaignDialogue =
            !this.state.visibleDeleteCampaignDialogue;
          draft.isAllCampaignsLoaded = false;
          // change deletedSuccessfully to false to that this loop runs only once
          draft.archivedSuccessfully = false;
        })
      );
      this.fetchAllCampaigns();
      setTimeout(() => {
        this.setState(
          produce((draft) => {
            draft.success_msg = "";
          })
        );
      }, 10000);
    }

    // Display message on Patched Campaign success
    if (this.props.isCampaignPatched && this.state.isCampaignPatched) {
      this.setState(
        produce((draft) => {
          draft.success_msg = "Campaign Updated successfully";
        })
      );
      this.setState(
        produce((draft) => {
          draft.isAllCampaignsLoaded = false;
        })
      );
      this.fetchAllCampaigns();
      // change deletedSuccessfully to false to that this loop runs only once
      this.setState(
        produce((draft) => {
          draft.isCampaignPatched = false;
        })
      );
      setTimeout(() => {
        this.setState(
          produce((draft) => {
            draft.success_msg = "";
          })
        );
      }, 10000);
    }

    // load data from props to state after each update in the data, edit / delete / add / duplicate
    if (
      prevProps.allCampaignsDataLoaded !== this.props.allCampaignsDataLoaded &&
      this.props.allCampaignsDataLoaded !== null
    ) {
      this.setState(
        produce((draft) => {
          draft.isAllCampaignsLoaded = true;
          draft.allLoadedCampaignsData = this.props.allCampaignsDataLoaded;
          if (draft.pagination.total) {
            draft.pagination.total =
              this.props.allCampaignsDataLoaded.total.totalRows;
          }
          draft.loader = false;
        })
      );
      this.setTotalPagination();
      setTimeout(() => {
        this.setPaginationUI();
      }, 50);
    }

    // if ((prevProps.allCampaignsDataLoaded !== this.props.allCampaignsDataLoaded) && prevProps.allCampaignsDataLoaded === null) {
    //     // if condition is true than data is being loaded for the first time.
    //     //conditons data loading in advance
    //     this.fetchAllLanders();
    //     this.fetchAllOffers();
    //     this.fetchAllFlows();
    //     this.fetchTrafficSources();
    //     if (this.props.browserDataReceived === null) { this.props.fetchBrowserData(this.props.accessToken); }
    //     if (this.props.brandDataReceived === null) { this.props.fetchBrandData(this.props.accessToken); }
    //     if (this.props.languageDataReceived === null) { this.props.fetchLanguageData(this.props.accessToken); }
    //     if (this.props.proxyDataReceived === null) { this.props.fetchProxyData(this.props.accessToken); }
    //     if (this.props.regionDataReceived === null) { this.props.fetchRegionData(this.props.accessToken); }
    //     if (this.props.cityDataReceived === null) { this.props.fetchCityData(this.props.accessToken); }
    //     if (this.props.conditionCountryDaraReceived === null) { this.props.fetchCountryData(this.props.accessToken); }
    //     if (this.props.connectionTypeDataReceived === null) { this.props.fetchConnectionTypeData(this.props.accessToken); }
    //     if (this.props.deviceTypeDataReceived === null) { this.props.fetchDeviceTypeData(this.props.accessToken); }
    //     if (this.props.osDataReceived === null) { this.props.fetchOperatingSystemData(this.props.accessToken); }
    //     if (this.props.ispDataReceived === null) { this.props.fetchISPData(this.props.accessToken); }
    //     if (this.props.weekdayDataReceived === null) { this.props.fetchWeekDayData(this.props.accessToken); }
    // }

    if (
      prevProps.chartsAllDataLoaded !== this.props.chartsAllDataLoaded &&
      this.props.chartsAllDataLoaded !== null
    ) {
      this.setState(
        produce((draft) => {
          draft.charts.chartsData = this.props.chartsAllDataLoaded;
        })
      );
    }

    if (
      prevProps.isMovedToWorkspace !== this.props.isMovedToWorkspace &&
      this.props.isMovedToWorkspace === true
    ) {
      this.fetchAllCampaigns();
      this.setState(
        produce((draft) => {
          draft.loader = false;
        })
      );
    }
    // if (this.props.allLandersDataLoaded !== null && typeof this.props.allLandersDataLoaded!== 'undefined' && this.props.allLandersDataLoaded.length === 0 ) {
    //     this.fetchAllLanders();
    // }

    // Check the error
    if (prevProps.Error !== this.props.Error && this.props.Error !== false) {
      if (this.props.Error && this.props.Error.data) {
        this.setState(
          produce((draft) => {
            draft.backendErrors.errorMsg =
              (this.props.Error.data.message
                ? this.props.Error.data.message
                : null) +
              ", " +
              (this.props.Error.data.error
                ? this.props.Error.data.error
                : null);
            draft.backendErrors.error = true;
            draft.loader = false;
          })
        );
      }
    }
    if (prevProps.Error !== this.props.Error && this.props.Error === false) {
      this.setState(
        produce((draft) => {
          draft.backendErrors.errorMsg = null;
        })
      );
    }

    // check for archive or delete or restore message from backend
    if (
      prevProps.archiveDeleteResponse !== this.props.archiveDeleteResponse &&
      this.props.archiveDeleteResponse !== null
    ) {
      this.setState(
        produce((draft) => {
          draft.archiveDeleteResponse = this.props.archiveDeleteResponse;
        })
      );
      setTimeout(() => {
        this.setState(
          produce((draft) => {
            draft.archiveDeleteResponse = null;
          })
        );
      }, 10000);
    }
  }

  // toggle modal windows
  toggleCampaignDialogue = (data) => {
    if (data === "Toggle_Campaign_Simple") {
      this.setState(
        produce((draft) => {
          if (this.state.visibleAddCampaignDialogue !== true) {
            draft.loader = !this.state.loader;
          }
          draft.visibleAddCampaignDialogue =
            !this.state.visibleAddCampaignDialogue;
        })
      );
    } else if (data === "Toggle_Campaign_Advanced") {
      this.setState(
        produce((draft) => {
          draft.visibleAdvancedAddCampaignDialogue =
            !this.state.visibleAdvancedAddCampaignDialogue;
        })
      );
    } else if (data === "UpdateCost_Toggle_Campaign") {
      this.setState(
        produce((draft) => {
          draft.visibleUpdateCostDialogue =
            !this.state.visibleUpdateCostDialogue;
          draft.loader = !this.state.loader;
        })
      );
    } else if (data === "Edit_Toggle_Campaign") {
      if (this.state.selectedListReceivedFromGrid[0].extra_info === false) {
        this.setState(
          produce((draft) => {
            if (this.state.visibleEditCampaignDialogue !== true) {
              draft.loader = !this.state.loader;
            }
            draft.visibleEditCampaignDialogue =
              !this.state.visibleEditCampaignDialogue;
            draft.isDuplicate = false;
          })
        );
      } else if (
        this.state.selectedListReceivedFromGrid[0].extra_info === true
      ) {
        if (this.state.visibleEditAdvanceCampaignDialogue) {
          this.props.editCampaignDialogueClosed();
        }
        this.setState(
          produce((draft) => {
            draft.visibleEditAdvanceCampaignDialogue =
              !this.state.visibleEditAdvanceCampaignDialogue;
            draft.isDuplicate = false;
          })
        );
      }
    } else if (data === "Edit_Toggle_Campaign_Duplicate") {
      if (this.state.selectedListReceivedFromGrid[0].extra_info === false) {
        this.setState(
          produce((draft) => {
            if (this.state.visibleEditCampaignDialogue !== true) {
              draft.loader = !this.state.loader;
            }
            draft.visibleEditCampaignDialogue =
              !this.state.visibleEditCampaignDialogue;
            draft.isDuplicate = true;
          })
        );
      } else if (
        this.state.selectedListReceivedFromGrid[0].extra_info === true
      ) {
        this.setState(
          produce((draft) => {
            draft.visibleEditAdvanceCampaignDialogue =
              !this.state.visibleEditAdvanceCampaignDialogue;
            draft.isDuplicate = true;
          })
        );
      }
    }
  };

  // Close the Modal window after data submission and
  // make isNewCampaignAddedSuccessfully to true to fetch the landers again.
  closeAddCampaignDialogueAfterSubmission = () => {
    this.setState(
      produce((draft) => {
        draft.visibleAddCampaignDialogue =
          !this.state.visibleAddCampaignDialogue;
      })
    );
    this.setState(
      produce((draft) => {
        draft.isNewCampaignAddedSuccessfully = true;
      })
    );
  };
  closeAddAdvancedCampaignDialogueAfterSubmission = () => {
    this.setState(
      produce((draft) => {
        draft.visibleAdvancedAddCampaignDialogue =
          !this.state.visibleAdvancedAddCampaignDialogue;
      })
    );
    this.setState(
      produce((draft) => {
        draft.isNewCampaignAddedSuccessfully = true;
      })
    );
  };

  // ------------------
  // edit functionality
  // for simple
  closeEditCampaignDialogueAfterSubmission = (data) => {
    //this.props.editCampaignDialogueClosed();
    if (data === "true") {
      this.setState(
        produce((draft) => {
          draft.visibleEditCampaignDialogue =
            !this.state.visibleEditCampaignDialogue;
        })
      );
      this.setState(
        produce((draft) => {
          draft.isNewCampaignAddedSuccessfully = true;
        })
      );
    } else {
      this.setState(
        produce((draft) => {
          draft.visibleEditCampaignDialogue =
            !this.state.visibleEditCampaignDialogue;
        })
      );
      this.setState(
        produce((draft) => {
          draft.isCampaignPatched = true;
        })
      );
    }
  };

  // close update cost modal
  closeUpdateCostModal = (data) => {
    if (data === "true") {
      this.toggleLoader();
    }
    this.setState(
      produce((draft) => {
        draft.visibleUpdateCostDialogue = !this.state.visibleUpdateCostDialogue;
      })
    );
  };

  // edit functionality
  // for advanced
  closeEditAdvancedCampaignDialogueAfterSubmission = (data) => {
    //this.props.editCampaignDialogueClosed();
    if (data === "true") {
      this.setState(
        produce((draft) => {
          draft.visibleEditAdvanceCampaignDialogue =
            !this.state.visibleEditAdvanceCampaignDialogue;
        })
      );
      this.setState(
        produce((draft) => {
          draft.isNewCampaignAddedSuccessfully = true;
        })
      );
    } else {
      this.setState(
        produce((draft) => {
          draft.visibleEditAdvanceCampaignDialogue =
            !this.state.visibleEditAdvanceCampaignDialogue;
        })
      );
      this.setState(
        produce((draft) => {
          draft.isCampaignPatched = true;
        })
      );
    }
  };

  sendSearchRequest = () => {
    let search = JSON.parse(localStorage.getItem("search"));
    search.pagination.skip = 0;
    if (
      search.groupingValues.main === "conversion" ||
      search.groupingValues.main === "months" ||
      search.groupingValues.main === "days" ||
      search.groupingValues.main === "hours"
    ) {
      this.setState(
        produce((draft) => {
          draft.disableReport = true;
        })
      );
    } else {
      this.setState(
        produce((draft) => {
          draft.disableReport = false;
        })
      );
    }
    localStorage.setItem("search", JSON.stringify(search));
    this.setState(
      produce((draft) => {
        draft.pagination.page = 1;
      })
    );
    this.setPaginationUI();
    this.fetchAllCampaigns();
    this.fetchChartsData();
    this.setState(
      produce((draft) => {
        draft.isAllCampaignsLoaded = false;
        draft.showCalendar = false;
      })
    );
  };

  gridCheckedList = (list) => {
    this.setState(
      produce((draft) => {
        draft.selectedListReceivedFromGrid = list;
      })
    );
  };

  toggleDeleteArchiveModal = (data) => {
    // console.log(this.state.selectedListReceivedFromGrid);
    this.setState(
      produce((draft) => {
        draft.visibleDeleteCampaignDialogue =
          !this.state.visibleDeleteCampaignDialogue;
        if (data === "Archive") {
          draft.archiveType = "Archive";
        } else if (data === "Delete") {
          draft.archiveType = "Delete";
        } else if (data === "Restore") {
          draft.archiveType = "Restore";
        } else if (data === "Close") {
          draft.archiveType = "";
        }
      })
    );
  };
  archiveCampaignHandler = (data) => {
    this.setState(
      produce((draft) => {
        draft.loader = true;
        draft.archivedSuccessfully = true;
        // draft.visibleDeleteCampaignDialogue = !this.state.visibleDeleteCampaignDialogue;
      })
    );
    let ids = [];
    for (let i = 0; i < this.state.selectedListReceivedFromGrid.length; i++) {
      ids.push(this.state.selectedListReceivedFromGrid[i].id);
    }
    return this.props.dispatchArchiveCampaign({
      accessToken: this.props.accessToken,
      campaignId: ids,
      value: data === "Archive" ? false : true,
    });
  };
  deleteCampaignHandler = () => {
    // console.log("selected item: ", this.state.selectedListReceivedFromGrid);
    this.setState(
      produce((draft) => {
        draft.loader = true;
        draft.deletedSuccessfully = true;
        // draft.visibleDeleteCampaignDialogue = !this.state.visibleDeleteCampaignDialogue;
      })
    );
    let ids = [];
    for (let i = 0; i < this.state.selectedListReceivedFromGrid.length; i++) {
      ids.push(this.state.selectedListReceivedFromGrid[i].id);
    }
    return this.props.dispatchDeleteCampaign({
      accessToken: this.props.accessToken,
      campaignId: ids,
    });
  };

  showCharts = () => {
    if (this.state.charts.showCharts !== true) {
      this.fetchChartsData();
    }
    this.setState(
      produce((draft) => {
        draft.charts.showCharts = !this.state.charts.showCharts;
        draft.showCalendar = false;
        draft.charts.isChartsDataLoaded = !this.state.charts.isChartsDataLoaded;
      })
    );
  };

  getTopMenuDate = () => {};
  toggleCalendarWindow = () => {
    this.setState(
      produce((draft) => {
        draft.showCalendar = !this.state.showCalendar;
      })
    );
  };

  moveToWorkspaceMethod = (workspaceId) => {
    this.setState(
      produce((draft) => {
        draft.loader = true;
      })
    );
    let selectedList = [...this.state.selectedListReceivedFromGrid];
    let idStringList = [];
    for (let i = 0; i < this.state.selectedListReceivedFromGrid.length; i++) {
      idStringList.push(selectedList[i].id);
    }
    return this.props.dispatchMoveToWorkspace({
      accessToken: this.props.accessToken,
      query: {
        type: "campaign",
        typeIdStringList: idStringList.join(","),
        workspaceId: workspaceId,
      },
    });
  };
  toggleColumnsModal = () => {
    this.setState(
      produce((draft) => {
        draft.showColumnsModal = !this.state.showColumnsModal;
      })
    );
  };

  changeSorting = (data) => {
    let sorting = { ...this.state.sorting };
    if (String(sorting.order) === String(data) && sorting.sort === "ASC") {
      this.setState(
        produce((draft) => {
          draft.sorting.sort = "DESC";
        })
      );
    } else if (
      String(sorting.order) === String(data) &&
      sorting.sort === "DESC"
    ) {
      this.setState(
        produce((draft) => {
          draft.sorting.sort = "ASC";
        })
      );
    } else if (String(sorting.order) !== String(data)) {
      this.setState(
        produce((draft) => {
          draft.sorting.order = data;
          draft.sorting.sort = "ASC";
        })
      );
    }
    setTimeout(() => {
      this.fetchAllCampaigns();
    }, 50);
  };

  setTotalPagination = () => {
    if (this.props.allCampaignsDataLoaded.total) {
      if (Number(this.props.allCampaignsDataLoaded.total.totalRows) > 0) {
        let totalRows = Number(
          this.props.allCampaignsDataLoaded.total.totalRows
        );
        if (totalRows <= this.state.pagination.take) {
          this.setState(
            produce((draft) => {
              draft.pagination.totalPages = 1;
            })
          );
        } else {
          if (totalRows % Number(this.state.pagination.take) > 0) {
            this.setState(
              produce((draft) => {
                draft.pagination.totalPages =
                  Math.floor(totalRows / this.state.pagination.take) + 1;
              })
            );
          } else {
            this.setState(
              produce((draft) => {
                draft.pagination.totalPages =
                  totalRows / this.state.pagination.take;
              })
            );
          }
        }
      } else {
        this.setState(
          produce((draft) => {
            draft.pagination.totalPages = 1;
          })
        );
      }
    }
  };
  setPaginationUI = () => {
    if (Number(this.state.pagination.totalPages) === 1) {
      this.setState(
        produce((draft) => {
          draft.pagination.blur.blurFirst = true;
          draft.pagination.blur.blurPrev = true;
          draft.pagination.blur.blurNext = true;
          draft.pagination.blur.blurLast = true;
        })
      );
    } else if (
      this.state.pagination.page === 1 &&
      this.state.pagination.totalPages > 1
    ) {
      this.setState(
        produce((draft) => {
          draft.pagination.blur.blurFirst = true;
          draft.pagination.blur.blurPrev = true;
          draft.pagination.blur.blurNext = false;
          draft.pagination.blur.blurLast = false;
        })
      );
    } else if (
      this.state.pagination.page !== 1 &&
      this.state.pagination.totalPages > 1 &&
      this.state.pagination.page < this.state.pagination.totalPages
    ) {
      this.setState(
        produce((draft) => {
          draft.pagination.blur.blurFirst = false;
          draft.pagination.blur.blurPrev = false;
          draft.pagination.blur.blurNext = false;
          draft.pagination.blur.blurLast = false;
        })
      );
    } else if (
      this.state.pagination.page !== 1 &&
      this.state.pagination.totalPages > 1 &&
      this.state.pagination.page === this.state.pagination.totalPages
    ) {
      this.setState(
        produce((draft) => {
          draft.pagination.blur.blurFirst = false;
          draft.pagination.blur.blurPrev = false;
          draft.pagination.blur.blurNext = true;
          draft.pagination.blur.blurLast = true;
        })
      );
    }
  };

  changePagination = (data) => {
    let search = JSON.parse(localStorage.getItem("search"));
    if (data === "first") {
      this.setState(
        produce((draft) => {
          draft.pagination.skip = 0;
          draft.pagination.page = 1;
        })
      );
      search.pagination.skip = 0;
    } else if (data === "prev") {
      let dataToSkip = this.state.pagination.skip - this.state.pagination.take;
      this.setState(
        produce((draft) => {
          draft.pagination.skip = dataToSkip;
          draft.pagination.page -= 1;
        })
      );
      search.pagination.skip = dataToSkip;
    } else if (data === "next") {
      let dataToSkip = this.state.pagination.skip + this.state.pagination.take;
      this.setState(
        produce((draft) => {
          draft.pagination.skip = dataToSkip;
          draft.pagination.page += 1;
        })
      );
      search.pagination.skip = dataToSkip;
    } else if (data === "last") {
      let dataToSkip =
        (this.state.pagination.totalPages - 1) * this.state.pagination.take;
      this.setState(
        produce((draft) => {
          draft.pagination.skip = dataToSkip;
          draft.pagination.page = this.state.pagination.totalPages;
        })
      );
      search.pagination.skip = dataToSkip;
    }

    localStorage.setItem("search", JSON.stringify(search));
    setTimeout(() => {
      this.fetchAllCampaigns();
    }, 50);
    this.setPaginationUI();
  };

  changeGridRowsNumber = (e) => {
    let search = JSON.parse(localStorage.getItem("search"));
    search.pagination.take = Number(e.target.value);
    search.pagination.skip = 0;
    this.setState(
      produce((draft) => {
        draft.pagination.skip = 0;
        draft.pagination.page = 1;
        draft.pagination.take = Number(e.target.value);
      })
    );
    localStorage.setItem("search", JSON.stringify(search));
    setTimeout(() => {
      this.fetchAllCampaigns();
    }, 50);
    this.setPaginationUI();
  };

  changeFilterStatusType = (e) => {
    let search = JSON.parse(localStorage.getItem("search"));
    search.filterStatus = e.target.value;
    this.setState(
      produce((draft) => {
        draft.filterStatus = e.target.value;
      })
    );
    localStorage.setItem("search", JSON.stringify(search));
    setTimeout(() => {
      this.fetchAllCampaigns();
    }, 50);
  };

  toggleLoader = () => {
    this.setState(
      produce((draft) => {
        draft.loader = !this.state.loader;
      })
    );
  };
  removeLoader = () => {
    this.setState(
      produce((draft) => {
        draft.loader = false;
      })
    );
  };

  openNewReport = (data) => {
    let search = JSON.parse(localStorage.getItem("search"));
    if (data === "minus" && this.state.reportCount > 0) {
      search.report.reportCount = this.state.reportCount - 1;
      this.setState(
        produce((draft) => {
          draft.reportCount = this.state.reportCount - 1;
        })
      );
      localStorage.setItem("search", JSON.stringify(search));
    } else if (data === "plus") {
      let count = this.state.reportCount + 1;
      search.report["report" + count].name =
        this.state.selectedListReceivedFromGrid[0].name;
      search.report.reportCount = count;
      search.activeTab = count;
      this.setState(
        produce((draft) => {
          draft.reportCount = this.state.reportCount + 1;
        })
      );
      let startDate = search.modifiedDate.startDate;
      let endDate = search.modifiedDate.endDate;
      localStorage.setItem("search", JSON.stringify(search));
      setTimeout(() => {
        this.props.history.push({
          pathname: "/report",
          search:
            "id=" +
            this.state.selectedListReceivedFromGrid[0].id +
            "&reportType=" +
            search.groupingValues.main +
            "&startDate=" +
            startDate +
            "&endDate=" +
            endDate +
            "&new=true",
          hash: "",
        });
      }, 80);
    }
  };

  decreaseReportCount = () => {
    this.setState(
      produce((draft) => {
        draft.reportCount = Number(this.state.reportCount) - 1;
      })
    );
  };

  render() {
    return (
      <Fragment>
        <Layout
          reportCount={this.state.reportCount}
          decreaseReportCount={this.decreaseReportCount}
        >
          <div id="page_container" className="page_container">
            <div id="page_menu" className="page_menu_bar">
              <PageMenuBarTop
                sendSearchRequest={this.sendSearchRequest}
                showCharts={this.showCharts}
                toggleLoader={this.toggleLoader}
                showCalendar={this.state.showCalendar}
                toggleCalendarWindow={this.toggleCalendarWindow}
                pageType="Campaign"
              />
              {this.state.charts.showCharts ? (
                <ResponsiveMenuCharts
                  data={this.state.charts.chartsData}
                  dates={
                    JSON.parse(localStorage.getItem("search")).modifiedDate
                  }
                />
              ) : null}
              <PageMenuBarBottom
                toggleCampaignDialogue={this.toggleCampaignDialogue}
                loadedWorkspaces={this.state.loadedWorkspaces}
                selectedListReceivedFromGrid={
                  this.state.selectedListReceivedFromGrid
                }
                // new code
                toggleDeleteArchiveModal={this.toggleDeleteArchiveModal}
                moveToWorkspaceMethod={this.moveToWorkspaceMethod}
                toggleColumnsModal={this.toggleColumnsModal}
                pagination={this.state.pagination}
                changePagination={this.changePagination}
                changeGridRowsNumber={this.changeGridRowsNumber}
                changeFilterStatusType={this.changeFilterStatusType}
                filterStatus={this.state.filterStatus}
                reportId={
                  this.state.selectedListReceivedFromGrid.length > 0
                    ? this.state.selectedListReceivedFromGrid[0].id
                    : ""
                }
                disable={this.state.disableReport}
                openNewReport={this.openNewReport}
                reportCount={this.state.reportCount}
              />
              <div className="page_content"></div>
              <div className="page_modal">
                {this.state.visibleAddCampaignDialogue && (
                  <AddCampaignModal
                    state={this.state}
                    toggleLoader={this.toggleLoader}
                    toggleCampaignDialogue={this.toggleCampaignDialogue}
                    closeAddCampaignDialogueAfterSubmission={
                      this.closeAddCampaignDialogueAfterSubmission
                    }
                  />
                )}
                {this.state.visibleAdvancedAddCampaignDialogue && (
                  <AddAdvancedCampaignModal
                    state={this.state}
                    toggleCampaignDialogue={this.toggleCampaignDialogue}
                    closeAddAdvancedCampaignDialogueAfterSubmission={
                      this.closeAddAdvancedCampaignDialogueAfterSubmission
                    }
                    toggleLoader={this.toggleLoader}
                    removeLoader={this.removeLoader}
                  />
                )}
              </div>
            </div>
            {this.state.loader && <LoadingPanel />}
            <div
              style={{ height: `calc(100% - 85px - 127px)`}}
              className="temporary_affiliate_network_page_data"
            >
              <GridClassComponent
                pageType="Campaign"
                gridHeight={this.state.pageContentHeight}
                allDataLoaded={this.state.allLoadedCampaignsData}
                isAllDataLoaded={this.state.isAllCampaignsLoaded}
                paginationSize={this.state.paginationSize}
                gridCheckedList={(list) => this.gridCheckedList(list)}
                groupingValues={this.state.groupingValues}
                showColumnsModal={this.state.showColumnsModal}
                toggleColumnsModal={this.toggleColumnsModal}
                sorting={this.state.sorting}
                changeSorting={this.changeSorting}
                toggleLoader={this.toggleLoader}
                selectedListFromPage={this.state.selectedListReceivedFromGrid}
                showNoRecordFound={this.state.showNoRecordFound}
                
              />
            </div>

            {/* Edit and duplicate campaign modal*/}
            {this.state.visibleEditCampaignDialogue && (
              <EditCampaignModal
                state={this.state}
                toggleLoader={this.toggleLoader}
                campaignId={this.state.selectedListReceivedFromGrid[0].id}
                toggleCampaignDialogue={this.toggleCampaignDialogue}
                closeEditCampaignDialogueAfterSubmission={
                  this.closeEditCampaignDialogueAfterSubmission
                }
              />
            )}
            {this.state.visibleEditAdvanceCampaignDialogue && (
              <EditAdvancedCampaignModal
                state={this.state}
                campaignId={this.state.selectedListReceivedFromGrid[0].id}
                toggleCampaignDialogue={this.toggleCampaignDialogue}
                toggleLoader={this.toggleLoader}
                removeLoader={this.removeLoader}
                closeEditAdvancedCampaignDialogueAfterSubmission={
                  this.closeEditAdvancedCampaignDialogueAfterSubmission
                }
              />
            )}
            {/*delete campaign modal*/}
            {this.state.visibleDeleteCampaignDialogue && (
              <DeletePageModal
                state={this.state}
                closeDeleteCampaignDialog={this.closeDeleteCampaignDialog}
                deleteCampaignOnSubmit={this.deleteCampaignOnSubmit}
                // new code
                archiveCampaignHandler={this.archiveCampaignHandler}
                deleteCampaignHandler={this.deleteCampaignHandler}
                toggleDeleteArchiveModal={this.toggleDeleteArchiveModal}
              />
            )}
            {/*Update cost modal*/}
            {this.state.visibleUpdateCostDialogue && (
              <UpdateCostModal
                state={this.state}
                toggleLoader={this.toggleLoader}
                toggleCampaignDialogue={this.toggleCampaignDialogue}
                closeUpdateCostModal={this.closeUpdateCostModal}
                campaignId={this.state.selectedListReceivedFromGrid[0].id}
                campaignName={this.state.selectedListReceivedFromGrid[0].name}
              />
            )}
            {this.state.backendErrors.error && (
              <NotificationGroup
                style={{
                  alignItems: "flex-start",
                  flexWrap: "wrap-reverse",
                  left: "50%",
                  zIndex: 10000000,
                  top: "20px",
                  transform: "translateX(-50%)",
                }}
              >
                <Fade enter={true} exit={true}>
                  {this.state.backendErrors.error && (
                    <Notification
                      type={{ style: "error", icon: true }}
                      closable={true}
                      // onClose={() => this.setState({ backendErrors.error: false })}
                      onClose={() =>
                        this.setState(
                          produce((draft) => {
                            draft.backendErrors.error = false;
                          })
                        )
                      }
                    >
                      <span>{this.state.backendErrors.errorMsg}</span>
                    </Notification>
                  )}
                </Fade>
              </NotificationGroup>
            )}
            {this.state.archiveDeleteResponse && (
              <NotificationGroup
                style={{
                  alignItems: "flex-start",
                  flexWrap: "wrap-reverse",
                  left: "50%",
                  zIndex: 10000000,
                  top: "20px",
                  transform: "translateX(-50%)",
                }}
              >
                <Fade enter={true} exit={true}>
                  {this.state.archiveDeleteResponse && (
                    <Notification
                      type={{ style: "warning", icon: true }}
                      closable={true}
                      // onClose={() => this.setState({ backendErrors.error: false })}
                      onClose={() =>
                        this.setState(
                          produce((draft) => {
                            draft.archiveDeleteResponse = null;
                          })
                        )
                      }
                    >
                      <span>{this.state.archiveDeleteResponse}</span>
                    </Notification>
                  )}
                </Fade>
              </NotificationGroup>
            )}
          </div>
        </Layout>
      </Fragment>
    );
  }
}

export const mapStateToProps = createStructuredSelector({
  isNewCampaignDataSent: makeSelectIsNewCampaignDataSent(),
  isAllCampaignsDataLoaded: makeSelectIsAllCampaignsLoaded(),
  isCampaignDeleted: makeSelectIsDeleteCampaignDataSent(),
  isCampaignArchived: makeSelectIsArchiveCampaignDataSent(),
  accessToken: makeSelectToken(),
  domainsAndWorkspacesListsReceived: makeSelectDomainsDataReceived(),
  allCampaignsDataLoaded: makeSelectAllCampaignsDataLoaded(),
  isCampaignPatched: makeSelectIsPatchedCampaignDataSent(),
  searchTagsListReceived: makeSelectSearchTagsListReceived(),
  isSearchTagsListReceived: makeSelectIsSearchTagsListReceived(),
  trafficSourcesDataReceived: makeSelectTrafficSourcesDataLoaded(),
  allLandersDataLoaded: makeSelectAllLandersDataLoaded(),
  allOffersDataLoaded: makeSelectOffersDataLoaded(),
  flowsDataReceived: makeSelectLoadFlowsData(),

  brandDataReceived: makeSelectBrandData(),
  browserDataReceived: makeSelectBrowserData(),
  cityDataReceived: makeSelectCityData(),
  conditionCountryDataReceived: makeSelectConditionCountryData(),
  languageDataReceived: makeSelectLanguageData(),
  regionDataReceived: makeSelectRegionData(),
  connectionTypeDataReceived: makeSelectConnectionTypeData(),
  deviceTypeDataReceived: makeSelectDeviceData(),
  osDataReceived: makeSelectOperatingSystemData(),
  proxyDataReceived: makeSelectProxyData(),
  ispDataReceived: makeSelectISPData(),
  weekdayDataReceived: makeSelectWeekDayData(),
  isChartsDataLoaded: makeSelectIsChartsDataLoaded(),
  chartsAllDataLoaded: makeSelectAllChartsDataLoaded(),
  isMovedToWorkspace: makeSelectMoveToWorkspace(),
  Error: makeSelectError(),
  archiveDeleteResponse: makeSelectArchiveDeleteResponse(),
});
export function mapDispatchToProps(dispatch) {
  return {
    dispatchLoadDomainsAndWorkspaces: (...payload) =>
      dispatch(loadCustomDomains(...payload)),
    dispatchPostCampaign: (...payload) => dispatch(postCampaign(...payload)),
    dispatchLoadAllCampaigns: (...payload) =>
      dispatch(loadAllCampaigns(...payload)),
    dispatchDeleteCampaign: (...payload) =>
      dispatch(deleteCampaign(...payload)),
    dispatchArchiveCampaign: (...payload) =>
      dispatch(archiveCampaign(...payload)),
    editCampaignDialogueClosed: () => dispatch(editCampaignDialogueClosed()),
    dispatchLoadSearchTags: (...payload) =>
      dispatch(loadSearchTags(...payload)),

    dispatchLoadTrafficSources: (...payload) =>
      dispatch(loadTrafficSources(...payload)),
    dispatchLoadAllLanders: (...payload) =>
      dispatch(loadAllLanders(...payload)),
    dispatchLoadAllOffers: (...payload) => dispatch(loadOffers(...payload)),
    dispatchLoadFlows: (...payload) =>
      dispatch(newFlowsActions.loadFlows(...payload)),

    fetchBrandData: (...payload) =>
      dispatch(newFlowsActions.loadBrand(...payload)),
    fetchCityData: (...payload) =>
      dispatch(newFlowsActions.loadCity(...payload)),
    fetchCountryData: (...payload) =>
      dispatch(newFlowsActions.loadConditionCountries(...payload)),
    fetchBrowserData: (...payload) =>
      dispatch(newFlowsActions.loadBrowser(...payload)),
    fetchDeviceTypeData: (...payload) =>
      dispatch(newFlowsActions.loadDeviceType(...payload)),
    fetchConnectionTypeData: (...payload) =>
      dispatch(newFlowsActions.loadConnectionType(...payload)),
    fetchLanguageData: (...payload) =>
      dispatch(newFlowsActions.loadLanguage(...payload)),
    fetchProxyData: (...payload) =>
      dispatch(newFlowsActions.loadProxy(...payload)),
    fetchRegionData: (...payload) =>
      dispatch(newFlowsActions.loadRegion(...payload)),
    fetchOperatingSystemData: (...payload) =>
      dispatch(newFlowsActions.loadOperatingSystem(...payload)),
    fetchISPData: (...payload) => dispatch(newFlowsActions.loadISP(...payload)),
    fetchWeekDayData: (...payload) =>
      dispatch(newFlowsActions.loadWeekDay(...payload)),

    dispatchLoadChartsData: (...payload) =>
      dispatch(loadChartsData(...payload)),
    dispatchMoveToWorkspace: (...payload) =>
      dispatch(moveToWorkspace(...payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Campaigns);
function ResponsiveMenuCharts(props) {
  const [dimensions, setDimensions] = React.useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  })
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    
}

    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)
    
}
  })
  return <>{//<div>{window.innerWidth}</div>
  }
  <MenuCharts data={props.data} dates={props.dates}/>
  </>

}