import React from "react";

import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet"
import "./pricing.css"
import { useEffect, useState } from 'react';
import { classNames } from "@progress/kendo-react-common";

//const scripts =["../js/features/headroom.min.js","../js/features/jquery.min.js","../js/features/slick.min.js","../js/features/scripts.js"]




export default function Features(){
    function activateCard(x) {
        document.getElementById(`features-card${x}`).classList.add("active-box")    
    }
    function desactivateCard(x) {
        document.getElementById(`features-card${x}`).classList.remove("active-box")
     }
     function toggleActivateCard(x) {
        document.getElementById(`features-card${x}`).classList.toggle("active-box")
     }

    return(

            <section className="pricing-section" id="pricing">        
                 

                <div className="features-page" >
    <div className="site-container">
        <header className="headroom">
            

        </header>


        <div className="site-content" >

            <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>    
                <div className="features-text">
                <h1>Pricing</h1>
                <p>Discover the perfect plan for your media buying needs with our flexible and scalable pricing options. <span style={{color:"#e0358b"}}><br/>Try any plan free for 14 days.</span> No credit card required.</p>
                </div>
            </div> 

            <div className="container-1 home-container">




        <div className="section-content features" >
            <span className="bg features" data-0="transform: translateY(0px)" data-100000="transform: translateY(-20000px)"></span>
            <ul className="row-1 feature-list">
                <li className="col-md-4 col-sm-6" id="features-card"  >
                    <div className="item">
                        <div className="content">
                            {
                            //<div className="icon ai"></div>
                            }
                            <div className="plans__title-3">Basic</div>
                            <div class="plans__price-box"><b class="plans__price-text">$50<span>/mo</span></b></div>
                            <div className="desc">
                                <p>This plan is best suited for small businesses or individuals who are just starting out with their media buying efforts and have a limited budget.</p>
                        
                                <ul>
                                    <li><p>Up to 500,000 clicks /mo with $0.07 / 1000 visit over charge</p></li>
                                    <li><p>6 months of data retention</p></li>
                                    <li><p>Up to 20 active campaigns</p></li>
                                    <li><p>Single user only</p></li>
                                    <li><p>Up to 3 workspaces</p></li>
                                    <li><p>Up to 3 custom domain</p></li>
                                    <li><p>Email Support</p></li>
                                </ul>
                                <div style={{display:"flex",justifyContent:"center",marginTop:"15px"}}>
                                <Link class="sign-up-btn k-button signinbutton " id="sign-up-btn" to="/signup" style={{textAlign:"center"}}>Sign Up</Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </li>
                <li className="col-md-4 col-sm-6" id="features-card1" >
                    <div className="item">
                        <div className="content">
                            {
                            //<div className="icon bot"></div>
                            }
                            <div className="plans__title-3">Standard</div>
                            <div class="plans__price-box"><b class="plans__price-text">$100<span>/mo</span></b></div>
                            <div className="desc">
                                <p>This plan is ideal for businesses that have a larger budget and want to take their media buying to the next level.</p>
                               
                                <ul>
                                    <li><p>Up to 1,000,000 clicks /mo with $0.06 / 1000 visit over charge</p></li>
                                    <li><p>12 months of data retention</p></li>
                                    <li><p>Up to 60 active campaigns</p></li>
                                    <li><p>Can add up to 2 additional users</p></li>
                                    <li><p>Up to 5 custom domains</p></li>
                                    <li><p>Up to 5 workspaces</p></li>
                                    <li><p>Account manager support</p></li>
                                </ul>
                                
                                <div style={{display:"flex",justifyContent:"center",marginTop:"15px"}}>
                                <Link class="sign-up-btn k-button signinbutton " id="sign-up-btn" to="/signup" style={{textAlign:"center"}}>Sign Up</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="col-md-4 col-sm-6" id="features-card2" >
                    <div className="item">
                        <div className="content">
                            {
                            //<div className="icon redirects"></div>
                            }
                            <div className="plans__title-3">Premium</div>
                            <div class="plans__price-box"><b class="plans__price-text">$200<span>/mo</span></b></div>
                            <div className="desc last">
                                <p>This plan is targeted towards larger businesses or enterprises that require a more personalized approach to their media buying efforts.</p>
                                
                                <ul>
                                    <li><p>Up to 5,000,000 clicks /mo with $0.05 / 1000 visit over charge</p></li>
                                    <li><p>18 months of data retention</p></li>
                                    <li><p>Up to 100 active campaigns</p></li>
                                    <li><p>Can add up to 5 additional users</p></li>
                                    <li><p>Up to 10 custom domains</p></li>
                                    <li><p>Up to 10 workspaces</p></li>
                                    <li><p>Dedicated Account manager</p></li>
                                </ul>
                                <div style={{display:"flex",justifyContent:"center",marginTop:"15px"}}>
                                <Link class="sign-up-btn k-button signinbutton " id="sign-up-btn" to="/signup" style={{textAlign:"center"}}>Sign Up</Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>






            </div>
            
            <div className="container-1 home-container-desk">
            <div className="card-wrapper">
                <div className="card-details">
                    <div className="plans__title-3">Basic</div>
                    <p>This plan is best suited for small businesses or individuals who are just starting out with their media buying efforts and have a limited budget.</p>
                    <div class="plans__price-box"><b class="plans__price-text">$50<span>/mo</span></b></div>
                    
                
                </div>
                
                <div className="reveal-details">
                                <ul>
                                    <li><p>Up to 500,000 clicks /mo with $0.07 / 1000 visit over charge</p></li>
                                    <li><p>6 months of data retention</p></li>
                                    <li><p>Up to 20 active campaigns</p></li>
                                    <li><p>Single user only</p></li>
                                    <li><p>Up to 3 workspaces</p></li>
                                    <li><p>Up to 3 custom domain</p></li>
                                    <li><p>Email Support</p></li>
                                </ul><
                    div style={{display:"flex",justifyContent:"center",paddingBottom:"20px"}}>
                        <Link class="sign-up-btn k-button signinbutton " id="sign-up-btn" to="/signup" style={{textAlign:"center"}}>Sign Up</Link>
                    </div>
                </div>
            </div>
  
  

            <div className="card-wrapper">
                <div className="card-details">
                    <div className="plans__title-3">Standard</div>
                    <p>This plan is ideal for businesses that have a larger budget and want to take their media buying to the next level.</p>
                    <div class="plans__price-box"><b class="plans__price-text">$100<span>/mo</span></b></div>
                    
                    

                </div>
                
                <div className="reveal-details">
                                <ul>
                                    <li><p>Up to 1,000,000 clicks /mo with $0.06 / 1000 visit over charge</p></li>
                                    <li><p>12 months of data retention</p></li>
                                    <li><p>Up to 60 active campaigns</p></li>
                                    <li><p>Can add up to 2 additional users</p></li>
                                    <li><p>Up to 5 custom domains</p></li>
                                    <li><p>Up to 5 workspaces</p></li>
                                    <li><p>Account manager support</p></li>
                                </ul>
                    <div style={{display:"flex",justifyContent:"center",paddingBottom:"20px"}}>
                        <Link class="sign-up-btn k-button signinbutton " id="sign-up-btn" to="/signup" style={{textAlign:"center"}}>Sign Up</Link>
                    </div>
                </div>
             </div>

            <div className="card-wrapper">
                <div className="card-details">
                    <div className="plans__title-3">Premium</div>
                    <p>This plan is targeted towards larger businesses or enterprises that require a more personalized approach to their media buying efforts.</p>
                    <div class="plans__price-box"><b class="plans__price-text">$200<span>/mo</span></b></div>
 
                </div>
                <div className="reveal-details">
                                <ul>
                                    <li><p>Up to 5,000,000 clicks /mo with $0.05 / 1000 visit over charge</p></li>
                                    <li><p>18 months of data retention</p></li>
                                    <li><p>Up to 100 active campaigns</p></li>
                                    <li><p>Can add up to 5 additional users</p></li>
                                    <li><p>Up to 10 custom domains</p></li>
                                    <li><p>Up to 10 workspaces</p></li>
                                    <li><p>Dedicated Account manager</p></li>
                                </ul>                   
                    <div style={{display:"flex",justifyContent:"center",paddingBottom:"20px"}}>
                        <Link class="sign-up-btn k-button signinbutton " id="sign-up-btn" to="/signup" style={{textAlign:"center"}}>Sign Up</Link>
                    </div>
                </div>
            </div>
            </div>

            <div className="pricing-custom"><p style={{textAlign:"center",fontSize:"16px",color:"white",marginTop:"40px"}}>Looking for a more customized solution? Feel free to <a href="mailto:contact@tellmobi.com" style={{color:"#e0358b",textDecoration:"underline",fontWeight:"700"}}>contact us</a> to discuss personalized plans that fit your business needs.</p></div>
  
            

        
        
        </div>






    </div>

</div>




                
                


    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"/>


            </section>
            )
}