import {
    GET_SUB_LEVEL_DATA, GET_SUB_LEVEL_DATA_SUCCESS, GET_SUB_LEVEL_DATA_FAILED,
    GET_COLUMNS_DATA, GET_COLUMNS_DATA_SUCCESS, GET_COLUMNS_DATA_FAILED,
    PATCH_COLUMNS_DATA, PATCH_COLUMNS_DATA_SUCCESS, PATCH_COLUMNS_DATA_FAILED,
    GET_CURRENCY_DATA, GET_CURRENCY_DATA_SUCCESS, GET_CURRENCY_DATA_FAILED
} from "./constants";

export const getSubLevelData = (payload) => {
    return {
        type: GET_SUB_LEVEL_DATA,
        payload: payload
    };
};

export const getSubLevelDataSuccess = (payload) => {
    return {
        type: GET_SUB_LEVEL_DATA_SUCCESS,
        payload: payload
    };
};

export const getSubLevelDataFailed = (error) => {
    return {
        type: GET_SUB_LEVEL_DATA_FAILED,
        payload: error
    };
};


// Get the columns data from the backend
export const getColumnsData = (payload) => {
    return {
        type: GET_COLUMNS_DATA,
        payload: payload
    };
};

export const getColumnsDataSuccess = (payload) => {
    return {
        type: GET_COLUMNS_DATA_SUCCESS,
        payload: payload
    };
};

export const getColumnsDataFailed = (error) => {
    return {
        type: GET_COLUMNS_DATA_FAILED,
        payload: error
    };
};

// Patch the columns data from the backend
export const patchColumnsData = (payload) => {
    return {
        type: PATCH_COLUMNS_DATA,
        payload: payload
    };
};

export const patchColumnsDataSuccess = (payload) => {
    return {
        type: PATCH_COLUMNS_DATA_SUCCESS,
        payload: payload
    };
};

export const patchColumnsDataFailed = (error) => {
    return {
        type: PATCH_COLUMNS_DATA_FAILED,
        payload: error
    };
};

// Get currency data from the backend
// export const patchColumnsData = (payload) => {
//     return {
//         type: PATCH_COLUMNS_DATA,
//         payload: payload
//     };
// };
//
// export const patchColumnsDataSuccess = (payload) => {
//     return {
//         type: PATCH_COLUMNS_DATA_SUCCESS,
//         payload: payload
//     };
// };
//
// export const patchColumnsDataFailed = (error) => {
//     return {
//         type: PATCH_COLUMNS_DATA_FAILED,
//         payload: error
//     };
// };