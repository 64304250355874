import produce from "immer";
import {
    GET_SUB_LEVEL_DATA, GET_SUB_LEVEL_DATA_SUCCESS, GET_SUB_LEVEL_DATA_FAILED,
    GET_COLUMNS_DATA, GET_COLUMNS_DATA_SUCCESS, GET_COLUMNS_DATA_FAILED,
    PATCH_COLUMNS_DATA, PATCH_COLUMNS_DATA_SUCCESS, PATCH_COLUMNS_DATA_FAILED
} from "./constants";

export const initialGridSubLevelState = {
    loading: false,
    success: false,
    error: false,
    subLevelData: null,
    columnsData: null,
    isColumnsDataPatched: false
}

const gridReducer = (state= initialGridSubLevelState, action) => produce(state, (draft => {
    switch (action.type) {
        case GET_SUB_LEVEL_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.subLevelData = null;
            return draft;
        case GET_SUB_LEVEL_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.subLevelData = action.payload;
            return draft;
        case GET_SUB_LEVEL_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload;
            draft.subLevelData = null;
            return draft;

            // Get the columns data
        case GET_COLUMNS_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.columnsData = null;
            return draft;
        case GET_COLUMNS_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.columnsData = action.payload;
            return draft;
        case GET_COLUMNS_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload;
            draft.columnsData = null;
            return draft;

        // Patch the columns data
        case PATCH_COLUMNS_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.isColumnsDataPatched = false;
            return draft;
        case PATCH_COLUMNS_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.isColumnsDataPatched = true;
            return draft;
        case PATCH_COLUMNS_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload;
            draft.isColumnsDataPatched = false;
            return draft;
        default:
            return draft;
    }
}));

export default gridReducer;