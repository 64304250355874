import { call, put, takeLatest } from "redux-saga/effects";
import {
    LOAD_SUB_LEVEL_DATA,
    LOAD_ALL_REPORT_DATA,
    GET_CHARTS_DATA
} from "./constants";
import {
    loadSubLevelDataSuccess,
    loadSubLevelDataFailed,
    loadChartsDataSuccess,
    loadChartsDataFailed,
    loadAllReportDataSuccess,
    loadAllReportDataFailed
} from "./actions";

import {
    LoadAllReportFromAPI,
    LoadSubLevelReportDataFromAPI,
    LoadReportChartsDataFromAPI

} from "./api";

function* loadAllReportData(payload) {
    try {
        const response = yield call(LoadAllReportFromAPI, payload);
        yield put(
            loadAllReportDataSuccess(response.data)
        );
    } catch (error) {
        yield put(loadAllReportDataFailed(error));
    }
}

function* loadChartsData(payload) {
    try {
        const response = yield call(LoadReportChartsDataFromAPI, payload);
        yield put(
            loadChartsDataSuccess(response.data)
        );
    } catch (error) {
        yield put(loadChartsDataFailed(error));
    }
}

function* loadSubLevelReportData(payload) {
    try {
        const response = yield call(LoadSubLevelReportDataFromAPI, payload);
        yield put(
            loadSubLevelDataSuccess(response.data)
        );
    } catch (error) {
        yield put(loadSubLevelDataFailed(error));
    }
}

export default function* ReportSaga() {
    yield takeLatest(LOAD_ALL_REPORT_DATA, loadAllReportData);
    yield takeLatest(LOAD_SUB_LEVEL_DATA, loadSubLevelReportData);
    yield takeLatest(GET_CHARTS_DATA, loadChartsData);
}