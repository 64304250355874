import { call, put, takeLatest } from "redux-saga/effects";

import {
    LOAD_COUNTRIES, ADD_FLOWS,
    EDIT_FLOWS, DELETE_FLOWS, LOAD_FLOWS, UPDATE_FLOWS, DELETE_DEFAULT_PATH, DELETE_RULE, ARCHIVE_FLOWS,GET_FLOW_CHARTS_DATA,
    
    LOAD_BRAND, LOAD_BROWSER, LOAD_OPERATING_SYSTEM, LOAD_REGION, LOAD_PROXY,
    LOAD_CITY, LOAD_DEVICE_TYPE, LOAD_CONNECTION_TYPE, LOAD_LANGUAGE, LOAD_ISP, LOAD_CONDITION_COUNTRIES,
    LOAD_MOBILE_CARRIER,

    ADD_BRAND, ADD_BROWSER, ADD_OPERATING_SYSTEM, ADD_REGION, ADD_PROXY,
    ADD_CITY, ADD_DEVICE_TYPE, ADD_CONNECTION_TYPE, ADD_LANGUAGE, LOAD_WEEK_DAY, LOAD_ALL_CITY, LOAD_ALL_ISP,
} from './constants';

import {
    loadCountriesFailed, loadCountriesSuccess, addFlowsFailed, addFlowsSuccess, loadFlowsSuccess, loadFlowsFailed,
    editFlowsSuccess, editFlowsFailed, deleteFlowsSuccess, deleteFlowsFailed, updateFlowsSuccess, updateFlowsFailed,
    deleteDefaultPathSuccess, deleteDefaultPathFailed, deleteRuleSuccess, deleteRuleFailed, archiveFlowsSuccess,
    archiveFlowsFailed,loadFlowChartsDataSuccess,loadFlowChartsDataFailed, loadMobileCarrierSuccess, loadMobileCarrierFailed,
    
    loadBrandSuccess, loadBrandFailed, loadBrowserSuccess, loadBrowserFailed, loadCitySuccess, loadCityFailed, 
    loadConnectionTypeFailed, loadConnectionTypeSuccess, loadDeviceTypeFailed, loadDeviceTypeSuccess, 
    loadLanguageSuccess, loadLanguageFailed, loadOperatingSystemFailed, loadOperatingSystemSuccess,
    loadRegionFailed, loadRegionSuccess, loadProxyFailed, loadProxySuccess,
    loadISPSuccess, loadISPFailed, loadConditionCountriesSuccess, loadConditionCountriesFailed, loadAllCitySuccess, loadAllCityFailed,
    loadAllISPSuccess, loadAllISPFailed, loadWeekDaySuccess, loadWeekDayFailed,

    addBrandSuccess, addBrandFailed, addBrowserSuccess, addBrowserFailed, addCitySuccess, addCityFailed, 
    addConnectionTypeFailed, addConnectionTypeSuccess, addDeviceTypeFailed, addDeviceTypeSuccess, 
    addLanguageSuccess, addLanguageFailed, addOperatingSystemFailed, addOperatingSystemSuccess,
    addRegionFailed, addRegionSuccess, addProxyFailed, addProxySuccess,

} from './actions';
import {
    loadCountriesApi, newAddFlowsApi, loadFlowsApi, deleteFlowsApi, editFlowsApi, updateFlowsApi, deleteDefaultPathApi,
    addRegionApi, getRegionApi, addBrowserApi, addBrandApi, addConnectionTypeApi,
    addDeviceTypeApi, addLanguageApi, addOperatingSystemApi, addProxyApi, addCityApi,
    getCityApi, getProxyApi, getOperatingSystemApi, getBrowserApi, getBrandApi, getConnectionTypeApi,
    getDeviceTypeApi, getLanguageApi, getISPApi, getCountryApi, getAllCityApi, getAllISPApi, getWeekDayApi,
    deleteRuleApi, archiveFlowsApi,LoadFlowChartsDataFromAPI, getMCApi
} from './newFlowsApi';


function* loadCountries(payload) {
    try {
        const response = yield call(loadCountriesApi, payload.payload);
        yield put( loadCountriesSuccess({countries:response.data}))
    } catch (error) {
        yield put(loadCountriesFailed(error));
    }
}
function* addNewFlows(payload) {
    try {
        const response = yield call(newAddFlowsApi, payload.payload);
        yield put( addFlowsSuccess({flowsData:response}))
    } catch (error) {
        yield put(addFlowsFailed(error));
    }
}

function* loadFlows(payload) {
    try {
        const response = yield call(loadFlowsApi, payload.payload);
        yield put( loadFlowsSuccess({flowsData:response.data}))
    } catch (error) {
        yield put(loadFlowsFailed(error));
    }
}

function* editFlows(payload) {
    try {
        const response = yield call(editFlowsApi, payload.payload);
        yield put( editFlowsSuccess({data:response.data}))
    } catch (error) {
        yield put( editFlowsFailed(error));
    }
}

function* updateFlows(payload) {
    try {
        const response = yield call(updateFlowsApi, payload.payload);
        yield put( updateFlowsSuccess({data:response.data}))
    } catch (error) {
        yield put( updateFlowsFailed(error));
    }
}

function* deleteFlows(payload) {
    try {
        const response = yield call(deleteFlowsApi, payload.payload);
        yield put( deleteFlowsSuccess({deleteData:response}))
    } catch (error) {
        yield put( deleteFlowsFailed(error));
    }
}

function* archiveFlows(payload) {
    try {
        const response = yield call(archiveFlowsApi, payload.payload);
        yield put( archiveFlowsSuccess({archiveData:response}))
    } catch (error) {
        yield put( archiveFlowsFailed(error));
    }
}

function* deleteDefaultPath(payload) {
    //console.log('data coming in payload from delete flow action', payload.payload);
    try {
        const response = yield call(deleteDefaultPathApi, payload.payload);
        yield put( deleteDefaultPathSuccess({data:response}))
    } catch (error) {
        yield put( deleteDefaultPathFailed(error));
    }
}

function* deleteRule(payload) {
    //console.log('data coming in payload from delete flow action', payload.payload);
    try {
        const response = yield call(deleteRuleApi, payload.payload);
        yield put( deleteRuleSuccess({data:response}))
    } catch (error) {
        yield put( deleteRuleFailed(error));
    }
}

function* loadFlowChartsData(payload) {
    try {
        const response = yield call(LoadFlowChartsDataFromAPI, payload);
        yield put(
            loadFlowChartsDataSuccess(response.data)
        );
    } catch (error) {
        yield put(loadFlowChartsDataFailed(error));
    }
}

// --------------------------------------------------------------------------------------------------------
//=========================================================================================================
//condition saga
function* addBrowser(payload){
    try {
        const response = yield call(addBrowserApi, payload.payload);
        yield put( addBrowserSuccess({data: response}))
    } catch(error) {
        yield put( addBrowserFailed(error))
    }
}

function* getBrowser(payload){
    try {
        const response = yield call(getBrowserApi, payload.payload);
        yield put(loadBrowserSuccess({data: response}))
    } catch(error) {
        yield put(loadBrowserFailed(error))
    }
}

function* addBrand(payload){
    try {
        const response = yield call(addBrandApi, payload.payload);
        yield put(addBrandSuccess({data: response}))
    } catch(error) {
        yield put(addBrandFailed(error))
    }
}

function* getBrand(payload){
    try {
        const response = yield call(getBrandApi, payload.payload);
        yield put(loadBrandSuccess({data: response}))
    } catch(error) {
        yield put(loadBrandFailed(error))
    }
}

function* addConnectionType(payload){
    try {
        const response = yield call(addConnectionTypeApi, payload.payload);
        yield put(addConnectionTypeSuccess({data: response}))
    } catch(error) {
        yield put(addConnectionTypeFailed(error))
    }
}

function* getConnectionType(payload){
    try {
        const response = yield call(getConnectionTypeApi, payload.payload);
        yield put(loadConnectionTypeSuccess({data: response}))
    } catch(error) {
        yield put(loadConnectionTypeFailed(error))
    }
}

function* addDeviceType(payload){
    try {
        const response = yield call(addDeviceTypeApi, payload.payload);
        yield put(addDeviceTypeSuccess({data: response}))
    } catch(error) {
        yield put(addDeviceTypeFailed(error))
    }
}

function* getDeviceType(payload){
    try {
        const response = yield call(getDeviceTypeApi, payload.payload);
        yield put(loadDeviceTypeSuccess({data: response}))
    } catch(error) {
        yield put(loadDeviceTypeFailed(error))
    }
}

function* addLanguage(payload){
    try {
        const response = yield call(addLanguageApi, payload.payload);
        yield put(addLanguageSuccess({data: response}))
    } catch(error) {
        yield put(addLanguageFailed(error))
    }
}

function* getLanguage(payload){
    try {
        const response = yield call(getLanguageApi, payload.payload);
        //console.log('response of language', response);
        yield put(loadLanguageSuccess({data: response}))
    } catch(error) {
        yield put(loadLanguageFailed(error))
    }
}

function* addOperatingSystem(payload){
    try {
        const response = yield call(addOperatingSystemApi,payload.payload);
        yield put(addOperatingSystemSuccess({data: response}))
    } catch(error) {
        yield put(addOperatingSystemFailed(error))
    }
}

function* getOperatingSystem(payload){
    try {
        const response = yield call(getOperatingSystemApi, payload.payload);
        yield put(loadOperatingSystemSuccess({data: response}))
    } catch(error) {
        yield put(loadOperatingSystemFailed(error))
    }
}

function* addProxy(payload){
    try {
        const response = yield call(addProxyApi, payload.payload);
        yield put(addProxySuccess({data: response}))
    } catch(error) {
        yield put(addProxyFailed(error))
    }
}

function* getProxy(payload){
    try {
        const response = yield call(getProxyApi,payload.payload);
        yield put(loadProxySuccess({data: response}))
    } catch(error) {
        yield put(loadProxyFailed(error))
    }
}

function* addRegion(payload){
    try {
        const response = yield call(addRegionApi, payload.payload);
        yield put(addRegionSuccess({data: response}))
    } catch(error) {
        yield put(addRegionFailed(error))
    }
}

function* getRegion(payload){
    try {
        const response = yield call(getRegionApi, payload.payload);
        yield put(loadRegionSuccess({ data: response }))
    } catch(error) {
        yield put(loadRegionFailed(error))
    }
}

function* addCity(payload){
    try {
        const response = yield call(addCityApi, payload.payload);
        yield put(addCitySuccess({data: response}))
    } catch(error) {
        yield put(addCityFailed(error))
    }
}

function* getCity(payload){
    try {
        const response = yield call(getCityApi, payload.payload);
        yield put(loadCitySuccess({data: response}))
    } catch(error) {
        yield put(loadCityFailed(error))
    }
}

function* getMC(payload){
    try {
        const response = yield call(getMCApi, payload.payload);
        yield put(loadMobileCarrierSuccess({data: response}))
    } catch(error) {
        yield put(loadMobileCarrierFailed(error))
    }
}

function* getCountry(payload){
    try {
        const response = yield call(getCountryApi, payload.payload);
        yield put(loadConditionCountriesSuccess({data: response}))
    } catch(error) {
        yield put(loadConditionCountriesFailed(error))
    }
}

function* getISP(payload){
    try {
        const response = yield call(getISPApi, payload.payload);
        yield put(loadISPSuccess({data: response}))
    } catch(error) {
        yield put(loadISPFailed(error))
    }
}

function* getAllISP(payload){
    try {
        const response = yield call(getAllISPApi, payload.payload);
        yield put(loadAllISPSuccess({data: response}))
    } catch(error) {
        yield put(loadAllISPFailed(error))
    }
}

function* getAllCity(payload){
    try {
        const response = yield call(getAllCityApi, payload.payload);
        yield put(loadAllCitySuccess({data: response}))
    } catch(error) {
        yield put(loadAllCityFailed(error))
    }
}

function* getWeekDay(payload){
    try {
        const response = yield call(getWeekDayApi, payload.payload);
        yield put(loadWeekDaySuccess({data: response}))
    } catch(error) {
        yield put(loadWeekDayFailed(error))
    }
}


export default function* NewFlowsSaga() {
    yield takeLatest(LOAD_COUNTRIES, loadCountries);
    yield takeLatest(ADD_FLOWS, addNewFlows);
    yield takeLatest(LOAD_FLOWS, loadFlows);
    yield takeLatest(EDIT_FLOWS, editFlows);
    yield takeLatest(DELETE_FLOWS, deleteFlows);
    yield takeLatest(ARCHIVE_FLOWS, archiveFlows);
    yield takeLatest(UPDATE_FLOWS, updateFlows);
    yield takeLatest(DELETE_DEFAULT_PATH, deleteDefaultPath);
    yield takeLatest(DELETE_RULE, deleteRule);
    yield takeLatest(GET_FLOW_CHARTS_DATA, loadFlowChartsData);
    // yield takeLatest(DELETE_RULE_PATH, deleteRulePath);

    yield takeLatest(LOAD_BRAND, getBrand);
    yield takeLatest(LOAD_BROWSER, getBrowser);
    yield takeLatest(LOAD_CONNECTION_TYPE, getConnectionType);
    yield takeLatest(LOAD_LANGUAGE, getLanguage);
    yield takeLatest(LOAD_OPERATING_SYSTEM, getOperatingSystem);
    yield takeLatest(LOAD_PROXY, getProxy);
    yield takeLatest(LOAD_CITY, getCity);
    yield takeLatest(LOAD_MOBILE_CARRIER, getMC);
    yield takeLatest(LOAD_DEVICE_TYPE, getDeviceType);
    yield takeLatest(LOAD_REGION, getRegion);
    yield takeLatest(LOAD_ISP, getISP);
    yield takeLatest(LOAD_CONDITION_COUNTRIES, getCountry);
    yield takeLatest(LOAD_WEEK_DAY, getWeekDay);
    yield takeLatest(LOAD_ALL_CITY, getAllCity);
    yield takeLatest(LOAD_ALL_ISP, getAllISP);
    
    yield takeLatest(ADD_BRAND, addBrand);
    yield takeLatest(ADD_BROWSER, addBrowser);
    yield takeLatest(ADD_CONNECTION_TYPE, addConnectionType);
    yield takeLatest(ADD_LANGUAGE, addLanguage);
    yield takeLatest(ADD_OPERATING_SYSTEM, addOperatingSystem);
    yield takeLatest(ADD_PROXY, addProxy);
    yield takeLatest(ADD_CITY, addCity);
    yield takeLatest(ADD_DEVICE_TYPE, addDeviceType);
    yield takeLatest(ADD_REGION, addRegion);
    
  }
  