import React from "react";
import { Button } from '@progress/kendo-react-buttons';
import { Popup } from '@progress/kendo-react-popup';
import produce from "immer";
import {Link} from "react-router-dom";

class PageMenuBarBottom extends React.Component {
    anchor = null;
    workspacesAnchor = null;
    constructor(props) {
        super(props);
        this.state = {
            showActionButtons: false,
            showWorkspaces: false,
            showButtons: true,
            count: 0,
            menuBackDrop: false
        };
    }

    componentDidMount() {
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let search = JSON.parse(localStorage.getItem("search"));
        let mainValue = search.groupingValues.main;
        if (mainValue !== "Campaign" && this.state.count === 0) {
            this.setState(produce(draft => {
                draft.showButtons = false;
                draft.count = 1;
            }));
        }
        if (mainValue === "Campaign" && this.state.count !== 0) {
            this.setState(produce(draft => {
                draft.showButtons = true;
                draft.count = 0;
            }));
        }
    }

    togglePopupsMethod = (popup) => {
        this.setState(produce(draft => { 
            draft[popup] = !this.state[popup];
            draft.menuBackDrop = !this.state.menuBackDrop;
        }));
    }
    closePopups = () => {
        this.setState(produce(draft => { 
            draft.showActionButtons = false;
            draft.showWorkspaces = false;
            draft.menuBackDrop = false;
        }));
    }
    closePopup = (popup,data) => {
        this.setState(produce( draft => { 
            draft[popup] = !this.state[popup];
            draft.menuBackDrop = false;
        }));
        if (popup === "showWorkspaces") {
            this.props.moveToWorkspaceMethod(data);
        }
        else {
            this.props.toggleDeleteArchiveModal(data);
        }
    }
    render () {
        return (
            <div className="page_menu_bar_bottom">
                { this.state.menuBackDrop ? <div id="menuBackDrop" onClick={this.closePopups}></div> : null }
                <div className="left">
                    <button
                        className="k-button normal_text normal_font page_bottom_menu_button k-ml-1 k-mr-1"
                        disabled={this.props.selectedListReceivedFromGrid.length > 1 || this.props.selectedListReceivedFromGrid.length === 0 || this.props.disable || this.props.reportCount > 2}
                    >
                        <span onClick={() => this.props.openNewReport ("plus")}>
                            <span className="k-icon k-i-document-manager"></span>
                            &nbsp;
                            Report
                        </span>
                    </button>
                    {
                        this.state.showButtons ?
                            <>
                            <button
                                className="k-button normal_text normal_font page_bottom_menu_button k-ml-1 k-mr-1"
                                disabled={this.props.selectedListReceivedFromGrid.length > 1 || this.props.selectedListReceivedFromGrid.length === 0}
                                onClick={() => this.props.toggleCampaignDialogue("Edit_Toggle_Campaign")}
                            >

                                <span className="k-icon k-i-pencil"></span>
                                &nbsp;
                                Edit
                            </button>
                            <button
                                className="k-button normal_text normal_font page_bottom_menu_button k-ml-1 k-mr-1"
                                disabled={this.props.selectedListReceivedFromGrid.length > 1 || this.props.selectedListReceivedFromGrid.length === 0}
                                onClick={() => this.props.toggleCampaignDialogue("Edit_Toggle_Campaign_Duplicate")}
                            >

                                <span className="k-icon k-i-copy"></span>
                                &nbsp;
                                Duplicate
                            </button>
                                <button
                                    className="k-button normal_text normal_font page_bottom_menu_button k-ml-1 k-mr-1"
                                    disabled={this.props.selectedListReceivedFromGrid.length > 1 || this.props.selectedListReceivedFromGrid.length === 0 || this.props.selectedListReceivedFromGrid[0].is_active === false}
                                    onClick={() => this.props.toggleCampaignDialogue("UpdateCost_Toggle_Campaign")}
                                >

                                    <span className="k-icon k-i-pencil"></span>
                                    &nbsp;
                                    Update Cost
                                </button>
                        <div className="action_buttons_title k-ml-1 k-mr-1">
                        <button
                            className="k-button normal_text normal_font"
                            onClick={() => this.togglePopupsMethod("showActionButtons")}
                                ref={(button) => {
                                this.anchor = button;
                            }}
                            disabled={this.props.selectedListReceivedFromGrid.length === 0}
                        >
                            Actions
                            &nbsp;
                            <span className="k-icon k-i-arrow-chevron-down"></span>
                        </button>
                        <Popup
                            anchor={this.anchor}
                            show={this.state.showActionButtons}
                            popupClass={'popup-content action_buttons_popup'}
                        >
                        <div className="popup_link">
                        <button
                        className="k-button link normal_text normal_font"
                        disabled={this.props.selectedListReceivedFromGrid.length === 0}
                        onClick={() => this.closePopup("showActionButtons","Archive")}
                        >
                        <span className="k-icon k-i-delete"></span>
                        &nbsp;
                        Archive
                        </button>
                        <button
                        className="k-button link normal_text normal_font"
                        disabled={this.props.selectedListReceivedFromGrid.length === 0}
                        onClick={() => this.closePopup("showActionButtons","Delete")}
                        >
                        <span className="k-icon k-i-delete"></span>
                        &nbsp;
                        Delete
                        </button>
                        <button
                        className="k-button link normal_text normal_font"
                        disabled={this.props.selectedListReceivedFromGrid.length === 0}
                        onClick={() => this.closePopup("showActionButtons","Restore")}
                        >
                        <span className="k-icon k-i-reset"></span>
                        &nbsp;
                        Restore
                        </button>
                        </div>
                        </Popup>
                        </div>
                        <div className="action_buttons_title k-ml-1 k-mr-1">
                        <button
                        className="k-button normal_text normal_font"
                        onClick={() => this.togglePopupsMethod("showWorkspaces")}
                        ref={(item) => {
                        this.workspacesAnchor = item;
                    }}
                        disabled={this.props.selectedListReceivedFromGrid.length === 0}
                        >
                        Move to
                        &nbsp;
                        <span className="k-icon k-i-arrow-chevron-down"></span>
                        </button>
                            <Popup
                            anchor={this.workspacesAnchor}
                            show={this.state.showWorkspaces}
                            popupClass={'popup-content action_buttons_popup'}
                            >
                                <div className="popup_link">
                                {
                                    (this.props.loadedWorkspaces !== null && this.props.loadedWorkspaces.length > 0) ?
                                        this.props.loadedWorkspaces.map(workspace => (
                                            <span
                                                key={workspace.id}
                                                onClick={() => this.closePopup("showWorkspaces",workspace.id)}
                                                className="k-button link normal_text normal_font"
                                                disabled={this.props.selectedListReceivedFromGrid.length === 0}
                                            >
                                                    {workspace.name}
                                                </span>
                                        ))
                                        : null
                                }
                                </div>
                            </Popup>
                        </div>
                            </>
                        : null
                    }
                </div>
                <div className="right">
                    <Button
                        onClick={() => this.props.toggleCampaignDialogue("Toggle_Campaign_Simple")}
                        className="add_button normal_text"
                        icon="k-i-icon k-i-plus-circle"
                    >New Campaign Simple</Button>
                    <Button
                        onClick={() => this.props.toggleCampaignDialogue("Toggle_Campaign_Advanced")}
                        className="add_button normal_text"
                        icon="k-i-icon k-i-plus-circle"
                    >New Campaign Advanced</Button>
                    <button
                        className="k-button normal_text normal_font page_bottom_menu_button k-ml-1 k-mr-1"
                        onClick={this.props.toggleColumnsModal}
                    >

                        <span className="k-icon k-i-columns"></span>
                        &nbsp;
                        Columns
                    </button>
                    <div className="show_data_number_dropdown normal_text">
                        <select
                            name="dataStatus"
                            id="dataStatus"
                            onChange={this.props.changeFilterStatusType}
                            value={this.props.filterStatus}
                        >
                            <option value="all">All</option>
                            <option value="traffic">With Traffic</option>
                            <option value="archived">Archived</option>
                            <option value="active">Active</option>
                        </select>
                    </div>
                    <div className="show_data_number_dropdown normal_text">
                        <select
                            name="showDataRows"
                            id="showDataRows"
                            onChange={this.props.changeGridRowsNumber}
                        >
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                        </select>
                    </div>
                    <div className="pagination inline_block normal_text">
                        <span
                            className="first"
                            style={this.props.pagination.blur.blurFirst ? {
                                filter: "grayscale(0.1)",
                                cursor: "not-allowed",
                                opacity: "0.3",
                                pointerEvents: "none"
                            }: null }
                            onClick={() => this.props.changePagination("first")}
                        >First</span>
                        <span
                            className="k-icon k-i-arrow-chevron-left"
                            style={this.props.pagination.blur.blurPrev ? {
                                filter: "grayscale(0.1)",
                                cursor: "not-allowed",
                                opacity: "0.3",
                                pointerEvents: "none"
                            }: null }
                            onClick={() => this.props.changePagination("prev")}
                        ></span>
                        <span className="number">{this.props.pagination.page}</span>
                        <span
                            className="k-icon k-i-arrow-chevron-right"
                            style={this.props.pagination.blur.blurNext ? {
                                filter: "grayscale(0.1)",
                                cursor: "not-allowed",
                                opacity: "0.3",
                                pointerEvents: "none"
                            }: null }
                            onClick={() => this.props.changePagination("next")}
                        ></span>
                        <span
                            className="last"
                            style={this.props.pagination.blur.blurLast ? {
                                filter: "grayscale(0.1)",
                                cursor: "not-allowed",
                                opacity: "0.3",
                                pointerEvents: "none"
                            }: null }
                            onClick={() => this.props.changePagination("last")}
                        >Last</span>
                    </div>
                </div>
            </div>
        );
    }
};

export default PageMenuBarBottom;