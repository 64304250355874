// LoginRegister Saga file
import {
  call,
  put,
  takeLatest,
  // select
} from "redux-saga/effects";

import { LOGIN_USER, LOGOUT_USER, REGISTER_USER,FORGOT_PASSWORD } from "./constants";

import {
  loginUserSuccess,
  loginUserFailed,
  signUpUserSuccess,
  signUpUserFailed,
  forgetPasswordSucess,
  forgetPasswordFailed,
} from "./actions";
import { loginUserService, registerUserService,forgetUserService } from "./authentication";


function* loginUser(payload) {
  try {
    const response = yield call(loginUserService, payload);    
    yield put(
      loginUserSuccess(response.data)
    );
  } catch (error) {
    yield put(loginUserFailed(error));
  }
}

function* signUpUser(payload) {
  try {
    const response = yield call(registerUserService, payload);
    yield put(
        signUpUserSuccess({ signupSuccessMessage: "Registration successful."  })
    );
  } catch (error) {
    yield put(signUpUserFailed(error));
  }
}

function* forgetPass(payload) {
  try {
    const response = yield call(forgetUserService, payload);
    yield put(
      forgetPasswordSucess({ useremail: response.config.data})
    );
  } catch (error) {
    yield put(forgetPasswordFailed(error));
  }
}

function* logoutUser() {
  try {
    localStorage.clear();
  }
  catch (error) {
  }

}

export default function* LoginRegisterUserContainerSaga() {
  yield takeLatest(LOGIN_USER, loginUser);
  yield takeLatest(REGISTER_USER, signUpUser);
  yield takeLatest(FORGOT_PASSWORD, forgetPass)
  yield takeLatest(LOGOUT_USER, logoutUser);
}
