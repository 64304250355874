import {
    LOGIN_USER,LOGIN_USER_FAILED,LOGIN_USER_SUCCESS,
    REGISTER_USER,REGISTER_USER_SUCCESS,REGISTER_USER_ERROR,
    FORGOT_PASSWORD, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_ERROR,
    LOGOUT_USER,RESET_USER
} from './constants';


export const loginUser = (email, password) => {
    return{
        type: LOGIN_USER,
        payload: { email, password },
    }
};

export const loginUserSuccess = (user) => {
    return {
        type: LOGIN_USER_SUCCESS,
        payload: { user},
    }
};

export const loginUserFailed = error => {
    //console.log('login failed', error);
    return {
        type: LOGIN_USER_FAILED,
        payload: error,
    }
};

export const signUpUser = (payload) => {
    return{
        type: REGISTER_USER,
        payload: payload,
    }
};

export const signUpUserSuccess = (payload) => {
    //console.log('sign up success',user);
    return {
        type: REGISTER_USER_SUCCESS,
        payload: payload,
    }
};

export const signUpUserFailed = error => {
    // console.log('signup failed', error);
    return {
        type: REGISTER_USER_ERROR,
        payload: error,
    }
};

export const forgetPass=(email)=> {
    // console.log('forget pass', email);
    return {
        type: FORGOT_PASSWORD,
        payload: email
    }
}

export const forgetPasswordSucess = (useremail) => {
    // console.log('forget password success', useremail);
    return {
        type: FORGOT_PASSWORD_SUCCESS,
        payload: useremail
    }
}

export const forgetPasswordFailed = (error) => {
    // console.log('forget password failed', error);
    return {
        type: FORGOT_PASSWORD_ERROR,
        payload: error,
    }
}


export const logoutUser = () => {
// console.log('logout');
    return {
        type: LOGOUT_USER,
        // payload: {},
    }
};
export const resetUser = () => {
    return {
        type: RESET_USER,
    }
};