import * as requestHandler from "../../helpers/requestHandlers";
import {
	PATH_WORKSPACE,
	PATH_WORKSPACE_MOVE_TO
} from "../../helpers/path";

export const addWorkspace = async (request) => {
	const response = await requestHandler.post(PATH_WORKSPACE, {name: request.workspaceName});
    return response;
};

export const loadWorkspace = async (request) => {
	const response = await requestHandler.get(PATH_WORKSPACE);
	return response;
};

export const editWorkspace = async (request) => {
	const response = await requestHandler.patch(`${PATH_WORKSPACE}/${request.id}`,{name: request.name});
	return response;
}

export const deleteWorkspace = async (request) => {
	const response = await requestHandler.delet(`${PATH_WORKSPACE}/${request.id}`);
	return response;
}