import {
    LOAD_NOTIFICATIONS, LOAD_NOTIFICATIONS_SUCCESS, LOAD_NOTIFICATIONS_FAILED, UPDATE_NOTIFICATIONS,
    UPDATE_NOTIFICATIONS_SUCCESS, UPDATE_NOTIFICATIONS_FAILED
} from './constants';


export const loadNotifications = (accessToken) => {
    return {
        type: LOAD_NOTIFICATIONS,
        payload:{accessToken}
    }
}

export const loadNotificationsSuccess = (notificationData) => {
    return {
        type: LOAD_NOTIFICATIONS_SUCCESS,
        payload:{notificationData}
    }
}

export const loadNotificationsFailed = (error) => {
    return {
        type: LOAD_NOTIFICATIONS_FAILED,
        payload:{error}
    }
}
export const updateNotifications = (accessToken,updateNotificatationData) => {
    return {
        type: UPDATE_NOTIFICATIONS,
        payload:{accessToken,updateNotificatationData}
    }
}
export const updateNotificationsSuccess = (notificatationData) => {
    return {
        type: UPDATE_NOTIFICATIONS_SUCCESS,
        payload:{notificatationData}
    }
}
export const updateNotificationsFailed = (error) => {
    return {
        type: UPDATE_NOTIFICATIONS_FAILED,
        payload:{error}
    }
}
