import { call, put, takeLatest } from "redux-saga/effects";
import {
    LOAD_CURRENCY,
    POST_TRAFFIC_SOURCE_DATA,
    LOAD_TRAFFIC_SOURCES_DATA,
    LOAD_EDIT_TRAFFIC_SOURCE_DATA,
    SEND_EDIT_TRAFFIC_SOURCE_DATA,
    DELETE_TRAFFIC_SOURCE_DATA,
    LOAD_TOKEN_TAGS,
    LOAD_CUSTOM_TRAFFIC_SOURCE,
    LOAD_CUSTOM_TRAFFIC_SOURCE_BY_ID,
    ARCHIVE_TRAFFIC_SOURCE_DATA,
    GET_TS_CHARTS_DATA
} from "./constants";
import {
    loadCurrencySuccess,
    loadCurrencyFailed,
    postTrafficSourceSuccess,
    postTrafficSourceFailed,
    loadTrafficSourcesSuccess,
    loadTrafficSourcesFailed,
    loadEditTrafficSourceSuccess,
    loadEditTrafficSourceFailed,
    sendEditTrafficSourceSuccess,
    sendEditTrafficSourceFailed,
    deleteTrafficSourceSuccess,
    deleteTrafficSourceFailed,
    loadTokensDictionarySuccess,
    loadTokensDictionaryFailed,
    archiveTrafficSourceSuccess,
    archiveTrafficSourceFailed,
    
    loadAllCustomTrafficSourceDataSuccess,
    loadAllCustomTrafficSourceDataFailed,
    loadTrafficSourceDataByIdSuccess,
    loadTrafficSourceDataByIdFailed,
    loadTSChartsDataSuccess,
    loadTSChartsDataFailed

} from "./actions";

import {
    LoadCurrenciesListFromAPI,
    postNewTrafficSourceFromAPI,
    loadTrafficSourcesFromAPI,
    loadEditTrafficSourceFromAPI,
    sendEditTrafficSourceFromAPI,
    deleteTrafficSourceFromAPI,
    archiveTrafficSourceFromAPI,
    loadTokensDictionaryFromAPI,
    loadAllCustomTrafficSourceDataFromAPI,
    loadCustomTrafficSourceDataByIdFromAPI,
    LoadTSChartsDataFromAPI
} from "./api";
import { loadCustomAffiliateNetworkFailed } from "../AffiliateNetworks/actions";

function* loadCurrenciesList(payload) {
    try {
        const response = yield call(LoadCurrenciesListFromAPI, payload);
        yield put(
            loadCurrencySuccess(response.data)
        );
    } catch (error) {
        yield put(loadCurrencyFailed(error));
    }
}

function* postNewTrafficSourceData(payload) {
    try {
        const response = yield call(postNewTrafficSourceFromAPI, payload);
        yield put(
            postTrafficSourceSuccess(response.data)
        );
    } catch (error) {
        yield put(postTrafficSourceFailed(error));
    }
}

function* loadTrafficSourcesData(payload) {
    try {
        const response = yield call(loadTrafficSourcesFromAPI, payload);
        yield put(
            loadTrafficSourcesSuccess(response.data)
        );
    } catch (error) {
        yield put(loadTrafficSourcesFailed(error));
    }
}

function* loadEditTrafficSourceData(payload) {
    try {
        const response = yield call(loadEditTrafficSourceFromAPI, payload);
        yield put(
            loadEditTrafficSourceSuccess(response.data)
        );
    } catch (error) {
        yield put(loadEditTrafficSourceFailed(error));
    }
}

function* sendEditTrafficSourceData(payload) {
    try {
        const response = yield call(sendEditTrafficSourceFromAPI, payload);
        yield put(
            sendEditTrafficSourceSuccess(response.data)
        );
    } catch (error) {
        yield put(sendEditTrafficSourceFailed(error));
    }
}

function* deleteTrafficSourceData(payload) {
    try {
        const response = yield call(deleteTrafficSourceFromAPI, payload);
        yield put(
            deleteTrafficSourceSuccess(response.data)
        );
    } catch (error) {
        yield put(deleteTrafficSourceFailed(error));
    }
}

function* archiveTrafficSourceData(payload) {
    try {
        const response = yield call(archiveTrafficSourceFromAPI, payload);
        yield put(
            archiveTrafficSourceSuccess(response.data)
        );
    } catch (error) {
        yield put(archiveTrafficSourceFailed(error));
    }
}

function* loadTokenTagsData(payload) {
    try {
        const response = yield call(loadTokensDictionaryFromAPI, payload);
        yield put(
            loadTokensDictionarySuccess(response.data)
        );
    } catch (error) {
        yield put(loadTokensDictionaryFailed(error));
    }
}

function* loadAllCustomTrafficSourceData(payload) {
    try {
        const response = yield call(loadAllCustomTrafficSourceDataFromAPI, payload);
        yield put(
            loadAllCustomTrafficSourceDataSuccess(response.data)
        );
    }
    catch (error) {
        yield put(loadAllCustomTrafficSourceDataFailed(error));
    }
}

function* loadCustomTrafficSourceDataById(payload) {
    try {
        const response = yield call(loadCustomTrafficSourceDataByIdFromAPI, payload);
        yield put(
            loadTrafficSourceDataByIdSuccess(response.data)
        );
    }
    catch (error) {
        yield put(loadTrafficSourceDataByIdFailed(error));
    }
}

function* loadTSChartsData(payload) {
    try {
        const response = yield call(LoadTSChartsDataFromAPI, payload);
        yield put(
            loadTSChartsDataSuccess(response.data)
        );
    } catch (error) {
        yield put(loadTSChartsDataFailed(error));
    }
}


export default function* trafficSourcesSaga() {
    yield takeLatest(LOAD_CURRENCY, loadCurrenciesList);
    yield takeLatest(POST_TRAFFIC_SOURCE_DATA, postNewTrafficSourceData);
    yield takeLatest(LOAD_TRAFFIC_SOURCES_DATA, loadTrafficSourcesData);
    yield takeLatest(LOAD_EDIT_TRAFFIC_SOURCE_DATA, loadEditTrafficSourceData);
    yield takeLatest(SEND_EDIT_TRAFFIC_SOURCE_DATA, sendEditTrafficSourceData);
    yield takeLatest(DELETE_TRAFFIC_SOURCE_DATA, deleteTrafficSourceData);
    yield takeLatest(ARCHIVE_TRAFFIC_SOURCE_DATA, archiveTrafficSourceData);
    yield takeLatest(LOAD_TOKEN_TAGS, loadTokenTagsData);
    yield takeLatest(LOAD_CUSTOM_TRAFFIC_SOURCE, loadAllCustomTrafficSourceData);
    yield takeLatest(LOAD_CUSTOM_TRAFFIC_SOURCE_BY_ID, loadCustomTrafficSourceDataById);
    yield takeLatest(GET_TS_CHARTS_DATA, loadTSChartsData);
}