const checkNames = (value) => {
    let list = [
        { name: 'Campaign', value: 'campaign'},
        { name: 'Offers', value: 'offers'},
        { name: 'Lander', value: 'lander'},
        { name: 'Affiliate Network', value: 'affilateNetwork'},
        { name: 'Traffic Source', value: 'trafficSource'},
        { name: 'Flows', value: 'flow'},
        { name: 'Browser', value: 'browser'},
        { name: 'Browser Version', value: 'browserVersions'},
        { name: 'Brand', value: 'brand'},
        { name: 'Models', value: 'models'},
        { name: 'Device Type', value: 'deviceType'},
        { name: 'Language', value: 'language'},
        { name: 'OS', value: 'os'},
        { name: 'OS Version', value: 'osVersions'},
        { name: 'Proxy', value: 'proxy'},
        { name: 'Region', value: 'region'},
        { name: 'City', value: 'city'},
        { name: 'ISP', value: 'isp'},
        { name: 'Day of Week', value: 'weekday'},
        { name: 'Timezone', value: 'timezone'},
        { name: 'Connection Type', value: 'connectionType'},
        { name: 'Mobile Carrier', value: 'mobileCarrier'},
        { name: 'Day', value: 'days'},
        { name: 'Hour Of day', value: 'hours'},
        { name: 'Path', value: 'path'},
        { name: 'Country', value: 'country'},
        { name: 'Conversion', value: 'conversion'},
        { name: 'Month', value: 'months'},
        { name: 'IP', value:'ip'},
        { name: 'Referrer', value: 'referrer'},
        { name: 'Referrer Domain', value: 'referrerDomain'},
        { name: 'var1', value: 'var1'},
        { name: 'var2', value: 'var2'},
        { name: 'var3', value: 'var3'},
        { name: 'var4', value: 'var4'},
        { name: "var5", value: "var5"},
        { name: "var6", value: "var6"},
        { name: "var7", value: "var7"},
        { name: "var8", value: "var8"},
        { name: "var9", value: "var9"},
        { name: "var10", value: "var10"},
        { name: "var11", value: "var11"},
        { name: "var12", value: "var12"},
        { name: "var13", value: "var13"},
        { name: "var14", value: "var14"},
        { name: "var15", value: "var15"},
        { name: "var16", value: "var16"},
        { name: "var17", value: "var17"},
        { name: "var18", value: "var18"},
        { name: "var19", value: "var19"},
        { name: "var20", value: "var20"},
    ];
    for (let j=0;j<list.length;j++) {
        if (value === list[j].name) {
            return list[j].value;
        }
    }
}

export const checkFilters = (filter2,filter3,filter4) => {
    let filter2Value = "", filter3Value = "", filter4Value = "";
    if (filter2 !== "" || filter2.length > 0) { filter2Value = checkNames(filter2); }
    if (filter3 !== "" || filter3.length > 0) { filter3Value = checkNames(filter3); }
    if (filter4 !== "" || filter4.length > 0) { filter4Value = checkNames(filter4); }

    let newList = [filter2Value, filter3Value, filter4Value];
    return newList;
}