import React from "react";
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Link } from "react-router-dom";

const TooltipsCon = (props) => {
    return (
        <div className="tooltip_con">
            <Tooltip openDelay={100} position="bottom" anchorElement="target" style={{ maxWidth: "250px" }}>
                <div style={{ textAlign: 'left' }}>
                    <span title="As public elements can be used in Private Workspaces, all the stats are available in the Workspace view and at least one Workspace must be chosen.">?</span>
                </div>
            </Tooltip>
        </div>
    );
};

export default TooltipsCon;