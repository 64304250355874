import * as React from "react";
import { Checkbox, Switch } from "@progress/kendo-react-inputs";
import { Input } from "@progress/kendo-react-inputs";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectBrandData, makeSelectBrowserData, makeSelectCityData, makeSelectConnectionTypeData, makeSelectDeviceData, makeSelectLanguageData, makeSelectOperatingSystemData, makeSelectProxyData, makeSelectRegionData, makeSelectISPData } from "../../selectors";
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { makeSelectToken } from "../../../../../registration/LoginRegister/selectors";
import * as newFlowsActions from '../../actions';
import { Error } from '@progress/kendo-react-labels';

import { RadioButton } from "@progress/kendo-react-inputs";

class ModalForm extends React.Component {

    state = {
        selectedValue: "mustbe",
    };

    handleChange = (e) => {
        this.setState({ selectedValue: e.value });
    };
    render() {
        //console.log('this.props ka sara data', this.props.state);
        return (

            <div className="conditions-select">
                <section >
                    <div className="flow-workspace" style={{ width: "100%" }}>
                        <div style={{ width: "30%" }}>
                            <select
                                className="select-workspace"
                                name="conditions"
                                id="conditions"
                                placeholder="Select options"
                                onChange={this.props.onChangeSelect}
                            >
                                {/* {this.props.propsState.options.map(options => (
                                <option value={options.value}>{ options.name}</option>
                            ))} */}
                                {this.props.propsState.filteredOptions[this.props.propsState.newRuleIndex].map(options => (
                                    <option value={options.value}>{options.name}</option>
                                ))}
                            </select>
                        </div>
                        <span className="k-icon k-i-arrows-kpi arrow-span-icon" />


                        {this.props.state.select !== "" && this.props.state.select !== "Custom variable 1" && this.props.state.select !== "Custom variable 2" && this.props.state.select !== "Custom variable 3" && this.props.state.select !== "Custom variable 4" && this.props.state.select !== "Custom variable 5" && this.props.state.select !== "Custom variable 6" && this.props.state.select !== "Custom variable 7" && this.props.state.select !== "Custom variable 8" && this.props.state.select !== "Custom variable 9" && this.props.state.select !== "Custom variable 10" && this.props.state.select !== "Custom variable 11" && this.props.state.select !== "Custom variable 12" && this.props.state.select !== "Custom variable 13" && this.props.state.select !== "Custom variable 14" && this.props.state.select !== "Custom variable 15" && this.props.state.select !== "Custom variable 16" && this.props.state.select !== "Custom variable 17" && this.props.state.select !== "Custom variable 18" && this.props.state.select !== "Custom variable 19" && this.props.state.select !== "Custom variable 20" ? (
                            <div style={{ width: "50%" }}>
                                <Switch
                                    className="conditions-switch"
                                    id="conditions-switch"
                                    onLabel={"Is"}
                                    offLabel={"Is not"}
                                    checked={this.props.state.predicate}
                                    onChange={this.props.onPredicateSwitchChange}
                                />
                            </div>
                        ) : this.props.state.select !== "" &&

                        <div className="conditions-radio-btns">
                            <RadioButton
                                name="group1"
                                value="MUST_BE"
                                checked={this.props.state.iRSelectedValue === "MUST_BE"}
                                label="Must be"
                                onChange={this.props.handleChange}
                            />

                            <RadioButton
                                name="group1"
                                value="MUST_NOT_BE"
                                checked={this.props.state.iRSelectedValue === "MUST_NOT_BE"}
                                label="Must not be"
                                onChange={this.props.handleChange}
                            />

                            <RadioButton
                                name="group1"
                                value="CONTAIN"
                                checked={this.props.state.iRSelectedValue === "CONTAIN"}
                                label="Contains"
                                onChange={this.props.handleChange}
                            />
                            <RadioButton
                                name="group1"
                                value="NOT_CONTAIN"
                                checked={this.props.state.iRSelectedValue === "NOT_CONTAIN"}
                                label="Does not contain"
                                onChange={this.props.handleChange}
                            />
                        </div>}
                    </div>

                </section>
                {this.props.state.select === "brand" || this.props.state.select === "browser" || this.props.state.select === "operating" ? (
                    <>
                        <div className="conditions-search">
                            <section className="parent-items" >
                                <Input className="input" placeholder="Search..." onChange={this.props.onChange}></Input>
                                <span className="k-icon k-i-search"></span>
                                <div style={{ overflowY: "scroll" }}>
                                    {

                                        this.props.state.select === "brand" ?
                                            this.props.brandDataReceived.filter(item => {
                                                if (!this.props.state.value) return true;
                                                if (item.name.toLowerCase().includes(this.props.state.value)) {
                                                    return true;
                                                }
                                                return false;
                                            }).map((item) => (

                                                <div>
                                                    <Checkbox name={item.name} id={item.id} className="condition-checkbox" label="" ariaDescribedBy={"tags"} defaultChecked={false} checked={this.props.state.models.includes(item.name) ? true : false} onChange={this.props.onClickBox}>
                                                        {item.name}
                                                    </Checkbox>
                                                </div>
                                            ))
                                            : this.props.state.select === "browser" ?
                                                this.props.browserDataReceived.filter(item => {
                                                    if (!this.props.state.value) return true;
                                                    if (item.name.toLowerCase().includes(this.props.state.value)) {
                                                        return true;
                                                    }
                                                    return false;
                                                }).map(item => (
                                                    <div>
                                                        <Checkbox name={item.name} id={item.id} ariaDescribedBy={"tags"} className="condition-checkbox" label="" defaultChecked={false} checked={this.props.state.models.includes(item.name) ? true : false} onChange={this.props.onClickBox}>
                                                            {item.name}
                                                        </Checkbox>
                                                    </div>
                                                ))
                                                : this.props.state.select === "operating" ?
                                                    this.props.osDataReceived.filter(item => {
                                                        if (!this.props.state.value) return true;
                                                        if (item.name.toLowerCase().includes(this.props.state.value)) {
                                                            return true;
                                                        }
                                                        return false;
                                                    }).map(item => (
                                                        <div>
                                                            <Checkbox name={item.name} id={item.id} ariaDescribedBy={"tags"} className="condition-checkbox" label="" defaultChecked={false} checked={this.props.state.models.includes(item.name) ? true : false} onChange={this.props.onClickBox}>
                                                                {item.name}
                                                            </Checkbox>
                                                        </div>
                                                    ))
                                                    : null
                                    }
                                </div>
                            </section>
                            <section className="parent-items">
                                <Input className="input" placeholder="Search..." onChange={this.props.onModelChange}></Input>
                                <span className="k-icon k-i-search"></span>
                                <div style={{ overflowY: "scroll" }}>

                                    {
                                        this.props.state.select === "brand" ?
                                            this.props.state.modelsData.filter(item => {
                                                if (!this.props.state.modelValue) return true;
                                                if (item.toLowerCase().includes(this.props.state.modelValue)) {
                                                    return true;
                                                }
                                                return false;
                                            }).map(item => (
                                                <div>
                                                    <Checkbox name={item} ariaDescribedBy={"tags"} className="condition-checkbox" label="" defaultChecked={false} checked={this.props.state.tagsData.includes(item) ? true : false} onChange={this.props.onClickBoxTagsList}>
                                                        {item}
                                                    </Checkbox>

                                                </div>
                                            ))
                                            : this.props.state.select === "browser" || this.props.state.select === "operating" ?
                                                this.props.state.modelsData.filter(item => {
                                                    if (!this.props.state.modelValue) return true;
                                                    if (item.toLowerCase().includes(this.props.state.modelValue)) {
                                                        return true;
                                                    }
                                                    return false;
                                                }).map(item => (
                                                    <div>
                                                        <Checkbox name={item} ariaDescribedBy={"tags"} className="condition-checkbox" label="" defaultChecked={false} checked={this.props.state.tagsData.includes(item) ? true : false} onChange={this.props.onClickBoxTagsList}>
                                                            {item}
                                                        </Checkbox>
                                                    </div>
                                                ))
                                                : null
                                    }
                                </div>
                            </section>
                            <section className="parent-items tag-list">
                                {this.props.state.tagsData.length === 0 ? <>
                                    <span className="k-icon k-i-track-changes-accept-all"></span>
                                    <div>Select at least one item</div> </> :
                                    <MultiSelect
                                        style={{ height: "100%" }}
                                        className="conditions-multiselect"
                                        id="conditions-multiselect"
                                        onChange={this.props.tagsChange}
                                        value={this.props.state.tagsData}
                                        ariaDescribedBy={"tags"}
                                    />
                                }
                            </section>
                        </div>
                        <div>

                            {
                                this.props.state.errors.tagsError !== "" && <Error id={"tags"}>{this.props.state.errors.tagsError}</Error>
                            }
                        </div> </>
                ) : this.props.state.select !== "" && this.props.state.select !== "Custom variable 1" && this.props.state.select !== "Custom variable 2" && this.props.state.select !== "Custom variable 3" && this.props.state.select !== "Custom variable 4" && this.props.state.select !== "Custom variable 5" && this.props.state.select !== "Custom variable 6" && this.props.state.select !== "Custom variable 7" && this.props.state.select !== "Custom variable 8" && this.props.state.select !== "Custom variable 9" && this.props.state.select !== "Custom variable 10" && this.props.state.select !== "Custom variable 11" && this.props.state.select !== "Custom variable 12" && this.props.state.select !== "Custom variable 13" && this.props.state.select !== "Custom variable 14" && this.props.state.select !== "Custom variable 15" && this.props.state.select !== "Custom variable 16" && this.props.state.select !== "Custom variable 17" && this.props.state.select !== "Custom variable 18" && this.props.state.select !== "Custom variable 19" && this.props.state.select !== "Custom variable 20" && this.props.state.select !== "IP" && this.props.state.select !== "timezone" && this.props.state.select !== "Referrer" && this.props.state.select !== "Referrer domain" ? (
                    <div className="search-tag-list">
                        <section>
                            <MultiSelect
                                className="conditions-multiselect"
                                id="conditions-multiselect"
                                data={this.props.state.data}
                                textField={this.props.state.select === "country" ? "country_name" : "name"}
                                filterable={true}
                                dataItemKey="id"
                                onFilterChange={this.props.filterChange}
                                onChange={this.props.onCityChange}
                                value={this.props.state.cityValue}
                                placeholder={"Search " + this.props.state.select}
                                ariaDescribedBy={"multiselect"}
                            />
                        </section>
                        {
                            this.props.state.errors.multiSelectError !== "" && <Error id={'multiselect'}>{this.props.state.errors.multiSelectError}</Error>
                        }
                    </div>
                ) : this.props.state.select === "IP" ?
                    <>
                        <textarea
                            onChange={this.props.onIpAdressChange}
                            className="filter-textarea"
                            aria-describedby="ipaddress"
                            placeholder="Type ip range using comma(,) seperated. for eg: 0.0.0.0,1.1.1.1"
                        ></textarea>
                        {
                            this.props.state.errors.ipAddressError !== "" && <Error id={'ipaddress'}>{this.props.state.errors.ipAddressError}</Error>
                        }
                    </>
                    : this.props.state.select === "Referrer" || this.props.state.select === "Referrer domain" ?
                        <>
                            <div className="condition_referrer_checkbox">
                                <input type="checkbox" id="referrer" name="is_empty" checked={this.props.state.is_empty} onChange={this.props.inputChangeHandler}></input>
                                <label for="referrer"><span>Include empty referrer domain</span></label>
                            </div>
                            <textarea
                                onChange={this.props.inputChangeHandler}
                                className="filter-textarea"
                                aria-describedby="referrerdomain"
                                placeholder="Type domain using comma(,) seperated."
                                id="domain"
                                name="domain"
                            ></textarea>
                            {this.props.state.errors.referrerDomainError !== "" &&
                                <Error id="referrerdomain">{this.props.state.errors.referrerDomainError}</Error>
                            }
                        </>
                        : this.props.state.select === "timezone" ?
                            <>
                                <div className="condtions_timezone">
                                    Between
                                    <div className="from">
                                        {/* <input aria-describedby="from" className="timezone" placeholder="0:00" name="from" onChange={this.props.inputChangeHandler}></input> */}
                                        <select required name="fromh" id="fromh" onChange={this.props.inputChangeHandler}>
                                            <option value="" disabled selected>h</option>
                                            {this.props.propsState.from.map(options => (
                                                <option value={options}>{options}</option>
                                            ))}
                                        </select>
                                        :
                                        <select required name="fromm" id="fromm" onChange={this.props.inputChangeHandler}>
                                            <option value="" disabled selected>MM</option>
                                            {this.props.propsState.to.map(options => (
                                                <option value={options}>{options}</option>
                                            ))}
                                        </select>
                                    </div>
                                    and
                                    <div className="to">
                                        {/* <input aria-describedby="to" className="timezone" placeholder="0:00" name="to" onChange={this.props.inputChangeHandler}></input> */}
                                        <select required name="toh" id="toh" onChange={this.props.inputChangeHandler}>
                                            <option value="" disabled selected>h</option>
                                            {this.props.propsState.from.map(options => (
                                                <option value={options}>{options}</option>
                                            ))}
                                        </select>
                                        :
                                        <select required name="tom" id="tom" onChange={this.props.inputChangeHandler}>
                                            <option value="" disabled selected>MM</option>
                                            {this.props.propsState.to.map(options => (
                                                <option value={options}>{options}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="timezone_error">
                                    {this.props.state.errors.timezoneError.from !== "" &&
                                        <Error id="from">{this.props.state.errors.timezoneError.from}</Error>
                                    }

                                    {this.props.state.errors.timezoneError.to !== "" &&
                                        <Error id="to">{this.props.state.errors.timezoneError.to}</Error>
                                    }
                                </div>
                                <hr style={{ marginBottom: "2em" }}></hr>
                                <ComboBox
                                    className=""
                                    style={{ width: "100%" }}
                                    data={this.props.state.data}
                                    filterable={true}
                                    onFilterChange={this.props.filterTimezoneChange}
                                    defaultValue={this.props.state.defaultTime}
                                    textField="name"
                                    dataItemKey="id"
                                    name="timeZoneId"
                                    value={this.props.state.timeZoneIddata}
                                    onChange={this.props.inputChangeHandler}
                                    placeholder="Select timezone"
                                    ariaDescribedBy="timezone"
                                />
                                {this.props.state.errors.timezoneError.timezone !== "" &&
                                    <Error id="timezone">{this.props.state.errors.timezoneError.timezone}</Error>
                                }
                                <hr style={{ marginBottom: "2em" }}></hr>
                            </>
                            : this.props.state.select === "Custom variable 1" || this.props.state.select === "Custom variable 2" || this.props.state.select === "Custom variable 3" || this.props.state.select === "Custom variable 4" || this.props.state.select === "Custom variable 5" || this.props.state.select === "Custom variable 6" || this.props.state.select === "Custom variable 7" || this.props.state.select === "Custom variable 8" || this.props.state.select === "Custom variable 9" || this.props.state.select === "Custom variable 10" || this.props.state.select === "Custom variable 11" || this.props.state.select === "Custom variable 12" || this.props.state.select === "Custom variable 13" || this.props.state.select === "Custom variable 14" || this.props.state.select === "Custom variable 15" || this.props.state.select === "Custom variable 16" || this.props.state.select === "Custom variable 17" || this.props.state.select === "Custom variable 18" || this.props.state.select === "Custom variable 19" || this.props.state.select === "Custom variable 20" ?
                                <>
                                    <div className="condition_referrer_checkbox">
                                        <input type="checkbox" id="customVar" name="is_empty" onChange={this.props.inputChangeHandler} checked={this.props.state.is_empty}></input>
                                        <label for="customVar"><span>Include empty custom variable</span></label>
                                    </div>
                                    <textarea
                                        onChange={this.props.inputChangeHandler}
                                        className="filter-textarea"
                                        aria-describedby="var"
                                        placeholder="Type one variable using comma(,) seperated. for eg: var1,var2"
                                        id="customVar"
                                        name="customVar"
                                    ></textarea>
                                    {this.props.state.errors.customVarError !== "" &&
                                        <Error id="var">{this.props.state.errors.customVarError}</Error>
                                    }
                                </>
                                : null}
            </div>
        );
    }
}





export const mapStateToProps = createStructuredSelector({
    brandDataReceived: makeSelectBrandData(),
    browserDataReceived: makeSelectBrowserData(),
    cityDataReceived: makeSelectCityData(),
    languageDataReceived: makeSelectLanguageData(),
    regionDataReceived: makeSelectRegionData(),
    connectionTypeDataReceived: makeSelectConnectionTypeData(),
    deviceTypeDataReceived: makeSelectDeviceData(),
    osDataReceived: makeSelectOperatingSystemData(),
    proxyDataReceived: makeSelectProxyData(),
    accessToken: makeSelectToken(),
    ispDataReceived: makeSelectISPData(),

});


export function mapDispatchToProps(dispatch) {
    return {
        dispatchLoadFlows: (...payload) => dispatch(newFlowsActions.loadFlows(...payload)),
        deleteFlows: (...payload) => dispatch(newFlowsActions.deleteFlows(...payload)),
        editFlows: (...payload) => dispatch(newFlowsActions.editFlows(...payload)),
        dispatchLoadCountries: (...payload) => dispatch(newFlowsActions.loadCountries(...payload)),

        fetchBrandData: (...payload) => dispatch(newFlowsActions.loadBrand(...payload)),
        fetchCityData: (...payload) => dispatch(newFlowsActions.loadCity(...payload)),
        fetchBrowserData: (...payload) => dispatch(newFlowsActions.loadBrowser(...payload)),
        fetchDeviceTypeData: (...payload) => dispatch(newFlowsActions.loadDeviceType(...payload)),
        fetchConnectionTypeData: (...payload) => dispatch(newFlowsActions.loadConnectionType(...payload)),
        fetchLanguageData: (...payload) => dispatch(newFlowsActions.loadLanguage(...payload)),
        fetchProxyData: (...payload) => dispatch(newFlowsActions.loadProxy(...payload)),
        fetchRegionData: (...payload) => dispatch(newFlowsActions.loadRegion(...payload)),
        fetchOperatingSystemData: (...payload) => dispatch(newFlowsActions.loadOperatingSystem(...payload)),
        fetchISPData: (...payload) => dispatch(newFlowsActions.loadISP(...payload)),
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(ModalForm);
