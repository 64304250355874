import * as requestHandler from "../../helpers/requestHandlers";
import {
    PATH_LOAD_TRACKING_URLS,
    PATH_LOAD_CURRENCY_LIST,
    PATH_OFFERS,
    PATH_OFFERS_SHORT,
    PATH_OFFERS_ARCHIVED,
    PATH_OFFERS_CHART,
    PATH_LOAD_TIMEZONES_LIST,
    PATH_GROUPING
} from "../../helpers/path"; 
import { checkFilters } from "../../../components/checkNamesForGrouping/checkNames";
import { returnTimeZoneId } from "../../../components/timeZonesExchange";

export const LoadTrackingUrlsFromAPI = async (request) => {
    const response = await requestHandler.get(PATH_LOAD_TRACKING_URLS);
    return response;    
};

export const LoadCurrenciesListFromAPI = async (request) => {
    const response = await requestHandler.get(PATH_LOAD_CURRENCY_LIST);
    return response;
    
};

export const postNewOfferDataFromAPI = async (request) => {
    const response = await requestHandler.post(PATH_OFFERS, request.payload.data);
    return response;
    
};

export const loadOffersDataFromAPI = async (request) => {
    let endDate = request.payload.searchData.modifiedDate.endDate;
    let startDate = request.payload.searchData.modifiedDate.startDate;

    let timeZoneInLocalStorage = request.payload.searchData.dates.timeZone;
    let timeZoneIdToSend = returnTimeZoneId(timeZoneInLocalStorage);

    let filtersToSend = checkFilters(request.payload.searchData.groupingValues.main, request.payload.searchData.groupingValues.group1, request.payload.searchData.groupingValues.group2);

    let parameters = "?filter="+ (request.payload.primaryPage ? request.payload.searchData.inputSearchValue : "") + "&date=&from="+ startDate+"&to="+endDate  +"&tags="+request.payload.searchData.tagsSearchValue+"&main="+ filtersToSend[0] + "&group1="+ filtersToSend[1] + "&group2="+ filtersToSend[2] + "&order=" + request.payload.sorting.order + "&sort=" + request.payload.sorting.sort + "&skip=" + request.payload.searchData.pagination.skip + "&take=" + request.payload.searchData.pagination.take + "&workspace=" + request.payload.searchData.selectedWorkspacesList + "&timezone=" + timeZoneIdToSend + "&status=" + request.payload.searchData.filterStatus;
    if (request.payload.primaryPage) {
        const response = await requestHandler.get(PATH_GROUPING + parameters);
        return response;
    } else {
        if (request.payload.dataType && request.payload.dataType.length > 0) {
            const response = await requestHandler.get(PATH_OFFERS_SHORT + parameters);
            return response;
        } else {
            const response = await requestHandler.get(PATH_OFFERS + parameters);
            return response;
        }
    }

    
};

export const editOfferDataFromAPI = async (request) => {
    const response = await requestHandler.get(PATH_OFFERS + request.payload.editOfferId);
    return response;
    
};

export const sendEditOfferDataFromAPI = async (request) => {
    const data = request.payload.data;
    let url = PATH_OFFERS + request.payload.editOfferId;
    const response = await requestHandler.patch(url, data);
    return response;
    
};

export const deleteOfferDataFromAPI = async (request) => {
    let url = PATH_OFFERS + request.payload.offerId;
    const response = await requestHandler.delet(url);
    return response;
    
};

export const ArchiveOfferDataFromAPI = async (request) => {
    let url = PATH_OFFERS_ARCHIVED + request.payload.offerId;
    const response = await requestHandler.post(url,{ value: request.payload.value});
    return response;
    
};


export const loadTimezoneDataFromAPI = async (request) => {
    let url = PATH_LOAD_TIMEZONES_LIST;
    const response = await requestHandler.get(url);
    return response;
    
}

export const LoadOffersChartsDataFromAPI = async (request) => {  
    let search = JSON.parse(localStorage.getItem('search'));
    let timeZoneInLocalStorage = search.dates.timeZone;
    let timeZoneIdToSend = returnTimeZoneId(timeZoneInLocalStorage);
    let endDate = request.payload.dates.endDate;
    let startDate = request.payload.dates.startDate;
    let parameters = "to=" + endDate + "&from=" + startDate + "&workspace=" + search.selectedWorkspacesList + "&timezone=" + timeZoneIdToSend;

    let url = PATH_OFFERS_CHART + parameters;
    const response = await requestHandler.get(url);
    return response;
};

