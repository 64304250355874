import { call, put, takeLatest } from "redux-saga/effects";
import {
    ADD_CUSTOM_DOMAIN,
    LOAD_CUSTOM_DOMAINS,
    EDIT_CUSTOM_DOMAIN,
    DELETE_CUSTOM_DOMAIN,
    SEND_MAIN_DOMAIN,
    LOAD_REDIRECT_DOMAIN,
    SEND_REDIRECT_DOMAIN
} from "./constants";
import {
    addCustomDomainSuccess,
    addCustomDomainFailure,
    loadCustomDomainsSuccess,
    loadCustomDomainsFailure,
    editCustomDomainSuccess,
    editCustomDomainFailed,
    deleteCustomDomainSuccess,
    deleteCustomDomainFailed,
    sendMainDomainSuccess,
    sendMainDomainFailed,
    loadRedirectDomainSuccess,
    loadRedirectDomainFailed,
    sendRedirectDomainSuccess,
    sendRedirectDomainFailed
} from "./actions";
import {
    addDomainToAPI,
    LoadCustomDomainsFromAPI,
    EditCustomDomainFromAPI,
    DeleteCustomDomainFromAPI,
    sendMainDomainFromAPI,
    LoadRedirectDomainFromAPI,
    SendRedirectDomainFromAPI,
} from "./api";


function* addCustomDomain(payload) {
    try {
        const response = yield call(addDomainToAPI, payload);
        yield put(
            addCustomDomainSuccess(response)
        );
    } catch (error) {
        yield put(addCustomDomainFailure(error));
    }
}

function* loadCustomDomains(payload) {
    try {
        const response = yield call(LoadCustomDomainsFromAPI, payload);
        yield put(
            loadCustomDomainsSuccess(response.data)
        );
    } catch (error) {
        yield put(loadCustomDomainsFailure(error));
    }
}

function* editCustomDomain(payload) {
    try {
        const response = yield call(EditCustomDomainFromAPI, payload);
        yield put(
            editCustomDomainSuccess(response.data)
        );
    } catch (error) {
        yield put(editCustomDomainFailed(error));
    }
}

function* deleteCustomDomain(payload) {
    try {
        const response = yield call(DeleteCustomDomainFromAPI, payload);
        yield put(
            deleteCustomDomainSuccess(response.data)
        );
    } catch (error) {
        yield put(deleteCustomDomainFailed(error));
    }
}

function* sendMainDomain(payload) {
    try {
        const response = yield call(sendMainDomainFromAPI, payload);
        yield put(
            sendMainDomainSuccess(response.data)
        );
    } catch (error) {
        yield put(sendMainDomainFailed(error));
    }
}

function* sendRedirectDomain(payload) {
    try {
        const response = yield call(SendRedirectDomainFromAPI, payload);
        yield put(
            sendRedirectDomainSuccess(response.data)
        );
    } catch (error) {
        yield put(sendRedirectDomainFailed(error));
    }
}

function* loadRedirectDomain(payload) {
    try {
        const response = yield call(LoadRedirectDomainFromAPI, payload);
        yield put(
            loadRedirectDomainSuccess(response.data)
        );
    } catch (error) {
        yield put(loadRedirectDomainFailed(error));
    }
}

export default function* domainSettingsSaga() {
    yield takeLatest(ADD_CUSTOM_DOMAIN, addCustomDomain);
    yield takeLatest(LOAD_CUSTOM_DOMAINS, loadCustomDomains);
    yield takeLatest(EDIT_CUSTOM_DOMAIN, editCustomDomain);
    yield takeLatest(DELETE_CUSTOM_DOMAIN, deleteCustomDomain);
    yield takeLatest(SEND_MAIN_DOMAIN, sendMainDomain);
    yield takeLatest(SEND_REDIRECT_DOMAIN, sendRedirectDomain);
    yield takeLatest(LOAD_REDIRECT_DOMAIN, loadRedirectDomain);
}