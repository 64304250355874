import * as requestHandler from "../../helpers/requestHandlers";
import {
    PATH_LOAD_CURRENCY_LIST,
    PATH_TRAFFIC_SOURCES,
    PATH_TRAFFIC_SOURCES_SHORT,
    PATH_TRAFFIC_SOURCES_ARCHIVED,
    PATH_TRAFFIC_SOURCES_CUSTOM,
    PATH_TRAFFIC_SOURCES_CHART,
    PATH_GROUPING,
    PATH_LOAD_TOKENS_DICTIONARY
} from "../../helpers/path"; 
import { checkFilters } from "../../../components/checkNamesForGrouping/checkNames";
import { returnTimeZoneId } from "../../../components/timeZonesExchange";


export const LoadCurrenciesListFromAPI = async (request) => {
    const response = await requestHandler.get(PATH_LOAD_CURRENCY_LIST);
    return response;
};

export const postNewTrafficSourceFromAPI = async (request) => {
    const response = await requestHandler.post(PATH_TRAFFIC_SOURCES, request.payload.data);
    return response;
};

export const loadTrafficSourcesFromAPI = async (request) => {
    let endDate = request.payload.searchQuery.modifiedDate.endDate;
    let startDate = request.payload.searchQuery.modifiedDate.startDate;

    let timeZoneInLocalStorage = request.payload.searchQuery.dates.timeZone;
    let timeZoneIdToSend = returnTimeZoneId(timeZoneInLocalStorage);

    let filtersToSend = checkFilters(request.payload.searchQuery.groupingValues.main, request.payload.searchQuery.groupingValues.group1, request.payload.searchQuery.groupingValues.group2);

    let parameters = "?filter=" + (request.payload.primaryPage ? request.payload.searchQuery.inputSearchValue : "") + "&date=&from=" + startDate + "&to=" + endDate + "&tags=" + request.payload.searchQuery.tagsSearchValue + "&main=" + filtersToSend[0] + "&group1=" + filtersToSend[1] + "&group2=" + filtersToSend[2] + "&order=" + request.payload.sorting.order + "&sort=" + request.payload.sorting.sort + "&skip=" + request.payload.searchQuery.pagination.skip + "&take=" + request.payload.searchQuery.pagination.take + "&workspace=" + request.payload.searchQuery.selectedWorkspacesList + "&timezone=" + timeZoneIdToSend + "&status=" + request.payload.searchQuery.filterStatus;
    if (request.payload.primaryPage) {
        const response = await requestHandler.get(PATH_GROUPING + parameters);
        return response;
    } else {
        if (request.payload.dataType) {
            const response = await requestHandler.get((request.payload.dataType === "short" ? PATH_TRAFFIC_SOURCES_SHORT : PATH_TRAFFIC_SOURCES) + parameters);
            return response;
        } else {
            const response = await requestHandler.get(PATH_TRAFFIC_SOURCES + parameters);
            return response;
        }
    }
};

export const loadEditTrafficSourceFromAPI = async (request) => {
    const response = await requestHandler.get(PATH_TRAFFIC_SOURCES + request.payload.loadEditTrafficSourceId);
    return response;
};

export const sendEditTrafficSourceFromAPI = async (request) => {
    const data = request.payload.data;
    let url = PATH_TRAFFIC_SOURCES + request.payload.sendEditTrafficSourceId;
    const response = await requestHandler.patch(url, data);
    return response;
};

export const deleteTrafficSourceFromAPI = async (request) => {
    const response = await requestHandler.delet(PATH_TRAFFIC_SOURCES + request.payload.networkId);
    return response;
};

export const archiveTrafficSourceFromAPI = async (request) => {
    let url = PATH_TRAFFIC_SOURCES_ARCHIVED + request.payload.networkId;
    const response = await requestHandler.post(url,{ value: request.payload.value});
    return response;
};

export const loadTokensDictionaryFromAPI = async (request) => {
    const response = await requestHandler.get(PATH_LOAD_TOKENS_DICTIONARY);
    return response;
};

export const loadAllCustomTrafficSourceDataFromAPI = async (request) => {
    const response = await requestHandler.get(PATH_TRAFFIC_SOURCES_CUSTOM);
    return response;
}

export const loadCustomTrafficSourceDataByIdFromAPI = async(request) => {
    const response = await requestHandler.get(PATH_TRAFFIC_SOURCES_CUSTOM + request.payload.customTrafficSourceId);
    return response;
}

export const LoadTSChartsDataFromAPI = async (request) => {
    let search = JSON.parse(localStorage.getItem('search'));
    let timeZoneInLocalStorage = search.dates.timeZone;
    let timeZoneIdToSend = returnTimeZoneId(timeZoneInLocalStorage);

    let endDate = request.payload.dates.endDate;
    let startDate = request.payload.dates.startDate;
    let parameters = "to=" + endDate + "&from=" + startDate + "&workspace=" + search.selectedWorkspacesList + "&timezone=" + timeZoneIdToSend;
    const response = await requestHandler.get(PATH_TRAFFIC_SOURCES_CHART + parameters);
    return response;
};
