import { createSelector } from "reselect";
import { initialMoveToWorkspaceState } from "./reducer";

const selectMoveToWorkspaceState = (state) => state.moveToWorkspace || initialMoveToWorkspaceState;

const makeSelectLoading = () => createSelector(selectMoveToWorkspaceState, (subState) => subState.loading);
const makeSelectSuccess = () => createSelector(selectMoveToWorkspaceState, (subState) => subState.success);
const makeSelectError = () => createSelector(selectMoveToWorkspaceState, (subState) => subState.error);
const makeSelectMoveToWorkspace = () => createSelector(selectMoveToWorkspaceState, (subState) => subState.movedToWorkspace);

export {
    makeSelectLoading,
    makeSelectSuccess,
    makeSelectError,
    makeSelectMoveToWorkspace
}