import { createSelector } from "reselect";
import { initialConversionsState } from "./reducer";

const selectConversionsState = (state) =>  state.Conversions || initialConversionsState;

const makeSelectLoading = () => createSelector(selectConversionsState, (subState) => subState.loading);
const makeSelectSuccess = () => createSelector(selectConversionsState, (subState) => subState.success);
const makeSelectError = () => createSelector(selectConversionsState, (subState) => subState.error);
const makeSelectLoadConversionData = () => createSelector(selectConversionsState, (subState) => subState.allConversionData);


export {
    makeSelectLoading,
    makeSelectSuccess,
    makeSelectError,
    makeSelectLoadConversionData,
}