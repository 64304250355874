import produce from 'immer';
import { LOAD_NOTIFICATIONS,LOAD_NOTIFICATIONS_SUCCESS,LOAD_NOTIFICATIONS_FAILED,UPDATE_NOTIFICATIONS, UPDATE_NOTIFICATIONS_SUCCESS, UPDATE_NOTIFICATIONS_FAILED } from './constants';

export const initialState = {
    loading: false,
    success: false,
    error: false,
    notficationsData: null,
    isNotificationsDataLoaded: false,
    isNotificationsDataUpdate:false,
}

const notificationReducer = (state = initialState, action) =>
    produce(state, (draft) => { 
        switch (action.type) {
            case LOAD_NOTIFICATIONS:
                draft.loading = true;
                draft.success = false;
                draft.error = false;
                return draft;
            case LOAD_NOTIFICATIONS_SUCCESS:
                //console.log('action load payload', action.payload);
                draft.loading = false;
                draft.success = true;
                draft.error = false;
                draft.notficationsData = action.payload.notificationData.data;
                draft.isNotificationsDataLoaded = true;
                return draft
            case LOAD_NOTIFICATIONS_FAILED:
                draft.loading = false;
                draft.success = false;
                draft.error = true;
                draft.notficationsData = null;
                draft.isNotificationsDataLoaded = false;
                return draft;
                case UPDATE_NOTIFICATIONS:
                draft.loading = true;
                draft.success = false;
                draft.error = false;
                return draft;
            case UPDATE_NOTIFICATIONS_SUCCESS:
                //console.log('action update payload', action.payload.notificatationData.data);
                draft.loading = false;
                draft.success = true;
                draft.error = false;
                draft.notficationsData = action.payload.notificatationData.data;
                draft.isNotificationsDataLoaded = true;
                draft.isNotificationsDataUpdate = true;
                return draft;
            case UPDATE_NOTIFICATIONS_FAILED:
                draft.loading = false;
                draft.success = false;
                draft.error = true;
                draft.notficationsData = null;
                draft.isNotificationsDataLoaded = false;
                draft.isNotificationsDataUpdate = false;
                return draft;
            default:
                return draft;
            
        }
    })

export default notificationReducer;