import { all } from 'redux-saga/effects';
import LoginRegisterUserContainerSaga from './../../registration/LoginRegister/saga';
import domainSettingsSaga from "../../containers/Settings/Domains/saga";
import CollaborationToolsWorkspacesSaga from '../../containers/Settings/CollaborationTools/saga';
import NotificationsSaga from '../../containers/Settings/Notifications/saga';
import affiliateNetworksSaga from "../../containers/Pages/AffiliateNetworks/saga";
import trafficSourcesSaga from "../../containers/Pages/TrafficSources/saga";
import CampaignsSaga from "../../containers/Pages/Campaigns/saga";
import ConversionsSaga from "../../containers/Pages/Conversion/saga";
import offersSaga from '../../containers/Pages/Offers/saga';
import LandersSaga from "../../containers/Pages/Landers/saga";
import NewFlowsSaga from "../../containers/Pages/NewFlows/saga";
import ProfileSaga from "../../containers/Settings/Profile/saga";
import IPUAFilterSaga from '../../containers/Settings/IP-UA-Filtering/saga';
import SettingsSaga from '../../containers/Settings/GeneralSettings/saga';
import EventLogSaga from '../../containers/Settings/Event-Log/saga';
import GridSaga from "../../containers/grid/saga";
import MoveToWorkspaceSaga from "../../moveToWorkspaceRedux/saga";
import ReportSaga from "../../containers/Report/reportPage/saga";
import UsersSaga from '../../containers/Settings/Users/saga';
import DashboardSaga from '../../containers/Pages/Dashboard/saga';

export default function* rootSaga(getState) {
    yield all(
        [
            LoginRegisterUserContainerSaga(),
            CollaborationToolsWorkspacesSaga(),
            domainSettingsSaga(),
            affiliateNetworksSaga(),
            trafficSourcesSaga(),
            NewFlowsSaga(),
            offersSaga(),
            LandersSaga(),
            ProfileSaga(),
            NotificationsSaga(),
            IPUAFilterSaga(),
            SettingsSaga(),
            CampaignsSaga(),
            ConversionsSaga(),
            EventLogSaga(),
            GridSaga(),
            MoveToWorkspaceSaga(),
            ReportSaga(),
            UsersSaga(),
            DashboardSaga()
        ]
    );
}