import { call, put, takeLatest } from "redux-saga/effects";
import {
    LOAD_COUNTRY,
    POST_LANDER_DATA,
    LOAD_ALL_LANDERS_DATA,
    LOAD_EDIT_LANDER_DATA,
    SEND_EDIT_LANDER_DATA,
    DELETE_LANDER_DATA,
    LOAD_TOKEN_TAGS,
    ARCHIVE_LANDER_DATA,
    GET_LANDER_CHARTS_DATA
} from "./constants";
import {
    loadCountrySuccess,
    loadCountryFailed,
    postLanderSuccess,
    postLanderFailed,
    loadAllLandersSuccess,
    loadAllLandersFailed,
    loadEditLanderSuccess,
    loadEditLanderFailed,
    sendEditLanderSuccess,
    sendEditLanderFailed,
    deleteLanderSuccess,
    deleteLanderFailed,
    loadTokensDictionarySuccess,
    loadTokensDictionaryFailed,
    archiveLanderSuccess,
    archiveLanderFailed,
    loadLanderChartsDataSuccess,
    loadLanderChartsDataFailed
} from "./actions";

import {
    LoadCountriesListFromAPI,
    PostNewLanderFromAPI,
    LoadAllLandersFromAPI,
    LoadEditLanderFromAPI,
    SendEditLanderFromAPI,
    DeleteLanderFromAPI,
    ArchiveLanderFromAPI,
    LoadTokensDictionaryFromAPI,
    LoadLanderChartsDataFromAPI
} from "./api";

function* loadCountriesList(payload) {
    try {
        const response = yield call(LoadCountriesListFromAPI, payload);
        yield put(
            loadCountrySuccess(response.data)
        );
    } catch (error) {
        yield put(loadCountryFailed(error));
    }
}

function* postNewLanderData(payload) {
    try {
        const response = yield call(PostNewLanderFromAPI, payload);
        yield put(
            postLanderSuccess(response.data)
        );
    } catch (error) {
        yield put(postLanderFailed(error));
    }
}

function* loadAllLandersData(payload) {
    try {
        const response = yield call(LoadAllLandersFromAPI, payload);
        yield put(
            loadAllLandersSuccess(response.data)
        );
    } catch (error) {
        yield put(loadAllLandersFailed(error));
    }
}

function* loadEditLanderData(payload) {
    try {
        const response = yield call(LoadEditLanderFromAPI, payload);
        yield put(
            loadEditLanderSuccess(response.data)
        );
    } catch (error) {
        yield put(loadEditLanderFailed(error));
    }
}

function* sendEditLanderData(payload) {
    try {
        const response = yield call(SendEditLanderFromAPI, payload);
        yield put(
            sendEditLanderSuccess(response.data)
        );
    } catch (error) {
        yield put(sendEditLanderFailed(error));
    }
}

function* deleteLanderData(payload) {
    try {
        const response = yield call(DeleteLanderFromAPI, payload);
        yield put(
            deleteLanderSuccess(response.data)
        );
    } catch (error) {
        yield put(deleteLanderFailed(error));
    }
}

function* archiveLanderData(payload) {
    try {
        const response = yield call(ArchiveLanderFromAPI, payload);
        yield put(
            archiveLanderSuccess(response.data)
        );
    } catch (error) {
        yield put(archiveLanderFailed(error));
    }
}

function* loadTokenTagsData(payload) {
    try {
        const response = yield call(LoadTokensDictionaryFromAPI, payload);
        yield put(
            loadTokensDictionarySuccess(response.data)
        );
    } catch (error) {
        yield put(loadTokensDictionaryFailed(error));
    }
}

function* loadLanderChartsData(payload) {
    try {
        const response = yield call(LoadLanderChartsDataFromAPI, payload);
        yield put(
            loadLanderChartsDataSuccess(response.data)
        );
    } catch (error) {
        yield put(loadLanderChartsDataFailed(error));
    }
}
export default function* LandersSaga() {
    yield takeLatest(LOAD_COUNTRY, loadCountriesList);
    yield takeLatest(POST_LANDER_DATA, postNewLanderData);
    yield takeLatest(LOAD_ALL_LANDERS_DATA, loadAllLandersData);
    yield takeLatest(LOAD_EDIT_LANDER_DATA, loadEditLanderData);
    yield takeLatest(SEND_EDIT_LANDER_DATA, sendEditLanderData);
    yield takeLatest(DELETE_LANDER_DATA, deleteLanderData);
    yield takeLatest(ARCHIVE_LANDER_DATA, archiveLanderData);
    yield takeLatest(LOAD_TOKEN_TAGS, loadTokenTagsData);
    yield takeLatest(GET_LANDER_CHARTS_DATA, loadLanderChartsData);
}