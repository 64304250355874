import "./headerTabs.css";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
// import { Button } from "@progress/kendo-react-buttons";
import { Button } from "antd";
import {
  SearchOutlined,
  ExclamationCircleFilled,
  DollarCircleFilled,
} from "@ant-design/icons";

const HeaderTabs = (props) => {
  const location = useLocation();
  return (
    <>
      <div className="header_tabs" style={{ display: "flex" }}>
        <div
          className="tab"
          style={
            Number(props.activeTab) === 0
              ? { background: "#0e0e10", borderBottom: "1px solid #fff",color:"white",border:"0px",cursor:"pointer" }
              : null
          }
          onClick={() => props.changeActiveTab("0")}
        >
          Home
        </div>
        {props.reportCount >= 1 ? (
          <div
            className="tab"
            style={
              Number(props.activeTab) === 1
                ? { background: "#fff", borderBottom: "1px solid #fff",
                display:"flex",
                justifyContent:"space-between",alignItems:"flex-end" }
                : null
            }
          >
            <div style={{maxWidth:"200px", whiteSpace:"nowrap", textOverflow:"ellipsis", overflow:"hidden"}} onClick={() => props.changeActiveTab("1")}>
              {props.names[0]}
            </div>
            &nbsp;&nbsp;|
            <span
              className="close_btn k-icon k-i-close"
              onClick={() => props.closeReport("1")}
            ></span>
          </div>
        ) : null}
        {props.reportCount >= 2 ? (
          <div
            className="tab"
            style={
              Number(props.activeTab) === 2
                ? { background: "#fff", borderBottom: "1px solid #fff" ,
                display:"flex",
                justifyContent:"space-between",alignItems:"flex-end"  }
                : null
            }
          >

            <div style={{maxWidth:"200px", whiteSpace:"nowrap", textOverflow:"ellipsis", overflow:"hidden"}} onClick={() => props.changeActiveTab("2")}>
              {props.names[1]}
            </div>
            &nbsp;&nbsp;|
            <span
              className="close_btn k-icon k-i-close"
              onClick={() => props.closeReport("2")}
            ></span>
          </div>
        ) : null}
        {props.reportCount > 2 ? (
          <div
            className="tab"
            style={
              Number(props.activeTab) === 3
                ? { background: "#fff", borderBottom: "1px solid #fff",  display:"flex",
                justifyContent:"space-between",alignItems:"flex-end"  }
                : null
            }
          >
            <div style={{maxWidth:"200px", whiteSpace:"nowrap", textOverflow:"ellipsis", overflow:"hidden"}} onClick={() => props.changeActiveTab("3")}>
              {props.names[2]}
            </div>
            &nbsp;&nbsp;|
            <span
              className="close_btn k-icon k-i-close"
              onClick={() => props.closeReport("3")}
            ></span>
          </div>
        ) : null}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginBottom: "6px",
          }}
        >
          <NavLink
            exact
            to={{
              pathname: "/conversion/",
            }}
          >
        <Button
              size="small"
              type="dashed"
              icon={<DollarCircleFilled style={{marginRight:"5px"}}/>}
              style={{
                color: "#6786DE",
                marginRight: "18px",
                background: "transparent",
                padding: "5px",
                display: "inline-block",
                border: location.pathname === "/conversion/" ? "dashed 0px #6786DE" : "dashed 0.5px #6786DE",
                borderBottom:
                  location.pathname === "/conversion/"
                    ? "2px solid #6786DE"
                    : "dashed 0.5px #6786DE",
              }}
            >
              Conversions
            </Button> 
          </NavLink>
   <Button
            size="small"
            type="dashed"
            style={{
              color: "#ff3333",
              marginRight: "18px",
              background: "transparent",
              padding: "5px",
              display: "inline-block",
              border: "dashed 0.5px #ff3333",
            }}
            icon={<ExclamationCircleFilled style={{marginRight:"5px"}}/>}
          >
            Error Log
          </Button> 
        </div>
      </div>
    </>
  );
};

export default HeaderTabs;
