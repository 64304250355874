import {createSelector} from 'reselect';


const selectLangDomain = state => state.multilanguage || {};

/**
 * Other specific selectors
 */
const makeSelectCurrentLanguage = () =>
    createSelector(
        selectLangDomain,
        subState => subState.currentLanguageCode,
    );


export {
    makeSelectCurrentLanguage,
};