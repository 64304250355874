import React from "react";
import "./profile.css";
import { Button } from "@progress/kendo-react-buttons";

const StaticProfileData = props => {
    //new
    return (
        <div className="static_profile_con">
            <div className="profile_row">
                <div className="container row">
                    <div className="col-md-6" style={{ width: "50%" }}>
                        <label className="label normal_text color_black">
                            First Name
                        </label>
                        <div className="label_content">
                            <span className="content">
                                {props.profileDataReceived.firstname}
                            </span>
                        </div>
                    </div>
                    <div className="col-md-6" style={{ width: "50%" }}>
                        <label className="label normal_text color_black">
                            Last Name
                        </label>
                        <div className="label_content">
                            <span className="content">
                                {props.profileDataReceived.lastname}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="profile_row">
                <div className="container row">
                    <div className="col-md-6" style={{ width: "50%" }}>
                        <label className="label normal_text color_black">
                            Email
                        </label>
                        <div className="label_content">
                            <span className="content">
                                {props.profileDataReceived.email}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="profile_row">
                <div className="container row">
                    <div className="col-md-6" style={{ width: "50%" }}>
                        <label className="label normal_text color_black">
                            Account Type
                        </label>
                        <div className="label_content">
                            <span className="content">
                                {props.profileDataReceived.accountType}
                            </span>
                        </div>
                    </div>
                    <div className="col-md-6" style={{ width: "50%" }}>
                        <label className="label normal_text color_black">
                            Account Status
                        </label>
                        <div className="label_content">
                            <span className="content">
                                {props.profileDataReceived.accountStatus}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="profile_row">
                <div className="container row">
                    <div className="col-md-6" style={{ width: "50%" }}>
                        <label className="label normal_text color_black">
                            Linkedin or Facebook
                        </label>
                        <div className="label_content">
                            <span className="content">
                                {props.profileDataReceived.link === null
                                    ? "-"
                                    : props.profileDataReceived.link}
                            </span>
                        </div>
                    </div>
                    <div className="col-md-6" style={{ width: "50%" }}>
                        <label className="label normal_text color_black">
                            Instant messenger
                        </label>
                        <div className="label_content">
                            <span className="content">
                                {props.profileDataReceived
                                    .instant_messenger_number === null
                                    ? "-"
                                    : props.profileDataReceived
                                          .instant_messenger +
                                      " - " +
                                      props.profileDataReceived
                                          .instant_messenger_number}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="profile_row">
                <div className="container row">
                    <div className="col-md-12" style={{ width: "50%" }}>
                        <label className="label normal_text color_black">
                            Website
                        </label>
                        <div className="label_content">
                            <span className="content">
                                {props.profileDataReceived.website === null ||
                                props.profileDataReceived.website === ""
                                    ? "-"
                                    : props.profileDataReceived.website}
                            </span>
                        </div>
                    </div>
                    {props.profileDataReceived.accountType === "Company" && (
                        <div className="col-md-6" style={{ width: "50%" }}>
                            <label className="label normal_text color_black">
                                Company
                            </label>
                            <div className="label_content">
                                <span className="content">
                                    {props.profileDataReceived.company === null
                                        ? "-"
                                        : props.profileDataReceived.company}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="profile_row">
                <div className="container row">
                    <div className="col-md-6" style={{ width: "50%" }}>
                        <label className="label normal_text color_black">
                            Address
                        </label>
                        <div className="label_content">
                            <span className="content">
                                {props.profileDataReceived.address === null
                                    ? "-"
                                    : props.profileDataReceived.address}
                            </span>
                        </div>
                    </div>
                    <div className="col-md-6" style={{ width: "50%" }}>
                        <label className="label normal_text color_black">
                            City
                        </label>
                        <div className="label_content">
                            <span className="content">
                                {props.profileDataReceived.city === null
                                    ? "-"
                                    : props.profileDataReceived.city}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="profile_row">
                <div className="container row">
                    <div className="col-md-6" style={{ width: "50%" }}>
                        <label className="label normal_text color_black">
                            Region / State / Province
                        </label>
                        <div className="label_content">
                            <span className="content">
                                {props.profileDataReceived.region === null
                                    ? "-"
                                    : props.profileDataReceived.region}
                            </span>
                        </div>
                    </div>

                    <div className="col-md-6" style={{ width: "50%" }}>
                        <label className="label normal_text color_black">
                            Country
                        </label>
                        <div className="label_content">
                            <span className="content">
                                {props.profileDataReceived.country === null
                                    ? "-"
                                    : props.profileDataReceived.country}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="profile_row">
                <div className="container row">
                    <div className="col-md-6" style={{ width: "50%" }}>
                        <label className="label normal_text color_black">
                            Zip Code / Postal Code{" "}
                        </label>
                        <div className="label_content">
                            <span className="content">
                                {props.profileDataReceived.zipCode === null
                                    ? "-"
                                    : props.profileDataReceived.zipCode}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="profile_row">
                <div className="container row">
                    <div className="col-md-6" style={{ width: "50%" }}>
                        <label className="label normal_text color_black">
                            Phone Number
                        </label>
                        <div className="label_content">
                            <span className="content">
                                {props.profileDataReceived.phoneNumber === null
                                    ? "-"
                                    : props.profileDataReceived.phoneNumber}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="profile_row">
                <Button
                    icon="k-i-edit k-i-pencil"
                    className="edit_button gray_button_bg_imp theme_dark_blue_color"
                    onClick={props.clickedEdit}
                >
                    Edit
                </Button>
            </div>
        </div>
    );
};

export default StaticProfileData;
