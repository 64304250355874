import React, { Component } from "react";
import { Input } from "@progress/kendo-react-inputs";
//import produce from "immer";
import { Error } from '@progress/kendo-react-labels';
class ModalForm extends Component {
    state = {
        text1: true,
        text2: true,
    };

    // componentWillMount() {
    //     document.addEventListener("mousedown", this.handleClick, false);
    // }
    // componentWillUnmount() {
    //     document.removeEventListener("mousedown", this.handleClick, false);
    // }
    // handleClick = (e) => {
    //        if (this.node.contains(e.target)) {
    //             this.setState(produce((draft) => {  draft.text1 = true;}));
    //             return;
    //         }
    //            this.handleClickOutside();   
    // };
    // handleClickOutside = () => {
    //     this.setState(produce((draft) => { draft.text1 = false; }));
    // };
    render() {
        return (
            <div className="filtering-modal-content">
                <span className="normal_text normal_font black" style={{ color: "black" }}>
                    Define a filter name and add at least one IP / IP range or user agent value.
                    <br />
                    <b>You can add up to 50 values within a filtering rule.</b> One value means one
                    line that is populated with an IP / IP range or user agent string.
                </span>
                <hr className="hr"></hr>
                <div>
                    <label className="filtering-label">Filter name</label>
                </div>
                <Input required name="name" onChange={this.props.inputChangeHandler} value={this.props.state.dataToSend.name} ariaDescribedBy={'firstNameError'}></Input>
                {
                    this.props.state.dataToSend.name.length===0 && <Error id={'firstNameError'}>Name is required.</Error>
                }
                <hr className="hr"></hr>
                <div>
                    <label className="filtering-label">IP or IP range</label>
                    <label>Enter one IP / IP range or Multiple using (,) seperated.
                    <br />
                        Note: after and before (,) there should be no space. 
                    </label>
                </div>
                {/* {this.state.text1 || this.props.state.dataToSend.ip_address.length===0 ? (
                    <textarea
                        ref={(node) => (this.node = node)}
                        name="ip_address"
                        className="filter-textarea"
                        value={this.props.state.dataToSend.ip_address}
                        onChange={this.props.inputChangeHandler}
                        ariaDescribedBy={"address"}
                    ></textarea>
                ) : (
                    <div id="ip_address" ref={(node) => (this.node = node)} >
                        <ul>
                            <li>{ this.props.state.dataToSend.ip_address}</li>
                        </ul>
                    </div>
                    )}
                {
                    this.props.state.dataToSend.ip_address.length===0 && <Error id={'address'}>Ip address is required.</Error>
                } */}
                <textarea
                        name="ip_address"
                        className="filter-textarea"
                        value={this.props.state.dataToSend.ip_address}
                        onChange={this.props.inputChangeHandler}
                        ariaDescribedBy={"address"}
                        required
                    ></textarea>
                {
                    this.props.state.errors.ip_address !== null  && <Error id={'address'}>{this.props.state.errors.ip_address}</Error>
                }

                <hr className="hr"></hr>
                <div>
                    <label className="filtering-label">User agent</label>
                    <label>Enter one user agent or multiple user agent using (@) seperated.<br />
                    Note: Name should be or more than 5 characters.
                    </label>
                </div>
                
                <textarea
                        name="user_agent"
                        className="filter-textarea"
                        value={this.props.state.dataToSend.user_agent}
                        onChange={this.props.inputChangeHandler}
                        ariaDescribedBy={"agent"}
                        required
                ></textarea>
                {
                    this.props.state.errors.user_agent !== null && <Error id={'agent'}>{this.props.state.errors.user_agent}</Error>
                } 
                    {/* <div id="user_agent" >
                        <ul>
                            <li>{ this.props.state.dataToSend.user_agent}</li>
                        </ul>
                    </div> */}
                
            </div>
        );
    }
}

export default ModalForm;
