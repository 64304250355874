import { createSelector } from "reselect";
import { initialAffiliateNetworksState } from "./reducer";
import _ from "lodash"

const selectAffiliateNetworksState= (state) =>  state.affiliateNetworks || initialAffiliateNetworksState;

const makeSelectLoading = () => createSelector(selectAffiliateNetworksState, (subState) => subState.loading);
const makeSelectSuccess = () => createSelector(selectAffiliateNetworksState, (subState) => subState.success);
const makeSelectError = () => createSelector(selectAffiliateNetworksState, (subState) => subState.error);


const makeSelectTrackingUrlsReceived = () => createSelector(selectAffiliateNetworksState, (subState) => subState.trackingUrlsReceived);
const makeSelectLoadCurrenciesList = () => createSelector(selectAffiliateNetworksState, (subState) => subState.currenciesListReceived);


const makeSelectAffiliatedNetworkDataLoaded = () => createSelector(selectAffiliateNetworksState, (subState) =>  {
        // console.log("SADdsasdadas", subState?.affiliatedNetworkDataLoaded)    
        // let affiliatedNetworkDataLoaded = _.cloneDeep(subState?.affiliatedNetworkDataLoaded?.data ?? [])
        // affiliatedNetworkDataLoaded?.sort((first,second) => first.name.localeCompare(second.name))    
        // return { data: affiliatedNetworkDataLoaded, total :  subState?.affiliatedNetworkDataLoaded?.total }

        return subState?.affiliatedNetworkDataLoaded
    });

const makeSelectEditAffiliateNetworkDataReceived = () => createSelector(selectAffiliateNetworksState, (subState) => subState.editAffiliateNetworkDataReceived);

const makeSelectIsNewAffiliateNetworkDataSent = () => createSelector(selectAffiliateNetworksState, (subState) => subState.isNewAffiliateNetworkDataSent);
const makeSelectIsAllAffiliateNetworksDataLoaded = () => createSelector(selectAffiliateNetworksState, (subState) => subState.isAllAffiliateNetworksDataLoaded);
const makeSelectIsEditAffiliateNetworkDataReceived = () => createSelector(selectAffiliateNetworksState, (subState) => subState.isEditAffiliateNetworkDataReceived);
const makeSelectIsPatchedAffiliateNetworkDataSent = () => createSelector(selectAffiliateNetworksState, (subState) => subState.isPatchedAffiliateNetworkDataSent);
const makeSelectIsDeleteAffiliateNetworkDataSent = () => createSelector(selectAffiliateNetworksState, (subState) => subState.isDeleteAffiliateNetworkDataSent);
const makeSelectIsAffNetworkArchived = () => createSelector(selectAffiliateNetworksState, (subState) => subState.isAffNetworkArchived);

const makeSelectCustomAffiliateNetworkData = () => createSelector(selectAffiliateNetworksState, (subState) => subState.customAffiliateNetworkData);
const makeSelectIsCustomAffiliateNetworkDataLoaded = () => createSelector(selectAffiliateNetworksState, (subState) => subState.isCustomAffiliateNetworkDataLoaded);

const makeSelectIsChartsDataLoaded = () => createSelector(selectAffiliateNetworksState, (subState) => subState.charts.isChartsDataLoaded);
const makeSelectAllChartsDataLoaded = () => createSelector(selectAffiliateNetworksState, (subState) => subState.charts.allChartsDataLoaded);

const makeSelectCustomAffiliateNetworkDataById = () => createSelector(selectAffiliateNetworksState, (subState) => subState.customAffiliateNetworkDataById);
const makeSelectIsCustomAffiliateNetworkDataLoadedById = () => createSelector(selectAffiliateNetworksState, (subState) => subState.isCustomAffiliateNetworkDataByIdLoaded);
const makeSelectArchiveDeleteResponseAffNetwork = () => createSelector(selectAffiliateNetworksState, (subState) => subState.archiveDeleteResponseAffNetwork);
export {
    makeSelectLoading,
    makeSelectSuccess,
    makeSelectError,
    makeSelectTrackingUrlsReceived,
    makeSelectLoadCurrenciesList,
    makeSelectAffiliatedNetworkDataLoaded,
    makeSelectEditAffiliateNetworkDataReceived,

    makeSelectIsNewAffiliateNetworkDataSent,
    makeSelectIsAllAffiliateNetworksDataLoaded,
    makeSelectIsEditAffiliateNetworkDataReceived,
    makeSelectIsPatchedAffiliateNetworkDataSent,
    makeSelectIsDeleteAffiliateNetworkDataSent,
    makeSelectCustomAffiliateNetworkData,
    makeSelectIsCustomAffiliateNetworkDataLoaded,
    makeSelectIsAffNetworkArchived,

    makeSelectCustomAffiliateNetworkDataById,
    makeSelectIsCustomAffiliateNetworkDataLoadedById,
    makeSelectIsChartsDataLoaded,
    makeSelectAllChartsDataLoaded,

    makeSelectArchiveDeleteResponseAffNetwork
}