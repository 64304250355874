import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
// import LayoutReport from "../../../layout/layoutReport";
import Layout from "../../../layout";
import ReportMenuBarTop from "../../PageMenuBarTop/reportMenuBarTop";
import PageMenuBarBottom from "./PageMenuBarBottom";
import "../../Pages/pages.css";
import { createStructuredSelector } from "reselect";
import {
  makeSelectAllChartsDataLoaded,
  makeSelectIsChartsDataLoaded,
  makeSelectAllLoadedReportData,
  makeSelectError,
} from "./selectors";
import { loadChartsData, loadAllReportData } from "./actions";
import { makeSelectToken } from "../../../registration/LoginRegister/selectors";
import "./Landers.css";
import produce from "immer";
import { Fade } from "@progress/kendo-react-animation";
import GridReportComponent from "./gridReport";
import MenuCharts from "../../charts";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import GeneralHeaderMenuBarReport from "../../LayoutHeader/GeneralHeaderMenuBarReport";
import LoadingPanel from "../../grid/loader/loader";
import moment from "moment-timezone";
import { timeZonesConversion } from "../../../components/timeZonesExchange";

class Report extends Component {
  state = {
    allLoadedReportData: null,
    selectedListReceivedFromGrid: [],
    charts: {
      showCharts: false,
      chartsData: null,
      isChartsDataLoaded: false,
    },
    search: {
      tagsListReceived: [],
      inputSearchValue: "",
      tagsSearchValue: [],
      isTagsSearchSelected: false,
      reportGroupingValues: {
        id: "",
        filter1: "",
        filter2: "",
        filter3: "",
        filter4: "",
        reportType: "",
      },
      sorting: {
        order: "visits",
        sort: "ASC",
      },
      modifiedDate: {
        startDate: moment(new Date()).format("MM/DD/YYYY hh:mm A"),
        endDate: moment(new Date()).format("MM/DD/YYYY hh:mm A"),
      },
      date: {
        startDate: moment(new Date()),
        endDate: moment(new Date()),
        timeZone: "Pacific/Kwajalein",
      },
    },
    showCalendar: false,
    showColumnsModal: false,
    loader: true,
    pagination: {
      skip: 0,
      take: 50,
      page: 1,
      total: 0,
      totalPages: 1,
      blur: {
        blurFirst: true,
        blurLast: true,
        blurPrev: true,
        blurNext: true,
      },
    },
    backendErrors: {
      error: false,
      errorMsg: "",
    },
    activeIcons: {
      campaign: false,
      offers: false,
      affilateNetwork: false,
      flow: false,
      trafficSource: false,
      lander: false,
      path: false,
    },
    menuButtonsActive: [
      { name: "Campaign", activeStatus: false },
      { name: "Offers", activeStatus: false },
      { name: "Flows", activeStatus: false },
      { name: "Affiliate Network", activeStatus: false },
      { name: "Traffic Source", activeStatus: false },
      { name: "Lander", activeStatus: false },
      { name: "Path", activeStatus: false },
    ],
    fullGroupingList: [],
    disable: {
      filter3: false,
      filter4: false,
    },
    appliedGroupingValues: {
      id: "",
      filter1: "",
      filter2: "",
      filter3: "",
      filter4: "",
      reportType: "",
    },
    pageContentHeight: "500",
    reportCount: 0,
    activeTab: 0,
    ranges: {},
    defaultTimeZone: "(GMT -12:00) Eniwetok, Kwajalein",
    timeZonesList: [],
  };

  fetchAllReportData = async () => {
    return this.props.dispatchLoadReportData({
      accessToken: this.props.accessToken,
      searchQuery: this.state.search,
      sorting: this.state.search.sorting,
      pagination: this.state.pagination,
    });
  };
  fetchChartsData = () => {
    return this.props.dispatchLoadChartsData({
      accessToken: this.props.accessToken,
      dates: this.state.search.modifiedDate,
      groupingValues: this.state.search.reportGroupingValues,
    });
  };

  setInitialGroupingFilters = () => {
    let url = new URL(window.location.href);
    if (url.searchParams.get("reportType") === "Campaign") {
      this.setState(
        produce((draft) => {
          draft.search.reportGroupingValues.filter2 = "Offers";
          draft.search.reportGroupingValues.filter3 = "";
          draft.search.reportGroupingValues.filter4 = "";

          draft.appliedGroupingValues.filter2 = "Offers";
          draft.appliedGroupingValues.filter3 = "";
          draft.appliedGroupingValues.filter4 = "";
        })
      );
      this.changeActiveStyleMenuBtn("Offers", "new");
    } else {
      this.setState(
        produce((draft) => {
          draft.search.reportGroupingValues.filter2 = "Campaign";
          draft.search.reportGroupingValues.filter3 = "";
          draft.search.reportGroupingValues.filter4 = "";

          draft.appliedGroupingValues.filter2 = "Campaign";
          draft.appliedGroupingValues.filter3 = "";
          draft.appliedGroupingValues.filter4 = "";
        })
      );
      this.changeActiveStyleMenuBtn("Campaign", "new");
    }
  };

  componentDidMount() {
    let search = JSON.parse(localStorage.getItem("search"));
    this.setState(
      produce((draft) => {
        draft.reportCount = search.report.reportCount;
      })
    );
    let url = new URL(window.location.href);
    if (url.searchParams.get("new") === "true") {
      this.setSearchFilters(url);
      this.setInitialGroupingFilters();
      this.showHideTopMenuButtons(url);
      // set all the necessary values in the report localstorage as well.
      setTimeout(() => {
        this.returnRanges();
        this.calculateNewTimeZonesList();
        this.setSearchPropertiesToLocalStorage();
      }, 1000);
    } else {
      let url = new URL(window.location.href);
      this.loadDataFromLocalStorageToState(url);
      setTimeout(() => {
        this.changeActiveStyleMenuBtn(
          this.state.search.reportGroupingValues.filter2,
          "old"
        );
        // fetch All report data again
        this.fetchAllReportData();
      }, 1000);
    }

    // fetching all data initially
    setTimeout(() => {
      if (this.state.allLoadedReportData === null) {
        this.fetchAllReportData();
      }
    }, 1000);
    // code for setting grid height initially
    let pageMenuHeight = Number(
      document.getElementById("page_menu").clientHeight
    );
    let pageFullHeight = Number(
      document.getElementsByTagName("html")[0].clientHeight
    );
    this.setState(
      produce((draft) => {
        draft.pageContentHeight = pageFullHeight - pageMenuHeight - 127;
      })
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // set charts data on any reload of it
    if (
      prevProps.chartsAllDataLoaded !== this.props.chartsAllDataLoaded &&
      this.props.chartsAllDataLoaded !== null
    ) {
      this.setState(
        produce((draft) => {
          draft.charts.chartsData = this.props.chartsAllDataLoaded;
          draft.loader = false;
        })
      );
    }

    // load data from props to state after each update in the data, edit / delete / add
    // then make isLanders Separated to false so that the next loop can run and separate the landers and pre landers
    if (
      prevProps.allLoadedReportData !== this.props.allLoadedReportData &&
      this.props.allLoadedReportData !== null
    ) {
      // console.log(this.props.allLoadedReportData);
      this.setState(
        produce((draft) => {
          draft.appliedGroupingValues = this.state.search.reportGroupingValues;
          draft.allLoadedReportData = this.props.allLoadedReportData;
          if (this.props.allLoadedReportData.total) {
            draft.pagination.total =
              this.props.allLoadedReportData.total.totalRows;
          }
          if (this.props.allLoadedReportData.grouping) {
            draft.fullGroupingList = this.props.allLoadedReportData.grouping;
          }
          draft.loader = false;
        })
      );
      this.setTotalPagination();
      setTimeout(() => {
        this.setPaginationUI();
      }, 50);
    }

    // Check the error
    if (prevProps.Error !== this.props.Error && this.props.Error !== false) {
      if (this.props.Error.data) {
        this.setState(
          produce((draft) => {
            draft.backendErrors.errorMsg =
              (this.props.Error.data.message
                ? this.props.Error.data.message
                : null) +
              ", " +
              (this.props.Error.data.error
                ? this.props.Error.data.error
                : null);
            draft.backendErrors.error = true;
            draft.loader = false;
          })
        );
      }
    }

    // re-load the report data on url change
    if (prevProps.location.search !== this.props.location.search) {
      this.toggleLoader();
      let url = new URL(window.location.href);
      this.loadDataFromLocalStorageToState(url);
      setTimeout(() => {
        this.changeActiveStyleMenuBtn(
          this.state.search.reportGroupingValues.filter2,
          "old"
        );
        // fetch All report data again
        this.fetchAllReportData();
      }, 1000);
    }
  }

  addTooltipDate = () => {
    let search = JSON.parse(localStorage.getItem("search"));
    for (let i = 0; i < this.state.timeZonesList.length; i++) {
      if (search.dates.timeZone === this.state.timeZonesList[i].calendar) {
        this.setState(
          produce((draft) => {
            draft.defaultTimeZone = this.state.timeZonesList[i].name;
          })
        );
      }
    }
  };

  calculateNewTimeZonesList = () => {
    let newTimeZoneList = [];
    let defaultTimeZoneId = JSON.parse(localStorage.getItem("search")).setting
      .timeZoneId;
    for (let i = 0; i < timeZonesConversion.length; i++) {
      if (Number(defaultTimeZoneId) === Number(timeZonesConversion[i].id)) {
        newTimeZoneList.push({
          id: timeZonesConversion[i].id,
          name: "Default: " + timeZonesConversion[i].name,
          calendar: timeZonesConversion[i].calendar,
        });
      }
    }
    for (let i = 0; i < timeZonesConversion.length; i++) {
      if (Number(defaultTimeZoneId) !== Number(timeZonesConversion[i].id)) {
        newTimeZoneList.push({
          id: timeZonesConversion[i].id,
          name: timeZonesConversion[i].name,
          calendar: timeZonesConversion[i].calendar,
        });
      }
    }
    this.setState(
      produce((draft) => {
        draft.timeZonesList = newTimeZoneList;
      })
    );
    setTimeout(() => {
      this.addTooltipDate();
    }, 100);
  };

  loadDataFromLocalStorageToState = (url) => {
    let search = JSON.parse(localStorage.getItem("search"));
    this.setState(
      produce((draft) => {
        draft.reportCount = search.report.reportCount;
        // draft.activeTab = search.activeTab;
      })
    );
    for (let i = 1; i < 4; i++) {
      let id = url.search.split("id=")[1].split("&")[0];
      if (
        url.searchParams.get("id") ===
        search.report["report" + i].search.reportGroupingValues.id
      ) {
        this.setState(
          produce((draft) => {
            draft.search.reportGroupingValues =
              search.report["report" + i].search.reportGroupingValues;
            draft.search.sorting = search.report["report" + i].search.sorting;
            draft.search.inputSearchValue =
              search.report["report" + i].search.inputSearchValue;
            draft.search.modifiedDate =
              search.report["report" + i].search.modifiedDate;
            draft.search.date = search.report["report" + i].search.date;
            // set active icons
            draft.activeIcons = search.report["report" + i].activeIcons;
            // // set applied grouping values
            // draft.appliedGroupingValues = search.report["report" + i].appliedGroupingValues;
            // set the disable properties for grouping dropdowns
            draft.disable = search.report["report" + i].disable;
            // set full grouping list received from all data
            draft.fullGroupingList =
              search.report["report" + i].fullGroupingList;
            // set menu buttons active
            draft.menuButtonsActive =
              search.report["report" + i].menuButtonsActive;

            // reset the rest of the values...
            draft.allLoadedReportData = null;
            draft.selectedListReceivedFromGrid = [];
            draft.charts = {
              showCharts: false,
              chartsData: null,
              isChartsDataLoaded: false,
            };
            draft.showCalendar = false;
            draft.showColumnsModal = false;
            draft.pagination = {
              skip: 0,
              take: 50,
              page: 1,
              total: 0,
              totalPages: 1,
              blur: {
                blurFirst: true,
                blurLast: true,
                blurPrev: true,
                blurNext: true,
              },
            };
            draft.fullGroupingList = [];
          })
        );
      }
    }
  };

  setSearchFilters = (url) => {
    this.setState(
      produce((draft) => {
        // set grouping values...
        draft.search.reportGroupingValues.id = url.searchParams.get("id");
        draft.search.reportGroupingValues.reportType =
          url.searchParams.get("reportType");
        draft.search.reportGroupingValues.filter1 =
          url.searchParams.get("reportType");

        draft.appliedGroupingValues.id = url.searchParams.get("id");
        draft.appliedGroupingValues.reportType =
          url.searchParams.get("reportType");
        draft.appliedGroupingValues.filter1 =
          url.searchParams.get("reportType");
        // set dates for calendar
        let search = JSON.parse(localStorage.getItem("search"));
        draft.search.date.timeZone = search.dates.timeZone;
        draft.search.date.startDate = moment(
          url.searchParams.get("startDate")
        ).startOf("day");
        draft.search.date.endDate = moment(
          url.searchParams.get("endDate")
        ).endOf("day");
        // set dates to show above the calendar
        draft.search.modifiedDate.startDate = moment(
          url.searchParams.get("startDate")
        )
          .startOf("day")
          .format("MM/DD/YYYY hh:mm A");
        draft.search.modifiedDate.endDate = moment(
          url.searchParams.get("endDate")
        )
          .endOf("day")
          .format("MM/DD/YYYY hh:mm A");
      })
    );
  };

  showHideTopMenuButtons = (url) => {
    // Show and hide the top menu buttons on the basis of report type
    if (url.searchParams.get("reportType") === "Campaign") {
      this.setState(
        produce((draft) => {
          draft.activeIcons.campaign = false;
          draft.activeIcons.offers = true;
          draft.activeIcons.affilateNetwork = true;
          draft.activeIcons.flow = true;
          draft.activeIcons.trafficSource = true;
          draft.activeIcons.lander = true;
          draft.activeIcons.path = true;
        })
      );
    } else if (url.searchParams.get("reportType") === "Offers") {
      this.setState(
        produce((draft) => {
          draft.activeIcons.campaign = true;
          draft.activeIcons.offers = false;
          draft.activeIcons.affilateNetwork = false;
          draft.activeIcons.flow = true;
          draft.activeIcons.trafficSource = true;
          draft.activeIcons.lander = true;
          draft.activeIcons.path = false;
        })
      );
    } else if (url.searchParams.get("reportType") === "Lander") {
      this.setState(
        produce((draft) => {
          draft.activeIcons.campaign = true;
          draft.activeIcons.offers = true;
          draft.activeIcons.affilateNetwork = true;
          draft.activeIcons.flow = true;
          draft.activeIcons.trafficSource = true;
          draft.activeIcons.lander = false;
          draft.activeIcons.path = false;
        })
      );
    } else if (url.searchParams.get("reportType") === "Flows") {
      this.setState(
        produce((draft) => {
          draft.activeIcons.campaign = true;
          draft.activeIcons.offers = true;
          draft.activeIcons.affilateNetwork = true;
          draft.activeIcons.flow = false;
          draft.activeIcons.trafficSource = true;
          draft.activeIcons.lander = true;
          draft.activeIcons.path = true;
        })
      );
    } else if (url.searchParams.get("reportType") === "Affiliate Network") {
      this.setState(
        produce((draft) => {
          draft.activeIcons.campaign = true;
          draft.activeIcons.offers = true;
          draft.activeIcons.affilateNetwork = false;
          draft.activeIcons.flow = true;
          draft.activeIcons.trafficSource = true;
          draft.activeIcons.lander = true;
          draft.activeIcons.path = false;
        })
      );
    } else if (url.searchParams.get("reportType") === "Traffic Source") {
      this.setState(
        produce((draft) => {
          draft.activeIcons.campaign = true;
          draft.activeIcons.offers = true;
          draft.activeIcons.affilateNetwork = true;
          draft.activeIcons.flow = true;
          draft.activeIcons.trafficSource = false;
          draft.activeIcons.lander = true;
          draft.activeIcons.path = false;
        })
      );
    } else {
      this.setState(
        produce((draft) => {
          draft.activeIcons.campaign = true;
          draft.activeIcons.offers = true;
          draft.activeIcons.affilateNetwork = true;
          draft.activeIcons.flow = true;
          draft.activeIcons.trafficSource = true;
          draft.activeIcons.lander = true;
          draft.activeIcons.path = true;
        })
      );
    }
  };

  setSearchPropertiesToLocalStorage = (data) => {
    let search = JSON.parse(localStorage.getItem("search"));
    // set search values necessary in the localstorage
    // set report grouping values
    search.report[
      "report" + this.state.reportCount
    ].search.reportGroupingValues = this.state.search.reportGroupingValues;
    // set sorting
    search.report["report" + this.state.reportCount].search.sorting =
      this.state.search.sorting;
    // set input search value
    search.report["report" + this.state.reportCount].search.inputSearchValue =
      this.state.search.inputSearchValue;
    // set dates
    search.report["report" + this.state.reportCount].search.modifiedDate =
      this.state.search.modifiedDate;
    search.report["report" + this.state.reportCount].search.date =
      this.state.search.date;
    // set active icons
    search.report["report" + this.state.reportCount].activeIcons =
      this.state.activeIcons;
    // set applied grouping values
    search.report["report" + this.state.reportCount].appliedGroupingValues =
      this.state.appliedGroupingValues;
    // set the disable proprties for grouping dropdowns
    search.report["report" + this.state.reportCount].disable =
      this.state.disable;
    // set full grouping list received from all data
    search.report["report" + this.state.reportCount].fullGroupingList =
      this.state.fullGroupingList;
    // set menu buttons active
    search.report["report" + this.state.reportCount].menuButtonsActive =
      this.state.menuButtonsActive;

    // set all this in the localStorage
    localStorage.setItem("search", JSON.stringify(search));
  };

  sendSearchRequest = () => {
    // check if grouping values are empty or not.
    if (this.state.search.reportGroupingValues.filter2 === "") {
      if (this.state.search.reportGroupingValues.filter3 === "") {
        if (this.state.search.reportGroupingValues.filter4 === "") {
          this.setInitialGroupingFilters();
        } else {
          const filter4Value = this.state.search.reportGroupingValues.filter4;
          this.setState(
            produce((draft) => {
              draft.search.reportGroupingValues.filter2 = filter4Value;
              draft.search.reportGroupingValues.filter3 = "";
              draft.search.reportGroupingValues.filter4 = "";
            })
          );
        }
      } else {
        const filter3Value = this.state.search.reportGroupingValues.filter3;
        const filter4Value = this.state.search.reportGroupingValues.filter4;
        this.setState(
          produce((draft) => {
            draft.search.reportGroupingValues.filter2 = filter3Value;
            draft.search.reportGroupingValues.filter3 = filter4Value;
            draft.search.reportGroupingValues.filter4 = "";
          })
        );
      }
    } else if (this.state.search.reportGroupingValues.filter3 === "") {
      if (this.state.search.reportGroupingValues.filter4 !== "") {
        const filter4Value = this.state.search.reportGroupingValues.filter4;
        this.setState(
          produce((draft) => {
            draft.search.reportGroupingValues.filter3 = filter4Value;
            draft.search.reportGroupingValues.filter4 = "";
          })
        );
      }
    }
    setTimeout(() => {
      // set the applied Grouping values equal to the search report grouping values....
      this.setState(
        produce((draft) => {
          draft.appliedGroupingValues.filter2 =
            this.state.search.reportGroupingValues.filter2;
          draft.appliedGroupingValues.filter3 =
            this.state.search.reportGroupingValues.filter3;
          draft.appliedGroupingValues.filter4 =
            this.state.search.reportGroupingValues.filter4;
          draft.appliedGroupingValues.reportType =
            this.state.search.reportGroupingValues.reportType;
        })
      );
      // change the color of active button before sending request.
      this.changeActiveStyleMenuBtn(
        this.state.search.reportGroupingValues.filter2
      );
    }, 40);
    setTimeout(() => {
      this.fetchAllReportData();
      if (this.state.charts.showCharts === true) {
        this.fetchChartsData();
      }
      this.setState(
        produce((draft) => {
          draft.showCalendar = false;
          draft.loader = true;
        })
      );
    }, 60);
  };

  gridCheckedList = (list) => {
    this.setState(
      produce((draft) => {
        draft.selectedListReceivedFromGrid = list;
      })
    );
  };

  showCharts = () => {
    if (this.state.charts.showCharts !== true) {
      this.fetchChartsData();
    }
    this.setState(
      produce((draft) => {
        draft.showCalendar = false;
        draft.charts.showCharts = !this.state.charts.showCharts;
        draft.charts.isChartsDataLoaded = !this.state.charts.isChartsDataLoaded;
      })
    );
  };

  toggleCalendarWindow = () => {
    this.setState(
      produce((draft) => {
        draft.showCalendar = !this.state.showCalendar;
      })
    );
  };

  toggleColumnsModal = () => {
    this.setState(
      produce((draft) => {
        draft.showColumnsModal = !this.state.showColumnsModal;
      })
    );
  };
  changeSorting = (data) => {
    let sorting = { ...this.state.search.sorting };
    if (String(sorting.order) === String(data) && sorting.sort === "ASC") {
      this.setState(
        produce((draft) => {
          draft.search.sorting.sort = "DESC";
        })
      );
    } else if (
      String(sorting.order) === String(data) &&
      sorting.sort === "DESC"
    ) {
      this.setState(
        produce((draft) => {
          draft.search.sorting.sort = "ASC";
        })
      );
    } else if (String(sorting.order) !== String(data)) {
      this.setState(
        produce((draft) => {
          draft.search.sorting.order = data;
          draft.search.sorting.sort = "ASC";
        })
      );
    }
    setTimeout(() => {
      this.fetchAllReportData();
      this.setSearchPropertiesToLocalStorage();
    }, 50);
  };

  setTotalPagination = () => {
    if (Number(this.props.allLoadedReportData.total.totalRows) > 0) {
      let totalRows = Number(this.props.allLoadedReportData.total.totalRows);
      if (totalRows <= this.state.pagination.take) {
        this.setState(
          produce((draft) => {
            draft.pagination.totalPages = 1;
          })
        );
      } else {
        if (totalRows % Number(this.state.pagination.take) > 0) {
          this.setState(
            produce((draft) => {
              draft.pagination.totalPages =
                Math.floor(totalRows / this.state.pagination.take) + 1;
            })
          );
        } else {
          this.setState(
            produce((draft) => {
              draft.pagination.totalPages =
                totalRows / this.state.pagination.take;
            })
          );
        }
      }
    } else {
      this.setState(
        produce((draft) => {
          draft.pagination.totalPages = 1;
        })
      );
    }
  };
  setPaginationUI = () => {
    if (Number(this.state.pagination.totalPages) === 1) {
      this.setState(
        produce((draft) => {
          draft.pagination.blur.blurFirst = true;
          draft.pagination.blur.blurPrev = true;
          draft.pagination.blur.blurNext = true;
          draft.pagination.blur.blurLast = true;
        })
      );
    } else if (
      this.state.pagination.page === 1 &&
      this.state.pagination.totalPages > 1
    ) {
      this.setState(
        produce((draft) => {
          draft.pagination.blur.blurFirst = true;
          draft.pagination.blur.blurPrev = true;
          draft.pagination.blur.blurNext = false;
          draft.pagination.blur.blurLast = false;
        })
      );
    } else if (
      this.state.pagination.page !== 1 &&
      this.state.pagination.totalPages > 1 &&
      this.state.pagination.page < this.state.pagination.totalPages
    ) {
      this.setState(
        produce((draft) => {
          draft.pagination.blur.blurFirst = false;
          draft.pagination.blur.blurPrev = false;
          draft.pagination.blur.blurNext = false;
          draft.pagination.blur.blurLast = false;
        })
      );
    } else if (
      this.state.pagination.page !== 1 &&
      this.state.pagination.totalPages > 1 &&
      this.state.pagination.page === this.state.pagination.totalPages
    ) {
      this.setState(
        produce((draft) => {
          draft.pagination.blur.blurFirst = false;
          draft.pagination.blur.blurPrev = false;
          draft.pagination.blur.blurNext = true;
          draft.pagination.blur.blurLast = true;
        })
      );
    }
  };

  changePagination = (data) => {
    if (data === "first") {
      this.setState(
        produce((draft) => {
          draft.pagination.skip = 0;
          draft.pagination.page = 1;
        })
      );
    } else if (data === "prev") {
      let dataToSkip = this.state.pagination.skip - this.state.pagination.take;
      this.setState(
        produce((draft) => {
          draft.pagination.skip = dataToSkip;
          draft.pagination.page -= 1;
        })
      );
    } else if (data === "next") {
      let dataToSkip = this.state.pagination.skip + this.state.pagination.take;
      this.setState(
        produce((draft) => {
          draft.pagination.skip = dataToSkip;
          draft.pagination.page += 1;
        })
      );
    } else if (data === "last") {
      let dataToSkip =
        (this.state.pagination.totalPages - 1) * this.state.pagination.take;
      this.setState(
        produce((draft) => {
          draft.pagination.skip = dataToSkip;
          draft.pagination.page = this.state.pagination.totalPages;
        })
      );
    }
    setTimeout(() => {
      this.fetchAllReportData();
    }, 50);
    this.setPaginationUI();
  };

  changeGridRowsNumber = (e) => {
    this.setState(
      produce((draft) => {
        draft.pagination.skip = 0;
        draft.pagination.page = 1;
        draft.pagination.take = Number(e.target.value);
      })
    );
    setTimeout(() => {
      this.fetchAllReportData();
    }, 50);
    this.setPaginationUI();
  };

  toggleLoader = () => {
    this.setState(
      produce((draft) => {
        draft.loader = !this.state.loader;
      })
    );
  };

  // code for search parameters
  changeSearchValues = (e, data) => {
    if (data === "tagsSearchValue") {
      this.setState(
        produce((draft) => {
          draft.search.tagsSearchValue = e.target.value;
        })
      );
    } else if (data === "inputSearchValue") {
      if (e === "") {
        this.setState(
          produce((draft) => {
            draft.search.inputSearchValue = "";
          }),
          () => this.sendSearchRequest()
        );
      } else {
        this.setState(
          produce((draft) => {
            draft.search.inputSearchValue = e.target.value;
          })
        );
      }
    } else if (data === "grouping") {
      this.setState(
        produce((draft) => {
          draft.search.reportGroupingValues.filter2 = e[0];
          draft.search.reportGroupingValues.filter3 = e[1];
          draft.search.reportGroupingValues.filter4 = e[2];
        })
      );
      if (
        e[0] === "Month" ||
        e[0] === "Day" ||
        e[0] === "Conversion" ||
        e[0] === "Hour Of day"
      ) {
        this.setState(
          produce((draft) => {
            draft.disable.filter3 = true;
            draft.disable.filter4 = true;
            draft.search.reportGroupingValues.filter3 = "";
            draft.search.reportGroupingValues.filter4 = "";
          })
        );
      } else if (
        e[1] === "Month" ||
        e[1] === "Day" ||
        e[1] === "Conversion" ||
        e[1] === "Hour Of day"
      ) {
        this.setState(
          produce((draft) => {
            draft.disable.filter3 = false;
            draft.disable.filter4 = true;
            draft.search.reportGroupingValues.filter4 = "";
          })
        );
      } else {
        this.setState(
          produce((draft) => {
            draft.disable.filter3 = false;
            draft.disable.filter4 = false;
          })
        );
      }
    }
    setTimeout(() => {
      this.setSearchPropertiesToLocalStorage();
    }, 400);
  };

  onSearchBtnClick = () => {
    this.setState(
      produce((draft) => {
        draft.search.isTagsSearchSelected =
          !this.state.search.isTagsSearchSelected;
      })
    );
  };

  changeActiveStyleMenuBtn = (data, status) => {
    for (let i = 0; i < this.state.menuButtonsActive.length; i++) {
      if (this.state.menuButtonsActive[i].name === data) {
        this.setState(
          produce((draft) => {
            draft.menuButtonsActive[i].activeStatus = true;
          })
        );
      } else {
        this.setState(
          produce((draft) => {
            draft.menuButtonsActive[i].activeStatus = false;
          })
        );
      }
    }
    if (status === "new") {
      setTimeout(() => {
        this.setSearchPropertiesToLocalStorage();
      }, 400);
    }
  };
  menuButtonClickedMethod = (data) => {
    this.setState(
      produce((draft) => {
        draft.search.reportGroupingValues.filter2 = data;
        draft.search.reportGroupingValues.filter3 = "";
        draft.search.reportGroupingValues.filter4 = "";

        draft.appliedGroupingValues.filter2 = data;
        draft.appliedGroupingValues.filter3 = "";
        draft.appliedGroupingValues.filter4 = "";

        draft.loader = !this.state.loader;
      })
    );
    this.changeActiveStyleMenuBtn(data);
    setTimeout(() => {
      this.fetchAllReportData();
      this.setSearchPropertiesToLocalStorage();
    }, 500);
  };

  rangeCallback = (index, value) => {
    //   setTimeout(() => {
    //     for (let i=0;i<12;i++) {
    //         document.getElementById("rangeButton" + i).style.backgroundColor = "rgb(245, 245, 245) !important";
    //         document.getElementById("rangeButton" + i).style.border = "1px solid rgb(245, 245, 245) !important";
    //         document.getElementById("rangeButton" + i).style.color = "rgb(0, 136, 204) !important";
    //     }
    //     document.getElementById("rangeButton" + index).style.backgroundColor = "rgb(0, 136, 204) !important";
    //     document.getElementById("rangeButton" + index).style.border = "1px solid rgb(245, 245, 245) !important";
    //     document.getElementById("rangeButton" + index).style.color = "rgb(245, 245, 245) !important";
    //   },50);
  };

  returnRanges = () => {
    let now = new Date();
    let start = moment(now).startOf("day");
    let end = moment(now).endOf("day");

    const ranges = {
      Today: [moment(start), moment(end)],
      Yesterday: [
        moment(start).subtract(1, "days"),
        moment(end).subtract(1, "days"),
      ],
      "Last 24 hours": [moment(now).subtract(1, "days"), moment(now)],
      "Last 48 hours": [moment(now).subtract(2, "days"), moment(now)],
      "Last 3 Days": [moment(start).subtract(2, "days"), moment(end)],
      "Last 7 Days": [moment(start).subtract(6, "days"), moment(end)],
      "This month": [
        moment(start).clone().startOf("month"),
        moment(end).clone().endOf("month"),
      ],
      "Last 30 Days": [moment(start).subtract(29, "days"), moment(end)],
      "Last month": [
        moment(start).subtract(1, "months").startOf("month"),
        moment(start).subtract(1, "months").endOf("month"),
      ],
      "This year": [
        moment(start).clone().startOf("year"),
        moment(start).clone().endOf("year"),
      ],
      "Last year": [
        moment(start).subtract(1, "year").startOf("year"),
        moment(start).subtract(1, "year").endOf("year"),
      ],
    };
    this.setState(
      produce((draft) => {
        draft.ranges = ranges;
      })
    );
  };

  setDatesToState = (startDate, endDate) => {
    this.setState(
      produce((draft) => {
        draft.search.date.startDate = startDate;
        draft.search.date.endDate = endDate;
        draft.search.modifiedDate.startDate =
          startDate.format("MM/DD/YYYY hh:mm A");
        draft.search.modifiedDate.endDate =
          endDate.format("MM/DD/YYYY hh:mm A");
      }),
      () => this.sendSearchRequest()
    );
  };

  applyCallback = (startDate, endDate) => {
    this.setDatesToState(startDate, endDate);
    // setDatesToLocalStorage(startDate, endDate);
  };

  timeZoneChange = (e) => {
    this.setState(
      produce((draft) => {
        draft.search.date.timeZone = e.target.value;
      })
    );

    // set the changed timeZone to localStorage
    let search = JSON.parse(localStorage.getItem("search"));
    search.dates.timeZone = e.target.value;
    search.report.report1.search.date.timeZone = e.target.value;
    search.report.report2.search.date.timeZone = e.target.value;
    search.report.report3.search.date.timeZone = e.target.value;
    localStorage.setItem("search", JSON.stringify(search));

    setTimeout(() => {
      this.addTooltipDate();
    }, 60);
  };

  render() {
    return (
      <Fragment>
        {this.state.loader && <LoadingPanel />}
        <Layout reportCount={this.state.reportCount}>
          <GeneralHeaderMenuBarReport
            activeIcons={this.state.activeIcons}
            menuButtonsActive={this.state.menuButtonsActive}
            menuButtonClickedMethod={this.menuButtonClickedMethod}
          />
          <div id="page_container" className="page_container">
            <div id="page_menu" className="page_menu_bar">
              <ReportMenuBarTop
                sendSearchRequest={this.sendSearchRequest}
                showCharts={this.showCharts}
                showCalendar={this.state.showCalendar}
                toggleCalendarWindow={this.toggleCalendarWindow}
                search={this.state.search}
                handleDateChange={this.handleDateChange}
                changeSearchValues={this.changeSearchValues}
                onSearchBtnClick={this.onSearchBtnClick}
                fullGroupingList={this.state.fullGroupingList}
                disable={this.state.disable}
                ranges={this.state.ranges}
                applyCallback={this.applyCallback}
                rangeCallback={this.rangeCallback}
                timeZoneChange={this.timeZoneChange}
                defaultTimeZone={this.state.defaultTimeZone}
                timeZonesList={this.state.timeZonesList}
              />
              {this.state.charts.showCharts ? (
                <ResponsiveMenuCharts
                  data={this.state.charts.chartsData}
                  dates={this.state.search.modifiedDate}
                />
              ) : null}
              <PageMenuBarBottom
                selectedListReceivedFromGrid={
                  this.state.selectedListReceivedFromGrid
                }
                toggleColumnsModal={this.toggleColumnsModal}
                pagination={this.state.pagination}
                changePagination={this.changePagination}
                changeGridRowsNumber={this.changeGridRowsNumber}
              />
              <div className="page_content"></div>
            </div>

            <div
              style={{ height: this.state.pageContentHeight }}
              className="temporary_affiliate_network_page_data"
            >
              <GridReportComponent
                pageType={this.state.appliedGroupingValues.filter2}
                gridHeight={this.state.pageContentHeight}
                allDataLoaded={this.state.allLoadedReportData}
                gridCheckedList={(list) => this.gridCheckedList(list)}
                showColumnsModal={this.state.showColumnsModal}
                toggleColumnsModal={this.toggleColumnsModal}
                sorting={this.state.search.sorting}
                changeSorting={this.changeSorting}
                search={this.state.search}
                toggleLoader={this.toggleLoader}
                appliedGroupingValues={this.state.appliedGroupingValues}
              />
            </div>
            {this.state.backendErrors.error && (
              <NotificationGroup
                style={{
                  alignItems: "flex-start",
                  flexWrap: "wrap-reverse",
                  left: "50%",
                  zIndex: 10000000,
                  top: "20px",
                  transform: "translateX(-50%)",
                }}
              >
                <Fade enter={true} exit={true}>
                  {this.state.backendErrors.error && (
                    <Notification
                      type={{ style: "error", icon: true }}
                      closable={true}
                      // onClose={() => this.setState({ backendErrors.error: false })}
                      onClose={() =>
                        this.setState(
                          produce((draft) => {
                            draft.backendErrors.error = false;
                          })
                        )
                      }
                    >
                      <span>{this.state.backendErrors.errorMsg}</span>
                    </Notification>
                  )}
                </Fade>
              </NotificationGroup>
            )}
          </div>
        </Layout>
      </Fragment>
    );
  }
}

export const mapStateToProps = createStructuredSelector({
  accessToken: makeSelectToken(),
  allLoadedReportData: makeSelectAllLoadedReportData(),
  isChartsDataLoaded: makeSelectIsChartsDataLoaded(),
  chartsAllDataLoaded: makeSelectAllChartsDataLoaded(),
  Error: makeSelectError(),
});
export function mapDispatchToProps(dispatch) {
  return {
    dispatchLoadReportData: (...payload) =>
      dispatch(loadAllReportData(...payload)),
    dispatchLoadChartsData: (...payload) =>
      dispatch(loadChartsData(...payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Report);
function ResponsiveMenuCharts(props) {
  const [dimensions, setDimensions] = React.useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  })
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    
}

    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)
    
}
  })
  return <>{//<div>{window.innerWidth}</div>
  }
  <MenuCharts data={props.data} dates={props.dates}/>
  </>

}