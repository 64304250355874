import React from "react";
import { Input } from "@progress/kendo-react-inputs";
import { RadioButton } from "@progress/kendo-react-inputs";
import { makeSelectWorkspaceData } from "../../../../../Settings/CollaborationTools/selectors";
import { makeSelectCountriesData } from "../../../selectors";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { withRouter } from "react-router";
import * as colloborationWorkspaceActions from "../../../../../Settings/CollaborationTools/actions";
import * as newFlowsActions from "../../../actions";
import { ComboBox } from "@progress/kendo-react-dropdowns";
//import FlowCollapse from "../../../collapse/flowCollapse";
import Tooltip from "../../../tooltip/tooltip";
import { Error } from "@progress/kendo-react-labels";

const RightFLow = (props) => {
    const onChangeFlowName = (e) => {
        // props.inputChangeHandler(e);
        props.nameChangeHandler(e);
    };

    return (
        <div className="new-right-flow">
            <label className="flow-name-label">Flow name</label>
            <div className="flow-partials">
                <div className="flow-partials country">
                    <div className="select-input">
                        <div className="country-select">
                            <div className="row">
                                <span className="country-tag">Country tag</span>
                                {/* country tooltip */}
                                <Tooltip
                                    datafor={"country-tag"}
                                    text={
                                        "It has only an organizational purpose to group flows by its Country label."
                                    }
                                ></Tooltip>
                            </div>
                            <div className="flow-workspace">
                                <select
                                    className="select-country"
                                    onChange={props.inputChangeHandler}
                                    id="country"
                                    name="countryId"
                                    value={props.state.dataToSend.countryId}
                                >
                                    {props.countriesDataRecieved
                                        ? props.countriesDataRecieved.map((country, index) => (
                                            <option key={index} value={country.id}>
                                                {country.country_name}
                                            </option>
                                        ))
                                        : null}
                                </select>
                                <span className="k-icon k-i-arrows-kpi arrow-span-icon" />
                            </div>
                        </div>

                        <div className="unique-name-input">
                            <span className="country-tag">Name</span>
                            <Input
                                name="flow_name"
                                placeholder="Type unique name"
                                required
                                id={"flow_name"}
                                value={props.state.dataToSend.flow_name}
                                onChange={onChangeFlowName}
                                ariaDescribedBy={"name"}
                            ></Input>
                            {props.state.errors.name !== "" && (
                                <Error id={"name"}>{props.state.errors.name}</Error>
                            )}
                        </div>
                    </div>
                    {/* <hr></hr> */}
                    <div className="row">
                        <span className="preview">Preview &nbsp;</span>
                        <span className="entity-name">
                            {props.state.countryName} - {props.state.dataToSend.flow_name}
                        </span>
                    </div>
                    <hr></hr>
                    <div className="wokspace">
                        <div className="row">
                            <span className="title">Workspace</span>
                            {/* works</Tooltip>pace tooltip */}
                            <Tooltip
                                datafor={"workspace-tool"}
                                text={
                                    "Workspace is an isolated area with a set of entities where users with different roles can work on. Assign a workspace to make the flow accessible only by the workspace members."
                                }
                            ></Tooltip>
                        </div>
                        <div className="flow-workspace">
                            {/* workspace select */}
                            <select
                                id="workspace"
                                name="workspaceId"
                                className="select-workspace"
                                onChange={props.inputChangeHandler}
                                value={props.state.dataToSend.workspaceId}
                            >
                                {props.workspaceDataReceived
                                    ? props.workspaceDataReceived.map((workspace) => (
                                        <option key={workspace.id} value={workspace.id}>
                                            {workspace.name}
                                            {/* {props.workspaceDataReceived.find(x => x.id === props.editFlowData.workspaceId).name} */}
                                        </option>
                                    ))
                                    : null}
                            </select>
                            <span className="k-icon k-i-arrows-kpi arrow-span-icon" />
                        </div>
                    </div>
                    <hr></hr>
                    <div className="default-offer-redirect">
                        <div className="row">
                            <label>Default redirect mode</label>
                            <Tooltip
                                datafor="default-redirect-mode"
                                text={
                                    "It determines how redirection to the campaign’s endpoint is handled and whether referrer data is passed through or not. Learn more"
                                }
                            ></Tooltip>
                        </div>
                        <div className="row path-offer-redirect radio-buttons">
                            <RadioButton
                                className="buttons"
                                label="302"
                                name="flow_redirect_mode"
                                value="302"
                                checked={props.state.dataToSend.flow_redirect_mode === "302"}
                                onChange={props.handleSwitchChange}
                            />
                            <RadioButton
                                className="buttons"
                                label="Meta Refresh"
                                name="flow_redirect_mode"
                                value="Meta Refresh"
                                checked={
                                    props.state.dataToSend.flow_redirect_mode === "Meta Refresh"
                                }
                                onChange={props.handleSwitchChange}
                            />
                            <RadioButton
                                className="buttons"
                                label="Double Meta Refresh"
                                name="flow_redirect_mode"
                                value="Double Meta Refresh"
                                checked={
                                    props.state.dataToSend.flow_redirect_mode ===
                                    "Double Meta Refresh"
                                }
                                onChange={props.handleSwitchChange}
                            />
                            <RadioButton
                                className="buttons"
                                label="Direct"
                                name="flow_redirect_mode"
                                value="Direct"
                                checked={props.state.dataToSend.flow_redirect_mode === "Direct"}
                                onChange={props.handleSwitchChange}
                            />
                        </div>
                    </div>
                    <hr></hr>
                    {/* <FlowCollapse /> */}
                </div>
            </div>
        </div>
    );
};

export const mapStateToProps = createStructuredSelector({
    workspaceDataReceived: makeSelectWorkspaceData(),
    countriesDataRecieved: makeSelectCountriesData(),
});
export function mapDispatchToProps(dispatch) {
    return {
        fetchingData: (...payload) =>
            dispatch(colloborationWorkspaceActions.fetchingData(...payload)),
        loadCountries: (...payload) =>
            dispatch(newFlowsActions.loadCountries(...payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(RightFLow)
);
