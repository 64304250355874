import React, {Component} from "react";
import produce from "immer";
import "./domainsConfiguration.css";
import CustomDomains from "../../../components/settings/domains/domainsConfiguration/customDomains";
// import MainDomain from "../../../../components/settings/domains/domainsConfiguration/mainDomain";
import MainDomain from "../../../components/settings/domains/domainsConfiguration/mainDomain";
// import DedicatedDomain from "../../../../components/settings/domains/domainsConfiguration/DedicatedDomain";
import {
    makeSelectDomainsDataReceived,
    makeSelectAddDomainSuccess,
    makeSelectEditDomainPatchingSuccess,
    makeSelectDeleteDomainSuccess,
    makeSelectIsMainDomainDataSent,
    makeSelectError
} from "./selectors";
import { createStructuredSelector } from "reselect";
import * as domainsActions from "./actions";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import LoadingPanel from "../../grid/loader/loader";
import {Notification, NotificationGroup} from "@progress/kendo-react-notification";
import {Fade} from "@progress/kendo-react-animation";
import DefaultDomain from "../../../components/settings/domains/domainsConfiguration/defaultDomain";

class DomainsConfiguration extends Component {

    state = {
        addCustomDomainDialogueVisible: false,
        editCustomDomainDialogueVisible: false,
        deleteCustomDomainDialogueVisible: false,
        workspace: null,
        addCustomDomainInput: "",
        addCustomDomainInputError: false,
        addCustomDomainInputErrorValue: "",
        editCustomDomainInputError: false,
        editCustomDomainInputErrorValue: "",
        domainsDataReceived: null,
        domainsDataForTable: null,
        domainUnderEditing: {},
        isFetchingFreshDomains: false,
        isDomainAdded: false,
        isDomainDeleted: false,
        isDomainPatched: false,
        mainDomainId: "",
        isMainDomainDataSent: false,
        mainDomainSuccessMsg: null,
        mainDomainErrMsg: null,
        loader: false,
        backendErrors: {
            error: false,
            errorMsg: ""
        }
    }

    fetchDomains = async () => {
        this.props.dispatchLoadDomains(this.props.accessToken);
    };
    sendMainDomain = async () => {
        this.props.dispatchSendMainDomain({
            accessToken: this.props.accessToken,
            domainId: this.state.mainDomainId
        });
    }

    setDomainsInState = () => {
        let tableData = [];
        for (let i=0; i<this.props.domainsDataReceived.domain.length; i++) {
            let domain_obj = {};
            let workspaceName = "";
            if (this.props.domainsDataReceived.domain[i].selected === true) {
                this.setState(produce(draft => { draft.mainDomainId = this.props.domainsDataReceived.domain[i].id }));
            }
            for (let j=0; j<this.props.domainsDataReceived.workspace.length; j++) {
                if (this.props.domainsDataReceived.domain[i].workspaceId === this.props.domainsDataReceived.workspace[j].id) {
                    workspaceName = this.props.domainsDataReceived.workspace[j].name;
                }
            }
            tableData.push({
                name: this.props.domainsDataReceived.domain[i].name,
                id: this.props.domainsDataReceived.domain[i].id,
                verified: this.props.domainsDataReceived.domain[i].verified,
                workspaceName: workspaceName,
                userId: this.props.domainsDataReceived.domain[i].userId
            });
        }
        this.setState(produce(draft => {
            draft.domainsDataForTable = tableData;
            draft.domainsDataReceived = this.props.domainsDataReceived;
            draft.workspace = this.props.domainsDataReceived.workspace[0].id;
        }));
    }

    componentDidMount() {
        this.toggleLoader();
        this.fetchDomains();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // set domains initially to the state
        if (((prevProps.domainsDataReceived !== this.props.domainsDataReceived) || (prevProps.domainsDataReceived === this.props.domainsDataReceived)) && this.state.domainsDataReceived === null && this.props.domainsDataReceived !== null && this.props.domainsDataReceived.domain.length > 0) {
            this.toggleLoader();
            this.setDomainsInState();
        }
        // check if domain added then re-load the domains list
        if ((prevProps.isDomainAdded !== this.props.isDomainAdded) && this.props.isDomainAdded === true && this.state.isDomainAdded === true) {
            this.toggleAddCustomDomainDialog();
            this.fetchDomains();
            this.setState(produce(draft => {
                // make isDomainAdded to false to avoid infinite loop
                draft.isDomainAdded = false;
                // then set isFetchingFreshDomains to true to to run the reload loop below
                draft.isFetchingFreshDomains = true;
                draft.loader = false;
                draft.addCustomDomainInput = "";
            }));
        }

        // check if domain edited successfully then re-load the domains list
        if ((prevProps.isEditDomainPatched !== this.props.isEditDomainPatched) && this.props.isEditDomainPatched === true && this.state.isDomainPatched === true) {
            this.closeEditCustomDomainDialog();
            this.fetchDomains();
            this.setState(produce(draft => {
                draft.loader = false;
                // make isDomainPatched to false to avoid infinite loop
                draft.isDomainPatched = false;
                // then set isFetchingFreshDomains to true to to run the reload loop below
                draft.isFetchingFreshDomains = true;
            }));
        }

        // check if domain deleted successfully then re-load the domains list
        if ((prevProps.isDomainDeleted !== this.props.isDomainDeleted) && this.props.isDomainDeleted === true && this.state.isDomainDeleted === true) {
            this.closeDeleteCustomDomainDialog();
            this.fetchDomains();
            this.setState(produce(draft => {
                draft.loader = false;
                // make isDomainDeleted to false to avoid infinite loop
                draft.isDomainDeleted = false;
                // then set isFetchingFreshDomains to true to to run the reload loop below
                draft.isFetchingFreshDomains = true;
            }));
        }

        // check if there is data in this.props.domainsDataReceived and state isFetchingDomains set to true...
        // then re-load the domains data to the state
        if (this.props.domainsDataReceived !== null && this.props.domainsDataReceived.domain.length > 0 && this.state.isFetchingFreshDomains === true) {
            this.setDomainsInState();
            // make isFetchingDomains to false to avoid infinite loop
            this.setState(produce(draft => { draft.isFetchingFreshDomains = false }));
        }

        // check if main domain sent to the server successfully
        if (this.props.isMainDomainDataSent && this.state.isMainDomainDataSent) {
            this.setState(produce(draft => { draft.mainDomainSuccessMsg = "Main domain selected successfully." }));
            this.setState(produce(draft => { draft.isMainDomainDataSent = false }));
            setTimeout(() => {
                this.setState(produce(draft => { draft.mainDomainSuccessMsg = null }));
                },5000);
        }

        if ((prevProps.error !== this.props.error) && this.props.error !== false) {
            if (this.props.error && this.props.error.data) {
                this.setState(produce(draft => {
                    draft.backendErrors.errorMsg = (
                        (this.props.error.data.message ? this.props.error.data.message : null)
                        + ", " +
                        (this.props.error.data.error ? this.props.error.data.error : null)
                    );
                    draft.backendErrors.error = true;
                    draft.loader = false;
                }));
            }
        }
    }

    toggleAddCustomDomainDialog = () => {
        this.setState(produce(
            draft => {
                draft.addCustomDomainDialogueVisible = !draft.addCustomDomainDialogueVisible;
            }
        ));
    };

    openEditCustomDomainDialog = (e) => {
        let selectedDomain;
        for (let i=0; i<this.state.domainsDataReceived.domain.length;i++) {
            if (Number(this.state.domainsDataReceived.domain[i].id) === Number(e.target.id)){
                selectedDomain = {...this.props.domainsDataReceived.domain[i]};
            }
        }
        this.setState(produce(
            draft => {
                draft.domainUnderEditing = selectedDomain;
                draft.editCustomDomainDialogueVisible = !draft.editCustomDomainDialogueVisible;
            }
        ));
    }
    openDeleteCustomDomainDialog = (e) => {
        for (let j=0; j<this.props.domainsDataReceived.domain.length;j++) {
            if (Number(this.props.domainsDataReceived.domain[j].id) === Number(e.target.id)){
                this.setState(produce(draft => { draft.domainUnderEditing = this.props.domainsDataReceived.domain[j] }));
            }
        }
        this.setState(produce(
            draft => {
                draft.deleteCustomDomainDialogueVisible = !draft.deleteCustomDomainDialogueVisible;
            }
        ));
    }
    closeEditCustomDomainDialog = () => {
        this.setState(produce(
            draft => {
                draft.editCustomDomainDialogueVisible = !draft.editCustomDomainDialogueVisible;
            }
        ));
    }

    closeDeleteCustomDomainDialog= () => {
        this.setState(produce(
            draft => {
                draft.deleteCustomDomainDialogueVisible = !draft.deleteCustomDomainDialogueVisible;
            }
        ));
    }

    CheckIsValidDomain = (domain) => {
        const re = new RegExp(/[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/);
        return domain.match(re);
    }

    handleAddCustomDomainFormSubmit = () => {
        this.setState(produce(draft => {
            draft.loader = true;
            // set state isDomainAdded to true to reload the domains in component did update
            draft.isDomainAdded = true;
        }));
        if (this.state.addCustomDomainInput === "") {
            this.setState(produce( draft => {
                draft.addCustomDomainInputError = true;
                draft.addCustomDomainInputErrorValue = "Please enter a domain name...";
                draft.loader = false;
            }));
        }
        if (this.CheckIsValidDomain(this.state.addCustomDomainInput) === null) {
            this.setState(produce(draft => {
                draft.addCustomDomainInputError = true;
                draft.addCustomDomainInputErrorValue = "Please enter a valid domain name...";
                draft.loader = false;
            }))
        } else {
            this.props.dispatchAddDomain({
                'name': this.state.addCustomDomainInput,
                'workspaceId': String(this.state.workspace),
                'accessToken': this.props.accessToken
            });
        }
    }

    handleEditCustomDomainFormSubmit = () => {
        this.setState(produce(draft => { draft.loader = true; }));
        if (this.state.domainUnderEditing.name === "") {
            this.setState({
                loader: false,
                editCustomDomainInputError: true,
                editCustomDomainInputErrorValue: "Please enter a domain name..."
            });
            // this.setState({});
        }
        else if (this.CheckIsValidDomain(this.state.domainUnderEditing.name) == null) {
            this.setState({
                loader: false,
                editCustomDomainInputError: true,
                editCustomDomainInputErrorValue: "Please enter a valid domain name..."
            });
            this.setState({});
        } else {
            this.props.dispatchUpdateDomain({
                'name': this.state.domainUnderEditing.name,
                'workspaceId': String(this.state.domainUnderEditing.workspaceId),
                'accessToken': this.props.accessToken,
                "domainId": this.state.domainUnderEditing.id
            });
            // set state isDomainPatched to true to reload the domains in component did update
            this.setState(produce(draft => { draft.isDomainPatched = true }));
        }

    }

    handleDeleteCustomDomainFormSubmit = () => {
        this.setState(produce(draft => {
            draft.loader = true;
            // set state isDomainDeleted to true to reload the domains in component did update
            draft.isDomainDeleted = true;
        }));
        this.props.dispatchDeleteCustomDomain({
            'accessToken': this.props.accessToken,
            "domainId": this.state.domainUnderEditing.id
        });
    }

    sendMainDomainHandler = () => {
        if (this.state.mainDomainId === "") {
            this.setState(produce(draft => { draft.mainDomainErrMsg = "Please select a domain to make it the main domain." }));
        } else {
            this.setState(produce(draft => { draft.mainDomainErrMsg = null }));
            this.sendMainDomain();
            this.setState(produce(draft => { draft.isMainDomainDataSent = true }));
        }
    }
    simpleInputChangeHandler = (e) => {
        if (e.target.name === "mainDomainId") {
            this.setState(produce(draft => { draft.mainDomainId = e.target.value }));
        }
    }

    toggleLoader = () => {
        this.setState(produce(draft => { draft.loader = !this.state.loader; }));
    }

    render () {
        const handleAddCustomDomainInputChange = (e) => {
            this.setState({
                ...this.state,
                [e.target.id]: e.target.value
            })
        };
        const handleAddCustomDomainDropChange = (e) => {
            this.setState({
                ...this.state,
                workspace: e.target.value
            });
        };
        const handleEditCustomDomainInputChange = (e) => {
            this.setState(produce(draft => {
                draft.domainUnderEditing.name = e.target.value;
            }));
        };
        const handleCustomDomainEditDropChange = (e) => {
            this.setState(produce(draft => {
                draft.domainUnderEditing.workspaceId = e.target.value;
            }));
        };
        return (
            <div className="white_box_with_header_con white_bg">
                {this.state.loader && <LoadingPanel />}
                <header className="header light_gray_border_color">
                    <div className="title_con">
                        <h2 className="h2 gray_h2 inline_block">DOMAIN CONFIGURATION</h2>
                    </div>
                    {/*<Link to={"#"} className="normal_text">*/}
                    {/*    <Button*/}
                    {/*        icon="k-i-help k-i-question"*/}
                    {/*        className="normal_text bg_transparent_imp no_border theme_blue_color_imp learn_more_btn"*/}
                    {/*    >Learn More</Button>*/}
                    {/*</Link>*/}
                </header>
                <div className="box">
                    {/*<DedicatedDomain />*/}
                    <DefaultDomain 
                        domainsDataForTable={this.state.domainsDataForTable}
                    />
                    <CustomDomains
                        domainsDataForTable={this.state.domainsDataForTable}
                        handleAddCustomDomainFormSubmit={this.handleAddCustomDomainFormSubmit}
                        toggleAddCustomDomainDialog={this.toggleAddCustomDomainDialog}
                        handleAddCustomDomainInputChange={handleAddCustomDomainInputChange}
                        state={this.state}
                        handleCustomDomainDropChange={handleAddCustomDomainDropChange}
                        addCustomDomainInputError={this.state.addCustomDomainInputError}
                        addCustomDomainInputErrorValue={this.state.addCustomDomainInputErrorValue}
                        openEditCustomDomainDialog={(e) => this.openEditCustomDomainDialog(e)}
                        closeEditCustomDomainDialog={this.closeEditCustomDomainDialog}
                        handleEditCustomDomainFormSubmit={this.handleEditCustomDomainFormSubmit}
                        handleEditCustomDomainInputChange={handleEditCustomDomainInputChange}
                        handleCustomDomainEditDropChange={handleCustomDomainEditDropChange}
                        closeDeleteCustomDomainDialog={this.closeDeleteCustomDomainDialog}
                        openDeleteCustomDomainDialog={(e) => this.openDeleteCustomDomainDialog(e)}
                        handleDeleteCustomDomainFormSubmit={this.handleDeleteCustomDomainFormSubmit}
                    />
                    <hr style={{ margin: "0px" }} />
                    <MainDomain
                        state={this.state}
                        sendMainDomainHandler={this.sendMainDomainHandler}
                        simpleInputChangeHandler={this.simpleInputChangeHandler}
                    />
                </div>
                {this.state.backendErrors.error &&
                <NotificationGroup
                    style={{
                        alignItems: 'flex-start',
                        flexWrap: 'wrap-reverse',
                        left: "50%",
                        zIndex: 10000000,
                        top: "20px",
                        transform: "translateX(-50%)",
                    }}
                >
                    <Fade enter={true} exit={true}>
                        {this.state.backendErrors.error && <Notification
                            type={{ style: 'error', icon: true }}
                            closable={true}
                            // onClose={() => this.setState({ backendErrors.error: false })}
                            onClose={() => this.setState(produce(draft => { draft.backendErrors.error = false; }))}
                        >
                            <span>{this.state.backendErrors.errorMsg}</span>
                        </Notification>}
                    </Fade>

                </NotificationGroup>
                }
            </div>
        );
    }
};

export const mapStateToProps = () => createStructuredSelector({
    domainsDataReceived: makeSelectDomainsDataReceived(),
    isDomainAdded: makeSelectAddDomainSuccess(),
    isEditDomainPatched: makeSelectEditDomainPatchingSuccess(),
    isDomainDeleted:makeSelectDeleteDomainSuccess(),
    isMainDomainDataSent: makeSelectIsMainDomainDataSent(),
    error: makeSelectError()
});

export function mapDispatchToProps(dispatch) {
    return {
        dispatchLoadDomains: (...payload) => dispatch(domainsActions.loadCustomDomains(...payload)),
        dispatchSendMainDomain: (...payload) => dispatch(domainsActions.sendMainDomain(...payload)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DomainsConfiguration));