import { call, put, takeLatest } from "redux-saga/effects";
import {
    LOAD_OFFER_TRACKING_URLS,
    LOAD_OFFER_CURRENCY,
    POST_NEW_OFFER_DATA,
    LOAD_OFFERS_DATA,
    EDIT_OFFER_DATA,
    EDIT_OFFER_DATA_SENT,
    DELETE_OFFER_DATA,
    LOAD_TIMEZONE,
    ARCHIVE_OFFER_DATA,
    GET_OFFERS_CHARTS_DATA
} from "./constants";
import {
    loadTrackingURLSSuccess,
    loadTrackingURLSFailed,
    loadCurrencySuccess,
    loadCurrencyFailed,
    postNewOfferSuccess,
    postNewOfferFailed,
    loadOffersSuccess,
    loadOffersFailed,
    editOfferSuccess,
    editOfferFailed,
    sendEditOfferSuccess,
    sendEditOfferFailed,
    deleteOfferSuccess,
    deleteOfferFailed,
    archiveOfferSuccess,
    archiveOfferFailed,
    loadTimezoneSuccess,
    loadTimezoneFailed,
    loadOffersChartsDataSuccess,
    loadOffersChartsDataFailed
} from "./actions";

import {
    LoadTrackingUrlsFromAPI,
    LoadCurrenciesListFromAPI,
    postNewOfferDataFromAPI,
    loadOffersDataFromAPI,
    editOfferDataFromAPI,
    sendEditOfferDataFromAPI,
    deleteOfferDataFromAPI,
    loadTimezoneDataFromAPI,
    ArchiveOfferDataFromAPI,
    LoadOffersChartsDataFromAPI
} from "./api";

function* loadTrackingUrls(payload) {
    try {
        const response = yield call(LoadTrackingUrlsFromAPI, payload);
        yield put(
            loadTrackingURLSSuccess(response.data)
        );
    } catch (error) {
        yield put(loadTrackingURLSFailed(error));
    }
}

function* loadCurrenciesList(payload) {
    try {
        const response = yield call(LoadCurrenciesListFromAPI, payload);
        yield put(
            loadCurrencySuccess(response.data)
        );
    } catch (error) {
        yield put(loadCurrencyFailed(error));
    }
}

function* postNewOfferData(payload) {
    try {
        const response = yield call(postNewOfferDataFromAPI, payload);
        yield put(
            postNewOfferSuccess(response.data)
        );
    } catch (error) {
        yield put(postNewOfferFailed(error));
    }
}

function* loadOffersData(payload) {
    try {
        const response = yield call(loadOffersDataFromAPI, payload);
        yield put(
            loadOffersSuccess(response.data)
        );
    } catch (error) {
        yield put(loadOffersFailed(error));
    }
}


function* editOfferData(payload) {
    try {
        const response = yield call(editOfferDataFromAPI, payload);
        yield put(
            editOfferSuccess(response.data)
        );
    } catch (error) {
        yield put(editOfferFailed(error));
    }
}

function* sendEditOfferData(payload) {
    
    try {
        const response = yield call(sendEditOfferDataFromAPI, payload);
        yield put(
            sendEditOfferSuccess(response.data)
        );
    } catch (error) {
        yield put(sendEditOfferFailed(error));
    }
}

function* deleteOfferData(payload) {
    try {
        const response = yield call(deleteOfferDataFromAPI, payload);
        yield put(
            deleteOfferSuccess(response.data)
        );
    } catch (error) {
        yield put(deleteOfferFailed(error));
    }
}

function* archiveOfferData(payload) {
    try {
        const response = yield call(ArchiveOfferDataFromAPI, payload);
        yield put(
            archiveOfferSuccess(response.data)
        );
    } catch (error) {
        yield put(archiveOfferFailed(error));
    }
}

function* loadTimezone(payload) {
    try {
        const response = yield call(loadTimezoneDataFromAPI, payload);
        
        yield put(
            loadTimezoneSuccess(response.data)
        );
    } catch (error) {
        yield put(loadTimezoneFailed(error));
    }
}

function* loadOffersChartsData(payload) {
    try {
        const response = yield call(LoadOffersChartsDataFromAPI, payload);
        yield put(
            loadOffersChartsDataSuccess(response.data)
        );
    } catch (error) {
        yield put(loadOffersChartsDataFailed(error));
    }
}



export default function* offersSaga() {
    yield takeLatest(LOAD_OFFER_TRACKING_URLS, loadTrackingUrls);
    yield takeLatest(LOAD_OFFER_CURRENCY, loadCurrenciesList);
    yield takeLatest(POST_NEW_OFFER_DATA, postNewOfferData);
    yield takeLatest(LOAD_OFFERS_DATA, loadOffersData);
    yield takeLatest(EDIT_OFFER_DATA, editOfferData);
    yield takeLatest(EDIT_OFFER_DATA_SENT, sendEditOfferData);
    yield takeLatest(DELETE_OFFER_DATA, deleteOfferData);
    yield takeLatest(ARCHIVE_OFFER_DATA, archiveOfferData);
    yield takeLatest(LOAD_TIMEZONE, loadTimezone);
    yield takeLatest(GET_OFFERS_CHARTS_DATA, loadOffersChartsData);
}