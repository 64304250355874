import React, {useState} from "react";
import {Dialog} from "@progress/kendo-react-dialogs";
import Cname from "./cname";

const AddCustomDomainDialogue = (props) => {
    const [nextClicked,setNextClicked] = useState(false);
    return (
        props.props.state.addCustomDomainDialogueVisible && <Dialog title={"Add Custom Domain"} className="settings_dialogue custom_domain_dialogue" onClose={props.props.toggleAddCustomDomainDialog}>
            {
                nextClicked ?
                    <div className="settings_add_custom_domains_form">
                        <div>
                            <div className="row cname_info">
                                <p className="normal_text bold black">5. Once you have completed the steps from the previous tab, provide your full tracking subdomain below:</p>
                                <p></p>
                                <label className="block normal_text black medium_font full_width_label">Domain</label>
                                <input className="block add_custom_domain_input" name={'addCustomDomainInput'} id="addCustomDomainInput" onChange={props.props.handleAddCustomDomainInputChange} value={props.props.state.addCustomDomainInput} />
                                {
                                    props.props.addCustomDomainInputError ?
                                        <p className="domain_error">{props.props.addCustomDomainInputErrorValue}</p>
                                        : null
                                }

                            </div>
                            <div className="row cname_info">
                                <p className="normal_text bold black">6. (Optional) Assign your custom domain to the Workspace.</p>
                                <p></p>
                                <label className="block normal_text black medium_font full_width_label">Select Workspace</label>
                                <select
                                    className="add_custom_domain_dropdown normal_text normal_font"
                                    name="workspace"
                                    id="workspace"
                                    value={props.props.state.workspace}
                                    onChange={props.props.handleAddCustomDomainInputChange}
                                >
                                    {
                                        props.props.state.domainsDataReceived.workspace.map((workspace) => (
                                            <option key={workspace.id} value={workspace.id}>{workspace.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    :
                    <Cname />
            }
            <div className="buttons_row custom_domain_dialog_buttons">
                <button className="cancel_button gray_button_bg_imp black border_radius_normal_btn profile_form_btn normal_text black no_border" onClick={props.props.toggleAddCustomDomainDialog}>Cancel</button>
                {
                    nextClicked ?
                        <>
                        <button
                            className="theme_dark_bg_color_imp color_white_imp k-mr-2 border_radius_normal_btn save_button normal_text"
                            onClick={() => setNextClicked(!nextClicked)}
                        >Back</button>
                        <button
                            className="theme_dark_bg_color_imp color_white_imp border_radius_normal_btn save_button normal_text"
                            onClick={props.props.handleAddCustomDomainFormSubmit}
                        >Save</button>
                        </>
                        :
                        <button
                            className="theme_dark_bg_color_imp color_white_imp border_radius_normal_btn save_button normal_text"
                            onClick={() => setNextClicked(!nextClicked)}
                        >Next</button>
                }
            </div>
        </Dialog>
    );
};

export default AddCustomDomainDialogue;