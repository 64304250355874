import { call, put, takeLatest } from "redux-saga/effects";
import {
    LOAD_COUNTRY,
    POST_CAMPAIGN_DATA,
    LOAD_ALL_CAMPAIGNS_DATA,
    LOAD_EDIT_CAMPAIGN_DATA,
    SEND_EDIT_CAMPAIGN_DATA,
    DELETE_CAMPAIGN_DATA,
    LOAD_TOKEN_TAGS,
    LOAD_SEARCH_TAGS,
    ARCHIVE_CAMPAIGN_DATA,
    GET_CHARTS_DATA,
    TIME_ZONES_DATA,
    MANUAL_COST_UPDATE_DATA,
    GET_COST_CURRENCY_DATA
} from "./constants";
import {
    loadCountrySuccess,
    loadCountryFailed,
    postCampaignSuccess,
    postCampaignFailed,
    loadAllCampaignsSuccess,
    loadAllCampaignsFailed,
    loadEditCampaignSuccess,
    loadEditCampaignFailed,
    sendEditCampaignSuccess,
    sendEditCampaignFailed,
    deleteCampaignSuccess,
    deleteCampaignFailed,
    loadTokensDictionarySuccess,
    loadTokensDictionaryFailed,
    loadSearchTagsSuccess,
    loadSearchTagsFailed,
    archiveCampaignSuccess,
    archiveCampaignFailed,
    loadChartsDataSuccess,
    loadChartsDataFailed,
    timeZonesSuccess,
    timeZonesFailed,
    updateManualCostDataSuccess,
    updateManualCostDataFailed,
    getCostCurrencyDataSuccess,
    getCostCurrencyDataFailed
} from "./actions";

import {
    LoadCountriesListFromAPI,
    PostNewCampaignFromAPI,
    LoadAllCampaignsFromAPI,
    LoadEditCampaignFromAPI,
    SendEditCampaignFromAPI,
    DeleteCampaignFromAPI,
    LoadTokensDictionaryFromAPI,
    LoadSearchTagsFromAPI,
    ArchiveCampaignFromAPI,
    LoadChartsDataFromAPI,
    TimeZonesDataFromAPI,
    ManualCostUpdateDataFromAPI,
    LoadManualCostCurrencyDataFromAPI,
} from "./api";

function* loadCountriesList(payload) {
    try {
        const response = yield call(LoadCountriesListFromAPI, payload);
        yield put(
            loadCountrySuccess(response.data)
        );
    } catch (error) {
        yield put(loadCountryFailed(error));
    }
}

function* postNewCampaignData(payload) {
    try {
        const response = yield call(PostNewCampaignFromAPI, payload);
        yield put(
            postCampaignSuccess(response.data)
        );
    } catch (error) {
        yield put(postCampaignFailed(error));
    }
}

function* loadAllCampaignsData(payload) {
    try {
        const response = yield call(LoadAllCampaignsFromAPI, payload);
        yield put(
            loadAllCampaignsSuccess(response.data)
        );
    } catch (error) {
        yield put(loadAllCampaignsFailed(error));
    }
}

function* loadEditCampaignData(payload) {
    try {
        const response = yield call(LoadEditCampaignFromAPI, payload);
        console.log("Rers", response)
        yield put(
            loadEditCampaignSuccess(response.data)
        );
    } catch (error) {
        yield put(loadEditCampaignFailed(error));
    }
}

function* sendEditCampaignData(payload) {
    try {
        const response = yield call(SendEditCampaignFromAPI, payload);
        yield put(
            sendEditCampaignSuccess(response.data)
        );
    } catch (error) {
        yield put(sendEditCampaignFailed(error));
    }
}

function* deleteCampaignData(payload) {
    try {
        const response = yield call(DeleteCampaignFromAPI, payload);
        yield put(
            deleteCampaignSuccess(response.data)
        );
    } catch (error) {
        yield put(deleteCampaignFailed(error));
    }
}

function* archiveCampaignData(payload) {
    try {
        const response = yield call(ArchiveCampaignFromAPI, payload);
        yield put(
            archiveCampaignSuccess(response.data)
        );
    } catch (error) {
        yield put(archiveCampaignFailed(error));
    }
}

function* loadTokenTagsData(payload) {
    try {
        const response = yield call(LoadTokensDictionaryFromAPI, payload);
        yield put(
            loadTokensDictionarySuccess(response.data)
        );
    } catch (error) {
        yield put(loadTokensDictionaryFailed(error));
    }
}

function* loadSearchTagsData(payload) {
    try {
        const response = yield call(LoadSearchTagsFromAPI, payload);
        yield put(
            loadSearchTagsSuccess(response.data)
        );
    } catch (error) {
        yield put(loadSearchTagsFailed(error));
    }
}

function* loadChartsData(payload) {
    try {
        const response = yield call(LoadChartsDataFromAPI, payload);
        yield put(
            loadChartsDataSuccess(response.data)
        );
    } catch (error) {
        yield put(loadChartsDataFailed(error));
    }
}

function* timeZonesData(payload) {
    try {
        const response = yield call(TimeZonesDataFromAPI, payload);
        yield put(
            timeZonesSuccess(response.data)
        );
    } catch (error) {
        yield put(timeZonesFailed(error));
    }
}

function* manualCostUpdateData(payload) {
    try {
        const response = yield call(ManualCostUpdateDataFromAPI, payload);
        yield put(
            updateManualCostDataSuccess(response.data)
        );
    } catch (error) {
        yield put(updateManualCostDataFailed(error));
    }
}

function* manualCostCurrencyData(payload) {
    try {
        const response = yield call(LoadManualCostCurrencyDataFromAPI, payload);
        yield put(
            getCostCurrencyDataSuccess(response.data)
        );
    } catch (error) {
        yield put(getCostCurrencyDataFailed(error));
    }
}

export default function* CampaignsSaga() {
    yield takeLatest(LOAD_COUNTRY, loadCountriesList);
    yield takeLatest(POST_CAMPAIGN_DATA, postNewCampaignData);
    yield takeLatest(LOAD_ALL_CAMPAIGNS_DATA, loadAllCampaignsData);
    yield takeLatest(LOAD_EDIT_CAMPAIGN_DATA, loadEditCampaignData);
    yield takeLatest(SEND_EDIT_CAMPAIGN_DATA, sendEditCampaignData);
    yield takeLatest(DELETE_CAMPAIGN_DATA, deleteCampaignData);
    yield takeLatest(LOAD_TOKEN_TAGS, loadTokenTagsData);
    yield takeLatest(LOAD_SEARCH_TAGS, loadSearchTagsData);
    yield takeLatest(ARCHIVE_CAMPAIGN_DATA, archiveCampaignData);
    yield takeLatest(GET_CHARTS_DATA, loadChartsData);
    yield takeLatest(TIME_ZONES_DATA, timeZonesData);
    yield takeLatest(MANUAL_COST_UPDATE_DATA, manualCostUpdateData);
    yield takeLatest(GET_COST_CURRENCY_DATA, manualCostCurrencyData);
}