import produce from "immer";
import {
    LOAD_COUNTRY, LOAD_COUNTRY_SUCCESS, LOAD_COUNTRY_FAILED,
    POST_LANDER_DATA, POST_LANDER_DATA_SUCCESS, POST_LANDER_DATA_FAILED,
    LOAD_ALL_LANDERS_DATA, LOAD_ALL_LANDERS_DATA_SUCCESS, LOAD_ALL_LANDERS_DATA_FAILED,
    LOAD_EDIT_LANDER_DATA, LOAD_EDIT_LANDER_DATA_SUCCESS, LOAD_EDIT_LANDER_DATA_FAILED,
    EDIT_LANDER_DIALOGUE_CLOSED,
    SEND_EDIT_LANDER_DATA, SEND_EDIT_LANDER_DATA_SUCCESS, SEND_EDIT_LANDER_DATA_FAILED,
    DELETE_LANDER_DATA, DELETE_LANDER_DATA_SUCCESS, DELETE_LANDER_DATA_FAILED,
    LOAD_TOKEN_TAGS, LOAD_TOKEN_TAGS_SUCCESS, LOAD_TOKEN_TAGS_FAILED,
    ARCHIVE_LANDER_DATA, ARCHIVE_LANDER_DATA_SUCCESS, ARCHIVE_LANDER_DATA_FAILED,
    GET_LANDER_CHARTS_DATA, GET_LANDER_CHARTS_DATA_SUCCESS, GET_LANDER_CHARTS_DATA_FAILED
} from "./constants";


export const initialLandersState = {
    loading: false,
    success: false,
    error: false,
    countriesListReceived: null,
    tokenTagsListReceived: null,
    isTokenTagsListReceived: false,
    isNewLanderDataSent: false,
    isAllLandersLoaded: false,
    isPatchedLanderDataSent: false,
    isDeleteLanderDataSent: false,
    isArchiveLanderDataSent: false,
    allLandersDataLoaded: null,
    editLanderDataReceived: {},
    archiveDeleteResponseLanders: null,
    charts: {
        isChartsDataLoaded: false,
        allChartsDataLoaded: null
    }
};

const landersReducer = (state= initialLandersState, action) => produce(state, (draft => {
    switch (action.type) {
        case LOAD_COUNTRY:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case LOAD_COUNTRY_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.countriesListReceived = action.payload;
            return draft;
        case LOAD_COUNTRY_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            return draft;


        case LOAD_TOKEN_TAGS:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case LOAD_TOKEN_TAGS_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.tokenTagsListReceived = action.payload;
            draft.isTokenTagsListReceived = true;
            return draft;
        case LOAD_TOKEN_TAGS_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            return draft;


        case POST_LANDER_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case POST_LANDER_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.allLandersDataLoaded = null;
            draft.editLanderDataReceived = null;
            draft.isNewLanderDataSent = true;
            draft.isAllLandersLoaded = false;
            draft.isPatchedLanderDataSent = false;
            draft.isDeleteLanderDataSent = false;
            return draft;
        case POST_LANDER_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            draft.newPreLanderDataSent = false;
            return draft;


        case LOAD_ALL_LANDERS_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case LOAD_ALL_LANDERS_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.allLandersDataLoaded = action.payload;
            draft.editLanderDataReceived = null;
            draft.isNewLanderDataSent = false;
            draft.isAllLandersLoaded = true;
            draft.isPatchedLanderDataSent = false;
            draft.isDeleteLanderDataSent = false;
            draft.isArchiveLanderDataSent = false;
            return draft;
        case LOAD_ALL_LANDERS_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            draft.allPreLandersLoaded = false;
            return draft;


        case LOAD_EDIT_LANDER_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case LOAD_EDIT_LANDER_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.editLanderDataReceived = action.payload;
            // draft.allLandersDataLoaded = null;
            draft.isNewLanderDataSent = false;
            draft.isAllLandersLoaded = false;
            draft.isPatchedLanderDataSent = false;
            draft.isDeleteLanderDataSent = false;
            draft.isArchiveLanderDataSent = false;
            return draft;
        case LOAD_EDIT_LANDER_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            return draft;

        case EDIT_LANDER_DIALOGUE_CLOSED:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.editLanderDataReceived = null;
            // draft.allLandersDataLoaded = null;
            draft.isNewLanderDataSent = false;
            draft.isAllLandersLoaded = false;
            draft.isPatchedLanderDataSent = false;
            draft.isDeleteLanderDataSent = false;
            draft.isArchiveLanderDataSent = false;
            return draft;


        case SEND_EDIT_LANDER_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case SEND_EDIT_LANDER_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.allLandersDataLoaded = null;
            draft.isNewLanderDataSent = false;
            draft.isAllLandersLoaded = false;
            draft.isPatchedLanderDataSent = true;
            draft.isDeleteLanderDataSent = false;
            draft.isArchiveLanderDataSent = false;
            return draft;
        case SEND_EDIT_LANDER_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            return draft;


        case DELETE_LANDER_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.archiveDeleteResponseLanders = null;
            return draft;
        case DELETE_LANDER_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.allLandersDataLoaded = null;
            draft.editLanderDataReceived = null;
            draft.isNewLanderDataSent = false;
            draft.isAllLandersLoaded = false;
            draft.isPatchedLanderDataSent = false;
            draft.isDeleteLanderDataSent = true;
            draft.isArchiveLanderDataSent = false;
            draft.archiveDeleteResponseLanders = action.payload;
            return draft;
        case DELETE_LANDER_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            draft.archiveDeleteResponseLanders = null;
            return draft;

        case ARCHIVE_LANDER_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.archiveDeleteResponseLanders = null;
            return draft;
        case ARCHIVE_LANDER_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.allLandersDataLoaded = null;
            draft.editLanderDataReceived = null;
            draft.isNewLanderDataSent = false;
            draft.isAllLandersLoaded = false;
            draft.isPatchedLanderDataSent = false;
            draft.isDeleteLanderDataSent = false;
            draft.isArchiveLanderDataSent = true;
            draft.archiveDeleteResponseLanders = action.payload;
            return draft;
        case ARCHIVE_LANDER_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            draft.archiveDeleteResponseLanders = null;
            return draft;
        
        case GET_LANDER_CHARTS_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case GET_LANDER_CHARTS_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.charts.isChartsDataLoaded = true;
            draft.charts.allChartsDataLoaded = action.payload;
            return draft;
        case GET_LANDER_CHARTS_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.charts.isChartsDataLoaded = false;
            draft.error = action.payload.response;
            return draft;

        default:
            return draft;
    }
}));

export default landersReducer;