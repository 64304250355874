import React from "react";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import "../../pages.css";
import "./delete.css";
import ReactTooltip from "react-tooltip";
import {formatDate, setInitialDates} from "../../../../components/searchParameters";
import produce from "immer";
import MenuCalendar from "../../../../components/header/menuCalendar";
import {
    makeSelectTimeZonesDataLoaded,
    makeSelectError,
    makeSelectManualCostUpdateSuccess,
    makeSelectManualCostCurrencyList,
} from "../selectors";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {timeZones, updateManualCostData, getCostCurrencyData} from "../actions";
import {makeSelectToken} from "../../../../registration/LoginRegister/selectors";
import {RadioButton} from "@progress/kendo-react-inputs";
import {Fade} from "@progress/kendo-react-animation";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


class UpdateCostModal extends React.Component {
    state = {
        type: "",
        date: {
            startDate: new Date(),
            endDate: new Date()
        },
        time: {
            startTime: "00:00",
            endTime: "23:30"
        },
        modifiedDate: {
            startDate: formatDate(new Date()),
            endDate: formatDate(new Date())
        },
        timezoneId: "",
        timeZonesDataLoaded: [],
        currenciesListReceived: [],
        cost: "Update",
        costValue: 0,
        currencyId: "",
        showCalendar: false,
        error: {
            costValue: null,
            backend: null,
            general: null,
        }
    }

    componentDidMount() {
        // get timeZones list
        this.props.dispatchLoadTimeZones({
            accessToken: this.props.accessToken
        });
        // get the currencies list
        this.props.dispatchLoadManualCostCurrency({
            accessToken: this.props.accessToken
        });

        let search = JSON.parse(localStorage.getItem("search"));
        this.setState(produce(draft => {
            draft.date = {
                startDate: new Date(search.dates.startDate),
                endDate: new Date(search.dates.endDate),
                key: 'selection'
            };
            draft.modifiedDate = search.modifiedDate;
        }));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.timeZonesDataLoaded !== this.props.timeZonesDataLoaded) && this.props.timeZonesDataLoaded !== null) {
            this.setState(produce(draft => { draft.timezoneId = this.props.timeZonesDataLoaded[9].id; }));
            this.props.toggleLoader();
        }
        if ((prevProps.manualCostCurrencyListLoaded !== this.props.manualCostCurrencyListLoaded) && this.props.manualCostCurrencyListLoaded !== null) {
            this.setState(produce(draft => {
                draft.manualCostCurrencyListLoaded = this.props.manualCostCurrencyListLoaded;
                draft.currencyId = this.props.manualCostCurrencyListLoaded[0].id;
            }));
        }
        // set the backend error in case  of failed request.
        if ((prevProps.backendError !== this.props.backendError) && this.props.backendError !== false) {
            console.log(this.props.backendError);
            this.setState(produce(draft => { draft.error.backend = this.props.backendError.data.message; }));
        }
        // reset the backend error in case of success
        if ((prevProps.backendError !== this.props.backendError) && this.props.backendError === false) {
            this.setState(produce(draft => { draft.error.backend = null; }));
        }

        // close modal window if data submission is successful
        if ((prevProps.submissionSuccess !== this.props.submissionSuccess) && this.props.submissionSuccess !== false) {
            this.props.closeUpdateCostModal("true");
        }
    }


    handleDateChange = (data, type) => {
        if (type === "startDate") {
            this.setState(produce(draft => {
                draft.date.startDate = data;
            }));
        }
        if (type === "endDate") {
            this.setState(produce(draft => {
                draft.date.endDate = data;
            }));
        }
        setTimeout(() => { this.setDateFormat(); },40);
    }

    handleTimeChange = (e,type) => {
        if (type === "start") {
            this.setState(produce(draft => { draft.time.startTime = e.target.value; }));
        } else { this.setState(produce(draft => { draft.time.endTime = e.target.value; })); }
        setTimeout(() => { this.setDateFormat(); },40);
    }

    toggleCalendarWindow = () => {
        this.setState(produce(draft => {
            draft.showCalendar = !this.state.showCalendar;
        }));
    }

    inputChangeHandler= (e) => {
        this.setState(produce(draft => {
            draft[e.target.name] = e.target.value;
        }));
    }

    // For kendo radio buttons
    simpleRadioChangeHandler = (e) => {
        this.setState(produce(draft => { draft[e.target.element.name] = e.value }));
        if (e.value === "Restore") {
            this.setState(produce(draft => {
                draft.costValue = 0;
                draft.error.costValue = null;
                if (this.state.error.backend === null) {
                    draft.error.general = null;
                }
            }));
        }
    }

    costValueChangeHandler = (e) => {
        if (e.target.id === "plus") { this.setState(produce(draft => { draft.costValue += 1; })); }
        else if (e.target.id === "minus" && this.state.costValue >= 1) { this.setState(produce(draft => { draft.costValue -= 1; })); }
    }

    costValueInputChangeHandler = (e) => {
        let value = e.target.value;
        if ((Number(value) < 0) || (Number(value) > 10000000)) {
            this.setState(produce(draft => {
                draft.costValue = value;
                draft.error.costValue = "Cost value must be in range from 0 to 10000000.";
            }));
        } else {
            this.setState(produce(draft => {
                draft.error.costValue = null;
                draft.costValue = value;
            }));
        }
    }

    setDateFormat = () => {
        let startTime = this.state.time.startTime;
        let endTime = this.state.time.endTime;
        let st, et;
        if (Number(startTime.split(":")[0]) < 12) {
            st = startTime + " AM";
        } else {
            if ((Number(startTime.split(":")[0]) - 12) < 10) {
                st = "0" + (Number(startTime.split(":")[0]) - 12) + ":" + startTime.split(":")[1] + " PM";
            } else {
                st = (Number(startTime.split(":")[0]) - 12) + ":" + startTime.split(":")[1] + " PM";
            }
        }
        if (Number(endTime.split(":")[0]) < 12) {
            et = endTime + " AM";
        } else {
            if ((Number(endTime.split(":")[0]) - 12) < 10) {
                et = "0" + (Number(endTime.split(":")[0]) - 12) + ":" + endTime.split(":")[1] + " PM";
            } else {
                et = (Number(endTime.split(":")[0]) - 12) + ":" + endTime.split(":")[1] + " PM";
            }
        }
        this.setState(produce(draft => {
            draft.modifiedDate.startDate = formatDate(this.state.date.startDate) + ", " + st;
            draft.modifiedDate.endDate = formatDate(this.state.date.endDate) + ", " + et;
        }));
    }


    submitCostData = () => {
        this.props.toggleLoader();
        if (this.state.error.costValue !== null) {
            this.setState(produce(draft => { draft.error.general = "Please make sure to resolve all the errors before you submit data."; }));
            setTimeout(() => {
                this.props.toggleLoader();
            },40);
        } else {
            let cost  = this.state.cost;
            let costValue,currencyId;
            if (cost === "Restore") {
                costValue = null;
                currencyId = null;
            } else {
                costValue = this.state.costValue;
                currencyId = this.state.currencyId;
            }
            this.props.dispatchUpdateManualCost({
                accessToken: this.props.accessToken,
                campaignId: this.props.campaignId,
                data: {
                    "name": this.props.campaignName,
                    "from": this.state.modifiedDate.startDate,
                    "to": this.state.modifiedDate.endDate,
                    "timezoneId": String(this.state.timezoneId),
                    "cost": this.state.cost,
                    "costValue": Number(costValue),
                    "currencyId": String(currencyId)
                }
            });
        }
    }

    // handleDateChange = (e) => {
    //     console.log(e);
    // }

    render () {
        return (
            <div>
                <Dialog className="update_cost_dialogue"
                        title={"Manual Cost Update"} onClose={() => this.props.closeUpdateCostModal()}>
                    <div className="add_affiliate_network_dialogue_content">
                        <div className="content_form">
                            {
                                this.props.state.selectedListReceivedFromGrid !== null && this.props.state.selectedListReceivedFromGrid.length < 2 && this.props.state.selectedListReceivedFromGrid.length > 0
                                ?
                                    <div>
                                        <div className="row">
                                            <p>You can manually modify the campaign's cost in a selected time range {"(< 32 days)"}. Manual cost update is designed to make updates a maximum of 60 times per one time frame. The time frame is 1 h. If you try to update the cost more frequently for the same hour, all updates will be rejected.</p>
                                        </div>
                                        <div className="row block">
                                            <h4 className="h4 black k-align-content-start">
                                                <span className="bold">Campaign name</span>
                                            </h4>
                                            <input
                                                type="text"
                                                value={this.props.campaignName}
                                                className="inp"
                                            />
                                        </div>
                                        <div className="k-mt-4"></div>
                                        <div className="row block">
                                            <h4 className="h4 black k-align-content-start">
                                                <span className="bold">
                                                    Time range
                                                    <span data-tip data-for='time_tooltip' className="question_mark">
                                                        <span className="k-icon k-i-question-circle"></span>
                                                    </span>
                                                    <ReactTooltip id='time_tooltip' type='dark'>
                                                        <span>Specify the time range within a 31 day limit.</span>
                                                    </ReactTooltip>
                                                </span>
                                            </h4>
                                            {/*<MenuCalendar*/}
                                            {/*    dates={this.state.date[0]}*/}
                                            {/*    showCalendar={this.state.showCalendar}*/}
                                            {/*    toggleCalendarWindow={this.toggleCalendarWindow}*/}
                                            {/*    handleDateChange={this.handleDateChange}*/}
                                            {/*    modifiedDate={this.state.modifiedDate}*/}
                                            {/*/>*/}
                                            <div className="date_con">
                                                <div className="inner">
                                                    <DatePicker
                                                        selected={this.state.date.startDate}
                                                        onChange={date => this.handleDateChange(date,"startDate")}
                                                    />
                                                    <select
                                                        name="startTime"
                                                        id="startTime"
                                                        value={this.state.time.startTime}
                                                        onChange={(e) => this.handleTimeChange(e,"start")}
                                                    >
                                                        <option value="00:00">00:00</option>
                                                        <option value="00:30">00:30</option>
                                                        <option value="01:00">01:00</option>
                                                        <option value="01:30">01:30</option>
                                                        <option value="02:00">02:00</option>
                                                        <option value="02:30">02:30</option>
                                                        <option value="03:00">03:00</option>
                                                        <option value="03:30">03:30</option>
                                                        <option value="04:00">04:00</option>
                                                        <option value="04:30">04:30</option>
                                                        <option value="05:00">05:00</option>
                                                        <option value="05:30">05:30</option>
                                                        <option value="06:00">06:00</option>
                                                        <option value="06:30">06:30</option>
                                                        <option value="07:00">07:00</option>
                                                        <option value="07:30">07:30</option>
                                                        <option value="08:00">08:00</option>
                                                        <option value="08:30">08:30</option>
                                                        <option value="09:00">09:00</option>
                                                        <option value="09:30">09:30</option>
                                                        <option value="10:00">10:00</option>
                                                        <option value="10:30">10:30</option>
                                                        <option value="11:00">11:00</option>
                                                        <option value="11:30">11:30</option>
                                                        <option value="12:00">12:00</option>
                                                        <option value="12:30">12:30</option>
                                                        <option value="13:00">13:00</option>
                                                        <option value="13:30">13:30</option>
                                                        <option value="14:00">14:00</option>
                                                        <option value="14:30">14:30</option>
                                                        <option value="15:00">15:00</option>
                                                        <option value="15:30">15:30</option>
                                                        <option value="16:00">16:00</option>
                                                        <option value="16:30">16:30</option>
                                                        <option value="17:00">17:00</option>
                                                        <option value="17:30">17:30</option>
                                                        <option value="18:00">18:00</option>
                                                        <option value="18:30">18:30</option>
                                                        <option value="19:00">19:00</option>
                                                        <option value="19:30">19:30</option>
                                                        <option value="20:00">20:00</option>
                                                        <option value="20:30">20:30</option>
                                                        <option value="21:00">21:00</option>
                                                        <option value="21:30">21:30</option>
                                                        <option value="22:00">22:00</option>
                                                        <option value="22:30">22:30</option>
                                                        <option value="23:00">23:00</option>
                                                        <option value="23:30">23:30</option>
                                                    </select>
                                                </div>
                                                <span>-</span>
                                                <div className="inner">
                                                    <DatePicker
                                                        selected={this.state.date.endDate}
                                                        onChange={date => this.handleDateChange(date,"endDate")}
                                                    />
                                                    <select
                                                        name="endTime"
                                                        id="endTime"
                                                        value={this.state.time.endTime}
                                                        onChange={(e) => this.handleTimeChange(e,"end")}
                                                    >
                                                        <option value="00:00">00:00</option>
                                                        <option value="00:30">00:30</option>
                                                        <option value="01:00">01:00</option>
                                                        <option value="01:30">01:30</option>
                                                        <option value="02:00">02:00</option>
                                                        <option value="02:30">02:30</option>
                                                        <option value="03:00">03:00</option>
                                                        <option value="03:30">03:30</option>
                                                        <option value="04:00">04:00</option>
                                                        <option value="04:30">04:30</option>
                                                        <option value="05:00">05:00</option>
                                                        <option value="05:30">05:30</option>
                                                        <option value="06:00">06:00</option>
                                                        <option value="06:30">06:30</option>
                                                        <option value="07:00">07:00</option>
                                                        <option value="07:30">07:30</option>
                                                        <option value="08:00">08:00</option>
                                                        <option value="08:30">08:30</option>
                                                        <option value="09:00">09:00</option>
                                                        <option value="09:30">09:30</option>
                                                        <option value="10:00">10:00</option>
                                                        <option value="10:30">10:30</option>
                                                        <option value="11:00">11:00</option>
                                                        <option value="11:30">11:30</option>
                                                        <option value="12:00">12:00</option>
                                                        <option value="12:30">12:30</option>
                                                        <option value="13:00">13:00</option>
                                                        <option value="13:30">13:30</option>
                                                        <option value="14:00">14:00</option>
                                                        <option value="14:30">14:30</option>
                                                        <option value="15:00">15:00</option>
                                                        <option value="15:30">15:30</option>
                                                        <option value="16:00">16:00</option>
                                                        <option value="16:30">16:30</option>
                                                        <option value="17:00">17:00</option>
                                                        <option value="17:30">17:30</option>
                                                        <option value="18:00">18:00</option>
                                                        <option value="18:30">18:30</option>
                                                        <option value="19:00">19:00</option>
                                                        <option value="19:30">19:30</option>
                                                        <option value="20:00">20:00</option>
                                                        <option value="20:30">20:30</option>
                                                        <option value="21:00">21:00</option>
                                                        <option value="21:30">21:30</option>
                                                        <option value="22:00">22:00</option>
                                                        <option value="22:30">22:30</option>
                                                        <option value="23:00">23:00</option>
                                                        <option value="23:30">23:30</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="k-mt-4"></div>
                                        <div className="row block">
                                            <h4 className="h4 black k-align-content-start">
                                                <span className="bold">
                                                    Time zone
                                                    <span data-tip data-for='time_zone_tooltip' className="question_mark">
                                                        <span className="k-icon k-i-question-circle"></span>
                                                    </span>
                                                    <ReactTooltip id='time_zone_tooltip' type='dark'>
                                                        <span>Use the same time zone as your traffic source to have the cost updated accurately.</span>
                                                    </ReactTooltip>
                                                </span>
                                            </h4>
                                            <select
                                                className="add_custom_domain_dropdown normal_text normal_font"
                                                id="timezoneId"
                                                value={this.state.timezoneId}
                                                onChange={this.inputChangeHandler}
                                            >
                                                {
                                                    this.props.timeZonesDataLoaded ?
                                                        this.props.timeZonesDataLoaded.map((timeZone) => (
                                                            <option key={timeZone.id} value={timeZone.id}>{timeZone.name}</option>
                                                        ))
                                                        : null
                                                }
                                            </select>
                                        </div>
                                        <div className="k-mt-5"></div>
                                        <h4 className="h4 black k-align-content-start">
                                                <span className="bold">
                                                    Cost
                                                    <span data-tip data-for='cost_up_tooltip' className="question_mark">
                                                        <span className="k-icon k-i-question-circle"></span>
                                                    </span>
                                                    <ReactTooltip id='cost_up_tooltip' type='dark'>
                                                        <span>All visits recorded in the selected time range will be updated by the provided cost value.</span>
                                                    </ReactTooltip>
                                                </span>
                                        </h4>
                                        <div className="flex_row k-pb-2">
                                            <div className="inline_block k-mr-5">
                                                <RadioButton name="cost" value="Update" checked={this.state.cost === 'Update'} label="Update" onChange={this.simpleRadioChangeHandler} />
                                            </div>
                                            <div className="inline_block">
                                                <RadioButton name="cost" value="Restore" checked={this.state.cost === 'Restore'} label="Restore" onChange={this.simpleRadioChangeHandler} />
                                            </div>
                                        </div>

                                        {
                                            this.state.cost === "Update" ?
                                                <>
                                                    <h4 className="h4 black k-align-content-start">
                                                        <span className="bold">
                                                            Cost Value
                                                            <span data-tip data-for='cost_val_up_tooltip' className="question_mark">
                                                                <span className="k-icon k-i-question-circle"></span>
                                                            </span>
                                                            <ReactTooltip id='cost_val_up_tooltip' type='dark'>
                                                                <span>Enter a total amount of the cost of all registered visits for the selected time range.</span>
                                                            </ReactTooltip>
                                                        </span>
                                                    </h4>
                                                    <div className="flex_row k-pb-1">
                                                        <div className="five_hun_half_con">
                                                            <div className="input_group five_hun_half flex cost_currency_con">
                                                                <div className="inner">
                                                                    <div className="weightage_inner_con light_gray_border_color">
                                                                        <div className="input_con">
                                                                            <input
                                                                                name="cost_value"
                                                                                className="number normal_text normal_font black k-ml-1 k-mr-1"
                                                                                type="number"
                                                                                value={this.state.costValue}
                                                                                onChange={this.costValueInputChangeHandler}
                                                                            />
                                                                        </div>
                                                                        <div className="numbers_con">
                                                                            <span
                                                                                className="k-icon k-i-minus icon_color_theme_default"
                                                                                id="minus"
                                                                                onClick={this.costValueChangeHandler}
                                                                            ></span>
                                                                            <span
                                                                                className="k-icon k-i-plus icon_color_theme_default"
                                                                                id="plus"
                                                                                onClick={this.costValueChangeHandler}
                                                                            ></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <select
                                                                    className="add_custom_domain_dropdown normal_text normal_font"
                                                                    id="currencyId"
                                                                    value={this.state.currencyId}
                                                                    onChange={this.inputChangeHandler}
                                                                >
                                                                    {
                                                                        this.props.manualCostCurrencyListLoaded ?
                                                                            this.props.manualCostCurrencyListLoaded.map((currency) => (
                                                                                <option key={currency.id} value={currency.id}>{currency.name}</option>
                                                                            ))
                                                                            : null
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.error.costValue !== null
                                                            ?
                                                            <div className="block" style={{ textAlign: "left" }}>
                                                                <Fade enter={true} exit={true}>
                                                                    <p className="danger_para k-mt-2 mb-10 mh-0">{this.state.error.costValue}</p>
                                                                </Fade>
                                                            </div>
                                                            : null
                                                    }
                                                </>
                                            : null
                                        }
                                        {
                                            this.state.error.backend !== null
                                                ?
                                                <div className="block" style={{ textAlign: "left" }}>
                                                    <Fade enter={true} exit={true}>
                                                        <p className="danger_para k-mt-2 mb-10 mh-0">{this.state.error.backend}</p>
                                                    </Fade>
                                                </div>
                                                : null
                                        }
                                        {
                                            this.state.error.general !== null
                                                ?
                                                <div className="block" style={{ textAlign: "left" }}>
                                                    <Fade enter={true} exit={true}>
                                                        <p className="danger_para k-mt-2 mb-10 mh-0">{this.state.error.general}</p>
                                                    </Fade>
                                                </div>
                                                : null
                                        }
                                    </div>
                                : null
                            }
                        </div>
                    </div>
                    <DialogActionsBar>
                        <div className="action_bar_buttons">
                            <div className="left">
                                <button onClick={() => this.props.closeUpdateCostModal()} className="normal_btn cancel_button gray_button_bg_imp black border_radius_normal_btn normal_text black no_border">Cancel</button>
                            </div>
                            <div className="right">
                                <button
                                    onClick={this.submitCostData}
                                    className="normal_btn theme_dark_btn theme_dark_bg_color_imp color_white_imp border_radius_normal_btn save_button normal_text">
                                    Save
                                </button>
                            </div>
                        </div>
                    </DialogActionsBar>
                </Dialog>
            </div>
        );
    }
};

export const mapStateToProps = createStructuredSelector({
    timeZonesDataLoaded: makeSelectTimeZonesDataLoaded(),
    accessToken: makeSelectToken(),
    backendError: makeSelectError(),
    manualCostCurrencyListLoaded: makeSelectManualCostCurrencyList(),
    submissionSuccess: makeSelectManualCostUpdateSuccess()
});

export function mapDispatchToProps(dispatch) {
    return {
        dispatchLoadTimeZones: (...payload) => dispatch(timeZones(...payload)),
        dispatchUpdateManualCost: (...payload) => dispatch(updateManualCostData(...payload)),
        dispatchLoadManualCostCurrency: (...payload) => dispatch(getCostCurrencyData(...payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCostModal);