import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import MenuCalendar from "../../components/header/menuCalendar";
import ReportGroupingDropdowns from "../../components/header/groupingDropdowns/reportGroupingDropdowns";

class ReportMenuBarTop extends React.Component {
  render() {
    return (
      <div className="page_menu_bar_top">
        <div className="grouping_dropdowns">
          <ReportGroupingDropdowns
            getGroupingValues={this.props.search.reportGroupingValues}
            changeSearchValues={this.props.changeSearchValues}
            fullGroupingList={this.props.fullGroupingList}
            disable={this.props.disable}
          />
          <div className="search_and_date_filters">
            <Button
              className="apply_btn"
              icon="k-i-icon k-i-checkmark"
              onClick={this.props.sendSearchRequest}
            >
              Apply
            </Button>
          </div>
        </div>
        <div className="search_and_date_filters">
          <div className="search_input">
            <div className="search_type_selection inline_block k-mr-2">
              <span
                className="normal_text"
                style={
                  this.props.search.isTagsSearchSelected
                    ? { backgroundColor: "#ba2374",color:"white",border:"0px" }
                    : {
                        backgroundColor: "#e0358b",
                        color: "#fff",border:"0px"
                      }
                }
                onClick={this.props.onSearchBtnClick}
              >
                text
              </span>
            </div>
            {this.props.search.isTagsSearchSelected ? (
              <MultiSelect
                data={this.props.search.tagsListReceived}
                id="tagsSearchValue"
                onChange={(e) =>
                  this.props.changeSearchValues(e, "tagsSearchValue")
                }
                value={this.props.search.tagsSearchValue}
                textField="text"
                dataItemKey="id"
              />
            ) : (
              <div style={{ position: "relative" }}>
                <input
                  className="normal_text black simple_gray_border"
                  type="text"
                  style={{ width: "245px" }}
                  placeholder="Search..."
                  value={this.props.search.inputSearchValue}
                  id="inputSearchValue"
                  onChange={(e) =>
                    this.props.changeSearchValues(e, "inputSearchValue")
                  }
                />

                {Boolean(this.props.search.inputSearchValue) && (
                  <span
                    className="k-icon k-i-check"
                    style={{
                      position: "absolute",
                      top: "20%",
                      right: "16%",
                      color: "#656565",
                    }}
                    onClick={this.props.sendSearchRequest}
                  ></span>
                )}
                {Boolean(this.props.search.inputSearchValue) && (
                  <span
                    className="k-icon k-i-x"
                    style={{
                      position: "absolute",
                      top: "20%",
                      right: "6%",
                      color: "darkgoldenrod",
                    }}
                    onClick={() => {
                      this.props.changeSearchValues("", "inputSearchValue");
                    }}
                  ></span>
                )}
              </div>
            )}
          </div>
          <MenuCalendar
            dates={this.props.search.date}
            showCalendar={this.props.showCalendar}
            toggleCalendarWindow={this.props.toggleCalendarWindow}
            modifiedDate={this.props.search.modifiedDate}
            ranges={this.props.ranges}
            applyCallback={this.props.applyCallback}
            rangeCallback={this.props.rangeCallback}
            timeZoneChange={this.props.timeZoneChange}
            defaultTimeZone={this.props.defaultTimeZone}
            timeZonesList={this.props.timeZonesList}
          />
          <div className="search_and_date_filters">
            <Button
              className="apply_btn"
              icon="k-i-icon k-i-refresh"
              onClick={this.props.sendSearchRequest}
            >
              Refresh
            </Button>
          </div>
          <button
            className="k-button normal_text normal_font page_bottom_menu_button k-ml-1 k-mr-1"
            onClick={() => this.props.showCharts()}
          >
            <span className="k-icon k-i-copy"></span>
            &nbsp; Charts
          </button>
        </div>
      </div>
    );
  }
}

export default ReportMenuBarTop;
