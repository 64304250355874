import React from "react";
import {Button} from "@progress/kendo-react-buttons";
import {Fade} from "@progress/kendo-react-animation";

const MainDomain = (props) => {
    return (
        <section className="section border_bottom light_gray_border_color text_align_left">
            <h3 className="h3 light_font">Main domain</h3>
            <p className="normal_text normal_font black k-ml-0 k-pl-0 k-mb-6">Select a domain that will be used to generate URLs in AffMark:</p>
            {
                props.state.domainsDataReceived !== null && props.state.domainsDataReceived.domain.length > 0
                    ?
                    <div className="col-xs-12 example-col">
                        <div className="select_dropdown">
                            <select
                                className="half_width select k-mb-5"
                                name="mainDomainId"
                                id="mainDomainId"
                                value={props.state.mainDomainId}
                                onChange={props.simpleInputChangeHandler}
                            >
                                <option value="">Select main domain</option>
                                {
                                    props.state.domainsDataReceived.domain.map(domain => (
                                        <option key={domain.id} value={domain.id}>{domain.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        {/* error msg */}
                        {
                            props.state.mainDomainErrMsg !== null ?
                                <Fade enter={true} exit={true}>
                                    <p className="danger_para mb-5 mh-0">{props.state.mainDomainErrMsg}</p>
                                </Fade>
                                : null
                        }
                        {/* success msg */}
                        {
                            props.state.mainDomainSuccessMsg !== null ?
                                <Fade enter={true} exit={true}>
                                    <p className="success_para mb-5 mh-0">{props.state.mainDomainSuccessMsg}</p>
                                </Fade>
                                : null
                        }
                        <p className="note" style={{ margin: "0px", padding: "0px", fontSize: "11px", color: "black" }}>* You need to click the save button if you want to change the main domain.</p>
                        <div className="redirect_domains_container_row text_align_right k-mt-5">
                            {/*<Button*/}
                            {/*    className="edit_button gray_button_bg_imp theme_dark_blue_color profile_form_btn"*/}
                            {/*>*/}
                            {/*    Cancel*/}
                            {/*</Button>*/}
                            <Button
                                className="theme_dark_bg_color_imp color_white_imp theme_dark_bg_color save_button"
                                onClick={props.sendMainDomainHandler}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                    :
                    <Fade enter={true} exit={true}>
                        <p className="warning_para mb-10 mh-0">You have not added any domains yet.</p>
                    </Fade>
            }
        </section>
    );
};

export default MainDomain;