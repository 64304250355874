import React, { Component } from "react";
import Layout from "../../../layout";
import produce from "immer";
import LoadingPanel from "./../../grid/loader/loader";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { filterBy } from "@progress/kendo-data-query";
// import AddUserModal from "./PageModal/";
import { makeSelectToken } from "../../../registration/LoginRegister/selectors";
import {
    makeSelectAllUsersData,
    makeSelectIsAllUsersDataLoaded,makeSelectIsUsersDataAdded,makeSelectUsersDataError,makeSelectIsUsersStatusChanged, makeSelectUserDataLoading, makeSelectUserDataSuccess, makeSelectUserUpdated
} from "./selectors";
import * as userActions from "./actions";
import EditUserModal from "./EditPageModal/index";
import DeleteUserModal from "./DeletePageModal/index";
import PreviewUserModal from "./PreviewPageModal/index";
import AddNewUserModal from "./AddNewUserModal";
import "./user.css";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";


class Users extends Component {
    state = {
        skip: 0,
        take: 10,
        visibleAddNewUserDialog: false,
        visibleEditUserDialog: false,
        visibleDeleteUserDialog: false,
        visiblePreviewUserDialog: false,
        deleteSuccess:false,
        backendErrors: {
            error: false,
            errorMsg:""
        },
        loader: false,
        changeStatus: false,
        editVisited: false,
        registeredSuccessfully: false,
        selectedId: "",
        isUserUpdatedSuccessfully: false,
        userAddedSuccessfullyMsg: null,
    };

    componentDidMount() {
        this.props.fetchingUsersData(this.props.accessToken);
    }
    componentDidUpdate(prevProps) {
        if((prevProps.error !== this.props.error) && this.props.error!==false ){
            console.log("Parent compoent error");
            this.setState(produce(draft=>{draft.backendErrors.error=true}));
            if(this.state.editVisited){
                this.toggleLoader();
                this.setState(produce(draft=>{draft.editVisited=false}));
            }else{
                // this.toggleLoader();
            }
        }
        if((prevProps.userAdded !== this.props.userAdded) && this.props.userAdded){
            this.setState(produce(draft=>{
                draft.backendErrors.error=false;
                draft.userAddedSuccessfullyMsg = "You have successfully added the user. You can change their status in teh table below.";
            }));
            this.closeAddUserModal();
            this.toggleLoader();
            setTimeout(() => {
                this.setState(produce(draft => { draft.userAddedSuccessfullyMsg = null; }));
            },10000);
        }
        if((prevProps.userStatusUpdated !==this.props.userStatusUpdated) && this.props.userStatusUpdated){
            this.props.fetchingUsersData(this.props.accessToken);
            this.toggleLoader();
        }
        // if((prevProps.error !== this.props.error) && this.props.error !== false && !this.props.userUpdate){
        //     this.toggleLoader();
        //     this.setState(produce(draft=>{draft.backendErrors.error=true}));
        // }

        if((prevProps.userUpdateSuccess !== this.props.userUpdateSuccess) && this.props.userUpdateSuccess ){
            this.props.fetchingUsersData(this.props.accessToken);
            this.toggleLoader();
        }
        
     }

    pageChange = (event) => {
        this.setState({
            skip: event.page.skip,
            take: event.page.take,
        });
    };

    addNewUser = () => {
        this.setState(produce((draft) => {
            draft.visibleAddNewUserDialog = true;
            // draft.loader = true;
        }));
    };
    closeAddUserModal = () => {
        this.setState(produce((draft) => {draft.visibleAddNewUserDialog = false;}));
    };

    visibleEditUserModal = (id) => {
        this.setState(produce((draft) => {
            draft.selectedId = id;
        }));
        setTimeout(() => {
            this.setState(produce(draft => { draft.visibleEditUserDialog = true; }));
        },40);
    }

    closeVisibleEditUserModalSuccess = () => {
        this.setState(produce((draft) => {
            draft.visibleEditUserDialog = false;
            draft.loader = false;
            draft.isUserUpdatedSuccessfully = true;
            setTimeout(() => {
                this.setState(produce(draft => {
                    draft.isUserUpdatedSuccessfully = false;
                }));
            }, 10000);
        }));
    }

    visibleDeleteUserModal = (id) => {
        this.setState(produce((draft) => {
            draft.selectedId = id;
        }));
        setTimeout(() => {
            this.setState(produce(draft => { draft.visibleDeleteUserDialog = true; }));
        },40);
    }

    visiblePreviewUserModal = (id) => {
        this.setState(produce((draft) => {
            draft.selectedId = id;
        }));
        setTimeout(() => {
            this.setState(produce(draft => { draft.visiblePreviewUserDialog = true; }));
        },40);
    }
    closeVisiblePreviewUserModal=()=>{ this.setState(produce((draft) => {draft.visiblePreviewUserDialog = false;})); }
    
    closeVisibleEditUserModal=()=>{ this.setState(produce((draft) => {draft.visibleEditUserDialog = false;})); }
    closeVisibleDeleteUserModal=()=>{  this.setState(produce((draft) => { draft.visibleDeleteUserDialog = false; })); }
    closeVisibleDeleteUserModalSuccess=()=>{
        this.setState(produce((draft) => {
            draft.visibleDeleteUserDialog = false;
            draft.loader = false;
            draft.registeredSuccessfully = true;
            setTimeout(() => {
                this.setState(produce(draft => { draft.registeredSuccessfully = false; }));
            },10000);
            this.props.fetchingUsersData(this.props.accessToken);
        }));
    }
    visited=()=>{
        this.setState(produce((draft) => {draft.editVisited = true;}));
    }
    changeStatus=(e)=>{
        if(e.is_active){
            this.props.changeUserStatus(this.props.accessToken,e.id,{"is_active":false});
            this.setState(produce((draft) => {draft.changeStatus = true;}));
            this.toggleLoader();
        }else{
            this.props.changeUserStatus(this.props.accessToken,e.id,{"is_active":true});
            this.setState(produce((draft) => {draft.changeStatus = true;}));
            this.toggleLoader();
        }
    }
    toggleLoader = () => {
        this.setState(produce(draft => { draft.loader = !this.state.loader; }));
    }
    closeLoader = () => {
        this.setState(produce(draft => { draft.loader = false; }));
    }
    fun() {
        this.props.history.push("/settings/add-user/");
      }

      userAddedSuccessfully = () => {
        this.setState(produce(draft => {
            draft.loader = false;
            draft.visibleAddNewUserDialog = false;
            draft.registeredSuccessfully = true;
        }));
          setTimeout(() => {
              this.setState(produce(draft => { draft.registeredSuccessfully = false; }));
          },10000);
      }

    render() {
        return (
            <>
                <Layout>
                {(this.state.backendErrors.error ) && 
                <NotificationGroup
                    style={{
                        alignItems: 'flex-start',
                        flexWrap: 'wrap-reverse',
                        left: "50%",
                        zIndex: 10000000,
                        top: "20px",
                        transform: "translateX(-50%)",
                    }}
                >
                    <Fade enter={true} exit={true}>
                        {(this.state.backendErrors.error&& this.props.error) && <Notification
                            type={{ style: 'error', icon: true }}
                            closable={true}
                            onClose={() => this.setState(produce(draft => { draft.backendErrors.error = false; }))}
                        >
                            <span>{ this.props.error.message+", "+this.props.error.error}</span>
                            </Notification>}
                    </Fade>
                </NotificationGroup>
                }

                    {this.state.deleteSuccess &&
                    <NotificationGroup
                        style={{
                            alignItems: 'flex-start',
                            flexWrap: 'wrap-reverse',
                            left: "50%",
                            zIndex: 10000000,
                            top: "20px",
                            transform: "translateX(-50%)",
                        }}
                    >
                        <Fade enter={true} exit={true}>
                            <Notification
                                type={{ style: 'success', icon: true }}
                                closable={true}
                                onClose={() => this.setState(produce(draft => { draft.deleteSuccess = false; }))}
                            >
                                <span>You have successfully deleted the user.</span>
                            </Notification>
                        </Fade>
                    </NotificationGroup>
                    }

                    {this.state.registeredSuccessfully &&
                    <NotificationGroup
                        style={{
                            alignItems: 'flex-start',
                            flexWrap: 'wrap-reverse',
                            left: "50%",
                            zIndex: 10000000,
                            top: "20px",
                            transform: "translateX(-50%)",
                        }}
                    >
                        <Fade enter={true} exit={true}>
                            <Notification
                                type={{ style: 'success', icon: true }}
                                closable={true}
                                onClose={() => this.setState(produce(draft => { draft.registeredSuccessfully = false; }))}
                            >
                                <span>You have successfully added the user. You can approve and dis-approve the user in the table below.</span>
                            </Notification>
                        </Fade>
                    </NotificationGroup>
                    }
                    {this.state.isUserUpdatedSuccessfully &&
                    <NotificationGroup
                        style={{
                            alignItems: 'flex-start',
                            flexWrap: 'wrap-reverse',
                            left: "50%",
                            zIndex: 10000000,
                            top: "20px",
                            transform: "translateX(-50%)",
                        }}
                    >
                        <Fade enter={true} exit={true}>
                            <Notification
                                type={{ style: 'success', icon: true }}
                                closable={true}
                                onClose={() => this.setState(produce(draft => { draft.isUserUpdatedSuccessfully = false; }))}
                            >
                                <span>You have successfully updated the user.</span>
                            </Notification>
                        </Fade>
                    </NotificationGroup>
                    }
                    {this.state.userAddedSuccessfullyMsg !== null &&
                    <NotificationGroup
                        style={{
                            alignItems: 'flex-start',
                            flexWrap: 'wrap-reverse',
                            left: "50%",
                            zIndex: 10000000,
                            top: "20px",
                            transform: "translateX(-50%)",
                        }}
                    >
                        <Fade enter={true} exit={true}>
                            <Notification
                                type={{ style: 'success', icon: true }}
                                closable={true}
                                onClose={() => this.setState(produce(draft => { draft.userAddedSuccessfullyMsg = null; }))}
                            >
                                <span>{this.state.userAddedSuccessfullyMsg}</span>
                            </Notification>
                        </Fade>
                    </NotificationGroup>
                    }
                    <div className="settings_area_container user-settings">
                        <div className="h1_con">
                            <h1 className="h1 m-l">Users</h1>
                        </div>
                        <div
                            className="white_box_with_header_con white_bg"
                            style={{ marginBottom: "0px" }}
                        >
                            <div className="new-user-btn">
                                <Button
                                    icon={"k-icon k-i-plus-circle"}
                                    className="add_user_btn"
                                    onClick={this.addNewUser}
                                >
                                    Add new user
                                </Button>


                                {this.state.visibleAddNewUserDialog && (
                                    <AddNewUserModal
                                        state={this.state}
                                        closeAddUserModal={this.closeAddUserModal}
                                        toggleLoader={this.toggleLoader}
                                        closeLoader={this.closeLoader}
                                        userAddedSuccessfully={this.userAddedSuccessfully}
                                    />
                                )}
                                {this.state.visibleEditUserDialog && <EditUserModal
                                    id={this.state.selectedId}
                                    state= {this.state}
                                    toggleLoader={this.toggleLoader}
                                    closeVisibleEditUserModal={this.closeVisibleEditUserModal}
                                    closeVisibleEditUserModalSuccess={this.closeVisibleEditUserModalSuccess}
                                />}
                                {this.state.visibleDeleteUserDialog && <DeleteUserModal
                                    id={this.state.selectedId}
                                    toggleLoader={this.toggleLoader}
                                    closeVisibleDeleteUserModal={this.closeVisibleDeleteUserModal}
                                    closeVisibleDeleteUserModalSuccess={this.closeVisibleDeleteUserModalSuccess}
                                />}

                                {this.state.visiblePreviewUserDialog && <PreviewUserModal
                                    id={this.state.selectedId}
                                    toggleLoader={this.toggleLoader}
                                    closeVisiblePreviewUserModal={this.closeVisiblePreviewUserModal}
                                />}
                            </div>

                            <div className="box">
                                <section className="section text_align_left">
                                    {typeof this.props.allUsersDataLoaded !== "undefined" &&
                                        this.props.allUsersDataLoaded !== null ? (
                                        <Grid
                                            style={{ height: "100%" }}
                                            data={this.props.allUsersDataLoaded.slice(
                                                this.state.skip,
                                                this.state.take + this.state.skip
                                            )}
                                            skip={this.state.skip}
                                            take={this.state.take}
                                            total={this.props.allUsersDataLoaded.length}
                                            pageable={true}
                                            onPageChange={this.pageChange}
                                        >
                                            <GridColumn field="id" title="User id" width="100px" />
                                            <GridColumn field="fullname" title="Full name" cell={(props) => (
                                                <td>
                                                    { (props.dataItem["firstname"] !==null && props.dataItem["lastname"] !==null) ? props.dataItem["firstname"] + " "+ props.dataItem["lastname"]: "-"}
                                                </td>
                                            )}/>
                                            <GridColumn field="email" title="User e-mail" />
                                            <GridColumn
                                                field="accountStatus"
                                                title="Account Status"
                                                cell={(props) => (
                                                    <td
                                                        className={
                                                            props.dataItem["accountStatus"] === 'APPROVED'
                                                                ? "active" : props.dataItem["accountStatus"] === 'REJECTED' ? 'rejected'
                                                                : "unactive"
                                                        }
                                                    >
                                                        {props.dataItem["accountStatus"] === "APPROVED" && "Approved" }
                                                        {props.dataItem["accountStatus"] === "PENDING" && "Pending" }
                                                        {props.dataItem["accountStatus"] === "REJECTED" && "Rejected" }
                                                    </td>
                                                )}
                                            />
                                            <GridColumn
                                                field="is_active"
                                                title="User Active Status"
                                                cell={(props) => (
                                                    <td
                                                        className={
                                                            props.dataItem["is_active"]
                                                                ? "active"
                                                                : "unactive"
                                                        }
                                                    >
                                                        {props.dataItem["is_active"]
                                                            ? "Active"
                                                            : "Unactive"}
                                                    </td>
                                                )}
                                            />
                                            <GridColumn
                                                field="is_active"
                                                title="Change Status"
                                                cell={(props) => (
                                                    <td>
                                                        {props.dataItem["is_active"] ? (
                                                            <Button className="unactive" onClick={()=>this.changeStatus(props.dataItem)}>Disable User</Button>
                                                        ) : (
                                                            <Button className="active" onClick={()=>this.changeStatus(props.dataItem)}>Activate User</Button>
                                                        )}
                                                    </td>
                                                )}
                                            />
                                            <GridColumn
                                                field="create_at"
                                                title="Signup Date"
                                                cell={(props) => (
                                                    <td>
                                                        {props.dataItem["create_at"].split(",")[0]}
                                                    </td>
                                                )}
                                            />
                                            <GridColumn
                                                field="entity_name"
                                                title="Actions"
                                                width="100px"
                                                cell={(props) => (
                                                    <>
                                                        <td className="row">
                                                            {/*<EditUserModal*/}
                                                            {/*    icon="k-icon k-i-edit"*/}
                                                            {/*    classNameForButton="edit_workspace_btn"*/}
                                                            {/*    buttonText=""*/}
                                                            {/*    title={"Edit user"}*/}
                                                            {/*    item={props.dataItem}*/}
                                                            {/*    id={props.dataItem["id"]}*/}
                                                            {/*    token={this.props.accessToken}*/}
                                                            {/*    action={"Edit"}*/}
                                                            {/*    toggleLoader={this.toggleLoader}*/}
                                                            {/*    visited={this.visited}*/}
                                                            {/*/>*/}
                                                             <span
                                                                 item={props.dataItem}
                                                                 onClick={() => this.visibleEditUserModal(props.dataItem["id"])}
                                                                 className="k-icon k-i-edit icon_color_theme_dark" style={{cursor:"pointer"}}
                                                             />
                                                            {props.dataItem["is_active"] ?
                                                                null
                                                                :
                                                                <span
                                                                     item={props.dataItem}
                                                                     onClick={() => this.visibleDeleteUserModal(props.dataItem["id"])}
                                                                     className="k-icon k-i-trash icon_color_theme_dark" style={{cursor:"pointer"}}
                                                                 />
                                                            }
                                                            <span
                                                                 item={props.dataItem}
                                                                 onClick={() => this.visiblePreviewUserModal(props.dataItem["id"])}
                                                                 className="k-icon k-i-preview icon_color_theme_dark" style={{cursor:"pointer"}}
                                                             />
                                                        </td>
                                                    </>
                                                )}
                                            />
                                        </Grid>
                                    ) : (
                                        <LoadingPanel></LoadingPanel>
                                    )}
                                </section>
                                {this.state.loader && <LoadingPanel/>}
                                
                            </div>
                        </div>
                    </div>
                    
                </Layout>
            </>
        );
    }
}

export const mapStateToProps = createStructuredSelector({
    accessToken: makeSelectToken(),
    allUsersDataLoaded: makeSelectAllUsersData(),
    isAllUsersDataLoaded: makeSelectIsAllUsersDataLoaded(),
    userAdded:makeSelectIsUsersDataAdded(),
    error:makeSelectUsersDataError(),
    userStatusUpdated:makeSelectIsUsersStatusChanged(),
    loading: makeSelectUserDataLoading(),
    success: makeSelectUserDataSuccess(),
    userUpdateSuccess: makeSelectUserUpdated(),
});

export function mapDispatchToProps(dispatch) {
    return {
        fetchingUsersData: (...payload) =>dispatch(userActions.loadAllUsers(...payload)),
        changeUserStatus:(...payload) =>dispatch(userActions.changeUserStatus(...payload)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
