import React, { useState, useRef } from "react";
import ReactTooltip from "react-tooltip";
import { Switch } from "@progress/kendo-react-inputs";
import { Fade } from "@progress/kendo-react-animation";
import { Link } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import _ from "lodash"

const ModalForm = (props) => {
    const [affiliateTrackingURLCopySuccess, setAffiliateTrackingURLCopySuccess] = useState("");
    const affiliateTrackingURLRef = useRef(null);

    function copyToClipboard(e) {
        affiliateTrackingURLRef.current.select();
        document.execCommand("copy");
        e.target.focus();
        setAffiliateTrackingURLCopySuccess("Copied!");
    }
    const [options, setOptions] = React.useState([]);
    React.useEffect(() => {
        let sortedOffers = _.cloneDeep(props?.state?.affiliateNetworkDataReceived?.data)
        sortedOffers?.length && sortedOffers.sort((first,second) => first.name.localeCompare(second.name));
        setOptions(sortedOffers)
    },[props])

    return (
        <div className="form_con">
            <section>
                <h3>General</h3>
                <div className="input_group five_hun">
                    <label htmlFor="affiliate-network-name">
                        Workspace
                        <span data-tip data-for="workspaces_tooltip" className="question_mark">
                            <span className="k-icon k-i-question-circle"></span>
                        </span>
                        <ReactTooltip id="workspaces_tooltip" type="dark">
                            <span>
                                Assign your affiliate network to a particular workspace to make it
                                accessible only by its members. Public option enables members from
                                all workspaces to use this element in their campaigns.
                            </span>
                        </ReactTooltip>
                    </label>
                    <select
                        className="add_custom_domain_dropdown normal_text normal_font"
                        name="workspaceId"
                        value={props.state.dataToSend["workspaceId"]}
                        onChange={props.inputChangeHandler}
                    >
                        {props.state.domainsDataReceived.workspace.map((workspace) => (
                            <option key={workspace.id} value={workspace.id}>
                                {workspace.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="input_group five_hun row">
                    <div className="offer_affiliate_network input_group_inline_small">
                        <label htmlFor="affiliate-network-name">Affiliate network</label>
                        <select
                            className="add_custom_domain_dropdown normal_text normal_font"
                            name="affilateNetworkId"
                            value={props.state.dataToSend["affilateNetworkId"]}
                            onChange={props.inputChangeHandler}
                        >   
                            {options?.map((affNetwork, index) => (
                                <>
                                    {Boolean(affNetwork.id !== options[index + 1]?.id) && (
                                    <option key={affNetwork.id} value={affNetwork.id}>
                                        {affNetwork.name}
                                    </option>
                                    )}
                                </>
                            ))}
                        </select>
                    </div>
                        
                    <div className="offer_countries input_group_inline_small">
                        <label htmlFor="affiliate-network-name">
                            Country tag
                            <span data-tip data-for="country_tooltip" className="question_mark">
                                <span className="k-icon k-i-question-circle"></span>
                            </span>
                            <ReactTooltip id="country_tooltip" type="dark">
                                <span>
                                    It has only an organizational purpose to group offers by its
                                    Country label
                                </span>
                            </ReactTooltip>
                        </label>
                        <select
                            className="add_custom_domain_dropdown normal_text normal_font"
                            name="countryId"
                            value={props.state.dataToSend["countryId"]}
                            onChange={props.inputChangeHandler}
                        >
                            {props.state.countriesDataReceived.map((country) => (
                                <option key={country.id} value={country.id}>
                                    {country.country_name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                    {props.state.empty_values.AffNet ? (
                        <Fade enter={true} exit={true}>
                            <p className="danger_para mb-10 mh-0" style={{ margin: "0 0 10px", padding: "10px 15px" }}>You do not have any Affiliate Network to add, and without Affiliate Network you can not create Offer. Go to Affiliate Network page and create one.</p>
                        </Fade>
                    ) : null}
                <div className="input_group">
                    <label htmlFor="affiliate-network-name">Name</label>
                    <input
                        type="text"
                        name="name"
                        placeholder="Type offer name"
                        // defaultValue={props.state.dataToSend.affilateNetworkId+" - "+props.state.dataToSend.countryId}
                        value={props.state.dataToSend.name}
                        onChange={props.inputChangeHandler}
                    />
                    {props.state.empty_values.name ? (
                        <Fade enter={true} exit={true}>
                            <p className="danger_para mb-10 mh-0">You must add the offer name.</p>
                        </Fade>
                    ) : null}
                </div>
                <div className="input_group">
                    <label htmlFor="affiliate-network-name">
                        Tags
                        <span data-tip data-for="tags_tooltip" className="question_mark">
                            <span className="k-icon k-i-question-circle"></span>
                        </span>
                        <ReactTooltip id="tags_tooltip" type="dark">
                            <span>
                                Add personalized tags to easily search for offers afterward. Keep in
                                mind that tags can only contain letters, numbers, and underscores.
                            </span>
                        </ReactTooltip>
                    </label>
                        <MultiSelect
                            placeholder="Type or select tags from list"
                            onChange={props.handleTagChange}
                            value={props.state.dataToSend["tags"]}
                            allowCustom={true}
                            
                        />
                    {props.state.empty_values.tags_name ? (
                        <Fade enter={true} exit={true}>
                            <p className="danger_para mb-10 mh-0">You must add the tags.</p>
                        </Fade>
                    ) : null}
                </div>
            </section>
            <section>
                <h3>
                    Offer URL
                    <span data-tip data-for="offer_url" className="question_mark">
                        <span className="k-icon k-i-question-circle"></span>
                    </span>
                    <ReactTooltip id="offer_url" type="dark">
                        <span>
                            Paste a website URL to the product / service that you advertise over the
                            Internet.
                        </span>
                    </ReactTooltip>
                </h3>
                <div className="additional_settings_con">
                    <input
                        className="ts_postback_input"
                        type="text"
                        placeholder="E.g. https://www.offer-url.com"
                        name="offer_url"
                        onChange={props.offerUrlChangeHandler}
                        value={props.state.dataToSend.offer_url}
                    />
                    {props.state.empty_values.offer_url ? (
                        <Fade enter={true} exit={true}>
                            <p className="danger_para mb-10 mh-0"
                                style={{ margin: "0 0 10px", padding: "10px 15px" }}>
                                {"- Please make sure that url must have: {clickid}"}<br />
                                {"eg: https://www.abcd.com?{clickid}"}</p>
                        </Fade>
                    ) : null}
                    <div className="note normal_font normal_text">
                    <span className="question_mark">
                        <span className="k-icon k-i-warning icon_color_theme_default"></span>
                    </span>
                    <div className="note_p black">
                        When using postback URL for conversion tracking, add the <span>&#123;clickid&#125;</span> token to the offer URL
                    </div>
                </div>
                    {!props.state.is_protocol_valid || !props.state.is_domain_valid ? (
                        <Fade enter={true} exit={true}>
                            <p
                                className="danger_para mb-10 mh-0"
                                style={{ margin: "0 0 10px", padding: "10px 15px" }}
                            >
                                {!props.state.is_protocol_valid
                                    ? "- Please make sure the url starts with http:// or https:// "
                                    : null}
                                &nbsp;&nbsp;
                                {!props.state.is_domain_valid
                                    ? " - Please make sure you enter a valid domain name "
                                    : null}
                            </p>
                        </Fade>
                    ) : null}
                    <div className="ts_postback_tokens_container">
                        {props.tagsListReceived
                            ? props.tagsListReceived.map((tag) => (
                                  <span
                                      id={tag.code}
                                      className="token"
                                      onClick={props.addTokenToUrl}
                                  >
                                      {tag.code}
                                  </span>
                              ))
                            : null}
                    </div>
                </div>
            </section>
            <section className="five_hun">
                <h3>
                    Conversion tracking
                    <span data-tip data-for="affiliate_networks_ct" className="question_mark">
                        <span className="k-icon k-i-question-circle"></span>
                    </span>
                    <ReactTooltip id="affiliate_networks_ct" type="dark">
                        <span>
                            All methods for conversion tracking supported by AffMark. If your
                            affiliate network don’t support passing conversion data to 3rd party,
                            you can always upload conversions later on via API or by using
                            Conversion upload tool available in Settings. You will need a valid
                            click ID for that.
                        </span>
                    </ReactTooltip>
                </h3>
                <div className="input_group_inline_small">
                    <label htmlFor="affiliate-network-name">Tracking domain</label>
                    <select
                        className="add_custom_domain_dropdown normal_text normal_font"
                        name="domainId"
                        value={props.state.dataToSend.domainId}
                        onChange={props.inputChangeHandler}
                    >
                        {props.state.domainsDataReceived.domain.map((domain) => (
                            <option key={domain.id} value={domain.id}>
                                {domain.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="input_group_inline_small">
                    <label htmlFor="affiliate-network-name">
                        Tracking method
                        <span
                            data-tip
                            data-for="affiliate_method_tooltip"
                            className="question_mark"
                        >
                            <span className="k-icon k-i-question-circle"></span>
                        </span>
                        <ReactTooltip id="affiliate_method_tooltip" type="dark">
                            <span>
                                A method that you will use to pass the conversion to the tracker. If
                                the affiliate network / advertiser / any 3rd party tool you use
                                supports more than one option, we recommend to use s2s postback URL
                                as the most bullet-proof way (server-to-server) to deliver a
                                conversion information to AffMark.
                            </span>
                        </ReactTooltip>
                    </label>
                    <select
                        className="add_custom_domain_dropdown normal_text normal_font"
                        name="tracking_urlId"
                        value={props.state.dataToSend.tracking_urlId}
                        onChange={props.inputChangeHandler}
                    >
                        {props.state.trackingUrlsReceived !== null
                            ? props.state.trackingUrlsReceived.map((trackingUrlReceived) => (
                                  <option
                                      key={trackingUrlReceived.id}
                                      value={trackingUrlReceived.id}
                                  >
                                      {trackingUrlReceived.name}
                                  </option>
                              ))
                            : null}
                    </select>
                </div>
                {
                    props.state.empty_values.domainId ?
                        <Fade enter={true} exit={true}>
                            <p className="danger_para mb-10 mh-0">No existing Domains found. Please add a domain under settings / domains</p>
                        </Fade>
                        :
                        null
                }
                <div className="input_group copy_group">
                    {props.state.showCIdReplaceWarning ? (
                        <div className="tooltip">
                            <span
                                data-tip
                                data-for="affiliate_tracking_input_tooltip"
                                className="question_mark"
                            >
                                <span className="k-icon k-i-warning icon_color_warning"></span>
                            </span>
                            <ReactTooltip id="affiliate_tracking_input_tooltip" type="dark">
                                <span>
                                    REPLACE needs to be replaced with the data copied from your
                                    affiliate network.
                                </span>
                            </ReactTooltip>
                        </div>
                    ) : null}
                    {/*=================================================================*/}
                    {/*                 Input Container for offer url                   */}
                    {/*=================================================================*/}
                    <div className="input_container">
                        <input
                            className="data"
                            ref={affiliateTrackingURLRef}
                            name="conversion_tracking_code_input"
                            value={
                                props.state.tracking_url_id_code_before +
                                props.state.url_value.domain_name +
                                props.state.url_value.tracking_url_code +
                                props.state.url_value.click_id_token +
                                props.state.url_value.before_payout_ampersand +
                                props.state.url_value.payout_token +
                                props.state.url_value.before_tx_id_ampersand +
                                props.state.url_value.tx_id_token +
                                props.state.url_value.before_et_ampersand +
                                props.state.url_value.et_token +
                                props.state.tracking_url_id_code_after
                            }
                        />
                        <div className="tags">
                            {/* <span className="copy">Expand</span> */}
                            <span className="copy" onClick={copyToClipboard}>
                                Copy
                            </span>
                        </div>
                    </div>
                    {/*=================================================================*/}
                    {/*                 Input Container for offer    url                */}
                    {/*=================================================================*/}
                </div>
                <div className="note normal_font normal_text">
                    <span className="question_mark">
                        <span className="k-icon k-i-warning icon_color_theme_default"></span>
                    </span>
                    <div className="note_p black">
                        Copy the above content and paste it into the affiliate network global
                        conversion tracking settings.
                    </div>
                </div>
                <div className="currency_group input_group_inline_small">
                    <label htmlFor="payout">
                        Payout
                        <span data-tip data-for="payout_tooltip" className="question_mark">
                            <span className="k-icon k-i-question-circle"></span>
                        </span>
                        <ReactTooltip id="payout_tooltip" type="dark">
                            <span>
                                Select either Manual to enter the fixed payment amount or the Auto
                                option if you've added the payout parameter to your postback or
                                pixel URL.
                            </span>
                        </ReactTooltip>
                    </label>
                    <select
                        className="add_custom_domain_dropdown normal_text normal_font"
                        name="offerPayout"
                        id="offerPayout"
                        value={props.state.dataToSend["payoutType"]}
                        onChange={props.payoutChangeHandler}
                    >
                        <option key={1} value={"Auto"}>
                            Auto
                        </option>
                        <option key={2} value={"Manual"}>
                            Manual
                        </option>
                    </select>
                </div>
                <div className="currency_group input_group_inline_small ">
                    <form className="payout_form">
                        <input
                            className="payout_input"
                            placeholder="Payout"
                            type="number"
                            min="0"
                            max="1000000"
                            name="payout"
                            id="payout"
                            onChange={props.payoutChangeHandler}
                            value={props.state.dataToSend["payout"]}
                            disabled = {(props.state.dataToSend.payoutType==="Auto")? true : ""}
                        ></input>
                        {/* <span className="k-icon k-i-plus"></span>
                        <span className="k-icon k-i-minus"></span> */}
                        <Button className="btn-clr" name="payout"  icon={"k-icon k-i-plus"} onClick={(e)=>props.onClickIncrement(e)} disabled = {(props.state.dataToSend.payoutType==="Auto")? true : ""}></Button>
                        <Button className="btn-clr" name="payout" icon={"k-icon k-i-minus"} onClick={(e)=>props.onClickDecrement(e)} disabled = {(props.state.dataToSend.payoutType==="Auto")? true : ""}></Button>
                    </form>
                </div>
                <div className="currency_group input_group_inline_small">
                    <label htmlFor="offer-currency">
                        Currency
                        <span data-tip data-for="currency_tooltip" className="question_mark">
                            <span className="k-icon k-i-question-circle"></span>
                        </span>
                        <ReactTooltip id="currency_tooltip" type="dark">
                            <span>
                                Assign your affiliate network to a particular workspace to make it
                                accessible only by its members. Public option enables members from
                                all workspaces to use this element in their campaigns.
                            </span>
                        </ReactTooltip>
                    </label>
                    <select
                        className="add_custom_domain_dropdown normal_text normal_font"
                        name="currencyId"
                        value={props.state.dataToSend.currencyId}
                        onChange={props.inputChangeHandler}
                    >
                        {props.state.currenciesListReceived.map((currency) => (
                            <option key={currency.id} value={currency.id}>
                                {currency.name}
                            </option>
                        ))}
                    </select>
                </div>
            </section>
            <section>
                <div className="five_hun additional_settings_con">
                    <div className="switches_row">
                        <Switch
                            name="ip_address_switch_value"
                            onClick={props.ipAddressSwitchChangeHandler}
                            onChange={props.ipAddressSwitchChangeHandler}
                            checked={props.state.ip_address_switch_value}
                        />
                        Conversion cap
                        <span data-tip data-for="conversion_cap_tooltip" className="question_mark">
                            <span className="k-icon k-i-question-circle"></span>
                        </span>
                        <ReactTooltip id="conversion_cap_tooltip" type="dark">
                            <span>
                                Enable this option if you need to set a conversion limit for the
                                offer.<Link to="#">Learn more</Link>
                            </span>
                        </ReactTooltip>
                    </div>
                    {props.state.ip_address_switch_value ? (
                        <>
                            <div className="row conversion_cap">
                                <div className="currency_group input_group_inline_small ">
                                    <label htmlFor="affiliate-network-name">
                                        Daily cap
                                        <span
                                            data-tip
                                            data-for="dailyCap_tooltip"
                                            className="question_mark"
                                        >
                                            <span className="k-icon k-i-question-circle"></span>
                                        </span>
                                        <ReactTooltip id="dailyCap_tooltip" type="dark">
                                            <span>
                                                Restrict the number of conversions according to your
                                                affiliate network’s / direct partner limit.
                                            </span>
                                        </ReactTooltip>
                                    </label>
                                    <form className="payout_form">
                                        <input
                                            className="payout_input"
                                            placeholder="Type value"
                                            type="number"
                                            min="0"
                                            max="1000000"
                                            name="capSize"
                                            id="capSize"
                                            onChange={props.payoutChangeHandler}
                                            value={props.state.dataToSend["cap_size"]}
                                        ></input>
                                        {/* <span className="k-icon k-i-plus"></span>
                                <span className="k-icon k-i-minus"></span> */}
                                        <Button className="btn-clr" name="capSize" icon={"k-icon k-i-plus"} onClick={(e)=>props.onClickIncrement(e)}></Button>
                                        <Button className="btn-clr" name="capSize" icon={"k-icon k-i-minus"} onClick={(e)=>props.onClickDecrement(e)}></Button>
                                    </form>
                                </div>
                                <div className="input_group five_hun">
                                    <label htmlFor="affiliate-network-name" style={{marginBottom:"5px"}}>
                                        Time zone
                                        <span
                                            data-tip
                                            data-for="timzone_tooltip"
                                            className="question_mark"
                                        >
                                            <span className="k-icon k-i-question-circle"></span>
                                        </span>
                                        <ReactTooltip id="timzone_tooltip" type="dark">
                                            <span>
                                                Conversion counter resets at 12:00 am every day
                                                based on your affiliate network's time zone.
                                            </span>
                                        </ReactTooltip>
                                    </label>
                                    <select
                                        className="add_custom_domain_dropdown normal_text normal_font"
                                        name="timeZoneId"
                                        value={props.state.dataToSend["timeZoneId"]}
                                        onChange={props.timezoneChangeHandler}
                                    >
                                        {props.state.timezoneDataReceived.map((timzone) => (
                                            <option key={timzone.id} value={timzone.id}>
                                                {timzone.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
            </section>
        </div>
    );
};

export default ModalForm;
