export const getUserParameters = () => {
    if (JSON.parse(localStorage.getItem('user'))) {
        return JSON.parse(localStorage.getItem('user'));
    } else {
        const user = {
            user: null,
            userId: null,
            isAuthenticated: null,
        };
        localStorage.setItem("user",JSON.stringify(user));
        return user;
    }
};