import { Component, Fragment } from "react";
import { connect } from "react-redux";
import Layout from "./layout/index";
import "../pages.css";
import { createStructuredSelector } from "reselect";
import { makeSelectLoadConversionData } from "./selectors";
import {
  loadAllConversions,
  loadInitState,
  loadLastRowsFromConversion,
  searchedValue,
} from "./actions";
import { makeSelectToken } from "../../../registration/LoginRegister/selectors";
import "./Landers.css";
import GridClassComponent from "../../conversion-grid/grid";
import React from "react";
import LoadingPanel from "../../grid/loader/loader";
import PageMenuBarBottom from "./PageMenuBarBottom/index";
import PageMenuBarTop from "./PageMenuBarTop/index";
import moment from "moment";
import { setDatesToLocalStorage } from "../../../components/setDatesToLocalStorage/index";
import MenuCharts from "../../charts";
import { loadChartsData } from "../Campaigns/actions";
import { makeSelectAllChartsDataLoaded } from "../Campaigns/selectors";

const Pagination = {
  page: 0,
  first: "first",
  last: "last",
  lastBlur: false,
};

const Sorting = {
  sort: null,
  order: null,
};

const Conversion = (props) => {
  let searchDates = JSON.parse(localStorage.getItem("search"));
  const [pageContentHeight, setPageHeight] = React.useState("500");
  const [tableData, setTableData] = React.useState([]);
  const [pagination, setPagination] = React.useState(Pagination);
  const [loader, setLoader] = React.useState(false);
  const [dates, setDates] = React.useState({
    startDate: moment(searchDates?.dates?.startDate),
    endDate: moment(searchDates?.dates?.endDate),
    timeZone: searchDates?.dates?.timeZone,
    secondDisplay: false,
    modifiedDate: {
      startDate:
        searchDates?.modifiedDate?.startDate ??
        moment(new Date()).format("MM/DD/YYYY hh:mm A"),
      endDate:
        searchDates?.modifiedDate?.endDate ??
        moment(new Date()).format("MM/DD/YYYY hh:mm A"),
    },
    defaultTimeZone: "(GMT -12:00) Eniwetok, Kwajalein",
  });
  const [tableRowSize, setTableRowSize] = React.useState(50);
  const [searchValue, setSearchValue] = React.useState("");
  const [groupBy, setGroupByValue] = React.useState("");
  const [sorting, setSorting] = React.useState(Sorting);
  const [showColumnModal, setShowColumnModal] = React.useState(false);
  const [noRecordFound, setShowNoRecordFound] = React.useState(false);
  const [chartsData, setShowCharts] = React.useState({
    showCharts: false,
    chartsData: null,
  });

  const reset = () => {
    setLoader(() => true);
    setTableData(() => []);
  };

  const handleStep = (value) => {
    reset();
    if (value === pagination.first)
      setPagination((prevState) => ({
        ...prevState,
        page: 0,
        lastBlur: false,
      }));
    if (value === pagination.last)
      setPagination((prevState) => ({
        ...prevState,
        page: undefined,
        lastBlur: true,
      }));
    props.dispatchLoadLastRowsFromConversion({
      position: value,
      rowSize: tableRowSize,
      value: searchValue,
      groupBy: groupBy,
      startDate: dates.modifiedDate.startDate,
      endDate: dates.modifiedDate.endDate,
      sortBy: sorting.sort,
      sortName: sorting.order,
    });
  };

  const handlePagination = (value) => {
    reset();
    setPagination((prevState) => ({ ...prevState, page: value }));
    props.dispatchLoadAllConversions({
      rowSize: tableRowSize,
      page: value,
      value: searchValue,
      groupBy: groupBy,
      startDate: dates.modifiedDate.startDate,
      endDate: dates.modifiedDate.endDate,
      sortBy: sorting.sort,
      sortName: sorting.order,
    });
  };

  const changeRowSize = (rowSize) => {
    reset();
    setTableRowSize(() => rowSize);
    props.dispatchLoadAllConversions({
      rowSize: tableRowSize,
      page: pagination.page,
      value: searchValue,
      groupBy: groupBy,
      startDate: dates.modifiedDate.startDate,
      endDate: dates.modifiedDate.endDate,
      sortBy: sorting.sort,
      sortName: sorting.order,
    });
  };

  const Apply = () => {
    reset();
    setTableRowSize(() => 50);
    setPagination((prevState) => ({ ...prevState, page: 0 }));
    props.dispatchLoadAllConversions({
      rowSize: 50,
      page: 0,
      value: searchValue,
      groupBy: groupBy,
      startDate: dates.modifiedDate.startDate,
      endDate: dates.modifiedDate.endDate,
      sortBy: sorting.sort,
      sortName: sorting.order,
    });
  };

  const RemoveSearchAndReset = () => {
    reset();
    setTableRowSize(() => 50);
    setPagination((prevState) => ({ ...prevState, page: 0 }));
    props.dispatchLoadAllConversions({
      rowSize: 50,
      page: 0,
      value: "",
      groupBy: groupBy,
      startDate: dates.modifiedDate.startDate,
      endDate: dates.modifiedDate.endDate,
      sortBy: sorting.sort,
      sortName: sorting.order,
    });
  };

  const Refresh = () => {
    setLoader(() => true);
    setTableData(() => []);
    setTableRowSize(() => 50);
    setSearchValue(() => "");
    setGroupByValue(() => "");
    setShowCharts(() => ({ chartsData: [], showCharts: false }));
    setPagination((prevState) => ({ ...prevState, page: 0 }));
    props.dispatchLoadAllConversions({
      rowSize: tableRowSize,
      page: pagination.page,
      startDate: dates.modifiedDate.startDate,
      endDate: dates.modifiedDate.endDate,
      sortBy: sorting.sort,
      sortName: sorting.order,
    });
  };

  const timeRangeApply = (s, e) => {};

  const applyDates = (s, e) => {
    reset();
    setDatesToLocalStorage(s, e);
    setPagination((prevState) => ({ ...prevState, page: 0, lastBlur: false }));
    let startDate = s.format("MM/DD/YYYY hh:mm A");
    let endDate = e.format("MM/DD/YYYY hh:mm A");
    setDates((prevStates) => ({
      ...prevStates,
      startDate: s,
      endDate: e,
      modifiedDate: {
        startDate: s.format("MM/DD/YYYY hh:mm A"),
        endDate: e.format("MM/DD/YYYY hh:mm A"),
      },
    }));
    props.dispatchLoadAllConversions({
      rowSize: 50,
      page: 0,
      value: searchValue,
      groupBy: groupBy,
      startDate: startDate,
      endDate: endDate,
      sortBy: sorting.sort,
      sortName: sorting.order,
    });
    if (chartsData.showCharts) {
      setShowCharts((prevState) => ({ ...prevState, chartsData: null }));
      props?.dispatchLoadChartsData({
        accessToken: props.accessToken,
        dates: JSON.parse(localStorage.getItem("search")).modifiedDate,
      });
    }
  };

  const HandleSorting = (name) => {
    setSorting(() => ({
      order: name,
      sort: sorting.sort === "DESC" ? "ASC" : "DESC",
    }));
    reset();
    props.dispatchLoadAllConversions({
      rowSize: tableRowSize,
      page: pagination.page,
      value: searchValue,
      groupBy: groupBy,
      startDate: dates.modifiedDate.startDate,
      endDate: dates.modifiedDate.endDate,
      sortBy: sorting.sort === "DESC" ? "ASC" : "DESC",
      sortName: name,
    });
  };

  const toggleColumnsModal = () => setShowColumnModal(!showColumnModal);

  const loadCharts = async () =>
    setShowCharts((prevState) => ({
      showCharts: !chartsData.showCharts,
    }));

  React.useEffect(async () => {
    setShowCharts((prevState) => ({
      ...prevState,
      chartsData: props.chartsAllDataLoaded,
    }));
  }, [props.chartsAllDataLoaded]);

  React.useEffect(async () => {
    if (chartsData.showCharts) {
      props?.dispatchLoadChartsData({
        accessToken: props.accessToken,
        dates: JSON.parse(localStorage.getItem("search")).modifiedDate,
      });
    }
  }, [chartsData.showCharts]);

  React.useEffect(() => {
    setLoader(true);
    props.dispatchLoadAllConversions({
      rowSize: 50,
      page: 0,
      value: "",
      groupBy: groupBy,
      startDate: dates.modifiedDate.startDate,
      endDate: dates.modifiedDate.endDate,
      sortBy: sorting.sort,
      sortName: sorting.order,
    });
    // let pageMenuHeight = Number(
    //   document.getElementById("page_menu").clientHeight
    // );
    // let pageFullHeight = Number(
    //   document.getElementsByTagName("html")[0].clientHeight
    // );
    // setPageHeight(() => pageFullHeight - pageMenuHeight - 127);
  }, []);

  React.useEffect(() => {
    Boolean(!tableData.length) && setTableData(props?.conversionData ?? []);
    Boolean(props?.conversionData) && setLoader(false);
    if (props?.conversionData) {
      setShowNoRecordFound(true);
    } else {
      setShowNoRecordFound(false);
    }
  }, [props]);

  React.useEffect(() => {
    return () => props.dispatchInitState();
  }, []);

  return (
    <Fragment>
      <Layout>
        <div id="page_container" className="page_container">
          <div id="page_menu" className="page_menu_bar">
            {loader && <LoadingPanel />}
            <PageMenuBarTop
              setSearchValue={setSearchValue}
              searchValue={searchValue}
              groupBy={groupBy}
              setGroupByValue={setGroupByValue}
              dates={dates}
              applyDates={applyDates}
              setDates={setDates}
              timeRangeApply={timeRangeApply}
              RemoveSearchAndReset={RemoveSearchAndReset}
              apply={Apply}
              refresh={Refresh}
              setShowCharts={loadCharts}
            />
            {chartsData?.showCharts ? (
              <ResponsiveMenuCharts
                data={chartsData.chartsData}
                dates={JSON.parse(localStorage.getItem("search")).modifiedDate}
              />
            ) : null}
            <PageMenuBarBottom
              handleStep={handleStep}
              changeRowSize={changeRowSize}
              pagination={pagination}
              handlePagination={handlePagination}
              allDataLoaded={tableData}
              searchValue={searchValue}
              toggleColumnsModal={toggleColumnsModal}
              tableRowSize={tableRowSize}
            />
          </div>
          <div
            // style={{ height: pageContentHeight }}
            style={{ height: `calc(100% - 85px - 127px)`}}
            className="temporary_affiliate_network_page_data"
          >
            <GridClassComponent
              pageType="Conversion"
              gridHeight={pageContentHeight}
              allDataLoaded={tableData}
              isAllDataLoaded={Boolean(tableData.length)}
              paginationSize={50}
              changeSorting={HandleSorting}
              searchValue={searchValue}
              sorting={sorting}
              showColumnModal={showColumnModal}
              toggleColumnsModal={toggleColumnsModal}
              noRecordFound={noRecordFound}
            />
          </div>
        </div>
      </Layout>
    </Fragment>
  );
};

export const mapStateToProps = createStructuredSelector({
  accessToken: makeSelectToken(),
  conversionData: makeSelectLoadConversionData(),
  chartsAllDataLoaded: makeSelectAllChartsDataLoaded(),
});
export function mapDispatchToProps(dispatch) {
  return {
    dispatchLoadAllConversions: (...payload) =>
      dispatch(loadAllConversions(...payload)),
    dispatchLoadLastRowsFromConversion: (...payload) =>
      dispatch(loadLastRowsFromConversion(...payload)),
    dispatchSearch: (...payload) => dispatch(searchedValue(...payload)),
    dispatchInitState: (...payload) => dispatch(loadInitState(...payload)),
    dispatchLoadChartsData: (...payload) =>
      dispatch(loadChartsData(...payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Conversion);

function ResponsiveMenuCharts(props) {
  const [dimensions, setDimensions] = React.useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  })
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    
}

    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)
    
}
  })
  return <>{//<div>{window.innerWidth}</div>
  }
  <MenuCharts data={props.data} dates={props.dates}/>
  </>

}