import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import MenuButton from "../../../components/header/menuButtons/menuButtons";
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import "./headerMenuBar.css";
import { makeSelectLoggedIn, makeSelectIsUserAdmin } from "../../../registration/LoginRegister/selectors";
class HeaderMenuBar extends Component {
    render () {
        return (
            <div className="header_menu_bar">
                <NavLink exact to={{
                    pathname: "/settings/"
                }}
                         activeClassName="menu-active"
                >
                    <MenuButton
                        icon={"k-i-gears k-i-cogs"}
                        buttonText="General Settings"
                        classNameForButton="normal_button menu_button"
                        onClickMethod={this.menuButtonClickedMethod}
                    />
                </NavLink>
                <NavLink exact to={{
                    pathname: "/settings/profile"
                }}
                         activeClassName="menu-active"
                >
                    <MenuButton
                        icon={"k-icon k-i-user"}
                        buttonText="Profile"
                        classNameForButton="normal_button menu_button"
                        onClickMethod={this.menuButtonClickedMethod}
                    />
                </NavLink>
                <NavLink exact to={{
                    pathname: "/settings/domains"
                }}
                         activeClassName="menu-active"
                >
                    <MenuButton
                        icon={"k-icon k-i-user"}
                        buttonText="Domains"
                        classNameForButton="normal_button menu_button"
                        onClickMethod={this.menuButtonClickedMethod}
                    />
                </NavLink>
                <NavLink exact to={{
                    pathname: "/settings/tracking-urls"
                }}
                         activeClassName="menu-active"
                >
                    <MenuButton
                        icon={"k-icon k-i-user"}
                        buttonText="Tracking URLs"
                        classNameForButton="normal_button menu_button"
                        onClickMethod={this.menuButtonClickedMethod}
                    />
                </NavLink>
                <NavLink exact to={{
                    pathname: "/settings/collaboration-tools/workspace"
                }}
                         activeClassName="menu-active"
                >
                    <MenuButton
                        icon={"k-icon k-i-user"}
                        buttonText="Collaboration Tools"
                        classNameForButton="normal_button menu_button"
                        onClickMethod={this.menuButtonClickedMethod}
                    />
                </NavLink>
                {/* <NavLink exact to={{
                    pathname: "/settings/notifications/"
                }}
                         activeClassName="menu-active"
                >
                    <MenuButton
                        icon={"k-icon k-i-notification"}
                        buttonText="Notifications"
                        classNameForButton="normal_button menu_button"
                        onClickMethod={this.menuButtonClickedMethod}
                    />
                </NavLink> */}
                <NavLink exact to={{
                    pathname: "/settings/ip-ua-filtering/"
                }}
                         activeClassName="menu-active"
                >
                    <MenuButton
                        icon={"k-icon k-i-filter-clear"}
                        buttonText="IP/UA Filtering"
                        classNameForButton="normal_button menu_button"
                        onClickMethod={this.menuButtonClickedMethod}
                    />
                </NavLink>
                <NavLink exact to={{
                    pathname: "/settings/event-log/"
                }}
                         activeClassName="menu-active"
                >
                    <MenuButton
                        icon={"k-icon k-i-calendar"}
                        buttonText="Event log"
                        classNameForButton="normal_button menu_button"
                        onClickMethod={this.menuButtonClickedMethod}
                    />
                </NavLink>
                {this.props.isUserAdmin && 
                    <NavLink exact to={{
                        pathname: "/settings/users/"
                    }}
                            activeClassName="menu-active"
                    >
                        <MenuButton
                            icon={"k-icon k-i-user"}
                            buttonText="Users"
                            classNameForButton="normal_button menu_button"
                            onClickMethod={this.menuButtonClickedMethod}
                        />
                    </NavLink>
                }
            </div>
        );
    }
}

export const mapStateToProps = createStructuredSelector(
    {
      isLoggedIn: makeSelectLoggedIn(),
      isUserAdmin: makeSelectIsUserAdmin(),
  });
//   export function mapDispatchToProps(dispatch) {
//     return {
      
//     };
//   }
  
  export default withRouter(connect(mapStateToProps, null)(HeaderMenuBar));