import React, {useState} from "react";
import produce from "immer";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import "./delete.css";
// import {Fade} from "@progress/kendo-react-animation";

class DeletePageModal extends React.Component {
    state = {
        type: ""
    }
    componentDidMount() {
        if (this.props.state.archiveType === "Archive") {
            this.setState({type: "Archive" });
        } else if (this.props.state.archiveType === "Restore") {
            this.setState({type: "Restore" });
        } else if (this.props.state.archiveType === "Delete") {
            this.setState({type: "Delete" });
        }
    }

    render () {
        return (
            <div>
                <Dialog
                    className="delete_dialogue"
                    title={(this.state.type) + " lander"}
                    onClose={() => this.props.toggleLanderDialogue("Close_Delete_Toggle_Lander")}
                >
                    <div className="add_affiliate_network_dialogue_content">
                        <div className="content_form">
                            {
                                this.props.state.selectedListReceivedFromGrid !== null && this.props.state.selectedListReceivedFromGrid.length < 2 && this.props.state.selectedListReceivedFromGrid.length > 0
                                    ?
                                    <div>
                                        <div className="row">
                                            <h4 className="h4 black">
                                                <span className="bold">{(this.state.type)} selected element/s?</span></h4>
                                        </div>
                                        <div className="row">
                                            {
                                                this.state.type === "Archive"
                                                    ? <p>Lander/s with archived elements will still receive traffic and can be restored at any moment.</p> : null
                                            }
                                            {
                                                this.state.type === "Restore"
                                                    ? <p>Lander/s will be restored to full functionality.</p> : null
                                            }
                                            {
                                                this.state.type === "Delete"
                                                    ? <p>This will permanently delete the selected Lander/s You will no longer be able to revert this action.</p> : null
                                            }
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                    <DialogActionsBar>
                        <div className="action_bar_buttons">
                            <div className="left">
                                <button onClick={() => this.props.toggleLanderDialogue("Close_Delete_Toggle_Lander")} className="normal_btn cancel_button gray_button_bg_imp black border_radius_normal_btn normal_text black no_border">Cancel</button>
                            </div>
                            <div className="right">
                                <button
                                    onClick={
                                        this.state.type === "Delete"
                                            ?
                                            this.props.deleteLanderOnSubmit
                                    :
                                            () => this.props.archiveLanderHandler(this.state.type)
                                    }
                                    className="normal_btn theme_dark_btn theme_dark_bg_color_imp color_white_imp border_radius_normal_btn save_button normal_text">{this.state.type}</button>
                            </div>
                        </div>
                    </DialogActionsBar>
                </Dialog>
            </div>
        );
    }
};

export default DeletePageModal;