export const LOAD_TRACKING_URLS = "LOAD_TRACKING_URLS";
export const LOAD_TRACKING_URLS_SUCCESS = "LOAD_TRACKING_URLS_SUCCESS";
export const LOAD_TRACKING_URLS_FAILED = "LOAD_TRACKING_URLS_FAILED";

export const LOAD_CURRENCY = "LOAD_CURRENCY";
export const LOAD_CURRENCY_SUCCESS = "LOAD_CURRENCY_SUCCESS";
export const LOAD_CURRENCY_FAILED = "LOAD_CURRENCY_FAILED";

export const POST_NEW_AFFILIATE_NETWORK_DATA = "POST_NEW_AFFILIATE_NETWORK_DATA";
export const POST_NEW_AFFILIATE_NETWORK_DATA_SUCCESS = "POST_NEW_AFFILIATE_NETWORK_DATA_SUCCESS";
export const POST_NEW_AFFILIATE_NETWORK_DATA_FAILED = "POST_NEW_AFFILIATE_NETWORK_DATA_FAILED";

export const LOAD_AFFILIATE_NETWORK_DATA = "LOAD_AFFILIATE_NETWORK_DATA";
export const LOAD_AFFILIATE_NETWORK_DATA_SUCCESS = "LOAD_AFFILIATE_NETWORK_DATA_SUCCESS";
export const LOAD_AFFILIATE_NETWORK_DATA_FAILED = "LOAD_AFFILIATE_NETWORK_DATA_FAILED";

export const EDIT_AFFILIATE_NETWORK_DATA = "EDIT_AFFILIATE_NETWORK_DATA";
export const EDIT_AFFILIATE_NETWORK_DATA_SUCCESS = "EDIT_AFFILIATE_NETWORK_DATA_SUCCESS";
export const EDIT_AFFILIATE_NETWORK_DATA_FAILED = "EDIT_AFFILIATE_NETWORK_DATA_FAILED";


export const EDIT_AFFILIATE_NETWORK_DATA_SENT = "EDIT_AFFILIATE_NETWORK_DATA_SENT";
export const EDIT_AFFILIATE_NETWORK_DATA_SENT_SUCCESS = "EDIT_AFFILIATE_NETWORK_DATA_SENT_SUCCESS";
export const EDIT_AFFILIATE_NETWORK_DATA_SENT_FAILED = "EDIT_AFFILIATE_NETWORK_DATA_SENT_FAILED";

export const DELETE_AFFILIATE_NETWORK_DATA = "DELETE_AFFILIATE_NETWORK_DATA";
export const DELETE_AFFILIATE_NETWORK_DATA_SUCCESS = "DELETE_AFFILIATE_NETWORK_DATA_SUCCESS";
export const DELETE_AFFILIATE_NETWORK_DATA_FAILED = "DELETE_AFFILIATE_NETWORK_DATA_FAILED";

export const ARCHIVE_AFFILIATE_NETWORK_DATA = "ARCHIVE_AFFILIATE_NETWORK_DATA";
export const ARCHIVE_AFFILIATE_NETWORK_DATA_SUCCESS = "ARCHIVE_AFFILIATE_NETWORK_DATA_SUCCESS";
export const ARCHIVE_AFFILIATE_NETWORK_DATA_FAILED = "ARCHIVE_AFFILIATE_NETWORK_DATA_FAILED";

export const LOAD_CUSTOM_AFFILIATE_NETWORK = "LOAD_CUSTOM_AFFILIATE_NETWORK";
export const LOAD_CUSTOM_AFFILIATE_NETWORK_SUCCESS = "LOAD_CUSTOM_AFFILIATE_NETWORK_SUCCESS";
export const LOAD_CUSTOM_AFFILIATE_NETWORK_FAILED = "LOAD_CUSTOM_AFFILIATE_NETWORK_FAILED";

export const LOAD_CUSTOM_AFFILIATE_NETWORK_BY_ID = "LOAD_CUSTOM_AFFILIATE_NETWORK_BY_ID";
export const LOAD_CUSTOM_AFFILIATE_NETWORK_BY_ID_SUCCESS = "LOAD_CUSTOM_AFFILIATE_NETWORK_BY_ID_SUCCESS";
export const LOAD_CUSTOM_AFFILIATE_NETWORK_BY_ID_FAILED = "LOAD_CUSTOM_AFFILIATE_NETWORK_BY_ID_FAILED";

export const GET_AFFNETWORK_CHARTS_DATA = "GET_AFFNETWORK_CHARTS_DATA";
export const GET_AFFNETWORK_CHARTS_DATA_SUCCESS = "GET_AFFNETWORK_CHARTS_DATA_SUCCESS";
export const GET_AFFNETWORK_CHARTS_DATA_FAILED = "GET_AFFNETWORK_CHARTS_DATA_FAILED";

export const CLOSE_EDIT_DIALOGUE = "CLOSE_EDIT_DIALOGUE";
export const RESET_CUSTOM_DATA_BY_ID = "RESET_CUSTOM_DATA_BY_ID";
