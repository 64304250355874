import { checkFilters } from "../../../components/checkNamesForGrouping/checkNames"; 
import { returnTimeZoneId } from "../../../components/timeZonesExchange";
import * as requestHandler from "../../helpers/requestHandlers";
import {
    PATH_LOAD_COUNTRIES_LIST,
    PATH_CAMPAIGNS,
    PATH_GROUPING,
    PATH_CAMPAIGNS_ARCHIVED,
    PATH_LOAD_TOKENS_DICTIONARY,
    PATH_LOAD_TAGS,
    PATH_CAMPAIGNS_CHART,
    PATH_LOAD_TIMEZONES_LIST,
    PATH_CAMPAIGNS_MANUAL_COST,
    PATH_LOAD_CURRENCY_LIST,
} from "../../helpers/path";

export const LoadCountriesListFromAPI = async (request) => {
    const response = await requestHandler.get(PATH_LOAD_COUNTRIES_LIST);
    return response;
};

export const PostNewCampaignFromAPI = async (request) => {
    const data = request.payload.data;
    const response = await requestHandler.post(PATH_CAMPAIGNS, data);
    return response;
};

export const LoadAllCampaignsFromAPI = async (request) => {
    let endDate = request.payload.searchQuery.modifiedDate.endDate;
    let startDate = request.payload.searchQuery.modifiedDate.startDate;

    let timeZoneInLocalStorage = request.payload.searchQuery.dates.timeZone;
    let timeZoneIdToSend = returnTimeZoneId(timeZoneInLocalStorage);

    let filtersToSend = checkFilters(request.payload.searchQuery.groupingValues.main,request.payload.searchQuery.groupingValues.group1,request.payload.searchQuery.groupingValues.group2);

    let parameters = "?filter="+ request.payload.searchQuery.inputSearchValue + "&from="+ startDate +"&to="+ endDate +"&tags="+request.payload.searchQuery.tagsSearchValue+"&main="+ filtersToSend[0] + "&group1="+ filtersToSend[1] + "&group2="+ filtersToSend[2] + "&order=" + request.payload.sorting.order + "&sort=" + request.payload.sorting.sort + "&skip=" + request.payload.searchQuery.pagination.skip + "&take=" + request.payload.searchQuery.pagination.take + "&workspace=" + request.payload.searchQuery.selectedWorkspacesList + "&timezone=" + timeZoneIdToSend + "&status=" + request.payload.searchQuery.filterStatus;
    if (request.payload.primaryPage) {
        const response = await requestHandler.get(PATH_GROUPING + parameters);
        return response;
    } else {
        const response = await requestHandler.get(PATH_CAMPAIGNS + parameters);
        return response;
    }
};

export const LoadEditCampaignFromAPI = async (request) => {
    const response = await requestHandler.get(PATH_CAMPAIGNS + request.payload.loadEditCampaignId);
    return response;
};

export const SendEditCampaignFromAPI = async (request) => {
    const data = request.payload.data;
    let url = PATH_CAMPAIGNS + request.payload.sendEditCampaignId;
    const response = await requestHandler.patch(url, data);
    return response;
};

export const DeleteCampaignFromAPI = async (request) => {
    const response = await requestHandler.delet(PATH_CAMPAIGNS + request.payload.campaignId);
    return response;
};

export const ArchiveCampaignFromAPI = async (request) => {
    let url = PATH_CAMPAIGNS_ARCHIVED + request.payload.campaignId;
    const response = await requestHandler.post(url,{ value: request.payload.value});
    return response;
};

export const LoadTokensDictionaryFromAPI = async (request) => {
    const response = await requestHandler.get(PATH_LOAD_TOKENS_DICTIONARY);
    return response;

};

export const LoadSearchTagsFromAPI = async (request) => {
    const response = await requestHandler.get(PATH_LOAD_TAGS);
    return response;
};

export const LoadChartsDataFromAPI = async (request) => {
    let search = JSON.parse(localStorage.getItem('search'));
    let timeZoneInLocalStorage = search.dates.timeZone;
    let timeZoneIdToSend = returnTimeZoneId(timeZoneInLocalStorage);
    let endDate = request.payload.dates.endDate;
    let startDate = request.payload.dates.startDate;
    let parameters = "to=" + endDate + "&from=" + startDate + "&workspace=" + search.selectedWorkspacesList + "&timezone=" + timeZoneIdToSend;
    let url = PATH_CAMPAIGNS_CHART + parameters;
    const response = await requestHandler.get(url);
    return response;
};

// Get time zones data
export const TimeZonesDataFromAPI = async (request) => {
    const response = await requestHandler.get(PATH_LOAD_TIMEZONES_LIST);
    return response;
};

// Send update cost data
export const ManualCostUpdateDataFromAPI = async (request) => {
    const response = await requestHandler.post(PATH_CAMPAIGNS_MANUAL_COST + request.payload.campaignId, request.payload.data);
    return response;
};

// Get currencies list data
export const LoadManualCostCurrencyDataFromAPI = async (request) => {
    const response = await requestHandler.get(PATH_LOAD_CURRENCY_LIST);
    return response;
};