import moment from "moment-timezone";

export const getSearchParameters = () => {
    if (JSON.parse(localStorage.getItem("search")) && JSON.parse(localStorage.getItem("search")).filterStatus) {
        return JSON.parse(localStorage.getItem("search"));
    } else {
        let search = {
            selectedWorkspacesList: [],
            tagsListReceived: [],
            inputSearchValue: "",
            tagsSearchValue: [],
            isTagsSearchSelected: false,
            groupingValues: {
                main: "",
                group1: "",
                group2: ""
            },
            dates: {
                startDate: moment(new Date()).tz("Pacific/Kwajalein"),
                endDate: moment(new Date()).tz("Pacific/Kwajalein"),
                timeZone: "Pacific/Kwajalein",
            },
            modifiedDate: {
                startDate: moment(new Date()).tz("Pacific/Kwajalein").format("MM/DD/YYYY hh:mm A"),
                endDate: moment(new Date()).tz("Pacific/Kwajalein").format("MM/DD/YYYY hh:mm A")
            },
            isFiltersChanged: false,
            pagination: {
                skip: 0,
                take: 50,
            },
            activeTab: 0,
            report: {
                reportCount: 0,
                report1: {
                    name: "",
                    search: {
                        inputSearchValue: "",
                        isTagsSearchSelected: false,
                        reportGroupingValues: {
                            id: "",
                            filter1: "",
                            filter2: "",
                            filter3: "",
                            filter4: "",
                            reportType: ""
                        },
                        sorting: {
                            order: "visits",
                            sort: "ASC"
                        },
                        modifiedDate: {
                            startDate: moment(new Date()).tz("Pacific/Kwajalein").format("MM/DD/YYYY hh:mm A"),
                            endDate: moment(new Date()).tz("Pacific/Kwajalein").format("MM/DD/YYYY hh:mm A")
                        },
                        date: {
                            startDate: moment(new Date()).tz("Pacific/Kwajalein"),
                            endDate: moment(new Date()).tz("Pacific/Kwajalein"),
                            timeZone: "Pacific/Kwajalein",
                        },
                    },
                    appliedGroupingValues: {
                        id: "",
                        filter1: "",
                        filter2: "",
                        filter3: "",
                        filter4: "",
                        reportType: ""
                    },
                    disable: {
                        filter3: false,
                        filter4: false
                    },
                    activeIcons: {
                        campaign: false,
                        offers: false,
                        affilateNetwork: false,
                        flow: false,
                        trafficSource: false,
                        lander: false,
                        path: false,
                    },
                    menuButtonsActive: [
                        {name: "campaign", activeStatus: false},
                        {name: "offers", activeStatus: false},
                        {name: "flow", activeStatus: false},
                        {name: "affilateNetwork", activeStatus: false},
                        {name: "trafficSource", activeStatus: false},
                        {name: "lander", activeStatus: false},
                        {name: "path", activeStatus: false},
                    ],
                    fullGroupingList: [],
                },
                report2: {
                    name: "",
                    search: {
                        inputSearchValue: "",
                        isTagsSearchSelected: false,
                        reportGroupingValues: {
                            id: "",
                            filter1: "",
                            filter2: "",
                            filter3: "",
                            filter4: "",
                            reportType: ""
                        },
                        sorting: {
                            order: "visits",
                            sort: "ASC"
                        },
                        modifiedDate: {
                            startDate: moment(new Date()).tz("Pacific/Kwajalein").format("MM/DD/YYYY hh:mm A"),
                            endDate: moment(new Date()).tz("Pacific/Kwajalein").format("MM/DD/YYYY hh:mm A")
                        },
                        date:  {
                            startDate: moment(new Date()).tz("Pacific/Kwajalein"),
                            endDate: moment(new Date()).tz("Pacific/Kwajalein"),
                            timeZone: "Pacific/Kwajalein",
                        },
                    },
                    appliedGroupingValues: {
                        id: "",
                        filter1: "",
                        filter2: "",
                        filter3: "",
                        filter4: "",
                        reportType: ""
                    },
                    disable: {
                        filter3: false,
                        filter4: false
                    },
                    activeIcons: {
                        campaign: false,
                        offers: false,
                        affilateNetwork: false,
                        flow: false,
                        trafficSource: false,
                        lander: false,
                        path: false,
                    },
                    menuButtonsActive: [
                        {name: "campaign", activeStatus: false},
                        {name: "offers", activeStatus: false},
                        {name: "flow", activeStatus: false},
                        {name: "affilateNetwork", activeStatus: false},
                        {name: "trafficSource", activeStatus: false},
                        {name: "lander", activeStatus: false},
                        {name: "path", activeStatus: false},
                    ],
                    fullGroupingList: [],
                },
                report3: {
                    name: "",
                    search: {
                        inputSearchValue: "",
                        isTagsSearchSelected: false,
                        reportGroupingValues: {
                            id: "",
                            filter1: "",
                            filter2: "",
                            filter3: "",
                            filter4: "",
                            reportType: ""
                        },
                        sorting: {
                            order: "visits",
                            sort: "ASC"
                        },
                        modifiedDate: {
                            startDate: moment(new Date()).tz("Pacific/Kwajalein").format("MM/DD/YYYY hh:mm A"),
                            endDate: moment(new Date()).tz("Pacific/Kwajalein").format("MM/DD/YYYY hh:mm A")
                        },
                        date:  {
                            startDate: moment(new Date()).tz("Pacific/Kwajalein"),
                            endDate: moment(new Date()).tz("Pacific/Kwajalein"),
                            timeZone: "Pacific/Kwajalein",
                        },
                    },
                    appliedGroupingValues: {
                        id: "",
                        filter1: "",
                        filter2: "",
                        filter3: "",
                        filter4: "",
                        reportType: ""
                    },
                    disable: {
                        filter3: false,
                        filter4: false
                    },
                    activeIcons: {
                        campaign: false,
                        offers: false,
                        affilateNetwork: false,
                        flow: false,
                        trafficSource: false,
                        lander: false,
                        path: false,
                    },
                    menuButtonsActive: [
                        {name: "campaign", activeStatus: false},
                        {name: "offers", activeStatus: false},
                        {name: "flow", activeStatus: false},
                        {name: "affilateNetwork", activeStatus: false},
                        {name: "trafficSource", activeStatus: false},
                        {name: "lander", activeStatus: false},
                        {name: "path", activeStatus: false},
                    ],
                    fullGroupingList: [],
                }
            },
            setting: {
                home_screen: "dashboard",
                timeZoneId: 6
            },
            filterStatus: "all"
        };
        localStorage.setItem("search",JSON.stringify(search));
        return search;
    }
}

export const formatDate = (date) => {
    return (date.getMonth()+1) + "/" + date.getDate() + "/" + date.getFullYear();
}
export const setDates = (data) => {
    return {
        startDate: data.startDate + ", 0:00:00 AM",
        endDate: data.endDate + ", 11:59:59 PM"
    }
}

export const setInitialDates = (date) => {
    let dateInitial = formatDate(date);
    return {
        startDate: dateInitial + ", 0:00:00 AM",
        endDate: dateInitial + ", 11:59:59 PM"
    }
}