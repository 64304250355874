import { call, put, takeLatest } from "redux-saga/effects";
import {
    LOAD_TRACKING_URLS,
    LOAD_CURRENCY,
    POST_NEW_AFFILIATE_NETWORK_DATA,
    LOAD_AFFILIATE_NETWORK_DATA,
    EDIT_AFFILIATE_NETWORK_DATA,
    EDIT_AFFILIATE_NETWORK_DATA_SENT,
    DELETE_AFFILIATE_NETWORK_DATA,
    ARCHIVE_AFFILIATE_NETWORK_DATA,
    LOAD_CUSTOM_AFFILIATE_NETWORK,
    LOAD_CUSTOM_AFFILIATE_NETWORK_BY_ID,
    GET_AFFNETWORK_CHARTS_DATA
} from "./constants";
import {
    loadTrackingURLSSuccess,
    loadTrackingURLSFailed,
    loadCurrencySuccess,
    loadCurrencyFailed,
    postNewAffiliateNetworkSuccess,
    postNewAffiliateNetworkFailed,
    loadAffiliateNetworkSuccess,
    loadAffiliateNetworkFailed,
    editAffiliateNetworkSuccess,
    editAffiliateNetworkFailed,
    sendEditAffiliateNetworkSuccess,
    sendEditAffiliateNetworkFailed,
    deleteAffiliateNetworkSuccess,
    deleteAffiliateNetworkFailed,
    archiveAffiliateNetworkSuccess,
    archiveAffiliateNetworkFailed,
    loadCustomAffiliateNetworkSuccess,
    loadCustomAffiliateNetworkFailed,
    loadCustomAffiliateNetworkByIdSuccess,
    loadCustomAffiliateNetworkByIdFailed,
    loadAffNetworkChartsDataSuccess,
    loadAffNetworkChartsDataFailed
} from "./actions";

import {
    LoadTrackingUrlsFromAPI,
    LoadCurrenciesListFromAPI,
    postNewAffiliateNetworkDataFromAPI,
    loadAffiliateNetworkDataFromAPI,
    editAffiliateNetworkDataFromAPI,
    sendEditAffiliateNetworkDataFromAPI,
    deleteAffiliateNetworkDataFromAPI,
    loadAllCustomAffiliateNetworkFromAPI,
    loadCustomAffiliateNetworkByIdFromAPI,
    archiveAffiliateNetworkDataFromAPI,
    LoadAffNetworkChartsDataFromAPI
} from "./api";

function* loadTrackingUrls(payload) {
    try {
        const response = yield call(LoadTrackingUrlsFromAPI, payload);
        yield put(
            loadTrackingURLSSuccess(response.data)
        );
    } catch (error) {
        yield put(loadTrackingURLSFailed(error));
    }
}

function* loadCurrenciesList(payload) {
    try {
        const response = yield call(LoadCurrenciesListFromAPI, payload);
        yield put(
            loadCurrencySuccess(response.data)
        );
    } catch (error) {
        yield put(loadCurrencyFailed(error));
    }
}

function* postNewAffiliateNetworkData(payload) {
    try {
        const response = yield call(postNewAffiliateNetworkDataFromAPI, payload);
        yield put(
            postNewAffiliateNetworkSuccess(response.data)
        );
    } catch (error) {
        yield put(postNewAffiliateNetworkFailed(error));
    }
}

function* loadAffiliateNetworkData(payload) {
    try {
        const response = yield call(loadAffiliateNetworkDataFromAPI, payload);
        yield put(
            loadAffiliateNetworkSuccess(response.data)
        );
    } catch (error) {
        yield put(loadAffiliateNetworkFailed(error));
    }
}

function* editAffiliateNetworkData(payload) {
    try {
        const response = yield call(editAffiliateNetworkDataFromAPI, payload);
        yield put(
            editAffiliateNetworkSuccess(response.data)
        );
    } catch (error) {
        yield put(editAffiliateNetworkFailed(error));
    }
}

function* sendEditAffiliateNetworkData(payload) {
    try {
        const response = yield call(sendEditAffiliateNetworkDataFromAPI, payload);
        yield put(
            sendEditAffiliateNetworkSuccess(response.data)
        );
    } catch (error) {
        yield put(sendEditAffiliateNetworkFailed(error));
    }
}

function* deleteAffiliateNetworkData(payload) {
    try {
        const response = yield call(deleteAffiliateNetworkDataFromAPI, payload);
        yield put(
            deleteAffiliateNetworkSuccess(response.data)
        );
    } catch (error) {
        yield put(deleteAffiliateNetworkFailed(error));
    }
}

function* archiveAffiliateNetworkData(payload) {
    try {
        const response = yield call(archiveAffiliateNetworkDataFromAPI, payload);
        yield put(
            archiveAffiliateNetworkSuccess(response.data)
        );
    } catch (error) {
        yield put(archiveAffiliateNetworkFailed(error));
    }
}

function* loadAllCustomAffiliateNetwork(payload) {
    try {
        const response = yield call(loadAllCustomAffiliateNetworkFromAPI, payload);
        yield put(loadCustomAffiliateNetworkSuccess(response.data));
    }
    catch (error) {
        yield put(loadCustomAffiliateNetworkFailed(error));
    }
}

function* loadCustomAffiliateNetworkById(payload) {
    try {
        const response = yield call(loadCustomAffiliateNetworkByIdFromAPI, payload);
        yield put(loadCustomAffiliateNetworkByIdSuccess(response.data));
    }
    catch (error) {
        yield put(loadCustomAffiliateNetworkByIdFailed(error));
    }
}

function* loadAffNetworkChartsData(payload) {
    try {
        const response = yield call(LoadAffNetworkChartsDataFromAPI, payload);
        yield put(
            loadAffNetworkChartsDataSuccess(response.data)
        );
    } catch (error) {
        yield put(loadAffNetworkChartsDataFailed(error));
    }
}
export default function* affiliateNetworksSaga() {
    yield takeLatest(LOAD_TRACKING_URLS, loadTrackingUrls);
    yield takeLatest(LOAD_CURRENCY, loadCurrenciesList);
    yield takeLatest(POST_NEW_AFFILIATE_NETWORK_DATA, postNewAffiliateNetworkData);
    yield takeLatest(LOAD_AFFILIATE_NETWORK_DATA, loadAffiliateNetworkData);
    yield takeLatest(EDIT_AFFILIATE_NETWORK_DATA, editAffiliateNetworkData);
    yield takeLatest(EDIT_AFFILIATE_NETWORK_DATA_SENT, sendEditAffiliateNetworkData);
    yield takeLatest(DELETE_AFFILIATE_NETWORK_DATA, deleteAffiliateNetworkData);
    yield takeLatest(ARCHIVE_AFFILIATE_NETWORK_DATA,archiveAffiliateNetworkData);
    yield takeLatest(LOAD_CUSTOM_AFFILIATE_NETWORK, loadAllCustomAffiliateNetwork);
    yield takeLatest(LOAD_CUSTOM_AFFILIATE_NETWORK_BY_ID, loadCustomAffiliateNetworkById);
    yield takeLatest(GET_AFFNETWORK_CHARTS_DATA, loadAffNetworkChartsData);
}