import produce from 'immer';
import {
    LOAD_EVENTLOG, LOAD_EVENTLOG_SUCCESS, LOAD_EVENTLOG_FAILED,
    LOAD_EVENTLOG_DETAILS_FAILED,LOAD_EVENTLOG_DETAILS,LOAD_EVENTLOG_DETAILS_SUCCESS
} from './constants';

export const initialState = {
    loading: false,
    success: false,
    error: false,
    eventlogData: null,
    isEventLogDataLoaded: false,
    eventlogDetailData: null,
    isEventLogDetailDataLoaded: false,
}

const eventlogReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case LOAD_EVENTLOG:
                draft.loading = true;
                draft.success = false;
                draft.error = false;
                return draft;
            case LOAD_EVENTLOG_SUCCESS:
                draft.loading = false;
                draft.success = true;
                draft.error = false;
                draft.eventlogData = action.payload.data;
                draft.isEventLogDataLoaded = true;
                return draft;
            case LOAD_EVENTLOG_FAILED:
                draft.loading = false;
                draft.success = false;
                draft.error = false;
                draft.eventlogData = null;
                draft.isEventLogDataLoaded = false;
                return draft;
            case LOAD_EVENTLOG_DETAILS:
                draft.loading = true;
                draft.success = false;
                draft.error = false;
                return draft;
            case LOAD_EVENTLOG_DETAILS_SUCCESS:
                draft.loading = false;
                draft.success = true;
                draft.error = false;
                draft.eventlogDetailData = action.payload.data;
                draft.isEventLogDetailDataLoaded = true;
                return draft;
            case LOAD_EVENTLOG_DETAILS_FAILED:
                draft.loading = false;
                draft.success = false;
                draft.error = false;
                draft.eventlogDetailData = null;
                draft.isEventLogDetailDataLoaded = false;
                return draft;
            default:
                return draft;
            
         }
    })


export default eventlogReducer;
