export const ADD_PRIVATE_WORKSPACE = "ADD_PRIVATE_WORKSPACE";
export const ADD_PRIVATE_WORKSPACE_SUCCESS = "ADD_PRIVATE_WORKSPACE_SUCCESS";
export const ADD_PRIVATE_WORKSPACE_FAILED = "ADD_PRIVATE_WORKSPACE_FAILED";

export const EDIT_PRIVATE_WORKSPACE = "EDIT_PRIVATE_WORKSPACE";
export const EDIT_PRIVATE_WORKSPACE_SUCCESS = "EDIT_PRIVATE_WORKSPACE_SUCCESS";
export const EDIT_PRIVATE_WORKSPACE_FAILED = "EDIT_PRIVATE_WORKSPACE_FAILED";

export const DELETE_PRIVATE_WORKSPACE = "DELETE_PRIVATE_WORKSPACE";
export const DELETE_PRIVATE_WORKSPACE_SUCCESS = "DELETE_PRIVATE_WORKSPACE_SUCCESS";
export const DELETE_PRIVATE_WORKSPACE_FAILED = "DELETE_PRIVATE_WORKSPACE_FAILED";

export const LOAD_PRIVATE_WORKSPACE = "LOAD_PRIVATE_WORKSPACE";
export const LOAD_PRIVATE_WORKSPACE_SUCCESS = "LOAD_PRIVATE_WORKSPACE_SUCCESS";
export const LOAD_PRIVATE_WORKSPACE_FAILED = "LOAD_PRIVATE_WORKSPACE_FAILED";

