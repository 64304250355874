import React from "react";
import { Splitter } from "@progress/kendo-react-layout";
import LeftFlow from "../../../../NewFlows/PageModal/ModalForm/LeftPane/index";
import RightFlow from "../../../../NewFlows/PageModal/ModalForm/RightPane/index";
import RuleBased from "../../../../NewFlows/PageModal/ModalForm/RightPane/RuleBased/index";
import NewPath from "../../../../NewFlows/PageModal/ModalForm/NewPath/index";
import { Fade } from '@progress/kendo-react-animation';


const FlowModalForm = (props) => {
    // console.log('this.props.state',props.state.dataToSend);
    return (
        <div>
            <Splitter
                style={{ height: "inherit" }}
                panes={props.state.panes}
                onChange={props.onChangePane}
            >
                <div>
                    <LeftFlow
                        state={props.state}
                        addNewRule={props.addNewRule}
                        deleteRule={props.deleteRule}
                        duplicateRule={props.duplicateRule}
                        addNewFlow={props.addNewFlow}
                        newRuleClicked={props.newRuleClicked}
                        addNewPath={props.addNewPath}
                        addDefaultPath={props.addDefaultPath}
                        defaultPathClicked={props.defaultPathClicked}
                        deleteDefaultPath={props.deleteDefaultPath}
                        duplicateDefaultPath={props.duplicateDefaultPath}
                        newRulePathClicked={props.newRulePathClicked}
                        handleSwitchChange={props.handleSwitchChange}
                        decreaseDefaultPathWeightMethod={props.decreaseDefaultPathWeightMethod}
                        increaseDefaultPathWeightMethod={props.increaseDefaultPathWeightMethod}
                        onChangePathSwitch={props.onChangePathSwitch}
                        inputChangeHandler={props.inputChangeHandler}
                        onSort={props.onSort}
                    />
                </div>
                {props.state.onFlowClick ? (
                    <div>
                        <RightFlow
                            state={props.state}
                            inputChangeHandler={props.inputChangeHandler}
                            handleSwitchChange={props.handleSwitchChange}
                        />
                    </div>
                ) : props.state.onRuleClick ? (
                    <div>
                        <RuleBased
                                state={props.state}
                                onRuleNameChange={props.onRuleNameChange}
                                addConditions={props.addConditions}
                                showConditionValues={props.showConditionValues}
                                deleteAllConditions={props.deleteAllConditions}
                                showOptions={props.showOptions}
                                deleteOneCondition={props.deleteOneCondition}
                                editRuleCondition={props.editRuleCondition}
                                showCondtionsName={props.showCondtionsName}

                        />
                    </div>
                    ) : props.state.onPathClick ? (
                        <div>
                            <NewPath
                                state={props.state}
                                landersDataReceived={props.landersDataReceived}
                                offersDataReceived={props.offersDataReceived}
                                handleDefaultPathChange={props.handleDefaultPathChange}
                                handleRulePathChange={props.handleRulePathChange}
                                addNewOffer={props.addNewOffer}
                                addLandersAndOffersMethod={props.addLandersAndOffersMethod}
                                increaseWeightMethod={props.increaseWeightMethod}
                                decreaseWeightMethod={props.decreaseWeightMethod}
                                weightageSelectTagChangeHandler={props.weightageSelectTagChangeHandler}
                                deleteWeightageMethod={props.deleteWeightageMethod}
                                changeWeightageHandler={props.changeWeightageHandler}
                            />
                    </div>  
                        ) : <div>
                                {props.state.success_msg.length > 0 ?
                                    <Fade enter={true} exit={true}>
                                        <p className="success_para k-mt-2 mb-10 mh-0">{props.state.success_msg}</p>
                                    </Fade> : 
                                    <Fade enter={true} exit={true}>
                                        <p className="danger_para k-mt-2 mb-10 mh-0">{props.state.warning_msg}</p>
                                    </Fade>
                                }
                            </div>
                }
                
            </Splitter>
        </div>
    );
};

export default FlowModalForm;
