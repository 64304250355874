import React from "react";

import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet"
import "./features.css"
import { useEffect, useState } from 'react';
import { classNames } from "@progress/kendo-react-common";

//const scripts =["../js/features/headroom.min.js","../js/features/jquery.min.js","../js/features/slick.min.js","../js/features/scripts.js"]




export default function Features(){
    function activateCard(x) {
        document.getElementById(`features-card${x}`).classList.add("active-box")    
    }
    function desactivateCard(x) {
        document.getElementById(`features-card${x}`).classList.remove("active-box")
     }
     function toggleActivateCard(x) {
        document.getElementById(`features-card${x}`).classList.toggle("active-box")
     }

    return(

            <section className="features-section" id="features">        
                
                <Helmet>
                    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"></meta>
                    
                   {
                    //<script type='text/javascript' src='./js/features/jquery.min.js' id='jquery-core-js'></script>
                   // <script type='text/javascript' src='./js/features/headroom.min.js' id='headroom-js'></script>
                   // <script type='text/javascript' src='./js/features/scripts.js' id='scripts-js'></script>
                   // <script type='text/javascript' src='./js/features/slick.min.js' id='slick-js'></script>
                    }

                    <link rel="preconnect" href="https://fonts.googleapis.com"/>
                   <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
                    <link href="https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet"/>
                </Helmet>    

                <div className="features-page" >
    <div className="site-container">
        <header className="headroom">
            

        </header>


        <div className="site-content" >

            <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>    
                <div className="features-text">
                <h1>Tellmobi Features</h1>
                <p>Tellmobi offers a range of powerful features for campaign management, including rule-based paths for targeted traffic, split testing for offers and landing pages, and custom domain tracking. With pre-set templates for traffic sources and affiliate networks, and a comprehensive reporting system, Tellmobi makes it easy to optimize your campaigns for maximum ROI.</p>
                </div>
            </div> 

            <div className="container-1 home-container">

    <section className="section features-section">


        <div className="section-content features" >
            <span className="bg features" data-0="transform: translateY(0px)" data-100000="transform: translateY(-20000px)"></span>
            <ul className="row-1 feature-list">
                <li className="col-md-4 col-sm-6" id="features-card"  >
                    <div className="item">
                        <div className="content">
                            <div className="icon ai"></div>
                            <div className="title">Campaign Management</div>
                            <div className="desc">
                                <p>Tellmobi's platform for advanced campaign management enables effortless control over your online advertising with options for campaigns, traffic sources, landing pages, affiliate networks, and offers.</p>
                                <span>Campaigns:</span>
                                <ul>
                                    <li>Choose basic or advanced campaign creation options</li>
                                    <li>Select from 4 cost models (CPC, CPM, CPA, RevShare) and input cost values</li>
                                </ul>
                                <span>Traffic Sources:</span>
                                <ul>
                                    <li>Configure traffic source element to set up campaigns</li>
                                    <li>Choose from preset templates or add a custom traffic source</li>
                                    <li>Add tokens to enable custom tracking parameters</li>
                                </ul>
                                <span>Landing Pages:</span>
                                <ul>
                                    <li>Prepare and host a standalone landing page</li>
                                    <li>Direct visitors from ads to landing page using campaign URL</li>
                                    <li>Incorporate Click URLs to redirect visitors to offers</li>
                                    <li>Use Click URLs to link to one or multiple offers</li>
                                </ul>
                                <span>Affiliate Networks:</span>
                                <ul>
                                    <li>Add custom affiliate networks or access preset templates</li>
                                    <li>Link networks to offers</li>
                                    <li>Define s2s postback tokens and payout currency</li>
                                </ul>
                                <span>Offers:</span>
                                <ul>
                                    <li>Associate offers with specific networks</li>
                                    <li>Enter offer URLs and corresponding tokens</li>
                                    <li>Incorporate [clickid] token to track conversions using redirect method and postback URL</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </li>
                <li className="col-md-4 col-sm-6" id="features-card1" >
                    <div className="item">
                        <div className="content">
                            <div className="icon bot"></div>
                            <div className="title">Reports & Insights</div>
                            <div className="desc">
                            <p>To achieve efficient optimization, obtaining the necessary information is crucial. To do so, it is imperative to define the appropriate data sets. In Tellmobi, you can access a required data set through two primary methods: drill-down and grouping. Knowing the dissimilarities between these reporting types will enable you to access the desired information efficiently.</p>
                                <strong>Grouping vs Drill-Down<br/></strong>
                                <span>Grouping:</span>
                                <ul>
                                    <li>Applicable to both global and specific reports.</li>
                                    <li>Displays statistics that match all selected grouping criteria.</li>
                                    <li>Allows up to three grouping options to be selected.</li>
                                </ul>
                                <span>Drill-down:</span>
                                <ul>
                                    <li>Only available for specific reports.</li>
                                    <li>Displays data for selected elements only.</li>
                                    <li>Allows up to three levels of detail to be viewed.</li>
                                    <li>Displays up to 20 custom variable tokens dynamically replaced from your traffic source
                                         during the redirection from the ad to the destination page, 
                                         for viewing data on.</li>
                                </ul>
                                <span>Available Reporting Conditions:</span>
                                    <p>Affiliate network, Brand, Browser, Browser Version, Campaign, City, Connection type, Conversion, 
                                        Country, Day, Day of week, Device type, Flows, Hour of day, ISP, Lander, Language,
                                         Models, Month, Offers, OS, OS Version, Proxy, Region, Time zone & Traffic source.
                                    </p>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="col-md-4 col-sm-6" id="features-card2" >
                    <div className="item">
                        <div className="content">
                            <div className="icon redirects"></div>
                            <div className="title">Conversion Tracking</div>
                            <div className="desc">
                                <p>By tracking conversions, you'll have visibility into the amount of revenue you've
                                     generated and gain insight into the performance of your campaigns.</p>
                                <p>You can choose to use S2S postback URL conversion tracking 
                                    or Pixel Tracking to monitor the performance of your offers, traffic sources,
                                     landing pages, and campaigns.</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="col-md-4 col-sm-6" id="features-card3" >
                    <div className="item">
                        <div className="content">
                            <div className="icon scaling"></div>
                            <div className="title">Paths & Flows</div>
                            <div className="desc">
                                <span>Default Path(s):</span>
                                <p>In a campaign flow, a default path is necessary and does not include any 
                                    traffic-targeting options. It follows the same pattern as rule-based paths,
                                     except for the rule configuration. At least one default path is required to
                                      ensure consistency in the setup. This default path is where all traffic 
                                      that does not match any criteria will be redirected. If you opt not to 
                                      add any rule-based paths, all traffic will be directed along the default 
                                      path(s).</p>
                                <span>Rule Based Path(s):</span>
                                <p>While adding a rule-based path is optional, it can help you target your traffic more effectively. In the Rules-based paths section of the campaign creation form or flow, you can define paths that meet specific constraints. For instance, if you want to display your offer exclusively on mobile devices, you can define this constraint as a rule in Tellmobi. Tellmobi provides a range of conditions that you can apply to your flows, allowing you to optimize your campaign funnels.</p>
                                <p>Available Rule-Based Path Conditions with Logical Condition Selection of “IS” and “IS NOT”:
Brand, Browser, City, Connection Type, Country Device type, ISP, IP, Language, Mobile Carrier, Operating System, Proxy, Region, Referrer, Referrer Domain, Time of day, Weekday & up to 20 custom variable tokens dynamically replaced from your traffic source during the redirection from the ad to the destination page.</p>

                                <span>Flows:</span>
                                <p>A flow is a predetermined collection of campaign destination paths, offering the ability to assign a distinct redirect mode for each path. When setting up your campaign's destination, you can use the flow as either a fixed set of destination paths or a template that can be customized by adding or editing the destination paths.</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="col-md-4 col-sm-6" id="features-card4" >
                    <div className="item">
                        <div className="content">
                            <div className="icon domains"></div>
                            <div className="title">Split Testing</div>
                            <div className="desc">
                                <p>Easily split test paths, rule-based paths, offers, and landing pages in your campaign by defining a weight value. The weight value determines the percentage of traffic distribution, always totaling 100%. Elements added to the campaign are automatically assigned a default weight of 100 to ensure even traffic distribution. You can change the weight value manually to reflect the proportion of traffic distribution, such as increasing the weight of a better performing offer to distribute traffic at a higher ratio. Adjusting weight values is typically done after the campaign's testing period to ensure accurate measurement of path performance.</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="col-md-4 col-sm-6" id="features-card5" >
                    <div className="item">
                        <div className="content">
                            <div className="icon world"></div>
                            <div className="title">Redirects & Bot Filters</div>
                            <div className="desc">
                                <span>Redirect Options:</span>
                                <p>Redirect modes in Tellmobi determine two things. First, how redirection to the campaign endpoint or offer is handled. Second, whether referrer data is passed through or not. The available redirect options in Tellmobi include direct, 302, meta refresh, and double meta refresh.</p>
                                <span>Bot & User Agent Filters:</span>
                                <p>Tellmobi offers IP/UA Filtering, which enables you to add filtering rules based on IP/IP range and user agent values. This functionality allows you to exclude the traffic defined within the filtering rules from accessing your campaigns, providing you with better insight into the real live campaign events.</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="col-md-4 col-sm-6" id="features-card6" >
                    <div className="item">
                        <div className="content">
                            <div className="icon pixel"></div>
                            <div className="title">Real-Time Data</div>
                            <div className="desc">
                                <p>You can access real-time live data to analyze and monitor your campaigns. In addition, you have the ability to pull reports at specific times of the day and set specific dates, giving you a better understanding of your campaign performance. You can view reports and data based on different time zones, making it easier to work with teams across different regions. Moreover, you can choose from pre-set date options or set a custom range and time to pull reports and analyze your data. With these features, you can better track your campaign performance and make informed decisions to optimize your campaigns.</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="col-md-4 col-sm-6" id="features-card7" >
                    <div className="item">
                        <div className="content">
                            <div className="icon funnel"></div>
                            <div className="title">Dedicated & Custom Domains</div>
                            <div className="desc">
                                <p>With Tellmobi, you can either use the Dedicated Default Domain or add a Custom Domain as a tracking domain. The Custom Domain option enables you to track traffic only from your campaigns and not get mixed up with other users' activities. Additionally, by adding a Custom Domain, you can selectively customize the campaign's click URLs and tracking pixel URLs using your preferred domain. This feature can be set up per campaign, giving you the flexibility to personalize your campaigns.</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="col-md-4 col-sm-6" id="features-card8" >
                    <div className="item">
                        <div className="content">
                            <div className="icon access"></div>
                            <div className="title">Workspaces</div>
                            <div className="desc">
                                <p>You can organize your work in Tellmobi by creating separate workspace groups, which helps you manage your data more effectively. Each workspace group can have different access levels to your data, allowing you to control who can view or modify certain information. This feature helps you to streamline your workflow and ensures that each team member has access to the information they need to complete their tasks efficiently.</p>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </section>



            </div>

        </div>





    </div>

                </div>




                
                


    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"/>


            </section>
            )
}
