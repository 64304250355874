import { LOAD_DASHBOARD_CHART_DATA, LOAD_DASHBOARD_CHART_DATA_FAILED, LOAD_DASHBOARD_CHART_DATA_SUCCESS, LOAD_DASHBOARD_DATA, LOAD_DASHBOARD_DATA_FAILED, LOAD_DASHBOARD_DATA_SUCCESS, LOAD_DASHBOARD_SUMMARY, LOAD_DASHBOARD_SUMMARY_FAILED, LOAD_DASHBOARD_SUMMARY_SUCCESS } from "./constants";


import produce from "immer";

export const initialState = {
    loading: false,
    success: false,
    error: false,
    allDashboardData: null,
    isAllDashboardDataLoaded: false,
    dashboardSummary: null,
    isDashboardSummaryLoaded: false,
    isDashboardChartsDataLoaded: false,
    allDashboardChartsDataLoaded: null
};

const dashboardReducer = (state = initialState, action) => produce(state, (draft => {
    switch (action.type) {
        case LOAD_DASHBOARD_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.isAllDashboardDataLoaded = false;
            return draft;
        case LOAD_DASHBOARD_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.allDashboardData = action.payload.dashboardDataSuccess;
            draft.isAllDashboardDataLoaded = true;
            return draft;
        case LOAD_DASHBOARD_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            draft.isAllDashboardDataLoaded = false;
            return draft;
        case LOAD_DASHBOARD_SUMMARY:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.isDashboardSummaryLoaded = false;
            return draft;
        case LOAD_DASHBOARD_SUMMARY_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.dashboardSummary = action.payload.dashboardSummarySuccess;
            draft.isDashboardSummaryLoaded = true;
            return draft;
        case LOAD_DASHBOARD_SUMMARY_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            draft.isDashboardSummaryLoaded = false;
            return draft;

        case LOAD_DASHBOARD_CHART_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.isDashboardChartsDataLoaded = false;
            return draft;
        case LOAD_DASHBOARD_CHART_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.allDashboardChartsDataLoaded = action.payload.chartsDataSuccess;
            draft.isDashboardChartsDataLoaded = true;
            return draft;
        case LOAD_DASHBOARD_CHART_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            draft.isDashboardChartsDataLoaded = false;
            return draft;
        default:
            return draft
    }
}))

export default dashboardReducer;