// LoginRegister constants file
export const LOGIN_USER = 'app/Authentication/LoginRegister/LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'app/Authentication/LoginRegister/LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'app/Authentication/LoginRegister/LOGIN_USER_FAILED';

export const REGISTER_USER = 'app/Authentication/LoginRegister/REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'app/Authentication/LoginRegister/REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'app/Authentication/LoginRegister/REGISTER_USER_ERROR';

export const FORGOT_PASSWORD = 'app/Authentication/LoginRegister/FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'app/Authentication/LoginRegister/FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'app/Authentication/LoginRegister/FORGOT_PASSWORD_ERROR';


export const LOGOUT_USER = 'app/Authentication/LoginRegister/LOGOUT_USER';
export const RESET_USER = 'app/Authentication/LoginRegister/RESET_USER';
