import React from "react";
import ReactTooltip from "react-tooltip";
import { ComboBox } from "@progress/kendo-react-dropdowns";

const DestinationFlow = (props) => {
    return (
        <section>
            <div className=" flex_row " style={{ display: "flex", alignItems: "baseline" }}>
                <h3>Flow</h3>
                <span data-tip data-for="destination_flow_tooltip" className="question_mark">
                    <span className="k-icon k-i-question-circle"></span>
                </span>
                <ReactTooltip id="destination_flow_tooltip" type="dark">
                    <span>
                        It is a predefined set of campaign destination paths. You cannot edit it
                        unless you convert the flow to paths.
                    </span>
                </ReactTooltip>
            </div>
            {/*<ComboBox*/}
            {/*    className="destination-combobox"*/}
            {/*    data={props.flowsDataReceived}*/}
            {/*    filterable={true}*/}
            {/*    onFilterChange={props.filterChange}*/}
            {/*    textField="name"*/}
            {/*    dataItemKey="id"*/}
            {/*    name="flowId"*/}
            {/*    value={props.state.filterData}*/}
            {/*    onChange={props.inputChangeHandler}*/}
            {/*    placeholder="Select flow"*/}
            {/*    required*/}
            {/*/>*/}
            <select
                name="flowId"
                id="flowId"
                onChange={props.inputChangeHandler}
                value={props.state.dataToSend.flowId}
                className="add_custom_domain_dropdown normal_text normal_font five_hun"
            >
                <option value="">Select Flow</option>
                {
                    props.flowsDataReceived.map((flow) => (
                        <option value={flow.id}>{flow.name}</option>
                    ))
                }
            </select>
        </section>
    );
};

export default DestinationFlow;
