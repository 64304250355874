import * as requestHandler from "../../helpers/requestHandlers";
import {
	PATH_LOAD_COUNTRIES_LIST,
    PATH_FLOWS,
	PATH_FLOWS_SHORT,
    PATH_FLOWS_ARCHIVED,
    PATH_FLOWS_CHART,
    PATH_GROUPING,
	PATH_OF_PATH,
	PATH_RULE,
	PATH_CONDITION_BRAND,
	PATH_CONDITION_BROWSER,
	PATH_CONDITION_CONNECTION_TYPE,
	PATH_CONDITION_COUNTRY,
	PATH_CONDITION_DEVICE_TYPE,
	PATH_CONDITION_LANGUAGE,
	PATH_CONDITION_MOBILE_CARRIER,
	PATH_CONDITION_OS,
	PATH_CONDITION_PROXY,
	PATH_CONDITION_REGION,
	PATH_CONDITION_CITY,
	PATH_CONDITION_CITY_ALL,
	PATH_CONDITION_ISP,
	PATH_CONDITION_ISP_ALL,
	PATH_CONDITION_WEEKDAY,
} from "../../helpers/path"; 
import { checkFilters } from '../../../components/checkNamesForGrouping/checkNames';
import { returnTimeZoneId } from '../../../components/timeZonesExchange';


export const loadCountriesApi = async (request) => {
	const response = await requestHandler.get(PATH_LOAD_COUNTRIES_LIST);
	return response;
};


export const newAddFlowsApi = async (request) => {	
	const response = await requestHandler.post(PATH_FLOWS, request.flowData);
	return response;
};

export const loadFlowsApi = async (request) => {
	let endDate = request.searchData.modifiedDate.endDate;
    let startDate = request.searchData.modifiedDate.startDate;

	let timeZoneInLocalStorage = request.searchData.dates.timeZone;
    let timeZoneIdToSend = returnTimeZoneId(timeZoneInLocalStorage);

	let filtersToSend = checkFilters(request.searchData.groupingValues.main, request.searchData.groupingValues.group1, request.searchData.groupingValues.group2);

    let parameters = "?filter="+ request.searchData.inputSearchValue + "&date=&from="+ startDate+"&to="+endDate +"&tags="+request.searchData.tagsSearchValue+"&main="+ filtersToSend[0] + "&group1="+ filtersToSend[1] + "&group2="+ filtersToSend[2] + "&order=" + request.sorting.order + "&sort=" + request.sorting.sort + "&skip=" + request.searchData.pagination.skip + "&take=" + request.searchData.pagination.take + "&workspace=" + request.searchData.selectedWorkspacesList + "&timezone=" + timeZoneIdToSend + "&status=" + request.searchData.filterStatus;
    if (request.primaryPage) {
		const response = await requestHandler.get(PATH_GROUPING + parameters);
		return response;
	} else {
		if (request.dataType && request.dataType.length > 0) {
			const response = await requestHandler.get(PATH_FLOWS_SHORT + parameters);
			return response;
		} else {
			const response = await requestHandler.get(PATH_FLOWS + parameters);
			return response;
		}
	}
};


export const editFlowsApi = async (request) => {
	const response = await requestHandler.get(PATH_FLOWS + request.editFlowId);
	return response;
};

export const updateFlowsApi = async (request) => {
	const response = await requestHandler.patch((PATH_FLOWS + request.editFlowData.editId), request.editFlowData.dataToSend);
	return response;
};


export const deleteFlowsApi = async (request) => {
	const response = await requestHandler.delet(PATH_FLOWS + request.deleteFlowId);
	return response;
};

export const archiveFlowsApi = async (request) => {
    let url = PATH_FLOWS_ARCHIVED + request.archiveFlowData.flowId;
	const response = await requestHandler.post(url,{ value: request.archiveFlowData.value});
	return response;
};


export const deleteDefaultPathApi = async (request) => {
	const response = await requestHandler.delet(PATH_OF_PATH + request.id);
	return response;
};

export const deleteRuleApi = async (request) => {
	const response = await requestHandler.delet(PATH_RULE + request.id);
	return response;
};

export const LoadFlowChartsDataFromAPI = async (request) => {
	let search = JSON.parse(localStorage.getItem('search'));
    let timeZoneInLocalStorage = search.dates.timeZone;
    let timeZoneIdToSend = returnTimeZoneId(timeZoneInLocalStorage);
	let endDate = request.payload.dates.endDate;
	let startDate = request.payload.dates.startDate;
	let parameters = "to=" + endDate + "&from=" + startDate + "&workspace=" + search.selectedWorkspacesList + "&timezone=" + timeZoneIdToSend;

	const response = await requestHandler.get(PATH_FLOWS_CHART + parameters);
	return response;
};



//-----------------------------------------------------------------------------------------------------
//conditions api
export const getCityApi = async (request) => {
  	try {
		const response = await requestHandler.get(`${PATH_CONDITION_CITY}?key=${request.data}`);
		return response;
	} catch (err) {
		console.log('errors', err);
		return err;
	}
};

export const getMCApi = async (request) => {
  	try {
		const response = await requestHandler.get(`${PATH_CONDITION_MOBILE_CARRIER}?key=${request.data}`);
		return response;
	} catch (err) {
		console.log('errors', err);
		return err;
	}
};

export const getAllCityApi = async (request) => {
	try {
		const response = await requestHandler.get(`${PATH_CONDITION_CITY_ALL}`);
		return response;
	} catch (err) {
		console.log('errors', err);
		return err;
	}
};

export const getWeekDayApi = async (request) => {
  	try {
		const response = await requestHandler.get(`${PATH_CONDITION_WEEKDAY}`);
		return response;
	} catch (err) {
		console.log('errors', err);
		return err;
	}
};


export const getCountryApi = async (request) => {
	try {
		const response = await requestHandler.get(`${PATH_CONDITION_COUNTRY}?key=${request.data}`);
		return response;
	} catch (err) {
		console.log('errors', err);
		return err;
	}
};

export const getISPApi = async (request) => {
  	try {
		const response = await requestHandler.get(`${PATH_CONDITION_ISP}?key=${request.data}`);
		return response;
	} catch (err) {
		console.log('errors', err);
		return err;
	}
};

export const getAllISPApi = async (request) => {
  	try {
		const response = await requestHandler.get(`${PATH_CONDITION_ISP_ALL}`);
		return response;
	} catch (err) {
		console.log('errors', err);
		return err;
	}
};

export const addCityApi = async (request) => {
  try {
		const response = await requestHandler.post(PATH_CONDITION_CITY, request.data);
		return response;
	} catch (err) {
		console.log('errors', err);
		return err;
	}
};

export const getRegionApi = async (request) => {
  	try {
		const response = await requestHandler.get(PATH_CONDITION_REGION);
		return response;
	} catch (err) {
		console.log('errors', err);
		return err;
	}
};

export const addRegionApi = async (request) => {
  	try {
		const response = await requestHandler.post(PATH_CONDITION_REGION, request.data);
		return response;
	} catch (err) {
		console.log('errors', err);
		return err;
	}
};

export const getProxyApi = async (request) => {
  	try {
		const response = await requestHandler.get(PATH_CONDITION_PROXY);
		return response;
	} catch (err) {
		console.log('errors', err);
		return err;
	}
};

export const addProxyApi = async (request) => {
  	try {
		const response = await requestHandler.post(PATH_CONDITION_PROXY, request.data);
		return response;
	} catch (err) {
		console.log('errors', err);
		return err;
	}
};

export const getOperatingSystemApi = async (request) => {
  	try {
		const response = await requestHandler.get(PATH_CONDITION_OS);
		return response;
	} catch (err) {
		console.log('errors', err);
		return err;
	}
};

export const addOperatingSystemApi = async (request) => {
  	try {
		const response = await requestHandler.post(PATH_CONDITION_OS, request.data);
		return response;
	} catch (err) {
		console.log('errors', err);
		return err;
	}
};


export const getLanguageApi = async (request) => {
  	try {
		const response = await requestHandler.get(PATH_CONDITION_LANGUAGE);
		return response;
	} catch (err) {
		console.log('errors', err);
		return err;
	}
};

export const addLanguageApi = async (request) => {
  	try {
		const response = await requestHandler.post(PATH_CONDITION_LANGUAGE, request.data);
		return response;
	} catch (err) {
		console.log('errors', err);
		return err;
	}
};

export const getDeviceTypeApi = async (request) => {
  	try {
		const response = await requestHandler.get(PATH_CONDITION_DEVICE_TYPE);
		return response;
	} catch (err) {
		console.log('errors', err);
		return err;
	}
};

export const addDeviceTypeApi = async (request) => {
  	try {
		const response = await requestHandler.post(PATH_CONDITION_DEVICE_TYPE, request.data);
		return response;
	} catch (err) {
		console.log('errors', err);
		return err;
	}
};

export const getConnectionTypeApi = async (request) => {
  	try {
		const response = await requestHandler.get(PATH_CONDITION_CONNECTION_TYPE);
		return response;
	} catch (err) {
		console.log('errors', err);
		return err;
	}
};

export const addConnectionTypeApi = async (request) => {
  	try {
		const response = await requestHandler.post(PATH_CONDITION_CONNECTION_TYPE, request.data);
		return response;
	} catch (err) {
		console.log('errors', err);
		return err;
	}
};


export const getBrowserApi = async (request) => {
  	try {
		const response = await requestHandler.get(PATH_CONDITION_BROWSER);
		return response;
	} catch (err) {
		console.log('errors', err);
		return err;
	}
};

export const addBrowserApi = async (request) => {
  	try {
		const response = await requestHandler.post(PATH_CONDITION_BROWSER, request.data);
		return response;
	} catch (err) {
		console.log('errors', err);
		return err;
	}
};

export const getBrandApi = async (request) => {
  	try {
		const response = await requestHandler.get(PATH_CONDITION_BRAND);
		return response;
	} catch (err) {
		console.log('errors', err);
		return err;
	}
};

export const addBrandApi = async (request) => {
  	try {
		const response = await requestHandler.post(PATH_CONDITION_BRAND, request.data);
		return response;
	} catch (err) {
		console.log('errors', err);
		return err;
	}
};