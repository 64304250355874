import { call, put, takeLatest } from "redux-saga/effects";

import { GET_SUB_LEVEL_DATA, GET_COLUMNS_DATA, PATCH_COLUMNS_DATA } from "./constants";
import {
    getSubLevelDataSuccess, getSubLevelDataFailed,
    getColumnsDataSuccess, getColumnsDataFailed,
    patchColumnsDataSuccess, patchColumnsDataFailed
} from "./actions";
import { GetSubLevelDataFromAPI, GetColumnsDataFromAPI, PatchColumnsDataToAPI } from "./api";

function* getGridSubLevelData(payload) {
    try {
        const response = yield call(GetSubLevelDataFromAPI, payload);
        yield put(
            getSubLevelDataSuccess(response.data)
        );
    } catch (error) {
        yield put(getSubLevelDataFailed(error));
    }
}

function* getGridColumnsData(payload) {
    try {
        const response = yield call(GetColumnsDataFromAPI, payload);
        yield put(
            getColumnsDataSuccess(response.data)
        );
    } catch (error) {
        yield put(getColumnsDataFailed(error));
    }
}

function* patchGridColumnsData(payload) {
    try {
        const response = yield call(PatchColumnsDataToAPI, payload);
        yield put(
            patchColumnsDataSuccess(response.data)
        );
    } catch (error) {
        yield put(patchColumnsDataFailed(error));
    }
}

export default function* GridSaga() {
    yield takeLatest(GET_SUB_LEVEL_DATA, getGridSubLevelData);
    yield takeLatest(GET_COLUMNS_DATA, getGridColumnsData);
    yield takeLatest(PATCH_COLUMNS_DATA, patchGridColumnsData);
}