export const ADD_CUSTOM_DOMAIN = "ADD_CUSTOM_DOMAIN";
export const ADD_CUSTOM_DOMAIN_SUCCESS = "ADD_CUSTOM_DOMAIN_SUCCESS";
export const ADD_CUSTOM_DOMAIN_FAILED = "ADD_CUSTOM_DOMAIN_FAILED";

export const LOAD_CUSTOM_DOMAINS = "LOAD_CUSTOM_DOMAINS";
export const LOAD_CUSTOM_DOMAINS_SUCCESS = "LOAD_CUSTOM_DOMAINS_SUCCESS";
export const LOAD_CUSTOM_DOMAINS_FAILED = "LOAD_CUSTOM_DOMAINS_FAILED";

export const EDIT_CUSTOM_DOMAIN = "EDIT_CUSTOM_DOMAINS";
export const EDIT_CUSTOM_DOMAIN_SUCCESS = "EDIT_CUSTOM_DOMAINS_SUCCESS";
export const EDIT_CUSTOM_DOMAIN_FAILED = "EDIT_CUSTOM_DOMAINS_FAILED";

export const DELETE_CUSTOM_DOMAIN = "DELETE_CUSTOM_DOMAIN";
export const DELETE_CUSTOM_DOMAIN_SUCCESS = "DELETE_CUSTOM_DOMAIN_SUCCESS";
export const DELETE_CUSTOM_DOMAIN_FAILED = "DELETE_CUSTOM_DOMAIN_FAILED";

export const SEND_MAIN_DOMAIN = "SEND_MAIN_DOMAIN";
export const SEND_MAIN_DOMAIN_SUCCESS = "SEND_MAIN_DOMAIN_SUCCESS";
export const SEND_MAIN_DOMAIN_FAILED = "SEND_MAIN_DOMAIN_FAILED";

export const LOAD_REDIRECT_DOMAIN = "LOAD_REDIRECT_DOMAIN";
export const LOAD_REDIRECT_DOMAIN_SUCCESS = "LOAD_REDIRECT_DOMAIN_SUCCESS";
export const LOAD_REDIRECT_DOMAIN_FAILED = "LOAD_REDIRECT_DOMAIN_FAILED";

export const SEND_REDIRECT_DOMAIN = "SEND_REDIRECT_DOMAIN";
export const SEND_REDIRECT_DOMAIN_SUCCESS = "SEND_REDIRECT_DOMAIN_SUCCESS";
export const SEND_REDIRECT_DOMAIN_FAILED = "SEND_REDIRECT_DOMAIN_FAILED";