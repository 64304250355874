export const ADD_FLOWS = "ADD_FLOWS";
export const ADD_FLOWS_SUCCESS = "ADD_FLOWS_SUCCESS";
export const ADD_FLOWS_FAILED = "ADD_FLOWS_FAILED";

export const LOAD_FLOWS = "LOAD_FLOWS";
export const LOAD_FLOWS_SUCCESS = "LOAD_FLOWS_SUCCESS";
export const LOAD_FLOWS_FAILED = "LOAD_FLOWS_FAILED";

export const EDIT_FLOWS = "EDIT_FLOWS";
export const EDIT_FLOWS_SUCCESS = "EDIT_FLOWS_SUCCESS";
export const EDIT_FLOWS_FAILED = "EDIT_FLOWS_FAILED";

export const DELETE_FLOWS = "DELETE_FLOWS";
export const DELETE_FLOWS_SUCCESS = "DELETE_FLOWS_SUCCESS";
export const DELETE_FLOWS_FAILED = "DELETE_FLOWS_FAILED";

export const ARCHIVE_FLOWS = "ARCHIVE_FLOWS";
export const ARCHIVE_FLOWS_SUCCESS = "ARCHIVE_FLOWS_SUCCESS";
export const ARCHIVE_FLOWS_FAILED = "ARCHIVE_FLOWS_FAILED";

export const LOAD_COUNTRIES = "LOAD_COUNTRIES";
export const LOAD_COUNTRIES_SUCCESS = "LOAD_COUNTRIES_SUCCESS";
export const LOAD_COUNTRIES_FAILED = "LOAD_COUNTRIES_FAILED";

export const UPDATE_FLOWS = "UPDATE_FLOWS"
export const UPDATE_FLOWS_SUCCESS = "UPDATE_FLOWS_SUCCESS"
export const UPDATE_FLOWS_FAILED = "UPDATE_FLOWS_FAILED"

export const DELETE_DEFAULT_PATH = "DELETE_DEFAULT_PATH";
export const DELETE_DEFAULT_PATH_SUCCESS = "DELETE_DEFAULT_PATH_SUCCESS";
export const DELETE_DEFAULT_PATH_FAILED = "DELETE_DEFAULT_PATH_FAILED";

export const DELETE_RULE_PATH = "DELETE_RULE_PATH";
export const DELETE_RULE_PATH_SUCCESS = "DELETE_RULE_PATH_SUCCESS";
export const DELETE_RULE_PATH_FAILED = "DELETE_RULE_PATH_FAILED";

export const DELETE_RULE = "DELETE_RULE";
export const DELETE_RULE_SUCCESS = "DELETE_RULE_SUCCESS";
export const DELETE_RULE_FAILED = "DELETE_RULE_FAILED";

export const GET_FLOW_CHARTS_DATA = "GET_FLOW_CHARTS_DATA";
export const GET_FLOW_CHARTS_DATA_SUCCESS = "GET_FLOW_CHARTS_DATA_SUCCESS";
export const GET_FLOW_CHARTS_DATA_FAILED = "GET_FLOW_CHARTS_DATA_FAILED";

//LOAD CONDTIONS

export const LOAD_BRAND = "LOAD_BRAND";
export const LOAD_BRAND_FAILED = "LOAD_BRAND_FAILED";
export const LOAD_BRAND_SUCCESS = "LOAD_BRAND_SUCCESS";

export const LOAD_BROWSER = "LOAD_BROWSER";
export const LOAD_BROWSER_SUCCESS = "LOAD_BROWSER_SUCCESS";
export const LOAD_BROWSER_FAILED = "LOAD_BROWSER_FAILED";

export const LOAD_CONNECTION_TYPE = "LOAD_CONNECTION_TYPE";
export const LOAD_CONNECTION_TYPE_FAILED = "LOAD_CONNECTION_TYPE_FAILED";
export const LOAD_CONNECTION_TYPE_SUCCESS = "LOAD_CONNECTION_TYPE_SUCCESS";

export const LOAD_DEVICE_TYPE = "LOAD_DEVICE_TYPE";
export const LOAD_DEVICE_TYPE_SUCCESS = "LOAD_DEVICE_TYPE_SUCCESS";
export const LOAD_DEVICE_TYPE_FAILED = "LOAD_DEVICE_TYPE_FAILED";

export const LOAD_LANGUAGE = "LOAD_LANGUAGE";
export const LOAD_LANGUAGE_FAILED = "LOAD_LANGUAGE_FAILED";
export const LOAD_LANGUAGE_SUCCESS = "LOAD_LANGUAGE_SUCCESS";

export const LOAD_OPERATING_SYSTEM = "LOAD_OPERATING_SYSTEM";
export const LOAD_OPERATING_SYSTEM_SUCCESS = "LOAD_OPERATING_SYSTEM_SUCCESS";
export const LOAD_OPERATING_SYSTEM_FAILED = "LOAD_OPERATING_SYSTEM_FAILED";

export const LOAD_PROXY = "LOAD_PROXY";
export const LOAD_PROXY_FAILED = "LOAD_PROXY_FAILED";
export const LOAD_PROXY_SUCCESS = "LOAD_PROXY_SUCCESS";

export const LOAD_REGION = "LOAD_REGION";
export const LOAD_REGION_SUCCESS = "LOAD_REGION_SUCCESS";
export const LOAD_REGION_FAILED = "LOAD_REGION_FAILED";

export const LOAD_CITY = "LOAD_CITY";
export const LOAD_CITY_FAILED = "LOAD_CITY_FAILED";
export const LOAD_CITY_SUCCESS = "LOAD_CITY_SUCCESS";

export const LOAD_ALL_CITY = "LOAD_ALL_CITY";
export const LOAD_ALL_CITY_FAILED = "LOAD_ALL_CITY_FAILED";
export const LOAD_ALL_CITY_SUCCESS = "LOAD_ALL_CITY_SUCCESS";

export const LOAD_CONDITION_COUNTRIES = "LOAD_CONDITION_COUNTRIES";
export const LOAD_CONDITION_COUNTRIES_SUCCESS = "LOAD_CONDITION_COUNTRIES_SUCCESS";
export const LOAD_CONDITION_COUNTRIES_FAILED = "LOAD_CONDITION_COUNTRIES_FAILED";

export const LOAD_ISP = "LOAD_ISP";
export const LOAD_ISP_FAILED = "LOAD_ISP_FAILED";
export const LOAD_ISP_SUCCESS = "LOAD_ISP_SUCCESS";

export const LOAD_ALL_ISP = "LOAD_ALL_ISP";
export const LOAD_ALL_ISP_FAILED = "LOAD_ALL_ISP_FAILED";
export const LOAD_ALL_ISP_SUCCESS = "LOAD_ALL_ISP_SUCCESS";

export const LOAD_WEEK_DAY = "LOAD_WEEK_DAY";
export const LOAD_WEEK_DAY_SUCCESS = "LOAD_WEEK_DAY_SUCCESS";
export const LOAD_WEEK_DAY_FAILED = "LOAD_WEEK_DAY_FAILED";

export const LOAD_MOBILE_CARRIER = "LOAD_MOBILE_CARRIER";
export const LOAD_MOBILE_CARRIER_SUCCESS = "LOAD_MOBILE_CARRIER_SUCCESS";
export const LOAD_MOBILE_CARRIER_FAILED = "LOAD_MOBILE_CARRIER_FAILED";

//ADD CONDITIONS

export const ADD_BRAND = "ADD_BRAND";
export const ADD_BRAND_FAILED = "ADD_BRAND_FAILED";
export const ADD_BRAND_SUCCESS = "ADD_BRAND_SUCCESS";

export const ADD_BROWSER = "ADD_BROWSER";
export const ADD_BROWSER_SUCCESS = "ADD_BROWSER_SUCCESS";
export const ADD_BROWSER_FAILED = "ADD_BROWSER_FAILED";

export const ADD_CONNECTION_TYPE = "ADD_CONNECTION_TYPE";
export const ADD_CONNECTION_TYPE_FAILED = "ADD_CONNECTION_TYPE_FAILED";
export const ADD_CONNECTION_TYPE_SUCCESS = "ADD_CONNECTION_TYPE_SUCCESS";

export const ADD_DEVICE_TYPE = "ADD_DEVICE_TYPE";
export const ADD_DEVICE_TYPE_SUCCESS = "ADD_DEVICE_TYPE_SUCCESS";
export const ADD_DEVICE_TYPE_FAILED = "ADD_DEVICE_TYPE_FAILED";

export const ADD_LANGUAGE = "ADD_LANGUAGE";
export const ADD_LANGUAGE_FAILED = "ADD_LANGUAGE_FAILED";
export const ADD_LANGUAGE_SUCCESS = "ADD_BRAND_SUCCESS";

export const ADD_OPERATING_SYSTEM = "ADD_OPERATING_SYSTEM";
export const ADD_OPERATING_SYSTEM_SUCCESS = "ADD_OPERATING_SYSTEM_SUCCESS";
export const ADD_OPERATING_SYSTEM_FAILED = "ADD_OPERATING_SYSTEM_FAILED";

export const ADD_PROXY = "ADD_PROXY";
export const ADD_PROXY_FAILED = "ADD_PROXY_FAILED";
export const ADD_PROXY_SUCCESS = "ADD_PROXY_SUCCESS";

export const ADD_REGION = "ADD_REGION";
export const ADD_REGION_SUCCESS = "ADD_REGION_SUCCESS";
export const ADD_REGION_FAILED = "ADD_REGION_FAILED";

export const ADD_CITY = "ADD_CITY";
export const ADD_CITY_FAILED = "ADD_CITY_FAILED";
export const ADD_CITY_SUCCESS = "ADD_CITY_SUCCESS";



