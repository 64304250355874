import React, {Fragment, useState} from "react";
import { Link } from "react-router-dom";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import EmailInput from "../../../../components/authentication/emailInput/emailInput";
import * as loginRegisterActions from '../../actions'
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import "./forgotPassword.css";
import { emailValidator } from "../../../../validator/validator";
import { makeSelectForgetPasswordUserEmail, makeSelectForgetPasswordError,makeSelectForgetPasswordLoading,makeSelectForgetPassword,makeSelectForgetPasswordSuccess } from "../../selectors";
import {Fade} from "@progress/kendo-react-animation";
import Navbar from "../../../../components/header/navbar";
import { Helmet } from "react-helmet"

// import axios from 'axios';


const ForgetPassword = (props) => {

    const [err, setErr] = useState("");
    const [msg, setMsg] = useState("");
    const handleSubmit = (event) => {
        const email = event.email;
        if (!email) {
            setErr("Please enter email...");
            setMsg("");
        }
        else {
            props.forgetPass(email);
            setErr("");
            setMsg("Please check your email for new password and return to sign in page to log in again.");
        }
    }
  return (
      <Fragment>
                <Helmet>
                    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"></meta>
                </Helmet>    
          <div className="page_container">
              <div className="hide-buttons"><Navbar /></div>
              <div className="form_page_logo_container">
              </div>
              <div className="container">
                <Form
                  onSubmit={handleSubmit}
                  render={(formRenderProps) => (
                    <FormElement className="forget_form_element" style={{ maxWidth: "500px",padding:"40px 30px 30px" }}>
                      <fieldset className={"k-form-fieldset"}>
                        <h2 className="login_h2" style={{color:"hsla(0,0%,100%,.85)",marginTop:"30px",borderBottomColor:"hsla(0,0%,100%,.85)"}}>Reset your password</h2>
                        <div className="mb-3">
                          <Field
                            name={"email"}
                            type={"email"}
                            id="email"
                            component={EmailInput}
                            label={"Email"}
                            validator={emailValidator}
                          />
                        </div>
                      </fieldset>
                      <div className="mb-6">
                        <Button
                          className="half_width forget-pass-btn"
                          type={"submit"}
                          disabled={!formRenderProps.allowSubmit}
                        >
                          Reset Password
                        </Button>
                      </div>
                      <Link className="login_links" href="/login" to="/login"> Return to log in page</Link>
                        <div className="block mb-10"></div>
                        <div className="row block">
                            <p>
                                {
                                    err !== ""
                                        ?
                                        <Fade enter={true} exit={true}>
                                            <p className="danger_para mb-10 mh-0">{err}</p>
                                        </Fade>
                                        : null
                                }
                                {
                                    msg !== ""
                                        ?
                                        <Fade enter={true} exit={true}>
                                            <p className="success_para mb-10 mh-0">{msg}</p>
                                        </Fade>
                                        : null
                                }
                            </p>
                        </div>
                    </FormElement>
                  )}
                  />
              </div>

          </div>
              <div className="homepage">
                <footer id="contact-us">


                        <div className="copy"><div>Copyright © 2023 Tellmobi. All rights reserved.</div><a href="https://nmfmedia.com/" target="_blank"> Part of NMF Media Ltd.</a></div>
                        

                </footer>
              </div>
      </Fragment>
  );
};

export const mapStateToProps = createStructuredSelector(
  {
    forgetPasswordUserEmail: makeSelectForgetPasswordUserEmail(),
    error: makeSelectForgetPasswordError(),
    loading: makeSelectForgetPasswordLoading(),
    success: makeSelectForgetPasswordSuccess(),
    isForgotPass: makeSelectForgetPassword(),
});
export function mapDispatchToProps(dispatch) {
  return {
    forgetPass: (...payload) => dispatch(loginRegisterActions.forgetPass(...payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
