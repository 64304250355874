import produce from "immer";
import {
    LOAD_SETTINGS,
    LOAD_SETTINGS_FAILED,
    LOAD_SETTINGS_SUCCESS,
    UPDATE_SETTINGS,
    UPDATE_SETTINGS_FAILED,
    UPDATE_SETTINGS_SUCCESS,
} from "./constants";

export const initialState = {
    loading: false,
    success: false,
    error: false,
    settingsData: null,
    isSettingsDataLoaded: false,
    isSettingsDataUpdated: false,
};

const settingReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case LOAD_SETTINGS:
                draft.loading = true;
                draft.success = false;
                draft.error = false;
                return draft;
            case LOAD_SETTINGS_SUCCESS:
                // console.log('action.payload',action.payload);
                //new settings success
                draft.loading = false;
                draft.success = true;
                draft.error = false;
                draft.settingsData = action.payload.settingData.data;
                draft.isSettingsDataLoaded = true;
                return draft;
            case LOAD_SETTINGS_FAILED:
                draft.loading = false;
                draft.success = false;
                draft.error = false;
                draft.settingsData = null;
                draft.isSettingsDataLoaded = false;
                return draft;
            case UPDATE_SETTINGS:
                draft.loading = true;
                draft.success = false;
                draft.error = false;
                draft.isSettingsDataUpdated = false;
                return draft;
            case UPDATE_SETTINGS_SUCCESS:
                draft.loading = false;
                draft.success = true;
                draft.error = false;
                draft.isSettingsDataUpdated = true;
                return draft;
            case UPDATE_SETTINGS_FAILED:
                draft.loading = false;
                draft.success = false;
                draft.error = false;
                draft.isSettingsDataUpdated = false;
                return draft;
            default:
                return draft;
        }
    });
export default settingReducer;
