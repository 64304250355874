import React, { Component } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import ReactJson from 'react-json-view';
import produce from 'immer';
import { Input } from '@progress/kendo-react-inputs';

class EventLogModal extends Component {
    state = { 
        visible: this.props.state.visibleEventLogDialog,
    }

    componentDidUpdate() {
        //console.log('this state value', this.state);
    }

    
    toggleDialog = () => {
        this.setState((produce(draft => { draft.visible = !this.state.visible })))
        this.props.closeEventLogModal();
    }
    onItemClick = (event) => {
        event.item.selected = !event.item.selected;
        this.forceUpdate();
    }
    onExpandChange = (event) => {
        event.item.expanded = !event.item.expanded;
        this.forceUpdate();
    }
    
    render() {
        return (
            <div>
                {this.state.visible && (
                    <Dialog className="add_affiliate_networks_dialogue event-log" title={this.props.state.data.entity_type + " - " + this.props.state.data.entity_name} onClose={this.toggleDialog}>
                        <header className="event-log-header">{"Action: " + this.props.state.data.action_type}</header>
                        <section className="custom-search-items">
                            <div className="custom-search-row">
                                <Input  placeholder="Search changes"/>
                                <span className="k-icon k-i-search"/>
                            </div>
                        </section>
                    <div className="add_affiliate_network_dialogue_content">
                        <ReactJson src={this.props.state.data.detail} collapsed={1} displayObjectSize={false}
                            displayDataTypes={false} enableClipboard={false} collapseStringsAfterLength= {50}
                        />
                    </div>
                    <DialogActionsBar>
                        <div className="action_bar_buttons">
                            <div className="right">
                                <button onClick={this.toggleDialog} className="push normal_btn cancel_button gray_button_bg_imp black border_radius_normal_btn normal_text black no_border">Close</button>
                            </div>
                        </div>
                    </DialogActionsBar>
                </Dialog>
                )}
            </div>
        );
    }
}



export default EventLogModal;
 