import jwt_decode from "jwt-decode";
import { logoutAndClear } from "../logoutAndClear";

export const accessTokenExists = () => {
    if (localStorage.getItem('accessToken')) {
        return true;
    } else {
        return false;
    }
};

export const checkAccessTokenExpiry = (logoutUser, auth) => {
    let localAccessToken = localStorage.getItem('accessToken');
    var decoded = jwt_decode(localAccessToken);
    const exp = new Date(decoded.exp * 1000);
    if (new Date(Date.now()) > exp) {
        logoutAndClear(logoutUser, auth);
    } else {
        auth.login(() => {});
    }
};