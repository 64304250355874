import React from "react";
import ReactTooltip from 'react-tooltip';
import { Fade } from '@progress/kendo-react-animation';
import produce from "immer";
import { RadioButton } from '@progress/kendo-react-inputs';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import WeightageComponent from "./WeightageComponent";
import _ from "lodash"
class GeneralTab extends React.Component {
    constructor(props) {
        super(props);
        this.inputCountryTagRef = React.createRef();
        this.inputTSTagRef = React.createRef();
    }

    state = {
        inputTagsWidth: "",
        inputCountryWidth: "",
        elemChanged: false,
        sortedOffers:[]
    }



    // Check if country or traffic source name changed...
    /// make elemChanged to true to adjust padding on the Campaign name input
    setWidthOfInputCountryTag = (e) => {
        this.props.props.countryChangeHandler(e.target.value);
        this.setState(produce(draft => { draft.elemChanged = true }));
    };
    setWidthOfInputTSTag = (e) => {
        this.props.props.tSChangeHandler(e.target.value);
        this.setState(produce(draft => { draft.elemChanged = true }));
    };

    componentDidMount() {
        // Update the document title using the browser API
        // setInputCountryTagWidth(inputCountryTagReg.current.clientWidth + "px");
        // let sortedOffers = _.cloneDeep(this?.props?.props?.state?.dataToMap?.offers)
        // sortedOffers?.length && sortedOffers.sort((first,second) => first.name.localeCompare(second.name));
        // this.setState(produce(draft => { draft.sortedOffers = sortedOffers }));
        setTimeout(() => {
            this.setState(produce(draft => { draft.inputTagsWidth = (this.inputCountryTagRef.current.clientWidth + this.inputTSTagRef.current.clientWidth) + "px" }));
            this.setState(produce(draft => { draft.inputCountryWidth = this.inputCountryTagRef.current.clientWidth + "px" }));
        },200);

        // // populate landers initially in path destination
        // for (let i=0; i<this.props.props.state.landersAddedCounter.length; i++) {
        //
        // }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.elemChanged) {
            this.setState(produce(draft => { draft.inputTagsWidth = (this.inputCountryTagRef.current.clientWidth + this.inputTSTagRef.current.clientWidth) + "px" }));
            this.setState(produce(draft => { draft.inputCountryWidth = this.inputCountryTagRef.current.clientWidth + "px" }));
            this.setState(produce(draft => { draft.elemChanged = false }));
        }

        if (prevProps.props.state.dataToSend.countryId !== this.props.props.state.dataToSend.countryId) {
            this.setState(produce(draft => { draft.elemChanged = true }));
        }

        if (prevProps.props.state.dataToSend.trafficSourceId !== this.props.props.state.dataToSend.trafficSourceId) {
            this.setState(produce(draft => { draft.elemChanged = true }));
        }
    }

    render () {
        console.log("thsiss", this.props)
        return (
            <div className="form_con k-p-5">
                <section>
                    <h3>General</h3>
                    <div className="input_group five_hun">
                        <label htmlFor="affiliate-network-name">
                            Workspace
                            <span data-tip data-for='workspaces_tooltip' className="question_mark">
                                    <span className="k-icon k-i-question-circle"></span>
                                </span>
                            <ReactTooltip id='workspaces_tooltip' type='dark'>
                                <span>Workspace is an isolated area with a set of entities where users with different roles can work on. Assign a workspace to make the lander accessible only by the workspace members.</span>
                            </ReactTooltip>
                        </label>
                        <select
                            className="add_custom_domain_dropdown normal_text normal_font"
                            name="workspaceId"
                            value={this.props.props.state.dataToSend.workspaceId}
                            onChange={this.props.props.simpleInputChangeHandler}
                        >
                            {
                                this.props.props.state.loadedWorkspaces ?
                                    this.props.props.state.loadedWorkspaces.map((workspace) => (
                                        <option key={workspace.id} value={workspace.id}>{workspace.name}</option>
                                    ))
                                    : null
                            }
                        </select>
                        {
                            this.props.props.state.errors.workspaceId !== null
                                ?
                                <Fade enter={true} exit={true}>
                                    <p className="danger_para k-mt-4 mb-10 mh-0">{this.props.props.state.errors.workspaceId}</p>
                                </Fade>
                                : null
                        }
                    </div>
                    <div className="input_group five_hun">
                        <label htmlFor="affiliate-network-name">Traffic Source</label>
                        <select
                            className="add_custom_domain_dropdown normal_text normal_font"
                            name="trafficSource"
                            value={this.props.props.state.dataToSend.trafficSourceId}
                            // onChange={props.countryChangeHandler}
                            onChange={this.setWidthOfInputTSTag}
                        >
                            {
                                this.props.props.state.loadedTrafficSources ?
                                    this.props.props.state.loadedTrafficSources.map((ts) => (
                                        <option key={ts.id} value={ts.id}>{ts.name}</option>
                                    ))
                                    : null
                            }
                        </select>
                        {
                            this.props.props.state.errors.trafficSourceId !== null
                                ?
                                <Fade enter={true} exit={true}>
                                    <p className="danger_para k-mt-4 mb-10 mh-0">{this.props.props.state.errors.trafficSourceId}</p>
                                </Fade>
                                : null
                        }
                        {
                            this.props.props.state.errors.noTrafficSource !== null
                                ?
                                <Fade enter={true} exit={true}>
                                    <p className="danger_para k-mt-4 mb-10 mh-0">{this.props.props.state.errors.noTrafficSource}</p>
                                </Fade>
                                : null
                        }
                    </div>
                    <div className="input_group five_hun">
                        <label htmlFor="affiliate-network-name">
                            Country Tag
                            <span data-tip data-for='country_tooltip' className="question_mark">
                                    <span className="k-icon k-i-question-circle"></span>
                                </span>
                            <ReactTooltip id='country_tooltip' type='dark'>
                                <span>It has only an organizational purpose to group prelanders by its Country label.</span>
                            </ReactTooltip>
                        </label>
                        <select
                            className="add_custom_domain_dropdown normal_text normal_font"
                            name="countryId"
                            value={this.props.props.state.dataToSend.countryId}
                            // onChange={props.countryChangeHandler}
                            onChange={this.setWidthOfInputCountryTag}
                        >
                            {
                                this.props.props.state.loadedCountries ?
                                    this.props.props.state.loadedCountries.map((country) => (
                                        <option key={country.id} value={country.id}>{country.country_name}</option>
                                    ))
                                    : null
                            }
                        </select>
                    </div>
                    <div className="input_group">
                        <label htmlFor="affiliate-network-name">Name</label>
                        <div className="name_con_mix">
                            <span
                                className="normal_text black"
                                ref={this.inputCountryTagRef}
                            >{this.props.props.state.countryName}&nbsp;-&nbsp;</span>
                            <span
                                className="normal_text black"
                                ref={this.inputTSTagRef}
                            >{this.props.props.state.trafficSourceName}&nbsp;-&nbsp;</span>
                            <input
                                type="text"
                                name="name"
                                placeholder=""
                                value={this.props.props.state.dataToSend.name}
                                onChange={this.props.props.simpleInputChangeHandler}
                            />
                            {/*<span*/}
                            {/*    className="lander_name_ts_tag normal_text black"*/}
                            {/*    ref={this.inputTSTagRef}*/}
                            {/*    style={{marginLeft: this.state.inputCountryWidth}}*/}
                            {/*>{this.props.props.state.trafficSourceName}&nbsp;-&nbsp;</span>*/}
                            {/*<span*/}
                            {/*    className="lander_name_country_tag normal_text black"*/}
                            {/*    ref={this.inputCountryTagRef}*/}
                            {/*>{this.props.props.state.countryName}&nbsp;-&nbsp;</span>*/}
                            {/*<input*/}
                            {/*    type="text"*/}
                            {/*    name="name"*/}
                            {/*    placeholder=""*/}
                            {/*    value={this.props.props.state.dataToSend.name}*/}
                            {/*    onChange={this.props.props.simpleInputChangeHandler}*/}
                            {/*    style={{paddingLeft: this.state.inputTagsWidth}}*/}
                            {/*/>*/}
                        </div>
                        {
                            this.props.props.state.errors.name ?
                                <Fade enter={true} exit={true}>
                                    <p className="danger_para mb-10 mh-0">{this.props.props.state.errors.name}</p>
                                </Fade>
                                : null
                        }
                    </div>
                    <div className="five_hun_half_con">
                        <div className="input_group five_hun_half">
                            <label htmlFor="affiliate-network-name">
                                Cost model
                                <span data-tip data-for='cm_tooltip' className="question_mark">
                                    <span className="k-icon k-i-question-circle"></span>
                                </span>
                                <ReactTooltip id='cm_tooltip' type='dark'>
                                    <span>Select the appropriate cost model based on your traffic spend.</span>
                                </ReactTooltip>
                            </label>
                            <select
                                className="add_custom_domain_dropdown normal_text normal_font"
                                name="cost_model"
                                value={this.props.props.state.dataToSend.cost_model}
                                // onChange={props.countryChangeHandler}
                                onChange={this.props.props.costModelSelectChangeHandler}
                            >
                                <option value="Not Tracked">Not Tracked</option>
                                <option value="CPC">CPC</option>
                                <option value="CPM">CPM</option>
                                <option value="CPA">CPA</option>
                                <option value="RevShare">RevShare</option>
                                <option value="Auto (CPI)">Auto (CPI)</option>
                            </select>
                        </div>
                        {
                            (this.props.props.state.dataToSend.cost_model === "Not Tracked" || this.props.props.state.dataToSend.cost_model === "Auto (CPI)")
                            ?
                                null
                                :
                                <div className="input_group five_hun_half">
                                    {
                                        this.props.props.state.dataToSend.cost_model === "RevShare" ?
                                            <label htmlFor="affiliate-network-name">
                                                Percentage Share
                                                <span data-tip data-for='cost_value_tooltip' className="question_mark">
                                            <span className="k-icon k-i-question-circle"></span>
                                        </span>
                                                <ReactTooltip id='cost_value_tooltip' type='dark'>
                                                    <span>A percentage of the offer(s)' payout.</span>
                                                </ReactTooltip>
                                            </label>
                                            :
                                            <label htmlFor="affiliate-network-name">Cost value</label>
                                    }
                                    <div className="inner">
                                        <div className="weightage_inner_con light_gray_border_color">
                                            <div className="input_con">
                                                <input
                                                    name="cost_value"
                                                    className="number normal_text normal_font black k-ml-1 k-mr-1"
                                                    type="string"
                                                    value={this.props.props.state.dataToSend.cost_value}
                                                    onChange={this.props.props.costValueInputChangeHandler}
                                                />
                                            </div>
                                            <div className="numbers_con">
                                                <span
                                                    className="k-icon k-i-minus icon_color_theme_default"
                                                    id="minus"
                                                    onClick={this.props.props.costValueChangeHandler}
                                                ></span>
                                                <span
                                                    className="k-icon k-i-plus icon_color_theme_default"
                                                    id="plus"
                                                    onClick={this.props.props.costValueChangeHandler}
                                                ></span>
                                            </div>
                                        </div>
                                        <div className="right normal_text black">
                                            {
                                                this.props.props.state.dataToSend.cost_model === "RevShare" ? <span>% &nbsp;</span> : null
                                            }
                                            USD
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                    {
                        this.props.props.state.errors.cost_value !== null
                            ?
                            <Fade enter={true} exit={true}>
                                <p className="danger_para k-mt-2 mb-10 mh-0">{this.props.props.state.errors.cost_value}</p>
                            </Fade>
                            : null
                    }
                    {
                        this.props.props.state.errors.on_submit_cost_value !== null
                            ?
                            <Fade enter={true} exit={true}>
                                <p className="danger_para k-mt-2 mb-10 mh-0">{this.props.props.state.errors.on_submit_cost_value}</p>
                            </Fade>
                            : null
                    }
                </section>
                <section>
                    <h3>Transition between ad and campaign funnel</h3>
                    <div className="flex_row">
                        <div className="inline_block k-mr-5">
                            <RadioButton name="campaignMode" value="Redirect" checked={this.props.props.state.dataToSend.campaignMode === 'Redirect'} label="Redirect" onChange={this.props.props.simpleRadioChangeHandler} />
                            <span data-tip data-for='redirect_tooltip' className="question_mark">
                                <span className="k-icon k-i-question-circle"></span>
                            </span>
                            <ReactTooltip id='redirect_tooltip' type='dark'>
                                <span>Tracking through the AffMark's campaign URL. Within this option you can rotate both, landers and offers, in your campaign.</span>
                            </ReactTooltip>
                        </div>
                        <div className="inline_block">
                            <RadioButton name="campaignMode" value="Direct" checked={this.props.props.state.dataToSend.campaignMode === 'Direct'} label="Direct" onChange={this.props.props.simpleRadioChangeHandler} />
                            <span data-tip data-for='direct_tooltip' className="question_mark">
                                <span className="k-icon k-i-question-circle"></span>
                            </span>
                            <ReactTooltip id='direct_tooltip' type='dark'>
                                <span>Tracking through direct tracking URL and pixel without an initial redirect. Lander rotation is not supported, but you can still rotate offers in your campaign.</span>
                            </ReactTooltip>
                        </div>
                        <div className="grey_bg_box five_hun k-p-4 k-mt-3">
                            <div
                                className="flex_row k-mb-3"
                                style={
                                     this.props.props.state.dataToSend.campaignMode === "Redirect" ? { fontWeight: "500"} : { opacity: "0.6" }
                                }
                            >
                                <div className="label">Redirect</div>
                                <div className="campaign_flow_diagram k-mt-2 k-mb-2 k-ml-3 k-mr-3 normal_text">
                                    Ad
                                    <span className="k-icon k-i-arrow-right k-mr-2 k-ml-2"></span>
                                    <div className="logo_img">
                                        <img src="../assets/img/logos/RPC-JP_Logo.png" alt="Aff Mark Logo"/>
                                    </div>
                                    Aff Mark TRK
                                    <span className="k-icon k-i-arrow-right k-mr-2 k-ml-2"></span>
                                    Your campaign funnel
                                </div>
                            </div>
                            <div
                                className="flex_row"
                                style={
                                    this.props.props.state.dataToSend.campaignMode === "Direct" ? { fontWeight: "500"} : { opacity: "0.6" }
                                }
                            >
                                <div className="label">Direct</div>
                                <div className="campaign_flow_diagram k-mt-2 k-mb-2 k-ml-3 k-mr-3 normal_text">
                                    Ad
                                    <span className="k-icon k-i-arrow-right k-mr-2 k-ml-2"></span>
                                    Your campaign funnel
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="k-mt-4 light_grey_border_bottom">
                    <h3>Campaign destination</h3>
                    <div className="flex_row k-pb-2">
                        <div className="inline_block k-mr-5">
                            <RadioButton name="campaign_destination" value="Flow" checked={this.props.props.state.dataToSend.campaign_destination === 'Flow'} label="Flow" onChange={this.props.props.simpleRadioChangeHandler} />
                        </div>
                        <div className="inline_block">
                            <RadioButton name="campaign_destination" value="Path" checked={this.props.props.state.dataToSend.campaign_destination === 'Path'} label="Path" onChange={this.props.props.simpleRadioChangeHandler} />
                        </div>
                    </div>
                </section>
                {
                    this.props.props.state.dataToSend.campaign_destination === "Path"
                    ?
                        <div>
                            <section className="k-mt-4 light_grey_border_bottom">
                                <h5 className="k-mb-2 color_black">Path destination</h5>
                                <div className="flex_row k-pb-2">
                                    <div className="inline_block k-mr-5">
                                        <RadioButton name="path_destination" value="Lander & Offer" checked={this.props.props.state.dataToSend.path_destination === 'Lander & Offer'} label="Lander & Offer" onChange={this.props.props.simpleRadioChangeHandler} />
                                    </div>
                                    <div className="inline_block">
                                        <RadioButton name="path_destination" value="Offer Only" checked={this.props.props.state.dataToSend.path_destination === 'Offer Only'} label="Offer Only" onChange={this.props.props.simpleRadioChangeHandler} />
                                    </div>
                                </div>
                            </section>
                            <section className="k-mt-4 light_grey_border_bottom">
                                <h5 className="k-mb-2 color_black">
                                    Transition to offer
                                    <span data-tip data-for='transition_h5_tooltip' className="question_mark">
                                        <span className="k-icon k-i-question-circle"></span>
                                    </span>
                                    <ReactTooltip id='transition_h5_tooltip' type='dark'>
                                        <span>It determines how redirection to the campaign’s endpoint is handled and whether referrer data is passed through or not.</span>
                                    </ReactTooltip>
                                </h5>
                                <div className="flex_row">
                                    <div className="inline_block k-mr-5">
                                        <RadioButton name="redirect_mode" value="302" checked={this.props.props.state.dataToSend.redirect_mode === '302'} label="302" onChange={this.props.props.simpleRadioChangeHandler} />
                                    </div>
                                    <div className="inline_block k-mr-5">
                                        <RadioButton name="redirect_mode" value="Meta Refresh" checked={this.props.props.state.dataToSend.redirect_mode === 'Meta Refresh'} label="Meta Refresh" onChange={this.props.props.simpleRadioChangeHandler} />
                                    </div>
                                    <div className="inline_block k-mr-5">
                                        <RadioButton name="redirect_mode" value="Double Meta Refresh" checked={this.props.props.state.dataToSend.redirect_mode === 'Double Meta Refresh'} label="Double Meta Refresh" onChange={this.props.props.simpleRadioChangeHandler} />
                                    </div>
                                    <div className="inline_block k-mr-5">
                                        <RadioButton name="redirect_mode" value="Direct" checked={this.props.props.state.dataToSend.redirect_mode === 'Direct'} label="Direct" onChange={this.props.props.simpleRadioChangeHandler} />
                                        <span data-tip data-for='transition_method_direct_tooltip' className="question_mark">
                                            <span className="k-icon k-i-question-circle"></span>
                                        </span>
                                        <ReactTooltip id='transition_method_direct_tooltip' type='dark'>
                                            <span>No redirect between landers and offers</span>
                                        </ReactTooltip>
                                    </div>
                                </div>
                                <div className="grey_bg_box five_hun k-p-2 k-mt-3">
                                    <div className="flex_row">
                                        <div className="campaign_flow_diagram k-ml-3 k-mr-3 normal_text">
                                            <span className="traffics_flow k-mr-2">Your traffic's flow: </span>
                                            Ad
                                            <span className="k-icon k-i-arrow-right k-mr-2 k-ml-2"></span>
                                            <div className="logo_img">
                                                <img src="../assets/img/logos/RPC-JP_Logo.png" alt="Aff Mark Logo"/>
                                            </div>
                                            <span className="k-icon k-i-arrow-right k-mr-2 k-ml-2"></span>
                                            Lander
                                            {
                                                this.props.props.state.dataToSend.redirect_mode !== "Direct"
                                                    ?
                                                    <div className="flex_center">
                                                        <span className="k-icon k-i-arrow-right k-mr-2 k-ml-2"></span>
                                                        <div className="logo_img">
                                                            <img src="../assets/img/logos/RPC-JP_Logo.png" alt="Aff Mark Logo"/>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <span className="k-icon k-i-arrow-right k-mr-2 k-ml-2"></span>
                                            Offer
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {/*landers and offers */}
                            {
                                this.props.props.state.dataToSend.path_destination !== "Offer Only"
                                ?
                                    <section className="k-mt-8">
                                        <div className="flex_center campaigns_landers_heading light_grey_border_bottom k-pb-2">
                                            <div className="flex_center">
                                                <h3>Landers</h3>
                                                <span data-tip data-for='c_modal_ch_tooltip' className="question_mark">
                                            <span className="k-icon k-i-question-circle"></span>
                                        </span>
                                                <ReactTooltip id='c_modal_ch_tooltip' type='dark'>
                                                    <span>You can add upto 50 Landers</span>
                                                </ReactTooltip>
                                            </div>
                                            <div>
                                                {
                                                    this.props.props.state.count.landersAdded < this.props.props.state.count.landerInBackend
                                                        ?
                                                        <span
                                                            id="add_lander"
                                                            className="normal_text normal_font theme_blue_color"
                                                            onClick={this.props.props.addLandersAndOffersMethod}
                                                        >
                                                            <span className="k-icon k-i-plus"></span>
                                                            Add Landers
                                                        </span>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        <div className="flex_row">
                                            <p className="normal_text k-ml-0 k-pl-0 k-mt-3 k-mb-2 color_black">Add landers to this path (you can add multiple landers at once).</p>
                                            <div>
                                                {
                                                    this.props.props.state.allLandersDataLoaded !== null
                                                        ?
                                                        this.props.props.state.count.landersAdded > 0 ?
                                                            this.props.props.state.dataToMap.landers.map((lander,index) => (
                                                                <WeightageComponent
                                                                    type="lander"
                                                                    name={lander.name}
                                                                    options={lander.options}
                                                                    selectedId={lander.selectedId}
                                                                    weightageNumber={lander.weightageNumber}
                                                                    weightagePercentage={lander.weightagePercentage}
                                                                    increaseWeightMethod={this.props.props.increaseWeightMethod}
                                                                    decreaseWeightMethod={this.props.props.decreaseWeightMethod}
                                                                    index={index}
                                                                    weightageSelectTagChangeHandler={this.props.props.weightageSelectTagChangeHandler}
                                                                    deleteWeightageMethod={this.props.props.deleteWeightageMethod}
                                                                    changeWeightageHandler={this.props.props.changeWeightageHandler}
                                                                />
                                                            ))
                                                            : null
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        {
                                            this.props.props.state.errors.landers !== null
                                                ?
                                                <Fade enter={true} exit={true}>
                                                    <p className="danger_para k-mt-2 mb-10 mh-0">{this.props.props.state.errors.landers}</p>
                                                </Fade>
                                                : null
                                        }
                                    </section>
                                    : null
                            }
                            <section className="k-mt-3">
                                <div className="flex_center campaigns_landers_heading light_grey_border_bottom k-pb-2">
                                    <div className="flex_center">
                                        <h3>Offers</h3>
                                        <span data-tip data-for='c_modal_ch_offers_tooltip' className="question_mark">
                                            <span className="k-icon k-i-question-circle"></span>
                                        </span>
                                        <ReactTooltip id='c_modal_ch_offers_tooltip' type='dark'>
                                            <span>You can add upto 50 Offers</span>
                                        </ReactTooltip>
                                    </div>
                                    <div>
                                        {
                                            this.props.props.state.count.offersAdded < this.props.props.state.count.offersInBackend
                                            ?
                                                <span
                                                    id="add_offer"
                                                    className="normal_text normal_font theme_blue_color"
                                                    onClick={this.props.props.addLandersAndOffersMethod}
                                                >
                                                    <span className="k-icon k-i-plus"></span>
                                                    Add Offers
                                                </span>
                                                : null
                                        }
                                    </div>
                                </div>
                                <div className="flex_row">
                                    <p className="normal_text k-ml-0 k-pl-0 k-mt-3 k-mb-2 color_black">Add Offers to this path (you can add multiple offers at once).</p>
                                    <div>
                                        {
                                            this.props.props.state.allOffersDataLoaded !== null
                                                ?
                                                this.props.props.state.count.offersAdded > 0 ?
                                                     this.props.props.state.dataToMap.offers.map((offer,index) => (
                                                        <WeightageComponent
                                                            type="offer"
                                                            name={offer.name}
                                                            options={offer.options}
                                                            selectedId={offer.selectedId}
                                                            weightageNumber={offer.weightageNumber}
                                                            weightagePercentage={offer.weightagePercentage}
                                                            increaseWeightMethod={this.props.props.increaseWeightMethod}
                                                            decreaseWeightMethod={this.props.props.decreaseWeightMethod}
                                                            index={index}
                                                            weightageSelectTagChangeHandler={this.props.props.weightageSelectTagChangeHandler}
                                                            deleteWeightageMethod={this.props.props.deleteWeightageMethod}
                                                            changeWeightageHandler={this.props.props.changeWeightageHandler}
                                                        />
                                                    ))
                                                    : null
                                                : null
                                        }
                                    </div>                                    
                                </div>
                                {
                                    this.props.props.state.errors.offers !== null
                                        ?
                                        <Fade enter={true} exit={true}>
                                            <p className="danger_para k-mt-2 mb-10 mh-0">{this.props.props.state.errors.offers}</p>
                                        </Fade>
                                        : null
                                }
                            </section>
                            {/*landers and offers section end*/}
                        </div>
                :
                        <section className="k-mt-4 light_grey_border_bottom">
                            <h3>
                                Flow
                                <span data-tip data-for='c_modal_flow_tooltip' className="question_mark">
                                    <span className="k-icon k-i-question-circle"></span>
                                </span>
                                <ReactTooltip id='c_modal_flow_tooltip' type='dark'>
                                    <span>It is a predefined set of campaign destination paths. You cannot edit it unless you convert the flow to paths.</span>
                                </ReactTooltip>
                            </h3>
                            <div className="flex_row">
                                {
                                    this.props.props.state.allFlowsDataLoaded !== null
                                    ?
                                        <select
                                            className="add_custom_domain_dropdown normal_text normal_font five_hun"
                                            name="flowId"
                                            id="flowId"
                                            value={this.props.props.state.dataToSend.flowId}
                                            onChange={this.props.props.simpleInputChangeHandler}
                                        >
                                            <option value="">Select Flow</option>
                                            {this.props.props.state.allFlowsDataLoaded.map(flow => (
                                                <option key={flow.id} value={flow.id}>{flow.name}</option>
                                            ))}
                                        </select>
                                        : null
                                }

                            </div>
                            {
                                this.props.props.state.errors.flow !== null
                                ?
                                    <Fade enter={true} exit={true}>
                                        <p className="danger_para k-mt-4 mb-10 mh-0">{this.props.props.state.errors.flow}</p>
                                    </Fade>
                                    : null
                            }
                        </section>
                }
                <div className="flex_row">
                    <div className="flex_center">
                        <h3 className="notes_h3">Notes</h3>
                        {/* <span data-tip data-for='c_modal_ch_offers_tooltip' className="question_mark">
                            <span className="k-icon k-i-question-circle"></span>
                        </span>
                        <ReactTooltip id='c_modal_ch_offers_tooltip' type='dark'>
                            <span>You can add upto 50 Offers</span>
                        </ReactTooltip> */}
                    </div>
                    <div>
                        <textarea
                            className="campaign_notes"
                            id="notes_app"
                            name="notes_app"
                            onChange={this.props.props.simpleInputChangeHandler}
                            value={this.props.props.state.dataToSend.notes_app}
                            placeholder={"You can add your notes here..."}
                        ></textarea>
                    </div>                                    
                </div>
                <section>
                    <div className="empty_space_10"></div>
                    {
                        this.props.props.state.resolveErrors !== "" ?
                            <Fade enter={true} exit={true}>
                                <p className="danger_para mb-10 mh-0">{this.props.props.state.resolveErrors}</p>
                            </Fade>
                            : null
                    }
                </section>
            </div>
        );
    }
};

export default GeneralTab;