import React,{Fragment,useEffect} from 'react';
import "../Domains/domainsConfiguration.css";
import "./TrackingURLs.css"
import URLS from './URLs';
import LanderProtection from './landerProtection';
import Layout from "../../../layout";
import { useSelector } from "react-redux";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router";
import { makeSelectDomainsDataReceived } from '../Domains/selectors';
import * as domainsActions from "../Domains/actions";
import LoadingPanel from './../../grid/loader/loader';

//tracking urls

const TrackingUrls = (props) => {
	const clickURLText = "Click URLs are used to redirect visitors from a landing page to an offer. Copy the click URL and place it into the body section of landing page’s HTML. Landing pages with a AffMark click URL do not require any additional scripting for tracking clicks or offer redirections.";
	const clickURLNote = "Double-check if the domain used in the click URL/multi-offer click URL matches exactly to the domain used for the campaign URL."
	const postbackURLText = "Server-to-server postback URLs are the most reliable method to count conversions. Copy a postback URL, modify it with the tokens supported by the affiliate network, and then, paste it on the affiliate network side.";
	const postbackURLNote = "Double-check if you have included the {clickid} token in your offer URL; for example: http://my_offer_URL.com/?aff_net_parameter={clickid} Otherwise, the conversions wouldn’t be registered."
	const pixelText = "Conversion tracking pixels are used when an offer only supports cookie-based pixels for conversion tracking purposes. You need to copy and paste the tracking pixel on a thank_you page."
	const pixelNote = "Double-check if the domain used in the pixel matches exactly to the domain used for the initial campaign URL."
	const clickUrl = "/click"
	const clickUrl1 = "/click/1"
	const postbackUrl = "/postback?cid=REPLACE&payout=OPTIONAL&txid=OPTIONAL"
	const conversionTrackingUrl = "/conversion.gif?cid=OPTIONAL&payout=OPTIONAL&txid=OPTIONAL"
	

	
	const accessToken = useSelector(state => state.login.token);
	let [selectedDomain, setSelectedDomain] = React.useState("");
	let [selectedDomain2, setSelectedDomain2] = React.useState("");
	let [selectedDomain3, setSelectedDomain3] = React.useState("");
	const [inputValue, setInputValue] = React.useState("");
	const [inputValue2, setInputValue2] = React.useState("");
	const [inputValue3, setInputValue3] = React.useState("");
	const [firstTime, setFirstTime] = React.useState(false);
	const [trackingURLSLoader, setTrackingURLSLoader] = React.useState(false);
	//console.log('access', accessToken);
	const fetchDomains = async () => {
		props.dispatchLoadDomains(accessToken);
	};
	useEffect(() => {
		setTrackingURLSLoader(true);
		if (props.domainsDataReceived === null) {
			setTrackingURLSLoader(true);
			fetchDomains();
		}
	}, []);
	
	setTimeout(() => {
		selectDomain()
	}, 1000);
	const selectDomain = () => {
		if (props.domainsDataReceived !== null && firstTime===false) {
			setTrackingURLSLoader(true);
			for (let i = 0; i < props.domainsDataReceived.domain.length; i++) {
				if (props.domainsDataReceived.domain[i].selected === true) {
					setSelectedDomain(props.domainsDataReceived.domain[i].id);
					setSelectedDomain2(props.domainsDataReceived.domain[i].id);
					setSelectedDomain3(props.domainsDataReceived.domain[i].id);
					setInputValue(props.domainsDataReceived.domain[i].name)
					setInputValue2(props.domainsDataReceived.domain[i].name)
					setInputValue3(props.domainsDataReceived.domain[i].name)
					setFirstTime(true);
					setTrackingURLSLoader(false);
				}
            }
		}
		//console.log('props.domains data received', props.domainsDataReceived);
	}
	const inputChangeHandler = (e) => {
		for (let i = 0; i < props.domainsDataReceived.domain.length; i++) {
			if (e.target.value === String(props.domainsDataReceived.domain[i].id)) {
				setSelectedDomain(props.domainsDataReceived.domain[i].id);
				setInputValue(props.domainsDataReceived.domain[i].name)
			}

		}
	}
	const inputChangeHandler2 = (e) => {
		for (let i = 0; i < props.domainsDataReceived.domain.length; i++) {
			if (e.target.value === String(props.domainsDataReceived.domain[i].id)) {
				setSelectedDomain2(props.domainsDataReceived.domain[i].id);
				setInputValue2(props.domainsDataReceived.domain[i].name)
			}

		}
	}
	const inputChangeHandler3 = (e) => {
		for (let i = 0; i < props.domainsDataReceived.domain.length; i++) {
			if (e.target.value === String(props.domainsDataReceived.domain[i].id)) {
				setSelectedDomain3(props.domainsDataReceived.domain[i].id);
				setInputValue3(props.domainsDataReceived.domain[i].name)
			}

		}
	}


	return (
		<Fragment>
			<Layout>
				<div className="settings_area_container">
					<div className="small_width_page_con">
						<div className="small_width_page_con_inner">
							<div className="h1_con">
								<h1 className="h1 no_border inline_block">Tracking URLs</h1>
							</div>
							{
								props.domainsDataReceived ? <>
							
									<URLS 
										inputValue={inputValue} 
										inputChangeHandler={inputChangeHandler} 
										selectedDomain={selectedDomain} 
										heading={"CLICK URL"} 
										id={1} 
										url={clickUrl} 
										url1={clickUrl1} 
										note={clickURLNote} 
										text={clickURLText} 
										childHeading1={"Click URL"} 
										childHeading2={"Multi-offer click URL"} 
									/>
								
									<URLS 
										inputValue={inputValue2} 
										inputChangeHandler={inputChangeHandler2} 
										selectedDomain={selectedDomain2} 
										heading={"POSTBACK URL"} 
										url={postbackUrl} 
										id={2} 
										note={postbackURLNote} 
										text={postbackURLText} 
										childHeading1={"Postback URL"} 
										childHeading2={"Secure postback URL"} 
									/>
								
									<URLS 
										inputValue={inputValue3}
										inputChangeHandler={inputChangeHandler3}
										selectedDomain={selectedDomain3}
										heading={"CONVERSION TRACKING PIXEL"}
										url={conversionTrackingUrl} id={3}
										note={pixelNote}
										text={pixelText}
										childHeading1={"Conversion tracking pixel URL"}
										childHeading2={"Conversion tracking pixel"}
										childHeading3={"Conversion tracking script"}
									/>
									{/* <LanderProtection heading={"LANDER PROTECTION SCRIPT"} /> */}
									
									</> : null
							}
							{(trackingURLSLoader) && <LoadingPanel></LoadingPanel>}
						</div>
					</div>
				</div>
			</Layout>
		</Fragment>
	)
}

// export default TrackingUrls;
export const mapStateToProps = createStructuredSelector({
    domainsDataReceived: makeSelectDomainsDataReceived()
});
export function mapDispatchToProps(dispatch) {
    return {
        dispatchLoadDomains: (...payload) => dispatch(domainsActions.loadCustomDomains(...payload)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TrackingUrls));
