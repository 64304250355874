import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectSetting = (state) => state.settings || initialState;

const makeSelectSettingData = () => createSelector(selectSetting, (substate) => substate.settingsData);

const makeSelectSettingDataLoaded = () => createSelector(selectSetting, (substate) => substate.isSettingsDataLoaded);

const makeSelectSettingDataUpdated = () => createSelector(selectSetting, (substate) => substate.isSettingsDataUpdated);

const makeSelectSettingDataLoading = () => createSelector(selectSetting, (substate) => substate.loading);

const makeSelectSettingDataSuccess = () => createSelector(selectSetting, (substate) => substate.success);

const makeSelectSettingDataError = () => createSelector(selectSetting, (substate) => substate.error);

export {
    makeSelectSettingData,
    makeSelectSettingDataLoaded,
    makeSelectSettingDataUpdated,
    makeSelectSettingDataLoading,
    makeSelectSettingDataSuccess,
    makeSelectSettingDataError
}