import React, {Component} from "react";
import produce from "immer";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import ModalForm from "./ModalForm";
import {createStructuredSelector} from "reselect";
import {connect} from 'react-redux';
import * as domainsActions from "../../../Settings/Domains/actions";
import * as affiliatedNetworksActions from "../actions";
import {makeSelectDomainsDataReceived} from "../../../Settings/Domains/selectors";
import {makeSelectToken} from "../../../../registration/LoginRegister/selectors";
import { Input } from '@progress/kendo-react-inputs';
import  ReactTooltip  from 'react-tooltip';
import {
    makeSelectLoading,
    makeSelectSuccess,
    makeSelectError,
    makeSelectTrackingUrlsReceived,
    makeSelectLoadCurrenciesList,
    makeSelectIsNewAffiliateNetworkDataSent,
    makeSelectIsCustomAffiliateNetworkDataLoadedById
} from "../selectors";

class AffiliateNetworksModal extends Component {
    state = {
        dataToSend: {
            "name": "",
            "workspaceId": "",
            "domainId": "",
            "trackingId": "",
            "currencyId": "",
            "parameters": [
                {
                    "name": "Click Id",
                    "an_parameter": "",
                    "an_token": "",
                    "vlm_token": "{clickid}"
                },
                {
                    "name": "Payout",
                    "an_parameter": "payout",
                    "an_token": "",
                    "vlm_token": "{payout}"
                },
                {
                    "name": "Transaction Id",
                    "an_parameter": "",
                    "an_token": "",
                    "vlm_token": "{txid}"
                },
                {
                    "name": "Event Type",
                    "an_parameter": "",
                    "an_token": "",
                    "vlm_token": "{eventType}"
                }
            ],
            "append_click_offer": false,
            "postback_pixel": false,
            "ip_address_ranges": null,
            "additional_parameters": false,
        },
        empty_values: {
            "name": false,
            "workspaceId": false,
            "domainId": false,
            "currencyId": false,
            "clickId_an_parameter": false
        },
        domainsDataReceived : null,
        trackingUrlsReceived: null,
        currenciesListReceived: null,
        conversion_tracking_code_input_value: '',
        showCIdReplaceWarning: false,
        url_value: {
            "domain_name": "",
            "tracking_url_code": "",
            "click_id_token": "",
            "is_click_id_token_populated": false,
            "before_payout_ampersand": "",
            "payout_token": "",
            "is_payout_token_populated": false,
            "before_tx_id_ampersand": "",
            "tx_id_token": "",
            "is_tx_id_token_populated": false,
            "before_et_ampersand": "",
            "et_token": "",
            "is_et_token_populated": false
        },
        tracking_url_id_code_before: "",
        tracking_url_id_code_after: "",
        ip_address_switch_value: false,
        ip_address_valid_value: true,
        isLoadCurrencies: false,
        isLoadDomains: false,
        isLoadTrackingUrls: false,
        isCustomLoadDataReceived: false,
        isCustomNameReset: true,
        value: '',
        customAffNetworkDataLoaded: false,
        nextButtonLoaded: true,
        saveBtnDisabled: false,
    }


    onChange = (event) => {
        const value = event.target.value;
        this.setState({
            value: value
        });
    }

    fetchDomains = async () => { return this.props.dispatchLoadDomains(this.props.accessToken); };
    fetchTrackingUrls = async () => { return this.props.dispatchLoadTrackingURLS(this.props.accessToken); }
    fetchCurrencies = async () => { return this.props.dispatchLoadCurrencies(this.props.accessToken); }
    resetCustomData = async () => { return this.props.dispatchResetCustomDataById(this.props.accessToken); };
    sendAffiliateNetworkData = async () => {
        if(this.state.dataToSend.ip_address_ranges === "") {
            this.setState(produce(draft => { draft.dataToSend.ip_address_ranges = null }));
        }
        let currencyId = String(this.state.dataToSend.currencyId);
        let trackingId = String(this.state.dataToSend.trackingId);
        let domainId = String(this.state.dataToSend.domainId);
        let name = String(this.state.dataToSend.name);
        let parameters = this.state.dataToSend.parameters;
        let workspaceId = String(this.state.dataToSend.workspaceId);
        let allData = {
            name: name,
            workspaceId: workspaceId,
            domainId: domainId,
            trackingId:trackingId,
            currencyId: currencyId,
            parameters:parameters,
            additional_parameters: this.state.dataToSend.additional_parameters
        }

        setTimeout(() => {
            return this.props.dispatchAddNewAffiliateNetwork({
                data: allData,
                accessToken: this.props.accessToken
            });
        },200);
    }

    componentDidMount() {
        // fetching domains and workspaces
        this.resetCustomData();
        this.fetchDomains();
        this.fetchTrackingUrls();
        this.fetchCurrencies();
        this.setState(produce(draft => {
            draft.isLoadDomains = true;
            draft.isLoadTrackingUrls = true;
            draft.isLoadCurrencies = true;
            draft.isCustomLoadDataReceived = true;
            draft.isCustomNameReset = true;
        }));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        
        // setting initial values in the state on component load
        if (this.props.domainsDataReceived && this.state.isLoadDomains === true) {
            this.setState(produce(draft => { draft.dataToSend.workspaceId = String(this.props.domainsDataReceived.workspace[0].id); }));
            this.setState(produce(draft => { draft.domainsDataReceived = this.props.domainsDataReceived; }));
            if (this.props.domainsDataReceived.domain === []) {
                this.setState(produce(draft => { draft.empty_values.domainId = true; }));
            } else {
                for (let i = 0; i < this.props.domainsDataReceived.domain.length; i++){
                    if (this.props.domainsDataReceived.domain[i].selected === true) {
                        this.setState(produce(draft => { draft.dataToSend.domainId = String(this.props.domainsDataReceived.domain[i].id); }));
                        // set initial domain name in state.url_value
                        this.setState(produce(draft => {
                            if (this.props.domainsDataReceived.domain[i].name.startsWith("https://")) {
                                draft.url_value.domain_name = this.props.domainsDataReceived.domain[i].name;
                            } else if (this.props.domainsDataReceived.domain[i].name.startsWith("http://")) {
                                let name = this.props.domainsDataReceived.domain[i].name.split("http://")[1];
                                draft.url_value.domain_name = "https://" + name;
                            } else {
                                draft.url_value.domain_name = "https://" + this.props.domainsDataReceived.domain[i].name;
                            }
                            draft.empty_values.domainId = false;
                        }));
                    }
                }
            }
            this.setState(produce(draft => { draft.isLoadDomains = false; }));
        }
        if (this.props.trackingUrlsReceived && this.state.isLoadTrackingUrls === true){
            let trackingUrls = [...this.props.trackingUrlsReceived];
            trackingUrls.shift();
            this.setState(produce(draft => {
                draft.trackingUrlsReceived = trackingUrls;
                // set initial tracking url id
                draft.dataToSend.trackingId = String(trackingUrls[0].id);
                // Set initial tracking url code in state.url_value
                draft.url_value.tracking_url_code = trackingUrls[0].code;
                // When initial load... tracking url is set to postback url so we add cid=REPLACE initially...
                draft.url_value.click_id_token = "cid=REPLACE";
                draft.url_value.is_click_id_token_populated = true;
                draft.url_value.payout_token = "payout=REPLACE";
                draft.url_value.before_payout_ampersand = "&";
                draft.url_value.is_payout_token_populated = true;
                draft.showCIdReplaceWarning = true;
                draft.isLoadTrackingUrls = false;
            }));
        }
        if(this.props.currenciesListReceived && this.state.isLoadCurrencies === true) {
            this.setState(produce(draft => {
                draft.currenciesListReceived = this.props.currenciesListReceived;
                // Set initial currency value to the state
                draft.dataToSend.currencyId = String(this.props.currenciesListReceived[0].id);
                draft.isLoadCurrencies = false;
            }));
        }
        

        // --------------------------------------------------------------------------------------------------
        // Loading network data initial to the state
        // --------------------------------------------------------------------------------------------------
        
        setTimeout(() => {
            if (this.props.customDataReceivedById && this.state.isCustomLoadDataReceived === true) {
                this.setState(produce(draft => { 
                    draft.customAffNetworkDataLoaded = true;
                    // set initial value in state
                    draft.dataToSend = this.props.customDataReceivedById;
                }));
                // set url names for domain and tracking code
                for (let i = 0; i < this.props.domainsDataReceived.domain.length; i++) {
                    if (this.props.customDataReceivedById.domainId === Number(this.props.domainsDataReceived.domain[i].id)) {
                        this.setState(produce(draft => { draft.url_value.domain_name = this.props.domainsDataReceived.domain[i].name }));
                    }
                }
                for (let j = 0; j < this.props.trackingUrlsReceived.length; j++) {
                    if (this.props.customDataReceivedById.trackingId === Number(this.props.trackingUrlsReceived[j].id)) {
                        this.setState(produce(draft => { draft.url_value.tracking_url_code = this.props.trackingUrlsReceived[j].code }));
                    }
                }
                // checking is Pixel method is selected or not.
                if (this.props.customDataReceivedById.trackingId === 3) {
                    this.setState(produce(draft => {
                        draft.tracking_url_id_code_before = '<img src="';
                        draft.tracking_url_id_code_after = '" width="1" height="1" />';
                    }));
                } else {
                    this.setState(produce(draft => {
                        draft.tracking_url_id_code_before = '';
                        draft.tracking_url_id_code_after = '';
                    }));
                }
    
                if (this.props.customDataReceivedById !== null && this.props.customDataReceivedById.ip_address_ranges !== null) {
                    this.setState(produce(draft => { draft.ip_address_switch_value = true }));
                }
                // setting url parameters to be loaded to the input
                if (this.props.customDataReceivedById.parameters[0].an_token !== "") {
                    this.setState(produce(draft => {
                        draft.url_value.click_id_token = "cid=" + this.props.customDataReceivedById.parameters[0].an_token;
                        draft.url_value.is_click_id_token_populated = true;
                    }));
                }
                if (this.props.customDataReceivedById.parameters[1].an_token !== "") {
                    this.setState(produce(draft => {
                        draft.url_value.payout_token = "payout=" + this.props.customDataReceivedById.parameters[1].an_token;
                        draft.url_value.is_payout_token_populated = true;
                    }));
                    if (this.props.customDataReceivedById.parameters[0].an_token !== "") {
                        this.setState(produce(draft => { draft.url_value.before_payout_ampersand = "&" }));
                    }
                }
                if (this.props.customDataReceivedById.parameters[2].an_token !== "") {
                    this.setState(produce(draft => { 
                        draft.url_value.tx_id_token = "txid=" + this.props.customDataReceivedById.parameters[2].an_token;
                        draft.url_value.is_tx_id_token_populated = true;
                    }));
                    if (this.props.customDataReceivedById.parameters[0].an_token !== "" || this.props.customDataReceivedById.parameters[1].an_token !== "") {
                        this.setState(produce(draft => { draft.url_value.before_tx_id_ampersand = "&"; }));
                    }
                }
                if (this.props.customDataReceivedById.parameters[3].an_token !== "") {
                    this.setState(produce(draft => {
                        draft.url_value.et_token = "et=" + this.props.customDataReceivedById.parameters[3].an_token;
                        draft.url_value.is_et_token_populated = true;
                    }));
                    if (this.props.customDataReceivedById.parameters[0].an_token !== "" || this.props.customDataReceivedById.parameters[1].an_token !== "" || this.props.editAffiliateNetworkDataReceived.parameters[2].an_token !== "") {
                        this.setState(produce(draft => { draft.url_value.before_et_ampersand = "&" }));
                    }
                }
    
                // making the value false in the state to break the loop
                this.setState(produce(draft => { draft.isCustomLoadDataReceived = false }));
                setTimeout(() => {
                    this.setState(produce(draft => {
                        draft.customAffNetworkDataLoaded = false;
                        draft.nextButtonLoaded = false;
                    }));
                    this.props.toggleLoader();
                }, 500);
            }
            // if (this.props.state.selectedAffiliateNetwork === "custom-affiliate-network" && this.state.nextButtonLoaded) {                
            //     this.setState(produce(draft => { draft.nextButtonLoaded = false; }));
            //     this.props.toggleLoader();
            // }
        }, 3000);


        
    }

    inputChangeHandler = (e) => {
        this.setState(produce(draft => { draft.dataToSend[e.target.name] = e.target.value }));
        if (e.target.name === "domainId") {
            for (let i=0; i<this.state.domainsDataReceived.domain.length;i++) {
                if (e.target.value === String(this.state.domainsDataReceived.domain[i].id)) {
                    if(this.state.domainsDataReceived.domain[i].name.startsWith("https://")){
                        this.setState(produce(draft => { draft.url_value.domain_name = this.state.domainsDataReceived.domain[i].name}));
                    }
                    else if(this.state.domainsDataReceived.domain[i].name.startsWith("http://")){
                        this.setState(produce(draft => {draft.url_value.domain_name = [this.state.domainsDataReceived.domain[i].name.slice(0, 4), "s", this.state.domainsDataReceived.domain[i].name.slice(4)].join('')}));
                    }else{
                        this.setState(produce(draft => {draft.url_value.domain_name = [this.state.url_value.domain_name.slice(0, 0), "https://", this.state.domainsDataReceived.domain[i].name.slice(0)].join('')}));
                    }
                }
            }
        }
        if (e.target.name === "trackingId") {
            if (e.target.value !== "2" && this.state.dataToSend.parameters[0].an_token === "cid=REPLACE") {
                this.setState(produce(draft => { 
                    draft.dataToSend.parameters[0].an_token = "";
                    draft.url_value.is_click_id_token_populated = false;
                }));
            }
            if (e.target.value !== "2" && this.state.dataToSend.parameters[1].an_token === "payout=REPLACE") {
                this.setState(produce(draft => {
                    draft.dataToSend.parameters[1].an_token = "";
                    draft.url_value.is_payout_token_populated = false;
                }));
            }
            if (e.target.value === "2" && this.state.dataToSend.parameters[0].an_token === "") {
                this.setState(produce(draft => {
                    draft.url_value.click_id_token = "cid=REPLACE";
                    draft.url_value.is_click_id_token_populated = true;
                    draft.url_value.is_payout_token_populated = true;
                    draft.url_value.before_payout_ampersand = "&";
                }));
            }
            if (e.target.value === "2" && this.state.dataToSend.parameters[1].an_token === "") {
                this.setState(produce(draft => {
                    draft.url_value.payout_token = "payout=REPLACE";
                    draft.url_value.is_payout_token_populated = true;
                }));
            }
            if (e.target.value === "3") {
                this.setState(produce(draft => {
                    draft.tracking_url_id_code_before = '<img src="';
                    draft.tracking_url_id_code_after = '" width="1" height="1" />';
                }));
            } else {
                this.setState(produce(draft => {
                    draft.tracking_url_id_code_before = '';
                    draft.tracking_url_id_code_after = '';
                }));
            }
        }
    }

    // Token change handler
    anTokenChangeHandler = (e) => {
        const arr_split = e.target.name.split("_");
        const arr_index = arr_split[arr_split.length -1];
        this.setState(produce(draft => { draft.dataToSend.parameters[arr_index].an_token = e.target.value }));
        // check if name if an_token_1
        if (e.target.name === "an_token_0") {
            if (this.state.url_value.tracking_url_code === "/postback?" && e.target.value === "") {
                this.setState(produce(draft => void (draft.url_value.click_id_token = "cid=REPLACE" )));
                this.setState(produce(draft => { draft.url_value.is_click_id_token_populated = true }));
            } else if (e.target.value !== "") {
                this.setState(produce(draft => void (draft.url_value.click_id_token = ("cid=" + e.target.value) )));
                this.setState(produce(draft => { draft.url_value.is_click_id_token_populated = true }));
            } else {
                this.setState(produce(draft => void (draft.url_value.click_id_token = "" )));
                this.setState(produce(draft => { draft.url_value.is_click_id_token_populated = false }));
            }
            // if not empty check for other & signs
            // populate &s after while checking their values
            if (e.target.value !== "" && this.state.url_value.is_payout_token_populated) {
                this.setState(produce(draft => {draft.url_value.before_payout_ampersand = "&" }));
            }
            if (e.target.value !== "" && this.state.url_value.is_tx_id_token_populated) {
                this.setState(produce(draft => {draft.url_value.before_tx_id_ampersand = "&" }));
            }
            if (e.target.value !== "" && this.state.url_value.is_et_token_populated) {
                this.setState(produce(draft => {draft.url_value.before_et_ampersand = "&" }));
            }
            // remove &s after whiles checking their values if this becomes empty
            if (e.target.value === "" && this.state.url_value.is_payout_token_populated) {
                this.setState(produce(draft => {draft.url_value.before_payout_ampersand = "&" }));
            }
            if (e.target.value === "" && this.state.url_value.is_tx_id_token_populated && !this.state.url_value.is_payout_token_populated) {
                this.setState(produce(draft => {draft.url_value.before_tx_id_ampersand = "" }));
            }
            if (e.target.value === "" && this.state.url_value.is_et_token_populated && !this.state.url_value.is_payout_token_populated && !this.state.url_value.is_tx_id_token_populated) {
                this.setState(produce(draft => {draft.url_value.before_et_ampersand = "" }));
            }
        } else if (e.target.name === "an_token_1") {
            if (e.target.value !== "") {
                this.setState(produce(draft => {
                    draft.url_value.payout_token = "payout=" + e.target.value;
                    draft.url_value.is_payout_token_populated = true;
                }));
            } else {
                this.setState(produce(draft => {
                    draft.url_value.payout_token = "payout=REPLACE";
                    draft.url_value.is_payout_token_populated = true;
                }));
            }
            //condition for & before the payout
            if (this.state.url_value.is_click_id_token_populated) {
                this.setState(produce(draft => { draft.url_value.before_payout_ampersand = "&" }));
            } else { this.setState(produce(draft => { draft.url_value.before_payout_ampersand = "&" })); }
            // condition for & before the txid
            if (this.state.url_value.is_tx_id_token_populated && e.target.value !== "") {
                this.setState(produce(draft => { draft.url_value.before_tx_id_ampersand = "&" }));
            }
            // condition for & before et
            if (this.state.url_value.is_et_token_populated && e.target.value !== "") {
                this.setState(produce(draft => { draft.url_value.before_et_ampersand = "&" }));
            }
            //  if this empty and there is no value in click id / payout / tx-id then remove & before et
            if (this.state.url_value.is_et_token_populated && e.target.value === "" && !this.state.url_value.is_click_id_token_populated && !this.state.url_value.is_tx_id_token_populated) {
                this.setState(produce(draft => {draft.url_value.before_et_ampersand = "" }));
            }
        } else if (e.target.name === "an_token_2") {
            if (e.target.value !== "") {
                this.setState(produce(draft => { draft.url_value.tx_id_token = "txid=" + e.target.value }));
                this.setState(produce(draft => { draft.url_value.is_tx_id_token_populated = true }));
            } else {
                this.setState(produce(draft => { draft.url_value.tx_id_token = "" }));
                this.setState(produce(draft => { draft.url_value.is_tx_id_token_populated = false }));
            }
            //condition for & before the txid
            if ((this.state.url_value.is_click_id_token_populated || this.state.url_value.is_payout_token_populated) && e.target.value !== "") {
                this.setState(produce(draft => { draft.url_value.before_tx_id_ampersand = "&" }));
            }
            // condition for & before et
            if (this.state.url_value.is_et_token_populated && e.target.value !== "") {
                this.setState(produce(draft => {draft.url_value.before_et_ampersand = "&" }));
            }
            // removal condition for & before txid
            if (e.target.value === "") {
                this.setState(produce(draft => { draft.url_value.before_tx_id_ampersand = "" }))
            }
            // if empty check for the & after it
            if (e.target.value === "" && this.state.url_value.is_et_token_populated && !this.state.url_value.is_payout_token_populated && !this.state.url_value.is_click_id_token_populated) {
                this.setState(produce(draft => {draft.url_value.before_et_ampersand = "" }));
            }
        } else if (e.target.name === "an_token_3") {
            if (e.target.value !== "") {
                this.setState(produce(draft => { draft.url_value.et_token = "et=" + e.target.value }));
                this.setState(produce(draft => { draft.url_value.is_et_token_populated = true }));
            } else {
                this.setState(produce(draft => { draft.url_value.et_token = "" }));
                this.setState(produce(draft => { draft.url_value.is_et_token_populated = false }));
            }
            // condition for & before et
            if ((this.state.url_value.is_click_id_token_populated || this.state.url_value.is_payout_token_populated || this.state.url_value.is_tx_id_token_populated) && e.target.value !== "") {
                this.setState(produce(draft => { draft.url_value.before_et_ampersand = "&"}));
            }
            else {
                this.setState(produce(draft => { draft.url_value.before_et_ampersand = ""}));
            }
        }
    }


    anParameterChangeHandler = (e) => {
        this.setState(produce(draft => { draft.dataToSend.parameters[0].an_parameter = e.target.value }));
    }

    ValidateIPAddress = (ipaddress) =>  {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
            return (true)
        }
        // alert("You have entered an invalid IP address!")
        return (false)
    }

    switchChangeHandler = (e) => {
        this.setState(produce(draft => { draft.dataToSend[e.target.name] = !this.state.dataToSend[e.target.name]}));
    }

    ipAddressSwitchChangeHandler = (e) => {
        this.setState(produce(draft => { draft.ip_address_switch_value = !this.state.ip_address_switch_value}));
        this.setState(produce(draft => { draft.dataToSend.ip_address_ranges = "" }));
    }

    ipAddressCheckHandler = (e) => {
        this.setState(produce(draft => { draft.dataToSend[e.target.name] = e.target.value }));
        let un_filtered_ip_addresses_list = e.target.value.split("\n");
        let ip_addresses_list = un_filtered_ip_addresses_list.filter(function (el) {
            return (el !== null && el !== "");
        });
        for (let i = 0; i<ip_addresses_list.length; i++) {
            if(this.ValidateIPAddress(ip_addresses_list[i])) {
                this.setState(produce(draft => { draft.ip_address_valid_value = true }));
            } else {
                this.setState(produce(draft => { draft.ip_address_valid_value = false }));
            }
        }

    }

    submitAffiliateNetworksData = () => {
        if (this.state.dataToSend.name === "") {
            this.setState(produce(draft => { draft.empty_values.name = true }));
        } else {
            this.setState(produce(draft => { draft.saveBtnDisabled = true }));
            this.sendAffiliateNetworkData();
            setTimeout(() => {
                this.props.closeAffiliateNetworkDialogueAfterSubmission("Add_Affiliate");
            }, 100);
        }
    }

    onCustomAffiliateClick = (e) => {
        //console.log('e.id',e.target.id);
    }

    render () {
        return (
            <div>
                <Dialog className="add_affiliate_networks_dialogue" title={"Add affiliate network"} onClose={this.props.toggleAddAffiliateNetworkDialog}>

                    <div className="add_affiliate_network_dialogue_content">
                        
                        {
                            this.props.state.affiliateDialogueNextBtnClicked
                                ?
                                this.state.domainsDataReceived ?
                                    <div className="content_form">
                                        <ModalForm
                                            state={this.state}
                                            inputChangeHandler={this.inputChangeHandler}
                                            anTokenChangeHandler={this.anTokenChangeHandler}
                                            anParameterChangeHandler={this.anParameterChangeHandler}
                                            switchChangeHandler={this.switchChangeHandler}
                                            ipAddressSwitchChangeHandler={this.ipAddressSwitchChangeHandler}
                                            ipAddressCheckHandler={this.ipAddressCheckHandler}
                                        />
                                    </div>
                                    : null
                                :
                                
                                <>
                                <section className="custom-search-items">
                                    <div className="custom-search-row">
                                        <Input onChange={this.onChange} placeholder="Search by affliate network name"/>
                                        <span className="k-icon k-i-search"></span>
                                        <label htmlFor="affiliate-network-name">
                                            What is an affiliate network?
                                            <span data-tip data-for='affiliate_tooltip' className="question_mark">
                                                <span className="k-icon k-i-question-circle"></span>
                                            </span>
                                            <ReactTooltip id='affiliate_tooltip' type='dark'>
                                                <span>Affiliate networks are platforms from which you take the products / services to promote.  Don’t use affiliate networks? Skip this option or use it to organise promoted offers. Learn more.</span>
                                            </ReactTooltip>
                                        </label>
                                    </div>
                                </section>
                                <div className="content">
                                    <div className="box">
                                        <div className="inner">
                                            <span className="text" id="custom-affiliate-network" onClick={this.props.selectAffiliateNetwork}>Create custom affiliate network</span>
                                        </div>
                                        </div>
                                        {this.props.customDataReceived ? this.props.customDataReceived.filter(item => {
                                            if (!this.state.value) return true;
                                            if (item.name.toLowerCase().includes(this.state.value)) {
                                                return true;
                                            }
                                            return false;
                                        }).map(item => (
                                            <div className="box">
                                                    <div className="inner">
                                                        <span className="text" id={item.id} onClick={this.props.selectAffiliateNetwork}>{ item.name}</span>
                                                    </div>
                                            </div>
                                        )): null}
                                    {/* {this.props.customDataReceived ?
                                        this.props.customDataReceived.map((customAND) => (
                                                <div className="box">
                                                    <div className="inner">
                                                        <span className="text" id={customAND.id} onClick={this.props.selectAffiliateNetwork}>{ customAND.name}</span>
                                                    </div>
                                            </div>
                                        )):null
                                    } */}
                                </div></>
                        }
                        {
                            // ((this.state.nextButtonLoaded && this.props.state.affiliateDialogueNextBtnClicked) || this.state.saveBtnDisabled) && <LoadingPanel />
                            // ((this.state.nextButtonLoaded && this.props.state.affiliateDialogueNextBtnClicked)) && <LoadingPanel />
                        }
                    </div>
                    <DialogActionsBar>
                        {
                            this.props.state.affiliateDialogueNextBtnClicked
                                ?
                                <div className="action_bar_buttons">
                                    <div className="left">
                                        <button onClick={this.props.toggleAddAffiliateNetworkDialog} className="normal_btn cancel_button gray_button_bg_imp black border_radius_normal_btn normal_text black no_border">Close</button>
                                    </div>
                                    <div className="right">
                                        <button onClick={this.submitAffiliateNetworksData} disabled={ this.state.saveBtnDisabled} className="normal_btn theme_dark_btn theme_dark_bg_color_imp color_white_imp border_radius_normal_btn save_button normal_text">Save</button>
                                    </div>
                                </div>
                                :
                                <div className="action_bar_buttons">
                                    <div className="left">
                                        <button onClick={this.props.toggleAddAffiliateNetworkDialog} className="normal_btn cancel_button gray_button_bg_imp black border_radius_normal_btn normal_text black no_border">Close</button>
                                    </div>
                                    <div className="right">
                                        {
                                            this.props.state.selectedAffiliateNetwork
                                                ?
                                                <button onClick={this.props.affiliateDialogueNextClicked} className="normal_btn theme_dark_btn theme_dark_bg_color_imp color_white_imp border_radius_normal_btn save_button normal_text">Next</button>
                                                :
                                                <button disabled={true} className="normal_btn theme_dark_bg_color_disabled_imp_disabled color_white_imp border_radius_normal_btn normal_text">Next</button>
                                        }
                                    </div>
                                </div>
                        }
                    </DialogActionsBar>
                </Dialog>
            </div>
        );
    }
}

export const mapStateToProps = createStructuredSelector({
    domainsDataReceived: makeSelectDomainsDataReceived(),
    accessToken: makeSelectToken(),
    // Affiliate network props
    loading: makeSelectLoading(),
    success: makeSelectSuccess(),
    error: makeSelectError(),
    trackingUrlsReceived: makeSelectTrackingUrlsReceived(),
    currenciesListReceived: makeSelectLoadCurrenciesList(),
    isNewAffiliateNetworkDataSent: makeSelectIsNewAffiliateNetworkDataSent(),
    // loadCustomAffiliateNetworkDataReceived: makeSelectCustomAffiliateNetworkData(),
    isCustomLoadById:makeSelectIsCustomAffiliateNetworkDataLoadedById(),

});
export function mapDispatchToProps(dispatch) {
    return {
        dispatchLoadDomains: (...payload) => dispatch(domainsActions.loadCustomDomains(...payload)),
        dispatchLoadTrackingURLS: (...payload) => dispatch(affiliatedNetworksActions.loadTrackingURLS(...payload)),
        dispatchLoadCurrencies: (...payload) => dispatch(affiliatedNetworksActions.loadCurrency(...payload)),
        dispatchAddNewAffiliateNetwork: (...payload) => dispatch(affiliatedNetworksActions.postNewAffiliateNetwork(...payload)),
        dispatchResetCustomDataById: () => dispatch(affiliatedNetworksActions.resetCustomDataById())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AffiliateNetworksModal);