import axios from "axios";
import { accessTokenExists, checkAccessTokenExpiry } from "../../components/accessTokenCheck";
import { logoutAndClear } from "../../components/logoutAndClear";
import { logoutUser } from "../../registration/LoginRegister/actions";
import auth from "../../auth";
import { BASE_URL } from "./path";

let tellMobiInstance;

export const tellMobiAxiosInstance = () => {
    if (accessTokenExists()) {
        let accessToken = localStorage.getItem('accessToken');
        accessToken = accessToken.replace('"', '').replace('"', '');
        checkAccessTokenExpiry(logoutUser, auth);
    
        // // 1st method
        // axios.interceptors.request.use(
        //     config => {
        //         config.headers.Authorization = `Bearer ${accessToken}`;
        //         return config;
        //     },
        //     error => {
        //         logoutAndClear(logoutUser, auth);
        //     }
        // );
    
        // 2nd method, create instance for your own website and for others as well.
        tellMobiInstance = axios.create({
            baseURL: BASE_URL,
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }
        });
    }
}

// all the get requests
export const get = async (url) => {
    checkAccessTokenExpiry(logoutUser, auth);
    return tellMobiInstance.get(url);
};

// all the post requests
export const post = (url, payload) => {
    checkAccessTokenExpiry(logoutUser, auth);
    return tellMobiInstance.post(url, payload);
};

// all the delete requests
// Naming 'delet' is not a mistake
// 'delete' is reserved keyword we can not use.
export const delet = async (url) => {
    checkAccessTokenExpiry(logoutUser, auth);
    return tellMobiInstance.delete(url);
};

// all the patch requests
export const patch = (url, payload) => {
    checkAccessTokenExpiry(logoutUser, auth);
    return tellMobiInstance.patch(url, payload);
};