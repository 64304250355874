export const timeZonesConversion = [
    {
        "id":1,
        "name":"(GMT -12:00) Eniwetok, Kwajalein",
        "calendar": "Pacific/Kwajalein" 
    },
    {
        "id":2,
        "name":"(GMT -11:00) Midway Island, Samoa",
        "calendar": "Pacific/Midway" 
    },
    {
        "id":3,
        "name":"(GMT -10:00) Hawaii",
        "calendar": "Pacific/Honolulu" 
    },
    {
        "id":4,
        "name":"(GMT -09:00) Alaska",
        "calendar": "America/Anchorage" 
    },
    {
        "id":5,
        "name":"(GMT -08:00) Pacific Time (US & Canada)",
        "calendar": "America/Los_Angeles" 
    },
    {
        "id":6,
        "name":"(GMT -07:00) Mountain Time (US & Canada)",
        "calendar": "America/Denver" 
    },
    {
        "id":7,
        "name":"(GMT -06:00) Central Time (US & Canada), Mexico City",
        "calendar": "America/Chicago" 
    },
    {
        "id":8,
        "name":"(GMT -05:00) Eastern Time (US & Canada), Bogota, Lima",
        "calendar": "America/New_York" 
    },
    {
        "id":9,
        "name":"(GMT -04:00) Atlantic Time (Canada), Caracas, La Paz",
        "calendar": "America/La_Paz" 
    },
    {
        "id":10,
        "name":"(GMT -03:30) Newfoundland",
        "calendar": "Canada/Newfoundland" 
    },
    {
        "id":11,
        "name":"(GMT -03:00) Brazil, Buenos Aires, Georgetown",
        "calendar": "America/Sao_Paulo" 
    },
    {
        "id":12,
        "name":"(GMT -02:00) Mid-Atlantic",
        "calendar": "Atlantic/South_Georgia" 
    },
    {
        "id":13,
        "name":"(GMT -01:00) Azores, Cape Verde Islands",
        "calendar": "Atlantic/Cape_Verde" 
    },
    {
        "id":14,
        "name":"(GMT +00:00) Western Europe Time, London, Lisbon, Casablanca",
        "calendar": "Europe/London" 
    },
    {
        "id":15,
        "name":"(GMT +01:00) Brussels, Copenhagen, Madrid, Paris",
        "calendar": "Europe/Paris" 
    },
    {
        "id":16,
        "name":"(GMT +02:00) Kaliningrad, South Africa",
        "calendar": "Europe/Istanbu" 
    },
    {
        "id":17,
        "name":"(GMT +03:00) Baghdad, Riyadh, Moscow, St. Petersburg",
        "calendar": "Europe/Moscow" 
    },
    {
        "id":18,
        "name":"(GMT +03:30) Tehran",
        "calendar": "Asia/Tehran" 
    },
    {
        "id":19,
        "name":"(GMT +04:00) Abu Dhabi, Muscat, Baku, Tbilisi",
        "calendar": "Asia/Dubai" 
    },
    {
        "id":20,
        "name":"(GMT +04:30) Kabul",
        "calendar": "Asia/Kabul" 
    },
    {
        "id":21,
        "name":"(GMT +05:00) Ekaterinburg, Islamabad, Karachi, Tashkent",
        "calendar": "Asia/Karachi" 
    },
    {
        "id":22,
        "name":"(GMT +05:30) Bombay, Calcutta, Madras, New Delhi",
        "calendar": "Asia/Calcutta" 
    },
    {
        "id":23,
        "name":"(GMT +05:45) Kathmandu",
        "calendar": "Asia/Katmandu" 
    },
    {
        "id":24,
        "name":"(GMT +06:00) Almaty, Dhaka, Colombo",
        "calendar": "Asia/Dhaka" 
    },
    {
        "id":25,
        "name":"(GMT +07:00) Bangkok, Hanoi, Jakarta",
        "calendar": "Asia/Bangkok" 
    },
    {
        "id":26,
        "name":"(GMT +08:00) Beijing, Perth, Singapore, Hong Kong",
        "calendar": "Asia/Shanghai" 
    },
    {
        "id":27,
        "name":"(GMT +09:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk",
        "calendar": "Asia/Tokyo" 
    },
    {
        "id":28,
        "name":"(GMT +09:30) Adelaide, Darwin",
        "calendar": "Australia/Darwin" 
    },
    {
        "id":29,
        "name":"(GMT +10:00) Eastern Australia, Guam, Vladivostok",
        "calendar": "Australia/Sydney" 
    },
    {
        "id":30,
        "name":"(GMT +11:00) Magadan, Solomon Islands, New Caledonia",
        "calendar": "Asia/Magadan" 
    },
    {
        "id":31,
        "name":"(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka",
        "calendar": "Pacific/Auckland" 
    }
  ];

export const returnTimeZoneForCalendar = (id) => {
    let match = "";
    for (let i=0;i<timeZonesConversion.length;i++) {
        if (Number(id) === Number(timeZonesConversion[i].id)) {
            match = timeZonesConversion[i].calendar;
        }
    }
    return match;
}

export const returnTimeZoneId = (calendarValue) => {
    let match = "";
    for (let i=0;i<timeZonesConversion.length;i++) {
        if (calendarValue === timeZonesConversion[i].calendar) {
            match = timeZonesConversion[i].id;
        }
    }
    return match;
}

export const returnTimeZoneName = (calendar) => {
    let match = "";
    for (let i=0;i<timeZonesConversion.length;i++) {
        if (calendar === timeZonesConversion[i].calendar) {
            match = timeZonesConversion[i].name;
        }
    }
    return match;
}