import React, {Component} from "react";
import produce from "immer";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import ModalForm from "./ModalForm";
import {createStructuredSelector} from "reselect";
import {connect} from 'react-redux';
import * as domainsActions from "../../../Settings/Domains/actions";
import * as affiliatedNetworksActions from "../../AffiliateNetworks/actions";
import * as offersActions from "../actions"
import * as workspaceActions from "../../../Settings/CollaborationTools/actions";
import * as flowsActions from "../../NewFlows/actions";
import * as TSActions from "../../TrafficSources/actions";
import {makeSelectDomainsDataReceived} from "../../../Settings/Domains/selectors";
import {makeSelectToken} from "../../../../registration/LoginRegister/selectors";
import { makeSelectWorkspaceData } from "../../../Settings/CollaborationTools/selectors";
import {
    makeSelectLoading,
    makeSelectSuccess,
    makeSelectError,
    makeSelectTrackingUrlsReceived,
    makeSelectLoadCurrenciesList,
    makeSelectNewOfferDataSent,
    makeSelectLoadTimeZoneData,
} from "../selectors";
import { makeSelectCountriesData } from "../../NewFlows/selectors";
import { makeSelectAffiliatedNetworkDataLoaded } from "../../AffiliateNetworks/selectors";
import { makeSelectIsTagsListReceived, makeSelectTagsListReceived } from "../../TrafficSources/selectors";
import LoadingPanel from "../../../grid/loader/loader";

class OffersModal extends Component {
    state = {
        dataToSend: {
                "name":"",
                "workspaceId":"",
                "affilateNetworkId":"",
                "countryId":"",
                "offer_url":"",
                "domainId":"",
                "tracking_urlId":"",
                "payoutType":"Auto",
                "payout":'',
                "currencyId":"",
                "timeZoneId":"",
                "cap_size":'',
                "offerId": null,
                "tags":null,
        },
        "append_click_offer": false,
        "postback_pixel": false,
        "ip_address_ranges": null,
        "parameters": [
            {
                "name": "Click Id",
                "an_parameter": "",
                "an_token": "",
                "vlm_token": "{clickid}"
            },
            {
                "name": "Payout",
                "an_parameter": "payout",
                "an_token": "",
                "vlm_token": "{payout}"
            },
            {
                "name": "Transaction Id",
                "an_parameter": "",
                "an_token": "",
                "vlm_token": "{txid}"
            },
            {
                "name": "Event Type",
                "an_parameter": "",
                "an_token": "",
                "vlm_token": "{eventType}"
            }
        ],
        empty_values: {
            "name": false,
            " workspaceId": false,
            "domainId": false,
            "currencyId": false,
            "clickId_an_parameter": false,
            "tags_name": false,
            "offer_url":false,
            "AffNet": false,
            "backendError":false,
        },
        domainsDataReceived : null,
        trackingUrlsReceived: null,
        currenciesListReceived: null,
        workspaceDataReceived: null,
        countriesDataReceived: null,
        affiliateNetworkDataReceived: null,
        tagsListReceived: null,
        timezoneDataReceived: null,
        
        is_postback_url_valid: true,
        is_protocol_valid: true,
        is_domain_valid: true,
        resolveErrors: "",
        conversion_tracking_code_input_value: '',
        showCIdReplaceWarning: false,
        url_value: {
            "domain_name": "",
            "tracking_url_code": "",
            "click_id_token": "",
            "is_click_id_token_populated": false,
            "before_payout_ampersand": "",
            "payout_token": "",
            "is_payout_token_populated": false,
            "before_tx_id_ampersand": "",
            "tx_id_token": "",
            "is_tx_id_token_populated": false,
            "before_et_ampersand": "",
            "et_token": "",
            "is_et_token_populated": false
        },
        tracking_url_id_code_before: "",
        tracking_url_id_code_after: "",
        ip_address_switch_value: false,
        ip_address_valid_value: true,
        disableSaveBtn: false,
        sendingRequest:false,
        isOfferStateDataLoaded:false,
    }
    fetchDomains = async () => {
        return this.props.dispatchLoadDomains(this.props.accessToken);
    };
    fetchTrackingUrls = async () => {
        return this.props.dispatchLoadTrackingURLS(this.props.accessToken);
    }
    fetchCurrencies = async () => {
        return this.props.dispatchLdCurrencies(this.props.accessToken);
    }
    fetchWorkspaces = async () => {
        return this.props.dispatchLoadWorkspaces(this.props.accessToken);
    }
    fetchCountries = async () => {
        return this.props.dispatchLoadCountries(this.props.accessToken);
    }
    fetchAffiliateNetworks = async () => {
        return this.props.dispatchLoadAffiliateNetworks({
            accessToken: this.props.accessToken,
            searchData: JSON.parse(localStorage.getItem("search")),
            sorting: this.state.sorting,
            primaryPage: false,
            dataType: "short"
        });
    }
    fetchTags = async () => {
        return this.props.dispatchLoadTags(this.props.accessToken);
    }
    fetchTimezone = async () => {
        return this.props.dispatchLoadTimezone(this.props.accessToken);
    }
    
    sendOfferData = async () => {
        if(this.state.dataToSend.payoutType === "Auto") {
            this.setState(produce(draft => { draft.dataToSend.payout = null }));
        }
        else {
            this.setState(produce(draft => { draft.dataToSend.payout = parseFloat(this.state.dataToSend.payout).toFixed(1) }));
        }
        if(this.state.dataToSend.cap_size === "") {
            this.setState(produce(draft => { draft.dataToSend.cap_size = null }));
        }
        else {
            this.setState(produce(draft => { draft.dataToSend.cap_size = parseInt(this.state.dataToSend.cap_size)}));
        }
        setTimeout(() => {
            return this.props.dispatchAddNewOffer({
                data: this.state.dataToSend,
                accessToken: this.props.accessToken
            });
        },500);
    }

    componentDidMount() {
        //fetching domains and workspaces
        // this.fetchDomains();
        // this.fetchTrackingUrls();
        // this.fetchCurrencies();
        // this.fetchWorkspaces();
        // this.fetchCountries();
        // this.fetchAffiliateNetworks();
        // this.fetchTags();
        // this.fetchTimezone();

        // setting initial values in the state on component load
        
    }

    componentDidUpdate(prevProps,prevState) {

        if(this.props.affiliateNetworkDataReceived !==null && this.props.domainsDataReceived && this.props.timezoneDataReceived && this.props.isTagsListReceived !== null && this.props.countriesDataReceived && this.props.currenciesListReceived && this.props.trackingUrlsReceived && !this.state.isOfferStateDataLoaded){
            if (this.props.domainsDataReceived) {
                this.setState(produce(draft => { draft.dataToSend.workspaceId = String(this.props.domainsDataReceived.workspace[0].id)}));
                this.setState(produce(draft => { draft.domainsDataReceived = this.props.domainsDataReceived }));
                this.setState(produce(draft => { draft.workspaceDataReceived = this.props.domainsDataReceived.workspace }));
                if (this.props.domainsDataReceived.domain === []) {
                    this.setState(produce(draft => { draft.empty_values.domainId = true}));
                } else {
                    for (let i = 0; i < this.props.domainsDataReceived.domain.length; i++){
                        if (this.props.domainsDataReceived.domain[i].selected === true) {
                            this.setState(produce(draft => {
                                draft.dataToSend.domainId = String(this.props.domainsDataReceived.domain[i].id);
                                let name = this.props.domainsDataReceived.domain[i].name;
                                if (name.startsWith("http://")) {
                                    draft.url_value.domain_name = "https://" + name.split("http://")[1];
                                } else if (name.startsWith("https://")) {
                                    draft.url_value.domain_name = name;
                                } else {
                                    draft.url_value.domain_name = "https://" + name;
                                }
                            }));
    
                            this.setState(produce(draft => { draft.empty_values.domainId = false}));
                        }
                    }
                }
            }
            if (this.props.trackingUrlsReceived){
                let trackingUrls = [...this.props.trackingUrlsReceived];
                trackingUrls.shift();
                this.setState(produce(draft => {
                    draft.trackingUrlsReceived = trackingUrls;
                    // set initial tracking url id
                    draft.dataToSend.tracking_urlId = String(trackingUrls[0].id);
                    // Set initial tracking url code in state.url_value
                    draft.url_value.tracking_url_code = trackingUrls[0].code
                    // When initial load... tracking url is set to postback url so we add cid=REPLACE initially...
                    draft.url_value.click_id_token = "cid=REPLACE";
                    draft.url_value.is_click_id_token_populated = true;
                    draft.showCIdReplaceWarning = true;
                    draft.url_value.payout_token = "payout=REPLACE";
                    draft.url_value.before_payout_ampersand = "&";
                    draft.url_value.is_payout_token_populated = true;
                }));
            }
            if(this.props.currenciesListReceived) {
                this.setState(produce(draft => { draft.currenciesListReceived = this.props.currenciesListReceived }));
                // Set initial currency value to the state
                this.setState(produce(draft => { draft.dataToSend.currencyId = String(this.props.currenciesListReceived[0].id) }));
            }
            if (this.props.countriesDataReceived) {
                this.setState(produce(draft => {
                    draft.countriesDataReceived = this.props.countriesDataReceived;
                    draft.dataToSend.countryId = String(this.props.countriesDataReceived[98].id);
                }));
            }
            if (this.props.affiliateNetworkDataReceived) {
                this.setState(produce(draft => { draft.affiliateNetworkDataReceived = this.props.affiliateNetworkDataReceived }));
                if (this.props.affiliateNetworkDataReceived.data.length > 0) {
                    this.setState(produce(draft => { draft.dataToSend.affilateNetworkId = String(this.props.affiliateNetworkDataReceived.data[0].id) }));
                }
            }
            if (this.props.isTagsListReceived !== null && this.props.isTagsListReceived !== undefined && this.state.tagsListReceived === null) {
                this.setState(produce(draft => { draft.tagsListReceived = this.props.tagsListReceived }));
            }
    
            if (this.props.timezoneDataReceived) {
                this.setState(produce(draft => { draft.timezoneDataReceived = this.props.timezoneDataReceived }));
                this.setState(produce(draft => { draft.dataToSend.timeZoneId = String(this.props.timezoneDataReceived[0].id) }));
            }
            if (this.props.affiliateNetworkDataReceived !== null) {
                if (this.props.affiliateNetworkDataReceived.data.length === 0) {
                    this.setState(produce(draft => { draft.empty_values.AffNet = true; }));
                }
            }
            this.setState(produce(draft=>{draft.isOfferStateDataLoaded=true}));
        }

        if((prevState.dataToSend.offer_url !== this.state.dataToSend.offer_url)){
            this.changeTagsColor(this.state.dataToSend.offer_url);
        }




        if ((prevProps.newOfferDataSent !== this.props.newOfferDataSent) && this.props.error === false && this.props.newOfferDataSent) {
            this.setState(produce(draft => { draft.disableSaveBtn = true }));
            this.setState(produce(draft => { draft.empty_values.backendError = false }));
            this.props.closeOfferDialogueAfterSubmission("Add_Offer");
            // this.props.toggleLoader();
            setTimeout(() => {
                this.setState(produce(draft => { draft.disableSaveBtn = false }));
            },600);
        }
        if ((prevProps.error !== this.props.error) && this.props.error !== false && !this.props.newOfferDataSent) {
            this.setState(produce(draft => { draft.disableSaveBtn = false }));
            this.setState(produce(draft => { draft.sendingRequest = false }));
            this.setState(produce(draft => { draft.empty_values.backendError = true }));
            this.props.toggleLoader();
        }
        if ((((prevState.empty_values.name !== this.state.empty_values.name) && !this.state.empty_values.name) && ((prevState.empty_values.offer_url !== this.state.empty_values.offer_url) && !this.state.empty_values.offer_url)) || (this.state.sendingRequest && !this.state.disableSaveBtn)) {
            if (!this.state.empty_values.name && !this.state.empty_values.offer_url && !this.state.empty_values.AffNet) {
                this.setState(produce(draft => { draft.disableSaveBtn = true }));
                this.props.toggleLoader();
                this.sendOfferData();
            }
            else {
                this.setState(produce(draft => { draft.sendingRequest = false }));
            }
        }
    }

    inputChangeHandler = (e) => {
        this.setState(produce(draft => { draft.dataToSend[e.target.name] = e.target.value }));
        if (e.target.name === "domainId") {
            for (let i=0; i<this.state.domainsDataReceived.domain.length;i++) {
                if (e.target.value === String(this.state.domainsDataReceived.domain[i].id)) {
                    if(this.state.domainsDataReceived.domain[i].name.startsWith("https://")){
                        this.setState(produce(draft => { draft.url_value.domain_name = this.state.domainsDataReceived.domain[i].name}));
                    }
                    else if(this.state.domainsDataReceived.domain[i].name.startsWith("http://")){
                        this.setState(produce(draft => {draft.url_value.domain_name = [this.state.domainsDataReceived.domain[i].name.slice(0, 4), "s", this.state.domainsDataReceived.domain[i].name.slice(4)].join('')}));
                    }else{
                        this.setState(produce(draft => {draft.url_value.domain_name = [this.state.url_value.domain_name.slice(0, 0), "https://", this.state.domainsDataReceived.domain[i].name.slice(0)].join('')}));
                    }
                }
            }
        }
        if (e.target.name === "tracking_urlId") {
            if (e.target.value === "3") {
                this.setState(produce(draft => { draft.tracking_url_id_code_before = '<img src="' }));
                this.setState(produce(draft => { draft.tracking_url_id_code_after = '" width="1" height="1" />' }));

            } else {
                this.setState(produce(draft => { draft.tracking_url_id_code_before = '' }));
                this.setState(produce(draft => { draft.tracking_url_id_code_after = '' }));
            }
            if (e.target.value !== "2" && this.state.parameters[0].an_token === "cid=REPLACE") {
                this.setState(produce(draft => { draft.parameters[0].an_token = "" }));
                this.setState(produce(draft => { draft.url_value.is_click_id_token_populated = false }));
            }
            if (e.target.value === "2" && this.state.parameters[0].an_token === "") {
                this.setState(produce(draft => { draft.parameters[0].an_token = "cid=REPLACE" }));
                this.setState(produce(draft => { draft.url_value.is_click_id_token_populated = true }));
            }
        }
    }
    

    // Token change handler
    anTokenChangeHandler = (e) => {
        const arr_split = e.target.name.split("_");
        const arr_index = arr_split[arr_split.length -1];
        this.setState(produce(draft => { draft.parameters[arr_index].an_token = e.target.value }));
        // check if name if an_token_1
        if (e.target.name === "an_token_0") {
            if (this.state.url_value.tracking_url_code === "/postback?" && e.target.value === "") {
                this.setState(produce(draft => void (draft.url_value.click_id_token = "cid=REPLACE" )));
                this.setState(produce(draft => { draft.url_value.is_click_id_token_populated = true }));
            } else if (e.target.value !== "") {
                this.setState(produce(draft => void (draft.url_value.click_id_token = ("cid=" + e.target.value) )));
                this.setState(produce(draft => { draft.url_value.is_click_id_token_populated = true }));
            } else {
                this.setState(produce(draft => void (draft.url_value.click_id_token = "" )));
                this.setState(produce(draft => { draft.url_value.is_click_id_token_populated = false }));
            }
            // if not empty check for other & signs
            // populate &s after while checking their values
            if (e.target.value !== "" && this.state.url_value.is_payout_token_populated) {
                this.setState(produce(draft => {draft.url_value.before_payout_ampersand = "&" }));
            }
            if (e.target.value !== "" && this.state.url_value.is_tx_id_token_populated) {
                this.setState(produce(draft => {draft.url_value.before_tx_id_ampersand = "&" }));
            }
            if (e.target.value !== "" && this.state.url_value.is_et_token_populated) {
                this.setState(produce(draft => {draft.url_value.before_et_ampersand = "&" }));
            }
            // remove &s after whiles checking their values if this becomes empty
            if (e.target.value === "" && this.state.url_value.is_payout_token_populated) {
                this.setState(produce(draft => {draft.url_value.before_payout_ampersand = "" }));
            }
            if (e.target.value === "" && this.state.url_value.is_tx_id_token_populated && !this.state.url_value.is_payout_token_populated) {
                this.setState(produce(draft => {draft.url_value.before_tx_id_ampersand = "" }));
            }
            if (e.target.value === "" && this.state.url_value.is_et_token_populated && !this.state.url_value.is_payout_token_populated && !this.state.url_value.is_tx_id_token_populated) {
                this.setState(produce(draft => {draft.url_value.before_et_ampersand = "" }));
            }
        } else if (e.target.name === "an_token_1") {
            if (e.target.value !== "") {
                this.setState(produce(draft => { draft.url_value.payout_token = "payout=" + e.target.value }));
                this.setState(produce(draft => { draft.url_value.is_payout_token_populated = true }));
            } else {
                this.setState(produce(draft => { draft.url_value.payout_token = "" }));
                this.setState(produce(draft => { draft.url_value.is_payout_token_populated = false }));
            }
            //condition for & before the payout
            if (this.state.url_value.is_click_id_token_populated && e.target.value !== "") {
                this.setState(produce(draft => { draft.url_value.before_payout_ampersand = "&" }));
            } else { this.setState(produce(draft => { draft.url_value.before_payout_ampersand = "" })); }
            // condition for & before the txid
            if (this.state.url_value.is_tx_id_token_populated && e.target.value !== "") {
                this.setState(produce(draft => { draft.url_value.before_tx_id_ampersand = "&" }));
            }
            // condition for & before et
            if (this.state.url_value.is_et_token_populated && e.target.value !== "") {
                this.setState(produce(draft => { draft.url_value.before_et_ampersand = "&" }));
            }
            //  if this empty and there is no value in click id / payout / tx-id then remove & before et
            if (this.state.url_value.is_et_token_populated && e.target.value === "" && !this.state.url_value.is_click_id_token_populated && !this.state.url_value.is_tx_id_token_populated) {
                this.setState(produce(draft => {draft.url_value.before_et_ampersand = "" }));
            }
        } else if (e.target.name === "an_token_2") {
            if (e.target.value !== "") {
                this.setState(produce(draft => { draft.url_value.tx_id_token = "txid=" + e.target.value }));
                this.setState(produce(draft => { draft.url_value.is_tx_id_token_populated = true }));
            } else {
                this.setState(produce(draft => { draft.url_value.tx_id_token = "" }));
                this.setState(produce(draft => { draft.url_value.is_tx_id_token_populated = false }));
            }
            //condition for & before the txid
            if ((this.state.url_value.is_click_id_token_populated || this.state.url_value.is_payout_token_populated) && e.target.value !== "") {
                this.setState(produce(draft => { draft.url_value.before_tx_id_ampersand = "&" }));
            }
            // condition for & before et
            if (this.state.url_value.is_et_token_populated && e.target.value !== "") {
                this.setState(produce(draft => {draft.url_value.before_et_ampersand = "&" }));
            }
            // removal condition for & before txid
            if (e.target.value === "") {
                this.setState(produce(draft => { draft.url_value.before_tx_id_ampersand = "" }))
            }
            // if empty check for the & after it
            if (e.target.value === "" && this.state.url_value.is_et_token_populated && !this.state.url_value.is_payout_token_populated && !this.state.url_value.is_click_id_token_populated) {
                this.setState(produce(draft => {draft.url_value.before_et_ampersand = "" }));
            }
        } else if (e.target.name === "an_token_3") {
            if (e.target.value !== "") {
                this.setState(produce(draft => { draft.url_value.et_token = "et=" + e.target.value }));
                this.setState(produce(draft => { draft.url_value.is_et_token_populated = true }));
            } else {
                this.setState(produce(draft => { draft.url_value.et_token = "" }));
                this.setState(produce(draft => { draft.url_value.is_et_token_populated = false }));
            }
            // condition for & before et
            if ((this.state.url_value.is_click_id_token_populated || this.state.url_value.is_payout_token_populated || this.state.url_value.is_tx_id_token_populated) && e.target.value !== "") {
                this.setState(produce(draft => { draft.url_value.before_et_ampersand = "&"}));
            }
            else {
                this.setState(produce(draft => { draft.url_value.before_et_ampersand = ""}));
            }
        }
    }


    anParameterChangeHandler = (e) => {
        this.setState(produce(draft => { draft.parameters[0].an_parameter = e.target.value }));
    }

    ValidateIPAddress = (ipaddress) =>  {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
            return (true)
        }
        // alert("You have entered an invalid IP address!")
        return (false)
    }

    switchChangeHandler = (e) => {
        this.setState(produce(draft => { draft.dataToSend[e.target.name] = !this.state.dataToSend[e.target.name]}));
    }

    ipAddressSwitchChangeHandler = (e) => {
        this.setState(produce(draft => { draft.ip_address_switch_value = !this.state.ip_address_switch_value}));
        this.setState(produce(draft => { draft.ip_address_ranges = "" }));
    }

    ipAddressCheckHandler = (e) => {
        this.setState(produce(draft => { draft.dataToSend[e.target.name] = e.target.value }));
        let un_filtered_ip_addresses_list = e.target.value.split("\n");
        let ip_addresses_list = un_filtered_ip_addresses_list.filter(function (el) {
            return (el !== null && el !== "");
        });
        for (let i = 0; i<ip_addresses_list.length; i++) {
            if(this.ValidateIPAddress(ip_addresses_list[i])) {
                this.setState(produce(draft => { draft.ip_address_valid_value = true }));
            } else {
                this.setState(produce(draft => { draft.ip_address_valid_value = false }));
            }
        }

    }

    submitOffersData = () => {
        if (this.state.dataToSend.name === "") {
            this.setState(produce(draft => { draft.empty_values.name = true }));
        }
        if (this.state.dataToSend.name !== "") {
            this.setState(produce(draft => { draft.empty_values.name = false }));
        }
        if (!this.state.dataToSend.offer_url.includes("{clickid}")) {
            this.setState(produce(draft => { draft.empty_values.offer_url = true }));
        }
        if (this.state.dataToSend.offer_url.includes("{clickid}")) {
            this.setState(produce(draft => { draft.empty_values.offer_url = false }));
        }
        if (this.props.affiliateNetworkDataReceived.data.length === 0) {
            this.setState(produce(draft => { draft.empty_values.AffNet = true }));
        }
        if (this.props.affiliateNetworkDataReceived.data.length > 0) {
            this.setState(produce(draft => { draft.empty_values.AffNet = false }));
        }
        setTimeout(() => {
            if (!this.state.empty_values.name && !this.state.empty_values.offer_url && !this.state.empty_values.AffNet) {
                this.setState(produce(draft => { draft.sendingRequest = true }));
            }
        }, 30);
    }
    addTokenToUrl = (e) => {
        if (!this.state.dataToSend.offer_url.includes(e.target.id)) {
            const value = this.state.dataToSend.offer_url + String(e.target.id);
            this.setState(produce(draft => { draft.dataToSend.offer_url =  value }));
            document.getElementById(e.target.id).style.backgroundColor = "#e0358b";
            document.getElementById(e.target.id).style.color = "#fff";
            this.checkProtocolsValidity(this.state.dataToSend.offer_url);
        }
    }
    checkProtocolsValidity = (data) => {
        if(this.hasValidUrlProtocol(data)) {
            this.setState(produce(draft => {draft.is_protocol_valid = true }));
        } else { this.setState(produce(draft => {draft.is_protocol_valid = false })); }
        if (this.hasValidDomainName(data)) {
            this.setState(produce(draft => { draft.is_domain_valid = true }));
        } else { this.setState(produce(draft => { draft.is_domain_valid = false })); }
    }

    changeTagsColor = (data) => {
        let indexes = [];
        if (this.state.tagsListReceived !== null && this.state.tagsListReceived !== undefined){
            for(let i=0;i<this.state.tagsListReceived.length;i++) {
                if (data.includes(this.state.tagsListReceived[i].code)) {
                    indexes.push(data.indexOf(this.state.tagsListReceived[i].code));
                    document.getElementById(this.state.tagsListReceived[i].code).style.backgroundColor = "#6786DE";
                    document.getElementById(this.state.tagsListReceived[i].code).style.color = "#fff";
                } else {
                    document.getElementById(this.state.tagsListReceived[i].code).style.backgroundColor = "#F0F3F7";
                    document.getElementById(this.state.tagsListReceived[i].code).style.color = "#8892AA";
                }
            }
        }
    }
    hasValidUrlProtocol = (url = '') => Boolean(['http://', 'https://', 'ftp://'].some(protocol => url.startsWith(protocol)));
    // checking for valid domain name
    hasValidDomainName = (text) => {
        return new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(text);

        // let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        //     '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        //     '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        //     '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*','i') // port and path
        //     //     // '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
        //     //     // '(\\#[-a-z\\d_]*)?$','i') // fragment locator
        // ;
        // return !!pattern.test(text);
    }
    offerUrlChangeHandler = (e) => {
        // let indexes = [];
        if (e.target.value !== "") {
            if(this.hasValidUrlProtocol(e.target.value)) {
                this.setState(produce(draft => {draft.is_protocol_valid = true }));
            } else { this.setState(produce(draft => {draft.is_protocol_valid = false })); }
            if (this.hasValidDomainName(e.target.value)) {
                this.setState(produce(draft => { draft.is_domain_valid = true }));
            } else { this.setState(produce(draft => { draft.is_domain_valid = false })); }
            // for(let i=0;i<this.state.tagsListReceived.length;i++) {
            //     if (e.target.value.includes(this.state.tagsListReceived[i].code)) {
            //         indexes.push(e.target.value.indexOf(this.state.tagsListReceived[i].code));
            //         document.getElementById(this.state.tagsListReceived[i].code).style.backgroundColor = "#6786DE";
            //         document.getElementById(this.state.tagsListReceived[i].code).style.color = "#fff";
            //     } else {
            //         document.getElementById(this.state.tagsListReceived[i].code).style.backgroundColor = "#F0F3F7";
            //         document.getElementById(this.state.tagsListReceived[i].code).style.color = "#8892AA";
            //     }
            // }
        }
        else {
            this.setState(produce(draft => {draft.is_protocol_valid = true}));
            this.setState(produce(draft => { draft.is_domain_valid = true }));
        }
        this.setState(produce(draft => { draft.dataToSend.offer_url = e.target.value }));
    }

    timezoneChangeHandler = (e) => {
        //console.log('timezone change value', e.target.value);
        this.setState(produce(draft => { draft.dataToSend.timeZoneId = e.target.value }));
    }

    payoutChangeHandler = (e) => {
        if(e.target.name==="payout") {
            this.setState(produce(draft => { draft.dataToSend.payout = e.target.value }));
        }
        if(e.target.name==="capSize") {
            this.setState(produce(draft => { draft.dataToSend.cap_size = e.target.value }));
        }
        if (e.target.name === "offerPayout") {
            this.setState(produce(draft => { draft.dataToSend.payoutType = e.target.value }));
        }
    }
    onClickIncrement = (e) => {
        e.preventDefault();
        
        if (e.currentTarget.name === "payout") {
            var payvalue = parseInt(document.getElementById('payout').value, 10);
            payvalue = isNaN(payvalue) ? 0 : payvalue;
            payvalue++;
            document.getElementById('payout').value = payvalue;
            this.setState(produce(draft => { draft.dataToSend.payout = payvalue }));
        }
        if (e.currentTarget.name === "capSize") {
        
            var capvalue = parseInt(document.getElementById('capSize').value, 10);
            capvalue = isNaN(capvalue) ? 0 : capvalue;
            capvalue++;
            document.getElementById('capSize').value = capvalue;
            this.setState(produce(draft => { draft.dataToSend.cap_size = capvalue }));
        }
    }
    onClickDecrement = (e) => {
        e.preventDefault();
        
        if (e.currentTarget.name === "payout") {
            var payvalue = parseInt(document.getElementById('payout').value, 10);
            payvalue = isNaN(payvalue) ? 0 : payvalue;
            payvalue--;
            document.getElementById('payout').value = payvalue;
            this.setState(produce(draft => { draft.dataToSend.payout = payvalue }));
        }
        if (e.currentTarget.name === "capSize") {
            
            var value = parseInt(document.getElementById('capSize').value, 10);
            value = isNaN(value) ? 0 : value;
            value--;
            document.getElementById('capSize').value = value;
            this.setState(produce(draft => { draft.dataToSend.cap_size = value }));
        }
    }

    handleTagChange = (event) => {
        const values = event.target.value;
        const lastItem = values[values.length - 1];

        if (lastItem) {
            values.pop();
            const sameItem = values.find(value => value === lastItem);
            if (sameItem === undefined) {
                values.push(lastItem);
            }
        }
        this.setState(produce(draft => { draft.dataToSend.tags = values }));
    }

    render() {
        // console.log('time zone data',this.props.timezoneDataReceived);
        return (
            <div>
                <Dialog className="add_affiliate_networks_dialogue" title={"Add offer"} onClose={this.props.toggleAddOfferDialog}>

                    <div className="add_affiliate_network_dialogue_content">
                        {
                            this.props.state.offerDialogueNextBtnClicked
                                ?
                                this.state.domainsDataReceived ?
                                    <div className="content_form">
                                        <ModalForm
                                            state={this.state}
                                            inputChangeHandler={this.inputChangeHandler}
                                            anTokenChangeHandler={this.anTokenChangeHandler}
                                            anParameterChangeHandler={this.anParameterChangeHandler}
                                            switchChangeHandler={this.switchChangeHandler}
                                            ipAddressSwitchChangeHandler={this.ipAddressSwitchChangeHandler}
                                            ipAddressCheckHandler={this.ipAddressCheckHandler}
                                            tagsListReceived={this.state.tagsListReceived}
                                            addTokenToUrl={this.addTokenToUrl}
                                            offerUrlChangeHandler={this.offerUrlChangeHandler}
                                            timezoneChangeHandler={this.timezoneChangeHandler}
                                            payoutChangeHandler={this.payoutChangeHandler}
                                            onClickIncrement={this.onClickIncrement}
                                            onClickDecrement={this.onClickDecrement}
                                            handleTagChange={this.handleTagChange}
                                        />
                                    </div>
                                    : null
                                :
                                <div className="content">
                                    <div className="box">
                                        <div className="inner">
                                            <span className="text" id="custom-affiliate-network" onClick={this.props.selectOffer}>Create custom offer</span>
                                        </div>
                                    </div>
                                </div>
                        }
                        {/* {(this.props.loading ) && <LoadingPanel/>} */}
                    </div>
                    <DialogActionsBar>
                        {
                            this.props.state.offerDialogueNextBtnClicked
                                ?
                                <div className="action_bar_buttons">
                                    <div className="left">
                                        <button onClick={this.props.toggleAddOfferDialog} className="normal_btn cancel_button gray_button_bg_imp black border_radius_normal_btn normal_text black no_border">Close</button>
                                    </div>
                                    <div className="right">
                                        <button onClick={this.submitOffersData} disabled={this.state.disableSaveBtn} className="normal_btn theme_dark_btn theme_dark_bg_color_imp color_white_imp border_radius_normal_btn save_button normal_text">Save</button>
                                    </div>
                                </div>
                                :
                                <div className="action_bar_buttons">
                                    <div className="left">
                                        <button onClick={this.props.toggleAddOfferDialog} className="normal_btn cancel_button gray_button_bg_imp black border_radius_normal_btn normal_text black no_border">Close</button>
                                    </div>
                                    <div className="right">
                                        {
                                            this.props.state.selectedOffers
                                                ?
                                                <button onClick={this.props.offerDialogueNextClicked} className="normal_btn theme_dark_btn theme_dark_bg_color_imp color_white_imp border_radius_normal_btn save_button normal_text">Next</button>
                                                :
                                                <button disabled={true} className="normal_btn theme_dark_bg_color_disabled_imp_disabled color_white_imp border_radius_normal_btn normal_text">Next</button>
                                        }
                                    </div>
                                </div>
                        }
                    </DialogActionsBar>
                </Dialog>
            </div>
        );
    }
}

export const mapStateToProps = createStructuredSelector({
    domainsDataReceived: makeSelectDomainsDataReceived(),
    accessToken: makeSelectToken(),
    // Affiliate network props
    loading: makeSelectLoading(),
    success: makeSelectSuccess(),
    error: makeSelectError(),
    trackingUrlsReceived: makeSelectTrackingUrlsReceived(),
    currenciesListReceived: makeSelectLoadCurrenciesList(),
    newOfferDataSent: makeSelectNewOfferDataSent(),
    workspaceDataReceived: makeSelectWorkspaceData(),
    countriesDataReceived: makeSelectCountriesData(),
    affiliateNetworkDataReceived: makeSelectAffiliatedNetworkDataLoaded(),
    tagsListReceived: makeSelectTagsListReceived(),
    isTagsListReceived: makeSelectIsTagsListReceived(),
    timezoneDataReceived: makeSelectLoadTimeZoneData()
});
export function mapDispatchToProps(dispatch) {
    return {
        dispatchLoadDomains: (...payload) => dispatch(domainsActions.loadCustomDomains(...payload)),
        dispatchLoadTrackingURLS: (...payload) => dispatch(offersActions.loadTrackingURLS(...payload)),
        dispatchAddNewOffer: (...payload) => dispatch(offersActions.postNewOffer(...payload)),
        dispatchLoadWorkspaces: (...payload) => dispatch(workspaceActions.fetchingData(...payload)),
        dispatchLdCurrencies: (...payload) => dispatch(offersActions.loadCurrency(...payload)),
        dispatchLoadCountries: (...payload) => dispatch(flowsActions.loadCountries(...payload)),
        dispatchLoadAffiliateNetworks: (...payload) => dispatch(affiliatedNetworksActions.loadAffiliateNetwork(...payload)),
        dispatchLoadTags: (...payload) => dispatch(TSActions.loadTokensDictionary(...payload)),
        dispatchLoadTimezone: (...payload) => dispatch(offersActions.loadTimezone(...payload)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OffersModal);