import axios from "axios";
import { BASE_URL } from "../containers/helpers/path";

export const MoveToWorkspaceFromAPI = async (request) => {
    axios.interceptors.request.use(req => {
        req.headers.authorization = request.payload.accessToken;
        return req;
    });

    let parameters = "?type=" + request.payload.query.type + "&typeId=" + request.payload.query.typeIdStringList + "&workspace=" + request.payload.query.workspaceId;
    try {
        const response = await axios.post(BASE_URL + 'workspace/move-to'+parameters);
        return response;
    } catch (err) {
        return err;
    }
};