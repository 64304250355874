import { call, put, takeLatest } from "redux-saga/effects";
import {
    loadIPUAFailed, loadIPUASuccess,
    editIPUASuccess, editIPUAFailed,
    addIPUAFailed, addIPUASuccess,
    updateIPUAFailed, updateIPUASuccess,
    deleteIPUAFailed, deleteIPUASuccess
} from './actions';
import { addIPUAFilterApi, deleteIPUAFilterApi, editIPUAFilterApi, loadIPUAFilterApi, updateIPUAFilterApi } from "./api";
import { LOAD_IP_UA_FILTERING, EDIT_IP_UA_FILTERING, ADD_IP_UA_FILTERING, DELETE_IP_UA_FILTERING, UPDATE_IP_UA_FILTERING } from "./constants";




function* loadIPUAFilter(payload) {
    try {
        const response = yield call(loadIPUAFilterApi, payload.payload);
        yield put(loadIPUASuccess({ data: response.data }));
    } catch (error) {
        yield put( loadIPUAFailed(error));
    }
}

function* addIPUAFilter(payload) {
    try {
        const response = yield call(addIPUAFilterApi, payload.payload);
        //console.log('add response', response);
        yield put( addIPUASuccess({data:response.data}));
        //console.log('add ip ua saga');
    } catch(err) {
        //console.log('error saga');
        yield put( addIPUAFailed(err));
    }
}

function* updateIPUAFilter(payload) {
    try {
        const response = yield call(updateIPUAFilterApi, payload.payload);
        // console.log('response of load notifications', response);
        yield put( updateIPUASuccess({data:response.data}))
    } catch (error) {
        yield put( updateIPUAFailed(error));
    }
}

function* editIPUAFilter(payload) {
    try {
        const response = yield call(editIPUAFilterApi, payload.payload);
        // console.log('response of edit', response);
        yield put( editIPUASuccess({data:response.data}))
    } catch (error) {
        yield put( editIPUAFailed(error));
    }
}

function* deleteIPUAFilter(payload) {
    try {
        const response = yield call(deleteIPUAFilterApi, payload.payload);
        yield put( deleteIPUASuccess({data:response.data}))
    } catch (error) {
        yield put( deleteIPUAFailed(error));
    }
}



export default function* IPUAFilterSaga() {
    yield takeLatest(LOAD_IP_UA_FILTERING, loadIPUAFilter);
    yield takeLatest(EDIT_IP_UA_FILTERING, editIPUAFilter);
    yield takeLatest(ADD_IP_UA_FILTERING, addIPUAFilter);
    yield takeLatest(UPDATE_IP_UA_FILTERING, updateIPUAFilter);
    yield takeLatest(DELETE_IP_UA_FILTERING, deleteIPUAFilter);
  }