import { produce } from "immer";

import {
    LOAD_ALL_USERS_DATA,
    LOAD_ALL_USERS_DATA_SUCCESS,
    LOAD_ALL_USERS_DATA_FAILED,
    ADD_USER_BY_ADMIN,
    ADD_USER_BY_ADMIN_FAILED,
    ADD_USER_BY_ADMIN_SUCCESS,
    CHANGE_STATUS_USER, CHANGE_STATUS_USER_FAILED, CHANGE_STATUS_USER_SUCCESS,
    UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_FAILED,
    EDIT_USER, EDIT_USER_SUCCESS, EDIT_USER_FAILED, DELETE_USER, DELETE_USER_SUCCESS, DELETE_USER_FAILED
} from "./constants";

export const initialState = {
    loading: false,
    success: false,
    error: false,
    allUsersData: null,
    isUsersDataLoaded: false,
    isUserAdded: false,
    editUsersData: null,
    isEditUsersDataLoaded: false,
    updateUsersData: null,
    isUpdateUsersDataLoaded: false,
    isUserStatusUpdated: false,
    isUserUpdated: false,
    editUserDataLoaded: null,
    isDeleteSuccess:false,
};

const allUsersReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case LOAD_ALL_USERS_DATA:
                draft.loading = true;
                draft.success = false;
                draft.error = false;
                draft.isUsersDataLoaded = false;
                draft.isUserStatusUpdated = false;
                draft.isUserAdded = false;
                // draft.isUserUpdated = false;
                draft.allUsersData = null;
                // draft.isUserAdded = false;
                return draft;
            case LOAD_ALL_USERS_DATA_SUCCESS:
                draft.loading = false;
                draft.success = true;
                draft.error = false;
                draft.allUsersData = action.payload.allUserDataPayload;
                draft.isUsersDataLoaded = true;
                return draft;
            case LOAD_ALL_USERS_DATA_FAILED:
                draft.loading = false;
                draft.success = false;
                draft.error = action.payload.error.response.data;
                draft.isUsersDataLoaded = false;
                return draft;

            case ADD_USER_BY_ADMIN:
                draft.loading = true;
                draft.success = false;
                draft.error = false;
                draft.isUserAdded = false;
                return draft;
            case ADD_USER_BY_ADMIN_SUCCESS:
                draft.loading = false;
                draft.success = true;
                draft.error = false;
                draft.isUserAdded = true;
                return draft;
            case ADD_USER_BY_ADMIN_FAILED:
                draft.loading = false;
                draft.success = false;
                draft.error = action.payload.response.data;
                draft.isUserAdded = false;
                return draft;

            case CHANGE_STATUS_USER:
                draft.loading = true;
                draft.success = false;
                draft.error = false;
                draft.isUserStatusUpdated = false;
                return draft;
            case CHANGE_STATUS_USER_SUCCESS:
                draft.loading = false;
                draft.success = true;
                draft.error = false;
                draft.isUserStatusUpdated = true;
                return draft;
            case CHANGE_STATUS_USER_FAILED:
                draft.loading = false;
                draft.success = false;
                draft.error = action.payload.error.response.data;
                draft.isUserStatusUpdated = false;
                return draft;

            case UPDATE_USER:
                draft.loading = true;
                draft.success = false;
                draft.error = false;
                draft.isUserUpdated = false;
                return draft;
            case UPDATE_USER_SUCCESS:
                draft.loading = false;
                draft.success = true;
                draft.error = false;
                draft.isUserUpdated = true;
                return draft;
            case UPDATE_USER_FAILED:
                draft.loading = false;
                draft.success = false;
                draft.error = action.payload.error.response.data;
                draft.isUserUpdated = false;
                return draft;

            case EDIT_USER:
                draft.loading = true;
                draft.success = false;
                draft.error = false;
                draft.editUserDataLoaded = null;
                return draft;
            case EDIT_USER_SUCCESS:
                draft.loading = false;
                draft.success = true;
                draft.error = false;
                draft.editUserDataLoaded = action.payload;
                return draft;
            case EDIT_USER_FAILED:
                draft.loading = false;
                draft.success = false;
                draft.error = action.payload.error.response.data;
                draft.editUserDataLoaded = null;
                return draft;

            case DELETE_USER:
                draft.loading = true;
                draft.success = false;
                draft.error = false;
                draft.isDeleteSuccess = false;
                return draft;
            case DELETE_USER_SUCCESS:
                draft.loading = false;
                draft.success = true;
                draft.error = false;
                draft.isDeleteSuccess = true;
                return draft;
            case DELETE_USER_FAILED:
                draft.loading = false;
                draft.success = false;
                draft.error = action.payload.error.response.data;
                
                return draft;
            default:
                return draft;
        }
    });
export default allUsersReducer;
