import React, { useEffect } from "react";
import { Input, RadioButton, Switch } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import Tooltip from '../../../tooltip/tooltip';
import NewRule from './NewRule/index';
import Path from "./path";

const LeftFlow = (props) => {

    const [selected, setSelected] = React.useState(true);
    // const [selectedRulePath, setSelectedRulePath] = React.useState(true);
    //const [addRule, setAddRule] = React.useState(false);
    const isTrue = props.selected;
    useEffect(() => {
        
        setTimeout(() => {
            if (isTrue) {
                setSelected(false);
            }
        }, 1500);
    }, []);
    

    // const onSelectFlow = () => {
    //     props.newFlow();
    //     setSelected(true);
    // };

    // const onSelectPath = () => {
    //     props.newPath();
    //     setSelected(false);
    // };
    const onSelectRulePath = () => {
        props.addNewRulePath();
        setSelected(false);
    }
    // const onSelectDefaultRulePath = () => {
    //     props.addNewDefault();
    //     setSelected(false);
    // }
    // const addNewRule = () => {
    //     props.onClickAddNewRule();
    // }
    // const addNewDefault = () => {
    //     props.addNewDefault();
    // }

    const deleterule = (e,id) => {
        props.deleteRule(e,id);
    }

    const defaultPathClicked = (index) => {
        //console.log('index of default path4', index);
        props.defaultPathClicked(index)
    }
    //console.log('props.state.dataToSend.path.is_default',props.state.dataToSend.path.is_default);
    
    return (
        
        <div className="new-left-flow">
            {props.state.newFlows ?
                <div
                    className={props.state.onFlowClick ? "header selected new-flow-text" : "header new-flow-text"}
                    onClick={props.addNewFlow}
                >
                    <div className="text">New flow</div>
                </div> :
                <div className="default-offer-redirect header" style={{display:"grid"}}>
                        <div className="row">
                            <label>Default transition in path</label>
                            <Tooltip
                                datafor="default-redirect-mode"
                                text={
                                    "It determines how redirection to the campaign’s endpoint is handled and whether referrer data is passed through or not. Learn more"
                                }
                            ></Tooltip>
                        </div>
                        <div className="row path-offer-redirect radio-buttons" style={{display:"flex"}}>
                            <RadioButton
                                className="buttons"
                                label="302"
                                name="redirect_mode"
                                value="302"
                                checked={props.state.dataToSend.redirect_mode === "302"}
                                onChange={props.handleSwitchChange}
                            />
                            <RadioButton
                                className="buttons"
                                label="Meta Refresh"
                                name="redirect_mode"
                                value="Meta Refresh"
                                checked={props.state.dataToSend.redirect_mode === "Meta Refresh"}
                                onChange={props.handleSwitchChange}
                            />
                            <RadioButton
                                className="buttons"
                                label="Double Meta Refresh"
                                name="redirect_mode"
                                value="Double Meta Refresh"
                                checked={props.state.dataToSend.redirect_mode === "Double Meta Refresh"}
                                onChange={props.handleSwitchChange}
                            />
                            <RadioButton
                                className="buttons"
                                label="Direct"
                                name="redirect_mode"
                                value="Direct"
                                checked={props.state.dataToSend.redirect_mode === "Direct"}
                                onChange={props.handleSwitchChange}
                        />
                        <Tooltip
                                datafor="redirect-mode"
                                text={
                                    "No redirect between landers and offers"
                                }
                            ></Tooltip>
                        </div>
                    </div>
                }
            <div className="header">
                <div className="text">Rule-based paths</div>
                
                <Tooltip datafor={"rule-based"} text={"Rules are applied to paths. Use the rule-based paths when you want to target traffic to particular paths based on specific criteria and defined values. You can add up to 100 rules and 100 rule-based paths per one rule (10 000 rule-based paths in total per campaign)."}></Tooltip>
                <Button className="transparent-btn m-l-c" icon={"k-icon k-i-plus-circle"} onClick={props.addNewRule}>
                    Add rule
                </Button>
            </div>
            
            {props.state.dataToSend.path.rule?
                props.state.dataToSend.path.rule.map((key,index) => (
                    <NewRule
                        addNewPath={props.addNewPath}
                        duplicateDefaultPath={props.duplicateDefaultPath}
                        deleteDefaultPath={props.deleteDefaultPath}
                        newRulePathClicked={props.newRulePathClicked}
                        newRuleClicked={props.newRuleClicked}
                        defaultValue={key}
                        type={"rule_path"}
                        state={props.state}
                        id={key.ruleId}
                        index={index}
                        deleterule={(e,id) => deleterule(e,id)} 
                        duplicateRule={props.duplicateRule}
                        decreaseDefaultPathWeightMethod={props.decreaseDefaultPathWeightMethod}
                        increaseDefaultPathWeightMethod={props.increaseDefaultPathWeightMethod}
                        onChangePathSwitch={props.onChangePathSwitch}
                        inputChangeHandler={props.inputChangeHandler}
                        onSort={props.onSort}
                        showRuleConditionsName={props.showRuleConditionsName}
                    ></NewRule>
                ))
                :null
            }
                {
                props.addNewRuleBased ?
                    <>
                    {/* <div className="optimization-switch">
                        <div className="switch">
                            <Switch onLabel="" offLabel="" />
                            <span>Optimization disabled</span>
                        </div>
                    </div> */}
                <div className="new-path" onClick={onSelectRulePath}>
                    <div className={props.addRulePath ? "new-path-content selected" : "new-path-content "}>
                        <div className="name">
                            New path
                            <Button
                                className="copy transparent-btn"
                                icon={"k-icon k-i-copy"}
                            ></Button>
                            <Button
                                className="remove transparent-btn"
                                icon={"k-icon k-i-delete"}
                            ></Button>
                            {/* <Input className="path-input" defaultValue="100"></Input>
                            <span class="k-icon k-i-minus"></span>
                            <span class="k-icon k-i-plus"></span>
                            <div className="switch">
                                <span className="name-span">(N/A)</span>
                                <Switch onLabel="" offLabel="" />
                            </div> */}
                        </div>
                    </div>
                </div></>:null
                }
            <div className="default-path">
                <div className="header">
                    <div className="text">Default paths</div>
                    
                    <Tooltip datafor={"dafault-paths"} text={"You can define specific paths within individual campaigns. Default paths are those ones without rules applied. You can add up to 100 default paths per campaign."}></Tooltip>
                    <Button className="transparent-btn push" icon={"k-icon k-i-plus-circle"} onClick={props.addDefaultPath}>
                        Add default path
                    </Button>
                </div>
                
                {/* <div className="optimization-switch">
                    <div className="switch">
                        <Switch onLabel="" offLabel="" />
                        <span>Optimization disabled</span>
                    </div>
                </div> */}
                {
                    props.state.dataToSend.path.is_default ?
                        props.state.dataToSend.path.is_default.map((key,index) => (
                            <Path
                                state={props.state}
                                type={"default"}
                                id={key.pathId} defaultValue={key} index={index}
                                defaultPathClicked={(e) => defaultPathClicked(e)}
                                duplicateDefaultPath={props.duplicateDefaultPath}
                                deleteDefaultPath={props.deleteDefaultPath}
                                decreaseDefaultPathWeightMethod={props.decreaseDefaultPathWeightMethod}
                                increaseDefaultPathWeightMethod={props.increaseDefaultPathWeightMethod}
                                onChangePathSwitch={props.onChangePathSwitch}
                                inputChangeHandler={props.inputChangeHandler}
                            >
                            </Path>
                    )):null   
                }

            </div>
        </div>
    );
};

export default LeftFlow;
