import React, { Component } from "react";
import MenuCalendar from "../../../components/header/menuCalendar";
import {
  Button,
  DropDownButton,
  DropDownButtonItem,
} from "@progress/kendo-react-buttons";
class DashboardActions extends Component {
  state = {};
  render() {
    return (
      <div className="action-controls">
        <div className="right">
          <MenuCalendar
            dates={this.props.dates}
            showCalendar={this.props.showCalendar}
            toggleCalendarWindow={this.props.toggleCalendarWindow}
            modifiedDate={this.props.modifiedDate}
            ranges={this.props.ranges}
            applyCallback={(s, e) => {
              this.props.applyCallback(s, e);
              setTimeout(() => this.props.sendAllDataRequest(), 300);
            }}
            rangeCallback={this.props.rangeCallback}
            timeZoneChange={this.props.timeZoneChange}
            defaultTimeZone={this.props.defaultTimeZone}
            timeZonesList={this.props.timeZonesList}
          />
          <div className="refresh-btn-action">
            <Button
              onClick={this.props.sendAllDataRequest}
              className="refresh_btn"
              icon="refresh"
              dir="ltr"
            >
              Refresh &nbsp;
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardActions;
