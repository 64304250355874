import {
    ADD_CUSTOM_DOMAIN,
    ADD_CUSTOM_DOMAIN_SUCCESS,
    ADD_CUSTOM_DOMAIN_FAILED,
    LOAD_CUSTOM_DOMAINS,
    LOAD_CUSTOM_DOMAINS_SUCCESS,
    LOAD_CUSTOM_DOMAINS_FAILED,
    EDIT_CUSTOM_DOMAIN,
    EDIT_CUSTOM_DOMAIN_SUCCESS,
    EDIT_CUSTOM_DOMAIN_FAILED,
    DELETE_CUSTOM_DOMAIN,
    DELETE_CUSTOM_DOMAIN_SUCCESS,
    DELETE_CUSTOM_DOMAIN_FAILED,
    SEND_MAIN_DOMAIN,
    SEND_MAIN_DOMAIN_SUCCESS,
    SEND_MAIN_DOMAIN_FAILED,
    LOAD_REDIRECT_DOMAIN,
    LOAD_REDIRECT_DOMAIN_SUCCESS,
    LOAD_REDIRECT_DOMAIN_FAILED,
    SEND_REDIRECT_DOMAIN,
    SEND_REDIRECT_DOMAIN_SUCCESS,
    SEND_REDIRECT_DOMAIN_FAILED
} from "./constants";

export const addCustomDomain = (addDomainPayload) => {
    return {
        type: ADD_CUSTOM_DOMAIN,
        payload: addDomainPayload
    };
};

export const addCustomDomainSuccess = (addDomainSuccessPayload) => {
    return {
        type: ADD_CUSTOM_DOMAIN_SUCCESS,
        payload: addDomainSuccessPayload
    };
};

export const addCustomDomainFailure = (error) => {
    return {
        type: ADD_CUSTOM_DOMAIN_FAILED,
        payload: error
    }
};

export const loadCustomDomains = (loadCustomDomainsPayload) => {
    return {
        type: LOAD_CUSTOM_DOMAINS,
        payload: loadCustomDomainsPayload
    }
};

export const loadCustomDomainsSuccess = (loadCustomDomainsSuccessPayload) => {
    return {
        type: LOAD_CUSTOM_DOMAINS_SUCCESS,
        payload: loadCustomDomainsSuccessPayload
    }
};

export const loadCustomDomainsFailure = (error) => {
    return {
        type: LOAD_CUSTOM_DOMAINS_FAILED,
        payload: error
    }
};

export const editCustomDomain = (payload) => {
    return {
        type: EDIT_CUSTOM_DOMAIN,
        payload: payload
    }
};

export const editCustomDomainSuccess = (payload) => {
    return {
        type: EDIT_CUSTOM_DOMAIN_SUCCESS,
        payload: payload
    }
};

export const editCustomDomainFailed = (payload) => {
    return {
        type: EDIT_CUSTOM_DOMAIN_FAILED,
        payload: payload
    }
};

export const deleteCustomDomain = (payload) => {
    return {
        type: DELETE_CUSTOM_DOMAIN,
        payload: payload
    }
};

export const deleteCustomDomainSuccess = (payload) => {
    return {
        type: DELETE_CUSTOM_DOMAIN_SUCCESS,
        payload: payload
    }
};

export const deleteCustomDomainFailed = (payload) => {
    return {
        type: DELETE_CUSTOM_DOMAIN_FAILED,
        payload: payload
    }
};

export const sendMainDomain = (payload) => {
    return {
        type: SEND_MAIN_DOMAIN,
        payload: payload
    }
};

export const sendMainDomainSuccess = (payload) => {
    return {
        type: SEND_MAIN_DOMAIN_SUCCESS,
        payload: payload
    }
};

export const sendMainDomainFailed = (error) => {
    return {
        type: SEND_MAIN_DOMAIN_FAILED,
        payload: error
    }
};

export const loadRedirectDomain = (payload) => {
    return {
        type: LOAD_REDIRECT_DOMAIN,
        payload: payload
    }
};

export const loadRedirectDomainSuccess = (payload) => {
    return {
        type: LOAD_REDIRECT_DOMAIN_SUCCESS,
        payload: payload
    }
};

export const loadRedirectDomainFailed = (error) => {
    return {
        type: LOAD_REDIRECT_DOMAIN_FAILED,
        payload: error
    }
};

export const sendRedirectDomain = (payload) => {
    return {
        type: SEND_REDIRECT_DOMAIN,
        payload: payload
    }
};

export const sendRedirectDomainSuccess = (payload) => {
    return {
        type: SEND_REDIRECT_DOMAIN_SUCCESS,
        payload: payload
    }
};

export const sendRedirectDomainFailed = (error) => {
    return {
        type: SEND_REDIRECT_DOMAIN_FAILED,
        payload: error
    }
};