import React, {useState, useRef, useEffect} from "react";
import ReactTooltip from 'react-tooltip';
import { Switch } from '@progress/kendo-react-inputs';
import { Fade } from '@progress/kendo-react-animation';

const ModalForm = (props) => {
    const [affiliateTrackingURLCopySuccess, setAffiliateTrackingURLCopySuccess] = useState('');
    const affiliateTrackingURLRef = useRef(null);

    function copyToClipboard(e) {
        affiliateTrackingURLRef.current.select();
        document.execCommand('copy');
        e.target.focus();
        setAffiliateTrackingURLCopySuccess('Copied!');
    };

    // useEffect(() => {
    //     setTimeout(() => {
    //         if (props.state.extra_parameters_count > 0) {
    //             setTimeout(() => {
    //                 console.log("This is working");
    //                 for (let i=0; i<props.state.extra_parameters_count; i++) {
    //                     document.getElementById("var" + (i+1)).style.display = "table-row";
    //                 }
    //                 for (let i=props.state.extra_parameters_count; i<10; i++) {
    //                     document.getElementById("var" + (i+1)).style.display = "none";
    //                 }
    //             },300);
    //         } else {
    //             setTimeout(() => {
    //                 for (let i=props.state.extra_parameters_count; i<10; i++) {
    //                     document.getElementById("var" + (i+1)).style.display = "none";
    //                 }
    //             },300);
    //         }
    //     },400);
    // }, []);

    const addParameter = () => {
        if (props.state.extra_parameters_count < 20) {
            document.getElementById("var" + (props.state.extra_parameters_count+1)).style.display = "table-row";
        }
        props.increaseExtraParametersCount();
    }

    const hideRowAndRemoveData = () => {
        if (props.state.extra_parameters_count > 0) {
            document.getElementById("var" + (props.state.extra_parameters_count)).style.display = "none";
        }
        props.decreaseCountAndRemoveData();
    }

    return (
        <div className="form_con">
            <section>
                <h3>General</h3>
                <div className="input_group">
                    <label htmlFor="affiliate-network-name">Name</label>
                    <input
                        type="text"
                        name="name"
                        placeholder="Type affiliate network name"
                        value={props.state.dataToSend.name}
                        onChange={props.simpleInputChangeHandler}
                    />
                    {
                        props.state.isNameEmpty ?
                            <Fade enter={true} exit={true}>
                                <p className="danger_para mb-10 mh-0">You must add the traffic source name.</p>
                            </Fade>
                            : null
                    }
                </div>
                <div className="input_group five_hun">
                    <label htmlFor="affiliate-network-name">
                        Workspace
                        <span data-tip data-for='workspaces_tooltip' className="question_mark">
                            <span className="k-icon k-i-question-circle"></span>
                        </span>
                        <ReactTooltip id='workspaces_tooltip' type='dark'>
                            <span>Assign your traffic source to a particular workspace to make it accessible only by the members. The public option enables members from all workspaces to use this element in their campaigns.</span>
                        </ReactTooltip>
                    </label>
                    <select
                        className="add_custom_domain_dropdown normal_text normal_font"
                        name="workspaceId"
                        value={props.state.dataToSend.workspaceId}
                        onChange={props.simpleInputChangeHandler}
                    >
                        {
                            props.state.workspacesListReceived ?
                                props.state.workspacesListReceived.map((workspace) => (
                                    <option key={workspace.id} value={workspace.id}>{workspace.name}</option>
                                ))
                                : null
                        }
                    </select>
                </div>
            </section>
            <section>
                <h3>
                    Parameters
                    <div data-tip data-for='affiliate_networks_parameters' className="question_mark">
                        <span className="k-icon k-i-question-circle"></span>
                    </div>
                    <ReactTooltip id='affiliate_networks_parameters' type='dark'>
                        <span>Here you can define what data to pass from your traffic source to AffMark. Check your source documentation for supported parameters and tokens. You can add up to 10 parameters. We will use them to generate your tracking links for example: https://www.campaignurl.com?parameter={'{token}'}</span>
                    </ReactTooltip>
                </h3>
                <div className="inner_half_data_div five_hun ts_table_con">
                    {/*===========================================================*/}
                    {/*     Start of the table      */}
                    {/*===========================================================*/}
                    <table className="add_affiliate_popup_table ts_table">
                        <thead>
                            <tr>
                                <th className="first_col">
                                    <span className="text">Name</span>
                                    <span data-tip data-for='traffic-sources-parameters-name' className="question_mark">
                                        <span className="k-icon k-i-question-circle"></span>
                                    </span>
                                    <ReactTooltip id='traffic-sources-parameters-name' type='dark'>
                                        <span>It will be used to name your reports.</span>
                                    </ReactTooltip>
                                </th>
                                <th className="second_col">
                                    <span className="text">TS parameter</span>
                                    <span data-tip data-for='traffic_sources_ts_parameter' className="question_mark">
                                        <span className="k-icon k-i-question-circle"></span>
                                    </span>
                                    <ReactTooltip id='traffic_sources_ts_parameter' type='dark'>
                                        <span>Traffic source parameter is a static value used in your campaign URL. When the traffic source replaces your tokens, AffMark uses these parameters to be able to recognise what data the tokens contain. Exemplary parameters: keyword, price, trackingid. Copy it from your traffic source documentation.</span>
                                    </ReactTooltip>
                                </th>
                                <th>
                                    <span className="text">TS token</span>
                                    <span data-tip data-for='ts_token' className="question_mark">
                                        <span className="k-icon k-i-question-circle"></span>
                                    </span>
                                    <ReactTooltip id='ts_token' type='dark'>
                                        <span>Traffic source tokens will be dynamically replaced by your traffic source during the redirection from the ad to the destination page. Exemplary tokens: {'{keyword}'}, #price#, $$trackingid$$. Copy it from your traffic source documentation.</span>
                                    </ReactTooltip>
                                </th>
                                <th className="last_col">
                                    <span className="text">VLM token</span>
                                    <span data-tip data-for='affiliate_networks_vlm_token' className="question_mark">
                                        <span className="k-icon k-i-question-circle"></span>
                                    </span>
                                    <ReactTooltip id='affiliate_networks_vlm_token' type='dark'>
                                        <span>AffMark tokens can be used to pass traffic source data to a landing page, an offer or back to the traffic source when sending back conversion data.</span>
                                    </ReactTooltip>
                                </th>
                        </tr>
                        </thead>
                        <tbody id="ts_table_body">
                            <tr>
                                <td className="first_col">
                                    <span className="text">External ID</span>
                                    <span data-tip data-for='ts_external_id' className="question_mark">
                                        <span className="k-icon k-i-question-circle"></span>
                                    </span>
                                    <ReactTooltip id='ts_external_id' type='dark'>
                                        <span>A unique click identifier generated by your traffic source, typically used in postback URLs to pass the information about the conversion to the traffic source.</span>
                                    </ReactTooltip>
                                </td>
                                <td> <input type="text" placeholder="Type parameter" name="parameter_0" value={props.state.dataToSend.parameters[0].ts_parameter} onChange={props.parameterChangeHandler} /> </td>
                                <td> <input type="text" placeholder="Type token" name="token_0" value={props.state.dataToSend.parameters[0].ts_token} onChange={props.parameterChangeHandler} /> </td>
                                <td> <span className="text">{'{externalid}'}</span> </td>
                            </tr>
                            <tr>
                                <td className="first_col">
                                    <span className="text">Cost</span>
                                    <span data-tip data-for='affiliate_networks_payout' className="question_mark">
                                        <span className="k-icon k-i-question-circle"></span>
                                    </span>
                                    <ReactTooltip id='affiliate_networks_payout' type='dark'>
                                        <span>Add this parameter if your traffic source can automatically pass ad cost (usually CPC campaigns). If your source doesn’t support this option, you can upload campaign costs later on.</span>
                                    </ReactTooltip>
                                </td>
                                <td> <input type="text" placeholder="Type parameter" name="parameter_1" value={props.state.dataToSend.parameters[1].ts_parameter} onChange={props.parameterChangeHandler} /> </td>
                                <td> <input type="text" placeholder="Type token" name="token_1" value={props.state.dataToSend.parameters[1].ts_token} onChange={props.parameterChangeHandler}  /> </td>
                                <td> <span className="text">{'{cost}'}</span> </td>
                            </tr>
                            <tr id="var1">
                                <td className="first_col"> <input type="text" placeholder="E.g. variable 1" name="name_2" value={props.state.dataToSend.parameters[2].name} onChange={props.parameterChangeHandler} /> </td>
                                <td> <input type="text" placeholder="Type parameter" name="parameter_2" value={props.state.dataToSend.parameters[2].ts_parameter} onChange={props.parameterChangeHandler} /> </td>
                                <td> <input type="text" placeholder="Type token" name="token_2" value={props.state.dataToSend.parameters[2].ts_token} onChange={props.parameterChangeHandler} /> </td>
                                <td className="ts_table_var_col">
                                    <span className="text">{'{var1}'}</span>
                                    <Switch
                                        name="switch_var_2"
                                        onClick={props.tsVarSwitchChangeHandler}
                                        onChange={props.tsVarSwitchChangeHandler}
                                        checked={props.state.dataToSend.parameters[2].is_active}
                                    />
                                    {
                                        props.state.extra_parameters_count == 1 ?
                                            <span className="delete_variable_con" onClick={hideRowAndRemoveData}> <span className="k-icon k-i-x"></span> </span>
                                            : null
                                    }
                                </td>
                            </tr>
                            <tr id="var2">
                                <td className="first_col">
                                    <input type="text" placeholder="E.g. variable 1" name="name_3" value={props.state.dataToSend.parameters[3].name} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type parameter" name="parameter_3" value={props.state.dataToSend.parameters[3].ts_parameter} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type token" name="token_3" value={props.state.dataToSend.parameters[3].ts_token} onChange={props.parameterChangeHandler} />
                                </td>
                                <td className="ts_table_var_col">
                                    <span className="text">{'{var2}'}</span>
                                    <Switch
                                        name="switch_var_3"
                                        onClick={props.tsVarSwitchChangeHandler}
                                        onChange={props.tsVarSwitchChangeHandler}
                                        checked={props.state.dataToSend.parameters[3].is_active}
                                    />
                                    {
                                        props.state.extra_parameters_count == 2 ?
                                            <span className="delete_variable_con" onClick={hideRowAndRemoveData}> <span className="k-icon k-i-x"></span> </span>
                                            : null
                                    }
                                </td>
                            </tr>
                            <tr id="var3">
                                <td className="first_col">
                                    <input type="text" placeholder="E.g. variable 1" name="name_4" value={props.state.dataToSend.parameters[4].name} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type parameter" name="parameter_4" value={props.state.dataToSend.parameters[4].ts_parameter} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type token" name="token_4" value={props.state.dataToSend.parameters[4].ts_token} onChange={props.parameterChangeHandler} />
                                </td>
                                <td className="ts_table_var_col">
                                    <span className="text">{'{var3}'}</span>
                                    <Switch
                                        name="switch_var_4"
                                        onClick={props.tsVarSwitchChangeHandler}
                                        onChange={props.tsVarSwitchChangeHandler}
                                        checked={props.state.dataToSend.parameters[4].is_active}
                                    />
                                    {
                                        props.state.extra_parameters_count == 3 ?
                                            <span className="delete_variable_con" onClick={hideRowAndRemoveData}> <span className="k-icon k-i-x"></span> </span>
                                            : null
                                    }
                                </td>
                            </tr>
                            <tr id="var4">
                                <td className="first_col">
                                    <input type="text" placeholder="E.g. variable 1" name="name_5" value={props.state.dataToSend.parameters[5].name} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type parameter" name="parameter_5" value={props.state.dataToSend.parameters[5].ts_parameter} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type token" name="token_5" value={props.state.dataToSend.parameters[5].ts_token} onChange={props.parameterChangeHandler} />
                                </td>
                                <td className="ts_table_var_col">
                                    <span className="text">{'{var4}'}</span>
                                    <Switch
                                        name="switch_var_5"
                                        onClick={props.tsVarSwitchChangeHandler}
                                        onChange={props.tsVarSwitchChangeHandler}
                                        checked={props.state.dataToSend.parameters[5].is_active}
                                    />
                                    {
                                        props.state.extra_parameters_count == 4 ?
                                            <span className="delete_variable_con" onClick={hideRowAndRemoveData}> <span className="k-icon k-i-x"></span> </span>
                                            : null
                                    }
                                </td>
                            </tr>
                            <tr id="var5">
                                <td className="first_col">
                                    <input type="text" placeholder="E.g. variable 1" name="name_6" value={props.state.dataToSend.parameters[6].name} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type parameter" name="parameter_6" value={props.state.dataToSend.parameters[6].ts_parameter} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type token" name="token_6" value={props.state.dataToSend.parameters[6].ts_token} onChange={props.parameterChangeHandler} />
                                </td>
                                <td className="ts_table_var_col">
                                    <span className="text">{'{var5}'}</span>
                                    <Switch
                                        name="switch_var_6"
                                        onClick={props.tsVarSwitchChangeHandler}
                                        onChange={props.tsVarSwitchChangeHandler}
                                        checked={props.state.dataToSend.parameters[6].is_active}
                                    />
                                    {
                                        props.state.extra_parameters_count == 5 ?
                                            <span className="delete_variable_con" onClick={hideRowAndRemoveData}> <span className="k-icon k-i-x"></span> </span>
                                            : null
                                    }
                                </td>
                            </tr>
                            <tr id="var6">
                                <td className="first_col">
                                    <input type="text" placeholder="E.g. variable 1" name="name_7" value={props.state.dataToSend.parameters[7].name} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type parameter" name="parameter_7" value={props.state.dataToSend.parameters[7].ts_parameter} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type token" name="token_7" value={props.state.dataToSend.parameters[7].ts_token} onChange={props.parameterChangeHandler} />
                                </td>
                                <td className="ts_table_var_col">
                                    <span className="text">{'{var6}'}</span>
                                    <Switch
                                        name="switch_var_7"
                                        onClick={props.tsVarSwitchChangeHandler}
                                        onChange={props.tsVarSwitchChangeHandler}
                                        checked={props.state.dataToSend.parameters[7].is_active}
                                    />
                                    {
                                        props.state.extra_parameters_count == 6 ?
                                            <span className="delete_variable_con" onClick={hideRowAndRemoveData}> <span className="k-icon k-i-x"></span> </span>
                                            : null
                                    }
                                </td>
                            </tr>
                            <tr id="var7">
                                <td className="first_col">
                                    <input type="text" placeholder="E.g. variable 1" name="name_8" value={props.state.dataToSend.parameters[8].name} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type parameter" name="parameter_8" value={props.state.dataToSend.parameters[8].ts_parameter} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type token" name="token_8" value={props.state.dataToSend.parameters[8].ts_token} onChange={props.parameterChangeHandler} />
                                </td>
                                <td className="ts_table_var_col">
                                    <span className="text">{'{var7}'}</span>
                                    <Switch
                                        name="switch_var_8"
                                        onClick={props.tsVarSwitchChangeHandler}
                                        onChange={props.tsVarSwitchChangeHandler}
                                        checked={props.state.dataToSend.parameters[8].is_active}
                                    />
                                    {
                                        props.state.extra_parameters_count == 7 ?
                                            <span className="delete_variable_con" onClick={hideRowAndRemoveData}> <span className="k-icon k-i-x"></span> </span>
                                            : null
                                    }
                                </td>
                            </tr>
                            <tr id="var8">
                                <td className="first_col">
                                    <input type="text" placeholder="E.g. variable 1" name="name_9" value={props.state.dataToSend.parameters[9].name} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type parameter" name="parameter_9" value={props.state.dataToSend.parameters[9].ts_parameter} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type token" name="token_9" value={props.state.dataToSend.parameters[9].ts_token} onChange={props.parameterChangeHandler} />
                                </td>
                                <td className="ts_table_var_col">
                                    <span className="text">{'{var8}'}</span>
                                    <Switch
                                        name="switch_var_9"
                                        onClick={props.tsVarSwitchChangeHandler}
                                        onChange={props.tsVarSwitchChangeHandler}
                                        checked={props.state.dataToSend.parameters[9].is_active}
                                    />
                                    {
                                        props.state.extra_parameters_count == 8 ?
                                            <span className="delete_variable_con" onClick={hideRowAndRemoveData}> <span className="k-icon k-i-x"></span> </span>
                                            : null
                                    }
                                </td>
                            </tr>
                            <tr id="var9">
                                <td className="first_col">
                                    <input type="text" placeholder="E.g. variable 1" name="name_10" value={props.state.dataToSend.parameters[10].name} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type parameter" name="parameter_10" value={props.state.dataToSend.parameters[10].ts_parameter} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type token" name="token_10" value={props.state.dataToSend.parameters[10].ts_token} onChange={props.parameterChangeHandler} />
                                </td>
                                <td className="ts_table_var_col">
                                    <span className="text">{'{var9}'}</span>
                                    <Switch
                                        name="switch_var_10"
                                        onClick={props.tsVarSwitchChangeHandler}
                                        onChange={props.tsVarSwitchChangeHandler}
                                        checked={props.state.dataToSend.parameters[10].is_active}
                                    />
                                    {
                                        props.state.extra_parameters_count == 9 ?
                                            <span className="delete_variable_con" onClick={hideRowAndRemoveData}> <span className="k-icon k-i-x"></span> </span>
                                            : null
                                    }
                                </td>
                            </tr>
                            <tr id="var10">
                                <td className="first_col">
                                    <input type="text" placeholder="E.g. variable 1" name="name_11" value={props.state.dataToSend.parameters[11].name} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type parameter" name="parameter_11" value={props.state.dataToSend.parameters[11].ts_parameter} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type token" name="token_11" value={props.state.dataToSend.parameters[11].ts_token} onChange={props.parameterChangeHandler} />
                                </td>
                                <td className="ts_table_var_col">
                                    <span className="text">{'{var10}'}</span>
                                    <Switch
                                        name="switch_var_11"
                                        onClick={props.tsVarSwitchChangeHandler}
                                        onChange={props.tsVarSwitchChangeHandler}
                                        checked={props.state.dataToSend.parameters[11].is_active}
                                    />
                                    {
                                        props.state.extra_parameters_count == 10 ?
                                            <span className="delete_variable_con" onClick={hideRowAndRemoveData}> <span className="k-icon k-i-x"></span> </span>
                                            : null
                                    }
                                </td>
                            </tr>
                            <tr id="var11">
                                <td className="first_col">
                                    <input type="text" placeholder="E.g. variable 1" name="name_12" value={props.state.dataToSend.parameters[12].name} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type parameter" name="parameter_12" value={props.state.dataToSend.parameters[12].ts_parameter} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type token" name="token_12" value={props.state.dataToSend.parameters[12].ts_token} onChange={props.parameterChangeHandler} />
                                </td>
                                <td className="ts_table_var_col">
                                    <span className="text">{'{var11}'}</span>
                                    <Switch
                                        name="switch_var_12"
                                        onClick={props.tsVarSwitchChangeHandler}
                                        onChange={props.tsVarSwitchChangeHandler}
                                        checked={props.state.dataToSend.parameters[12].is_active}
                                    />
                                    {
                                        props.state.extra_parameters_count == 11 ?
                                            <span className="delete_variable_con" onClick={hideRowAndRemoveData}> <span className="k-icon k-i-x"></span> </span>
                                            : null
                                    }
                                </td>
                            </tr>
                            <tr id="var12">
                                <td className="first_col">
                                    <input type="text" placeholder="E.g. variable 1" name="name_13" value={props.state.dataToSend.parameters[13].name} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type parameter" name="parameter_13" value={props.state.dataToSend.parameters[13].ts_parameter} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type token" name="token_13" value={props.state.dataToSend.parameters[13].ts_token} onChange={props.parameterChangeHandler} />
                                </td>
                                <td className="ts_table_var_col">
                                    <span className="text">{'{var12}'}</span>
                                    <Switch
                                        name="switch_var_13"
                                        onClick={props.tsVarSwitchChangeHandler}
                                        onChange={props.tsVarSwitchChangeHandler}
                                        checked={props.state.dataToSend.parameters[13].is_active}
                                    />
                                    {
                                        props.state.extra_parameters_count == 12 ?
                                            <span className="delete_variable_con" onClick={hideRowAndRemoveData}> <span className="k-icon k-i-x"></span> </span>
                                            : null
                                    }
                                </td>
                            </tr>
                            <tr id="var13">
                                <td className="first_col">
                                    <input type="text" placeholder="E.g. variable 1" name="name_14" value={props.state.dataToSend.parameters[14].name} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type parameter" name="parameter_14" value={props.state.dataToSend.parameters[14].ts_parameter} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type token" name="token_14" value={props.state.dataToSend.parameters[14].ts_token} onChange={props.parameterChangeHandler} />
                                </td>
                                <td className="ts_table_var_col">
                                    <span className="text">{'{var13}'}</span>
                                    <Switch
                                        name="switch_var_14"
                                        onClick={props.tsVarSwitchChangeHandler}
                                        onChange={props.tsVarSwitchChangeHandler}
                                        checked={props.state.dataToSend.parameters[14].is_active}
                                    />
                                    {
                                        props.state.extra_parameters_count == 13 ?
                                            <span className="delete_variable_con" onClick={hideRowAndRemoveData}> <span className="k-icon k-i-x"></span> </span>
                                            : null
                                    }
                                </td>
                            </tr>
                            <tr id="var14">
                                <td className="first_col">
                                    <input type="text" placeholder="E.g. variable 1" name="name_15" value={props.state.dataToSend.parameters[15].name} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type parameter" name="parameter_15" value={props.state.dataToSend.parameters[15].ts_parameter} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type token" name="token_15" value={props.state.dataToSend.parameters[15].ts_token} onChange={props.parameterChangeHandler} />
                                </td>
                                <td className="ts_table_var_col">
                                    <span className="text">{'{var14}'}</span>
                                    <Switch
                                        name="switch_var_15"
                                        onClick={props.tsVarSwitchChangeHandler}
                                        onChange={props.tsVarSwitchChangeHandler}
                                        checked={props.state.dataToSend.parameters[15].is_active}
                                    />
                                    {
                                        props.state.extra_parameters_count == 14 ?
                                            <span className="delete_variable_con" onClick={hideRowAndRemoveData}> <span className="k-icon k-i-x"></span> </span>
                                            : null
                                    }
                                </td>
                            </tr>
                            <tr id="var15">
                                <td className="first_col">
                                    <input type="text" placeholder="E.g. variable 1" name="name_16" value={props.state.dataToSend.parameters[16].name} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type parameter" name="parameter_16" value={props.state.dataToSend.parameters[16].ts_parameter} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type token" name="token_16" value={props.state.dataToSend.parameters[16].ts_token} onChange={props.parameterChangeHandler} />
                                </td>
                                <td className="ts_table_var_col">
                                    <span className="text">{'{var15}'}</span>
                                    <Switch
                                        name="switch_var_16"
                                        onClick={props.tsVarSwitchChangeHandler}
                                        onChange={props.tsVarSwitchChangeHandler}
                                        checked={props.state.dataToSend.parameters[16].is_active}
                                    />
                                    {
                                        props.state.extra_parameters_count == 15 ?
                                            <span className="delete_variable_con" onClick={hideRowAndRemoveData}> <span className="k-icon k-i-x"></span> </span>
                                            : null
                                    }
                                </td>
                            </tr>
                            <tr id="var16">
                                <td className="first_col">
                                    <input type="text" placeholder="E.g. variable 1" name="name_17" value={props.state.dataToSend.parameters[17].name} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type parameter" name="parameter_17" value={props.state.dataToSend.parameters[17].ts_parameter} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type token" name="token_17" value={props.state.dataToSend.parameters[17].ts_token} onChange={props.parameterChangeHandler} />
                                </td>
                                <td className="ts_table_var_col">
                                    <span className="text">{'{var16}'}</span>
                                    <Switch
                                        name="switch_var_17"
                                        onClick={props.tsVarSwitchChangeHandler}
                                        onChange={props.tsVarSwitchChangeHandler}
                                        checked={props.state.dataToSend.parameters[17].is_active}
                                    />
                                    {
                                        props.state.extra_parameters_count == 16 ?
                                            <span className="delete_variable_con" onClick={hideRowAndRemoveData}> <span className="k-icon k-i-x"></span> </span>
                                            : null
                                    }
                                </td>
                            </tr>
                            <tr id="var17">
                                <td className="first_col">
                                    <input type="text" placeholder="E.g. variable 1" name="name_18" value={props.state.dataToSend.parameters[18].name} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type parameter" name="parameter_18" value={props.state.dataToSend.parameters[18].ts_parameter} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type token" name="token_18" value={props.state.dataToSend.parameters[18].ts_token} onChange={props.parameterChangeHandler} />
                                </td>
                                <td className="ts_table_var_col">
                                    <span className="text">{'{var17}'}</span>
                                    <Switch
                                        name="switch_var_18"
                                        onClick={props.tsVarSwitchChangeHandler}
                                        onChange={props.tsVarSwitchChangeHandler}
                                        checked={props.state.dataToSend.parameters[18].is_active}
                                    />
                                    {
                                        props.state.extra_parameters_count == 17 ?
                                            <span className="delete_variable_con" onClick={hideRowAndRemoveData}> <span className="k-icon k-i-x"></span> </span>
                                            : null
                                    }
                                </td>
                            </tr>
                            <tr id="var18">
                                <td className="first_col">
                                    <input type="text" placeholder="E.g. variable 1" name="name_19" value={props.state.dataToSend.parameters[19].name} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type parameter" name="parameter_19" value={props.state.dataToSend.parameters[19].ts_parameter} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type token" name="token_19" value={props.state.dataToSend.parameters[19].ts_token} onChange={props.parameterChangeHandler} />
                                </td>
                                <td className="ts_table_var_col">
                                    <span className="text">{'{var18}'}</span>
                                    <Switch
                                        name="switch_var_19"
                                        onClick={props.tsVarSwitchChangeHandler}
                                        onChange={props.tsVarSwitchChangeHandler}
                                        checked={props.state.dataToSend.parameters[19].is_active}
                                    />
                                    {
                                        props.state.extra_parameters_count == 18 ?
                                            <span className="delete_variable_con" onClick={hideRowAndRemoveData}> <span className="k-icon k-i-x"></span> </span>
                                            : null
                                    }
                                </td>
                            </tr>
                            <tr id="var19">
                                <td className="first_col">
                                    <input type="text" placeholder="E.g. variable 1" name="name_20" value={props.state.dataToSend.parameters[20].name} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type parameter" name="parameter_20" value={props.state.dataToSend.parameters[20].ts_parameter} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type token" name="token_20" value={props.state.dataToSend.parameters[20].ts_token} onChange={props.parameterChangeHandler} />
                                </td>
                                <td className="ts_table_var_col">
                                    <span className="text">{'{var19}'}</span>
                                    <Switch
                                        name="switch_var_20"
                                        onClick={props.tsVarSwitchChangeHandler}
                                        onChange={props.tsVarSwitchChangeHandler}
                                        checked={props.state.dataToSend.parameters[20].is_active}
                                    />
                                    {
                                        props.state.extra_parameters_count == 19 ?
                                            <span className="delete_variable_con" onClick={hideRowAndRemoveData}> <span className="k-icon k-i-x"></span> </span>
                                            : null
                                    }
                                </td>
                            </tr>
                            <tr id="var20">
                                <td className="first_col">
                                    <input type="text" placeholder="E.g. variable 1" name="name_21" value={props.state.dataToSend.parameters[21].name} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type parameter" name="parameter_21" value={props.state.dataToSend.parameters[21].ts_parameter} onChange={props.parameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type token" name="token_21" value={props.state.dataToSend.parameters[21].ts_token} onChange={props.parameterChangeHandler} />
                                </td>
                                <td className="ts_table_var_col">
                                    <span className="text">{'{var20}'}</span>
                                    <Switch
                                        name="switch_var_21"
                                        onClick={props.tsVarSwitchChangeHandler}
                                        onChange={props.tsVarSwitchChangeHandler}
                                        checked={props.state.dataToSend.parameters[21].is_active}
                                    />
                                    {
                                        props.state.extra_parameters_count == 20 ?
                                            <span className="delete_variable_con" onClick={hideRowAndRemoveData}> <span className="k-icon k-i-x"></span> </span>
                                            : null
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {/*===========================================================*/}
                    {/*     End of the table    */}
                    {/*===========================================================*/}
                    {
                        props.state.extra_parameters_count < 10 ?
                            <div className="ts_add_parameter">
                                <p className="normal_text theme_blue_color" onClick={addParameter} >
                                    <span className="question_mark">
                                        <span className="k-icon k-i-plus-circle"></span>
                                    </span>
                                    Add parameter
                                </p>
                            </div>
                            : null
                    }
                </div>
            </section>
            <section className="five_hun">
                <div className="currency_group input_group_inline_small">
                    <label htmlFor="affiliate-network-currency">
                        Cost currency
                        <span data-tip data-for='cost_currency_tooltip' className="question_mark">
                            <span className="k-icon k-i-question-circle"></span>
                        </span>
                        <ReactTooltip id='cost_currency_tooltip' type='dark'>
                            <span>Select a currency in which you buy your ads. It's important when the currency you use to buy traffic and the currency you get paid in are different.</span>
                        </ReactTooltip>
                    </label>
                    <select
                        className="add_custom_domain_dropdown normal_text normal_font"
                        name="currencyId"
                        value={props.state.dataToSend.currencyId}
                        onChange={props.simpleInputChangeHandler}
                    >
                        {
                            props.state.currenciesListReceived ?
                            props.state.currenciesListReceived.map((currency) => (
                                <option key={currency.id} value={currency.id}>{currency.name}</option>
                            ))
                                : null
                        }
                    </select>
                </div>
            </section>
            <section>
                <h3>
                    Passing conversion info to traffic source
                    <span data-tip data-for='ts_pass_conversion' className="question_mark">
                        <span className="k-icon k-i-question-circle"></span>
                    </span>
                    <ReactTooltip id='ts_pass_conversion' type='dark'>
                        <span>Use this option if you want to inform your traffic source about conversions recorded in Voluum. For CPA traffic sources it may be a mandatory option.</span>
                    </ReactTooltip>
                </h3>
                <div className="additional_settings_con">
                    <div className="switches_row ts_switches_row">
                        <Switch
                            name="postback_url_check"
                            onClick={props.switchChangeHandler}
                            onChange={props.switchChangeHandler}
                            checked={props.state.postback_url_check}
                        />
                        Traffic source postback URL
                        <span data-tip data-for='ts_postback_tooltip' className="question_mark">
                            <span className="k-icon k-i-question-circle"></span>
                        </span>
                        <ReactTooltip id='ts_postback_tooltip' type='dark'>
                            <span>The most bullet-proof (server-to-server) way to deliver information about conversion to the traffic source. A traffic source will be able to recognize which user, from those who initially clicked on the ad, converted if this URL contains a unique click ID generated by the source.</span>
                        </ReactTooltip>
                    </div>
                    <div className="note normal_font normal_text ts_note">
                    <span className="question_mark">
                        <span className="k-icon k-i-question-circle icon_color_theme_default"></span>
                    </span>
                        <div className="note_p black">If a unique click ID is required by your source, make sure that the below URL includes {'{externalid}'} token.
                        <br />
                        Please make sure you add a valid url, it must start with http:// or https:// and token parameters must be after the domain followed by a "?"
                            <br />
                            Here are a couple of valid examples, <span className="theme_blue_color">http://domain.com?aid=REPLACE&tid=REPLACE&visitor_id={'{externalid}'}</span> or <span className="theme_blue_color">https://subdomain.domain-name.com/page.php?cid={'{externalid}'}</span>
                        </div>
                    </div>
                    <input className="ts_postback_input" type="text" placeholder="E.g. https://www.example.com" name="postback_url" onChange={props.postbackUrlChangeHandler} value={props.state.dataToSend.postback_url} />
                    {
                        !props.state.is_protocol_valid || !props.state.is_domain_valid ?
                            <Fade enter={true} exit={true}>
                                <p className="danger_para mb-10 mh-0" style={{margin: "0 0 10px", padding: "10px 15px"}}>
                                    {
                                        !props.state.is_protocol_valid ?
                                            "- Please make sure the url starts with http:// or https:// "
                                            : null
                                    }
                                    &nbsp;&nbsp;
                                    {
                                        !props.state.is_domain_valid ?
                                            " - Please make sure you enter a valid domain name "
                                            : null
                                    }
                                </p>
                            </Fade>
                            : null
                    }
                    <div className="ts_postback_tokens_container">
                        {
                            props.tagsListReceived ?
                                props.tagsListReceived.map(tag => (
                                    <span id={tag.code} className="token" onClick={props.addTokenToUrl}>{tag.code}</span>
                                ))
                                : null
                        }
                    </div>
                    <div className="switches_row ts_switches_row flex_column">
                        <Switch
                            name="pixel_redirect_url"
                            onClick={props.switchChangeHandler}
                            onChange={props.switchChangeHandler}
                            checked={props.state.pixel_redirect_url}
                        />
                        Pixel redirect URL
                        <span data-tip data-for='ts_pixel_redirect_tooltip' className="question_mark">
                            <span className="k-icon k-i-question-circle"></span>
                        </span>
                        <ReactTooltip id='ts_pixel_redirect_tooltip' type='dark'>
                            <span>Do not add VLM tokens in the pixel redirect URL. This option works only while using conversion tracking pixel to register conversions in AffMark.</span>
                        </ReactTooltip>
                    </div>
                    {
                        props.state.pixel_redirect_url ?
                            <div classname="block">
                                <input name="pixel_redirect_url" className="ts_postback_input" type="text" placeholder="E.g. https://www.example.com" value={props.state.dataToSend.pixel_redirect_url} onChange={props.simpleInputChangeHandler} />
                            </div>
                            : null
                    }
                </div>
            </section>
            <section>
                <div className="empty_space_10"></div>
                <h3 className="mb-10">More tracking options</h3>
                <div className="additional_settings_con">
                    <div className="switches_row ts_switches_row">
                        <Switch
                            name="impression_tracking"
                            onClick={props.switchChangeHandler}
                            onChange={props.switchChangeHandler}
                            checked={props.state.dataToSend.impression_tracking}
                        />
                        Impression tracking
                        <span data-tip data-for='ts_impression_tracking_tooltip' className="question_mark">
                            <span className="k-icon k-i-question-circle"></span>
                        </span>
                        <ReactTooltip id='ts_impression_tracking_tooltip' type='dark'>
                            <span>Use it only if your traffic source allows you to track ad impressions in 3rd party tools. If enabled, AffMark will generate an additional tracking link once you create a campaign with this traffic source.</span>
                        </ReactTooltip>
                    </div>
                    <div className="switches_row ts_switches_row">
                        <Switch
                            name="direct_tracking"
                            onClick={props.switchChangeHandler}
                            onChange={props.switchChangeHandler}
                            checked={props.state.dataToSend.direct_tracking}
                        />
                        Direct tracking
                        <span data-tip data-for='ts_impression_tracking_tooltip' className="question_mark">
                            <span className="k-icon k-i-question-circle"></span>
                        </span>
                        <ReactTooltip id='ts_impression_tracking_tooltip' type='dark'>
                            <span>Use it only if your traffic source allows you to track ad impressions in 3rd party tools. If enabled, AffMark will generate an additional tracking link once you create a campaign with this traffic source.</span>
                        </ReactTooltip>
                    </div>
                </div>
                {
                    props.state.resolveErrors !== "" ?
                        <Fade enter={true} exit={true}>
                            <p className="danger_para mb-10 mh-0">{props.state.resolveErrors}</p>
                        </Fade>
                        : null
                }
            </section>
        </div>
    );
};

export default ModalForm;