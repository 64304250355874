import {
    LOAD_OFFER_TRACKING_URLS,
    LOAD_OFFER_TRACKING_URLS_SUCCESS,
    LOAD_OFFER_TRACKING_URLS_FAILED,

    LOAD_OFFER_CURRENCY,
    LOAD_OFFER_CURRENCY_SUCCESS,
    LOAD_OFFER_CURRENCY_FAILED,

    POST_NEW_OFFER_DATA,
    POST_NEW_OFFER_DATA_SUCCESS,
    POST_NEW_OFFER_DATA_FAILED,

    LOAD_OFFERS_DATA,
    LOAD_OFFERS_DATA_SUCCESS,
    LOAD_OFFERS_DATA_FAILED,

    EDIT_OFFER_DATA,
    EDIT_OFFER_DATA_SUCCESS,
    EDIT_OFFER_DATA_FAILED,

    EDIT_OFFER_DATA_SENT,
    EDIT_OFFER_DATA_SENT_SUCCESS,
    EDIT_OFFER_DATA_SENT_FAILED,

    DELETE_OFFER_DATA,
    DELETE_OFFER_DATA_SUCCESS,
    DELETE_OFFER_DATA_FAILED,

    ARCHIVE_OFFER_DATA,
    ARCHIVE_OFFER_DATA_SUCCESS,
    ARCHIVE_OFFER_DATA_FAILED,

    LOAD_TIMEZONE,
    LOAD_TIMEZONE_SUCCESS,
    LOAD_TIMEZONE_FAILED,

    GET_OFFERS_CHARTS_DATA,
    GET_OFFERS_CHARTS_DATA_SUCCESS,
    GET_OFFERS_CHARTS_DATA_FAILED
} from "./constants";

export const loadTrackingURLS = (loadTrackingURLSPayload) => {
    return {
        type: LOAD_OFFER_TRACKING_URLS,
        payload: loadTrackingURLSPayload
    };
};

export const loadTrackingURLSSuccess = (loadTrackingURLSSuccessPayload) => {
    return {
        type: LOAD_OFFER_TRACKING_URLS_SUCCESS,
        payload: loadTrackingURLSSuccessPayload
    };
};

export const loadTrackingURLSFailed = (error) => {
    return {
        type: LOAD_OFFER_TRACKING_URLS_FAILED,
        payload: error
    };
};


// Loading Currency Actions
export const loadCurrency = (loadCurrencyPayload) => {
    return {
        type: LOAD_OFFER_CURRENCY,
        payload: loadCurrencyPayload
    };
};

export const loadCurrencySuccess = (loadCurrencySuccessPayload) => {
    return {
        type: LOAD_OFFER_CURRENCY_SUCCESS,
        payload: loadCurrencySuccessPayload
    };
};

export const loadCurrencyFailed = (error) => {
    return {
        type: LOAD_OFFER_CURRENCY_FAILED,
        payload: error
    };
};


// Sending New Affiliate Network data Actions
export const postNewOffer = (postNewOfferPayload) => {
    return {
        type: POST_NEW_OFFER_DATA,
        payload: postNewOfferPayload
    };
};

export const postNewOfferSuccess = (postNewOfferSuccessPayload) => {
    return {
        type: POST_NEW_OFFER_DATA_SUCCESS,
        payload: postNewOfferSuccessPayload
    };
};

export const postNewOfferFailed = (error) => {
    return {
        type: POST_NEW_OFFER_DATA_FAILED,
        payload: error
    };
};


// Load Affiliate Network data Actions
export const loadOffers = (loadOffersPayload,searchData,sorting, primaryPage, dataType) => {
    return {
        type: LOAD_OFFERS_DATA,
        payload: {loadOffersPayload,searchData,sorting, primaryPage, dataType}
    };
};

export const loadOffersSuccess = (loadOffersSuccessPayload) => {
    return {
        type: LOAD_OFFERS_DATA_SUCCESS,
        payload: loadOffersSuccessPayload
    };
};

export const loadOffersFailed = (error) => {
    return {
        type: LOAD_OFFERS_DATA_FAILED,
        payload: error
    };
};

// EDIT Affiliate Network data Actions
export const editOffer = (editOfferPayload) => {
    return {
        type: EDIT_OFFER_DATA,
        payload: editOfferPayload
    };
};

export const editOfferSuccess = (editOfferSuccessPayload) => {
    return {
        type: EDIT_OFFER_DATA_SUCCESS,
        payload: editOfferSuccessPayload
    };
};

export const editOfferFailed = (error) => {
    return {
        type: EDIT_OFFER_DATA_FAILED,
        payload: error
    };
};


// Send EDIT Affiliate Network data Actions
export const sendEditOffer = (sendEditOfferPayload) => {
    return {
        type: EDIT_OFFER_DATA_SENT,
        payload: sendEditOfferPayload
    };
};

export const sendEditOfferSuccess = (sendEditOfferSuccessPayload) => {
    return {
        type: EDIT_OFFER_DATA_SENT_SUCCESS,
        payload: sendEditOfferSuccessPayload
    };
};

export const sendEditOfferFailed = (error) => {
    return {
        type: EDIT_OFFER_DATA_SENT_FAILED,
        payload: error
    };
};



export const deleteOffer = (deleteOfferPayload) => {
    return {
        type: DELETE_OFFER_DATA,
        payload: deleteOfferPayload
    };
};

export const deleteOfferSuccess = (deleteOfferSuccessPayload) => {
    return {
        type: DELETE_OFFER_DATA_SUCCESS,
        payload: deleteOfferSuccessPayload
    };
};

export const deleteOfferFailed = (error) => {
    return {
        type: DELETE_OFFER_DATA_FAILED,
        payload: error
    };
};

export const archiveOffer = (archiveOfferPayload) => {
    // console.log('archiveOfferPayload',archiveOfferPayload);
    return {
        type: ARCHIVE_OFFER_DATA,
        payload: archiveOfferPayload
    };
};

export const archiveOfferSuccess = (archiveOfferSuccessPayload) => {
    return {
        type: ARCHIVE_OFFER_DATA_SUCCESS,
        payload: archiveOfferSuccessPayload
    };
};

export const archiveOfferFailed = (error) => {
    return {
        type: ARCHIVE_OFFER_DATA_FAILED,
        payload: error
    };
};

export const loadTimezone = (loadTimezonePayload) => {
    return {
        type: LOAD_TIMEZONE,
        payload: loadTimezonePayload
    };
};

export const loadTimezoneSuccess = (loadTimezoneSuccessPayload) => {
    return {
        type: LOAD_TIMEZONE_SUCCESS,
        payload: loadTimezoneSuccessPayload
    };
};

export const loadTimezoneFailed = (error) => {
    return {
        type: LOAD_TIMEZONE_FAILED,
        payload: error
    };
};

export const loadOffersChartsData = (payload) => {
    return {
        type: GET_OFFERS_CHARTS_DATA,
        payload: payload
    };
};

export const loadOffersChartsDataSuccess = (payload) => {
    return {
        type: GET_OFFERS_CHARTS_DATA_SUCCESS,
        payload: payload
    };
};

export const loadOffersChartsDataFailed = (error) => {
    return {
        type: GET_OFFERS_CHARTS_DATA_FAILED,
        payload: error
    };
};
