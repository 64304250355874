import {
    LOAD_EVENTLOG, LOAD_EVENTLOG_SUCCESS, LOAD_EVENTLOG_FAILED,
    LOAD_EVENTLOG_DETAILS_FAILED,LOAD_EVENTLOG_DETAILS,LOAD_EVENTLOG_DETAILS_SUCCESS
} from './constants';


export const loadEventLog = (accessToken,data) => {
    return {
        type:LOAD_EVENTLOG,
        payload:{accessToken,data}
    }
}

export const loadEventLogSuccess = (data) => {
    return {
        type:LOAD_EVENTLOG_SUCCESS,
        payload:{data}
    }
}

export const loadEventLogFailed = (error) => {
    return {
        type:LOAD_EVENTLOG_FAILED,
        payload:{error}
    }
}


export const loadEventLogDetail = (accessToken) => {
    return {
        type:LOAD_EVENTLOG_DETAILS,
        payload:{accessToken}
    }
}

export const loadEventLogDetailSuccess = (data) => {
    return {
        type:LOAD_EVENTLOG_DETAILS_SUCCESS,
        payload:{data}
    }
}

export const loadEventLogDetailFailed = (error) => {
    return {
        type:LOAD_EVENTLOG_DETAILS_FAILED,
        payload:{error}
    }
}