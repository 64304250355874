import * as React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import ModalForm from "./modalForm";
import "./conditionModal.css";
import { Button } from "@progress/kendo-react-buttons";
import produce from 'immer';
import { filterBy } from "@progress/kendo-data-query";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
    makeSelectBrandData, makeSelectBrowserData, makeSelectCityData, makeSelectConnectionTypeData,
    makeSelectDeviceData, makeSelectLanguageData, makeSelectOperatingSystemData, makeSelectProxyData,
    makeSelectRegionData, makeSelectISPData, makeSelectConditionCountryData, makeSelectWeekDayData, makeSelectMCData
} from ".././selectors";
import { makeSelectToken } from "../../../.././registration/LoginRegister/selectors";
import * as newFlowsActions from '.././actions';
import { makeSelectLoadTimeZoneData } from "../../Offers/selectors";

const changeState = {
    dataToSend: {
        from:"",
        to:"",
        timeZoneId:"",     
    },
    models: [],
    modelsData:[],
    data: [],
    modelPushed: false,
    versionPushed: false,
    tagsData: [],
    tagsPushed: false,
    value: '',
    modelValue: '',
    selectChange: false,
    cityValue: [],
    connectionType: false,
    deviceType: false,
    region: false,
    language: false,
    proxy: false,
    os: false,
    predicate: true,
    timezone:false,
    contains:false,
    iRSelectedValue:"MUST_BE",
    errors: {
        tagsError: "",
        multiSelectError:"",
        ipAddressError:"",
        referrerDomainError:"",
        customVarError:"",
        timezoneError:{
            from:"",
            to:"",
            timezone:""
        }
    },
    timezonedata: null,
    defaultTime: null,
    fromh:"",
    fromm:"",
    toh:"",
    tom:"",
    domain:"",
    customVar:"",
    is_empty:false,
}

class CondtionModal extends React.Component {
    state = {
        dataToSend: {
            from:"",
            to:"",
            timeZoneId:"",
        },
        visible: this.props.visible,
        update: false,
        select: "",
        models: [],
        modelsData:[],
        data: [],
        modelPushed: false ,
        versionPushed: false,
        tagsData: [],
        tagsPushed: false,
        value: '',
        modelValue: '',
        selectChange: false,
        selectCityChange: false,
        cityValue: [],
        connectionType: false,
        deviceType: false,
        region: false,
        language: false,
        proxy: false,
        os: false,
        weekday: false,
        mc: false,
        predicate: true,
        timezone:false,
        contains:false,
        iRSelectedValue:"MUST_BE",
        ip:"",
        errors: {
            tagsError: "",
            multiSelectError:"",
            ipAddressError:"",
            referrerDomainError:"",
            customVarError:"",
            timezoneError:{
                from:"",
                to:"",
                timezone:""
            }
        },
        timezonedata: null,
        defaultTime: null,
        fromh:"",
        fromm:"",
        toh:"",
        tom:"",
        domain:"",
        customVar:"",
        is_empty:false,

    };

    toggleDialog = () => {
        this.setState({
            visible: !this.state.visible,
        });
        this.props.onVisibleClick(!this.state.visible);
    };

    componentDidMount() {
        
        this.setState(produce(draft => { draft.select= ""  }));
        // if(this.props.timezoneDataReceived!==null){
        //     this.setState(produce(draft => {draft.timezonedata = this.props.timezoneDataReceived.slice()}))
        // }   
    }

    componentDidUpdate(prevProps,prevState) {
        if (this.state.select === "connection" && this.state.connectionType===false) {
            setTimeout(() => {
                this.setState(produce(draft=>{draft.data = this.props.connectionTypeDataReceived.slice()}))
            }, 100);
            this.setState(produce(draft => { draft.connectionType= true  }));
        }

        if (this.state.select === "device" && this.state.deviceType === false) {
            setTimeout(() => {
                this.setState(produce(draft=>{draft.data = this.props.deviceTypeDataReceived.slice()}))
            }, 100);
            this.setState(produce(draft => { draft.deviceType= true  }));
        }

        if (this.state.select === "language" && this.state.language===false) {
            setTimeout(() => {
                this.setState(produce(draft=>{draft.data = this.props.languageDataReceived.slice()}))
            }, 100);
            this.setState(produce(draft => { draft.language= true  }));
        }

        if (this.state.select === "region" && this.state.region===false) {
            setTimeout(() => {
                this.setState(produce(draft=>{draft.data = this.props.regionDataReceived.slice()}))
            }, 100);
            this.setState(produce(draft => { draft.region= true  }));
        }

        if (this.state.select === "proxy" && this.state.proxy===false) {
            setTimeout(() => {
                this.setState(produce(draft=>{draft.data = this.props.proxyDataReceived.slice()}))
            }, 100);
            this.setState(produce(draft => { draft.proxy= true  }));
        }

        if (this.state.select === "operating" && this.state.os===false) {
            setTimeout(() => {
                this.setState(produce(draft=>{draft.data = this.props.osDataReceived.slice()}))
            }, 100);
            this.setState(produce(draft => { draft.os= true  }));
        }

        if (this.state.select === "weekday" && this.state.weekday===false) {
            setTimeout(() => {
                this.setState(produce(draft=>{draft.data = this.props.weekdayDataReceived.slice()}))
            }, 100);
            this.setState(produce(draft => { draft.weekday= true  }));
        }

        if (this.state.select === "timezone" && this.state.timezone===false) {
            setTimeout(() => {
                this.setState(produce(draft=>{draft.data = this.props.timezoneDataReceived.slice()}))
            }, 100);
            this.setState(produce(draft => { draft.timezone = true}));
        }
        
        // this.setState(produce(draft=>{draft.data = this.props.connectionTypeDataReceived.slice()}))
        if (this.state.modelPushed === true) {
            //console.log('true');
            this.pushModels();
            this.setState(produce(draft => { draft.modelPushed= false  }));
            //console.log('this.state.',this.state);
        }
        
        if (this.state.versionPushed === true) {
            if (this.state.select === "browser") {
                this.pushVersions(this.props.browserDataReceived);
                this.setState(produce(draft => {draft.versionPushed = false}));
            }
            if (this.state.select === "operating") {
                this.pushVersions(this.props.osDataReceived);
                this.setState(produce(draft => {draft.versionPushed = false}));
            }
        }
        

        if (this.state.selectChange === true) {
            this.resetState();
            this.setState(produce(draft => { draft.selectChange= false  }));
        }
        //console.log('this.state condition modal',this.state);
        // this.props.sendState(this.state, true);
        //this.props.addConditions(this.state);
        // console.log('this.state.data',this.state.data,this.props.conditionCountryDataReceived);
    }

    resetState = () => {
        this.setState(changeState);
    }


    onChange = (event) => {
        const value = event.target.value;
        this.setState({
            value: value
        });
    }
    onIpAdressChange=(event)=>{
        this.setState(produce(draft=>{draft.ip=event.target.value}));
    }

    onModelChange = (event) => {
        const value = event.target.value;
        this.setState({
            modelValue: value
        });
    }
    
    onChangeSelect = (e) => {
        this.setState(produce(draft => { draft.select = e.target.value }));
        this.setState(produce(draft => { draft.selectChange = true }));
        this.setState(produce(draft => { draft.errors.tagsError = "" }));
        this.setState(produce(draft => {draft.errors.multiSelectError=""}))
        
    };
    

    pushModels = () => {
        this.setState(produce(draft => { draft.modelsData.length = 0 }));
        for (let i = 0; i < this.state.models.length; i++){
            for (let j = 0; j < this.props.brandDataReceived.length; j++){
                if (this.state.models[i] === this.props.brandDataReceived[j].name) {
                    for (let k = 0; k < this.props.brandDataReceived[j].models.length; k++){
                        this.setState(produce(draft => { draft.modelsData.push(this.props.brandDataReceived[j].models[k])  }));                
                    }
                }
            }
        }
    }

    pushVersions = (data) => {
        this.setState(produce(draft => { draft.modelsData.length = 0 }));
        for (let i = 0; i < this.state.models.length; i++){
            for (let j = 0; j < data.length; j++){
                if (this.state.models[i] === data[j].name) {
                    for (let k = 0; k < data[j].version.length; k++){
                        this.setState(produce(draft => { draft.modelsData.push(data[j].version[k])  }));                
                    }
                }
            }
        }
    }

    onClickBoxTagsList = (event) => {
        //console.log('models name ', event.target.element.name);
        
        
        if (this.state.tagsData.includes(event.target.element.name)) {
            let arr = this.state.tagsData.filter(e => e !== event.target.element.name); 
                this.setState(produce(draft => { draft.tagsData = arr }));
        }
        else {
            this.setState(produce(draft => { draft.tagsData.push(event.target.element.name) }));    
        }
        setTimeout(() => {
            if (this.state.models.length === 0) {
                this.setState(produce(draft => { draft.tagsData = []}));    
            }
        }, 50);
    }

    onClickBox = (event) => {
        //console.log('this.state.models',this.state);
        if (this.state.select === "brand") {
            if (this.state.models.includes(event.target.element.name)) {
                let arr = this.state.models.filter(e => e !== event.target.element.name); 
                this.setState(produce(draft => { draft.models = arr }));
                this.setState(produce(draft => { draft.modelPushed = true }));
                this.setState(produce(draft => { draft.versionPushed = false }));
            }
            else {
                this.setState(produce(draft => { draft.models.push(event.target.element.name) }));
                this.setState(produce(draft => { draft.modelPushed = true }));
                this.setState(produce(draft => { draft.versionPushed = false }));
            }
        }

        if (this.state.select === "browser" || this.state.select === "operating") {
            if (this.state.models.includes(event.target.element.name)) {
                let arr = this.state.models.filter(e => e !== event.target.element.name); 
                this.setState(produce(draft => { draft.models = arr }));
                this.setState(produce(draft => { draft.versionPushed = true }));
                this.setState(produce(draft => { draft.modelPushed = false }));
            }
            else {
                this.setState(produce(draft => { draft.models.push(event.target.element.name) }));
                this.setState(produce(draft => { draft.versionPushed = true }));
                this.setState(produce(draft => { draft.modelPushed = false }));
            }
        }
        setTimeout(() => {
            if (this.state.models.length === 0) {
                this.setState(produce(draft => { draft.tagsData = []}));    
            }
        }, 50);
    }

    tagsChange = (event) => {
        
        this.setState({
            tagsData: [ ...event.target.value ]
        });
    }       

    onCityChange = (event) => {
        this.setState({
            cityValue: event.target.value
        });
    }
    filterChange = (event) => {
        if (this.state.select === "city") {
            this.props.fetchCityData(this.props.accessToken, event.filter.value);
            if (event.filter.value === "") {
                this.setState(produce(draft => { draft.data = [] }));
            }
            else {
                // this.setState(produce(draft => { draft.selectCityChange = true }));
                setTimeout(() => {
                    const data = filterBy(this.props.cityDataReceived.slice(), event.filter);
                    this.setState(produce(draft => { draft.data = data }));
                }, 1500);
            }
        }

        if (this.state.select === "country") {
            this.props.fetchCountryData(this.props.accessToken, event.filter.value);
            if (event.filter.value === "") {
                this.setState(produce(draft => { draft.data = [] }));
            }
            else {
                setTimeout(() => {
                    const data = filterBy(this.props.conditionCountryDataReceived.slice(), event.filter);
                    this.setState(produce(draft => { draft.data = data }));
                }, 2000);
            }
        }

        if (this.state.select === "isp") {
            this.props.fetchISPData(this.props.accessToken, event.filter.value);
            if (event.filter.value === "") {
                this.setState(produce(draft => { draft.data = [] }));
            }
            else {
                // this.setState(produce(draft => { draft.selectCityChange = true }));
                setTimeout(() => {
                    const data = filterBy(this.props.ispDataReceived.slice(), event.filter);
                    this.setState(produce(draft => { draft.data = data }));
                }, 2000);
            }
        }
        if (this.state.select === "Mobile carrier") {
            this.props.fetchMCData(this.props.accessToken, event.filter.value);
            if (event.filter.value === "") {
                this.setState(produce(draft => { draft.data = [] }));
            }
            else {
                setTimeout(() => {
                    const data = filterBy(this.props.mcDataReceived.slice(), event.filter);
                    this.setState(produce(draft => { draft.data = data }));
                }, 2000);
            }
        }
        if (this.state.select === "region") {
            const data = filterBy(this.props.regionDataReceived.slice(), event.filter);
            this.setState(produce(draft => { draft.data = data }));
        }
        if (this.state.select === "language") {
            const data = filterBy(this.props.languageDataReceived.slice(), event.filter);
            this.setState(produce(draft => { draft.data = data }));
        }

        if (this.state.select === "proxy") {
            const data = filterBy(this.props.proxyDataReceived.slice(), event.filter);
            this.setState(produce(draft => { draft.data = data }));
        }
        if (this.state.select === "device") {
            const data = filterBy(this.props.deviceTypeDataReceived.slice(), event.filter);
            this.setState(produce(draft => { draft.data = data }));
        }
        if (this.state.select === "connection") {
            const data = filterBy(this.props.connectionTypeDataReceived.slice(), event.filter);
            this.setState(produce(draft => { draft.data = data }));
        }
        if (this.state.select === "weekday") {
            const data = filterBy(this.props.weekdayDataReceived.slice(), event.filter);
            this.setState(produce(draft => { draft.data = data }));
        }
        // if(this.state.select ==="timzone"){
        //     this.setState({data: this.filterData(event.filter)});
        // }
    };

    errorsCheck = () => {
        
            if (this.state.select !== "") {
                if (this.state.select === "brand" || this.state.select === "browser" || this.state.select === "operating") {
                    if (this.state.models.length === 0 ) {
                        if (this.state.select === "brand" || this.state.select === "browser") {
                            //console.log('ERROR in brand browser !!!!!!!!!!');
                            this.setState(produce(draft => { draft.errors.tagsError = `Add atleast one ${this.state.select} with atleast one version or model.` }))
                            this.setState(produce(draft => { draft.errors.multiSelectError = "" }))
                        }
                        if (this.state.select === "operating") {
                            //console.log('ERROR in ooperating system !!!!!!!!!!');
                            this.setState(produce(draft => { draft.errors.tagsError = "Add atleast one operating system with atleast one version." }))
                            this.setState(produce(draft=>{draft.errors.multiSelectError=""}))
                        }
                    }
                    if (this.state.models.length > 0 && this.state.tagsData.length === 0) {
                        this.setState(produce(draft => { draft.errors.tagsError = "Add atleast one model/version." }))
                    }
                    if(this.state.models.length > 0 && this.state.tagsData.length > 0) {
                        this.setState(produce(draft => { draft.errors.tagsError = "" }))
                        this.setState(produce(draft=>{draft.errors.multiSelectError=""}))
                    }
                } else {
                    if (this.state.cityValue.length === 0 ) {
                        //console.log('ERROR in other selects !!!!!!!!!!');
                        if (this.state.select === "connection" || this.state.select === "device") {
                            this.setState(produce(draft=>{draft.errors.multiSelectError=`Add atleast one ${this.state.select} type.`}))
                            this.setState(produce(draft=>{draft.errors.tagsError=""}))
                        }
                        else {
                            this.setState(produce(draft=>{draft.errors.multiSelectError=`Add atleast one ${this.state.select}.`}))
                            this.setState(produce(draft=>{draft.errors.tagsError=""}))
                        }
                    }
                    else {
                        this.setState(produce(draft => { draft.errors.tagsError = "" }))
                        this.setState(produce(draft=>{draft.errors.multiSelectError=""}))
                        this.setState(produce(draft=>{draft.errors.ipAddressError=""}));
                        this.setState(produce(draft=>{draft.errors.customVarError=""}));
                    }
                    if(this.state.select==="IP"){
                        if (this.state.ip==="" || this.state.ip.length > 0) {
                            if(this.ValidateIPaddress(this.state.ip)===false){
                                this.setState(produce(draft => { draft.errors.ipAddressError = "You must add atleast one ip address with valid ip address." }));
                                this.setState(produce(draft=>{draft.errors.multiSelectError=""}))
                            }
                            else {
                                this.setState(produce(draft => { draft.errors.ipAddressError = "" }));
                                this.setState(produce(draft => { draft.errors.tagsError = "" }));
                                this.setState(produce(draft=>{draft.errors.multiSelectError=""}));
                            }
                        }
                        this.checkIpAddressValidation();
                    }
                    if(this.state.select==="timezone"){
                        if(this.state.fromh==="" || this.state.fromm===""){
                            this.setState(produce(draft => { draft.errors.timezoneError.from = "Start time must match the h:MM format." }));
                        }
                        if(this.state.toh==="" || this.state.tom===""){
                            this.setState(produce(draft => { draft.errors.timezoneError.to = "End time must match the h:MM format." }));
                        }
                        if(this.state.dataToSend.timeZoneId===""){
                            this.setState(produce(draft => { draft.errors.timezoneError.timezone = "Set a time zone." }));
                        }else{
                            this.setState(produce(draft => { draft.errors.timezoneError.timezone = "" }));
                        }
                        this.setState(produce(draft=>{draft.errors.multiSelectError=""}));
                        this.checkTimeZoneValidation();
                    }
                    if(this.state.select === "Referrer" || this.state.select === "Referrer domain"){
                        if(this.state.domain===""){
                            this.setState(produce(draft => { draft.errors.referrerDomainError = `${this.state.select} cannot be empty.`}));
                            this.setState(produce(draft=>{draft.errors.multiSelectError=""}));
                        }
                        else{
                            this.setState(produce(draft => { draft.errors.referrerDomainError = "" }));
                            this.setState(produce(draft=>{draft.errors.multiSelectError=""}));
                        }
                    }
                    if(this.state.select.includes("Custom")){
                        for(let i=1;i<=20;i++){
                            if(this.state.select==="Custom variable "+i){
                                if(this.state.customVar===""){
                                    this.setState(produce(draft => { draft.errors.customVarError = "You need to enter a value for the custom variable."}));
                                    this.setState(produce(draft=>{draft.errors.multiSelectError=""}));
                                }
                                else{
                                    this.setState(produce(draft => { draft.errors.customVarError = ""}));
                                    this.setState(produce(draft=>{draft.errors.multiSelectError=""}));
                                }
                            }
                        }
                    }
                }
        }
        
            this.submitConditionsData();
        
    }

    checkTimeZoneValidation=()=>{
        // if(this.state.dataToSend.from!==""){
        //     let hour= Number(this.state.dataToSend.from.split(':')[0]);
        //     let minutes = Number(this.state.dataToSend.from.split(':')[1]);
        //     if(hour>=0 && hour<24 && minutes>=0 && minutes<60){
        //         this.setState(produce(draft => { draft.errors.timezoneError.from = "" }));
        //     }
        //     else{
        //         this.setState(produce(draft => { draft.errors.timezoneError.from = "Start time must match the h:MM format." }));
        //     }
        // }

        // if(this.state.dataToSend.to!==""){
        //     let hour= Number(this.state.dataToSend.to.split(':')[0]);
        //     let minutes = Number(this.state.dataToSend.to.split(':')[1]);
        //     if(hour>=0 && hour<24 && minutes>=0 && minutes<60){
        //         this.setState(produce(draft => { draft.errors.timezoneError.to = "" }));
        //     }
        //     else{
        //         this.setState(produce(draft => { draft.errors.timezoneError.to = "End time must match the h:MM format." }));
        //     }
        // }
        if(this.state.fromh!=="" && this.state.fromm!==""){
            this.setState(produce(draft => { draft.dataToSend.from = this.state.fromh + ":" + this.state.fromm}));
            this.setState(produce(draft => { draft.errors.timezoneError.from = "" }));
        }
        else{
            this.setState(produce(draft => { draft.errors.timezoneError.from = "Start time must match the h:MM format." }));
        }
        if(this.state.toh!=="" && this.state.tom!==""){
            this.setState(produce(draft => { draft.dataToSend.to = this.state.toh + ":" + this.state.tom}));
            this.setState(produce(draft => { draft.errors.timezoneError.to = "" }));
        }
        else{
            this.setState(produce(draft => { draft.errors.timezoneError.to = "End time must match the h:MM format." }));
        }

    }

    submitConditionsData = () => {
        //console.log('state all value', this.state);
        setTimeout(() => {
            if (this.state.errors.tagsError === "" && this.state.errors.multiSelectError === "" && this.state.errors.ipAddressError === ""
            && this.state.errors.timezoneError.from==="" && this.state.errors.timezoneError.to==="" && this.state.errors.timezoneError.timezone==="" && this.state.errors.referrerDomainError ==="" && this.state.errors.customVarError ==="") {
                    this.props.addConditions(this.state);
                    this.toggleDialog();
            }
            else {
                console.log('this.state.error',this.state.errors);
                console.log('resolve errors....');
            }
        }, 100);
        
    }

    onPredicateSwitchChange = () => {
        this.setState({ predicate: !this.state.predicate });
    }

    filterTimezoneChange = (event) => {
        this.setState({
            data: this.filterData(event.filter)
        });
        //console.log('this.state.', this.state.data);
    }

    filterData(filter) {
        const data = this.props.timezoneDataReceived.slice();
        return filterBy(data, filter);
    }

    inputChangeHandler = (e) => {
        //console.log('name',e.target.name,e.target.value);
        if(e.target.name==="is_empty"){
            this.setState(produce(draft => { draft[e.target.name] = !this.state.is_empty }));     
        }
        else{
            if (e.target.name === "timeZoneId" && e.value!==null) {
                this.setState(produce(draft => { draft.dataToSend[e.target.name] = e.value.id }));    
            }
            else{
                this.setState(produce(draft => { draft[e.target.name] = e.target.value }));           
            }
        }
        // setTimeout(() => {
        //     console.log(this.state);
        // }, 1000);
    }

    ValidateIPaddress = (ipaddress)=> {
        var ipformat = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
         if(ipaddress.match(ipformat)){
             return true;
         }
         else
         {
             return false;
         }
    }

    checkIpAddressValidation = () => {
        let ip = this.state.ip.split(',');
        //console.log('ip', ip);
        if (this.state.ip.length > 0) {
            for (let i = 0; i < ip.length; i++){
                if(this.ValidateIPaddress(ip[i])===false){
                    this.setState(produce(draft => { draft.errors.ipAddressError = "You must add atleast one ip address with valid ip address." }));
                    break;
                }
                else {
                    this.setState(produce(draft => { draft.errors.ipAddressError = "" }));
                }   
            }
        }
    }
    handleChange = (e) => {
        //console.log('event',e);
        this.setState({ iRSelectedValue: e.value });
    };
    //edited again

    render() {
        return (
            <div className="main-condition-modal">
                {this.state.visible && (
                    <Dialog className="condition-modal" title={""} onClose={this.toggleDialog}>
                        <ModalForm
                            state={this.state}
                            propsState={this.props.state}
                            onChangeSelect={this.onChangeSelect}
                            onChange={this.onChange}
                            onClickBox={this.onClickBox}
                            filterChange={this.filterChange}
                            filterTimezoneChange={this.filterTimezoneChange}
                            onCityChange={this.onCityChange}
                            tagsChange={this.tagsChange}
                            onClickBoxTagsList={this.onClickBoxTagsList}
                            onModelChange={this.onModelChange}
                            onIpAdressChange={this.onIpAdressChange}
                            onPredicateSwitchChange={this.onPredicateSwitchChange}
                            showOptions={this.props.showOptions}
                            //timezoneData={this.props.timezoneDataReceived}
                            inputChangeHandler={(e) => this.inputChangeHandler(e)}
                            handleChange={this.handleChange}
                        />

                        <div className="flow-footer">
                            <div className="footer_buttons">
                                <Button
                                    className="cancel_flow_button button"
                                    onClick={this.toggleDialog}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="save_flow_button button"
                                    onClick={this.errorsCheck}
                                >
                                    Apply
                                </Button>
                            </div>
                        </div>
                    </Dialog>
                )}
            </div>
        );
    }
}

export const mapStateToProps = createStructuredSelector({
    brandDataReceived: makeSelectBrandData(),
    browserDataReceived: makeSelectBrowserData(),
    cityDataReceived: makeSelectCityData(),
    conditionCountryDataReceived: makeSelectConditionCountryData(),
    languageDataReceived: makeSelectLanguageData(),
    regionDataReceived: makeSelectRegionData(),
    connectionTypeDataReceived: makeSelectConnectionTypeData(),
    deviceTypeDataReceived: makeSelectDeviceData(),
    osDataReceived: makeSelectOperatingSystemData(),
    proxyDataReceived: makeSelectProxyData(),
    accessToken: makeSelectToken(),
    ispDataReceived: makeSelectISPData(),
    mcDataReceived: makeSelectMCData(),
    weekdayDataReceived: makeSelectWeekDayData(),
    timezoneDataReceived: makeSelectLoadTimeZoneData(),
});


export function mapDispatchToProps(dispatch) {
    return {
        dispatchLoadFlows: (...payload) => dispatch(newFlowsActions.loadFlows(...payload)),
        deleteFlows: (...payload) => dispatch(newFlowsActions.deleteFlows(...payload)),
        editFlows: (...payload) => dispatch(newFlowsActions.editFlows(...payload)),
        dispatchLoadCountries: (...payload) => dispatch(newFlowsActions.loadCountries(...payload)),

        fetchBrandData: (...payload) => dispatch(newFlowsActions.loadBrand(...payload)),
        fetchCityData: (...payload) => dispatch(newFlowsActions.loadCity(...payload)),
        fetchCountryData: (...payload) => dispatch(newFlowsActions.loadConditionCountries(...payload)),
        fetchBrowserData: (...payload) => dispatch(newFlowsActions.loadBrowser(...payload)),
        fetchDeviceTypeData: (...payload) => dispatch(newFlowsActions.loadDeviceType(...payload)),
        fetchConnectionTypeData: (...payload) => dispatch(newFlowsActions.loadConnectionType(...payload)),
        fetchLanguageData: (...payload) => dispatch(newFlowsActions.loadLanguage(...payload)),
        fetchProxyData: (...payload) => dispatch(newFlowsActions.loadProxy(...payload)),
        fetchRegionData: (...payload) => dispatch(newFlowsActions.loadRegion(...payload)),
        fetchOperatingSystemData: (...payload) => dispatch(newFlowsActions.loadOperatingSystem(...payload)),
        fetchISPData: (...payload) => dispatch(newFlowsActions.loadISP(...payload)),
        fetchMCData: (...payload) => dispatch(newFlowsActions.loadMobileCarrier(...payload)),
        fetchWeekDay: (...payload) => dispatch(newFlowsActions.loadWeekDay(...payload))
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(CondtionModal);
