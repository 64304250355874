import React, {Component} from "react";
import produce from "immer";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import ModalForm from "./ModalForm";
import {createStructuredSelector} from "reselect";
import {connect} from 'react-redux';
import {makeSelectDomainsDataReceived} from "../../../Settings/Domains/selectors";
import { loadCustomDomains } from "../../../Settings/Domains/actions";
import {makeSelectToken} from "../../../../registration/LoginRegister/selectors";
import {
    makeSelectLoading,
    makeSelectSuccess,
    makeSelectError,
    makeSelectTokenTagsListReceived,
    makeSelectIsTagsListReceived,
    makeSelectCountriesListReceived,
} from "../selectors";
import * as LanderActions from "../actions";
// import LoadingPanel from "../../../grid/loader/loader";

class AddLanderModal extends Component {
    state = {
        dataToSend: {
            "name": "",
            "pre_lander": false,
            "countryId": "",
            "workspaceId": "",
            "url": "",
            "no_of_cta": 1,
            "domainId": "",
            "verify_status": false,
            "tags": []
        },
        loadedWorkspaces: null,
        loadedDomains: null,
        tagsListReceived: null,
        loadedCountries: null,
        countryName: "",
        isNameEmpty: false,
        is_url_empty: false,
        is_url_valid: true,
        is_protocol_valid: true,
        is_domain_valid: true,
        resolveErrors: "",
        selectedDomainName: "",
        setupVerificationError: null,
        isVerifyingSetupDomain: false,
        setupDomainStatusVerified: false,
        landersDataLoaded:false,
        disabled: false,
        allLoaded: false
    }
    fetchDomainsAndWorkspaces = async () => {
        return this.props.dispatchLoadDomainsAndWorkspaces(this.props.accessToken);
    }
    fetchTokenTags = async () => {
        return this.props.dispatchLoadTags({accessToken: this.props.accessToken});
    }
    fetchCountries = async () => {
        return this.props.dispatchLoadCountries({accessToken: this.props.accessToken});
    }

    componentDidMount() {
        // fetching and workspaces initially
        if (this.state.loadedWorkspaces === null || this.state.loadedDomains === null) {
            this.fetchDomainsAndWorkspaces();
        } else {
            this.setState(produce(draft => { draft.dataToSend.workspaceId = String(this.state.loadedWorkspaces[0].id) }));
            this.setState(produce(draft => { draft.dataToSend.domainId = String(this.state.loadedDomains[0].id) }));
        }
        if (this.state.tagsListReceived === null) { this.fetchTokenTags(); }
        if (this.state.loadedCountries === null) { this.fetchCountries(); }


        // set lander or pre lander status based on the lander or pre lander button clicked based on parent component state
        this.setState(produce(draft => { draft.dataToSend.pre_lander = this.props.state.isPreLander }));

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        // Set domains and workspaces initial values in the state
        if ((prevProps.domainsAndWorkspacesListsReceived !== this.props.domainsAndWorkspacesListsReceived) && this.props.domainsAndWorkspacesListsReceived !== null && this.props.domainsAndWorkspacesListsReceived !== undefined && this.state.loadedWorkspaces === null) {
            this.setState(produce(draft => { draft.loadedWorkspaces = this.props.domainsAndWorkspacesListsReceived.workspace }));
            this.setState(produce(draft => { draft.dataToSend.workspaceId = String(this.props.domainsAndWorkspacesListsReceived.workspace[0].id) }));
            this.setState(produce(draft => { draft.loadedDomains = this.props.domainsAndWorkspacesListsReceived.domain }));
            for (let i = 0; i < this.props.domainsAndWorkspacesListsReceived.domain.length; i++){
                if (this.props.domainsAndWorkspacesListsReceived.domain[i].selected === true) {
                    this.setState(produce(draft => {
                        draft.dataToSend.domainId = String(this.props.domainsAndWorkspacesListsReceived.domain[i].id);
                        let name = this.props.domainsAndWorkspacesListsReceived.domain[i].name;
                        if (name.startsWith("http://")) {
                            draft.selectedDomainName = "https://" + name.split("http://")[1];
                        } else if (name.startsWith("https://")) {
                            draft.selectedDomainName = name;
                        } else {
                            draft.selectedDomainName = "https://" + name;
                        }
                    }));
                }
            }
        }
        // Set token tags list in the state
        if ((prevProps.tagsListReceived !== this.props.tagsListReceived) && this.props.tagsListReceived !== undefined && this.state.tagsListReceived === null) {
            this.setState(produce(draft => { draft.tagsListReceived = this.props.tagsListReceived }));
        }
        // Set countries list in the state
        if ((prevProps.countriesListReceived !== this.props.countriesListReceived) && this.props.countriesListReceived !== null && this.props.countriesListReceived !== undefined && this.state.loadedCountries === null) {
            if (this.props.countriesListReceived.length > 0) {
                this.setState(produce(draft => {
                    draft.loadedCountries = this.props.countriesListReceived;
                    draft.dataToSend.countryId = this.props.countriesListReceived[98].id;
                    draft.countryName = this.props.countriesListReceived[98].country_name;
                }));
            }
        }

        // remove the loader once the data is all loaded
        if (this.state.loadedCountries !== null && this.state.loadedWorkspaces !== null && this.state.allLoaded === false) {
            this.props.toggleLoader();
            this.setState(produce(draft => { draft.allLoaded = true; }));
        }
    }

    // for workspace and changes
    simpleInputChangeHandler = (e) => {
            this.setState(produce(draft => { draft.dataToSend[e.target.name] = e.target.value }));
            if (e.target.name === "domainId") {
                this.setStateDomainName(e.target.value);
            }
    }

    // setting domain name to change the value in click urls
    setStateDomainName = (data) => {
        for (let i=0;i<this.state.loadedDomains.length;i++) {
            if (Number(data) === this.state.loadedDomains[i].id) {
                if(this.state.loadedDomains[i].name.startsWith("https://")){
                    this.setState(produce(draft => { draft.selectedDomainName = this.state.loadedDomains[i].name}));
                }
                else if(this.state.loadedDomains[i].name.startsWith("http://")){
                    this.setState(produce(draft => {draft.selectedDomainName = [this.state.loadedDomains[i].name.slice(0, 4), "s", this.state.loadedDomains[i].name.slice(4)].join('')}));
                }else{
                    this.setState(produce(draft => {draft.selectedDomainName = [this.state.loadedDomains[i].name.slice(0, 0), "https://", this.state.loadedDomains[i].name.slice(0)].join('')}));
                }
            }
        }
    }

    // for changing countries
    countryChangeHandler = (value) => {
        for (let i=0;i<this.state.loadedCountries.length;i++) {
            if (Number(value) === this.state.loadedCountries[i].id) {
                this.setState(produce(draft => { draft.dataToSend.countryId = this.state.loadedCountries[i].id }));
                this.setState(produce(draft => { draft.countryName = this.state.loadedCountries[i].country_name }));
            }
        }
    }

    // for adding custom tags
    tagsInputChangeHandler = () => { }

    sendLanderData = async () => {
        this.setState(produce(draft => { draft.dataToSend.countryId = String(this.state.dataToSend.countryId )}));
        if(this.state.dataToSend.tags !== null) {
            if(this.state.dataToSend.tags.length === 0) {
                this.setState(produce(draft => { draft.dataToSend.tags = null }));
            }
        }
        setTimeout(() => {
            return this.props.dispatchPostLander({
                data: this.state.dataToSend,
                accessToken: this.props.accessToken
            });
        },100);
    }

    submitLanderData = () => {
        this.setState(produce(draft => { draft.disabled = true }));
        // check if protocol and domain is valid if url value is populated
        if (this.state.dataToSend.url.length === 0 ) {
            this.setState(produce(draft => { draft.is_url_empty = true }));
        } else {
            this.setState(produce(draft => { draft.is_url_empty = false }));
            if (this.state.dataToSend.url.length > 0 && !this.hasValidUrlProtocol(this.state.dataToSend.url)) {
                this.setState(produce(draft => { draft.is_protocol_valid = false; }));
            } else { this.setState(produce(draft => { draft.is_protocol_valid = true; })); }
            if (this.state.dataToSend.url.length > 0 && !this.hasValidDomainName(this.state.dataToSend.url)) {
                this.setState(produce(draft => { draft.is_url_valid = false; }));
            } else { this.setState(produce(draft => { draft.is_url_valid = true; })); }
        }

        setTimeout(() => {
            // check if name if not empty or domain entered is a valid url
            if (this.state.dataToSend.name === "") {
                this.setState(produce(draft => {
                    draft.isNameEmpty = true;
                    draft.disabled = false;
                }));
            } else if (this.state.is_protocol_valid === false || this.state.is_url_valid === false || this.state.is_url_empty === true) {
                this.setState(produce(draft => {
                    draft.resolveErrors = "Please make sure to resolve all errors before submitting the data.";
                    draft.disabled = false;
                }));
            } else {
                this.setState(produce(draft => { draft.resolveErrors = "" }));
                this.sendLanderData();
                this.props.closeAddLanderDialogueAfterSubmission();
            }
        },200);

    }

    //Checking for postback url
    // Checking for http:// and https://
    hasValidUrlProtocol = (url = '') => Boolean(['http://', 'https://', 'ftp://'].some(protocol => url.startsWith(protocol)));
    // check on form submit and for valid domain name
    hasValidDomainName = (str) => {
        // return new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(text);
        let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*','i') // port and path
            //     // '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
            //     // '(\\#[-a-z\\d_]*)?$','i') // fragment locator
        ;
        return !!pattern.test(str);
    }

    changeTagsColor = (data) => {
        let indexes = [];
        if (this.state.tagsListReceived !== null || this.state.tagsListReceived !== undefined){
            for(let i=0;i<this.state.tagsListReceived.length;i++) {
                if (data.includes(this.state.tagsListReceived[i].code)) {
                    indexes.push(data.indexOf(this.state.tagsListReceived[i].code));
                    document.getElementById(this.state.tagsListReceived[i].code).style.backgroundColor = "#6786DE";
                    document.getElementById(this.state.tagsListReceived[i].code).style.color = "#fff";
                } else {
                    document.getElementById(this.state.tagsListReceived[i].code).style.backgroundColor = "#F0F3F7";
                    document.getElementById(this.state.tagsListReceived[i].code).style.color = "#8892AA";
                }
            }
        }
    }

    urlChangeHandler = (e) => {
        let indexes = [];
        if (e.target.value !== "") {
            this.setState(produce(draft => { draft.is_url_empty = false; }));
            if(this.hasValidUrlProtocol(e.target.value)) {
                this.setState(produce(draft => {draft.is_protocol_valid = true }));
            } else { this.setState(produce(draft => {draft.is_protocol_valid = false })); }
            if (this.hasValidDomainName(e.target.value)) {
                this.setState(produce(draft => { draft.is_domain_valid = true }));
            } else { this.setState(produce(draft => { draft.is_domain_valid = false })); }
            this.changeTagsColor(e.target.value);
        }
        else {
            this.setState(produce(draft => {draft.is_protocol_valid = true}));
            this.setState(produce(draft => { draft.is_domain_valid = true }));
        }
        this.setState(produce(draft => { draft.dataToSend.url = e.target.value }));
    }
    addTokenToUrl = (e) => {
        if (!this.state.dataToSend.url.includes(e.target.id)) {
            const value = this.state.dataToSend.url + String(e.target.id);
            this.setState(produce(draft => { draft.dataToSend.url =  value }));
            document.getElementById(e.target.id).style.backgroundColor = "#6786DE";
            document.getElementById(e.target.id).style.color = "#fff";
            setTimeout(() => {
                if(this.hasValidUrlProtocol(this.state.dataToSend.url)) {
                    this.setState(produce(draft => {draft.is_protocol_valid = true }));
                } else { this.setState(produce(draft => {draft.is_protocol_valid = false })); }
                if (this.hasValidDomainName(this.state.dataToSend.url)) {
                    this.setState(produce(draft => { draft.is_domain_valid = true }));
                } else { this.setState(produce(draft => { draft.is_domain_valid = false })); }
                this.changeTagsColor(this.state.dataToSend.url);
            },200);
        }
    }

    changeNumberOfCTAs = (data) => {
        if (data === "add") {
            if (this.state.dataToSend.no_of_cta < 10) {
                this.setState(produce(draft => { draft.dataToSend.no_of_cta = this.state.dataToSend.no_of_cta + 1 }));
            }
        } else if (data === "subtract") {
            if (this.state.dataToSend.no_of_cta > 1) {
                this.setState(produce(draft => { draft.dataToSend.no_of_cta = this.state.dataToSend.no_of_cta - 1 }));
            }
        }
    }

    verifySetupLandingPage = () => {
        this.setState(produce(draft => { draft.isVerifyingSetupDomain = true }));
        const domain = this.state.selectedDomainName;
        let domainModified = "";
        if (domain.startsWith("http://") || domain.startsWith("https://")) {
            domainModified = domain;
        } else {
            domainModified = "http://" + domain;
        }
        let isVerified = false;
        let MrChecker = new XMLHttpRequest();
        // Opens the file and specifies the method (get)
        // Asynchronous is true
        MrChecker.open('get', domainModified, true);

        //check each time the ready state changes
        //to see if the object is ready
        MrChecker.onreadystatechange = checkReadyState;

        function checkReadyState() {
            if (MrChecker.readyState === 4) {
                //check to see whether request for the file failed or succeeded
                if ((MrChecker.status === 200) || (MrChecker.status === 0)) {
                    isVerified = true;
                }
                else {
                    isVerified = false;
                }
            }

        }
        MrChecker.send(null);
        setTimeout(() => {
            if(isVerified) {
                this.setupLandingPageVerified();
            } else {
                this.setupLandingPageNotVerified();
            }
        },2000);
    }

    setupLandingPageVerified = () => {
        this.setState(produce(draft => { draft.isVerifyingSetupDomain = false }));
        this.setState(produce(draft => { draft.dataToSend.verify_status = true }));
        this.setState(produce(draft => { draft.setupVerificationError = null }));
    }
    setupLandingPageNotVerified = () => {
        this.setState(produce(draft => { draft.isVerifyingSetupDomain = false }));
        this.setState(produce(draft => { draft.dataToSend.verify_status = false }));
        this.setState(produce(draft => { draft.setupVerificationError = "Can not verify the link.. Please check if the entered domain is correct" }));
        return;
    }
    handleTagChange = (event) => {
        const values = event.target.value;
        const lastItem = values[values.length - 1];

        if (lastItem) {
            values.pop();
            const sameItem = values.find(value => value === lastItem);
            if (sameItem === undefined) {
                values.push(lastItem);
            }
        }
        this.setState(produce(draft => { draft.dataToSend.tags = values }));
    }

    render () {
        return (
            <div>
                <Dialog className="add_affiliate_networks_dialogue" title={this.state.dataToSend.pre_lander ? "Add PreLander" : "Add Lander"} onClose={() => this.props.toggleLanderDialogue("Close_Add_Toggle_Lander")}>
                    <div className="add_affiliate_network_dialogue_content">
                        <div className="content_form">
                            <ModalForm
                                state={this.state}
                                simpleInputChangeHandler={this.simpleInputChangeHandler}
                                countryChangeHandler={this.countryChangeHandler}
                                addParameter={this.addParameter}
                                tagsListReceived={this.state.tagsListReceived}
                                urlChangeHandler={this.urlChangeHandler}
                                addTokenToUrl={this.addTokenToUrl}
                                tagsInputChangeHandler={this.tagsInputChangeHandler}
                                changeNumberOfCTAs={this.changeNumberOfCTAs}
                                verifySetupLandingPage={this.verifySetupLandingPage}
                                handleTagChange={this.handleTagChange}
                                dataLoaded={this.state.landersDataLoaded}
                            />
                        </div>
                    </div>
                    <DialogActionsBar>
                                <div className="action_bar_buttons">
                                    <div className="left">
                                        <button onClick={() => this.props.toggleLanderDialogue("Close_Add_Toggle_Lander")} className="normal_btn cancel_button gray_button_bg_imp black border_radius_normal_btn normal_text black no_border">Close</button>
                                    </div>
                                    <div className="right">
                                        <button
                                            onClick={this.submitLanderData}
                                            className="normal_btn theme_dark_btn theme_dark_bg_color_imp color_white_imp border_radius_normal_btn save_button normal_text"
                                            disabled={this.state.disabled}
                                        >Save</button>
                                    </div>
                                </div>
                    </DialogActionsBar>
                </Dialog>
            </div>
        );
    }
}

export const mapStateToProps = createStructuredSelector({
    accessToken: makeSelectToken(),
    loading: makeSelectLoading(),
    success: makeSelectSuccess(),
    error: makeSelectError(),
    domainsAndWorkspacesListsReceived: makeSelectDomainsDataReceived(),
    tagsListReceived: makeSelectTokenTagsListReceived(),
    isTagsListReceived: makeSelectIsTagsListReceived(),
    countriesListReceived: makeSelectCountriesListReceived(),

});
export function mapDispatchToProps(dispatch) {
    return {
        dispatchLoadDomainsAndWorkspaces: (...payload) => dispatch(loadCustomDomains(...payload)),
        dispatchLoadTags: (...payload) => dispatch(LanderActions.loadTokensDictionary(...payload)),
        dispatchLoadCountries: (...payload) => dispatch(LanderActions.loadCountry(...payload)),
        dispatchPostLander: (...payload) => dispatch(LanderActions.postLander(...payload)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddLanderModal);