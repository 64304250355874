import React, { Fragment } from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import {Button} from "@progress/kendo-react-buttons";
import produce from "immer";
import {formatDate} from "../../../components/searchParameters";
import { Fade } from '@progress/kendo-react-animation';
import { Calendar } from "@progress/kendo-react-dateinputs";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { CountryDropdown } from 'react-country-region-selector';
import { RadioGroup } from '@progress/kendo-react-inputs';


class ProfileForm extends React.Component {
    state = {
        firstname: this.props.state.dataToSend.firstname,
        lastname: this.props.state.dataToSend.lastname,
        email: this.props.state.dataToSend.email,
        accType: this.props.state.dataToSend.accountType,
        link: this.props.state.dataToSend.link,
        company: this.props.state.dataToSend.company,
        website: this.props.state.dataToSend.website,
        instant_messenger: this.props.state.dataToSend.instant_messenger,
        instant_messenger_number: this.props.state.dataToSend.instant_messenger_number,
        address: this.props.state.dataToSend.address,
        city: this.props.state.dataToSend.city,
        state: this.props.state.dataToSend.region,
        country: this.props.state.dataToSend.country,
        zipCode: this.props.state.dataToSend.zipCode,
        phone: this.props.state.dataToSend.phoneNumber,
        region:this.props.state.dataToSend.region,
        error: {
            firstname: null,
            lastname:null,
            email: null,
            // birthday: null,
            accType: null,
            link: null,
            company: null,
            instant_messenger_number:null,
            website: null,
            address: null,
            city: null,
            state: null,
            country: null,
            zipCode: null,
            phone: null
        },
        acc_Type: [
            {
                label: "Individual",
                value: "Individual",
            },
            {
                label: "Company",
                value: "Company",
            },
        ]
    }
    
    componentDidMount() {
        //this.props.dispatchLoadCountries({})
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.state.dataToSend.dob !== this.props.state.dataToSend.dob) && this.props.state.dataToSend.dob !== null) {
            this.setState(produce(draft => {
                draft.birthday = new Date(this.props.dataToSend.dob);
                draft.showBirthday = formatDate(new Date(this.props.dataToSend.dob));
            }));
        }
    }

    handleBirthdayChange = (e) => {
        this.setState({
            birthday: e.value,
            showBirthday: formatDate(e.value)
        });
    }
    toggleCalendar = () => {
        this.setState({ showCalendar: !this.state.showCalendar });
    }
    selectCountry               = (v) => { this.setState({ country: v }); }
    radioChangeHandler          = (e) => { this.setState(produce(draft => { draft.accType = e.value; })); }
    
    simpleInputChangeHandler    = (e) => {        
        this.setState(produce(draft => {
            draft[e.target.id] = e.target.value;
        }));
    }

    checkRegistrationFormErrors = () => {
        if (this.state.firstname === "") {
            this.setState(produce(draft => { draft.error.firstname = "This field is required"; }));
        } else { this.setState(produce(draft => { draft.error.firstname = null; })); }
        if (this.state.lastname === "") {
            this.setState(produce(draft => { draft.error.lastname = "This field is required"; }));
        } else { this.setState(produce(draft => { draft.error.lastname = null; })); }

        if (this.state.email === "") {
            this.setState(produce(draft => { draft.error.email = "This field is required"; }));
        } else {
            let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

            if (!pattern.test(this.state.email)) {
                this.setState(produce(draft => { draft.error.email = "Please enter valid email address."; }));
            } else { this.setState(produce(draft => { draft.error.email = null; })); }
        }

        if (this.state.accType === "Company") {
            if (this.state.link === "" || this.state.link === null || this.state.link === undefined ) {
                this.setState(produce(draft => { draft.error.link = "Linkedin or Facebook Profile link is required." }));
            } else { this.setState(produce(draft => { draft.error.link = null })); }
            if (this.state.company === "" || this.state.company === null || this.state.company === undefined ) {
                this.setState(produce(draft => { draft.error.company = "Company name is required." }));
            } else { this.setState(produce(draft => { draft.error.company = null })); }
            if (this.state.website === "" || this.state.website === null || this.state.website === undefined) {
                this.setState(produce(draft => { draft.error.website = "Website link is required." }));
            } else { this.setState(produce(draft => { draft.error.website = null })); }
        } else {
            this.setState(produce(draft => {
                draft.error.linkedin = null;
                draft.error.facebook = null;
                draft.error.website = null;
            }));
        }

        if (this.state.city === "" || this.state.city === undefined || this.state.city === null || this.state.country === "" || this.state.country === null || this.state.country === undefined || this.state.state === "" || this.state.state === null || this.state.state === undefined || this.state.country === "" || this.state.country === null || this.state.country === undefined || this.state.zipCode === "" || this.state.zipCode === null || this.state.zipCode === undefined) {
            this.setState(produce(draft => { draft.error.address = "You must enter the complete address. It includes Street Address, City, State, Country, and Zip Code/Postal Code." }));
        } else { this.setState(produce(draft => { draft.error.address = null })); }

        if (this.state.phone === "") {
            this.setState(produce(draft => { draft.error.phone = "Phone number is required." }));
        } else { this.setState(produce(draft => { draft.error.phone = null })); }

        if (this.state.instant_messenger_number === "") {
            this.setState(produce(draft => { draft.error.instant_messenger_number = "Messenger number is required." }));
        } else { this.setState(produce(draft => { draft.error.instant_messenger_number = null })); }
    }

    handleSubmit = event => {
        this.checkRegistrationFormErrors();
        setTimeout(() => {
            if (this.state.error.accType === null  && this.state.error.firstname === null && this.state.error.lastname === null && this.state.error.address === null && this.state.error.email === null && this.state.error.link === null && this.state.error.company === null && this.state.error.phone === null && this.state.error.website === null && this.state.error.instant_messenger_number === null) {
                this.props.submitProfileData({
                    firstname: this.state.firstname,
                    lastname: this.state.lastname,
                    email: this.state.email,
                    password: this.state.password,
                    // dob: this.state.showBirthday,
                    accountType: this.state.accType,
                    link: this.state.link,
                    company: this.state.company,
                    instant_messenger:this.state.instant_messenger,
                    instant_messenger_number:this.state.instant_messenger_number,
                    website: this.state.website,
                    address: this.state.address,
                    city: this.state.city,
                    region: this.state.state,
                    zipCode: this.state.zipCode,
                    country: this.state.country,
                    phoneNumber: this.state.phone
                });
            }
        },200);

    }

    render(){
        const radioGroupValidator = (value) => !value ? "Account Type is required" : "";
        // console.log(this.props)
        return (
            
                
            <div className="container">
                        <Form
                            // onSubmit={handleSubmit}
                            render={(formRenderProps) => (
                                <FormElement className="register_form_element">
                                    <fieldset className={"k-form-fieldset"}>
                                        <div className="signup_grid">

                                            <div className="signup-row">
                                                <div className="mb-2 col">
                                                    <div className="input_group">
                                                        <label htmlFor="name">First Name *</label>
                                                        <input
                                                            type="text"
                                                            name="firstname"
                                                            id="firstname"
                                                            value={this.state.firstname}
                                                            onChange={this.simpleInputChangeHandler}
                                                        />
                                                    </div>
                                                    {
                                                        this.state.error.firstname !== null ?
                                                            <Fade enter={true} exit={true}>
                                                                <p className="registration_failure_para">{this.state.error.firstname}</p>
                                                            </Fade>
                                                            : null
                                                    }
                                                </div>
                                            </div>

                                            <div className="signup-row">
                                                <div className="mb-2 col">
                                                    <div className="input_group">
                                                        <label htmlFor="name">Last Name *</label>
                                                        <input
                                                            type="text"
                                                            name="lastname"
                                                            id="lastname"
                                                            value={this.state.lastname}
                                                            onChange={this.simpleInputChangeHandler}
                                                        />
                                                    </div>
                                                    {
                                                        this.state.error.lastname !== null ?
                                                            <Fade enter={true} exit={true}>
                                                                <p className="registration_failure_para">{this.state.error.lastname}</p>
                                                            </Fade>
                                                            : null
                                                    }
                                                </div>
                                            </div>

                                            <div className="email">
                                                <div className="input_group">
                                                    <label htmlFor="email">E-mail *</label>
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        id="email"
                                                        value={this.state.email}
                                                        onChange={this.simpleInputChangeHandler}
                                                    />
                                                </div>
                                                {
                                                    this.state.error.email !== null ?
                                                        <Fade enter={true} exit={true}>
                                                            <p className="registration_failure_para">{this.state.error.email}</p>
                                                        </Fade>
                                                        : null
                                                }
                                            </div>
                                           
                                        </div>
                                        {/* <div className="signup_grid" style={{ marginTop: "20px"}}>
                                            <div className="sign_up_birthday_con">
                                                <div className="inner">
                                                    <label className="" htmlFor="birthday">Birthday *</label>
                                                    <div
                                                        className="birthday_show"
                                                        onClick={this.toggleCalendar}
                                                    >{this.state.showBirthday}</div>
                                                </div>
                                                {
                                                    this.state.showCalendar ?
                                                        <div className="birthday_calendar">
                                                            <Calendar
                                                                disabled={false}
                                                                value={this.state.birthday}
                                                                onChange={this.handleBirthdayChange}
                                                            />
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    this.state.error.birthday !== null ?
                                                        <Fade enter={true} exit={true}>
                                                            <p className="registration_failure_para">{this.state.error.birthday}</p>
                                                        </Fade>
                                                        : null
                                                }
                                            </div>
                                        </div> */}
                                        <div className="acc_type_row">
                                            <label className="" htmlFor="acc_type">Select Account Type *</label>
                                            <RadioGroup
                                                data={this.state.acc_Type}
                                                defaultValue={this.state.accType}
                                                valid={radioGroupValidator}
                                                onChange={this.radioChangeHandler}
                                            />
                                        </div>
                                        <div className="signup_grid">
                                            <div className="styled-input acc_type_row">
                                                <div className="input_group" style={{ paddingTop: "0px" }}>
                                                    <label htmlFor="linkedin or facebook">Linkedin or Facebook Url {this.state.accType === "Company" ? "*" : null}</label>
                                                    <input
                                                        type="text"
                                                        name="link"
                                                        id="link"
                                                        value={this.state.link}
                                                        onChange={this.simpleInputChangeHandler}
                                                    />
                                                </div>
                                                {
                                                    this.state.error.link !== null ?
                                                    <Fade enter={true} exit={true}>
                                                        <p className="registration_failure_para">{this.state.error.link}
                                                        </p>
                                                    </Fade>
                                                    : null
                                                }
                                            </div>
                                            {/* <div className="acc_type_row">
                                                <div className="input_group">
                                                    <label htmlFor="facebook">Facebook Url {this.state.accType === "Company" ? "*" : null}</label>
                                                    <input
                                                        type="text"
                                                        name="facebook"
                                                        id="facebook"
                                                        value={this.props.state.dataToSend.facebook}
                                                        onChange={this.simpleInputChangeHandler}
                                                    />
                                                </div>
                                                {
                                                    this.state.error.facebook !== null ?
                                                        <Fade enter={true} exit={true}>
                                                            <p className="registration_failure_para">{this.state.error.facebook}</p>
                                                        </Fade>
                                                        : null
                                                }
                                            </div> */}
                                            <div className="acc_type_row">
                                                <div className="input_group" style={{ paddingTop: "0px" }}>
                                                    <label htmlFor="website">Website Link {this.state.accType === "Company" ? "*" : null}</label>
                                                    <input
                                                        type="text"
                                                        name="website"
                                                        id="website"
                                                        value={this.state.website}
                                                        onChange={this.simpleInputChangeHandler}
                                                    />
                                                </div>
                                                {
                                                    this.state.error.website !== null ?
                                                        <Fade enter={true} exit={true}>
                                                            <p className="registration_failure_para">{this.state.error.website}</p>
                                                        </Fade>
                                                        : null
                                                }
                                            </div>
                                            {this.state.accType === "Company" && 
                                                <div className="acc_type_row">
                                                    <div className="input_group" style={{ paddingTop: "0px" }}>
                                                        <label htmlFor="company">Company Name *</label>
                                                        <input
                                                            type="text"
                                                            name="company"
                                                            id="company"
                                                            value={this.state.company}
                                                            onChange={this.simpleInputChangeHandler}
                                                        />
                                                    </div>
                                                    {
                                                        this.state.error.company !== null ?
                                                            <Fade enter={true} exit={true}>
                                                                <p className="registration_failure_para">{this.state.error.company}</p>
                                                            </Fade>
                                                            : null
                                                    }
                                                </div>
                                            }
                                            <div className="acc_type_row">
                                                <div className="input_group" style={{ paddingTop: "0px" }}>
                                                    <label htmlFor="website">Instant messenger *</label>
                                                    <div className="instant-messenger">
                                                        <select className="instant-messenger-select" name="instant_messenger" id="instant_messenger" onChange={this.simpleInputChangeHandler} value={this.state.instant_messenger}>
                                                            <option value="Skype">Skype</option>
                                                            <option value="Telegram">Telegram</option>
                                                            <option value="Whatsapp">Whatsapp</option>
                                                        </select>
                                                        <input
                                                            type="text"
                                                            name="instant_messenger_number"
                                                            id="instant_messenger_number"
                                                            value={this.state.instant_messenger_number}
                                                            onChange={this.simpleInputChangeHandler}
                                                        />
                                                    </div>
                                                </div>
                                                {
                                                    this.state.error.instant_messenger_number !== null ?
                                                        <Fade enter={true} exit={true}>
                                                            <p className="registration_failure_para">{this.state.error.instant_messenger_number}</p>
                                                        </Fade>
                                                        : null
                                                }
                                            </div>

                                        </div>
                                        <div className="acc_type_row">
                                            {/* <label className="signup_label" htmlFor="address">Address</label> */}
                                            <div className="address_con">
                                                <div className="input_group">
                                                    <label htmlFor="address">Street Address *</label>
                                                    <input
                                                        type="text"
                                                        name="address"
                                                        id="address"
                                                        value={this.state.address}
                                                        onChange={this.simpleInputChangeHandler}
                                                    />
                                                </div>
                                                <div className="input_group">
                                                    <label htmlFor="city">City *</label>
                                                    <input
                                                        type="text"
                                                        name="city"
                                                        id="city"
                                                        value={this.state.city}
                                                        onChange={this.simpleInputChangeHandler}
                                                    />
                                                </div>
                                                <div className="input_group">
                                                    <label htmlFor="state">Region / State / Province *</label>
                                                    <input
                                                        type="text"
                                                        name="state"
                                                        id="state"
                                                        value={this.state.region}
                                                        onChange={this.simpleInputChangeHandler}
                                                    />
                                                </div>
                                                <div className="input_group">
                                                    <label htmlFor="country">Select Country *</label>
                                                    <CountryDropdown
                                                        className="country_dropdown"
                                                        id="country"
                                                        name="country"
                                                        value={this.state.country}
                                                        onChange={this.selectCountry}
                                                    />
                                                </div>
                                                <div className="input_group">
                                                    <label htmlFor="zipCode">Zip Code / Postal Code *</label>
                                                    <input
                                                        type="text"
                                                        name="zipCode"
                                                        id="zipCode"
                                                        value={this.state.zipCode}
                                                        onChange={this.simpleInputChangeHandler}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                this.state.error.address !== null ?
                                                    <Fade enter={true} exit={true}>
                                                        <p className="registration_failure_para">{this.state.error.address}</p>
                                                    </Fade>
                                                    : null
                                            }
                                        </div>
                                        <div className="acc_type_row">
                                            <div className="address_con">
                                                <div className="input_group_phone">
                                                    <label htmlFor="phone">Phone Number *</label>
                                                    <PhoneInput
                                                        country={'us'}
                                                        value={this.state.phone}
                                                        onChange={phone => this.setState({ phone: phone })}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                this.state.error.phone !== null ?
                                                    <Fade enter={true} exit={true}>
                                                        <p className="registration_failure_para">{this.state.error.phone}</p>
                                                    </Fade>
                                                    : null
                                            }
                                        </div>
                                    </fieldset>
                                    <div className="k-mt-5">
                                    <Button
                                        className="edit_button gray_button_bg_imp theme_dark_blue_color profile_form_btn"
                                        onClick={this.props.closeForm}
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        style={{     marginLeft: "10px", borderRadius: "4px" }}
                                        className="k-button theme_dark_bg_color_imp color_white_imp theme_dark_bg_color save_button"
                                        onClick={this.handleSubmit}
                                    >
                                        Save
                                    </Button>
                                        
                                    </div>
                                    
                                </FormElement>
                            )}
                            />
                    </div>
            
        );
    }
}

export default ProfileForm;