import React from "react";
import produce from "immer";

class ReportGroupingDropdowns extends React.Component {
  state = {
    allValues: [
      "affilateNetwork",
      "brand",
      "browser",
      "browserVersions",
      "campaign",
      "city",
      "connectionType",
      "conversion",
      "country",
      "days",
      "deviceType",
      "flow",
      "hours",
      "isp",
      "lander",
      "language",
      "mobileCarrier",
      "models",
      "months",
      "offers",
      "os",
      "osVersions",
      "proxy",
      "region",
      "timeZone",
      "trafficSource",
      "weekday",
    ],
    groupingValues: ["", "", ""],
    selectOptions: {
      filter2: [],
      filter3: [],
      filter4: [],
    },
  };

  componentDidMount() {
    this.setState(
      produce((draft) => {
        draft.groupingValues[0] = this.props.getGroupingValues.filter2;
        draft.groupingValues[1] = this.props.getGroupingValues.filter3;
        draft.groupingValues[2] = this.props.getGroupingValues.filter4;
      })
    );
    setTimeout(() => {
      this.setSelectOptions();
    }, 40);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.getGroupingValues !== this.props.getGroupingValues) {
      this.setState(
        produce((draft) => {
          draft.groupingValues[0] = this.props.getGroupingValues.filter2;
          draft.groupingValues[1] = this.props.getGroupingValues.filter3;
          draft.groupingValues[2] = this.props.getGroupingValues.filter4;
        })
      );
      setTimeout(() => {
        this.setSelectOptions();
      }, 40);
    }

    // set the dynamic grouping list value
    if (
      prevProps.fullGroupingList !== this.props.fullGroupingList &&
      this.props.fullGroupingList.length > 0
    ) {
      this.setState(
        produce((draft) => {
          draft.allValues = this.props.fullGroupingList;
        })
      );
      setTimeout(() => {
        this.setSelectOptions();
      }, 40);
    }
  }

  setSelectOptions = () => {
    let filter2 = [];
    let filter3 = [];
    let filter4 = [];
    for (let j = 0; j < this.state.allValues.length; j++) {
      if (this.state.groupingValues[0] === this.state.allValues[j]) {
        // console.log("this.state.allValues[j]: filter2 ", this.state.allValues[j]);
        filter2.push(this.state.allValues[j]);
      } else if (this.state.groupingValues[1] === this.state.allValues[j]) {
        // console.log("this.state.allValues[j]: filter3 ", this.state.allValues[j]);
        filter3.push(this.state.allValues[j]);
      } else if (this.state.groupingValues[2] === this.state.allValues[j]) {
        // console.log("this.state.allValues[j]: filter4 ", this.state.allValues[j]);
        filter4.push(this.state.allValues[j]);
      } else {
        if (
          this.state.allValues[j] === "hours" ||
          this.state.allValues[j] === "months" ||
          this.state.allValues[j] === "days" ||
          this.state.allValues[j] === "conversion"
        ) {
          filter2.push(this.state.allValues[j]);
        } else {
          filter2.push(this.state.allValues[j]);
          filter3.push(this.state.allValues[j]);
          filter4.push(this.state.allValues[j]);
        }
      }
    }
    this.setState(
      produce((draft) => {
        draft.selectOptions.filter2 = filter2;
        draft.selectOptions.filter3 = filter3;
        draft.selectOptions.filter4 = filter4;
      })
    );
  };

  selectChangeHandler = (e) => {
    const index = Number(e.target.id.split("_")[1]);
    this.setState(
      produce((draft) => {
        draft.groupingValues[index] = e.target.value;
      })
    );
    setTimeout(() => {
      this.setSelectOptions();
      this.props.changeSearchValues(this.state.groupingValues, "grouping");
    }, 50);
  };

  render() {
    return (
      <>
        <div className="grouping_tags">
          <select
            name="filter2"
            id="filter2_0"
            value={this.props.getGroupingValues.filter2}
            onChange={this.selectChangeHandler}
          >
            <option value="">Select Grouping</option>
            {this.state.selectOptions.filter2.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="grouping_tags">
          <select
            name="filter3"
            id="filter3_1"
            value={this.props.getGroupingValues.filter3}
            onChange={this.selectChangeHandler}
            disabled={this.props.disable.filter3}
          >
            <option value="">Select Grouping</option>
            {this.state.selectOptions.filter3.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="grouping_tags">
          <select
            name="filter4"
            id="filter4_2"
            value={this.props.getGroupingValues.filter4}
            onChange={this.selectChangeHandler}
            disabled={this.props.disable.filter4}
          >
            <option value="">Select Grouping</option>
            {this.state.selectOptions.filter4.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  }
}

export default ReportGroupingDropdowns;
