import { call, put, takeLatest } from "redux-saga/effects";
import { loadDashboardChartsDataSucess,loadDashboardChartsDataFailed,loadDashboardDataFailed,loadDashboardDataSucess,loadDashboardSummaryFailed,loadDashboardSummarySucess } from "./actions";
import { LOAD_DASHBOARD_CHART_DATA,LOAD_DASHBOARD_DATA,LOAD_DASHBOARD_SUMMARY } from "./constants";

import{loadDashboardChartsDataApi,loadDashboardDataApi, loadDashboardSummaryApi} from "./api";


function* loadDashboardSummary(payload) {
    try {
        const response = yield call(loadDashboardSummaryApi, payload);
        yield put(
            loadDashboardSummarySucess(response.data)
        );
    } catch (error) {
        yield put(loadDashboardSummaryFailed(error));
    }
}

function* loadDashboardData(payload) {
    try {
        const response = yield call(loadDashboardDataApi, payload);
        yield put(
            loadDashboardDataSucess(response.data)
        );
    } catch (error) {
        yield put(loadDashboardDataFailed(error));
    }
}

function* loadDashboardChartsData(payload) {
    try {
        const response = yield call(loadDashboardChartsDataApi, payload);
        yield put(
            loadDashboardChartsDataSucess(response.data)
        );
    } catch (error) {
        yield put(loadDashboardChartsDataFailed(error));
    }
}

export default function* DashboardSaga() {
    yield takeLatest(LOAD_DASHBOARD_CHART_DATA, loadDashboardChartsData);
    yield takeLatest(LOAD_DASHBOARD_DATA, loadDashboardData);
    yield takeLatest(LOAD_DASHBOARD_SUMMARY, loadDashboardSummary);
}