// import { Button } from '@progress/kendo-react-buttons';
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
// import { Field, Form, FormElement } from '@progress/kendo-react-form';
import React, { Component } from 'react';
// import LoadingPanel from '../../../grid/loader/loader';
import produce from 'immer';
// import { Error } from '@progress/kendo-react-labels';
import {makeSelectRegistered, makeSelectToken} from '../../../../registration/LoginRegister/selectors';
import { makeSelectAllUsersData, makeSelectIsAllUsersDataLoaded,makeSelectIsUsersDataAdded,makeSelectUsersDataError,makeSelectUserUpdated } from '../selectors';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import * as userActions from "../actions";
import {formatDate} from "../../../../components/searchParameters";
import ModalForm from "./ModalForm";
import * as loginRegisterActions from "../../../../registration/LoginRegister/actions";

class AddNewUserModal extends Component {
    state = {
        loader: false,
        visible: false,
        dataToSend: {
            // showBirthday: formatDate(new Date(2000, 0, 1)),
            // showCalendar: false,
            firstname: "",
            lastname:"",
            email: "",
            password: "",
            confirmPassword: "",
            accType: "Individual",
            link: "",
            company: "",
            instant_messenger:"Skype",
            instant_messenger_number:"",
            website: "",
            address: "",
            city: "",
            state: "",
            signupCountry: "",
            zipCode: "",
        },
        // birthday: new Date(2000, 0, 1),
        emailValidation: true,
        error: {
            firstname: null,
            lastname: null,
            email: null,
            password: null,
            confirmPassword: null,
            // birthday: null,
            accType: null,
            link: null,
            company:null,
            instant_messenger_number:null,
            website: null,
            address: null,
            city: null,
            state: null,
            signupCountry: null,
            zipCode: null,
            signup: null,
            phone: null
        },
        phone: null,
        acc_Type: [
            {
                label: "Individual",
                value: "Individual",
            },
            {
                label: "Company",
                value: "Company",
            },
        ]
    }

    componentDidUpdate(prevProps){
        // console.log("this.props.isRegistered: ", this.props.isRegistered);
        // if ((prevProps.isRegistered !== this.props.isRegistered) && this.props.isRegistered === true) {
        //     console.log("Added successfully...")
        //     this.props.userAddedSuccessfully();
        // }

        // if ((prevProps.isUserAdded !== this.props.isUserAdded) && this.props.isUserAdded === true) {
        //     this.toggleLoader();
        //     this.setState(produce(draft => { draft.error.success = "You have successfully added the user - You can now change their status in the table below."; }));
        //     setTimeout(() => {
        //         this.setState(produce(draft => { draft.error.success = null; }));
        //     },10000);
        // }

        // console.log("error: ", this.props.error);
        if ((prevProps.error !== this.props.error) && this.props.error !== false) {
            // console.log("inside..");
            this.props.closeLoader();
            this.setState(produce(draft => { draft.error.signup = this.props.error ? this.props.error.message : null; }));
            setTimeout(() => {
                this.setState(produce(draft => { draft.error.signup = null; }));
            },10000);
        }
    }

    toggleDialog = () => {
        this.setState(produce(draft => { draft.visible = !this.state.visible; }))
        // this.props.closeVisibleEditUserModal();
    };

    handleSubmit = () => {
        this.props.toggleLoader();
        this.checkRegistrationFormErrors();
        setTimeout(() => {
            if (this.state.error.accType === null && this.state.error.confirmPassword === null && this.state.error.firstname === null && this.state.error.lastname === null && this.state.error.address === null  && this.state.error.email === null && this.state.error.company === null && this.state.error.link === null && this.state.error.password === null && this.state.error.phone === null && this.state.error.website === null && this.state.error.instant_messenger_number === null) {
                this.props.addUser(this.props.accessToken,{
                    firstname: this.state.dataToSend.firstname,
                    lastname: this.state.dataToSend.lastname,
                    email: this.state.dataToSend.email,
                    password: this.state.dataToSend.password,
                    // dob: this.state.dataToSend.showBirthday,
                    accountType: this.state.dataToSend.accType,
                    link: this.state.dataToSend.link,
                    company: this.state.dataToSend.company,
                    instant_messenger:this.state.dataToSend.instant_messenger,
                    instant_messenger_number:this.state.dataToSend.instant_messenger_number,
                    website: this.state.dataToSend.website,
                    address: this.state.dataToSend.address,
                    city: this.state.dataToSend.city,
                    region: this.state.dataToSend.state,
                    zipCode: this.state.dataToSend.zipCode,
                    country: this.state.dataToSend.signupCountry,
                    phoneNumber: this.state.phone
                });
            } else {
                this.props.toggleLoader();
            }
        },200);
    };

    handleBirthdayChange = (e) => {
        // this.setState({
        //     ,
        //     showBirthday: formatDate(e.value)
        // });
        this.setState(produce(draft => {
            draft.dataToSend.showBirthday = formatDate(e.value);
            draft.birthday = e.value;
        }));
    }
    toggleCalendar = () => {
        this.setState({ showCalendar: !this.state.showCalendar });
    }
    selectCountry               = (v) => { this.setState(produce(draft => { draft.dataToSend.signupCountry = v; })); }
    radioChangeHandler          = (e) => { this.setState(produce(draft => { draft.dataToSend.accType = e.value; })); }
    simpleInputChangeHandler    = (e) => {
        this.setState(produce(draft => {
            draft.dataToSend[e.target.id] = e.target.value;
        }));
        if (e.target.id === "password" && e.target.value.length < 8) {
            this.setState(produce(draft => { draft.error.password = "Password must be a minimum of 8 Characters."; }));
        } else { this.setState(produce(draft => { draft.error.password = null; })); }
    }

    checkRegistrationFormErrors = () => {
        // console.log("Checking errors");
        if (this.state.dataToSend.firstname === "") {
            this.setState(produce(draft => { draft.error.firstname = "This field is required"; }));
        } else { this.setState(produce(draft => { draft.error.firstname = null; })); }

        if (this.state.dataToSend.lastname === "") {
            this.setState(produce(draft => { draft.error.lastname = "This field is required"; }));
        } else { this.setState(produce(draft => { draft.error.lastname = null; })); }

        if (this.state.dataToSend.email === "") {
            this.setState(produce(draft => { draft.error.email = "This field is required"; }));
        } else {
            let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

            if (!pattern.test(this.state.dataToSend.email)) {
                this.setState(produce(draft => { draft.error.email = "Please enter valid email address."; }));
            } else { this.setState(produce(draft => { draft.error.email = null; })); }
        }

        if (this.state.dataToSend.password === "") {
            this.setState(produce(draft => { draft.error.password = "This field is required" }));
        } else {
            if (this.state.dataToSend.password.length < 8) {
                this.setState(produce(draft => { draft.error.password = "Password must be a minimum of 8 Characters."; }));
            } else { this.setState(produce(draft => { draft.error.password = null })); }
        }

        if (this.state.dataToSend.confirmPassword === "") {
            this.setState(produce(draft => { draft.error.confirmPassword = "This field is required" }));
        } else {
            if (this.state.dataToSend.confirmPassword !== this.state.dataToSend.password) {
                this.setState(produce(draft => { draft.error.confirmPassword = "Passwords do not match. Please re-enter the passwords."; }));
            } else { this.setState(produce(draft => { draft.error.confirmPassword = null })); }
        }

        if (this.state.dataToSend.accType === "Company") {
            if (this.state.dataToSend.link === "") {
                this.setState(produce(draft => { draft.error.link = "Linkedin or Facebook Profile link is required." }));
            } else { this.setState(produce(draft => { draft.error.link = null })); }
            if (this.state.dataToSend.company === "") {
                this.setState(produce(draft => { draft.error.company = "Company name is required." }));
            } else { this.setState(produce(draft => { draft.error.company = null })); }
            if (this.state.dataToSend.website === "") {
                this.setState(produce(draft => { draft.error.website = "Website link is required." }));
            } else { this.setState(produce(draft => { draft.error.website = null })); }
        } else {
            this.setState(produce(draft => {
                draft.error.linkedin = null;
                draft.error.facebook = null;
                draft.error.website = null;
            }));
        }

        if (this.state.dataToSend.city === "" || this.state.dataToSend.country === "" || this.state.dataToSend.state === "" || this.state.dataToSend.country === "" || this.state.dataToSend.zipCode === "") {
            this.setState(produce(draft => { draft.error.address = "You must enter the complete address. It includes Street Address, City, State, Country, and Zip Code/Postal Code." }));
        } else { this.setState(produce(draft => { draft.error.address = null })); }

        if (this.state.phone === "" || this.state.phone === null) {
            this.setState(produce(draft => { draft.error.phone = "Phone number is required." }));
        } else { this.setState(produce(draft => { draft.error.phone = null })); }

        if (this.state.dataToSend.instant_messenger_number === "" || this.state.dataToSend.instant_messenger_number === null) {
            this.setState(produce(draft => { draft.error.instant_messenger_number = "Messenger number is required." }));
        } else { this.setState(produce(draft => { draft.error.instant_messenger_number = null })); }
    }
    phoneChangeHandler = phone => this.setState({ phone: phone });
    radioGroupValidator = (value) => !value ? "Account Type is required" : "";
    render() {
        
        return (
            <div className="add_user_modal">
                <Dialog className="add_affiliate_networks_dialogue" title={"Add new user"} onClose={() => this.props.closeAddUserModal()}>
                    <div className="add_affiliate_network_dialogue_content">
                        <div className="content_form">
                            {this.state.showCalendar ? <div className="black_bg_signup" onClick={this.toggleCalendar}></div> : null }
                            <ModalForm
                                state={this.state}
                                simpleInputChangeHandler={this.simpleInputChangeHandler}
                                selectCountry={this.selectCountry}
                                radioChangeHandler={this.radioChangeHandler}
                                toggleCalendar={this.toggleCalendar}
                                radioGroupValidator={this.radioGroupValidator}
                                handleBirthdayChange={this.handleBirthdayChange}
                                phoneChangeHandler={this.phoneChangeHandler}
                            />
                        </div>
                    </div>
                    <DialogActionsBar>
                        <div className="action_bar_buttons">
                            <div className="left">
                                <button onClick={() => this.props.closeAddUserModal("")} className="normal_btn cancel_button gray_button_bg_imp black border_radius_normal_btn normal_text black no_border">Close</button>
                            </div>
                            <div className="right">
                                <button
                                    onClick={this.handleSubmit}
                                    className="normal_btn theme_dark_btn theme_dark_bg_color_imp color_white_imp border_radius_normal_btn save_button normal_text"
                                    disabled={this.state.disabled}
                                >Add user</button>
                            </div>
                        </div>
                    </DialogActionsBar>
                </Dialog>
            </div>
        );
    }
}

export const mapStateToProps = createStructuredSelector({
    accessToken: makeSelectToken(),
    allUsersDataLoaded: makeSelectAllUsersData(),
    isAllUsersDataLoaded: makeSelectIsAllUsersDataLoaded(),
    isUserAdded:makeSelectIsUsersDataAdded(),
    error: makeSelectUsersDataError(),
    userUpdate: makeSelectUserUpdated(),
    isRegistered: makeSelectRegistered(),
});

export function mapDispatchToProps(dispatch) {
    return {
        fetchingUsersData: (...payload) => dispatch(userActions.loadAllUsers(...payload)),
        addUser: (...payload) => dispatch(userActions.addUsers(...payload)),
        updateUser:(...payload) => dispatch(userActions.updateUsers(...payload)),
        signUpUser: (...payload) => dispatch(loginRegisterActions.signUpUser(...payload)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewUserModal);