import { produce } from "immer";
import {
    ADD_FLOWS,ADD_FLOWS_FAILED,ADD_FLOWS_SUCCESS,
    LOAD_FLOWS,LOAD_FLOWS_FAILED,LOAD_FLOWS_SUCCESS,
    EDIT_FLOWS,EDIT_FLOWS_FAILED,EDIT_FLOWS_SUCCESS,
    DELETE_FLOWS,DELETE_FLOWS_FAILED,DELETE_FLOWS_SUCCESS,
    ARCHIVE_FLOWS,ARCHIVE_FLOWS_FAILED,ARCHIVE_FLOWS_SUCCESS,
    LOAD_COUNTRIES,LOAD_COUNTRIES_FAILED,LOAD_COUNTRIES_SUCCESS,
    UPDATE_FLOWS,UPDATE_FLOWS_SUCCESS,UPDATE_FLOWS_FAILED,DELETE_DEFAULT_PATH,
    DELETE_DEFAULT_PATH_SUCCESS,DELETE_DEFAULT_PATH_FAILED,
    DELETE_RULE_PATH,DELETE_RULE_PATH_SUCCESS,DELETE_RULE_PATH_FAILED,
    DELETE_RULE,DELETE_RULE_SUCCESS,DELETE_RULE_FAILED,
    GET_FLOW_CHARTS_DATA, GET_FLOW_CHARTS_DATA_SUCCESS, GET_FLOW_CHARTS_DATA_FAILED,

    LOAD_BRAND,LOAD_BRAND_SUCCESS,LOAD_BRAND_FAILED,
    LOAD_BROWSER,LOAD_BROWSER_SUCCESS,LOAD_BROWSER_FAILED,
    LOAD_CONNECTION_TYPE,LOAD_CONNECTION_TYPE_SUCCESS,LOAD_CONNECTION_TYPE_FAILED,
    LOAD_DEVICE_TYPE,LOAD_DEVICE_TYPE_SUCCESS,LOAD_DEVICE_TYPE_FAILED,
    LOAD_CITY,LOAD_CITY_FAILED,LOAD_CITY_SUCCESS,
    LOAD_ALL_CITY,LOAD_ALL_CITY_FAILED,LOAD_ALL_CITY_SUCCESS,
    LOAD_REGION,LOAD_REGION_FAILED,LOAD_REGION_SUCCESS,
    LOAD_PROXY,LOAD_PROXY_FAILED,LOAD_PROXY_SUCCESS,
    LOAD_LANGUAGE,LOAD_LANGUAGE_FAILED,LOAD_LANGUAGE_SUCCESS,
    LOAD_OPERATING_SYSTEM,LOAD_OPERATING_SYSTEM_SUCCESS,LOAD_OPERATING_SYSTEM_FAILED,
    LOAD_ISP,LOAD_ISP_SUCCESS,LOAD_ISP_FAILED,
    LOAD_ALL_ISP,LOAD_ALL_ISP_SUCCESS,LOAD_ALL_ISP_FAILED,
    LOAD_CONDITION_COUNTRIES,LOAD_CONDITION_COUNTRIES_FAILED,LOAD_CONDITION_COUNTRIES_SUCCESS,
    LOAD_WEEK_DAY,LOAD_WEEK_DAY_SUCCESS,LOAD_WEEK_DAY_FAILED,
    LOAD_MOBILE_CARRIER,LOAD_MOBILE_CARRIER_SUCCESS,LOAD_MOBILE_CARRIER_FAILED,
    // ADD_BRAND,
    // ADD_BRAND_SUCCESS,
    // ADD_BRAND_FAILED,
    // ADD_BROWSER,
    // ADD_BROWSER_SUCCESS,
    // ADD_BROWSER_FAILED,
    // ADD_CONNECTION_TYPE,
    // ADD_CONNECTION_TYPE_SUCCESS,
    // ADD_CONNECTION_TYPE_FAILED,
    // ADD_DEVICE_TYPE,
    // ADD_DEVICE_TYPE_SUCCESS,
    // ADD_DEVICE_TYPE_FAILED,
    // ADD_CITY,
    // ADD_CITY_FAILED,
    // ADD_CITY_SUCCESS,
    // ADD_REGION,
    // ADD_REGION_FAILED,
    // ADD_REGION_SUCCESS,
    // ADD_PROXY,
    // ADD_PROXY_FAILED,
    // ADD_PROXY_SUCCESS,
    // ADD_LANGUAGE,
    // ADD_LANGUAGE_FAILED,
    // ADD_LANGUAGE_SUCCESS,
    // ADD_OPERATING_SYSTEM,
    // ADD_OPERATING_SYSTEM_SUCCESS,
    // ADD_OPERATING_SYSTEM_FAILED,
} from "./constants";

export const initialState = {
    loading: false,
    success: false,
    error: false,
    countriesLoaded: false,
    countryData: null,
    flowAdded: false,
    laodFlow: false,
    editFlow: false,
    flowEdited: false,
    deleteFlow: false,
    flowDeleted:false,
    archiveFlow: false,
    flowArchived:false,
    flowName: null,
    workspaceId: null,
    countryId: null,
    flowAdding: false,
    flowData: null,
    flowsLoaded: false,
    flowEditData: null,
    updateFlow: false,
    charts: {
        isChartsDataLoaded: false,
        allChartsDataLoaded: null
    },

    brandData: null,
    brandDataLoaded: false,
    cityData: null,
    cityDataLoaded: false,
    mcData: null,
    mcDataLoaded: false,
    allCityData: null,
    isAllCityDataLoaded: false,
    conditionCountryData: null,
    conditionCountryDataLoaded:false,
    browserData: null,
    browserDataLoaded: false,
    deviceTypeData: null,
    deviceTypeDataLoaded: false,
    connectionTypeData: null,
    connectionTypeDataLoaded: false,
    languageData: null,
    languageDataLoaded: false,
    operatingSystemData: null,
    operatingSystemDataLoaded: false,
    proxyData: null,
    proxyDataLoaded: false,
    regionData: null,
    regionDataLoaded: false,
    ispData: null,
    ispDataLoaded: false,
    ispAllData: null,
    ispAllDataLoaded: false,
    weekdayData: null,
    isWeekDataDataLoaded: false,
    archiveDeleteResponseFlows: null,
};

const newFlowsReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case LOAD_COUNTRIES:
                draft.loading = true;
                draft.error = false;
                draft.success = false;
                draft.countriesLoaded = false;
                return draft;
            case LOAD_COUNTRIES_SUCCESS:
                draft.loading = false;
                draft.success = true;
                draft.countriesLoaded = true;
                draft.countryData = action.payload.countriesData.countries;
                return draft;
            case LOAD_COUNTRIES_FAILED:
                //console.log("loading country failed");
                draft.loading = false;
                draft.error = true;
                draft.success = false;
                draft.countriesLoaded = false;
                return draft;
            case ADD_FLOWS:
                // console.log('flow is adding......');
                draft.flowAdding = true;
                draft.error = false;
                draft.flowAdded = false;
                draft.success = false;
                return draft;
            case ADD_FLOWS_SUCCESS:
                // console.log('flow successfully added...');
                draft.flowAdded = true;
                draft.success = true;
                draft.flowAdding = false;
                draft.error = false;
                return draft;
            case ADD_FLOWS_FAILED:
                // console.log('flow failed to add...');
                draft.flowAdded = false;
                draft.success = false;
                draft.flowAdding = false;
                draft.error = action.payload.error.response.data;
                return draft;
            case LOAD_FLOWS:
                // console.log('loading flows from api...');
                draft.loading = true;
                draft.error = false;
                draft.success = false;
                draft.flowsLoaded = false;
                draft.flowAdded = false;
                draft.archiveFlow = false;
                draft.updateFlow = false;
                draft.deleteFlow = false;
                return draft;
            case LOAD_FLOWS_SUCCESS:
                // console.log('flows loaded successfully...');
                // console.log("data", action.payload.flowsDataRecieved.flowsData);
                draft.flowData = action.payload.flowsDataRecieved.flowsData;
                draft.loading = false;
                draft.error = false;
                draft.flowAdded = false;
                draft.deleteFlow = false;
                draft.archiveFlow = false;
                draft.updateFlow = false;
                draft.success = true;
                draft.flowsLoaded = true;
                return draft;
            case LOAD_FLOWS_FAILED:
                //console.log("flows failed loading...");
                draft.loading = false;
                draft.error = action.payload.error.response.data;
                draft.success = false;
                draft.flowAdded = false;
                draft.deleteFlow = false;
                draft.archiveFlow = false;
                draft.updateFlow = false;
                draft.flowsLoaded = false;
                return draft;
            case EDIT_FLOWS:
                draft.loading = true;
                draft.error = false;
                draft.editFlow = false;
                return draft;
            case EDIT_FLOWS_SUCCESS:
                //console.log('success', action.payload.editFlowData.data);
                draft.flowEditData = action.payload.editFlowData.data;
                draft.editFlow = true;
                draft.error = false;
                draft.loading = false;
                draft.success = true;
                return draft;
            case EDIT_FLOWS_FAILED:
                draft.flowEditData = null;
                draft.editFlow = false;
                draft.error = action.payload.error.response.data;
                draft.loading = false;
                draft.success = false;
                return draft;
            case DELETE_FLOWS:
                draft.loading = true;
                draft.error = false;
                draft.deleteFlow = false;
                draft.success = false;
                draft.archiveDeleteResponseFlows = null;
                return draft;
            case DELETE_FLOWS_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.deleteFlow = true;
                draft.success = true;
                draft.archiveDeleteResponseFlows = action.payload.deleteResponse.deleteData.data;
                return draft;
            case DELETE_FLOWS_FAILED:
                draft.loading = false;
                draft.success = false;
                draft.error = action.payload.error.response.data;
                draft.archiveDeleteResponseFlows = null;
                draft.deleteFlow = false;
                return draft;
            case ARCHIVE_FLOWS:
                draft.loading = true;
                draft.error = false;
                draft.archiveFlow = false;
                draft.success = false;
                draft.archiveDeleteResponseFlows = null;
                return draft;
            case ARCHIVE_FLOWS_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.archiveFlow = true;
                draft.success = true;
                draft.archiveDeleteResponseFlows = action.payload.archiveFlowResponse.archiveData.data;
                return draft;
            case ARCHIVE_FLOWS_FAILED:
                draft.loading = false;
                draft.error = action.payload.error.response.data;
                draft.archiveFlow = false;
                draft.success = false;
                draft.archiveDeleteResponseFlows = null;
                return draft;
            case UPDATE_FLOWS:
                // console.log('updating flows....');
                draft.loading = true;
                draft.error = false;
                draft.updateFlow = false;
                return draft;
            case UPDATE_FLOWS_SUCCESS:
                // console.log('updating flow success...');
                draft.loading = false;
                draft.error = false;
                draft.success = true;
                draft.updateFlow = true;
                return draft;
            case UPDATE_FLOWS_FAILED:
                // console.log('updating flow failed...');
                draft.loading = false;
                draft.error = action.payload.error.response.data;
                draft.success = false;
                draft.updateFlow = false;
                return draft;
                case DELETE_DEFAULT_PATH:
                    draft.loading = true;
                    draft.error = false;
                    //draft.deleteFlow = false;
                    draft.success = false;
                    return draft;
                case DELETE_DEFAULT_PATH_SUCCESS:
                    draft.loading = false;
                    draft.error = false;
                    //draft.deleteFlow = true;
                    draft.success = true;
                    return draft;
                case DELETE_DEFAULT_PATH_FAILED:
                    draft.loading = false;
                    draft.error = true;
                    //draft.deleteFlow = false;
                    draft.success = false;
                return draft;
                case DELETE_RULE_PATH:
                    draft.loading = true;
                    draft.error = false;
                    //draft.deleteFlow = false;
                    draft.success = false;
                    return draft;
                case DELETE_RULE_PATH_SUCCESS:
                    draft.loading = false;
                    draft.error = false;
                    //draft.deleteFlow = true;
                    draft.success = true;
                    return draft;
                case DELETE_RULE_PATH_FAILED:
                    draft.loading = false;
                    draft.error = true;
                    //draft.deleteFlow = false;
                    draft.success = false;
                return draft;
            
                case DELETE_RULE:
                    draft.loading = true;
                    draft.error = false;
                    //draft.deleteFlow = false;
                    draft.success = false;
                    return draft;
                case DELETE_RULE_SUCCESS:
                    draft.loading = false;
                    draft.error = false;
                    //draft.deleteFlow = true;
                    draft.success = true;
                    return draft;
                case DELETE_RULE_FAILED:
                    draft.loading = false;
                    draft.error = true;
                    //draft.deleteFlow = false;
                    draft.success = false;
                return draft;
                case GET_FLOW_CHARTS_DATA:
                    draft.loading = true;
                    draft.success = false;
                    draft.error = false;
                    return draft;
                case GET_FLOW_CHARTS_DATA_SUCCESS:
                    draft.loading = false;
                    draft.success = true;
                    draft.error = false;
                    draft.charts.isChartsDataLoaded = true;
                    draft.charts.allChartsDataLoaded = action.payload;
                    return draft;
                case GET_FLOW_CHARTS_DATA_FAILED:
                    draft.loading = false;
                    draft.success = false;
                    draft.charts.isChartsDataLoaded = false;
                    draft.error = action.payload;
                    return draft;
//---------------------------------------------------------------------------------------------------
//conditions reducer            
                case LOAD_ISP:
                    draft.loading = true;
                    draft.error = false;
                    draft.success = false;
                    return draft;
                case LOAD_ISP_SUCCESS:
                    draft.loading = false;
                    draft.error = false;
                    draft.success = true;
                    draft.ispData = action.payload.data.data.data;
                    draft.ispDataLoaded = true;
                    return draft;
                case LOAD_ISP_FAILED:
                    draft.loading = false;
                    draft.error = true;
                    draft.success = false;
                    draft.ispDataLoaded = false;
                return draft;
            
                case LOAD_ALL_ISP:
                    draft.loading = true;
                    draft.error = false;
                    draft.success = false;
                    return draft;
                case LOAD_ALL_ISP_SUCCESS:
                    draft.loading = false;
                    draft.error = false;
                    draft.success = true;
                    draft.ispAllData = action.payload.data.data.data;
                    draft.ispAllDataLoaded = true;
                    return draft;
                case LOAD_ALL_ISP_FAILED:
                    draft.loading = false;
                    draft.error = true;
                    draft.success = false;
                    draft.ispAllDataLoaded = false;
                    return draft;
            
            case LOAD_BRAND:
                draft.loading = true;
                draft.error = false;
                draft.success = false;
                return draft;
            case LOAD_BRAND_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.success = true;
                draft.brandData = action.payload.data.data.data;
                draft.brandDataLoaded = true;
                return draft;
            case LOAD_BRAND_FAILED:
                draft.loading = false;
                draft.error = true;
                draft.success = false;
                draft.brandDataLoaded = false;
                return draft;

            case LOAD_BROWSER:
                draft.loading = true;
                draft.error = false;
                draft.success = false;
                return draft;
            case LOAD_BROWSER_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.success = true;
                draft.browserDataLoaded = true;
                draft.browserData = action.payload.data.data.data;
                return draft;
            case LOAD_BROWSER_FAILED:
                draft.loading = false;
                draft.error = true;
                draft.success = false;
                draft.browserDataLoaded = false;
                return draft;
            case LOAD_LANGUAGE:
                //console.log('loading language');
                draft.loading = true;
                draft.error = false;
                draft.success = false;
                return draft;
            case LOAD_LANGUAGE_SUCCESS:
                //console.log('loading language success');
                draft.loading = false;
                draft.error = false;
                draft.success = true;
                draft.languageDataLoaded = true;
                draft.languageData = action.payload.data.data.data;
                return draft;
            case LOAD_LANGUAGE_FAILED:
                //console.log('loading language failed');
                draft.loading = false;
                draft.error = true;
                draft.success = false;
                draft.languageDataLoaded = false;
                return draft;

            case LOAD_CITY:
                draft.loading = true;
                draft.error = false;
                draft.success = false;
                return draft;
            case LOAD_CITY_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.success = true;
                draft.cityDataLoaded = true;
                draft.cityData = action.payload.data.data.data;
                return draft;
            case LOAD_CITY_FAILED:
                draft.loading = false;
                draft.error = true;
                draft.success = false;
                draft.cityDataLoaded = false;
                return draft;
            case LOAD_MOBILE_CARRIER:
                draft.loading = true;
                draft.error = false;
                draft.success = false;
                return draft;
            case LOAD_MOBILE_CARRIER_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.success = true;
                draft.mcDataLoaded = true;
                draft.mcData = action.payload.data.data.data;
                return draft;
            case LOAD_MOBILE_CARRIER_FAILED:
                draft.loading = false;
                draft.error = true;
                draft.success = false;
                draft.mcDataLoaded = false;
                return draft;
            
                case LOAD_ALL_CITY:
                    draft.loading = true;
                    draft.error = false;
                    draft.success = false;
                    return draft;
                case LOAD_ALL_CITY_SUCCESS:
                    draft.loading = false;
                    draft.error = false;
                    draft.success = true;
                    draft.isAllCityDataLoaded = true;
                    draft.allCityData = action.payload.data.data.data;
                    return draft;
                case LOAD_ALL_CITY_FAILED:
                    draft.loading = false;
                    draft.error = true;
                    draft.success = false;
                    draft.isAllCityDataLoaded = false;
                    return draft;
            
            case LOAD_CONDITION_COUNTRIES:
                draft.loading = true;
                draft.error = false;
                draft.success = false;
                return draft;
            case LOAD_CONDITION_COUNTRIES_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.success = true;
                draft.conditionCountryDataLoaded = true;
                draft.conditionCountryData = action.payload.data.data.data;
                return draft;
            case LOAD_CONDITION_COUNTRIES_FAILED:
                draft.loading = false;
                draft.error = true;
                draft.success = false;
                draft.conditionCountryDataLoaded = false;
                return draft;

            case LOAD_OPERATING_SYSTEM:
                draft.loading = true;
                draft.error = false;
                draft.success = false;
                return draft;
            
            case LOAD_OPERATING_SYSTEM_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.success = true;
                draft.operatingSystemDataLoaded = true;
                draft.operatingSystemData = action.payload.data.data.data;
                return draft;
            
            case LOAD_OPERATING_SYSTEM_FAILED:
                draft.loading = false;
                draft.error = true;
                draft.success = false;
                draft.operatingSystemDataLoaded = false;
                return draft;

            case LOAD_DEVICE_TYPE:
                draft.loading = true;
                draft.error = false;
                draft.success = false;
                return draft;
            case LOAD_DEVICE_TYPE_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.success = true;
                draft.deviceTypeDataLoaded = true;
                draft.deviceTypeData = action.payload.data.data.data;
                return draft;
            case LOAD_DEVICE_TYPE_FAILED:
                draft.loading = false;
                draft.error = true;
                draft.success = false;
                draft.deviceTypeDataLoaded = false;
                return draft;

            case LOAD_PROXY:
                draft.loading = true;
                draft.error = false;
                draft.success = false;
                return draft;
            case LOAD_PROXY_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.success = true;
                draft.proxyDataLoaded = true;
                draft.proxyData = action.payload.data.data.data;
                return draft;
            case LOAD_PROXY_FAILED:
                draft.loading = false;
                draft.error =true;
                draft.success = false;
                draft.proxyDataLoaded = false;
                return draft;

            case LOAD_CONNECTION_TYPE:
                draft.loading = true;
                draft.error = false;
                draft.success = false;
                return draft;
            case LOAD_CONNECTION_TYPE_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.success = true;
                draft.connectionTypeDataLoaded = true;
                draft.connectionTypeData = action.payload.data.data.data;
                return draft;
            case LOAD_CONNECTION_TYPE_FAILED:
                draft.loading = false;
                draft.error = true;
                draft.success = false;
                draft.connectionTypeDataLoaded = false;
                return draft;

            case LOAD_REGION:
                draft.loading = true;
                draft.error = false;
                draft.success = false;
                return draft;
            case LOAD_REGION_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.success = true;
                draft.regionDataLoaded = true;
                draft.regionData = action.payload.data.data.data;
                return draft;
            case LOAD_REGION_FAILED:
                draft.loading = false;
                draft.error = true;
                draft.success = false;
                draft.regionDataLoaded = false;
                return draft;

                case LOAD_WEEK_DAY:
                    draft.loading = true;
                    draft.error = false;
                    draft.success = false;
                    return draft;
                case LOAD_WEEK_DAY_SUCCESS:
                    draft.loading = false;
                    draft.error = false;
                    draft.success = true;
                    draft.isWeekDataDataLoaded = true;
                    draft.weekdayData = action.payload.data.data.data;
                    return draft;
                case LOAD_WEEK_DAY_FAILED:
                    draft.loading = false;
                    draft.error = true;
                    draft.success = false;
                    draft.isWeekDataDataLoaded = false;
                    return draft;
    
            default:
                return draft;
        }
    });

export default newFlowsReducer;
