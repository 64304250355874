import React from "react";
import produce from "immer";

class GroupingDropdowns extends React.Component {
    state = {
        allValues: ["Affiliate Network", "Brand", "Browser", "Browser Version", "Campaign", "City", "Connection Type", "Conversion", "Country", "Day", "Day of Week", "Device Type", "Flows", "Hour Of day", "ISP", "Lander", "Language", "Models", "Month", "Offers", "OS", "OS Version", "Proxy", "Region", "Timezone", "Traffic Source"],
        groupingValues: ["","",""],
        selectOptions: {
            main: [],
            group1: [],
            group2: []
        }
    }

    componentDidMount() {
        setTimeout(() => {
            if (JSON.parse(localStorage.getItem("search")) !== null) {
                let search = JSON.parse(localStorage.getItem("search"));
                // console.log("Search: ", search);
                this.setState(produce(draft => {
                    draft.groupingValues[0] = search.groupingValues.main;
                    draft.groupingValues[1] = search.groupingValues.group1;
                    draft.groupingValues[2] = search.groupingValues.group2;
                }));
                setTimeout(() => {
                    this.setSelectOptions();
                }, 40);
            }
        },100);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.getGroupingValues !== this.props.getGroupingValues) {
            this.setState(produce(draft => {
                draft.groupingValues = [
                    this.props.getGroupingValues.main,
                    this.props.getGroupingValues.group1,
                    this.props.getGroupingValues.group2
                ];
            }));
            setTimeout(() => { this.setSelectOptions(); },40);
        }
    }

    setSelectOptions = () => {
        let main = [];
        let group1 = [];
        let group2 = [];
        for (let j=0;j<this.state.allValues.length;j++) {
            if (this.state.groupingValues[0] === this.state.allValues[j]) {
                // console.log("this.state.allValues[j]: main ", this.state.allValues[j]);
                main.push(this.state.allValues[j]);
            } else if (this.state.groupingValues[1] === this.state.allValues[j]) {
                // console.log("this.state.allValues[j]: group1 ", this.state.allValues[j]);
                group1.push(this.state.allValues[j]);
            } else if (this.state.groupingValues[2] === this.state.allValues[j]) {
                // console.log("this.state.allValues[j]: group2 ", this.state.allValues[j]);
                group2.push(this.state.allValues[j]);
            } else {
                if (this.state.allValues[j] === "Hour Of day" || this.state.allValues[j] === "Month" || this.state.allValues[j] === "Day" || this.state.allValues[j] === "Conversion") {
                    main.push(this.state.allValues[j]);
                } else {
                    main.push(this.state.allValues[j]);
                    group1.push(this.state.allValues[j]);
                    group2.push(this.state.allValues[j]);
                }
            }
        }
        this.setState(produce(draft => {
            draft.selectOptions.main = main;
            draft.selectOptions.group1 = group1;
            draft.selectOptions.group2 = group2;
        }));
    }

    selectChangeHandler = (e) => {
        if(e.target.name === "main" && e.target.id === "main_0" && e.target.value === "") {
            this.props.disableApplyButton(true)
        } else {
            this.props.disableApplyButton(false)
        }
        const index = Number(e.target.id.split("_")[1]);
        this.setState(produce(draft => { draft.groupingValues[index] = e.target.value } ));
        setTimeout(() => {
            this.setSelectOptions();
            this.props.changeSearchValues(this.state.groupingValues,"grouping");
        },50);
    }   

    render () {
        return (
            <>
                <div className="grouping_tags">
                    <select
                        name="main"
                        id="main_0"
                        value={this.props.getGroupingValues.main}
                        onChange={this.selectChangeHandler}
                    >
                        <option value="">Select Grouping</option>
                        {this.state.selectOptions.main.map(option => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </select>
                </div>
                <div className="grouping_tags">
                    <select
                        name="group1"
                        id="group_1"
                        value={this.props.getGroupingValues.group1}
                        onChange={this.selectChangeHandler}
                        disabled={this.props.disable.group1}
                    >
                        <option value="">Select Grouping</option>
                        {this.state.selectOptions.group1.map(option => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </select>
                </div>
                <div className="grouping_tags">
                    <select
                        name="group2"
                        id="group_2"
                        value={this.props.getGroupingValues.group2}
                        onChange={this.selectChangeHandler}
                        disabled={this.props.disable.group2}
                    >
                        <option value="">Select Grouping</option>
                        {this.state.selectOptions.group2.map(option => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </select>
                </div>
            </>
        );
    }
}

export default GroupingDropdowns;