import { call, put, takeLatest } from "redux-saga/effects";
import {MOVE_TO_WORKSPACE } from "./constants";
import { moveToWorkspaceSuccess, moveToWorkspaceFailed } from "./actions";
import { MoveToWorkspaceFromAPI } from "./api";

function* moveToWorkspace(payload) {
    try {
        const response = yield call(MoveToWorkspaceFromAPI, payload);
        yield put(
            moveToWorkspaceSuccess(response.data)
        );
    } catch (error) {
        yield put(moveToWorkspaceFailed(error));
    }
}

export default function* MoveToWorkspaceSaga() {
    yield takeLatest(MOVE_TO_WORKSPACE, moveToWorkspace);
}