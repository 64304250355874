import axios from 'axios';
import { BASE_URL } from '../../helpers/path';

export const loadSettingsApi = async (request) => {
    const LOAD_SETTINGS_API_ENDPOINT = BASE_URL + 'settings';
    
    const accessToken = request.accessToken;
    axiosintercepter(accessToken);
    
    try {
		const response = await axios.get(LOAD_SETTINGS_API_ENDPOINT);
		return response;
	} catch (err) {
		console.log('errors', err);
		return err;
	}
}

export const updateSettingsApi = async (request) => {
    const UPDATE_SETTINGS_API_ENDPOINT = BASE_URL + 'settings';
    // console.log('request of update data', request);
    const accessToken = request.accessToken;
    axiosintercepter(accessToken);
    
    try {
		const response = await axios.patch(UPDATE_SETTINGS_API_ENDPOINT,request.data);
		return response;
	} catch (err) {
		console.log('errors', err);
		return err;
	}
}


function axiosintercepter(accessToken) {
	axios.interceptors.request.use(
		config => {
			config.headers.authorization = `Bearer ${accessToken}`;
		  return config;
		},
		error => {
		  return Promise.reject(error);
		}
	  )
}