export const LOAD_DASHBOARD_SUMMARY = "LOAD_DASHBOARD_SUMMARY";
export const LOAD_DASHBOARD_SUMMARY_SUCCESS = "LOAD_DASHBOARD_SUMMARY_SUCCESS";
export const LOAD_DASHBOARD_SUMMARY_FAILED = "LOAD_DASHBOARD_SUMMARY_FAILED";

export const LOAD_DASHBOARD_CHART_DATA = "LOAD_DASHBOARD_CHART_DATA";
export const LOAD_DASHBOARD_CHART_DATA_SUCCESS = "LOAD_DASHBOARD_CHART_DATA_SUCCESS";
export const LOAD_DASHBOARD_CHART_DATA_FAILED = "LOAD_DASHBOARD_CHART_DATA_FAILED";

export const LOAD_DASHBOARD_DATA = "LOAD_DASHBOARD_DATA";
export const LOAD_DASHBOARD_DATA_SUCCESS = "LOAD_DASHBOARD_DATA_SUCCESS";
export const LOAD_DASHBOARD_DATA_FAILED = "LOAD_DASHBOARD_DATA_FAILED";
