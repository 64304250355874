import {
    LOAD_ALL_USERS_DATA,LOAD_ALL_USERS_DATA_SUCCESS,LOAD_ALL_USERS_DATA_FAILED,
    ADD_USER_BY_ADMIN,ADD_USER_BY_ADMIN_FAILED,ADD_USER_BY_ADMIN_SUCCESS,
    CHANGE_STATUS_USER, CHANGE_STATUS_USER_SUCCESS, CHANGE_STATUS_USER_FAILED,
    UPDATE_USER,UPDATE_USER_SUCCESS,UPDATE_USER_FAILED,
    EDIT_USER, EDIT_USER_SUCCESS, EDIT_USER_FAILED, DELETE_USER, DELETE_USER_SUCCESS, DELETE_USER_FAILED
 } from "./constants";

export const loadAllUsers=(accessToken)=>{
    return{
        type: LOAD_ALL_USERS_DATA,
        payload: {accessToken}
    }
}

export const loadAllUsersSuccess=(allUserDataPayload)=>{
    return{
        type: LOAD_ALL_USERS_DATA_SUCCESS,
        payload: {allUserDataPayload}
    }
}

export const loadAllUsersFailed =(error)=>{
    return{
        type: LOAD_ALL_USERS_DATA_FAILED,
        payload:{error}
    }
}


export const addUsers=(accessToken,data)=>{
    return{
        type: ADD_USER_BY_ADMIN,
        payload: {accessToken,data}
    }
}

export const addUsersSuccess=(addUserDataPayload)=>{
    return{
        type: ADD_USER_BY_ADMIN_SUCCESS,
        payload: addUserDataPayload
    }
}

export const addUsersFailed =(error)=>{
    return{
        type: ADD_USER_BY_ADMIN_FAILED,
        payload:error
    }
}

export const updateUsers=(accessToken,id,data)=>{
    return{
        type: UPDATE_USER,
        payload: {accessToken,id,data}
    }
}

export const updateUsersSuccess=(updateUserDataPayload)=>{
    return{
        type: UPDATE_USER_SUCCESS,
        payload: updateUserDataPayload
    }
}

export const updateUsersFailed =(error)=>{
    return{
        type: UPDATE_USER_FAILED,
        payload:error
    }
}


export const changeUserStatus=(accessToken,id,data)=>{
    return{
        type: CHANGE_STATUS_USER,
        payload: {accessToken,id,data}
    }
}

export const changeUserStatusSuccess=(userStatusPayload)=>{
    return{
        type: CHANGE_STATUS_USER_SUCCESS,
        payload: {userStatusPayload}
    }
}

export const changeUserStatusFailed =(error)=>{
    return{
        type: CHANGE_STATUS_USER_FAILED,
        payload:{error}
    }
}

export const loadEditUserData =(payload)=>{
    return{
        type: EDIT_USER,
        payload:payload
    }
}

export const loadEditUserDataSuccess =(payload)=>{
    return{
        type: EDIT_USER_SUCCESS,
        payload:payload
    }
}

export const loadEditUserDataFailed =(error)=>{
    return{
        type: EDIT_USER_FAILED,
        payload:error
    }
}

export const DeleteUserData =(payload)=>{
    return{
        type: DELETE_USER,
        payload:payload
    }
}

export const DeleteDataSuccess =(payload)=>{
    return{
        type: DELETE_USER_SUCCESS,
        payload:payload
    }
}

export const DeleteDataFailed =(error)=>{
    return{
        type: DELETE_USER_FAILED,
        payload:error
    }
}

