import React, {useState, useRef} from "react";
import ReactTooltip from 'react-tooltip';
import { Switch } from '@progress/kendo-react-inputs';
import { Fade } from '@progress/kendo-react-animation';

const ModalForm = (props) => {
    const [affiliateTrackingURLCopySuccess, setAffiliateTrackingURLCopySuccess] = useState('');
    const affiliateTrackingURLRef = useRef(null);

    function copyToClipboard(e) {
        affiliateTrackingURLRef.current.select();
        document.execCommand('copy');
        e.target.focus();
        setAffiliateTrackingURLCopySuccess('Copied!');
    };

    //console.log('props state', props.state);
    return (
        <div className="form_con">
            <section>
                <h3>
                    General
                </h3>
                <div className="input_group">
                    <label htmlFor="affiliate-network-name">Name</label>
                    <input type="text" name="name" placeholder="Type affiliate network name" value={props.state.dataToSend.name} onChange={props.inputChangeHandler} />
                    {
                        props.state.empty_values.name ?
                            <Fade enter={true} exit={true}>
                                <p className="danger_para mb-10 mh-0">You must add the network name.</p>
                            </Fade>
                            :
                            null
                    }
                </div>
                <div className="input_group five_hun">
                    <label htmlFor="affiliate-network-name">
                        Workspace
                        <span data-tip data-for='workspaces_tooltip' className="question_mark">
                            <span className="k-icon k-i-question-circle"></span>
                        </span>
                        <ReactTooltip id='workspaces_tooltip' type='dark'>
                            <span>Assign your affiliate network to a particular workspace to make it accessible only by its members. Public option enables members from all workspaces to use this element in their campaigns.</span>
                        </ReactTooltip>
                    </label>
                    <select className="add_custom_domain_dropdown normal_text normal_font" name="workspaceId" value={props.state.dataToSend["workspaceId"]} onChange={props.inputChangeHandler}>
                        {
                            props.state.domainsDataReceived ?
                                props.state.domainsDataReceived.workspace.map((workspace) => (
                                    <option key={workspace.id} value={workspace.id}>{workspace.name}</option>
                                ))
                                : null
                        }
                    </select>
                </div>
            </section>
            <section>
                <h3>
                    Parameters
                    <div data-tip data-for='affiliate_networks_parameters' className="question_mark">
                        <span className="k-icon k-i-question-circle"></span>
                    </div>
                    <ReactTooltip id='affiliate_networks_parameters' type='dark'>
                        <span>Parameters and tokens supported by the affiliate network. Important for conversion tracking with s2s postback method, optional for other conversion tracking methods. For values refer to the affiliate network documentation.</span>
                    </ReactTooltip>
                </h3>
                <div className="inner_half_data_div five_hun">
                    {/*===========================================================*/}
                    {/*     Start of the table      */}
                    {/*===========================================================*/}
                    <table className="add_affiliate_popup_table">
                        <thead>
                            <tr>
                                <th className="first_col">
                                    <span className="text">Name</span>
                                </th>
                                <th className="second_col">
                                    <span className="text">AN parameter</span>
                                    <span data-tip data-for='affiliate_networks_an_parameter' className="question_mark">
                                        <span className="k-icon k-i-question-circle"></span>
                                    </span>
                                    <ReactTooltip id='affiliate_networks_an_parameter' type='dark'>
                                        <span>A static value that your network uses to record AffMark data. When appended with a Voluum token in the offer URL, upon the redirection we dynamically pass the data to the affiliate network. Examples: s2, aff_sub, sub1, sid1</span>
                                    </ReactTooltip>
                                </th>
                                <th>
                                    <span className="text">AN tokens</span>
                                    <span data-tip data-for='affiliate_networks_an_token' className="question_mark">
                                        <span className="k-icon k-i-question-circle"></span>
                                    </span>
                                    <ReactTooltip id='affiliate_networks_an_token' type='dark'>
                                        <span>Tokens that will be dynamically replaced by your affiliate network while firing the postback URL. Examples: #s2#, {'{aff_sub}'}, #RATE#, {'{sid1}'}</span>
                                    </ReactTooltip>
                                </th>
                                <th className="last_col">
                                    <span className="text">VLM tokens</span>
                                    <span data-tip data-for='affiliate_networks_vlm_token' className="question_mark">
                                        <span className="k-icon k-i-question-circle"></span>
                                    </span>
                                    <ReactTooltip id='affiliate_networks_vlm_token' type='dark'>
                                        <span>Corresponding AffMark tokens. Other tokens than click ID are usually used if you want to pass the conversion data to the traffic source while using the traffic source postback URL.</span>
                                    </ReactTooltip>
                                </th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="first_col">
                                    <span className="text">Click ID</span>
                                    <span data-tip data-for='affiliate_networks_click_id' className="question_mark">
                                        <span className="k-icon k-i-question-circle"></span>
                                    </span>
                                    <ReactTooltip id='affiliate_networks_click_id' type='dark'>
                                        <span>This parameter is mandatory if you want to record conversions in AffMark by using s2s postback URL. It pass Voluum's click ID (unique identifier of each visitor).</span>
                                    </ReactTooltip>
                                </td>
                                <td>
                                    <input type="text" placeholder="Type parameter" name="an_parameter" value={props.state.dataToSend.parameters[0].an_parameter} onChange={props.anParameterChangeHandler} />
                                </td>
                                <td>
                                    <input type="text" placeholder="Type token" name="an_token_0" value={props.state.dataToSend.parameters[0].an_token} onChange={props.anTokenChangeHandler} />
                                </td>
                                <td>
                                    <span className="text">{'{clickid}'}</span>
                                </td>
                            </tr>
                            <tr>
                                <td className="first_col">
                                    <span className="text">Payout</span>
                                    <span data-tip data-for='affiliate_networks_payout' className="question_mark">
                                        <span className="k-icon k-i-question-circle"></span>
                                    </span>
                                    <ReactTooltip id='affiliate_networks_payout' type='dark'>
                                        <span>This is an affiliate network token that passes a payout value to AffMark (money you earn when managed to deliver conversion). Example: #RATE#, {'{payout}'}, %price%</span>
                                    </ReactTooltip>
                                </td>
                                <td>
                                </td>
                                <td>
                                    <input type="text" placeholder="Type token" name="an_token_1" value={props.state.dataToSend.parameters[1].an_token} onChange={props.anTokenChangeHandler} />
                                </td>
                                <td>
                                    <span className="text">{'{payout}'}</span>
                                </td>
                            </tr>
                            <tr>
                                <td className="first_col">
                                    <span className="text">Transaction ID</span>
                                    <span data-tip data-for='affiliate_networks_tx_id' className="question_mark">
                                        <span className="k-icon k-i-question-circle"></span>
                                    </span>
                                    <ReactTooltip id='affiliate_networks_tx_id' type='dark'>
                                        <span>Transaction ID can be any combination of characters and has to be unique within the scope of a clickid. You can record more than one conversion with the same click ID if a transaction ID differs. </span>
                                    </ReactTooltip>
                                </td>
                                <td>
                                </td>
                                <td>
                                    <input type="text" placeholder="Type token" name="an_token_2" value={props.state.dataToSend.parameters[2].an_token} onChange={props.anTokenChangeHandler} />
                                </td>
                                <td>
                                    <span className="text">{'{txid}'}</span>
                                </td>
                            </tr>
                            <tr>
                                <td className="first_col">
                                    <span className="text">Event Type</span>
                                    <span data-tip data-for='affiliate_networks_et' className="question_mark">
                                        <span className="k-icon k-i-question-circle"></span>
                                    </span>
                                    <ReactTooltip id='affiliate_networks_et' type='dark'>
                                        <span>It allows you to track more than one conversion type within a campaign. The custom conversion types are displayed in reports to measure the campaign’s performance more accurately. In order to track event types, specify custom conversions in settings.</span>
                                    </ReactTooltip>
                                </td>
                                <td>
                                </td>
                                <td>
                                    <input type="text" placeholder="Type token" name="an_token_3" value={props.state.dataToSend.parameters[3].an_token} onChange={props.anTokenChangeHandler} />
                                </td>
                                <td>
                                    <span className="text">{'{eventType}'}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {/*===========================================================*/}
                    {/*     End of the table    */}
                    {/*===========================================================*/}
                    {
                        props.state.empty_values.clickId_an_parameter ?
                            <Fade enter={true} exit={true}>
                                <p className="danger_para mb-10 mh-0">You must add atleast Click ID's AN Token to save the data.</p>
                            </Fade>
                            :
                            null
                    }
                </div>
            </section>
            <section className="five_hun">
                <h3>
                    Conversion tracking
                    <span data-tip data-for='affiliate_networks_ct' className="question_mark">
                        <span className="k-icon k-i-question-circle"></span>
                    </span>
                    <ReactTooltip id='affiliate_networks_ct' type='dark'>
                        <span>All methods for conversion tracking supported by AffMark. If your affiliate network don’t support passing conversion data to 3rd party, you can always upload conversions later on via API or by using Conversion upload tool available in Settings. You will need a valid click ID for that.</span>
                    </ReactTooltip>
                </h3>
                <div className="input_group_inline_small">
                    <label htmlFor="affiliate-network-name">Tracking domain</label>
                    <select className="add_custom_domain_dropdown normal_text normal_font" name="domainId" value={props.state.dataToSend.domainId} onChange={props.inputChangeHandler}>
                        {
                            props.state.domainsDataReceived ?
                                props.state.domainsDataReceived.domain.map((domain) => (
                                    <option key={domain.id} value={domain.id}>{domain.name}</option>
                                ))
                                : null
                        }
                    </select>
                </div>
                <div className="input_group_inline_small">
                    <label htmlFor="affiliate-network-name">
                        Method
                        <span data-tip data-for='affiliate_method_tooltip' className="question_mark">
                            <span className="k-icon k-i-question-circle"></span>
                        </span>
                        <ReactTooltip id='affiliate_method_tooltip' type='dark'>
                            <span>Choose a method that your affiliate network is using to pass the conversion to the tracker. If your affiliate network gives more than one option, we recommend to use s2s postback URL as the most bullet-proof way (server-to-server) to deliver a conversion information to AffMark.</span>
                        </ReactTooltip>
                    </label>
                    <select className="add_custom_domain_dropdown normal_text normal_font" name="trackingId" value={props.state.dataToSend.trackingId} onChange={props.inputChangeHandler}>
                        {
                            props.state.trackingUrlsReceived ?
                                props.state.trackingUrlsReceived.map((trackingUrlReceived) => (
                                    <option key={trackingUrlReceived.id} value={trackingUrlReceived.id}>{trackingUrlReceived.name}</option>
                                ))
                                : null
                        }
                    </select>
                </div>
                {
                    props.state.empty_values.domainId ?
                        <Fade enter={true} exit={true}>
                            <p className="danger_para mb-10 mh-0">No existing Domains found. Please add a domain under settings / domains</p>
                        </Fade>
                        :
                        null
                }
                <div className="switches_row">
                    <Switch
                        name="additional_parameters"
                        onClick={props.switchChangeHandler}
                        onChange={props.switchChangeHandler}
                        checked={props.state.dataToSend.additional_parameters}
                    />
                    Include additional parameters in the postback URL
                </div>
                <div className="input_group copy_group">
                    {
                        props.state.showCIdReplaceWarning
                        ?
                            <div className="tooltip">
                                <span data-tip data-for='affiliate_tracking_input_tooltip' className="question_mark">
                                    <span className="k-icon k-i-warning icon_color_warning"></span>
                                </span>
                                <ReactTooltip id='affiliate_tracking_input_tooltip' type='dark'>
                                    <span>REPLACE needs to be replaced with the data copied from your affiliate network.</span>
                                </ReactTooltip>
                            </div>
                            : null
                    }
                    {/*=================================================================*/}
                    {/*                 Input Container for postback url                */}
                    {/*=================================================================*/}
                    <div className="input_container">
                        {
                            props.state.dataToSend.additional_parameters ?
                                <input
                                    className="data"
                                    ref={affiliateTrackingURLRef}
                                    name="conversion_tracking_code_input"
                                    value={
                                        props.state.tracking_url_id_code_before +
                                        props.state.url_value.domain_name +
                                        props.state.url_value.tracking_url_code +
                                        props.state.url_value.click_id_token +
                                        props.state.url_value.before_payout_ampersand +
                                        props.state.url_value.payout_token +
                                        props.state.url_value.before_tx_id_ampersand +
                                        props.state.url_value.tx_id_token +
                                        props.state.url_value.before_et_ampersand +
                                        props.state.url_value.et_token +
                                        "&param1=OPTIONAL&param2=OPTIONAL&param3=OPTIONAL&param4=OPTIONAL&param5=OPTIONAL" +
                                        props.state.tracking_url_id_code_after
                                    }
                                />
                                :
                                <input
                                    className="data"
                                    ref={affiliateTrackingURLRef}
                                    name="conversion_tracking_code_input"
                                    value={
                                        props.state.tracking_url_id_code_before +
                                        props.state.url_value.domain_name +
                                        props.state.url_value.tracking_url_code +
                                        props.state.url_value.click_id_token +
                                        props.state.url_value.before_payout_ampersand +
                                        props.state.url_value.payout_token +
                                        props.state.url_value.before_tx_id_ampersand +
                                        props.state.url_value.tx_id_token +
                                        props.state.url_value.before_et_ampersand +
                                        props.state.url_value.et_token +
                                        props.state.tracking_url_id_code_after
                                    }
                                />
                        }
                        <div className="tags">
                            {/*<span className="copy">Expand</span>*/}
                            <span className="copy" onClick={copyToClipboard}>Copy</span>
                        </div>
                    </div>
                    {/*=================================================================*/}
                    {/*                 Input Container for postback url                */}
                    {/*=================================================================*/}
                </div>
                <div className="note normal_font normal_text">
                    <span className="question_mark">
                        <span className="k-icon k-i-warning icon_color_theme_default"></span>
                    </span>
                    <div className="note_p black">Copy the above content and paste it into the affiliate network global conversion tracking settings.</div>
                </div>
                <div className="currency_group input_group_inline_small">
                    <label htmlFor="affiliate-network-currency">
                        Payout currency
                        <span data-tip data-for='payout_currency_tooltip' className="question_mark">
                            <span className="k-icon k-i-question-circle"></span>
                        </span>
                        <ReactTooltip id='payout_currency_tooltip' type='dark'>
                            <span>Assign your affiliate network to a particular workspace to make it accessible only by its members. Public option enables members from all workspaces to use this element in their campaigns.</span>
                        </ReactTooltip>
                    </label>
                    <select
                        className="add_custom_domain_dropdown normal_text normal_font"
                        name="currencyId"
                        value={props.state.dataToSend.currencyId}
                        onChange={props.inputChangeHandler}
                    >
                        {
                            props.state.currenciesListReceived ?
                                props.state.currenciesListReceived.map((currency) => (
                                    <option key={currency.id} value={currency.id}>{currency.name}</option>
                                ))
                                : null
                        }
                    </select>
                </div>
            </section>
            <section>
                <div className="five_hun additional_settings_con">
                    <p className="normal_text normal_font theme_blue_color">Additional Settings Link</p>
                    <div className="switches_row">
                        <Switch
                            name="append_click_offer"
                            onClick={props.switchChangeHandler}
                            onChange={props.switchChangeHandler}
                            checked={props.state.dataToSend.append_click_offer}
                        />
                        Append click ID to offer URLs
                        <span data-tip data-for='add_settings_one_tooltip' className="question_mark">
                            <span className="k-icon k-i-question-circle"></span>
                        </span>
                        <ReactTooltip id='add_settings_one_tooltip' type='dark'>
                            <span>All offers created and grouped with the affiliate network will have click ID automatically passed in the offer URL and appended at the end of it.</span>
                        </ReactTooltip>
                    </div>
                    <div className="switches_row">
                        <Switch
                            name="postback_pixel"
                            onClick={props.switchChangeHandler}
                            onChange={props.switchChangeHandler}
                            checked={props.state.dataToSend.postback_pixel}
                        />
                        Accept duplicate postbacks/pixels
                        <span data-tip data-for='add_settings_two_tooltip' className="question_mark">
                            <span className="k-icon k-i-question-circle"></span>
                        </span>
                        <ReactTooltip id='add_settings_two_tooltip' type='dark'>
                            <span>Once enabled, duplicate postback requests with the same click ID will be accepted. It might have an impact on the result of tracking conversions.</span>
                        </ReactTooltip>
                    </div>
                    <div className="switches_row">
                        <Switch
                            name="ip_address_switch_value"
                            onClick={props.ipAddressSwitchChangeHandler}
                            onChange={props.ipAddressSwitchChangeHandler}
                            checked={props.state.ip_address_switch_value}
                        />
                        Only accept postbacks from white-listed IPs
                        <span data-tip data-for='add_settings_three_tooltip' className="question_mark">
                            <span className="k-icon k-i-question-circle"></span>
                        </span>
                        <ReactTooltip id='add_settings_three_tooltip' type='dark'>
                            <span>When enabled, AffMark will only count postback URLs from the white-listed IPs. You can either enter single IPs or IP range (accepted only in CIDR notation).</span>
                        </ReactTooltip>
                    </div>
                    {
                        props.state.ip_address_switch_value
                        ?
                            <div className="switches_row last_switches_row">
                                <p className="one normal_text normal_font black">Please add One IP Address per line.</p>
                                <textarea
                                    name="ip_address_ranges"
                                    id="ip_address_ranges"
                                    cols="30"
                                    rows="10"
                                    onChange={props.ipAddressCheckHandler}
                                    value={props.state.dataToSend.ip_address_ranges}
                                ></textarea>
                                {
                                    !props.state.ip_address_valid_value ?
                                        <Fade enter={true} exit={true}>
                                            <p className="danger_para mb-10 mh-0">Please enter valid Ip Address/es.</p>
                                        </Fade>
                                        :
                                        null
                                }
                            </div>
                            : null
                    }
                </div>
            </section>
        </div>
    );
};

export default ModalForm;