import { checkFilters } from "../../../components/checkNamesForGrouping/checkNames";
import { returnTimeZoneId } from "../../../components/timeZonesExchange";
import * as requestHandler from "../../helpers/requestHandlers";
import {
    PATH_LOAD_TRACKING_URLS,
    PATH_LOAD_CURRENCY_LIST,
    PATH_AFFILIATE_NETWORKS,
    PATH_AFFILIATE_NETWORKS_SHORT,
    PATH_AFFILIATE_NETWORKS_ARCHIVED,
    PATH_AFFILIATE_NETWORKS_CUSTOM,
    PATH_AFFILIATE_NETWORKS_CHART,
    PATH_GROUPING
} from "../../helpers/path"; 

export const LoadTrackingUrlsFromAPI = async (request) => {
    const response = await requestHandler.get(PATH_LOAD_TRACKING_URLS);
    return response;
};

export const LoadCurrenciesListFromAPI = async (request) => {
    const response = await requestHandler.get(PATH_LOAD_CURRENCY_LIST);
    return response;
};

export const postNewAffiliateNetworkDataFromAPI = async (request) => {
    const response = await requestHandler.post(PATH_AFFILIATE_NETWORKS, request.payload.data);
    return response;
};

export const loadAffiliateNetworkDataFromAPI = async (request) => {
    let endDate = request.payload.searchData.modifiedDate.endDate;
    let startDate = request.payload.searchData.modifiedDate.startDate;

    let timeZoneInLocalStorage = request.payload.searchData.dates.timeZone;
    let timeZoneIdToSend = returnTimeZoneId(timeZoneInLocalStorage);

    let filtersToSend = checkFilters(request.payload.searchData.groupingValues.main,request.payload.searchData.groupingValues.group1,request.payload.searchData.groupingValues.group2);

    let parameters = "?filter="+ request.payload.searchData.inputSearchValue + "&date=&from="+ startDate+"&to="+endDate +"&tags="+request.payload.searchData.tagsSearchValue+"&main="+ filtersToSend[0] + "&group1="+ filtersToSend[1] + "&group2="+ filtersToSend[2] + "&order=" + request.payload.sorting.order + "&sort=" + request.payload.sorting.sort + "&skip=" + request.payload.searchData.pagination.skip + "&take=" + request.payload.searchData.pagination.take + "&workspace=" + request.payload.searchData.selectedWorkspacesList + "&timezone=" + timeZoneIdToSend + "&status=" + request.payload.searchData.filterStatus;
    if (request.payload.primaryPage) {
        const response = await requestHandler.get(PATH_GROUPING + parameters);
        return response;
    } else {
        if (request.payload.dataType) {
            const response = await requestHandler.get((request.payload.dataType === "short" ? PATH_AFFILIATE_NETWORKS_SHORT : PATH_AFFILIATE_NETWORKS) + parameters);
            return response;
        } else {
            const response = await requestHandler.get(PATH_AFFILIATE_NETWORKS + parameters);
            return response;
        }
    }

};

export const editAffiliateNetworkDataFromAPI = async (request) => {
    const response = await requestHandler.get(PATH_AFFILIATE_NETWORKS + request.payload.editAffiliateNetworkId);
    return response;
};

export const sendEditAffiliateNetworkDataFromAPI = async (request) => {
    const data = request.payload.data;
    let url = PATH_AFFILIATE_NETWORKS + request.payload.editAffiliateNetworkId;
    const response = await requestHandler.patch(url, data);
    return response;
};

export const deleteAffiliateNetworkDataFromAPI = async (request) => {
    let url = PATH_AFFILIATE_NETWORKS + request.payload.networkId;
    const response = await requestHandler.delet(url);
    return response;
};

export const archiveAffiliateNetworkDataFromAPI = async (request) => {
    let url = PATH_AFFILIATE_NETWORKS_ARCHIVED + request.payload.anId;
    const response = await requestHandler.post(url,{ value: request.payload.value});
    return response;
};

export const loadAllCustomAffiliateNetworkFromAPI = async (request) => {
    const response = await requestHandler.get(PATH_AFFILIATE_NETWORKS_CUSTOM);
    return response;
}

export const loadCustomAffiliateNetworkByIdFromAPI = async (request) => {
    let url = PATH_AFFILIATE_NETWORKS_CUSTOM + request.payload.customAffiliateNetworkId;
    const response = await requestHandler.get(url);
    return response;
}

export const LoadAffNetworkChartsDataFromAPI = async (request) => {
    let endDate = request.payload.dates.endDate;
    let startDate = request.payload.dates.startDate;
    let parameters = "to=" + endDate + "&from=" + startDate;
    let url = PATH_AFFILIATE_NETWORKS_CHART + parameters;
    const response = await requestHandler.get(url);
    return response;
};