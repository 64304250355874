import { createSelector } from "reselect";
import { initialTrafficSourcesState } from "./reducer";

const selectTrafficSourcesState= (state) =>  state.trafficSources || initialTrafficSourcesState;

const makeSelectLoading = () => createSelector(selectTrafficSourcesState, (subState) => subState.loading);
const makeSelectSuccess = () => createSelector(selectTrafficSourcesState, (subState) => subState.success);
const makeSelectError = () => createSelector(selectTrafficSourcesState, (subState) => subState.error);
const makeSelectLoadCurrenciesList = () => createSelector(selectTrafficSourcesState, (subState) => subState.currenciesListReceived);


const makeSelectLoadAllData = () => createSelector(selectTrafficSourcesState, (subState) => subState.loadAllData);
const makeSelectNewTrafficSourceDataSent = () => createSelector(selectTrafficSourcesState, (subState) => subState.newTrafficSourceDataSent);
const makeSelectTrafficSourcesDataLoaded = () => createSelector(selectTrafficSourcesState, (subState) => subState.trafficSourcesDataLoaded);
const makeSelectEditTrafficSourceDataReceived = () => createSelector(selectTrafficSourcesState, (subState) => subState.editTrafficSourceDataReceived);
const makeSelectPatchedTrafficSourceDataSent = () => createSelector(selectTrafficSourcesState, (subState) => subState.patchedTrafficSourceDataSent);
const makeSelectDeleteTrafficSourceDataSent = () => createSelector(selectTrafficSourcesState, (subState) => subState.deleteTrafficSourceDataSent);
const makeSelectArchiveTrafficSourceDataSent = () => createSelector(selectTrafficSourcesState, (subState) => subState.archivedTrafficSourceDataSent);

const makeSelectTagsListReceived = () => createSelector(selectTrafficSourcesState, (subState) => subState.tagsListReceived);
const makeSelectIsTagsListReceived = () => createSelector(selectTrafficSourcesState, (subState) => subState.isTagsListReceived);

const makeSelectAllCustomDataReceived = () => createSelector(selectTrafficSourcesState, (subState) => subState.loadAllCustomTrafficSourceData);
const makeSelectIsAllCustomDataLoaded = () => createSelector(selectTrafficSourcesState, (subState) => subState.isAllCustomTrafficSourceDataLoaded);

const makeSelectIsChartsDataLoaded = () => createSelector(selectTrafficSourcesState, (subState) => subState.charts.isChartsDataLoaded);
const makeSelectAllChartsDataLoaded = () => createSelector(selectTrafficSourcesState, (subState) => subState.charts.allChartsDataLoaded);

const makeSelectCustomDataReceivedById = () => createSelector(selectTrafficSourcesState, (subState) => subState.loadCustomTrafficSourceDataById);
const makeSelectIsCustomDataByIdLoaded = () => createSelector(selectTrafficSourcesState, (subState) => subState.isCustomTrafficSourceDataByIdLoaded);
const makeSelectArchiveDeleteResponseTS = () => createSelector(selectTrafficSourcesState, (subState) => subState.archiveDeleteResponseTS);

export {
    makeSelectLoading,
    makeSelectSuccess,
    makeSelectError,
    makeSelectLoadCurrenciesList,
    makeSelectNewTrafficSourceDataSent,
    makeSelectTrafficSourcesDataLoaded,
    makeSelectEditTrafficSourceDataReceived,
    makeSelectPatchedTrafficSourceDataSent,
    makeSelectDeleteTrafficSourceDataSent,
    makeSelectArchiveTrafficSourceDataSent,
    makeSelectLoadAllData,
    makeSelectTagsListReceived,
    makeSelectIsTagsListReceived,

    makeSelectAllCustomDataReceived,
    makeSelectIsAllCustomDataLoaded,

    makeSelectCustomDataReceivedById,
    makeSelectIsCustomDataByIdLoaded,
    makeSelectIsChartsDataLoaded,
    makeSelectAllChartsDataLoaded,

    makeSelectArchiveDeleteResponseTS
}