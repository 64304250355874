import produce from "immer";
import {
    LOAD_TRACKING_URLS, LOAD_TRACKING_URLS_SUCCESS, LOAD_TRACKING_URLS_FAILED,
    LOAD_CURRENCY, LOAD_CURRENCY_SUCCESS, LOAD_CURRENCY_FAILED,
    POST_NEW_AFFILIATE_NETWORK_DATA, POST_NEW_AFFILIATE_NETWORK_DATA_SUCCESS, POST_NEW_AFFILIATE_NETWORK_DATA_FAILED,
    LOAD_AFFILIATE_NETWORK_DATA, LOAD_AFFILIATE_NETWORK_DATA_SUCCESS, LOAD_AFFILIATE_NETWORK_DATA_FAILED,
    EDIT_AFFILIATE_NETWORK_DATA, EDIT_AFFILIATE_NETWORK_DATA_SUCCESS, EDIT_AFFILIATE_NETWORK_DATA_FAILED,
    EDIT_AFFILIATE_NETWORK_DATA_SENT, EDIT_AFFILIATE_NETWORK_DATA_SENT_SUCCESS, EDIT_AFFILIATE_NETWORK_DATA_SENT_FAILED,
    DELETE_AFFILIATE_NETWORK_DATA, DELETE_AFFILIATE_NETWORK_DATA_SUCCESS, DELETE_AFFILIATE_NETWORK_DATA_FAILED,
    LOAD_CUSTOM_AFFILIATE_NETWORK, LOAD_CUSTOM_AFFILIATE_NETWORK_SUCCESS, LOAD_CUSTOM_AFFILIATE_NETWORK_FAILED,
    LOAD_CUSTOM_AFFILIATE_NETWORK_BY_ID, LOAD_CUSTOM_AFFILIATE_NETWORK_BY_ID_FAILED,LOAD_CUSTOM_AFFILIATE_NETWORK_BY_ID_SUCCESS,
    CLOSE_EDIT_DIALOGUE, RESET_CUSTOM_DATA_BY_ID,
    ARCHIVE_AFFILIATE_NETWORK_DATA, ARCHIVE_AFFILIATE_NETWORK_DATA_SUCCESS, ARCHIVE_AFFILIATE_NETWORK_DATA_FAILED,
    GET_AFFNETWORK_CHARTS_DATA, GET_AFFNETWORK_CHARTS_DATA_SUCCESS, GET_AFFNETWORK_CHARTS_DATA_FAILED
} from "./constants";


export const initialAffiliateNetworksState = {
    loading: false,
    success: false,
    error: false,
    trackingUrlsReceived: null,
    currenciesListReceived: null,
    isNewAffiliateNetworkDataSent: null,
    affiliatedNetworkDataLoaded: null,
    editAffiliateNetworkDataReceived: null,
    isEditAffiliateNetworkDataReceived: null,
    isAllAffiliateNetworksDataLoaded: null,
    isPatchedAffiliateNetworkDataSent: null,
    isDeleteAffiliateNetworkDataSent: null,
    isAffNetworkArchived: false,
    
    customAffiliateNetworkData: null,
    customAffiliateNetworkDataById:null,
    isCustomAffiliateNetworkDataLoaded: null,
    isCustomAffiliateNetworkDataByIdLoaded: null,
    archiveDeleteResponseAffNetwork: null,
    charts: {
        isChartsDataLoaded: false,
        allChartsDataLoaded: null
    }
};

const affiliateNetworksReducer = (state= initialAffiliateNetworksState, action) => produce(state, (draft => {
    switch (action.type) {
        case LOAD_TRACKING_URLS:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case LOAD_TRACKING_URLS_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.trackingUrlsReceived = action.payload;
            return draft;
        case LOAD_TRACKING_URLS_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            return draft;

        case LOAD_CURRENCY:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case LOAD_CURRENCY_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.currenciesListReceived = action.payload;
            return draft;
        case LOAD_CURRENCY_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            return draft;


        case POST_NEW_AFFILIATE_NETWORK_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case POST_NEW_AFFILIATE_NETWORK_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.editAffiliateNetworkDataReceived = null;
            draft.isAllAffiliateNetworksDataLoaded = false;
            draft.isNewAffiliateNetworkDataSent = true;
            draft.isEditAffiliateNetworkDataReceived = false;
            draft.isPatchedAffiliateNetworkDataSent = false;
            draft.deleteAffiliateNetworkDataSent = false;
            draft.isAffNetworkArchived = false;
            return draft;
        case POST_NEW_AFFILIATE_NETWORK_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            draft.newAffiliateNetworkDataSent = false;
            return draft;


        case LOAD_AFFILIATE_NETWORK_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case LOAD_AFFILIATE_NETWORK_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.affiliatedNetworkDataLoaded = action.payload;
            draft.editAffiliateNetworkDataReceived = null;
            draft.isAllAffiliateNetworksDataLoaded = true;
            draft.isNewAffiliateNetworkDataSent = false;
            draft.isEditAffiliateNetworkDataReceived = false;
            draft.isPatchedAffiliateNetworkDataSent = false;
            draft.isDeleteAffiliateNetworkDataSent = false;
            draft.isCustomAffiliateNetworkDataByIdLoaded = false;
            draft.customAffiliateNetworkDataById = null;
            draft.isAffNetworkArchived = false;
            return draft;
        case LOAD_AFFILIATE_NETWORK_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            return draft;


        case EDIT_AFFILIATE_NETWORK_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case EDIT_AFFILIATE_NETWORK_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.editAffiliateNetworkDataReceived = action.payload;
            draft.isAllAffiliateNetworksDataLoaded = false;
            draft.isNewAffiliateNetworkDataSent = false;
            draft.isEditAffiliateNetworkDataReceived = true;
            draft.isPatchedAffiliateNetworkDataSent = false;
            draft.isDeleteAffiliateNetworkDataSent = false;
            draft.isCustomAffiliateNetworkDataByIdLoaded = false;
            draft.customAffiliateNetworkDataById = null;
            draft.isAffNetworkArchived = false;
            return draft;
        case EDIT_AFFILIATE_NETWORK_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            return draft;
        case CLOSE_EDIT_DIALOGUE:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.editAffiliateNetworkDataReceived = null;
            return draft;

        case EDIT_AFFILIATE_NETWORK_DATA_SENT:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case EDIT_AFFILIATE_NETWORK_DATA_SENT_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.editAffiliateNetworkDataReceived = null;
            draft.isAllAffiliateNetworksDataLoaded = false;
            draft.isNewAffiliateNetworkDataSent = false;
            draft.isEditAffiliateNetworkDataReceived = false;
            draft.isPatchedAffiliateNetworkDataSent = true;
            draft.isDeleteAffiliateNetworkDataSent = false;
            draft.isCustomAffiliateNetworkDataByIdLoaded = false;
            draft.customAffiliateNetworkDataById = null;
            draft.isAffNetworkArchived = false;
            return draft;
        case EDIT_AFFILIATE_NETWORK_DATA_SENT_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            return draft;


        case DELETE_AFFILIATE_NETWORK_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.archiveDeleteResponseAffNetwork = null;
            return draft;
        case DELETE_AFFILIATE_NETWORK_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.editAffiliateNetworkDataReceived = null;
            draft.isAllAffiliateNetworksDataLoaded = false;
            draft.isNewAffiliateNetworkDataSent = false;
            draft.isEditAffiliateNetworkDataReceived = false;
            draft.isPatchedAffiliateNetworkDataSent = false;
            draft.isDeleteAffiliateNetworkDataSent = true;
            draft.isCustomAffiliateNetworkDataByIdLoaded = false;
            draft.customAffiliateNetworkDataById = null;
            draft.isAffNetworkArchived = false;
            draft.archiveDeleteResponseAffNetwork = action.payload;
            return draft;
        case DELETE_AFFILIATE_NETWORK_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            draft.archiveDeleteResponseAffNetwork = null;
            return draft;
        
        case ARCHIVE_AFFILIATE_NETWORK_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.archiveDeleteResponseAffNetwork = null;
            return draft;
        case ARCHIVE_AFFILIATE_NETWORK_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.editAffiliateNetworkDataReceived = null;
            draft.isAllAffiliateNetworksDataLoaded = false;
            draft.isNewAffiliateNetworkDataSent = false;
            draft.isEditAffiliateNetworkDataReceived = false;
            draft.isPatchedAffiliateNetworkDataSent = false;
            draft.isDeleteAffiliateNetworkDataSent = false;
            draft.isCustomAffiliateNetworkDataByIdLoaded = false;
            draft.customAffiliateNetworkDataById = null;
            draft.isAffNetworkArchived = true;
            draft.archiveDeleteResponseAffNetwork = action.payload;
            return draft;
        case ARCHIVE_AFFILIATE_NETWORK_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            draft.archiveDeleteResponseAffNetwork = null;
            return draft;

        case LOAD_CUSTOM_AFFILIATE_NETWORK:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.isCustomAffiliateNetworkDataLoaded = false;
            draft.customAffiliateNetworkData = null;
            return draft;
        case LOAD_CUSTOM_AFFILIATE_NETWORK_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.isCustomAffiliateNetworkDataLoaded = true;
            draft.customAffiliateNetworkData = action.payload;
            return draft;
        case LOAD_CUSTOM_AFFILIATE_NETWORK_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            draft.isCustomAffiliateNetworkDataLoaded = false;
            draft.customAffiliateNetworkData = null;
            return draft;
    
        case LOAD_CUSTOM_AFFILIATE_NETWORK_BY_ID:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.isCustomAffiliateNetworkDataByIdLoaded = false;
            draft.customAffiliateNetworkDataById = null;
            return draft;
        case LOAD_CUSTOM_AFFILIATE_NETWORK_BY_ID_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.isCustomAffiliateNetworkDataByIdLoaded = true;
            draft.customAffiliateNetworkDataById = action.payload;
            return draft;
        case LOAD_CUSTOM_AFFILIATE_NETWORK_BY_ID_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            draft.isCustomAffiliateNetworkDataByIdLoaded = false;
            draft.customAffiliateNetworkDataById = null;
            return draft;
        case RESET_CUSTOM_DATA_BY_ID:
            draft.loading = false;
            draft.success = false;
            draft.error = false;
            draft.isCustomAffiliateNetworkDataByIdLoaded = false;
            draft.customAffiliateNetworkDataById = null;
            draft.editAffiliateNetworkDataReceived = null;
            return draft;
            case GET_AFFNETWORK_CHARTS_DATA:
                draft.loading = true;
                draft.success = false;
                draft.error = false;
                return draft;
            case GET_AFFNETWORK_CHARTS_DATA_SUCCESS:
                draft.loading = false;
                draft.success = true;
                draft.error = false;
                draft.charts.isChartsDataLoaded = true;
                draft.charts.allChartsDataLoaded = action.payload;
                return draft;
            case GET_AFFNETWORK_CHARTS_DATA_FAILED:
                draft.loading = false;
                draft.success = false;
                draft.charts.isChartsDataLoaded = false;
                draft.error = action.payload.response;
                return draft;
        default:
            return draft;
    }
}));

export default affiliateNetworksReducer;