import React, {Component} from "react";
import produce from "immer";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import ModalForm from "./ModalForm";
import {createStructuredSelector} from "reselect";
import {connect} from 'react-redux';
import * as domainsActions from "../../../Settings/Domains/actions";
import * as affiliatedNetworksActions from "../actions";
import {makeSelectDomainsDataReceived} from "../../../Settings/Domains/selectors";
import {makeSelectToken} from "../../../../registration/LoginRegister/selectors";
import {
    makeSelectLoading,
    makeSelectSuccess,
    makeSelectError,
    makeSelectTrackingUrlsReceived,
    makeSelectLoadCurrenciesList,
    makeSelectIsNewAffiliateNetworkDataSent,
    makeSelectAffiliatedNetworkDataLoaded,
    makeSelectEditAffiliateNetworkDataReceived,
    makeSelectIsPatchedAffiliateNetworkDataSent
} from "../selectors";
import LoadingPanel from "../../../grid/loader/loader";

class EditAffiliateNetworksModal extends Component {
    state = {
        dataToSend: {
            "name": "",
            "workspaceId": "",
            "domainId": "",
            "trackingId": "",
            "currencyId": "",
            "parameters": [
                {
                    "name": "Click Id",
                    "an_parameter": "",
                    "an_token": "",
                    "vlm_token": "{clickid}"
                },
                {
                    "name": "Payout",
                    "an_parameter": "payout",
                    "an_token": "",
                    "vlm_token": "{payout}"
                },
                {
                    "name": "Transaction Id",
                    "an_parameter": "",
                    "an_token": "",
                    "vlm_token": "{txid}"
                },
                {
                    "name": "Event Type",
                    "an_parameter": "",
                    "an_token": "",
                    "vlm_token": "{eventType}"
                }
            ],
            "append_click_offer": false,
            "postback_pixel": false,
            "ip_address": null,
            "additional_parameters": false,
        },
        empty_values: {
            "name": false,
            "workspaceId": false,
            "domainId": false,
            "currencyId": false,
            "clickId_an_parameter": false
        },
        domainsDataReceived : null,
        trackingUrlsReceived: null,
        currenciesListReceived: null,
        conversion_tracking_code_input_value: '',
        showCIdReplaceWarning: false,
        url_value: {
            "domain_name": "",
            "tracking_url_code": "",
            "click_id_token": "",
            "is_click_id_token_populated": false,
            "before_payout_ampersand": "",
            "payout_token": "",
            "is_payout_token_populated": false,
            "before_tx_id_ampersand": "",
            "tx_id_token": "",
            "is_tx_id_token_populated": false,
            "before_et_ampersand": "",
            "et_token": "",
            "is_et_token_populated": false
        },
        tracking_url_id_code_before: "",
        tracking_url_id_code_after: "",
        ip_address_switch_value: false,
        ip_address_valid_value: true,
        isLoadCurrencies: false,
        isLoadDomains: false,
        isLoadTrackingUrls: false,
        isLoadEditAffiliateNetworkData: false,
        affNetworkDataLoaded: false,
        submitBtnDisabled: false,
        allLoaded: false
    }
    fetchDomains = async () => {
        return this.props.dispatchLoadDomains(this.props.accessToken);
    };
    fetchTrackingUrls = async () => {
        return this.props.dispatchLoadTrackingURLS(this.props.accessToken);
    }
    fetchCurrencies = async () => {
        return this.props.dispatchLoadCurrencies(this.props.accessToken);
    }
    fetchEditAffiliateNetworkData = () => {
        return this.props.dispatchEditAffiliateNetwork({accessToken: this.props.accessToken, editAffiliateNetworkId: this.props.editAffiliateNetworkId });
    }

    
    sendPatchedAffiliateNetworkData = async () => {
        if(this.state.dataToSend.ip_address === "") {
            this.setState(produce(draft => { draft.dataToSend.ip_address = null }));
        }
        this.setState(produce(draft => {
            draft.dataToSend.currencyId = String(this.state.dataToSend.currencyId);
            draft.dataToSend.domainId = String(this.state.dataToSend.domainId);
            draft.dataToSend.id = String(this.state.dataToSend.id);
            draft.dataToSend.trackingId = String(this.state.dataToSend.trackingId);
            draft.dataToSend.userId = String(this.state.dataToSend.userId);
            draft.dataToSend.workspaceId = String(this.state.dataToSend.workspaceId);
        }));

        setTimeout(() => {
            if (this.props.state.isDuplicate) {
                //console.log('duplicate');
                return this.props.dispatchAddNewAffiliateNetwork({
                    data: this.state.dataToSend,
                    accessToken: this.props.accessToken
                });
            } else {
                //console.log('edit');
                return this.props.dispatchSendPatchedAffiliateNetwork({
                    editAffiliateNetworkId: this.props.state.editAffiliateNetworkId,
                    data: this.state.dataToSend,
                    accessToken: this.props.accessToken
                });
            }
        },500);
    }

    componentDidMount() {
        // fetching domains and workspaces and make the conditions true...
        // so that we can match it once in component did update and load the values in state....
        this.fetchDomains();
        this.fetchTrackingUrls();
        this.fetchCurrencies();
        this.setState(produce(draft => {
            draft.isLoadDomains = true;
            draft.isLoadTrackingUrls = true;
            draft.isLoadCurrencies = true;
            draft.isLoadEditAffiliateNetworkData = true;
            draft.affNetworkDataLoaded = true;
        }));

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        //console.log('this.state before', this.state);
        // Loading currencies list initially to the state
        if(this.props.currenciesListReceived && this.state.isLoadCurrencies === true) {
            this.setState(produce(draft => { draft.currenciesListReceived = this.props.currenciesListReceived }));
            this.setState(produce(draft => { draft.isLoadCurrencies = !this.state.isLoadCurrencies }));
        }

        // Loading Tracking urls list initially to the state
        if ((prevProps.trackingUrlsReceived !== this.props.trackingUrlsReceived) && this.props.trackingUrlsReceived !== null && this.state.isLoadTrackingUrls === true){
            let trackingUrls = [...this.props.trackingUrlsReceived];
            trackingUrls.shift();
            this.setState(produce(draft => {
                draft.trackingUrlsReceived = trackingUrls;
                draft.isLoadTrackingUrls = false;
             }));
            this.fetchEditAffiliateNetworkData();
        }

        // Loading domains list initially to the state
        if (this.props.domainsDataReceived && this.state.isLoadDomains === true) {
            this.setState(produce(draft => { draft.domainsDataReceived = this.props.domainsDataReceived}));
            if (this.props.domainsDataReceived.domain === []) {
                this.setState(produce(draft => { draft.empty_values.domainId = true}));
            }
            this.setState(produce(draft => { draft.isLoadDomains = false }));
        }

        // --------------------------------------------------------------------------------------------------
        // Loading network data initial to the state
        // --------------------------------------------------------------------------------------------------
        if ((prevProps.editAffiliateNetworkDataReceived !== this.props.editAffiliateNetworkDataReceived) && this.props.editAffiliateNetworkDataReceived && this.state.isLoadEditAffiliateNetworkData === true) {
            // set initial value in state
            this.setState(produce(draft => { 
                draft.dataToSend = this.props.editAffiliateNetworkDataReceived;
            }));
            // set url names for domain and tracking code
            for (let i=0;i<this.props.domainsDataReceived.domain.length; i++) {
                if (this.props.editAffiliateNetworkDataReceived.domainId === Number(this.props.domainsDataReceived.domain[i].id)) {
                    if (this.props.domainsDataReceived.domain[i].name.startsWith("https://")) {
                        this.setState(produce(draft => { draft.url_value.domain_name = this.props.domainsDataReceived.domain[i].name}));
                    } else if (this.props.domainsDataReceived.domain[i].name.startsWith("http://")) {
                        let url = this.props.domainsDataReceived.domain[i].name.split("http://")[1];
                        this.setState(produce(draft => { draft.url_value.domain_name = "https://" + url }));
                    } else {
                        this.setState(produce(draft => { draft.url_value.domain_name = "https://" + this.props.domainsDataReceived.domain[i].name}));
                    }

                }
            }
            for (let j=0;j<this.props.trackingUrlsReceived.length; j++) {
                if (this.props.editAffiliateNetworkDataReceived.trackingId === Number(this.props.trackingUrlsReceived[j].id)) {
                    this.setState(produce(draft => { draft.url_value.tracking_url_code = this.props.trackingUrlsReceived[j].code}));
                }
            }
            // checking is Pixel method is selected or not.
            if (this.props.editAffiliateNetworkDataReceived.trackingId === 3) {
                this.setState(produce(draft => { draft.tracking_url_id_code_before = '<img src="' }));
                this.setState(produce(draft => { draft.tracking_url_id_code_after = '" width="1" height="1" />' }));
            } else {
                this.setState(produce(draft => { draft.tracking_url_id_code_before = '' }));
                this.setState(produce(draft => { draft.tracking_url_id_code_after = '' }));
            }

            if (this.props.editAffiliateNetworkDataReceived !== null && this.props.editAffiliateNetworkDataReceived.ip_address_ranges !== null) {
                this.setState(produce(draft => { draft.ip_address_switch_value = true }));
            }
            // setting url parameters to be loaded to the input
            if (this.props.editAffiliateNetworkDataReceived.parameters[0].an_token !== "") {
                this.setState(produce(draft => {
                    draft.url_value.click_id_token = "cid=" + this.props.editAffiliateNetworkDataReceived.parameters[0].an_token;
                    draft.url_value.is_click_id_token_populated = true;
                }));
            } else {
                this.setState(produce(draft => {
                    draft.url_value.click_id_token = "cid=REPLACE";
                    draft.url_value.is_click_id_token_populated = true;
                }));
            }
            if (this.props.editAffiliateNetworkDataReceived.parameters[1].an_token !== "") {
                this.setState(produce(draft => {
                    draft.url_value.payout_token = "payout=" + this.props.editAffiliateNetworkDataReceived.parameters[1].an_token;
                    draft.url_value.is_payout_token_populated = true;
                }));
                if (this.props.editAffiliateNetworkDataReceived.parameters[0].an_token !== "") {
                    this.setState(produce(draft => {draft.url_value.before_payout_ampersand = "&" }));
                }
            } else {
                this.setState(produce(draft => {
                    draft.url_value.payout_token = "payout=REPLACE";
                    draft.url_value.before_payout_ampersand = "&";
                    draft.url_value.is_payout_token_populated = true;
                }));
            }
            if (this.props.editAffiliateNetworkDataReceived.parameters[2].an_token !== "") {
                this.setState(produce(draft => {
                    draft.url_value.tx_id_token = "txid=" + this.props.editAffiliateNetworkDataReceived.parameters[2].an_token;
                    draft.url_value.is_tx_id_token_populated = true;
                }));
                if (this.props.editAffiliateNetworkDataReceived.parameters[0].an_token !== "" || this.props.editAffiliateNetworkDataReceived.parameters[1].an_token !== "") {
                    this.setState(produce(draft => {draft.url_value.before_tx_id_ampersand = "&" }));
                }
            }
            if (this.props.editAffiliateNetworkDataReceived.parameters[3].an_token !== "") {
                this.setState(produce(draft => {
                    draft.url_value.et_token = "et=" + this.props.editAffiliateNetworkDataReceived.parameters[3].an_token;
                    draft.url_value.is_et_token_populated = true;
                }));
                if (this.props.editAffiliateNetworkDataReceived.parameters[0].an_token !== "" || this.props.editAffiliateNetworkDataReceived.parameters[1].an_token !== "" || this.props.editAffiliateNetworkDataReceived.parameters[2].an_token !== "") {
                    this.setState(produce(draft => {draft.url_value.before_et_ampersand = "&" }));
                }
            }

            // making the value false in the state to break the loop
            this.setState(produce(draft => { draft.isLoadEditAffiliateNetworkData = false }));
            if (this.state.affNetworkDataLoaded) {
                this.setState(produce(draft => { draft.affNetworkDataLoaded = false }));
            }

            this.addDuplicateKeyword();
            this.props.toggleLoader();
        }
        //console.log('this.state after', this.state);
        // --------------------------------------------------------------------------------------------------
        // End loading network data initially to the state.
        // --------------------------------------------------------------------------------------------------

        // set the loader to false after everything is loaded
        // set loader to false after everything loaded
        if (this.state.isLoadDomains === false && this.state.isLoadTrackingUrls === false && this.state.isLoadCurrencies === false && this.state.isLoadEditAffiliateNetworkData === false && this.state.allLoaded === false) {
            this.setState(produce(draft => { draft.allLoaded = true; }));
            this.props.toggleLoader();
        }
    }

    addDuplicateKeyword = () => {
        if (this.props.state.isDuplicate) {
            if (this.state.dataToSend.name.length > 0) {
                this.setState(produce(draft => { draft.dataToSend.name = this.state.dataToSend.name + " (Duplicate)"}));
            } else {
                setTimeout(() => {
                    this.setState(produce(draft => { draft.dataToSend.name = this.state.dataToSend.name + " (Duplicate)"}));
                },500);
            }
        }
    }

    inputChangeHandler = (e) => {
        this.setState(produce(draft => { draft.dataToSend[e.target.name] = e.target.value }));
        if (e.target.name === "domainId") {
            for (let i=0; i<this.state.domainsDataReceived.domain.length;i++) {
                if (e.target.value === String(this.state.domainsDataReceived.domain[i].id)) {
                    // this.setState(produce(draft => { draft.url_value.domain_name = this.state.domainsDataReceived.domain[i].name}));
                    if (this.state.domainsDataReceived.domain[i].name.startsWith("https://")) {
                        this.setState(produce(draft => { draft.url_value.domain_name = this.state.domainsDataReceived.domain[i].name}));
                    } else if (this.state.domainsDataReceived.domain[i].name.startsWith("http://")) {
                        let url = this.state.domainsDataReceived.domain[i].name.split("http://")[1];
                        this.setState(produce(draft => { draft.url_value.domain_name = "https://" + url }));
                    } else {
                        this.setState(produce(draft => { draft.url_value.domain_name = "https://" + this.state.domainsDataReceived.domain[i].name; }));
                    }
                }
            }
        }
        if (e.target.name === "trackingId") {
            if (e.target.value === "3") {
                this.setState(produce(draft => {
                    draft.tracking_url_id_code_before = '<img src="';
                    draft.tracking_url_id_code_after = '" width="1" height="1" />';
                }));
            } else {
                this.setState(produce(draft => {
                    draft.tracking_url_id_code_before = '';
                    draft.tracking_url_id_code_after = '';
                }));
            }
            if (e.target.value !== "2" && this.state.dataToSend.parameters[0].an_token === "cid=REPLACE") {
                this.setState(produce(draft => {
                    draft.dataToSend.parameters[0].an_token = "";
                    draft.url_value.is_click_id_token_populated = false;
                }));
            }
            if (e.target.value === "2" && this.state.dataToSend.parameters[0].an_token === "") {
                this.setState(produce(draft => {
                    draft.url_value.click_id_token = "cid=REPLACE";
                    draft.url_value.is_click_id_token_populated = true;
                }));
            }
            if (e.target.value === "2" && this.state.dataToSend.parameters[1].an_token === "") {
                this.setState(produce(draft => {
                    draft.url_value.payout_token = "payout=REPLACE";
                    draft.url_value.before_payout_ampersand = "&";
                    draft.url_value.is_payout_token_populated = true;
                }));
            }
        }
    }

    // Token change handler
    anTokenChangeHandler = (e) => {
        const arr_split = e.target.name.split("_");
        const arr_index = arr_split[arr_split.length -1];
        this.setState(produce(draft => { draft.dataToSend.parameters[arr_index].an_token = e.target.value }));
        // check if name if an_token_1
        if (e.target.name === "an_token_0") {
            if (this.state.url_value.tracking_url_code === "/postback?" && e.target.value === "") {
                this.setState(produce(draft => void (draft.url_value.click_id_token = "cid=REPLACE" )));
                this.setState(produce(draft => { draft.url_value.is_click_id_token_populated = true }));
                this.setState(produce(draft => {draft.url_value.before_payout_ampersand = "&" }));
            } else if (e.target.value !== "") {
                this.setState(produce(draft => void (draft.url_value.click_id_token = ("cid=" + e.target.value) )));
                this.setState(produce(draft => { draft.url_value.is_click_id_token_populated = true }));
            } else {
                this.setState(produce(draft => void (draft.url_value.click_id_token = "" )));
                this.setState(produce(draft => { draft.url_value.is_click_id_token_populated = false }));
            }
            // if not empty check for other & signs
            // populate &s after while checking their values
            if (e.target.value !== "" && this.state.url_value.is_payout_token_populated) {
                this.setState(produce(draft => {draft.url_value.before_payout_ampersand = "&" }));
            }
            if (e.target.value !== "" && this.state.url_value.is_tx_id_token_populated) {
                this.setState(produce(draft => {draft.url_value.before_tx_id_ampersand = "&" }));
            }
            if (e.target.value !== "" && this.state.url_value.is_et_token_populated) {
                this.setState(produce(draft => {draft.url_value.before_et_ampersand = "&" }));
            }
            // remove &s after whiles checking their values if this becomes empty
            if (e.target.value === "" && this.state.url_value.is_payout_token_populated) {
                this.setState(produce(draft => {draft.url_value.before_payout_ampersand = "&" }));
            }
            if (e.target.value === "" && this.state.url_value.is_tx_id_token_populated && !this.state.url_value.is_payout_token_populated) {
                this.setState(produce(draft => {draft.url_value.before_tx_id_ampersand = "" }));
            }
            if (e.target.value === "" && this.state.url_value.is_et_token_populated && !this.state.url_value.is_payout_token_populated && !this.state.url_value.is_tx_id_token_populated) {
                this.setState(produce(draft => {draft.url_value.before_et_ampersand = "" }));
            }
        } else if (e.target.name === "an_token_1") {
            if (e.target.value !== "") {
                this.setState(produce(draft => {
                    draft.url_value.payout_token = "payout=" + e.target.value;
                    draft.url_value.is_payout_token_populated = true;
                }));
            } else {
                console.log("inside the else condition.");
                this.setState(produce(draft => {
                    draft.url_value.payout_token = "payout=REPLACE";
                    draft.url_value.is_payout_token_populated = true;
                }));
            }
            //condition for & before the payout
            if (this.state.url_value.is_click_id_token_populated) {
                this.setState(produce(draft => { draft.url_value.before_payout_ampersand = "&" }));
            }
            // condition for & before the txid
            if (this.state.url_value.is_tx_id_token_populated && e.target.value !== "") {
                this.setState(produce(draft => { draft.url_value.before_tx_id_ampersand = "&" }));
            }
            // condition for & before et
            if (this.state.url_value.is_et_token_populated && e.target.value !== "") {
                this.setState(produce(draft => { draft.url_value.before_et_ampersand = "&" }));
            }
            //  if this empty and there is no value in click id / payout / tx-id then remove & before et
            if (this.state.url_value.is_et_token_populated && e.target.value === "" && !this.state.url_value.is_click_id_token_populated && !this.state.url_value.is_tx_id_token_populated) {
                this.setState(produce(draft => {draft.url_value.before_et_ampersand = "" }));
            }
        } else if (e.target.name === "an_token_2") {
            if (e.target.value !== "") {
                this.setState(produce(draft => { draft.url_value.tx_id_token = "txid=" + e.target.value }));
                this.setState(produce(draft => { draft.url_value.is_tx_id_token_populated = true }));
            } else {
                this.setState(produce(draft => { draft.url_value.tx_id_token = "" }));
                this.setState(produce(draft => { draft.url_value.is_tx_id_token_populated = false }));
            }
            //condition for & before the txid
            if ((this.state.url_value.is_click_id_token_populated || this.state.url_value.is_payout_token_populated) && e.target.value !== "") {
                this.setState(produce(draft => { draft.url_value.before_tx_id_ampersand = "&" }));
            }
            // condition for & before et
            if (this.state.url_value.is_et_token_populated && e.target.value !== "") {
                this.setState(produce(draft => {draft.url_value.before_et_ampersand = "&" }));
            }
            // removal condition for & before txid
            if (e.target.value === "") {
                this.setState(produce(draft => { draft.url_value.before_tx_id_ampersand = "" }))
            }
            // if empty check for the & after it
            if (e.target.value === "" && this.state.url_value.is_et_token_populated && !this.state.url_value.is_payout_token_populated && !this.state.url_value.is_click_id_token_populated) {
                this.setState(produce(draft => {draft.url_value.before_et_ampersand = "" }));
            }
        } else if (e.target.name === "an_token_3") {
            if (e.target.value !== "") {
                this.setState(produce(draft => { draft.url_value.et_token = "et=" + e.target.value }));
                this.setState(produce(draft => { draft.url_value.is_et_token_populated = true }));
            } else {
                this.setState(produce(draft => { draft.url_value.et_token = "" }));
                this.setState(produce(draft => { draft.url_value.is_et_token_populated = false }));
            }
            // condition for & before et
            if ((this.state.url_value.is_click_id_token_populated || this.state.url_value.is_payout_token_populated || this.state.url_value.is_tx_id_token_populated) && e.target.value !== "") {
                this.setState(produce(draft => { draft.url_value.before_et_ampersand = "&"}));
            }
            else {
                this.setState(produce(draft => { draft.url_value.before_et_ampersand = ""}));
            }
        }
    }


    anParameterChangeHandler = (e) => {
        this.setState(produce(draft => { draft.dataToSend.parameters[0].an_parameter = e.target.value }));
    }

    ValidateIPAddress = (ipaddress) =>  {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
            return (true)
        }
        return (false)
    }

    switchChangeHandler = (e) => {
        this.setState(produce(draft => { draft.dataToSend[e.target.name] = !this.state.dataToSend[e.target.name]}));
    }

    ipAddressSwitchChangeHandler = (e) => {
        this.setState(produce(draft => { draft.ip_address_switch_value = !this.state.ip_address_switch_value}));
        this.setState(produce(draft => { draft.dataToSend.ip_address = "" }));
    }

    ipAddressCheckHandler = (e) => {
        this.setState(produce(draft => { draft.dataToSend[e.target.name] = e.target.value }));
        let un_filtered_ip_addresses_list = e.target.value.split("\n");
        let ip_addresses_list = un_filtered_ip_addresses_list.filter(function (el) {
            return (el !== null && el !== "");
        });
        for (let i = 0; i<ip_addresses_list.length; i++) {
            if(this.ValidateIPAddress(ip_addresses_list[i])) {
                this.setState(produce(draft => { draft.ip_address_valid_value = true }));
            } else {
                this.setState(produce(draft => { draft.ip_address_valid_value = false }));
            }
        }

    }

    submitEditAffiliateNetworksData = () => {
        
        if (this.state.dataToSend.name === "") {
            this.setState(produce(draft => { draft.empty_values.name = true }));
        } else {
            this.setState(produce(draft => { draft.submitBtnDisabled = true }));
            this.sendPatchedAffiliateNetworkData();
            // setTimeout to make sure producs function has updated the state in sendPatchedAffiliateNetworkData before closing this modal
            setTimeout(() => {
                this.props.state.isDuplicate ?
                    this.props.closeAffiliateNetworkDialogueAfterSubmission("Add_Affiliate")
                    : this.props.closeAffiliateNetworkDialogueAfterSubmission("Update_Affiliate")

            },400);
        }
    }

    render () {
        return (
            <div>
                <Dialog className="add_affiliate_networks_dialogue" title={this.props.state.isDuplicate?"Duplicate affiliate network":"Edit affiliate network"} onClose={this.props.closeEditAffiliateNetworkDialog}>
                    <div className="add_affiliate_network_dialogue_content">
                        {
                            this.state.domainsDataReceived
                                ?
                                <div className="content_form">
                                    <ModalForm
                                        state={this.state}
                                        inputChangeHandler={this.inputChangeHandler}
                                        anTokenChangeHandler={this.anTokenChangeHandler}
                                        anParameterChangeHandler={this.anParameterChangeHandler}
                                        switchChangeHandler={this.switchChangeHandler}
                                        ipAddressSwitchChangeHandler={this.ipAddressSwitchChangeHandler}
                                        ipAddressCheckHandler={this.ipAddressCheckHandler}
                                    />
                                </div>
                                : null
                        }
                        {
                            // (this.state.affNetworkDataLoaded || this.state.submitBtnDisabled) && <LoadingPanel/>
                            // (this.state.affNetworkDataLoaded) && <LoadingPanel/>
                        }
                    </div>
                    <DialogActionsBar>
                        <div className="action_bar_buttons">
                            <div className="left">
                                <button onClick={this.props.closeEditAffiliateNetworkDialog} className="normal_btn cancel_button gray_button_bg_imp black border_radius_normal_btn normal_text black no_border">Close</button>
                            </div>
                            <div className="right">
                                <button onClick={this.submitEditAffiliateNetworksData} disabled={this.state.submitBtnDisabled} className="normal_btn theme_dark_btn theme_dark_bg_color_imp color_white_imp border_radius_normal_btn save_button normal_text">Save</button>
                            </div>
                        </div>
                    </DialogActionsBar>
                </Dialog>
            </div>
        );
    }
}

export const mapStateToProps = createStructuredSelector({
    domainsDataReceived: makeSelectDomainsDataReceived(),
    accessToken: makeSelectToken(),
    // Affiliate network props
    loading: makeSelectLoading(),
    success: makeSelectSuccess(),
    error: makeSelectError(),
    trackingUrlsReceived: makeSelectTrackingUrlsReceived(),
    currenciesListReceived: makeSelectLoadCurrenciesList(),
    isNewAffiliateNetworkDataSent: makeSelectIsNewAffiliateNetworkDataSent(),
    loadAffiliateNetworkData: makeSelectAffiliatedNetworkDataLoaded(),
    editAffiliateNetworkDataReceived: makeSelectEditAffiliateNetworkDataReceived(),
    isPatchedAffiliateNetworkDataSent: makeSelectIsPatchedAffiliateNetworkDataSent()

});
export function mapDispatchToProps(dispatch) {
    return {
        dispatchLoadDomains: (...payload) => dispatch(domainsActions.loadCustomDomains(...payload)),
        dispatchLoadTrackingURLS: (...payload) => dispatch(affiliatedNetworksActions.loadTrackingURLS(...payload)),
        dispatchLoadCurrencies: (...payload) => dispatch(affiliatedNetworksActions.loadCurrency(...payload)),
        dispatchLoadAffiliateNetwork: (...payload) => dispatch(affiliatedNetworksActions.loadAffiliateNetwork(...payload)),
        dispatchEditAffiliateNetwork: (...payload) => dispatch(affiliatedNetworksActions.editAffiliateNetwork(...payload)),
        dispatchSendPatchedAffiliateNetwork: (...payload) => dispatch(affiliatedNetworksActions.sendEditAffiliateNetwork(...payload)),
        dispatchAddNewAffiliateNetwork: (...payload) => dispatch(affiliatedNetworksActions.postNewAffiliateNetwork(...payload)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAffiliateNetworksModal);