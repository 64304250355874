import {
    LOAD_SETTINGS, LOAD_SETTINGS_FAILED, LOAD_SETTINGS_SUCCESS,
    UPDATE_SETTINGS, UPDATE_SETTINGS_FAILED, UPDATE_SETTINGS_SUCCESS
} from './constants';


export const loadSettings = (accessToken) => {
    return {
        type:LOAD_SETTINGS,
        payload:{accessToken}
    }
}


export const loadSettingsSuccess = (settingData) => {
    return {
        type:LOAD_SETTINGS_SUCCESS,
        payload:{settingData}
    }
}

export const loadSettingsFailed = (error) => {
    return {
        type:LOAD_SETTINGS_FAILED,
        payload:{error}
    }
}


export const updateSettings = (accessToken,data) => {
    return {
        type:UPDATE_SETTINGS,
        payload:{accessToken,data}
    }
}


export const updateSettingsSuccess = (data) => {
    return {
        type:UPDATE_SETTINGS_SUCCESS,
        payload:{data}
    }
}

export const updateSettingsFailed = (error) => {
    return {
        type:UPDATE_SETTINGS_FAILED,
        payload:{error}
    }
}

