import React, { useState } from "react";
import { RadioButton } from "@progress/kendo-react-inputs";
import { Input } from "@progress/kendo-react-inputs";
import "./newpath.css";
import Tooltip from "../../../flowmodal/tooltip/tooltip";
import ReactTooltip from "react-tooltip";
import { Fade } from "@progress/kendo-react-animation";
import FlowWeightageComponent from "./../../../../../../components/weightageComponent/WeightageComponent";
import { Error } from "@progress/kendo-react-labels";

const NewPath = (props) => {
  // console.log('props',props.landersDataReceived, props.offersDataReceived);
  // console.log('state', props.state);
  const [sort, setSort] = useState(true);
  const [reset, setReset] = useState(false);

  const [show, setShow] = useState(false);
  const showOffers = () => {
    setShow(true);
  };
  const onSortButtonClick = (e) => {
    if (sort) {
      setSort(false);
      setReset(true);
    } else {
      setSort(true);
      setReset(false);
    }
  };

  const handlePathDestinationChange = (e) => {
    if (props.state.onDefaultPathClick) {
      props.handleDefaultPathChange(e);
    } else {
      props.handleRulePathChange(e);
    }
  };
  const handlePathRedirectChange = (e) => {
    if (props.state.onDefaultPathClick) {
      // console.log('default');
      props.handleDefaultPathChange(e);
    } else {
      //console.log('rule');
      props.handleRulePathChange(e);
    }
  };

  const onChangePathName = (e) => {
    if (props.state.onDefaultPathClick) {
      props.handleDefaultPathChange(e);
    } else {
      props.handleRulePathChange(e);
    }
  };
  // props.state.counts.landersAdded > 0 ?
  //props.state.dataToMap.landers.map((lander,index) => (

  //console.log('props.state.isDefault[props.state.newDefaultPathIndex].counts.landersAdded', props.state.is_default[props.state.newDefaultPathIndex].counts);
  //console.log('props.state.isDefault[props.state.newDefaultPathIndex].dataToMap.landers',props.state.is_default[props.state.newDefaultPathIndex].dataToMap.landers);
  //console.log('values in function',props.state.dataToSend.path.rule[props.state.newRuleIndex].path[props.state.newRulePathIndex].path_redirect_mode);
  //console.log('props.offersDataReceived', props.offersDataReceived[0].name);
  //console.log("data", props.state);
  // console.log('data');
  // console.log('props.state.editClicked? props.state.dataToSend.path.is_default[props.state.newDefaultPathIndex].name',props.state.editClicked);
  //console.log('landers and offers', props.offersDataReceived, props.landersDataReceived);
  // console.log('props.state.newRuleIndex',props.state.newRuleIndex);
  // console.log('rule',props.state.dataToSend.path);
  // console.log('props.staet.newRulePathIndex',props.state.newRulePathIndex);
  return (
    <div className="new-right-flow">
      <div className="path">
        <label className="flow-name-label">Path name</label>
        {props.state.editClicked ? (
          <>
            <Input
              className="new-path-input"
              value={
                props.state.onDefaultPathClick
                  ? props.state.dataToSend.path.is_default[
                      props.state.newDefaultPathIndex
                    ].name
                  : props.state.dataToSend.path.rule[props.state.newRuleIndex]
                      .path[props.state.newRulePathIndex].name
              }
              required
              placeholder="Type path name"
              onChange={onChangePathName}
              name="path_name"
              ariaDescribedBy={"rule_path_name"}
            ></Input>
            {props.state.errors.rule_path_name !== null && (
              <Error id={"rule_path_name"}>
                {props.state.errors.rule_path_name}
              </Error>
            )}
          </>
        ) : (
          <>
            <Input
              className="new-path-input"
              value={
                props.state.onDefaultPathClick
                  ? props.state.dataToSend.path.is_default[
                      props.state.newDefaultPathIndex
                    ].path_name
                  : props.state.dataToSend.path.rule[props.state.newRuleIndex]
                      .path[props.state.newRulePathIndex].path_name
              }
              required
              placeholder="Type path name"
              onChange={onChangePathName}
              name="path_name"
              ariaDescribedBy={"default_path_name"}
            ></Input>
            {props.state.onDefaultPathClick
              ? props.state.errors.default_path_name !== "" &&
                props.state.dataToSend.path.is_default[
                  props.state.newDefaultPathIndex
                ].path_name === "" && (
                  <Error id={"default_path_name"}>
                    {props.state.errors.default_path_name}
                  </Error>
                )
              : props.state.errors.default_path_name !== "" &&
                props.state.dataToSend.path.rule[props.state.newRuleIndex].path[
                  props.state.newRulePathIndex
                ].path_name === "" && (
                  <Error id={"default_path_name"}>
                    {props.state.errors.default_path_name}
                  </Error>
                )}
          </>
        )}
        {/* <Input
                    className="new-path-input"
                    value={
                            props.state.onDefaultPathClick
                            ? props.state.dataToSend.path.is_default[props.state.newDefaultPathIndex].path_name
                            : props.state.dataToSend.path.rule[props.state.newRuleIndex].path[props.state.newRulePathIndex].path_name
                    }
                    required
                    placeholder="Type path name"
                    onChange={onChangePathName}
                    name="path_name"
                ></Input> */}
        <hr></hr>
        <label className="flow-name-label">Path Destination</label>
        <div className="row path-destination radio-buttons">
          <RadioButton
            className="buttons"
            label="Lander & Offer"
            name="path_destination"
            value="Lander & Offer"
            checked={
              props.state.onDefaultPathClick
                ? props.state.dataToSend.path.is_default[
                    props.state.newDefaultPathIndex
                  ].path_destination === "Lander & Offer"
                : props.state.dataToSend.path.rule[props.state.newRuleIndex]
                    .path[props.state.newRulePathIndex].path_destination ===
                  "Lander & Offer"
            }
            onChange={handlePathDestinationChange}
          />
          <RadioButton
            className="buttons"
            label="Offer Only"
            name="path_destination"
            value="Offer Only"
            checked={
              props.state.onDefaultPathClick
                ? props.state.dataToSend.path.is_default[
                    props.state.newDefaultPathIndex
                  ].path_destination === "Offer Only"
                : props.state.dataToSend.path.rule[props.state.newRuleIndex]
                    .path[props.state.newRulePathIndex].path_destination ===
                  "Offer Only"
            }
            onChange={handlePathDestinationChange}
          />
        </div>
        <hr></hr>
        <div className="row">
          <label className="flow-name-label">Redirect mode</label>
          <Tooltip
            datafor={"redirect_tooltip"}
            text={
              "It determines how redirection to the campaign’s endpoint is handled and whether referrer data is passed through or not. Learn more"
            }
          />
        </div>
        {props.state.editClicked ? (
          <div className="row path-offer-redirect radio-buttons">
            <RadioButton
              className="buttons"
              label="302"
              name="redirect_mode"
              value="302"
              checked={
                props.state.onDefaultPathClick
                  ? props.state.dataToSend.path.is_default[
                      props.state.newDefaultPathIndex
                    ].redirect_mode === "302"
                  : props.state.dataToSend.path.rule[props.state.newRuleIndex]
                      .path[props.state.newRulePathIndex].redirect_mode ===
                    "302"
              }
              onChange={handlePathRedirectChange}
            />
            <RadioButton
              className="buttons"
              label="Meta Refresh"
              name="redirect_mode"
              value="Meta Refresh"
              checked={
                props.state.onDefaultPathClick
                  ? props.state.dataToSend.path.is_default[
                      props.state.newDefaultPathIndex
                    ].redirect_mode === "Meta Refresh"
                  : props.state.dataToSend.path.rule[props.state.newRuleIndex]
                      .path[props.state.newRulePathIndex].redirect_mode ===
                    "Meta Refresh"
              }
              onChange={handlePathRedirectChange}
            />
            <RadioButton
              className="buttons"
              label="Double Meta Refresh"
              name="redirect_mode"
              value="Double Meta Refresh"
              checked={
                props.state.onDefaultPathClick
                  ? props.state.dataToSend.path.is_default[
                      props.state.newDefaultPathIndex
                    ].redirect_mode === "Double Meta Refresh"
                  : props.state.dataToSend.path.rule[props.state.newRuleIndex]
                      .path[props.state.newRulePathIndex].redirect_mode ===
                    "Double Meta Refresh"
              }
              onChange={handlePathRedirectChange}
            />
            <RadioButton
              className="buttons"
              label="Redirectless"
              name="redirect_mode"
              value="Redirectless"
              checked={
                props.state.onDefaultPathClick
                  ? props.state.dataToSend.path.is_default[
                      props.state.newDefaultPathIndex
                    ].redirect_mode === "Redirectless"
                  : props.state.dataToSend.path.rule[props.state.newRuleIndex]
                      .path[props.state.newRulePathIndex].redirect_mode ===
                    "Redirectless"
              }
              onChange={handlePathRedirectChange}
            />
          </div>
        ) : (
          <div className="row path-offer-redirect radio-buttons">
            <RadioButton
              className="buttons"
              label="302"
              name="path_redirect_mode"
              value="302"
              checked={
                props.state.onDefaultPathClick
                  ? props.state.dataToSend.path.is_default[
                      props.state.newDefaultPathIndex
                    ].path_redirect_mode === "302"
                  : props.state.dataToSend.path.rule[props.state.newRuleIndex]
                      .path[props.state.newRulePathIndex].path_redirect_mode ===
                    "302"
              }
              onChange={handlePathRedirectChange}
            />
            <RadioButton
              className="buttons"
              label="Meta Refresh"
              name="path_redirect_mode"
              value="Meta Refresh"
              checked={
                props.state.onDefaultPathClick
                  ? props.state.dataToSend.path.is_default[
                      props.state.newDefaultPathIndex
                    ].path_redirect_mode === "Meta Refresh"
                  : props.state.dataToSend.path.rule[props.state.newRuleIndex]
                      .path[props.state.newRulePathIndex].path_redirect_mode ===
                    "Meta Refresh"
              }
              onChange={handlePathRedirectChange}
            />
            <RadioButton
              className="buttons"
              label="Double Meta Refresh"
              name="path_redirect_mode"
              value="Double Meta Refresh"
              checked={
                props.state.onDefaultPathClick
                  ? props.state.dataToSend.path.is_default[
                      props.state.newDefaultPathIndex
                    ].path_redirect_mode === "Double Meta Refresh"
                  : props.state.dataToSend.path.rule[props.state.newRuleIndex]
                      .path[props.state.newRulePathIndex].path_redirect_mode ===
                    "Double Meta Refresh"
              }
              onChange={handlePathRedirectChange}
            />
            <RadioButton
              className="buttons"
              label="Redirectless"
              name="path_redirect_mode"
              value="Redirectless"
              checked={
                props.state.onDefaultPathClick
                  ? props.state.dataToSend.path.is_default[
                      props.state.newDefaultPathIndex
                    ].path_redirect_mode === "Redirectless"
                  : props.state.dataToSend.path.rule[props.state.newRuleIndex]
                      .path[props.state.newRulePathIndex].path_redirect_mode ===
                    "Redirectless"
              }
              onChange={handlePathRedirectChange}
            />
          </div>
        )}

        <hr></hr>
        {props.state.onDefaultPathClick ? (
          props.state.dataToSend.path.is_default[
            props.state.newDefaultPathIndex
          ].path_destination === "Lander & Offer" ? (
            <>
              <div className="header">
                <section className="k-mt-8">
                  <div className="flex_center campaigns_landers_heading light_grey_border_bottom k-pb-2">
                    <div className="flex_center">
                      <h3>Landers</h3>
                      <span
                        data-tip
                        data-for="c_modal_ch_tooltip"
                        className="question_mark"
                      >
                        <span className="k-icon k-i-question-circle"></span>
                      </span>
                      <ReactTooltip id="c_modal_ch_tooltip" type="dark">
                        <span>You can add upto 50 Landers</span>
                      </ReactTooltip>
                    </div>
                    <div>
                      {props.state.is_default[props.state.newDefaultPathIndex]
                        .counts.landersAdded <
                      props.state.is_default[props.state.newDefaultPathIndex]
                        .counts.landerInBackend ? (
                        <span
                          style={{ cursor: "pointer" }}
                          id="add_lander"
                          className="normal_text normal_font theme_blue_color"
                          onClick={props.addLandersAndOffersMethod}
                        >
                          <span className="k-icon k-i-plus"></span>
                          Add Landers
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex_row">
                    <p className="normal_text k-ml-0 k-pl-0 k-mt-3 k-mb-2 color_black">
                      Add landers to this path (you can add multiple landers at
                      once).
                    </p>
                    <div>
                      {props.state.is_default[props.state.newDefaultPathIndex]
                        .allLandersDataLoaded !== null
                        ? props.state.is_default[
                            props.state.newDefaultPathIndex
                          ].counts.landersAdded > 0
                          ? props.state.is_default[
                              props.state.newDefaultPathIndex
                            ].dataToMap.landers.map((lander, index) => (
                              <FlowWeightageComponent
                                type="lander"
                                name={lander.name}
                                options={lander.options}
                                selectedId={lander.selectedId}
                                weightageNumber={lander.weightageNumber}
                                weightagePercentage={lander.weightagePercentage}
                                increaseWeightMethod={
                                  props.increaseWeightMethod
                                }
                                decreaseWeightMethod={
                                  props.decreaseWeightMethod
                                }
                                index={index}
                                weightageSelectTagChangeHandler={
                                  props.weightageSelectTagChangeHandler
                                }
                                deleteWeightageMethod={
                                  props.deleteWeightageMethod
                                }
                                changeWeightageHandler={
                                  props.changeWeightageHandler
                                }
                              />
                            ))
                          : null
                        : null}
                    </div>
                  </div>
                  {props.state.errors.default_path_lander !== null &&
                  props.state.dataToSend.path.is_default[
                    props.state.newDefaultPathIndex
                  ].landerId.length === 0 ? (
                    <Fade enter={true} exit={true}>
                      <p className="danger_para k-mt-2 mb-10 mh-0">
                        {props.state.errors.default_path_lander}
                      </p>
                    </Fade>
                  ) : null}
                </section>

                <section className="k-mt-3">
                  <div className="flex_center campaigns_landers_heading light_grey_border_bottom k-pb-2">
                    <div className="flex_center">
                      <h3>Offers</h3>
                      <span
                        data-tip
                        data-for="c_modal_ch_offers_tooltip"
                        className="question_mark"
                      >
                        <span className="k-icon k-i-question-circle"></span>
                      </span>
                      <ReactTooltip id="c_modal_ch_offers_tooltip" type="dark">
                        <span>You can add upto 50 Offers</span>
                      </ReactTooltip>
                    </div>
                    <div>
                      {props.state.is_default[props.state.newDefaultPathIndex]
                        .counts.offersAdded <
                      props.state.is_default[props.state.newDefaultPathIndex]
                        .counts.offersInBackend ? (
                        <span
                          style={{ cursor: "pointer" }}
                          id="add_offer"
                          className="normal_text normal_font theme_blue_color"
                          onClick={props.addLandersAndOffersMethod}
                        >
                          <span
                            className="k-icon k-i-plus"
                            style={{ cursor: "unset" }}
                          ></span>
                          Add Offers
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex_row">
                    <p className="normal_text k-ml-0 k-pl-0 k-mt-3 k-mb-2 color_black">
                      Add Offers to this path (you can add multiple offers at
                      once).
                    </p>
                    <div>
                      {props.state.is_default[props.state.newDefaultPathIndex]
                        .allOffersDataLoaded !== null
                        ? props.state.is_default[
                            props.state.newDefaultPathIndex
                          ].counts.offersAdded > 0
                          ? props.state.is_default[
                              props.state.newDefaultPathIndex
                            ].dataToMap.offers.map((offer, index) => (
                              <FlowWeightageComponent
                                type="offer"
                                name={offer.name}
                                options={offer.options}
                                selectedId={offer.selectedId}
                                weightageNumber={offer.weightageNumber}
                                weightagePercentage={offer.weightagePercentage}
                                increaseWeightMethod={
                                  props.increaseWeightMethod
                                }
                                decreaseWeightMethod={
                                  props.decreaseWeightMethod
                                }
                                index={index}
                                weightageSelectTagChangeHandler={
                                  props.weightageSelectTagChangeHandler
                                }
                                deleteWeightageMethod={
                                  props.deleteWeightageMethod
                                }
                                changeWeightageHandler={
                                  props.changeWeightageHandler
                                }
                              />
                            ))
                          : null
                        : null}
                    </div>
                  </div>
                  {props.state.errors.default_path_offer !== null &&
                  props.state.dataToSend.path.is_default[
                    props.state.newDefaultPathIndex
                  ].offerId.length === 0 ? (
                    <Fade enter={true} exit={true}>
                      <p className="danger_para k-mt-2 mb-10 mh-0">
                        {props.state.errors.default_path_offer}
                      </p>
                    </Fade>
                  ) : null}
                </section>
              </div>
            </>
          ) : props.state.onDefaultPathClick ? (
            props.state.dataToSend.path.is_default[
              props.state.newDefaultPathIndex
            ].path_destination === "Offer Only" ? (
              <>
                <div className="header">
                  <section className="k-mt-3">
                    <div className="flex_center campaigns_landers_heading light_grey_border_bottom k-pb-2">
                      <div className="flex_center">
                        <h3>Offers</h3>
                        <span
                          data-tip
                          data-for="c_modal_ch_offers_tooltip"
                          className="question_mark"
                        >
                          <span className="k-icon k-i-question-circle"></span>
                        </span>
                        <ReactTooltip
                          id="c_modal_ch_offers_tooltip"
                          type="dark"
                        >
                          <span>You can add upto 50 Offers</span>
                        </ReactTooltip>
                      </div>
                      <div>
                        {props.state.is_default[props.state.newDefaultPathIndex]
                          .counts.offersAdded <
                        props.state.is_default[props.state.newDefaultPathIndex]
                          .counts.offersInBackend ? (
                          <span
                            style={{ cursor: "pointer" }}
                            id="add_offer"
                            className="normal_text normal_font theme_blue_color"
                            onClick={props.addLandersAndOffersMethod}
                          >
                            <span className="k-icon k-i-plus"></span>
                            Add Offers
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="flex_row">
                      <p className="normal_text k-ml-0 k-pl-0 k-mt-3 k-mb-2 color_black">
                        Add Offers to this path (you can add multiple offers at
                        once).
                      </p>
                      <div>
                        {props.state.is_default[props.state.newDefaultPathIndex]
                          .allOffersDataLoaded !== null
                          ? props.state.is_default[
                              props.state.newDefaultPathIndex
                            ].counts.offersAdded > 0
                            ? props.state.is_default[
                                props.state.newDefaultPathIndex
                              ].dataToMap.offers.map((offer, index) => (
                                <FlowWeightageComponent
                                  type="offer"
                                  name={offer.name}
                                  options={offer.options}
                                  selectedId={offer.selectedId}
                                  weightageNumber={offer.weightageNumber}
                                  weightagePercentage={
                                    offer.weightagePercentage
                                  }
                                  increaseWeightMethod={
                                    props.increaseWeightMethod
                                  }
                                  decreaseWeightMethod={
                                    props.decreaseWeightMethod
                                  }
                                  index={index}
                                  weightageSelectTagChangeHandler={
                                    props.weightageSelectTagChangeHandler
                                  }
                                  deleteWeightageMethod={
                                    props.deleteWeightageMethod
                                  }
                                  changeWeightageHandler={
                                    props.changeWeightageHandler
                                  }
                                />
                              ))
                            : null
                          : null}
                      </div>
                    </div>
                    {props.state.errors.default_path_offer !== null &&
                    props.state.dataToSend.path.is_default[
                      props.state.newDefaultPathIndex
                    ].offerId.length === 0 ? (
                      <Fade enter={true} exit={true}>
                        <p className="danger_para k-mt-2 mb-10 mh-0">
                          {props.state.errors.default_path_offer}
                        </p>
                      </Fade>
                    ) : null}
                  </section>
                </div>
              </>
            ) : null
          ) : null
        ) : null}
        {props.state.onDefaultPathClick === false ? (
          props.state.dataToSend.path.rule[props.state.newRuleIndex].path[
            props.state.newRulePathIndex
          ].path_destination === "Lander & Offer" ? (
            <>
              <div className="header">
                <section className="k-mt-8">
                  <div className="flex_center campaigns_landers_heading light_grey_border_bottom k-pb-2">
                    <div className="flex_center">
                      <h3>Landers</h3>
                      <span
                        data-tip
                        data-for="c_modal_ch_tooltip"
                        className="question_mark"
                      >
                        <span className="k-icon k-i-question-circle"></span>
                      </span>
                      <ReactTooltip id="c_modal_ch_tooltip" type="dark">
                        <span>You can add upto 50 Landers</span>
                      </ReactTooltip>
                    </div>
                    <div>
                      {props.state.rule[props.state.newRuleIndex].path[
                        props.state.newRulePathIndex
                      ].counts.landersAdded <
                      props.state.rule[props.state.newRuleIndex].path[
                        props.state.newRulePathIndex
                      ].counts.landerInBackend ? (
                        <span
                          id="add_lander"
                          className="normal_text normal_font theme_blue_color"
                          onClick={props.addLandersAndOffersMethod}
                          style={{ cursor: "pointer" }}
                        >
                          <span className="k-icon k-i-plus"></span>
                          Add Landers
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex_row">
                    <p className="normal_text k-ml-0 k-pl-0 k-mt-3 k-mb-2 color_black">
                      Add landers to this path (you can add multiple landers at
                      once).
                    </p>
                    <div>
                      {props.state.rule[props.state.newRuleIndex].path[
                        props.state.newRulePathIndex
                      ].allLandersDataLoaded !== null
                        ? props.state.rule[props.state.newRuleIndex].path[
                            props.state.newRulePathIndex
                          ].counts.landersAdded > 0
                          ? props.state.rule[props.state.newRuleIndex].path[
                              props.state.newRulePathIndex
                            ].dataToMap.landers.map((lander, index) => (
                              <FlowWeightageComponent
                                type="lander"
                                name={lander.name}
                                options={lander.options}
                                selectedId={lander.selectedId}
                                weightageNumber={lander.weightageNumber}
                                weightagePercentage={lander.weightagePercentage}
                                increaseWeightMethod={
                                  props.increaseWeightMethod
                                }
                                decreaseWeightMethod={
                                  props.decreaseWeightMethod
                                }
                                index={index}
                                weightageSelectTagChangeHandler={
                                  props.weightageSelectTagChangeHandler
                                }
                                deleteWeightageMethod={
                                  props.deleteWeightageMethod
                                }
                                changeWeightageHandler={
                                  props.changeWeightageHandler
                                }
                              />
                            ))
                          : null
                        : null}
                    </div>
                  </div>
                  {props.state.errors.rule_path_lander !== null &&
                  props.state.dataToSend.path.rule[props.state.newRuleIndex]
                    .path[props.state.newRulePathIndex].landerId.length ===
                    0 ? (
                    <Fade enter={true} exit={true}>
                      <p className="danger_para k-mt-2 mb-10 mh-0">
                        {props.state.errors.rule_path_lander}
                      </p>
                    </Fade>
                  ) : null}
                </section>

                <section className="k-mt-3">
                  <div className="flex_center campaigns_landers_heading light_grey_border_bottom k-pb-2">
                    <div className="flex_center">
                      <h3>Offers</h3>
                      <span
                        data-tip
                        data-for="c_modal_ch_offers_tooltip"
                        className="question_mark"
                      >
                        <span className="k-icon k-i-question-circle"></span>
                      </span>
                      <ReactTooltip id="c_modal_ch_offers_tooltip" type="dark">
                        <span>You can add upto 50 Offers</span>
                      </ReactTooltip>
                    </div>
                    <div>
                      {props.state.rule[props.state.newRuleIndex].path[
                        props.state.newRulePathIndex
                      ].counts.offersAdded <
                      props.state.rule[props.state.newRuleIndex].path[
                        props.state.newRulePathIndex
                      ].counts.offersInBackend ? (
                        <span
                          id="add_offer"
                          className="normal_text normal_font theme_blue_color"
                          onClick={props.addLandersAndOffersMethod}
                          style={{ cursor: "pointer" }}
                        >
                          <span className="k-icon k-i-plus"></span>
                          Add Offers
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex_row">
                    <p className="normal_text k-ml-0 k-pl-0 k-mt-3 k-mb-2 color_black">
                      Add Offers to this path (you can add multiple offers at
                      once).
                    </p>
                    <div>
                      {props.state.rule[props.state.newRuleIndex].path[
                        props.state.newRulePathIndex
                      ].allOffersDataLoaded !== null
                        ? props.state.rule[props.state.newRuleIndex].path[
                            props.state.newRulePathIndex
                          ].counts.offersAdded > 0
                          ? props.state.rule[props.state.newRuleIndex].path[
                              props.state.newRulePathIndex
                            ].dataToMap.offers.map((offer, index) => (
                              <FlowWeightageComponent
                                type="offer"
                                name={offer.name}
                                options={offer.options}
                                selectedId={offer.selectedId}
                                weightageNumber={offer.weightageNumber}
                                weightagePercentage={offer.weightagePercentage}
                                increaseWeightMethod={
                                  props.increaseWeightMethod
                                }
                                decreaseWeightMethod={
                                  props.decreaseWeightMethod
                                }
                                index={index}
                                weightageSelectTagChangeHandler={
                                  props.weightageSelectTagChangeHandler
                                }
                                deleteWeightageMethod={
                                  props.deleteWeightageMethod
                                }
                                changeWeightageHandler={
                                  props.changeWeightageHandler
                                }
                              />
                            ))
                          : null
                        : null}
                    </div>
                  </div>
                  {props.state.errors.rule_path_offer !== null &&
                  props.state.dataToSend.path.rule[props.state.newRuleIndex]
                    .path[props.state.newRulePathIndex].offerId.length === 0 ? (
                    <Fade enter={true} exit={true}>
                      <p className="danger_para k-mt-2 mb-10 mh-0">
                        {props.state.errors.rule_path_offer}
                      </p>
                    </Fade>
                  ) : null}
                </section>
                {/*landers and offers section end*/}
              </div>
            </>
          ) : props.state.onDefaultPathClick === false ? (
            props.state.dataToSend.path.rule[props.state.newRuleIndex].path[
              props.state.newRulePathIndex
            ].path_destination === "Offer Only" ? (
              <>
                <div className="header">
                  <section className="k-mt-3">
                    <div className="flex_center campaigns_landers_heading light_grey_border_bottom k-pb-2">
                      <div className="flex_center">
                        <h3>Offers</h3>
                        <span
                          data-tip
                          data-for="c_modal_ch_offers_tooltip"
                          className="question_mark"
                        >
                          <span className="k-icon k-i-question-circle"></span>
                        </span>
                        <ReactTooltip
                          id="c_modal_ch_offers_tooltip"
                          type="dark"
                        >
                          <span>You can add upto 50 Offers</span>
                        </ReactTooltip>
                      </div>
                      <div>
                        {props.state.rule[props.state.newRuleIndex].path[
                          props.state.newRulePathIndex
                        ].counts.offersAdded <
                        props.state.rule[props.state.newRuleIndex].path[
                          props.state.newRulePathIndex
                        ].counts.offersInBackend ? (
                          <span
                            id="add_offer"
                            className="normal_text normal_font theme_blue_color"
                            onClick={props.addLandersAndOffersMethod}
                            style={{ cursor: "pointer" }}
                          >
                            <span className="k-icon k-i-plus"></span>
                            Add Offers
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="flex_row">
                      <p className="normal_text k-ml-0 k-pl-0 k-mt-3 k-mb-2 color_black">
                        Add Offers to this path (you can add multiple offers at
                        once).
                      </p>
                      <div>
                        {props.state.rule[props.state.newRuleIndex].path[
                          props.state.newRulePathIndex
                        ].allOffersDataLoaded !== null
                          ? props.state.rule[props.state.newRuleIndex].path[
                              props.state.newRulePathIndex
                            ].counts.offersAdded > 0
                            ? props.state.rule[props.state.newRuleIndex].path[
                                props.state.newRulePathIndex
                              ].dataToMap.offers.map((offer, index) => (
                                <FlowWeightageComponent
                                  type="offer"
                                  name={offer.name}
                                  options={offer.options}
                                  selectedId={offer.selectedId}
                                  weightageNumber={offer.weightageNumber}
                                  weightagePercentage={
                                    offer.weightagePercentage
                                  }
                                  increaseWeightMethod={
                                    props.increaseWeightMethod
                                  }
                                  decreaseWeightMethod={
                                    props.decreaseWeightMethod
                                  }
                                  index={index}
                                  weightageSelectTagChangeHandler={
                                    props.weightageSelectTagChangeHandler
                                  }
                                  deleteWeightageMethod={
                                    props.deleteWeightageMethod
                                  }
                                  changeWeightageHandler={
                                    props.changeWeightageHandler
                                  }
                                />
                              ))
                            : null
                          : null}
                      </div>
                    </div>
                    {props.state.errors.rule_path_offer !== null &&
                    props.state.dataToSend.path.rule[props.state.newRuleIndex]
                      .path[props.state.newRulePathIndex].offerId.length ===
                      0 ? (
                      <Fade enter={true} exit={true}>
                        <p className="danger_para k-mt-2 mb-10 mh-0">
                          {props.state.errors.rule_path_offer}
                        </p>
                      </Fade>
                    ) : null}
                  </section>
                </div>
              </>
            ) : null
          ) : null
        ) : null}
        <hr></hr>
      </div>
    </div>
  );
};

export default NewPath;
