import {

    LOAD_CURRENCY,
    LOAD_CURRENCY_SUCCESS,
    LOAD_CURRENCY_FAILED,

    POST_TRAFFIC_SOURCE_DATA,
    POST_TRAFFIC_SOURCE_DATA_SUCCESS,
    POST_TRAFFIC_SOURCE_DATA_FAILED,

    LOAD_TRAFFIC_SOURCES_DATA,
    LOAD_TRAFFIC_SOURCES_DATA_SUCCESS,
    LOAD_TRAFFIC_SOURCES_DATA_FAILED,

    LOAD_EDIT_TRAFFIC_SOURCE_DATA,
    LOAD_EDIT_TRAFFIC_SOURCE_DATA_SUCCESS,
    LOAD_EDIT_TRAFFIC_SOURCE_DATA_FAILED,

    SEND_EDIT_TRAFFIC_SOURCE_DATA,
    SEND_EDIT_TRAFFIC_SOURCE_DATA_SUCCESS,
    SEND_EDIT_TRAFFIC_SOURCE_DATA_FAILED,

    DELETE_TRAFFIC_SOURCE_DATA,
    DELETE_TRAFFIC_SOURCE_DATA_SUCCESS,
    DELETE_TRAFFIC_SOURCE_DATA_FAILED,

    ARCHIVE_TRAFFIC_SOURCE_DATA,
    ARCHIVE_TRAFFIC_SOURCE_DATA_SUCCESS,
    ARCHIVE_TRAFFIC_SOURCE_DATA_FAILED,

    LOAD_TOKEN_TAGS,
    LOAD_TOKEN_TAGS_SUCCESS,
    LOAD_TOKEN_TAGS_FAILED,

    LOAD_CUSTOM_TRAFFIC_SOURCE,
    LOAD_CUSTOM_TRAFFIC_SOURCE_SUCCESS,
    LOAD_CUSTOM_TRAFFIC_SOURCE_FAILED,

    LOAD_CUSTOM_TRAFFIC_SOURCE_BY_ID,
    LOAD_CUSTOM_TRAFFIC_SOURCE_BY_ID_SUCCESS,
    LOAD_CUSTOM_TRAFFIC_SOURCE_BY_ID_FAILED,
    
    RESET_CUSTOM_TRAFFIC_SOURCE_DATA_BY_ID,
    RESET_EDIT_TRAFFIC_SOURCE_DATA,

    GET_TS_CHARTS_DATA,
    GET_TS_CHARTS_DATA_SUCCESS,
    GET_TS_CHARTS_DATA_FAILED
} from "./constants";


// Loading Currency Actions
export const loadCurrency = (loadCurrencyPayload) => {
    return {
        type: LOAD_CURRENCY,
        payload: loadCurrencyPayload
    };
};

export const loadCurrencySuccess = (loadCurrencySuccessPayload) => {
    return {
        type: LOAD_CURRENCY_SUCCESS,
        payload: loadCurrencySuccessPayload
    };
};

export const loadCurrencyFailed = (error) => {
    return {
        type: LOAD_CURRENCY_FAILED,
        payload: error
    };
};


// Sending New Traffic Sources data Actions
export const postTrafficSource = (postTrafficSourcesPayload) => {
    return {
        type: POST_TRAFFIC_SOURCE_DATA,
        payload: postTrafficSourcesPayload
    };
};

export const postTrafficSourceSuccess = (postTrafficSourcesSuccessPayload) => {
    return {
        type: POST_TRAFFIC_SOURCE_DATA_SUCCESS,
        payload: postTrafficSourcesSuccessPayload
    };
};

export const postTrafficSourceFailed = (error) => {
    return {
        type: POST_TRAFFIC_SOURCE_DATA_FAILED,
        payload: error
    };
};


// Load Traffic Sources data Actions
export const loadTrafficSources = (loadTrafficSourcesPayload) => {
    return {
        type: LOAD_TRAFFIC_SOURCES_DATA,
        payload: loadTrafficSourcesPayload
    };
};

export const loadTrafficSourcesSuccess = (loadTrafficSourcesSuccessPayload) => {
    return {
        type: LOAD_TRAFFIC_SOURCES_DATA_SUCCESS,
        payload: loadTrafficSourcesSuccessPayload
    };
};

export const loadTrafficSourcesFailed = (error) => {
    return {
        type: LOAD_TRAFFIC_SOURCES_DATA_FAILED,
        payload: error
    };
};

// EDIT Traffic Sources data Actions
export const loadEditTrafficSource = (editTrafficSourcesPayload) => {
    return {
        type: LOAD_EDIT_TRAFFIC_SOURCE_DATA,
        payload: editTrafficSourcesPayload
    };
};

export const loadEditTrafficSourceSuccess = (editTrafficSourcesSuccessPayload) => {
    return {
        type: LOAD_EDIT_TRAFFIC_SOURCE_DATA_SUCCESS,
        payload: editTrafficSourcesSuccessPayload
    };
};

export const loadEditTrafficSourceFailed = (error) => {
    return {
        type: LOAD_EDIT_TRAFFIC_SOURCE_DATA_FAILED,
        payload: error
    };
};


// Send EDIT Traffic Sources data Actions
export const sendEditTrafficSource = (sendEditTrafficSourcesPayload) => {
    return {
        type: SEND_EDIT_TRAFFIC_SOURCE_DATA,
        payload: sendEditTrafficSourcesPayload
    };
};

export const sendEditTrafficSourceSuccess = (sendEditTrafficSourcesSuccessPayload) => {
    return {
        type: SEND_EDIT_TRAFFIC_SOURCE_DATA_SUCCESS,
        payload: sendEditTrafficSourcesSuccessPayload
    };
};

export const sendEditTrafficSourceFailed = (error) => {
    return {
        type: SEND_EDIT_TRAFFIC_SOURCE_DATA_FAILED,
        payload: error
    };
};


// Delete Traffic Sources data Actions
export const deleteTrafficSource = (deleteTrafficSourcesPayload) => {
    return {
        type: DELETE_TRAFFIC_SOURCE_DATA,
        payload: deleteTrafficSourcesPayload
    };
};

export const deleteTrafficSourceSuccess = (deleteTrafficSourcesSuccessPayload) => {
    return {
        type: DELETE_TRAFFIC_SOURCE_DATA_SUCCESS,
        payload: deleteTrafficSourcesSuccessPayload
    };
};

export const deleteTrafficSourceFailed = (error) => {
    return {
        type: DELETE_TRAFFIC_SOURCE_DATA_FAILED,
        payload: error
    };
};

// Delete Traffic Sources data Actions
export const archiveTrafficSource = (payload) => {
    return {
        type: ARCHIVE_TRAFFIC_SOURCE_DATA,
        payload: payload
    };
};

export const archiveTrafficSourceSuccess = (payload) => {
    return {
        type: ARCHIVE_TRAFFIC_SOURCE_DATA_SUCCESS,
        payload: payload
    };
};

export const archiveTrafficSourceFailed = (error) => {
    return {
        type: ARCHIVE_TRAFFIC_SOURCE_DATA_FAILED,
        payload: error
    };
};

// Load Tokens Dictionary data Actions
export const loadTokensDictionary = (loadTokensDictionaryPayload) => {
    return {
        type: LOAD_TOKEN_TAGS,
        payload: loadTokensDictionaryPayload
    };
};

export const loadTokensDictionarySuccess = (loadTokensDictionarySuccessPayload) => {
    return {
        type: LOAD_TOKEN_TAGS_SUCCESS,
        payload: loadTokensDictionarySuccessPayload
    };
};

export const loadTokensDictionaryFailed = (error) => {
    return {
        type: LOAD_TOKEN_TAGS_FAILED,
        payload: error
    };
};

export const loadAllCustomTrafficSourceData = (customTrafficSourcePayload) => {
    return {
        type: LOAD_CUSTOM_TRAFFIC_SOURCE,
        payload:customTrafficSourcePayload,
    }
}

export const loadAllCustomTrafficSourceDataSuccess = (customTrafficSourcePayloadDataSuccess) => {
    return {
        type: LOAD_CUSTOM_TRAFFIC_SOURCE_SUCCESS,
        payload:customTrafficSourcePayloadDataSuccess,
    }
}

export const loadAllCustomTrafficSourceDataFailed = (error) => {
    return {
        type: LOAD_CUSTOM_TRAFFIC_SOURCE_FAILED,
        payload:error,
    }
}

export const loadTrafficSourceDataById = (customTrafficSourceDataByIdPayload) => {
    return {
        type: LOAD_CUSTOM_TRAFFIC_SOURCE_BY_ID,
        payload: customTrafficSourceDataByIdPayload
    }
}

export const loadTrafficSourceDataByIdSuccess = (customTrafficSourceDataByIdSuccessPayload) => {
    return {
        type: LOAD_CUSTOM_TRAFFIC_SOURCE_BY_ID_SUCCESS,
        payload:customTrafficSourceDataByIdSuccessPayload
    }
}

export const loadTrafficSourceDataByIdFailed = (error)=>{
    return {
        type: LOAD_CUSTOM_TRAFFIC_SOURCE_BY_ID_FAILED,
        payload: error
    }
}

export const resetLoadedTrafficSourceDataById = () => {
    return {
        type: RESET_CUSTOM_TRAFFIC_SOURCE_DATA_BY_ID,
    }
}

export const resetLoadedEditTrafficSourceData = () => {
    return {
        type:RESET_EDIT_TRAFFIC_SOURCE_DATA,
    }
}

export const loadTSChartsData = (payload) => {
    return {
        type: GET_TS_CHARTS_DATA,
        payload: payload
    };
};

export const loadTSChartsDataSuccess = (payload) => {
    return {
        type: GET_TS_CHARTS_DATA_SUCCESS,
        payload: payload
    };
};

export const loadTSChartsDataFailed = (error) => {
    return {
        type: GET_TS_CHARTS_DATA_FAILED,
        payload: error
    };
};