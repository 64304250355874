import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectipuaFilter = (state) => state.ipuaFilter || initialState;

const makeSelectFilterData = () =>
    createSelector(selectipuaFilter, (substate) => substate.ipuaFilterData);

    const makeSelectEditFilterData = () =>
    createSelector(selectipuaFilter, (substate) => substate.editIpUaFilterData);
    const makeSelectIsFilterDataLoaded = () =>
    createSelector(selectipuaFilter, (substate) => substate.isIpUaFilterDataLoaded);
    const makeSelectIsFilterDataUpdated = () =>
    createSelector(selectipuaFilter, (substate) => substate.isIpUaFilterDataUpdated);
    const makeSelectIsFilterDataDeleted = () =>
    createSelector(selectipuaFilter, (substate) => substate.isIpUaFilterDataDeleted);
    const makeSelectIsFilterDataAddingError = () =>
    createSelector(selectipuaFilter, (substate) => substate.addingError);
    const makeSelectIsFilterDataAddingSuccess = () =>
    createSelector(selectipuaFilter, (substate) => substate.addingSuccess);

    const makeSelectIsFilterDataError = () =>
    createSelector(selectipuaFilter, (substate) => substate.error);
    
    

export {
    makeSelectFilterData,
    makeSelectEditFilterData,
    makeSelectIsFilterDataLoaded,
    makeSelectIsFilterDataUpdated,
    makeSelectIsFilterDataAddingError,
    makeSelectIsFilterDataAddingSuccess,
    makeSelectIsFilterDataDeleted,
    makeSelectIsFilterDataError
    }