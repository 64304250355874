import React from 'react';
import HeaderTopGlobalBar from "./HeaderTopGlobalBar";
import SettingsHeaderMenuBar from "./SettingsHeaderMenuBar";
import GeneralHeaderMenuBar from "./GeneralHeaderMenuBar";
import "./header.css";
import HeaderTabs from "./headerTabs";
import produce from "immer";
import {withRouter} from "react-router-dom";
import {setInitialDates} from "../../components/searchParameters";

class Header extends React.Component {
    state = {
        activeTab: 0,
        names: ["","",""],
        reportCount: 0
    }

    componentDidMount() {
        setTimeout(() => {
            let url = new URL(window.location.href);
            let search = JSON.parse(localStorage.getItem("search"));
            if (url.pathname.includes("report")) {
                this.setState({ activeTab: JSON.parse(localStorage.getItem("search")).activeTab });
            } else {
                this.setState({ activeTab: 0 });
                search.activeTab = 0;
                localStorage.setItem("search",JSON.stringify(search));
            }

            this.setState(produce(draft => {
                draft.names[0] = search.report.report1.name;
                draft.names[1] = search.report.report2.name;
                draft.names[2] = search.report.report3.name;
                draft.reportCount = search.report.reportCount;
            }));

        },1000);
    }

    changeActiveTab = (data) => {
        let search = JSON.parse(localStorage.getItem("search"));
        search.activeTab = Number(data);
        this.setState(produce(draft => { draft.activeTab = Number(data); }));
        localStorage.setItem("search",JSON.stringify(search));
        setTimeout(() => {
            if (data === "0") {
                this.props.history.push({
                    pathname: "/campaigns",
                    search: "",
                    hash: ""
                });
            } else {
                let search = JSON.parse(localStorage.getItem("search"));
                this.props.history.push({
                    pathname: "/report",
                    search: "id=" + search.report["report" + data].search.reportGroupingValues.id + "&reportType=" + search.report["report" + data].search.reportGroupingValues.filter1 + "&startDate=" + search.report["report" + data].search.modifiedDate.startDate + "&endDate=" + search.report["report" + data].search.modifiedDate.startDate + "&new=false",
                    hash: ""
                });
            }
        },80);
    };

    closeReport = (data) => {
        let url = new URL(window.location.href);
        if (!url.pathname.includes("report")){
            this.props.decreaseReportCount();
        }
        let search = JSON.parse(localStorage.getItem("search"));
        let activeTab = search.activeTab;
        search.report.reportCount = Number(search.report.reportCount) - 1;
        this.setState(produce(draft => {
            draft.reportCount = Number(this.state.reportCount) - 1;
            draft.names[Number(data) - 1] = "";
        }));
        if (data === "3") {
            search.report.report3 = this.resetReportSearch();
            localStorage.setItem("search",JSON.stringify(search));
            if (Number(activeTab) === 3) {
                this.changeActiveTab("2");
            }
        } else if (data === "2") {
            if (search.report.report3.name !== "") {
                this.setState(produce(draft => {
                    draft.names[1] = this.state.names[2];
                    draft.names[2] = "";
                }));
                search.report.report2 = search.report.report3;
                search.report.report3 = this.resetReportSearch();
                localStorage.setItem("search",JSON.stringify(search));
                if (Number(activeTab) === 3 || Number(activeTab) === 2) {
                    this.changeActiveTab("2");
                }
            } else {
                search.report.report2 = this.resetReportSearch();
                localStorage.setItem("search",JSON.stringify(search));
                if (Number(activeTab) === 2) {
                    this.changeActiveTab("1");
                }
            }
        } else if (data === "1") {
            if (search.report.report2.name !== "" || search.report.report3.name !== "") {
                this.setState(produce(draft => {
                    draft.names[0] = this.state.names[1];
                    draft.names[1] = this.state.names[2];
                    draft.names[2] = "";
                }));
                search.report.report1 = search.report.report2;
                search.report.report2 = search.report.report3;
                search.report.report3 = this.resetReportSearch();
                localStorage.setItem("search",JSON.stringify(search));
                if (Number(activeTab) === 1) {
                    this.changeActiveTab("1");
                } else if (Number(activeTab) === 3) {
                    this.changeActiveTab("2");
                } else if (Number(activeTab) === 2) {
                    this.changeActiveTab("1");
                }
            } else {
                this.setState(produce(draft => {
                    draft.names[0] = "";
                    draft.names[1] = "";
                    draft.names[2] = "";
                }));
                search.report.report1 = this.resetReportSearch();
                localStorage.setItem("search",JSON.stringify(search));
                if (Number(activeTab) === 1) {
                    this.changeActiveTab("0");
                }
            }
        }
    }

    resetReportSearch = () => {
        return {
            search: {
                inputSearchValue: "",
                isTagsSearchSelected: false,
                reportGroupingValues: {
                    id: "",
                    filter1: "",
                    filter2: "",
                    filter3: "",
                    filter4: "",
                    reportType: ""
                },
                sorting: {
                    order: "visits",
                    sort: "ASC"
                },
                modifiedDate: setInitialDates(new Date()),
                date: [
                    {
                        startDate: new Date(),
                        endDate: new Date(),
                        key: 'selection'
                    }
                ],
            },
            activeIcons: {
                campaign: false,
                offers: false,
                affilateNetwork: false,
                flow: false,
                trafficSource: false,
                lander: false,
                path: false,
            },
            menuButtonsActive: [
                { name: "campaign", activeStatus: false },
                { name: "offers", activeStatus: false },
                { name: "flow", activeStatus: false },
                { name: "affilateNetwork", activeStatus: false },
                { name: "trafficSource", activeStatus: false },
                { name: "lander", activeStatus: false },
                { name: "path", activeStatus: false },
            ],
            fullGroupingList: [],
            disable: {
                filter3: false,
                filter4: false
            },
            appliedGroupingValues: {
                id: "",
                filter1: "",
                filter2: "",
                filter3: "",
                filter4: "",
                reportType: ""
            },
            name: ""
        }
    }

    render () {
        let headerToShow;

        if (window.location.href.indexOf("settings") > -1) {
            headerToShow = <SettingsHeaderMenuBar/>;
        } else if (window.location.href.indexOf("report") > -1) {
            headerToShow = <HeaderTabs
                reportCount={this.state.reportCount}
                activeTab={this.state.activeTab}
                changeActiveTab={this.changeActiveTab}
                names={this.state.names}
                closeReport={this.closeReport}
            />;
        } else {
            headerToShow = <>
                <HeaderTabs
                    reportCount={this.state.reportCount}
                    activeTab={this.state.activeTab}
                    changeActiveTab={this.changeActiveTab}
                    names={this.state.names}
                    closeReport={this.closeReport}
                />
                <GeneralHeaderMenuBar/>
            </>;
        }
        return (
            <header className="main_header">
                <HeaderTopGlobalBar/>
                {headerToShow}
            </header>
        );
    }
};

export default withRouter(Header);