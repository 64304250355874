import React, {useEffect} from "react";
import GeneralTab from "./generalTab";
import Tracking from "./tracking";
import produce from "immer";

class ModalForm extends React.Component {
    state = {
        showGeneralTab: true,
        showTrackingTab: false,
        activeStyle: {
            "color": "#e0358b",
            "borderTop": "1px solid #e0358b",
            "borderRight": "1px solid #18181b",
            "box-shadow": "0px 2px 4px rgb(0 0 0 / 16%)"
        },
        isVisible: false
    }
    toggleTabs = (e) => {
        console.log(e.target.id);
        switch (e.target.id) {
            case "general":
                this.setState(produce(draft => { draft.showGeneralTab = true }));
                this.setState(produce(draft => { draft.showTrackingTab = false }));
                break;
            case "tracking":
                this.setState(produce(draft => { draft.showGeneralTab = false }));
                this.setState(produce(draft => { draft.showTrackingTab = true }));
                break;
            default:
                this.setState(produce(draft => { draft.showGeneralTab = true }));
                this.setState(produce(draft => { draft.showTrackingTab = false }));
        }
        // this.setState(produce(draft => { draft.showGeneralTab = !this.state.showGeneralTab }));
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.state.isCampaignCreatedSuccessfully === true && this.state.isVisible === false) {
            this.setState(produce(draft => { draft.isVisible = true }));
            this.setState(produce(draft => { draft.showGeneralTab = false }));
            this.setState(produce(draft => { draft.showTrackingTab = true }));
        }
    }

    render () {
        return (
            <div className="form_con">
                <section className="campaign_modal_tabs k-pb-0">
                    <div
                        className="tab k-p-2 text_center"
                        id="general"
                        onClick={this.toggleTabs}
                        style={this.state.showGeneralTab ? this.state.activeStyle : null}
                    >General</div>
                    {
                        this.props.state.isCampaignCreatedSuccessfully
                            ?
                            <div
                                className="tab k-p-2 text_center"
                                id="tracking"
                                onClick={this.toggleTabs}
                                style={this.state.showTrackingTab ? this.state.activeStyle : null}
                            >Tracking</div>
                            :
                            <div className="k-p-2 text_center disabled">Tracking</div>
                    }
                </section>
                {
                    this.state.showGeneralTab ?
                        <GeneralTab
                            props={this.props}
                        />
                        : null
                }
                {
                    this.state.showTrackingTab ?
                        <Tracking
                            trafficSourceName={this.props.state.trafficSourceName}
                            countryName={this.props.state.countryName}
                            campaignName={this.props.state.dataToSend.name}
                            campaignUrl={this.props.state.campaign_url_generated}
                        />
                        : null
                }
            </div>
        );
    }
};

export default ModalForm;