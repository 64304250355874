export const LOAD_OFFER_TRACKING_URLS = "LOAD_OFFER_TRACKING_URLS";
export const LOAD_OFFER_TRACKING_URLS_SUCCESS = "LOAD_OFFER_TRACKING_URLS_SUCCESS";
export const LOAD_OFFER_TRACKING_URLS_FAILED = "LOAD_OFFER_TRACKING_URLS_FAILED";

export const LOAD_OFFER_CURRENCY = "LOAD_OFFER_CURRENCY";
export const LOAD_OFFER_CURRENCY_SUCCESS = "LOAD_OFFER_CURRENCY_SUCCESS";
export const LOAD_OFFER_CURRENCY_FAILED = "LOAD_OFFER_CURRENCY_FAILED";

export const POST_NEW_OFFER_DATA = "POST_NEW_OFFER_DATA";
export const POST_NEW_OFFER_DATA_SUCCESS = "POST_NEW_OFFER_DATA_SUCCESS";
export const POST_NEW_OFFER_DATA_FAILED = "POST_NEW_OFFER_DATA_FAILED";

export const LOAD_OFFERS_DATA = "LOAD_OFFERS_DATA";
export const LOAD_OFFERS_DATA_SUCCESS = "LOAD_OFFERS_DATA_SUCCESS";
export const LOAD_OFFERS_DATA_FAILED = "LOAD_OFFERS_DATA_FAILED";

export const EDIT_OFFER_DATA = "EDIT_OFFER_DATA";
export const EDIT_OFFER_DATA_SUCCESS = "EDIT_OFFER_DATA_SUCCESS";
export const EDIT_OFFER_DATA_FAILED = "EDIT_OFFER_DATA_FAILED";


export const EDIT_OFFER_DATA_SENT = "EDIT_OFFER_DATA_SENT";
export const EDIT_OFFER_DATA_SENT_SUCCESS = "EDIT_OFFER_DATA_SENT_SUCCESS";
export const EDIT_OFFER_DATA_SENT_FAILED = "EDIT_OFFER_DATA_SENT_FAILED";

export const DELETE_OFFER_DATA = "DELETE_OFFER_DATA";
export const DELETE_OFFER_DATA_SUCCESS = "DELETE_OFFER_DATA_SUCCESS";
export const DELETE_OFFER_DATA_FAILED = "DELETE_OFFER_DATA_FAILED";

export const ARCHIVE_OFFER_DATA = "ARCHIVE_OFFER_DATA";
export const ARCHIVE_OFFER_DATA_SUCCESS = "ARCHIVE_OFFER_DATA_SUCCESS";
export const ARCHIVE_OFFER_DATA_FAILED = "ARCHIVE_OFFER_DATA_FAILED";

export const LOAD_TIMEZONE = "LOAD_TIMEZONE";
export const LOAD_TIMEZONE_SUCCESS = "LOAD_TIMEZONE_SUCCESS";
export const LOAD_TIMEZONE_FAILED = "LOAD_TIMEZONE_FAILED";

export const GET_OFFERS_CHARTS_DATA = "GET_OFFERS_CHARTS_DATA";
export const GET_OFFERS_CHARTS_DATA_SUCCESS = "GET_OFFERS_CHARTS_DATA_SUCCESS";
export const GET_OFFERS_CHARTS_DATA_FAILED = "GET_OFFERS_CHARTS_DATA_FAILED";