import { LOAD_PROFILE, LOAD_PROFILE_FAILED, LOAD_PROFILE_SUCCESS, EDIT_PROFILE, EDIT_PROFILE_SUCCESS, EDIT_PROFILE_FAILED, UPDATE_PROFILE, UPDATE_PROFILE_FAILED, UPDATE_PROFILE_SUCCESS,
UPDATE_PASSWORD, UPDATE_PASSWORD_SUCCESS, UPDATE_PASSWORD_FAILED } from "./constants";

export const loadProfile = (accessToken) => {
    return {
        type: LOAD_PROFILE,
        payload:{accessToken}
    }
}

export const loadProfileSuccess = (profileData) => {
    return {
        type: LOAD_PROFILE_SUCCESS,
        payload: {profileData}
    }
}

export const loadProfileFailed = (error) => {
    return {
        type: LOAD_PROFILE_FAILED,
        payload: error
    }
}

export const editProfile = (accessToken, id) => {
    return {
        type: EDIT_PROFILE,
        payload: {accessToken,id}
    }
}

export const editProfileSuccess = (editProfileData) => {
    return {
        type: EDIT_PROFILE_SUCCESS,
        payload:{editProfileData}
    }
}

export const editProfileFailed = (error) => {
    return {
        type: EDIT_PROFILE_FAILED,
        payload:error
    }
}

export const updateProfile = (accessToken, updateData) => {
    return {
        type: UPDATE_PROFILE,
        payload:{accessToken, updateData}
    }
}

export const updateProfileSuccess = (updateProfileSuccessPayload) => {
    return {
        type: UPDATE_PROFILE_SUCCESS,
        payload: updateProfileSuccessPayload
    }
}

export const updateProfileFailed = (error) => {
    return {
        type: UPDATE_PROFILE_FAILED,
        payload: error
    }
}

export const updatePassword = (accessToken, updateData) => {
    return {
        type: UPDATE_PASSWORD,
        payload:{accessToken, updateData}
    }
}

export const updatePasswordSuccess = (updatePasswordSuccess) => {
    return {
        type: UPDATE_PASSWORD_SUCCESS,
        payload: {updatePasswordSuccess}
    }
}

export const updatePasswordFailed = (error) => {
    return {
        type: UPDATE_PASSWORD_FAILED,
        payload: {error}
    }
}