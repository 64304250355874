import "./styles.css"

const PageMenuBarBottom = (props) => {
    
    const blurIcons = () =>  props.allDataLoaded?.length <= 0 && props.searchValue?.length 
    const checkICons = () =>  props?.allDataLoaded?.length < props?.tableRowSize
        return (
            <div className="header-second-row">
                <div className="box-left">
                    <button
                        className="k-button normal_text normal_font page_bottom_menu_button k-ml-1 k-mr-1"
                        onClick={() => props.toggleColumnsModal()}
                    >
                        <span className="k-icon k-i-columns"></span>
                        &nbsp;
                        Columns
                    </button>
                    <div className="show_data_number_dropdown normal_text">
                        <select
                            name="showDataRows"
                            id="showDataRows"
                            onChange={(event) => props.changeRowSize(event.target.value)}
                        >
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                        </select>
                    </div>
                    <div className="pagination inline_block normal_text">
                        <span
                            className="first"
                            style={props?.pagination?.page === 0  ? {
                                filter: "grayscale(0.1)",
                                cursor: "not-allowed",
                                opacity: "0.3",
                                pointerEvents: "none"
                            }: null }
                            onClick={() => props?.handleStep("first")}
                        >First</span>
                        <span
                            className="k-icon k-i-arrow-chevron-left"
                            style={props?.pagination?.page === 0 || props.pagination.page === undefined ? {
                                filter: "grayscale(0.1)",
                                cursor: "not-allowed",
                                opacity: "0.3",
                                pointerEvents: "none"
                            }: null }
                            onClick={() => props.handlePagination(props.pagination.page  - 1 )}
                        ></span>
                        <span className="number">{props?.pagination?.page}</span>
                        <span
                            className="k-icon k-i-arrow-chevron-right"
                            style={props.pagination.page === undefined || blurIcons() || checkICons() ? {
                                filter: "grayscale(0.1)",
                                cursor: "not-allowed",
                                opacity: "0.3",
                                pointerEvents: "none"
                            }: null }
                            onClick={() => props.handlePagination(props.pagination.page  + 1 )}
                        ></span>
                        <span
                            className="last"
                            style={props.pagination.lastBlur === true || blurIcons()  || checkICons() ? {
                                filter: "grayscale(0.1)",
                                cursor: "not-allowed",
                                opacity: "0.3",
                                pointerEvents: "none"
                            }: null }
                            onClick={() => props?.handleStep("last")}
                        >Last</span>
                    </div>
                </div>
            </div>
        );
};

export default PageMenuBarBottom;