import NewRuleCollapse from './collapse/newRuleCollapse';

const NewRule = (props) => {
    //console.log('props',props.id);
    return (
        <div className="row">
            <NewRuleCollapse    
                deleterule={props.deleterule}
                duplicateRule={props.duplicateRule}
                id={props.id}
                index={props.index}
                type={props.type}
                defaultValue={props.defaultValue}
                state={props.state}
                newRuleClicked={props.newRuleClicked}
                addNewPath={props.addNewPath}
                newRulePathClicked={props.newRulePathClicked}
                duplicateDefaultPath={props.duplicateDefaultPath}
                deleteDefaultPath={props.deleteDefaultPath}
                decreaseDefaultPathWeightMethod={props.decreaseDefaultPathWeightMethod}
                increaseDefaultPathWeightMethod={props.increaseDefaultPathWeightMethod}
                onChangePathSwitch={props.onChangePathSwitch}
                inputChangeHandler={props.inputChangeHandler}
                onSort={props.onSort}
                showRuleConditionsName={props.showRuleConditionsName}
            ></NewRuleCollapse>
        </div>
    )
}
export default NewRule;