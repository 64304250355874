import React, {Component} from 'react';
import { Popup } from '@progress/kendo-react-popup';
import { SvgIcon } from '@progress/kendo-react-common';
import { caretAltDownIcon } from '@progress/kendo-svg-icons';
import TooltipsCon from "../../tooltips/tooltip";
import "./popups.css";
import { connect } from 'react-redux';
import {createStructuredSelector} from "reselect";
import { makeSelectDomainsDataReceived } from "../../../containers/Settings/Domains/selectors";
import { loadCustomDomains } from "../../../containers/Settings/Domains/actions";
import {makeSelectToken} from "../../../registration/LoginRegister/selectors";
import produce from 'immer';

class WorkspacePopup extends Component {
    anchor = null;
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            all_workspaces_checkbox: true,
            anchorAlign: {
                horizontal: 'right',
                vertical: 'bottom'
            },
            popupAlign: {
                horizontal: 'right',
                vertical: 'top'
            },
            workspacesList: [],
            selectedWorkspaces: [],
            allChecked: true
        };
    }

    fetchDomainsAndWorkspaces = async () => { return this.props.dispatchLoadDomainsAndWorkspaces(this.props.accessToken); };

    componentDidMount() {
        this.setState({ show: false });
        this.fetchDomainsAndWorkspaces();
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.domainsAndWorkspacesListsReceived !== this.props.domainsAndWorkspacesListsReceived) && this.props.domainsAndWorkspacesListsReceived !== null) {
            // this.setState({ workspacesList : this.props.domainsAndWorkspacesListsReceived.workspace });
            // console.log("Component rendered");
            let search = JSON.parse(localStorage.getItem("search"));
            let listReceived = this.props.domainsAndWorkspacesListsReceived.workspace;
            let newList = [];
            let selectedList = [];
            
            for (let i=0;i<listReceived.length; i++) {
                if (search.selectedWorkspacesList && search.selectedWorkspacesList.length === 0) {
                    newList.push({ 
                        id: listReceived[i].id,
                        name: listReceived[i].name,
                        checked: true
                    });
                    selectedList.push(listReceived[i].id);
                } else {
                    if (search.selectedWorkspacesList.includes(listReceived[i].id)) {
                        newList.push({ 
                            id: listReceived[i].id,
                            name: listReceived[i].name,
                            checked: true
                        });
                        selectedList.push(listReceived[i].id);
                    } else {
                        newList.push({ 
                            id: listReceived[i].id,
                            name: listReceived[i].name,
                            checked: false
                        });
                    }

                }                
            }
            this.setState(produce(draft => {
                draft.workspacesList = newList;
                draft.selectedWorkspaces = selectedList;
                if (selectedList.length !== listReceived.length) {
                    draft.allChecked = false;
                } else { draft.allChecked = true; }
            }));
            search.selectedWorkspacesList = selectedList;
            localStorage.setItem("search",JSON.stringify(search));
        }
    }
    showValueInConsole = event => {
        this.setState({ all_workspaces_checkbox: event.value });
    };
    checkWorkspace = (id) => {
        for (let i=0;i<this.state.workspacesList.length;i++) {
            if (Number(id) === Number(this.state.workspacesList[i].id)) {
                this.setState(produce(draft => {
                    draft.workspacesList[i].checked = !this.state.workspacesList[i].checked;
                }));
            }
        }
        let selectedList = [];
        setTimeout(() => {
            for (let i=0;i<this.state.workspacesList.length;i++) {
                if (this.state.workspacesList[i].checked) {
                    selectedList.push(this.state.workspacesList[i].id);
                }
            }
            this.setState(produce(draft => { draft.selectedWorkspaces = selectedList; }));
            if (selectedList.length === this.state.workspacesList.length) {
                this.setState(produce(draft => { draft.allChecked = true; }));
            } else { this.setState(produce(draft => { draft.allChecked = false; })); }
        },100);

        setTimeout(() => {
            let search = JSON.parse(localStorage.getItem("search"));
            search.selectedWorkspacesList = selectedList;
            localStorage.setItem("search",JSON.stringify(search));
        },200);
       
    }
    checkAll = (e) => {
        if (this.state.allChecked === true) {
            this.setState(produce(draft => { draft.allChecked = false; }));
            for (let i=0;i<this.state.workspacesList.length;i++) {
                this.setState(produce(draft => { draft.workspacesList[i].checked = false; }));
            }
            this.setState(produce(draft => { draft.selectedWorkspaces = []; }));
        } else {
            this.setState(produce(draft => { draft.allChecked = true; }));
            let selectedList = [];
            for (let i=0;i<this.state.workspacesList.length;i++) {
                this.setState(produce(draft => { 
                    draft.workspacesList[i].checked = true;
                    selectedList.push(this.state.workspacesList[i].id);
                }));
            }
            this.setState(produce(draft => { draft.selectedWorkspaces = selectedList; }));
            setTimeout(() => {
                let search = JSON.parse(localStorage.getItem("search"));
                search.selectedWorkspacesList = selectedList;
                localStorage.setItem("search",JSON.stringify(search));
            },200);
        }
    }

    refreshPage = () => {
        window.location.reload();
    }
    render() {
        const { anchorAlign, popupAlign } = this.state;

        return (
            <div
                className="workspace_popup_con_outer"
                ref={(button) => {
                    this.anchor = button;
                }}
            >
                { this.state.show && <div onClick={this.onClick} className="workspace_backdrop"></div>}
                <div className="workspace_popup_con">
                    <button
                        className="k-button"
                        onClick={this.onClick}
                    >
                        Workspaces <SvgIcon icon={caretAltDownIcon} className="down_icon" />
                    </button>
                    <Popup
                        anchor={this.anchor}
                        anchorAlign={anchorAlign}
                        popupAlign={popupAlign}
                        collision={{
                            horizontal: "fit",
                            vertical: "flip"
                        }}
                        show={this.state.show}
                        popupClass={'popup-content'}
                    >
                        <div className="popup_header">
                            <div>
                                <span className="text">Workspaces</span>
                                <span className="number"> ({this.state.workspacesList.length})</span>
                            </div>
                            <TooltipsCon />
                        </div>
                        <div className="popup_body">
                            <label 
                                onClick={this.checkAll}
                                className="workspaces_list_label"
                            >
                                    <input 
                                        type="checkbox" 
                                        value={"all"}
                                        checked={this.state.allChecked}
                                    /> All Workspaces
                                </label>
                            { this.state.workspacesList.map((workspace) => (
                                <label 
                                    onClick={() => this.checkWorkspace(workspace.id)}
                                    className="workspaces_list_label"
                                >
                                    <input 
                                        type="checkbox" 
                                        id={workspace.id}
                                        value={workspace.id}
                                        checked={workspace.checked} 
                                    /> {workspace.name}
                                </label>
                            ))}
                            {/* <Checkbox value={true} label={'All Workspaces'} />
                            <Checkbox value={true} label={'Initial'} /> */}
                            <div className="workspaces_apply_button">
                                <button
                                    disabled={this.state.selectedWorkspaces.length === 0}
                                    onClick={this.refreshPage}
                                    // style={ this.state.selectedWorkspaces.length === 0 ? { backgroundColor: "gray" } : null}
                                >Apply</button>
                            </div>
                        </div>
                    </Popup>
                </div>
            </div>
        );
    }
    onClick = () => {
        this.setState({ show: !this.state.show });
    }
}

export const mapStateToProps = createStructuredSelector({
    domainsAndWorkspacesListsReceived: makeSelectDomainsDataReceived(),
    accessToken: makeSelectToken(),
});

export function mapDispatchToProps(dispatch) {
    return {
        dispatchLoadDomainsAndWorkspaces: (...payload) => dispatch(loadCustomDomains(...payload)),
    };
}
  

export default connect(mapStateToProps, mapDispatchToProps)(WorkspacePopup);