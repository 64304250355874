import { checkFilters } from "../../../components/checkNamesForGrouping/checkNames"; 
import { returnTimeZoneId } from "../../../components/timeZonesExchange";
import * as requestHandler from "../../helpers/requestHandlers";
import {
    PATH_CAMPAIGNS,
    PATH_GROUPING,

} from "../../helpers/path";
import axios from "axios";
import { BASE_URL } from "../../helpers/path";

export const LoadAllConversions = async (request) => {
    let timeZoneInLocalStorage = JSON.parse(localStorage.getItem("search")).dates.timeZone;
    let timeZoneIdToSend = returnTimeZoneId(timeZoneInLocalStorage);
    let sortBy = request?.payload?.sortBy ? "&sortBy=" + request?.payload?.sortBy : ""
    let sortName = request?.payload?.sortName ? "&sortName=" + request?.payload?.sortName : ""
    let startDate = request?.payload?.startDate ? "&startDate=" + request?.payload?.startDate : ""
    let endDate = request?.payload?.endDate ? "&endDate=" + request?.payload?.endDate : ""
    let searchedValue = request?.payload?.value ? "&searchedValue=" + request?.payload?.value : ""
    let groupBy =  request?.payload?.groupBy ? "&groupBy=" +  request?.payload?.groupBy : ""
    let queryParams = "?rows=" + request?.payload?.rowSize +"&page=" + request?.payload?.page
    let timezone = "&timezone=" + timeZoneIdToSend ?? ""
    queryParams = queryParams + searchedValue + groupBy + startDate + endDate + sortBy + sortName + timezone
    const response =  requestHandler.get(BASE_URL + 'report/conversion_report/' + queryParams);
    return response;
};

export const LoadLastRowsFromConversion = async (request) => {
    let timeZoneInLocalStorage = JSON.parse(localStorage.getItem("search")).dates.timeZone;
    let timeZoneIdToSend = returnTimeZoneId(timeZoneInLocalStorage);
    let sortBy = request?.payload?.sortBy ? "&sortBy=" + request?.payload?.sortBy : ""
    let sortName = request?.payload?.sortName ? "&sortName=" + request?.payload?.sortName : ""
    let searchedValue = request?.payload?.value ? "&searchedValue=" + request?.payload?.value : ""
    let groupBy =  request?.payload?.groupBy ? "&groupBy=" +  request?.payload?.groupBy : ""
    let queryParams = "?rows=" + request?.payload?.rowSize +"&position=" + request?.payload?.position
    let timezone = "&timezone=" + timeZoneIdToSend ?? ""
    queryParams = queryParams + searchedValue + groupBy + sortBy + sortName + timezone
    const response =  requestHandler.post(BASE_URL + 'report/conversion_report/pagination' + queryParams);
    return response;
};

export const SearchedValueForConversion = async (request) => {  
    let searchedValue = request?.payload?.value ? "searchedValue=" + request?.payload?.value : ""
    let groupBy =  request?.payload?.groupBy ? "&groupBy=" +  request?.payload?.groupBy : ""
    let rowSize =  request?.payload?.rowSize ? "&rowSize=" +  request?.payload?.rowSize : 50
    let queryParams = "?" + searchedValue + groupBy + rowSize
    const response =  requestHandler.get(BASE_URL + 'report/conversion_report/search/' + queryParams);
    return response;
};

