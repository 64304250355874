import React, {Component, Fragment} from "react";
import produce from "immer";
import "./profile.css";
import StaticProfileData from "../../../components/settings/profile/static";
import ProfileForm from "../../../components/settings/profile/profileForm";
import Layout from "../../../layout";
import { makeSelectToken } from "../../../registration/LoginRegister/selectors";
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";
import { Fade } from "@progress/kendo-react-animation";
import * as profileActions from "./actions";
import { makeSelectIsPasswordUpdated, makeSelectProfileData, makeSelectProfileError, makeSelectUpdatePasswordData, makeSelectUpdateProfileDataLoading } from "./selectors";
import LoadingPanel from './../../grid/loader/loader';
import auth from "../../../auth";
import { logoutUser } from "../../../registration/LoginRegister/actions";
import { Button } from "@progress/kendo-react-buttons";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Error } from "@progress/kendo-react-labels";
import { logoutAndClear } from "../../../components/logoutAndClear";

class ProfilePage extends Component {
    state = {
        dataToSend: {
            firstname: null,
            lastname: null,
            email: null,
            // dob: null,
            accountType:null,
            accountStatus:null,
            link:null,
            company:null,
            instant_messenger:null,
            instant_messenger_number:null,
            website:null,
            address:null,
            city:null,
            region:null,
            country:null,
            zipCode:null,
            phoneNumber:null
        },
        changePassword:{
            previous_password:"",
            new_password: "",
            retype_password: "",
        },
        showStatic: true,
        showPasswordStatic: true,
        updateData: true,
        success_msg: "",
        error_msg: "",
        loader: false,
        passwordError:false,
        passwordSuccess:false,
        profileError:false,
    }

    componentDidMount() {
        if (this.props.profileDataReceived === null) {
            this.props.fetchingProfileData(this.props.accessToken);
        }
        setTimeout(() => {
            if (this.props.profileDataReceived !== null) {
                this.setState(produce(draft => {
                    draft.dataToSend.firstname = this.props.profileDataReceived.firstname;
                    draft.dataToSend.lastname = this.props.profileDataReceived.lastname;
                    draft.dataToSend.email = this.props.profileDataReceived.email;
                    draft.dataToSend.accountType = this.props.profileDataReceived.accountType;
                    draft.dataToSend.link = this.props.profileDataReceived.link;
                    draft.dataToSend.company = this.props.profileDataReceived.company;
                    draft.dataToSend.instant_messenger = this.props.profileDataReceived.instant_messenger;
                    draft.dataToSend.instant_messenger_number = this.props.profileDataReceived.instant_messenger_number;
                    draft.dataToSend.website = this.props.profileDataReceived.website;
                    draft.dataToSend.address = this.props.profileDataReceived.address;
                    draft.dataToSend.city = this.props.profileDataReceived.city;
                    draft.dataToSend.region = this.props.profileDataReceived.region;
                    draft.dataToSend.country = this.props.profileDataReceived.country;
                    draft.dataToSend.zipCode = this.props.profileDataReceived.zipCode;
                    draft.dataToSend.phoneNumber = this.props.profileDataReceived.phoneNumber;
                }));
                // this.setState(produce(draft => { draft.dataToSend.dob = this.props.profileDataReceived.dob }));
            }
        }, 100);

    }

    componentDidUpdate(prevProps) {
        if (this.props.profileDataReceived !== null && this.state.updateData) {
            this.setState(produce(draft => {
                draft.dataToSend.firstname = this.props.profileDataReceived.firstname;
                draft.dataToSend.lastname = this.props.profileDataReceived.lastname;
                draft.dataToSend.email = this.props.profileDataReceived.email;
                draft.dataToSend.accountType = this.props.profileDataReceived.accountType;
                draft.dataToSend.link = this.props.profileDataReceived.link;
                draft.dataToSend.company = this.props.profileDataReceived.company;
                draft.dataToSend.website = this.props.profileDataReceived.website;
                draft.dataToSend.address = this.props.profileDataReceived.address;
                draft.dataToSend.instant_messenger = this.props.profileDataReceived.instant_messenger;
                draft.dataToSend.instant_messenger_number = this.props.profileDataReceived.instant_messenger_number;
                draft.dataToSend.city = this.props.profileDataReceived.city;
                draft.dataToSend.region = this.props.profileDataReceived.region;
                draft.dataToSend.country = this.props.profileDataReceived.country;
                draft.dataToSend.zipCode = this.props.profileDataReceived.zipCode;
                draft.dataToSend.phoneNumber = this.props.profileDataReceived.phoneNumber;
                draft.updateData = false;
            }));
            // this.setState(produce(draft => { draft.dataToSend.dob = this.props.profileDataReceived.dob }));
        }
        if (this.state.success_msg.length > 0) {
            setTimeout(() => {
                this.setState(produce(draft => { draft.success_msg = ""  }));
            }, 5000);
        }
        if((prevProps.passwordChanged !== this.props.passwordChanged) && this.props.passwordChanged.includes("Password Changed")){
            this.setState(produce(draft => { 
                draft.passwordError = false;
                draft.passwordSuccess = true;
                draft.loader = false;
                draft.showPasswordStatic = !draft.showPasswordStatic;
            }));

            setTimeout(() => logoutAndClear(this.props.logoutUser(), auth),2500);
        }
        if((prevProps.isPasswordChanged !== this.props.isPasswordChanged) && !this.props.passwordChanged.includes("Password Changed")){
            this.setState(produce(draft => {
                draft.passwordError = true;
                draft.loader = false;
            }));
        }
        if ((prevProps.profileUpdatedSuccessfully !== this.props.profileUpdatedSuccessfully) && this.props.profileUpdatedSuccessfully && !this.props.error) {
            this.setState(produce(draft => {
                draft.showStatic = !draft.showStatic;
                draft.success_msg = "Profile data have been successfully updated.";
                draft.error_msg = "";
                draft.loader = false;
            }));

            logoutAndClear(this.props.logoutUser(), auth);
        }
        if ((prevProps.error !== this.props.error) && this.props.error) {
            this.setState(produce(draft => {
                draft.loader = false;
                draft.success_msg = "";
                draft.error_msg = "Oops! Profile Update Failed.";
            }));
        }
    }
    editClickedMethod = () => {
        this.setState(produce(draft => { draft.showStatic = !draft.showStatic; }));
    }

    editPasswordClickedMethod = () => {
        this.setState(produce(draft => { draft.showPasswordStatic = !draft.showPasswordStatic; }));
    }

    onInputChangeMethod = (e) => {
        this.setState(produce(draft => { draft.dataToSend[e.target.name] = e.value; }));
    }

    onPasswordInputChangeMethod = (e) => {
        this.setState(produce(draft => { draft.changePassword[e.target.name] = e.target.value; }));
    }
    closeForm = () => {
        this.setState(produce(draft => { draft.showStatic = !draft.showStatic; }));
    }
    
    closePsswordForm = () => {
        this.setState(produce(draft => { draft.showPasswordStatic = !draft.showPasswordStatic; }));
    }

    submitProfileData = (data) => {
        this.props.submitProfileData(this.props.accessToken, data);
        this.setState(produce(draft => {draft.loader = true;}));
    }

    submitUpadatePasswordData = () => {
        if(this.state.changePassword.previous_password.length>7 && this.state.changePassword.new_password.length>7 && this.state.changePassword.retype_password.length>7){
            this.props.submitUpadatePasswordData(this.props.accessToken, this.state.changePassword);
            this.setState(produce(draft => {draft.loader = true;}));
        }
    }
    render () {
        return (
            <Fragment>
                <Layout>
                {(this.state.passwordError && this.props.isPasswordChanged) && 
                    <NotificationGroup
                        style={{
                            alignItems: 'flex-start',
                            flexWrap: 'wrap-reverse',
                            left: "50%",
                            zIndex: 10000000,
                            top: "200px",
                            transform: "translateX(-50%)",
                        }}
                    >
                        <Fade enter={true} exit={true}>
                            <Notification
                                type={{ style: 'error', icon: true }}
                                closable={true}
                                onClose={() => this.setState(produce(draft => { draft.passwordError = false; }))}
                            >
                                <span>{ this.props.passwordChanged}</span>
                                </Notification>
                            </Fade>
                    </NotificationGroup>
                }
                {(this.state.passwordSuccess && this.props.isPasswordChanged && this.props.passwordChanged.includes("Password Changed")) && 
                    <NotificationGroup
                        style={{
                            alignItems: 'flex-start',
                            flexWrap: 'wrap-reverse',
                            left: "50%",
                            zIndex: 10000000,
                            top: "200px",
                            transform: "translateX(-50%)",
                        }}
                    >
                        <Fade enter={true} exit={true}>
                            <Notification
                                type={{ style: 'success', icon: true }}
                                closable={true}
                                onClose={() => this.setState(produce(draft => { draft.passwordSuccess = false; }))}
                            >
                                <span>{ this.props.passwordChanged + " Redirecting to Login Page"}</span>
                                </Notification>
                            </Fade>
                    </NotificationGroup>
                }
                    <div className="settings_area_container">
                        <div className="settings_area_con_inner">
                            <h1 className="h1 black_color text_left no_border">Profile information</h1>
                            
                            {
                                this.state.success_msg !== "" ?
                                    <div className="success_msg_con">
                                        <Fade enter={true} exit={true}>
                                            <p className="success_para mb-10 mh-0">{this.state.success_msg}</p>
                                        </Fade>
                                    </div>
                                    : null
                            }
                            {
                                this.state.error_msg !== "" ?
                                    <div className="success_msg_con">
                                        <Fade enter={true} exit={true}>
                                            <p className="danger_para mb-10 mh-0">{this.state.error_msg}</p>
                                        </Fade>
                                    </div>
                                    : null
                            }
                            <div className="box">
                                {
                                    this.state.showStatic && this.props.profileDataReceived !==null ?
                                        <StaticProfileData
                                            clickedEdit={this.editClickedMethod}
                                            profileDataReceived={this.props.profileDataReceived}
                                        />
                                        :
                                        <ProfileForm 
                                            state={this.state}
                                            onInputChangeMethod={(e) => this.onInputChangeMethod(e)}
                                            closeForm={this.closeForm}
                                            submitProfileData={this.submitProfileData}
                                        />
                                }
                                {(this.props.profileDataReceived ===null || this.state.loader) && <LoadingPanel/>}

                        </div>
                                <h1 className="h1 black_color text_left no_border">Change password</h1>
                                <div className="box">
                                {
                                    this.state.showPasswordStatic ? 
                                    <div className="static_profile_con">
                                        <div className="">
                                            <label className="label normal_text color_black">Password</label>
                                            <div className="label_content">
                                                <span className="content">*********</span>
                                            </div>
                                        </div>
                                        <div className="profile_row">
                                            <Button
                                                icon="k-i-edit k-i-pencil"
                                                className="edit_button gray_button_bg_imp theme_dark_blue_color"
                                                onClick={this.editPasswordClickedMethod}
                                            >
                                                Edit
                                            </Button>
                                        </div>
                                    </div>
                                    :
                                    <div className="static_profile_con">
                                        <div className="profile_row">
                                            <label className="label normal_text color_black">Old password</label>
                                            <div className="label_content">
                                                <input
                                                    className={this.state.changePassword.previous_password.length>7 ?"k-textbox": "k-textbox k-state-invalid" }
                                                    name="previous_password"
                                                    style={{ width: "100%" }}
                                                    type="password"
                                                    placeholder="Type old password"
                                                    required={true}
                                                    value={this.state.changePassword.previous_password}
                                                    onChange={this.onPasswordInputChangeMethod}
                                                />
                                                { this.state.changePassword.previous_password.length===0 ? 
                                                    <Error direction={'end'}>This field is required.</Error>:
                                                    this.state.changePassword.previous_password.length<8 &&
                                                    <Error direction={'end'}>Password must be atleast 8 symbols.</Error>
                                                }
                                            </div>
                                        </div>
                                        <div className="profile_row">
                                            <label className="label normal_text color_black">New password</label>
                                            <div className="label_content">
                                                <input
                                                    className={this.state.changePassword.new_password.length>7 ?"k-textbox": "k-textbox k-state-invalid" }
                                                    name="new_password"
                                                    style={{ width: "100%" }}
                                                    type="password"
                                                    placeholder="Type new password"
                                                    required={true}
                                                    value={this.state.changePassword.new_password}
                                                    onChange={this.onPasswordInputChangeMethod}
                                                />
                                                { this.state.changePassword.new_password.length===0 ?
                                                    <Error direction={'end'}>This field is required.</Error>:
                                                    this.state.changePassword.new_password.length<8 &&
                                                    <Error direction={'end'}>Password must be atleast 8 symbols.</Error>
                                                }
                                            </div>
                                        </div>
                                        <div className="profile_row">
                                            <label className="label normal_text color_black">Retype new password</label>
                                            <div className="label_content">
                                                <input
                                                    className={this.state.changePassword.retype_password.length>7 ?"k-textbox": "k-textbox k-state-invalid" }
                                                    name="retype_password"
                                                    style={{ width: "100%" }}
                                                    type="password"
                                                    placeholder="Retype new password"
                                                    required={true}
                                                    value={this.state.changePassword.retype_password}
                                                    onChange={this.onPasswordInputChangeMethod}
                                                />
                                                { this.state.changePassword.retype_password.length===0 ?
                                                    <Error direction={'end'}>This field is required.</Error>: 
                                                    this.state.changePassword.retype_password.length<8 &&
                                                    <Error direction={'end'}>Password must be atleast 8 symbols.</Error>
                                                }
                                            </div>
                                        </div>
                                        <div className="profile_row">
                                            <Button
                                                className="edit_button gray_button_bg_imp theme_dark_blue_color profile_form_btn"
                                                onClick={this.closePsswordForm}
                                            >
                                                Close
                                            </Button>
                                            <Button
                                                className="edit_button theme_dark_bg_color color_white_imp profile_form_btn"
                                                onClick={this.submitUpadatePasswordData}
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </div>    
                                    }
                                </div>
                            </div>
                    </div>
                </Layout>
            </Fragment>
        );
    }
}

export const mapStateToProps = createStructuredSelector({
    accessToken: makeSelectToken(),
    profileDataReceived: makeSelectProfileData(),
    profileUpdatedSuccessfully: makeSelectUpdateProfileDataLoading(),
    error: makeSelectProfileError(),
    passwordChanged: makeSelectUpdatePasswordData(),
    isPasswordChanged: makeSelectIsPasswordUpdated(),
});

export function mapDispatchToProps(dispatch) {
    return {
        fetchingProfileData: (...payload) =>dispatch(profileActions.loadProfile(...payload)),
        submitProfileData: (...payload) => dispatch(profileActions.updateProfile(...payload)),
        submitUpadatePasswordData: (...payload) => dispatch(profileActions.updatePassword(...payload)),
        logoutUser: () => dispatch(logoutUser()),
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);