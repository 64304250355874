import React, { Fragment } from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import EmailInput from "./../../../components/authentication/emailInput/emailInput";
import { Input, RadioGroup } from '@progress/kendo-react-inputs';
import PasswordInput from "./../../../components/authentication/passwordInput/passwordInput";
import { userNameValidator, passwordValidator, emailValidator} from "../../../validator/validator";
import { connect } from 'react-redux';
import * as loginRegisterActions from '../../../registration/LoginRegister/actions'
import { createStructuredSelector } from 'reselect';
import { makeSelectRegisterUsername, makeSelectRegisterError, makeSelectRegisterSuccess, makeSelectRegisterLoading, makeSelectRegistered, makeSelectToken } from '../../../registration/LoginRegister/selectors';
import { Fade } from '@progress/kendo-react-animation';
import { Calendar } from "@progress/kendo-react-dateinputs";
import "../../../registration/LoginRegister/Signup/signup.css";
import {formatDate} from "../../../components/searchParameters";
import produce from "immer";
import {makeSelectCountriesListReceived} from "../../../containers/Pages/Campaigns/selectors";
import * as CampaignActions from "../../../containers/Pages/Campaigns/actions";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { CountryDropdown } from 'react-country-region-selector';
import { makeSelectAllUsersData, makeSelectIsAllUsersDataLoaded, makeSelectIsUsersDataAdded, makeSelectUserDataLoading, makeSelectUserDataSuccess, makeSelectUsersDataError,} from "./selectors";
import * as userActions from "./actions";
import LoadingPanel from "../../grid/loader/loader";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import Layout from "../../../layout";

class AddUser extends React.Component {
    state = {
        birthday: new Date(2000, 0, 1),
        showBirthday: formatDate(new Date(2000, 0, 1)),
        showCalendar: false,
        country: "",
        accType: "Individual",
        phone: "",
        linkedin: "",
        facebook: "",
        website: "",
        error: {
            name: null,
            email: null,
            password: null,
            confirmPass: null,
            birthday: null,
            accType: null,
            linkedin: null,
            facebook: null,
            website: null,
            address: null,
            phone: null
        },
        acc_Type: [
            {
                label: "Individual",
                value: "Individual",
            },
            {
                label: "Company",
                value: "Company",
            },
        ],
        loader: false,
        backendErrors: {
            error: false,
            errorMsg:""
        },
    }

    componentDidMount() {
        //this.props.dispatchLoadCountries({})
    }
    componentDidUpdate(prevProps,prevState){
        if((prevProps.isUserAdded !== this.props.isUserAdded) && this.props.isUserAdded){
            //console.log('reg fetching data after adding');
            this.props.fetchingUsersData(this.props.accessToken);
            this.setState(produce(draft => { draft.loader = false; }));
            this.setState(produce(draft=>{draft.backendErrors.error=false}));
            this.redirect();
        }
        if((prevProps.error !== this.props.error) && this.props.error !== false && !this.props.isUserAdded){
            //console.log(' add reg error');
            this.setState(produce(draft => { draft.loader = false; }));
            this.setState(produce(draft=>{draft.backendErrors.error=true}));
        }
        // if((prevState.sendingRequest !== this.state.sendingRequest) && this.state.sendingRequest &&)
    }

    handleBirthdayChange = (e) => {
        this.setState({
            birthday: e.value,
            showBirthday: formatDate(e.value)
        });
    }
    toggleCalendar = () => {
        this.setState({ showCalendar: !this.state.showCalendar });
    }
    selectCountry = (val) => { this.setState({ country: val }); }
    simpleInputChangeHandler = (e) => {
        console.log(e.target);
        this.setState(produce(draft => { draft[e.target.name] = e.target.value; }));
    }
    radioChangeHandler = (e) => {
        this.setState(produce(draft => { draft.accType = e.value; }));
    }
    checkErrors = () => {
        if (this.state.city === "" || this.state.country === "") {
            this.setState(produce(draft => { draft.error.address = "You must enter the complete address. It includes Street Address, City, State, Country, and Zip Code/Postal Code." }));
        } else { this.setState(produce(draft => { draft.error.address = null })); }

        if (this.state.phone === "") {
            this.setState(produce(draft => { draft.error.phone = "Phone number is required." }));
        } else { this.setState(produce(draft => { draft.error.phone = null })); }
        if (this.state.accType === "company") {
            if (this.state.linkedin === "") {
                this.setState(produce(draft => { draft.error.linkedin = "Linkedin Profile link is required." }));
            } else { this.setState(produce(draft => { draft.error.linkedin = null })); }
            if (this.state.facebook === "") {
                this.setState(produce(draft => { draft.error.facebook = "Facebook Page link is required." }));
            } else { this.setState(produce(draft => { draft.error.facebook = null })); }
            if (this.state.website === "") {
                this.setState(produce(draft => { draft.error.website = "Website link is required." }));
            } else { this.setState(produce(draft => { draft.error.website = null })); }
        } else {
            this.setState(produce(draft => {
                draft.error.linkedin = null;
                draft.error.facebook = null;
                draft.error.website = null;
            }));
        }
    }
    redirect() {
        this.props.history.push("/settings/users/");
      }
    render () {
        const radioGroupValidator = (value) => !value ? "Account Type is required" : "";
        const handleSubmit = event => {
            //console.log('event', event);
            this.setState(produce(draft => { draft.loader = true; }));
            if (event.password !== event.repassword) {
                this.setState(produce(draft => { draft.error.confirmPass = "Passwords do not match. Please re-enter the passwords."; }));
            }
            else{
                this.setState(produce(draft => { draft.error.confirmPass = null; }));
            }
            this.checkErrors();
            setTimeout(() => {
                //console.log("state", this.state);
                // console.log("event: ",this.state);
                if (this.state.error.accType === null && this.state.error.confirmPass === null && this.state.error.name === null && this.state.error.address === null && this.state.error.birthday === null && this.state.error.email === null && this.state.error.facebook === null && this.state.error.linkedin === null && this.state.error.password === null && this.state.error.phone === null && this.state.error.website === null) {
                    this.props.addUser(this.props.accessToken,{
                        username: event.userName,
                        email: event.email,
                        password: event.password,
                        confirmPass: event.confirmPass,
                        dob: this.state.showBirthday,
                        accountType: this.state.accType,
                        linkedin: this.state.linkedin,
                        facebook: this.state.facebook,
                        website: this.state.website,
                        address: event.streetAddress,
                        city: event.city,
                        region: event.state,
                        zipCode: event.zipCode,
                        country: this.state.country,
                        phoneNumber: this.state.phone
                    });
                    
                }
            },200);
        }

        // const confirmPasswordValidator = (value) => value && value.length >= 8 ? '' : 'Password must be at least 8 symbols.';
        // console.log("Signup props", this.props);

        return (
            <Fragment>
                <Layout>
            {this.state.loader && <LoadingPanel/>}
            {(this.state.backendErrors.error ) && 
                <NotificationGroup
                    style={{
                        alignItems: 'flex-start',
                        flexWrap: 'wrap-reverse',
                        left: "50%",
                        zIndex: 10000000,
                        top: "20px",
                        transform: "translateX(-50%)",
                    }}
                >
                    <Fade enter={true} exit={true}>
                        {(this.state.backendErrors.error&& this.props.error) && <Notification
                            type={{ style: 'error', icon: true }}
                            closable={true}
                            onClose={() => this.setState(produce(draft => { draft.backendErrors.error = false; }))}
                        >
                            <span>{ this.props.error.message+", "+this.props.error.error}</span>
                            </Notification>}
                    </Fade>
                </NotificationGroup>
                }
                <div className="page_container" style={{height:"inherit"}}>
                    {this.state.showCalendar ? <div className="black_bg_signup" onClick={this.toggleCalendar}></div> : null }
                    
                    <div className="container">
                        <Form
                            onSubmit={handleSubmit}
                            render={(formRenderProps) => (
                                <FormElement className="register_form_element">
                                    <fieldset className={"k-form-fieldset"}>
                                        <h2 className="login_h2">Add User</h2>
                                        <div className="signup_grid">
                                            <div className="signup-row">
                                                <div className="mb-2 col">
                                                    <Field
                                                        name={"userName"}
                                                        type={"text"}
                                                        id="userName"
                                                        component={Input}
                                                        label={"Name"}
                                                        required={true}
                                                        validator={userNameValidator}
                                                    />
                                                    {
                                                        this.state.error.name !== null ?
                                                            <Fade enter={true} exit={true}>
                                                                <p className="registration_failure_para">{this.state.error.name}</p>
                                                            </Fade>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="email">
                                                <Field
                                                    name={"email"}
                                                    type={"email"}
                                                    id="email"
                                                    component={EmailInput}
                                                    label={"Email"}
                                                    validator={emailValidator}
                                                    required={true}
                                                />
                                                {
                                                    this.state.error.email !== null ?
                                                        <Fade enter={true} exit={true}>
                                                            <p className="registration_failure_para">{this.state.error.email}</p>
                                                        </Fade>
                                                        : null
                                                }
                                            </div>
                                            <div>
                                                <Field
                                                    name="password"
                                                    type="password"
                                                    label="Password"
                                                    id="password"
                                                    required={true}
                                                    minLength={6}
                                                    maxLength={18}
                                                    validator={passwordValidator}
                                                    component={PasswordInput}
                                                />
                                            </div>
                                            <div>
                                                <Field
                                                    name="repassword"
                                                    type="password"
                                                    label="Re-Type Password"
                                                    id="re-password"
                                                    required={true}
                                                    minLength={6}
                                                    maxLength={18}
                                                    validator={passwordValidator}
                                                    component={PasswordInput}
                                                />
                                            </div>
                                            {
                                                this.state.error.confirmPass !== null ?
                                                    <Fade enter={true} exit={true}>
                                                        <p className="registration_failure_para">{this.state.error.confirmPass}</p>
                                                    </Fade>
                                                    : null
                                            }
                                        </div>
                                        <div className="signup_grid" style={{ marginTop: "20px"}}>
                                            <div className="sign_up_birthday_con">
                                                <div className="inner">
                                                    <label className="signup_label" htmlFor="birthday">Birthday</label>
                                                    <div
                                                        className="birthday_show"
                                                        onClick={this.toggleCalendar}
                                                    >{this.state.showBirthday}</div>
                                                </div>
                                                {
                                                    this.state.showCalendar ?
                                                        <div className="birthday_calendar">
                                                            <Calendar
                                                                disabled={false}
                                                                value={this.state.birthday}
                                                                onChange={this.handleBirthdayChange}
                                                            />
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    this.state.error.birthday !== null ?
                                                        <Fade enter={true} exit={true}>
                                                            <p className="registration_failure_para">{this.state.error.birthday}</p>
                                                        </Fade>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        <div className="acc_type_row">
                                            <label className="signup_label" htmlFor="acc_type">Select Account Type</label>
                                            <RadioGroup
                                                data={this.state.acc_Type}
                                                defaultValue={this.state.acc_Type[0].value}
                                                valid={radioGroupValidator}
                                                onChange={this.radioChangeHandler}
                                            />
                                        </div>
                                        <div className="signup_grid">
                                            <div className="styled-input acc_type_row">
                                            <label className="signup_label" htmlFor="Linkedin">Linkedin Url</label>
                                                <input
                                                    name="linkedin"
                                                    type="text"
                                                    id="linkedin"
                                                    component={Input}
                                                    label={"Linkedin Url"}
                                                    onChange={this.simpleInputChangeHandler}
                                                    className="signup_simple_input k-textbox k-state-invalid"
                                                />
                                                {
                                                    this.state.error.linkedin !== null ?
                                                    <Fade enter={true} exit={true}>
                                                        <p className="registration_failure_para">{this.state.error.linkedin}
                                                        </p>
                                                    </Fade>
                                                    : null
                                                }
                                            </div>
                                            <div className="acc_type_row">
                                            <label className="signup_label" htmlFor="Facebook">Facebook</label>
                                                <input
                                                    name={"facebook"}
                                                    type={"text"}
                                                    id="facebook"
                                                    onChange={this.simpleInputChangeHandler}
                                                    component={Input}
                                                    label={"Facebook Link"}
                                                    className="signup_simple_input k-textbox k-state-invalid"
                                                />
                                                {
                                                    this.state.error.facebook !== null ?
                                                        <Fade enter={true} exit={true}>
                                                            <p className="registration_failure_para">{this.state.error.facebook}</p>
                                                        </Fade>
                                                        : null
                                                }
                                            </div>
                                            <div className="acc_type_row">
                                            <label className="signup_label" htmlFor="Website">Website Link</label>
                                                <input
                                                    name={"website"}
                                                    type={"text"}
                                                    id="website"
                                                    onChange={this.simpleInputChangeHandler}
                                                    component={Input}
                                                    label={"Website Link"}
                                                    className="signup_simple_input k-textbox k-state-invalid"
                                                />
                                                {
                                                    this.state.error.website !== null ?
                                                        <Fade enter={true} exit={true}>
                                                            <p className="registration_failure_para">{this.state.error.website}</p>
                                                        </Fade>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        <div className="acc_type_row">
                                            {/* <label className="signup_label" htmlFor="address">Address</label> */}
                                            <div className="address_con">
                                                <div className="o">
                                                    <Field
                                                        name={"streetAddress"}
                                                        type={"text"}
                                                        id="streetAddress"
                                                        component={Input}
                                                        label={"Address"}
                                                        required={true}
                                                        className="signup_simple_input"
                                                    />
                                                </div>
                                                <div className="o">
                                                    <Field
                                                        name={"city"}
                                                        type={"text"}
                                                        id="city"
                                                        component={Input}
                                                        label={"City"}
                                                        required={true}
                                                        className="signup_simple_input"
                                                    />
                                                </div>
                                                <div className="o">
                                                    <Field
                                                        name={"state"}
                                                        type={"text"}
                                                        id="state"
                                                        component={Input}
                                                        label={"Region/State/Province"}
                                                        required={true}
                                                        className="signup_simple_input"
                                                    />
                                                </div>
                                                <div className="o">
                                                    <CountryDropdown
                                                        className="country_dropdown"
                                                        id="signupCountryList"
                                                        name="country_list"
                                                        value={this.state.country}
                                                        onChange={this.selectCountry}
                                                    />
                                                </div>
                                                <div className="o">
                                                    <Field
                                                        name={"zipCode"}
                                                        type={"text"}
                                                        id="zipCode"
                                                        component={Input}
                                                        label={"Zip Code/Postal Code"}
                                                        required={true}
                                                        className="signup_simple_input"
                                                    />
                                                </div>
                                            </div>
                                            {
                                                this.state.error.address !== null ?
                                                    <Fade enter={true} exit={true}>
                                                        <p className="registration_failure_para">{this.state.error.address}</p>
                                                    </Fade>
                                                    : null
                                            }
                                        </div>
                                        <div className="acc_type_row">
                                            <div className="address_con">
                                                <div className="o">
                                                    <PhoneInput
                                                        country={'us'}
                                                        value={this.state.phone}
                                                        onChange={phone => this.setState({ phone: phone })}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                this.state.error.phone !== null ?
                                                    <Fade enter={true} exit={true}>
                                                        <p className="registration_failure_para">{this.state.error.phone}</p>
                                                    </Fade>
                                                    : null
                                            }
                                        </div>
                                    </fieldset>
                                    <div className="k-mt-5 action_bar_buttons" style={{display:"flex",justifyContent:"space-between"}}>
                                    <div className="left">
                                        <button
                                            className="normal_btn cancel_button gray_button_bg_imp black border_radius_normal_btn normal_text black no_border"
                                            onClick={this.redirect.bind(this)}
                                        >
                                            Cancel
                                        </button>
                                        </div>
                                        <div className="right">
                                        <button
                                            className="normal_btn theme_dark_btn theme_dark_bg_color_imp color_white_imp border_radius_normal_btn save_button normal_text"
                                            type={"submit"}
                                            disabled={!formRenderProps.allowSubmit}
                                        >
                                            Save
                                        </button>
                                        </div>
                                    </div>
                                </FormElement>
                            )}
                            />
                    </div>
                </div>
                </Layout>
            </Fragment>
        );
    }
};

export const mapStateToProps = createStructuredSelector(
  {
        regError: makeSelectRegisterError(),
        //loading: makeSelectRegisterLoading(),
        username: makeSelectRegisterUsername(),
        //success: makeSelectRegisterSuccess(),
        isRegistered: makeSelectRegistered(),
        countriesListReceived: makeSelectCountriesListReceived(),
        accessToken: makeSelectToken(),
        allUsersDataLoaded: makeSelectAllUsersData(),
        isAllUsersDataLoaded: makeSelectIsAllUsersDataLoaded(),
        isUserAdded:makeSelectIsUsersDataAdded(),
        loading: makeSelectUserDataLoading(),
        success: makeSelectUserDataSuccess(),
        error: makeSelectUsersDataError(),
  });
export function mapDispatchToProps(dispatch) {
  return {
      signUpUser: (...payload) => dispatch(loginRegisterActions.signUpUser(...payload)),
      dispatchLoadCountries: (...payload) => dispatch(CampaignActions.loadCountry(...payload)),
        changeUserStatus:(...payload) =>dispatch(userActions.changeUserStatus(...payload)),
        fetchingUsersData: (...payload) =>
            dispatch(userActions.loadAllUsers(...payload)),
        addUser: (...payload) =>
            dispatch(userActions.addUsers(...payload)),
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(AddUser);