import React, {Component} from "react";
import MenuButton from "../../../components/header/menuButtons/menuButtons";
import "./headerMenuBar.css";

class GeneralHeaderMenuBarReport extends Component {
    render () {
        return (
            <div className="header_menu_bar">
                {
                    this.props.activeIcons.campaign ?
                        <MenuButton
                            id="Campaign"
                            icon={"k-i-gears k-i-user"}
                            buttonText="Campaigns"
                            classNameForButton="normal_button menu_button menu_button_report"
                            onClickMethod={() => this.props.menuButtonClickedMethod("Campaign")}
                            style={ this.props.menuButtonsActive[0].activeStatus ? {
                                "backgroundColor": "#6786de",
                                "background": "#6786de",
                                "color": "#e0358b",
                                "borderBottom":"1px solid #e0358b"
                            } : {"backgroundColor": "#fff"} }
                        />
                        : null
                }
                {
                    this.props.activeIcons.offers ?
                        <MenuButton
                            id="Offers"
                            icon={"k-i-gears k-i-user"}
                            buttonText="Offers"
                            classNameForButton="normal_button menu_button menu_button_report"
                            onClickMethod={() => this.props.menuButtonClickedMethod("Offers")}
                            style={ this.props.menuButtonsActive[1].activeStatus ? {
                                "backgroundColor": "#6786de",
                                "background": "#6786de",
                                "color": "#e0358b",
                                "borderBottom":"1px solid #e0358b"
                            } : {"backgroundColor": "#fff"} }
                        />
                        : null
                }
                {
                    this.props.activeIcons.flow ?
                        <MenuButton
                            id="Flows"
                            icon={"k-i-gears k-i-user"}
                            buttonText="Flows"
                            classNameForButton="normal_button menu_button menu_button_report"
                            onClickMethod={() => this.props.menuButtonClickedMethod("Flows")}
                            style={ this.props.menuButtonsActive[2].activeStatus ? {
                                "backgroundColor": "#6786de",
                                "background": "#6786de",
                                "color": "#e0358b",
                                "borderBottom":"1px solid #e0358b"
                            } : {"backgroundColor": "#fff"} }
                        />
                        : null
                }
                {
                    this.props.activeIcons.affilateNetwork ?
                        <MenuButton
                            id="Affiliate Network"
                            icon={"k-i-gears k-i-user"}
                            buttonText="Affiliate Networks"
                            classNameForButton="normal_button menu_button menu_button_report"
                            onClickMethod={() => this.props.menuButtonClickedMethod("Affiliate Network")}
                            style={ this.props.menuButtonsActive[3].activeStatus ? {
                                "backgroundColor": "#6786de",
                                "background": "#6786de",
                                "color": "#e0358b",
                                "borderBottom":"1px solid #e0358b"
                            } : {"backgroundColor": "#fff"} }
                        />
                        : null
                }
                {
                    this.props.activeIcons.trafficSource ?
                        <MenuButton
                            id="Traffic Source"
                            icon={"k-i-gears k-i-user"}
                            buttonText="Traffic Sources"
                            classNameForButton="normal_button menu_button menu_button_report"
                            onClickMethod={() => this.props.menuButtonClickedMethod("Traffic Source")}
                            style={ this.props.menuButtonsActive[4].activeStatus ? {
                                "backgroundColor": "#6786de",
                                "background": "#6786de",
                                "color": "#e0358b",
                                "borderBottom":"1px solid #e0358b"
                            } : {"backgroundColor": "#fff"} }
                        />
                        : null
                }
                {
                    this.props.activeIcons.lander ?
                        <MenuButton
                            id="Lander"
                            icon={"k-i-gears k-i-user"}
                            buttonText="Landers"
                            classNameForButton="normal_button menu_button menu_button_report"
                            onClickMethod={() => this.props.menuButtonClickedMethod("Lander")}
                            style={ this.props.menuButtonsActive[5].activeStatus ? {
                                "backgroundColor": "#6786de",
                                "background": "#6786de",
                                "color": "#e0358b",
                                "borderBottom":"1px solid #e0358b"
                            } : {"backgroundColor": "#fff"} }
                        />
                        : null
                }
                {
                    this.props.activeIcons.path ?
                        <MenuButton
                            id="Path"
                            icon={"k-i-gears k-i-user"}
                            buttonText="Paths"
                            classNameForButton="normal_button menu_button menu_button_report"
                            onClickMethod={() => this.props.menuButtonClickedMethod("Path")}
                            style={ this.props.menuButtonsActive[6].activeStatus ? {
                                "backgroundColor": "#6786de",
                                "background": "#6786de",
                                "color": "#e0358b",
                                "borderBottom":"1px solid #e0358b"
                            } : {"backgroundColor": "#fff"} }
                        />
                        : null
                }
            </div>
        );
    }
}

export default GeneralHeaderMenuBarReport;