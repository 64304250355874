import * as React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import ModalForm from "./modalForm";
import "./conditionModal.css";
import { Button } from "@progress/kendo-react-buttons";
import produce from 'immer';
import { filterBy } from "@progress/kendo-data-query";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
    makeSelectBrandData, makeSelectBrowserData, makeSelectCityData, makeSelectConnectionTypeData,
    makeSelectDeviceData, makeSelectLanguageData, makeSelectOperatingSystemData, makeSelectProxyData,
    makeSelectRegionData, makeSelectISPData, makeSelectConditionCountryData, makeSelectCountriesData,
    makeSelectAllCitiesData, makeSelectAllISPData, makeSelectWeekDayData, makeSelectMCData
} from ".././selectors";
import { makeSelectToken } from "../../../.././registration/LoginRegister/selectors";
import * as newFlowsActions from '.././actions';
import { makeSelectLoadTimeZoneData } from "../../Offers/selectors";
import { makeSelectCountriesListReceived } from "../../Campaigns/selectors";

//import LoadingPanel from "../../../grid/loader/loader";

const changeState = {
    dataToSend: {
        from:"",
        to:"",
        timeZoneId:"",
    },
    models: [],
    modelsData:[],
    data: [],
    modelPushed: false,
    versionPushed: false,
    tagsData: [],
    tagsPushed: false,
    value: '',
    modelValue: '',
    selectChange: false,
    cityValue: [],
    connectionType: false,
    deviceType: false,
    region: false,
    language: false,
    proxy: false,
    os: false,
    predicate: true,
    timezone:false,
    contains:false,
    iRSelectedValue:"MUST_BE",
    ip:"",
    errors: {
        tagsError: "",
        multiSelectError:"",
        ipAddressError:"",
        referrerDomainError:"",
        customVarError:"",
        timezoneError:{
            from:"",
            to:"",
            timezone:""
        }
    },
    loader:false,
    timezonedata: null,
    defaultTime: null,
    fromh:"",
    fromm:"",
    toh:"",
    tom:"",
    domain:"",
    customVar:"",
    is_empty:false,
}


class EditCondtionModal extends React.Component {
    state = {
        dataToSend: {
            from:"",
            to:"",
            timeZoneId:"",
        },
        visible: this.props.visible,
        update: false,
        select: "",
        models: [],
        modelsData:[],
        data: [],
        modelPushed: false ,
        versionPushed: false,
        tagsData: [],
        tagsPushed: false,
        value: '',
        modelValue: '',
        selectChange: false,
        selectCityChange: false,
        cityValue: [],
        connectionType: false,
        deviceType: false,
        region: false,
        language: false,
        proxy: false,
        os: false,
        predicate: true,
        timezone:false,
        contains:false,
        iRSelectedValue:"MUST_BE",
        ip:"",
        options: [{ value: "", name: "" }],
        isEditValue: true,
        editValues: [],
        errors: {
            tagsError: "",
            multiSelectError:"",
            ipAddressError:"",
            referrerDomainError:"",
            customVarError:"",
            timezoneError:{
                from:"",
                to:"",
                timezone:""
            }
        },
        loader:false,
        timezonedata: null,
        defaultTime: null,
        fromh:"",
        fromm:"",
        toh:"",
        tom:"",
        domain:"",
        customVar:"",
        is_empty:false,
    };

    toggleDialog = () => {
        this.setState({
            visible: !this.state.visible,
        });
        this.props.onEditVisibleClick(!this.state.visible);
    };

    componentDidMount() {
        //console.log('this.state.state', this.props.state);
        this.setState(produce(draft => { draft.isEditValue = true }));
        // if (this.props.allCitiesDataRecieved === null) {
        //     this.props.fetchAllCityData(this.props.accessToken);
        // }
        if (this.props.allIspDataRecieved === null) {
            this.props.fetchAllISPData(this.props.accessToken);
        }
        
        if (this.props.state.editSelect === "Connection type") {
            this.setState(produce(draft => { draft.options[0].value = "connection" }));
            this.setState(produce(draft => { draft.options[0].name = "Connection type" }));
            this.setState(produce(draft => { draft.select = "connection" }));
            if (this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.connectionType.predicate === "MUST_BE") {
                this.setState(produce(draft => { draft.predicate = true }));
            } else { this.setState(produce(draft => { draft.predicate = false })); }
            
            for (let i = 0; i < this.props.connectionTypeDataReceived.length; i++){
                for (let j = 0; j< this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.connectionType.connection.length;j++) {
                    if (this.props.connectionTypeDataReceived[i].name === this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.connectionType.connection[j].name) {
                        this.setState(produce(draft=>{draft.editValues.push(this.props.connectionTypeDataReceived[i])}))
                    }
                }
            }
        }

        if (this.props.state.editSelect === "Weekday") {
            this.setState(produce(draft => { draft.options[0].value = "weekday" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "weekday" }));
            if (this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.weekday.predicate === "MUST_BE") {
                this.setState(produce(draft => { draft.predicate = true }));
            } else { this.setState(produce(draft => { draft.predicate = false })); }
            
            for (let i = 0; i < this.props.weekdayDataReceived.length; i++){
                for (let j = 0; j< this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.weekday.weekdays.length;j++) {
                    if (this.props.weekdayDataReceived[i].name === this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.weekday.weekdays[j].name) {
                        this.setState(produce(draft=>{draft.editValues.push(this.props.weekdayDataReceived[i])}))
                    }
                }
            }
        }

        if (this.props.state.editSelect === "Device type") {
            this.setState(produce(draft => { draft.options[0].value = "device" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "device" }));
            if (this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.deviceType.predicate === "MUST_BE") {
                this.setState(produce(draft => { draft.predicate = true }));
            } else { this.setState(produce(draft => { draft.predicate = false })); }
            
            for (let i = 0; i < this.props.deviceTypeDataReceived.length; i++){
                for (let j = 0; j< this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.deviceType.device.length;j++) {
                    if (this.props.deviceTypeDataReceived[i].name === this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.deviceType.device[j].name) {
                        this.setState(produce(draft=>{draft.editValues.push(this.props.deviceTypeDataReceived[i])}))
                    }            
                }
            }    
        }

        if (this.props.state.editSelect === "Language") {
            this.setState(produce(draft => { draft.options[0].value = "language" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "language" }));
            if (this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.language.predicate === "MUST_BE") {
                this.setState(produce(draft => { draft.predicate = true }));
            } else { this.setState(produce(draft => { draft.predicate = false })); }
            
            for (let i = 0; i < this.props.languageDataReceived.length; i++){
                for (let j = 0; j< this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.language.languages.length;j++) {
                    if (this.props.languageDataReceived[i].name === this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.language.languages[j].name) {
                        this.setState(produce(draft=>{draft.editValues.push(this.props.languageDataReceived[i])}))
                    }            
                }
            }
        }

        if (this.props.state.editSelect === "Proxy") {
            this.setState(produce(draft => { draft.options[0].value = "proxy" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "proxy" }));
            if (this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.proxy.predicate === "MUST_BE") {
                this.setState(produce(draft => { draft.predicate = true }));
            } else { this.setState(produce(draft => { draft.predicate = false })); }
            
            for (let i = 0; i < this.props.proxyDataReceived.length; i++){
                for (let j = 0; j< this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.proxy.proxies.length;j++) {
                    if (this.props.proxyDataReceived[i].name === this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.proxy.proxies[j].name) {
                        this.setState(produce(draft=>{draft.editValues.push(this.props.proxyDataReceived[i])}))
                    }            
                }
            }
        }
        if (this.props.state.editSelect === "IP") {
            this.setState(produce(draft => { draft.options[0].value = "IP" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "IP" }));
            if (this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.ip.predicate === "MUST_BE") {
                this.setState(produce(draft => { draft.predicate = true }));
            } else { this.setState(produce(draft => { draft.predicate = false })); }
            let ip="";
            for(let i =0;i<this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.ip.ips.length;i++){
                ip=ip+(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.ip.ips[i].name + ",")
            }
            //console.log('ips',ip.slice(0,-1));
            this.setState(produce(draft => { draft.ip = ip.slice(0,-1) }));
        }
        if (this.props.state.editSelect === "Referrer") {
            this.setState(produce(draft => { draft.options[0].value = "Referrer" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "Referrer" }));
            if (this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.referrer.predicate === "MUST_BE") {
                this.setState(produce(draft => { draft.predicate = true }));
            } else { this.setState(produce(draft => { draft.predicate = false })); }
            this.setState(produce(draft => { draft.is_empty = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.referrer.is_empty }));
            let ref="";
            for(let i =0;i<this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.referrer.referrers.length;i++){
                ref=ref+(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.referrer.referrers[i].name + ",")
            }
            this.setState(produce(draft => { draft.domain = ref.slice(0,-1) }));
        }
        if (this.props.state.editSelect === "Referrer domain") {
            this.setState(produce(draft => { draft.options[0].value = "Referrer domain" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "Referrer domain" }));
            if (this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.referrerDomain.predicate === "MUST_BE") {
                this.setState(produce(draft => { draft.predicate = true }));
            } else { this.setState(produce(draft => { draft.predicate = false })); }
            this.setState(produce(draft => { draft.is_empty = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.referrerDomain.is_empty }));
            let ref="";
            for(let i =0;i<this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.referrerDomain.referrerDomains.length;i++){
                ref=ref+(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.referrerDomain.referrerDomains[i].name + ",")
            }
            this.setState(produce(draft => { draft.domain = ref.slice(0,-1) }));
        }

        if (this.props.state.editSelect === "Custom variable 1") {
            this.setState(produce(draft => { draft.options[0].value = "Custom variable 1" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "Custom variable 1" }));
            this.setState(produce(draft => { draft.iRSelectedValue = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var1.predicate }));
            this.setState(produce(draft => { draft.is_empty = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var1.is_empty }));
            let custVar="";
            for(let i =0;i<this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var1.var1s.length;i++){
                custVar=custVar+(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var1.var1s[i].name + ",")
            }
            this.setState(produce(draft => { draft.customVar = custVar.slice(0,-1) }));
        }
        if (this.props.state.editSelect === "Custom variable 2") {
            this.setState(produce(draft => { draft.options[0].value = "Custom variable 2" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "Custom variable 2" }));
            this.setState(produce(draft => { draft.iRSelectedValue = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var2.predicate }));
            this.setState(produce(draft => { draft.is_empty = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var2.is_empty }));
            let custVar="";
            for(let i =0;i<this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var2.var2s.length;i++){
                custVar=custVar+(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var2.var2s[i].name + ",")
            }
            this.setState(produce(draft => { draft.customVar = custVar.slice(0,-1) }));
        }
        if (this.props.state.editSelect === "Custom variable 3") {
            this.setState(produce(draft => { draft.options[0].value = "Custom variable 3" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "Custom variable 3" }));
            this.setState(produce(draft => { draft.iRSelectedValue = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var3.predicate }));
            this.setState(produce(draft => { draft.is_empty = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var3.is_empty }));
            let custVar="";
            for(let i =0;i<this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var3.var3s.length;i++){
                custVar=custVar+(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var3.var3s[i].name + ",")
            }
            this.setState(produce(draft => { draft.customVar = custVar.slice(0,-1) }));
        }
        if (this.props.state.editSelect === "Custom variable 4") {
            this.setState(produce(draft => { draft.options[0].value = "Custom variable 4" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "Custom variable 4" }));
            this.setState(produce(draft => { draft.iRSelectedValue = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var4.predicate }));
            this.setState(produce(draft => { draft.is_empty = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var4.is_empty }));
            let custVar="";
            for(let i =0;i<this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var4.var4s.length;i++){
                custVar=custVar+(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var4.var4s[i].name + ",")
            }
            this.setState(produce(draft => { draft.customVar = custVar.slice(0,-1) }));
        }
        if (this.props.state.editSelect === "Custom variable 5") {
            this.setState(produce(draft => { draft.options[0].value = "Custom variable 5" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "Custom variable 5" }));
            this.setState(produce(draft => { draft.iRSelectedValue = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var5.predicate }));
            this.setState(produce(draft => { draft.is_empty = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var5.is_empty }));
            let custVar="";
            for(let i =0;i<this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var5.var5s.length;i++){
                custVar=custVar+(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var5.var5s[i].name + ",")
            }
            this.setState(produce(draft => { draft.customVar = custVar.slice(0,-1) }));
        }
        if (this.props.state.editSelect === "Custom variable 6") {
            this.setState(produce(draft => { draft.options[0].value = "Custom variable 6" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "Custom variable 6" }));
            this.setState(produce(draft => { draft.iRSelectedValue = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var6.predicate }));
            this.setState(produce(draft => { draft.is_empty = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var6.is_empty }));
            let custVar="";
            for(let i =0;i<this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var6.var6s.length;i++){
                custVar=custVar+(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var6.var6s[i].name + ",")
            }
            this.setState(produce(draft => { draft.customVar = custVar.slice(0,-1) }));
        }
        if (this.props.state.editSelect === "Custom variable 7") {
            this.setState(produce(draft => { draft.options[0].value = "Custom variable 7" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "Custom variable 7" }));
            this.setState(produce(draft => { draft.iRSelectedValue = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var7.predicate }));
            this.setState(produce(draft => { draft.is_empty = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var7.is_empty }));
            let custVar="";
            for(let i =0;i<this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var7.var7s.length;i++){
                custVar=custVar+(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var7.var7s[i].name + ",")
            }
            this.setState(produce(draft => { draft.customVar = custVar.slice(0,-1) }));
        }
        if (this.props.state.editSelect === "Custom variable 8") {
            this.setState(produce(draft => { draft.options[0].value = "Custom variable 8" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "Custom variable 8" }));
            this.setState(produce(draft => { draft.iRSelectedValue = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var8.predicate }));
            this.setState(produce(draft => { draft.is_empty = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var8.is_empty }));
            let custVar="";
            for(let i =0;i<this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var8.var8s.length;i++){
                custVar=custVar+(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var8.var8s[i].name + ",")
            }
            this.setState(produce(draft => { draft.customVar = custVar.slice(0,-1) }));
        }
        if (this.props.state.editSelect === "Custom variable 9") {
            this.setState(produce(draft => { draft.options[0].value = "Custom variable 9" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "Custom variable 9" }));
            this.setState(produce(draft => { draft.iRSelectedValue = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var9.predicate }));
            this.setState(produce(draft => { draft.is_empty = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var9.is_empty }));
            let custVar="";
            for(let i =0;i<this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var9.var9s.length;i++){
                custVar=custVar+(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var9.var9s[i].name + ",")
            }
            this.setState(produce(draft => { draft.customVar = custVar.slice(0,-1) }));
        }
        if (this.props.state.editSelect === "Custom variable 10") {
            this.setState(produce(draft => { draft.options[0].value = "Custom variable 10" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "Custom variable 10" }));
            this.setState(produce(draft => { draft.iRSelectedValue = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var10.predicate }));
            this.setState(produce(draft => { draft.is_empty = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var10.is_empty }));
            let custVar="";
            for(let i =0;i<this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var10.var10s.length;i++){
                custVar=custVar+(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var10.var10s[i].name + ",")
            }
            this.setState(produce(draft => { draft.customVar = custVar.slice(0,-1) }));
        }

        if (this.props.state.editSelect === "Custom variable 11") {
            this.setState(produce(draft => { draft.options[0].value = "Custom variable 11" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "Custom variable 11" }));
            this.setState(produce(draft => { draft.iRSelectedValue = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var11.predicate }));
            this.setState(produce(draft => { draft.is_empty = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var11.is_empty }));
            let custVar="";
            for(let i =0;i<this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var11.var11s.length;i++){
                custVar=custVar+(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var11.var11s[i].name + ",")
            }
            this.setState(produce(draft => { draft.customVar = custVar.slice(0,-1) }));
        }
        if (this.props.state.editSelect === "Custom variable 12") {
            this.setState(produce(draft => { draft.options[0].value = "Custom variable 12" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "Custom variable 12" }));
            this.setState(produce(draft => { draft.iRSelectedValue = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var12.predicate }));
            this.setState(produce(draft => { draft.is_empty = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var12.is_empty }));
            let custVar="";
            for(let i =0;i<this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var12.var12s.length;i++){
                custVar=custVar+(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var12.var12s[i].name + ",")
            }
            this.setState(produce(draft => { draft.customVar = custVar.slice(0,-1) }));
        }
        if (this.props.state.editSelect === "Custom variable 13") {
            this.setState(produce(draft => { draft.options[0].value = "Custom variable 13" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "Custom variable 13" }));
            this.setState(produce(draft => { draft.iRSelectedValue = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var13.predicate }));
            this.setState(produce(draft => { draft.is_empty = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var13.is_empty }));
            let custVar="";
            for(let i =0;i<this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var13.var13s.length;i++){
                custVar=custVar+(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var13.var13s[i].name + ",")
            }
            this.setState(produce(draft => { draft.customVar = custVar.slice(0,-1) }));
        }
        if (this.props.state.editSelect === "Custom variable 14") {
            this.setState(produce(draft => { draft.options[0].value = "Custom variable 14" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "Custom variable 14" }));
            this.setState(produce(draft => { draft.iRSelectedValue = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var14.predicate }));
            this.setState(produce(draft => { draft.is_empty = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var14.is_empty }));
            let custVar="";
            for(let i =0;i<this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var14.var14s.length;i++){
                custVar=custVar+(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var14.var14s[i].name + ",")
            }
            this.setState(produce(draft => { draft.customVar = custVar.slice(0,-1) }));
        }
        if (this.props.state.editSelect === "Custom variable 15") {
            this.setState(produce(draft => { draft.options[0].value = "Custom variable 15" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "Custom variable 15" }));
            this.setState(produce(draft => { draft.iRSelectedValue = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var15.predicate }));
            this.setState(produce(draft => { draft.is_empty = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var15.is_empty }));
            let custVar="";
            for(let i =0;i<this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var15.var15s.length;i++){
                custVar=custVar+(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var15.var15s[i].name + ",")
            }
            this.setState(produce(draft => { draft.customVar = custVar.slice(0,-1) }));
        }
        if (this.props.state.editSelect === "Custom variable 16") {
            this.setState(produce(draft => { draft.options[0].value = "Custom variable 16" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "Custom variable 16" }));
            this.setState(produce(draft => { draft.iRSelectedValue = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var16.predicate }));
            this.setState(produce(draft => { draft.is_empty = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var16.is_empty }));
            let custVar="";
            for(let i =0;i<this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var16.var16s.length;i++){
                custVar=custVar+(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var16.var16s[i].name + ",")
            }
            this.setState(produce(draft => { draft.customVar = custVar.slice(0,-1) }));
        }
        if (this.props.state.editSelect === "Custom variable 17") {
            this.setState(produce(draft => { draft.options[0].value = "Custom variable 17" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "Custom variable 17" }));
            this.setState(produce(draft => { draft.iRSelectedValue = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var17.predicate }));
            this.setState(produce(draft => { draft.is_empty = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var17.is_empty }));
            let custVar="";
            for(let i =0;i<this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var17.var17s.length;i++){
                custVar=custVar+(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var17.var17s[i].name + ",")
            }
            this.setState(produce(draft => { draft.customVar = custVar.slice(0,-1) }));
        }
        if (this.props.state.editSelect === "Custom variable 18") {
            this.setState(produce(draft => { draft.options[0].value = "Custom variable 18" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "Custom variable 18" }));
            this.setState(produce(draft => { draft.iRSelectedValue = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var18.predicate }));
            this.setState(produce(draft => { draft.is_empty = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var18.is_empty }));
            let custVar="";
            for(let i =0;i<this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var18.var18s.length;i++){
                custVar=custVar+(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var18.var18s[i].name + ",")
            }
            this.setState(produce(draft => { draft.customVar = custVar.slice(0,-1) }));
        }
        if (this.props.state.editSelect === "Custom variable 19") {
            this.setState(produce(draft => { draft.options[0].value = "Custom variable 19" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "Custom variable 19" }));
            this.setState(produce(draft => { draft.iRSelectedValue = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var19.predicate }));
            this.setState(produce(draft => { draft.is_empty = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var19.is_empty }));
            let custVar="";
            for(let i =0;i<this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var19.var19s.length;i++){
                custVar=custVar+(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var19.var19s[i].name + ",")
            }
            this.setState(produce(draft => { draft.customVar = custVar.slice(0,-1) }));
        }
        if (this.props.state.editSelect === "Custom variable 20") {
            this.setState(produce(draft => { draft.options[0].value = "Custom variable 20" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "Custom variable 20" }));
            this.setState(produce(draft => { draft.iRSelectedValue = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var20.predicate }));
            this.setState(produce(draft => { draft.is_empty = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var20.is_empty }));
            let custVar="";
            for(let i =0;i<this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var20.var20s.length;i++){
                custVar=custVar+(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.var20.var20s[i].name + ",")
            }
            this.setState(produce(draft => { draft.customVar = custVar.slice(0,-1) }));
        }

        if (this.props.state.editSelect === "Time of day") {
            this.setState(produce(draft => { draft.options[0].value = "Time of day" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "timezone" }));
            if (this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.timeofday.predicate === "MUST_BE") {
                this.setState(produce(draft => { draft.predicate = true }));
            } else { this.setState(produce(draft => { draft.predicate = false })); }
            let timezone = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.timeofday.timezone;
            //console.log('timezone', timezone);
            this.setState(produce(draft => { 
                draft.fromh =  timezone[0].split(":")[0];
                draft.fromm =  timezone[0].split(":")[1];
                draft.toh =  timezone[1].split(":")[0];
                draft.tom =  timezone[1].split(":")[1];
                // draft.dataToSend.timeZoneId = this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.timeofday.timezone[2];
            }));
                for (let i = 0; i < this.props.timezoneDataReceived.length; i++){
                    if (Number(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.timeofday.timezone[2]) === this.props.timezoneDataReceived[i].id) {
                        this.setState(produce(draft => {draft.defaultTime = this.props.timezoneDataReceived[i]}))
                    }
                }

        }


        if (this.props.state.editSelect === "Region") {
            this.setState(produce(draft => { draft.options[0].value = "region" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "region" }));
            if (this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.region.predicate === "MUST_BE") {
                this.setState(produce(draft => { draft.predicate = true }));
            } else { this.setState(produce(draft => { draft.predicate = false })); }
            
            for (let i = 0; i < this.props.regionDataReceived.length; i++){
                for (let j = 0; j< this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.region.regions.length;j++) {
                    if (this.props.regionDataReceived[i].name === this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.region.regions[j].name) {
                        this.setState(produce(draft=>{draft.editValues.push(this.props.regionDataReceived[i])}))
                    }            
                }
            }    
        }

        if (this.props.state.editSelect === "Country") {
            this.setState(produce(draft => { draft.options[0].value = "country" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "country" }));
            if (this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.country.predicate === "MUST_BE") {
                this.setState(produce(draft => { draft.predicate = true }));
            } else { this.setState(produce(draft => { draft.predicate = false })); }
            
            console.log(this.props.preCountriesDataRecieved);
            for (let i = 0; i < this.props.preCountriesDataRecieved.length; i++){
                for (let j = 0; j< this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.country.countries.length;j++) {
                    if (this.props.preCountriesDataRecieved[i].country_name === this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.country.countries[j].country_name) {
                        this.setState(produce(draft => { draft.editValues.push(this.props.preCountriesDataRecieved[i]) }))
                    }            
                }
            }    
        }

        if (this.props.state.editSelect === "ISP") {
            this.setState(produce(draft => { draft.options[0].value = "isp" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "isp" }));
            if (this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.isp.predicate === "MUST_BE") {
                this.setState(produce(draft => { draft.predicate = true }));
            } else { this.setState(produce(draft => { draft.predicate = false })); }
            
            for (let i = 0; i < this.props.allIspDataRecieved.length; i++){
                for (let j = 0; j< this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.isp.ispies.length;j++) {
                    if (this.props.allIspDataRecieved[i].name === this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.isp.ispies[j].name) {
                        this.setState(produce(draft => { draft.editValues.push(this.props.allIspDataRecieved[i]) }))
                    }            
                }
            }    
        }
        

        if (this.props.state.editSelect === "City") {
            this.setState(produce(draft => { draft.options[0].value = "city" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "city" }));
            if (this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.city.predicate === "MUST_BE") {
                this.setState(produce(draft => { draft.predicate = true }));
            } else { this.setState(produce(draft => { draft.predicate = false })); }
            for (let i = 0; i < this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.city.cities.length; i++){
                this.setState(produce(draft => { draft.editValues.push(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.city.cities[i]) }))
            }
        }

        if (this.props.state.editSelect === "Mobile carrier") {
            this.setState(produce(draft => { draft.options[0].value = "Mobile carrier" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "Mobile carrier" }));
            if (this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.mobileCarrier.predicate === "MUST_BE") {
                this.setState(produce(draft => { draft.predicate = true }));
            } else { this.setState(produce(draft => { draft.predicate = false })); }
            for (let i = 0; i < this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.mobileCarrier.mobileCarrieries.length; i++){
                this.setState(produce(draft => { draft.editValues.push(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.mobileCarrier.mobileCarrieries[i]) }))
            }
        }

        if (this.props.state.editSelect === "Brand") {
            this.setState(produce(draft => { draft.options[0].value = "brand" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "brand" }));
            if (this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.brand.predicate === "MUST_BE") {
                this.setState(produce(draft => { draft.predicate = true }));
            } else { this.setState(produce(draft => { draft.predicate = false })); }
            
            for (let j = 0; j < this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.brand.brands[0].length; j++) {
                this.setState(produce(draft => { draft.models.push(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.brand.brands[0][j].name) }))
                 for (let i = 0; i < this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.brand.brands[0][j].models.length; i++){
                     this.setState(produce(draft => { draft.tagsData.push(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.brand.brands[0][j].models[i]) }))
                 }
            }
            this.setState(produce(draft => { draft.loader = true }));
            
            setTimeout(() => {
                this.pushModels();
            }, 100);
        }

        if (this.props.state.editSelect === "Operating system") {
            this.setState(produce(draft => { draft.options[0].value = "operating" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "operating" }));
            if (this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.os.predicate === "MUST_BE") {
                this.setState(produce(draft => { draft.predicate = true }));
            } else { this.setState(produce(draft => { draft.predicate = false })); }
            for (let j = 0; j < this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.os.oss[0].length; j++) {
                this.setState(produce(draft => { draft.models.push(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.os.oss[0][j].name) }))
                 for (let i = 0; i < this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.os.oss[0][j].version.length; i++){
                     this.setState(produce(draft => { draft.tagsData.push(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.os.oss[0][j].version[i]) }))
                 }
            }
            this.setState(produce(draft => { draft.loader = true }));
            setTimeout(() => {
                this.pushVersions(this.props.osDataReceived);
            }, 100);
        }

        if (this.props.state.editSelect === "Browser") {
            this.setState(produce(draft => { draft.options[0].value = "browser" }));
            this.setState(produce(draft => { draft.options[0].name = this.props.state.editSelect }));
            this.setState(produce(draft => { draft.select = "browser" }));
            if (this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.browser.predicate === "MUST_BE") {
                this.setState(produce(draft => { draft.predicate = true }));
            } else { this.setState(produce(draft => { draft.predicate = false })); }
            for (let j = 0; j < this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.browser.browsers[0].length; j++) {
                this.setState(produce(draft => { draft.models.push(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.browser.browsers[0][j].name) }))
                 for (let i = 0; i < this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.browser.browsers[0][j].version.length; i++){
                     this.setState(produce(draft => { draft.tagsData.push(this.props.state.dataToSend.path.rule[this.props.state.newRuleIndex].conditions.browser.browsers[0][j].version[i]) }))
                 }
            }
            this.setState(produce(draft => { draft.loader = true }));
            setTimeout(() => {
                this.pushVersions(this.props.browserDataReceived);
            }, 100);
        }
        
        setTimeout(() => {
            //console.log('edit values', this.state.editValues);
            this.onCityChange(this.state.editValues);
        }, 200);
        
    }

    componentDidUpdate() {
        //console.log('component did update', this.state);
        if (this.state.select === "connection" && this.state.connectionType===false) {
            setTimeout(() => {
                this.setState(produce(draft=>{draft.data = this.props.connectionTypeDataReceived.slice()}))
            }, 100);
            this.setState(produce(draft => { draft.connectionType= true  }));
        }

        if (this.state.select === "device" && this.state.deviceType === false) {
            setTimeout(() => {
                this.setState(produce(draft=>{draft.data = this.props.deviceTypeDataReceived.slice()}))
            }, 100);
            this.setState(produce(draft => { draft.deviceType= true  }));
        }

        if (this.state.select === "language" && this.state.language===false) {
            setTimeout(() => {
                this.setState(produce(draft=>{draft.data = this.props.languageDataReceived.slice()}))
            }, 100);
            this.setState(produce(draft => { draft.language= true  }));
        }

        if (this.state.select === "region" && this.state.region===false) {
            setTimeout(() => {
                this.setState(produce(draft=>{draft.data = this.props.regionDataReceived.slice()}))
            }, 100);
            this.setState(produce(draft => { draft.region= true  }));
        }

        if (this.state.select === "proxy" && this.state.proxy===false) {
            setTimeout(() => {
                this.setState(produce(draft=>{draft.data = this.props.proxyDataReceived.slice()}))
            }, 100);
            this.setState(produce(draft => { draft.proxy= true  }));
        }

        if (this.state.select === "operating" && this.state.os===false) {
            setTimeout(() => {
                this.setState(produce(draft=>{draft.data = this.props.osDataReceived.slice()}))
            }, 100);
            this.setState(produce(draft => { draft.os= true  }));
        }

        if (this.state.select === "weekday" && this.state.weekday===false) {
            setTimeout(() => {
                this.setState(produce(draft=>{draft.data = this.props.weekdayDataReceived.slice()}))
            }, 100);
            this.setState(produce(draft => { draft.weekday = true }));
        }
        if (this.state.select === "timezone" && this.state.timezone===false) {
            setTimeout(() => {
                this.setState(produce(draft=>{draft.data = this.props.timezoneDataReceived.slice()}))
            }, 100);
            this.setState(produce(draft => { draft.timezone = true}));
        }


        // this.setState(produce(draft=>{draft.data = this.props.connectionTypeDataReceived.slice()}))
        if (this.state.modelPushed === true) {
            this.pushModels();
            this.setState(produce(draft => { draft.modelPushed= false  }));
        }
        
        if (this.state.versionPushed === true) {
            if (this.state.select === "browser") {
                this.pushVersions(this.props.browserDataReceived);
                this.setState(produce(draft => {draft.versionPushed = false}));
            }
            if (this.state.select === "operating") {
                this.pushVersions(this.props.osDataReceived);
                this.setState(produce(draft => {draft.versionPushed = false}));
            }
        }
        

        if (this.state.selectChange === true) {
            this.resetState();
            this.setState(produce(draft => { draft.selectChange= false  }));
        }
        //console.log('this.state condition modal',this.state);
        // this.props.sendState(this.state, true);
        //this.props.addConditions(this.state);
        // console.log('this.state.data',this.state.data,this.props.conditionCountryDataReceived);
    }

    resetState = () => {
        this.setState(changeState);
    }


    onChange = (event) => {
        const value = event.target.value;
        this.setState({
            value: value
        });
    }
    onIpAdressChange=(event)=>{
        this.setState(produce(draft=>{draft.ip=event.target.value}));
    }

    onModelChange = (event) => {
        const value = event.target.value;
        this.setState({
            modelValue: value
        });
    }
    
    onChangeSelect = (e) => {
        this.setState(produce(draft => { draft.select = e.target.value }));
        this.setState(produce(draft => { draft.selectChange= true  }));
        
    };
    

    pushModels = () => {
        this.setState(produce(draft => { draft.modelsData.length = 0 }));
        for (let i = 0; i < this.state.models.length; i++){
            for (let j = 0; j < this.props.brandDataReceived.length; j++){
                if (this.state.models[i] === this.props.brandDataReceived[j].name) {
                    for (let k = 0; k < this.props.brandDataReceived[j].models.length; k++){
                        this.setState(produce(draft => { draft.modelsData.push(this.props.brandDataReceived[j].models[k])  }));

                    }
                }
            }
        }
        this.setState(produce(draft=>{draft.loader=false}));
    }

    pushVersions = (data) => {
        this.setState(produce(draft => { draft.modelsData.length = 0 }));
            for (let i = 0; i < this.state.models.length; i++){
                for (let j = 0; j < data.length; j++){
                    if (this.state.models[i] === data[j].name) {
                        for (let k = 0; k < data[j].version.length; k++){
                            this.setState(produce(draft => { draft.modelsData.push(data[j].version[k])  }));                
                        }
                    }
                }
            }
            this.setState(produce(draft=>{draft.loader=false}));
    }

    onClickBoxTagsList = (event) => {
        //console.log('models name ', event.target.element.name);
        
        if (this.state.tagsData.includes(event.target.element.name)) {
            let arr = this.state.tagsData.filter(e => e !== event.target.element.name); 
                this.setState(produce(draft => { draft.tagsData = arr }));
        }
        else {
            this.setState(produce(draft => { draft.tagsData.push(event.target.element.name) }));    
        }

        setTimeout(() => {
            if (this.state.models.length === 0) {
                this.setState(produce(draft => { draft.tagsData = []}));    
            }
        }, 50);
    }

    onClickBox = (event) => {
        
        if (this.state.select === "brand") {
            if (this.state.models.includes(event.target.element.name)) {
                let arr = this.state.models.filter(e => e !== event.target.element.name); 
                this.setState(produce(draft => { draft.models = arr }));
                this.setState(produce(draft => { draft.modelPushed = true }));
                this.setState(produce(draft => { draft.versionPushed = false }));
            }
            else {
                this.setState(produce(draft => { draft.models.push(event.target.element.name) }));
                this.setState(produce(draft => { draft.modelPushed = true }));
                this.setState(produce(draft => { draft.versionPushed = false }));
            }
        }

        if (this.state.select === "browser" || this.state.select === "operating") {
            if (this.state.models.includes(event.target.element.name)) {
                let arr = this.state.models.filter(e => e !== event.target.element.name); 
                this.setState(produce(draft => { draft.models = arr }));
                this.setState(produce(draft => { draft.versionPushed = true }));
                this.setState(produce(draft => { draft.modelPushed = false }));
            }
            else {
                this.setState(produce(draft => { draft.models.push(event.target.element.name) }));
                this.setState(produce(draft => { draft.versionPushed = true }));
                this.setState(produce(draft => { draft.modelPushed = false }));
            }
        }
        setTimeout(() => {
            if (this.state.models.length === 0) {
                this.setState(produce(draft => { draft.tagsData = []}));    
            }
        }, 50);
    }

    tagsChange = (event) => {
        
        this.setState({
            tagsData: [ ...event.target.value ]
        });
    }

    onCityChange = (event) => {
        if (this.props.state.editSelect !== "" && this.state.isEditValue===true) {
            //console.log('pushing event', event);
            this.setState({
                cityValue: event
            });
            this.setState(produce(draft => { draft.isEditValue = false }));
        } else {
            //console.log('event.target.value',event.target.value);
            this.setState({
                cityValue: event.target.value
            });
        }
    }
    filterChange = (event) => {
        if (this.state.select === "city") {
            this.props.fetchCityData(this.props.accessToken, event.filter.value);
            if (event.filter.value === "") {
                this.setState(produce(draft => { draft.data = [] }));
            }
            else {
                // this.setState(produce(draft => { draft.selectCityChange = true }));
                setTimeout(() => {
                    const data = filterBy(this.props.cityDataReceived.slice(), event.filter);
                    this.setState(produce(draft => { draft.data = data }));
                }, 1500);
            }
        }

        if (this.state.select === "country") {
            this.props.fetchCountryData(this.props.accessToken, event.filter.value);
            if (event.filter.value === "") {
                this.setState(produce(draft => { draft.data = [] }));
            }
            else {
                setTimeout(() => {
                    const data = filterBy(this.props.conditionCountryDataReceived.slice(), event.filter);
                    this.setState(produce(draft => { draft.data = data }));
                }, 2000);
            }
        }

        if (this.state.select === "isp") {
            this.props.fetchISPData(this.props.accessToken, event.filter.value);
            if (event.filter.value === "") {
                this.setState(produce(draft => { draft.data = [] }));
            }
            else {
                // this.setState(produce(draft => { draft.selectCityChange = true }));
                setTimeout(() => {
                    const data = filterBy(this.props.ispDataReceived.slice(), event.filter);
                    this.setState(produce(draft => { draft.data = data }));
                }, 2000);
            }
        }
        if (this.state.select === "Mobile carrier") {
            this.props.fetchMCData(this.props.accessToken, event.filter.value);
            if (event.filter.value === "") {
                this.setState(produce(draft => { draft.data = [] }));
            }
            else {
                setTimeout(() => {
                    const data = filterBy(this.props.mcDataReceived.slice(), event.filter);
                    this.setState(produce(draft => { draft.data = data }));
                }, 2000);
            }
        }
        if (this.state.select === "region") {
            const data = filterBy(this.props.regionDataReceived.slice(), event.filter);
            this.setState(produce(draft => { draft.data = data }));
        }
        if (this.state.select === "language") {
            const data = filterBy(this.props.languageDataReceived.slice(), event.filter);
                this.setState(produce(draft => { draft.data = data }));
        }

        if (this.state.select === "proxy") {
            const data = filterBy(this.props.proxyDataReceived.slice(), event.filter);
                this.setState(produce(draft => { draft.data = data }));
        }
        if (this.state.select === "device") {
            const data = filterBy(this.props.deviceTypeDataReceived.slice(), event.filter);
                this.setState(produce(draft => { draft.data = data }));
        }
        if (this.state.select === "connection") {
            const data = filterBy(this.props.connectionTypeDataReceived.slice(), event.filter);
                this.setState(produce(draft => { draft.data = data }));
        }

        if (this.state.select === "weekday") {
            const data = filterBy(this.props.weekdayDataReceived.slice(), event.filter);
            this.setState(produce(draft => { draft.data = data }));
        }
    };

    errorsCheck = () => {
        //console.log('state all value', this.state);
            if (this.state.select !== "") {
                if (this.state.select === "brand" || this.state.select === "browser" || this.state.select === "operating") {
                    if (this.state.models.length === 0 ) {
                        if (this.state.select === "brand" || this.state.select === "browser") {
                            //console.log('ERROR!!!!!!!!!!');
                            this.setState(produce(draft => { draft.errors.tagsError = `Add atleast one ${this.state.select} with atleast one version or model.` }))
                            this.setState(produce(draft => { draft.errors.multiSelectError = "" }))
                        }
                        if (this.state.select === "operating") {
                            //console.log('ERROR!!!!!!!!!!');
                            this.setState(produce(draft => { draft.errors.tagsError = "Add atleast one operating system with atleast one version." }))
                            this.setState(produce(draft=>{draft.errors.multiSelectError=""}))
                        }
                    }
                    if (this.state.models.length > 0 && this.state.tagsData.length === 0) {
                        this.setState(produce(draft => { draft.errors.tagsError = "Add atleast one model/version." }))
                    }
                    if(this.state.models.length > 0 && this.state.tagsData.length > 0) {
                        this.setState(produce(draft => { draft.errors.tagsError = "" }))
                        this.setState(produce(draft=>{draft.errors.multiSelectError=""}))
                    }
                    
                } else {
                    if (this.state.cityValue.length === 0) {
                        //console.log('ERROR!!!!!!!!!!');
                        if (this.state.select === "connection" || this.state.select === "device") {
                            this.setState(produce(draft=>{draft.errors.multiSelectError=`Add atleast one ${this.state.select} type.`}))
                            this.setState(produce(draft=>{draft.errors.tagsError=""}))
                        }
                        else {
                            this.setState(produce(draft=>{draft.errors.multiSelectError=`Add atleast one ${this.state.select}.`}))
                            this.setState(produce(draft=>{draft.errors.tagsError=""}))
                        }
                    }
                    else {
                        this.setState(produce(draft => { draft.errors.tagsError = "" }))
                        this.setState(produce(draft=>{draft.errors.multiSelectError=""}))
                        this.setState(produce(draft=>{draft.errors.ipAddressError=""}));
                    }
                    if(this.state.select==="IP"){
                        if (this.state.ip==="" || this.state.ip.length > 0) {
                            if(this.ValidateIPaddress(this.state.ip)===false){
                                this.setState(produce(draft => { draft.errors.ipAddressError = "You must add atleast one ip address with valid ip address." }));
                                this.setState(produce(draft=>{draft.errors.multiSelectError=""}))
                            }
                            else {
                                this.setState(produce(draft => { draft.errors.ipAddressError = "" }));
                                this.setState(produce(draft => { draft.errors.tagsError = "" }));
                                this.setState(produce(draft=>{draft.errors.multiSelectError=""}));
                            }
                        }
                        this.checkIpAddressValidation();
                    }
                    if(this.state.select==="timezone"){
                        if(this.state.fromh==="" || this.state.fromm===""){
                            this.setState(produce(draft => { draft.errors.timezoneError.from = "Start time must match the h:MM format." }));
                        }
                        if(this.state.toh==="" || this.state.tom===""){
                            this.setState(produce(draft => { draft.errors.timezoneError.to = "End time must match the h:MM format." }));
                        }
                        if(this.state.dataToSend.timeZoneId===""){
                            this.setState(produce(draft => { draft.errors.timezoneError.timezone = "Set a time zone." }));
                        }else{
                            this.setState(produce(draft => { draft.errors.timezoneError.timezone = "" }));
                        }
                        this.setState(produce(draft=>{draft.errors.multiSelectError=""}));
                        this.checkTimeZoneValidation();
                    }
                    if(this.state.select === "Referrer" || this.state.select === "Referrer domain"){
                        if(this.state.domain===""){
                            this.setState(produce(draft => { draft.errors.referrerDomainError = `${this.state.select} cannot be empty.`}));
                            this.setState(produce(draft=>{draft.errors.multiSelectError=""}));
                        }
                        else{
                            this.setState(produce(draft => { draft.errors.referrerDomainError = "" }));
                            this.setState(produce(draft=>{draft.errors.multiSelectError=""}));
                        }
                    }
                    if(this.state.select.includes("Custom")){
                        for(let i=1;i<=20;i++){
                            if(this.state.select==="Custom variable "+i){
                                if(this.state.customVar===""){
                                    this.setState(produce(draft => { draft.errors.customVarError = "You need to enter a value for the custom variable."}));
                                    this.setState(produce(draft=>{draft.errors.multiSelectError=""}));
                                }
                                else{
                                    this.setState(produce(draft => { draft.errors.customVarError = ""}));
                                    this.setState(produce(draft=>{draft.errors.multiSelectError=""}));
                                }
                            }
                        }
                    }
                }
            }
        this.submitConditionsData();   
    }
    checkTimeZoneValidation=()=>{
        // if(this.state.dataToSend.from!==""){
        //     let hour= Number(this.state.dataToSend.from.split(':')[0]);
        //     let minutes = Number(this.state.dataToSend.from.split(':')[1]);
        //     if(hour>=0 && hour<24 && minutes>=0 && minutes<60){
        //         this.setState(produce(draft => { draft.errors.timezoneError.from = "" }));
        //     }
        //     else{
        //         this.setState(produce(draft => { draft.errors.timezoneError.from = "Start time must match the h:MM format." }));
        //     }
        // }

        // if(this.state.dataToSend.to!==""){
        //     let hour= Number(this.state.dataToSend.to.split(':')[0]);
        //     let minutes = Number(this.state.dataToSend.to.split(':')[1]);
        //     if(hour>=0 && hour<24 && minutes>=0 && minutes<60){
        //         this.setState(produce(draft => { draft.errors.timezoneError.to = "" }));
        //     }
        //     else{
        //         this.setState(produce(draft => { draft.errors.timezoneError.to = "End time must match the h:MM format." }));
        //     }
        // }
        if(this.state.fromh!=="" && this.state.fromm!==""){
            this.setState(produce(draft => { draft.dataToSend.from = this.state.fromh + ":" + this.state.fromm}));
            this.setState(produce(draft => { draft.errors.timezoneError.from = "" }));
        }
        else{
            this.setState(produce(draft => { draft.errors.timezoneError.from = "Start time must match the h:MM format." }));
        }
        if(this.state.toh!=="" && this.state.tom!==""){
            this.setState(produce(draft => { draft.dataToSend.to = this.state.toh + ":" + this.state.tom}));
            this.setState(produce(draft => { draft.errors.timezoneError.to = "" }));
        }
        else{
            this.setState(produce(draft => { draft.errors.timezoneError.to = "End time must match the h:MM format." }));
        }

    }


    submitConditionsData = () => {
        setTimeout(() => {
            if (this.state.errors.tagsError === "" && this.state.errors.multiSelectError === ""&& this.state.errors.ipAddressError === ""
            && this.state.errors.timezoneError.from==="" && this.state.errors.timezoneError.to==="" && this.state.errors.timezoneError.timezone==="" && this.state.errors.referrerDomainError ==="" && this.state.errors.customVarError ==="") {
                    this.props.addConditions(this.state);
                    this.toggleDialog();
            }
            else {
                console.log('resolve errors....');
            }
        }, 100);
        
    }

    onPredicateSwitchChange = () => {
        this.setState({ predicate: !this.state.predicate });
    }
    filterData(filter) {
        const data = this.props.timezoneDataReceived.slice();
        return filterBy(data, filter);
    }
    inputChangeHandler = (e) => {
        if(e.target.name==="is_empty"){
            this.setState(produce(draft => { draft[e.target.name] = !this.state.is_empty }));     
        }
        else{
            if (e.target.name === "timeZoneId" && e.value!==null) {
                this.setState(produce(draft => { draft.dataToSend[e.target.name] = e.value.id }));    
            }
            else{
                this.setState(produce(draft => { draft[e.target.name] = e.target.value }));                
            }
        }
    }

    ValidateIPaddress = (ipaddress)=> {
        var ipformat = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
         if(ipaddress.match(ipformat)){
             return true;
         }
         else
         {
             return false;
         }
    }

    checkIpAddressValidation = () => {
        let ip = this.state.ip.split(',');
        //console.log('ip', ip);
        if (this.state.ip.length > 0) {
            for (let i = 0; i < ip.length; i++){
                if(this.ValidateIPaddress(ip[i])===false){
                    this.setState(produce(draft => { draft.errors.ipAddressError = "You must add atleast one ip address with valid ip address." }));
                    break;
                }
                else {
                    this.setState(produce(draft => { draft.errors.ipAddressError = "" }));
                }   
            }
        }
    }
    handleChange = (e) => {
        //console.log('event',e);
        this.setState({ iRSelectedValue: e.value });
    };


    render() {
        return (
            <div className="main-condition-modal">
                {this.state.visible && (
                    <Dialog className="condition-modal" title={""} onClose={this.toggleDialog}>
                        <ModalForm
                            state={this.state}
                            propsState={this.props.state}
                            onChangeSelect={this.onChangeSelect}
                            onChange={this.onChange}
                            onClickBox={this.onClickBox}
                            filterChange={this.filterChange}
                            onCityChange={this.onCityChange}
                            tagsChange={this.tagsChange}
                            onClickBoxTagsList={this.onClickBoxTagsList}
                            onModelChange={this.onModelChange}
                            onPredicateSwitchChange={this.onPredicateSwitchChange}
                            showOptions={this.props.showOptions}
                            inputChangeHandler={(e) => this.inputChangeHandler(e)}
                            onIpAdressChange={this.onIpAdressChange}
                            handleChange={this.handleChange}
                        />

                        <div className="flow-footer">
                            <div className="footer_buttons">
                                <Button
                                    className="cancel_flow_button button"
                                    onClick={this.toggleDialog}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="save_flow_button button"
                                    onClick={this.errorsCheck}
                                >
                                    Apply
                                </Button>
                            </div>
                        </div>
                    </Dialog>
                )}
            </div>
        );
    }
}

export const mapStateToProps = createStructuredSelector({
    brandDataReceived: makeSelectBrandData(),
    browserDataReceived: makeSelectBrowserData(),
    cityDataReceived: makeSelectCityData(),
    conditionCountryDataReceived: makeSelectConditionCountryData(),
    languageDataReceived: makeSelectLanguageData(),
    regionDataReceived: makeSelectRegionData(),
    connectionTypeDataReceived: makeSelectConnectionTypeData(),
    deviceTypeDataReceived: makeSelectDeviceData(),
    osDataReceived: makeSelectOperatingSystemData(),
    proxyDataReceived: makeSelectProxyData(),
    accessToken: makeSelectToken(),
    ispDataReceived: makeSelectISPData(),
    mcDataReceived: makeSelectMCData(),
    preCountriesDataRecieved: makeSelectCountriesListReceived(),
    allCitiesDataRecieved: makeSelectAllCitiesData(),
    allIspDataRecieved: makeSelectAllISPData(),
    weekdayDataReceived: makeSelectWeekDayData(),
    timezoneDataReceived: makeSelectLoadTimeZoneData(),
});


export function mapDispatchToProps(dispatch) {
    return {
        dispatchLoadFlows: (...payload) => dispatch(newFlowsActions.loadFlows(...payload)),
        deleteFlows: (...payload) => dispatch(newFlowsActions.deleteFlows(...payload)),
        editFlows: (...payload) => dispatch(newFlowsActions.editFlows(...payload)),
        dispatchLoadCountries: (...payload) => dispatch(newFlowsActions.loadCountries(...payload)),

        fetchBrandData: (...payload) => dispatch(newFlowsActions.loadBrand(...payload)),
        fetchCityData: (...payload) => dispatch(newFlowsActions.loadCity(...payload)),
        fetchAllCityData: (...payload) => dispatch(newFlowsActions.loadAllCity(...payload)),
        fetchCountryData: (...payload) => dispatch(newFlowsActions.loadConditionCountries(...payload)),
        fetchBrowserData: (...payload) => dispatch(newFlowsActions.loadBrowser(...payload)),
        fetchDeviceTypeData: (...payload) => dispatch(newFlowsActions.loadDeviceType(...payload)),
        fetchConnectionTypeData: (...payload) => dispatch(newFlowsActions.loadConnectionType(...payload)),
        fetchLanguageData: (...payload) => dispatch(newFlowsActions.loadLanguage(...payload)),
        fetchProxyData: (...payload) => dispatch(newFlowsActions.loadProxy(...payload)),
        fetchRegionData: (...payload) => dispatch(newFlowsActions.loadRegion(...payload)),
        fetchOperatingSystemData: (...payload) => dispatch(newFlowsActions.loadOperatingSystem(...payload)),
        fetchMCData: (...payload) => dispatch(newFlowsActions.loadMobileCarrier(...payload)),
        fetchISPData: (...payload) => dispatch(newFlowsActions.loadISP(...payload)),
        fetchAllISPData:(...payload) => dispatch(newFlowsActions.loadAllISP(...payload)),
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(EditCondtionModal);
