export const GET_SUB_LEVEL_DATA = "GET_SUB_LEVEL_DATA";
export const GET_SUB_LEVEL_DATA_SUCCESS = "GET_SUB_LEVEL_DATA_SUCCESS";
export const GET_SUB_LEVEL_DATA_FAILED = "GET_SUB_LEVEL_DATA_FAILED";

export const GET_COLUMNS_DATA = "GET_COLUMNS_DATA";
export const GET_COLUMNS_DATA_SUCCESS = "GET_COLUMNS_DATA_SUCCESS";
export const GET_COLUMNS_DATA_FAILED = "GET_COLUMNS_DATA_FAILED";

export const PATCH_COLUMNS_DATA = "PATCH_COLUMNS_DATA";
export const PATCH_COLUMNS_DATA_SUCCESS = "PATCH_COLUMNS_DATA_SUCCESS";
export const PATCH_COLUMNS_DATA_FAILED = "PATCH_COLUMNS_DATA_FAILED";

export const GET_CURRENCY_DATA = "GET_CURRENCY_DATA";
export const GET_CURRENCY_DATA_SUCCESS = "GET_CURRENCY_DATA_SUCCESS";
export const GET_CURRENCY_DATA_FAILED = "GET_CURRENCY_DATA_FAILED";