import { Button } from '@progress/kendo-react-buttons';
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import React, { Component } from 'react';
import LoadingPanel from '../../../grid/loader/loader';
import produce from 'immer';
import { Error } from '@progress/kendo-react-labels';
import {makeSelectRegistered, makeSelectToken} from '../../../../registration/LoginRegister/selectors';
import { makeSelectAllUsersData, makeSelectIsAllUsersDataLoaded,makeSelectIsUsersDataAdded,makeSelectUsersDataError,makeSelectUserUpdated,makeSelectIsDeleteSuccess, makeSelectEditUserDataLoaded } from '../selectors';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import * as userActions from "../actions";
import {formatDate} from "../../../../components/searchParameters";
import ModalForm from "./ModalForm";
import * as loginRegisterActions from "../../../../registration/LoginRegister/actions";

class DeleteUserModal extends Component {
    state = {
        loader: false,
        visible: false,
    }


    componentDidUpdate(prevProps){
        if ((prevProps.isDeleteSuccess !== this.props.isDeleteSuccess) && this.props.isDeleteSuccess !== false) {
            this.props.closeVisibleDeleteUserModalSuccess();
        }
    }

    toggleDialog = () => {
        this.setState(produce(draft => { draft.visible = !this.state.visible; }))
    };

    handleSubmit = () => {
        
        this.props.toggleLoader();
        this.props.DeleteUserData({
            accessToken: this.props.accessToken,
            id: this.props.id
        })
        
    };

    
    render() {

        return (
            <div className="add_user_modal">
                <Dialog className="delete_dialogue" title={"Delete"} onClose={() => this.props.closeVisibleDeleteUserModal()}>
                    <div>
                        Are you sure you want to delete this user ?
                    </div>
                    <DialogActionsBar>
                        <div className="action_bar_buttons">
                            <div className="left">
                                <button onClick={() => this.props.closeVisibleDeleteUserModal("")} className="normal_btn cancel_button gray_button_bg_imp black border_radius_normal_btn normal_text black no_border">Close</button>
                            </div>
                            <div className="right">
                                <button
                                    onClick={this.handleSubmit}
                                    className="normal_btn theme_dark_btn theme_dark_bg_color_imp color_white_imp border_radius_normal_btn save_button normal_text"
                                >Delete User</button>
                            </div>
                        </div>
                    </DialogActionsBar>
                </Dialog>
            </div>
        );
    }
}

export const mapStateToProps = createStructuredSelector({
    accessToken: makeSelectToken(),
    allUsersDataLoaded: makeSelectAllUsersData(),
    isAllUsersDataLoaded: makeSelectIsAllUsersDataLoaded(),
    isUserAdded:makeSelectIsUsersDataAdded(),
    error: makeSelectUsersDataError(),
    userUpdate: makeSelectUserUpdated(),
    editUserDataLoaded: makeSelectEditUserDataLoaded(),
    isDeleteSuccess: makeSelectIsDeleteSuccess()
});

export function mapDispatchToProps(dispatch) {
    return {
        fetchingUsersData: (...payload) => dispatch(userActions.loadAllUsers(...payload)),
        fetchEditUserData: (...payload) => dispatch(userActions.loadEditUserData(...payload)),
        addUser: (...payload) => dispatch(userActions.addUsers(...payload)),
        updateUser:(...payload) => dispatch(userActions.updateUsers(...payload)),
        signUpUser: (...payload) => dispatch(loginRegisterActions.signUpUser(...payload)),
        DeleteUserData: (...payload) => dispatch(userActions.DeleteUserData(...payload)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteUserModal);