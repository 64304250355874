export const LOAD_ALL_USERS_DATA= "LOAD_ALL_USERS_DATA";
export const LOAD_ALL_USERS_DATA_SUCCESS= "LOAD_ALL_USERS_DATA_SUCCESS";
export const LOAD_ALL_USERS_DATA_FAILED= "LOAD_ALL_USERS_DATA_FAILED";

export const ADD_USER_BY_ADMIN="ADD_USER_BY_ADMIN";
export const ADD_USER_BY_ADMIN_SUCCESS="ADD_USER_BY_ADMIN_SUCCESS";
export const ADD_USER_BY_ADMIN_FAILED="ADD_USER_BY_ADMIN_FAILED";

export const EDIT_USER="EDIT_USER";
export const EDIT_USER_SUCCESS="EDIT_USER_SUCCESS";
export const EDIT_USER_FAILED="EDIT_USER_FAILED";

export const UPDATE_USER="UPDATE_USER";
export const UPDATE_USER_SUCCESS="UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILED="UPDATE_USER_FAILED";


export const CHANGE_STATUS_USER="CHANGE_STATUS_USER";
export const CHANGE_STATUS_USER_SUCCESS="CHANGE_STATUS_USER_SUCCESS";
export const CHANGE_STATUS_USER_FAILED="CHANGE_STATUS_USER_FAILED";

export const DELETE_USER="DELETE_USER";
export const DELETE_USER_SUCCESS="DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED="DELETE_USER_FAILED";