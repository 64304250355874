import React from 'react'
import ReactTooltip from 'react-tooltip';

function DefaultDomain(props) {
    return (
        <div>
            <section className="section text_align_left">
                <h3 className="h3 light_font">Default Domain</h3>
                <div className="settings_table_con">
                    <table className="settings_table">
                        <tbody>
                            <tr>
                                <th>Domain</th>
                                <th>Assigned Workspace</th>
                                <th>Actions</th>
                            </tr>
                            {
                                (props.domainsDataForTable !== null && props.domainsDataForTable.length > 0) ?
                                    props.domainsDataForTable.filter(domain=>domain.userId===null).map((domainData,index) => (
                                        <tr key={index}>
                                            <td>
                                                <div className="icon_with_tooltip">
                                                    {
                                                        domainData.verified ? 
                                                        <>
                                                            <div data-tip data-for='domain_verification_tool' className="question_mark">
                                                                <span className="k-icon k-i-lock icon_color_success"></span>
                                                            </div>
                                                            <ReactTooltip id='domain_verification_tool' type='dark'>
                                                                <span>This domain has SSL activated.</span>
                                                            </ReactTooltip>
                                                        </>
                                                        : <span className="k-icon k-i-unlock icon_color_danger"></span>
                                                    }
                                                    &nbsp;
                                                    {domainData.name}
                                                </div>
                                            </td>
                                            <td>{domainData.workspaceName}</td>
                                            <td>
                                                {
                                                    domainData.userId === null && <div className="icons text_center">Default</div>
                                                }
                                            </td>
                                        </tr>
                                    )): <>
                                    {
                                        props.domainsDataForTable === null &&
                                            <tr>
                                                <td>No domains have been added yet.</td>
                                            </tr>
                                    }
                                </>
                            }
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    )
}

export default DefaultDomain
