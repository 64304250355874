import React,{Fragment, useEffect} from 'react';
import "../Domains/domainsConfiguration.css";
import "./TrackingURLs.css"
import Collapse from './collapse/collapse';
import { useSelector } from "react-redux";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from "react-router";
import { makeSelectDomainsDataReceived } from '../Domains/selectors';
import * as domainsActions from "../Domains/actions";

const URLS = (props) => {	
	const [inputValue, setInputValue] = React.useState(props.domainsDataReceived.domain[0].name);
	let [success, setSucess] = React.useState(false);

	const handleOnClick = (value,e) => {
		navigator.clipboard.writeText(value);
		setSucess(true);
		document.getElementById(e.target.id).innerHTML = "Copied";
		document.getElementById(e.target.id).style.color = "#29AF61";
		setTimeout(() => {
			document.getElementById(e.target.id).innerHTML = "Copy";
			document.getElementById(e.target.id).style.color = "#6786DE";
		}, 10000);
	}

	return (
		
		<Fragment>
			<div className="white_box_with_header_con white_bg tracking_url_con" >
                <header className="header light_gray_border_color">
					<div className="title_con">
						<h2 className="h2 gray_h2 inline_block">{ props.heading}</h2>
					</div>
				</header>
					<Collapse text={props.text} id={props.id}/>
					<hr />
					<p className="normal_font_p_tu"><b>Note:</b>&nbsp;{ props.note}</p>
					<h2 className="h2-text">
					<div>
						Tracking Domain
					</div>
				</h2>
				<div className="auto-complete">
					<select id="url" className="select-url"  value={props.selectedDomain} onChange={props.inputChangeHandler}>
						{
							props.domainsDataReceived.domain.map((domain) => (
								<option key={domain.id} value={domain.id}>{domain.name}</option>
							))
						}
					</select>
					<span className="k-icon k-i-arrows-kpi" />
				</div>
				{props.id === 1 ?
					<>
						<h2 className="h2-text">
							<div>
								{props.childHeading1}
							</div>
						</h2>
						<div className={props.id===1?"click-url1":"click-url"}>
							<textarea
								id = {"textarea"}
								value={`https://${props.inputValue}${props.url}`}
								readOnly
							/>
							<button id="btn-1" className="copy-url-btn" onClick={(e) => handleOnClick(document.getElementById("textarea").value,e)}>Copy</button>
						</div>
						<h2 className="h2-text">
							<div>
								{props.childHeading2}
							</div>
						</h2>
						<div className={props.id===1?"click-url1":"click-url"}>
							<textarea
								id={"textarea1"}
								value={props.id===1?`https://${props.inputValue}${props.url1}`:`https://${props.inputValue}${props.url}`}					
								readOnly
							/>
							<button id="btn-2" className="copy-url-btn" onClick={(e) => handleOnClick(document.getElementById("textarea1").value,e)}>Copy</button>
						</div>
					</> : null
				}
				{props.id === 2 ?
					<>
						<h2 className="h2-text">
							<div>
								{props.childHeading1}
							</div>
						</h2>
						<div className={"click-url"}>
							<textarea
								id = {"textarea6"}
								value={`https://${props.inputValue}${props.url}`}
								readOnly
							/>
							<button id="btn-6" className="copy-url-btn" onClick={(e) => handleOnClick(document.getElementById("textarea6").value,e)}>Copy</button>
						</div>	
						<h2 className="h2-text">
							<div>
								{props.childHeading2}
							</div>
						</h2>
						<div className={"click-url"}>
							<textarea
								id={"textarea5"}
								value={props.id===1?`https://${props.inputValue}${props.url1}`:`https://${props.inputValue}${props.url}`}					
								readOnly
							/>
							<button id="btn-5" className="copy-url-btn" onClick={(e) => handleOnClick(document.getElementById("textarea5").value,e)}>Copy</button>
						</div>				
					</> : null
				}
							{props.id === 3 ?
								<>
					<h2 className="h2-text">
							<div>
								{props.childHeading1}
							</div>
						</h2>
						<div className={"click-url"}>
							<textarea
								id = {"textarea3"}
								value={`https://${props.inputValue}${props.url}`}
								readOnly
							/>
							<button id="btn-3" className="copy-url-btn" onClick={(e) => handleOnClick(document.getElementById("textarea3").value,e)}>Copy</button>
						</div>
				
						<h2 className="h2-text">
							<div>
							{props.childHeading2}
							</div>
						</h2>
						<div className={"click-url"}>
							<textarea
									id={"textarea4"}
									value={`<img src="https://${props.inputValue}${props.url}"width="1"height="1"/>`}					
									readOnly
							/>
						<button id="btn-4" className="copy-url-btn" onClick={(e) => handleOnClick(document.getElementById("textarea4").value,e)}>Copy</button>
						</div>
								<h2 className="h2-text">
										<div>
											{props.childHeading3}
										</div>
								</h2>
								<div className={"click-url"}>
									<textarea
										id = {"textarea2"}
										value={`<script type="text/javascript" src="https://${props.inputValue}${props.url}"`}		
										readOnly
									/>
							<button id="btn-7" className="copy-url-btn" onClick={(e) => handleOnClick(document.getElementById("textarea2").value,e)}>Copy</button>
								</div>
							</> : null}
						<div className="box" style={{ paddingBottom: "28px" }} />
				</div>
			</Fragment>
		
	)
}


export const mapStateToProps = createStructuredSelector({
    domainsDataReceived: makeSelectDomainsDataReceived()
});
export function mapDispatchToProps(dispatch) {
    return {
        dispatchLoadDomains: (...payload) => dispatch(domainsActions.loadCustomDomains(...payload)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(URLS));