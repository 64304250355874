// import { Button } from '@progress/kendo-react-buttons';
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
// import { Field, Form, FormElement } from '@progress/kendo-react-form';
import React, { Component } from 'react';
// import LoadingPanel from '../../../grid/loader/loader';
import produce from 'immer';
// import { Error } from '@progress/kendo-react-labels';
import {makeSelectRegistered, makeSelectToken} from '../../../../registration/LoginRegister/selectors';
import { makeSelectAllUsersData, makeSelectIsAllUsersDataLoaded,makeSelectIsUsersDataAdded,makeSelectUsersDataError,makeSelectUserUpdated,makeSelectIsDeleteSuccess, makeSelectEditUserDataLoaded } from '../selectors';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import * as userActions from "../actions";
// import {formatDate} from "../../../../components/searchParameters";
import * as loginRegisterActions from "../../../../registration/LoginRegister/actions";
import "./preview.css";

class PreviewUserModal extends Component {
    state = {
        loader: false,
        visible: false,
    }

    componentDidMount() {
        this.props.toggleLoader();
        this.props.fetchEditUserData({
            accessToken: this.props.accessToken,
            id: this.props.id
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.editUserDataLoaded !== this.props.editUserDataLoaded) && this.props.editUserDataLoaded !== null) {
            this.props.toggleLoader();
        }
    }

    toggleDialog = () => {
        this.setState(produce(draft => { draft.visible = !this.state.visible; }))
    };

    
    render() {
        return (
            <div className="add_user_modal">
                <Dialog className="add_affiliate_networks_dialogue" title={"Preview"} onClose={() => this.props.closeVisiblePreviewUserModal()}>
                    {this.props.editUserDataLoaded ? 
                    <div>
                        <div className="profile_row">
                            <div className="container row">
                                <div className="col-md-6" style={{"width":"50%"}}>
                                    <label className="label normal_text color_black">First Name</label>
                                    <div className="label_content">
                                        <span className="content">{ this.props.editUserDataLoaded.firstname}</span>
                                    </div>
                                </div>
                                <div className="col-md-6" style={{"width":"50%"}}>
                                    <label className="label normal_text color_black">Last Name</label>
                                    <div className="label_content">
                                        <span className="content">{ this.props.editUserDataLoaded.lastname}</span>
                                    </div>
                                </div>
                                {/* <div className="col-md-6" style={{"width":"50%"}}>
                                    <label className="label normal_text color_black">Email</label>
                                    <div className="label_content">
                                        <span className="content">{ this.props.editUserDataLoaded.email}</span>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="profile_row">
                            <div className="container row">
                                <div className="col-md-12">
                                    <label className="label normal_text color_black">Email</label>
                                    <div className="label_content">
                                        <span className="content">{ this.props.editUserDataLoaded.email}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profile_row">
                            <div className="container row">
                                <div className="col-md-6" style={{"width":"50%"}}>
                                    <label className="label normal_text color_black" >Account Type</label>
                                    <div className="label_content">
                                        <span className="content">{this.props.editUserDataLoaded.accountType}</span>
                                    </div>
                                </div>
                                <div className="col-md-6" style={{"width":"50%"}}>
                                    <label className="label normal_text color_black" >Account Status</label>
                                    <div className="label_content">
                                        <span className="content">{this.props.editUserDataLoaded.accountStatus}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profile_row">
                            <div className="container row">
                                <div className="col-md-6"  style={{"width":"50%"}}>
                                    <label className="label normal_text color_black">LinkedIn or Facebook</label>
                                    <div className="label_content">
                                        <span className="content">{this.props.editUserDataLoaded.link===null || this.props.editUserDataLoaded.link==="" ? "-":  this.props.editUserDataLoaded.link}</span>
                                    </div>
                                </div>
                                {/* <div className="col-md-6" style={{"width":"50%"}}>
                                    <label className="label normal_text color_black">Company</label>
                                    <div className="label_content">
                                        <span className="content">{this.props.editUserDataLoaded.company===null || this.props.editUserDataLoaded.company === "" ? "-":  this.props.editUserDataLoaded.company}</span>
                                    </div>
                                </div> */}
                                <div className="col-md-6" style={{ width: "50%" }}>
                                    <label className="label normal_text color_black">
                                        Instant messenger
                                    </label>
                                    <div className="label_content">
                                        <span className="content">
                                            {this.props.editUserDataLoaded.instant_messenger_number === null
                                                ? "-"
                                                : this.props.editUserDataLoaded.instant_messenger +" - " + this.props.editUserDataLoaded.instant_messenger_number}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profile_row">
                            <div className="container row">
                                <div className="col-md-12" style={{ width: "50%" }}>
                                    <label className="label normal_text color_black">Website</label>
                                    <div className="label_content">
                                        <span className="content">{this.props.editUserDataLoaded.website===null || this.props.editUserDataLoaded.website==="" ? "-":  this.props.editUserDataLoaded.website}</span>
                                    </div>
                                </div>
                                {this.props.editUserDataLoaded.accountType === "Company" && (
                                <div className="col-md-6" style={{ width: "50%" }}>
                                    <label className="label normal_text color_black">
                                        Company
                                    </label>
                                    <div className="label_content">
                                        <span className="content">
                                            {this.props.editUserDataLoaded.company === null
                                                ? "-"
                                                : this.props.editUserDataLoaded.company}
                                        </span>
                                    </div>
                                </div>
                                )}
                            </div>
                        </div>
                        <div className="profile_row">
                            <div className="container row">
                                <div className="col-md-6" style={{"width":"50%"}}>
                                    <label className="label normal_text color_black">Address</label>
                                    <div className="label_content">
                                        <span className="content">{this.props.editUserDataLoaded.address===null ? "-":  this.props.editUserDataLoaded.address}</span>
                                    </div>
                                </div>
                                <div className="col-md-6" style={{"width":"50%"}}>
                                    <label className="label normal_text color_black">City</label>
                                    <div className="label_content">
                                        <span className="content">{this.props.editUserDataLoaded.city===null ? "-":  this.props.editUserDataLoaded.city}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profile_row">
                            <div className="container row">
                                <div className="col-md-6" style={{"width":"50%"}}>
                                    <label className="label normal_text color_black">Region / State / Province</label>
                                    <div className="label_content">
                                        <span className="content">{this.props.editUserDataLoaded.region===null ? "-":  this.props.editUserDataLoaded.region}</span>
                                    </div>
                                </div>
                                <div className="col-md-6" style={{"width":"50%"}}>
                                    <label className="label normal_text color_black">Country</label>
                                    <div className="label_content">
                                        <span className="content">{this.props.editUserDataLoaded.country===null ? "-":  this.props.editUserDataLoaded.country}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profile_row">
                            <div className="container row">
                                <div className="col-md-6">
                                    <label className="label normal_text color_black">Zip Code / Postal Code </label>
                                    <div className="label_content">
                                        <span className="content">{this.props.editUserDataLoaded.zipCode===null ? "-":  this.props.editUserDataLoaded.zipCode}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profile_row">
                            <div className="container row">
                                <div className="col-md-6">
                                    <label className="label normal_text color_black">Phone Number</label>
                                    <div className="label_content">
                                        <span className="content">{this.props.editUserDataLoaded.phoneNumber===null ? "-":  this.props.editUserDataLoaded.phoneNumber}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null }
                    <DialogActionsBar>
                        <div className="action_bar_buttons">
                            <div className="left">
                                <button onClick={() => this.props.closeVisiblePreviewUserModal("")} className="normal_btn cancel_button gray_button_bg_imp black border_radius_normal_btn normal_text black no_border">Close</button>
                            </div>
                        </div>
                    </DialogActionsBar>
                </Dialog>
            </div>
        );
    }
}

export const mapStateToProps = createStructuredSelector({
    accessToken: makeSelectToken(),
    allUsersDataLoaded: makeSelectAllUsersData(),
    isAllUsersDataLoaded: makeSelectIsAllUsersDataLoaded(),
    isUserAdded:makeSelectIsUsersDataAdded(),
    error: makeSelectUsersDataError(),
    userUpdate: makeSelectUserUpdated(),
    editUserDataLoaded: makeSelectEditUserDataLoaded(),
    isDeleteSuccess: makeSelectIsDeleteSuccess()
});

export function mapDispatchToProps(dispatch) {
    return {
        fetchingUsersData: (...payload) => dispatch(userActions.loadAllUsers(...payload)),
        fetchEditUserData: (...payload) => dispatch(userActions.loadEditUserData(...payload)),
        addUser: (...payload) => dispatch(userActions.addUsers(...payload)),
        updateUser:(...payload) => dispatch(userActions.updateUsers(...payload)),
        signUpUser: (...payload) => dispatch(loginRegisterActions.signUpUser(...payload)),
        DeleteUserData: (...payload) => dispatch(userActions.DeleteUserData(...payload)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewUserModal);