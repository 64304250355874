import {

    LOAD_COUNTRY,
    LOAD_COUNTRY_SUCCESS,
    LOAD_COUNTRY_FAILED,

    POST_LANDER_DATA,
    POST_LANDER_DATA_SUCCESS,
    POST_LANDER_DATA_FAILED,

    LOAD_ALL_LANDERS_DATA,
    LOAD_ALL_LANDERS_DATA_SUCCESS,
    LOAD_ALL_LANDERS_DATA_FAILED,

    LOAD_EDIT_LANDER_DATA,
    LOAD_EDIT_LANDER_DATA_SUCCESS,
    LOAD_EDIT_LANDER_DATA_FAILED,

    EDIT_LANDER_DIALOGUE_CLOSED,

    SEND_EDIT_LANDER_DATA,
    SEND_EDIT_LANDER_DATA_SUCCESS,
    SEND_EDIT_LANDER_DATA_FAILED,

    DELETE_LANDER_DATA,
    DELETE_LANDER_DATA_SUCCESS,
    DELETE_LANDER_DATA_FAILED,

    LOAD_TOKEN_TAGS,
    LOAD_TOKEN_TAGS_SUCCESS,
    LOAD_TOKEN_TAGS_FAILED,

    ARCHIVE_LANDER_DATA,
    ARCHIVE_LANDER_DATA_SUCCESS,
    ARCHIVE_LANDER_DATA_FAILED,

    GET_LANDER_CHARTS_DATA,
    GET_LANDER_CHARTS_DATA_SUCCESS,
    GET_LANDER_CHARTS_DATA_FAILED
} from "./constants";


// Loading Countries Actions
export const loadCountry = (loadCountryPayload) => {
    return {
        type: LOAD_COUNTRY,
        payload: loadCountryPayload
    };
};

export const loadCountrySuccess = (loadCountySuccessPayload) => {
    return {
        type: LOAD_COUNTRY_SUCCESS,
        payload: loadCountySuccessPayload
    };
};

export const loadCountryFailed = (error) => {
    return {
        type: LOAD_COUNTRY_FAILED,
        payload: error
    };
};


// Sending New Traffic Sources data Actions
export const postLander = (postLandersPayload) => {
    return {
        type: POST_LANDER_DATA,
        payload: postLandersPayload
    };
};

export const postLanderSuccess = (postLanderSuccessPayload) => {
    return {
        type: POST_LANDER_DATA_SUCCESS,
        payload: postLanderSuccessPayload
    };
};

export const postLanderFailed = (error) => {
    return {
        type: POST_LANDER_DATA_FAILED,
        payload: error
    };
};


// Load PreLanders data Actions
export const loadAllLanders = (loadAllLandersPayload) => {
    return {
        type: LOAD_ALL_LANDERS_DATA,
        payload: loadAllLandersPayload
    };
};

export const loadAllLandersSuccess = (loadAllLandersSuccessPayload) => {
    return {
        type: LOAD_ALL_LANDERS_DATA_SUCCESS,
        payload: loadAllLandersSuccessPayload
    };
};

export const loadAllLandersFailed = (error) => {
    return {
        type: LOAD_ALL_LANDERS_DATA_FAILED,
        payload: error
    };
};

// EDIT Traffic Sources data Actions
export const loadEditLander = (editLanderPayload) => {
    return {
        type: LOAD_EDIT_LANDER_DATA,
        payload: editLanderPayload
    };
};

export const loadEditLanderSuccess = (editLanderSuccessPayload) => {
    return {
        type: LOAD_EDIT_LANDER_DATA_SUCCESS,
        payload: editLanderSuccessPayload
    };
};

export const loadEditLanderFailed = (error) => {
    return {
        type: LOAD_EDIT_LANDER_DATA_FAILED,
        payload: error
    };
};

// Close edit lander and cancel data in edit lander data received
export const editLanderDialogueClosed = () => {
    return {
        type: EDIT_LANDER_DIALOGUE_CLOSED
    };
};


// Send EDIT Traffic Sources data Actions
export const sendEditLander = (sendEditLanderPayload) => {
    return {
        type: SEND_EDIT_LANDER_DATA,
        payload: sendEditLanderPayload
    };
};

export const sendEditLanderSuccess = (sendEditLanderSuccessPayload) => {
    return {
        type: SEND_EDIT_LANDER_DATA_SUCCESS,
        payload: sendEditLanderSuccessPayload
    };
};

export const sendEditLanderFailed = (error) => {
    return {
        type: SEND_EDIT_LANDER_DATA_FAILED,
        payload: error
    };
};


// Delete Lander data Actions
export const deleteLander = (deleteLanderPayload) => {
    return {
        type: DELETE_LANDER_DATA,
        payload: deleteLanderPayload
    };
};

export const deleteLanderSuccess = (deleteLanderSuccessPayload) => {
    return {
        type: DELETE_LANDER_DATA_SUCCESS,
        payload: deleteLanderSuccessPayload
    };
};

export const deleteLanderFailed = (error) => {
    return {
        type: DELETE_LANDER_DATA_FAILED,
        payload: error
    };
};

// Archive Lander data Actions
export const archiveLander = (payload) => {
    return {
        type: ARCHIVE_LANDER_DATA,
        payload: payload
    };
};

export const archiveLanderSuccess = (payload) => {
    return {
        type: ARCHIVE_LANDER_DATA_SUCCESS,
        payload: payload
    };
};

export const archiveLanderFailed = (error) => {
    return {
        type: ARCHIVE_LANDER_DATA_FAILED,
        payload: error
    };
};

// Load Tokens Dictionary data Actions
export const loadTokensDictionary = (loadTokensDictionaryPayload) => {
    return {
        type: LOAD_TOKEN_TAGS,
        payload: loadTokensDictionaryPayload
    };
};

export const loadTokensDictionarySuccess = (loadTokensDictionarySuccessPayload) => {
    return {
        type: LOAD_TOKEN_TAGS_SUCCESS,
        payload: loadTokensDictionarySuccessPayload
    };
};

export const loadTokensDictionaryFailed = (error) => {
    return {
        type: LOAD_TOKEN_TAGS_FAILED,
        payload: error
    };
};

export const loadLanderChartsData = (payload) => {
    return {
        type: GET_LANDER_CHARTS_DATA,
        payload: payload
    };
};

export const loadLanderChartsDataSuccess = (payload) => {
    return {
        type: GET_LANDER_CHARTS_DATA_SUCCESS,
        payload: payload
    };
};

export const loadLanderChartsDataFailed = (error) => {
    return {
        type: GET_LANDER_CHARTS_DATA_FAILED,
        payload: error
    };
};
