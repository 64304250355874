import * as requestHandler from "../../helpers/requestHandlers";
import {
    PATH_LOAD_COUNTRIES_LIST,
    PATH_LANDERS,
    PATH_LANDERS_SHORT,
    PATH_LANDERS_ARCHIVED,
    PATH_LANDERS_CHART,
    PATH_GROUPING,
    PATH_LOAD_TOKENS_DICTIONARY
} from "../../helpers/path"; 
import { checkFilters } from "../../../components/checkNamesForGrouping/checkNames";
import { returnTimeZoneId } from "../../../components/timeZonesExchange";


export const LoadCountriesListFromAPI = async (request) => {
    const response = await requestHandler.get(PATH_LOAD_COUNTRIES_LIST);
    return response;
};

export const PostNewLanderFromAPI = async (request) => {
    const response = await requestHandler.post(PATH_LANDERS, request.payload.data);
    return response;
};

export const LoadAllLandersFromAPI = async (request) => {
    let endDate = request.payload.searchQuery.modifiedDate.endDate;
    let startDate = request.payload.searchQuery.modifiedDate.startDate;

    let timeZoneInLocalStorage = request.payload.searchQuery.dates.timeZone;
    let timeZoneIdToSend = returnTimeZoneId(timeZoneInLocalStorage);

    let filtersToSend = checkFilters(request.payload.searchQuery.groupingValues.main, request.payload.searchQuery.groupingValues.group1, request.payload.searchQuery.groupingValues.group2);

    let parameters = "?filter="+ (request.payload.primaryPage ? request.payload.searchQuery.inputSearchValue : "") + "&date=&from="+ startDate +"&to="+ endDate +"&tags="+request.payload.searchQuery.tagsSearchValue+"&main="+ filtersToSend[0] + "&group1="+ filtersToSend[1] + "&group2="+ filtersToSend[2] + "&order=" + request.payload.sorting.order + "&sort=" + request.payload.sorting.sort + "&skip=" + request.payload.searchQuery.pagination.skip + "&take=" + request.payload.searchQuery.pagination.take + "&workspace=" + request.payload.searchQuery.selectedWorkspacesList + "&timezone=" + timeZoneIdToSend + "&status=" + request.payload.searchQuery.filterStatus;
    if (request.payload.primaryPage) {
        const response = await requestHandler.get(PATH_GROUPING + parameters);
        return response;
    } else {
        if (request.payload.dataType) {
            const response = await requestHandler.get((request.payload.dataType === "short" ? PATH_LANDERS_SHORT : PATH_LANDERS) + parameters);
            return response;
        } else {
            const response = await requestHandler.get(PATH_LANDERS + parameters);
            return response;
        }
    }

};

export const LoadEditLanderFromAPI = async (request) => {
    const response = await requestHandler.get(PATH_LANDERS + request.payload.loadEditLanderId);
    return response;
};

export const SendEditLanderFromAPI = async (request) => {
    const data = request.payload.data;
    let url = PATH_LANDERS + request.payload.sendEditLanderId;
    const response = await requestHandler.patch(url, data);
    return response;
};

export const DeleteLanderFromAPI = async (request) => {
    const response = await requestHandler.delet(PATH_LANDERS + request.payload.landerId);
    return response;
};

export const ArchiveLanderFromAPI = async (request) => {
    let url = PATH_LANDERS_ARCHIVED + request.payload.landerId;
    const response = await requestHandler.post(url,{ value: request.payload.value});
    return response;
};

export const LoadTokensDictionaryFromAPI = async (request) => {
    const response = await requestHandler.get(PATH_LOAD_TOKENS_DICTIONARY);
    return response;
};

export const LoadLanderChartsDataFromAPI = async (request) => {
    let search = JSON.parse(localStorage.getItem('search'));
    let timeZoneInLocalStorage = search.dates.timeZone;
    let timeZoneIdToSend = returnTimeZoneId(timeZoneInLocalStorage);
    let endDate = request.payload.dates.endDate;
    let startDate = request.payload.dates.startDate;
    let parameters = "to=" + endDate + "&from=" + startDate + "&workspace=" + search.selectedWorkspacesList + "&timezone=" + timeZoneIdToSend;
    
    const response = await requestHandler.get(PATH_LANDERS_CHART + parameters);
    return response;
};