import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Layout from "../../../layout";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
//import PageMenuBarTop from "./PageMenuBarTop";
import PageMenuBarTop from "../../PageMenuBarTop";
import PageMenuBarBottom from "./PageMenuBarBottom";
import "../pages.css";
import { createStructuredSelector } from "reselect";
import AddLanderModal from "./LanderPageModal";
import DeletePageModal from "./DeleteLanderPageModal";
import EditLanderModal from "./LanderEditModal";
import {
  makeSelectIsNewLanderDataSent,
  // makeSelectIsAllLandersLoaded,
  makeSelectIsDeleteLanderDataSent,
  makeSelectIsArchiveLanderDataSent,
  makeSelectAllLandersDataLoaded,
  makeSelectIsPatchedLanderDataSent,
  makeSelectAllChartsDataLoaded,
  makeSelectIsChartsDataLoaded,
  makeSelectError,
  makeSelectArchiveDeleteResponseLanders,
} from "./selectors";
import {
  postLander,
  loadAllLanders,
  deleteLander,
  editLanderDialogueClosed,
  archiveLander,
  loadLanderChartsData,
} from "./actions";
import { makeSelectToken } from "../../../registration/LoginRegister/selectors";
import { makeSelectDomainsDataReceived } from "../../Settings/Domains/selectors";
import { loadCustomDomains } from "../../Settings/Domains/actions";
import "./Landers.css";
import produce from "immer";
import { Fade } from "@progress/kendo-react-animation";
import GridClassComponent from "../../grid/grid";
import MenuCharts from "../../charts";
import { makeSelectMoveToWorkspace } from "../../../moveToWorkspaceRedux/selectors";
import { moveToWorkspace } from "../../../moveToWorkspaceRedux/actions";
import LoadingPanel from "../../grid/loader/loader";

class Landers extends Component {
  state = {
    visibleAddLanderDialogue: false,
    visibleEditLanderDialogue: false,
    visibleDeleteLanderDialogue: false,
    isPreLander: false,
    selectedAddPreLanderMethod: "",
    allLoadedLandersData: null,
    loadedLandersData: null,
    loadedPreLandersData: null,
    isNewLanderAddedSuccessfully: false,
    isLanderPatched: false,
    loadedWorkspaces: null,
    loadedDomains: null,
    editLanderId: null,
    success_msg: "",
    deletedSuccessfully: false,
    archivedSuccessfully: false,
    updateState: false,
    paginationSize: 10,
    selectedListReceivedFromGrid: [],
    isDuplicate: false,
    isArchive: false,
    archiveType: "",
    charts: {
      showCharts: false,
      chartsData: null,
      isChartsDataLoaded: false,
    },
    pageContentHeight: "500",
    groupingValues: {
      main: "",
      group1: "",
      group2: "",
    },
    showCalendar: false,
    showColumnsModal: false,
    sorting: {
      order: "visits",
      sort: "ASC",
    },
    loader: false,
    pagination: {
      skip: 0,
      take: 50,
      page: 1,
      total: 0,
      totalPages: 1,
      blur: {
        blurFirst: true,
        blurLast: true,
        blurPrev: true,
        blurNext: true,
      },
    },
    filterStatus: "all",
    backendErrors: {
      error: false,
      errorMsg: "",
    },
    disableReport: false,
    reportCount: 0,
    archiveDeleteResponseLanders: null,
    showNoRecordFound: false,
  };

  fetchDomainsAndWorkspaces = async () => {
    return this.props.dispatchLoadDomainsAndWorkspaces(this.props.accessToken);
  };

  fetchAllLanders = async () => {
    this.setState(
      produce((draft) => {
        draft.loader = true;
        draft.selectedListReceivedFromGrid = [];
      })
    );
    let search = JSON.parse(localStorage.getItem("search"));
    this.setState(
      produce((draft) => {
        draft.groupingValues = search.groupingValues;
      })
    );
    return this.props.dispatchLoadAllLanders({
      accessToken: this.props.accessToken,
      searchQuery: JSON.parse(localStorage.getItem("search")),
      sorting: this.state.sorting,
      primaryPage: true,
    });
  };
  fetchChartsData = () => {
    return this.props.dispatchLoadChartsData({
      accessToken: this.props.accessToken,
      dates: JSON.parse(localStorage.getItem("search")).modifiedDate,
    });
  };
  editLanderDialogueClosed = () => {
    return this.props.editLanderDialogueClosed();
  };

  componentDidMount() {
    // check if main group value is not lander value...
    // than set it to lander value to avoid any errors.
    if (
      JSON.parse(localStorage.getItem("search")).groupingValues.main !==
      "Lander"
    ) {
      let search = JSON.parse(localStorage.getItem("search"));
      search.groupingValues.main = "Lander";
      localStorage.setItem("search", JSON.stringify(search));
    }
    // set initial grouping values in state to be passed on to the grid...
    // so that is does not show error grouping values of none.
    if (JSON.parse(localStorage.getItem("search"))) {
      this.setState(
        produce((draft) => {
          draft.groupingValues.main = JSON.parse(
            localStorage.getItem("search")
          ).groupingValues.main;
          draft.groupingValues.group1 = JSON.parse(
            localStorage.getItem("search")
          ).groupingValues.group1;
          draft.groupingValues.group2 = JSON.parse(
            localStorage.getItem("search")
          ).groupingValues.group2;
          draft.reportCount = JSON.parse(
            localStorage.getItem("search")
          ).report.reportCount;
          draft.filterStatus = JSON.parse(
            localStorage.getItem("search")
          ).filterStatus;
        })
      );
    }

    // fetching currencies and workspaces initially
    if (
      this.state.loadedWorkspaces === null ||
      this.state.loadedDomains === null
    ) {
      this.fetchDomainsAndWorkspaces();
    }
    if (this.state.allLoadedLandersData === null) {
      this.fetchAllLanders();
    }
    // code for setting grid height initially
    let pageMenuHeight = Number(
      document.getElementById("page_menu").clientHeight
    );
    let pageFullHeight = Number(
      document.getElementsByTagName("html")[0].clientHeight
    );
    this.setState(
      produce((draft) => {
        draft.pageContentHeight = pageFullHeight - pageMenuHeight - 127;
      })
    );
    this.setState({ showNoRecordFound: true });
  }

  returnWorkspaceName = (id) => {
    for (let i = 0; i < this.state.loadedWorkspaces.length; i++) {
      if (id === this.state.loadedWorkspaces[i].id) {
        return this.state.loadedWorkspaces[i].name;
      }
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Set workspaces and currencies initial values in the state
    if (
      this.props.domainsAndWorkspacesListsReceived !== null &&
      this.props.domainsAndWorkspacesListsReceived !== undefined &&
      this.state.loadedWorkspaces === null
    ) {
      this.setState(
        produce((draft) => {
          draft.loadedWorkspaces =
            this.props.domainsAndWorkspacesListsReceived.workspace;
        })
      );
      this.setState(
        produce((draft) => {
          draft.loadedDomains =
            this.props.domainsAndWorkspacesListsReceived.domain;
        })
      );
    }

    if (prevProps.chartsAllDataLoaded !== this.props.chartsAllDataLoaded) {
      this.setState(
        produce((draft) => {
          draft.charts.chartsData = this.props.chartsAllDataLoaded;
        })
      );
    }

    // load data from props to state after each update in the data, edit / delete / add
    // then make isLanders Separated to false so that the next loop can run and separate the landers and pre landers
    if (
      prevProps.allLandersDataLoaded !== this.props.allLandersDataLoaded &&
      this.props.allLandersDataLoaded !== null
    ) {
      this.setState(
        produce((draft) => {
          draft.allLoadedLandersData = this.props.allLandersDataLoaded;
          if (this.props.allLandersDataLoaded.total) {
            draft.pagination.total =
              this.props.allLandersDataLoaded.total.totalRows;
          }
          draft.loader = false;
        })
      );
      this.setTotalPagination();
      setTimeout(() => {
        this.setPaginationUI();
      }, 50);
    }
    if (
      prevProps.isMovedToWorkspace !== this.props.isMovedToWorkspace &&
      this.props.isMovedToWorkspace === true
    ) {
      this.fetchAllLanders();
      this.setState(
        produce((draft) => {
          draft.loader = false;
        })
      );
    }
    // check if new landers data has been sent then load fresh copy of data
    // if (this.props.isNewLanderDataSent === true && this.state.isNewLanderAddedSuccessfully === true) {
    if (
      prevProps.isNewLanderDataSent !== this.props.isNewLanderDataSent &&
      this.props.isNewLanderDataSent === true &&
      this.state.isNewLanderAddedSuccessfully === true
    ) {
      this.setState(
        produce((draft) => {
          draft.loader = false;
          if (this.state.visibleEditLanderDialogue === true) {
            draft.visibleEditLanderDialogue =
              !this.state.visibleEditLanderDialogue;
          }
          if (this.state.visibleAddLanderDialogue === true) {
            draft.visibleAddLanderDialogue =
              !this.state.visibleAddLanderDialogue;
          }
          draft.isNewLanderAddedSuccessfully = false;
        })
      );
      this.fetchAllLanders();
    }

    // Display message on delete
    if (
      prevProps.isLanderDeleted !== this.props.isLanderDeleted &&
      this.state.deletedSuccessfully
    ) {
      if (this.props.isLanderDeleted === true) {
        this.setState(
          produce((draft) => {
            draft.visibleDeleteLanderDialogue =
              !this.state.visibleDeleteLanderDialogue;
            draft.success_msg = "Lander deleted successfully";
            draft.loader = false;
          })
        );
        // this.setState(produce(draft => { draft.loader = false;}));
        this.fetchAllLanders();
        // change deletedSuccessfully to false to that this loop runs only once
        this.setState(
          produce((draft) => {
            draft.deletedSuccessfully = false;
          })
        );
        setTimeout(() => {
          this.setState(
            produce((draft) => {
              draft.success_msg = "";
            })
          );
        }, 10000);
        // this.setState(produce(draft => { draft.selectedListReceivedFromGrid = [] }));
      } else {
      }
    }

    // Display message on archive
    // if (this.props.isLanderArchived && this.state.archivedSuccessfully) {
    if (
      prevProps.isLanderArchived !== this.props.isLanderArchived &&
      this.props.isLanderArchived === true &&
      this.state.archivedSuccessfully === true
    ) {
      this.setState(
        produce((draft) => {
          draft.loader = false;
          draft.visibleDeleteLanderDialogue =
            !this.state.visibleDeleteLanderDialogue;
          draft.success_msg = "Lander archived successfully";
        })
      );
      this.fetchAllLanders();
      // change deletedSuccessfully to false to that this loop runs only once
      this.setState(
        produce((draft) => {
          draft.archivedSuccessfully = false;
        })
      );
      setTimeout(() => {
        this.setState(
          produce((draft) => {
            draft.success_msg = "";
          })
        );
      }, 10000);
    }

    // Display message on Patched lander success
    // if (this.props.isLanderPatched && this.state.isLanderPatched) {
    if (
      prevProps.isLanderPatched !== this.props.isLanderPatched &&
      this.state.isLanderPatched === true &&
      this.props.isLanderPatched === true
    ) {
      this.setState(
        produce((draft) => {
          draft.loader = false;
          draft.visibleEditLanderDialogue =
            !this.state.visibleEditLanderDialogue;
          draft.success_msg = "Lander Updated successfully";
          // change deletedSuccessfully to false to that this loop runs only once
          draft.isLanderPatched = false;
        })
      );
      // this.setState(produce(draft => { draft.isLandersSeparated = false }));
      this.fetchAllLanders();
      setTimeout(() => {
        this.setState(
          produce((draft) => {
            draft.success_msg = "";
          })
        );
      }, 10000);
    }

    // Check the error
    if (prevProps.Error !== this.props.Error && this.props.Error !== false) {
      if (this.props.Error && this.props.Error.data) {
        this.setState(
          produce((draft) => {
            draft.backendErrors.errorMsg =
              (this.props.Error.data.message
                ? this.props.Error.data.message
                : null) +
              ", " +
              (this.props.Error.data.error
                ? this.props.Error.data.error
                : null);
            draft.backendErrors.error = true;
            draft.loader = false;
          })
        );
      }
    }

    // check for archive or delete or restore message from backend
    if (
      prevProps.archiveDeleteResponseLanders !==
        this.props.archiveDeleteResponseLanders &&
      this.props.archiveDeleteResponseLanders !== null
    ) {
      this.setState(
        produce((draft) => {
          draft.archiveDeleteResponseLanders =
            this.props.archiveDeleteResponseLanders;
        })
      );
      setTimeout(() => {
        this.setState(
          produce((draft) => {
            draft.archiveDeleteResponseLanders = null;
          })
        );
      }, 10000);
    }
  }

  // toggle modal windows
  toggleLanderDialogue = (data) => {
    if (data === "Pre_Add_Toggle_Lander") {
      this.setState(
        produce((draft) => {
          draft.loader = true;
          draft.visibleAddLanderDialogue = !this.state.visibleAddLanderDialogue;
          draft.isPreLander = true;
        })
      );
    } else if (data === "Add_Toggle_Lander") {
      this.setState(
        produce((draft) => {
          draft.loader = true;
          draft.isPreLander = false;
          draft.visibleAddLanderDialogue = !this.state.visibleAddLanderDialogue;
        })
      );
    } else if (data === "Close_Add_Toggle_Lander") {
      this.setState(
        produce((draft) => {
          draft.visibleAddLanderDialogue = !this.state.visibleAddLanderDialogue;
        })
      );
    } else if (data === "Close_EDIT_Toggle_Lander") {
      this.setState(
        produce((draft) => {
          draft.visibleEditLanderDialogue =
            !this.state.visibleEditLanderDialogue;
        })
      );
      this.editLanderDialogueClosed();
    } else if (data === "Close_Delete_Toggle_Lander") {
      this.setState(
        produce((draft) => {
          draft.visibleDeleteLanderDialogue =
            !this.state.visibleDeleteLanderDialogue;
        })
      );
    }
  };

  // Close the Modal window after data submission and
  // make isNewLanderAddedSuccessfully to true to fetch the landers again.
  closeAddLanderDialogueAfterSubmission = () => {
    this.setState(
      produce((draft) => {
        draft.loader = true;
        draft.isNewLanderAddedSuccessfully = true;
      })
    );
  };

  // ------------------
  // edit functionality
  openEditLanderDialog = (data) => {
    if (data === "Edit") {
      this.setState(
        produce((draft) => {
          draft.isDuplicate = false;
        })
      );
    } else if (data === "Duplicate") {
      this.setState(
        produce((draft) => {
          draft.isDuplicate = true;
        })
      );
    }
    this.setState(
      produce((draft) => {
        draft.loader = true;
        draft.editLanderId = this.state.selectedListReceivedFromGrid[0].id;
        draft.visibleEditLanderDialogue = !this.state.visibleEditLanderDialogue;
      })
    );
  };
  closeEditLanderDialogueAfterSubmission = (data) => {
    this.setState(
      produce((draft) => {
        draft.loader = true;
      })
    );
    if (data === "Edit") {
      this.setState(
        produce((draft) => {
          draft.isLanderPatched = true;
        })
      );
    }
    if (data === "Duplicate") {
      this.setState(
        produce((draft) => {
          draft.isNewLanderAddedSuccessfully = true;
        })
      );
    }
  };

  sendSearchRequest = () => {
    let search = JSON.parse(localStorage.getItem("search"));
    search.pagination.skip = 0;
    if (
      search.groupingValues.main === "conversion" ||
      search.groupingValues.main === "months" ||
      search.groupingValues.main === "days" ||
      search.groupingValues.main === "hours"
    ) {
      this.setState(
        produce((draft) => {
          draft.disableReport = true;
        })
      );
    } else {
      this.setState(
        produce((draft) => {
          draft.disableReport = false;
        })
      );
    }
    localStorage.setItem("search", JSON.stringify(search));
    this.setState(
      produce((draft) => {
        draft.pagination.page = 1;
      })
    );
    this.setPaginationUI();
    this.fetchAllLanders();
    this.fetchChartsData();
    this.setState(
      produce((draft) => {
        draft.showCalendar = false;
      })
    );
  };

  gridCheckedList = (list) => {
    this.setState(
      produce((draft) => {
        draft.selectedListReceivedFromGrid = list;
      })
    );
  };

  toggleDeleteArchiveModal = (data) => {
    this.setState(
      produce((draft) => {
        draft.loader = true;
      })
    );
    this.setState(
      produce((draft) => {
        draft.visibleDeleteLanderDialogue =
          !this.state.visibleDeleteLanderDialogue;
        if (data === "Archive") {
          draft.archiveType = "Archive";
        } else if (data === "Delete") {
          draft.archiveType = "Delete";
        } else if (data === "Restore") {
          draft.archiveType = "Restore";
        } else if (data === "Close") {
          draft.archiveType = "";
        }
      })
    );
    this.setState(
      produce((draft) => {
        draft.loader = false;
      })
    );
  };

  archiveLanderHandler = (data) => {
    this.setState(
      produce((draft) => {
        draft.loader = true;
        draft.archivedSuccessfully = true;
      })
    );
    let ids = [];
    for (let i = 0; i < this.state.selectedListReceivedFromGrid.length; i++) {
      ids.push(this.state.selectedListReceivedFromGrid[i].id);
    }
    return this.props.dispatchArchiveLander({
      accessToken: this.props.accessToken,
      landerId: ids,
      value: data === "Archive" ? false : true,
    });
  };

  deleteLanderOnSubmit = () => {
    this.setState(
      produce((draft) => {
        draft.loader = true;
        draft.deletedSuccessfully = true;
      })
    );
    let ids = [];
    for (let i = 0; i < this.state.selectedListReceivedFromGrid.length; i++) {
      ids.push(this.state.selectedListReceivedFromGrid[i].id);
    }
    return this.props.dispatchDeleteLander({
      accessToken: this.props.accessToken,
      landerId: ids,
    });
  };

  showCharts = () => {
    if (this.state.charts.showCharts !== true) {
      this.fetchChartsData();
    }
    this.setState(
      produce((draft) => {
        draft.showCalendar = false;
        draft.charts.showCharts = !this.state.charts.showCharts;
        draft.charts.isChartsDataLoaded = !this.state.charts.isChartsDataLoaded;
      })
    );
  };

  toggleCalendarWindow = () => {
    this.setState(
      produce((draft) => {
        draft.showCalendar = !this.state.showCalendar;
      })
    );
  };

  moveToWorkspaceMethod = (workspaceId) => {
    this.setState(
      produce((draft) => {
        draft.loader = true;
      })
    );
    let selectedList = [...this.state.selectedListReceivedFromGrid];
    let idStringList = [];
    for (let i = 0; i < this.state.selectedListReceivedFromGrid.length; i++) {
      idStringList.push(selectedList[i].id);
    }
    // setTimeout(() => {
    //     this.setState(produce(draft => { draft.loader = false;}));
    // }, 1000);
    return this.props.dispatchMoveToWorkspace({
      accessToken: this.props.accessToken,
      query: {
        type: "lander",
        typeIdStringList: idStringList.join(","),
        workspaceId: workspaceId,
      },
    });
  };
  toggleColumnsModal = () => {
    this.setState(
      produce((draft) => {
        draft.showColumnsModal = !this.state.showColumnsModal;
      })
    );
  };
  changeSorting = (data) => {
    let sorting = { ...this.state.sorting };
    if (String(sorting.order) === String(data) && sorting.sort === "ASC") {
      this.setState(
        produce((draft) => {
          draft.sorting.sort = "DESC";
        })
      );
    } else if (
      String(sorting.order) === String(data) &&
      sorting.sort === "DESC"
    ) {
      this.setState(
        produce((draft) => {
          draft.sorting.sort = "ASC";
        })
      );
    } else if (String(sorting.order) !== String(data)) {
      this.setState(
        produce((draft) => {
          draft.sorting.order = data;
          draft.sorting.sort = "ASC";
        })
      );
    }
    setTimeout(() => {
      this.fetchAllLanders();
    }, 50);
  };

  setTotalPagination = () => {
    if (this.props.allLandersDataLoaded.total) {
      if (Number(this.props.allLandersDataLoaded.total.totalRows) > 0) {
        let totalRows = Number(this.props.allLandersDataLoaded.total.totalRows);
        if (totalRows <= this.state.pagination.take) {
          this.setState(
            produce((draft) => {
              draft.pagination.totalPages = 1;
            })
          );
        } else {
          if (totalRows % Number(this.state.pagination.take) > 0) {
            this.setState(
              produce((draft) => {
                draft.pagination.totalPages =
                  Math.floor(totalRows / this.state.pagination.take) + 1;
              })
            );
          } else {
            this.setState(
              produce((draft) => {
                draft.pagination.totalPages =
                  totalRows / this.state.pagination.take;
              })
            );
          }
        }
      } else {
        this.setState(
          produce((draft) => {
            draft.pagination.totalPages = 1;
          })
        );
      }
    }
  };
  setPaginationUI = () => {
    if (Number(this.state.pagination.totalPages) === 1) {
      this.setState(
        produce((draft) => {
          draft.pagination.blur.blurFirst = true;
          draft.pagination.blur.blurPrev = true;
          draft.pagination.blur.blurNext = true;
          draft.pagination.blur.blurLast = true;
        })
      );
    } else if (
      this.state.pagination.page === 1 &&
      this.state.pagination.totalPages > 1
    ) {
      this.setState(
        produce((draft) => {
          draft.pagination.blur.blurFirst = true;
          draft.pagination.blur.blurPrev = true;
          draft.pagination.blur.blurNext = false;
          draft.pagination.blur.blurLast = false;
        })
      );
    } else if (
      this.state.pagination.page !== 1 &&
      this.state.pagination.totalPages > 1 &&
      this.state.pagination.page < this.state.pagination.totalPages
    ) {
      this.setState(
        produce((draft) => {
          draft.pagination.blur.blurFirst = false;
          draft.pagination.blur.blurPrev = false;
          draft.pagination.blur.blurNext = false;
          draft.pagination.blur.blurLast = false;
        })
      );
    } else if (
      this.state.pagination.page !== 1 &&
      this.state.pagination.totalPages > 1 &&
      this.state.pagination.page === this.state.pagination.totalPages
    ) {
      this.setState(
        produce((draft) => {
          draft.pagination.blur.blurFirst = false;
          draft.pagination.blur.blurPrev = false;
          draft.pagination.blur.blurNext = true;
          draft.pagination.blur.blurLast = true;
        })
      );
    }
  };

  changePagination = (data) => {
    let search = JSON.parse(localStorage.getItem("search"));
    if (data === "first") {
      this.setState(
        produce((draft) => {
          draft.pagination.skip = 0;
          draft.pagination.page = 1;
        })
      );
      search.pagination.skip = 0;
    } else if (data === "prev") {
      let dataToSkip = this.state.pagination.skip - this.state.pagination.take;
      this.setState(
        produce((draft) => {
          draft.pagination.skip = dataToSkip;
          draft.pagination.page -= 1;
        })
      );
      search.pagination.skip = dataToSkip;
    } else if (data === "next") {
      let dataToSkip = this.state.pagination.skip + this.state.pagination.take;
      this.setState(
        produce((draft) => {
          draft.pagination.skip = dataToSkip;
          draft.pagination.page += 1;
        })
      );
      search.pagination.skip = dataToSkip;
    } else if (data === "last") {
      let dataToSkip =
        (this.state.pagination.totalPages - 1) * this.state.pagination.take;
      this.setState(
        produce((draft) => {
          draft.pagination.skip = dataToSkip;
          draft.pagination.page = this.state.pagination.totalPages;
        })
      );
      search.pagination.skip = dataToSkip;
    }

    localStorage.setItem("search", JSON.stringify(search));
    setTimeout(() => {
      this.fetchAllLanders();
    }, 50);
    this.setPaginationUI();
  };

  changeGridRowsNumber = (e) => {
    let search = JSON.parse(localStorage.getItem("search"));
    search.pagination.take = Number(e.target.value);
    search.pagination.skip = 0;
    this.setState(
      produce((draft) => {
        draft.pagination.skip = 0;
        draft.pagination.page = 1;
        draft.pagination.take = Number(e.target.value);
      })
    );
    localStorage.setItem("search", JSON.stringify(search));
    setTimeout(() => {
      this.fetchAllLanders();
    }, 50);
    this.setPaginationUI();
  };

  changeFilterStatusType = (e) => {
    let search = JSON.parse(localStorage.getItem("search"));
    search.filterStatus = e.target.value;
    this.setState(
      produce((draft) => {
        draft.filterStatus = e.target.value;
      })
    );
    localStorage.setItem("search", JSON.stringify(search));
    setTimeout(() => {
      this.fetchAllLanders();
    }, 50);
  };

  toggleLoader = () => {
    this.setState(
      produce((draft) => {
        draft.loader = !this.state.loader;
      })
    );
  };

  openNewReport = (data) => {
    let search = JSON.parse(localStorage.getItem("search"));
    if (data === "minus" && this.state.reportCount > 0) {
      search.report.reportCount = this.state.reportCount - 1;
      this.setState(
        produce((draft) => {
          draft.reportCount = this.state.reportCount - 1;
        })
      );
      localStorage.setItem("search", JSON.stringify(search));
    } else if (data === "plus") {
      let count = this.state.reportCount + 1;
      search.report["report" + count].name =
        this.state.selectedListReceivedFromGrid[0].name;
      search.report.reportCount = count;
      search.activeTab = count;
      this.setState(
        produce((draft) => {
          draft.reportCount = this.state.reportCount + 1;
        })
      );
      let startDate = search.modifiedDate.startDate;
      let endDate = search.modifiedDate.endDate;
      localStorage.setItem("search", JSON.stringify(search));
      setTimeout(() => {
        this.props.history.push({
          pathname: "/report",
          search:
            "id=" +
            this.state.selectedListReceivedFromGrid[0].id +
            "&reportType=" +
            search.groupingValues.main +
            "&startDate=" +
            startDate +
            "&endDate=" +
            endDate +
            "&new=true",
          hash: "",
        });
      }, 80);
    }
  };

  decreaseReportCount = () => {
    this.setState(
      produce((draft) => {
        draft.reportCount = Number(this.state.reportCount) - 1;
      })
    );
  };

  render() {
    return (
      <Fragment>
        <Layout
          reportCount={this.state.reportCount}
          decreaseReportCount={this.decreaseReportCount}
        >
          <div id="page_container" className="page_container">
            <div id="page_menu" className="page_menu_bar">
              <PageMenuBarTop
                sendSearchRequest={this.sendSearchRequest}
                showCharts={this.showCharts}
                toggleLoader={this.toggleLoader}
                showCalendar={this.state.showCalendar}
                toggleCalendarWindow={this.toggleCalendarWindow}
                pageType="Lander"
              />
              {this.state.charts.showCharts ? (
                <ResponsiveMenuCharts
                  data={this.state.charts.chartsData}
                  dates={
                    JSON.parse(localStorage.getItem("search")).modifiedDate
                  }
                />
              ) : null}
              <PageMenuBarBottom
                toggleLanderDialogue={this.toggleLanderDialogue}
                loadedWorkspaces={this.state.loadedWorkspaces}
                selectedListReceivedFromGrid={
                  this.state.selectedListReceivedFromGrid
                }
                // new code
                toggleDeleteArchiveModal={this.toggleDeleteArchiveModal}
                openEditLanderDialog={this.openEditLanderDialog}
                moveToWorkspaceMethod={this.moveToWorkspaceMethod}
                toggleColumnsModal={this.toggleColumnsModal}
                pagination={this.state.pagination}
                changePagination={this.changePagination}
                changeGridRowsNumber={this.changeGridRowsNumber}
                changeFilterStatusType={this.changeFilterStatusType}
                filterStatus={this.state.filterStatus}
                reportId={
                  this.state.selectedListReceivedFromGrid.length > 0
                    ? this.state.selectedListReceivedFromGrid[0].id
                    : ""
                }
                disable={this.state.disableReport}
                openNewReport={this.openNewReport}
                reportCount={this.state.reportCount}
              />
              <div className="page_content"></div>
              <div className="page_modal">
                {this.state.visibleAddLanderDialogue && (
                  <AddLanderModal
                    state={this.state}
                    toggleLanderDialogue={this.toggleLanderDialogue}
                    toggleLoader={this.toggleLoader}
                    closeAddLanderDialogueAfterSubmission={
                      this.closeAddLanderDialogueAfterSubmission
                    }
                  />
                )}
                {this.state.loader && <LoadingPanel />}
              </div>
            </div>

            <div
              // style={{ height: this.state.pageContentHeight }}
              style={{ height: `calc(100% - 85px - 127px)` }}
              className="temporary_affiliate_network_page_data"
            >
              <GridClassComponent
                pageType="Lander"
                gridHeight={this.state.pageContentHeight}
                allDataLoaded={this.state.allLoadedLandersData}
                paginationSize={this.state.paginationSize}
                gridCheckedList={(list) => this.gridCheckedList(list)}
                groupingValues={this.state.groupingValues}
                showColumnsModal={this.state.showColumnsModal}
                toggleColumnsModal={this.toggleColumnsModal}
                sorting={this.state.sorting}
                changeSorting={this.changeSorting}
                toggleLoader={this.toggleLoader}
                selectedListFromPage={this.state.selectedListReceivedFromGrid}
                showNoRecordFound={this.state.showNoRecordFound}
              />
            </div>

            {this.state.visibleEditLanderDialogue && (
              <EditLanderModal
                state={this.state}
                toggleLanderDialogue={this.toggleLanderDialogue}
                closeEditLanderDialogueAfterSubmission={
                  this.closeEditLanderDialogueAfterSubmission
                }
                editLanderId={this.state.selectedListReceivedFromGrid[0].id}
                toggleLoader={this.toggleLoader}
              />
            )}
            {this.state.visibleDeleteLanderDialogue && (
              <DeletePageModal
                state={this.state}
                toggleLanderDialogue={this.toggleLanderDialogue}
                deleteLanderOnSubmit={this.deleteLanderOnSubmit}
                archiveLanderHandler={this.archiveLanderHandler}
                toggleDeleteArchiveModal={this.toggleDeleteArchiveModal}
              />
            )}
            {this.state.backendErrors.error && (
              <NotificationGroup
                style={{
                  alignItems: "flex-start",
                  flexWrap: "wrap-reverse",
                  left: "50%",
                  zIndex: 10000000,
                  top: "20px",
                  transform: "translateX(-50%)",
                }}
              >
                <Fade enter={true} exit={true}>
                  {this.state.backendErrors.error && (
                    <Notification
                      type={{ style: "error", icon: true }}
                      closable={true}
                      // onClose={() => this.setState({ backendErrors.error: false })}
                      onClose={() =>
                        this.setState(
                          produce((draft) => {
                            draft.backendErrors.error = false;
                          })
                        )
                      }
                    >
                      <span>{this.state.backendErrors.errorMsg}</span>
                    </Notification>
                  )}
                </Fade>
              </NotificationGroup>
            )}
            {this.state.archiveDeleteResponseLanders && (
              <NotificationGroup
                style={{
                  alignItems: "flex-start",
                  flexWrap: "wrap-reverse",
                  left: "50%",
                  zIndex: 10000000,
                  top: "20px",
                  transform: "translateX(-50%)",
                }}
              >
                <Fade enter={true} exit={true}>
                  {this.state.archiveDeleteResponseLanders && (
                    <Notification
                      type={{ style: "warning", icon: true }}
                      closable={true}
                      // onClose={() => this.setState({ backendErrors.error: false })}
                      onClose={() =>
                        this.setState(
                          produce((draft) => {
                            draft.archiveDeleteResponseLanders = null;
                          })
                        )
                      }
                    >
                      <span>{this.state.archiveDeleteResponseLanders}</span>
                    </Notification>
                  )}
                </Fade>
              </NotificationGroup>
            )}
          </div>
        </Layout>
      </Fragment>
    );
  }
}

export const mapStateToProps = createStructuredSelector({
  isNewLanderDataSent: makeSelectIsNewLanderDataSent(),
  // isAllLandersDataLoaded: makeSelectIsAllLandersLoaded(),
  isLanderDeleted: makeSelectIsDeleteLanderDataSent(),
  accessToken: makeSelectToken(),
  domainsAndWorkspacesListsReceived: makeSelectDomainsDataReceived(),
  allLandersDataLoaded: makeSelectAllLandersDataLoaded(),
  isLanderPatched: makeSelectIsPatchedLanderDataSent(),
  isLanderArchived: makeSelectIsArchiveLanderDataSent(),
  isChartsDataLoaded: makeSelectIsChartsDataLoaded(),
  chartsAllDataLoaded: makeSelectAllChartsDataLoaded(),
  isMovedToWorkspace: makeSelectMoveToWorkspace(),
  archiveDeleteResponseLanders: makeSelectArchiveDeleteResponseLanders(),
  Error: makeSelectError(),
});
export function mapDispatchToProps(dispatch) {
  return {
    dispatchLoadDomainsAndWorkspaces: (...payload) =>
      dispatch(loadCustomDomains(...payload)),
    dispatchPostLander: (...payload) => dispatch(postLander(...payload)),
    dispatchLoadAllLanders: (...payload) =>
      dispatch(loadAllLanders(...payload)),
    dispatchDeleteLander: (...payload) => dispatch(deleteLander(...payload)),
    dispatchArchiveLander: (...payload) => dispatch(archiveLander(...payload)),
    editLanderDialogueClosed: () => dispatch(editLanderDialogueClosed()),
    dispatchLoadChartsData: (...payload) =>
      dispatch(loadLanderChartsData(...payload)),
    dispatchMoveToWorkspace: (...payload) =>
      dispatch(moveToWorkspace(...payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Landers);
function ResponsiveMenuCharts(props) {
  const [dimensions, setDimensions] = React.useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  })
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    
}

    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)
    
}
  })
  return <>{//<div>{window.innerWidth}</div>
  }
  <MenuCharts data={props.data} dates={props.dates}/>
  </>

}