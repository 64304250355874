import axios from "axios";
import { checkFilters } from "../../../components/checkNamesForGrouping/checkNames";
import { returnTimeZoneId } from "../../../components/timeZonesExchange";
import { BASE_URL } from "../../helpers/path";

export const LoadAllReportFromAPI = async (request) => {
    axios.interceptors.request.use(req => {
        // `req` is the Axios request config, so you can modify
        // the `headers`.
        req.headers.authorization = request.payload.accessToken;
        return req;
    });

    
    let filtersToSend = checkFilters(request.payload.searchQuery.reportGroupingValues.filter2,request.payload.searchQuery.reportGroupingValues.filter3,request.payload.searchQuery.reportGroupingValues.filter4);

    let changeFilter1 = checkFilters(request.payload.searchQuery.reportGroupingValues.filter1, "", "");

    let timeZoneInLocalStorage = request.payload.searchQuery.date.timeZone;
    let timeZoneIdToSend = returnTimeZoneId(timeZoneInLocalStorage);

    let search = JSON.parse(localStorage.getItem("search"));
    
    let parameters = "/?id=" + request.payload.searchQuery.reportGroupingValues.id + "&filter1=" + changeFilter1[0] + "&filter="+ request.payload.searchQuery.inputSearchValue + "&from="+ request.payload.searchQuery.modifiedDate.startDate +"&to="+ request.payload.searchQuery.modifiedDate.endDate +"&tags="+request.payload.searchQuery.tagsSearchValue+"&filter2="+filtersToSend[0] + "&filter2Id=&filter3="+ filtersToSend[1] + "&filter3id=&filter4="+ filtersToSend[2] + "&order=" + request.payload.sorting.order + "&sort=" + request.payload.sorting.sort + "&skip=" + request.payload.pagination.skip + "&take=" + request.payload.pagination.take + "&workspace=" + search.selectedWorkspacesList + "&timezone=" + timeZoneIdToSend;
    const response = await axios.get(BASE_URL + 'report' + parameters);
    return response;
};

// Get the sub level data of grouping for the report
export const LoadSubLevelReportDataFromAPI = async (request) => {
    axios.interceptors.request.use(req => {
        // `req` is the Axios request config, so you can modify
        // the `headers`.
        req.headers.authorization = request.payload.accessToken;
        return req;
    });
    const response = await axios.get(BASE_URL + 'report/grouping' + request.payload.parameters);
    return response;
};

// get charts data for report
export const LoadReportChartsDataFromAPI = async (request) => {
    axios.interceptors.request.use(req => {
        req.headers.authorization = request.payload.accessToken;
        return req;
    });

    let changeFilter1 = checkFilters(request.payload.groupingValues.filter1, request.payload.groupingValues.filter2, "");

    let search = JSON.parse(localStorage.getItem("search"));
    let timeZoneInLocalStorage = search.dates.timeZone;
    let timeZoneIdToSend = returnTimeZoneId(timeZoneInLocalStorage);


    let parameters = "id=" + request.payload.groupingValues.id + "&filter1=" + changeFilter1[0] + "&filter2=" + changeFilter1[1] + "&to=" + request.payload.dates.endDate + "&from=" + request.payload.dates.startDate + "&workspace=" + search.selectedWorkspacesList  + "&timezone=" + timeZoneIdToSend;

    //let parameters = "to=" + request.payload.dates.endDate + "&from=" + request.payload.dates.startDate;
    let url = BASE_URL + 'report/ch/chart?' + parameters;
    const response = await axios.get(url);
    return response;
};