import React, { Fragment } from 'react';
import { Link} from 'react-router-dom';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import EmailInput from "../../../components/authentication/emailInput/emailInput";
import { connect} from 'react-redux';
import PasswordInput from "../../../components/authentication/passwordInput/passwordInput";
//import GoogleLogin from "react-google-login";
import * as loginRegisterActions from '../actions'
import { createStructuredSelector } from 'reselect';
import {withRouter} from "react-router-dom";
import auth from "../../../auth";
import {
    makeSelectUser,
    makeSelectError,
    makeSelectLoading,
    makeSelectSuccess,
    makeSelectToken,
    makeSelectLoggedIn,
    makeSelectUserId,
    makeSelectUserCurrency,
    makeSelectTimeZoneId,
    makeSelectHomeScreen,
    makeSelectWorkspacesList,
    makeSelectFilterStatus
} from './../selectors';
import { emailValidator, passwordValidator } from '../../../validator/validator';
import "./login.css";
import { Fade } from '@progress/kendo-react-animation';
import { useEffect } from 'react';
import LoadingPanel from '../../../containers/grid/loader/loader';
import moment from "moment-timezone";
import { returnTimeZoneForCalendar } from "../../../components/timeZonesExchange";
import Navbar from "../../../components/header/navbar/index";
import { Helmet } from "react-helmet"

const Login = (props) => {    
      
    const handleSubmit = (event) => { 
        const email = event.email;
        const password = event.password;
        props.loginUser(email, password);
    }

    useEffect(() => {
        if (props.isLoggedIn) {
            // set the user values in the localStorage
            let user = {
                user: 'loginTrue',
                userName: props.user,
                userId: props.userId,
                isAuthenticated: props.isLoggedIn,
            };
            localStorage.setItem('user', JSON.stringify(user));

            // set the accessToken in the localStorage
            localStorage.setItem('accessToken', JSON.stringify(props.token));

            auth.login(() => {
                // props.history.push("/?clientId=" + Cookies.get("userId") + "/settings/");
                if (props.home_screen !== null) {
                    let search = JSON.parse(localStorage.getItem("search"));
                    search.setting.home_screen = props.home_screen.toLowerCase();
                    search.setting.timeZoneId = props.timeZoneId;
                    search.dates.timeZone = returnTimeZoneForCalendar(props.timeZoneId);
                    search.report.report1.search.date.timeZone = returnTimeZoneForCalendar(props.timeZoneId);
                    search.report.report2.search.date.timeZone = returnTimeZoneForCalendar(props.timeZoneId);
                    search.report.report3.search.date.timeZone = returnTimeZoneForCalendar(props.timeZoneId);
                    let startDate = moment().startOf('day');
                    let endDate = moment().endOf("day");
                    search.dates.startDate = startDate;
                    search.dates.endDate = endDate;
                    search.modifiedDate.startDate = startDate.format("MM/DD/YYYY hh:mm A");
                    search.modifiedDate.endDate = endDate.format("MM/DD/YYYY hh:mm A");
                    search.selectedWorkspacesList = props.workspaces.map(workspace => workspace.id);
                    search.filterStatus = props.filterStatus;
                    localStorage.setItem("search",JSON.stringify(search));
                    props.history.push("/" + props.home_screen.toLowerCase() + "/");
                }
            });
        } else { }
    }, [props.isLoggedIn]);

  // const responseGoogle = (response) => {
  //   console.log(response.profileObj);
  // };

  return (
      <Fragment>
        {
             <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"></meta>
            </Helmet>    
                }

        <div className="page_container" >
            <div className="hide-buttons"><Navbar/></div>
            <div className="form_page_logo_container">

            </div>
            <div className="container">
                {(props.isLoggedIn || props.loading) && <LoadingPanel/>}
                <Form
                    className="mb-6"
                    onSubmit={handleSubmit}
                    render={(formRenderProps) => (
                        <FormElement className={"mb-6 form_element"} style={{ maxWidth: "500px",background:"#18181b",border:"0px",padding:"40px 30px 30px"}}>
                            {props.error !== false && 
                            <Fade enter={true} exit={true}>
                                <p className="danger_para mb-10 mh-0"><span className="k-icon k-i-warning"/>&nbsp;&nbsp;{props.error && props.error.data ? props.error.data.message : null}</p>                              
                            </Fade>
                            }
                            <fieldset className={"k-form-fieldset mb-6"}>
                            
                              <h2 className="login_h2" style={{color:"hsla(0,0%,100%,.85)",marginTop:"30px",borderBottomColor:"hsla(0,0%,100%,.85)"}} >Welcome, please Log in!</h2>
                              <div className="email">
                                <Field
                                    
                                    name={"email"}
                                    type={"email"}
                                    id="email"
                                    label={"Email"}
                                    component={EmailInput}
                                    validator={emailValidator}
                                    required={true}
                                />
                              </div>
                              <div className="mb-6" style={{ marginBottom: 20 }}>
                                <Field
                                    name="password"
                                    type="password"
                                    label="Password"
                                    id="password"
                                    required={true}
                                    minLength={6}
                                    maxLength={18}
                                    validator={passwordValidator}
                                    component={PasswordInput}
                                />
                              </div>
                            </fieldset>
                            <Link to="/login/resetPassword" className="login_links" style={{ margin: 20 ,color:"#e0358b"}}>
                              Forgot your password?
                            </Link>
                            <div className="mb-6" style={{ margin: 20 }}>
                              <Button
                                type={"submit"}
                                className="signinbutton"
                                disabled={!formRenderProps.allowSubmit}
                              >
                                LOG IN
                              </Button>

                            </div>
                            {/*<div className="separator"><span>OR</span></div>*/}
                            {/*<div className="mb-6">*/}
                            {/*  <GoogleLogin*/}
                            {/*    className="googleLogin"*/}
                            {/*    id="googleId"*/}
                            {/*    clientId="1054264414223-iia0k7autqc36tj7ej3fae3dde5udn7o.apps.googleusercontent.com"*/}
                            {/*    buttonText="Sign in with Google"*/}
                            {/*    onSuccess={responseGoogle}*/}
                            {/*    onFailure={responseGoogle}*/}
                            {/*    cookiePolicy={"single_host_origin"}*/}
                            {/*  />*/}
                            {/*</div>*/}
                            <div className="mb-6" style={{ margin: 20,color:"hsla(0,0%,100%,.85)"}}> Don't have an account?
                                <Link className="register login_links" to="/signup" style={{ margin: 4, color:"#e0358b"}}>Sign up here.</Link>
                            </div>
                        </FormElement>
                    )}
                />
            </div>

        </div>
            <div className="homepage">
                <footer id="contact-us">

                        <div className="copy"><div>Copyright © 2023 Tellmobi. All rights reserved.</div><a href="https://nmfmedia.com/" target="_blank"> Part of NMF Media Ltd.</a></div>
                        

                </footer>
            </div>
      </Fragment>
  );
};

export const mapStateToProps = createStructuredSelector({
    error: makeSelectError(),
    loading: makeSelectLoading(),
    user: makeSelectUser(),
    userId: makeSelectUserId(),
    success: makeSelectSuccess(),
    token: makeSelectToken(),
    isLoggedIn: makeSelectLoggedIn(),
    userCurrency: makeSelectUserCurrency(),
    home_screen: makeSelectHomeScreen(),
    timeZoneId: makeSelectTimeZoneId(),
    workspaces: makeSelectWorkspacesList(),
    filterStatus: makeSelectFilterStatus(),
});
export function mapDispatchToProps(dispatch) {
    return {
        loginUser: (...payload) => dispatch(loginRegisterActions.loginUser(...payload)),
        resetUser: () => dispatch(loginRegisterActions.resetUser())
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
