import * as React from "react";
import "./grid.css";
import produce from "immer";
import moment from "moment";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import GridColumnsModal from "./GridColumnsToShowModal/index";



const screenWidth = window.innerWidth
class GridClassComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.nameColRef = React.createRef();
  }
  // pageSizesList = [50, 100, 200,500, 1000];
  state = {
    skip: 0,
    take: 50,
    sort: [{ field: "id", dir: "asc" }],
    allDataLoaded: [],
    data: [],
    selectedRowIds: [],
    group: [],
    allChecked: false,
    groupingDataToLoad: {
      mainId: null,
      group1Id: null,
    },
    pageMounted: false,
    columnsData: [
      "clickid",
      "postbacktimestamp",
      "visittimestamp",
      "profit",
      "revenue",
      "revenueinoriginalcurrency",
      "cost",
      "city",
      "region",
      "country",
      "currency",
      "campaign_name",
      "campaignid",
      "offerid",
      "affiliatenetworkid",
      "visitorip",
      "campaign_name",
      "offers_name",
      "affiliate_network_name"
    ],
    allColumnsChecked: false,
    allColumns: [
      { name: "Click Id", is_active: true },
      { name: "Postback Timestamp", is_active: true },
      { name: "Visit Timestamp", is_active: true },
      { name: "Transaction Id", is_active: false },
      { name: "Profit", is_active: true },
      { name: "Revenue", is_active: true },
      { name: "Revenue in Original Currency", is_active: true },
      { name: "Cost", is_active: true },
      { name: "Region", is_active: true },
      { name: "Country", is_active: true },
      { name: "Outgoing Postbackurl", is_active: false },
      { name: "Offers_Name", is_active: true },
      { name: "Campaign_Name", is_active: true },
      { name: "Affiliate_Network_Name", is_active: true },
      { name: "External ID", is_active: false },
      { name: "Campaign ID", is_active: true },
      { name: "Offer ID", is_active: true },
      { name: "Affiliate Network ID", is_active: true },
      { name: "Visitor IP", is_active: true },
      { name: "Browser", is_active: false },
      { name: "City", is_active: true },
      { name: "Lander ID", is_active: false },
      { name: "Lander Name", is_active: false },
      { name: "Proxy", is_active: false },
    ],
    selectedRowIndex: -1,
    totalcost: 0,
    totalrevenue: 0,
    totalprofit: 0,
  };

  columnsCheckBoxChangeHandler = (e) => {
    for (let i = 0; i < this.state.allColumns.length; i++) {
      if (
        this.state.allColumns[i].name.toLowerCase().replaceAll(" ", "") ===
        e.target.name.toLowerCase().replaceAll(" ", "")
      ) {
        this.setState(
          produce((draft) => {
            draft.allColumns[i].is_active = !this.state.allColumns[i].is_active;
          })
        );
      }
    }
    // setTimeout(() => { this.columnsCheckSelectAllHandler(); },30);
  };

  columnsSelectAllHandler = (e) => {
    this.setState(
      produce((draft) => {
        draft.allColumnsChecked = !this.state.allColumnsChecked;
      })
    );
    for (let i = 0; i < this.state.allColumns.length; i++) {
      this.setState(
        produce((draft) => {
          draft.allColumns[i].is_active = !this.state.allColumnsChecked;
        })
      );
    }
  };

  saveColumnsHandler = () => {
    const items = this.state.allColumns;
    const newColumns = items.map((item) => {
      let name = "";
      if (item?.is_active) {
        name = item?.name;
      }
      return name.toLowerCase().replaceAll(" ", "");
    });
    this.setState({ columnsData: newColumns });
    this.setState(
      produce((draft) => {
        draft.allColumnsChecked = false;
      })
    );
    this.props.toggleColumnsModal();
  };

  rowClick = (index) => {
    if (index === this.state.selectedRowIndex) {
      this.setState({ selectedRowIndex: -1 });
    } else {
      this.setState({ selectedRowIndex: index });
    }
  };

  componentDidMount() {
    this.setState(
      produce((draft) => {
        draft.pageMounted = true;
      })
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props?.allDataLoaded?.length) {
      this.setState({
        selectedRowIndex: -1,
      });
    }
    if (this.props?.allDataLoaded) {
      let totalrevenue = 0;
      let totalcost = 0;
      let totalprofit = 0;
      this.props.allDataLoaded.forEach((item) => {
        totalprofit = Number(totalprofit) + Number(item.profit);
        totalcost = Number(totalcost) + Number(item.cost);
        totalrevenue = Number(totalrevenue) + Number(item.revenue);
      });
      this.setState({
        allDataLoaded: this.props.allDataLoaded,
        totalcost: totalcost,
        totalrevenue: totalrevenue,
        totalprofit: totalprofit,
      });
      console.log(totalrevenue, totalprofit);
    }
  }

  render() {
    return (
      <>
        <div id="table-scroll" className="table-scroll">
          <table
            id="grid"
            className="grid"
            // style={{ height: ((Number(this.props.gridHeight) - 86) + "px") }}
            style={{ height: "99%" }}
          >
            <tbody style={{ height: "inherit" }}>
              <tr className="table_header_row">
                {this.state.columnsData.includes("visittimestamp") && (
                  <th style={{ width: "200px" }} className="resizingDiv">
                    <div
                      style={{ width: "inherit", textAlign: "left" }}
                      onClick={() => this.props.changeSorting("create_at")}
                    >
                      Visit Timestamp
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "create_at" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "create_at" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                    </div>
                  </th>
                )}
                {this.state.columnsData.includes("postbacktimestamp") && (
                  <th style={{ width: "200px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit", textAlign: "left" }}
                      onClick={() =>
                        this.props.changeSorting("postback_created")
                      }
                    >
                      Postback Timestamp
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "postback_created" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "postback_created" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                    </div>
                  </th>
                )}

                {this.state.columnsData.includes("campaign_name") && (
                  <th style={{ width: "250px", padding: "0px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit", textAlign: "left" }}
                      onClick={() => this.props.changeSorting("campaign_name")}
                    >
                      Campaign
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "campaign_name" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "campaign_name" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                    </div>
                  </th>
                )}

                {this.state.columnsData.includes("offers_name") && (
                  <th style={{ width: "250px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit", textAlign: "left" }}
                      onClick={() => this.props.changeSorting("offers_name")}
                    >
                      Offer
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "offers_name" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "offers_name" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                    </div>
                  </th>
                )}
                {this.state.columnsData.includes("affiliate_network_name") && (
                  <th style={{ width: "250px", padding: "0px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit", textAlign: "left" }}
                      onClick={() =>
                        this.props.changeSorting("affiliate_network_name")
                      }
                    >
                      Affiliate Network
                      <div className="sort_icon_con">
                        {this.props.sorting.order ===
                        "affiliate_network_name" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order ===
                          "affiliate_network_name" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                    </div>
                  </th>
                )}
                {this.state.columnsData.includes("clickid") && (
                  <th style={{ width: "100px", padding: "0px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit", textAlign: "left" }}
                    >
                      Click ID
                    </div>
                  </th>
                )}
                {this.state.columnsData.includes("profit") && (
                  <th style={{ width: "100px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit", textAlign: "left" }}
                      onClick={() => this.props.changeSorting("profit")}
                    >
                      Profit
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "profit" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "profit" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                    </div>
                  </th>
                )}
                {this.state.columnsData.includes("revenue") && (
                  <th style={{ width: "100px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit", textAlign: "left" }}
                      onClick={() => this.props.changeSorting("revenue")}
                    >
                      Revenue
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "revenue" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "revenue" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                    </div>
                  </th>
                )}
                {this.state.columnsData.includes(
                  "revenueinoriginalcurrency"
                ) && (
                  <th style={{ width: "200px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit", textAlign: "left" }}
                      onClick={() => this.props.changeSorting("revenue")}
                    >
                      Revenue in original currency
                      <div className="sort_icon_con">
                        {this.props.sorting.order ===
                        "revenueinoriginalcurrency" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order ===
                          "revenueinoriginalcurrency" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                    </div>
                  </th>
                )}
                {this.state.columnsData.includes("currency") && (
                  <th style={{ width: "100px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit", textAlign: "left" }}
                      onClick={() => this.props.changeSorting("")}
                    >
                      Currency
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                    </div>
                  </th>
                )}
                {this.state.columnsData.includes("cost") && (
                  <th style={{ width: "100px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit", textAlign: "left" }}
                      onClick={() => this.props.changeSorting("")}
                    >
                      Cost
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                    </div>
                  </th>
                )}
                      {this.state.columnsData.includes("city") && (
                        <th style={{ width: "200px" }}className="resizingDiv">
                          <div
                            
                            style={{ width: "inherit", textAlign: "left" }}
                            onClick={() => this.props.changeSorting("city")}
                          >
                            City
                            <div className="sort_icon_con">
                              {this.props.sorting.order === "" ? (
                                <span
                                  className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                                    this.props.sorting.sort === "ASC" ? "down" : "up"
                                  }`}
                                ></span>
                              ) : null}
                              {this.props.sorting.order === "" &&
                              this.props.sorting.sort === "ASC" ? (
                                <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                              ) : (
                                <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                              )}
                            </div>
                          </div>
                        </th>
                      )}
                {this.state.columnsData.includes("region") && (
                  <th style={{ width: "200px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit", textAlign: "left" }}
                      onClick={() => this.props.changeSorting("region")}
                    >
                      Region
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                    </div>
                  </th>
                )}
                    {this.state.columnsData.includes("country") && (
                      <th style={{ width: "200px" }}className="resizingDiv">
                        <div
                          
                          style={{ width: "inherit", textAlign: "left" }}
                          onClick={() => this.props.changeSorting("country")}
                        >
                          Country
                          <div className="sort_icon_con">
                            {this.props.sorting.order === "" ? (
                              <span
                                className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                                  this.props.sorting.sort === "ASC" ? "down" : "up"
                                }`}
                              ></span>
                            ) : null}
                            {this.props.sorting.order === "" &&
                            this.props.sorting.sort === "ASC" ? (
                              <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                            ) : (
                              <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                            )}
                          </div>
                        </div>
                      </th>
                    )}
                {this.state.columnsData.includes("transactionid") && (
                  <th style={{ width: "200px", textAlign: "left" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit", textAlign: "left" }}
                      onClick={() => this.props.changeSorting("region")}
                    >
                      Transaction ID
                    </div>
                  </th>
                )}
                {this.state.columnsData.includes("outgoingpostbackurl") && (
                  <th style={{ width: "200px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit", textAlign: "left" }}
                      onClick={() => this.props.changeSorting("")}
                    >
                      Outgoing Postback Url
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                    </div>
                  </th>
                )}
                {this.state.columnsData.includes("externalid") && (
                  <th style={{ width: "200px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit", textAlign: "left" }}
                      onClick={() => this.props.changeSorting("")}
                    >
                      External ID
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                    </div>
                  </th>
                )}
                {this.state.columnsData.includes("campaignid") && (
                  <th style={{ width: "200px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit", textAlign: "left" }}
                      onClick={() => this.props.changeSorting("campaignId")}
                    >
                      Campaign ID
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                    </div>
                  </th>
                )}
                {this.state.columnsData.includes("offerid") && (
                  <th style={{ width: "100" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit", textAlign: "left" }}
                      onClick={() => this.props.changeSorting("offersId")}
                    >
                      Offer ID
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                    </div>
                  </th>
                )}
                {this.state.columnsData.includes("affiliatenetworkid") && (
                  <th style={{ width: "100" ,whiteSpace:"pre-wrap" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit", textAlign: "left" }}
                      onClick={() =>
                        this.props.changeSorting("affilateNetworkId")
                      }
                    >
                      Affiliate Network ID
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                    </div>
                  </th>
                )}
                {this.state.columnsData.includes("visitorip") && (
                  <th style={{ width: "200px" }}className="resizingDiv">
                    <div
                      
                      style={{ width: "inherit", textAlign: "left" }}
                      onClick={() => this.props.changeSorting("ipAddress")}
                    >
                      IP Address
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                    </div>
                  </th>
                )}
                {this.state.columnsData.includes("browser") && (
                  <th style={{ minWidth: "200px" }}className="resizingDiv">
                    <div
                      
                      style={{ minWidth: "100px", textAlign: "left" }}
                      onClick={() => this.props.changeSorting("browserName")}
                    >
                      Browser
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                    </div>
                  </th>
                )}
                {this.state.columnsData.includes("landerid") && (
                  <th style={{ minWidth: "200px" }}className="resizingDiv">
                    <div
                      
                      style={{ minWidth: "100px", textAlign: "left" }}
                      onClick={() => this.props.changeSorting("landerId")}
                    >
                      Lander ID
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                    </div>
                  </th>
                )}
                {this.state.columnsData.includes("landerid") && (
                  <th style={{ minWidth: "200px" }}className="resizingDiv">
                    <div
                      
                      style={{ minWidth: "100px", textAlign: "left" }}
                      onClick={() => this.props.changeSorting("lander_name")}
                    >
                      Lander Name
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                    </div>
                  </th>
                )}
                {this.state.columnsData.includes("proxy") && (
                  <th style={{ minWidth: "200px" }}className="resizingDiv">
                    <div
                      
                      style={{ minWidth: "100px", textAlign: "left" }}
                      onClick={() => this.props.changeSorting("proxy")}
                    >
                      Proxy
                      <div className="sort_icon_con">
                        {this.props.sorting.order === "" ? (
                          <span
                            className={`sorting_icon_fixed k-icon k-i-arrow-chevron-${
                              this.props.sorting.sort === "ASC" ? "down" : "up"
                            }`}
                          ></span>
                        ) : null}
                        {this.props.sorting.order === "" &&
                        this.props.sorting.sort === "ASC" ? (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-up"></span>
                        ) : (
                          <span className="sorting_icon_hover k-icon k-i-arrow-chevron-down"></span>
                        )}
                      </div>
                    </div>
                  </th>
                )}
              </tr>
              {this.state?.allDataLoaded?.map((e, i) => (
                <tr
                  id={e.id}
                  key={e.id}
                  onClick={() => this.rowClick(i)}
                  style={{
                    color: i === this.state.selectedRowIndex ? "#fff" : "#000",
                    backgroundColor:
                      i === this.state.selectedRowIndex ? "#2e4887" : null,
                  }}
                >
                  {this.state.columnsData.includes("postbacktimestamp") && (
                    <td>
                      {e?.postbackcreated?.split("T")[0]
                        ? `${e?.postbackcreated?.split("T")[0]} ${moment(
                            e?.postbackcreated
                          ).format("hh:mm:ss A")}`
                        : "UNKNOWN"}
                    </td>
                  )}
                  {this.state.columnsData.includes("visittimestamp") && (
                    <td>
                      {e?.postbackvisit?.split("T")[0]
                        ? `${e?.postbackvisit?.split("T")[0]} ${moment(
                            e?.postbackvisit
                          ).format("hh:mm:ss A")}`
                        : "UNKNOWN"}
                    </td>
                  )}
                  {this.state.columnsData.includes("campaign_name") && (
                    <td style={{ textAlign: "left", maxWidth:"200px", textOverflow:"ellipsis", whiteSpace:"nowrap", overflow:"hidden" }}>{e?.campaign_name ?? "UNKNOWN"}</td>
                  )}
                  {this.state.columnsData.includes("offers_name") && (
                    <td style={{ textAlign: "left", maxWidth:"200px", textOverflow:"ellipsis", whiteSpace:"nowrap", overflow:"hidden" }}> {e?.offers_name ?? "UNKNOWN"}</td>
                  )}
                  {this.state.columnsData.includes(
                    "affiliate_network_name"
                  ) && <td style={{textAlign: "left"}}>{e?.affiliate_network_name ?? "UNKNOWN"}</td>}
                  {this.state.columnsData.includes("clickid") && (
                    <td style={{ textAlign: "left", maxWidth:"200px", textOverflow:"ellipsis", whiteSpace:"nowrap", overflow:"hidden" }}>{e?.clickId ? e?.clickId : "UNKNOWN"}</td>
                  )}
                  {this.state.columnsData.includes("profit") && (
                    <td>{e?.profit ? e?.profit.toFixed(2) : "$0.00"}</td>
                  )}
                  {this.state.columnsData.includes("revenue") && (
                    <td>
                      {e?.revenue ? `$${e?.revenue.toFixed(2)}` : "$0.00"}
                    </td>
                  )}
                  {this.state.columnsData.includes(
                    "revenueinoriginalcurrency"
                  ) && <td>{e?.revenue ? e?.revenue.toFixed(2) : "0.00"}</td>}
                  {this.state.columnsData.includes("currency") && (
                    <td>{e?.currency ? e?.currency : "-"}</td>
                  )}
                  {this.state.columnsData.includes("cost") && (
                    <td>{e?.cost ? e?.cost.toFixed(2) : "$0.00"}</td>
                  )}
                    {this.state.columnsData.includes("city") && (
                      <td>{e?.city ?? "UNKNOWN"}</td>
                    )}
                  {this.state.columnsData.includes("region") && (
                    <td>{e?.region ?? "UNKNOWN"}</td>
                  )}
                  {this.state.columnsData.includes("country") && (
                    <td>{e?.country ?? "UNKNOWN"}</td>
                  )}
                  {this.state.columnsData.includes("transactionid") && (
                    <td>{e?.transactionId ? e?.transactionId : "UNKNOWN"}</td>
                  )}
                  {this.state.columnsData.includes("outgoingpostbackurl") && (
                    <td>
                      {e?.outGoingPostBack ? e?.outGoingPostBack : "UNKNOWN"}
                    </td>
                  )}
                  {this.state.columnsData.includes("externalid") && (
                    <td>{e?.externalId ?? "UNKNOWN"}</td>
                  )}
                  {this.state.columnsData.includes("campaignid") && (
                    <td>{e?.campaignId ?? "UNKNOWN"}</td>
                  )}
                  {this.state.columnsData.includes("offerid") && (
                    <td>{e?.offersId ?? "UNKNOWN"}</td>
                  )}
                  {this.state.columnsData.includes("affiliatenetworkid") && (
                    <td>{e?.affiliateNetworkId ?? "UNKNOWN"}</td>
                  )}
                  {this.state.columnsData.includes("visitorip") && (
                    <td>{e?.ipAddress ?? "UNKNOWN"}</td>
                  )}
                  {this.state.columnsData.includes("browser") && (
                    <td>{e?.browser ?? "UNKNOWN"}</td>
                  )}
                  {this.state.columnsData.includes("landerid") && (
                    <td>{e?.landerId ?? "UNKNOWN"}</td>
                  )}
                  {this.state.columnsData.includes("landername") && (
                    <td>{e?.landerName ?? "UNKNOWN"}</td>
                  )}
                  {this.state.columnsData.includes("proxy") && (
                    <td>{e?.proxy ?? "UNKNOWN"}</td>
                  )}
                </tr>
              ))}
              {Boolean(this.props.noRecordFound) &&
                Boolean(!this.state?.allDataLoaded.length) && (
                  
                  <tr>
                    <th style={{height:"65vh",background:"transparent"}} 
                    colSpan="18"
                    >
                      <div
                        style={{
                        display: !this.state.allDataLoaded?.length
                          ? // ? "flow-root"
                            "flex"
                          : "none",
                        height: "inherit",
                        justifyContent: "center",
                        alignItems: "center",
                        background:"transparent",
                        width:window.innerWidth,
                      }}
                    >
                      <span className="k-icon k-i-search"></span> No results have
                      been found in response
                      </div>
                    </th>
                  
                  </tr>

                
                )}
              <tr className="table_footer_row">
                <th  style={{ minWidth: "200px" }}>
                  Total({this.state.allDataLoaded?.length})
                </th>
                {this.state.columnsData.includes("visittimestamp") && (
                  <th
                    
                    style={{ minWidth: "200px" }}
                  ></th>
                )}
                {this.state.columnsData.includes("campaign_name") && (
                  <th
                    
                    style={{ minWidth: "250px" }}
                  ></th>
                )}
                {this.state.columnsData.includes("offers_name") && (
                  <th
                  
                    style={{ minWidth: "250px" }}
                  ></th>
                )}
                {this.state.columnsData.includes("affiliate_network_name") && (
                  <th
                    
                    style={{ minWidth: "250px" }}
                  ></th>
                )}
                {this.state.columnsData.includes("clickid") && (
                  <th
                   
                    style={{ minWidth: "100px" }}
                  ></th>
                )}
                {this.state.columnsData.includes("profit") && (
                  <th  style={{ minWidth: "100px" }}>
                    {`$${this.state.totalprofit.toFixed(2)}`}
                  </th>
                )}
                {this.state.columnsData.includes("revenue") && (
                  <th  style={{ minWidth: "100px" }}>
                    {`$${this.state.totalrevenue.toFixed(2)}`}
                  </th>
                )}
                {this.state.columnsData.includes(
                  "revenueinoriginalcurrency"
                ) && (
                  <th  style={{ minWidth: "200px" }}>
                    {`$${this.state.totalrevenue.toFixed(2)}`}
                  </th>
                )}
                {this.state.columnsData.includes("currency") && (
                  <th
                   
                    style={{ minWidth: "100px" }}
                  ></th>
                )}
                {this.state.columnsData.includes("cost") && (
                  <th style={{ minWidth: "100px" }}>
                    {`$${this.state.totalcost.toFixed(2)}`}
                  </th>
                )}
                  {this.state.columnsData.includes("city") && (
                    <th

                      style={{ minWidth: "200px" }}
                    ></th>
                  )}
                {this.state.columnsData.includes("region") && (
                  <th

                    style={{ minWidth: "200px" }}
                  ></th>
                )}
                {this.state.columnsData.includes("country") && (
                  <th

                    style={{ minWidth: "200px" }}
                  ></th>
                )}
                {this.state.columnsData.includes("transcationid") && (
                  <th

                    style={{ minWidth: "200px" }}
                  ></th>
                )}
                {this.state.columnsData.includes("outgoingpostbackurl") && (
                  <th

                    style={{ minWidth: "200px" }}
                  ></th>
                )}
                {this.state.columnsData.includes("externalid") && (
                  <th

                    style={{ minWidth: "200px" }}
                  ></th>
                )}
                {this.state.columnsData.includes("campaignid") && (
                  <th

                    style={{ minWidth: "200px" }}
                  ></th>
                )}
                {this.state.columnsData.includes("offerid") && (
                  <th

                    style={{ minWidth: "100px" }}
                  ></th>
                )}
                {this.state.columnsData.includes("affiliatenetworkid") && (
                  <th

                    style={{ minWidth: "100px" }}
                  ></th>
                )}
                {this.state.columnsData.includes("visitorip") && (
                  <th

                    style={{ minWidth: "100px" }}
                  ></th>
                )}
                {this.state.columnsData.includes("browser") && (
                  <th

                    style={{ minWidth: "200px" }}
                  ></th>
                )}
                {this.state.columnsData.includes("landerid") && (
                  <th

                    style={{ minWidth: "200px" }}
                  ></th>
                )}
                {this.state.columnsData.includes("landername") && (
                  <th

                    style={{ minWidth: "200px" }}
                  ></th>
                )}
                {this.state.columnsData.includes("proxy") && (
                  <th

                    style={{ minWidth: "200px" }}
                  ></th>
                )}
                {this.state.columnsData.includes("proxy") && (
                  <th

                    style={{ minWidth: "200px" }}
                  ></th>
                )}
              </tr>
            </tbody>
            <tfoot></tfoot>
          </table>
        </div>
        {Boolean(this.props.showColumnModal) && (
          <GridColumnsModal
            toggleColumnsModal={this.props.toggleColumnsModal}
            allColumns={this.state.allColumns}
            columnsSelectAllHandler={this.columnsSelectAllHandler}
            allColumnsChecked={this.state.allColumnsChecked}
            columnsCheckBoxChangeHandler={this.columnsCheckBoxChangeHandler}
            saveColumnsHandler={this.saveColumnsHandler}
          />
        )}
      </>
    );
  }
}

export const mapStateToProps = createStructuredSelector({});

export function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(GridClassComponent);


                  //<div
                  //  style={{
                  //    display: "flex",
                  //    alignItems: "center",
                  //    justifyContent: "center",
                  //    height: "90%",
                 //   }}
                 // >
                 //   <div
                 //     style={{
                 //       display: "flex",
                 //       height: "inherit",
                 //       alignItems: "center",
                  //      justifyContent: "center",
                 //     }}
                //    >
               //       <div>
               //         <span className="k-icon k-i-search"></span> No results
              //          have been found in response
              //        </div>
              //      </div>
              //    </div>