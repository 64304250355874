import { call, put, takeLatest } from "redux-saga/effects";
import { ADD_PRIVATE_WORKSPACE, EDIT_PRIVATE_WORKSPACE,DELETE_PRIVATE_WORKSPACE,LOAD_PRIVATE_WORKSPACE } from './constants';
import {
	addPrivateWorkspaceSuccess, addPrivateWorkspaceFailed, fetchingDataSuccess,
	fetchingDataFailed,editDataSuccess,editDataFailed,deletePrivateWorkspaceSuccess,deletePrivateWorkspaceFailed } from './actions';
import { loadWorkspace, editWorkspace, addWorkspace,deleteWorkspace } from './workspaceApi';

function* addPrivateWorkspace(payload) {
	try {
		const response = yield call(addWorkspace, payload.payload);
		yield put(
			addPrivateWorkspaceSuccess({ workspaceName: response.data})
		);
	  } catch (error) {
		yield put(addPrivateWorkspaceFailed(error));
	  }
}


function* fetchingData(payload) {
	try {
		const response = yield call(loadWorkspace, payload.payload.axiosdata);
		yield put(
		  fetchingDataSuccess({ data: response.data})
		);
	  } catch (error) {
		yield put(fetchingDataFailed(error));
	  }
}
function* editData(payload) {
	try {
		const response = yield call(editWorkspace, payload.payload);
		yield put(
		  editDataSuccess({ data: response.data})
		);
	  } catch (error) {
		yield put(editDataFailed(error));
	  }
}
function* deletePrivateWorkspace(payload) {
	try {
		const response = yield call(deleteWorkspace, payload.payload);
		yield put(
			deletePrivateWorkspaceSuccess({ data: response.data})
		);
	  } catch (error) {
		yield put(deletePrivateWorkspaceFailed(error));
	  }
	}
	
	export default function* CollaborationToolsWorkspacesSaga() {
		yield takeLatest(ADD_PRIVATE_WORKSPACE, addPrivateWorkspace);
		yield takeLatest(DELETE_PRIVATE_WORKSPACE, deletePrivateWorkspace);
		yield takeLatest(LOAD_PRIVATE_WORKSPACE, fetchingData);
		yield takeLatest(EDIT_PRIVATE_WORKSPACE, editData);
	  }
	  