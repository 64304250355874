import produce from "immer";
import {
    ADD_CUSTOM_DOMAIN,
    ADD_CUSTOM_DOMAIN_SUCCESS,
    ADD_CUSTOM_DOMAIN_FAILED,
    LOAD_CUSTOM_DOMAINS,
    LOAD_CUSTOM_DOMAINS_SUCCESS,
    LOAD_CUSTOM_DOMAINS_FAILED,
    EDIT_CUSTOM_DOMAIN,
    EDIT_CUSTOM_DOMAIN_SUCCESS,
    EDIT_CUSTOM_DOMAIN_FAILED,
    DELETE_CUSTOM_DOMAIN,
    DELETE_CUSTOM_DOMAIN_SUCCESS,
    DELETE_CUSTOM_DOMAIN_FAILED,
    SEND_MAIN_DOMAIN,
    SEND_MAIN_DOMAIN_SUCCESS,
    SEND_MAIN_DOMAIN_FAILED,
    LOAD_REDIRECT_DOMAIN,
    LOAD_REDIRECT_DOMAIN_SUCCESS,
    LOAD_REDIRECT_DOMAIN_FAILED,
    SEND_REDIRECT_DOMAIN,
    SEND_REDIRECT_DOMAIN_SUCCESS,
    SEND_REDIRECT_DOMAIN_FAILED
} from "./constants";

export const initialDomainsState = {
    domainName: null,
    workspaceId: null,
    loading: false,
    success: false,
    addDomainSuccess: false,
    error: false,
    domainsDataReceived: null,
    editDomainPatchingSuccess: false,
    deleteDomainSuccess: false,
    isMainDomainDataSent: false,
    redirectDomainDataReceived: null,
    isRedirectDomainDataSent: false
}

const domainsSettingsReducer = (state= initialDomainsState, action) => produce(state, (draft => {
        switch (action.type) {
            case ADD_CUSTOM_DOMAIN:
                draft.loading = true;
                draft.success = false;
                draft.error = false;
                return draft;
            case ADD_CUSTOM_DOMAIN_SUCCESS:
                draft.loading = false;
                draft.success = true;
                draft.error = false;
                draft.domainsDataReceived = null;
                draft.addDomainSuccess = true;
                draft.editDomainPatchingSuccess = false;
                draft.deleteDomainSuccess = false;
                return draft;
            case ADD_CUSTOM_DOMAIN_FAILED:
                draft.loading = false;
                draft.success = false;
                draft.error = action.payload.response;
                return draft;
            case LOAD_CUSTOM_DOMAINS:
                draft.loading = true;
                draft.success = false;
                draft.error = action.payload;
                return draft;
            case LOAD_CUSTOM_DOMAINS_SUCCESS:
                draft.domainsDataReceived = action.payload;
                draft.loading = false;
                draft.success = true;
                draft.error = false;
                draft.addDomainSuccess = false;
                draft.editDomainPatchingSuccess = false;
                draft.deleteDomainSuccess = false;
                return draft;
            case LOAD_CUSTOM_DOMAINS_FAILED:
                draft.loading = false;
                draft.success = false;
                draft.error = action.payload.response;
            case EDIT_CUSTOM_DOMAIN:
                draft.loading = true;
                draft.success = false;
                draft.error = false;
                return draft;
            case EDIT_CUSTOM_DOMAIN_SUCCESS:
                draft.loading = false;
                draft.success = true;
                draft.error = false;
                draft.domainsDataReceived = null;
                draft.editDomainPatchingSuccess = true;
                draft.deleteDomainSuccess = false;
                draft.addDomainSuccess = false;
                return draft;
            case EDIT_CUSTOM_DOMAIN_FAILED:
                draft.loading = false;
                draft.success = false;
                draft.error = action.payload.response;
                return draft;
            case DELETE_CUSTOM_DOMAIN:
                draft.loading = true;
                draft.success = false;
                draft.error = false;
                return draft;
            case DELETE_CUSTOM_DOMAIN_SUCCESS:
                draft.loading = false;
                draft.success = true;
                draft.error = false;
                draft.domainsDataReceived = null;
                draft.editDomainPatchingSuccess = false;
                draft.deleteDomainSuccess = true;
                draft.addDomainSuccess = false;
                return draft;
            case DELETE_CUSTOM_DOMAIN_FAILED:
                draft.loading = false;
                draft.success = false;
                draft.error = action.payload.response;
                return draft;
            case SEND_MAIN_DOMAIN:
                draft.loading = true;
                draft.success = false;
                draft.error = false;
                return draft;
            case SEND_MAIN_DOMAIN_SUCCESS:
                draft.loading = false;
                draft.success = true;
                draft.error = false;
                draft.isMainDomainDataSent = true;
                return draft;
            case SEND_MAIN_DOMAIN_FAILED:
                draft.loading = false;
                draft.success = false;
                draft.error = action.payload.response;
                return draft;

                // redirect domain
            case SEND_REDIRECT_DOMAIN:
                draft.loading = true;
                draft.success = false;
                draft.error = false;
                return draft;
            case SEND_REDIRECT_DOMAIN_SUCCESS:
                draft.loading = false;
                draft.success = true;
                draft.error = false;
                draft.redirectDomainDataReceived = null;
                draft.isRedirectDomainDataSent = true;
                return draft;
            case SEND_REDIRECT_DOMAIN_FAILED:
                draft.loading = false;
                draft.success = false;
                draft.error = action.payload.response;
                draft.isRedirectDomainDataSent = false;
                return draft;
            case LOAD_REDIRECT_DOMAIN:
                draft.loading = true;
                draft.success = false;
                draft.error = false;
                return draft;
            case LOAD_REDIRECT_DOMAIN_SUCCESS:
                draft.loading = false;
                draft.success = true;
                draft.error = false;
                draft.redirectDomainDataReceived = action.payload;
                draft.isRedirectDomainDataSent = false;
                return draft;
            case LOAD_REDIRECT_DOMAIN_FAILED:
                draft.loading = false;
                draft.success = false;
                draft.error = action.payload.response;
                return draft;
            default:
                return draft;
        }
    }
    ));


export default domainsSettingsReducer;