import produce from "immer";
import {
    LOAD_ALL_REPORT_DATA, LOAD_ALL_REPORT_DATA_SUCCESS, LOAD_ALL_REPORT_DATA_FAILED,
    GET_CHARTS_DATA, GET_CHARTS_DATA_SUCCESS, GET_CHARTS_DATA_FAILED,
    LOAD_SUB_LEVEL_DATA, LOAD_SUB_LEVEL_DATA_SUCCESS, LOAD_SUB_LEVEL_DATA_FAILED
} from "./constants";


export const initialReportState = {
    loading: false,
    success: false,
    error: false,
    allLoadedReportData: [],
    reportSubLevelData: null,
    charts: {
        isChartsDataLoaded: false,
        allChartsDataLoaded: null
    }
};

const reportReducer = (state= initialReportState, action) => produce(state, (draft => {
    switch (action.type) {
        case LOAD_ALL_REPORT_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case LOAD_ALL_REPORT_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.allLoadedReportData = action.payload;
            return draft;
        case LOAD_ALL_REPORT_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            return draft;
        
        case GET_CHARTS_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            return draft;
        case GET_CHARTS_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.charts.isChartsDataLoaded = true;
            draft.charts.allChartsDataLoaded = action.payload;
            return draft;
        case GET_CHARTS_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.charts.isChartsDataLoaded = false;
            draft.error = action.payload.response;
            return draft;

        case LOAD_SUB_LEVEL_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.reportSubLevelData = null;
            return draft;
        case LOAD_SUB_LEVEL_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.reportSubLevelData = action.payload;
            return draft;
        case LOAD_SUB_LEVEL_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            draft.reportSubLevelData = null;
            return draft;

        default:
            return draft;
    }
}));

export default reportReducer;