export const  setDatesToLocalStorage = (startDate, endDate) => {
    let search = JSON.parse(localStorage.getItem("search"));
    search.dates.startDate = startDate;
    search.dates.endDate = endDate;
    search.modifiedDate.startDate = startDate.format("MM/DD/YYYY hh:mm A");
    search.modifiedDate.endDate = endDate.format("MM/DD/YYYY hh:mm A");
    localStorage.setItem("search",JSON.stringify(search));
}

export const  setReportDatesToLocalStorage = (startDate, endDate, reportNumber) => {
    let search = JSON.parse(localStorage.getItem("search"));
    search.report["report" + reportNumber].search.date.startDate = startDate;
    search.report["report" + reportNumber].search.date.endDate = endDate;
    search.report["report" + reportNumber].search.modifiedDate.startDate = startDate.format("MM/DD/YYYY hh:mm A");
    search.report["report" + reportNumber].search.modifiedDate.endDate = endDate.format("MM/DD/YYYY hh:mm A");
    localStorage.setItem("search",JSON.stringify(search));
}