import axios from 'axios';
import { BASE_URL } from '../../helpers/path';

export const loadNotificationsApi = async (request)=>{
    const LOAD_NOTIFICATIONS_ENDPOINT = BASE_URL + 'notification';
	const accessToken = request.accessToken;
	axiosintercepter(accessToken);
	let response = await axios.get(LOAD_NOTIFICATIONS_ENDPOINT);
	return response;
}

export const updateNotificationsApi = async (request) => {
	const UPDATE_NOTIFICATIONS_ENDPOINT = BASE_URL + 'notification';
	const accessToken = request.accessToken;
	axiosintercepter(accessToken);
	let response = await axios.patch(UPDATE_NOTIFICATIONS_ENDPOINT,request.updateNotificatationData);
	return response;
}

function axiosintercepter(accessToken) {
	axios.interceptors.request.use(
		config => {
			config.headers.authorization = `Bearer ${accessToken}`;
		  return config;
		},
		error => {
		  return Promise.reject(error);
		}
	  )
}