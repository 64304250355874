import {

    LOAD_COUNTRY,
    LOAD_COUNTRY_SUCCESS,
    LOAD_COUNTRY_FAILED,

    POST_CAMPAIGN_DATA,
    POST_CAMPAIGN_DATA_SUCCESS,
    POST_CAMPAIGN_DATA_FAILED,

    LOAD_ALL_CAMPAIGNS_DATA,
    LOAD_ALL_CAMPAIGNS_DATA_SUCCESS,
    LOAD_ALL_CAMPAIGNS_DATA_FAILED,

    LOAD_EDIT_CAMPAIGN_DATA,
    LOAD_EDIT_CAMPAIGN_DATA_SUCCESS,
    LOAD_EDIT_CAMPAIGN_DATA_FAILED,

    EDIT_CAMPAIGN_DIALOGUE_CLOSED,

    SEND_EDIT_CAMPAIGN_DATA,
    SEND_EDIT_CAMPAIGN_DATA_SUCCESS,
    SEND_EDIT_CAMPAIGN_DATA_FAILED,

    DELETE_CAMPAIGN_DATA,
    DELETE_CAMPAIGN_DATA_SUCCESS,
    DELETE_CAMPAIGN_DATA_FAILED,

    ARCHIVE_CAMPAIGN_DATA,
    ARCHIVE_CAMPAIGN_DATA_SUCCESS,
    ARCHIVE_CAMPAIGN_DATA_FAILED,

    LOAD_TOKEN_TAGS,
    LOAD_TOKEN_TAGS_SUCCESS,
    LOAD_TOKEN_TAGS_FAILED,

    CLOSE_MODAL_WINDOW,
    LOAD_SEARCH_TAGS,
    LOAD_SEARCH_TAGS_SUCCESS,
    LOAD_SEARCH_TAGS_FAILED,

    GET_CHARTS_DATA,
    GET_CHARTS_DATA_SUCCESS,
    GET_CHARTS_DATA_FAILED,

    TIME_ZONES_DATA,
    TIME_ZONES_DATA_SUCCESS,
    TIME_ZONES_DATA_FAILED,

    MANUAL_COST_UPDATE_DATA,
    MANUAL_COST_UPDATE_DATA_SUCCESS,
    MANUAL_COST_UPDATE_DATA_FAILED,

    GET_COST_CURRENCY_DATA,
    GET_COST_CURRENCY_DATA_SUCCESS,
    GET_COST_CURRENCY_DATA_FAILED
} from "./constants";


// Loading Countries Actions
export const loadCountry = (loadCountryPayload) => {
    return {
        type: LOAD_COUNTRY,
        payload: loadCountryPayload
    };
};

export const loadCountrySuccess = (loadCountySuccessPayload) => {
    return {
        type: LOAD_COUNTRY_SUCCESS,
        payload: loadCountySuccessPayload
    };
};

export const loadCountryFailed = (error) => {
    return {
        type: LOAD_COUNTRY_FAILED,
        payload: error
    };
};


// Sending New Campaign data Actions
export const postCampaign = (postCampaignPayload) => {
    return {
        type: POST_CAMPAIGN_DATA,
        payload: postCampaignPayload
    };
};

export const postCampaignSuccess = (postCampaignSuccessPayload) => {
    return {
        type: POST_CAMPAIGN_DATA_SUCCESS,
        payload: postCampaignSuccessPayload
    };
};

export const postCampaignFailed = (error) => {
    return {
        type: POST_CAMPAIGN_DATA_FAILED,
        payload: error
    };
};

// Load All Campaigns data Actions
export const loadAllCampaigns = (loadAllCampaignsPayload) => {
    return {
        type: LOAD_ALL_CAMPAIGNS_DATA,
        payload: loadAllCampaignsPayload
    };
};

export const loadAllCampaignsSuccess = (loadAllCampaignsSuccessPayload) => {
    return {
        type: LOAD_ALL_CAMPAIGNS_DATA_SUCCESS,
        payload: loadAllCampaignsSuccessPayload
    };
};

export const loadAllCampaignsFailed = (error) => {
    return {
        type: LOAD_ALL_CAMPAIGNS_DATA_FAILED,
        payload: error
    };
};

// EDIT Campaign data Actions
export const loadEditCampaign = (editCampaignPayload) => {
    return {
        type: LOAD_EDIT_CAMPAIGN_DATA,
        payload: editCampaignPayload
    };
};

export const loadEditCampaignSuccess = (editCampaignSuccessPayload) => {
    return {
        type: LOAD_EDIT_CAMPAIGN_DATA_SUCCESS,
        payload: editCampaignSuccessPayload
    };
};

export const loadEditCampaignFailed = (error) => {
    return {
        type: LOAD_EDIT_CAMPAIGN_DATA_FAILED,
        payload: error
    };
};

// Close edit campaign and cancel data in edit campaign data received
export const editCampaignDialogueClosed = () => {
    return {
        type: EDIT_CAMPAIGN_DIALOGUE_CLOSED
    };
};


// Send EDIT Traffic Sources data Actions
export const sendEditCampaign = (sendEditCampaignPayload) => {
    return {
        type: SEND_EDIT_CAMPAIGN_DATA,
        payload: sendEditCampaignPayload
    };
};

export const sendEditCampaignSuccess = (sendEditCampaignSuccessPayload) => {
    return {
        type: SEND_EDIT_CAMPAIGN_DATA_SUCCESS,
        payload: sendEditCampaignSuccessPayload
    };
};

export const sendEditCampaignFailed = (error) => {
    return {
        type: SEND_EDIT_CAMPAIGN_DATA_FAILED,
        payload: error
    };
};


// Delete Campaign data Actions
export const deleteCampaign = (deleteCampaignPayload) => {
    return {
        type: DELETE_CAMPAIGN_DATA,
        payload: deleteCampaignPayload
    };
};

export const deleteCampaignSuccess = (deleteCampaignSuccessPayload) => {
    return {
        type: DELETE_CAMPAIGN_DATA_SUCCESS,
        payload: deleteCampaignSuccessPayload
    };
};

export const deleteCampaignFailed = (error) => {
    return {
        type: DELETE_CAMPAIGN_DATA_FAILED,
        payload: error
    };
};

// Delete Campaign data Actions
export const archiveCampaign = (payload) => {
    return {
        type: ARCHIVE_CAMPAIGN_DATA,
        payload: payload
    };
};

export const archiveCampaignSuccess = (payload) => {
    return {
        type: ARCHIVE_CAMPAIGN_DATA_SUCCESS,
        payload: payload
    };
};

export const archiveCampaignFailed = (error) => {
    return {
        type: ARCHIVE_CAMPAIGN_DATA_FAILED,
        payload: error
    };
};

// Load Tokens Dictionary data Actions
export const loadTokensDictionary = (loadTokensDictionaryPayload) => {
    return {
        type: LOAD_TOKEN_TAGS,
        payload: loadTokensDictionaryPayload
    };
};

export const loadTokensDictionarySuccess = (loadTokensDictionarySuccessPayload) => {
    return {
        type: LOAD_TOKEN_TAGS_SUCCESS,
        payload: loadTokensDictionarySuccessPayload
    };
};

export const loadTokensDictionaryFailed = (error) => {
    return {
        type: LOAD_TOKEN_TAGS_FAILED,
        payload: error
    };
};

// Load Search Tags data Actions
export const loadSearchTags = (payload) => {
    return {
        type: LOAD_SEARCH_TAGS,
        payload: payload
    };
};

export const loadSearchTagsSuccess = (payload) => {
    return {
        type: LOAD_SEARCH_TAGS_SUCCESS,
        payload: payload
    };
};

export const loadSearchTagsFailed = (error) => {
    return {
        type: LOAD_SEARCH_TAGS_FAILED,
        payload: error
    };
};

// Load Search Tags data Actions
export const loadChartsData = (payload) => {
    return {
        type: GET_CHARTS_DATA,
        payload: payload
    };
};

export const loadChartsDataSuccess = (payload) => {
    return {
        type: GET_CHARTS_DATA_SUCCESS,
        payload: payload
    };
};

export const loadChartsDataFailed = (error) => {
    return {
        type: GET_CHARTS_DATA_FAILED,
        payload: error
    };
};


// Update Cost TimeZones data Actions
export const timeZones = (payload) => {
    return {
        type: TIME_ZONES_DATA,
        payload: payload
    };
};

export const timeZonesSuccess = (payload) => {
    return {
        type: TIME_ZONES_DATA_SUCCESS,
        payload: payload
    };
};

export const timeZonesFailed = (error) => {
    return {
        type: TIME_ZONES_DATA_FAILED,
        payload: error
    };
};


// Update Cost data Submission Actions
export const updateManualCostData = (payload) => {
    return {
        type: MANUAL_COST_UPDATE_DATA,
        payload: payload
    };
};

export const updateManualCostDataSuccess = (payload) => {
    return {
        type: MANUAL_COST_UPDATE_DATA_SUCCESS,
        payload: payload
    };
};

export const updateManualCostDataFailed = (error) => {
    return {
        type: MANUAL_COST_UPDATE_DATA_FAILED,
        payload: error
    };
};

// Update Cost Currency Actions
export const getCostCurrencyData = (payload) => {
    return {
        type: GET_COST_CURRENCY_DATA,
        payload: payload
    };
};

export const getCostCurrencyDataSuccess = (payload) => {
    return {
        type: GET_COST_CURRENCY_DATA_SUCCESS,
        payload: payload
    };
};

export const getCostCurrencyDataFailed = (error) => {
    return {
        type: GET_COST_CURRENCY_DATA_FAILED,
        payload: error
    };
};