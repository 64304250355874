import { createSelector } from "reselect";
import { initialDomainsState } from "./reducers";

const selectDomainsState= (state) =>  state.domains || initialDomainsState;


const makeSelectWorkspaceId = () => createSelector(selectDomainsState, (subState) => subState.workspaceId);
const makeSelectDomainName = () => createSelector(selectDomainsState, (subState) => subState.domainName);
const makeSelectLoading = () => createSelector(selectDomainsState, (subState) => subState.loading);
const makeSelectSuccess = () => createSelector(selectDomainsState, (subState) => subState.success);
const makeSelectError = () => createSelector(selectDomainsState, (subState) => subState.error);

// checks if load domain data is received
const makeSelectDomainsDataReceived = () => createSelector(selectDomainsState, (subState) => subState.domainsDataReceived);
// checks if domain added to the db successfully
const makeSelectAddDomainSuccess = () => createSelector(selectDomainsState, (subState) => subState.addDomainSuccess);
const makeSelectEditDomainPatchingSuccess = () => createSelector(selectDomainsState, (subState) => subState.editDomainPatchingSuccess);
const makeSelectDeleteDomainSuccess = () => createSelector(selectDomainsState, (subState) => subState.deleteDomainSuccess);

const makeSelectIsMainDomainDataSent = () => createSelector(selectDomainsState, (subState) => subState.isMainDomainDataSent);
const makeSelectIsRedirectDomainDataSent = () => createSelector(selectDomainsState, (subState) => subState.isRedirectDomainDataSent);
const makeSelectRedirectDomainDataReceived = () => createSelector(selectDomainsState, (subState) => subState.redirectDomainDataReceived);

export {
    makeSelectWorkspaceId,
    makeSelectDomainName,
    makeSelectLoading,
    makeSelectSuccess,
    makeSelectError,
    makeSelectDomainsDataReceived,
    makeSelectAddDomainSuccess,
    makeSelectEditDomainPatchingSuccess,
    makeSelectDeleteDomainSuccess,
    makeSelectIsMainDomainDataSent,
    makeSelectIsRedirectDomainDataSent,
    makeSelectRedirectDomainDataReceived
}