import produce from "immer";

import {
    ADD_IP_UA_FILTERING,
    ADD_IP_UA_FILTERING_SUCCESS,
    ADD_IP_UA_FILTERING_FAILED,
    LOAD_IP_UA_FILTERING,
    LOAD_IP_UA_FILTERING_SUCCESS,
    LOAD_IP_UA_FILTERING_FAILED,
    EDIT_IP_UA_FILTERING,
    EDIT_IP_UA_FILTERING_SUCCESS,
    EDIT_IP_UA_FILTERING_FAILED,
    UPDATE_IP_UA_FILTERING,
    UPDATE_IP_UA_FILTERING_SUCCESS,
    UPDATE_IP_UA_FILTERING_FAILED,
    DELETE_IP_UA_FILTERING,
    DELETE_IP_UA_FILTERING_SUCCESS,
    DELETE_IP_UA_FILTERING_FAILED,
} from "./constants";

export const initialState = {
    loading: false,
    success: false,
    error: false,
    addingError: false,
    addingSuccess: false,
    ipuaFilterData: null,
    editIpUaFilterData: null,
    isIpUaFilterDataLoaded: false,
    isIpUaFilterDataUpdated: false,
    isIpUaFilterDataDeleted:false,
};

const ipuaFilterReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case ADD_IP_UA_FILTERING:
                // console.log('add.........');
                draft.loading = true;
                draft.error = false;
                draft.success = false;
                draft.addingError = false;
                draft.addingSuccess = false;
                draft.isIpUaFilterDataLoaded = false;
                return draft;
            case ADD_IP_UA_FILTERING_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.success = true;
                draft.isIpUaFilterDataLoaded = true;
                draft.addingError = false;
                draft.addingSuccess = true;
                return draft;
                //console.log('add success .............');
                // console.log('action.payload.data',action.payload.data);
                //draft.ipuaFilterData = action.payload.data;
            case ADD_IP_UA_FILTERING_FAILED:
                //console.log('add ..... failed');
                draft.loading = false;
                draft.error = true;
                draft.success = false;
                draft.addingError = true;
                draft.addingSuccess = false;
                //draft.ipuaFilterData = null;
                draft.isIpUaFilterDataLoaded = false;
                return draft;
            case LOAD_IP_UA_FILTERING:
                draft.loading = true;
                draft.error = false;
                draft.success = false;
                draft.isIpUaFilterDataUpdated = false;
                draft.isIpUaFilterDataLoaded = false;
                draft.isIpUaFilterDataDeleted = false;
                return draft;
            case LOAD_IP_UA_FILTERING_SUCCESS:
                draft.loading = false;
                draft.success = true;
                draft.error = false;
                draft.ipuaFilterData = action.payload.ipuaData.data;
                draft.isIpUaFilterDataLoaded = true;
                draft.isIpUaFilterDataUpdated = false;
                draft.isIpUaFilterDataLoaded = false;
                draft.isIpUaFilterDataDeleted = false;
                return draft;
            case LOAD_IP_UA_FILTERING_FAILED:
                draft.loading = false;
                draft.error = true;
                draft.success = false;
                draft.ipuaFilterData = null;
                draft.isIpUaFilterDataLoaded = false;
                draft.isIpUaFilterDataUpdated = false;
                draft.isIpUaFilterDataLoaded = false;
                draft.isIpUaFilterDataDeleted = false;
                return draft;
            case EDIT_IP_UA_FILTERING:
                draft.loading = true;
                draft.error = false;
                draft.success = false;
                
                return draft;
            case EDIT_IP_UA_FILTERING_SUCCESS:
                //console.log('edit data',action.payload.editData.data);
                draft.loading = false;
                draft.error = false;
                draft.success = true;
                draft.editIpUaFilterData = action.payload.editData.data;
                return draft;
            case EDIT_IP_UA_FILTERING_FAILED:
                draft.loading = false;
                draft.error = true;
                draft.success = false;
                draft.editIpUaFilterData = null;
                return draft;
            case UPDATE_IP_UA_FILTERING:
                draft.loading = true;
                draft.error = false;
                draft.success = false;
                draft.isIpUaFilterDataUpdated = false;
                return draft;
            case UPDATE_IP_UA_FILTERING_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.success = true;
                //draft.ipuaFilterData = action.payload;
                draft.isIpUaFilterDataUpdated = true;
                return draft;
            case UPDATE_IP_UA_FILTERING_FAILED:
                draft.loading = false;
                draft.error = true;
                draft.success = false;
                draft.ipuaFilterData = null;
                draft.isIpUaFilterDataUpdated = false;
                return draft;
            case DELETE_IP_UA_FILTERING:
                draft.loading = true;
                draft.error = false;
                draft.success = false;
                draft.isIpUaFilterDataDeleted = false;
                return draft;
            case DELETE_IP_UA_FILTERING_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.success = true;
                draft.isIpUaFilterDataDeleted = true;
                return draft;
            case DELETE_IP_UA_FILTERING_FAILED:
                draft.loading = false;
                draft.error = true;
                draft.success = false;
                draft.isIpUaFilterDataDeleted = false;
                return draft;
            default:
                return draft;
        }
    });

export default ipuaFilterReducer;
