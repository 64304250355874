import axios from "axios";
import { BASE_URL } from "../helpers/path";

export const GetSubLevelDataFromAPI = async (request) => {
    axios.interceptors.request.use(req => {
        // `req` is the Axios request config, so you can modify
        // the `headers`.
        req.headers.authorization = request.payload.accessToken;
        return req;
    });
    const response = await axios.get(BASE_URL + 'grouping' + request.payload.parameters);
    return response;
};

export const GetColumnsDataFromAPI = async (request) => {
    axios.interceptors.request.use(req => {
        // `req` is the Axios request config, so you can modify
        // the `headers`.
        req.headers.authorization = `Bearer ${request.payload.accessToken}`;
        return req;
    });
    const response = await axios.get(BASE_URL + 'columns');
    return response;
};

export const PatchColumnsDataToAPI = async (request) => {
    axios.interceptors.request.use(req => {
        // `req` is the Axios request config, so you can modify
        // the `headers`.
        req.headers.authorization = request.payload.accessToken;
        return req;
    });
    const response = await axios.patch(BASE_URL + 'columns',request.payload.columnsData);
    return response;
};