import * as React from "react";

import { Reveal } from "@progress/kendo-react-animation";

import "./newRuleCollapse.css";
// import { barcodeOutlineIcon } from '@progress/kendo-svg-icons';
import { Button } from "@progress/kendo-react-buttons";
//import { Switch, Input } from "@progress/kendo-react-inputs";
import Path from "../../path";
//import produce from "immer";

export default class NewRuleCollapse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            direction: "vertical",
            divShow: true,
            count:0
        };
    }

    componentDidMount(){
        //console.log('this.props.',this.props.state);
        if(this.props.state.editAdvanceCampaign){
            if(!this.props.state.deleted && (this.props.state.isShowDestinationTab || this.props.state.isShowGeneralTab)){
                // console.log('did mount');
                //console.log('call 0');
                this.speratingConditions();
            }
        }
    }
    componentDidUpdate(prevProps,prevState){
        if(prevProps.state !== this.props.state && prevProps.state.conditionsName!== this.props.state.conditionsName && !this.props.state.deleted){
            //console.log('call');
            this.speratingConditions();
        }
        if(prevProps.state !== this.props.state && prevProps.defaultValue.name!== this.props.defaultValue.name && this.props.defaultValue.name.length===0 && !this.props.state.deleted){
            //console.log('call 2');
            this.speratingConditions();
        }
        if(this.props.state.dataToSend.path.rule[this.props.index]!==undefined){

            if(prevProps.state !== this.props.state && this.props.state.dataToSend.path.rule.length>0 && prevProps.state.dataToSend.path.rule.length !== this.props.state.dataToSend.path.rule.length){
            //console.log('call 3');
                this.speratingConditions();
            }
        }
        if((prevProps.state.deleted !== this.props.state.deleted)){
            this.speratingConditions();
        }
        //console.log(this.props.state.addedConditions);
        if((prevProps.state.addedConditions !== this.props.state.addedConditions)){
            this.speratingConditions();
        }
    }
    
    

    onClick = () => {
        this.setState({
            show: !this.state.show,
        });
    };
    onChange = (e) => {
        this.setState({
            direction: e.target.value,
        });
    };
    onDeleteClick = (e) => {
        this.props.deleterule(e);
    };
    onDuplicateClick = (e) => {
        this.props.duplicateRule(e);
    };
    selected = (e) => {
        this.props.newRuleClicked(e);
    };
    addNewPath = () => {        
        this.props.addNewPath(this.props.index);
    };
    onRulePathClicked = (e) => {
        // console.log("this.props.index", this.props.index);
        // console.log("event", e.target);
    };
    onSort = (e) => {
        this.props.onSort(e);
    }
    //asdf
    speratingConditions=()=>{

        if(this.props.state.conditionsName[this.props.index] !==undefined && this.props.defaultValue.name.length===0){
            let length = this.props.state.conditionsName[this.props.index].length;
            setTimeout(() => {
                
                //console.log('length',length);
                if(length>1){
                // console.log('inside');    
                    //console.log('document.getElementById(this.props.type + "_" + this.props.index).childNodes[0]',document.getElementById(this.props.type + "_" + this.props.index).childNodes[0]);
                    if(document.getElementById(this.props.type + "_" + this.props.index)!==null && document.getElementById(this.props.type + "_" + this.props.index)!==undefined && document.getElementById(this.props.type + "_" + this.props.index).childNodes[0] !==null && document.getElementById(this.props.type + "_" + this.props.index).childNodes[0] !== undefined){
                        let allElements = document.getElementById(this.props.type + "_" + this.props.index).childNodes[0].childNodes[length-1].id;
                        let lastChild = document.getElementById(String(allElements));
                        for(let i =0;i<length-1;i++){
                            let child = document.getElementById(this.props.type + "_" + this.props.index).childNodes[0].childNodes[i].id;
                            let childElement = document.getElementById(String(child));
                            if(childElement!==undefined && childElement.childNodes[1] !==undefined){
                                childElement.childNodes[1].style.display="inline";
                            }
                        }
                        if(lastChild!==undefined && lastChild.childNodes[1] !==undefined){
                            lastChild.childNodes[1].style.display="none";
                        }
                    }
                }
            }, 50);
        }
    }
    

    render() {
        // console.log('index',this.props.index);
        // console.log('length',Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length);
        // console.log('data',this.props.state.dataToSend.path.rule[this.props.index].conditions.connectionType);
        
        
        //console.log('this.state.dataToSend.path.rule',this.props.state.dataToSend.path.rule[this.props.index].conditions);
        const { show, direction } = this.state;
        const children = show ? (
            <>
                <div className="rule-collapse-child row">
                    <div className="">PATH</div>
                    <div className="rule-btn push">
                        <Button
                            className="transparent-btn push"
                            icon={"k-icon k-i-plus-circle"}
                            onClick={this.addNewPath}
                        >
                            Add path
                        </Button>
                    </div>
                </div>
                {this.props.state.dataToSend.path.rule
                    ? this.props.state.dataToSend.path.rule[
                        this.props.index
                    ].path.map((key, index) => (
                        <Path
                            state={this.props.state}
                            type={"rule_" + this.props.index}
                            ruleIndex={this.props.index}
                            id={key}
                            defaultValue={key}
                            index={index}
                            newRulePathClicked={this.props.newRulePathClicked}
                            duplicateDefaultPath={this.props.duplicateDefaultPath}
                            deleteDefaultPath={this.props.deleteDefaultPath}
                            decreaseDefaultPathWeightMethod={this.props.decreaseDefaultPathWeightMethod}
                            increaseDefaultPathWeightMethod={this.props.increaseDefaultPathWeightMethod}
                            onChangePathSwitch={this.props.onChangePathSwitch}
                            inputChangeHandler={this.props.inputChangeHandler}
                        ></Path>
                    ))
                : null}
            </>
        ) : null;

        return (
            <div
                className={this.props.state.onRuleClick ? "row notes" : "row notes"}
                id={"new-rule"}
                style={{ width: "100%" }}
                onMouseEnter={() => this.setState({ divShow: true })}
                onMouseLeave={() => this.setState({ divShow: true })}
            >
                {this.props.index >= 0 ? (
                    <div className={this.props.state.bgClrRuleIndex === this.props.index ? "col-md-12 path-animation selected" : "col-md-12 path-animation"} style={{ width: "100%" }}>
                        <dl>
                            <dt>
                                <div className="flex row" id={this.props.type + "_" + this.props.index} style={{ cursor: "unset" }}>
                                    <div className="notes rule-notes-name" id={this.props.type + "_" + this.props.index} onClick={this.selected} style={{ cursor: "pointer", display:"flex" }}>

                                    {/* <p>New rule</p> */}
                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&

                                        this.props.state.dataToSend.path.rule[this.props.index].conditions &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions)?.length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.connectionType &&
                                            <p id={ "0_" + this.props.index} className={this.props.state.dataToSend.path.rule[this.props.index].conditions.connectionType.predicate==="MUST_BE" ? "rule-name-green": "rule-name-red"}
                                            >
                                            {this.props.state.dataToSend.path.rule[this.props.index].conditions.connectionType.connection!==undefined &&
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.connectionType.connection.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.connectionType.connection[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.connectionType.connection[0].name + "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.connectionType.connection.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span onClick={e => e.preventDefault()} className="conditions-sperator" id={this.props.type + "_" + this.props.index}>{"&"}</span>
                                            }
                                            </p>
                                    }

                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions)?.length>0 &&

                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.deviceType &&
                                        
                                            <p 
                                                id={"1_" + this.props.index}  
                                                className={this.props.state.dataToSend.path.rule[this.props.index].conditions.deviceType.predicate==="MUST_BE" ? "rule-name-green": "rule-name-red"}
                                            >
                                                {
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.deviceType.device.length===1?  

                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.deviceType.device[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.deviceType.device[0].name + "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.deviceType.device.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }

                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.region &&
                                            <p id={"2_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.region.predicate==="MUST_BE" ? "rule-name-green": "rule-name-red"}
                                            >
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.region.regions.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.region.regions[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.region.regions[0].name + "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.region.regions.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }

                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.proxy &&
                                            <p id={"3_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.proxy.predicate==="MUST_BE" ? "rule-name-green": "rule-name-red"}
                                            >
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.proxy.proxies.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.proxy.proxies[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.proxy.proxies[0].name + "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.proxy.proxies.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }

                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.city &&
                                            <p id={"4_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.city.predicate==="MUST_BE" ? "rule-name-green": "rule-name-red"}
                                            >
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.city.cities.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.city.cities[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.city.cities[0].name + "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.city.cities.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }
                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.mobileCarrier &&
                                            <p id={"5_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.mobileCarrier.predicate==="MUST_BE" ? "rule-name-green": "rule-name-red"}
                                            >
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.mobileCarrier.mobileCarrieries.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.mobileCarrier.mobileCarrieries[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.mobileCarrier.mobileCarrieries[0].name + "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.mobileCarrier.mobileCarrieries.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }

                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.country &&
                                            <p id={"6_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.country.predicate==="MUST_BE" ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.country.countries.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.country.countries[0].country_name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.country.countries[0].country_name + "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.country.countries.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }
                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.isp &&
                                            <p id={"7_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.isp.predicate==="MUST_BE" ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.isp.ispies.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.isp.ispies[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.isp.ispies[0].name + "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.isp.ispies.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }

                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.language &&
                                            <p id={"8_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.language.predicate==="MUST_BE" ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.language.languages.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.language.languages[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.language.languages[0].name + "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.language.languages.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }

                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.brand &&
                                            <p id={"9_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.brand.predicate==="MUST_BE" ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.brand.brands[0].length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.brand.brands[0][0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.brand.brands[0][0].name + "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.brand.brands[0].length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }
                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.os &&
                                            <p id={"10_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.os.predicate==="MUST_BE" ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.os.oss[0].length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.os.oss[0][0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.os.oss[0][0].name + "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.os.oss[0].length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }
                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.browser &&
                                            <p id={"11_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.browser.predicate==="MUST_BE" ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.browser.browsers[0].length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.browser.browsers[0][0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.browser.browsers[0][0].name + "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.browser.browsers[0].length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }
                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.weekday &&
                                            <p id={"12_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.weekday.predicate==="MUST_BE" ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.weekday.weekdays.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.weekday.weekdays[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.weekday.weekdays[0].name + "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.weekday.weekdays.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }

                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.ip &&
                                            <p id={"13_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.ip.predicate==="MUST_BE" ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.ip.ips.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.ip.ips[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.ip.ips[0].name+ "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.ip.ips.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }
                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.referrer &&
                                            <p id={"14_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.referrer.predicate==="MUST_BE" ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.referrer.referrers.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.referrer.referrers[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.referrer.referrers[0].name+ "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.referrer.referrers.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }
                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.referrerDomain &&
                                            <p id={"15_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.referrerDomain.predicate==="MUST_BE" ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.referrerDomain.referrerDomains.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.referrerDomain.referrerDomains[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.referrerDomain.referrerDomains[0].name+ "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.referrerDomain.referrerDomains.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }
                                    
                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.var1 &&
                                            <p id={"16_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.var1.predicate==="MUST_BE" || this.props.state.dataToSend.path.rule[this.props.index].conditions.var1.predicate==="CONTAIN"  ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.var1.var1s.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var1.var1s[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var1.var1s[0].name+ "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.var1.var1s.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }
                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.var2 &&
                                            <p id={"17_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.var2.predicate==="MUST_BE" || this.props.state.dataToSend.path.rule[this.props.index].conditions.var2.predicate==="CONTAIN"  ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.var2.var2s.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var2.var2s[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var2.var2s[0].name+ "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.var2.var2s.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }
                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.var3 &&
                                            <p id={"18_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.var3.predicate==="MUST_BE" || this.props.state.dataToSend.path.rule[this.props.index].conditions.var3.predicate==="CONTAIN"  ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.var3.var3s.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var3.var3s[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var3.var3s[0].name+ "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.var3.var3s.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }
                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.var4 &&
                                            <p id={"19_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.var4.predicate==="MUST_BE" || this.props.state.dataToSend.path.rule[this.props.index].conditions.var4.predicate==="CONTAIN"  ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.var4.var4s.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var4.var4s[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var4.var4s[0].name+ "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.var4.var4s.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }
                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.var5 &&
                                            <p id={"20_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.var5.predicate==="MUST_BE" || this.props.state.dataToSend.path.rule[this.props.index].conditions.var5.predicate==="CONTAIN"  ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.var5.var5s.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var5.var5s[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var5.var5s[0].name+ "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.var5.var5s.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }
                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.var6 &&
                                            <p id={"21_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.var6.predicate==="MUST_BE" || this.props.state.dataToSend.path.rule[this.props.index].conditions.var6.predicate==="CONTAIN"  ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.var6.var6s.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var6.var6s[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var6.var6s[0].name+ "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.var6.var6s.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }
                                    
                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.var7 &&
                                            <p id={"22_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.var7.predicate==="MUST_BE" || this.props.state.dataToSend.path.rule[this.props.index].conditions.var7.predicate==="CONTAIN"  ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.var7.var7s.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var7.var7s[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var7.var7s[0].name+ "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.var7.var7s.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }
                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.var8 &&
                                            <p id={"23_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.var8.predicate==="MUST_BE" || this.props.state.dataToSend.path.rule[this.props.index].conditions.var8.predicate==="CONTAIN"  ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.var8.var8s.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var8.var8s[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var8.var8s[0].name+ "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.var8.var8s.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }
                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.var9 &&
                                            <p id={"24_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.var9.predicate==="MUST_BE" || this.props.state.dataToSend.path.rule[this.props.index].conditions.var9.predicate==="CONTAIN"  ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.var9.var9s.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var9.var9s[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var9.var9s[0].name+ "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.var9.var9s.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }
                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.var10 &&
                                            <p id={"25_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.var10.predicate==="MUST_BE" || this.props.state.dataToSend.path.rule[this.props.index].conditions.var10.predicate==="CONTAIN"  ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.var10.var10s.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var10.var10s[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var10.var10s[0].name+ "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.var10.var10s.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }


                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.var11 &&
                                            <p id={"26_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.var11.predicate==="MUST_BE" || this.props.state.dataToSend.path.rule[this.props.index].conditions.var11.predicate==="CONTAIN"  ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.var11.var11s.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var11.var11s[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var11.var11s[0].name+ "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.var11.var11s.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }
                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.var12 &&
                                            <p id={"27_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.var12.predicate==="MUST_BE" || this.props.state.dataToSend.path.rule[this.props.index].conditions.var12.predicate==="CONTAIN"  ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.var12.var12s.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var12.var12s[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var12.var12s[0].name+ "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.var12.var12s.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }
                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.var13 &&
                                            <p id={"28_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.var13.predicate==="MUST_BE" || this.props.state.dataToSend.path.rule[this.props.index].conditions.var13.predicate==="CONTAIN"  ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.var13.var13s.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var13.var13s[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var13.var13s[0].name+ "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.var13.var13s.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }
                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.var14 &&
                                            <p id={"29_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.var14.predicate==="MUST_BE" || this.props.state.dataToSend.path.rule[this.props.index].conditions.var14.predicate==="CONTAIN"  ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.var14.var14s.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var14.var14s[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var14.var14s[0].name+ "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.var14.var14s.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }
                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.var15 &&
                                            <p id={"30_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.var15.predicate==="MUST_BE" || this.props.state.dataToSend.path.rule[this.props.index].conditions.var15.predicate==="CONTAIN"  ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.var15.var15s.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var15.var15s[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var15.var15s[0].name+ "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.var15.var15s.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }
                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.var16 &&
                                            <p id={"31_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.var16.predicate==="MUST_BE" || this.props.state.dataToSend.path.rule[this.props.index].conditions.var16.predicate==="CONTAIN"  ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.var16.var16s.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var16.var16s[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var16.var16s[0].name+ "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.var16.var16s.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }
                                    
                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.var17 &&
                                            <p id={"32_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.var17.predicate==="MUST_BE" || this.props.state.dataToSend.path.rule[this.props.index].conditions.var17.predicate==="CONTAIN"  ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.var17.var17s.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var17.var17s[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var17.var17s[0].name+ "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.var17.var17s.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }
                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.var18 &&
                                            <p id={"33_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.var18.predicate==="MUST_BE" || this.props.state.dataToSend.path.rule[this.props.index].conditions.var18.predicate==="CONTAIN"  ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.var18.var18s.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var18.var18s[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var18.var18s[0].name+ "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.var18.var18s.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }
                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.var19 &&
                                            <p id={"34_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.var19.predicate==="MUST_BE" || this.props.state.dataToSend.path.rule[this.props.index].conditions.var19.predicate==="CONTAIN"  ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.var19.var19s.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var19.var19s[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var19.var19s[0].name+ "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.var19.var19s.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }
                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.var20 &&
                                            <p id={"35_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.var20.predicate==="MUST_BE" || this.props.state.dataToSend.path.rule[this.props.index].conditions.var20.predicate==="CONTAIN"  ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.var20.var20s.length===1?                           
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var20.var20s[0].name : 
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.var20.var20s[0].name+ "(+" + 
                                                (Number(this.props.state.dataToSend.path.rule[this.props.index].conditions.var20.var20s.length)-1) + ")"}
                                                {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>}
                                            </p>
                                    }

                                    {this.props.defaultValue.name.length===0 && !this.props.state.deleted &&
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length>0 &&
                                            this.props.state.dataToSend.path.rule[this.props.index].conditions.timeofday &&
                                            <p id={"36_" + this.props.index}  className={this.props.state.dataToSend.path.rule[this.props.index].conditions.timeofday.predicate==="MUST_BE" ? "rule-name-green": "rule-name-red"}
                                            > 
                                                {this.props.state.dataToSend.path.rule[this.props.index].conditions.timeofday.timezone.length>0 &&
                                                this.props.state.dataToSend.path.rule[this.props.index].conditions.timeofday.timezone[0] +  
                                                " - " + this.props.state.dataToSend.path.rule[this.props.index].conditions.timeofday.timezone[1]}
                                                {/* {this.props.state.conditionsName[this.props.index].length>1 && <span id={this.props.type + "_" + this.props.index} className="conditions-sperator">{"&"}</span>} */}
                                            </p>
                                    }
                                    
                                    {
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length===0 && this.props.defaultValue.name.length===0 &&
                                        <p>
                                            New rule
                                        </p>
                                    }
                                    {
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length!==0 && this.props.defaultValue.name.length>0 &&
                                        <p>
                                            {this.props.defaultValue.name}
                                        </p>
                                    }
                                    {
                                        Object.keys(this.props.state.dataToSend.path.rule[this.props.index].conditions).length===0 && this.props.defaultValue.name.length>0 &&
                                        <p>
                                            {this.props.defaultValue.name}
                                        </p>
                                    }

                                    </div>
                                    <div className="notes-arrow-up-down" id={this.props.type + "_" + this.props.index} onClick={this.selected}>
                                        <span
                                            className={this.state.show ? "k-icon k-i-arrow-chevron-up" : "k-icon k-i-arrow-chevron-down"}
                                            onClick={this.onClick}
                                        ></span>
                                    </div>

                                    <div
                                        className="name row rule-action-btns"
                                        style={{ marginLeft: "auto", padding: "0px", marginTop: "0px" }}
                                    >
                                        {this.state.divShow ? (
                                            <>
                                                <div class="row">
                                                    <div className="rule-right-btns" style={{ paddingRight: "20px" }}>
                                                        {this.props.state.dataToSend.path.rule.length > 1 &&
                                                            <>{this.props.index!==0 && 
                                                                <button
                                                                    className="copy transparent-btn"
                                                                    onClick={this.onSort}
                                                                    style={{ padding: "4px", }}
                                                                    id={this.props.type + "_" + this.props.index + "_up"}
                                                                >
                                                                    <span className={"k-icon k-i-sort-asc-sm"} />
                                                                </button>
                                                                }
                                                                {this.props.index+1  !== this.props.state.dataToSend.path.rule.length &&
                                                                <button
                                                                    className="copy transparent-btn"
                                                                    onClick={this.onSort}
                                                                    style={{ padding: "4px", }}
                                                                    id={this.props.type + "_" + this.props.index + "_down"}
                                                                >
                                                                    <span className={"k-icon k-i-sort-desc-sm"} />
                                                                </button>
                                                                }
                                                            </>
                                                        }
                                                        <button
                                                            className="copy transparent-btn"
                                                            onClick={this.onDuplicateClick}
                                                            style={{ padding: "4px", }}
                                                            id={this.props.type + "_" + this.props.index}
                                                        >
                                                            <span className={"k-icon k-i-copy"} />
                                                        </button>
                                                        <button
                                                            onClick={this.onDeleteClick}
                                                            className="remove transparent-btn"
                                                            style={{ padding: "4px" }}
                                                            id={this.props.type + "_" + this.props.index}
                                                        >
                                                            <span className={"k-icon k-i-delete"} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (null

                                        )}
                                    </div>
                                </div>
                            </dt>
                        </dl>
                        <Reveal direction={direction}>{children}</Reveal>
                    </div>
                ) : null}
            </div>
        );
    }
}
