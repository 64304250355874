import { MOVE_TO_WORKSPACE, MOVE_TO_WORKSPACE_SUCCESS, MOVE_TO_WORKSPACE_FAILED } from "./constants";

export const moveToWorkspace = (payload) => {
    return {
        type: MOVE_TO_WORKSPACE,
        payload: payload
    };
};

export const moveToWorkspaceSuccess = (payload) => {
    return {
        type: MOVE_TO_WORKSPACE_SUCCESS,
        payload: payload
    };
};

export const moveToWorkspaceFailed = (error) => {
    return {
        type: MOVE_TO_WORKSPACE_FAILED,
        payload: error
    };
};