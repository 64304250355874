import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Layout from "../../../layout";
import PageMenuBarTop from "../../PageMenuBarTop";
import PageMenuBarBottom from "./PageMenuBarBottom";
import "../pages.css";
import "./flows.css";
import { createStructuredSelector } from "reselect";
import { makeSelectToken } from "../../../registration/LoginRegister/selectors";
import { Fade } from "@progress/kendo-react-animation";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import FlowsModal from "./PageModal/index";
import * as newFlowsActions from "./actions";
import * as offerActions from "./../Offers/actions";
import * as landerActions from "./../Landers/actions";
import * as colloborationWorkspaceActions from "../../Settings/CollaborationTools/actions";
import { makeSelectWorkspaceData } from "../../Settings/CollaborationTools/selectors";
import {
  makeSelectAllChartsDataLoaded,
  makeSelectIsChartsDataLoaded,
  makeSelectCountriesData,
  makeSelectLoadFlowsData,
  makeSelectEditDataFlow,
  makeSelectBrandData,
  makeSelectBrowserData,
  makeSelectCityData,
  makeSelectConnectionTypeData,
  makeSelectDeviceData,
  makeSelectLanguageData,
  makeSelectOperatingSystemData,
  makeSelectProxyData,
  makeSelectRegionData,
  makeSelectISPData,
  makeSelectConditionCountryData,
  makeSelectWeekDayData,
  makeSelectArchiveFlow,
  makeSelectFlowsAdd,
  makeSelectUpdateFlow,
  makeSelectDeleteFlow,
  makeSelectFlowError,
  makeSelectArchiveDeleteResponseFlows,
} from "./selectors";

//import EditFlowModal from './PageMenuBarBottom/editModal/editFlowModal';
import produce from "immer";
import DeletePageModal from "./DeletePageModal/index";
import EditFlowModal from "./EditPageModal/index";
import { makeSelectAllLandersDataLoaded } from "../Landers/selectors";
import { makeSelectOffersDataLoaded } from "../Offers/selectors";
import GridClassComponent from "./../../grid/grid";
import MenuCharts from "./../../charts/index";
import { makeSelectMoveToWorkspace } from "../../../moveToWorkspaceRedux/selectors";
import { moveToWorkspace } from "../../../moveToWorkspaceRedux/actions";
import LoadingPanel from "../../grid/loader/loader";

class NewFlows extends Component {
  state = {
    visibleFlowsDialog: false,
    visibleEditFlowsDialog: false,
    visibleDeleteFlowsDialog: false,
    selectedFlows: null,
    flowsDialogueNextBtnClicked: false,
    loadedWorkspaceData: null,
    loadedFlowsData: null,
    allFlowsDataLoaded: null,
    editFlowsId: null,
    success_msg: "",
    isDataGettingLoaded: true,
    isFlowsDeleted: false,
    archivedSuccessfully: false,
    isNewFlowsAdded: false,
    isFlowsPatched: false,
    isCutomButtonClicked: false,
    loadFlowsData: false,
    deletedFlowData: false,
    deleteId: null,
    editId: null,
    loadLandersFirstTime: true,
    loadOffersFirstTime: true,
    errors: {
      emptyOffers: "",
      emptyLanders: "",
    },
    paginationSize: 10,
    selectedListReceivedFromGrid: [],
    isDuplicate: false,
    isArchive: false,
    archiveType: "",
    charts: {
      showCharts: false,
      chartsData: null,
      isChartsDataLoaded: false,
    },
    pageContentHeight: "500",
    groupingValues: {
      main: "",
      group1: "",
      group2: "",
    },
    showCalendar: false,
    editFlowOpenLoading: false,
    showColumnsModal: false,
    sorting: {
      order: "visits",
      sort: "ASC",
    },
    loader: false,
    pagination: {
      skip: 0,
      take: 50,
      page: 1,
      total: 0,
      totalPages: 1,
      blur: {
        blurFirst: true,
        blurLast: true,
        blurPrev: true,
        blurNext: true,
      },
    },
    filterStatus: "all",
    backendErrors: {
      error: false,
    },
    disableReport: false,
    reportCount: 0,
    archiveDeleteResponseFlows: null,
    showNoRecordFound: false,
  };

  fetchFlows = () => {
    this.setState(
      produce((draft) => {
        draft.loader = true;
        draft.selectedListReceivedFromGrid = [];
      })
    );
    let search = JSON.parse(localStorage.getItem("search"));
    this.setState(
      produce((draft) => {
        draft.groupingValues = search.groupingValues;
      })
    );
    return this.props.dispatchLoadFlows(
      this.props.accessToken,
      JSON.parse(localStorage.getItem("search")),
      this.state.sorting,
      true
    );
  };
  fetchWorkspaces = () => {
    return this.props.fetchingData(this.props.accessToken);
  };
  fetchOffers = () => {
    return this.props.loadAllOffers(
      this.props.accessToken,
      JSON.parse(localStorage.getItem("search")),
      { order: "id", sort: "ASC" },
      false
    );
  };
  fetchChartsData = () => {
    return this.props.dispatchLoadChartsData({
      accessToken: this.props.accessToken,
      dates: JSON.parse(localStorage.getItem("search")).modifiedDate,
    });
  };

  componentDidMount() {
    // check if main group value is not flow value...
    // than set it to flow value to avoid any errors.
    if (
      JSON.parse(localStorage.getItem("search")).groupingValues.main !== "Flows"
    ) {
      let search = JSON.parse(localStorage.getItem("search"));
      search.groupingValues.main = "Flows";
      localStorage.setItem("search", JSON.stringify(search));
    }
    // set initial grouping values in state to be passed on to the grid...
    // so that is does not show error grouping values of none.
    if (JSON.parse(localStorage.getItem("search"))) {
      this.setState(
        produce((draft) => {
          draft.groupingValues.main = JSON.parse(
            localStorage.getItem("search")
          ).groupingValues.main;
          draft.groupingValues.group1 = JSON.parse(
            localStorage.getItem("search")
          ).groupingValues.group1;
          draft.groupingValues.group2 = JSON.parse(
            localStorage.getItem("search")
          ).groupingValues.group2;
          draft.reportCount = JSON.parse(
            localStorage.getItem("search")
          ).report.reportCount;
          draft.filterStatus = JSON.parse(
            localStorage.getItem("search")
          ).filterStatus;
        })
      );
    }

    this.fetchFlows();

    if (this.props.landersDataReceived === null) {
      this.props.loadAllLanders({
        accessToken: this.props.accessToken,
        searchQuery: JSON.parse(localStorage.getItem("search")),
        sorting: { order: "name", sort: "DESC" },
        primaryPage: false,
        dataType: "short",
      });
    }
    if (this.props.offersDataReceived === null) {
      this.props.loadAllOffers(
        this.props.accessToken,
        JSON.parse(localStorage.getItem("search")),
        { order: "name", sort: "DESC" },
        false,
        "short"
      );
    }

    // if (this.props.landersDataReceived === null || this.props.landersDataReceived.data.length ===0) {
    // }

    // if (this.props.offersDataReceived === null || this.props.offersDataReceived.data.length ===0) {
    // }
    // code for setting grid height initially
    let pageMenuHeight = Number(
      document.getElementById("page_menu").clientHeight
    );
    let pageFullHeight = Number(
      document.getElementsByTagName("html")[0].clientHeight
    );
    this.setState(
      produce((draft) => {
        draft.pageContentHeight = pageFullHeight - pageMenuHeight - 127;
      })
    );

    if (this.props.browserDataReceived === null) {
      this.props.fetchBrowserData(this.props.accessToken);
    }
    if (this.props.brandDataReceived === null) {
      this.props.fetchBrandData(this.props.accessToken);
    }
    if (this.props.languageDataReceived === null) {
      this.props.fetchLanguageData(this.props.accessToken);
    }
    if (this.props.proxyDataReceived === null) {
      this.props.fetchProxyData(this.props.accessToken);
    }
    if (this.props.regionDataReceived === null) {
      this.props.fetchRegionData(this.props.accessToken);
    }
    if (this.props.cityDataReceived === null) {
      this.props.fetchCityData(this.props.accessToken);
    }
    if (this.props.conditionCountryDaraReceived === null) {
      this.props.fetchCountryData(this.props.accessToken);
    }
    if (this.props.connectionTypeDataReceived === null) {
      this.props.fetchConnectionTypeData(this.props.accessToken);
    }
    if (this.props.deviceTypeDataReceived === null) {
      this.props.fetchDeviceTypeData(this.props.accessToken);
    }
    if (this.props.osDataReceived === null) {
      this.props.fetchOperatingSystemData(this.props.accessToken);
    }
    if (this.props.ispDataReceived === null) {
      this.props.fetchISPData(this.props.accessToken);
    }
    if (this.props.weekdayDataReceived === null) {
      this.props.fetchWeekDayData(this.props.accessToken);
    }
    if (this.props.workspaceDataReceived === null) {
      this.fetchWorkspaces();
      this.setState(
        produce((draft) => {
          draft.loadedWorkspaceData = true;
        })
      );
    }

    if (this.props.countriesDataRecieved === null) {
      this.props.dispatchLoadCountries(this.props.accessToken);
    }
    if (this.props.workspaceDataReceived !== null) {
      this.setState(
        produce((draft) => {
          draft.loadedWorkspaceData = true;
        })
      );
    }
    if (this.props.flowsDataRecieved === null) {
      this.fetchFlows();
      this.setState(
        produce((draft) => {
          draft.loadedFlowsData = true;
        })
      );
    }
    if (this.props.flowsDataRecieved !== null) {
      this.setState(
        produce((draft) => {
          draft.loadedFlowsData = true;
        })
      );
    }
    this.setState({ showNoRecordFound: true });
  }

  componentDidUpdate(prevProps) {
    // Display message on archive
    if (this.props.archiveFlow && this.state.archivedSuccessfully) {
      this.setState(
        produce((draft) => {
          draft.success_msg = "Flow archived successfully";
          draft.archivedSuccessfully = false;
        })
      );

      setTimeout(() => {
        this.setState(
          produce((draft) => {
            draft.success_msg = "";
          })
        );
      }, 5000);
    }
    if (prevProps.chartsAllDataLoaded !== this.props.chartsAllDataLoaded) {
      this.setState(
        produce((draft) => {
          draft.charts.chartsData = this.props.chartsAllDataLoaded;
        })
      );
    }
    if (
      prevProps.flowsDataRecieved !== this.props.flowsDataRecieved &&
      this.props.flowsDataRecieved !== null
    ) {
      // console.log('fetching flows data');
      this.setState(
        produce((draft) => {
          draft.allFlowsDataLoaded = this.props.flowsDataRecieved;
          if (this.props.flowsDataRecieved.total) {
            draft.pagination.total =
              this.props.flowsDataRecieved.total.totalRows;
          }
          draft.loader = false;
        })
      );
      this.setTotalPagination();
      setTimeout(() => {
        this.setPaginationUI();
      }, 50);
    }
    if (
      prevProps.isMovedToWorkspace !== this.props.isMovedToWorkspace &&
      this.props.isMovedToWorkspace === true
    ) {
      this.fetchFlows();
      // this.setState(produce((draft) => {draft.loader = false;}));
    }

    //flow archived successfully
    if (
      prevProps.flowArchived !== this.props.flowArchived &&
      this.props.flowArchived === true &&
      this.props.error === false
    ) {
      // console.log("Flow archived successfully...");
      this.setState(
        produce((draft) => {
          draft.archivedSuccessfully = true;
          draft.visibleDeleteFlowsDialog = !this.state.visibleDeleteFlowsDialog;
        })
      );
      this.fetchFlows();
      // this.setState(produce((draft) => { draft.loader = false; }));
    }

    if (
      prevProps.flowDeleted !== this.props.flowDeleted &&
      this.props.flowDeleted === true &&
      this.props.error === false
    ) {
      this.setState(
        produce((draft) => {
          draft.success_msg = "Flow Deleted Successfully!";
        })
      );
      this.setState(
        produce((draft) => {
          draft.deletedFlowData = true;
        })
      );
      this.setState(
        produce((draft) => {
          draft.selectedListReceivedFromGrid = [];
        })
      );
      this.fetchFlows();
      // this.setState(produce((draft) => { draft.loader = false; }));
      this.setState(
        produce((draft) => {
          draft.visibleDeleteFlowsDialog = !this.state.visibleDeleteFlowsDialog;
        })
      );
    }
    //flows error or failed request
    if (prevProps.error !== this.props.error && this.props.error !== false) {
      if (Object.keys(this.props.error).length > 0) {
        this.setState(
          produce((draft) => {
            draft.backendErrors.error = true;
          })
        );
        this.setState(
          produce((draft) => {
            draft.loader = false;
          })
        );
      }
    }

    setTimeout(() => {
      if (this.state.loadFlowsData === true) {
        // console.log('fetch flow after add');
        this.setState(
          produce((draft) => {
            draft.success_msg = "";
          })
        );
        this.fetchFlows();
        this.setState(
          produce((draft) => {
            draft.loadFlowsData = false;
          })
        );
        // this.setState(produce((draft) => {draft.loader = false;}));
      }
    }, 1000);
    if (this.state.deletedFlowData === true) {
      setTimeout(() => {
        this.setState(
          produce((draft) => {
            draft.success_msg = "";
          })
        );
        this.setState(
          produce((draft) => {
            draft.deletedFlowData = false;
          })
        );
      }, 1000);
    }

    // check for archive or delete or restore message from backend
    if (
      prevProps.archiveDeleteResponseFlows !==
        this.props.archiveDeleteResponseFlows &&
      this.props.archiveDeleteResponseFlows !== null
    ) {
      this.setState(
        produce((draft) => {
          draft.archiveDeleteResponseFlows =
            this.props.archiveDeleteResponseFlows;
        })
      );
      setTimeout(() => {
        this.setState(
          produce((draft) => {
            draft.archiveDeleteResponseFlows = null;
          })
        );
      }, 10000);
    }
  }

  submitData = (edited) => {
    // console.log('submit data');
    this.setState(
      produce((draft) => {
        draft.loadFlowsData = true;
        draft.loader = true;
      })
    );
    // this.fetchFlows();
    this.setState(
      produce((draft) => {
        draft.success_msg = "Flow Added Successfully!";
      })
    );
    if (edited) {
      this.setState(
        produce((draft) => {
          draft.loadFlowsData = true;
        })
      );
      this.setState(
        produce((draft) => {
          draft.success_msg = "Flow Updated Successfully!";
        })
      );
    }
    setTimeout(() => {
      // this.setState(produce((draft) => {draft.loader = false;}));
    }, 1000);
  };

  toggleAddFlowsDialog = () => {
    this.setState(
      produce((draft) => {
        draft.visibleFlowsDialog = !this.state.visibleFlowsDialog;
      })
    );
  };

  openDeleteFlowDialogue = () => {
    this.setState(
      produce((draft) => {
        draft.loader = true;
      })
    );
    setTimeout(() => {
      this.setState(
        produce((draft) => {
          draft.visibleDeleteFlowsDialog = !this.state.visibleDeleteFlowsDialog;
        })
      );
      this.setState(
        produce((draft) => {
          draft.loader = false;
        })
      );
    }, 100);
  };
  closeDeleteFlowsDialog = () => {
    this.setState(
      produce((draft) => {
        draft.visibleDeleteFlowsDialog = !this.state.visibleDeleteFlowsDialog;
      })
    );
  };
  closeEditFlowDialog = () => {
    this.setState(
      produce((draft) => {
        draft.visibleEditFlowsDialog = !this.state.visibleEditFlowsDialog;
      })
    );
  };
  deleteFlowsOnSubmit = () => {
    this.setState(
      produce((draft) => {
        draft.loader = true;
      })
    );
    let ids = [];
    for (let i = 0; i < this.state.selectedListReceivedFromGrid.length; i++) {
      ids.push(this.state.selectedListReceivedFromGrid[i].id);
    }
    this.props.deleteFlows(this.props.accessToken, ids);
  };

  openEditFlowDialogue = (data) => {
    this.setState(
      produce((draft) => {
        draft.loader = true;
      })
    );
    if (data === "Edit") {
      this.setState(
        produce((draft) => {
          draft.isDuplicate = false;
        })
      );
    } else if (data === "Duplicate") {
      this.setState(
        produce((draft) => {
          draft.isDuplicate = true;
        })
      );
    }
    this.setState(
      produce((draft) => {
        draft.editId = this.state.selectedListReceivedFromGrid[0].id;
      })
    );
    this.props.editFlows(
      this.props.accessToken,
      this.state.selectedListReceivedFromGrid[0].id
    );
    setTimeout(() => {
      this.setState(
        produce((draft) => {
          draft.visibleEditFlowsDialog = !this.state.visibleEditFlowsDialog;
        })
      );
      // this.setState(produce((draft) => {draft.editFlowOpenLoading = !this.state.editFlowOpenLoading;}));
    }, 2000);
  };

  sendSearchRequest = () => {
    let search = JSON.parse(localStorage.getItem("search"));
    search.pagination.skip = 0;
    if (
      search.groupingValues.main === "conversion" ||
      search.groupingValues.main === "months" ||
      search.groupingValues.main === "days" ||
      search.groupingValues.main === "hours"
    ) {
      this.setState(
        produce((draft) => {
          draft.disableReport = true;
        })
      );
    } else {
      this.setState(
        produce((draft) => {
          draft.disableReport = false;
        })
      );
    }
    localStorage.setItem("search", JSON.stringify(search));
    this.setState(
      produce((draft) => {
        draft.pagination.page = 1;
      })
    );
    this.setPaginationUI();
    this.fetchFlows();
    this.fetchChartsData();
    this.setState(
      produce((draft) => {
        draft.isAllLandersLoaded = false;
        draft.showCalendar = false;
      })
    );
  };

  showCharts = () => {
    if (this.state.charts.showCharts !== true) {
      this.fetchChartsData();
    }
    this.setState(
      produce((draft) => {
        draft.charts.showCharts = !this.state.charts.showCharts;
        draft.showCalendar = false;
        draft.charts.isChartsDataLoaded = !this.state.charts.isChartsDataLoaded;
      })
    );
  };

  toggleDeleteArchiveModal = (data) => {
    this.setState(
      produce((draft) => {
        draft.loader = true;
      })
    );
    this.setState(
      produce((draft) => {
        draft.visibleDeleteFlowsDialog = !this.state.visibleDeleteFlowsDialog;
        if (data === "Archive") {
          draft.archiveType = "Archive";
        } else if (data === "Delete") {
          draft.archiveType = "Delete";
        } else if (data === "Restore") {
          draft.archiveType = "Restore";
        } else if (data === "Close") {
          draft.archiveType = "";
        }
      })
    );
    this.setState(
      produce((draft) => {
        draft.loader = false;
      })
    );
  };

  archiveFlowHandler = (data) => {
    this.setState(
      produce((draft) => {
        draft.loader = true;
      })
    );
    let ids = [];
    for (let i = 0; i < this.state.selectedListReceivedFromGrid.length; i++) {
      ids.push(this.state.selectedListReceivedFromGrid[i].id);
    }
    this.props.archiveFlows({
      accessToken: this.props.accessToken,
      flowId: ids,
      value: data === "Archive" ? false : true,
    });
  };

  gridCheckedList = (list) => {
    this.setState(
      produce((draft) => {
        draft.selectedListReceivedFromGrid = list;
      })
    );
  };

  toggleCalendarWindow = () => {
    this.setState(
      produce((draft) => {
        draft.showCalendar = !this.state.showCalendar;
      })
    );
  };
  moveToWorkspaceMethod = (workspaceId) => {
    this.setState(
      produce((draft) => {
        draft.loader = true;
      })
    );
    let selectedList = [...this.state.selectedListReceivedFromGrid];
    let idStringList = [];
    for (let i = 0; i < this.state.selectedListReceivedFromGrid.length; i++) {
      idStringList.push(selectedList[i].id);
    }
    return this.props.dispatchMoveToWorkspace({
      accessToken: this.props.accessToken,
      query: {
        type: "flow",
        typeIdStringList: idStringList.join(","),
        workspaceId: workspaceId,
      },
    });
  };

  toggleColumnsModal = () => {
    this.setState(
      produce((draft) => {
        draft.showColumnsModal = !this.state.showColumnsModal;
      })
    );
  };

  changeSorting = (data) => {
    let sorting = { ...this.state.sorting };
    if (String(sorting.order) === String(data) && sorting.sort === "ASC") {
      this.setState(
        produce((draft) => {
          draft.sorting.sort = "DESC";
        })
      );
    } else if (
      String(sorting.order) === String(data) &&
      sorting.sort === "DESC"
    ) {
      this.setState(
        produce((draft) => {
          draft.sorting.sort = "ASC";
        })
      );
    } else if (String(sorting.order) !== String(data)) {
      this.setState(
        produce((draft) => {
          draft.sorting.order = data;
          draft.sorting.sort = "ASC";
        })
      );
    }
    setTimeout(() => {
      this.fetchFlows();
    }, 50);
  };

  setTotalPagination = () => {
    if (this.props.flowsDataRecieved.total) {
      if (Number(this.props.flowsDataRecieved.total.totalRows) > 0) {
        let totalRows = Number(this.props.flowsDataRecieved.total.totalRows);
        if (totalRows <= this.state.pagination.take) {
          this.setState(
            produce((draft) => {
              draft.pagination.totalPages = 1;
            })
          );
        } else {
          if (totalRows % Number(this.state.pagination.take) > 0) {
            this.setState(
              produce((draft) => {
                draft.pagination.totalPages =
                  Math.floor(totalRows / this.state.pagination.take) + 1;
              })
            );
          } else {
            this.setState(
              produce((draft) => {
                draft.pagination.totalPages =
                  totalRows / this.state.pagination.take;
              })
            );
          }
        }
      } else {
        this.setState(
          produce((draft) => {
            draft.pagination.totalPages = 1;
          })
        );
      }
    }
  };
  setPaginationUI = () => {
    if (Number(this.state.pagination.totalPages) === 1) {
      this.setState(
        produce((draft) => {
          draft.pagination.blur.blurFirst = true;
          draft.pagination.blur.blurPrev = true;
          draft.pagination.blur.blurNext = true;
          draft.pagination.blur.blurLast = true;
        })
      );
    } else if (
      this.state.pagination.page === 1 &&
      this.state.pagination.totalPages > 1
    ) {
      this.setState(
        produce((draft) => {
          draft.pagination.blur.blurFirst = true;
          draft.pagination.blur.blurPrev = true;
          draft.pagination.blur.blurNext = false;
          draft.pagination.blur.blurLast = false;
        })
      );
    } else if (
      this.state.pagination.page !== 1 &&
      this.state.pagination.totalPages > 1 &&
      this.state.pagination.page < this.state.pagination.totalPages
    ) {
      this.setState(
        produce((draft) => {
          draft.pagination.blur.blurFirst = false;
          draft.pagination.blur.blurPrev = false;
          draft.pagination.blur.blurNext = false;
          draft.pagination.blur.blurLast = false;
        })
      );
    } else if (
      this.state.pagination.page !== 1 &&
      this.state.pagination.totalPages > 1 &&
      this.state.pagination.page === this.state.pagination.totalPages
    ) {
      this.setState(
        produce((draft) => {
          draft.pagination.blur.blurFirst = false;
          draft.pagination.blur.blurPrev = false;
          draft.pagination.blur.blurNext = true;
          draft.pagination.blur.blurLast = true;
        })
      );
    }
  };

  changePagination = (data) => {
    let search = JSON.parse(localStorage.getItem("search"));
    if (data === "first") {
      this.setState(
        produce((draft) => {
          draft.pagination.skip = 0;
          draft.pagination.page = 1;
        })
      );
      search.pagination.skip = 0;
    } else if (data === "prev") {
      let dataToSkip = this.state.pagination.skip - this.state.pagination.take;
      this.setState(
        produce((draft) => {
          draft.pagination.skip = dataToSkip;
          draft.pagination.page -= 1;
        })
      );
      search.pagination.skip = dataToSkip;
    } else if (data === "next") {
      let dataToSkip = this.state.pagination.skip + this.state.pagination.take;
      this.setState(
        produce((draft) => {
          draft.pagination.skip = dataToSkip;
          draft.pagination.page += 1;
        })
      );
      search.pagination.skip = dataToSkip;
    } else if (data === "last") {
      let dataToSkip =
        (this.state.pagination.totalPages - 1) * this.state.pagination.take;
      this.setState(
        produce((draft) => {
          draft.pagination.skip = dataToSkip;
          draft.pagination.page = this.state.pagination.totalPages;
        })
      );
      search.pagination.skip = dataToSkip;
    }

    localStorage.setItem("search", JSON.stringify(search));
    setTimeout(() => {
      this.fetchFlows();
    }, 50);
    this.setPaginationUI();
  };

  changeGridRowsNumber = (e) => {
    let search = JSON.parse(localStorage.getItem("search"));
    search.pagination.take = Number(e.target.value);
    search.pagination.skip = 0;
    this.setState(
      produce((draft) => {
        draft.pagination.skip = 0;
        draft.pagination.page = 1;
        draft.pagination.take = Number(e.target.value);
      })
    );
    localStorage.setItem("search", JSON.stringify(search));
    setTimeout(() => {
      this.fetchFlows();
    }, 50);
    this.setPaginationUI();
  };

  changeFilterStatusType = (e) => {
    let search = JSON.parse(localStorage.getItem("search"));
    search.filterStatus = e.target.value;
    this.setState(
      produce((draft) => {
        draft.filterStatus = e.target.value;
      })
    );
    localStorage.setItem("search", JSON.stringify(search));
    setTimeout(() => {
      this.fetchFlows();
    }, 50);
  };

  toggleLoader = () => {
    this.setState(
      produce((draft) => {
        draft.loader = !this.state.loader;
      })
    );
  };
  removeLoader = () => {
    this.setState(
      produce((draft) => {
        draft.loader = false;
      })
    );
  };

  openNewReport = (data) => {
    let search = JSON.parse(localStorage.getItem("search"));
    if (data === "minus" && this.state.reportCount > 0) {
      search.report.reportCount = this.state.reportCount - 1;
      this.setState(
        produce((draft) => {
          draft.reportCount = this.state.reportCount - 1;
        })
      );
      localStorage.setItem("search", JSON.stringify(search));
    } else if (data === "plus") {
      let count = this.state.reportCount + 1;
      search.report["report" + count].name =
        this.state.selectedListReceivedFromGrid[0].name;
      search.report.reportCount = count;
      search.activeTab = count;
      this.setState(
        produce((draft) => {
          draft.reportCount = this.state.reportCount + 1;
        })
      );
      let startDate = search.modifiedDate.startDate;
      let endDate = search.modifiedDate.endDate;
      localStorage.setItem("search", JSON.stringify(search));
      setTimeout(() => {
        this.props.history.push({
          pathname: "/report",
          search:
            "id=" +
            this.state.selectedListReceivedFromGrid[0].id +
            "&reportType=" +
            search.groupingValues.main +
            "&startDate=" +
            startDate +
            "&endDate=" +
            endDate +
            "&new=true",
          hash: "",
        });
      }, 80);
    }
  };

  decreaseReportCount = () => {
    this.setState(
      produce((draft) => {
        draft.reportCount = Number(this.state.reportCount) - 1;
      })
    );
  };

  render() {
    return (
      <Fragment>
        <Layout
          reportCount={this.state.reportCount}
          decreaseReportCount={this.decreaseReportCount}
        >
          {this.state.backendErrors.error && (
            <NotificationGroup
              style={{
                alignItems: "flex-start",
                flexWrap: "wrap-reverse",
                left: "50%",
                zIndex: 10000000,
                top: "20px",
                transform: "translateX(-50%)",
              }}
            >
              <Fade enter={true} exit={true}>
                {this.state.backendErrors.error && this.props.error && (
                  <Notification
                    type={{ style: "error", icon: true }}
                    closable={true}
                    onClose={() =>
                      this.setState(
                        produce((draft) => {
                          draft.backendErrors.error = false;
                        })
                      )
                    }
                  >
                    <span>
                      {this.props.error.message + ", " + this.props.error.error}
                    </span>
                  </Notification>
                )}
              </Fade>
              {/* <Fade enter={true} exit={true}>
                        {(this.state.success && this.state.success_msg!=="" ) && <Notification
                            type={{ style: 'success', icon: true }}
                            closable={true}
                                onClose={() => this.setState(produce(draft => { draft.success = false; }))}
                        >
                            <span>{ this.state.success_msg}</span>
                            </Notification>}
                            </Fade> */}
            </NotificationGroup>
          )}
          <div id="page_container" className="page_container">
            <div id="page_menu" className="page_menu_bar">
              <PageMenuBarTop
                sendSearchRequest={this.sendSearchRequest}
                showCharts={this.showCharts}
                toggleLoader={this.toggleLoader}
                showCalendar={this.state.showCalendar}
                toggleCalendarWindow={this.toggleCalendarWindow}
                pageType="Flows"
              />
              {this.state.charts.showCharts ? (
                <ResponsiveMenuCharts
                  data={this.state.charts.chartsData}
                  dates={
                    JSON.parse(localStorage.getItem("search")).modifiedDate
                  }
                />
              ) : null}
              <div>
                <PageMenuBarBottom
                  toggleAddFlowsDialog={this.toggleAddFlowsDialog}
                  loadedWorkspaces={this.props.workspaceDataReceived}
                  selectedListReceivedFromGrid={
                    this.state.selectedListReceivedFromGrid
                  }
                  // new code
                  toggleDeleteArchiveModal={this.toggleDeleteArchiveModal}
                  openEditFlowDialogue={this.openEditFlowDialogue}
                  moveToWorkspaceMethod={this.moveToWorkspaceMethod}
                  toggleColumnsModal={this.toggleColumnsModal}
                  pagination={this.state.pagination}
                  changePagination={this.changePagination}
                  changeGridRowsNumber={this.changeGridRowsNumber}
                  changeFilterStatusType={this.changeFilterStatusType}
                  filterStatus={this.state.filterStatus}
                  reportId={
                    this.state.selectedListReceivedFromGrid.length > 0
                      ? this.state.selectedListReceivedFromGrid[0].id
                      : ""
                  }
                  disable={this.state.disableReport}
                  openNewReport={this.openNewReport}
                  reportCount={this.state.reportCount}
                />
              </div>
              <div className="page_content"></div>
              <div className="page_modal">
                {this.state.visibleFlowsDialog ? (
                  <FlowsModal
                    visible={this.state.visibleFlowsDialog}
                    toggleAddFlowsDialog={this.toggleAddFlowsDialog}
                    accessToken={this.props.accessToken}
                    submitData={this.submitData}
                    state={this.state}
                    toggleLoader={this.toggleLoader}
                    removeLoader={this.removeLoader}
                  />
                ) : null}
              </div>
            </div>
            <div
              // style={{ height: this.state.pageContentHeight }}
              style={{ height: `calc(100% - 85px - 127px)` }}
              className="temporary_affiliate_network_page_data"
            >
              <GridClassComponent
                pageType="Flows"
                gridHeight={this.state.pageContentHeight}
                allDataLoaded={this.state.allFlowsDataLoaded}
                paginationSize={this.state.paginationSize}
                gridCheckedList={(list) => this.gridCheckedList(list)}
                groupingValues={this.state.groupingValues}
                showColumnsModal={this.state.showColumnsModal}
                toggleColumnsModal={this.toggleColumnsModal}
                sorting={this.state.sorting}
                changeSorting={this.changeSorting}
                toggleLoader={this.toggleLoader}
                selectedListFromPage={this.state.selectedListReceivedFromGrid}
                showNoRecordFound={this.state.showNoRecordFound}
              />
            </div>

            {this.state.visibleEditFlowsDialog && (
              <EditFlowModal
                state={this.state}
                closeEditFlowDialog={this.closeEditFlowDialog}
                editFlowOnSubmit={this.editFlowOnSubmit}
                editFlowsDataRecieved={this.props.editFlowsDataRecieved}
                accessToken={this.props.accessToken}
                submitData={this.submitData}
                toggleLoader={this.toggleLoader}
                removeLoader={this.removeLoader}
              />
            )}

            {(this.state.editFlowOpenLoading || this.state.loader) && (
              <LoadingPanel />
            )}

            {this.state.visibleDeleteFlowsDialog && (
              <DeletePageModal
                state={this.state}
                closeDeleteFlowsDialog={this.closeDeleteFlowsDialog}
                deleteFlowsOnSubmit={this.deleteFlowsOnSubmit}
                editFlowsDataRecieved={this.props.editFlowsDataRecieved}
                archiveFlowHandler={this.archiveFlowHandler}
              />
            )}
          </div>
          {this.state.archiveDeleteResponseFlows && (
            <NotificationGroup
              style={{
                alignItems: "flex-start",
                flexWrap: "wrap-reverse",
                left: "50%",
                zIndex: 10000000,
                top: "20px",
                transform: "translateX(-50%)",
              }}
            >
              <Fade enter={true} exit={true}>
                {this.state.archiveDeleteResponseFlows && (
                  <Notification
                    type={{ style: "warning", icon: true }}
                    closable={true}
                    // onClose={() => this.setState({ backendErrors.error: false })}
                    onClose={() =>
                      this.setState(
                        produce((draft) => {
                          draft.archiveDeleteResponseFlows = null;
                        })
                      )
                    }
                  >
                    <span>{this.state.archiveDeleteResponseFlows}</span>
                  </Notification>
                )}
              </Fade>
            </NotificationGroup>
          )}
        </Layout>
      </Fragment>
    );
  }
}

export const mapStateToProps = createStructuredSelector({
  workspaceDataReceived: makeSelectWorkspaceData(),
  flowsDataRecieved: makeSelectLoadFlowsData(),
  editFlowsDataRecieved: makeSelectEditDataFlow(),
  countriesDataRecieved: makeSelectCountriesData(),
  offersDataReceived: makeSelectOffersDataLoaded(),
  landersDataReceived: makeSelectAllLandersDataLoaded(),
  accessToken: makeSelectToken(),
  isChartsDataLoaded: makeSelectIsChartsDataLoaded(),
  chartsAllDataLoaded: makeSelectAllChartsDataLoaded(),
  isMovedToWorkspace: makeSelectMoveToWorkspace(),
  flowArchived: makeSelectArchiveFlow(),
  flowDeleted: makeSelectDeleteFlow(),
  flowUpdated: makeSelectUpdateFlow(),
  flowsAddedSuccessfully: makeSelectFlowsAdd(),
  error: makeSelectFlowError(),

  brandDataReceived: makeSelectBrandData(),
  browserDataReceived: makeSelectBrowserData(),
  cityDataReceived: makeSelectCityData(),
  conditionCountryDaraReceived: makeSelectConditionCountryData(),
  languageDataReceived: makeSelectLanguageData(),
  regionDataReceived: makeSelectRegionData(),
  connectionTypeDataReceived: makeSelectConnectionTypeData(),
  deviceTypeDataReceived: makeSelectDeviceData(),
  osDataReceived: makeSelectOperatingSystemData(),
  proxyDataReceived: makeSelectProxyData(),
  ispDataReceived: makeSelectISPData(),
  weekdayDataReceived: makeSelectWeekDayData(),
  archiveDeleteResponseFlows: makeSelectArchiveDeleteResponseFlows(),
});

export function mapDispatchToProps(dispatch) {
  return {
    fetchingData: (...payload) =>
      dispatch(colloborationWorkspaceActions.fetchingData(...payload)),
    dispatchLoadFlows: (...payload) =>
      dispatch(newFlowsActions.loadFlows(...payload)),
    deleteFlows: (...payload) =>
      dispatch(newFlowsActions.deleteFlows(...payload)),
    archiveFlows: (...payload) =>
      dispatch(newFlowsActions.archiveFlows(...payload)),
    editFlows: (...payload) => dispatch(newFlowsActions.editFlows(...payload)),
    dispatchLoadCountries: (...payload) =>
      dispatch(newFlowsActions.loadCountries(...payload)),
    dispatchLoadChartsData: (...payload) =>
      dispatch(newFlowsActions.loadFlowChartsData(...payload)),

    fetchBrandData: (...payload) =>
      dispatch(newFlowsActions.loadBrand(...payload)),
    fetchCityData: (...payload) =>
      dispatch(newFlowsActions.loadCity(...payload)),
    fetchCountryData: (...payload) =>
      dispatch(newFlowsActions.loadConditionCountries(...payload)),
    fetchBrowserData: (...payload) =>
      dispatch(newFlowsActions.loadBrowser(...payload)),
    fetchDeviceTypeData: (...payload) =>
      dispatch(newFlowsActions.loadDeviceType(...payload)),
    fetchConnectionTypeData: (...payload) =>
      dispatch(newFlowsActions.loadConnectionType(...payload)),
    fetchLanguageData: (...payload) =>
      dispatch(newFlowsActions.loadLanguage(...payload)),
    fetchProxyData: (...payload) =>
      dispatch(newFlowsActions.loadProxy(...payload)),
    fetchRegionData: (...payload) =>
      dispatch(newFlowsActions.loadRegion(...payload)),
    fetchOperatingSystemData: (...payload) =>
      dispatch(newFlowsActions.loadOperatingSystem(...payload)),
    fetchISPData: (...payload) => dispatch(newFlowsActions.loadISP(...payload)),
    fetchWeekDayData: (...payload) =>
      dispatch(newFlowsActions.loadWeekDay(...payload)),
    loadAllOffers: (...payload) =>
      dispatch(offerActions.loadOffers(...payload)),
    loadAllLanders: (...payload) =>
      dispatch(landerActions.loadAllLanders(...payload)),
    dispatchMoveToWorkspace: (...payload) =>
      dispatch(moveToWorkspace(...payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewFlows);
function ResponsiveMenuCharts(props) {
  const [dimensions, setDimensions] = React.useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  })
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    
}

    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)
    
}
  })
  return <>{//<div>{window.innerWidth}</div>
  }
  <MenuCharts data={props.data} dates={props.dates}/>
  </>

}