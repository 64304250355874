import React from "react";
import moment from "moment";
import "./charts.css";
import produce from "immer";
import { Line } from "@nivo/line";


let divWidth =window.innerWidth;
class MenuCharts extends React.Component {
  state = {
    categories: [],
    visits: [],
    clicks: [],
    conversions: [],
    impressions: [],
    cost: [],
    revenue: [],
    profit: [],
    data: [],
    width: 500,
    height: 400,
    dataToShow: {
      clicks: [],
      visits: [],
      conversions: [],
      cost: [],
      impressions: [],
      profit: [],
      revenue: [],
    },
    timeFormat: "%m/%d/%Y %H:%M",
    xFormat: "time:%m/%d/%Y %H:%M",
    axisFormat: "%d %b %H:%M",
    dataChart: [
      {
        id: "clicks",
        color: "hsl(152, 70%, 50%)",
        data: [],
      },
      {
        id: "conversions",
        color: "hsl(314, 70%, 50%)",
        data: [],
      },
      {
        id: "cost",
        color: "hsl(59, 70%, 50%)",
        data: [],
      },
      {
        id: "impressions",
        color: "hsl(74, 70%, 50%)",
        data: [],
      },
      {
        id: "profit",
        color: "hsl(203, 70%, 50%)",
        data: [],
      },
      {
        id: "revenue",
        color: "#fcba03",
        data: [],
      },
      {
        id: "visits",
        color: "#fcba03",
        data: [],
      },
    ],
  };
  componentDidMount() {
    this.populateData();
    this.setState(
      produce((draft) => {
        draft.width = document.getElementById(
          "menu_charts_container"
        ).clientWidth;
        draft.height = document.getElementById(
          "menu_charts_container"
        ).clientHeight;
      })
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.data !== this.props.data && this.props.data !== null) {
      this.populateData();
    }
  }

  round = (num) => {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return (Math.round(m) / 100) * Math.sign(num);
  };

  populateData = () => {
    this.setState(
      produce((draft) => {
        draft.data = this.props.data;
      })
    );
    let visits = [];
    let clicks = [];
    let conversions = [];
    let impressions = [];
    let cost = [];
    let revenue = [];
    let profit = [];

    const to = new Date(this.props.dates.endDate);
    const from = new Date(this.props.dates.startDate);
    const diff = moment(to).diff(moment(from), "days");
    let dateObj;

    if (this.props?.data !== null && this.props?.data?.length > 0) {
      for (let i = 0; i < this.props?.data?.length; i++) {
        if (diff <= 3) {
          dateObj = this.props.data[i].date_trunc;
          this.setState(
            produce((draft) => {
              draft.axisFormat = "%d %b %H:%M";
            })
          );
        } else if (diff > 3 && diff < 91) {
          dateObj = this.props.data[i].date_trunc;
          this.setState(
            produce((draft) => {
              draft.axisFormat = "%d %b";
            })
          );
        } else if (diff >= 91 && diff < 366) {
          dateObj = this.props.data[i].date_trunc;
          this.setState(
            produce((draft) => {
              draft.axisFormat = "%b %d";
            })
          );
        } else {
          dateObj = this.props.data[i].date_trunc;
          this.setState(
            produce((draft) => {
              draft.axisFormat = "%b %Y";
            })
          );
        }

        visits.push({ x: dateObj, y: Math.round(this.props.data[i].visits) });
        clicks.push({ x: dateObj, y: Math.round(this.props.data[i].clicks) });
        conversions.push({
          x: dateObj,
          y: Math.round(this.props.data[i].conversion),
        });
        impressions.push({
          x: dateObj,
          y: Math.round(this.props.data[i].impression),
        });
        cost.push({
          x: dateObj,
          y: this.round(Number(this.props.data[i].cost)),
        });
        revenue.push({
          x: dateObj,
          y: this.round(Number(this.props.data[i].revenue)),
        });
        profit.push({
          x: dateObj,
          y: this.round(Number(this.props.data[i].profit)),
        });
      }
      setTimeout(() => {
        this.setState(
          produce((draft) => {
            draft.dataToShow.visits = visits;
            draft.dataToShow.clicks = clicks;
            draft.dataToShow.conversions = conversions;
            draft.dataToShow.impressions = impressions;
            draft.dataToShow.cost = cost;
            draft.dataToShow.revenue = revenue;
            draft.dataToShow.profit = profit;

            draft.dataChart[0].data = clicks;
            draft.dataChart[1].data = conversions;
            draft.dataChart[2].data = cost;
            draft.dataChart[3].data = impressions;
            draft.dataChart[4].data = profit;
            draft.dataChart[5].data = revenue;
            draft.dataChart[6].data = visits;
          })
        );
      });
    }
  };

  clickEvent = (point, event) => {
    let sortedList = [
      "clicks",
      "conversions",
      "cost",
      "impressions",
      "profit",
      "revenue",
      "visits",
    ];
    for (let i = 0; i < sortedList.length; i++) {
      if (point.id === sortedList[i]) {
        if (this.state.dataChart[i].data.length === 0) {
          this.setState(
            produce((draft) => {
              draft.dataChart[i].data = this.state.dataToShow[sortedList[i]];
            })
          );
          event.target.style.fill = "#ccc";
        } else {
          this.setState(
            produce((draft) => {
              draft.dataChart[i].data = [];
            })
          );
          event.target.style.fill = "rgba(0, 0, 0, .03)";
        }
      }
    }
  };

  render() {
    return (
      <div id="menu_charts_container" className="menu_charts_container">
        <Line
          width={divWidth}
          height={this.state.height}
          data={this.state.dataChart}
          margin={{ top: 30, right: 140, bottom: 30, left: 60 }}
          xScale={{
            type: "time",
            format: "%m/%d/%Y %H:%M",
            useUTC: false,
          }}
          xFormat="time:%m/%d/%Y %H:%M"
          useUTC={false}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: "bottom",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "",
            legendOffset: 0,
            legendPosition: "middle",
            format: this.state.axisFormat,
            tickValues: 4,
          }}
          yFormat={(value) => `${Number(value)}`}
          enableSlices="x"
          yScale={{
            type: "linear",
            stacked: false,
          }}
          sliceTooltip={({ slice }) => {
            return (
              <div
                style={{
                  background: "white",
                  padding: "9px 20px",
                  border: "1px solid #ccc",
                  fontSize: "13px",
                  lineHeight: "17px",
                  textAlign: "left",
                }}
              >
                <div>Date: {slice.points[0].data.xFormatted}</div>
                {slice.points.map((point) => (
                  <div
                    key={point.id}
                    style={{
                      padding: "3px 0",
                    }}
                  >
                    <strong
                      style={{
                        color: point.serieColor,
                        width: "150px",
                      }}
                    >
                      {point.serieId}:
                    </strong>{" "}
                    {point.serieId === "profit" ||
                    point.serieId === "revenue" ||
                    point.serieId === "cost"
                      ? "$" +
                        (Math.round(point.data.yFormatted * 100) / 100).toFixed(
                          2
                        )
                      : point.data.yFormatted}
                  </div>
                ))}
              </div>
            );
          }}
          axisLeft={{
            orient: "left",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "",
            legendOffset: -40,
            legendPosition: "middle",
          }}
          colors={{ scheme: "category10" }}
          pointSize={4}
          pointColor={{ theme: "background" }}
          pointBorderWidth={1}
          pointBorderColor={{ from: "serieColor", modifiers: [] }}
          pointLabelYOffset={-12}
          // enableSlices="x"
          useMesh={true}
          legends={[
            {
              anchor: "right",
              direction: "column",
              justify: false,
              translateX: 110,
              translateY: 0,
              itemsSpacing: 3,
              itemDirection: "left-to-right",
              itemWidth: 90,
              itemHeight: 23,
              itemOpacity: 0.75,
              itemBackground: "#ccc",
              padding: 0,
              symbolSize: 7,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
              onClick: (point, event) => this.clickEvent(point, event),
            },
          ]}
        />
      </div>
    );
  }
}

window.addEventListener('resize',function(){
  //divWidth = document.getElementById("menu_charts_container").clientWidth
  divWidth = window.innerWidth
})

window.addEventListener("load",function(){
  divWidth = window.innerWidth

})

document.addEventListener("DOMContentLoaded", function(){
  divWidth = window.innerWidth
});






export default MenuCharts;
