import React, {useState} from "react";
import {Button} from "@progress/kendo-react-buttons";
import AddCustomDomainDialogue from "./dialoguePopups/addCustomDomainDoaloguePopup";
import EditCustomDomainDialogue from "./dialoguePopups/editCustomDomainDoaloguePopup";
import DeleteCustomDomainDialogue from "./dialoguePopups/deleteCustomDomainPopup";
import 'react-css-dropdown/dist/index.css';
import "./domainsConfiguration.css";
import ReactTooltip from 'react-tooltip';

const CustomDomains = (props) => {
    const [showCname,toggleCname] = useState(false);
        return (
            <section className="section text_align_left">
                <h3 className="h3 light_font">Custom Domains</h3>
                <p className="normal_text normal_font black ml-0 pl-0">In order to add a custom domain you will need to CNAME the Dedicated Domain.&nbsp;
                <a href={"#"} onClick={() => toggleCname(!showCname)}>How it works.</a>
                </p>
                {
                    showCname ?
                        <p className="normal_text cname_img">
                            <img src="/assets/img/CNAME/add-a-cname-godaddy-01.gif" alt="CNAME working"/>
                        </p>
                        : null
                }
                
                <div className="add_custom_domain_row ">
                    {/* <div className="bold_font normal_text black uppercase">Available: {props.domainsDataForTable !== null && props.domainsDataForTable.length} of 3</div> */}
                    <Button
                        onClick={props.toggleAddCustomDomainDialog}
                        icon="k-i-help k-i-plus-circle"
                        className="theme_dark_bg_color_imp color_white_imp theme_dark_bg_color save_button push"
                    >Add custom domain
                    </Button>
                </div>
                <AddCustomDomainDialogue props={props} />
                <div className="settings_table_con">
                    <table className="settings_table">
                        <tbody>
                            <tr>
                                <th>Domain</th>
                                <th>Assigned Workspace</th>
                                {/* <th>DNS Status</th> */}
                                <th>Actions</th>
                            </tr>
                            {
                                (props.domainsDataForTable !== null && props.domainsDataForTable.length > 0) ?
                                    props.domainsDataForTable.filter(domain=>domain.userId!==null).map((domainData,index) => (
                                        <tr key={index}>
                                            <td>
                                                <div className="icon_with_tooltip">
                                                    {/*for later use*/}
                                                    {
                                                        domainData.verified ? 
                                                        <>
                                                            <div data-tip data-for='domain_verification_tool' className="question_mark">
                                                                <span className="k-icon k-i-lock icon_color_success"></span>
                                                            </div>
                                                            <ReactTooltip id='domain_verification_tool' type='dark'>
                                                                <span>This domain has SSL activated.</span>
                                                            </ReactTooltip>
                                                        </>
                                                        : <span className="k-icon k-i-unlock icon_color_danger"></span>
                                                    }
                                                    &nbsp;
                                                    {domainData.name}
                                                </div>
                                            </td>
                                            <td>{domainData.workspaceName}</td>
                                            {/* <td>
                                                <div className="icons text_center">
                                                    {
                                                        domainData.verified ?
                                                            <span className="k-icon k-i-check icon_color_success"></span>
                                                        :
                                                        <span className="k-icon k-i-exception icon_color_warning"></span>
                                                    }
                                                    {
                                                        domainData.verified ?
                                                            <span className="">Verified</span>
                                                            :
                                                            <span className="">Verify</span>
                                                    }
                                                </div>
                                            </td> */}
                                            <td>
                                                {
                                                    domainData.userId !== null
                                                        ?
                                                        <div className="icons text_center">
                                                            <span id={domainData.id} onClick={props.openEditCustomDomainDialog} className="k-icon k-i-edit icon_color_theme_dark"></span>
                                                            <span id={domainData.id} onClick={props.openDeleteCustomDomainDialog} className="k-icon k-i-delete icon_color_theme_dark"></span>
                                                        </div>
                                                        : <div className="icons text_center">Default</div>
                                                }
                                            </td>
                                        </tr>
                                    ))
                                    : <>
                                        {props.domainsDataForTable === null &&
                                        <tr>
                                            <td>No domains have been added yet.</td>
                                        </tr>
                                        
                                        }
                                    </>
                            }
                        </tbody>
                    </table>
                </div>
                <div><EditCustomDomainDialogue props={props} /></div>
                <div><DeleteCustomDomainDialogue props={props} /></div>
            </section>
        );
};

export default CustomDomains;