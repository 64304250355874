import React, {Fragment} from "react";
import {withRouter} from "react-router";
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import DomainsConfiguration from "./domainsConfiguration";
import {
    makeSelectDomainName,
    makeSelectWorkspaceId,
    makeSelectLoading,
    makeSelectSuccess,
    makeSelectError,
    makeSelectAddDomainSuccess,
    makeSelectEditDomainPatchingSuccess
} from "./selectors";
import * as domainsActions from "./actions";
// import LegacyDomains from "../../../components/settings/domains/legacyDomains/legacyDomains";
import RedirectDomains from "./redirectDomains/redirectDomains";
// import RootDomains from "../../../components/settings/domains/rootDomains";
import "./domains.css";
import "../settingsGlobalSherry.css";
import Layout from "../../../layout";
import { makeSelectToken } from "../../../registration/LoginRegister/selectors";

class Domains extends React.Component {
    // const accessToken = useSelector(state => state.login.token);
    state = {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render () {
        return (
            <Fragment>
                <Layout>
                    <div className="settings_area_container">
                        <div className="small_width_page_con">
                            <div className="small_width_page_con_inner">
                                <div className="h1_con">
                                    <h1 className="h1 no_border inline_block">Domain</h1>
                                    {/*<button className="btn yellow_btn color_black normal_text no_border medium_font border_radius_normal_btn">How to use Dedicated Domains</button>*/}
                                </div>

                                {/*{*/}
                                {/*    props.domainsDataReceived ?*/}
                                <DomainsConfiguration
                                    dispatchAddDomain={this.props.dispatchAddDomain}
                                    accessToken={this.props.accessToken}
                                    dispatchLoadDomains={this.props.dispatchLoadDomains}
                                    dispatchUpdateDomain={this.props.dispatchUpdateDomain}
                                    dispatchDeleteCustomDomain={this.props.dispatchDeleteCustomDomain}
                                />
                                {/*        : null*/}
                                {/*}*/}

                                {/*<LegacyDomains />*/}
                                <RedirectDomains
                                    accessToken={this.props.accessToken}
                                />
                                {/*<RootDomains />*/}
                            </div>
                        </div>
                    </div>
                </Layout>
            </Fragment>
        );
    }
};

export const mapStateToProps = createStructuredSelector({
    domainName: makeSelectDomainName(),
    workspaceId: makeSelectWorkspaceId(),
    loading: makeSelectLoading(),
    success: makeSelectSuccess(),
    error: makeSelectError(),
    addDomainSuccess: makeSelectAddDomainSuccess(),
    editCustomDomainPatched: makeSelectEditDomainPatchingSuccess(),
    accessToken: makeSelectToken()
});
export function mapDispatchToProps(dispatch) {
    return {
        dispatchAddDomain: (...payload) => dispatch(domainsActions.addCustomDomain(...payload)),
        dispatchLoadDomains: (...payload) => dispatch(domainsActions.loadCustomDomains(...payload)),
        dispatchUpdateDomain: (...payload) => dispatch(domainsActions.editCustomDomain(...payload)),
        dispatchDeleteCustomDomain: (...payload) => dispatch(domainsActions.deleteCustomDomain(...payload)),
        // fetchingData: (...payload) => dispatch(colloborationWorkspaceActions.fetchingData(...payload))
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Domains));