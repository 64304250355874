import {
    ADD_FLOWS,ADD_FLOWS_FAILED,ADD_FLOWS_SUCCESS,
    LOAD_FLOWS, LOAD_FLOWS_FAILED, LOAD_FLOWS_SUCCESS,
    EDIT_FLOWS, EDIT_FLOWS_FAILED, EDIT_FLOWS_SUCCESS,
    DELETE_FLOWS, DELETE_FLOWS_FAILED, DELETE_FLOWS_SUCCESS,
    LOAD_COUNTRIES, LOAD_COUNTRIES_FAILED, LOAD_COUNTRIES_SUCCESS,
    UPDATE_FLOWS, UPDATE_FLOWS_SUCCESS, UPDATE_FLOWS_FAILED,
    DELETE_DEFAULT_PATH, DELETE_DEFAULT_PATH_SUCCESS, DELETE_DEFAULT_PATH_FAILED,
    DELETE_RULE_PATH, DELETE_RULE_PATH_SUCCESS, DELETE_RULE_PATH_FAILED,
    DELETE_RULE, DELETE_RULE_SUCCESS, DELETE_RULE_FAILED,
    ARCHIVE_FLOWS, ARCHIVE_FLOWS_SUCCESS, ARCHIVE_FLOWS_FAILED,
    GET_FLOW_CHARTS_DATA,GET_FLOW_CHARTS_DATA_SUCCESS,GET_FLOW_CHARTS_DATA_FAILED,
    
    LOAD_BRAND, LOAD_BRAND_SUCCESS, LOAD_BRAND_FAILED,
    LOAD_BROWSER, LOAD_BROWSER_SUCCESS, LOAD_BROWSER_FAILED,
    LOAD_CONNECTION_TYPE, LOAD_CONNECTION_TYPE_SUCCESS, LOAD_CONNECTION_TYPE_FAILED,
    LOAD_DEVICE_TYPE, LOAD_DEVICE_TYPE_SUCCESS, LOAD_DEVICE_TYPE_FAILED,
    LOAD_CITY, LOAD_CITY_FAILED, LOAD_CITY_SUCCESS,
    LOAD_REGION, LOAD_REGION_FAILED, LOAD_REGION_SUCCESS,
    LOAD_PROXY, LOAD_PROXY_FAILED, LOAD_PROXY_SUCCESS,
    LOAD_LANGUAGE, LOAD_LANGUAGE_FAILED, LOAD_LANGUAGE_SUCCESS,
    LOAD_OPERATING_SYSTEM, LOAD_OPERATING_SYSTEM_SUCCESS, LOAD_OPERATING_SYSTEM_FAILED,
    LOAD_ISP, LOAD_ISP_SUCCESS, LOAD_ISP_FAILED,
    LOAD_CONDITION_COUNTRIES, LOAD_CONDITION_COUNTRIES_SUCCESS, LOAD_CONDITION_COUNTRIES_FAILED,
    LOAD_ALL_ISP, LOAD_ALL_ISP_SUCCESS, LOAD_ALL_ISP_FAILED,
    LOAD_ALL_CITY, LOAD_ALL_CITY_SUCCESS, LOAD_ALL_CITY_FAILED,
    LOAD_WEEK_DAY, LOAD_WEEK_DAY_SUCCESS, LOAD_WEEK_DAY_FAILED,
    LOAD_MOBILE_CARRIER,LOAD_MOBILE_CARRIER_SUCCESS, LOAD_MOBILE_CARRIER_FAILED,
    
    ADD_BRAND, ADD_BRAND_SUCCESS, ADD_BRAND_FAILED,
    ADD_BROWSER, ADD_BROWSER_SUCCESS, ADD_BROWSER_FAILED,
    ADD_CONNECTION_TYPE, ADD_CONNECTION_TYPE_SUCCESS, ADD_CONNECTION_TYPE_FAILED,
    ADD_DEVICE_TYPE, ADD_DEVICE_TYPE_SUCCESS, ADD_DEVICE_TYPE_FAILED,
    ADD_CITY, ADD_CITY_FAILED, ADD_CITY_SUCCESS,
    ADD_REGION, ADD_REGION_FAILED, ADD_REGION_SUCCESS,
    ADD_PROXY, ADD_PROXY_FAILED, ADD_PROXY_SUCCESS,
    ADD_LANGUAGE, ADD_LANGUAGE_FAILED, ADD_LANGUAGE_SUCCESS,
    ADD_OPERATING_SYSTEM, ADD_OPERATING_SYSTEM_SUCCESS, ADD_OPERATING_SYSTEM_FAILED, 

} from "./constants";

//actions

// LOAD countries
export const loadCountries = (accessToken) => {
    return{
        type: LOAD_COUNTRIES,
        payload: {accessToken}
    }
}
export const loadCountriesSuccess = (countriesData) => {
    return{
        type: LOAD_COUNTRIES_SUCCESS,
        payload: {countriesData}
    }
}

export const loadCountriesFailed = (error) => {
    return{
        type: LOAD_COUNTRIES_FAILED,
        payload: error
    }
}

// load flows

export const loadFlows = (accessToken, searchData,sorting, primaryPage, dataType) => {
    return{
        type: LOAD_FLOWS,
        payload:{accessToken,searchData,sorting, primaryPage, dataType}
    }
}
export const loadFlowsSuccess = (flowsDataRecieved) => {
    return{
        type: LOAD_FLOWS_SUCCESS,
        payload:{ flowsDataRecieved}
    }
}

export const loadFlowsFailed = (error) => {
    return{
        type: LOAD_FLOWS_FAILED,
        payload: {error}
    }
}

//add flows

export const addNewFlows = (accessToken, flowData) => {
    // console.log("flowData",flowData);
    return{
        type: ADD_FLOWS,
        payload: {
            accessToken,
            flowData
        }
    }
}
export const addFlowsSuccess = (flowData) => {
    return{
        type: ADD_FLOWS_SUCCESS,
        payload:{flowData}
    }
}

export const addFlowsFailed = (error) => {
    return{
        type: ADD_FLOWS_FAILED,
        payload: {error}
    }
}

// edit flows
export const editFlows = (accessToken,editFlowId) => {
    return{
        type: EDIT_FLOWS,
        payload:{
            accessToken,
            editFlowId
        }
    }
}
export const editFlowsSuccess = (editFlowData) => {
    return{
        type: EDIT_FLOWS_SUCCESS,
        payload:{editFlowData}
    }
}

export const editFlowsFailed = (error) => {
    return{
        type: EDIT_FLOWS_FAILED,
        payload:{error}
    }
}

//update flows

export const updateFlows = (accessToken,editFlowData) => {
    return{
        type: UPDATE_FLOWS,
        payload:{
            accessToken,
            editFlowData
        }
    }
}
export const updateFlowsSuccess = (editFlowData) => {
    return{
        type: UPDATE_FLOWS_SUCCESS,
        payload:{editFlowData}
    }
}

export const updateFlowsFailed = (error) => {
    return{
        type: UPDATE_FLOWS_FAILED,
        payload: {error}
    }
}

// delete flows

export const deleteFlows = (accessToken, deleteFlowId) => {
    return{
        type: DELETE_FLOWS,
        payload: {
            accessToken,
            deleteFlowId
        }
    }
}
export const deleteFlowsSuccess = (deleteResponse) => {
    return{
        type: DELETE_FLOWS_SUCCESS,
        payload:{deleteResponse}
    }
}

export const deleteFlowsFailed = (error) => {
    return{
        type: DELETE_FLOWS_FAILED,
        payload:{error}
    }
}

export const archiveFlows = (archiveFlowData) => {
    return{
        type: ARCHIVE_FLOWS,
        payload: {
            archiveFlowData
        }
    }
}
export const archiveFlowsSuccess = (archiveFlowResponse) => {
    return{
        type: ARCHIVE_FLOWS_SUCCESS,
        payload:{archiveFlowResponse}
    }
}

export const archiveFlowsFailed = (error) => {
    return{
        type: ARCHIVE_FLOWS_FAILED,
        payload:{error}
    }
}

export const deleteDefaultPath = (accessToken,id) => {
    return {
        type: DELETE_DEFAULT_PATH,
        payload:{accessToken,id}
    }
}

export const deleteDefaultPathSuccess = (data) => {
    return {
        type: DELETE_DEFAULT_PATH_SUCCESS,
        payload:{data}
    }
}

export const deleteDefaultPathFailed = (error) => {
    return {
        type: DELETE_DEFAULT_PATH_FAILED,
        payload:{error}
    }
}

export const deleteRulePath = (accessToken,id) => {
    return {
        type: DELETE_RULE_PATH,
        payload:{accessToken,id}
    }
}

export const deleteRulePathSuccess = (data) => {
    return {
        type: DELETE_RULE_PATH_SUCCESS,
        payload:{data}
    }
}

export const deleteRulePathFailed = (error) => {
    return {
        type: DELETE_RULE_PATH_FAILED,
        payload:{error}
    }
}

export const deleteRule = (accessToken,id) => {
    return {
        type: DELETE_RULE,
        payload:{accessToken,id}
    }
}

export const deleteRuleSuccess = (data) => {
    return {
        type: DELETE_RULE_SUCCESS,
        payload:{data}
    }
}

export const deleteRuleFailed = (error) => {
    return {
        type: DELETE_RULE_FAILED,
        payload:{error}
    }
}

export const loadFlowChartsData = (payload) => {
    return {
        type: GET_FLOW_CHARTS_DATA,
        payload: payload
    };
};

export const loadFlowChartsDataSuccess = (payload) => {
    return {
        type: GET_FLOW_CHARTS_DATA_SUCCESS,
        payload: payload
    };
};

export const loadFlowChartsDataFailed = (error) => {
    return {
        type: GET_FLOW_CHARTS_DATA_FAILED,
        payload: error
    };
};
//conditions actions------------------------------------------------------------------------------------------------
//===========================================================================================================================

export const loadISP = (accessToken,data) => {
    return {
        type: LOAD_ISP,
        payload:{accessToken,data}
    }
}

export const loadISPSuccess = (data) => {
    return {
        type: LOAD_ISP_SUCCESS,
        payload:{data}
    }
}
export const loadISPFailed = (error) => {
    return {
        type: LOAD_ISP_FAILED,
        payload:{error}
    }
}

export const loadAllISP = (accessToken) => {
    return {
        type: LOAD_ALL_ISP,
        payload:{accessToken}
    }
}

export const loadAllISPSuccess = (data) => {
    return {
        type: LOAD_ALL_ISP_SUCCESS,
        payload:{data}
    }
}
export const loadAllISPFailed = (error) => {
    return {
        type: LOAD_ALL_ISP_FAILED,
        payload:{error}
    }
}

export const loadBrand = (accessToken) => {
    return {
        type: LOAD_BRAND,
        payload:{accessToken}
    }
}

export const loadBrandSuccess = (data) => {
    return {
        type: LOAD_BRAND_SUCCESS,
        payload:{data}
    }
}
export const loadBrandFailed = (error) => {
    return {
        type: LOAD_BRAND_FAILED,
        payload:{error}
    }
}

export const addBrand = (accessToken, data) => {
    return {
        type: ADD_BRAND,
        payload:{accessToken, data}
    }
}

export const addBrandSuccess = (data) => {
    return {
        type: ADD_BRAND_SUCCESS,
        payload:{data}
    }
}
export const addBrandFailed = (error) => {
    return {
        type: ADD_BRAND_FAILED,
        payload:{error}
    }
}


export const loadConnectionType = (accessToken) => {
    return {
        type: LOAD_CONNECTION_TYPE,
        payload:{accessToken}
    }
}
export const loadConnectionTypeSuccess = (data) => {
    return {
        type: LOAD_CONNECTION_TYPE_SUCCESS,
        payload:{data}
    }
}
export const loadConnectionTypeFailed = (error) => {
    return {
        type: LOAD_CONNECTION_TYPE_FAILED,
        payload:{error}
    }
}

export const addConnectionType = (accessToken,data) => {
    return {
        type: ADD_CONNECTION_TYPE,
        payload:{accessToken,data}
    }
}
export const addConnectionTypeSuccess = (data) => {
    return {
        type: ADD_CONNECTION_TYPE_SUCCESS,
        payload:{data}
    }
}
export const addConnectionTypeFailed = (error) => {
    return {
        type: ADD_CONNECTION_TYPE_FAILED,
        payload:{error}
    }
}

export const loadProxy = (accessToken) => {
    return {
        type: LOAD_PROXY,
        payload:{accessToken}
    }
}

export const loadProxySuccess = (data) => {
    return {
        type: LOAD_PROXY_SUCCESS,
        payload:{data}
    }
}

export const loadProxyFailed = (error) => {
    return {
        type: LOAD_PROXY_FAILED,
        payload:{error}
    }
}

export const addProxy = (accessToken,data) => {
    return {
        type: ADD_PROXY,
        payload:{accessToken,data}
    }
}

export const addProxySuccess = (data) => {
    return {
        type: ADD_PROXY_SUCCESS,
        payload:{data}
    }
}

export const addProxyFailed = (error) => {
    return {
        type: ADD_PROXY_FAILED,
        payload:{error}
    }
}

export const loadLanguage = (accessToken) => {
    return {
        type: LOAD_LANGUAGE,
        payload:{accessToken}
    }
}

export const loadLanguageSuccess = (data) => {
    //console.log('data in actions of language', data);
    return {
        type: LOAD_LANGUAGE_SUCCESS,
        payload:{data}
    }
}

export const loadLanguageFailed = (error) => {
    return {
        type: LOAD_LANGUAGE_FAILED,
        payload:{error}
    }
}

export const addLanguage = (accessToken,data) => {
    return {
        type: ADD_LANGUAGE,
        payload:{accessToken,data}
    }
}

export const addLanguageSuccess = (data) => {
    return {
        type: ADD_LANGUAGE_SUCCESS,
        payload:{data}
    }
}

export const addLanguageFailed = (error) => {
    return {
        type: ADD_LANGUAGE_FAILED,
        payload:{error}
    }
}

export const loadCity = (accessToken, data) => {
    //console.log(' ',accessToken,data);
    return {
        type: LOAD_CITY,
        payload:{accessToken,data}
    }
}

export const loadCitySuccess = (data) => {
    return {
        type: LOAD_CITY_SUCCESS,
        payload:{data}
    }
}

export const loadCityFailed = (error) => {
    return {
        type: LOAD_CITY_FAILED,
        payload:{error}
    }
}

export const loadMobileCarrier = (accessToken, data) => {
    //console.log(' ',accessToken,data);
    return {
        type: LOAD_MOBILE_CARRIER,
        payload:{accessToken,data}
    }
}

export const loadMobileCarrierSuccess = (data) => {
    return {
        type: LOAD_MOBILE_CARRIER_SUCCESS,
        payload:{data}
    }
}

export const loadMobileCarrierFailed = (error) => {
    return {
        type: LOAD_MOBILE_CARRIER_FAILED,
        payload:{error}
    }
}

export const loadAllCity = (accessToken) => {
    //console.log('load all city data');
    return {
        type: LOAD_ALL_CITY,
        payload:{accessToken}
    }
}

export const loadAllCitySuccess = (data) => {
    return {
        type: LOAD_ALL_CITY_SUCCESS,
        payload:{data}
    }
}

export const loadAllCityFailed = (error) => {
    return {
        type: LOAD_ALL_CITY_FAILED,
        payload:{error}
    }
}

export const loadConditionCountries = (accessToken,data) => {
    return{
        type: LOAD_CONDITION_COUNTRIES,
        payload: {accessToken,data}
    }
}
export const loadConditionCountriesSuccess = (data) => {
    return{
        type: LOAD_CONDITION_COUNTRIES_SUCCESS,
        payload: {data}
    }
}

export const loadConditionCountriesFailed = (error) => {
    return{
        type: LOAD_CONDITION_COUNTRIES_FAILED,
        payload: error
    }
}

export const addCity = (accessToken,data) => {
    return {
        type: ADD_CITY,
        payload:{accessToken,data}
    }
}

export const addCitySuccess = (data) => {
    return {
        type: ADD_CITY_SUCCESS,
        payload:{data}
    }
}

export const addCityFailed = (error) => {
    return {
        type: ADD_CITY_FAILED,
        payload:{error}
    }
}

export const loadBrowser = (accessToken) => {
    return {
        type: LOAD_BROWSER,
        payload:{accessToken}
    }
}

export const loadBrowserSuccess = (data) => {
    return {
        type: LOAD_BROWSER_SUCCESS,
        payload:{data}
    }
}

export const loadBrowserFailed = (error) => {
    return {
        type: LOAD_BROWSER_FAILED,
        payload:{error}
    }
}

export const addBrowser = (accessToken, data) => {
    return {
        type: ADD_BROWSER,
        payload:{accessToken, data}
    }
}

export const addBrowserSuccess = (data) => {
    return {
        type: ADD_BROWSER_SUCCESS,
        payload:{data}
    }
}

export const addBrowserFailed = (error) => {
    return {
        type: ADD_BROWSER_FAILED,
        payload:{error}
    }
}

export const loadRegion = (accessToken) => {
    return {
        type: LOAD_REGION,
        payload:{accessToken}
    }
}

export const loadRegionSuccess = (data) => {
    return {
        type: LOAD_REGION_SUCCESS,
        payload:{data}
    }
}

export const loadRegionFailed = (error) => {
    return {
        type: LOAD_REGION_FAILED,
        payload:{error}
    }
}

export const addRegion = (accessToken, data) => {
    return {
        type: ADD_REGION,
        payload:{accessToken, data}
    }
}


export const addRegionSuccess = (data) => {
    return {
        type: ADD_REGION_SUCCESS,
        payload:{data}
    }
}


export const addRegionFailed = (error) => {
    return {
        type: ADD_REGION_FAILED,
        payload:{error}
    }
}

export const loadOperatingSystem = (accessToken) => {
    return {
        type: LOAD_OPERATING_SYSTEM,
        payload:{accessToken}
    }
}

export const loadOperatingSystemSuccess = (data) => {
    return {
        type: LOAD_OPERATING_SYSTEM_SUCCESS,
        payload:{data}
    }
}

export const loadOperatingSystemFailed = (error) => {
    return {
        type: LOAD_OPERATING_SYSTEM_FAILED,
        payload:{error}
    }
}



export const addOperatingSystem = (accessToken, data) => {
    return {
        type: ADD_OPERATING_SYSTEM,
        payload:{accessToken, data}
    }
}

export const addOperatingSystemSuccess = (data) => {
    return {
        type: ADD_OPERATING_SYSTEM_SUCCESS,
        payload:{data}
    }
}

export const addOperatingSystemFailed = (error) => {
    return {
        type: ADD_OPERATING_SYSTEM_FAILED,
        payload:{error}
    }
}

export const loadDeviceType = (accessToken) => {
    return {
        type: LOAD_DEVICE_TYPE,
        payload:{accessToken}
    }
}

export const loadDeviceTypeSuccess = (data) => {
    return {
        type: LOAD_DEVICE_TYPE_SUCCESS,
        payload:{data}
    }
}

export const loadDeviceTypeFailed = (error) => {
    return {
        type: LOAD_DEVICE_TYPE_FAILED,
        payload:{error}
    }
}

export const addDeviceType = (accessToken, data) => {
    return {
        type: ADD_DEVICE_TYPE,
        payload:{accessToken, data}
    }
}

export const addDeviceTypeSuccess = (data) => {
    return {
        type: ADD_DEVICE_TYPE_SUCCESS,
        payload:{data}
    }
}

export const addDeviceTypeFailed = (error) => {
    return {
        type: ADD_DEVICE_TYPE_FAILED,
        payload:{error}
    }
}

export const loadWeekDay = (accessToken) => {
    return {
        type: LOAD_WEEK_DAY,
        payload:{accessToken}
    }
}

export const loadWeekDaySuccess = (data) => {
    return {
        type: LOAD_WEEK_DAY_SUCCESS,
        payload:{data}
    }
}

export const loadWeekDayFailed = (error) => {
    return {
        type: LOAD_WEEK_DAY_FAILED,
        payload:{error}
    }
}

