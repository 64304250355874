import produce from "immer";
import {
    LOAD_ALL_CONVERSIONS_DATA, LOAD_CONVERSIONS_DATA_SUCCESS, LOAD_CONVERSIONS_DATA_FAILED,LOAD_INIT_STATE
} from "./constants";


export const initialConversionsState = {
    loading: false,
    success: false,
    error: false,
    allConversionData: [],

};

const conversionReducer = (state= initialConversionsState, action) => produce(state, (draft => {
    switch (action.type) {
        case LOAD_ALL_CONVERSIONS_DATA:
            draft.loading = true;
            draft.success = false;
            draft.error = false;
            draft.allConversionData = null;
            return draft;
        case LOAD_CONVERSIONS_DATA_SUCCESS:
            draft.loading = false;
            draft.success = true;
            draft.error = false;
            draft.allConversionData = action.payload;
            return draft;
        case LOAD_CONVERSIONS_DATA_FAILED:
            draft.loading = false;
            draft.success = false;
            draft.error = action.payload.response;
            draft.allConversionData = false;
            return draft;
        case LOAD_INIT_STATE:
            draft.loading = false;
            draft.success = false;
            draft.error = [];
            draft.allConversionData = false;
        default:
            return draft;
    }
}));

export default conversionReducer;