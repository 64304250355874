import produce from "immer";
import {
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_USER
} from "./constants";

export const initialState = {
  error: false,
  success: false,
  loading: false,
  token: null,
  isLoggedIn: false,
  user: {
    user: null,
    userId: null,
    isUserAdmin: null,
    userCurrency: null,
    home_screen: null,
    timeZoneId: null,
  },
  register: {
    error: false,
    success: false,
    loading: false,
    isRegistered: null,
  },
  workspacesList: [],
  filterStatus: null,
  forgetpassword: {
    useremail:null,
    error: false,
    success: false,
    loading: false,
    isForgotPass: false,
  }
};

/* eslint-disable default-case, no-param-reassign */
const loginRegisterContainerReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOGIN_USER:
        draft.loading = true;
        draft.error = false;
        draft.success = false;
        draft.token = null;
        draft.isLoggedIn = false;
        draft.filterStatus = null;
        draft.user = {
          user: null,
          userId: null,
          isUserAdmin: null,
          userCurrency: null,
          home_screen: null,
          timeZoneId: null,
        };
        return draft;
      case LOGIN_USER_SUCCESS:
        draft.user = {
          user: action.payload.user.user.username,
          userId: action.payload.user.user.id,
          isUserAdmin: action.payload.user.user.is_admin,
          userCurrency: action.payload.user.currency.currency_sign,
          home_screen: action.payload.user.setting.home_screen,
          timeZoneId: action.payload.user.setting.timeZoneId,
        };
        draft.filterStatus = action.payload.user.status;
        draft.workspacesList = action.payload.user.workspace;
        draft.token = action.payload.user.accessToken;
        draft.isLoggedIn = true;
        draft.success = true;
        draft.loading = false;
        draft.error = false;
        return draft;
      case LOGIN_USER_FAILED:
        draft.error = action.payload.response;
        draft.success = false;
        draft.loading = false;
        return draft;
      case REGISTER_USER:
        draft.register.loading = true;
        draft.register.error = false;
        draft.register.success = false;
        draft.register.isRegistered = false;
        return draft;
      case REGISTER_USER_SUCCESS:
        // console.log("action success payload user", action.payload);
        draft.register.isRegistered = true;
        draft.register.success = true;
        draft.register.loading = false;
        draft.register.error = false;
        return draft;
      case REGISTER_USER_ERROR:
        draft.register.error = action.payload.response;
        draft.register.success = false;
        draft.register.loading = false;
        draft.register.isRegistered = false;
        return draft;
      case FORGOT_PASSWORD:
        // console.log('FORGET PASSWORD user');
        draft.forgetpassword.loading = true;
        return draft;
      case FORGOT_PASSWORD_SUCCESS:
        draft.forgetpassword.useremail = action.payload;
        draft.forgetpassword.success = true;
        draft.forgetpassword.error = false;
        draft.forgetpassword.loading = false;
        draft.forgetpassword.isForgotPass = true;
        draft.user = {
          user: null,
          userId: null,
          isUserAdmin: null,
          userCurrency: null,
          home_screen: null,
          timeZoneId: null,
        };
        return draft;
      case FORGOT_PASSWORD_ERROR:
        draft.forgetpassword.error = true;
        draft.forgetpassword.success = false;
        draft.forgetpassword.isForgotPass = false;
        draft.forgetpassword.useremail = null;
        draft.forgetpassword.loading = false;
        return draft;
      case LOGOUT_USER:
        draft.user = {
          user: null,
          userId: null,
          isUserAdmin: null,
          userCurrency: null,
          home_screen: null,
          timeZoneId: null,
        };
        draft.token = null;
        draft.loading = false;
        draft.isLoggedIn = false;
        return draft;
      case RESET_USER:
        draft.loading = false;
        draft.error = false;
        draft.success = false;
        draft.user = {
          user: null,
          userId: null,
          isUserAdmin: null,
          userCurrency: null,
          home_screen: null,
          timeZoneId: null,
        };
        draft.token = null;
        return draft;
      default:
        return draft;
    }
  });

export default loginRegisterContainerReducer;
