import {
    LOAD_TRACKING_URLS,
    LOAD_TRACKING_URLS_SUCCESS,
    LOAD_TRACKING_URLS_FAILED,

    LOAD_CURRENCY,
    LOAD_CURRENCY_SUCCESS,
    LOAD_CURRENCY_FAILED,

    POST_NEW_AFFILIATE_NETWORK_DATA,
    POST_NEW_AFFILIATE_NETWORK_DATA_SUCCESS,
    POST_NEW_AFFILIATE_NETWORK_DATA_FAILED,

    LOAD_AFFILIATE_NETWORK_DATA,
    LOAD_AFFILIATE_NETWORK_DATA_SUCCESS,
    LOAD_AFFILIATE_NETWORK_DATA_FAILED,

    EDIT_AFFILIATE_NETWORK_DATA,
    EDIT_AFFILIATE_NETWORK_DATA_SUCCESS,
    EDIT_AFFILIATE_NETWORK_DATA_FAILED,

    EDIT_AFFILIATE_NETWORK_DATA_SENT,
    EDIT_AFFILIATE_NETWORK_DATA_SENT_SUCCESS,
    EDIT_AFFILIATE_NETWORK_DATA_SENT_FAILED,

    DELETE_AFFILIATE_NETWORK_DATA,
    DELETE_AFFILIATE_NETWORK_DATA_SUCCESS,
    DELETE_AFFILIATE_NETWORK_DATA_FAILED,

    ARCHIVE_AFFILIATE_NETWORK_DATA,
    ARCHIVE_AFFILIATE_NETWORK_DATA_SUCCESS,
    ARCHIVE_AFFILIATE_NETWORK_DATA_FAILED,

    LOAD_CUSTOM_AFFILIATE_NETWORK,
    LOAD_CUSTOM_AFFILIATE_NETWORK_SUCCESS,
    LOAD_CUSTOM_AFFILIATE_NETWORK_FAILED,

    LOAD_CUSTOM_AFFILIATE_NETWORK_BY_ID,
    LOAD_CUSTOM_AFFILIATE_NETWORK_BY_ID_SUCCESS,
    LOAD_CUSTOM_AFFILIATE_NETWORK_BY_ID_FAILED,
    
    GET_AFFNETWORK_CHARTS_DATA,
    GET_AFFNETWORK_CHARTS_DATA_SUCCESS,
    GET_AFFNETWORK_CHARTS_DATA_FAILED,

    CLOSE_EDIT_DIALOGUE,
    RESET_CUSTOM_DATA_BY_ID
} from "./constants";

export const loadTrackingURLS = (loadTrackingURLSPayload) => {
    return {
        type: LOAD_TRACKING_URLS,
        payload: loadTrackingURLSPayload
    };
};

export const loadTrackingURLSSuccess = (loadTrackingURLSSuccessPayload) => {
    return {
        type: LOAD_TRACKING_URLS_SUCCESS,
        payload: loadTrackingURLSSuccessPayload
    };
};

export const loadTrackingURLSFailed = (error) => {
    return {
        type: LOAD_TRACKING_URLS_FAILED,
        payload: error
    };
};


// Loading Currency Actions
export const loadCurrency = (loadCurrencyPayload) => {
    return {
        type: LOAD_CURRENCY,
        payload: loadCurrencyPayload
    };
};

export const loadCurrencySuccess = (loadCurrencySuccessPayload) => {
    return {
        type: LOAD_CURRENCY_SUCCESS,
        payload: loadCurrencySuccessPayload
    };
};

export const loadCurrencyFailed = (error) => {
    return {
        type: LOAD_CURRENCY_FAILED,
        payload: error
    };
};


// Sending New Affiliate Network data Actions
export const postNewAffiliateNetwork = (postNewAffiliateNetworkPayload) => {
    return {
        type: POST_NEW_AFFILIATE_NETWORK_DATA,
        payload: postNewAffiliateNetworkPayload
    };
};

export const postNewAffiliateNetworkSuccess = (postNewAffiliateNetworkSuccessPayload) => {
    return {
        type: POST_NEW_AFFILIATE_NETWORK_DATA_SUCCESS,
        payload: postNewAffiliateNetworkSuccessPayload
    };
};

export const postNewAffiliateNetworkFailed = (error) => {
    return {
        type: POST_NEW_AFFILIATE_NETWORK_DATA_FAILED,
        payload: error
    };
};


// Load Affiliate Network data Actions
export const loadAffiliateNetwork = (payload) => {
    return {
        type: LOAD_AFFILIATE_NETWORK_DATA,
        payload: payload
    };
};

export const loadAffiliateNetworkSuccess = (loadAffiliateNetworkSuccessPayload) => {
    return {
        type: LOAD_AFFILIATE_NETWORK_DATA_SUCCESS,
        payload: loadAffiliateNetworkSuccessPayload
    };
};

export const loadAffiliateNetworkFailed = (error) => {
    return {
        type: LOAD_AFFILIATE_NETWORK_DATA_FAILED,
        payload: error
    };
};

// EDIT Affiliate Network data Actions
export const editAffiliateNetwork = (editAffiliateNetworkPayload) => {
    return {
        type: EDIT_AFFILIATE_NETWORK_DATA,
        payload: editAffiliateNetworkPayload
    };
};

export const editAffiliateNetworkSuccess = (editAffiliateNetworkSuccessPayload) => {
    return {
        type: EDIT_AFFILIATE_NETWORK_DATA_SUCCESS,
        payload: editAffiliateNetworkSuccessPayload
    };
};

export const editAffiliateNetworkFailed = (error) => {
    return {
        type: EDIT_AFFILIATE_NETWORK_DATA_FAILED,
        payload: error
    };
};

export const closeEditAffiliateNetworkDialogue = () => {
    return {
        type: CLOSE_EDIT_DIALOGUE,
    };
};


// Send EDIT Affiliate Network data Actions
export const sendEditAffiliateNetwork = (sendEditAffiliateNetworkPayload) => {
    return {
        type: EDIT_AFFILIATE_NETWORK_DATA_SENT,
        payload: sendEditAffiliateNetworkPayload
    };
};

export const sendEditAffiliateNetworkSuccess = (sendEditAffiliateNetworkSuccessPayload) => {
    return {
        type: EDIT_AFFILIATE_NETWORK_DATA_SENT_SUCCESS,
        payload: sendEditAffiliateNetworkSuccessPayload
    };
};

export const sendEditAffiliateNetworkFailed = (error) => {
    return {
        type: EDIT_AFFILIATE_NETWORK_DATA_SENT_FAILED,
        payload: error
    };
};


// Delete Affiliate Network data Actions
export const deleteAffiliateNetwork = (deleteAffiliateNetworkPayload) => {
    return {
        type: DELETE_AFFILIATE_NETWORK_DATA,
        payload: deleteAffiliateNetworkPayload
    };
};

export const deleteAffiliateNetworkSuccess = (deleteAffiliateNetworkSuccessPayload) => {
    return {
        type: DELETE_AFFILIATE_NETWORK_DATA_SUCCESS,
        payload: deleteAffiliateNetworkSuccessPayload
    };
};

export const deleteAffiliateNetworkFailed = (error) => {
    return {
        type: DELETE_AFFILIATE_NETWORK_DATA_FAILED,
        payload: error
    };
};

export const archiveAffiliateNetwork = (archiveAffiliateNetworkPayload) => {
    return {
        type: ARCHIVE_AFFILIATE_NETWORK_DATA,
        payload: archiveAffiliateNetworkPayload
    };
};

export const archiveAffiliateNetworkSuccess = (archiveAffiliateNetworkSuccessPayload) => {
    return {
        type: ARCHIVE_AFFILIATE_NETWORK_DATA_SUCCESS,
        payload: archiveAffiliateNetworkSuccessPayload
    };
};

export const archiveAffiliateNetworkFailed = (error) => {
    return {
        type: ARCHIVE_AFFILIATE_NETWORK_DATA_FAILED,
        payload: error
    };
};

export const loadCustomAffiliateNetwork = (customAffiliateNetworkPayload) => {
    return {
        type: LOAD_CUSTOM_AFFILIATE_NETWORK,
        payload: customAffiliateNetworkPayload
    };
}

export const loadCustomAffiliateNetworkSuccess = (customAffiliateNetworkPayloadSuccess)=>{
    return {
        type: LOAD_CUSTOM_AFFILIATE_NETWORK_SUCCESS,
        payload: customAffiliateNetworkPayloadSuccess,
    }
}

export const loadCustomAffiliateNetworkFailed = (customAffiliateNetworkPayloadFailed)=>{
    return {
        type: LOAD_CUSTOM_AFFILIATE_NETWORK_FAILED,
        payload: customAffiliateNetworkPayloadFailed,
    }
}

export const loadCustomAffiliateNetworkById = (customAffiliateNetworkPayload) => {
    return {
        type: LOAD_CUSTOM_AFFILIATE_NETWORK_BY_ID,
        payload: customAffiliateNetworkPayload
    };
}

export const loadCustomAffiliateNetworkByIdSuccess = (customAffiliateNetworkPayloadSuccess)=> {
    return {
        type: LOAD_CUSTOM_AFFILIATE_NETWORK_BY_ID_SUCCESS,
        payload: customAffiliateNetworkPayloadSuccess,
    }
}

export const loadCustomAffiliateNetworkByIdFailed = (customAffiliateNetworkPayloadFailed)=> {
    return {
        type: LOAD_CUSTOM_AFFILIATE_NETWORK_BY_ID_FAILED,
        payload: customAffiliateNetworkPayloadFailed,
    }
}

export const loadAffNetworkChartsData = (payload) => {
    return {
        type: GET_AFFNETWORK_CHARTS_DATA,
        payload: payload
    };
};

export const loadAffNetworkChartsDataSuccess = (payload) => {
    return {
        type: GET_AFFNETWORK_CHARTS_DATA_SUCCESS,
        payload: payload
    };
};

export const loadAffNetworkChartsDataFailed = (error) => {
    return {
        type: GET_AFFNETWORK_CHARTS_DATA_FAILED,
        payload: error
    };
};

export const resetCustomDataById = () => {
    return {
        type:RESET_CUSTOM_DATA_BY_ID,
    }
}