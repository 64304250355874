export const LOAD_COUNTRY = "LOAD_COUNTRY";
export const LOAD_COUNTRY_SUCCESS = "LOAD_COUNTRY_SUCCESS";
export const LOAD_COUNTRY_FAILED = "LOAD_COUNTRY_FAILED";

export const POST_LANDER_DATA = "POST_LANDER_DATA";
export const POST_LANDER_DATA_SUCCESS = "POST_LANDER_DATA_SUCCESS";
export const POST_LANDER_DATA_FAILED = "POST_LANDER_DATA_FAILED";

export const LOAD_ALL_LANDERS_DATA = "LOAD_ALL_LANDERS_DATA";
export const LOAD_ALL_LANDERS_DATA_SUCCESS = "LOAD_ALL_LANDERS_DATA_SUCCESS";
export const LOAD_ALL_LANDERS_DATA_FAILED = "LOAD_ALL_LANDERS_DATA_FAILED";

export const LOAD_EDIT_LANDER_DATA = "LOAD_EDIT_LANDER_DATA";
export const LOAD_EDIT_LANDER_DATA_SUCCESS = "LOAD_EDIT_LANDER_DATA_SUCCESS";
export const LOAD_EDIT_LANDER_DATA_FAILED = "LOAD_EDIT_LANDER_DATA_FAILED";

export const GET_LANDER_CHARTS_DATA = "GET_LANDER_CHARTS_DATA";
export const GET_LANDER_CHARTS_DATA_SUCCESS = "GET_LANDER_CHARTS_DATA_SUCCESS";
export const GET_LANDER_CHARTS_DATA_FAILED = "GET_LANDER_CHARTS_DATA_FAILED";


export const EDIT_LANDER_DIALOGUE_CLOSED = "EDIT_LANDER_DIALOGUE_CLOSED";


export const SEND_EDIT_LANDER_DATA = "SEND_EDIT_LANDER_DATA";
export const SEND_EDIT_LANDER_DATA_SUCCESS = "SEND_EDIT_LANDER_DATA_SUCCESS";
export const SEND_EDIT_LANDER_DATA_FAILED = "SEND_EDIT_LANDER_DATA_FAILED";

export const DELETE_LANDER_DATA = "DELETE_LANDER_DATA";
export const DELETE_LANDER_DATA_SUCCESS = "DELETE_LANDER_DATA_SUCCESS";
export const DELETE_LANDER_DATA_FAILED = "DELETE_LANDER_DATA_FAILED";

export const ARCHIVE_LANDER_DATA = "ARCHIVE_LANDER_DATA";
export const ARCHIVE_LANDER_DATA_SUCCESS = "ARCHIVE_LANDER_DATA_SUCCESS";
export const ARCHIVE_LANDER_DATA_FAILED = "ARCHIVE_LANDER_DATA_FAILED";

export const LOAD_TOKEN_TAGS = "LOAD_TOKEN_TAGS";
export const LOAD_TOKEN_TAGS_SUCCESS = "LOAD_TOKEN_TAGS_SUCCESS";
export const LOAD_TOKEN_TAGS_FAILED = "LOAD_TOKEN_TAGS_FAILED";