export const LOAD_COUNTRY = "LOAD_COUNTRY";
export const LOAD_COUNTRY_SUCCESS = "LOAD_COUNTRY_SUCCESS";
export const LOAD_COUNTRY_FAILED = "LOAD_COUNTRY_FAILED";

export const POST_CAMPAIGN_DATA = "POST_CAMPAIGN_DATA";
export const POST_CAMPAIGN_DATA_SUCCESS = "POST_CAMPAIGN_DATA_SUCCESS";
export const POST_CAMPAIGN_DATA_FAILED = "POST_CAMPAIGN_DATA_FAILED";

export const LOAD_ALL_CAMPAIGNS_DATA = "LOAD_ALL_CAMPAIGNS_DATA";
export const LOAD_ALL_CAMPAIGNS_DATA_SUCCESS = "LOAD_ALL_CAMPAIGNS_DATA_SUCCESS";
export const LOAD_ALL_CAMPAIGNS_DATA_FAILED = "LOAD_ALL_CAMPAIGNS_DATA_FAILED";

export const LOAD_EDIT_CAMPAIGN_DATA = "LOAD_EDIT_CAMPAIGN_DATA";
export const LOAD_EDIT_CAMPAIGN_DATA_SUCCESS = "LOAD_EDIT_CAMPAIGN_DATA_SUCCESS";
export const LOAD_EDIT_CAMPAIGN_DATA_FAILED = "LOAD_EDIT_CAMPAIGN_DATA_FAILED";


export const EDIT_CAMPAIGN_DIALOGUE_CLOSED = "EDIT_CAMPAIGN_DIALOGUE_CLOSED";


export const SEND_EDIT_CAMPAIGN_DATA = "SEND_EDIT_CAMPAIGN_DATA";
export const SEND_EDIT_CAMPAIGN_DATA_SUCCESS = "SEND_EDIT_CAMPAIGN_DATA_SUCCESS";
export const SEND_EDIT_CAMPAIGN_DATA_FAILED = "SEND_EDIT_CAMPAIGN_DATA_FAILED";

export const DELETE_CAMPAIGN_DATA = "DELETE_CAMPAIGN_DATA";
export const DELETE_CAMPAIGN_DATA_SUCCESS = "DELETE_CAMPAIGN_DATA_SUCCESS";
export const DELETE_CAMPAIGN_DATA_FAILED = "DELETE_CAMPAIGN_DATA_FAILED";

export const ARCHIVE_CAMPAIGN_DATA = "ARCHIVE_CAMPAIGN_DATA";
export const ARCHIVE_CAMPAIGN_DATA_SUCCESS = "ARCHIVE_CAMPAIGN_DATA_SUCCESS";
export const ARCHIVE_CAMPAIGN_DATA_FAILED = "ARCHIVE_CAMPAIGN_DATA_FAILED";

export const LOAD_TOKEN_TAGS = "LOAD_TOKEN_TAGS";
export const LOAD_TOKEN_TAGS_SUCCESS = "LOAD_TOKEN_TAGS_SUCCESS";
export const LOAD_TOKEN_TAGS_FAILED = "LOAD_TOKEN_TAGS_FAILED";

export const LOAD_SEARCH_TAGS = "LOAD_SEARCH_TAGS";
export const LOAD_SEARCH_TAGS_SUCCESS = "LOAD_SEARCH_TAGS_SUCCESS";
export const LOAD_SEARCH_TAGS_FAILED = "LOAD_SEARCH_TAGS_FAILED";

export const GET_CHARTS_DATA = "GET_CHARTS_DATA";
export const GET_CHARTS_DATA_SUCCESS = "GET_CHARTS_DATA_SUCCESS";
export const GET_CHARTS_DATA_FAILED = "GET_CHARTS_DATA_FAILED";

export const TIME_ZONES_DATA = "TIME_ZONES_DATA";
export const TIME_ZONES_DATA_SUCCESS = "TIME_ZONES_DATA_SUCCESS";
export const TIME_ZONES_DATA_FAILED = "TIME_ZONES_DATA_FAILED";

export const MANUAL_COST_UPDATE_DATA = "MANUAL_COST_UPDATE_DATA";
export const MANUAL_COST_UPDATE_DATA_SUCCESS = "MANUAL_COST_UPDATE_DATA_SUCCESS";
export const MANUAL_COST_UPDATE_DATA_FAILED = "MANUAL_COST_UPDATE_DATA_FAILED";

export const GET_COST_CURRENCY_DATA = "GET_COST_CURRENCY_DATA";
export const GET_COST_CURRENCY_DATA_SUCCESS = "GET_COST_CURRENCY_DATA_SUCCESS";
export const GET_COST_CURRENCY_DATA_FAILED = "GET_COST_CURRENCY_DATA_FAILED";