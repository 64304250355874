import React from "react";
import {Button} from "@progress/kendo-react-buttons";
import "./redirectDomains.css";
import {withRouter} from "react-router";
import { connect } from 'react-redux';
import {createStructuredSelector} from "reselect";
import {
    makeSelectError,
    makeSelectLoading,
    makeSelectSuccess,
    makeSelectRedirectDomainDataReceived,
    makeSelectIsRedirectDomainDataSent,
    makeSelectDomainsDataReceived
} from "../selectors";
import * as domainsActions from "../actions";
import produce from "immer";
import {Fade} from "@progress/kendo-react-animation";

class RedirectDomains extends React.Component {
    state = {
        domainsDataReceived: null,
        redirectDomainName: "",
        error: null,
        successMsg: null,
        isRedirectDomainDataSent: false,
        isRedirectDomainDataReceived: false
    }

    fetchDomains = async () => {
        this.props.dispatchLoadAllDomains(this.props.accessToken);
    }

    sendRedirectDomain = async () => {
        this.props.dispatchSendRedirectDomain({
            accessToken: this.props.accessToken,
            redirectDomainName: this.state.redirectDomainName
        });
    }

    fetchRedirectDomain = async () => {
        this.props.dispatchLoadRedirectDomain(this.props.accessToken);
    }

    componentDidMount() {
        if (this.state.domainsDataReceived === null) {
            this.fetchDomains();
        }
        if (this.state.redirectDomainName === "" ) {
            this.fetchRedirectDomain();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Set domains list initially to the state
        // and then check if there is any domain already selected as redirect one then list it to the state
        if (this.state.domainsDataReceived === null && this.props.domainsDataReceived !== null && this.props.domainsDataReceived.domain.length > 0) {
            this.setState(produce(draft => { draft.domainsDataReceived = this.props.domainsDataReceived.domain }));
            for (let i=0; i < this.props.domainsDataReceived.domain.length; i++) {
                if (this.props.domainsDataReceived.domain[i].is_redirect === true) {
                    this.setState(produce(draft => { draft.redirectDomainName = this.props.domainsDataReceived.domain[i].name }));
                }
            }
        }

        // check if redirect domain sent to the server successfully
        if (this.props.isRedirectDomainDataSent && this.state.isRedirectDomainDataSent) {
            this.setState(produce(draft => { draft.successMsg = "Redirect domain selected successfully." }));
            this.setState(produce(draft => { draft.isRedirectDomainDataSent = false }));
            setTimeout(() => {
                this.setState(produce(draft => { draft.successMsg = null }));
            },5000);
        }

        // check if redirect domain data is received
        if (this.props.redirectDomainDataReceived !== null && this.state.isRedirectDomainDataReceived === false) {
            this.setState(produce(draft => { draft.redirectDomainName = this.props.redirectDomainDataReceived.name }));
            this.setState(produce(draft => { draft.isRedirectDomainDataReceived = true }));
        }
    }

    simpleInputChangeHandler = (e) => {
        this.setState(produce(draft => { draft.redirectDomainName = e.target.value }));
    }
    submitRedirectDomainHandler = () => {
        if (this.state.redirectDomainName === "") {
            this.setState(produce(draft => { draft.error = "Please select a Redirect domain before saving data." }))
        } else {
            this.setState(produce(draft => { draft.error = null }));
            this.sendRedirectDomain();
            this.setState(produce(draft => { draft.isRedirectDomainDataSent = true }));
        }
    }


    render () {
        return (
            <div className="white_box_with_header_con white_bg">
                <header className="header light_gray_border_color">
                    <div className="title_con">
                        <h2 className="h2 gray_h2 inline_block">REDIRECT DOMAIN</h2>
                    </div>
                </header>
                <div className="box">
                    <section className="section text_align_left">
                        <p className="normal_text normal_font black k-ml-0 k-pl-0">If you redirect visitors using the Double meta
                            refresh option in the campaign configuration, the redirect domain name will show up in their
                            browser’s address bar. We highly recommend adding a custom redirect domain and set it up the
                            same way as a custom domain.</p>
                        <p className="normal_text normal_font black  k-ml-0 k-pl-0">The same domain can be used for both, custom domain
                            and custom redirect domain.</p>
                        <div className="col-xs-12 col-sm-7 example-col k-mt-5">
                            <div className="redirect_domains_container_row">
                                {/*<div>*/}
                                {/*    <input type="radio" id="existing-domain" className="settings_radio_inputs"*/}
                                {/*           name="redirect-domain" value="existing-domain"/>*/}
                                {/*    <label htmlFor="existing-domain" className="normal_text black">Existing*/}
                                {/*        Domain</label>*/}
                                {/*</div>*/}
                                {
                                    this.state.domainsDataReceived !== null && this.state.domainsDataReceived.length > 0
                                        ?
                                        <div className="col-xs-12 example-col k-mt-2 k-ml-0">
                                            <div className="select_dropdown">
                                                <select
                                                    className="half_width select k-mb-5"
                                                    name="redirectDomainName"
                                                    value={this.state.redirectDomainName}
                                                    onChange={this.simpleInputChangeHandler}
                                                >
                                                    <option value="">Select redirect domain</option>
                                                    {
                                                        this.state.domainsDataReceived.map(domain => (
                                                            <option key={domain.id} value={domain.name}>{domain.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            {/* error msg */}
                                            {
                                                this.state.error !== null ?
                                                    <Fade enter={true} exit={true}>
                                                        <p className="danger_para mb-5 mh-0">{this.state.error}</p>
                                                    </Fade>
                                                    : null
                                            }
                                            {/* success msg */}
                                            {
                                                this.state.successMsg !== null ?
                                                    <Fade enter={true} exit={true}>
                                                        <p className="success_para mb-5 mh-0">{this.state.successMsg}</p>
                                                    </Fade>
                                                    : null
                                            }
                                        </div>
                                        :
                                        <Fade enter={true} exit={true}>
                                            <p className="warning_para mb-10 mh-0">You have not added any domains yet.</p>
                                        </Fade>
                                }
                            </div>
                            {/*<div className="redirect_domains_container_row">*/}
                            {/*    <div>*/}
                            {/*        <input type="radio" id="custom-domain" className="settings_radio_inputs"*/}
                            {/*               name="redirect-domain" value="custom-domain"/>*/}
                            {/*        <label htmlFor="custom-domain" className="normal_text black">Custom</label>*/}
                            {/*    </div>*/}
                            {/*    <div>*/}
                            {/*        <input type="text"*/}
                            {/*               className="inline_block redirect_domains_radio_check_inputs half_width"*/}
                            {/*               value={this.state.redirectDomainName}*/}
                            {/*               onChange={this.simpleInputChangeHandler}*/}
                            {/*        />*/}
                            {/*        <div className="inline_block normal_text theme_blue_color verify_dns">*/}
                            {/*            <span className="k-icon k-i-check-circle icon_color_theme"></span>*/}
                            {/*            &nbsp;*/}
                            {/*            Verify DNS Settings*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <p className="note" style={{ margin: "0px", padding: "0px", fontSize: "11px", color: "black" }}>* You need to click the save button if you want to change the redirect domain.</p>
                            <div className="redirect_domains_container_row text_align_right k-mt-5">
                                {/*<Button*/}
                                {/*    className="edit_button gray_button_bg_imp black profile_form_btn"*/}
                                {/*>*/}
                                {/*    Cancel*/}
                                {/*</Button>*/}
                                <Button
                                    className="theme_dark_bg_color_imp color_white_imp theme_dark_bg_color save_button normal_text"
                                    onClick={this.submitRedirectDomainHandler}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
};

export const mapStateToProps = createStructuredSelector({
    loading: makeSelectLoading(),
    success: makeSelectSuccess(),
    error: makeSelectError(),
    domainsDataReceived: makeSelectDomainsDataReceived(),
    redirectDomainDataReceived: makeSelectRedirectDomainDataReceived(),
    isRedirectDomainDataSent: makeSelectIsRedirectDomainDataSent()
});
export function mapDispatchToProps(dispatch) {
    return {
        dispatchSendRedirectDomain: (...payload) => dispatch(domainsActions.sendRedirectDomain(...payload)),
        dispatchLoadRedirectDomain: (...payload) => dispatch(domainsActions.loadRedirectDomain(...payload)),
        dispatchLoadAllDomains: (...payload) => dispatch(domainsActions.loadCustomDomains(...payload)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RedirectDomains));