import React from "react";
import "./calendar.css";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
// import moment from "moment-timezone";
// import { timeZonesConversion } from "../../timeZonesExchange";
import ReactTooltip from "react-tooltip";

const MenuCalendar = (props) => {
  return (
    <div className="date_container">
      <div
        className="date_display normal_text black "
        data-tip
        data-for="calendar_tt"
        onClick={props.toggleCalendarWindow}
      >
        Date: {props.modifiedDate.startDate + ", " + props.modifiedDate.endDate}
      </div>
      {!props.defaultTimeZone.includes("Default") ? (
        <ReactTooltip
          className="grid_tooltip box_shadow_tt"
          id="calendar_tt"
          type="light"
        >
          <span>{props.defaultTimeZone}</span>
        </ReactTooltip>
      ) : null}
      {props.showCalendar ? (
        <div className="date_popup_container">
          <DateTimeRangeContainer
            ranges={props.ranges}
            start={props.dates.startDate}
            end={props.dates.endDate}
            applyCallback={props.applyCallback}
            rangeCallback={props.rangeCallback}
            smartMode
            standalone
            months={2}
            style={{
              standaloneLayout: { display: "flex", maxWidth: "fit-content",color:"#fff" },
            }}
          />
          <hr />
          <div className="calendar_bottom_row">
            <div className="select_dropdown">
              <select
                id="timeZone_select"
                name="timeZone_select"
                value={props.dates.timeZone}
                onChange={props.timeZoneChange}
              >
                {props.timeZonesList.length > 0
                  ? props.timeZonesList.map((timeZone) => (
                      <option value={timeZone.calendar}>{timeZone.name}</option>
                    ))
                  : null}
              </select>
            </div>
            <div className="cancelBtn" onClick={props.toggleCalendarWindow}>
              Close
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default MenuCalendar;
