import { createSelector } from "reselect";
import { initialReportState } from "./reducer";

const selectReportState= (state) =>  state.Report || initialReportState;

const makeSelectLoading = () => createSelector(selectReportState, (subState) => subState.loading);
const makeSelectSuccess = () => createSelector(selectReportState, (subState) => subState.success);
const makeSelectError = () => createSelector(selectReportState, (subState) => subState.error);

const makeSelectIsChartsDataLoaded = () => createSelector(selectReportState, (subState) => subState.charts.isChartsDataLoaded);
const makeSelectAllChartsDataLoaded = () => createSelector(selectReportState, (subState) => subState.charts.allChartsDataLoaded);

const makeSelectAllLoadedReportData = () => createSelector(selectReportState, (subState) => subState.allLoadedReportData);
const makeSelectSubLevelReportData = () => createSelector(selectReportState, (subState) => subState.reportSubLevelData);


export {
    makeSelectLoading,
    makeSelectSuccess,
    makeSelectError,

    makeSelectAllLoadedReportData,
    makeSelectIsChartsDataLoaded,

    makeSelectAllChartsDataLoaded,
    makeSelectSubLevelReportData
}