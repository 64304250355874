import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
// import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import MenuCalendar from "../../components/header/menuCalendar";
import GroupingDropdowns from "../../components/header/groupingDropdowns";
import produce from "immer";
import { withRouter } from "react-router-dom";
import { getSearchParameters } from "../../components/searchParameters";
// import { formatDate } from "../../components/searchParameters";
import moment from "moment-timezone";
import { setDatesToLocalStorage } from "../../components/setDatesToLocalStorage";
import { timeZonesConversion } from "../../components/timeZonesExchange";
import "./pageMenuBarTop.css";

class PageMenuBarTop extends React.Component {
  state = {
    search: {
      tagsListReceived: [],
      inputSearchValue: "",
      tagsSearchValue: [],
      isTagsSearchSelected: false,
      groupingValues: {
        main: "",
        group1: "",
        group2: "",
      },
      dates: {
        startDate: moment(new Date()),
        endDate: moment(new Date()),
        timeZone: "Pacific/Kwajalein",
        secondDisplay: false,
      },
      modifiedDate: {
        startDate: moment(new Date()).format("MM/DD/YYYY hh:mm A"),
        endDate: moment(new Date()).format("MM/DD/YYYY hh:mm A"),
      },
      reportGroupingValues: {
        id: "",
        filter1: "",
        filter2: "",
        filter3: "",
        filter4: "",
        pageType: "",
      },
      isFiltersChanged: false,
    },
    disable: {
      group1: false,
      group2: false,
    },
    ranges: {},
    defaultTimeZone: "(GMT -12:00) Eniwetok, Kwajalein",
    timeZonesList: [],
    disableApplyButton:false
  };

  componentDidMount() {
    // check values in the localStorage
    // then populate the data in this state
    setTimeout(() => {
      let searchParameters = getSearchParameters();
      this.setState(
        produce((draft) => {
          draft.search.tagsListReceived = searchParameters.tagsListReceived;
          draft.search.inputSearchValue = searchParameters.inputSearchValue;
          draft.search.tagsSearchValue = searchParameters.tagsSearchValue;
          draft.search.isTagsSearchSelected =
            searchParameters.isTagsSearchSelected;
          draft.search.groupingValues = searchParameters.groupingValues;
          // draft.search.dates = searchParameters.dates;
          draft.search.dates.timeZone = searchParameters.dates.timeZone;
          draft.search.dates.startDate = moment(
            searchParameters.dates.startDate
          );
          draft.search.dates.endDate = moment(searchParameters.dates.endDate);
          draft.search.modifiedDate = {
            startDate: moment(searchParameters.dates.startDate).format(
              "MM/DD/YYYY hh:mm A"
            ),
            endDate: moment(searchParameters.dates.endDate).format(
              "MM/DD/YYYY hh:mm A"
            ),
          };
          draft.search.isFiltersChanged = searchParameters.isFiltersChanged;
        })
      );
    }, 500);
    setTimeout(() => {
      this.returnRanges();
      this.calculateNewTimeZonesList();
    }, 1000);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.showCalendar !== this.props.showCalendar) {
      this.setState(
        produce((draft) => {
          draft.showCalendar = this.props.showCalendar;
        })
      );
    }
  }

  addTooltipDate = () => {
    let search = JSON.parse(localStorage.getItem("search"));
    for (let i = 0; i < this.state.timeZonesList.length; i++) {
      if (search.dates.timeZone === this.state.timeZonesList[i].calendar) {
        this.setState(
          produce((draft) => {
            draft.defaultTimeZone = this.state.timeZonesList[i].name;
          })
        );
      }
    }
  };

  calculateNewTimeZonesList = () => {
    let newTimeZoneList = [];
    let defaultTimeZoneId = JSON.parse(localStorage.getItem("search")).setting
      .timeZoneId;
    for (let i = 0; i < timeZonesConversion.length; i++) {
      if (Number(defaultTimeZoneId) === Number(timeZonesConversion[i].id)) {
        newTimeZoneList.push({
          id: timeZonesConversion[i].id,
          name: "Default: " + timeZonesConversion[i].name,
          calendar: timeZonesConversion[i].calendar,
        });
      }
    }
    for (let i = 0; i < timeZonesConversion.length; i++) {
      if (Number(defaultTimeZoneId) !== Number(timeZonesConversion[i].id)) {
        newTimeZoneList.push({
          id: timeZonesConversion[i].id,
          name: timeZonesConversion[i].name,
          calendar: timeZonesConversion[i].calendar,
        });
      }
    }
    this.setState(
      produce((draft) => {
        draft.timeZonesList = newTimeZoneList;
      })
    );
    setTimeout(() => {
      this.addTooltipDate();
    }, 100);
  };

  changeSearchValues = (e, data) => {
    if (data === "tagsSearchValue") {
      this.setState(
        produce((draft) => {
          draft.search.tagsSearchValue = e.target.value;
        })
      );
      setTimeout(() => {
        let search = JSON.parse(localStorage.getItem("search"));
        search.tagsSearchValue = this.state.search.tagsSearchValue;
        localStorage.setItem("search", JSON.stringify(search));
      }, 50);
    } else if (data === "inputSearchValue") {
      if (e === "") {
        this.setState(
          produce((draft) => {
            draft.search.inputSearchValue = "";
          }),
          () => {
            this.sendRequest();
            let search = JSON.parse(localStorage.getItem("search"));
            search.inputSearchValue = this.state.search.inputSearchValue;
            localStorage.setItem("search", JSON.stringify(search));
          }
        );
      } else {
        this.setState(
          produce((draft) => {
            draft.search.inputSearchValue = e?.target?.value ?? "";
          })
        );
        setTimeout(() => {
          let search = JSON.parse(localStorage.getItem("search"));
          search.inputSearchValue = this.state.search.inputSearchValue;
          localStorage.setItem("search", JSON.stringify(search));
        }, 50);
      }
    } else if (data === "grouping") {
      this.setState(
        produce((draft) => {
          draft.search.groupingValues.main = e[0];
          draft.search.groupingValues.group1 = e[1];
          draft.search.groupingValues.group2 = e[2];
        })
      );
      if (
        e[0] === "Month" ||
        e[0] === "Day" ||
        e[0] === "Conversion" ||
        e[0] === "Hour Of day"
      ) {
        this.setState(
          produce((draft) => {
            draft.disable.group1 = true;
            draft.disable.group2 = true;
            draft.search.groupingValues.group1 = "";
            draft.search.groupingValues.group2 = "";
          })
        );
      } else if (
        e[1] === "Month" ||
        e[1] === "Day" ||
        e[1] === "Conversion" ||
        e[1] === "Hour Of day"
      ) {
        this.setState(
          produce((draft) => {
            draft.disable.group1 = false;
            draft.disable.group2 = true;
            draft.search.groupingValues.group2 = "";
          })
        );
      } else {
        this.setState(
          produce((draft) => {
            draft.disable.group1 = false;
            draft.disable.group2 = false;
          })
        );
      }
      setTimeout(() => {
        let search = JSON.parse(localStorage.getItem("search"));
        search.groupingValues = this.state.search.groupingValues;
        localStorage.setItem("search", JSON.stringify(search));
      }, 50);
    }
  };

  onSearchBtnClick = () => {
    this.setState(
      produce((draft) => {
        draft.search.isTagsSearchSelected =
          !this.state.search.isTagsSearchSelected;
      })
    );
    setTimeout(() => {
      localStorage.setItem("search", JSON.stringify(this.state.search));
    }, 50);
  };

  sendRequest = () => {
    // check if first or 2nd grouping values are empty...
    // then set the value in the first grouping
    if (this.state.search.groupingValues.main === "") {
      if (this.state.search.groupingValues.group1 === "") {
        if (this.state.search.groupingValues.group2 === "") {
          this.setState(
            produce((draft) => {
              draft.search.groupingValues.main = this.props.pageType;
            })
          );
        } else {
          let groupingValue = this.state.search.groupingValues.group2;
          this.setState(
            produce((draft) => {
              draft.search.groupingValues.main = groupingValue;
              draft.search.groupingValues.group1 = "";
              draft.search.groupingValues.group2 = "";
            })
          );
        }
      } else {
        let group1Value = this.state.search.groupingValues.group1;
        let group2Value = this.state.search.groupingValues.group2;
        this.setState(
          produce((draft) => {
            draft.search.groupingValues.main = group1Value;
            draft.search.groupingValues.group1 = group2Value;
            draft.search.groupingValues.group2 = "";
          })
        );
      }
      setTimeout(() => {
        localStorage.setItem("search", JSON.stringify(this.state.search));
      }, 25);
    } else if (this.state.search.groupingValues.group1 === "") {
      if (this.state.search.groupingValues.group2 !== "") {
        let group2Value = this.state.search.groupingValues.group2;
        this.setState(
          produce((draft) => {
            draft.search.groupingValues.group1 = group2Value;
            draft.search.groupingValues.group2 = "";
          })
        );
      }
    }
    setTimeout(() => {
      // check grouping values
      // redirect or not based on the grouping values....
      // send data to respective page
      if (this.state.search.groupingValues.main === "Campaign") {
        this.props.history.push("/campaigns/");
        this.props.sendSearchRequest();
      } else if (this.state.search.groupingValues.main === "Offers") {
        this.props.history.push("/offers/");
        this.props.sendSearchRequest();
      } else if (this.state.search.groupingValues.main === "Lander") {
        this.props.history.push("/landers/");
        this.props.sendSearchRequest();
      } else if (
        this.state.search.groupingValues.main === "Affiliate Network"
      ) {
        this.props.history.push("/affiliate-networks/");
        this.props.sendSearchRequest();
      } else if (this.state.search.groupingValues.main === "Traffic Source") {
        this.props.history.push("/traffic-sources/");
        this.props.sendSearchRequest();
      } else if (this.state.search.groupingValues.main === "Flows") {
        this.props.history.push("/flows/");
        this.props.sendSearchRequest();
      } else {
        this.props.sendSearchRequest();
      }
    }, 50);
  };

  rangeCallback = (index, value) => {
    //   setTimeout(() => {
    //     for (let i=0;i<12;i++) {
    //         document.getElementById("rangeButton" + i).style.backgroundColor = "rgb(245, 245, 245) !important";
    //         document.getElementById("rangeButton" + i).style.border = "1px solid rgb(245, 245, 245) !important";
    //         document.getElementById("rangeButton" + i).style.color = "rgb(0, 136, 204) !important";
    //     }
    //     document.getElementById("rangeButton" + index).style.backgroundColor = "rgb(0, 136, 204) !important";
    //     document.getElementById("rangeButton" + index).style.border = "1px solid rgb(245, 245, 245) !important";
    //     document.getElementById("rangeButton" + index).style.color = "rgb(245, 245, 245) !important";
    //   },50);
  };

  returnRanges = () => {
    let now = new Date();
    let start = moment(now).startOf("day");
    let end = moment(now).endOf("day");

    const ranges = {
      Today: [moment(start), moment(end)],
      Yesterday: [
        moment(start).subtract(1, "days"),
        moment(end).subtract(1, "days"),
      ],
      "Last 24 hours": [moment(now).subtract(1, "days"), moment(now)],
      "Last 48 hours": [moment(now).subtract(2, "days"), moment(now)],
      "Last 3 Days": [moment(start).subtract(2, "days"), moment(end)],
      "Last 7 Days": [moment(start).subtract(6, "days"), moment(end)],
      "This month": [
        moment(start).clone().startOf("month"),
        moment(end).clone().endOf("month"),
      ],
      "Last 30 Days": [moment(start).subtract(29, "days"), moment(end)],
      "Last month": [
        moment(start).subtract(1, "months").startOf("month"),
        moment(start).subtract(1, "months").endOf("month"),
      ],
      "This year": [
        moment(start).clone().startOf("year"),
        moment(start).clone().endOf("year"),
      ],
      "Last year": [
        moment(start).subtract(1, "year").startOf("year"),
        moment(start).subtract(1, "year").endOf("year"),
      ],
    };
    this.setState(
      produce((draft) => {
        draft.ranges = ranges;
      })
    );
  };

  setDatesToState = (startDate, endDate) => {
    this.setState(
      produce((draft) => {
        draft.search.dates.startDate = startDate;
        draft.search.dates.endDate = endDate;
        draft.search.modifiedDate.startDate =
          startDate.format("MM/DD/YYYY hh:mm A");
        draft.search.modifiedDate.endDate =
          endDate.format("MM/DD/YYYY hh:mm A");
      }),
      () => this.sendRequest()
    );
  };

  applyCallback = (startDate, endDate) => {
    this.setDatesToState(startDate, endDate);
    setDatesToLocalStorage(startDate, endDate);
  };

  timeZoneChange = (e) => {
    this.setState(
      produce((draft) => {
        draft.search.dates.timeZone = e.target.value;
      })
    );

    // set the changed timeZone to localStorage
    let search = JSON.parse(localStorage.getItem("search"));
    search.dates.timeZone = e.target.value;
    search.report.report1.search.date.timeZone = e.target.value;
    search.report.report2.search.date.timeZone = e.target.value;
    search.report.report3.search.date.timeZone = e.target.value;
    localStorage.setItem("search", JSON.stringify(search));
    setTimeout(() => {
      this.addTooltipDate();
    }, 60);
  };

  disableApplyButton = (value) => this.setState({disableApplyButton: value})



  render() {
    return (
      <div className="page_menu_bar_top">
        <div className="grouping_dropdowns">
          <GroupingDropdowns
            getGroupingValues={this.state.search.groupingValues}
            changeSearchValues={this.changeSearchValues}
            disable={this.state.disable}
            disableApplyButton={this.disableApplyButton}
          />
          <div className="grouping_tags">
            <div className="search_and_date_filters">
              <Button
                className="apply_btn"
                icon="k-i-icon k-i-checkmark"
                onClick={this.sendRequest}
                style={
                  this.state.disableApplyButton ? {
                    filter: "grayscale(0.1)",
                    cursor: "not-allowed",
                    opacity: "0.3",
                    pointerEvents: "none"
                  } : null
                }
              >
                Apply
              </Button>
            </div>
          </div>
        </div>
        <div className="search_and_date_filters">
          <div className="search_input">
            <div className="search_type_selection inline_block k-mr-2">
              <span
                className="normal_text"
                style={
                  this.state.search.isTagsSearchSelected
                    ? { backgroundColor: "transparent",borderRadius:" 5px 0 0 5px",borderColor:"white",color:"white",borderRightColor:"transparent"}
                    : {
                        backgroundColor: "transparent",borderColor:"#e0358b",
                        color: "#e0358b",borderRadius:" 5px 0 0 5px",borderRightColor:"#e0358b"
                      }
                }
                onClick={this.onSearchBtnClick}
              >
                text
              </span>
              <span
                className="normal_text"
                style={
                  this.state.search.isTagsSearchSelected
                    ? {
                        backgroundColor: "transparent",borderColor:"#e0358b",color: "#e0358b",borderRadius:"0 5px 5px 0",borderLeftColor:"#e0358b"
                      }
                    : { backgroundColor: "transparent",borderRadius:"0 5px 5px 0",borderColor:"white",color:"white",borderLeftColor:"transparent" }
                }
                onClick={this.onSearchBtnClick}
              >
                tags
              </span>
            </div>
            {this.state.search.isTagsSearchSelected ? (
              <MultiSelect
                data={this.state.search.tagsListReceived}
                id="tagsSearchValue"
                onChange={(e) => this.changeSearchValues(e, "tagsSearchValue")}
                value={this.state.search.tagsSearchValue}
                textField="text"
                dataItemKey="id"
              />
            ) : (
              // <input
              //   className="normal_text black simple_gray_border"
              //   type="text"
              //   placeholder="Search..."
              //   value={this.state.search.inputSearchValue}
              //   id="inputSearchValue"
              //   onChange={(e) => this.changeSearchValues(e, "inputSearchValue")}
              // />
              <div style={{ position: "relative" }}>
                <input
                  className="search-box normal_text black simple_gray_border"
                  type="text"
                  placeholder="Search Name..."
                  value={this.state.search.inputSearchValue}
                  id="inputSearchValue"
                  style={{ width: "245px" }}
                  onChange={(e) =>
                    this.changeSearchValues(e, "inputSearchValue")
                  }
                />
                {Boolean(this.state.search.inputSearchValue) && (
                  <span
                    className="k-icon k-i-check"
                    style={{
                      position: "absolute",
                      top: "20%",
                      right: "16%",
                      color: "#656565",
                    }}
                    onClick={() => this.sendRequest()}
                  ></span>
                )}
                {Boolean(this.state.search.inputSearchValue) && (
                  <span
                    className="k-icon k-i-x"
                    style={{
                      position: "absolute",
                      top: "20%",
                      right: "6%",
                      color: "darkgoldenrod",
                    }}
                    onClick={() => {
                      this.changeSearchValues("", "inputSearchValue");
                    }}
                  ></span>
                )}
              </div>
            )}
          </div>
          <MenuCalendar
            defaultTimeZone={this.state.defaultTimeZone}
            dates={this.state.search.dates}
            modifiedDate={this.state.search.modifiedDate}
            showCalendar={this.props.showCalendar}
            toggleCalendarWindow={this.props.toggleCalendarWindow}
            // modifiedDate={this.state.search.modifiedDate}
            ranges={this.state.ranges}
            applyCallback={this.applyCallback}
            rangeCallback={this.rangeCallback}
            timeZoneChange={this.timeZoneChange}
            timeZonesList={this.state.timeZonesList}
            sendRequest={this.sendRequest}
          />
          <Button
            className="apply_btn"
            icon="k-i-icon k-i-refresh"
            onClick={this.sendRequest}
          >
            Refresh
          </Button>

          <button
            className="k-button normal_text normal_font page_bottom_menu_button k-ml-1 k-mr-1"
            onClick={() => this.props.showCharts()}
          >
            <span className="k-icon k-i-copy"></span>
            &nbsp; Charts
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(PageMenuBarTop);
