import { call, put, takeLatest } from "redux-saga/effects";
import {
   LOAD_ALL_CONVERSIONS_DATA, SEARCHED_VALUE, LOAD_LAST_ROWS
} from "./constants";
import {
    loadConversionsDataFailed,
    loadConversionsDataSuccess,
} from "./actions";

import {
    LoadAllConversions,LoadLastRowsFromConversion, SearchedValueForConversion
} from "./api";

function* loadAllConversionsData(payload) {
    try {
        const response = yield call(LoadAllConversions, payload);
        console.log("esssssssssss", response)
        yield put(
            loadConversionsDataSuccess(response.data)
        );
    } catch (error) {
        yield put(loadConversionsDataFailed(error));
    }
}

function* loadLastRowsFromConversion(payload) {
    try {
        const response = yield call(LoadLastRowsFromConversion, payload);
        yield put(
            loadConversionsDataSuccess(response.data)
        );
    } catch (error) {
        yield put(loadConversionsDataFailed(error));
    }
}

function* searchedValue(payload) {
    try {
        const response = yield call(SearchedValueForConversion, payload);
        yield put(
            loadConversionsDataSuccess(response.data)
        );
    } catch (error) {
        yield put(loadConversionsDataFailed(error));
    }
}


export default function* CampaignsSaga() {
    yield takeLatest(LOAD_ALL_CONVERSIONS_DATA, loadAllConversionsData);
    yield takeLatest(LOAD_LAST_ROWS, loadLastRowsFromConversion);
    yield takeLatest(SEARCHED_VALUE, searchedValue);
}