import React, { useEffect } from "react";
import { BrowserRouter as Router} from "react-router-dom";
import './App.css';
import "@progress/kendo-theme-default/dist/all.css";
import Routes from "./routes/routes";
import auth from "./auth";
import { logoutUser } from './registration/LoginRegister/actions';
import { connect } from 'react-redux';
import { getUserParameters } from "./components/userParameters";
import { getSearchParameters } from "./components/searchParameters";
import { accessTokenExists, checkAccessTokenExpiry } from "./components/accessTokenCheck";

const  App = (props) => {

    useEffect(() => {
        // set initial parameters in the localstorage as soon as the app loads
        // this will check if there are any search or user parameters in the localstorage
        // if there are then it will do nothing otherwise it will create them
        getUserParameters();
        getSearchParameters();

        // if there is already accessToken parameter present...
        // then it means the user already has a session giong on...
        // then check if the sesstion has expired or not
        // if expired logout... otherwise do nothing.
        if (accessTokenExists()) {
            checkAccessTokenExpiry(props.logoutUser, auth);
        }
    },[]); 


    return (
    <div>
        <Router>
            <div className="App">
                <Routes />
            </div>
        </Router>
    </div>
  );
}
export function mapDispatchToProps(dispatch) {
    return {
        logoutUser: () => dispatch(logoutUser()),
    };
  }
export default connect(null, mapDispatchToProps)(App);